import { FormikError } from "@spordle/formik-elements";
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Col, Fade, ModalBody, Row } from 'reactstrap';
import { mixed, object, string } from 'yup';
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import { I18nContext } from "../../../../../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../../../../../contexts/IdentityRolesContext";
import { OrganizationContext } from '../../../../../../../contexts/OrganizationContext';
import { DisplayI18n, displayI18n } from "../../../../../../../helpers/i18nHelper";
import { BC_ID } from '../../../gameIncidentHelper';
import StepFooter from '../../components/StepFooter';
import StepTitle from '../../components/StepTitle';
import TeamCard from './components/TeamCard';
import { fail } from "@spordle/toasts";

export const teamInit = {
    branch_id: BC_ID,
    coach: '',
    coach_name: '',
    existing: '1',
    manager: '',
    manager_name: '',
    members: [],
    organisation_id: '',
    team_id: '',
    team_name: '',
};

/**
 * @description The team step of the incident add form. Contains away team and home team info
 * @param {Object} props
 * @param {function} props.next
 * @param {function} props.previous
 * @returns {JSX.Element}
 */
const AddGameIncidentStepTeam = ({ next, girData, updateGirData, previous }) => {
    const { getOrganizationByCategories, getOrganizationCategory, organisation_id } = useContext(OrganizationContext);
    const { federation } = useContext(IdentityRolesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const [ ressources, setRessources ] = useState({
        awayStaff: null,
        homeStaff: null,
        branches: null,
        categories: null,
    });

    const teamSchema = object().shape({
        coach: string(),
        coach_name: string().when([ 'coach', 'existing' ], {
            is: (val, existing) => existing == "1" && val === 'OTHER',
            then: string().required(<Translate id='gameIncident.addModal.validation.coach.other' />),
        }),
        existing: string(),
        manager: string(),
        manager_name: string().when([ 'manager', 'existing' ], {
            is: (val, existing) => existing == "1" && val === 'OTHER',
            then: string().required(<Translate id='gameIncident.addModal.validation.manager.other' />),
        }),
        organisation_id: string().when('existing', {
            is: '1',
            then: string().required(<Translate id='gameIncident.addModal.validation.organisation' />),
        }),
        team_id: string().when([ 'existing', 'organisation_id' ], {
            is: (existing, orgId) => existing == "1" && !!orgId,
            then: string().required(<Translate id='gameIncident.addModal.validation.team' />),
        }),
        team_name: string().when('existing', {
            is: '0',
            then: string().required(<Translate id='gameIncident.addModal.validation.teamName' />),
        }),
    });

    const updateData = (data) => setRessources((prev) => ({ ...prev, ...data }));

    const getBranches = async() => {
        const categories = await getOrganizationCategory(organisation_id);
        const branchCatId = categories?.find((c) => (c.default_name || '').toLowerCase() === "member branches")?.category_id;
        const branches = await getOrganizationByCategories(federation.organisation_id, { organisation_category_id: branchCatId });

        updateData({
            categories: categories || [],
            branches: (branches || []).sort((a, b) => {
                const aName = displayI18n('name', a.i18n, a.organisation_name, getGenericLocale());
                const bName = displayI18n('name', b.i18n, b.organisation_name, getGenericLocale());
                return aName.localeCompare(bName);
            }),
        })
    }

    useEffect(() => { // on mount
        getBranches()
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    updateData({ branches: [] });
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, []);

    return (
        <Formik
            initialValues={{
                away_team: teamInit,
                home_team: teamInit,
                teams_validation: "valuidate",
            }}
            validationSchema={object().shape({
                away_team: teamSchema,
                home_team: teamSchema,
                teams_validation: mixed().test({
                    name: 'oneExisting',
                    message: <Translate id='gameIncident.addModal.validation.twoOtherTeams' />,
                    test: function(){
                        return this.parent.away_team.existing == "1" || this.parent.home_team.existing == "1"
                    },
                })
                    .test({
                        name: 'duplicatedTeams',
                        message: <Translate id='gameIncident.addModal.validation.twoSameTeams' />,
                        test: function(){
                            if(this.parent.away_team.existing == "0" || this.parent.home_team.existing == "0"){
                                return true;
                            }
                            const away = this.parent.away_team.team_id || 'home';
                            const home = this.parent.home_team.team_id || 'away';
                            return away !== home;

                        },
                    }),
            })}
            onSubmit={(values) => {
                updateGirData(values);
                next();
            }}
        >
            {(_) => (
                <Form>
                    <ModalBody>
                        <StepTitle title='gameIncident.addModal.steps.teams.title' />
                        <Row form>
                            <Col md="6" className="h-md-100 form-group">
                                <div className="font-medium h5 mb-2"><Translate id='gameIncident.addModal.teams.home.label' /></div>
                                <TeamCard
                                    girData={girData}
                                    branches={ressources.branches}
                                    categories={ressources.categories}
                                    teamPos="home"
                                />
                            </Col>
                            <Col md="6" className="h-md-100 form-group">
                                <div className="font-medium h5 mb-2"><Translate id='gameIncident.addModal.teams.away.label' /></div>
                                <TeamCard
                                    girData={girData}
                                    branches={ressources.branches}
                                    categories={ressources.categories}
                                    teamPos="away"
                                />
                            </Col>
                        </Row>
                        <FormikError name="teams_validation">
                            {(msg) => (
                                <Fade className="text-danger">
                                    <div className="font-medium">
                                        <Translate id='gameIncident.addModal.validation.teamWarning' />
                                    </div>
                                    <small>{msg}</small>
                                </Fade>
                            )}
                        </FormikError>
                    </ModalBody>
                    <StepFooter previous={previous} />
                </Form>
            )}
        </Formik>
    )
}

export default AddGameIncidentStepTeam;