export default{
    "task.ineligible.title"                             : "Potential Ineligible Members",
    "task.ineligible.modal.manage.title"                : "Manage Potential Ineligible Members",
    "task.ineligible.member.profile.modal.manage.title" : "Manage Potential Ineligibility",
    "task.ineligible.modal.release"                     : "Remove from list",
    "task.ineligible.modal.confirm.release"             : "Confirm removal",
    "task.ineligible.modal.ineligible"                  : "Make Ineligible",
    "task.ineligible.modal.confirm.ineligible"          : "Confirm Ineligibility",
    "task.ineligible.modal.reason.placeholder"          : "Reason for Ineligibility",
    "task.ineligible.modal.reason.validation"           : "A reason is required to make a member ineligible.",
    "task.ineligible.modal.step.success"                : "Changes successfully applied.",
    "task.ineligible.modal.step.success.multi"          : "There are no potentially ineligible members left in queue.",

    "task.ineligible.sidepanel.matching.members"              : "Potential Matches",
    "task.ineligible.sidepanel.matching.caused.members"       : "Caused by",
    "task.ineligible.sidepanel.matching.members.status"       : "Permanently Ineligible",
    "task.ineligible.sidepanel.matching.no.potential.matches" : "No potential matches found.",

    "task.ineligible.member.source.label"                : "Source",
    "task.ineligible.member.created.submitted"           : "Submitted",
    "task.ineligible.member.created.from.ONLINE"         : "Online",
    "task.ineligible.member.created.from.MANUAL"         : "Manual",
    "task.ineligible.member.created.from.THIRD_PARTY"    : "Third Party",
    "task.ineligible.member.created.from.IMPORT"         : "Import",
    "task.ineligible.member.created.from.INITIAL_IMPORT" : "Initial Import",


}