export default{
    "components.manualRegistration.waivers.title"              : "Waivers",
    "components.manualRegistration.waivers.of"                 : "of",
    "components.manualRegistration.waivers.accept"             : "I have read and accept the waiver",
    "components.manualRegistration.waivers.unsign"             : "Unsign",
    "components.manualRegistration.waivers.unsign.confirm"     : "You are about to unsign this waiver",
    "components.manualRegistration.waivers.unsign.disabled"    : "Unsigning is only available if you are the signee",
    "components.manualRegistration.waivers.myAccount"          : "Waiver from {spordle_account}",
    "components.manualRegistration.waivers.required"           : "This waiver is mandatory",
    "components.manualRegistration.waivers.notMandatoryHelper" : "This waiver is not mandatory",
    "components.manualRegistration.waivers.skipAll"            : "Skip all waivers",
    "components.manualRegistration.waivers.VERBAL"             : "Verbal agreement collected",
    "components.manualRegistration.waivers.PHYSICAL"           : "Physical agreement collected",
    "components.manualRegistration.waivers.ONLINE"             : "Online agreement collected",
}