export default{
    "onlineStore.components.onlineStorePaymentOptions.title"                          : "Payment Options",
    "onlineStore.components.onlineStorePaymentOptions.merchantAccount.title"          : "Merchant Account",
    "onlineStore.components.onlineStorePaymentOptions.merchantAccount.title.helper"   : "Allows credit card payments",
    "onlineStore.components.onlineStorePaymentOptions.merchantAccount.required"       : "A merchant account is required if no payment method is specified.",
    "onlineStore.components.onlineStorePaymentOptions.merchantAccount.empty"          : "No merchant account",
    "onlineStore.components.onlineStorePaymentOptions.paymentMethod.title"            : "Payment Methods",
    "onlineStore.components.onlineStorePaymentOptions.paymentMethod.required"         : "At least one payment method is required if no merchant account is specified.",
    "onlineStore.components.onlineStorePaymentOptions.paymentMethod.label.CHEQUE"     : "Cheque",
    "onlineStore.components.onlineStorePaymentOptions.paymentMethod.label.CASH"       : "Cash",
    "onlineStore.components.onlineStorePaymentOptions.paymentMethod.label.CREDITCARD" : "Credit card",
    "onlineStore.components.onlineStorePaymentOptions.paymentMethod.label.INTERAC"    : "Interac",
    "onlineStore.components.onlineStorePaymentOptions.paymentMethod.label.FUNDING"    : "Funding",
}