/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-class-component-methods */
import React, { createContext } from 'react';
import Translate from "@spordle/intl-elements";
import { ModalHeader } from "reactstrap";
import AnalyticsModal from '../../../../../../../analytics/AnalyticsModal';
import RefundModalRefundInformation from './RefundModalRefundInformation';
import RefundModalMemberInstallments from './RefundModalStepMemberInstallments'
import RefundModalConfirmation from './RefundModalConfirmation';
import { getConfirmCancelled } from '../../../../../../members/profile/registration/components/Sidepanel/components/ConfirmCancelledBy';

// context
import withContext from '../../../../../../../helpers/withContexts';
import { RolesContext } from '../../../../../../../contexts/RolesContext';
import { IdentityRolesContext } from '../../../../../../../contexts/IdentityRolesContext';

// isOpen,
// toggle,
// transaction,
// invoicePaymentId,
// onRefund,
// refreshTable,
// cancelPermissions,
// onCancelRegistration,
// onCancelAttendee,
// fromRegistration = false,
// mode = 'registration' OR 'clinics' (defaults to registration) (for choosing which post-refund cancel messages to show)

/** @type {React.Context<Omit<RefundModalContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const RefundModalContext = createContext()
RefundModalContext.displayName = 'RefundModalContext';

class RefundModalContextProvider extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            isRefunded: false,
            hasCancelledInstallments: false,
            activeStep: 'refundInformation', //props.onlyCancelInstallments ? 'memberInstallments' :
            transaction: props.transaction,
            invoicePaymentId: props.invoicePaymentId,
            //onlyCancelInstallment: props.onlyCancelInstallments,
        }

        this.views = {
            refundInformation: 'refundInformation',
            memberInstallments: 'memberInstallments',
            confirmation: 'confirmation',
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.transaction.invoice_number !== this.props.transaction.invoice_number){
            if(this.props.transaction.invoice_number){
                this.setState(() => ({
                    transaction: this.props.transaction,
                }))
            }
        }
    }

    setIsRefunded = (_isRefunded) => {
        this.setState(() => ({
            isRefunded: _isRefunded,
        }))
    }

    setHasCancelledInstallments = (_hasCancelledInstallments) => {
        this.setState(() => ({
            hasCancelledInstallments: _hasCancelledInstallments,
        }))
    }

    handleOnRefund = () => {
        this.props.onRefund?.();
    }

    handleCancel = () => {
        this.props.onCancelRegistration?.();
        this.props.onCancelAttendee?.();
    }

    handleToggleRefresh = () => {
        //if(this.state.hasCancelledInstallments){
        //    this.props.onCancelInstallments?.();
        //} else {
        this.props.refreshTable?.();
        //}
        this.props.toggle?.();
    }

    handleToggle = () => {
        this.props.toggle?.();
    }

    /**
     * @private
     */
    _transactionHasInstallments = () => {
        return this.props.transaction.registrations.some((invoiceItem) => invoiceItem.payments?.length > 1 &&
            invoiceItem.payments?.some((payment) => payment.status === 'PENDING'))
    }

    displayView = () => {
        switch (this.state.activeStep){
            case this.views.refundInformation:
                return <RefundModalRefundInformation />
            case this.views.memberInstallments:
                return <RefundModalMemberInstallments />
            case this.views.confirmation:
                return <RefundModalConfirmation />
        }
    }

    goToView = (newView, goBack = false) => {
        switch (newView){
            case this.views.refundInformation:
                this.setState(() => ({ activeStep: newView }))
                break;
            case this.views.memberInstallments:
                if(this._transactionHasInstallments()){
                    this.setState(() => ({ activeStep: newView }))
                }else{
                    this.goToView(goBack ? this.views.refundInformation : this.views.confirmation, goBack)
                }
                break;
            case this.views.confirmation:
                //****** REFUND THEN CANCEL REGISTRATION LOGIC *******/
                // Check if this modal is in the registration tab
                // if it isn't, then it means that we may want to open the cancel registration modal.
                // If all registrations are already cancelled, then opening the Cancelled modal isn't needed
                if(
                    this.props.chainWithCancel &&
                    (this.props.mode === 'clinics' || this.props.transaction.registrations.some((r) => getConfirmCancelled(r) !== "CANCELLED")) &&
                    (this.props.cancelPermissions ?
                        this.props.RolesContext.canDoAction(
                            this.props.cancelPermissions.action,
                            this.props.cancelPermissions.componentCode,
                            this.props.cancelPermissions.componentPermissionCode,
                        ) || this.props.IdentityRolesContext.isGod()
                        :
                        true
                    )
                ){
                    this.setState(() => ({ activeStep: newView }))
                }else{
                    this.handleToggleRefresh();
                }
                break;
        }
    }

    render(){
        return (
            <RefundModalContext.Provider value={{ ...this }}>
                <AnalyticsModal isOpen={this.props.isOpen} analyticsName='OnlineStoreTransactionRefundModal'>
                    <ModalHeader toggle={this.handleToggle}>
                        {/* {this.props.onlyCancelInstallments ?
                            <Translate id='onlineStore.profile.transactions.modals.refundModal.form.cancelInstallments.title' />
                        : */}
                        <Translate id='onlineStore.profile.transactions.modals.refundModal.title' />
                        {/* } */}
                    </ModalHeader>

                    {this.displayView()}

                </AnalyticsModal>
            </RefundModalContext.Provider>
        )
    }
}

export default withContext(RolesContext, IdentityRolesContext)(RefundModalContextProvider);