import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import { InboxContext } from "../../inboxContext/InboxContext";

const MessagesActionsDropdown = ({ isLoading, handleOnArchive, handleOnFavorite, handleOnMarkAsRead, handleOnDownloadMsg, handleOnPrintMsg }) => {
    const { selectedMsg: msg } = useContext(InboxContext);

    return (
        <UncontrolledDropdown>
            <DropdownToggle size="sm" color="light" className="mdi mdi-dots-vertical rounded-lg" />
            <DropdownMenu right>
                {!msg.hideTools &&
                    <>
                        <DropdownItem onClick={handleOnArchive} disabled={isLoading}>
                            <Translate id={`communications.inbox.tools.${msg.archive == 1 ? "unarchive" : "archive"}`} />
                        </DropdownItem>
                        <DropdownItem onClick={handleOnFavorite} disabled={isLoading}>
                            <Translate id={`communications.inbox.tools.${msg.favorite == 1 ? "unfavorite" : "favorite"}`} />
                        </DropdownItem>
                        <DropdownItem onClick={handleOnMarkAsRead} disabled={isLoading}>
                            <Translate id={`communications.inbox.tools.mark${msg.is_read == 1 ? "Unread" : "Read"}`} />
                        </DropdownItem>
                    </>
                }
                <DropdownItem onClick={handleOnDownloadMsg} disabled={isLoading}>
                    <Translate id="misc.download" />
                </DropdownItem>
                <DropdownItem onClick={handleOnPrintMsg} disabled={isLoading}>
                    <Translate id="misc.print" />
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export default MessagesActionsDropdown;