import registrationFeesAdd from "./registrationFeesAdd";
import registrationFeesSidepanel from "./registrationFeesSidepanel";
import registrationFeesRollover from "./registrationFeesRollover";

export default{
    "catalog.registrationFees.title"            : "Frais d'inscription",
    "catalog.registrationFees.help.1"           : "Les frais d’inscription représentent le montant déboursé par le membre pour valider son inscription à une activité sportive fédérée (saison, camp de développement, stages) d’une durée spécifique (par exemple: un (1) an pour une saison de hockey complète ou encore quelques heures pour un stage). Ce montant N’INCLUT PAS les frais d’affiliation*",
    "catalog.registrationFees.help.2"           : "*Note aux registraires: les frais d’inscription doivent être ajoutés SANS le frais d’affiliation.",
    "catalog.registrationFees.help.3"           : "Le système vous offrira l’option d’ajouter les frais d’affiliation au montant total de l’inscription du membre si nécessaire.",
    "catalog.registrationFees.unlimited"        : "Illimitées",
    "catalog.registrationFees.active"           : "Actif",
    "catalog.registrationFees.inactive"         : "Inactif",
    "catalog.registrationFees.category"         : "Catégorie",
    "catalog.registrationFees.member_type"      : "Type de membre",
    "catalog.registrationFees.registration"     : "Inscription",
    "catalog.registrationFees.availablePlaces"  : "Places disponibles",
    "catalog.registrationFees.total_sold"       : "Total Vendu",
    "catalog.registrationFees.status"           : "Statut",
    "catalog.registrationFees.inactiveCategory" : "Cette catégorie est inactive.",
    "catalog.registrationFees.registrationFees" : "Frais d'inscription",

    ...registrationFeesAdd,
    ...registrationFeesSidepanel,
    ...registrationFeesRollover,
}