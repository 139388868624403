import API_SPORDLE from "../API-Spordle";
import queryString from "query-string";
import { serverError } from "../CancellableAPI";
import { jsObjectToApi } from "@spordle/helpers";


/**
 * Get list of checklists for a period
 * @param {object} queryParams
 * @returns {Promise}
 */
export function getChecklists(queryParams: {
    period_id: string,
    system_type?: "SEASON_ROLLOVER",
    active: "1"|"0"

}) {
    return API_SPORDLE.get(queryString.stringifyUrl({
        url: '/checklists',
        query: queryParams,
    }, {
        arrayFormat: 'comma',
        skipEmptyString: true,
        skipNull: true,
    }))
    .then((response) => {
        if(response.data.status){
            // Lite format
            const checklists = response.data.checklists || [];
            const checklist_steps = response.data.checklist_steps || {};
            const checklist_items = response.data.checklist_items || {};
            const components = response.data.components || {};
            const component_permissions = response.data.component_permissions || {};
            const periods = response.data.periods || {};

            // Build resources
            return checklists.map((checklist) => {
                checklist.checklist_steps = (checklist.checklist_steps || []).reduce((steps, step) => {
                    if(checklist_steps[step]) {
                        step = {
                            checklist_step_id: step,
                            ...checklist_steps[step]
                        };

                        if(checklist_items[step.checklist_item_id]) {
                            step.checklist_item = checklist_items[step.checklist_item_id];
                            step.checklist_item.checklist_item_id = step.checklist_item_id;
                        }

                        if(component_permissions[step.component_permission_id]) {
                            step.component_permission = component_permissions[step.component_permission_id];
                            step.component_permission.component_permission_id = step.component_permission_id;

                            if(components[step.component_permission.component_id]) {
                                step.component_permission.component = components[step.component_permission.component_id];
                                step.component_permission.component.component_id = step.component_permission.component_id;
                            }
                        }

                        if(periods[step.period_id]) {
                            step.period = periods[step.period_id];
                            step.period.period_id = step.period_id;
                        }

                        // Cleanup resource IDs
                        delete step.period_id;
                        delete step.checklist_id;
                        delete step.checklist_item_id;
                        delete step.component_permission_id;
                        delete step.component_permission?.component_id;

                        steps.push(step);
                    }

                    return steps;
                }, []);

                return checklist;
            })
        }
        throw response.data.errors;
    }, serverError)
}

/**
 * Get the logs of a checklist
 * @param {object} queryParams
 * @returns {Promise}
 */
export function getChecklistStepLogs(queryParams: {
    checklist_id: string,
    organisation_id: string,
    period_id: string
}) {
    return API_SPORDLE.get(queryString.stringifyUrl({
        url: '/checklist-step-logs',
        query: queryParams,
    }, {
        arrayFormat: 'comma',
        skipEmptyString: true,
        skipNull: true,
    }))
    .then((response) => {
        if(response.data.status){
            // Lite format
            const checklist_step_logs = response.data.checklist_step_logs || [];
            const checklists = response.data.checklists || {};
            const checklist_steps = response.data.checklist_steps || {};
            const checklist_items = response.data.checklist_items || {};
            const identities = response.data.identities || {};
            const periods = response.data.periods || {};

            // Build resources
            return checklist_step_logs.map((stepLog) => {
                if(checklist_steps[stepLog.checklist_step_id]) {
                    stepLog.checklist_step = checklist_steps[stepLog.checklist_step_id];
                    stepLog.checklist_step.checklist_step_id = stepLog.checklist_step_id;

                    if(checklists[stepLog.checklist_step.checklist_id]) {
                        stepLog.checklist_step.checklist = checklists[stepLog.checklist_step.checklist_id];
                        stepLog.checklist_step.checklist.checklist_id = stepLog.checklist_step.checklist_id;
                    }

                    if(checklist_items[stepLog.checklist_step.checklist_item_id]) {
                        stepLog.checklist_step.checklist_item = checklist_items[stepLog.checklist_step.checklist_item_id];
                        stepLog.checklist_step.checklist_item.checklist_item_id = stepLog.checklist_step.checklist_item_id;
                    }
                }

                if(identities[stepLog.identity_id]) {
                    stepLog.identity = identities[stepLog.identity_id];
                    stepLog.identity.identity_id = stepLog.identity_id;
                }

                if(periods[stepLog.period_id]) {
                    stepLog.period = periods[stepLog.period_id];
                    stepLog.period.period_id = stepLog.period_id;
                }

                // Cleanup resource IDs
                delete stepLog.checklist_step_id;
                delete stepLog.checklist_step?.checklist_id;
                delete stepLog.checklist_step?.checklist_item_id;
                delete stepLog.identity_id;
                delete stepLog.period_id;

                return stepLog;
            })
        }
        throw response.data.errors;
    }, serverError)
}

export function createStepLog(
    checklistStepId: string,
    values: {
        organisation_id: string
    }
) {
    const apiValues = new URLSearchParams();
    jsObjectToApi(values, apiValues);

    return API_SPORDLE.post(
        queryString.stringifyUrl({
            url: `checklist-steps/${checklistStepId}/checklist-step-logs`
        }),
        apiValues
    )
        .then((response) => {
            if(response.data.status){
                return response.data.checklist_step_log_id;
            }
            throw response.data.errors;
        }, serverError)
}