export default{
    "onlineStore.components.onlineStoreGeneralInformation.optional.male"   : "Optionnel",
    "onlineStore.components.onlineStoreGeneralInformation.optional.female" : "Optionnelle",

    "onlineStore.components.onlineStoreGeneralInformation.section.generalInfo"        : "Informations générales",
    "onlineStore.components.onlineStoreGeneralInformation.section.registrationPeriod" : "Période d'inscription",
    "onlineStore.components.onlineStoreGeneralInformation.section.message"            : "Message",

    "onlineStore.components.onlineStoreGeneralInformation.field.period"                : "Saison",
    "onlineStore.components.onlineStoreGeneralInformation.field.period.season"         : "saison",
    "onlineStore.components.onlineStoreGeneralInformation.field.period.text"           : "Assurez-vous de créer votre boutique en ligne dans la bonne {season}!",
    "onlineStore.components.onlineStoreGeneralInformation.field.languages"             : "Langues",
    "onlineStore.components.onlineStoreGeneralInformation.field.languages.placeholder" : "Choisir une langue",
    "onlineStore.components.onlineStoreGeneralInformation.field.organisation"          : "Organisation",
    "onlineStore.components.onlineStoreGeneralInformation.field.formName"              : "Nom de l'inscription en ligne",
    "onlineStore.components.onlineStoreGeneralInformation.field.registrarEmail"        : "Adresse courriel du registraire",
    "onlineStore.components.onlineStoreGeneralInformation.field.registrarEmail.sendTo" : "Envoyer un courriel à chaque inscription",
    "onlineStore.components.onlineStoreGeneralInformation.field.startDate"             : "Date de début",
    "onlineStore.components.onlineStoreGeneralInformation.field.startTime"             : "Heure de début",
    "onlineStore.components.onlineStoreGeneralInformation.field.endDate"               : "Date de fin",
    "onlineStore.components.onlineStoreGeneralInformation.field.endTime"               : "Heure de fin",
    "onlineStore.components.onlineStoreGeneralInformation.field.confirmMessage"        : "Message de confirmation",
    "onlineStore.components.onlineStoreGeneralInformation.field.bypassPostalCode"      : "Vérification des codes postaux",

    "onlineStore.components.onlineStoreGeneralInformation.validation.bypassPostalCode"       : "Veuillez spécifier si les codes postaux de l'organisation doivent être pris en compte pour les inscriptions.",
    "onlineStore.components.onlineStoreGeneralInformation.validation.organisation.required"  : "Veuillez choisir une organisation",
    "onlineStore.components.onlineStoreGeneralInformation.validation.registrar.required"     : "Vous devez saisir l'adresse e-mail d'un registraire",
    "onlineStore.components.onlineStoreGeneralInformation.validation.registrar.emailFormat"  : "Doit être un format d'adresse e-mail",
    "onlineStore.components.onlineStoreGeneralInformation.validation.language.min"           : "Vous devez sélectionner au moins une langue",
    "onlineStore.components.onlineStoreGeneralInformation.validation.formName.required"      : "Le nom du questionnaire est requis",
    "onlineStore.components.onlineStoreGeneralInformation.validation.date.required"          : "Date requise",
    "onlineStore.components.onlineStoreGeneralInformation.validation.date.invalidFormat"     : "Date invalide",
    "onlineStore.components.onlineStoreGeneralInformation.validation.time.required"          : "Heure requise",
    "onlineStore.components.onlineStoreGeneralInformation.validation.time.invalidFormat"     : "Heure invalide",
    "onlineStore.components.onlineStoreGeneralInformation.validation.endDate.afterStartDate" : "La date de fin doit être après la date de début",

    "onlineStore.components.onlineStoreGeneralInformation.hint.pickLanguage" : "Choisissez une langue",
}