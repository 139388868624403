const SpordleLogo = (props) => {
    return (
        <svg
            id="SpordleLogo"
            data-name="Spordle Logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 124"
            {...props}
        >
            <path
                id="Tracé_821"
                data-name="Tracé 821"
                className="fill-current"
                d="M198.65,31.4a11.77,11.77,0,0,0-10.26-4.28H164.67a19.9,19.9,0,0,0-19.33,14.53l-11.15,37a12.76,12.76,0,0,0,1,11.25,11.73,11.73,0,0,0,10.26,4.28h23.73a19.89,19.89,0,0,0,19.32-14.53l11.16-37A12.76,12.76,0,0,0,198.65,31.4ZM181.2,46.14l-8.47,28.09c-.91,3-2.15,3.58-4.12,3.58H153.74c-1,0-1.43-.22-1.49-.3s-.29-.44.26-2.27L161,47.14c.89-3,2.34-3.58,4.24-3.58h14.87c1.14,0,1.42.26,1.42.26s.24.43-.32,2.32Z"
            />
            <path
                id="Tracé_822"
                data-name="Tracé 822"
                className="fill-current"
                d="M327,3.82,300.71,91.17a2.31,2.31,0,0,0,1.54,2.88,2.18,2.18,0,0,0,.67.1H316a2.31,2.31,0,0,0,2.21-1.64L344.87,4.15a2.31,2.31,0,0,0-1.54-2.88,2.18,2.18,0,0,0-.84-.1l-13.41,1A2.32,2.32,0,0,0,327,3.82Z"
            />
            <path
                id="Tracé_823"
                data-name="Tracé 823"
                className="fill-current"
                d="M135.16,31.47a11.86,11.86,0,0,0-10.36-4.36l-19,.08a2.36,2.36,0,0,0-1.46.53l-2.38,2a2.31,2.31,0,0,1-3.2-.27L97.4,27.84a2.34,2.34,0,0,0-1.75-.79l-7.92.05a2.3,2.3,0,0,0-2.2,1.64L58.05,119.85a2.32,2.32,0,0,0,1.55,2.88,2.21,2.21,0,0,0,.88.09L74,121.56a2.29,2.29,0,0,0,2-1.63l7.77-25.8h22A20,20,0,0,0,125.07,79.6l11.15-37A12.45,12.45,0,0,0,135.16,31.47ZM117.85,46.13l-8.47,28.1c-.9,3-2.14,3.58-4.11,3.58H90.4c-1.05,0-1.43-.22-1.49-.3s-.3-.45.25-2.28l8.47-28.09c.9-3,2.34-3.58,4.24-3.58h14.87c1.14,0,1.42.25,1.42.25s.26.43-.31,2.32Z"
            />
            <path
                id="Tracé_824"
                data-name="Tracé 824"
                className="fill-current"
                d="M231.35,27.72l-2.12,1.86a2.32,2.32,0,0,1-3.27-.2,1.34,1.34,0,0,1-.11-.14L224.93,28a2.29,2.29,0,0,0-1.85-.91H215.4a2.32,2.32,0,0,0-2.21,1.65l-18.81,62.4a2.31,2.31,0,0,0,1.55,2.88,2.44,2.44,0,0,0,.66.09h13a2.3,2.3,0,0,0,2.21-1.64l13.45-44.61c.78-2.58,1.81-3.45,4.08-3.45h11.32a2.31,2.31,0,0,0,2.11-1.38l5.6-12.69a2.32,2.32,0,0,0-2.11-3.25l-13.38,0A2.3,2.3,0,0,0,231.35,27.72Z"
            />
            <path
                id="Tracé_825"
                data-name="Tracé 825"
                className="fill-current"
                d="M300.08,3.82l-6.54,21.71a2.32,2.32,0,0,1-2.22,1.64l-20.4,0a20,20,0,0,0-19.45,14.53l-11.16,37a12.72,12.72,0,0,0,1,11.23,11.84,11.84,0,0,0,10.38,4.3l19-.08a2.3,2.3,0,0,0,1.52-.58l2.37-2.08a2.3,2.3,0,0,1,3.24.17l1.72,1.89a2.3,2.3,0,0,0,1.72.75l7.34-.06a2.29,2.29,0,0,0,2.19-1.64L317.43,4.15a2.32,2.32,0,0,0-1.55-2.88,2.42,2.42,0,0,0-.85-.09l-12.91,1A2.32,2.32,0,0,0,300.08,3.82ZM287.32,46.15l-8.47,28.09c-.91,3-2.15,3.58-4.12,3.58H259.86c-1,0-1.42-.22-1.48-.3s-.3-.44.25-2.27l8.47-28.1c.9-3,2.35-3.58,4.24-3.58h14.87c1.15,0,1.42.25,1.42.26s.29.43-.3,2.32Z"
            />
            <path
                id="Tracé_826"
                data-name="Tracé 826"
                className="fill-current"
                d="M352.17,69.81h23a18.64,18.64,0,0,0,18.64-14.28l3.88-12.86a12.49,12.49,0,0,0-1.06-11.18,11.85,11.85,0,0,0-10.36-4.35H362.7a19.92,19.92,0,0,0-19.33,14.53l-11.15,37a12.6,12.6,0,0,0,1,11.25,12,12,0,0,0,10.45,4.29h33.94a2.31,2.31,0,0,0,2.17-1.52L384,81a2.31,2.31,0,0,0-1.38-3,2.35,2.35,0,0,0-.79-.14h-30c-1,0-1.46-.22-1.55-.34s-.23-.84.19-2.23Zm27.16-24-1.65,5.5a3.09,3.09,0,0,1-3.16,2.46H360.14a2.32,2.32,0,0,1-2.32-2.32,2.45,2.45,0,0,1,.1-.66l1.27-4.22a3.78,3.78,0,0,1,3.93-2.95H378a1.49,1.49,0,0,1,1.45,1.52,1.5,1.5,0,0,1-.05.37C379.39,45.57,379.37,45.68,379.33,45.78Z"
            />
            <path
                id="Tracé_827"
                data-name="Tracé 827"
                className="fill-current"
                d="M75,30.16,71.16,42.05A2.32,2.32,0,0,1,69,43.66l-32.47,0a3.26,3.26,0,0,0-1.88.71A7.44,7.44,0,0,0,33,47.26a26,26,0,0,0-1.17,4.05.85.85,0,0,0,.36.8,5,5,0,0,0,2,.3c.67,0,1.6,0,2.74,0H54.31A13.38,13.38,0,0,1,60,53.54a8.64,8.64,0,0,1,3.73,3.12,9.8,9.8,0,0,1,1.51,5,28.14,28.14,0,0,1-.43,4.39l-4.1,13.6A19.91,19.91,0,0,1,42,94.17H3.73a2.31,2.31,0,0,1-2.3-2.31,2.46,2.46,0,0,1,.14-.81L5.88,79.47A2.29,2.29,0,0,1,8,78l33.9,0c1.52-.06,2.33-1.29,3-3.41a11.29,11.29,0,0,0,.84-4.06c-.11-.53-.67-.79-1.65-.79H25.27a18.69,18.69,0,0,1-6.34-1,10.75,10.75,0,0,1-4.43-2.82,8.17,8.17,0,0,1-.85-1.09,8.59,8.59,0,0,1-1.28-3.38,9.56,9.56,0,0,1,0-2.39A130.83,130.83,0,0,1,17.5,41.68,19.91,19.91,0,0,1,33.05,27.51a55.64,55.64,0,0,1,6.32-.34l33.42,0a2.3,2.3,0,0,1,2.3,2.32A2.38,2.38,0,0,1,75,30.16Z"
            />
        </svg>
    );
}

export default SpordleLogo;