import { useSpordleTable } from "@spordle/datatables";
import { Form, Formik } from "formik";
import { useContext, useMemo } from "react";
import useSWR from "swr";
import { mixed, object } from "yup";
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import CrossFade from "../../../../../components/crossFade/CrossFade";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { UtilsContext } from "../../../../../contexts/UtilsContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import PostalCodesSelection from "./PostalCodesSelection";
import PostalCodesSuccess from "./PostalCodesSuccess";

const EditPostalCodesModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='EditPostalCodesModal'>
            <EditPostalCodesModalInner {...props} />
        </AnalyticsModal>
    )
};

const EditPostalCodesModalInner = ({ toggle, postalCode, canEdit }) => {
    const spordleTable = useSpordleTable();
    const { getPostalCodesFor } = useContext(UtilsContext);
    const { updateOrganisationPostalCode, organisation_id, postal_codes, setCurrentOrg } = useContext(OrganizationContext);

    const getFormattedPostalCodes = (postalCodes) => {
        /**
         * @example
         * const postalCodeData = {
         *      codes = {
         *          'laval': [
         *               {
         *                       postal_code: 'H7L 2L2',
         *                       city_name: 'Laval',
         *                       province_abbreviation: 'QC',
         *                       province_name: 'Quebec',
         *                       latitude: '45.60',
         *                       longitude: '-73.68',
         *               }
         *          ]
         *      },
         *      selectedCodes = {
         *          'laval': new Set('H7J 0A1', 'H7J 0A2')
         *      },
         *      total: 2
         * }
                 */
        const postalCodeData = postalCodes.reduce((formattedData, postalCode) => {
            // If search with postal code prefix, we separate cards per city.
            // If we search per city, cards will be separated with the prefix of the postal codes.
            const splitPrefix = postalCode.city_name;
            const splitCodes = formattedData.codes;
            const code = postalCode.postal_code;

            if(!splitCodes[splitPrefix]){
                splitCodes[splitPrefix] = [];
            }

            if(postal_codes.indexOf(code) !== -1){ // indexOf is faster then includes
                if(!formattedData.selectedCodes[splitPrefix]){
                    formattedData.selectedCodes[splitPrefix] = new Set();
                }

                formattedData.selectedCodes[splitPrefix].add(code);
            }

            // In case some codes returned from the API are duplicated. (happens on INT)
            // Looping should be fine, since this does not happen on every render.
            if(!splitCodes[splitPrefix].some(({ postal_code }) => postal_code === code)){
                splitCodes[splitPrefix].push(postalCode);
            }

            return formattedData;
        }, {
            // In case some codes returned from the API are duplicated. (happens on INT)
            // Looping should be fine, since this does not happen on every render.
            total: new Set(postalCodes.map(({ postal_code, city_name }) => postal_code + city_name)).size,
            codes: {},
            selectedCodes: {},
        });

        return postalCodeData;
    }

    const { data: availablePostalCodes, isValidating } = useSWR(
        [ 'editPostalCodesOrgModal', 'getPostalCodesFor', postalCode ],
        () => postalCode ? getPostalCodesFor({ partial_postal_code: postalCode }) : null,
    );

    const postalCodesList = useMemo(() => availablePostalCodes ? getFormattedPostalCodes(availablePostalCodes) : null, [ !!availablePostalCodes, postal_codes.join('') ]);

    return (
        <Formik
            enableReinitialize
            initialValues={{
                step: 1,
                postalCodes: postalCodesList?.selectedCodes || {},
                partialPostalCode: postalCode,
            }}
            validationSchema={object().shape({
                postalCodes: mixed(),
            })}
            onSubmit={async({ step, ...values }, { setFieldValue, setStatus }) => {
                setStatus();

                const allCodes = [ ...postal_codes ] // Making a shallow copy to prevent editing the actual current array
                    .filter((pCode) => !pCode.toLowerCase().startsWith(postalCode.toLowerCase())); // Remove all postal codes that start with the partial_postal_code

                const postalCodes = Object.values(values.postalCodes).reduce((apiValues, codesSet) => {
                    codesSet.forEach((postalCode) => {
                        apiValues.add(postalCode);
                    });

                    return apiValues;
                }, new Set(allCodes));

                return updateOrganisationPostalCode(organisation_id, Array.from(postalCodes))
                    .then(async() => {

                        // Update the table in the background while we show the success step
                        await setCurrentOrg(organisation_id)
                            .then(spordleTable.refreshTable)
                            .catch(console.error);

                        return setFieldValue('step', step + 1);
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                        }
                    })

            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting || isValidating}>
                        <CrossFade isVisible={formik.values.step == 1}>
                            <PostalCodesSelection
                                isEdit
                                toggle={toggle}
                                canEdit={canEdit}
                                postalCodesData={postalCodesList}
                            />
                        </CrossFade>
                        <CrossFade isVisible={formik.values.step == 2}>
                            <PostalCodesSuccess isEdit toggle={toggle} />
                        </CrossFade>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default EditPostalCodesModal;
