import { useSpordleTable } from "@spordle/datatables";
import SpordleSelect from "@spordle/spordle-select";
import { useContext } from "react";
import { Card, Col, Row } from "reactstrap";
import SavedSearchCue from "../../../../components/savedSearchCue/SavedSearchCue";
import { I18nContext } from "../../../../contexts/I18nContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { displayI18n, DisplayI18n } from "../../../../helpers/i18nHelper";

const DiscriminationSearch = ({ savedSearch, clearSavedSearch }) => {
    const { getFilters, filterChange } = useSpordleTable();
    const { getDiscriminationStatus, getDiscriminationOutcomes, getMaltreatmentTypes } = useContext(OrganizationContext);
    const { getGenericLocale } = useContext(I18nContext);

    return (
        <Card body className="card-shadow mb-3">
            <Row form>
                <Col sm={4}>
                    <SpordleSelect
                        name="status"
                        id="status"
                        className="mb-3 mb-sm-0"
                        clearable
                        values={[ getFilters().status ]}
                        renderOption={({ option }) => (
                            <DisplayI18n
                                field="name"
                                i18n={option.ressource.i18n}
                                defaultValue={option.label}
                            />
                        )}
                        onOptionSelected={([ value ]) => {
                            filterChange('status', value);
                        }}
                        loadData={(from) => {
                            switch (from){
                                case 'CDM':
                                    return getDiscriminationStatus({ active: 1 })
                                        .then((status) => status
                                            .sort((a, b) => {
                                                const aName = displayI18n("name", a.i18n, a.name, getGenericLocale()) || "";
                                                const bName = displayI18n("name", b.i18n, b.name, getGenericLocale()) || "";

                                                return aName.localeCompare(bName);
                                            })
                                            .map((s) => ({
                                                ressource: s,
                                                label: s.name,
                                                value: s.discrimination_status_id,
                                            })))

                                default:
                                    break;
                            }
                        }}
                        search={false}
                        placeholder='discrimination.label.status'
                    />

                </Col>
                <Col sm={4}>
                    <SpordleSelect
                        name="outcomes"
                        id="outcomes"
                        className="mb-3 mb-sm-0"
                        clearable
                        search={false}
                        values={[ getFilters().outcomes ]}
                        placeholder='discrimination.label.outcomes'
                        renderSelectedOption={(option) => (
                            <DisplayI18n
                                field="name"
                                i18n={option.ressource.i18n}
                                defaultValue={option.label}
                            />
                        )}
                        onOptionSelected={([ value ]) => {
                            filterChange('outcomes', value);
                        }}
                        renderOption={({ option, isSelected }) => (
                            <>
                                <DisplayI18n
                                    field="name"
                                    i18n={option.ressource.i18n}
                                    defaultValue={option.label}
                                />
                                <small className={`d-block text-${isSelected ? 'light' : 'muted'}`}>
                                    <DisplayI18n
                                        field="description"
                                        i18n={option.ressource.i18n}
                                        defaultValue={option.ressource.description}
                                    />
                                </small>
                            </>
                        )}
                        loadData={(from) => {
                            switch (from){
                                case 'CDM':
                                    return getDiscriminationOutcomes({ active: 1 })
                                        .then((outcomes) => outcomes
                                            .sort((a, b) => {
                                                const aName = displayI18n("name", a.i18n, a.name, getGenericLocale()) || "";
                                                const bName = displayI18n("name", b.i18n, b.name, getGenericLocale()) || "";
                                                return aName.localeCompare(bName);
                                            })
                                            .map((out) => ({ ressource: out, label: out.name, value: out.outcome_id })))
                                default:
                                    break;

                            }
                        }}
                    />
                </Col>
                <Col sm={4}>
                    <SpordleSelect
                        name="type"
                        id="type"
                        search={false}
                        clearable
                        values={[ getFilters().type ]}
                        placeholder='discrimination.global.maltreatmentType'
                        renderSelectedOption={(option) => (
                            <DisplayI18n
                                field="name"
                                i18n={option.ressource.i18n}
                                defaultValue={option.label}
                            />
                        )}
                        onOptionSelected={([ value ]) => {
                            filterChange('type', value);
                        }}
                        renderOption={({ option, isSelected }) => (
                            <>
                                <DisplayI18n
                                    field="name"
                                    i18n={option.ressource.i18n}
                                    defaultValue={option.label}
                                />
                                <small className={`d-block text-${isSelected ? 'light' : 'muted'}`}>
                                    <DisplayI18n
                                        field="description"
                                        i18n={option.ressource.i18n}
                                        defaultValue={option.ressource.description}
                                    />
                                </small>
                            </>
                        )}
                        loadData={(from) => {
                            switch (from){
                                case 'CDM':
                                    return getMaltreatmentTypes({ active: 1 })
                                        .then((types) => types
                                            .sort((a, b) => {
                                                const aName = displayI18n("name", a.i18n, a.name, getGenericLocale()) || "";
                                                const bName = displayI18n("name", b.i18n, b.name, getGenericLocale()) || "";
                                                return aName.localeCompare(bName);
                                            })
                                            .map((type) => ({ ressource: type, label: type.name, value: type.maltreatment_type_id })))
                                default:
                                    break;

                            }
                        }}
                    />
                </Col>
            </Row>
            <SavedSearchCue
                className="mt-2"
                clearSavedSearch={clearSavedSearch}
                savedSearch={savedSearch}
            />
        </Card>
    );
}

export default DiscriminationSearch;