import { useSpordleTable } from "@spordle/datatables";
import { formatSelectData } from "@spordle/spordle-select";
import { DisplayPhoneNumber, FormikCheckedButton, FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Alert, Button, Col, Collapse, FormGroup, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import useSWR from "swr";
import { object, string } from "yup";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { separateAndFormatDocs } from "../../../../../../components/uploader/uploadHelpers";
import { PeriodsContext } from "../../../../../../contexts/contexts";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { InjuriesContext } from "../../../../../../contexts/InjuriesContext";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { RolesContext } from "../../../../../../contexts/RolesContext";
import { TeamsContext } from "../../../../../../contexts/TeamsContext";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { DisplayCategory, getLocation } from "../../../../../teams/TeamHelpers";
import { RegistrationDivisionsContext } from "../../../../../../contexts/RegistrationDivisionsContext";
import Required from "../../../../../../components/formik/Required";
import { fail } from "@spordle/toasts";
import { IdentityRolesContext } from "../../../../../../contexts/IdentityRolesContext";
import { NO_TEAM, sortRessource } from "../../InsuranceHelper";
import { RegistrationClassesContext } from "../../../../../../contexts/RegistrationClassesContext";
import { Tooltip } from "@mantine/core";

const StepTeam = ({ toggle, data, setIsLoading, previous, isLoading }) => {
    const { filterChange } = useSpordleTable();
    const { federation } = useContext(IdentityRolesContext);
    const { getMemberTeams } = useContext(MembersContext);
    const { selectedPeriod } = useContext(PeriodsContext);
    const { getRegistrationDivisions: getDivisions } = useContext(RegistrationDivisionsContext);
    const { getRegistrationClasses: getClasses } = useContext(RegistrationClassesContext);
    const i18nContext = useContext(I18nContext);
    const { isGod } = useContext(IdentityRolesContext);
    const { canDoAction } = useContext(RolesContext);

    const { createInjury, createInjuryAttachments } = useContext(InjuriesContext);
    const { data: teams } = useSWR(
        [ 'getMemberTeams', data.member?.member_id, data.accident.organisation_id ],
        () => !!data.member?.member_id && !!data.accident.organisation_id
            ?
            getMemberTeams(data.member?.member_id, { period_id: selectedPeriod.period_id })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                    return [];
                })
            :
            null,
        {
            onError: (e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                }
            },
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
            fallbackData: [],
        },

    );

    const handleOnSubmit = (values, { setStatus }) => {
        setIsLoading(true);
        const apiVal = { ...data.accident, ...data.injury, ...data.insurance };

        delete apiVal.injury_equipment_id;

        if(values.team_id){
            if(values.team_id === NO_TEAM){
                apiVal.team_name = values.team_name;
                apiVal.division_id = values.division_id;
                apiVal.class_id = values.class_id;
            }else{
                const chosenTeam = teams.find((t) => t.team.team_id === values.team_id);
                apiVal.team_id = values.team_id;
                apiVal.team_category_id = chosenTeam.team?.team_category?.team_category_id;
                apiVal.division_id = chosenTeam.team?.team_category?.division?.division_id;
                apiVal.class_id = chosenTeam.team?.team_category?.class?.class_id;
            }
        }

        if(apiVal.delivered_date){
            apiVal.delivered_date = moment(apiVal.delivered_date).toISOString();
        }

        if(data.injury.injury_equipment_id?.length > 0){
            for(let i = 0; i < data.injury.injury_equipment_id.length; i++){
                apiVal[`injury_equipment_id[${i}]`] = data.injury.injury_equipment_id[i];
            }
        }

        apiVal.member_id = data.member?.member_id;
        apiVal.period_id = selectedPeriod.period_id;
        apiVal.accident_date = moment(data.accident.accident_date).toISOString();
        apiVal.team_official_name = values.team_official_name || "";
        apiVal.team_official_position = values.team_official_position || "";
        apiVal.approved = values.approved ? '1' : '0';

        if(canDoAction('ADD', 'members', 'member_insurance_sensitive_data') || isGod()){
            apiVal.reserve = Math.round((data.accident.reserve || 0) * 100);
        }else{
            delete apiVal.reserve;
        }

        let formattedAttachments = null;
        if(data.attachments?.length > 0){
            formattedAttachments = separateAndFormatDocs(data.attachments ?? [], "file");
        }

        createInjury(apiVal)
            .then(async(injuryId) => {
                if(Array.isArray(formattedAttachments) && formattedAttachments.length > 0){
                    await Promise.all(
                        formattedAttachments.map((subArr) => {
                            return createInjuryAttachments(
                                injuryId,
                                subArr,
                                { ignore_injury_status: 1 },
                            )
                        }),
                    )
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        });
                }
                toggle();
                filterChange('unique_identifier', data.member?.unique_identifier);
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                    setIsLoading(false);
                    setStatus(<DisplayI18n defaultValue={e.message} i18n={e.i18n} field="message" />);
                }
            });
    }

    return (
        <Formik
            initialValues={{
                team_id: "",
                team_name: "",
                team_official_name: "",
                team_official_position: "",
                // team_category_id: "",
                division_id: "",
                class_id: "",
                official: {},
                approved: false,
            }}
            validationSchema={object().shape({
                team_id: string(),
                team_official_name: string(),
                team_official_position: string(),
                team_name: string().when("team_id", {
                    is: NO_TEAM,
                    then: (schema) => schema.required(<Translate id='insurance.validation.noTeam.teamName' />),
                }),
                division_id: string().when("team_id", {
                    is: NO_TEAM,
                    then: (schema) => schema.required(<Translate id='insurance.validation.noTeam.teamDivision' />),
                }),
                class_id: string().when("team_id", {
                    is: NO_TEAM,
                    then: (schema) => schema.required(<Translate id='insurance.validation.noTeam.teamClass' />),
                }),
                // team_category_id: string(),
                official: object().shape({
                    member_id: string(),
                }),
            })}
            onSubmit={handleOnSubmit}
        >
            {(formik) => (
                <>
                    <Form>
                        <ModalBody>
                            <FormGroup className="pb-2">
                                <div className="font-bold h4">
                                    <Translate id='insurance.label.teamInfo' />
                                </div>
                                <div className="text-muted mb-2">
                                    <Translate id='insurance.label.team' />
                                </div>
                                <FormikSelect
                                    clearable
                                    search
                                    name="team_id"
                                    id="team_id"
                                    searchKeys={[
                                        `i18n.${i18nContext.getGenericLocale()}.name`,
                                        `i18n.${i18nContext.getGenericLocale()}.short_name`,
                                        `category.division.i18n.${i18nContext.getGenericLocale()}.name`,
                                        `division.i18n.${i18nContext.getGenericLocale()}.name`,
                                        `class.i18n.${i18nContext.getGenericLocale()}.name`,
                                        'category.gender',
                                    ]}
                                    renderOption={(team) => (
                                        (team.option.isGroup || team.option.value === NO_TEAM) ?
                                            <Translate id={team.option.label} />
                                            :
                                            <div>
                                                <div>{team.option.label}</div>
                                                <div className='text-muted small'>
                                                    {team.option.division ? team.option.division : <DisplayCategory category={{ ...team?.option.category }} short /> }
                                                    {team.option.category?.gender && <> - <Translate id={`form.fields.gender.${team.option.category.gender.toLowerCase()}`} /></>}
                                                </div>
                                            </div>
                                    )}
                                    options={teams ?
                                        formatSelectData(teams.reduce((shownTeams, team) => {
                                            shownTeams.push({
                                                label: team.team.name,
                                                i18n: team.team.i18n,
                                                value: team.team.team_id,
                                                category: team.team.team_category,
                                                division: team.team.division,
                                            });
                                            return shownTeams;
                                        }, [
                                            {
                                                value: 'NONE',
                                                label: 'insurance.label.teamNonListed',
                                            },
                                        ]), {
                                            getGroupId: (team) => team.value === NO_TEAM ? "NOT_LISTED" : undefined,
                                            newGroupIndexes: {
                                                0: {
                                                    label: 'insurance.label.teamNonListed',
                                                    groupId: 'NOT_LISTED',
                                                },
                                            },
                                        })
                                        :
                                        []
                                    }
                                    isLoading={!teams}
                                />
                            </FormGroup>
                            <Collapse isOpen={formik.values.team_id === NO_TEAM}>
                                <div className="font-medium h5 mb-1"><Translate id='insurance.label.teamNonListed' /></div>
                                <Row form>
                                    <Col className="mb-3" sm="6">
                                        <Label className="text-muted"><Translate id='insurance.label.teamName' /> <Required /></Label>
                                        <FormikInputText
                                            id="team_name"
                                            name="team_name"
                                        />
                                    </Col>
                                    <Col className="mb-3" sm="6">
                                        <Label className="text-muted"><Translate id='insurance.label.teamDivision' /> <Required /></Label>
                                        <FormikSelect
                                            id="division_id"
                                            name="division_id"
                                            renderSelectedOption={(option) => (
                                                <DisplayI18n
                                                    field="short_name"
                                                    defaultValue={option.name}
                                                    i18n={option.i18n}
                                                />
                                            )}
                                            searchKeys={[
                                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                                `i18n.${i18nContext.getGenericLocale()}.short_name`,
                                            ]}
                                            renderOption={({ option }) => (
                                                <>
                                                    <DisplayI18n
                                                        field="short_name"
                                                        defaultValue={option.short_name}
                                                        i18n={option.i18n}
                                                    />
                                                    <div className="small text-muted">
                                                        <DisplayI18n
                                                            field="name"
                                                            defaultValue={option.name}
                                                            i18n={option.i18n}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return getDivisions(federation.organisation_id, { active: 1, period_id: selectedPeriod.period_id })
                                                            .then((divisions) => {
                                                                return sortRessource(divisions)
                                                                    .map((theDivision) => ({
                                                                        ...theDivision,
                                                                        value: theDivision.division_id,
                                                                        label: theDivision.name,
                                                                    }))
                                                            })
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col className="mb-3" sm="6">
                                        <Label className="text-muted">
                                            <Translate id='insurance.label.teamClass' />
                                        </Label>
                                        <FormikSelect
                                            id="class_id"
                                            name="class_id"
                                            renderSelectedOption={(option) => (
                                                <DisplayI18n
                                                    field="short_name"
                                                    defaultValue={option.name}
                                                    i18n={option.i18n}
                                                />
                                            )}
                                            searchKeys={[
                                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                                `i18n.${i18nContext.getGenericLocale()}.short_name`,
                                            ]}
                                            renderOption={({ option }) => (
                                                <>
                                                    <DisplayI18n
                                                        field="short_name"
                                                        defaultValue={option.short_name}
                                                        i18n={option.i18n}
                                                    />
                                                    <div className="small text-muted">
                                                        <DisplayI18n
                                                            field="name"
                                                            defaultValue={option.name}
                                                            i18n={option.i18n}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return getClasses(
                                                            federation.organisation_id,
                                                            { active: 1, period_id: selectedPeriod.period_id },
                                                        )
                                                            .then((allClasses) => {
                                                                return sortRessource(allClasses)
                                                                    .map((theClass) => ({
                                                                        ...theClass,
                                                                        value: theClass.class_id,
                                                                        label: theClass.name,
                                                                    }))
                                                            })
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Collapse>
                            <FormGroup className="pt-2">
                                <div className="font-medium h5 mb-1">
                                    <Translate id='insurance.label.teamOfficial' />
                                </div>
                                <Row form>
                                    <Col md="6">
                                        <Label className="text-muted"><Translate id='insurance.label.teamOfficial.name' /></Label>
                                        <FormikInputText id="team_official_name" name="team_official_name" />
                                    </Col>
                                    <Col md="6">
                                        <Label className="text-muted"><Translate id='insurance.label.teamOfficial.position' /></Label>
                                        <FormikInputText id="team_official_position" name="team_official_position" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            {!!formik.values.team_id && <TeamInfo teams={teams || []} teamId={formik.values.team_id} />}
                            <FormGroup className="pt-2">
                                <div className="font-medium h5 mb-1">
                                    <Translate id='insurance.modal.approved.title' />
                                </div>
                                <Row form>
                                    <FormGroup className="d-flex ml-1">
                                        <FormikCheckedButton
                                            name='approved'
                                            id='insurance-approved-modal'
                                            label={'insurance.search.accidentDate.approved.label'}
                                            translateLabel
                                            className={`w-100`}
                                        />
                                        <Tooltip zIndex={3000} withArrow label={<Translate id='insurance.modal.approved.tooltip' />}>
                                            <i className="ml-1 mdi mdi-information-outline text-primary" />
                                        </Tooltip>
                                    </FormGroup>
                                </Row>
                            </FormGroup>
                            {formik.status &&
                                <Collapse isOpen appear>
                                    <Alert className="mt-3" color="danger">
                                        {formik.status}
                                    </Alert>
                                </Collapse>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button disabled={isLoading} color="primary" type="button" outline className="mr-auto" onClick={previous}><Translate id="misc.previous" /></Button>
                            <Button disabled={isLoading} color="primary" type="submit"><Translate id="misc.submit" /></Button>
                        </ModalFooter>
                    </Form>
                </>
            )}
        </Formik>
    )
};

const TeamInfo = ({ teams, teamId }) => {
    const { getOrganization } = useContext(OrganizationContext);
    const { getTeamContacts } = useContext(TeamsContext);
    const chosenTeam = teams.find((t) => t.team.team_id === teamId);
    const [ orgInfo, setOrgInfo ] = useState(null);
    const [ teamContact, setTeamContact ] = useState(null);

    useEffect(() => {
        if(teamId !== "NONE"){
            const orgId = chosenTeam?.team?.organisation?.organisation_id;
            if(orgId){
                if(orgId !== orgInfo?.organisation_id){
                    setOrgInfo(null);
                    getOrganization(orgId)
                        .then(setOrgInfo)
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })
                }
            }
            if(teamContact?.team_id !== teamId){
                setTeamContact(null);
                getTeamContacts(teamId)
                    .then((contacts) => setTeamContact(contacts[0]))
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    })
            }
        }else{
            setTeamContact(null);
        }
    }, [ teamId ]);

    return (
        chosenTeam ?
            <Collapse appear isOpen>
                <div className="pt-2 h5 font-medium"><Translate id='insurance.label.teamInfo' /></div>
                <div className="p-2 rounded-lg bg-light border">
                    <Row form>
                        <Col sm="6" className="form-group">
                            <Label className="mb-0 text-muted" for="association">
                                <Translate id='insurance.label.association' />
                            </Label>
                            <div className="font-medium">
                                <DisplayI18n
                                    field="name"
                                    defaultValue={chosenTeam.team?.organisation?.organisation_name}
                                    i18n={chosenTeam.team?.organisation?.i18n}
                                />
                            </div>
                        </Col>
                        <Col sm="6" className="form-group">
                            <Label className="mb-0 text-muted" for="team_name">
                                <Translate id='insurance.label.teamName' />
                            </Label>
                            <div className="font-medium">
                                <DisplayI18n
                                    field="name"
                                    defaultValue={chosenTeam.team?.name}
                                    i18n={chosenTeam.team?.i18n}
                                />
                            </div>
                        </Col>
                        <Col sm="6" className="form-group">
                            <Label className="mb-0 text-muted" for="team_office">
                                <Translate id='insurance.label.teamOfficePos' />
                            </Label>
                            <div className="font-medium">
                                {orgInfo ? getLocation(orgInfo) : <Skeleton height={20} className="d-block" />}
                            </div>
                        </Col>
                        <Col sm="6" className="form-group">
                            <Label className="mb-0 text-muted" for="team_phone">
                                <Translate id='form.fields.phone' />
                            </Label>
                            <div className="font-medium">
                                <DisplayPhoneNumber phoneString={teamContact?.phone} format='INTERNATIONAL' emptyValue='-' />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Collapse>
            :
            null
    )
}

export default StepTeam;