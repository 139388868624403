import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import React from 'react';
import { DisplayCategory } from "../../../../../teams/TeamHelpers";

class OrganizationsSidepanel extends React.Component{

    render(){
        return (
            <>
                <SidePanel.Header>
                    <div className='d-flex mb-2'>
                        <SidePanel.ToggleButton />
                    </div>
                    <SidePanel.Title>
                        <DisplayI18n
                            field='name'
                            defaultValue={this.props.selectedRows[0].values[0].linked_organisation.organisation_name}
                            i18n={this.props.selectedRows[0].values[0].linked_organisation.i18n}
                        />
                    </SidePanel.Title>
                </SidePanel.Header>
                <div className='p-3'>
                    <ul>
                        {this.props.selectedRows[0].values.filter((value) => value.active == 1).map((value) => (
                            <li key={value.affiliated_organisation_id}>
                                {value.team_category ?
                                    <DisplayCategory category={value.team_category} short />
                                    :
                                    <DisplayI18n field='name' defaultValue={value.division.name} i18n={value.division.i18n} />
                                }
                                {value.active == 1 ?
                                    <i className="mdi mdi-check text-primary ml-1" />
                                    :
                                    <i className="mdi mdi-close text-danger ml-1" />
                                }
                            </li>
                        ))}
                    </ul>
                </div>
            </>
        )
    }
}

export default OrganizationsSidepanel;