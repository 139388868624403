import Translate from "@spordle/intl-elements";

const SpordleListEmpty = ({ emptySearch }) => {
    return (
        <div className="p-3 text-center text-muted d-flex flex-column flex-center h-100">
            <div className="mdi-email mdi line-height-1 fa-4x" />
            <div className="mb-3">
                {emptySearch ?
                    <Translate id="communications.inbox.search.empty" />
                    :
                    <Translate id="communications.inbox.empty" />
                }
            </div>
        </div>
    );
}

export default SpordleListEmpty;