import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { Fragment, useContext } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap'
import ConfirmModal from '../../components/confirmModal/ConfirmModal';
import { DashboardContext } from '../../contexts/DashboardContext';
import { I18nContext } from '../../contexts/I18nContext';
import { JiraContext } from '../../contexts/JiraContext';
import { isMobileDevice } from '../../helpers/constants';
import { DisplayI18n, displayI18n } from '../../helpers/i18nHelper';
import { getLink, parseData } from './dashboardHelpers';
import { Tooltip } from '@mantine/core';

const DashboardWidget = ({ widget, isPreview, isEditing, fromCaroussel, removeWidget }) => {
    const dashboardContext = useContext(DashboardContext);
    const jiraContext = useContext(JiraContext);

    const isValidCarousel = widget.dataType.code === 'caroussel' && widget.data.length > 1;

    const getContentRenderer = (type) => {
        switch (type){
            case 'small':
                return SmallWidgetContent;
            case 'medium':
            case 'tall':
            default:
                return MediumWidgetContent;
            case 'large':
                return LargeWidgetContent;
        }
    }

    const getApiData = (name) => {
        switch (name){
            case 'supportRequest':
                return jiraContext.getRequests(
                    undefined,
                    {
                        start_at: 0,
                        limit: 20,
                    },
                )
                    .then(({ issues, total }) => {
                        return {
                            headers: [
                                {
                                    headerId: '1',
                                    data: total,
                                    label: 'Created support requests',
                                    i18n: {
                                        en: { label: 'Created support requests' },
                                        fr: { label: 'Demandes de soutien créées' },
                                    },
                                },
                                {
                                    headerId: '2',
                                    data: (issues || []).filter((issue) => issue.fields.status.statusCategory.key !== "done").length,
                                    label: 'Open support requests',
                                    i18n: {
                                        en: { label: 'Open support requests' },
                                        fr: { label: 'Demandes de soutien ouvertes' },
                                    },
                                },
                            ],
                            content: issues || [],
                        };
                    })
                    .catch((e) => {
                        console.error(e);

                        return {
                            headers: [
                                {
                                    headerId: '1',
                                    data: 0,
                                    label: 'Created support requests',
                                    i18n: {
                                        en: { label: 'Created support requests' },
                                        fr: { label: 'Demandes de soutien créées' },
                                    },
                                },
                                {
                                    headerId: '2',
                                    data: 0,
                                    label: 'Open support requests',
                                    i18n: {
                                        en: { label: 'Open support requests' },
                                        fr: { label: 'Demandes de soutien ouvertes' },
                                    },
                                },
                            ],
                            error: e,
                            content: [],
                        };
                    })
            default:
                return Promise.resolve();
        }
    }

    if(fromCaroussel){
        return <CarousselWidgetStep getApiData={getApiData} widget={widget} isPreview={isPreview} />
    }

    const Content = getContentRenderer(widget.type);

    if(isPreview){
        return (
            <Card
                className='my-0 card-shadow w-100'
                aria-labelledby={`title-${widget.widgetId}`}
                aria-describedby={`description-${widget.widgetId}`}
            >
                <CardHeader>
                    <i className='mdi mdi-information-outline text-primary mr-1' />
                    <Translate id='dashboard.addModal.widget.preview' />
                </CardHeader>
                <CardBody className='d-flex flex-column min-h-0'>
                    <Content getApiData={getApiData} widget={widget} isPreview />
                </CardBody>
            </Card>
        )
    }

    return (
        <Card
            className={stringBuilder('h-100 mb-0 card-shadow overflow-hidden', { 'border-0': isEditing, 'd-flex flex-column': isValidCarousel })}
            aria-labelledby={`title-${widget.widgetId}`}
            aria-describedby={`description-${widget.widgetId}`}
        >
            {!isMobileDevice &&
                    <>
                        <div className='dashboard-grid-toolbar'>
                            <div className='dashboard-grid-toolbar-inner h-100'>
                                <i className='mdi mdi-drag dashboard-grid-drag-handle dashboard-grid-toolbar-inner-icon dashboard-grid-tool font-22' />

                                {dashboardContext.state.configureWidgetFeature &&
                                    // logic not done smile
                                    <i className='mdi mdi-settings text-muted dashboard-grid-toolbar-inner-icon dashboard-grid-tool' />
                                }

                                {dashboardContext.state.addWidgetFeature &&
                                    <ConfirmModal
                                        toggler={(toggle) => <button className='reset-btn text-danger mt-auto dashboard-grid-tool' type='button'><i className='ti-trash text-danger mt-auto dashboard-grid-toolbar-inner-icon font-20' onClick={toggle} /></button>}
                                        modalMsg={<Translate id='dashboard.widgets.remove' />}
                                        modalTitle={<DisplayI18n field='label' defaultValue={widget.label} i18n={widget.i18n} />}
                                        translateModalTitle={false}
                                        translateModalMsg={false}
                                        onConfirm={() => removeWidget(widget.widgetId)}
                                    />
                                }
                                <div className='dashboard-grid-toolbar-icon-container'>
                                    <i className='mdi mdi-dots-vertical font-22' />
                                </div>
                            </div>
                        </div>
                        <div className='dashboard-grid-toolbar-overlay' />
                    </>
            }
            {isEditing &&
                    <>
                        <div className='dashboard-grid-overlay-mobile position-absolute w-100 h-100 d-flex flex-center'>
                            <i className='mdi mdi-cursor-move display-4' />
                        </div>
                        <div className='dashboard-grid-overlay-mobile-manage-container'>
                            <i className='mdi mdi-settings text-muted font-22 mr-2' />
                            <ConfirmModal
                                toggler={(toggle) => <button className='reset-btn text-danger' type='button'><i className='ti-trash text-danger font-22' onClick={toggle} /></button>}
                                modalMsg={<Translate id='dashboard.widgets.remove' />}
                                modalTitle={<DisplayI18n field='label' defaultValue={widget.label} i18n={widget.i18n} />}
                                translateModalTitle={false}
                                translateModalMsg={false}
                                onConfirm={() => removeWidget(widget.widgetId)}
                            />
                        </div>
                    </>
            }
            {isValidCarousel ?
                <Content widget={widget} isValidCarousel={isValidCarousel} />
                :
                <CardBody className='d-flex flex-column min-h-0'>
                    <Content getApiData={getApiData} widget={widget.dataType.code === 'caroussel' ? widget.data[0] : widget} isValidCarousel={isValidCarousel} />
                </CardBody>
            }
        </Card>
    );
}

const SmallWidgetContent = ({ widget, isPreview, getApiData, isValidCarousel }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const label = displayI18n('label', widget.i18n, widget.label, getGenericLocale());
    const description = displayI18n('description', widget.i18n, widget.description, getGenericLocale());
    const Title = isValidCarousel ? ({ children }) => <CardBody className='pb-0'>{children}</CardBody> : Fragment;
    return (
        <>
            <Title>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='text-truncate mr-2'>
                        <h5 className='font-bold mb-0 text-truncate' title={label} id={`title-${widget.widgetId}`}>
                            {label}
                        </h5>
                    </div>
                    <div className='text-nowrap'>
                        {description &&
                            <Tooltip withArrow label={`${description}. Does not make sense but this would be an actual description :)`}>
                                <i className='mdi mdi-information-outline text-primary mr-1' />
                            </Tooltip>
                        }
                        {!isPreview &&
                            getLink(widget)
                        }
                    </div>
                    <div className='sr-only' id={`description-${widget.widgetId}`}>{description}</div>
                </div>
            </Title>
            {parseData(widget, getApiData)}
        </>
    )
}

const MediumWidgetContent = ({ widget, getApiData, isPreview, isValidCarousel }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const label = displayI18n('label', widget.i18n, widget.label, getGenericLocale());
    const description = displayI18n('description', widget.i18n, widget.description, getGenericLocale());

    const Title = isValidCarousel ? ({ children }) => <CardBody className='pb-0'>{children}</CardBody> : Fragment;

    return (
        <>
            <Title>
                <div className='d-flex align-items-center border-bottom pb-1 mb-2'>
                    {/* {!isPreviewPopover && !isMobileDevice &&
                        <i className='mdi mdi-drag ml-n2 align-self-center dashboard-grid-drag-handle' />
                    } */}
                    <h4 className='font-bold mb-0 text-truncate' title={label} id={`title-${widget.widgetId}`}>
                        {label}
                    </h4>
                    <div className='ml-auto'>
                        {!isPreview &&
                            getLink(widget)
                        }
                    </div>
                </div>
                {description && <div className="font-medium mb-2" id={`description-${widget.widgetId}`}>{description}</div>}
            </Title>
            {parseData(widget, getApiData)}
        </>
    )
}

const LargeWidgetContent = ({ widget, isPreview, getApiData, isValidCarousel }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const label = displayI18n('label', widget.i18n, widget.label, getGenericLocale());
    const description = displayI18n('description', widget.i18n, widget.description, getGenericLocale());
    const Title = isValidCarousel ? ({ children }) => <CardBody className='pb-0'>{children}</CardBody> : Fragment;

    return (
        <>
            <Title>
                <div className='d-flex align-items-center pb-1 mb-2'>
                    {/* {!isPreviewPopover && !isMobileDevice &&
                        <i className='mdi mdi-drag dashboard-grid-drag-handle' />
                    } */}
                    <h4 className='font-bold mb-0 text-truncate' title={label} id={`title-${widget.widgetId}`}>
                        {label}
                    </h4>
                    <div className='ml-auto'>
                        {!isPreview &&
                            getLink(widget)
                        }
                    </div>
                </div>
                {description && <div className="font-medium mb-2" id={`description-${widget.widgetId}`}>{description}</div>}
            </Title>
            {parseData(widget, getApiData)}
        </>
    )
}

const CarousselWidgetStep = ({ widget, isPreview, getApiData }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const label = displayI18n('label', widget.i18n, widget.label, getGenericLocale());
    const description = displayI18n('description', widget.i18n, widget.description, getGenericLocale());

    return (
        <>
            <div className='d-flex align-items-center pb-1 mb-2'>
                <h6 className='font-bold mb-0 text-truncate' title={label} id={`title-${widget.widgetId}`}>
                    {label}
                </h6>
                <div className='ml-auto small'>
                    {!isPreview &&
                        getLink(widget)
                    }
                </div>
            </div>
            {description && <div className="font-medium mb-2" id={`description-${widget.widgetId}`}>{description}</div>}
            {parseData(widget, getApiData)}
        </>
    )
}

export default DashboardWidget