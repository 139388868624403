import { stringBuilder } from "@spordle/helpers";
import Translate, { DateFormat } from "@spordle/intl-elements";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import {
    DropdownItem,
    Fade,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import CreditInvoiceModal from "../../../../../components/CreditInvoiceModal";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import HasAccessTo from "../../../../../components/permissions/HasAccessTo";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { fail, success } from '@spordle/toasts';
import EditPaymentReceptionModal from "../../../../../components/transactions/EditPaymentReceptionModal";
import { PeriodsContext } from '../../../../../contexts/contexts';
import { I18nContext } from "../../../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import { RolesContext } from "../../../../../contexts/RolesContext";
import { MembersContext } from "../../../../../contexts/MembersContext";
// contexts
import { OnlineStoreContext } from '../../../../../contexts/OnlineStoreContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { ClinicsContext } from '../../../../../contexts/ClinicsContext';
import withContexts from '../../../../../helpers/withContexts';
import SidePanelMemberSearch from '../../../../members/search/SidePanelMemberSearch';
import OnlineStoreTransactionPaymentReceptionModal from '../../../../onlineStore/profile/transactions/components/modals/OnlineStoreTransactionPaymentReceptionModal';
import OnlineStoreTransactionRefundModal from '../../../../onlineStore/profile/transactions/components/modals/refundModal/OnlineStoreTransactionRefundModal';
import ClinicProfileRegistrationAdd from "../addModal/ClinicProfileRegistrationAdd";
// Actions Modals
import AttendeeModalCertify from "./sidePanelActionsModals/AttendeeModalCertify";
import ClinicAttendeesSidepanelForm from "./sidePanelComponents/ClinicAttendeesSidepanelForm";
import SidePanelAttendeeInfo from './sidePanelComponents/SidePanelAttendeeInfo';
import SidePanelAttendeeMulti from "./sidePanelComponents/SidePanelAttendeeMulti";
import SidePanelPaymentInfo from './sidePanelComponents/SidePanelPaymentInfo';
import CanDoAction from "../../../../../components/permissions/CanDoAction";
import AttendeeModalCancel from "./sidePanelActionsModals/attendeeModalCancel/AttendeeModalCancel";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import SidePanelWaivers from "./sidePanelComponents/SidePanelWaivers";
import generatePassword from "../../../../../helpers/passwordGenerator";
import SendCommModal from "../../../../../components/communications/SendCommModal";
import QuickViewButton from "../../../../../components/quickView/QuickViewButton";
import QuickViewActionButton from "../../../../../components/quickView/QuickViewActionButton";
import InlineCopy from "../../../../../components/inlineCopy/InlineCopy";
import DisplayClinicAttendeeGrade from "../../../../../components/display/DisplayClinicAttendeeGrade";


class SidePanelAttendees extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            activeTab: '1',
            invoiceInfo: false,
            infoLoading: false,

            showCertifyModal: false,
            showPaymentReceivedModal: false,
            showTransferModal: false,
            showRefundModal: false,
            showExchangeModal: false,
            commModal: {
                isOpen: false,
                initMsg: null,
                initSubject: null,
                recipients: null,
            },
            commModalV2: {
                isOpen: false,
                initMsg: null,
                initSubject: null,
                recipients: null,
            },
            showCancelModal: false,

            currentPaymentReceptionId: '',
            editPaymentReceptionModalOpen: false,

            creditInvoiceModalIsOpen: false,
            refundAmounts: false,

            syncIndex: 0,

            memberFundingLoading: false,
            memberFunding: [],
        }
    }

    toggleTab = (tab) => {
        if(this.state.activeTab !== tab){
            this.setState({ activeTab: tab });
        }
    }

    // for (maybe) future invoice system
    // triggerRefund = () => {
    //     // Place hash and attendee ID in url
    //     window.location.hash = '#refund=123123';
    // }

    componentDidMount(){
        if(this.props.selectedRows[0].invoice){
            this.getInvoice();
            if(this.props.selectedRows.length == 1)
                this.getRefundAmounts();
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(
            (this.props.selectedRows[0].invoice
            && this.props.selectedRows[0].invoice.invoice_number !== prevProps.selectedRows[0].invoice?.invoice_number)
            || prevState.syncIndex !== this.state.syncIndex // this allows me to trigger invoice update by incrementing syncIndex

        ){
            this.getInvoice();
            if(this.props.selectedRows.length == 1)
                this.getRefundAmounts();
        }

        if(this.props.selectedRows.length > 1) // Select Details tab when more then 1 row is selected because other tabs don't support multi select
            this.toggleTab('1');
    }

    syncSpecificAttendee = (clinic_id, attendee_member_id) => {
        this.setState(() => ({ isLoading: true, infoLoading: true }))
        this.props.ClinicsContext.getClinicAttendeeByMemberId(clinic_id, attendee_member_id)
            .then((attendee) => {
                this.props.syncRows(attendee);
                this.setState((prevState) => ({ isLoading: false, infoLoading: false, syncIndex: prevState.syncIndex + 1 }))
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }


    toggleEditPaymentModal = (paymentReceptionId) => {
        this.setState((prevState) => ({
            currentPaymentReceptionId: paymentReceptionId,
            editPaymentReceptionModalOpen: !prevState.editPaymentReceptionModalOpen,
        }))
    }
    // this has been replaced with invoice.total_amount - paidAmount to match what happens in transactionHeader
    //
    // getDueAmount = (invoice) => {
    //     // if we find payment_receptions (manual payment), we change the way to calculate the due amount
    //     // we get the total amount and subtract the total of every payment_reception
    //     if(invoice.payment_receptions && Array.isArray(invoice.payment_receptions) && invoice.payment_receptions.length > 0){
    //         const totalReceived = invoice.payment_receptions.reduce((total, pM) => {
    //             return total += parseInt(pM.amount)
    //         }, 0)

    //         // there are 2 types of credits
    //         // applied credits (from the credits array), and the credits from payment_receptions
    //         // the applied credits are never subtracted from the dueAmount when there are payment_receptions
    //         // they are subtracted from the invoice item payments, so we only want to do that here
    //         return parseInt(invoice.total_amount) - totalReceived - this.getCreditAmount(invoice)
    //     }
    //     // if we dont find any payment_receptions, that means that the payment method is either online (CREDITCARD), and we do the old way
    //     // or that the manual payment is manual, but that no payments have been done
    //     return invoice.invoice_items.reduce((sum, item) => {
    //         return sum += parseInt(item.payments.reduce((paymentSum, payment) => {
    //             return paymentSum += parseInt((payment.status !== "REFUNDED" && payment.status !== "COMPLETED") ? payment.amount : 0)
    //         }, 0))
    //     }, 0)

    // }

    getFundingAmounts = (invoice) => {
        if(invoice.status === 'COMPLETED'){
            const members = [];
            invoice.invoice_items.forEach((item) => {
                if(!members.find((m) => m.member_id === item.member.member_id))
                    members.push(item.member)
            })

            if(members.length > 0){
                this.setState(() => ({ memberFundingLoading: true }))
                Promise.all(members.map((m) => {
                    return this.props.MembersContext.getAvailableToBeFunded(invoice.invoice_number, { memberId: m.member_id })
                        .then((fundingAmount) => fundingAmount || 0)
                }))
                    .then((funding) => {
                        this.setState(() => ({
                            memberFunding: funding.map((fundVal, index) => {
                                return ({
                                    fundingValue: fundVal,
                                    label: `${members[index].first_name} ${members[index].last_name}`,
                                    value: generatePassword(),
                                    memberId: members[index].member_id,
                                });
                            }),
                        }))
                        this.setState(() => ({ memberFundingLoading: false }))
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                            this.setState(() => ({ memberFundingLoading: false }))
                        }
                    })
            }
        }
    }

    getPaymentReceptionsCreditAmount = (invoice) => {
        let paymentReceptionsCreditsTotal = 0;

        if(invoice.payment_receptions && Array.isArray(invoice.payment_receptions) && invoice.payment_receptions.length > 0){
            paymentReceptionsCreditsTotal += invoice.payment_receptions.reduce((total, pM) => {
                if(pM.member_credit_id){
                    total += parseInt(pM.amount)
                }
                return total
            }, 0)
        }

        return paymentReceptionsCreditsTotal
    }

    getCreditAmount = (invoice) => {
        return invoice.credits.reduce((sum, credit) => sum += parseInt(credit.amount), 0);
    }

    getInvoice = () => {
        const getClinic = (item) => item.clinic != null;

        this.setState(() => ({ invoiceInfo: false, infoLoading: true }))
        this.props.OnlineStoreContext.getInvoice(this.props.getCommonValue('invoice')?.invoice_number, {
            organisation_id: this.props.OrganizationContext.organisation_id,
            period_id: this.props.PeriodsContext.selectedPeriod.period_id,
        })
            .then((invoice) => {
                // there is a case where HC members are registered to a clinic from Sarnia (which should never, never, never ever happen)
                // and that causes a GET invoice call for a member from HC while managing Sarnia (it do be going boom)
                // that if prevents that
                if(invoice){
                    const paidAmount = invoice.invoice_items.reduce((sum, item) => sum += parseInt(item.paid_amount), 0) + this.getCreditAmount(invoice)
                    this.setState(() => ({
                        infoLoading: false,
                        invoiceInfo: {
                            ...invoice,

                            // these variables are needed for the receive money modal, same logic as member & online store transactions
                            registrations: [],
                            clinic: invoice.invoice_items.find(getClinic),
                            due: invoice.total_amount - paidAmount,
                            creditAmount: this.getCreditAmount(invoice) + this.getPaymentReceptionsCreditAmount(invoice),

                            invoiceClinicFeesPayments: invoice.invoice_items.filter((item) => !!item.clinic_fee).flatMap((item) => item.payments),
                            invoiceClinicFees: invoice.invoice_items.filter((item) => !!item.clinic_fee),

                            waivers: invoice.invoice_items.reduce((newArray, item) => {
                                item.waivers.forEach((waiver) => {
                                    newArray.push({ ...waiver, invoiceItem: item });
                                })
                                return newArray
                            }, []),
                        },
                    }))
                    this.getFundingAmounts(invoice)
                }else{
                    // to stop loading and show an error inside the payment info section
                    this.setState(() => ({ invoiceInfo: null, infoLoading: false }))
                }
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({ invoiceInfo: null, infoLoading: false }))
                }
            })
    }

    getRefundAmounts = () => {
        this.setState(() => ({ refundAmounts: false }));
        this.props.MembersContext.getRefundAmounts(this.props.getCommonValue('invoice').invoice_number)
            .then((refundAmounts) => {
                this.setState(() => ({ refundAmounts: refundAmounts }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    getClinicItem = (invoice_items) => {
        return (invoice_items.find((item) => item.clinic?.clinic_id === this.props.selectedRows[0].clinic.clinic_id))
    }

    handleResendInvoice = (withReceipt = false) => {
        this.props.MembersContext.resendInvoice(this.props.selectedRows.flatMap((a) => a.invoice?.invoice_number), withReceipt)
            .then(() => success({
                skipInfoTranslate: true,
                info: <Translate values={{ count: this.props.selectedRows.length }} id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice.success' />,
            }))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    if(error.i18n){
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }else{
                        fail({ info: 'members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice.error' })
                    }
                }
            })
    }

    onPaymentReception = () => {
        this.syncSpecificAttendee(this.props.selectedRows[0].clinic.clinic_id, this.props.selectedRows[0].member.member_id);
        success();
    }

    toggleCreditInvoiceModal = () => {
        this.setState((prevState) => ({
            creditInvoiceModalIsOpen: !prevState.creditInvoiceModalIsOpen,
        }))
    }

    onCreditInvoice = () => {
        this.syncSpecificAttendee(this.props.selectedRows[0].clinic.clinic_id, this.props.selectedRows[0].member.member_id);
    }

    openComm = (msg = null, initSubject = null, recipients = null) => {
        this.setState((prev) => ({ ...prev, commModal: { isOpen: true, initMsg: msg, recipients: recipients, initSubject } }))
    }

    closeComm = () => {
        this.setState((prev) => ({ ...prev, commModal: { isOpen: false, recipients: null, initMsg: null, initSubject: null } }))
    }

    canCreditInvoice = () => {
        if(this.state.invoiceInfo){
            return this.state.invoiceInfo.status === "PENDING" || this.state.invoiceInfo.status === "PARTIALLY_PAID" || !this.state.isVoid || this.props.selectedRows[0].due != 0
        }
        return false;
    }

    render(){
        const canReceivedPayment = ((this.state.invoiceInfo && (this.state.invoiceInfo.invoice_payment_method?.code !== 'CREDITCARD' || this.state.invoiceInfo.origin === 'ADMIN') && this.state.invoiceInfo.due > 0) || (this.state.memberFunding.some((fund) => fund.fundingValue > 0) && this.state.invoiceInfo));
        const canRefund = (parseInt(this.state.refundAmounts.payment_gateway) > 0 || parseInt(this.state.refundAmounts.manual) > 0);
        const canCancel = !this.props.selectedRows[0].cancelled_at && this.props.selectedRows[0].attended != "1";

        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                {/* This modal is the V2 */}
                <SendCommModal
                    id="clinicAttendeesSidePanel"
                    isOpen={!!this.state.commModal.isOpen}
                    toggle={this.closeComm}
                    initValues={{
                        msg: this.state.commModalV2.initMsg,
                        subject: this.state.commModalV2.initSubject,
                    }}
                    recipients={(this.state.commModal.recipients || this.props.selectedRows || []).map((attendee) => ({ ...attendee.member, email: attendee.member.confirmed_by?.email || attendee.member.email }))}
                />

                <AttendeeModalCancel
                    isOpen={this.state.showCancelModal}
                    toggle={() => this.setState((prev) => ({ ...prev, showCancelModal: false }))}
                    attendees={this.props.selectedRows}
                    syncRows={this.props.syncRows}
                    createNewValues={this.props.createNewValues}
                    sendMessage={this.openComm}
                />
                { this.props.selectedRows.length > 1 ?
                    <Fade in>
                        <SidePanelAttendeeMulti
                            showCommModal={() => this.openComm()}
                            handleResendInvoice={this.handleResendInvoice}
                            setLoading={(loading) => this.setState(() => ({ isLoading: loading }))}
                            showCancelModal={() => this.setState(() => ({ showCancelModal: true }))}
                            {...this.props}
                        />
                    </Fade>
                    :
                    <Fade in>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Modals                                                                         */}
                        {/*--------------------------------------------------------------------------------*/}
                        {this.state.editPaymentReceptionModalOpen &&
                            <EditPaymentReceptionModal
                                isOpen={this.state.editPaymentReceptionModalOpen}
                                toggle={this.toggleEditPaymentModal}
                                payment={// finds the correct payment reception object from the paymentReceptionId set in the toggle function
                                    this.state.invoiceInfo.payment_receptions.find(
                                        (paymentReception) => paymentReception.payment_reception_id === this.state.currentPaymentReceptionId,
                                    )
                                }
                                invoiceNumber={this.props.selectedRows[0].invoice.invoice_number}
                                paymentReceptionId={this.state.currentPaymentReceptionId}
                                onEdit={(values) => {
                                    const targetedReception = this.state.invoiceInfo.payment_receptions.findIndex(
                                        (paymentReception) => paymentReception.payment_reception_id === this.state.currentPaymentReceptionId,
                                    )

                                    const newReceptions = this.state.invoiceInfo.payment_receptions;
                                    newReceptions[targetedReception].note = values.note;
                                    newReceptions[targetedReception].received_at = values.received_at;

                                    const newValues = this.props.createNewValues({ payment_receptions: newReceptions });
                                    this.props.syncRows(newValues);
                                }}
                            />
                        }
                        {this.state.showRefundModal &&
                            <OnlineStoreTransactionRefundModal
                                {...this.props}
                                chainWithCancel={!this.props.selectedRows[0].cancelled_at}
                                isOpen={this.state.showRefundModal}
                                toggle={() => { this.setState((state) => ({ showRefundModal: !state.showRefundModal })) }}
                                onRefund={() => {
                                    this.syncSpecificAttendee(this.props.selectedRows[0].clinic.clinic_id, this.props.selectedRows[0].member.member_id)
                                }}
                                onCancelAttendee={() => {
                                    this.setState((state) => ({ showRefundModal: !state.showRefundModal }))
                                    this.setState((state) => ({ showCancelModal: !state.showCancelModal }))
                                }}
                                cancelPermissions={{ action: "EDIT", componentCode: "members", componentPermissionCode: "members_registrations" }}
                                transaction={this.state.invoiceInfo}
                                mode='clinics'
                            />
                        }
                        {this.state.showPaymentReceivedModal &&
                            <OnlineStoreTransactionPaymentReceptionModal
                                isOpen={this.state.showPaymentReceivedModal}
                                toggle={() => { this.setState((state) => ({ showPaymentReceivedModal: !state.showPaymentReceivedModal })) }}
                                onPaymentReception={this.onPaymentReception}
                                transaction={this.state.invoiceInfo}
                                allPaymentMethods={this.state.invoiceInfo.origin === 'ADMIN'}
                                memberFunding={this.state.memberFunding}
                                memberFundingLoading={this.state.memberFundingLoading}
                                getFundingAmounts={() => this.getInvoice()} // getting invoice gets the funding amount
                                refreshTable={this.props.tableRef.refreshTable}
                            />
                        }
                        {this.state.showExchangeModal &&
                            <ClinicProfileRegistrationAdd
                                isOpen={this.state.showExchangeModal}
                                toggle={() => {
                                    this.setState((state) => ({ showExchangeModal: !state.showExchangeModal }))
                                    this.props.forceCloseSidePanel();
                                }}
                                refreshTable={this.props.tableRef.refreshTable}
                                invoiceItemId={this.state.invoiceInfo?.invoice_items.find((item) => item.clinic)?.invoice_item_id}
                                currentClinicId={this.state.invoiceInfo?.invoice_items.find((item) => item.clinic)?.clinic.clinic_id}
                                member={{
                                    ...this.state.invoiceInfo?.invoice_items.find((item) => item.member)?.member || {},
                                    organisation: this.state.invoiceInfo?.invoice_items.find((item) => item.member)?.organisation,
                                }}
                            />
                        }

                        <CreditInvoiceModal
                            isOpen={this.state.creditInvoiceModalIsOpen}
                            toggle={() => {
                                this.toggleCreditInvoiceModal();
                            }}
                            invoiceNumber={this.props.selectedRows[0].invoice?.invoice_number}
                            onCreditInvoice={this.onCreditInvoice}
                        />
                        <div className='sticky-top bg-white z-index-1'>
                            <SidePanel.Header noBorder>
                                <div className='d-flex mb-2 align-items-center'>
                                    <SidePanel.ToggleButton />

                                    <div className="ml-auto d-flex align-items-center">
                                        <HasAccessTo componentCode='members' componentPermissionCode='members_profile'>
                                            <Link to={"/members/profile/" + this.props.selectedRows[0].member.member_id} className="mr-2">
                                                <Translate id='misc.goToProfile' /> <i className="mdi mdi-chevron-right" />
                                            </Link>
                                        </HasAccessTo>
                                        <SidePanel.ActionsMenu>
                                            {/* <SidePanel.MenuAction onClick={() => {this.setState(() => ({showNotificationModal: true}))}} disabled>
                                                <Translate id='clinics.profile.attendees.sidepanel.actions.notification'/>
                                            </SidePanel.MenuAction> */}

                                            {/* CERTIFY / REMOVE CERTIFICATION (ONLY FOR COACH) */}
                                            {/* qualification.certified ==> "certifiable" */}
                                            {(this.props.ClinicsContext.currentClinic.qualification.certified == '1' && this.props.selectedRows[0].certified != '1') &&
                                                <>
                                                    <SidePanel.MenuAction onClick={() => { this.setState(() => ({ showCertifyModal: true })) }} disabled={this.props.getCommonValue('certified') == 1 || (this.props.getCommonValue('attended') == 0 || this.props.getCommonValue('passed') == 0)}>
                                                        <Translate id='clinics.profile.attendees.sidepanel.actions.certify' />
                                                    </SidePanel.MenuAction>
                                                    <AttendeeModalCertify
                                                        isOpen={this.state.showCertifyModal}
                                                        toggle={() => { this.setState((state) => ({ showCertifyModal: !state.showCertifyModal })) }}
                                                        panelProps={this.props}
                                                    />
                                                    <DropdownItem divider />
                                                </>
                                            }


                                            {/* Payment / Refund / Credit */}
                                            <SidePanel.MenuAction
                                                onClick={() => { this.setState(() => ({ showPaymentReceivedModal: true })) }}
                                                disabled={!canReceivedPayment}
                                            >
                                                <Translate id='clinics.profile.attendees.sidepanel.actions.addPayment' />
                                            </SidePanel.MenuAction>
                                            <SidePanel.MenuAction
                                                onClick={() => { this.setState(() => ({ showRefundModal: true })) }}
                                                disabled={!canRefund}
                                            >
                                                <Translate id='clinics.profile.attendees.sidepanel.actions.refund' />
                                            </SidePanel.MenuAction>
                                            <SidePanel.MenuAction
                                                onClick={this.toggleCreditInvoiceModal}
                                                disabled={!this.canCreditInvoice()}
                                            >
                                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.credit' />
                                            </SidePanel.MenuAction>


                                            {/* ATTENDEE CANCEL / TRANSFER */}
                                            <DropdownItem divider />

                                            {(this.props.selectedRows[0].member.confirmed == '0' && this.props.RolesContext.canDoAction('EDIT', "members", 'confirm_members')) &&
                                                <CanDoAction action="EDIT" componentCode="members" componentPermissionCode="confirm_members">
                                                    <ConfirmModal
                                                        color='danger'
                                                        modalTitle='clinics.profile.attendees.sidepanel.actions.confirmMember.text'
                                                        translateModalTitle
                                                        actionButtonText='misc.confirm'
                                                        translateActionButtonText
                                                        onConfirm={async() => {
                                                            return this.props.MembersContext.confirmMembers([ this.props.selectedRows[0].member.member_id ])
                                                                .then(() => {
                                                                    this.syncSpecificAttendee(this.props.selectedRows[0].clinic.clinic_id, this.props.selectedRows[0].member.member_id)
                                                                    success();
                                                                })
                                                                .catch((error) => {
                                                                    if(!AxiosIsCancelled(error.message)){
                                                                        console.error(error.message)
                                                                        fail({
                                                                            msg: 'misc.error',
                                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                            skipInfoTranslate: true,
                                                                        })
                                                                    }
                                                                })
                                                        }}
                                                        toggler={(onClick) => (
                                                            <SidePanel.MenuAction onClick={onClick}>
                                                                <Translate id='clinics.profile.attendees.sidepanel.actions.confirmMember' />
                                                            </SidePanel.MenuAction>
                                                        )}
                                                    />
                                                </CanDoAction>
                                            }

                                            {/* <ConfirmModal
                                                color='danger'
                                                modalTitle='clinics.profile.attendees.sidepanel.actions.cancelAttendee.text'
                                                translateModalTitle
                                                actionButtonText='misc.confirm'
                                                translateActionButtonText
                                                onConfirm={async() => {
                                                    return this.props.MembersContext.cancelInvoiceItem(this.state.invoiceInfo?.invoice_items.find((item) => item.clinic)?.invoice_item_id, "Cancel attendee")
                                                        .then(() => {
                                                            this.syncSpecificAttendee(this.props.selectedRows[0].clinic.clinic_id, this.props.selectedRows[0].member.member_id)
                                                            success();
                                                        })
                                                        .catch((e) => {
                                                            if(!AxiosIsCancelled(e.message)){
                                                                console.error(e.message);
                                                                fail();
                                                            }
                                                        })
                                                }}
                                                toggler={(onClick) => (
                                                    <SidePanel.MenuAction onClick={onClick}>
                                                        <Translate id='clinics.profile.attendees.sidepanel.actions.cancelAttendee' />
                                                    </SidePanel.MenuAction>
                                                )}
                                            /> */}
                                            <SidePanel.MenuAction disabled={!canCancel} onClick={() => this.setState(() => ({ showCancelModal: true }))}>
                                                <Translate id='clinics.profile.attendees.sidepanel.actions.cancelAttendee' values={{ count: 1 }} />
                                                {!canCancel &&
                                                    <div className="small text-muted"><Translate id={this.props.selectedRows[0].cancelled_at ? 'clinics.profile.attendees.sidepanel.actions.cancelAttendee.error.alreadyCancelled' : 'clinics.profile.attendees.sidepanel.actions.cancelAttendee.error.alreadyPassed'} /></div>
                                                }
                                            </SidePanel.MenuAction>
                                            <SidePanel.MenuAction
                                                onClick={() => { this.setState(() => ({ showExchangeModal: true })) }}
                                                disabled={!this.state.invoiceInfo || this.state.invoiceInfo?.refunds?.length > 0}
                                            >
                                                <Translate id='clinics.profile.attendees.sidepanel.actions.transfer' />
                                                {this.state.invoiceInfo?.refunds?.length > 0 &&
                                                    <div className="small">
                                                        <Translate id='clinics.profile.attendees.sidepanel.actions.transfer.noRefund' />
                                                    </div>
                                                }
                                            </SidePanel.MenuAction>

                                            {/* Invoice */}
                                            <DropdownItem divider />
                                            <SidePanel.MenuAction onClick={() => this.handleResendInvoice(false)}>
                                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice' />
                                            </SidePanel.MenuAction>
                                            <SidePanel.MenuAction onClick={() => this.handleResendInvoice(true)}>
                                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoiceWithReceipt' />
                                            </SidePanel.MenuAction>

                                            <DropdownItem divider />
                                            <SidePanel.MenuAction action="ADD" componentCode="communications" componentPermissionCode="communications_manage" onClick={() => this.openComm()}>
                                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.sendCommunication' />
                                            </SidePanel.MenuAction>
                                            <QuickViewActionButton member={this.props.selectedRows[0].member} />
                                        </SidePanel.ActionsMenu>
                                    </div>
                                </div>

                                <SidePanel.Title>
                                    <QuickViewButton member={this.props.selectedRows[0].member} className="d-flex">
                                        {this.props.selectedRows[0].member.first_name} {this.props.selectedRows[0].member.last_name}
                                    </QuickViewButton>
                                </SidePanel.Title>
                                <SidePanel.Subtitle className="mr-4 d-flex align-items-center">
                                    <InlineCopy className="text-medium" toCopy={this.props.selectedRows[0].member.unique_identifier}>
                                        <Link className="text-nowrap" to={`/members/profile/${this.props.selectedRows[0].member.member_id}`}>
                                            {this.props.selectedRows[0].member.unique_identifier ?
                                                <>#{this.props.selectedRows[0].member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                                : '-'}
                                        </Link>
                                    </InlineCopy>
                                </SidePanel.Subtitle>
                                {this.props.selectedRows[0].member.confirmed == '0' &&
                                    <SidePanel.Subtitle className='text-danger font-bold'>
                                        <i className='mdi mdi-information-outline mr-1' /><Translate id='clinics.profile.attendees.sidepanel.header.notConfirmed' />
                                    </SidePanel.Subtitle>
                                }
                            </SidePanel.Header>

                            <Nav tabs>
                                <NavItem className="text-center" style={{ width: "33%" }}>
                                    <NavLink
                                        className={stringBuilder({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggleTab('1'); }}
                                    >
                                        <Translate id='clinics.profile.attendees.sidepanel.tabs.details' />
                                    </NavLink>
                                </NavItem>
                                <NavItem className="text-center" style={{ width: "33%" }}>
                                    <NavLink
                                        className={stringBuilder({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggleTab('2'); }}
                                    >
                                        <Translate id='clinics.profile.attendees.sidepanel.tabs.member' />
                                    </NavLink>
                                </NavItem>
                                <NavItem className="text-center" style={{ width: "34%" }}>
                                    <NavLink
                                        className={stringBuilder({ active: this.state.activeTab === '3' })}
                                        onClick={() => { this.toggleTab('3'); }}
                                    >
                                        <Translate id='clinics.profile.attendees.sidepanel.tabs.forms' />
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <SidePanel.Body className="border-bottom">
                                    <SidePanelAttendeeInfo {...this.props} setLoading={(loading) => this.setState(() => ({ isLoading: loading }))} />
                                    {this.props.ClinicsContext.currentClinic?.clinic_external_identifier?.[0]?.external_id &&
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id="clinics.profile.attendees.table.grade" /></div>
                                            <DisplayClinicAttendeeGrade className="font-medium" attendee={this.props.selectedRows[0]} />
                                        </div>
                                    }
                                    {this.props.getCommonValue('invoice')?.origin === 'ADMIN' ?
                                        <>
                                            {this.props.getCommonValue('invoice')?.initiated_by &&
                                                <>
                                                    <div className="h6 font-bold mb-2"><Translate id='clinics.profile.attendees.sidepanel.registeredFrom.ADMIN' /></div>
                                                    <div className="font-medium">
                                                        <span className='text-capitalize'><DateFormat value={this.props.getCommonValue('invoice').invoice_date} format={'dddd ' + DateFormat.formats.longMonthDayYear} /></span>
                                                        <span className='text-muted'> <Translate id='clinics.profile.attendees.sidepanel.registeredAt' /> </span>
                                                        <DateFormat value={this.props.getCommonValue('invoice').invoice_date} format={'h:mm a'} />
                                                    </div>
                                                </>
                                            }
                                        </>
                                        :
                                        this.props.getCommonValue('invoice') &&
                                        <>
                                            {this.props.getCommonValue('invoice').initiated_by &&
                                                <>
                                                    <div className="h6 font-bold mb-2"><Translate id='clinics.profile.attendees.sidepanel.registeredBy' /></div>
                                                    <div className="mb-2">
                                                        <div className="font-medium">{this.props.getCommonValue('invoice').initiated_by.name} {this.props.getCommonValue('invoice').initiated_by.family_name}</div>
                                                    </div>
                                                </>
                                            }
                                            <div className="text-muted"><Translate id={'clinics.profile.attendees.sidepanel.registeredFrom.' + this.props.getCommonValue('invoice').origin} /></div>
                                            <div className="font-medium">
                                                <span className='text-capitalize'><DateFormat value={this.props.getCommonValue('invoice').invoice_date} format={'dddd ' + DateFormat.formats.longMonthDayYear} /></span>
                                                <span className='text-muted'> <Translate id='clinics.profile.attendees.sidepanel.registeredAt' /> </span>
                                                <DateFormat value={this.props.getCommonValue('invoice').invoice_date} format={'h:mm a'} />
                                            </div>
                                        </>
                                    }
                                </SidePanel.Body>
                                {this.props.getCommonValue('invoice') && <SidePanelPaymentInfo invoice={this.state.invoiceInfo} {...this.props} toggleEdit={this.toggleEditPaymentModal} />}
                                {this.props.getCommonValue('invoice') && <SidePanelWaivers invoice={this.state.invoiceInfo} {...this.props} />}
                            </TabPane>
                            <TabPane tabId="2">
                                <SidePanelMemberSearch
                                    {...this.props}
                                    MembersContext={this.props.MembersContext}
                                    IdentityRolesContext={this.props.IdentityRolesContext}
                                    OrganizationContext={this.props.OrganizationContext}
                                    selectedRows={[ {
                                        ...this.props.selectedRows[0],
                                        ...this.props.selectedRows[0].member,
                                    } ]}
                                    hideHeader
                                    hideTeamsTab
                                />
                            </TabPane>
                            <TabPane tabId="3">
                                <ClinicAttendeesSidepanelForm
                                    invoiceNumber={this.props.selectedRows[0].invoice_number}
                                    selectedRows={this.props.selectedRows[0]}
                                    invoiceItem={this.state.infoLoading ? null : this.state.invoiceInfo ? this.getClinicItem(this.state.invoiceInfo?.invoice_items) : { custom_forms: { active: "0" } }}
                                    updateState={(groupIndex, fieldIndex, invoiceItemId, field) => {
                                        const itemIndex = this.state.invoiceInfo?.invoice_items.findIndex((item) => item.invoice_item_id === invoiceItemId);

                                        const invoiceItems = this.state.invoiceInfo.invoice_items;

                                        invoiceItems[itemIndex].custom_forms.groups[groupIndex].fields[fieldIndex] = field;

                                        this.setState((prevState) => ({
                                            invoiceInfo: {
                                                ...prevState.invoiceInfo,
                                                invoice_items: invoiceItems,
                                            },
                                        }))
                                    }}
                                />
                            </TabPane>
                        </TabContent>
                    </Fade>
                }
            </OverlayLoader>
        )
    }
}

export default withRouter(withContexts(OnlineStoreContext, OrganizationContext, PeriodsContext, MembersContext, IdentityRolesContext, I18nContext, ClinicsContext, RolesContext)(SidePanelAttendees));