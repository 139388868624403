import { FormikSelect } from '@spordle/formik-elements';
import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Button, CardFooter, Col, FormGroup, Label, Row } from 'reactstrap';
import { object, string } from 'yup';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import useSWR from 'swr';
import Translate from '@spordle/intl-elements';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { fail, success } from '@spordle/toasts';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';

const ReginalSettingsEdit = ({ toggleEditMode, settings }) => {
    const { getOrganizationSettings, organisation_id, updateSettings } = useContext(OrganizationContext);

    const [ selectForRegistration, setSelectForRegistration ] = useState([]);
    const [ selectForClinic, setSelectForClinic ] = useState([]);

    const { data, _, mutate } = useSWR(
        [ 'OrganizationPaymentSettings', organisation_id ],
        () => getOrganizationSettings(organisation_id, { active: 1 }).then(async(orgSettings) => {
            return { orgSettings };
        }),
        {
            fallbackData: {
                settings: {},
                orgSettings: {},
                initialValues: {},
                validationSchema: null,
            },
        },
    );

    useEffect(() => {
        if(settings){
            const optionsForRegistration = [];
            for(const key in settings?.fee_for_registration?.value){
                if(Object.hasOwnProperty.call(settings.fee_for_registration.value, key)){
                    const val = settings.fee_for_registration.value[key];
                    optionsForRegistration.push({
                        value: key.toUpperCase(),
                        label: val.i18n.en.name,
                        i18n: val.i18n,
                    })
                }
            }
            setSelectForRegistration(optionsForRegistration)

            const optionsForClinic = [];
            for(const key in settings?.fee_for_clinic?.value){
                if(Object.hasOwnProperty.call(settings.fee_for_clinic.value, key)){
                    const val = settings.fee_for_clinic.value[key];
                    optionsForClinic.push({
                        value: key.toUpperCase(),
                        label: val.i18n.en.name,
                        i18n: val.i18n,
                    })
                }
            }
            setSelectForClinic(optionsForClinic)
        }
    }, [ !!settings ])

    return (
        <Formik
            initialValues={{
                fee_for_registration: data.orgSettings?.fee_for_registration?.value ? data.orgSettings.fee_for_registration.value.toUpperCase() : '',
                fee_for_clinic: data.orgSettings?.fee_for_clinic?.value ? data.orgSettings.fee_for_clinic.value.toUpperCase() : '',
            }}
            validationSchema={object().shape({
                fee_for_registration: string(),
                fee_for_clinic: string(),
            })}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                return updateSettings(organisation_id, values)
                    .then(async() => {
                        await mutate()
                        toggleEditMode(false)
                        success();
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message);
                            fail(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <Row form>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for='fee_for_registration' className='text-muted'><DisplayI18n field='name' defaultValue={settings?.fee_for_registration?.name} i18n={settings?.fee_for_registration?.i18n} /></Label>
                                    <FormikSelect
                                        name='fee_for_registration'
                                        id='fee_for_registration'
                                        renderOption={({ option }) => <div className="text-uppercase"><DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} /></div>}
                                        options={selectForRegistration}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm="6">
                                <FormGroup>
                                    <Label for='fee_for_clinic' className='text-muted'><DisplayI18n field='name' defaultValue={settings?.fee_for_clinic?.name} i18n={settings?.fee_for_clinic?.i18n} /></Label>
                                    <FormikSelect
                                        name='fee_for_clinic'
                                        id='fee_for_clinic'
                                        renderOption={({ option }) => <div className="text-uppercase"><DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} /></div>}
                                        options={selectForClinic}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <CardFooter className='bg-white text-right'>
                            <Button color='primary' type='submit' disabled={formik.isSubmitting}>
                                <Translate id='misc.save' />
                            </Button>
                            <Button color='primary' type='button' outline className='ml-2' onClick={toggleEditMode} disabled={formik.isSubmitting}>
                                <Translate id='misc.cancel' />
                            </Button>
                        </CardFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default ReginalSettingsEdit;