import Translate from "@spordle/intl-elements";
import { useContext, useState } from 'react';
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import BtnDisplay from "../../../../../../components/input/BtnDisplay";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";
import { GameIncidentsContext } from "../../../../../../contexts/GameIncidentsContext";
import GameIncidentAddIncident from './components/GameIncidentAddIncident';
import GameIncidentCard from "./components/GameIncidentCard";
import { fail } from "@spordle/toasts";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";

const GameIncidentIncidentsTab = ({ gameIncident, handleCatch, setIsLoading, tableRef, syncNewVal, ...props }) => {
    const { partiallyUpdateGameIncidentPlayer, deleteGameIncidentPlayerIncident } = useContext(GameIncidentsContext);
    const [ modalIsOpen, setModalIsOpen ] = useState(false);

    const updatePlayerValues = (playerId, values, tableValues) => {
        setIsLoading(true);
        const updatedPlayersList = gameIncident.game_incident_players.map((p) => {
            if(p.game_incident_player_id === playerId){
                return { ...p, ...(tableValues || values) };
            }

            return p;
        });

        partiallyUpdateGameIncidentPlayer(gameIncident.game_incident_id, playerId, values)
            .then(() => {
                syncNewVal({ game_incident_players: updatedPlayersList })
            })
            .catch(handleCatch)

    }

    const handleRefreshTable = (rowValues = {}) => {
        tableRef.refreshTable();
        syncNewVal(rowValues);
    }

    return (
        <div className="mb-5 pb-5">
            <div className="font-bold h5"><Translate id='gameIncident.addModal.steps.incident.title' /></div>
            <GameIncidentAddIncident
                suspensions={props.suspensions}
                suspensionTypes={props.suspensionTypes}
                {...gameIncident}
                isOpen={modalIsOpen}
                refreshTable={handleRefreshTable}
                toggle={() => setModalIsOpen(false)}
            />
            <CanDoAction componentCode="organization" componentPermissionCode="game_incidents" action="ADD">
                <BtnDisplay
                    icon="tasks"
                    title={<Translate id='gameIncident.addModal.incident.title' />}
                    subtitle={<Translate id='gameIncident.addModal.incident.btn.subtitle' />}
                    onClick={() => setModalIsOpen(true)}
                />
            </CanDoAction>
            {gameIncident.game_incident_players.map((incident, index) => (
                <GameIncidentCard
                    updateValues={updatePlayerValues}
                    index={index}
                    incident={incident}
                    handleDelete={() => {
                        setIsLoading(true);
                        deleteGameIncidentPlayerIncident(gameIncident.game_incident_id, incident.game_incident_player_id)
                            .then(() => {
                                syncNewVal({ game_incident_players: gameIncident.game_incident_players.filter((p) => p.game_incident_player_id !== incident.game_incident_player_id) })
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                    setIsLoading(false);
                                }
                            })
                    }}
                    key={gameIncident.game_incident_id + incident.game_incident_player_id}
                    {...props}
                />
            ))}
        </div>
    );
}

export default GameIncidentIncidentsTab;