import { Tooltip } from "@mantine/core";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { BreadcrumbItem, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { PeriodsContext, AppContext } from "../../../../contexts/contexts";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import styles from "./BreadcrumbPeriodSelect.module.scss";

const BreadcrumbPeriodSelect = (props) => {
    const appContext = useContext(AppContext);
    const periodContext = useContext(PeriodsContext);

    const handleOnPeriodSelect = (period) => {
        return () => {
            periodContext.setSelectedPeriod(period, () => {
                if(props.onPeriodSelected)
                    props.onPeriodSelected(period);
                else
                    appContext.updateRouteKey();
            });
        }
    }

    return (
        <>
            {periodContext.periods &&
                <BreadcrumbItem>
                    <UncontrolledDropdown inNavbar>
                        <DropdownToggle id="main-period-select" tag='div' className={!props.disablePeriodSelect ? "clickable" : ""} disabled={props.disablePeriodSelect}>
                            <Tooltip
                                zIndex={3000}
                                disabled={props.disablePeriodSelect}
                                withArrow
                                label={<Translate id='header.breadcrumbs.changePeriod.tip' />}
                            >
                                <div className={stringBuilder(styles.btn, { [styles.disabled]: props.disablePeriodSelect })}>
                                    <i className="mdi mdi-calendar mr-1" />
                                    {periodContext.selectedPeriod ?
                                        <DisplayI18n
                                            field="name"
                                            defaultValue={periodContext.selectedPeriod?.name || <Translate id="misc.undefined" />}
                                            i18n={periodContext.selectedPeriod?.i18n}
                                        />
                                        :
                                        <Translate id="misc.select.none" />
                                    }
                                    {!props.disablePeriodSelect && <i className="ml-1 mdi mdi-chevron-down" />}
                                </div>
                            </Tooltip>
                        </DropdownToggle>
                        <DropdownMenu className="shadow-sm overflow-auto pretty-scrollbar" style={{ maxHeight: 400 }}>
                            {periodContext.periods.map((period) => {
                                if(period.active == 1){
                                    return (
                                        <DropdownItem
                                            key={period.period_id}
                                            onClick={handleOnPeriodSelect(period)}
                                            disabled={period.period_id === periodContext.selectedPeriod?.period_id}
                                        >
                                            <div className={period.period_id === periodContext.selectedPeriod?.period_id ? 'text-primary' : undefined}>
                                                {period.period_id === periodContext.selectedPeriod?.period_id && <i className="mdi mdi-check text-primary mr-1" />}
                                                <DisplayI18n
                                                    field="name"
                                                    defaultValue={period.name}
                                                    i18n={period.i18n}
                                                />
                                            </div>
                                            {!!period.current && <div className="text-muted small"><Translate id='misc.current' /></div>}
                                        </DropdownItem>
                                    )
                                }

                                return null;
                            })}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </BreadcrumbItem>
            }
        </>
    )
}

export default BreadcrumbPeriodSelect;