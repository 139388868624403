import { useEffect, useContext } from 'react';
import { AppContext } from '../../../contexts/contexts';
import { useNavigationTour } from "./useNavigationTour";

const NewNavigationTour = () => {
    const { getFrontEndParams } = useContext(AppContext);
    const hasSeenNewNavTour = getFrontEndParams("tours").navigation == 0;
    if(hasSeenNewNavTour)return null;

    const [ startTour ] = useNavigationTour();
    useEffect(() => {
        if(!hasSeenNewNavTour)startTour();
    }, []);

    return null;
}

export default NewNavigationTour;