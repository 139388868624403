import { useContext, useState } from "react";
import {
    Row,
    Col,
    Fade
} from "reactstrap";
import FormikEditable from '../../../../../components/formik/FormikEditable';
import { addExtension, DisplayPhoneNumber, FormikCheckedButton, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect } from '@spordle/formik-elements';
import { bool, number, object, string } from 'yup';
import PropTypes from 'prop-types';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';

// Context

// Language
import Translate from "@spordle/intl-elements";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import { ClinicsContext } from "../../../../../contexts/ClinicsContext";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { fail, success } from '@spordle/toasts';
import { HoverableCopy } from "../../../../../helpers/clipboardHelper";
import { Link } from "react-router-dom";
import InstructorsAddIdentity from "./InstructorsSection/InstructorsAddIdentity";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { RolesContext } from "../../../../../contexts/RolesContext";
import InlineCopy from "../../../../../components/inlineCopy/InlineCopy";

const ContactsInstructorsSidepanel = (props) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const clinicsContext = useContext(ClinicsContext);
    const rolesContext = useContext(RolesContext);

    const canEdit = rolesContext.canDoAction('EDIT', 'clinics', 'clinics_manage')

    const idKey = props.isContact ? 'clinicContactId' : 'clinicInstructorId'

    const assignValueToCompare = (newValues) => {
        return {
            ...newValues,
            compare: newValues.firstName + '-' + newValues.lastName + '-' + newValues.email + '-' + newValues.phone,
        }
    }

    const alreadyExists = (newValues) => {
        return props.tableRef.getData().find((row) => {
            const rowCompare = row.firstName + '-' + row.lastName + '-' + row.email + '-' + row.phone
            return rowCompare === newValues.compare && row[idKey] !== props.getCommonValue(idKey)
        })
    }

    const deleteCall = () => {
        if(props.isContact){
            return clinicsContext.deleteContact(props.clinicId, props.getCommonValue(idKey))
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }
        return clinicsContext.deleteInstructor(props.clinicId, props.getCommonValue(idKey))

    }

    const updateCall = (values) => {
        if(props.isContact){
            return clinicsContext.updateContact(props.clinicId, props.getCommonValue(idKey), values)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }
        return clinicsContext.updateInstructor(props.clinicId, props.getCommonValue(idKey), values)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })

    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <SidePanel.Header>
                <div className='d-flex mb-2'>
                    <SidePanel.ToggleButton />
                    <SidePanel.ActionsMenu action='DELETE' componentCode='clinics' componentPermissionCode='clinics_manage'>
                        <ConfirmModal
                            toggler={(toggle) => (
                                <SidePanel.MenuAction onClick={toggle} disabled={props.disableDelete}>
                                    <Translate id='clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.actions.remove' />
                                </SidePanel.MenuAction>
                            )}
                            modalTitle={<Translate id={props.isContact ? 'clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.actions.remove.text.contact' : 'clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.actions.remove.text.instructor'} />}
                            actionButtonText='misc.confirm'
                            translateActionButtonText
                            color='danger'
                            onConfirm={async() => {
                                return deleteCall()
                                    .then(() => {
                                        success({ msg: props.isContact ? 'clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.actions.remove.success.contact' : 'clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.actions.remove.success.instructor' });
                                        props.tableRef.deleteRow(props.getCommonValue(idKey)).then(() => {
                                            props.setNumberOfContacts?.(props.tableRef.getData().filter((row) => row.clinicContactId !== 'template').length)
                                            props.forceCloseSidePanel();
                                            props.onUpdate?.();
                                        })
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })
                            }}
                        />
                    </SidePanel.ActionsMenu>
                </div>
                <SidePanel.Title>
                    {props.getCommonValue('firstName')} {props.getCommonValue('lastName')}
                </SidePanel.Title>
                {!!props.selectedRows[0].member &&
                    <SidePanel.Subtitle>
                        <InlineCopy toCopy={props.selectedRows[0].member.unique_identifier}>
                            <Link className="text-nowrap" to={`/members/profile/${props.selectedRows[0].member.member_id}`}>
                                {props.selectedRows[0].member.unique_identifier ?
                                    <>#{props.selectedRows[0].member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                    : '-'}
                            </Link>
                        </InlineCopy>
                    </SidePanel.Subtitle>
                }
            </SidePanel.Header>

            {/* CONTACT/INSTRUCTOR INFO */}
            <SidePanel.Body>
                <div className="h4 font-bold mb-3"><Translate id={props.isContact ? 'clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.title.contact' : 'clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.title.instructor'} /></div>

                <Row className='mb-3'>
                    <Col md='6'>
                        <div className='text-muted'><Translate id='form.fields.firstName' /></div>
                        <FormikEditable
                            disabled={!canEdit}
                            id='formikEditableFirstName'
                            initialValues={{
                                firstName: props.getCommonValue('firstName'),
                            }}
                            validationSchema={object().shape({
                                firstName: string().required(<Translate id='form.validation.firstName.required' />).test({
                                    name: 'firstName-compare-test',
                                    message: <Translate id={props.isContact ? 'clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.update.contacts' : 'clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.update.instructors'} />,
                                    test: (firstName) => {
                                        if(alreadyExists(assignValueToCompare(props.createNewValues({ firstName: firstName })))){
                                            return false
                                        }
                                        return true
                                    },
                                }),
                            })}
                            onSubmit={(values) => {
                                if(values.firstName !== props.getCommonValue('firstName')){
                                    setIsLoading(true)
                                    const newValues = props.createNewValues({ firstName: values.firstName })
                                    updateCall(newValues)
                                        .then(() => {
                                            setIsLoading(false)
                                            success();
                                            props.syncRows(newValues)
                                            props.onUpdate?.();
                                        })
                                        .catch((e) => {
                                            if(!AxiosIsCancelled(e.message)){
                                                setIsLoading(false)
                                                fail();
                                                console.error(e.message)
                                            }
                                        })
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        props.getCommonValue('firstName')
                                    )
                                }
                                return (
                                    <FormikInputText id='firstName' name='firstName' trim />
                                )

                            }}
                        </FormikEditable>
                    </Col>
                    <Col md='6'>
                        <div className='text-muted'><Translate id='form.fields.lastName' /></div>
                        <FormikEditable
                            disabled={!canEdit}
                            id='formikEditableLastName'
                            initialValues={{
                                lastName: props.getCommonValue('lastName'),
                            }}
                            validationSchema={object().shape({
                                lastName: string().required(<Translate id='form.validation.lastName.required' />).test({
                                    name: 'lastName-compare-test',
                                    message: <Translate id={props.isContact ? 'clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.update.contacts' : 'clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.update.instructors'} />,
                                    test: (lastName) => {
                                        if(alreadyExists(assignValueToCompare(props.createNewValues({ lastName: lastName })))){
                                            return false
                                        }
                                        return true
                                    },
                                }),
                            })}
                            onSubmit={(values) => {
                                if(values.lastName !== props.getCommonValue('lastName')){
                                    setIsLoading(true)
                                    const newValues = props.createNewValues({ lastName: values.lastName })
                                    updateCall(newValues)
                                        .then(() => {
                                            setIsLoading(false)
                                            success();
                                            props.syncRows(newValues)
                                            props.onUpdate?.();
                                        })
                                        .catch((e) => {
                                            if(!AxiosIsCancelled(e.message)){
                                                setIsLoading(false)
                                                fail();
                                                console.error(e.message)
                                            }
                                        })
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        props.getCommonValue('lastName')
                                    )
                                }
                                return (
                                    <FormikInputText id='lastName' name='lastName' trim />
                                )

                            }}
                        </FormikEditable>
                    </Col>
                </Row>

                <div className='mb-3'>
                    <div className='text-muted'><Translate id='form.fields.email' /></div>
                    <FormikEditable
                        disabled={!canEdit}
                        id='formikEditableEmail'
                        initialValues={{
                            email: props.getCommonValue('email'),
                        }}
                        validationSchema={object().shape({
                            email: string().required(<Translate id='form.validation.email.required' />).email(<Translate id='form.validation.email.valid' />).test({
                                name: 'email-compare-test',
                                message: <Translate id={props.isContact ? 'clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.update.contacts' : 'clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.update.instructors'} />,
                                test: (email) => {
                                    if(alreadyExists(assignValueToCompare(props.createNewValues({ email: email })))){
                                        return false
                                    }
                                    return true
                                },
                            }),
                        })}
                        onSubmit={(values) => {
                            if(values.email !== props.getCommonValue('email')){
                                setIsLoading(true)
                                const newValues = props.createNewValues({ email: values.email })
                                updateCall(newValues)
                                    .then(() => {
                                        setIsLoading(false)
                                        success();
                                        props.syncRows(newValues)
                                        props.onUpdate?.();
                                    })
                                    .catch((e) => {
                                        if(!AxiosIsCancelled(e.message)){
                                            setIsLoading(false)
                                            fail();
                                            console.error(e.message)
                                        }
                                    })
                            }
                        }}
                    >
                        {(isEditing) => {
                            if(!isEditing){
                                return (
                                    props.getCommonValue('email')
                                )
                            }
                            return (
                                <FormikInputText id='email' name='email' trim />
                            )

                        }}
                    </FormikEditable>
                </div>

                <div className='mb-3'>
                    <div className='text-muted'><Translate id='form.fields.phone' /></div>
                    <FormikEditable
                        disabled={!canEdit}
                        id='formikEditablePhone'
                        initialValues={{
                            phone: props.getCommonValue('phone')?.split('#')?.[0] || '',
                            extension: props.getCommonValue('phone')?.split('#')?.[1] || '',
                        }}
                        validationSchema={object().shape({
                            phone: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />)
                                .test({
                                    name: 'phone-compare-test',
                                    message: <Translate id={props.isContact ? 'clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.update.contacts' : 'clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.update.instructors'} />,
                                    test: async function(phone){
                                        const newPhone = await addExtension(phone, this.parent.extension)
                                        if(alreadyExists(assignValueToCompare(props.createNewValues({ phone: newPhone })))){
                                            return false
                                        }
                                        return true
                                    },
                                }),
                            extension: number(),
                        })}
                        onSubmit={async(values) => {
                            if(values.phone !== props.getCommonValue('phone')?.split('#')?.[0] || '' || values.extension !== props.getCommonValue('phone')?.split('#')?.[1] || ''){
                                setIsLoading(true)
                                const newPhone = await addExtension(values.phone, values.extension)
                                const newValues = props.createNewValues({ phone: newPhone })
                                updateCall(newValues)
                                    .then(() => {
                                        setIsLoading(false)
                                        success();
                                        props.syncRows(newValues)
                                        props.onUpdate?.();
                                    })
                                    .catch((e) => {
                                        if(!AxiosIsCancelled(e.message)){
                                            setIsLoading(false)
                                            fail();
                                            console.error(e.message)
                                        }
                                    })
                            }
                        }}
                    >
                        {(isEditing, options, formik) => {
                            if(!isEditing){
                                return (
                                    <DisplayPhoneNumber phoneString={props.getCommonValue('phone')} withExtension format='INTERNATIONAL' emptyValue='-' />
                                )
                            }
                            return (
                                <div className='d-flex'>
                                    <div className='flex-grow-1'>
                                        <FormikPhoneInput
                                            id='phone'
                                            name='phone'
                                        />
                                    </div>
                                    <div className='flex-grow-0'>
                                        <FormikInputNumber id='extension' name='extension' trim />
                                    </div>
                                </div>
                            )

                        }}
                    </FormikEditable>
                </div>

                <div className='mb-3'>
                    <div className='text-muted'><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.showStatus' /></div>
                    <FormikEditable
                        disabled={!canEdit}
                        id='formikEditableShowStatus'
                        noConfirmation
                        initialValues={{
                            showStatus: props.getCommonValue('showStatus'),
                        }}
                        validationSchema={object().shape({
                            showStatus: string(),
                        })}
                        onSubmit={(values) => {
                            if(values.showStatus !== props.getCommonValue('showStatus')){
                                setIsLoading(true)
                                const newValues = props.createNewValues({ showStatus: values.showStatus })
                                updateCall(newValues)
                                    .then(() => {
                                        setIsLoading(false)
                                        success();
                                        props.syncRows(newValues)
                                        props.onUpdate?.();
                                    })
                                    .catch((e) => {
                                        if(!AxiosIsCancelled(e.message)){
                                            setIsLoading(false)
                                            fail();
                                            console.error(e.message)
                                        }
                                    })
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (
                                    <Translate id={'clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.showStatus.' + props.getCommonValue('showStatus')} />
                                )
                            }
                            return (
                                <FormikSelect
                                    id='showStatus'
                                    name='showStatus'
                                    search={false}
                                    menuIsDefaultOpen autoFocus
                                    onOptionSelected={() => {
                                        options.stopEditing();
                                    }}
                                    loadingStatus='success'
                                    defaultData={[
                                        { value: 'SHOW', label: 'clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.showStatus.SHOW', translateLabel: true },
                                        { value: 'SHOW_PARTIAL', label: 'clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.showStatus.SHOW_PARTIAL', translateLabel: true },
                                        { value: 'HIDE', label: 'clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.showStatus.HIDE', translateLabel: true },
                                    ]}
                                />
                            )

                        }}
                    </FormikEditable>
                </div>

                {props.isContact &&
                    <div className='mb-3'>
                        <div className='text-muted'><Translate id='clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.settings' /></div>
                        <FormikEditable
                            disabled={!canEdit}
                            id='formikEditableNotify'
                            initialValues={{
                                notify: props.getCommonValue('notify'),
                            }}
                            validationSchema={object().shape({
                                notify: bool(),
                            })}
                            onSubmit={(values) => {
                                if(values.notify !== props.getCommonValue('notify')){
                                    setIsLoading(true)
                                    const newValues = props.createNewValues({ notify: values.notify })
                                    updateCall(newValues)
                                        .then(() => {
                                            setIsLoading(false)
                                            success();
                                            props.syncRows(newValues)
                                            props.onUpdate?.();
                                        })
                                        .catch((e) => {
                                            if(!AxiosIsCancelled(e.message)){
                                                setIsLoading(false)
                                                fail();
                                                console.error(e.message)
                                            }
                                        })
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    if(props.getCommonValue('notify')){
                                        return (
                                            <>
                                                <i className='mdi mdi-check text-primary mr-2' /> <Translate id='clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.notify' />
                                            </>
                                        )
                                    }
                                    return (
                                        <>
                                            <i className='mdi mdi-close text-danger mr-2' /> <Translate id='clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.notify' />
                                        </>
                                    )

                                }
                                return (
                                    <FormikCheckedButton id='notify' name='notify' label='clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.notify' translateLabel defaultChecked={props.getCommonValue('notify')} />
                                )

                            }}
                        </FormikEditable>
                    </div>
                }
            </SidePanel.Body>
            {!props.isContact && !!props.selectedRows[0].member &&
                <SidePanel.Body className="border-top">
                    <div className="font-bold h4"><Translate id='clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.accessInfo' /></div>
                    {props.getCommonValue('clinicPermission') &&
                        <div className="bg-light mb-3 p-3 rounded position-relative">
                            <div className="d-flex">
                                <div className="text-dark flex-grow-1">
                                    <Translate id={`clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.sidepanel.desc.${props.getCommonValue('clinicPermission')}`} values={{ bold: (word) => <b>{word}</b> }} />
                                </div>
                                <div className="position-relative ml-2 p-1 flex-shrink-0">
                                    <div className="border bg-white rounded-circle d-flex justify-content-center align-items-center" style={{ width: "38px", height: "38px" }}>
                                        <Fade className="d-flex align-items-center position-absolute" in={props.getCommonValue('clinicPermission') === "NO_ACCESS"}>
                                            <i className="mdi mdi-eye-off text-primary font-20" />
                                        </Fade>
                                        <Fade className="d-flex align-items-center position-absolute" in={props.getCommonValue('clinicPermission') === "READ_ONLY"}>
                                            <i className="mdi mdi-eye text-primary font-20" />
                                        </Fade>
                                        <Fade className="d-flex align-items-center position-absolute" in={props.getCommonValue('clinicPermission') === "READ_WRITE"}>
                                            <i className="mdi mdi-account-edit text-primary font-20" />
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                            {/* This block should only be shown when a member is linked to the instructor */}
                            {/* Then we propose to give access (link account) if there is no linked identity (the formik editabled should be disabled if no identity is linked) */}
                            {!props.selectedRows[0].hasIdentity &&
                                <InstructorsAddIdentity
                                    forceCloseSidePanel={props.forceCloseSidePanel}
                                    tableRef={props.tableRef}
                                    instructor={props.selectedRows[0]}
                                />
                            }
                        </div>
                    }
                    <div className="mb-3">
                        <div className='text-muted'><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission' /></div>
                        <FormikEditable
                            id='formikEditablePermissions'
                            noConfirmation
                            disabled={!props.selectedRows[0].hasIdentity}
                            initialValues={{
                                clinic_permission: props.getCommonValue('clinicPermission') || '',
                            }}
                            validationSchema={object().shape({
                            })}
                            onSubmit={async(values) => {
                                if(values.clinic_permission !== props.getCommonValue('clinicPermission')){
                                    setIsLoading(true);
                                    const newValues = props.createNewValues({ clinicPermission: values.clinic_permission });
                                    updateCall(newValues)
                                        .then(() => {
                                            setIsLoading(false)
                                            success();
                                            props.syncRows(newValues)
                                            props.onUpdate?.();
                                        })
                                        .catch((e) => {
                                            if(!AxiosIsCancelled(e.message)){
                                                setIsLoading(false)
                                                fail({ info: <DisplayI18n field="message" defaultValue={e[0].message} i18n={e[0].i18n} />, skipInfoTranslate: true });
                                                console.error(e.message)
                                            }
                                        })
                                }
                            }}
                        >
                            {(isEditing, options) => {
                                if(!isEditing){
                                    return props.getCommonValue('clinicPermission') !== '-' ?
                                        <Translate id={`clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission.${props.getCommonValue('clinicPermission')}`} />
                                        : '-'
                                }
                                return (
                                    <FormikSelect
                                        id='clinic_permission' name='clinic_permission'
                                        search={false}
                                        onOptionSelected={options.stopEditing}
                                        autoFocus menuIsDefaultOpen
                                        defaultData={[
                                            { label: 'clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission.NO_ACCESS', value: 'NO_ACCESS', translateLabel: true },
                                            { label: 'clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission.READ_ONLY', value: 'READ_ONLY', translateLabel: true },
                                            { label: 'clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission.READ_WRITE', value: 'READ_WRITE', translateLabel: true },
                                        ]}
                                        loadingStatus='success'
                                    />
                                )
                            }}
                        </FormikEditable>
                    </div>
                    <UserDisplay card block className="mb-3">
                        <UserDisplay.Container>
                            <UserDisplay.Subtitle className="text-dark text-uppercase">
                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.sidepanel.member' />
                            </UserDisplay.Subtitle>
                            <UserDisplay.Title className="font-bold">{props.selectedRows[0].member.first_name} {props.selectedRows[0].member.last_name}</UserDisplay.Title>
                            <UserDisplay.Subtitle>
                                <HoverableCopy toCopy={props.selectedRows[0].member.unique_identifier}>
                                    <Link to={`/members/profile/${props.selectedRows[0].member.member_id}`}>
                                        #{props.selectedRows[0].member.unique_identifier} <i className="mdi mdi-chevron-right" />
                                    </Link>
                                </HoverableCopy>
                            </UserDisplay.Subtitle>
                        </UserDisplay.Container>
                    </UserDisplay>
                    {!!props.selectedRows[0].identity &&
                        <>
                            <UserDisplay card block className="mb-3">
                                <UserDisplay.Container>
                                    <UserDisplay.Subtitle className="text-dark text-uppercase">
                                        <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.sidepanel.spordleMyAccount' />
                                    </UserDisplay.Subtitle>
                                    <UserDisplay.Title className="font-bold">{props.selectedRows[0].identity.name} {props.selectedRows[0].identity.family_name}</UserDisplay.Title>
                                    <UserDisplay.Subtitle>
                                        <HoverableCopy toCopy={props.selectedRows[0].email}>
                                            <i className="mdi mdi-email-outline mr-1" />{props.selectedRows[0].email}
                                        </HoverableCopy>
                                    </UserDisplay.Subtitle>
                                </UserDisplay.Container>
                            </UserDisplay>
                        </>
                    }
                </SidePanel.Body>
            }
        </OverlayLoader>
    )
}

ContactsInstructorsSidepanel.propTypes = {
    isContact: PropTypes.bool.isRequired,
}

export default ContactsInstructorsSidepanel