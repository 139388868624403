import Translate from '@spordle/intl-elements';
import React, { useContext, useEffect, useState } from 'react';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { getLocation } from '../../TeamHelpers';
import { TeamsContext } from '../../../../contexts/TeamsContext';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import { fail } from "@spordle/toasts";

const TeamSearchSidepanelContact = ({ team }) => {
    const { getOrganization } = useContext(OrganizationContext);
    const { getTeamContacts } = useContext(TeamsContext);
    const [ contacts, setContacts ] = useState({});
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        Promise.all([
            getTeamContacts(team.team_id),
            getOrganization(team.organisation.organisation_id),
        ])
            .then(([ teamContacts, orgContact ]) => {
                setContacts({ teamContact: teamContacts, orgContact: orgContact });
                setIsLoading(false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setContacts({ teamContact: [], orgContact: [] });
                    setIsLoading(false);
                }
            })
    }, []);

    return (
        <OverlayLoader isLoading={isLoading}>
            <div className="h5 font-bold mb-3">
                <Translate id='teams.teamSearch.sideTable.label.teamContact' />
            </div>
            <div className="">
                { contacts.teamContact &&
                    contacts.teamContact.length > 0 ?
                    contacts.teamContact.map((contact) => (
                        <React.Fragment key={contact.team_contact_id_public}>
                            <UserDisplay card className="mb-3 w-100">
                                <UserDisplay.Container>
                                    <UserImg
                                        abbr={contact.first_name.charAt(0) + contact.last_name.charAt(0)}
                                        src={contact.logo?.full_path}
                                        filePos={contact.logo?.file_position}
                                        width={40}
                                        alt={contact.first_name + ' ' + contact.last_name}
                                    />
                                </UserDisplay.Container>
                                <UserDisplay.Container>
                                    <UserDisplay.Title>{contact.first_name} {contact.last_name}</UserDisplay.Title>
                                    {contact.phone &&
                                        <UserDisplay.Subtitle>
                                            <span className="text-dark font-medium"><i className="mdi mdi-phone" /> : </span>
                                            <a href={"tel:" + contact.phone}>
                                                {contact.phone}
                                            </a>
                                        </UserDisplay.Subtitle>
                                    }
                                    {contact.email &&
                                        <UserDisplay.Subtitle>
                                            <span className="text-dark font-medium"><i className="mdi mdi-email" /> : </span>
                                            <a href={"mailto:" + contact.email}>
                                                {contact.email}
                                            </a>
                                        </UserDisplay.Subtitle>
                                    }
                                </UserDisplay.Container>
                            </UserDisplay>
                        </React.Fragment>
                    ))
                    :
                    !isLoading && <div><Translate id='teams.teamSearch.sideTable.noTeamsContact' /></div>
                }
            </div>
            <div className="h5 font-bold mb-3 mt-2"><Translate id='teams.teamSearch.sideTable.label.orgContact' /></div>
            { contacts.orgContact &&
                <UserDisplay card className="mb-3 w-100">
                    <UserDisplay.Container>
                        <UserImg
                            abbr={contacts.orgContact.abbreviation}
                            src={contacts.orgContact.logo?.full_path}
                            filePos={contacts.orgContact.logo?.file_position}
                            width={40}
                            alt={contacts.orgContact.organisation_name}
                        />
                    </UserDisplay.Container>
                    <UserDisplay.Container>
                        <UserDisplay.Title>
                            <DisplayI18n
                                field="name"
                                i18n={contacts.orgContact.i18n}
                                defaultValue={contacts.orgContact.organisation_name}
                            />
                        </UserDisplay.Title>
                        { contacts.orgContact.phone &&
                            <UserDisplay.Subtitle>
                                <span className="text-dark font-medium"><i className="mdi mdi-phone" /> : </span>
                                <a href={"tel:" + contacts.orgContact.phone}>
                                    {contacts.orgContact.phone}
                                </a>
                            </UserDisplay.Subtitle>
                        }
                        { contacts.orgContact.organisation_email &&
                            <UserDisplay.Subtitle>
                                <span className="text-dark font-medium"><i className="mdi mdi-email" /> : </span>
                                <a href={"mailto:" + contacts.orgContact.organisation_email}>
                                    {contacts.orgContact.organisation_email}
                                </a>
                            </UserDisplay.Subtitle>
                        }
                        { contacts.orgContact.organisation_email &&
                            <UserDisplay.Subtitle>
                                <span className="text-dark font-medium"><i className="mdi mdi-map-marker" /> : </span>
                                <a href={contacts.orgContact.map_url} target='_blank' rel='noopener noreferrer'>
                                    {getLocation(contacts.orgContact)}
                                    {contacts.orgContact.unit_number && <div className="small">{contacts.orgContact.unit_number}</div>}
                                </a>
                            </UserDisplay.Subtitle>
                        }
                    </UserDisplay.Container>
                </UserDisplay>
            }
        </OverlayLoader>
    );
}

export default TeamSearchSidepanelContact;