export default{
    "catalog.waivers.title"             : "Dispenses",
    "catalog.waivers.count"             : "{count, plural, =0 {# dispense} =1 {# dispense} other {# dispenses}}",
    "catalog.waivers.creation"          : "Création",
    "catalog.waivers.edit"              : "Modification",
    "catalog.waivers.back"              : "Retour à la liste",
    "catalog.waivers.mandatory.tooltip" : "Une réponse doit être fournie pour la dispense au moment de l’inscription.",
    "catalog.waivers.critical.tooltip"  : "Une réponse doit être fournie pour être ajouté sur une équipe.",
    "catalog.waivers.add.title"         : "Ajouter une dispense",
    "catalog.waivers.add.confirmLabel"  : "Option de confirmation ({lang})",
    "catalog.waivers.add.declineLabel"  : "Option de refus ({lang})",
    "catalog.waivers.edit.preview"      : "Aperçu",

    "catalog.waivers.list.table.empty.title" : "Aucune dispense",
}