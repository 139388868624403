export default{
    // general
    "members.profile.appeals.title" : "Appeals",

    "members.profile.appeals.date"     : "Date",
    "members.profile.appeals.from"     : "From",
    "members.profile.appeals.to"       : "To",
    "members.profile.appeals.type"     : "Type",
    "members.profile.appeals.typeNote" : "Type note",
    "members.profile.appeals.status"   : "Status",
    "members.profile.appeals.approved" : "Approved",

    "members.profile.appeals.period"                     : "From season",
    "members.profile.appeals.division"                   : "Division",
    "members.profile.appeals.class"                      : "Class",
    "members.profile.appeals.category"                   : "Category",
    "members.profile.appeals.category.required"          : "Category is required",
    "members.profile.appeals.submittedDate"              : "Submitted date",
    "members.profile.appeals.submittedDate.required"     : "Submitted date is required",
    "members.profile.appeals.hearingDate"                : "Hearing date",
    "members.profile.appeals.hearingDate.afterSubmitted" : "Hearing date must be after submitted date",
    "members.profile.appeals.hearingDate.beforeSent"     : "Hearing date must be before or same as sent date",
    "members.profile.appeals.sentDate"                   : "Letter sent date",
    "members.profile.appeals.sentDate.afterHearing"      : "Sent date must be after or same as hearing date",
    "members.profile.appeals.regulation"                 : "Regulation",
    "members.profile.appeals.regulation.required"        : "Regulation is required",

    "members.profile.appeals.note"                : "Notes",
    "members.profile.appeals.attachments"         : "Attachments",
    "members.profile.appeals.attachments.account" : "Account receivable attachments",

    "members.profile.appeals.paymentMethod"          : "Payment method",
    "members.profile.appeals.paymentMethod.required" : "Payment method is required",
    "members.profile.appeals.owes"                   : "Owes",
    "members.profile.appeals.owes.required"          : "Owed amount is required",
    "members.profile.appeals.paid"                   : "Paid",
    "members.profile.appeals.paid.required"          : "Paid amount is required",
    "members.profile.appeals.client"                 : "Client organization",
    "members.profile.appeals.client.required"        : "Client organization is required",
    "members.profile.appeals.invoice"                : "Invoice number",
    "members.profile.appeals.invoice.required"       : "Invoice number is required",
    "members.profile.appeals.accountReceivableNote"  : "Account receivable note",

    // table


    // sidepanel
    "members.profile.appeals.sidepanel.tabs.general"       : "General",
    "members.profile.appeals.sidepanel.tabs.account.short" : "Acc. Receivable",
    "members.profile.appeals.sidepanel.tabs.account"       : "Account Receivable",

    "members.profile.appeals.sidepanel.actions.delete.text" : "Are you sure you want to delete this appeal?",

    // add
    "members.profile.appeals.sidepanel.add.title"                       : "Create Appeal",
    "members.profile.appeals.sidepanel.add.appealSelection.header"      : "Who is this appeal coming from",
    "members.profile.appeals.sidepanel.add.appealSelection.header.text" : "Please specify if the appeal is coming from the member's team",
    "members.profile.appeals.sidepanel.add.appealSelection.memberTeam"  : "Appeal coming from the member's team",
    "members.profile.appeals.sidepanel.add.appealSelection.otherTeam"   : "Appeal coming from another team",
    "members.profile.appeals.sidepanel.add.details"                     : "Details",
    "members.profile.appeals.sidepanel.add.organization"                : "Organization",
    "members.profile.appeals.sidepanel.add.organization.required"       : "The organization is required",
    "members.profile.appeals.sidepanel.add.organization.same"           : "The organizations must be different",
    "members.profile.appeals.sidepanel.add.teamName"                    : "Team name",
    "members.profile.appeals.sidepanel.add.teamName.manual"             : "Enter the team name",
    "members.profile.appeals.sidepanel.add.teamName.select"             : "Select a team",
    "members.profile.appeals.sidepanel.add.teamName.select.required"    : "Team is required",
    "members.profile.appeals.sidepanel.add.teamName.required"           : "Team name is required",
    "members.profile.appeals.sidepanel.add.period.required"             : "Season is required",
    "members.profile.appeals.sidepanel.add.status.required"             : "Status is required",
    "members.profile.appeals.sidepanel.add.approve.required"            : "Approved is required",
}