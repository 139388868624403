import { useState } from 'react';
import { ModalHeader } from "reactstrap";

import Step1 from './Step1';
import Step2 from './Step2';
import Translate from '@spordle/intl-elements';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';

const AddStaffModal = ({ toggle, types }) => {
    const [ step, setStep ] = useState(1);
    const [ info, setInfo ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    const setLoading = (bool) => setIsLoading(bool ?? !isLoading);
    const changeStep = (nb) => setStep(nb);
    const setTheInfo = (info) => setInfo((prev) => ({ ...prev, ...info }));

    const toggleModal = () => {
        toggle();
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalHeader toggle={toggleModal}><Translate id='organization.profile.staff.modals.addStaff.title' /> ({step}/2)</ModalHeader>
            { step === 1 &&
                <Step1 setLoading={setLoading} setInfo={setTheInfo} changeStep={changeStep} />
            }
            { step === 2 &&
                <Step2 toggle={toggleModal} setLoading={setLoading} info={info} types={types} changeStep={changeStep} />
            }
        </OverlayLoader>
    )
}

export default AddStaffModal;