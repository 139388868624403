import Translate from "@spordle/intl-elements";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import generatePassword from "../../../../helpers/passwordGenerator";
import { counterAnim } from "../../../../helpers/uiHelper";

const DisplayAttendeesCard = ({ selectedRows, state }) => {
    const id = generatePassword();

    useEffect(() => {
        document.getElementById(id).innerText = 0; // Reset interface count to 0
        counterAnim(id, 0, selectedRows[0].clinic_attendee_count || 0, 400) // start at current count and go to next number
    }, [ selectedRows[0].clinic_attendee_count ])

    return (
        <Card tag={Link} to={{ pathname: `/clinics/profile/${selectedRows[0]?.clinic_id}/attendees`, data: selectedRows[0] }} className="card-shadow card-hover text-center mb-0">
            <CardBody>
                {
                    selectedRows[0].max_attendee ? // attendee limit
                        <>
                            {!state.hostView &&
                                <div>
                                    <span id={id} className={`text-right small mt-n2 mr-n2`}>{0} / {selectedRows[0].max_attendee}</span>
                                </div>
                            }
                            <div className="h2 mb-0 font-medium">
                                {state.hostView ?
                                    <div>
                                        <span id={id}>{0}</span> / <span>{selectedRows[0].max_attendee}</span>
                                    </div>
                                    : // filter this to only display the attendees from current org
                                    <div id={id}>
                                        {0}
                                    </div>
                                }
                            </div>
                            <div className="small"><Translate id='clinics.search.sidePanelClinicSearch.details.registered' /></div>
                        </>
                        : // no attendee limit
                        <>
                            {!state.hostView &&
                                <div id={id} className="text-right small mt-n2 mr-n21">
                                    {0}
                                </div>
                            }
                            <div className="h2 mb-0 font-medium">
                                {state.hostView ?
                                    <div id={id}>
                                        {0}
                                    </div>
                                    :
                                    <div id={id}>
                                        {/* filter this to only display the attendees from current org */}
                                        {0}
                                    </div>
                                }
                            </div>
                            <div className="small"><Translate id='clinics.search.sidePanelClinicSearch.details.registered' /></div>
                        </>
                }
            </CardBody>
        </Card>
    )
}

export default DisplayAttendeesCard;
