import { stringBuilder } from "@spordle/helpers";
import moment from "moment";
import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../../../components/loading/OverlayLoader";
import CanDoAction from "../../../../../../../components/permissions/CanDoAction";
import { fail, success } from '@spordle/toasts';
import UserDisplay from "../../../../../../../components/userDisplay/UserDisplay";
import { InjuriesContext } from "../../../../../../../contexts/InjuriesContext";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import { getLocation } from "../../../../../../teams/TeamHelpers";
import EditChequeModal from "./chequeModal/EditChequeModal";
import { ISSUED_CLAIMANT, ISSUED_GUARDIAN, ISSUED_OTHER } from "./ChequeHelper";
import { RolesContext } from "../../../../../../../contexts/RolesContext";
import { IdentityRolesContext } from "../../../../../../../contexts/IdentityRolesContext";

const InfoCheque = ({ injury, cheque: theCheque, printCheques, getCheques, deleteCheque, canPrint, canEdit }) => {
    const [ cheque, setCheque ] = useState(theCheque);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ editIsOpen, setEditIsOpen ] = useState(false);
    const [ infoIsOpen, setInfoIsOpen ] = useState(false);
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const { partiallyUpdateInsuranceCheque, voidInsuranceCheque } = useContext(InjuriesContext);
    const canDelete = canDoAction("DELETE", "members", "member_checks_generation") || isGod();
    const isVoid = !!cheque.voided_at;

    const updateChequeState = (values) => setCheque((prev) => ({ ...prev, ...values }));

    const handleCatch = (e) => {
        if(!AxiosIsCancelled(e.message)){
            console.error(e.message)
            fail({ info: <DisplayI18n field="message" defaultValue={e.message} i18n={e.i18n} />, skipInfoTranslate: true });
            setIsLoading(false);
        }
    }

    const partiallyUpdateCheque = async(values) => {
        return partiallyUpdateInsuranceCheque(
            injury.injury_id,
            cheque.injury_cheque_id,
            values,
        )
            .then(getCheques)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });
    }

    const handleOnPrint = (id) => {
        printCheques({ injury_cheque_id: [ id ] });
        if(!cheque.printed_at){
            updateChequeState({ printed_at: moment().format('YYYY-MM-DD') });
        }
    };

    const handleOnVoid = (id) => {
        setIsLoading(true);
        voidInsuranceCheque(injury.injury_id, id)
            .then(() => {
                setIsLoading(false);
                success();
                updateChequeState({ voided_at: moment().toISOString() })
            })
            .catch(handleCatch)
    }

    const handleOnDelete = (id) => {
        setIsLoading(true);
        deleteCheque(id)
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                    fail({ info: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />, skipInfoTranslate: true });
                    setIsLoading(false);
                }
            });
    }

    const getChequePayTo = (info) => {
        if(info.pay_to === ISSUED_GUARDIAN && info.member_contact?.first_name){

            return `${info.member_contact.first_name} ${info.member_contact.last_name}`

        }else if(info.pay_to === ISSUED_CLAIMANT && info.member?.first_name){

            return `${info.member.first_name} ${info.member.last_name}`

        }else if(info.pay_to === ISSUED_OTHER && (info.payee?.first_name || info.payee?.business_name)){

            if(info.payee.first_name){

                return (
                    <>
                        {info.payee.prefix &&
                            <Translate id={`settings.payees.prefix.${(info.payee.prefix).toUpperCase()}`} defaultMessage={info.payee.prefix} />
                        } {info.payee.first_name} {info.payee.last_name}{info.payee.business_name ? ` (${info.payee.business_name})` : ""}
                    </>
                )

            }else if(info.payee.business_name){

                return info.payee.business_name;

            }

        }

        return info.pay_to || '-';

    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <UserDisplay className="p-1 mb-3" card block>
                <EditChequeModal
                    isOpen={editIsOpen}
                    toggle={() => setEditIsOpen(false)}
                    cheque={cheque}
                    updateCheque={partiallyUpdateCheque}
                    isPrinted={!!cheque.printed_at}
                    injury={injury}
                />
                {isVoid &&
                    <svg
                        style={{ opacity: 0.1, transform: 'rotate(-10deg)' }}
                        width="100%"
                        className="left-0 right-0 position-absolute text-uppercase top-0"
                    >
                        <text
                            className="font-bold"
                            dominantBaseline="middle"
                            textAnchor="middle"
                            style={{
                                fontSize: 70,
                            }}
                            x="50%"
                            y="50%"
                        >
                            <Translate id='insurance.sidePanel.cheque.void' />
                        </text>
                    </svg>
                }
                <UserDisplay.Container className="align-items-center d-flex flex-wrap p-2 bg-light-inverse w-100 border rounded">
                    <div className="border-bottom mb-2 d-flex justify-content-between w-100 text-right pb-2">
                        <UserDisplay.Subtitle>
                            {cheque.cheque_date ?
                                <>
                                    <DateFormat value={cheque.cheque_date} utc />
                                    {!!cheque.printed_at &&
                                        <span className="ml-2 text-muted">(<Translate id='insurance.sidePanel.cheque.print.lastPrinted' />: <DateFormat value={cheque.printed_at} utc />)</span>
                                    }
                                </>
                                :
                                '-'
                            }
                        </UserDisplay.Subtitle>
                        {(canEdit || cheque.printed_at || canDelete) &&
                            <UncontrolledDropdown>
                                <DropdownToggle color="link" className="p-0 text-primary">
                                    <i className="line-height-1 mdi mdi-dots-horizontal" />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {(cheque.printed_at && canPrint) &&
                                        <DropdownItem onClick={() => handleOnPrint(cheque.injury_cheque_id)}>
                                            <Translate id='misc.print' />
                                        </DropdownItem>
                                    }
                                    {canEdit &&
                                        <DropdownItem onClick={() => setEditIsOpen(true)}>
                                            <Translate id="misc.edit" />
                                        </DropdownItem>
                                    }
                                    {!isVoid && canEdit &&
                                        <DropdownItem onClick={() => handleOnVoid(cheque.injury_cheque_id)}>
                                            <Translate id='insurance.sidePanel.cheque.toVoid' />
                                        </DropdownItem>
                                    }
                                    {!cheque.printed_at &&
                                        <CanDoAction componentCode="members" componentPermissionCode="member_checks_generation" action="DELETE">
                                            <DropdownItem divider />
                                            <DropdownItem onClick={() => handleOnDelete(cheque.injury_cheque_id)}>
                                                <Translate id='misc.delete' />
                                            </DropdownItem>
                                        </CanDoAction>
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        }
                    </div>
                    <div className="d-flex align-items-start w-100">
                        <i
                            style={{
                                width: 45,
                                height: 45,
                            }}
                            className="flex-shrink-0 d-flex flex-center text-muted font-18 bg-white d-block rounded-circle border fas fa-dollar-sign"
                        />
                        <div className="ml-2 pr-2">
                            <UserDisplay.Subtitle>
                                <DisplayI18n field="name" i18n={cheque.service_type?.i18n} defaultValue={cheque.service_type?.name} />
                            </UserDisplay.Subtitle>
                            <UserDisplay.Title>{getChequePayTo(cheque)}</UserDisplay.Title>
                            <UserDisplay.Subtitle>{getLocation({ province_code: cheque.province_id, ...cheque })}</UserDisplay.Subtitle>
                        </div>
                        <div className="ml-auto text-right min-w-25">

                            {/* This className is used to overwrite helper classes of formik editable */}
                            <UserDisplay.Subtitle className="d-flex justify-content-end">
                                N°{cheque.cheque_number || '-'}
                            </UserDisplay.Subtitle>

                            {/* This className is used to overwrite helper classes of formik editable */}
                            <UserDisplay.Title className="d-flex justify-content-end">
                                <CurrencyFormat value={cheque.amount / 100} />
                            </UserDisplay.Title>
                        </div>
                    </div>
                    <Collapse isOpen={infoIsOpen} className="w-100">
                        <div className="pt-2 mt-3 border-top">
                            <div className="text-muted small"><Translate id='transfer.createdBy' /></div>
                            <div className="mb-2">
                                {cheque.created_by.name + ' ' + cheque.created_by.family_name} <span className="text-muted">(<DateFormat value={cheque.created_at} utc />)</span>
                            </div>
                            {!!cheque.voided_at &&
                                <>
                                    <div className="text-muted small">
                                        <Translate id='insurance.sidePanel.cheque.voidedAt' />
                                    </div>
                                    <div className="mb-2">
                                        <DateFormat value={cheque.voided_at} utc />
                                    </div>
                                </>
                            }
                            <div className="text-muted small">Memo</div>
                            {cheque.memo || '-'}
                        </div>
                    </Collapse>
                    <div
                        onClick={() => setInfoIsOpen(!infoIsOpen)}
                        style={{ width: 25, height: 25, transform: 'translateY(50%)' }}
                        className="mx-auto clickable rounded-circle position-absolute bg-white border p-2 left-0 right-0 bottom-0 d-flex flex-center"
                    >
                        <i className={stringBuilder("transition d-inline-block mdi mdi-chevron-down", { "rotate-180": infoIsOpen })} />
                    </div>
                </UserDisplay.Container>
            </UserDisplay>
        </OverlayLoader>
    );
}

export default InfoCheque;