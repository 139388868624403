import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useState } from "react";
import { Button, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import CrossFade from "../../../../../components/crossFade/CrossFade";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import SearchMemberForm from "../../../../../components/searchMemberForm/SearchMemberForm";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../components/UserImg";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import AssignPermissions from "./AssignPermissions";

const AddOfficiatingCoachModal = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName="addOfficiatingCoachModal">
            <AddOfficiatingCoachModalInner {...props} />
        </AnalyticsModal>
    );
}

const AddOfficiatingCoachModalInner = ({ toggle, supervisionTypes }) => {
    const [ step, setStep ] = useState(1);
    const [ selectedMember, setSelectedMember ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    const handleOnSelectMember = (member) => {
        setSelectedMember(member);
        setStep(2);
    }


    return (
        <OverlayLoader isLoading={isLoading}>
            <CrossFade isVisible={step === 1}>
                <ModalHeader toggle={toggle}>
                    <Translate id='officialSupervision.assignPermissions.search' />
                </ModalHeader>
                <SearchMemberForm
                    onCancel={toggle}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    searchParams={{
                        all: 1,
                        with_official_qualification: 1,
                    }}
                    memberComponent={({ member }) => (
                        <MemberCard member={member} handleOnSelectMember={handleOnSelectMember} />
                    )}
                />
            </CrossFade>
            <CrossFade onExited={() => setSelectedMember(null)} isVisible={step === 2}>
                <AssignPermissions
                    supervisionTypes={supervisionTypes}
                    member={selectedMember}
                    setStep={setStep}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    toggle={toggle}
                />
            </CrossFade>
        </OverlayLoader>
    )
}

export const MemberCard = ({ member, handleOnSelectMember, className }) => {
    return (
        <UserDisplay className={stringBuilder("mb-2 w-100", className)} card>
            <UserDisplay.Container>
                <UserImg
                    width={45}
                    abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                    alt={member.first_name + ' ' + member.last_name}
                    src={member.picture?.full_path}
                    filePos={member.picture?.file_position}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Title>{member.first_name + ' ' + member.last_name}</UserDisplay.Title>
                <UserDisplay.Subtitle>#{member.unique_identifier}</UserDisplay.Subtitle>
                <UserDisplay.Subtitle>{member.birthdate} (<Translate id='misc.yearsOld' values={{ age: member.age }} />)</UserDisplay.Subtitle>
                <UserDisplay.Subtitle>
                    {!member.member_qualification ?
                        <span className="px-1 bg-light-warning text-body"><i className="mdi mdi-alert-outline text-warning" /> <Translate id='officialSupervision.addSupervision.notOfficial.label' /></span>
                        :
                        <DisplayI18n
                            field="name"
                            defaultValue={member.organisation?.organisation_name}
                            i18n={member.organisation?.i18n}
                        />
                    }
                </UserDisplay.Subtitle>
                {member.member_qualification &&
                    <UserDisplay.Subtitle>
                        <DisplayI18n field='name' defaultValue={member.member_qualification.qualification.name} i18n={member.member_qualification.qualification.i18n} />{(member.member_qualification.qualification_level) && <> - <DisplayI18n field='name' defaultValue={member.member_qualification.qualification_level.name} i18n={member.member_qualification.qualification_level.i18n} /></>}
                    </UserDisplay.Subtitle>
                }
            </UserDisplay.Container>
            {handleOnSelectMember &&
                <UserDisplay.Container className="ml-auto text-right">
                    <Button
                        disabled={!member.member_qualification}
                        size="sm"
                        onClick={() => {
                            handleOnSelectMember(member);
                        }}
                        color="primary"
                        type="button"
                    >
                        <Translate id='misc.select' />
                    </Button>
                </UserDisplay.Container>
            }
        </UserDisplay>
    )
}

export default AddOfficiatingCoachModal;