import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';

// Contexts
import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';
import { IdentityRolesContext } from './IdentityRolesContext';

/** @type {React.Context<Omit<NotificationsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const NotificationsContext = createContext();
NotificationsContext.displayName = 'NotificationsContext';

class NotificationsContextProvider extends React.Component{

    state = {
        notificationsCount: 0,
    }

    /**
     * Sets the notifications count in the context's state
     * @param {number} count Notifications count
     */
    setNotificationsCount = (count) => {
        this.setState(() => ({
            notificationsCount: count,
        }))
    }

    /**
     * Gets all notifications
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Notifications/Apicontroller%5CNotifications%5CNotifications%3A%3AgetAllNotifications|documentation}
     * @returns {Promise.<Array>}
     */
    getNotifications = (queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/notifications',
            query: Object.assign({
                to_identity_id: this.props.IdentityRolesContext.identity.identity_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.notifications;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Gets all notification subscriptions
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Notifications/f7e9ef1ec643f55fac063847cc05ba92|documentation}
     * @returns {Promise.<object[]>}
     * @throws {Error}
     */
    getNotificationSubscriptions = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/notifications/subscriptions',
            query: Object.assign({
                identity_id: this.props.IdentityRolesContext.identity.identity_id,
                identity_role_id: this.props.IdentityRolesContext.identity_role_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.subscriptions;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PUT] Update a notification subscription
     * @param {object[]} values Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Notifications/ae117de041d98f026ce42a55b9179c10|documentation}
     * @returns {Promise.<boolean>}
     * @throws {Error}
     */
    updateNotificationSubscriptions = (values) => {
        const params = new URLSearchParams();

        for(let i = 0; i < values.length; i++){
            const sub = values[i];

            params.append(`subscriptions[${i}][identity_id]`, this.props.IdentityRolesContext.identity.identity_id);
            params.append(`subscriptions[${i}][identity_role_id]`, this.props.IdentityRolesContext.identity_role_id);
            params.append(`subscriptions[${i}][notification_type_id]`, sub.notification_type_id);
            params.append(`subscriptions[${i}][subscribed]`, sub.subscribed ? 1 : 0);

        }

        return API_SPORDLE.put('/notifications/subscriptions', params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Mark all notifications in account ass read
     * @returns {Promise.<Array>}
     */
    updateMarkAllAsRead = () => {
        return API_SPORDLE.patch(`/accounts/${this.props.IdentityRolesContext.identity.identity_id}/notifications/mark-as-read`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * M
     * @param {object} [notification_id] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Notifications/Apicontroller%5CNotifications%5CNotifications%3A%3AgetAllNotifications|documentation}
     * @returns {Promise.<Array>}
     */
    updateMarkAsRead = (notification_id) => {
        return API_SPORDLE.patch(`/notifications/${notification_id}/mark-as-read`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete notifications
     * @param {string} [notification_id]  Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Notifications/Apicontroller%5CNotifications%5CNotifications%3A%3AdeleteNotification|documentation}
     * @returns {Promise.<Boolean>}
     */
    deleteNotification = (notification_id) => {
        return API_SPORDLE.delete(`/notifications/${notification_id}`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] Deletes all notifications of an identity
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Notifications/Apicontroller%5CNotifications%5CNotifications%3A%3AdeleteToIdentity|documentation}
     * @returns {Promise.<Boolean>}
     */
    deleteAllNotifications = () => (
        API_SPORDLE.delete(`/accounts/${this.props.IdentityRolesContext.identity.identity_id}/notifications`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    )


    render(){
        return (
            <NotificationsContext.Provider value={{ ...this }}>
                {this.props.children}
            </NotificationsContext.Provider>
        )
    }
}

export default withContexts(IdentityRolesContext, OrganizationContext)(NotificationsContextProvider);