export default{
    "task.renewMembers.title"                  : "Renew Members",
    "task.renewMembers.affiliated.has"         : "Is affiliated",
    "task.renewMembers.memberType"             : "Member type",
    "task.renewMembers.memberType.has"         : "Has member type",
    "task.renewMembers.memberType.required"    : "Member type is required",
    "task.renewMembers.division"               : "Division",
    "task.renewMembers.division.required"      : "Division is required",
    "task.renewMembers.renew"                  : "Renew",
    "task.renewMembers.text"                   : "Please select a payment method to renew the following members",
    "task.renewMembers.paymentMethod"          : "Payment method",
    "task.renewMembers.paymentMethod.required" : "Payment method is required",
    "task.renewMembers.members"                : "{count, plural, one {Member (#)} other {Members (#)}}",
    "task.renewMembers.members.desc"           : "{count, plural, one {This member} other {These members}} will be renewed",
    "task.renewMembers.renew.success"          : "All members are now renewed!",
}