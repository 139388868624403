import Translate from "@spordle/intl-elements";
import { useEffect, useState } from "react"
import { ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import CrossFade from "../../../../../components/crossFade/CrossFade";
import RolloverInstallmentModalConfirmation from "./RolloverInstallmentModalConfirmation";
import RolloverInstallmentModalSelection from "./RolloverInstallmentModalSelection";
import RolloverInstallmentModalWarning from "./RolloverInstallmentModalWarning";

const RolloverInstallmentModal = ({ isOpen, toggle, currentSeasonInstallments, refreshTable }) => {

    const steps = {
        warning: 'warning',
        selection: 'selection',
        confirmation: 'confirmation',
    }
    const showWarning = currentSeasonInstallments?.length > 0;
    const [ activeStep, setActiveStep ] = useState();
    const [ selectedInstallments, setSelectedInstallments ] = useState([]);

    useEffect(() => {
        if(isOpen)
            setActiveStep(showWarning ? steps.warning : steps.selection)
    }, [ isOpen ])

    return (
        <AnalyticsModal id="rollover-modal" isOpen={isOpen} analyticsName='RegistrationFeesRolloverModal'>
            <ModalHeader toggle={toggle}>
                <Translate id='catalog.installment.rollover.title' />
            </ModalHeader>

            <CrossFade isVisible={activeStep === steps.warning}>
                <RolloverInstallmentModalWarning onSubmit={() => setActiveStep(steps.selection)} currentSeasonInstallments={currentSeasonInstallments} />
            </CrossFade>
            <CrossFade isVisible={activeStep === steps.selection}>
                <RolloverInstallmentModalSelection
                    onSubmit={(fees) => {
                        setSelectedInstallments(fees)
                        setActiveStep(steps.confirmation)
                    }}
                    goToPrevious={showWarning ? () => setActiveStep(steps.warning) : undefined}
                />
            </CrossFade>
            <CrossFade isVisible={activeStep === steps.confirmation} unmountOnExit>
                <RolloverInstallmentModalConfirmation
                    selectedInstallments={selectedInstallments}
                    goToPrevious={() => setActiveStep(steps.selection)}
                    toggle={toggle}
                    refreshTable={refreshTable}
                />
            </CrossFade>
        </AnalyticsModal>
    )
}

export default RolloverInstallmentModal