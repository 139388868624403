export default{
    "organization.settings.sidePanelOrganizationSuspensions.title" : "Informations de Suspension",

    "organization.settings.sidePanelOrganizationSuspensions.code"            : "Code",
    "organization.settings.sidePanelOrganizationSuspensions.code.required"   : "Le Code est requis",
    "organization.settings.sidePanelOrganizationSuspensions.status"          : "Statut",
    "organization.settings.sidePanelOrganizationSuspensions.status.active"   : "Actif",
    "organization.settings.sidePanelOrganizationSuspensions.status.inactive" : "Inactif",
    "organization.settings.sidePanelOrganizationSuspensions.status.required" : "Le Statut est requis",

    "organization.settings.sidePanelOrganizationSuspensions.update.success.text" : "Suspension mise à jour",
    "organization.settings.sidePanelOrganizationSuspensions.update.fail.text"    : "Une erreur est survenue lors de la mise à jour de la Suspension, veuillez rafraichir la page et réessayer",

    // actions
    "organization.settings.sidePanelOrganizationSuspensions.actions.remove"         : "Retirer",
    "organization.settings.sidePanelOrganizationSuspensions.actions.remove.topText" : "Vous êtes sur le point de retirer cette Suspension de votre Organisation",

    // tabs
    "organization.settings.sidePanelOrganizationSuspensions.tabs.details" : "Détails",
}