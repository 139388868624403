import Translate from "@spordle/intl-elements"
import { ModalHeader } from "reactstrap"

const SignWaiversModalHeader = ({ toggle }) => {
    return (
        <ModalHeader toggle={toggle}>
            <Translate id='teams.profile.header.signWaivers.title' />
        </ModalHeader>
    )
}

export default SignWaiversModalHeader