export default{
    "components.manualRegistration.forms.title"              : "Questionnaire(s) ({current} / {count})",
    "components.manualRegistration.forms.skipAll"            : "Sauter tous les questionnaires",
    "components.manualRegistration.forms.required"           : "Ce champ est requis",
    "components.manualRegistration.forms.min"                : "Veuillez respecter la valeur minimum",
    "components.manualRegistration.forms.max"                : "Veuillez respecter la valeur maximum",
    "components.manualRegistration.forms.label.between"      : "Entre",
    "components.manualRegistration.forms.label.and"          : "et",
    "components.manualRegistration.forms.label.min"          : "Minimum",
    "components.manualRegistration.forms.label.max"          : "Maximum",
    "components.manualRegistration.forms.notMandatoryHelper" : "Ce questionnaire n'est pas obligatoire",
}