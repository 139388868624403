import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Button, Collapse, ModalFooter } from "reactstrap";
import CrossFade from "../../../../../../components/crossFade/CrossFade";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../components/UserImg";
import { HoverableCopy } from "../../../../../../helpers/clipboardHelper";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { AllOfficialReportsModalForm } from "../../allOfficialReportsModal/AllOfficialReportsModal";
import { AddSupervisionFormContext } from "./AddSupervisionFormContext";

const OfficialInfo = ({ official, selectBtn }) => {
    const { formData, updateFormData } = useContext(AddSupervisionFormContext);

    const handleOnViewClick = () => {
        updateFormData("viewMode", "official");
    }

    const handleOnPrevious = () => {
        updateFormData("viewMode", "steps");
    }

    return (
        <>
            <div className="rounded-lg bg-light-inverse mt-2">
                <Collapse isOpen={formData.viewMode !== "official"}>
                    <div className="px-2 pt-2 pb-3 border rounded-lg">
                        {formData.officiatingCoach &&
                            <div className="small text-muted">
                                <HoverableCopy toCopy={formData.officiatingCoach?.unique_identifier}>
                                    #{formData.officiatingCoach?.unique_identifier}
                                </HoverableCopy>
                            </div>
                        }
                        <Translate id={official ? 'officialSupervision.addSupervision.officiatingCoachSupervises' : "officialSupervision.addSupervision.willSupervise"} values={{ name: <span className="font-bold">{formData.officiatingCoach?.first_name} {formData.officiatingCoach?.last_name}</span> }} />
                    </div>
                </Collapse>
                {official &&
                    <UserDisplay className="mb-3 flex-wrap flex-md-nowrap mt-n2" card block>
                        <UserDisplay.Container>
                            <UserImg
                                width={45}
                                abbr={official ? `${official.first_name.charAt(0)}${official.last_name.charAt(0)}` : "HC"}
                                alt="Official"
                            />
                        </UserDisplay.Container>
                        <UserDisplay.Container>
                            <UserDisplay.Title>{official ? `${official.first_name} ${official.last_name}` : '-'}</UserDisplay.Title>
                            <UserDisplay.Subtitle>{official?.unique_identifier ? <HoverableCopy toCopy={official?.unique_identifier}>#{official?.unique_identifier}</HoverableCopy> : '-'}</UserDisplay.Subtitle>
                            {official && <UserDisplay.Subtitle>{official.birthdate} (<Translate id='misc.yearsOld' values={{ age: official.age }} />)</UserDisplay.Subtitle>}
                            {official?.member_qualification &&
                                <UserDisplay.Subtitle>
                                    <DisplayI18n field='name' defaultValue={official.member_qualification.qualification.name} i18n={official.member_qualification.qualification.i18n} />{(official.member_qualification.qualification_level) && <> - <DisplayI18n field='name' defaultValue={official.member_qualification.qualification_level.name} i18n={official.member_qualification.qualification_level.i18n} /></>}
                                </UserDisplay.Subtitle>
                            }
                        </UserDisplay.Container>
                        <UserDisplay.Container className="ml-md-auto text-md-right">
                            {selectBtn}
                            {formData.viewMode !== "official" && official?.member_qualification &&
                                <CrossFade isVisible>
                                    <button onClick={handleOnViewClick} type="button" className="reset-btn text-primary">
                                        <span className="small">
                                            <Translate id='officialSupervision.allReportsBtn.short' />
                                        </span>
                                    </button>
                                </CrossFade>
                            }
                        </UserDisplay.Container>
                    </UserDisplay>
                }
            </div>
            <Collapse isOpen={formData.viewMode == "official"}>
                {official && <AllOfficialReportsModalForm pagination={3} member={official} />}
                <ModalFooter className="mx-n3 mt-3">
                    <Button className="mr-auto" color="primary" outline type="button" onClick={handleOnPrevious}><Translate id="misc.previous" /></Button>
                </ModalFooter>
            </Collapse>
        </>
    );
}

export default OfficialInfo;