import { FormikSelect } from "@spordle/formik-elements"
import Translate from "@spordle/intl-elements"
import { Form, Formik } from "formik"
import { useContext, useRef, useState } from "react"
import { Button, Collapse, FormGroup, Label, ModalBody, ModalFooter } from "reactstrap"
import { array, object } from "yup"
import CustomAlert from "../../../../../../../components/CustomAlert"
import Required from "../../../../../../../components/formik/Required"
import OrganizationSearch from "../../../../../../../components/organization/OrganizationSearch"
import { I18nContext } from "../../../../../../../contexts/I18nContext"
import { OrganizationContext } from "../../../../../../../contexts/OrganizationContext"
import { RegistrationCategoriesContext } from "../../../../../../../contexts/RegistrationCategoriesContext"
import { RegistrationDivisionsContext } from "../../../../../../../contexts/RegistrationDivisionsContext"
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper"
import { isLeague, isTournament } from "../../../../../../../helpers/orgHelper"
import { DisplayCategory } from "../../../../../../teams/TeamHelpers"

const OrgSelectionStep = ({ toggle, from, previous, onSubmit, affiliatedOrgs, actionButtonText, organizationId }) => {

    const divisionsContext = useContext(RegistrationDivisionsContext);
    const organizationContext = useContext(OrganizationContext);
    const categoryContext = useContext(RegistrationCategoriesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const [ categories, setCategories ] = useState();
    const categoriesSelectRef = useRef();

    const isDisabled = (orgId, divId, categoryId) => {
        if(affiliatedOrgs){
            return !!affiliatedOrgs.find((group) => {
                return group.key === orgId && group.values.find((value) => value.division.division_id === divId && (categoryId ? value.team_category?.team_category_id === categoryId : true))
            })
        }
        return false
    }

    return (
        <Formik
            initialValues={{
                orgs: [],
                divisions: [],
                categories: [],
            }}
            validationSchema={object().shape({
                orgs: array().min(1, <Translate id='organization.grouping.add.selectOrg.orgs.required' />),
                divisions: array().min(1, <Translate id='organization.grouping.add.selectOrg.divisions.required' />),
                categories: array(),
            })}
            onSubmit={(data, { setStatus }) => {
                const formattedData = {
                    ...data,
                    divisions: data.divisions.map((divId) => ({
                        divisionId: divId,
                        categoryIds: data.categories.length > 0 ? data.categories.filter((categoryId) => categories.find((cat) => cat.team_category_id === categoryId && divId === cat.division?.division_id)) : [],
                    })),
                }

                return onSubmit(formattedData, setStatus, isDisabled)
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <div className="mb-3 font-medium text-dark">
                            <Translate id={'organization.grouping.add.selectOrg.text.' + from} />
                        </div>

                        <FormGroup>
                            <Label for='orgs' className='text-muted'><Translate id='organization.grouping.add.selectOrg.orgs' /> <Required /></Label>
                            <OrganizationSearch
                                id="leagueZoneOrganizationAdd" name="orgs"
                                multi clearable skipParent
                                withTree withFormik onlyActive
                                isCompact
                                isOptionDisabled={(option) => {
                                    const orgIdIsValid = option.value !== organizationId;
                                    if(orgIdIsValid && !isTournament(option.category.category_system) && !isLeague(option.category.category_system)){
                                        return false
                                    }
                                    return true
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for='orgs' className='text-muted'><Translate id='organization.grouping.add.selectOrg.divisions' /> <Required /></Label>
                            <FormikSelect
                                id='divisions' name='divisions'
                                multi clearable
                                searchKeys={[
                                    { name: `i18n.${getGenericLocale()}.name`, weight: 2 },
                                    { name: `i18n.${getGenericLocale()}.short_name`, weight: 2 },
                                    `shortName`,
                                    'label',
                                ]}
                                renderOption={(option) => (
                                    <>
                                        <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                                        <span className="text-muted ml-1">(<DisplayI18n field='short_name' defaultValue={option.option.shortName} i18n={option.option.i18n} />)</span>
                                    </>
                                )}
                                initFilter={{
                                    orgId: formik.values.orgs || [],
                                }}
                                loadData={(_from, { filters }, spordleTable) => {
                                    switch (_from){
                                        case 'FILTER':
                                        case 'CDM':
                                            if(filters.orgId){
                                                return divisionsContext.getRegistrationDivisions(organizationContext.organisation_id)
                                                    .then((divisions) => divisions.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order)).map((div) => ({
                                                        value: div.division_id,
                                                        label: div.name,
                                                        shortName: div.short_name,
                                                        i18n: div.i18n,
                                                    })))
                                            }
                                            return Promise.resolve([]);
                                    }
                                }}
                                onOptionSelected={(values) => {
                                    categoriesSelectRef.current?.getSpordleTable().filterChange('divisionIds', values);
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for='orgs' className='text-muted'><Translate id='organization.grouping.add.selectOrg.categories' /></Label>
                            <FormikSelect
                                ref={categoriesSelectRef}
                                disabled={formik.values.divisions.length == 0}
                                name='categories'
                                id='categories'
                                clearable multi
                                searchKeys={[
                                    { name: `class.i18n.${getGenericLocale()}.short_name`, weight: 2 },
                                    { name: `division.i18n.${getGenericLocale()}.short_name`, weight: 2 },
                                    `class.short_name`,
                                    `division.short_name`,
                                    'label',
                                ]}
                                initFilter={{
                                    divisionIds: [],
                                }}
                                loadData={(from, extraData) => {
                                    switch (from){
                                        case 'CDM':
                                            return categoryContext.getRegistrationCategories(organizationContext.organisation_id, { active: '1', limit_to_my_organisation: '1' })
                                                .then((categories) => {
                                                    const formattedCategories = categories
                                                        .sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))
                                                        .map((category) => ({
                                                            ...category,
                                                            value: category.team_category_id,
                                                            label: category.name,
                                                        }))
                                                    setCategories(formattedCategories)
                                                    return formattedCategories
                                                })
                                        case "FILTER":
                                            return Promise.resolve(
                                                categories
                                                    .filter((category) => extraData.filters.divisionIds.length > 0 ? extraData.filters.divisionIds.indexOf(category.division?.division_id) !== -1 : true),
                                            )
                                    }
                                }}
                                renderSelectedOption={(option) => <DisplayCategory short category={option} />}
                                renderOption={({ option }) => (
                                    <>
                                        {!!option.gender && <div className="small">{<Translate id={`form.fields.gender.${option.gender.toLowerCase()}`} />}</div>}
                                        <div className="mb-0 font-medium"><DisplayCategory short category={option} /></div>
                                    </>
                                )}
                            />
                        </FormGroup>

                        <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                            {!!formik.status &&
                                <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                            }
                        </Collapse>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        {previous &&
                            <Button color='primary' outline type='button' onClick={previous}><Translate id='misc.previous' /></Button>
                        }

                        <div className="ml-auto">
                            <Button color='primary' type='submit' className="mr-2"><Translate id={actionButtonText} /></Button>
                            <Button color='primary' outline type='button' onClick={toggle}><Translate id='misc.cancel' /></Button>
                        </div>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    )
}

export default OrgSelectionStep