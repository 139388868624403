import AnalyticsModal from "../../../../../../../../analytics/AnalyticsModal";
import AddMultipleOutcomes from "./AddMultipleOutcomes";
import AddSingleOutcome from "./AddSingleOutcome";

/**
 * @description Modal that contains a form to add a member to a descrimination claim
 * @param {Object} props
 * @param {object} props.maltreatment The discrimination complaint
 * @param {function} props.toggle Function to close the modal
 * @param {boolean} props.isOpen Modal is open or not
 * @param {boolean} props.editOutcomeData Edit data, this will make the modal edit mode
 * @returns {React.ReactNode}
 */
const AddOutcomeModal = (props) => {
    return (
        <AnalyticsModal analyticsName="addMaltreatmentOutcomeModal" isOpen={props.isOpen}>
            {Array.isArray(props.editOutcomeData.maltreatmentIds) && props.editOutcomeData.maltreatmentIds.length > 0 ?
                <AddMultipleOutcomes {...props} />
                :
                <AddSingleOutcome {...props} />
            }
        </AnalyticsModal>
    )
}

export default AddOutcomeModal;