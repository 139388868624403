export default{
    "members.profile.overview.memberMedicalNotes.title" : "Notes médicales",

    "members.profile.overview.memberMedicalNotes.table.type"              : "Type",
    "members.profile.overview.memberMedicalNotes.table.severity"          : "Sévérité",
    "members.profile.overview.memberMedicalNotes.table.severity.critical" : "Critique",
    "members.profile.overview.memberMedicalNotes.table.severity.moderate" : "Modérée",
    "members.profile.overview.memberMedicalNotes.table.notes"             : "Notes",
    "members.profile.overview.memberMedicalNotes.table.emergency"         : "Action d'urgence",
    "members.profile.overview.memberMedicalNotes.table.hint.none1"        : "Vous pouvez ajouter des notes médicales",
    "members.profile.overview.memberMedicalNotes.table.hint.none2"        : "Ajouter une note médicale",

    "members.profile.overview.memberMedicalNotes.add.title"              : "Ajouter une note médicale",
    "members.profile.overview.memberMedicalNotes.add.type.required"      : "Le type est requis",
    "members.profile.overview.memberMedicalNotes.add.severity.required"  : "La sévérité est requise",
    "members.profile.overview.memberMedicalNotes.add.emergency.required" : "L'action d'urgence est requise",
    "members.profile.overview.memberMedicalNotes.add.emergency.hint"     : "Exemple: Epipen",
}