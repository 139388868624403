export default{
    "task.waitingList.title"                                             : "Liste d'attente",
    "task.waitingList.column.waiting_list_item_sequential_number"        : "# de référence",
    "task.waitingList.column.created_at"                                 : "Date d'ajout",
    "task.waitingList.column.waiting_list_item_sequential_number.online" : "Inscription en ligne",
    "task.waitingList.column.waiting_list_item_sequential_number.admin"  : "Inscription admin",
    "task.waitlist.registration"                                         : "Inscription",
    "task.waitlist.registration.required"                                : "Veuillez sélectionner une inscription",
    "task.waitlist.status"                                               : "Statut",
    "task.waitlist.status.PENDING"                                       : "En attente",
    "task.waitlist.status.WAITING_FOR_CLIENT"                            : "En attente du client",
    "task.waitlist.status.CANCELLED"                                     : "Annulé",
    "task.waitlist.status.CANCELED"                                      : "Annulé",
    "task.waitlist.status.CONVERTED"                                     : "Converti",
    "task.waitlist.status.alert.title"                                   : "Ce membre a été converti.",
    "task.waitlist.status.alert.text"                                    : "Date de facturation: {date}",
    "task.waitlist.notConfirmed"                                         : "Non confirmé",
    "task.waitlist.accept"                                               : "Accepter",
    "task.waitlist.accept.title"                                         : "Accepter le membre",
    "task.waitlist.reset"                                                : "Réinitialiser le statut",
    "task.waitlist.reset.text"                                           : "Réinitialiser le statut à en attente.",
    "task.waitlist.cancel"                                               : "Annuler",
    "task.waitlist.cancelReason.label"                                   : "Raison d'annulation",
    "task.unsactioned.club.title"                                        : "Organisation non-confirmée",
    "task.member.attachment.approval.title"                              : "Approbation des documents",
}