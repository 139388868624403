export default{
    // General
    "onlineStore.components.onlineStoreItemsFees.section.name"           : "Items / Fees",
    "onlineStore.components.onlineStoreItemsFees.status.SOLD_OUT"        : "Sold out",
    "onlineStore.components.onlineStoreItemsFees.status.ACTIVE"          : "Active",
    "onlineStore.components.onlineStoreItemsFees.status.INACTIVE"        : "Inactive",
    "onlineStore.components.onlineStoreItemsFees.status.SUSPENDED"       : "Suspended",
    "onlineStore.components.onlineStoreItemsFees.validation.oneRequired" : "You must add at least one registration or at least one other item.",

    "onlineStore.components.onlineStoreItemsFees.registrationFee.inactive" : "Registration Fee is inactive",
    "onlineStore.components.onlineStoreItemsFees.registrationFee.goTo"     : "Manage in Catalog / Registration Fees",
    "onlineStore.components.onlineStoreItemsFees.membershipFees.warning"   : "Membership fee configuration needed",
    "onlineStore.components.onlineStoreItemsFees.membershipFees.tooltip"   : "Membership fees must be configured for this registrations' division and member type for the current organization as well as all parent organizations.",
    "onlineStore.components.onlineStoreItemsFees.membershipFees.goTo"      : "Manage Membership Fees",
    "onlineStore.components.onlineStoreItemsFees.otherFee.inactive"        : "Other Item is inactive",
    "onlineStore.components.onlineStoreItemsFees.otherFee.goTo"            : "Manage in Catalog / Other Items",
    "onlineStore.components.onlineStoreItemsFees.item"                     : "Item",
    "onlineStore.components.onlineStoreItemsFees.add.activity.periods"     : "This is the activity period set on this item.",

    // Registration section
    "onlineStore.components.onlineStoreItemsFees.registration.title"                          : "Registrations",
    "onlineStore.components.onlineStoreItemsFees.registration.table.registration"             : "Registration",
    "onlineStore.components.onlineStoreItemsFees.registration.table.price"                    : "Price",
    "onlineStore.components.onlineStoreItemsFees.registration.table.registration.sold"        : "{amount} sold",
    "onlineStore.components.onlineStoreItemsFees.registration.table.registration.available"   : "{amount} available place(s)",
    "onlineStore.components.onlineStoreItemsFees.registration.table.registration.unlimited"   : "Unlimited places",
    "onlineStore.components.onlineStoreItemsFees.registration.table.status"                   : "Status",
    "onlineStore.components.onlineStoreItemsFees.registration.table.display_order"            : "Display Order",
    "onlineStore.components.onlineStoreItemsFees.registration.table.hint1"                    : "You can add registration fees.",
    "onlineStore.components.onlineStoreItemsFees.registration.table.hint2"                    : "Click here to add a registration fee",
    "onlineStore.components.onlineStoreItemsFees.registration.table.empty"                    : "No registration",
    "onlineStore.components.onlineStoreItemsFees.registration.table.items_sold"               : "Items Sold",
    "onlineStore.components.onlineStoreItemsFees.registration.modal.title"                    : "Add Registrations",
    "onlineStore.components.onlineStoreItemsFees.registration.modal.season.label"             : "Season",
    "onlineStore.components.onlineStoreItemsFees.registration.modal.registration.label"       : "Select Registrations",
    "onlineStore.components.onlineStoreItemsFees.registration.modal.registration.placeholder" : "Select registrations",
    "onlineStore.components.onlineStoreItemsFees.registration.modal.registration.empty"       : "No registration",
    "onlineStore.components.onlineStoreItemsFees.registration.modal.registration.textWhen"    : "{count} selected registrations",
    "onlineStore.components.onlineStoreItemsFees.registration.sidePanel.date.label"           : "Date",
    "onlineStore.components.onlineStoreItemsFees.registration.sidePanel.time.label"           : "Time",
    "onlineStore.components.onlineStoreItemsFees.registration.sidePanel.early.label"          : "Early Registration (Before)",
    "onlineStore.components.onlineStoreItemsFees.registration.sidePanel.late.label"           : "Late Registration (After)",

    // Other items section
    "onlineStore.components.onlineStoreItemsFees.otherItems.title"                         : "Other Items",
    "onlineStore.components.onlineStoreItemsFees.otherItems.table.item"                    : "Item",
    "onlineStore.components.onlineStoreItemsFees.otherItems.table.status"                  : "Status",
    "onlineStore.components.onlineStoreItemsFees.otherItems.table.items_sold"              : "Items Sold",
    "onlineStore.components.onlineStoreItemsFees.otherItems.table.price"                   : "Price",
    "onlineStore.components.onlineStoreItemsFees.otherItems.table.display_order"           : "Display Order",
    "onlineStore.components.onlineStoreItemsFees.otherItems.table.hint1"                   : "You can add other items.",
    "onlineStore.components.onlineStoreItemsFees.otherItems.table.hint2"                   : "Click here to add another item",
    "onlineStore.components.onlineStoreItemsFees.otherItems.table.empty"                   : "No other items",
    "onlineStore.components.onlineStoreItemsFees.otherItems.modal.title"                   : "Add Item",
    "onlineStore.components.onlineStoreItemsFees.otherItems.modal.searchLabel"             : "Search item",
    "onlineStore.components.onlineStoreItemsFees.otherItems.modal.selectedItems"           : "{count} selected item(s)",
    "onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.link.label"          : "Set mandatory for a registration fee",
    "onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.column.registration" : "Registration",
    "onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.column.mandatory"    : "Mandatory",
    "onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.column.link"         : "Link",
    "onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.column.link.all"     : "All Mandatory",
    "onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.column.unlink.all"   : "Unlink All",
}