export default{
    "clinics.clinicCommunications.clinicCommunications.title" : "Communications",

    // registration
    "clinics.clinicCommunications.clinicCommunications.registration.title"                  : "Registration confirmation",
    "clinics.clinicCommunications.clinicCommunications.registration.attachments"            : "Attachment(s)",
    "clinics.clinicCommunications.clinicCommunications.registration.attachments.comingSoon" : "Module in development",

    // confirmation
    "clinics.clinicCommunications.clinicCommunications.confirmation.title"       : "Confirmation email",
    "clinics.clinicCommunications.clinicCommunications.confirmation.attachments" : "Attachment(s)",

    // forms
    "clinics.clinicCommunications.clinicCommunications.forms.title"                 : "Questionnaires",
    "clinics.clinicCommunications.clinicCommunications.forms.noForms"               : "There is no questionnaire associated with this clinic",
    "clinics.clinicCommunications.clinicCommunications.terms.noTerms"               : "There are no terms and conditions associated with this clinic",
    "clinics.clinicCommunications.clinicCommunications.waivers.noWaivers"           : "There are no waivers associated with this clinic",
    "clinics.clinicCommunications.clinicCommunications.waivers.noConfirmation"      : "There is no registration confirmation associated with this clinic",
    "clinics.clinicCommunications.clinicCommunications.waivers.noConfirmationEmail" : "There is no confirmation email associated with this clinic",
}