export default{
    "helpCenter.submitTicket.title" : "Support request",
    "helpCenter.submitTicket.type"  : "Type",

    "helpCenter.submitTicket.search.title"               : "Ongoing issues",
    "helpCenter.submitTicket.search.tab.title"           : "My issues",
    "helpCenter.submitTicket.search.didNotFindBug"       : "You did not find the issue?",
    "helpCenter.submitTicket.search.comments"            : "Comment(s)",
    "helpCenter.submitTicket.search.updated"             : "Updated",
    "helpCenter.submitTicket.search.createTicket"        : "Submit a request",
    "helpCenter.submitTicket.search.gotoSupportRequests" : "See all my support requests",

    "helpCenter.submitTicket.create.story"            : "Story",
    "helpCenter.submitTicket.create.subject"          : "Subject",
    "helpCenter.submitTicket.create.subject.required" : "Subject is required",
    "helpCenter.submitTicket.create.priority"         : "Priority",

    "helpCenter.submitTicket.create.priority.1" : "High (Service is down or feature is unavailable, no workarounds)",
    "helpCenter.submitTicket.create.priority.3" : "Medium (Service is degraded or feature is unavailable, some workarounds)",
    "helpCenter.submitTicket.create.priority.5" : "Low (Grammatical, technical or quality issues)",

    "helpCenter.submitTicket.create.send" : "Send",

    "helpCenter.submitTicket.success.title"  : "Thank you!",
    "helpCenter.submitTicket.success.ticket" : "Ticket",
    "helpCenter.submitTicket.success.text"   : "Your ticket was successfully submitted! Our team will look into it as soon as possible.",
    "helpCenter.submitTicket.success.btn"    : "Submit another ticket",
}