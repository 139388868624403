import { DropdownItem } from "reactstrap";
import CanDoAction from "../../permissions/CanDoAction";
import PropTypes from 'prop-types';

/**
 * DropdownItem that handles CanDoAction. Should be child of Uncontrolled dropdown or SidePanelActionsMenu
 * @param {Object} props
 * @param {string|import("react").ReactElement} [props.tag=button]
 * @returns {JSX.Element}
 * @example
 * <SidePanel.ActionsMenu>
 *      <SidePanelMenuAction action='DELETE' componentCode='members' componentPermissionCode='members_request_transfer_usa'>
 *          Delete
 *      </SidePanelMenuAction>
 * </SidePanel.ActionsMenu>
 */
const SidePanelMenuAction = ({ tag = "button", action, componentCode, componentPermissionCode, skipAccess, ...props }) => {
    return (
        action && componentCode && componentPermissionCode ?
            <CanDoAction
                action={action}
                componentCode={componentCode}
                componentPermissionCode={componentPermissionCode}
                skipAccess={skipAccess}
            >
                <DropdownItem {...props} tag={tag} />
            </CanDoAction>
            :
            <DropdownItem {...props} tag={tag} />
    )
};

export default SidePanelMenuAction;

SidePanelMenuAction.propTypes = {
    componentCode: PropTypes.string,
    componentPermissionCode: PropTypes.string,
    tag: PropTypes.oneOfType([ PropTypes.string, PropTypes.elementType ]),
    skipAccess: PropTypes.bool,
}

SidePanelMenuAction.defaultProps = {
    tag: "button",
    skipAccess: false,
}