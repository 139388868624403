import Translate from "@spordle/intl-elements";
import React from "react";
import { Link } from "react-router-dom";
import { fire } from '@spordle/toasts';
import { DisplayI18n } from "../../../helpers/i18nHelper";

/**
 * Shows a Toast with the given warnings
 * @param {Array} warnings The list of warninngs
 * @param {number} [maxWarnings=Number.MAX_SAFE_INTEGER] The maximum number of warnings to show in the Toast
 */
export function triggerWarningToast(warnings, maxWarnings = Number.MAX_SAFE_INTEGER){
    const warningsToDisplay = warnings.slice(0, maxWarnings);// First maxWarnings
    const otherWarningsCount = warnings.slice(maxWarnings).length;
    fire({
        icon: <React.Fragment />,
        theme: 'warning',
        msg: 'teams.profile.settings.warning.hint.title',
        info:
        <>
            <ul className='pl-3 mb-1'>
                {warningsToDisplay.map((warning) => {
                    const splittedOrigin = typeof warning.origin === "string" ? warning.origin?.split('.') : [];
                    const name = splittedOrigin.length > 1 ? /'(.*?)'/.exec(splittedOrigin[1])[1].trim() : warning.origin;// Stringify before trim
                    return (
                        <li key={warning.code + warning.origin}>
                            {warning.i18n ?
                                <DisplayI18n field='message' defaultValue={warning.message} i18n={warning.i18n} />
                                :
                                <Translate id={`teams.profile.settings.error.${warning.code}`} values={{ data: name }} defaultMessageId="misc.error" />
                            }
                        </li>
                    )
                })}
            </ul>
            {otherWarningsCount > 0 && <div><Translate id='teams.profile.settings.warning.hint.more' values={{ warningCount: otherWarningsCount }} /></div>}
            <Link to='settings'><Translate id='teams.profile.settings.warning.hint.msg' /></Link>
        </>,
        skipInfoTranslate: true,
        timeout: warningsToDisplay.length * 5 + 10,
        id: 'team-warnings',
    });
}