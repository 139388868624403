import SpordleTableProvider from "@spordle/datatables";
import { FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, Col, FormGroup, Label } from "reactstrap";
import { object, string } from 'yup';
import { AxiosIsCancelled } from "../../../../../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";
import { PeriodsContext } from "../../../../../../../../contexts/contexts";
import { I18nContext } from "../../../../../../../../contexts/I18nContext";
import { TeamsContext } from "../../../../../../../../contexts/TeamsContext";
import { DisplayI18n } from "../../../../../../../../helpers/i18nHelper";
import { DisplayCategory } from "../../../../../../TeamHelpers";
import { SEARCH_TEAM } from "../../../TeamRosterAdd";
import TeamRosterSearchView from "../components/TeamRosterSearchView";

const TeamRosterSearchTeam = ({ preformatSubmit, activeTab, getTableProps }) => {
    // -- Contexts
    const { cachedTeam, getAvailableMembersByTeams, getTeams } = useContext(TeamsContext);
    const { activePeriods, selectedPeriod } = useContext(PeriodsContext);
    const { getGenericLocale } = useContext(I18nContext);

    // -- States
    const [ teams, setTeams ] = useState(null);

    // -- Functions
    const fetchTeams = (periodId) => {
        setTeams(null);

        getTeams({ period_id: periodId })
            .then(setTeams)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    // -- React cycles
    useEffect(() => {
        fetchTeams(selectedPeriod.period_id);
    }, []);

    return (
        <SpordleTableProvider {...getTableProps(getAvailableMembersByTeams)} id='roster-search-team'>
            {({ filterChange }) => (
                <Formik
                    initialValues={{
                        period_id: selectedPeriod.period_id,
                        target_team_id: '',
                    }}
                    validationSchema={object().shape({
                        period_id: string().test({
                            name: 'isRequired',
                            message: <Translate id='teams.profile.teamRoster.activeRoster.add.validation.period' />,
                            test: function(period){
                                return activeTab === SEARCH_TEAM ? !!period : true;
                            },
                        }),
                        target_team_id: string().test({
                            name: 'isRequired',
                            message: <Translate id='teams.profile.teamRoster.activeRoster.add.validation.targetTeam' />,
                            test: function(team){
                                return activeTab === SEARCH_TEAM ? !!team : true;
                            },
                        }),
                    })}
                    onSubmit={(values) => {
                        const newVal = preformatSubmit(values);

                        newVal.organisation_id = cachedTeam.organisation.organisation_id;
                        newVal.team_id = cachedTeam.team_id;

                        filterChange('search', newVal);
                    }}
                >
                    {() => (
                        <TeamRosterSearchView>
                            <Col sm="3">
                                <FormGroup>
                                    <Label className="text-muted" for="period_id"><Translate id='teams.profile.teamRoster.activeRoster.add.label.season' /></Label>
                                    <FormikSelect
                                        onOptionSelected={([ value ]) => {
                                            if(value){
                                                fetchTeams(value);
                                            }else{
                                                setTeams([]);
                                            }
                                        }}
                                        name='period_id'
                                        id='period_id'
                                        renderOption={({ option }) => (
                                            <DisplayI18n
                                                defaultValue={option.label}
                                                i18n={option.i18n}
                                                field="name"
                                            />
                                        )}
                                        options={activePeriods?.map((period) => ({
                                            label: period.name,
                                            value: period.period_id,
                                            i18n: period.i18n,
                                        }))}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label className="text-muted" for="target_team_id"><Translate id='teams.profile.teamRoster.activeRoster.add.label.team' /></Label>
                                    <FormikSelect
                                        clearable
                                        id='target_team_id'
                                        name='target_team_id'
                                        translateLabel={false}
                                        renderOption={({ option: team }) => (
                                            <>
                                                <DisplayI18n
                                                    defaultValue={team.label}
                                                    i18n={team.i18n}
                                                    field="name"
                                                />
                                                <div className='text-muted small'>
                                                    {team.division ? team.division : <DisplayCategory category={{ ...team?.category }} short /> }
                                                    {team.category?.gender && <> - <Translate id={`form.fields.gender.${team.category.gender.toLowerCase()}`} /></>}
                                                </div>
                                            </>
                                        )
                                        }
                                        isLoading={!teams}
                                        searchKeys={[
                                            `division.i18n.${getGenericLocale()}.short_name`,
                                            `category.class.i18n.${getGenericLocale()}.short_name`,
                                        ]}
                                        options={teams?.map((team) => ({
                                            value: team.team_id,
                                            i18n: team.i18n,
                                            label: team.name,
                                            category: team.team_category,
                                            division: team.division,
                                        }))}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="3" className="align-self-end">
                                <FormGroup>
                                    <Button type="submit" block color="primary"><i className="ti-search" /> <Translate id='misc.search' /></Button>
                                </FormGroup>
                            </Col>
                        </TeamRosterSearchView>
                    )}
                </Formik>
            )}
        </SpordleTableProvider>
    )
}

export default TeamRosterSearchTeam;