import { useEffect, useState } from 'react';
import Translate from '@spordle/intl-elements';
import {
    Container
} from "reactstrap";

import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import Steps from '../../../components/steps/Steps';
import OnlineStoreItemsAndFeesComponent from '../components/itemsAndFees/OnlineStoreItemsAndFeesComponent';
import OnlineStoreReview from './OnlineStoreReview';
import OnlineStoreRebatesTerms from '../components/rebatesTerms/OnlineStoreRebatesTerms';
import I18nHelperContextProvider from '../../../helpers/i18nHelper';
import OnlineStoreGeneralInformation from '../components/generalInformation/OnlineStoreGeneralInformation';

import * as Sentry from "@sentry/react";

const CreateOnlineStore = () => {
    const [ season, setSeason ] = useState("");
    const [ steps, setSteps ] = useState([
        {
            name: <Translate id='onlineStore.create.step1.label' />,
            isAccessible: false,
        },
        {
            name: <Translate id='onlineStore.create.step2.label' />,
            isAccessible: false,
        },
        {
            name: <Translate id='onlineStore.create.step3.label' />,
            isAccessible: false,
        },
        {
            name: <Translate id='onlineStore.create.step4.label' />,
            isAccessible: false,
        },
    ])

    const [ onlineStoreId, setOnlineStoreId ] = useState('');

    const [ activeStep, setActiveStep ] = useState(0); // saves the index of the active step

    // when the user start a step, we set isAccessible to true to enable 1 step at a time
    useEffect(() => {
        if(!steps[activeStep].isAccessible){
            setSteps((prev) => {
                prev[activeStep].isAccessible = true;
                return prev
            });
        }

        Sentry.addBreadcrumb({
            type: 'info',
            message: 'Active step changed',
            level: Sentry.Severity.Log,
            category: 'OnlineStore',
            data: {
                activeStep: activeStep,
                onlineStoreId: onlineStoreId,
            },
        });
    }, [ activeStep ])

    const nextStep = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        setActiveStep(activeStep + 1)
    }

    const previousStep = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        setActiveStep(activeStep - 1)
    }

    return (
        <>
            {/*--------------------------------------------------------------------------------*/}
            {/* View Header                                                                  */}
            {/*--------------------------------------------------------------------------------*/}
            <ViewHeaderV2
                title='onlineStore.create.title'
                breadcrumbsProps={{
                    disablePeriodSelect: true,
                }}
            />

            <div className="page-content p-4" key={season}>
                <I18nHelperContextProvider fields={[ 'name', 'description', 'confirmation_message_email' ]}>
                    <Container>
                        <Steps activeStepName={steps[activeStep].name} className="mb-4">
                            {steps.map((step, key) => (
                                <Steps.Item
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={key}
                                    number={key + 1}
                                    name={step.name}
                                    onClick={() => setActiveStep(key)}
                                    isActive={key === activeStep}
                                    isAccessible={step.isAccessible}
                                />
                            ))}
                        </Steps>
                        {activeStep === 0 &&
                            <OnlineStoreGeneralInformation
                                onFormSave={(store) => { setOnlineStoreId(store.online_store_id); nextStep() }}
                                isEditing
                                updateStoreSeason={setSeason}
                                onlineStoreId={onlineStoreId}
                                season={season}
                                wizard
                            />
                        }
                        {activeStep === 1 &&
                            <OnlineStoreItemsAndFeesComponent from="CREATE" isEditing wizard toggle={previousStep} onSubmit={nextStep} onlineStoreId={onlineStoreId} />
                        }
                        {activeStep === 2 &&
                            <OnlineStoreRebatesTerms toggle={previousStep} onSubmit={nextStep} onlineStoreId={onlineStoreId} isEditing wizard />
                        }
                        {activeStep === 3 &&
                            <OnlineStoreReview onlineStoreId={onlineStoreId} />
                        }

                    </Container>
                </I18nHelperContextProvider>
            </div>
        </>
    );
}

export default CreateOnlineStore;