export default{
    // review step
    "clinics.clinicInformation.formClinicInformation.review.title"         : "Edit clinic information",
    "clinics.clinicInformation.formClinicInformation.review.hasAttendees"  : "Clinic already has attendees. Certain fields may be disabled.",
    "clinics.clinicInformation.formClinicInformation.review.saveChanges"   : "Save changes",
    "clinics.clinicInformation.formClinicInformation.review.cancelChanges" : "Cancel changes",

    // host organization
    "clinics.clinicInformation.formClinicInformation.host.title"       : "Host organization",
    "clinics.clinicInformation.formClinicInformation.host.placeholder" : "Select an organization",
    "clinics.clinicInformation.formClinicInformation.host.validation"  : "Organization is required",

    // clinic info
    "clinics.clinicInformation.formClinicInformation.clinicInfo.title"                                : "Clinic info",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.name"                                 : "Name",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.type.qualification"                   : "Official qualification ({federationName})",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.type.other"                           : "Qualification or course from the host organization",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.type.validation"                      : "Type is required",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.category"                             : "Category",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.category.placeholder"                 : "Select a category",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.category.noOptions"                   : "No categories available",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.category.validation"                  : "Category is required",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualification"                        : "Qualification",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualification.placeholder"            : "Select a qualification",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualification.noOptions"              : "No qualifications available",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualification.validation"             : "Qualification is required",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration"                           : "Expiration",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.qualification"             : "Fill with qualification's expiration",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.type"                      : "Type",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.duration"                  : "Duration",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.duration.required"         : "The duration is required",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.duration.years"            : "{number, plural, one {# year} other {# years}}",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.NO_EXPIRATION"             : "No expiration",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.FIXED_PERIOD"              : "Fixed Period",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.FIXED_DATE"                : "Fixed date",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.date"                      : "Date",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.date.required"             : "The date is required",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.placeholder"               : "MM-DD",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.prerequisites"                        : "Prerequisites",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.prerequisites.required"               : "At least one prerequisite is required for a group.<break></break>You can delete the row to remove prerequisite requirements.",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualPrerequisites.selected"           : "{count, plural, one {{count} selected qualification} other {{count} selected qualifications}}",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualPrerequisites.addGroup"           : "Add a group",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualPrerequisites"                    : "Because you chose the qualification: {qualificationName}.",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualPrerequisites.hint.all"           : "The prerequisites below are the default prerequisites for the qualification. All of them are mandatory for the registration of an attendee.",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualPrerequisites.hint.one"           : "The prerequisites below are the default prerequisites for the qualification. One of them is mandatory for the registration of an attendee.",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites"                  : "Your organization's prerequisites",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites.hint"             : "Minimum number of mandatory prerequisites for the registration of an attendee",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites.hint.none1"       : "You can add prerequisites from your organization.",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites.hint.none2"       : "Click here to add a prerequisite",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites.hint.selectFirst" : "Please select a qualification first",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites.select.all"       : "All mandatory",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites.select.one"       : "At least one mandatory",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.prerequisites.description"            : "Description",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.prerequisites.organization"           : "Organization",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.prerequisites.type"                   : "Type",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.moodle"                               : "Moodle Course",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.passingGrade"                         : "Passing Grade",

    // Add Prerequisite modal
    "clinics.clinicInformation.formClinicInformation.clinicInfo.addPrerequisites.title"       : "Add prerequisite",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.addPrerequisites.label"       : "Prerequisite",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.addPrerequisites.placeholder" : "Select a prerequisite",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.addPrerequisites.required"    : "Prerequisite is required",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.addPrerequisites.noOptions"   : "No prerequisites",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.addPrerequisites.helper"      : "The prerequisites are simply qualifications that can come from another category.",

    // attendees
    "clinics.clinicInformation.formClinicInformation.attendees.title"                              : "Attendees",
    "clinics.clinicInformation.formClinicInformation.attendees.types"                              : "Attendee type(s)",
    "clinics.clinicInformation.formClinicInformation.attendees.types.placeholder"                  : "Select one or more attendee types",
    "clinics.clinicInformation.formClinicInformation.attendees.types.none"                         : "No attendee types available. Please add an attendee type to your organization to continue",
    "clinics.clinicInformation.formClinicInformation.attendees.types.validation.min"               : "Check at least 1 attendee type",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees"             : "Attendees requirements",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.min"         : "Minimum number",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.min.max"     : "Minimum number must be less than or equal to the maximum number",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.min.min"     : "Minimum number must be at least 1",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.max.already" : "This clinic already has {amount} attendees",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.max"         : "Maximum number",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.max.min"     : "Maximum number must be greater than or equal to the minimum number",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.waitingList" : "Maximum number is required if the clinic has a waiting list",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.age"                   : "Age requirements",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.age.min"               : "Minimum age",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.age.min.max.already"   : "This clinic aleardy has a {age}-year-old attendee",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.age.min.max"           : "Minimum age must be less than or equal to the maximum age",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.age.min.min"           : "Minimum age must be at least 1",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.age.max"               : "Maximum age",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.age.max.min"           : "Maximum age must be greater than or equal to the minimum age",
    "clinics.clinicInformation.formClinicInformation.attendees.waitingList"                        : "Waiting list",
    "clinics.clinicInformation.formClinicInformation.attendees.waitingList.helper"                 : "Members wishing to attend this clinic when it is already full will be placed on a waiting list so that you can add them as an attendee if necessary.",
    "clinics.clinicInformation.formClinicInformation.attendees.criminalRecord"                     : "Background check",
    "clinics.clinicInformation.formClinicInformation.attendees.criminalRecord.helper"              : "Require a background check - Any attendee must have a valid background check before signing up for this clinic. (Note: Not applicable to anyone under 18 years of age.)",

    // description
    "clinics.clinicInformation.formClinicInformation.description.title" : "Description",
}
