import { stringBuilder } from "@spordle/helpers";
import ChecklistAssistantStep from "./ChecklistAssistantStep";
import styles from "./ChecklistAssistantStepsList.module.scss";
import ChecklistProgress from "./ChecklistProgress";
import { useContext, useState } from "react";
import ChecklistIcon from "./ChecklistIcon";
import { ChecklistsContext } from "../../../../contexts/ChecklistsContext";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import { useIntl } from "react-intl";
import { Link } from 'react-router-dom'
import Translate from "@spordle/intl-elements";

const ChecklistAssistantStepsList = ({ closeAssistant }) => {
    const checklistContext = useContext(ChecklistsContext);
    const { formatMessage } = useIntl();
    const activeChecklist = checklistContext.checklists[checklistContext.activeChecklist];
    const steps = activeChecklist?.checklist_steps || [];
    const completedSteps = checklistContext.getCompletedSteps(checklistContext.activeChecklist);

    // Steps are accordeon
    // -1 === no steps open.
    const [ openedStep, setOpenedStep ] = useState(checklistContext.activeStep || -1);

    if(!activeChecklist){
        return (
            <div className='h4 px-3 pt-3 pb-2 font-bold border-bottom mb-0 mdi mdi-drag with-icon d-flex align-items-center w-100'>
                <Translate id="misc.notFound" />
                <button
                    type="button"
                    title={formatMessage({ id: "misc.close" })}
                    className="ml-auto pl-1 reset-btn p-3 m-n3 line-height-1 mdi mdi-close"
                    onClick={closeAssistant}
                />
            </div>
        );
    }

    return (
        <>
            <div className='h4 px-3 pt-3 pb-2 font-bold border-bottom mb-0 mdi mdi-drag with-icon d-flex align-items-center w-100'>
                <DisplayI18n field="name" i18n={activeChecklist.i18n} defaultValue={activeChecklist.name} />
                <button
                    type="button"
                    title={formatMessage({ id: "misc.close" })}
                    className="ml-auto pl-1 reset-btn p-3 m-n3 line-height-1 mdi mdi-close"
                    onClick={closeAssistant}
                />
            </div>
            <div className="p-3 overflow-auto pretty-scrollbar">
                <ChecklistIcon isComplete={steps.length <= completedSteps.length} width={100} />
                <ChecklistProgress total={steps.length} complete={completedSteps.length} />
                {steps.map((step, i) => (
                    <ChecklistAssistantStep
                        key={step.checklist_step_id}
                        step={step}
                        index={i}
                        onClick={() => setOpenedStep((prev) => prev === i ? -1 : i)}
                        isOpen={openedStep === i}
                        className={stringBuilder(`mb-${(i === steps.length - 1) ? 0 : 2}`, styles.step)}
                    />
                ))}
            </div>
            <Link
                className="btn btn-link btn-block btn-sm pb-3 px-3"
                to={`/tasks/checklists/${(activeChecklist.checklist_id)}`}
            >
                <Translate id="checklists.goTo" />
            </Link>
        </>
    );
}

export default ChecklistAssistantStepsList;