import Translate from '@spordle/intl-elements';

/**
 * @typedef {Object} Watermark
 * @prop {string|JSX.Element} text
 * @prop {boolean} [skipTranslateText=false]
 * @prop {number} [rotation=80]
 * @prop {number} [fontSize=175]
 */

/**
 * Watermark overlay that will display a text with a rotation over a container
 * @param {Watermark} props
 * @return {React.ReactNode}
 */
const Watermark = (props) => {
    return (
        <div className='position-relative'>
            <div className='watermark text-muted font-bold'>
                <span className="d-inline-block" style={{ transformOrigin: 'center', transform: `rotate(${props.rotation || 80}deg)`, fontSize: '20vh' }}>
                    {props.skipTranslateText ?
                        props.text
                        :
                        <Translate id={props.text} />
                    }
                </span>
            </div>
            {props.children}
        </div>
    )
}

/**
 * Component that wraps the watermark component with a condition
 * @param {object} props
 * @param {boolean} props.showWatermark
 * @param {Watermark} props.watermarkProps
 * @return {React.ReactNode}
 */
const WatermarkWrapper = ({ showWatermark, children, watermarkProps, ...props }) => {
    if(showWatermark){
        return (
            <Watermark {...watermarkProps}>
                {children}
            </Watermark>
        )
    }
    return children

}

export{
    Watermark,
    WatermarkWrapper
};