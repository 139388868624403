import Axios from 'axios';
import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { IdentityRolesContext } from './IdentityRolesContext';

import { setContext, setTag } from "@sentry/react";
import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';

/** @type {React.Context<Omit<RolesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & RolesContextProvider['state']>} */
export const RolesContext = createContext();
RolesContext.displayName = 'RolesContext';

class RolesContextProvider extends React.Component{
    static contextType = IdentityRolesContext;

    /*
    State exemple:
    https://dev.docp5bdn1uq1.amplifyapp.com/test-api?api=API-GATEWAY-DEV&base_URL=https%3A%2F%2Fjk6vn6hura.execute-api.ca-central-1.amazonaws.com%2Fv1%2F&body=%7B%0A%7D&encoding=x-www-form-urlencoded&endPointParams=%7B%0A%09%22components%22%3A%22permissions%22%0A%7D&end_point=%2Frights%2Froles%2F82b4b38e-43a1-11eb-a51c-00163e00707a&headers=%7B%0A%7D&method_type=get
    */

    constructor(props){
        super(props);

        this.state = {
            "role_id": "",
            "organisation_id": "",
            "title": "",
            "description": "",
            "is_system": "",
            "start_date": "",
            "expiration_date": null,
            "active": "",
            "components": [],
        }

        // QuickView - Load state data loaded in iframe element
        if(window.frameElement && window.frameElement.getAttribute('rolescontext')){
            this.state = JSON.parse(window.frameElement.getAttribute('rolescontext'));
        }
    }

    componentDidUpdate(){
        setContext('role', this.state.role_id ? {
            roleId: this.state.role_id,
            roleName: this.state.title,
            isSystem: this.state.is_system,
            isActive: this.state.active,
            startDate: this.state.start_date,
            expirationDate: this.state.expiration_date,
        } : null);
        setTag('roleId', this.state.role_id);
    }

    /**
     * Will be used inside the QuickView Iframe
     * @param {orgId} orgId
     * @returns {Promise}
     */
    setCurrentState = (state) => this.setState(() => ({ ...state }));

    /**
     * @typedef {'READ'|'ADD'|'EDIT'|'DELETE'|'IMPORT'|'EXPORT'} Actions
     */

    /**
     * Determines if this role grants the access to a given action in a given component
     * @param {Actions | Actions[]} action
     * @param {string} componentCode The component's code i.e 'dashboard'
     * @param {string} componentPermissionCode The component's code i.e 'organization_profile'
     * @param {boolean} skipAccess Bypass the "hasAccessTo" that checks if a READ is present. Used for componentPermissionCode that have no READ option. EX: link_suspension_maltreatment_claim, members_outstanding_balance
     * @returns {boolean} If the action is granted in this component
     */
    canDoAction = (action, componentCode, componentPermissionCode, skipAccess = false) => {
        if(!skipAccess && (Array.isArray(action) ? action.length > 0 : action) && componentCode && componentPermissionCode && !this.hasAccessTo(componentCode, componentPermissionCode))
            return false;

        return (this.state.components.find((comp) => comp.code === componentCode)
            ?.component_permissions.find((cp) => cp.code === componentPermissionCode)
            ?.role_component_permissions.findIndex((rcp) => (Array.isArray(action) ? action.includes(rcp.action) : rcp.action === action) && rcp.active === '1') ?? -1) !== -1;
    }

    /**
     * Determines if this role grants the access to a given component
     * @param {string} componentCode The component's code i.e 'dashboard'
     * @param {string|string[]} componentPermissionCode The component's code i.e 'organization_profile'
     * @returns {boolean} If the action is granted in this component
     */
    hasAccessTo = (componentCode, componentPermissionCode) => {
        const hasActiveRead = (rcp) => rcp.action === 'READ' && rcp.active == '1';

        const isValidComponentPermissionCode = (cpc) => {
            const componentPermission = component.component_permissions.find((permission) => permission.code === cpc);
            if(componentPermission && componentPermission.active === '1'){
                if(componentPermission.role_component_permissions.some(hasActiveRead))
                    return true;
            }
            return false;
        }

        const component = this.state.components.find((comp) => comp.code === componentCode);
        if(component && component.active === '1'){
            const isActivated = component.component_permissions.find((cp) => cp.visible_in_menu === '1' && cp.active === '1')?.role_component_permissions[0]?.active === '1';
            if(isActivated){
                if(componentPermissionCode){
                    if(Array.isArray(componentPermissionCode)){
                        if(componentPermissionCode.every(isValidComponentPermissionCode)){
                            return true;
                        }
                    }else if(isValidComponentPermissionCode(componentPermissionCode)){
                        return true;
                    }
                }else{
                    // Access to the component in general
                    for(let index = 0; index < component.component_permissions.length; index++){
                        if(component.component_permissions[index].role_component_permissions.some(hasActiveRead))
                            return true;
                    }
                }
            }
        }
        return false;
    }

    /**
     * Determines if the route can be shown or not
     * @param {object} routeObject
     * @param {object} [routeObject.permissionsLogic] // OR, AND
     * @param {object} [routeObject.permissions]
     * @param {object[]} [routeObject.subPermissionsRoutes]
     * @param {boolean} [routeObject.powerUserOnly]
     * @param {string|Array} [routeObject.showWhenSetting]
     * @param {string} [routeObject.showWhenSettingLogic]
     * @returns {boolean|'ADMIN'} `'ADMIN'` when the route should be shown because user is a power user
     * @param {boolean} [settingsOnlyMode] this mode is used so only the settings are checked, permissions are ignored
     */
    validateRouteAccess = (routeObject, settingsOnlyMode = false) => {
        const ADMIN = 'ADMIN'; // constant here for compilation
        const isGod = this.context.isGod();

        const isOr = (logic) => logic === 'OR';
        const isAnd = (logic) => logic === 'AND';

        // Check for setting verifications for the current route object
        if(routeObject.showWhenSetting){
            const visibilitySetting = routeObject.showWhenSetting;
            const orgSettings = this.props.OrganizationContext.settings;
            let settingsAllowAccess = null;

            const canAccessSetting = (setting) => orgSettings[setting]?.value == 1;

            if(Array.isArray(visibilitySetting)){
                const showLogic = routeObject.showWhenSettingLogic;

                // check if showWhenSetting is an Array, for arrays we can verify with 2 different logis: AND or OR
                if(isOr(showLogic) && visibilitySetting.some(canAccessSetting)){
                    settingsAllowAccess = true;
                }else if(isAnd(showLogic) && visibilitySetting.every(canAccessSetting)){
                    settingsAllowAccess = true;
                }else if(isGod){
                    settingsAllowAccess = ADMIN;
                }else{
                    settingsAllowAccess = false;
                }

            }else if(canAccessSetting(visibilitySetting)){
                // if showWhenSetting is not an array, check the setting directly
                settingsAllowAccess = true;
            }else if(isGod){
                settingsAllowAccess = ADMIN;
            }else{
                settingsAllowAccess = false;
            }

            if(settingsAllowAccess === false || settingsAllowAccess === ADMIN){
                return settingsAllowAccess;
            }
        }

        // return true to not hit the code that checks the role permissions
        if(settingsOnlyMode){
            return true;
        }

        const routePermissions = routeObject.permissions;

        if(Array.isArray(routePermissions)){
            if(isOr(routeObject.permissionsLogic) && routePermissions.some((permission) => true === this.validateRouteAccess({ ...routeObject, permissions: permission }))){
                return true;
            }else if(routePermissions.every((permission) => true === this.validateRouteAccess({ ...routeObject, permissions: permission }))){
                return true;
            }else if(isGod){
                return ADMIN
            }

            return false;
        }

        if(routeObject.powerUserOnly){
            return isGod ? ADMIN : false;
        }

        const checkAccess = (route) => {
            const permissions = route.permissions;

            if(!permissions){ // No permissions = For all
                return true;
            }

            // Has some permission set
            if(Array.isArray(permissions.actions)){ // Has action specific permissions
                return permissions.actions.some((action) => this.canDoAction(action, permissions.code, permissions.permissionCode));
            }

            // Does not have action specific access
            return this.hasAccessTo(permissions.code, permissions.permissionCode);
        }

        let showFor = checkAccess(routeObject);

        if(showFor && Array.isArray(routeObject.subPermissionsRoutes)){
            const subRoutesAccess = routeObject.subPermissionsRoutes
                .filter((route) => {
                    return !(route.title || route.redirect || route.tempTab || route.divider || route.powerUserOnly || route.sidebarRoute)
                })// Removing routes that are no applicable to permissions
                .map((route) => this.validateRouteAccess(route));

            if(subRoutesAccess.includes(true)){
                showFor = true;
            }else if(subRoutesAccess.includes(ADMIN) && isGod){ // If has a admin and isGod
                showFor = ADMIN;
            }else{ // No true nor ADMIN only access -> don't show
                showFor = false;
            }
        }

        if(!showFor && isGod){ // When doesn't have permission but isGod
            showFor = ADMIN;
        }

        return showFor;
    }

    /**
     * Get all the roles within an organization
     * @param {string} organizationId The organization id we want to get the roles from
     * @returns {Promise<Array>}
     */
    getOrganizationRoles = (organizationId) => {
        return API_SPORDLE.get(`rights/roles/organisations/${organizationId}?sort=+title`)
            .then((response) => {
                if(response.data.status){
                    return response.data.roles;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get role information
     * @param {string} roleId
     * @returns {Promise}
     */
    getRole = (roleId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `rights/roles/${roleId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.roles[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Sets the current role to whatever is given
     * @param {object} roleInfo The role info must correspond the data from the state exemple
     * @param {function} callback Function to execute after setState
     */
    setCurrentRole = (roleInfo, callback) => {
        this.setState(() => roleInfo, callback);
    }

    /**
     * Update the given roles ids
     * @param {string|Array.<string>} roleId The roles to update
     * @param {object} values The values to update the roles with
     * @param {string} organizationId ID to the Organization
     * @returns {Promise}
     */
    updateRole = (roleId, values, organizationId) => {
        const buildParams = (rowValues) => {
            const data = new URLSearchParams();
            data.append('organisation_id', organizationId);

            for(const key in rowValues){
                // eslint-disable-next-line no-prototype-builtins
                if(rowValues.hasOwnProperty(key)){
                    switch (key){
                        case 'title':
                            data.append('title', rowValues[key]);
                            break;
                        case 'description':
                            data.append('description', rowValues[key]);
                            break;
                        case 'active':
                            data.append('active', (rowValues[key] == '1') >>> 0);
                            break;
                        case 'is_system':
                            data.append('is_system', (rowValues[key] == '1') >>> 0);
                            break;
                            //case 'start_date':
                            //    data.append('start_date', rowValues[key]);
                            //    break;
                            //case 'expiration_date':
                            //    data.append('expiration_date', rowValues[key]);
                            //    break;
                        default:
                            break;
                    }
                }
            }
            return data;
        }

        if(Array.isArray(roleId)){ // Batch update
            return Axios.all(roleId.map((id) => {
                return API_SPORDLE.put('rights/roles/' + id, buildParams(values.find((dataValue) => dataValue.role_id === id)));
            }))
                .then((Axios.spread((...responses) => {
                    if(responses.every((response) => response.data.status)){
                        return;
                    }
                    throw responses[0].data.errors[0];
                }, serverError)));
        }
        // Single update
        return API_SPORDLE.put('rights/roles/' + roleId, buildParams(values))
            .then((response) => {
                if(response.data.status){
                    return response.data.result;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get the role permissions
     * @param {string} roleId The role to get the data
     * @returns {Promise} The Promise from the the api call
     */
    getRolesPermissions = (roleId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `rights/roles/${roleId}`, query: { components: 'permissions' } }))
            .then((response) => {
                if(response.data.status){
                    return response.data.roles[0];
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Update the component permission
     * @param {string} roleId The role to get the data
     * @param {Array.<{
     *      key: string,
     *      value: Array.<{
     *          action: string,
     *          role_component_permission_id?: string,
     *          active?: boolean
     *      }>
     *  }>} values All the component permissions of the given role
     * @returns {Promise} The Promise from the the api call
     */
    updateComponentsPermissions = (roleId, values) => {
        const data = new URLSearchParams();

        let index = 0;
        values.forEach((permission) => {
            if(Array.isArray(permission.value)){
                permission.value.forEach((action) => {
                    if(action.role_component_permission_id)
                        data.append(`permissions[${index}][role_component_permission_id]`, action.role_component_permission_id);
                    data.append(`permissions[${index}][component_permission_id]`, permission.key);
                    data.append(`permissions[${index}][action]`, action.action);
                    data.append(`permissions[${index}][assign]`, 1);
                    data.append(`permissions[${index}][active]`, !!action.active >>> 0);
                    index += 1;
                });
            }
        })

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `rights/roles/${roleId}/components-permissions` }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Update the component permission
     * @param {string} roleId The role to get the data
     * @param {Array} values All the component permissions for the new role
     * @returns {Promise} The Promise from the the api call
     */
    duplicateComponentsPermissions = (roleId, values) => {
        const data = new URLSearchParams();

        values.forEach((permission, index) => {
            if(permission.role_component_permission_id)
                data.append(`permissions[${index}][role_component_permission_id]`, permission.role_component_permission_id);
            data.append(`permissions[${index}][component_permission_id]`, permission.component_permission_id);
            data.append(`permissions[${index}][action]`, permission.action);
            data.append(`permissions[${index}][assign]`, permission.assign);
            data.append(`permissions[${index}][active]`, permission.active);
        });


        return API_SPORDLE.put(queryString.stringifyUrl({ url: `rights/roles/${roleId}/components-permissions` }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Get a role component permissions
     * @param {string} roleId The role to get the data
     * @param {string} roleComponentId The component to get the data
     * @returns {Promise} The Promise from the the api call
     * @throws {Error}
     */
    getComponentPermissions = (roleId, roleComponentId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `rights/roles/${roleId}/components-permissions/${roleComponentId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.role_component_permissions;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Delete all the role_component_permission from a role
     * @param {string} roleId The role_id to delete in
     * @param {string[]} roleComponentsIds The role_component_permission_id to be deleted
     * @returns {Promise} The Promise from the the api call
     */
    deleteAllRoleComponents = (roleId, roleComponentsIds) => {
        return Axios.all(roleComponentsIds.map((roleComponentId) => API_SPORDLE.delete(queryString.stringifyUrl({ url: `rights/roles/${roleId}/components-permissions/${roleComponentId}` }))))
            .then((responses) => {
                if(responses.every((response) => response.data.status)){
                    return true;
                }
                throw new Error('Error');
            }, serverError);
    }

    /**
     * Creates a Role under an Organization
     * @param {string} organizationId ID of the Organization to add the Role to
     * @param {string} title Title of the Role
     * @param {string} description Description of the Role
     * @param {boolean} active True or False to determine if the Role is active or not
     * @param {string} startDate Date when the Role starts being available (format: ISO)
     * @param {string} expirationDate Date when the Role stops being available (format: ISO)
     * @param {number} isSystem 1 or 0 - COMPLETE THIS PART
     * @returns {Promise}
     */
    createRole = (organizationId, title, description, active, startDate, expirationDate, isSystem) => {
        const params = new URLSearchParams();
        params.append('organisation_id', organizationId);
        params.append('title', title);
        params.append('description', description);
        params.append('active', active ? 1 : 0);
        //params.append('start_date', startDate);
        //params.append('expiration_date', expirationDate);
        params.append('is_system', isSystem);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/rights/roles` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.role_id
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a specific Role
     * @param {string} roleId ID of the Role to delete
     * @returns {Promise}
     */
    deleteRole = (roleId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `rights/roles/${roleId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.result;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <RolesContext.Provider value={{
                ...this.state,
                ...this,
                isGod: this.context.isGod,
            }}
            >
                {this.props.children}
            </RolesContext.Provider>
        );
    }
}

export default withContexts(OrganizationContext)(RolesContextProvider);
