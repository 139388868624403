export default{
    "settings.clinics.sidePanelClinicQualifications.title" : "Informations sur la qualification",

    "settings.clinics.sidePanelClinicQualifications.prerequisites"                  : "Préalables",
    "settings.clinics.sidePanelClinicQualifications.prerequisites.required.all"     : "Tous les préalables sont obligatoire",
    "settings.clinics.sidePanelClinicQualifications.prerequisites.required.partial" : "Au moin un préalable est obligatoire",

    "settings.clinics.sidePanelClinicQualifications.description"  : "Description",
    "settings.clinics.sidePanelClinicQualifications.organization" : "Organisation",

    "settings.clinics.sidePanelClinicQualifications.price"           : "Prix",
    "settings.clinics.sidePanelClinicQualifications.status"          : "Statut",
    "settings.clinics.sidePanelClinicQualifications.status.active"   : "Actif",
    "settings.clinics.sidePanelClinicQualifications.status.inactive" : "Inactif",
    "settings.clinics.sidePanelClinicQualifications.status.required" : "Le statut est requis",

    "settings.clinics.sidePanelClinicQualifications.update.success.text" : "Qualification mise à jour",
    "settings.clinics.sidePanelClinicQualifications.update.fail.text"    : "Une erreur est survenue lors de la mise à jour de la qualification, veuillez rafraichir la page et réessayer",

    // actions
    "settings.clinics.sidePanelClinicQualifications.actions.remove"         : "Retirer",
    "settings.clinics.sidePanelClinicQualifications.actions.remove.topText" : "Vous êtes sur le point de retirer cette qualification de votre organisation",

    // tabs
    "settings.clinics.sidePanelClinicQualifications.tabs.details" : "Détails",
}