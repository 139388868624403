import moment from 'moment';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { fail } from "@spordle/toasts";
import { FormsContext } from '../../../../contexts/FormsContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import withContexts from '../../../../helpers/withContexts';
import CustomFormEditor from './CustomFormEditor';
import queryString from 'query-string';
class FormsEdit extends Component{
    state = {
        formInfo: null,
    }

    componentDidMount(){
        const query = queryString.parse(location.search)

        if(!query.formId){
            this.props.history.push('/catalog/forms');
        }else{
            this.props.FormsContext.getForm(query.formId)
                .then((form) => {
                    // mapping the data for the custom form format
                    const formattedForms = form.sort((a, b) => parseInt(a.sort) - parseInt(b.sort)).map(({ fields, custom_form_group_id, ...group }) => ({
                        ...group,
                        formGroupId: custom_form_group_id,
                        questions: fields.sort((a, b) => parseInt(a.sort) - parseInt(b.sort)).map(({ form_field_code, custom_form_field_id, ...field }) => ({
                            ...field,
                            formFieldId: custom_form_field_id,
                            // min & max for DATETIME and DATE need to be moment objects
                            min: (form_field_code === 'DATETIME' || form_field_code === 'DATE') && field.min ? moment(field.min) : field.min,
                            max: (form_field_code === 'DATETIME' || form_field_code === 'DATE') && field.max ? moment(field.max) : field.max,
                            is_admin: field.is_admin == '1',
                            mandatory: field.mandatory == '1',
                            formFieldCode: form_field_code,
                            options: field.options?.map(({ i18n, field_option, custom_form_field_option_id, ...option }) => ({
                                ...option,
                                fieldOptionId: custom_form_field_option_id,
                                fieldOption: field_option,
                                default: option.default == '1',
                                i18n: {
                                    en: {
                                        fieldOption: i18n.en.field_option,
                                    },
                                    fr: {
                                        fieldOption: i18n.fr.field_option,
                                    },
                                },
                            })) || [],
                        })),
                    }))
                    this.setState(() => ({ formInfo: formattedForms }))
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }
    }

    render(){
        const query = queryString.parse(location.search)

        return (
            <div>
                {!this.state.formInfo ?
                    <div className='text-center'>
                        <Spinner type='grow' color='primary' />
                    </div>
                    :
                    <CustomFormEditor {...this.props} formId={query.formId} customFormsData={this.state.formInfo || undefined} formName={query.formName} duplicate={query.duplicate} />
                }
            </div>
        );
    }
}

export default withContexts(FormsContext)(withRouter(FormsEdit));
