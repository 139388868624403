import { stringBuilder } from '@spordle/helpers';
import Translate, { CurrencyFormat, DateFormat } from '@spordle/intl-elements';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { FormattedPlural } from 'react-intl';
import { Card, Collapse, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import ConfirmModal from '../../../../../../../components/confirmModal/ConfirmModal';
import { fail, success } from '@spordle/toasts';
import { I18nContext } from '../../../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../../../contexts/MembersContext';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import { useHistory } from 'react-router-dom';

const TransactionPaymentReceptions = ({ paymentReceptions, invoiceNumber, onDeletePaymentReception, toggleEdit, disableActions }) => {
    const i18nContext = useContext(I18nContext);

    const sortPaymentReception = (paymentA, paymentB) => {
        return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(paymentA.received_at, paymentB.received_at) ||
               new Intl.Collator(i18nContext.getGenericLocale(), { numeric: true }).compare(paymentA.amount, paymentB.amount)
    }

    return (
        (paymentReceptions && paymentReceptions.length > 0) &&
            <div className='mb-3'>
                <div className="h4 font-bold">
                    <FormattedPlural
                        value={paymentReceptions.length}
                        one={<Translate id='members.profile.transactions.memberProfileTransactions.paymentReception' />}
                        other={<Translate id='members.profile.transactions.memberProfileTransactions.paymentReceptions' />}
                    />
                </div>
                <Card className='card-shadow mb-3 p-3'>
                    {paymentReceptions.sort(sortPaymentReception).map((paymentReception) => (
                        <PaymentReceptionItem
                            key={paymentReception.payment_reception_id}
                            paymentReception={paymentReception}
                            invoiceNumber={invoiceNumber}
                            onDeletePaymentReception={onDeletePaymentReception}
                            toggleEdit={toggleEdit}
                            disableActions={disableActions}
                        />
                    ))}
                    {/* to be aligned perfectly we would need to add a border-right because the payment reception cards have p-2 and border */}
                    {/* but that would mean adding a border-right and styling the border so it is white or transparent :( */}
                    {/* my OCD is going nuts, but it do be that way sometimes */}
                    <div className='text-right pr-2'>
                        <span className='font-bold text-dark'>
                            <Translate id='members.profile.transactions.memberProfileTransactions.total' />
                            <span className='mx-1'>:</span>
                        </span>
                        <CurrencyFormat value={paymentReceptions.reduce((totalAmount, payment) => totalAmount + parseInt(payment.amount), 0) / 100} />
                    </div>
                </Card>
            </div>

    );
}

const PaymentReceptionItem = ({ paymentReception, invoiceNumber, onDeletePaymentReception, toggleEdit, disableActions }) => {
    const [ noteIsOpen, setNoteOpen ] = useState(false);
    const membersContext = useContext(MembersContext);
    const history = useHistory();

    return (
        <Card className='border p-2 mb-2 card-shadow'>
            <div onClick={paymentReception.note ? () => { setNoteOpen(!noteIsOpen) } : undefined} className={stringBuilder({ 'clickable': !!paymentReception.note })}>
                <div className='d-flex justify-content-between'>
                    <DateFormat value={paymentReception.received_at} utc />
                    <UncontrolledDropdown inNavbar>
                        <DropdownToggle tag='button' className='reset-btn text-link' onClick={(e) => e.stopPropagation()} disabled={disableActions}><i className='mdi mdi-dots-horizontal font-20' /></DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={() => history.push('receipt?invoiceNumber=' + invoiceNumber + '&paymentReceptionId=' + paymentReception.payment_reception_id)}>
                                <Translate id='members.profile.transactions.memberProfileTransactions.paymentReceptions.viewReceipt' />
                            </DropdownItem>
                            <DropdownItem onClick={() => toggleEdit(paymentReception.payment_reception_id)}>
                                <Translate id='misc.edit' />
                            </DropdownItem>
                            <ConfirmModal
                                toggler={(toggle) => (
                                    <DropdownItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggle();
                                        }}
                                    ><Translate id='misc.delete' />
                                    </DropdownItem>
                                )}
                                modalTitle={<Translate id='members.profile.transactions.memberProfileTransactions.paymentReceptions.deleteText' values={{ date: <DateFormat value={paymentReception.received_at} utc /> }} />}
                                actionButtonText='misc.confirm'
                                translateActionButtonText
                                color='warning'
                                onConfirm={() => {
                                    return membersContext.deletePaymentReception(invoiceNumber, paymentReception.payment_reception_id)
                                        .then(() => {
                                            success();
                                            onDeletePaymentReception();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                }}
                            />
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <div className='d-flex justify-content-between'>
                    <div>
                        {paymentReception.payment_method ?
                            <DisplayI18n field='name' defaultValue={paymentReception.payment_method?.name} i18n={paymentReception.payment_method?.i18n} />
                            :
                            <Translate id={`members.profile.transactions.memberProfileTransactions.paymentMethods.MEMBER_CREDIT`} />
                        }
                    </div>
                    <CurrencyFormat value={paymentReception.amount / 100} />
                </div>
                {paymentReception.note &&
                    <>
                        <Collapse isOpen={noteIsOpen}>
                            <div className='bg-light p-1 rounded'>
                                {paymentReception.note}
                            </div>
                        </Collapse>
                        <div className='d-flex justify-content-center mb-n3 mt-n2'>
                            <i className='font-20 mdi mdi-chevron-down d-block' style={{ transition: 'transform 0.15s ease-in-out', transform: `rotate(${noteIsOpen ? '180' : '0'}deg)` }} />
                        </div>
                    </>
                }
            </div>
        </Card>
    )
}

TransactionPaymentReceptions.propTypes = {
    paymentReceptions: PropTypes.array.isRequired,
};

export default TransactionPaymentReceptions;