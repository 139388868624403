import { useDisclosure } from "@mantine/hooks";
import IsDev from "../../../../../components/permissions/IsDev";
import AddFilterModal from "./AddFilterModal";

const AddFilter = () => {
    const [ isOpen, isOpenHandlers ] = useDisclosure();

    return (
        <>
            <IsDev className="w-100 mb-3">
                <button type="button" onClick={isOpenHandlers.open} className="reset-btn text-dev px-2 w-100 text-center">+ Link new filter</button>
            </IsDev>
            <AddFilterModal toggle={isOpenHandlers.close} isOpen={isOpen} />
        </>
    );
}

export default AddFilter;