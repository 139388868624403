import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    Button,
    Alert,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import SpordlePanelTable from '../../../../../components/sidePanel/SpordlePanel';
import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from '@spordle/datatables';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import TeamStaffSidepanel from './TeamStaffSidepanel';
import TeamRosterAdd from '../TeamrosterAdd/TeamRosterAdd';
import { displayI18n, DisplayI18n } from '../../../../../helpers/i18nHelper';
import HasAccessTo from '../../../../../components/permissions/HasAccessTo';
import { getTeamMemberStatus } from '../../../TeamHelpers';
import EmptyLayout from '../../../../../components/table/EmptyLayout';
import { I18nContext } from '../../../../../contexts/I18nContext';
import TeamMemberAge from '../components/TeamMemberAge';
import { RolesContext } from '../../../../../contexts/RolesContext';
import moment from 'moment';
import MemberStatusIcons from '../../../../members/search/components/MemberStatusIcons';
import { stringBuilder } from '@spordle/helpers';
import CrossFade from '../../../../../components/crossFade/CrossFade';
import { TeamsContext } from '../../../../../contexts/TeamsContext';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import CanDoAction from '../../../../../components/permissions/CanDoAction';
import SendCommModal from '../../../../../components/communications/SendCommModal';
import { useDisclosure } from '@mantine/hooks';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';
import TeamRosterTableHeader from '../components/TeamRosterTableHeader';
import GenderLabel from '../../../../../components/GenderLabel';

const TeamStaff = ({ teamId, lockedRoster, lockedRosterDate }) => {
    const spordleTableRef = useRef(null);
    const i18nContext = useContext(I18nContext);
    const rolesContext = useContext(RolesContext);
    const teamsContext = useContext(TeamsContext)
    const { isGod } = useContext(IdentityRolesContext);
    const canAddToRoster = !lockedRoster || rolesContext.canDoAction('ADD', 'teams', 'bypass_lock_roster_date') || isGod();

    const [ addModal, setAddModal ] = useState(false)
    const toggleAddModal = () => setAddModal(!addModal)
    const [ sendCommIsOpen, sendCommIsOpenHandlers ] = useDisclosure(false);

    const [ isOpen, isOpenHandlers ] = useDisclosure(false)

    useEffect(() => {
        if(teamsContext.rosterTablesLoading){
            spordleTableRef.current?.setLoading();
        }
    }, [ teamsContext.rosterTablesLoading ])

    useEffect(() => {
        if(teamsContext.rosterTablesDataError){
            spordleTableRef.current?.setError();
        }
    }, [ teamsContext.rosterTablesDataError ])

    useEffect(() => {
        if(teamsContext.rosterTablesData){
            spordleTableRef.current?.setData(teamsContext.formatDataStaff(teamsContext.rosterTablesData), 'success')
        }
    }, [ teamsContext.rosterTablesData ])

    const displayLockNotice = (alert = false) => {
        const displayAlert = (text) => (
            <Alert color="secondary">
                <div><i className="w-100 mr-1 mdi mdi-lock-outline" />{text}</div>
                {(rolesContext.canDoAction('ADD', 'teams', 'bypass_lock_roster_date') || isGod()) &&
                    <div className='small text-muted'>
                        <i className='mdi mdi-star text-warning' /> <Translate id='teams.profile.roster.lock.bypass' />
                    </div>
                }
            </Alert>
        )
        const displayLabel = (text) => <div className='font-medium small text-muted'><i className="mr-1 mdi mdi-lock-open-outline" />{text}</div>

        if(!lockedRoster && lockedRosterDate){ // show date
            if(alert){
                return displayAlert(<><Translate id="teams.profile.roster.lockdate" />: {moment(lockedRosterDate).format('lll')}</>)
            }
            return displayLabel(<><Translate id="teams.profile.roster.lockdate" />: {moment(lockedRosterDate).format('lll')}</>)
        }else if(lockedRoster){
            if(alert){
                return displayAlert(<Translate id="teams.profile.roster.locked" />)
            }
            return displayLabel(<Translate id="teams.profile.roster.locked" />)
        }
    }

    const displayCount = (spordleTable) => {
        return (
            <span className='ml-2'>
                (
                {spordleTable.getData().length}
                {(!!teamsContext.filters && Object.keys(teamsContext.filters)?.length !== 0) &&
                    ' / ' + teamsContext.formatDataStaff(teamsContext.completeRoster).length
                }
                )
            </span>
        )
    }

    return (
        <Card className={stringBuilder("card-shadow", { "clickable": !isOpen })} onClick={() => !isOpen ? isOpenHandlers.toggle() : null}>
            {/* loading for when the card is collapsed */}
            <OverlayLoader isLoading={(teamsContext.rosterTablesLoading && !isOpen)}>
                <CardBody>
                    <SpordlePanelTable
                        allowOutsideClick
                        sidePanel={(props) => <TeamStaffSidepanel {...props} lockedRoster={lockedRoster} />}
                        dataIndex='team_member_id'
                        table={(panelProps) => {
                            return (
                                <SpordleTableProvider
                                    tableHover bordered striped
                                    clickable
                                    id='teamStaff'
                                    ref={(r) => { spordleTableRef.current = r; panelProps.spordleTableRef(r) }}
                                    desktopWhen
                                    dataIndex='team_member_id'
                                    emptyLayout={<EmptyLayout />}
                                    searchKeys={[
                                        { name: 'member.unique_identifier', weight: 2 },
                                        'name',
                                        'member.first_name',
                                        'member.last_name',
                                        'registration_date',
                                        `position.i18n.${i18nContext.getGenericLocale()}.name`,
                                        'position.name',
                                    ]}
                                    tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                    loadData={(from, data, spordleTable) => {
                                        switch (from){
                                            case 'REFRESH':
                                                teamsContext.getRosterTablesData(teamId)
                                            default:
                                                break;
                                        }
                                    }}
                                    columnSorting={(teamMemberA, teamMemberB, _spordleTable, order) => {
                                        switch (order.column){
                                            case 'position':
                                                const valueAPosition = displayI18n('name', teamMemberA.position.i18n, teamMemberA.position.name, i18nContext.getGenericLocale())
                                                const valueBPosition = displayI18n('name', teamMemberB.position.i18n, teamMemberB.position.name, i18nContext.getGenericLocale())
                                                return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueAPosition, valueBPosition) * (order.order === 'ASC' ? 1 : -1);
                                        }
                                    }}
                                    defaultSorting='+name'
                                    columns={[
                                        {
                                            label: <Translate id='teams.profile.roster.table.addedDate' />,
                                            key: 'addedDate',
                                            className: 'th-shrink',
                                            dataClassName: 'td-shrink',
                                            sortKey: 'registration_date',
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='form.fields.name' />,
                                            key: 'name',
                                            sortable: true,
                                            sortKey: 'member.last_name',
                                        },
                                        {
                                            label: <Translate id='teams.profile.roster.table.position' />,
                                            key: 'position',
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='teams.profile.roster.table.teamMemberStatus' />,
                                            key: 'teamMemberStatus',
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='teams.profile.roster.table.status' />,
                                            key: 'status',
                                            sortable: true,
                                        },
                                    ]}
                                    renderRow={(columnKey, teamMember) => {
                                        switch (columnKey){
                                            case 'addedDate':
                                                return (
                                                    teamMember.registration_date ?
                                                        <DateFormat value={teamMember.registration_date} />
                                                        : '-'
                                                )
                                            case 'name':
                                                return (
                                                    <UserDisplay>
                                                        <UserDisplay.Container>
                                                            <UserImg
                                                                abbr={teamMember.member.first_name.charAt(0) + teamMember.member.last_name.charAt(0)}
                                                                src={teamMember.member.picture?.full_path}
                                                                filePos={teamMember.member.picture?.file_position}
                                                                width={40}
                                                                height={40}
                                                                alt={teamMember.member.first_name + ' ' + teamMember.member.last_name}
                                                            />
                                                        </UserDisplay.Container>
                                                        <UserDisplay.Container>
                                                            <UserDisplay.Title>
                                                                <div className='d-flex'>
                                                                    {teamMember.member.first_name + ' ' + teamMember.member.last_name}
                                                                </div>
                                                            </UserDisplay.Title>
                                                            <UserDisplay.Subtitle>
                                                                <HasAccessTo componentCode='members' componentPermissionCode='members_profile'>
                                                                    <Link className="text-nowrap" to={"/members/profile/" + teamMember.member.member_id}>#{teamMember.member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></Link>
                                                                </HasAccessTo>
                                                            </UserDisplay.Subtitle>
                                                            <UserDisplay.Subtitle>
                                                                <GenderLabel gender={teamMember.member.gender} />
                                                            </UserDisplay.Subtitle>
                                                            <TeamMemberAge member={teamMember} />
                                                        </UserDisplay.Container>
                                                        <UserDisplay.Container className="ml-auto">
                                                            <MemberStatusIcons member={{ ...teamMember.member, crc_is_expired: teamMember.crc_is_expired }} />
                                                        </UserDisplay.Container>
                                                    </UserDisplay>
                                                )
                                            case 'position':
                                                return (
                                                    <>
                                                        {teamMember.position ?
                                                            <DisplayI18n field='name' defaultValue={teamMember.position.name} i18n={teamMember.position.i18n} />
                                                            : '-'}
                                                    </>
                                                )
                                            case 'teamMemberStatus':
                                                if(teamMember.team_member_status){
                                                    return <DisplayI18n field='name' defaultValue={teamMember.team_member_status.name} i18n={teamMember.team_member_status.i18n} />
                                                }
                                                return '-'
                                            case 'status':
                                                return (
                                                    <>
                                                        {teamMember.status ?
                                                            <div className="mb-2">
                                                                {getTeamMemberStatus(teamMember.status)}
                                                            </div>
                                                            : '-'}
                                                    </>
                                                )
                                            default:
                                                break;
                                        }
                                    }}
                                    onColumnClick={(e, data) => {
                                        switch (e.button){
                                            case 0: // Left mouse button
                                                panelProps.onSingleSelectChange(data);
                                                break;
                                        }
                                    }}
                                    rowIsHighlighted={(data) => data.checked}
                                >
                                    {(spordleTable) => (
                                        <>
                                            {/* Collapse Card Roster Table Header */}
                                            <TeamRosterTableHeader
                                                title={<Translate id='teams.profile.roster.table.benchStaff.title' />}
                                                isOpen={isOpen}
                                                isOpenHandlers={isOpenHandlers}
                                                spordleTable={spordleTable}
                                                displayCount={displayCount}
                                                displayLockNotice={displayLockNotice}
                                            />

                                            {/* Roster table view and header search & actions */}
                                            <CrossFade isVisible={isOpen}>
                                                <div className='mb-2'>
                                                    {displayLockNotice(true)}
                                                    <div className='d-flex flex-wrap justify-content-between'>
                                                        <SearchInput />

                                                        <div className='d-flex ml-auto text-right'>
                                                            <Refresh />
                                                            {spordleTable.getData().length > 0 &&
                                                                <CanDoAction action="ADD" componentCode='communications' componentPermissionCode='communications_manage'>
                                                                    {(isGod) => (
                                                                        <UncontrolledDropdown inNavbar>
                                                                            <DropdownToggle caret color="primary" className="ml-2" outline><Translate id="misc.actions" /></DropdownToggle>
                                                                            <DropdownMenu right>
                                                                                <DropdownItem onClick={sendCommIsOpenHandlers.open} className={stringBuilder({ "text-purple": isGod })} id={spordleTable.generateId('send-communication')}>
                                                                                    <Translate id="communications.sendMessage" />
                                                                                </DropdownItem>
                                                                                <SendCommModal
                                                                                    isOpen={!!sendCommIsOpen}
                                                                                    toggle={sendCommIsOpenHandlers.close}
                                                                                    recipients={spordleTable.getData().map((participant) => ({ ...participant, ...participant.member }))}
                                                                                />
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    )}
                                                                </CanDoAction>
                                                            }
                                                            <TeamRosterAdd isOpen={addModal} toggle={toggleAddModal} from='bench' />

                                                            {isGod() ?
                                                                // this condition is only to display the button in purple and have the tooltip when the user is power user
                                                                // we don't want to use just CanDoAction because we want the button to be disabled, not hidden
                                                                <CanDoAction action='ADD' componentCode='teams' componentPermissionCode='bypass_lock_roster_date'>
                                                                    <Button
                                                                        id={spordleTable.generateId('add')}
                                                                        className='ml-2'
                                                                        color='primary'
                                                                        onClick={toggleAddModal}
                                                                    >
                                                                        <i className='ti-plus' /> <Translate id='misc.add' />
                                                                    </Button>
                                                                </CanDoAction>
                                                                :
                                                                <Button
                                                                    id={spordleTable.generateId('add')}
                                                                    className='ml-2'
                                                                    color='primary'
                                                                    onClick={toggleAddModal}
                                                                    disabled={!canAddToRoster}
                                                                >
                                                                    <i className={`${!canAddToRoster ? 'mdi mdi-lock-outline' : 'ti-plus'}`} /> <Translate id='misc.add' />
                                                                </Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <SpordleTableView />
                                            </CrossFade>
                                        </>
                                    )}
                                </SpordleTableProvider>
                            )
                        }}
                    />
                </CardBody>
            </OverlayLoader>
        </Card>
    );
}

export default TeamStaff;