import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { useFormikContext } from 'formik';
import { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Label, ModalBody, ModalFooter } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";
import UserDisplay from "../../../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../../../components/UserImg";
import { MembersContext } from '../../../../../../../../contexts/MembersContext';
import { DisplayI18n } from "../../../../../../../../helpers/i18nHelper";
import { DisplayCategory } from "../../../../../../../teams/TeamHelpers";
import InlineCopy from "../../../../../../../../components/inlineCopy/InlineCopy";
import { PeriodsContext } from "../../../../../../../../contexts/contexts";

const ComplaineeTeamStep = ({ currSubStep, previous }) => {
    const { values, setFieldValue, isSubmitting } = useFormikContext();
    const { getMemberTeams } = useContext(MembersContext);
    const [ teams, setTeams ] = useState(null);
    const { selectedPeriod } = useContext(PeriodsContext);

    useEffect(() => {
        if(currSubStep === 3){
            if(values.member?.member_id){
                getMemberTeams(values.member.member_id, { period_id: selectedPeriod?.period_id })
                    .then((teams) => {
                        setTeams(teams.filter((t) => t.active == 1));

                        if(Array.isArray(teams) && teams.length > 0){
                            if(!(teams || []).some(({ team }) => team?.team_id == values.team_id)){
                                setFieldValue('team_id', "");
                            }

                            setFieldValue("team_name", "");
                        }else{
                            setFieldValue("team_id", "");
                        }
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                            setTeams([]);
                        }
                    });
            }else if(values.first_name){
                setFieldValue("team_id", "");
            }
        }
    }, [ currSubStep ]);

    return (
        <>
            <ModalBody>
                <UserDisplay className="d-flex" card>
                    <UserDisplay.Container>
                        {!!values.first_name &&
                            <UserImg
                                abbr={values.first_name?.charAt(0) + values.last_name?.charAt(0)}
                                alt={values.first_name + ' ' + values.last_name}
                                className="text-uppercase"
                                filePos={values.member?.picture?.file_position}
                                src={values.member?.picture?.full_path}
                                width={50}
                            />
                        }
                    </UserDisplay.Container>
                    <UserDisplay.Container>
                        <UserDisplay.Title>{values.first_name} {values.last_name}</UserDisplay.Title>
                        {values.role &&
                            <UserDisplay.Subtitle>
                                <FormattedMessage id={`discrimination.global.roles.${values.role}`} defaultMessage="noTranslate">
                                    {(message) => (message[0] !== 'noTranslate') ? message : values.role || ''}
                                </FormattedMessage>
                            </UserDisplay.Subtitle>
                        }
                        {values.member?.unique_identifier &&
                            <UserDisplay.Subtitle>
                                <InlineCopy toCopy={values.member.unique_identifier}>
                                    #{values.member.unique_identifier}
                                </InlineCopy>
                            </UserDisplay.Subtitle>
                        }
                    </UserDisplay.Container>
                </UserDisplay>
                <p>
                    <Translate id='discrimination.form.team.helper' />
                </p>
                <div className="form-group">
                    <Label className="text-muted" for="belongs_to">
                        <Translate id='discrimination.label.team' />
                    </Label>
                    {(!values.member?.member_id || !teams || teams?.length <= 0) ?
                        <FormikInputText type="text" name="team_name" />
                        :
                        <FormikSelect
                            className="w-100"
                            name="team_id"
                            id="team_id"
                            clearable
                            renderOption={({ option: team }) => (
                                <div>
                                    <div>{team.label}</div>
                                    <div className='text-muted small'>
                                        {team.division ? team.division : <DisplayCategory category={team?.category} short /> }
                                        {team.category?.gender && <> - <Translate id={`form.fields.gender.${team.category.gender.toLowerCase()}`} /></>}
                                    </div>
                                </div>
                            )
                            }
                            defaultValue={values.member?.team?.team_id ? [ values.member.team.team_id ] : undefined}
                            options={teams ?
                                teams.reduce((keptTeams, team) => {
                                    if(team.active == 1){
                                        keptTeams.push({
                                            label: team.team.name,
                                            value: team.team.team_id,
                                            category: team.team.team_category,
                                            division: team.team.division,
                                            selected: values.member?.team?.team_id === team.team.team_id,
                                        })
                                    }
                                    return keptTeams;
                                }, [])
                                :
                                []
                            }
                        />
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    outline
                    type="button"
                    className="mr-auto"
                    onClick={previous}
                    disabled={isSubmitting}
                >
                    <Translate id="misc.previous" />
                </Button>
                <Button disabled={isSubmitting} color="primary" type="submit">
                    <Translate id="misc.submit" />
                </Button>
            </ModalFooter>
        </>
    )
}

export default ComplaineeTeamStep;