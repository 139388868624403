import * as Sentry from "@sentry/react";
import { FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import {
    Button, Card,
    CardBody, Col,
    FormGroup,
    Label, Row
} from "reactstrap";
import * as Yup from 'yup';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import Required from '../../../../components/formik/Required';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import CanDoAction from "../../../../components/permissions/CanDoAction";
import { fail } from "@spordle/toasts";
import { MerchantAccountContext } from '../../../../contexts/MerchantAccountContext';
import { OnlineStoreContext } from '../../../../contexts/OnlineStoreContext';
import { RebatesContext } from '../../../../contexts/RebatesContext';
import { TermsConditionsContext } from '../../../../contexts/TermsConditionsContext';
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import OnlineStorePaymentsOptions from './OnlineStorePaymentsOptions';

/**
* @param {object} props
* @param {string} props.onlineStoreId
* @param {boolean} [props.wizard]
* @param {boolean} [props.isEditing]
* @param {boolean} [props.toggle]
* @param {function} [props.onSubmit] used only in creation wizard to navigate between steps
*/
const OnlineStoreRebatesTerms = ({ toggle, onSubmit, ...props }) => {
    const onlineStoreContext = useContext(OnlineStoreContext);
    const rebatesContext = useContext(RebatesContext);
    const termsConditionsContext = useContext(TermsConditionsContext);
    const merchantAccountContext = useContext(MerchantAccountContext);

    const [ onlineStore, setOnlineStore ] = useState(null);

    const [ lists, setLists ] = useState({
        rebates: [],
        terms: [],
        merchantAccounts: [],
    })

    const formikRef = useRef(null);
    const rebatesRef = useRef(null);
    const termsRef = useRef(null);
    const merchantAccountRef = useRef(null);

    useEffect(() => {
        if(!props.wizard){
            Sentry.addBreadcrumb({
                type: 'info',
                message: `${props.isEditing ? 'Editing' : 'Reading'} online store rebates and terms`,
                level: Sentry.Severity.Log,
                category: 'OnlineStore',
            });
        }
    }, [ props.isEditing ])

    useEffect(() => {
        formikRef.current.setSubmitting(true);// Loading
        Promise.all([
            rebatesContext.getRebates(),
            onlineStoreContext.getOnlineStoreRebates(props.onlineStoreId),
            termsConditionsContext.getTermsConditions(),
            onlineStoreContext.getOnlineStoreTerms(props.onlineStoreId),
            onlineStoreContext.getOnlineStore(props.onlineStoreId, undefined, !!props.wizard),
            merchantAccountContext.getMerchantAccounts({ active: 1 }),
        ]).then(([ rebates, onlineStoreRebates, termsConditions, onlineStoreTerms, onlineStore, merchantAccounts ]) => {
            setLists({
                rebates: rebates,
                terms: termsConditions,
                merchantAccounts: merchantAccounts,
            })
            setOnlineStore({
                merchantAccount: onlineStore.merchant_account,
                paymentMethods: onlineStore.payment_methods?.map((pM) => pM.code) || [],
            })

            if(onlineStore.merchant_account){
                formikRef.current.setFieldValue('merchantAccount', onlineStore.merchant_account.merchant_account_id);
            }

            formikRef.current?.setValues({ // initiate the values for formik
                rebates: onlineStoreRebates.map((rebate) => rebate.rebate_id),
                terms: onlineStoreTerms[0]?.term_and_condition_id || '',
                paymentOptions: onlineStore.payment_methods?.map((pM) => pM.code) || [],
                merchantAccount: onlineStore.merchant_account?.merchant_account_id || '',
            });

            // Select the default rebates
            formikRef.current.setFieldValue('rebates', onlineStoreRebates.map((rebate) => rebate.rebate_id));

            if(onlineStoreTerms[0]){ // Already has a terms & conditions
                formikRef.current.setFieldValue('terms', onlineStoreTerms[0].term_and_condition_id);
            }

        })
            .then(() => {
                formikRef.current?.setSubmitting(false);// Stops loading
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message);
                    formikRef.current?.setSubmitting(false);// Stops loading
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, [])

    return (
        <Card className="card-shadow">
            <CardBody>
                <div className="d-flex align-items-end pb-1 border-bottom mb-3">
                    <div className="h4 font-bold card-title mb-0"><Translate id='onlineStore.create.step3.label' /></div>
                    {!props.isEditing &&
                        <CanDoAction className='ml-auto text-primary' action='EDIT' componentCode='page' componentPermissionCode='online_store_manage'>
                            <button type='button' className="reset-btn" onClick={toggle}><Translate id='misc.edit' /></button>
                        </CanDoAction>
                    }
                </div>

                <Formik
                    initialValues={{
                        rebates: [],
                        terms: '',
                        paymentOptions: [],
                        merchantAccount: '',
                    }}
                    validationSchema={Yup.object().shape({
                        terms: Yup.string().trim().required(<Translate id='onlineStore.components.onlineStoreRebatesTerms.terms.validation.required' />),
                        merchantAccount: Yup.string()
                            .test({
                                name: 'noPaymentMethods',
                                message: <Translate id='Un compte marchand est requis si aucune méthode de paiement est spécifiée.' />,
                                test: function(merchantAccount){
                                    return !(this.parent.paymentOptions.length === 0 && merchantAccount === undefined)
                                },
                            }),

                        paymentOptions: Yup.array()
                            .test({
                                name: 'noMerchantAccount',
                                message: <Translate id='onlineStore.components.onlineStorePaymentOptions.paymentMethod.required' />,
                                test: function(paymentMethods){
                                    return !(this.parent.merchantAccount === undefined && paymentMethods.length === 0)
                                },
                            }),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        Promise.all([
                            onlineStoreContext.updateOnlineStoreRebates(props.onlineStoreId, values.rebates),
                            onlineStoreContext.updateOnlineStoreTerms(props.onlineStoreId, values.terms ? [ values.terms ] : []),
                            // Updating merchant account only if it changed
                            values.merchantAccount === onlineStore?.merchantAccount?.merchant_account_id ?
                                Promise.resolve()
                                :
                                onlineStoreContext.partialUpdateOnlineStore(props.onlineStoreId, { merchant_account_id: values.merchantAccount })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    }),
                            // Updating payment options only if it changed
                            values.paymentOptions.toString() === onlineStore?.paymentMethods?.toString() ? Promise.resolve() : onlineStoreContext.updateOnlineStorePaymentMethods(props.onlineStoreId, values.paymentOptions),
                        ]).then(async() => {
                            const refreshedOnlineStore = await onlineStoreContext.getOnlineStore(props.onlineStoreId, undefined, true)
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        })
                                    }
                                });
                            setOnlineStore({
                                merchantAccount: refreshedOnlineStore.merchant_account,
                                paymentMethods: refreshedOnlineStore.payment_methods?.map((pM) => pM.code) || [],
                            })
                            setSubmitting(false);
                            onSubmit?.(values);
                            // No .catch defined here as it would use the .catch below
                        })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    setSubmitting(false);
                                    console.error(error.message);
                                }
                            })
                    }}
                    innerRef={formikRef}
                >
                    {(formik) => (
                        <div className='position-relative'>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <Form>
                                    <div className="h4 font-medium mb-3 mt-5"><Translate id='onlineStore.components.onlineStoreRebatesTerms.label' /></div>
                                    {/* Using d-none instead of unmounting the FormikSelects so we can still use the the api calls in this component without any problems */}
                                    <Row className={props.isEditing ? undefined : 'd-none'}>
                                        <Col sm="6" lg='5'>
                                            <FormGroup>
                                                <Label for='terms' className='text-muted'><Translate id='onlineStore.components.onlineStoreRebatesTerms.terms.label' /> <Required /></Label>
                                                <FormikSelect
                                                    ref={termsRef} name='terms' id='terms_OnlineStoreRebateTerms'
                                                    clearable
                                                    textWhenSetting={{
                                                        count: 1,
                                                        label: 'onlineStore.components.onlineStoreRebatesTerms.terms.textWhen',
                                                    }}
                                                    placeholder='onlineStore.components.onlineStoreRebatesTerms.terms.placeholder'
                                                    noOptionsMessage='onlineStore.components.onlineStoreRebatesTerms.terms.empty'
                                                    options={lists.terms.map((termCondition) => ({
                                                        id: termCondition.term_and_condition_id,
                                                        value: termCondition.term_and_condition_id,
                                                        label: termCondition.title,
                                                    })).sort((a, b) => a.label.localeCompare(b.label))
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" lg='5'>
                                            <FormGroup>
                                                <Label for='rebates' className='text-muted'><Translate id='onlineStore.components.onlineStoreRebatesTerms.rebates.label' /></Label>
                                                <FormikSelect
                                                    ref={rebatesRef} name='rebates' id='rebates_OnlineStoreRebatesterms'
                                                    clearable
                                                    placeholder='onlineStore.components.onlineStoreRebatesTerms.rebates.placeholder'
                                                    noOptionsMessage='onlineStore.components.onlineStoreRebatesTerms.rebates.empty'
                                                    options={lists.rebates.map((rebate) => ({
                                                        id: rebate.rebate_id,
                                                        value: rebate.rebate_id,
                                                        label: rebate.title,
                                                    })).sort((a, b) => a.label.localeCompare(b.label))
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {!props.isEditing &&
                                        <Row>
                                            <Col sm="6" lg='5'>
                                                <Label className='text-muted'><Translate id='onlineStore.components.onlineStoreRebatesTerms.terms.label' /></Label>
                                                <div>
                                                    <DisplayI18n
                                                        field='title'
                                                        defaultValue={lists.terms.find((term) => term.term_and_condition_id === formik.values.terms)?.title}
                                                        i18n={lists.terms.find((term) => term.term_and_condition_id === formik.values.terms)?.i18n}
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm="6" lg='5'>
                                                <Label className='text-muted'><Translate id='onlineStore.components.onlineStoreRebatesTerms.rebates.label' /></Label>
                                                {(!Array.isArray(formik.values.rebates) ? [ formik.values.rebates ] : formik.values.rebates).length > 0 ?
                                                    <ul className="list-unstyled font-medium">
                                                        {(!Array.isArray(formik.values.rebates) ? [ formik.values.rebates ] : formik.values.rebates).map((rebateId) => {
                                                            return (
                                                                <DisplayI18n
                                                                    key={rebateId}
                                                                    field='title'
                                                                    defaultValue={lists.rebates.find((rebate) => rebate.rebate_id === rebateId)?.title}
                                                                    i18n={lists.rebates.find((rebate) => rebate.rebate_id === rebateId)?.i18n}
                                                                />
                                                            )
                                                        })}
                                                    </ul>
                                                    :
                                                    <div><Translate id="onlineStore.components.onlineStoreRebatesTerms.rebates.empty" /></div>
                                                }
                                            </Col>
                                        </Row>
                                    }

                                    <div className="h4 font-medium mb-3 mt-5"><Translate id='onlineStore.components.onlineStorePaymentOptions.title' /></div>
                                    <OnlineStorePaymentsOptions onlineStoreId={props.onlineStoreId} isEditing={props.wizard || props.isEditing} onlineStore={onlineStore} merchantAccountRef={merchantAccountRef} merchantAccounts={lists.merchantAccounts} />

                                    <div className="d-flex justify-content-end mt-3">
                                        {props.wizard ?
                                            props.isEditing &&
                                                <>
                                                    <Button color="primary" outline onClick={toggle} type='button' className="mr-auto"><Translate id='misc.previous' /></Button>
                                                    <Button color="primary" type='submit'><Translate id='misc.next' /></Button>
                                                </>
                                            :
                                            props.isEditing &&
                                                <>
                                                    <Button color="primary" type='submit'><Translate id='misc.save' /></Button>
                                                    <Button color="primary" outline onClick={toggle} type='button' className="ml-2"><Translate id='misc.cancel' /></Button>
                                                </>
                                        }
                                    </div>
                                </Form>
                            </OverlayLoader>
                        </div>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
}

export default OnlineStoreRebatesTerms;