import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import DisplayBirthdateAge from "../../../../../components/DisplayBirthdateAge";
import InlineCopy from "../../../../../components/inlineCopy/InlineCopy";
import QuickViewButton from "../../../../../components/quickView/QuickViewButton";
import { MembersContext } from "../../../../../contexts/MembersContext";
import MemberOverviewStatus from "./MemberOverviewStatus";

const MemberOverviewTopInfo = () => {
    const { currentMember } = useContext(MembersContext);
    const statusLogs = currentMember.member_status_logs;
    const lastLog = statusLogs ? statusLogs[statusLogs.length - 1] : null;

    return (
        <div className="text-dark mb-2">
            <MemberOverviewStatus lastLog={lastLog} />
            <div className="mr-md-3 h3 mt-1 mb-0 font-medium font-bold">
                <QuickViewButton member={currentMember}>
                    {currentMember.first_name} {currentMember.last_name}
                </QuickViewButton>
            </div>
            <InlineCopy tag='div' toCopy={currentMember.unique_identifier} className="font-medium">
                <Translate id='organisation.profile.header.memberNumber' /> {currentMember.unique_identifier || '-'}
            </InlineCopy>
            {currentMember.externalIdentifiers && currentMember.externalIdentifiers.map((externalId, key) => (
                // eslint-disable-next-line react/no-array-index-key
                <InlineCopy key={key + '-' + externalId.partner.api_partner_id} tag='div' toCopy={externalId.external_id} className="font-medium">
                    {externalId.partner.name} {externalId.external_id || '-'}
                </InlineCopy>
            ))}
            <DisplayBirthdateAge className="font-medium" birthdate={currentMember.birthdate} age={currentMember.age} />
        </div>
    );
}

export default MemberOverviewTopInfo;