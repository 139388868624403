export default{
    "organizationTree.organizationSearch.modal.org.tree.title"       : "Organization Tree",
    "organizationTree.organizationSearch.modal.org.tree.searchLabel" : "Search an organization",
    "organizationTree.organizationSearch.modal.org.tree.selectAll"   : "{selectAll, select, true {Select} other {Unselect}} all sub-organizations",
    "organizationTree.organizationSearch.modal.org.tree.exactMatch"  : "Exact match",
    "organizationTree.organizationSearch.action.seeTree"             : "View Tree",

    "organizationTree.organizationSearch.select.noOrg"                 : "No organizations",
    "organizationTree.organizationSearch.select.placeholder"           : "Search organizations",
    "organizationTree.organizationSearch.select.valueContainer.plural" : "{count} organizations selected",

    "organizationTree.organizationSearch.display_organization.tooltip" : "Click to change organizations",
}