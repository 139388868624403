import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from "../contexts/contexts";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <AuthContext.Consumer>
        {({ isAuthenticated }) => (
            <Route
                {...rest} render={(props) => {
                    if(!isAuthenticated){
                    // not logged in so redirect to login page with the return url
                        return <Redirect to={{ pathname: '/auth', search: props.location.search, state: { from: props.location } }} />
                    }

                    // authorised so return component
                    return <Component {...props} />
                }}
            />
        )}
    </AuthContext.Consumer>
)

export default PrivateRoute;