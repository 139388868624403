import { serverError } from "../CancellableAPI";
import queryString from "query-string";
import { jsObjectToApi } from "@spordle/helpers";
import API_SPORDLE from "../API-Spordle";

/**
   * Creates a meta member for the given identity
   * @param {string} memberId Member ID to create a meta member for
   * @param {string} identityId Identity ID to create a meta member for
   * @returns {Promise<string>}
   */
export function getPendingClassificationRequestChanges(queryParams) {
    return API_SPORDLE.get(queryString.stringifyUrl({
        url: `/classification-request-changes/approbations`,
        query: { ...queryParams },
    }))
        .then((response) => {
            if (response.data.status) {
                return response.data.classification_request_changes.map((request) => ({
                    ...request,
                    member: response.data.members[request.member_id],
                    organisation: response.data.organisations[request.organisation_id],
                    created_by: response.data.identities[request.created_by],
                    period: response.data.periods[request.period_id],
                    status_updated_by_organisation: response.data.organisations[request.status_updated_by_organisation],
                    status_updated_by: response.data.identities[request.status_updated_by],
                    from_division: response.data.divisions[request.from_division_id],
                    from_team_category: response.data.team_categories[request.from_team_category_id],
                    to_division: response.data.divisions[request.to_division_id],
                    to_team_category: response.data.team_categories[request.to_team_category_id],
                }))
            }
            throw response.data.errors[0];
        }, serverError)
}

/**
 * Create a member suspension attachment
 * @param {string} memberId
 * @param {Array} data_to_change
 * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Suspension%20Attachments/Apicontroller%5CMembers%5CMembersuspensionattachments%3A%3AcreateMemberSuspensionAttachments|documentation}
 * @returns {Promise.<Array>}
 */
export function createMemberClassificationChange(data) {
    const params = new URLSearchParams();
    jsObjectToApi(data, params);
    return API_SPORDLE.post(queryString.stringifyUrl({ url: `/classification-request-changes` }), params)
        .then((response) => {
            if (response.data.status) {
                return response.data.member_request_id;
            }
            throw response.data.errors[0];
        }, serverError)
}

export function updateClassificationRequestChangeStatus(classificationRequestChangeIdId, values) {
    const params = new URLSearchParams()
    jsObjectToApi(values, params)

    return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/classification-request-changes/${classificationRequestChangeIdId}` }), params)
        .then((response) => {
            if (response.data.status) {
                return true
            }
            throw response.data.errors[0];
        }, serverError)
}

/**
 * gets statuses for classification change requests
 * @returns {Promise<string>}
 */
export function getMemberClassificationRequestStatus() {
    return API_SPORDLE.get(queryString.stringifyUrl({
        url: `/request-changes-status`,
    }))
        .then((response) => {
            if (response.data.status) {
                return response.data.request_change_status;
            }
            throw response.data.errors[0];
        }, serverError)
}

