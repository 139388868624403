export default{
    "members.profile.overview.memberMedicalNotes.title" : "Medical notes",

    "members.profile.overview.memberMedicalNotes.table.type"              : "Type",
    "members.profile.overview.memberMedicalNotes.table.severity"          : "Severity",
    "members.profile.overview.memberMedicalNotes.table.severity.critical" : "Critical",
    "members.profile.overview.memberMedicalNotes.table.severity.moderate" : "Moderate",
    "members.profile.overview.memberMedicalNotes.table.notes"             : "Notes",
    "members.profile.overview.memberMedicalNotes.table.emergency"         : "Emergency action",
    "members.profile.overview.memberMedicalNotes.table.hint.none1"        : "You can add a medical note",
    "members.profile.overview.memberMedicalNotes.table.hint.none2"        : "Add a medical note",

    "members.profile.overview.memberMedicalNotes.add.title"              : "Add a medical note",
    "members.profile.overview.memberMedicalNotes.add.type.required"      : "Type is required",
    "members.profile.overview.memberMedicalNotes.add.severity.required"  : "Severity is required",
    "members.profile.overview.memberMedicalNotes.add.emergency.required" : "Emergency action is required",
    "members.profile.overview.memberMedicalNotes.add.emergency.hint"     : "Example: Epipen",
}