export default{
    "members.search.memberSearch.title" : "Members",

    "members.search.memberSearch.header.dashboardView"         : "Dashboard View",
    "members.search.memberSearch.header.filter.previousSeason" : "Active Season vs Previous Season",
    "members.search.memberSearch.header.filter.allSeason"      : "Active Season vs All",

    "members.search.memberSearch.header.addMember"  : "Add Member",
    "members.search.memberSearch.header.members"    : "Members",
    "members.search.memberSearch.header.players"    : "Players",
    "members.search.memberSearch.header.coaches"    : "Coaches",
    "members.search.memberSearch.header.officials"  : "Officials",
    "members.search.memberSearch.header.volunteers" : "Volunteers",

    "members.search.memberSearch.filters.organization" : "Organization",
    "members.search.memberSearch.filters.type"         : "Type",
    "members.search.memberSearch.filters.status"       : "Status",
    "members.search.memberSearch.filters.gender"       : "Gender Identity",
    "members.search.memberSearch.filters.from"         : "From",
    "members.search.memberSearch.filters.to"           : "To",
    "members.search.memberSearch.filters.or"           : "Or",
    "members.search.memberSearch.filters.memberNumber" : "{unique_identifier}",

    "members.search.memberSearch.table.tabs.results" : "Search Results",
    "members.search.memberSearch.table.tabs.starred" : "Starred",

    "members.search.memberSearch.table.starred"      : "Starred",
    "members.search.memberSearch.table.status"       : "Status",
    "members.search.memberSearch.table.organization" : "Organization",

    "members.search.memberSearch.table.status.registered"   : "Registered",
    "members.search.memberSearch.table.status.unregistered" : "Unregistered",
    "members.search.memberSearch.table.status.renew"        : "Renew",


    "members.search.memberSearch.table.member"             : "Member",
    "members.search.memberSearch.table.member_status"      : "Status",
    "members.search.memberSearch.table.memberTypes"        : "Type(s)",
    "members.search.memberSearch.table.registrationStatus" : "Registration Status",
    "members.search.memberSearch.table.lastActiveSeason"   : "Last Active Season",
    "members.search.memberSearch.table.branch"             : "Branch",
    "members.search.memberSearch.table.divClass"           : "Div / Class",
    "members.search.memberSearch.table.team"               : "Team",
    "members.search.memberSearch.table.pagination.message" : "Member {itemStartPos} to {itemEndPos} out of a total of {totalItems} members",


    "members.search.memberSearch.table.gender.MALE"                    : "Male",
    "members.search.memberSearch.table.gender.FEMALE"                  : "Female",
    "members.search.memberSearch.table.gender.PREFER_NOT_TO_SAY"       : "Prefer not to say",
    "members.search.memberSearch.table.gender.PREFER_TO_SELF_DESCRIBE" : "Prefer to self-describe",
    "members.search.memberSearch.table.gender.NON_BINARY"              : "Non-binary",

    "members.search.memberSearch.unsignedCriticalWaiver.tooltip" : "Critical waiver needs to be signed",
    "members.search.memberSearch.missingFields.tooltip"          : "Required fields are missing",
    "members.search.memberSearch.missingFields.organization"     : "Your organization requires these fields:",
    "members.search.memberSearch.unconfirmed.address.tooltip"    : "New address to be confirmed.",
    "members.search.memberSearch.incomplete_profile.tooltip"     : "Member Profile Incomplete",
    "members.search.memberSearch.missingDocument"                : "Missing documents",
    "members.search.memberSearch.unconfirmed"                    : "Not confirmed",
    "members.search.memberSearch.unconfirmed.tooltip"            : "Not confirmed",

    "members.search.memberSearch.missingDocument.confirm"  : "Validate the required documents",
    "members.search.memberSearch.missingDocument.validate" : "Validate documents",

    "members.search.memberSearch.crc.tooltip" : "Background check is non existant or expired.",
}