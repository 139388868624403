import { useSpordleTable } from "@spordle/datatables";
import { Col, Row } from "reactstrap";
import { getTaxRateUnit } from "./taxHelper";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { Tooltip } from "@mantine/core";
import Translate from "@spordle/intl-elements";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import { useContext, useState } from "react";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { deleteOrganizationTaxNumber } from "../../../../../../api/client/organization";
import { success } from "@spordle/toasts";
import { stringBuilder } from "@spordle/helpers";
import EditableTaxNumber from "./EditableTaxNumber";
import { RolesContext } from "../../../../../../contexts/RolesContext";
import { IdentityRolesContext } from "../../../../../../contexts/IdentityRolesContext";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";

const OrganizationProfileTaxItem = ({ taxNumber }) => {
    const table = useSpordleTable();
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const { organisation_id } = useContext(OrganizationContext);
    const [ isLoading, setIsLoading ] = useState(false);
    const taxRate = taxNumber.tax_rate;
    const country = taxRate.country;
    const province = taxRate.province;
    const canEdit = canDoAction("EDIT", "organization", "organization_profile");

    const handleOnDelete = () => {
        return deleteOrganizationTaxNumber(organisation_id, taxNumber.tax_number_id)
            .then(() => {
                table.refreshTable();
                success();
            })
    }

    return (
        <div className={stringBuilder({ "has-loading-overlay": isLoading }, "border-bottom pb-3 mb-3")}>
            <Row form className="align-items-center">
                <Col sm={4} className="font-medium text-dark text-break">
                    {(canEdit || isGod()) ?
                        <div className="position-relative">
                            <EditableTaxNumber
                                isGodOnly={!canEdit}
                                setIsLoading={setIsLoading}
                                taxNumber={taxNumber}
                            />
                        </div>
                        :
                        `#${taxNumber.tax_number}`
                    }
                </Col>
                <Col sm={3}>
                    <span><DisplayI18n field="name" i18n={taxRate.i18n} defaultValue={taxRate.name} /></span>
                    <span className="pl-2 text-muted">({taxRate.rate}{getTaxRateUnit(taxRate.type)})</span>
                </Col>
                <Col sm={2}>
                    <DisplayI18n field="name" i18n={province.i18n} defaultValue={province.code} />
                </Col>
                <Col sm={2}>
                    <DisplayI18n field="name" i18n={country.i18n} defaultValue={country.code} />
                </Col>
                <Col sm={1} className="text-right">
                    <CanDoAction action="DELETE" componentCode="organization" componentPermissionCode="organization_profile">
                        {(godOnly) => ( // GodOnly because of helper class that overwrites parent colors
                            <ConfirmModal
                                translateModalMsg
                                modalTitle={
                                    <>
                                        <div className="h5">
                                            <span><DisplayI18n field="name" i18n={taxRate.i18n} defaultValue={taxRate.name} /></span>
                                            <span className="pl-2 text-muted">({taxRate.rate}{getTaxRateUnit(taxRate.type)})</span>
                                        </div>
                                        <div className="font-medium">#{taxNumber.tax_number}</div>
                                    </>
                                }
                                modalContent={
                                    <>
                                        <div><Translate id="members.profile.transactions.memberProfileTransactions.sidePanel.actions.void.text" /></div>
                                    </>
                                }
                                onConfirm={handleOnDelete}
                                toggler={(fn) => (
                                    <Tooltip
                                        label={<Translate id="misc.delete" />}
                                    >
                                        <button
                                            disabled={isLoading}
                                            onClick={fn}
                                            type="button"
                                            className={`fa fa-trash reset-btn text-${godOnly ? 'purple' : 'danger'} p-3 my-n3 mr-n3`}
                                        />
                                    </Tooltip>
                                )}
                            />
                        )}
                    </CanDoAction>
                </Col>
            </Row>
        </div>
    )
}

export default OrganizationProfileTaxItem;