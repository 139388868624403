import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useState } from "react"
import { Carousel, CarouselItem } from "reactstrap";
import DashboardWidget from "../DashboardWidget";

const CarousselWidget = ({ data, mainWidget }) => {
    const [ step, setStep ] = useState(0);
    const [ animating, setAnimating ] = useState(false);

    const next = () => {
        if(animating)return;
        setStep(step === data.length - 1 ? 0 : step + 1)
    }

    const previous = () => {
        if(animating)return;
        setStep(step === 0 ? data.length - 1 : step - 1)
    }

    const goToIndex = (newIndex) => {
        if(animating)return;
        setStep(newIndex);
    }

    return (
        <div className="h-100 px-4 pb-4 min-h-0 dashboard-grid-carousel">
            <Carousel
                activeIndex={step}
                next={next}
                previous={previous}
                className='h-100 px-4 pb-4 pt-0 min-h-0'
                enableTouch
                pause='hover'
                keyboard
            >
                <div className="position-absolute bottom-0 left-0 right-0 mb-n3 d-flex flex-center">
                    <a className="clickable" role="button" tabIndex="0" onClick={previous} id={`${mainWidget.widgetId}-carousel-prev`}>
                        <i className="mdi mdi-chevron-left text-dark font-20" />
                        <span className="sr-only"><Translate id='misc.previous' /></span>
                    </a>
                    <ol className="carousel-dots-container mt-0">
                        {data.map((widget, index) => (
                            <li
                                key={`carousel-dot-${widget.widgetId}`}
                                onClick={() => goToIndex(index)}
                                className={stringBuilder("carousel-dots-single", { "active": step === index })}
                                style={{ height: 7, width: 7 }}
                                id={`${mainWidget.widgetId}-carousel-dot-${widget.widgetId}`}
                            />
                        ))}
                    </ol>
                    <a className="clickable" role="button" tabIndex="0" onClick={next} id={`${mainWidget.widgetId}-carousel-next`}>
                        <i className="mdi mdi-chevron-right text-dark font-20" />
                        <span className="sr-only"><Translate id='misc.next' /></span>
                    </a>
                </div>
                {data.map((widget) => (
                    <CarouselItem
                        key={widget.widgetId}
                        onExiting={() => { setAnimating(true) }}
                        onExited={() => { setAnimating(false) }}
                        className='h-100'
                    >
                        <DashboardWidget widget={widget} fromCaroussel />
                    </CarouselItem>
                ))}
            </Carousel>
        </div>
    );
}

export default CarousselWidget