import { useState } from 'react';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';
import AddOrganizationVenuesModalForm from './components/AddOrganizationVenuesModalForm';
import AddOrganizationVenuesModalSelect from './components/AddOrganizationVenuesModalSelect';

const AddOrganizationVenuesModal = ({ isOpen, toggle }) => {
    const [ currentView, setCurrentView ] = useState(0);
    const toggleAddLocationForm = () => setCurrentView(currentView === 0 ? 1 : 0);

    return (
        <AnalyticsModal backdrop='static' isOpen={isOpen} toggle={toggle} onClosed={() => setCurrentView(0)} analyticsName='AddOrganizationVenuesModal'>
            { !currentView ?
                <AddOrganizationVenuesModalSelect toggle={toggle} toggleAddLocationForm={toggleAddLocationForm} />
                :
                <AddOrganizationVenuesModalForm toggle={toggle} toggleAddLocationForm={toggleAddLocationForm} />
            }
        </AnalyticsModal>
    )
}

export default AddOrganizationVenuesModal;