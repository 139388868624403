import { useContext, useState } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Label
} from "reactstrap";
import { FormikInputText, FormikSelect, FormikInputNumber, FormikCurrencyInput } from '@spordle/formik-elements';
import FormikEditable from '../../../../../components/formik/FormikEditable';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';

import { TeamsContext } from '../../../../../contexts/TeamsContext';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { fail, success } from '@spordle/toasts';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import TravelPermitSidepanelHostCategory from './TravelPermitSidepanelHostCategory';

const TravelPermitSidepanelHost = ({ data, syncRows, createNewValues, travelTypes }) => {
    const teamsContext = useContext(TeamsContext);
    const i18nContext = useContext(I18nContext);
    const [ isLoading, setIsLoading ] = useState(false);

    return (
        <OverlayLoader isLoading={isLoading}>
            <Row>
                {/* Event name */}
                <Col sm="6">
                    <FormGroup>
                        <Label for='travel_event_name' className='text-muted mb-0'><Translate id='travelPermit.sidePanelHost.name' /></Label>
                        <FormikEditable
                            id='travel_event_name'
                            initialValues={{
                                travel_event_name: data.travel_event_name || '',
                            }}
                            onSubmit={(values) => {
                                if(values.travel_event_name !== data.travel_event_name){
                                    setIsLoading(true);
                                    teamsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                        .then(() => {
                                            const newValues = createNewValues(values);
                                            syncRows(newValues);
                                            success({ msg: 'travelPermit.toast.success' });
                                            setIsLoading(false);
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                            setIsLoading(false);
                                        })
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{data.travel_event_name}</div>
                                    )
                                }
                                return (
                                    <FormikInputText id='travel_event_name' name='travel_event_name' />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                {/* Reference Number (travel_id) */}
                <Col sm="6">
                    <FormGroup>
                        <Label for='travel_id' className='text-muted mb-0'><Translate id='travelPermit.sidePanelHost.travel_id' /></Label>
                        <FormikEditable
                            id='travel_id'
                            initialValues={{
                                travel_id: data.travel_id || '',
                            }}
                            onSubmit={(values) => {
                                if(values.travel_id !== data.travel_id){
                                    setIsLoading(true);
                                    teamsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                        .then(() => {
                                            const newValues = createNewValues(values);
                                            syncRows(newValues);
                                            success({ msg: 'travelPermit.toast.success' });
                                            setIsLoading(false);
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                            setIsLoading(false);
                                        })
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>
                                            {data.travel_id || '-'}
                                        </div>
                                    )
                                }
                                return (
                                    <FormikInputText id='travel_id' name='travel_id' />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                {/* Reference Number (travel_id) */}
                <Col sm="12">
                    <FormGroup>
                        <Label for='travel_permit_fee' className='text-muted mb-0'><Translate id='travelPermit.fee' /></Label>
                        <FormikEditable
                            id='travel_permit_fee'
                            initialValues={{
                                travel_permit_fee: data.travel_permit_fee ? ((parseInt(data.travel_permit_fee)) / 100) : '',
                            }}
                            onSubmit={(values) => {
                                if(values.travel_permit_fee !== data.travel_permit_fee){
                                    setIsLoading(true);
                                    const submitData = { ...values };
                                    if(submitData.travel_permit_fee){
                                        submitData.travel_permit_fee = (submitData.travel_permit_fee * 100).toFixed(0);
                                    }
                                    teamsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, submitData)
                                        .then(() => {
                                            const newValues = createNewValues(submitData);
                                            syncRows(newValues);
                                            success({ msg: 'travelPermit.toast.success' });
                                            setIsLoading(false);
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                            setIsLoading(false);
                                        })
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>
                                            {data.travel_permit_fee ?
                                                <CurrencyFormat value={(parseInt(data.travel_permit_fee)) / 100} />
                                                :
                                                '-'}
                                        </div>
                                    )
                                }
                                return (
                                    <FormikCurrencyInput
                                        id='travel_permit_fee'
                                        name='travel_permit_fee'
                                        className="mb-1"
                                        allowNegative={false}
                                    />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                {/* Event type */}
                <Col sm="12">
                    <FormGroup>
                        <Label for='travel_type_id' className='text-muted mb-0'>
                            <Translate id='travelPermit.addModal.travelIdLabel' />
                        </Label>
                        <FormikEditable
                            id='travel_type_id'
                            initialValues={{
                                travel_type_id: data.travel_type.travel_type_id || '',
                            }}
                            noConfirmation
                            onSubmit={(values) => {
                                if(values.travel_type_id !== data.travel_type.travel_type_id){
                                    setIsLoading(true);
                                    teamsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                        .then(() => {
                                            const travelType = travelTypes.current.find((tT) => tT.travel_type_id == values.travel_type_id);
                                            syncRows(createNewValues({ travel_type: travelType }));
                                            success({ msg: 'travelPermit.toast.success' });
                                            setIsLoading(false);
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                            setIsLoading(false);
                                        })
                                }
                            }}
                        >
                            {(isEditing, options) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>
                                            {data.travel_type ?
                                                <DisplayI18n
                                                    field="name"
                                                    i18n={data.travel_type.i18n}
                                                    defaultValue={data.travel_type.name}
                                                />
                                                :
                                                '-'
                                            }
                                        </div>
                                    )
                                }
                                return (
                                    <FormikSelect
                                        name='travel_type_id'
                                        id='travel_type_id'
                                        onOptionSelected={options.stopEditing}
                                        menuIsDefaultOpen
                                        autoFocus
                                        search
                                        searchKeys={[
                                            `i18n.${i18nContext.getGenericLocale()}.name`,
                                        ]}
                                        renderOption={(opt) => <DisplayI18n field="name" defaultValue={opt.option.label} i18n={opt.option.i18n} />}
                                        isLoading={!travelTypes.current}
                                        noOptionLayout={<Translate id='travelPermit.sidePanelGeneral.noOptions' />}
                                        options={travelTypes.current ?
                                            travelTypes.current.map((travelType) => ({
                                                i18n: travelType.i18n,
                                                label: travelType.name,
                                                value: travelType.travel_type_id,
                                            }))
                                            :
                                            []
                                        }
                                    />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                <Col sm={12}>
                    <TravelPermitSidepanelHostCategory
                        syncRows={syncRows}
                        createNewValues={createNewValues}
                        setIsLoading={setIsLoading}
                        data={data}
                    />
                </Col>
            </Row>
            {true &&
                <Row>
                    {/* host_website */}
                    <Col sm="12" className="mb-3">
                        <Label for='host_website' className='text-muted mb-0'><Translate id='travelPermit.sidePanelHost.website' /></Label>
                        <FormikEditable
                            id='host_website'
                            initialValues={{
                                host_website: data.host_website || '',
                            }}
                            onSubmit={(values) => {
                                if(values.host_website !== data.host_website){
                                    setIsLoading(true);
                                    teamsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                        .then(() => {
                                            const newValues = createNewValues(values);
                                            syncRows(newValues);
                                            success({ msg: 'travelPermit.toast.success' });
                                            setIsLoading(false);
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                            setIsLoading(false);
                                        })
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{data.host_website || '-'}</div>
                                    )
                                }
                                return (
                                    <FormikInputText id='host_website' name='host_website' />
                                )

                            }}
                        </FormikEditable>
                    </Col>
                </Row>
            }
            {true &&
                <Row>
                    {/* host_team_name */}
                    <Col sm="12">
                        <FormGroup>
                            <Label for='host_team_name' className='text-muted mb-0'><Translate id='travelPermit.sidePanelHost.hostTeamName' /></Label>
                            <FormikEditable
                                id='host_team_name'
                                initialValues={{
                                    host_team_name: data.host_team_name || '',
                                }}
                                onSubmit={(values) => {
                                    if(values.host_team_name !== data.host_team_name){
                                        setIsLoading(true);
                                        teamsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                            .then(() => {
                                                const newValues = createNewValues(values);
                                                syncRows(newValues);
                                                success({ msg: 'travelPermit.toast.success' });
                                                setIsLoading(false);
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                                setIsLoading(false);
                                            })
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{data.host_team_name || '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikInputText id='host_team_name' name='host_team_name' />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                    {/* nb_games */}
                    <Col sm="12">
                        <FormGroup>
                            <Label for='nbGames' className='text-muted mb-0'><Translate id='travelPermit.sidePanelHost.nbGames' /></Label>
                            <FormikEditable
                                id='nbGames'
                                initialValues={{
                                    nbGames: data.nb_games || '',
                                }}
                                onSubmit={(values) => {
                                    if(values.nb_games !== data.nb_games && values.nb_games > 0){
                                        setIsLoading(true);
                                        teamsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                            .then(() => {
                                                const newValues = createNewValues(values);
                                                syncRows(newValues);
                                                success({ msg: 'travelPermit.toast.success' });
                                                setIsLoading(false);
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                                setIsLoading(false);
                                            })
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{data.nb_games || '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikInputNumber id='nb_games' name='nb_games' />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                </Row>
            }
        </OverlayLoader>
    );
}

export default TravelPermitSidepanelHost;