import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';


import { AxiosCancelAll, AxiosIsCancelled } from '../../../api/CancellableAPI';
import TabRoutesNavs from '../../../components/subSidebar/TabRoutesNavs';
import TabRoutesPanes from '../../../components/subSidebar/TabRoutesPanes';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import { RolesContext } from '../../../contexts/RolesContext';
import { TeamsContext } from '../../../contexts/TeamsContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import teamProfileTabsRoutes from '../../../routes/tabRoutes/teamProfileTabsRoutes';
import TeamHeader from './TeamHeader/TeamHeader';
import { triggerWarningToast } from './triggerWarningToast';
import { AppContext, PeriodsContext } from '../../../contexts/contexts';
import { fail } from '@spordle/toasts';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import useSWR from 'swr';

const TeamProfile = (props) => {
    const routeMatch = useRouteMatch();
    const { teamId } = useParams();

    const teamsContext = useContext(TeamsContext);
    const rolesContext = useContext(RolesContext);
    const organizationContext = useContext(OrganizationContext);
    const periodsContext = useContext(PeriodsContext)
    const { updateRecentlyViewed } = useContext(AppContext);

    const [ hasWarnings, setHasWarnings ] = useState(false);

    useEffect(() => {
        // Preset Data
        if(props.location?.data)
            teamsContext.presetCurrentTeam(props.location.data)
    }, [ teamId ])

    useEffect(() => {
        teamsContext.getTeam(teamId, true, { components: [ 'address', 'contact', 'competition' ] })
            .then((team) => {
                updateRecentlyViewed({
                    label: team.name,
                    path: props.location.pathname,
                });
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    AxiosCancelAll();
                    props.history.replace('/teams/404');
                }
            });

        if(rolesContext.hasAccessTo('teams', 'team_members')){
            teamsContext.getRosterTablesData(teamId, null, true);
        }
    }, [ routeMatch.url ])

    useEffect(() => {
        // This logic will intercept every api calls to the teams endpoint and will show a Toast when there is warnings
        // It will stop listening when we leave the team profile

        const settingsCallRegex = new RegExp(`/teams/${teamId}/team-settings$`, 'm');
        const logCallRegex = new RegExp(`/teams/${teamId}/status-history$`, 'm');

        const _apiCallHandler = function(){
            // Will be called when an api call returns something...
            // We will do something only if the call is is from the teams endpoint
            // AND if it is not the GET team-settings call
            if(this.responseURL.includes('/v1/teams/') && !settingsCallRegex.test(this.responseURL) && !logCallRegex.test(this.responseURL)){
                const response = JSON.parse(this.responseText);
                if(Array.isArray(response.warnings) && response.warnings.length > 0){
                    triggerWarningToast(response.warnings, 5);
                    setHasWarnings(true);
                }
            }
        }

        let defaultXml = null;

        const origOpen = XMLHttpRequest.prototype.open;
        XMLHttpRequest.prototype.open = function(){
            defaultXml = this;
            this.addEventListener('load', _apiCallHandler);// Adding the listener
            origOpen.apply(this, arguments);// Use the default settings to continue like normal
        };

        return () => { // Stop listening when the component is unmounting i.e. when we leave the team profile
            XMLHttpRequest.prototype.open = origOpen;
            defaultXml.removeEventListener('load', _apiCallHandler);// Stops listening
        }
    }, [])

    /*--------------------------------------------------------------------------------*/
    /* Locked Roster                                                                  */
    /* The roster may be locked by roster section.                                    */
    /* The organization settings decide the roster lock dates. Usually set by Federation */
    /* There could also be lock dates set in the teams settings. Ignore org lock dates if team settings lock dates are set. */
    /* The teams may have specific lock dates. Ignore org lock dates and teams settings lock dates if team lock dates are set. */
    /* There is also a component permission bypass_lock_roster_date ADD EDIT DELETE. */
    /*--------------------------------------------------------------------------------*/
    const getLockedRoster = (rosterType) => {
        const dateIsValid = (date) => !!date && moment(date).isSameOrBefore()
        const orgSettingIsValid = (orgSetting) => !orgSetting.period || orgSetting.period?.period_id === periodsContext.selectedPeriod.period_id

        // One ore the other, if team is set, ignore organization
        switch (rosterType){
            case 'ACTIVE':
                if(dateIsValid(teamsContext.cachedTeam.lock_roster_date))
                    return true
                else if(!teamsContext.cachedTeam.lock_roster_date && dateIsValid(teamsSettings?.lock_team_active_roster_date?.value))
                    return true
                else if(!teamsContext.cachedTeam.lock_roster_date && !teamsSettings?.lock_roster_date?.value && dateIsValid(organizationContext.settings.lock_roster_date.value) && orgSettingIsValid(organizationContext.settings.lock_roster_date))
                    return true
                break;
            case 'AFFILIATE':
                if(dateIsValid(teamsContext.cachedTeam.lock_affiliate_roster_date))
                    return true
                else if(!teamsContext.cachedTeam.lock_affiliate_roster_date && dateIsValid(teamsSettings?.lock_team_affiliate_roster_date?.value))
                    return true
                else if(!teamsContext.cachedTeam.lock_affiliate_roster_date && !teamsSettings?.lock_team_affiliate_roster_date?.value && dateIsValid(organizationContext.settings.lock_affiliate_roster_date.value) && orgSettingIsValid(organizationContext.settings.lock_affiliate_roster_date))
                    return true
                break;
            case 'STAFF':
                if(dateIsValid(teamsContext.cachedTeam.lock_staff_roster_date))
                    return true
                else if(!teamsContext.cachedTeam.lock_staff_roster_date && dateIsValid(organizationContext.settings.lock_staff_roster_date.value) && orgSettingIsValid(organizationContext.settings.lock_staff_roster_date))
                    return true
                break;
            default:
                return false
        }
    }

    const getLockedRosterDate = (rosterType) => {
        switch (rosterType){
            case 'ACTIVE':
                return teamsContext.cachedTeam.lock_roster_date ? teamsContext.cachedTeam.lock_roster_date : teamsSettings?.lock_team_active_roster_date?.value ? teamsSettings?.lock_team_active_roster_date?.value : organizationContext.settings.lock_roster_date.value ? organizationContext.settings.lock_roster_date.value : null
            case 'AFFILIATE':
                return teamsContext.cachedTeam.lock_affiliate_roster_date ? teamsContext.cachedTeam.lock_affiliate_roster_date : teamsSettings?.lock_team_affiliate_roster_date?.value ? teamsSettings?.lock_team_affiliate_roster_date?.value : organizationContext.settings.lock_affiliate_roster_date.value ? organizationContext.settings.lock_affiliate_roster_date.value : null
            case 'STAFF':
                return teamsContext.cachedTeam.lock_staff_roster_date ? teamsContext.cachedTeam.lock_staff_roster_date : organizationContext.settings.lock_staff_roster_date.value ? organizationContext.settings.lock_staff_roster_date.value : null
            default:
                return null;
        }
    }

    const detectHasWarnings = (settings) => {
        setHasWarnings(Object.values(settings).some((setting) => {
            if(setting){
                if(Array.isArray(setting)){ // qualification_rules
                    return setting.some((qualificationRule) => qualificationRule.qualifications.some((qualification) => qualification.warnings?.length > 0));
                }
                return setting.warnings?.length > 0
            }
            return false

        }));
    }

    const { data: teamsSettings, error, isValidating } = useSWR(
        [ 'getTeamsSettings', teamId ],
        () => teamsContext.getTeamSettings(teamId)
            .then((settings) => {
                const filteredSettings = {}
                for(const key in settings){
                    if(Object.hasOwnProperty.call(settings, key)){
                        if(settings[key].active == '1' || key === 'qualification_rules'){
                            filteredSettings[key] = settings[key];
                        }
                    }
                }
                return filteredSettings
            })
            .then((settings) => {
                if(settings.qualification_rules && settings.qualification_rules.length > 0){
                    return {
                        ...settings,
                        qualification_rules: settings.qualification_rules?.map((position) => ({
                            ...position,
                            ...position.qualifications.reduce((object, qualification) => {
                                if(qualification.equivalence && qualification.equivalence.length > 0){
                                    object.oneMandatory.push([
                                        qualification,
                                        ...qualification.equivalence.map((eq) => ({
                                            ...qualification,
                                            ...eq,
                                        })),
                                    ]) // we push an array in an array because there could be multiple "one mandatory" sets for 1 position
                                }else{
                                    object.allMandatory.push(qualification)
                                }
                                return object
                            }, { allMandatory: [], oneMandatory: [] }),
                        })),
                    }
                }
                return settings;
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            }),
        {
            revalidateOnFocus: false,
            onSuccess: detectHasWarnings,
        },
    );

    useEffect(() => {
        if(teamsSettings)
            detectHasWarnings(teamsSettings);
    }, [])

    return (
        <>
            <ViewHeaderV2
                breadcrumbsProps={{
                    disablePeriodSelect: true,
                    favoriteData: teamsContext.cachedTeam ? {
                        label: teamsContext.cachedTeam.name,
                        path: props.location.pathname,
                        translate: false,
                    } : {},
                }}
            />

            <div className="page-content">
                <>
                    <TeamHeader
                        inNavbar
                        teamId={teamId}
                        hasWarnings={hasWarnings}
                        lockedRoster={getLockedRoster('ACTIVE')}
                    />

                    <TabRoutesNavs routes={teamProfileTabsRoutes} />

                    <TabRoutesPanes
                        routes={teamProfileTabsRoutes}
                        getComponentProps={(route) => {
                            switch (route.path){
                                case '/roster':
                                    return {
                                        teamId: teamId,
                                        getLockedRoster: getLockedRoster,
                                        getLockedRosterDate: getLockedRosterDate,
                                        teamsSettings: teamsSettings,
                                    };
                                case '/info':
                                    return { teamId: teamId };
                                case '/settings':
                                    return {
                                        error: error,
                                        isValidating: isValidating,
                                        teamsSettings: teamsSettings,
                                        teamId: teamId,
                                    }
                                default:
                                    break;
                            }
                        }}
                    />
                    {/* } */}
                    {/* </TeamSettingsManager> */}
                </>
            </div>
        </>
    );
}

export default TeamProfile;

