export default{
    "members.profile.suspensions.title" : "Suspensions",
    "members.profile.status.log.title"  : "Member Status Logs",

    // fields
    "members.profile.suspensions.infraction"             : "Infraction",
    "members.profile.suspensions.infraction.existing"    : "Existing Infraction",
    "members.profile.suspensions.infraction.other"       : "Other Infraction",
    "members.profile.suspensions.organization"           : "Organization",
    "members.profile.suspensions.penaltyType"            : "Penalty Type",
    "members.profile.suspensions.suspensionType"         : "Suspension Type",
    "members.profile.suspensions.date"                   : "Date",
    "members.profile.suspensions.duration"               : "Duration",
    "members.profile.suspensions.duration.definite"      : "Definite",
    "members.profile.suspensions.duration.indefinite"    : "Indefinite",
    "members.profile.suspensions.duration.games"         : "{games} Games",
    "members.profile.suspensions.duration.numberOfGames" : "Number of games",
    "members.profile.suspensions.startDate"              : "Start Date",
    "members.profile.suspensions.endDate"                : "End Date",
    "members.profile.suspensions.incidentDate"           : "Incident Date",
    "members.profile.suspensions.receivedDate"           : "Received Date",
    "members.profile.suspensions.changeDate"             : "Change Date",
    "members.profile.suspensions.location"               : "Incident Location",
    "members.profile.suspensions.status"                 : "Status",
    "members.profile.suspensions.status.active"          : "Active",
    "members.profile.suspensions.status.expired"         : "Expired",
    "members.profile.suspensions.team"                   : "Team",
    "members.profile.suspensions.position"               : "Position",
    "members.profile.suspensions.opposingTeamName"       : "Opposing Team Name",
    "members.profile.suspensions.suggestedPosition"      : "Suggested position:",
    "members.profile.suspensions.suggestedPositions"     : "Suggested positions:",
    "members.profile.suspensions.notes"                  : "Notes",
    "members.profile.suspensions.attachments"            : "Attachments",
    "members.profile.suspensions.created_date"           : "Created Date",
    "members.profile.suspensions.submitted_by"           : "Submitted by",
    "members.profile.suspensions.submitted_email"        : "Submitted by Email",
    "members.profile.suspensions.teamMember"             : "Team Member",
    "members.profile.suspensions.teamMember.min"         : "At least one member is required",
    "members.profile.suspensions.memberTypes"            : "Member Types",
    "members.profile.suspensions.memberTypes.required"   : "At least one member type is required",
    "members.profile.suspensions.fee"                    : "Fee",
    "members.profile.suspensions.fine"                   : "Fine",
    "members.profile.suspensions.success.toast"          : "Successfully created the suspension for {memberType}",
    "members.profile.suspensions.fail.toast"             : "Failed to create the suspension for {memberType}",

    // validation
    "members.profile.suspensions.infraction.required"         : "Infraction is required",
    "members.profile.suspensions.penaltyType.required"        : "Penalty type is required",
    "members.profile.suspensions.games.required"              : "Number of games is required",
    "members.profile.suspensions.startDate.required"          : "Start date is required",
    "members.profile.suspensions.endDate.required"            : "End date is required",
    "members.profile.suspensions.endDate.afterReceived"       : "End date must be after the received date",
    "members.profile.suspensions.endDate.afterStart"          : "End date cannot be before the start date",
    "members.profile.suspensions.endDate.afterToday"          : "Date cannot be set after today",
    "members.profile.suspensions.incidentDate.required"       : "Incident date is required",
    "members.profile.suspensions.incidentDate.afterToday"     : "Incident date cannot be after today",
    "members.profile.suspensions.incidentDate.beforeReceived" : "Incident date cannot be after the reception date",
    "members.profile.suspensions.receivedDate.required"       : "Received date is required",
    "members.profile.suspensions.receivedDate.afterIncident"  : "Received date cannot be before the incident date",
    "members.profile.suspensions.location.required"           : "Incident location is required",
    "members.profile.suspensions.team.required"               : "Team is required",
    "members.profile.suspensions.position.required"           : "Position is required",

    // table

    // add modal
    "members.profile.suspensions.add.title" : "Add suspension",

    // sidepanel
    "members.profile.suspensions.sidepanel.title" : "Suspended on",
    "members.profile.suspensions.sidepanel.PDF"   : "Export PDF",

    // delete suspension modal
    "members.profile.suspensions.delete.message" : "You are about to delete a suspension for this player. This action is irreversible.",

    // activate modal
    "members.profile.suspensions.activate.title"             : "Activate a suspension",
    "members.profile.suspensions.activate.btn"               : "Activate",
    "members.profile.suspensions.activate.confirm"           : "Successfully activated suspension!",
    "members.profile.suspensions.deactivate.title"           : "Deactivate a suspension",
    "members.profile.suspensions.deactivate.btn"             : "Deactivate",
    "members.profile.suspensions.deactivate.confirm"         : "Successfully deactivated suspension!",
    "members.profile.suspensions.validation.dateAfterToday"  : "Date must be after today",
    "members.profile.suspensions.validation.dateBeforeToday" : "Date cannot be after today",
}