export default{
    "task.document.approval.member"                        : "Member",
    "task.document.approval.organization"                  : "Organization",
    "task.document.approval.submitted_by"                  : "Submitted By",
    "task.document.approval.created_at"                    : "Created At",
    "task.document.approval.manage.approval"               : "Manage Approval",
    "task.document.approval.manage.openAll"                : "Open all in Quick View",
    "task.document.approval.filename"                      : "File Name",
    "task.document.approval.document.type"                 : "Document Type",
    "task.document.approval.modal.title.type"              : "Manage Document Approval",
    "task.document.approval.modal.current.picture.preview" : "This preview is of the current profile picture.",

    "task.document.approval.modal.approve"         : "Approve",
    "task.document.approval.modal.approve.confirm" : "Confirm Approval",
    "task.document.approval.modal.refuse"          : "Refuse",
    "task.document.approval.modal.refuse.confirm"  : "Confirm Refusal",
    "task.document.approval.modal.cancel.confirm"  : "Confirm Request Cancellation",
    "task.document.approval.modal.skip"            : "Skip",

    "task.document.approval.list.approve.all" : "Approve All Documents",

    "task.document.approval.modal.refuse.validation"  : "A note is required to refuse a document.",
    "task.document.approval.modal.refuse.placeholder" : "Note",
    "task.document.approval.modal.success"            : "Successfully updated the documents' status.",
    "task.document.approval.modal.success.multi"      : "There are no documents left in queue to be approved.",

    "task.document.sidepanel.count" : "{count} selected documents",

    "task.document.approval.decide.if.appropriate.resize" : "Please decide whether or not this image is appropriate and approve or refuse it. You may need to recenter or resize the image.",
    "task.document.approval.decide.if.appropriate"        : "Please decide whether or not this document is appropriate and approve or refuse it.",

    "task.document.approval.status.NO_VALIDATION" : "No Validation Needed",
    "task.document.approval.status.PENDING"       : "Pending approval",
    "task.document.approval.status.APPROVED"      : "Approved",
    "task.document.approval.status.REJECTED"      : "Rejected",
}