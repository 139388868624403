export default{
    "members.profile.teams.title" : "Équipes",

    // table
    "members.profile.teams.season"             : "Saison",
    "members.profile.teams.previousSeason"     : "Saisons précédentes",
    "members.profile.teams.rostered"           : "Enregistré(e) le",
    "members.profile.teams.organizations"      : "Organisations",
    "members.profile.teams.category"           : "Catégorie",
    "members.profile.teams.teamName"           : "Nom d'équipe",
    "members.profile.teams.leagueName"         : "Nom de ligue",
    "members.profile.teams.division"           : "Division",
    "members.profile.teams.position"           : "Position",
    "members.profile.teams.affiliate"          : "Affilié",
    "members.profile.teams.tryout"             : "Essaie",
    "members.profile.teams.released"           : "Libération",
    "members.profile.teams.primaryTeam"        : "Équipe primaire",
    "members.profile.teams.currentPrimaryTeam" : "Équipe primaire courante",

    // sidepanel
    "members.profile.teams.actionsMenu.setPrimary"            : "Définir en tant qu'équipe primaire",
    "members.profile.teams.actionsMenu.setPrimary.primary"    : "Cette équipe est déjà l'équipe primaire",
    "members.profile.teams.actionsMenu.setPrimary.noAccess"   : "Vous n'avez pas accès à cette équipe",
    "members.profile.teams.actionsMenu.setPrimary.affiliated" : "Impossible en tant que joueur affilié",
}