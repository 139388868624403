import { useContext } from 'react';
import useSWR from 'swr';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { PeriodsContext } from '../../../contexts/contexts';
import { AffiliationFeesContext } from '../../../contexts/AffiliationFeesContext';
import Translate from '@spordle/intl-elements';
import { WaiversContext } from '../../../contexts/WaiversContext';
import { isFn } from '../../../helpers/helper';
import { ChecklistsContext } from '../../../contexts/ChecklistsContext';
import { Spinner } from "reactstrap";

const RefreshData = ({ swr }) => (
    swr.isValidating ?
        <Spinner
            type="grow"
            size="sm"
            style={{ height: "1em", width: "1em" }}
            className="ml-1"
        />
        :
        <button
            type="button"
            className="mdi mdi-refresh btn btn-link text-inherit btn-sm px-1 mr-n1 py-0 line-height-1"
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                swr.mutate();
            }}
        />
)

export function useStepDataSWR(step, checklistId, hasAccess){
    const { checklists, getCompletedData } = useContext(ChecklistsContext);
    const { organisation_id } = useContext(OrganizationContext);
    const { selectedPeriod } = useContext(PeriodsContext);
    const { getAffiliationFees } = useContext(AffiliationFeesContext);
    const { getWaivers } = useContext(WaiversContext);
    const checklistSteps = checklists[checklistId]?.checklist_steps || [];

    const noneSWR = {
        key: null,
        get: () => Promise.resolve([]),
    };

    const stepIsComplete = (theStep, steps) => {
        const isDep = steps.includes(theStep.checklist_item?.code);
        const isComplete = (getCompletedData(checklistId, theStep.checklist_step_id) || []).length > 0;

        return !isDep || isComplete;
    }

    const stepsAreComplete = (steps) => checklistSteps.every((theStep) => stepIsComplete(theStep, steps));

    function getInfo(item){
        switch (item?.code){
            case 'season_rollover_membership_fee':
                return {
                    key: [ 'getMembershipFees', organisation_id, selectedPeriod.period_id ],
                    get: () => getAffiliationFees(),
                    label: ({ data, ...swrInfo }) => (
                        hasAccess ?
                            <>
                                <Translate id='catalog.affiliationFees.count' values={{ count: (data?.length || 0) }} />
                                <RefreshData swr={swrInfo} />
                            </>
                            :
                            null
                    ),
                    canComplete: ({ data }) => data?.length > 0,
                }
            case 'season_rollover_waiver':
                return {
                    key: [ 'getWaivers', organisation_id, selectedPeriod.period_id ],
                    get: () => getWaivers({ organisation_id }),
                    label: ({ data, ...swrInfo }) => (
                        hasAccess ?
                            <>
                                <Translate id="catalog.waivers.count" values={{ count: (data?.length || 0) }} />
                                <RefreshData swr={swrInfo} />
                            </>
                            :
                            null
                    ),
                }
            case 'season_rollover_period':
                const neededSteps = [ 'season_rollover_user', 'season_rollover_waiver', 'season_rollover_membership_fee' ];
                const isComplete = (getCompletedData(checklistId, step.checklist_step_id) || []).length > 0;
                const isInProgress = !isComplete && checklistSteps.some((theStep) => stepIsComplete(theStep, neededSteps));

                return {
                    label: isInProgress ? <Translate id="misc.inProgress" /> : null,
                    canComplete: stepsAreComplete(neededSteps),
                }
            default:
                return { skip: true };
        }
    }

    const {
        key: swrKey,
        get: swrGet,
        label: stepLabel,
        canComplete: canCompleteStep,
        ...info
    } = getInfo(step.checklist_item);

    const swr = useSWR(
        (hasAccess ? swrKey || noneSWR.key : noneSWR.key),
        (hasAccess ? swrGet || noneSWR.get : noneSWR.get),
        ((hasAccess && swrKey) ?
            {
                revalidateOnFocus: false,
                revalidateOnReconnect: false,
                dedupingInterval: 1000 * 60 * 2, // 2 minutes: 1000ms * 60s = 1min, 1minx2 = 2min
            }
            :
            null
        ),
    );

    const isLoading = !swr.data && swr.isValidating;
    const label = isLoading ? <Translate id="misc.loading" /> : isFn(stepLabel) ? stepLabel(swr) : stepLabel || null;
    const canComplete = isFn(canCompleteStep) ? canCompleteStep(swr) : (canCompleteStep || canCompleteStep === void 0);

    return (
        info.skip ?
            {
                canComplete: true,
            }
            :
            {
                label,
                ...info,
                isDisabled: isLoading,
                canComplete: canComplete,
            }

    )
}