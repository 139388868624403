import SpordleTableProvider, { Refresh, SearchInput, SpordleTableContext, SpordleTableView, useSpordleTable } from '@spordle/datatables';
import Translate from '@spordle/intl-elements';
import moment from 'moment';
import { useContext, useLayoutEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useIntl } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    Badge, Button, Card,
    CardBody, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledButtonDropdown
} from "reactstrap";
import CardSectionTitle from '../../../../components/CardSectionTitle';
import CanDoAction from '../../../../components/permissions/CanDoAction';
import SpordlePanelTable from '../../../../components/sidePanel/SpordlePanel';
import EmptyLayout from '../../../../components/table/EmptyLayout';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import { AppContext, PeriodsContext } from '../../../../contexts/contexts';
import { I18nContext } from '../../../../contexts/I18nContext';
import { MembersContext } from '../../../../contexts/MembersContext';
// Contexts
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { DisplayI18n, displayI18n } from '../../../../helpers/i18nHelper';
import MemberProfileRegistrationAddContext from './components/AddModal/MemberProfileRegistrationAddContext';
import { getConfirmCancelled } from "./components/Sidepanel/components/ConfirmCancelledBy";
import MemberProfileRegistrationSidepanel from './components/Sidepanel/MemberProfileRegistrationSidepanel';
import queryString from 'query-string';
import { waiverNotSigned } from '../../../../helpers/waiversHelper';
import OnlineStoreRegistrationFilters from '../../../onlineStore/profile/registrations/components/OnlineStoreRegistrationFilters';
import { Tooltip } from "@mantine/core";
import { RolesContext } from '../../../../contexts/RolesContext';
import { MerchantAccountContext } from '../../../../contexts/MerchantAccountContext';

const MemberProfileRegistration = (props) => {
    const organizationContext = useContext(OrganizationContext);
    const membersContext = useContext(MembersContext);
    const periodsContext = useContext(PeriodsContext);
    const appContext = useContext(AppContext);
    const i18nContext = useContext(I18nContext);
    const rolesContext = useContext(RolesContext);
    const merchantAccountContext = useContext(MerchantAccountContext);
    const history = useHistory();
    const location = useLocation();

    const [ waitingListReturnData, setWaitingListReturnData ] = useState();
    const [ addModal, setAddModal ] = useState(false)
    const toggleAddModal = () => setAddModal(!addModal)

    const formatData = (invoices) => {
        // invoices
        const formattedInvoices = invoices.reduce((builtArray, invoice) => {
            const newRow = formatSingleInvoiceData(invoice); // Will return false if invoice has now registration
            if(newRow)
                builtArray.push(newRow)
            return builtArray;
        }, [])
        return formattedInvoices;
    }

    const toolTipMsgId = membersContext.currentMember.member_status?.name === 'BLOCKED' || membersContext.currentMember.member_status?.name === 'INELIGIBLE'
        ?
        `members.search.sidePanelMemberSearch.disabled.registration.${membersContext.currentMember.member_status?.name}`
        :
        '';

    // this is passed to the sidepanel, make sure actions work if modifying
    const formatSingleInvoiceData = (invoice) => {
        const creditAmount = invoice.credits.reduce((sum, credit) => sum += parseInt(credit.amount), 0);
        // Find current member's registration
        const invoiceRegistrationForMember = invoice.invoice_items.find((item) => (item.registration_fee !== null || (invoice.type === "WAIVER" && invoice.origin === "THIRD_PARTY") || (invoice.type === 'AFFILIATION')) && membersContext.currentMember.member_id === item.member.member_id);
        // Find current member's waivers (would technically be all linked to the registration)
        const mergedWaivers = invoice.invoice_items.reduce((waivers, item) => {
            if(membersContext.currentMember.member_id === item.member.member_id){
                item.waivers.forEach((waiver) => {
                    waivers.push({ ...waiver, invoiceItem: item });
                })
            }
            return waivers;
        }, []);

        return invoiceRegistrationForMember ? {
            ...invoice,
            key: invoice.invoice_number,

            memberRegistration: invoiceRegistrationForMember,

            registrations: invoice.invoice_items.filter((item) => item.registration_fee !== null),
            otherItems: invoice.invoice_items.filter((item) => (item.other_fee !== null)),

            waivers: mergedWaivers,

            creditAmount: creditAmount,
            total: parseInt(invoice.total_amount),
            paid: parseInt(creditAmount) + parseInt(invoice.invoice_items.reduce((sum, item) => sum += parseInt(item.payments.reduce((paymentSum, payment) => paymentSum += parseInt(payment.status === "COMPLETED" ? payment.amount : 0), 0)), 0)),
            due: invoice.invoice_items.reduce((sum, item) => sum += parseInt(item.payments.reduce((paymentSum, payment) => paymentSum += parseInt((payment.status !== "REFUNDED" && payment.status !== "COMPLETED") ? payment.amount : 0), 0)), 0),
        } : false
    }

    useLayoutEffect(() => {
        if(location.hash === '#waiting-list-return'){
            setWaitingListReturnData(queryString.parse(location.search));
            const parsedUrl = queryString.parseUrl(`${location.pathname}${location.search}${location.hash}`, { parseFragmentIdentifier: true/* parse hash(#) */ })
            // cleaning the URL
            delete parsedUrl.query.registrationFeeId;
            delete parsedUrl.query.memberId;
            delete parsedUrl.query.waitingListNumber;
            history.replace(queryString.stringifyUrl(parsedUrl))
            toggleAddModal();
        }

        // to fetch the merchant accounts in case they were not fetched before
        if(rolesContext.hasAccessTo('settings', 'settings_merchant_account')){
            merchantAccountContext.getMerchantAccountsByOrganization(organizationContext.organisation_id)
        }
    }, [])

    return (
        <Card className="card-shadow">
            <CardBody>
                <CardSectionTitle title='members.profile.memberProfile.tabs.registration' />
                {(!membersContext.currentMember.invoices && membersContext.currentMember.minimalistic_view == 0) &&
                    <div className='text-center'>
                        <Spinner color='primary' type='grow' />
                        <div className='h4'><Translate id='misc.loading' /></div>
                    </div>
                }
                {(!!membersContext.currentMember.invoices || membersContext.currentMember.minimalistic_view == 1) &&
                    <SpordlePanelTable
                        allowOutsideClick
                        dataIndex="key"
                        sidePanel={(sProps) => <MemberProfileRegistrationSidepanel {...props} {...sProps} formatSingleInvoiceData={formatSingleInvoiceData} />}
                        table={(panelProps) => {
                            return (
                                <SpordleTableProvider
                                    id='registration'
                                    tableHover bordered striped
                                    clickable
                                    dataIndex='key'
                                    key={props.forceRefresh}
                                    defaultSorting='-invoice_date'
                                    ref={panelProps.spordleTableRef}
                                    desktopWhen
                                    pagination={20}
                                    tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                    searchKeys={[
                                        { name: 'invoice_number', weight: 2 },
                                        'invoice_date',
                                        `memberRegistration.registration_fee.fee.i18n.${i18nContext.getGenericLocale()}.name`,
                                        'memberRegistration.registration_fee.fee.name',
                                    ]}
                                    loadData={(from, _filterData, spordleTable) => {
                                        switch (from){
                                            case 'REFRESH':
                                                props.setHeaderCardsRefresh();
                                                spordleTable.setLoading();
                                            case 'CDM':
                                                return membersContext.getMemberInvoices(membersContext.currentMember.member_id, {
                                                    organisation_id: organizationContext.organisation_id,
                                                    period_id: periodsContext.selectedPeriod.period_id,
                                                    my_organisation_only: 0,
                                                })
                                                    .then((invoices) => {
                                                        spordleTable.setLoading(false)
                                                        return formatData(invoices);
                                                    })
                                            default:
                                                break;
                                        }
                                    }}
                                    columns={[
                                        {
                                            label: <Translate id='memberProfile.registration.form.registrationDate' />,
                                            key: 'invoice_date',
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='memberProfile.registration.form.registration' />,
                                            key: 'category',
                                            sortable: false, // should sort based on seasons
                                        },
                                        // {
                                        //     label: <Translate id='memberProfile.registration.form.memberType'/>,
                                        //     key: 'memberType',
                                        //     // className: '',
                                        //     // dataClassName: '',
                                        //     sortable: false
                                        // },
                                        {
                                            label: <Translate id='memberProfile.registration.form.season' />,
                                            key: 'season',
                                            dataClassName: 'text-nowrap',
                                            sortable: false,
                                        },
                                        {
                                            label: <Translate id="memberProfile.registration.form.position" />,
                                            key: 'position',
                                            sortable: false,
                                        },
                                        {
                                            label: <Translate id='memberProfile.registration.form.waivers' />,
                                            key: 'waivers',
                                            sortable: false,
                                        },
                                        {
                                            label: <Translate id='memberProfile.registration.form.status' />,
                                            key: 'status',
                                            sortable: false,
                                        },
                                    ]}
                                    renderRow={(columnKey, registration) => {
                                        switch (columnKey){
                                            case 'invoice_date':
                                                return (
                                                    <>
                                                        <div className="small text-muted"><Translate id={`memberProfile.registration.form.status.${registration.origin?.toUpperCase()}`} /></div>
                                                        <div className="small">{moment(registration.invoice_date).format('LLL')}</div>
                                                        {registration.type !== "WAIVER" ?
                                                            <Link to={'invoice?invoiceNumber=' + registration.invoice_number}>#{registration.invoice_number}</Link>
                                                            :
                                                            <span>#{registration.invoice_number}</span>
                                                        }
                                                    </>
                                                )
                                            case 'category':
                                                return (
                                                    <>
                                                        {registration.api_partner ?
                                                            <><Translate id='memberProfile.registration.form.registration.partner' /> {registration.api_partner.name}</>
                                                            :
                                                            <DisplayI18n
                                                                field='name'
                                                                defaultValue={registration.memberRegistration?.registration_fee?.fee.name}
                                                                i18n={registration.memberRegistration?.registration_fee?.fee.i18n}
                                                            />
                                                        }
                                                        <div className="small text-muted">
                                                            <DisplayI18n
                                                                field='name'
                                                                defaultValue={periodsContext.selectedPeriod.name}
                                                                i18n={periodsContext.selectedPeriod.i18n}
                                                            />
                                                        </div>
                                                        {registration.organisation &&
                                                            <div className='small text-muted'>
                                                                <DisplayI18n
                                                                    field='name'
                                                                    defaultValue={registration.organisation.organisation_name}
                                                                    i18n={registration.organisation.i18n}
                                                                />
                                                            </div>
                                                        }
                                                    </>
                                                )
                                                // case 'memberType':
                                                //     return <DisplayI18n
                                                //         field='name'
                                                //         defaultValue={data.registration.item.member_type?.name || ""}
                                                //         i18n={data.registration.item.member_type?.i18n}
                                                //     />

                                            case 'member':
                                                return (
                                                    <UserDisplay>
                                                        <UserDisplay.Container>
                                                            <UserImg
                                                                abbr={registration.memberRegistration.member.first_name.substring(0, 1) + registration.memberRegistration.member.last_name.substring(0, 1)}
                                                                src={undefined}
                                                                width={30}
                                                                alt={registration.memberRegistration.member.first_name + ' ' + registration.memberRegistration.member.last_name}
                                                            />
                                                        </UserDisplay.Container>
                                                        <UserDisplay.Container>
                                                            <UserDisplay.Title>{registration.memberRegistration.member.first_name + ' ' + registration.memberRegistration.member.last_name}</UserDisplay.Title>
                                                            <UserDisplay.Subtitle>#{registration.memberRegistration.member.unique_identifier}</UserDisplay.Subtitle>
                                                        </UserDisplay.Container>
                                                    </UserDisplay>
                                                );
                                            case "position":
                                                const positionItem = registration.memberRegistration.positions[0];

                                                if(positionItem){
                                                    return (
                                                        <>
                                                            <div>
                                                                <DisplayI18n field="name" i18n={positionItem.position_group.i18n} defaultValue={positionItem.position_group.name} />
                                                            </div>
                                                            {positionItem.position &&
                                                                            <small className="text-muted">
                                                                                <DisplayI18n field="name" i18n={positionItem.position.i18n} defaultValue={positionItem.position.name} />
                                                                            </small>
                                                            }
                                                        </>
                                                    )
                                                }

                                                return "-";
                                            case 'waivers':
                                                if(registration.waivers && registration.waivers.length > 0){
                                                    const acceptedWaivers = registration.waivers?.reduce((sum, waiver) => {
                                                        if(waiver.answer !== waiverNotSigned)
                                                            sum++;
                                                        return sum
                                                    }, 0)
                                                    if(acceptedWaivers === 0){
                                                        return <span className="font-medium text-danger">{acceptedWaivers} / {registration.waivers.length}</span>
                                                    }else if(acceptedWaivers < registration.waivers.length){
                                                        return <span className="font-medium text-warning">{acceptedWaivers} / {registration.waivers.length}</span>
                                                    }
                                                    return <span className="font-medium text-success">{acceptedWaivers} / {registration.waivers.length}</span>

                                                }
                                                return <span className="font-medium text-muted">-</span>

                                                // if(registration.waivers.length > 0){
                                                //     const acceptedWaivers = registration.waivers?.reduce((sum, waiver) => {
                                                //         if(waiver.answer === "YES")
                                                //             sum++
                                                //         return sum
                                                //     }, 0)

                                                //     if(acceptedWaivers === 0){
                                                //         return <span className="font-medium text-danger">{acceptedWaivers} / {registration.waivers.length}</span>
                                                //     }else if(acceptedWaivers < registration.waivers.length){
                                                //         return <span className="font-medium text-warning">{acceptedWaivers} / {registration.waivers.length}</span>
                                                //     }else {
                                                //         return <span className="font-medium text-success">{acceptedWaivers} / {registration.waivers.length}</span>
                                                //     }
                                                // }else{
                                                //     return <span className="font-medium text-muted">-</span>
                                                // }
                                            case 'season':
                                                return (
                                                    <DisplayI18n
                                                        field='name'
                                                        defaultValue={periodsContext.selectedPeriod?.name}
                                                        i18n={periodsContext.selectedPeriod.i18n}
                                                    />
                                                )
                                            case 'status':
                                                switch (getConfirmCancelled(registration.memberRegistration)){
                                                    case "CONFIRMED":
                                                        return <Badge color="success"><Translate id='memberProfile.registration.status.confirmed' /></Badge>
                                                    case "PENDING":
                                                        return <Badge color="warning"><Translate id='memberProfile.registration.status.pending' /></Badge>
                                                    case "CANCELLED":
                                                        return <Badge color="danger"><Translate id='memberProfile.registration.status.cancelled' /></Badge>
                                                    default:
                                                        return ''
                                                }

                                            default:
                                                break;
                                        }
                                    }}
                                    onColumnClick={(e, data) => {
                                        switch (e.button){
                                            case 0: // Left mouse button
                                                panelProps.onSingleSelectChange(data);
                                                break;
                                        }
                                    }}
                                    rowIsHighlighted={(data) => !!data.checked}
                                    emptyLayout={<EmptyLayout translateMsg msg='memberProfile.registration.table.emptyLayout.noResult' />}
                                    initFilter={{
                                        status: null,
                                        registrationPackage: null,
                                        paymentMethod: null,
                                    }}
                                    filterJSCallback={(registration, { status, registrationPackage, paymentMethod }) => {
                                        let result = true;
                                        if(status && status !== getConfirmCancelled(registration.memberRegistration)){
                                            result = false;
                                        }
                                        if(registrationPackage && !registrationPackage.includes(registration.memberRegistration.registration_fee?.registration_fee_id)){
                                            result = false;
                                        }
                                        if(paymentMethod && !paymentMethod.includes(registration.invoice_payment_method?.code)){
                                            result = false;
                                        }

                                        return result;
                                    }}
                                >
                                    <div className='mb-2'>
                                        <OnlineStoreRegistrationFilters />
                                        <div className='d-flex flex-wrap justify-content-between'>
                                            {/* <UncontrolledDropdown>
                                                        <DropdownToggle color='primary' className='d-flex align-items-center' caret>
                                                            <div className='align-self-center custom-checkbox custom-control'>
                                                                <input type='checkbox' className='custom-control-input d-none' checked={panelProps.topChecked} onChange={panelProps.onTopCheckChange}/>
                                                                <label className='custom-control-label' htmlFor='topCheckID'></label>
                                                            </div>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={panelProps.checkAll}><Translate id='misc.all'/></DropdownItem>
                                                            <DropdownItem onClick={panelProps.uncheckAll}><Translate id='misc.none'/></DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown> */}
                                            <SearchInput />
                                            <div className='d-flex ml-auto text-right'>
                                                <Refresh />
                                                <UncontrolledButtonDropdown className='ml-2'>
                                                    <DropdownToggle color='primary' outline caret>
                                                        <i className='fas fa-download mr-1' /><Translate id='misc.export' />
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        <CanDoAction action='EXPORT' componentCode='members' componentPermissionCode='members_registrations'>
                                                            <ToCSV />
                                                        </CanDoAction>
                                                        <DropdownItem disabled><i className='fas fa-file-excel mr-1' /><Translate id='misc.excel' /></DropdownItem>
                                                        <DropdownItem disabled><i className='fas fa-file-pdf mr-1' /><Translate id='misc.pdf' /></DropdownItem>
                                                        <DropdownItem disabled><i className='fas fa-print mr-1' /><Translate id='misc.print' /></DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                                {addModal &&
                                                    <SpordleTableContext.Consumer>
                                                        {(spordleTable) => (
                                                            <MemberProfileRegistrationAddContext
                                                                isOpen={addModal}
                                                                toggle={toggleAddModal}
                                                                refreshTable={spordleTable.refreshTable}
                                                                setForceRefresh={props.setForceTransactionRefresh}
                                                                setWaiversForceRefresh={props.setForceWaiversRefresh}
                                                                waitingListReturnData={waitingListReturnData}
                                                                waitingListNumber={waitingListReturnData?.waitingListNumber}
                                                                refreshMemberProfile={appContext.updateRouteKey}
                                                            />
                                                        )}
                                                    </SpordleTableContext.Consumer>
                                                }
                                                {!membersContext.currentMember?.minimalistic_view &&
                                                    <div>
                                                        <Tooltip withArrow disabled={!toolTipMsgId} label={<Translate id={toolTipMsgId} />}>
                                                            <CanDoAction action='ADD' componentCode='members' componentPermissionCode='members_registrations'>
                                                                <Button
                                                                    disabled={membersContext.currentMember.member_status?.name === 'BLOCKED' || membersContext.currentMember.member_status?.name === 'INELIGIBLE'}
                                                                    className='ml-2' color='primary'
                                                                    onClick={() => {
                                                                        setWaitingListReturnData(null);
                                                                        toggleAddModal();
                                                                    }}
                                                                >
                                                                    <i className='mdi mdi-plus mr-1' /><Translate id='misc.add' />
                                                                </Button>
                                                            </CanDoAction>
                                                        </Tooltip>

                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <SpordleTableView />
                                </SpordleTableProvider>
                            )
                        }}
                    />
                }
            </CardBody>
        </Card>
    );
}

const ToCSV = () => {
    const membersContext = useContext(MembersContext);
    const i18nContext = useContext(I18nContext);
    const spordleTable = useSpordleTable();
    const intl = useIntl();

    const getStatus = (status) => {
        switch (status){
            case "COMPLETED":
            case "PAID":
                return intl.formatMessage({ id: 'memberProfile.registration.status.paid' });
            case "PARTIALLY_PAID":
                return intl.formatMessage({ id: 'memberProfile.registration.status.partially_paid' });
            case "PENDING":
                return intl.formatMessage({ id: 'memberProfile.registration.status.pending' });
            case "CANCELLED":
                return intl.formatMessage({ id: 'memberProfile.registration.status.cancelled' });
            default:
                return 'N/A';
        }
    }

    const formatData = () => {
        return spordleTable.getData().map((invoice) => ({
            invoice_date: moment(invoice.invoice_date).format('YYYY-MM-DD H:mm'),
            invoice_number: `="${invoice.invoice_number}"`, // added this so the csv export isn't shown as an exponent,
            invoice_payment_method: displayI18n("name", invoice.invoice_payment_method?.i18n, invoice.invoice_payment_method?.name, i18nContext.getGenericLocale()),
            member_position: invoice.memberRegistration?.positions?.[0] ? displayI18n("name", invoice.memberRegistration?.positions?.[0]?.position?.i18n, invoice.memberRegistration?.positions?.[0]?.position?.name, i18nContext.getGenericLocale()) : " - ",
            registration: displayI18n("short_name", invoice.memberRegistration?.registration_fee?.division?.i18n, invoice.memberRegistration?.registration_fee?.division?.short_name, i18nContext.getGenericLocale()) + ((invoice.memberRegistration?.registration_fee?.team_category?.class) ? '-' + displayI18n("short_name", invoice.memberRegistration?.registration_fee?.team_category?.class?.i18n, invoice.memberRegistration?.registration_fee?.team_category?.class?.short_name, i18nContext.getGenericLocale()) : ''),
            status: getStatus(invoice.memberRegistration?.invoice_item_payment_status),
        }));
    }

    return (
        <CSVLink
            className={"dropdown-item"} separator=','
            filename={`${membersContext.currentMember.first_name + ' ' + membersContext.currentMember.last_name}-Registrations-${moment().format('YYYY-MM-DD H:mm')}.csv`}
            headers={[
                {
                    label: intl.formatMessage({ id: 'memberProfile.registration.form.registrationDate' }),
                    key: 'invoice_date',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.transactions.memberProfileTransactions.csv.invoiceNumber' }),
                    key: 'invoice_number',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.transactions.memberProfileTransactions.csv.paymentMethod' }),
                    key: 'invoice_payment_method',
                },
                {
                    label: intl.formatMessage({ id: 'memberProfile.registration.form.position' }),
                    key: 'member_position',
                },
                {
                    label: intl.formatMessage({ id: 'memberProfile.registration.form.registration' }),
                    key: 'registration',
                },
                {
                    label: intl.formatMessage({ id: 'memberProfile.registration.form.status' }),
                    key: 'status',
                },
            ]}
            data={formatData()}
        >
            <i className='fas fa-file-excel mr-1' />CSV
        </CSVLink>
    )
}

export default MemberProfileRegistration;