import Translate from '@spordle/intl-elements';
import { useContext, useState, useRef } from 'react';
import {
    Row,
    Button,
    Card,
    Col,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Collapse
} from "reactstrap";

import RebatesTypes from './RebatesTypes';
import { fail, success } from '@spordle/toasts';
import { FieldArray, Form, Formik } from 'formik';
import { FormikDateTime, FormikError, FormikInputNumber, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import { object, string, number, array, mixed } from 'yup';


import moment from 'moment';
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../helpers/i18nHelper';
import { I18nContext } from '../../../../contexts/I18nContext';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import { RebatesContext } from '../../../../contexts/RebatesContext';
import { SpordleTableContext } from '@spordle/datatables';
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { useSteps } from '../../../../components/customHooks/useSteps';
import CrossFade from '../../../../components/crossFade/CrossFade';
import { AddPostalCodesModalInner } from '../../../organization/profile/cities/modals/AddPostalCodesModal';

const RebatesAdd = ({ toggle, isOpen }) => {

    const rebatesContext = useContext(RebatesContext);
    const spordleTable = useContext(SpordleTableContext);
    const i18nHelper = useContext(I18nHelperContext);
    const innerFormikRef = useRef();
    const [ currentStep, { next, previous, goTo } ] = useSteps(2, 0);

    const [ selectedPostalCodes, setSelectedPostalCodes ] = useState([])

    const innerOnSubmit = (values, setSubmitting) => {
        const newItem = { ...values }; // data that will be sent back to table
        if(values.mode !== RebatesTypes.mode.percent.tag) newItem.value = (values.value * 100).toFixed(); // toFixed avoids binary hiccups (20.10 -> 2010.000000002)
        newItem.expiration_date = newItem.expiration_date ? values.expiration_date.toISOString() : '';

        // data to send to api
        const apiValues = { ...newItem, ...i18nHelper.getAPIValues(newItem) };

        if(values.i18n?.fr?.title)
            apiValues['i18n[fr][short_name]'] = values.i18n.fr.title; // temp until they remove short_name
        if(values.i18n?.en?.title)
            apiValues['i18n[en][short_name]'] = values.i18n.en.title; // temp until they remove short_name

        if(values.type !== RebatesTypes.type.promo.tag){
            apiValues.rules.map((rule, index) => {
                apiValues.rules[index].rule_value = values.mode !== RebatesTypes.mode.percent.tag ? (rule.rule_value * 100).toFixed() : rule.rule_value;
                apiValues.rules[index].postalCodes = values.postalCodes;
            });
        }

        if(values.type === RebatesTypes.type.promo.tag)delete apiValues.rules;
        delete apiValues.i18n;

        return rebatesContext.createRebate(apiValues)
            .then(() => {
                spordleTable.refreshTable();
                toggle();
                // spordleTable.addRow({
                //     rebate_id: rebate_id,
                //     ...newItem,
                // }).then(toggle);
                success();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setSubmitting(false)
                }
            })
    }

    const goToPostalCodes = (selectedPostalCodes) => {
        setSelectedPostalCodes(selectedPostalCodes)
        next()
    }

    const postalCodesOnSubmit = (values, removedValues) => {
        const keptValues = (selectedPostalCodes || []).filter((code) => {
            // Do not filter out if does not exist in removed Values
            return removedValues.findIndex((c) => c === code) === -1;
        });

        const allCodes = Array.from(new Set([ ...(values.postalCodes || []), ...keptValues ]));

        innerFormikRef.current?.setFieldValue('postalCodes', allCodes);
        previous();
    }

    return (
        <AnalyticsModal analyticsName='RebatesAdd' isOpen={isOpen} onClosed={() => goTo(0)}>
            <CrossFade isVisible={currentStep == 0}>
                <RebatesAddInner
                    onSubmit={innerOnSubmit}
                    toggle={toggle}
                    goToPostalCodes={goToPostalCodes}
                    innerFormikRef={innerFormikRef}
                />
            </CrossFade>
            <CrossFade isVisible={currentStep == 1}>
                <AddPostalCodesModalInner
                    toggle={toggle}
                    onSubmit={postalCodesOnSubmit}
                    canEdit
                    selectedPostalCodes={selectedPostalCodes}
                    goToPrevious={previous}
                />
            </CrossFade>
        </AnalyticsModal>
    );
}

const RebatesAddInner = ({ onSubmit, toggle, goToPostalCodes, innerFormikRef }) => {
    const spordleTable = useContext(SpordleTableContext);
    const rulesStructure = {
        rule_registration_number: '',
        rule_value: '',
        active: '1',
        postalCodes: [],
    }

    const i18nHelper = useContext(I18nHelperContext);

    return (
        <Formik
            // probably the worst thing i've ever done, having a ref from the parent
            // we should NEVER do this, but in this case I think it is required
            // I cant mount the postal codes component in here since it also has a Formik in it
            // please forgive me
            innerRef={innerFormikRef}
            initialValues={{
                ...i18nHelper.getInitialValues(),
                type: RebatesTypes.type.multi.tag,
                mode: RebatesTypes.mode.amount.tag,
                postalCodes: [],
                active: '1',
                promo_code: '',
                value: '',
                rules: [ {
                    rule_registration_number: '',
                    rule_value: '',
                    active: '1',
                } ],
                expiration_date: '',
            }}
            validationSchema={object().shape({
                ...i18nHelper.getValidationSchema({ title: string().required(<Translate id='catalog.rebates.form.validation.title.required' />) }),
                type: string().required(),
                mode: string().required(),
                active: string(),
                promo_code: string()
                    .test({
                        name: 'alreadyExists',
                        message: <Translate id='catalog.rebates.form.validation.promoCode.existing' />,
                        test: function(val){
                            const spordleData = spordleTable?.getData();
                            if(spordleData){
                                for(const key in spordleData){
                                    if(spordleData[key].promo_code === val && spordleData[key].type === RebatesTypes.type.promo.tag)return false;
                                }
                                return true
                            }
                        },
                    })
                    .when('type', {
                        is: (val) => val === RebatesTypes.type.promo.tag,
                        then: string().required(<Translate id='catalog.rebates.form.validation.promoCode.required' />),
                        otherwise: string().nullable(),
                    }),
                value: number().positive()
                    .when('mode', {
                        is: (val) => val === RebatesTypes.mode.percent.tag,
                        then: number().max(100, <Translate id='catalog.rebates.form.validation.promoValue.percent.exceed' />),
                    })
                    .when('type', {
                        is: (val) => val === RebatesTypes.type.promo.tag,
                        then: number().required(<Translate id='catalog.rebates.form.validation.promoValue.required' />),
                        otherwise: number().nullable(),
                    }),
                rules: array().of(object().shape({
                    rule_registration_number: number().typeError(<Translate id='catalog.rebates.form.validation.participantNumber.nb.required' />)
                        .integer(<Translate id='catalog.rebates.form.validation.participantNumber.nb.required' />)
                        .positive(<Translate id='catalog.rebates.form.validation.participantNumber.nb.required' />)
                        .test({
                            name: 'minTestPostal',
                            message: <Translate id='catalog.rebates.form.validation.participantNumber.min.required.postal' />,
                            test: function(value){
                                if(this.from[this.from.length - 1].value.type === RebatesTypes.type.postalCode.tag && !!value){
                                    return parseInt(value) >= 1;
                                }
                                return true;
                            },
                        })
                        .test({
                            name: 'minTest',
                            message: <Translate id='catalog.rebates.form.validation.participantNumber.min.required' />,
                            test: function(value){
                                if(this.from[this.from.length - 1].value.type === RebatesTypes.type.multi.tag && !!value){
                                    return parseInt(value) >= 2;
                                }
                                return true;
                            },
                        })
                        .test({
                            name: 'modeIsMulti',
                            message: <Translate id='catalog.rebates.form.validation.participantNumber.required' />,
                            test: function(val){
                                if(this.from[this.from.length - 1].value.type !== RebatesTypes.type.promo.tag){
                                    return !!val;
                                }
                                return true;
                            },
                        }),
                    rule_value: number().test({
                        name: 'modeIsMultiAndPercent',
                        message: <Translate id='catalog.rebates.form.validation.promoValue.required' />,
                        test: function(val){
                            const formData = this.from[this.from.length - 1];
                            if(formData.value.type !== RebatesTypes.type.promo.tag){
                                return !!val;
                            }
                            return true;
                        },
                    }).test({
                        name: 'over100',
                        message: <Translate id='catalog.rebates.form.validation.promoValue.percent.exceed' />,
                        test: function(val){
                            const formData = this.from[this.from.length - 1];
                            if(formData.value.type !== RebatesTypes.type.promo.tag && formData.value.mode === RebatesTypes.mode.percent.tag){
                                return val <= 100;
                            }
                            return true
                        },
                    }),
                    active: string(),
                })),
                postalCodes: array().test({
                    name: 'minTestPostalCodes',
                    message: <Translate id='catalog.rebates.form.validation.postalCodes.required' />,
                    test: function(postalCodes){
                        const formData = this.from[this.from.length - 1];
                        if(formData.value.type === RebatesTypes.type.postalCode.tag){
                            return postalCodes.length > 0;
                        }
                        return true
                    },
                }),
                expiration_date: mixed()
                    .test({
                        name: 'dateIsValid',
                        message: <Translate id='form.validation.date.format' />,
                        test: function(val){
                            return val ? moment.isMoment(val) : true
                        },
                    }),
            })}
            onSubmit={(values, { setSubmitting }) => {
                onSubmit(values, setSubmitting)
            }}
        >
            {(formik) => (
                <Form name="addRebate" id="addRebate">
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={toggle}>
                            <Translate id='catalog.rebates.modal.title' />
                        </ModalHeader>
                        <ModalBody>
                            <Row form>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for='type' className='text-muted'><Translate id='catalog.rebates.form.type' /></Label>
                                        <FormikSelect
                                            name='type'
                                            id='type'
                                            search={false}
                                            loadingStatus='success'
                                            defaultData={[
                                                {
                                                    label: RebatesTypes.type.multi.label,
                                                    translateLabel: true,
                                                    value: RebatesTypes.type.multi.tag,
                                                },
                                                {
                                                    label: RebatesTypes.type.postalCode.label,
                                                    translateLabel: true,
                                                    value: RebatesTypes.type.postalCode.tag,
                                                },
                                            ]}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label for="mode" className='text-muted'><Translate id='catalog.rebates.form.mode' /></Label>
                                        <FormikSelect
                                            search={false}
                                            name='mode'
                                            id='mode'
                                            loadingStatus='success'
                                            defaultData={[
                                                {
                                                    label: '$',
                                                    value: RebatesTypes.mode.amount.tag,
                                                },
                                                {
                                                    label: '%',
                                                    value: RebatesTypes.mode.percent.tag,
                                                },
                                            ]}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Collapse isOpen={formik.values.type === RebatesTypes.type.postalCode.tag} mountOnEnter unmountOnExit>
                                <div className='mb-3'>
                                    <div className='d-flex'>
                                        <Translate id='catalog.rebates.form.postalCodes' values={{ number: formik.values.postalCodes.length }} />
                                        <button className='reset-btn text-link ml-auto' type='button' onClick={() => goToPostalCodes(formik.values.postalCodes)}>
                                            <Translate id='catalog.rebates.form.editPostalCodes' /><i className='mdi mdi-arrow-right ml-1' />
                                        </button>
                                    </div>
                                    <FormikError name='postalCodes' />
                                </div>
                            </Collapse>
                            {/* triggers the collapse opening animation only once (select should not be clearable)*/}
                            <Collapse isOpen={!!formik.values.type}>
                                <hr className="mt-0" />
                                <Row form>
                                    <RenderI18nForm field="title">
                                        {({ fieldName, fieldLabel }) => (
                                            <Col md="6" className="form-group" key={fieldName}>
                                                <Label for={fieldName} className='text-muted'>{fieldLabel}</Label>
                                                <FormikInputText id={fieldName} name={fieldName} trim />
                                            </Col>
                                        )}
                                    </RenderI18nForm>
                                </Row>
                                {formik.values.type !== RebatesTypes.type.promo.tag ?
                                    <FormGroup>
                                        <div className="mb-2 text-muted"><Translate id='catalog.rebates.form.rules' /></div>
                                        <FieldArray name="rules">
                                            { (arrayHelper) => (
                                                <>
                                                    {/* <PerfectScrollbar options={{suppressScrollX: true}}>
                                                        <div className="max-vh-50"> */}
                                                    { formik.values.rules.map((rule, index) => {
                                                        if(index > 0) rule.rule_registration_number = (parseInt(formik.values.rules[0].rule_registration_number) + index) || '';

                                                        return (
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            <Card className="card-shadow p-3 mb-2" key={`rules-${index}`}>
                                                                <Label className='font-bold'>
                                                                    {formik.values.type === RebatesTypes.type.postalCode.tag ?
                                                                        <Translate id='catalog.rebates.form.nbParticipant.postalCodes' />
                                                                        :
                                                                        <Translate id='catalog.rebates.form.nbParticipant' />
                                                                    }
                                                                </Label>
                                                                <Row form>
                                                                    <Col sm="6">
                                                                        <FormGroup>
                                                                            <Label className='text-muted'>
                                                                                <Translate id='catalog.rebates.form.nbParticipant.number' />
                                                                            </Label>
                                                                            <FormikInputText
                                                                                name={`rules.${index}.rule_registration_number`}
                                                                                id={`rules.${index}.rule_registration_number`}
                                                                                disabled={index > 0}
                                                                                placeholder='#'
                                                                                translatePlaceholder={false}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <Label for='discountType' className='text-muted'>
                                                                            <Translate id='catalog.rebates.form.discount' />
                                                                        </Label>
                                                                        <I18nContext.Consumer>
                                                                            {({ getGenericLocale }) => (
                                                                                <FormikInputNumber
                                                                                    allowLeadingZeros fixedDecimalScale
                                                                                    id={`rules.${index}.rule_value`}
                                                                                    name={`rules.${index}.rule_value`}
                                                                                    allowNegative={false}
                                                                                    decimalScale={0}
                                                                                    thousandSeparator=' '
                                                                                    suffix={
                                                                                        (getGenericLocale() === 'fr' && formik.values.mode !== RebatesTypes.mode.percent.tag) ?
                                                                                            '$' : formik.values.mode === RebatesTypes.mode.percent.tag ? '%' : ''
                                                                                    }
                                                                                    prefix={(getGenericLocale() !== 'fr' && formik.values.mode === RebatesTypes.mode.amount.tag) ? '$' : undefined}
                                                                                    decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                                                />
                                                                            )}
                                                                        </I18nContext.Consumer>
                                                                    </Col>
                                                                </Row>
                                                                { index !== 0 &&
                                                                    <button type="button" onClick={() => arrayHelper.remove(index)} className="position-absolute top-0 right-0 btn btn-link text-danger">
                                                                        <i className="mdi mdi-delete" />
                                                                    </button>
                                                                }
                                                            </Card>
                                                        )
                                                    })}
                                                    {/* </div>
                                                    </PerfectScrollbar> */}
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelper.push(rulesStructure)}
                                                        className="btn btn-link"
                                                    >
                                                        + <Translate id='catalog.rebates.form.addRules' />
                                                    </button>
                                                </>
                                            )}
                                        </FieldArray>
                                    </FormGroup>
                                    :
                                    <Row form>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label for='promo_code' className='text-muted'><Translate id='catalog.rebates.form.promoCode' /></Label>
                                                <FormikInputText id='promo_code' name='promo_code' />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label for='value' className='text-muted'>
                                                    <Translate id='catalog.rebates.form.discount' />
                                                </Label>
                                                <I18nContext.Consumer>
                                                    {({ getGenericLocale }) => (
                                                        <FormikInputNumber
                                                            allowLeadingZeros fixedDecimalScale
                                                            id='value'
                                                            name='value'
                                                            allowNegative={false}
                                                            decimalScale={2}
                                                            thousandSeparator=' '
                                                            suffix={
                                                                (getGenericLocale() === 'fr' && formik.values.mode !== RebatesTypes.mode.percent.tag) ?
                                                                    '$' : formik.values.mode === RebatesTypes.mode.percent.tag ? '%' : ''
                                                            }
                                                            prefix={(getGenericLocale() !== 'fr' && formik.values.mode === RebatesTypes.mode.amount.tag) ? '$' : undefined}
                                                            decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                        />
                                                    )}
                                                </I18nContext.Consumer>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }
                                <Row form>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='expiration_date' className='text-muted'><Translate id='catalog.rebates.form.expDate' /></Label>
                                            <FormikDateTime name='expiration_date' id='expiration_date' timeFormat={false} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label for='active' className='text-muted'><Translate id='catalog.rebates.form.status' /></Label>
                                            <FormikSelect
                                                name='active'
                                                id='active'
                                                loadingStatus='success'
                                                search={false}
                                                defaultData={[
                                                    {
                                                        label: 'misc.active',
                                                        translateLabel: true,
                                                        value: '1',
                                                    },
                                                    {
                                                        label: 'misc.inactive',
                                                        translateLabel: true,
                                                        value: '0',
                                                    },
                                                ]}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button form="addRebate" color='primary' type="submit"><Translate id='misc.confirm' /></Button>
                            <Button
                                color='primary' onClick={() => {
                                    formik.resetForm();
                                    toggle();
                                }} outline
                            ><Translate id='misc.cancel' />
                            </Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default RebatesAdd;