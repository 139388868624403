import { useCallback, useRef, useState } from "react";

const useLongPress = (
    onLongPress,
    onClick,
    { shouldPreventDefault = true, delay = 300 } = {},
) => {
    const [ longPressTriggered, setLongPressTriggered ] = useState(false);
    const timeout = useRef();
    const isCancelled = useRef(false);
    const target = useRef();

    const start = useCallback(
        (event) => {
            isCancelled.current = false;
            if(shouldPreventDefault && event.target){
                event.target.addEventListener("touchend", preventDefault, {
                    passive: false,
                });
                target.current = event.target;
            }
            timeout.current = setTimeout(() => {
                onLongPress?.(event);
                setLongPressTriggered(true);
            }, delay);
        },
        [ onLongPress, delay, shouldPreventDefault ],
    );

    const clear = useCallback(
        (event, shouldTriggerClick = true) => {
            timeout.current && clearTimeout(timeout.current);
            shouldTriggerClick && !longPressTriggered && !isCancelled.current && onClick?.();
            setLongPressTriggered(false);
            if(shouldPreventDefault && target.current){
                target.current.removeEventListener("touchend", preventDefault);
            }
        },
        [ shouldPreventDefault, onClick, longPressTriggered ],
    );

    return {
        onMouseDown: (e) => start(e),
        onTouchMove: (e) => {
            isCancelled.current = true;
            clear(e, false);
        },
        onTouchStart: (e) => start(e),
        onMouseUp: (e) => clear(e),
        onMouseLeave: (e) => clear(e, false),
        onTouchEnd: (e) => clear(e),
        isLongPressing: longPressTriggered,
    };
};

const isTouchEvent = (event) => {
    return "touches" in event;
};

const preventDefault = (event) => {
    if(!isTouchEvent(event))return;

    if(event.touches.length < 2 && event.preventDefault && event.cancelable){
        event.preventDefault();
    }
};

export default useLongPress;