import { useSpordleTable } from "@spordle/datatables";
import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext } from "react";
import {
    Button,
    Col,
    FormGroup,
    Label, ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import * as Yup from 'yup';
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import Required from '../../../../../components/formik/Required';
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { fail } from "@spordle/toasts";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { TeamsContext } from "../../../../../contexts/TeamsContext";
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../../helpers/i18nHelper'; // these are deprecated, look at OrganizationPhoneTypes i18nhelper

const TeamAddressTypesAddModal = (props) => {
    const i18nHelper = useContext(I18nHelperContext);
    const spordleTable = useSpordleTable();
    const organizationContext = useContext(OrganizationContext);
    const teamsContext = useContext(TeamsContext);

    return (
        <AnalyticsModal isOpen={props.addAddressTypeModalOpen} toggle={props.toggleAddAddressType} analyticsName='TeamAddressTypesAddModal'>
            <Formik
                initialValues={{
                    ...i18nHelper.getInitialValues(),
                    status: '1',
                    active: true,
                }}
                validationSchema={Yup.object().shape({
                    ...i18nHelper.getValidationSchema({ name: Yup.string().required(<Translate id='form.validation.name.required' />) }),
                    status: Yup.string().required(<Translate id='organization.settings.organizationTeamAddressTypes.add.status.required' />),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    values.active = !!(values.status === '1')
                    const newItem = values // data that will be sent back to table

                    const apiValues = { ...values, ...i18nHelper.getAPIValues(values) };
                    delete apiValues.i18n

                    teamsContext.createAddressType(organizationContext.organisation_id, apiValues)
                        .then((id) => {
                            const returnData = {
                                team_address_type_id: id,
                                ...newItem,
                            }

                            spordleTable.addRow(returnData).then(() => {
                                props.toggleAddAddressType();
                            })
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                            setStatus(error.message);
                        })
                        .finally(() => {
                            setSubmitting(false)
                        })
                }}
            >
                {(formik) => {
                    return (
                        <Form>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <ModalHeader toggle={props.toggleAddAddressType}><Translate id='organization.settings.organizationTeamAddressTypes.add.title' /></ModalHeader>
                                <ModalBody>
                                    <RenderI18nForm field={'name'}>
                                        {({ fieldName, fieldLabel }) => {
                                            return (
                                                <Row className='mb-3' key={fieldName}>
                                                    <Col md='12'>
                                                        <FormGroup>
                                                            <Label for={fieldName} className='text-muted'>{fieldLabel} <Required /></Label>
                                                            <FormikInputText id={fieldName} name={fieldName} trim />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            )
                                        }}
                                    </RenderI18nForm>
                                    <Row>
                                        <Col md='12'>
                                            <FormGroup>
                                                <Label for='status' className='text-muted'><Translate id='organization.settings.organizationTeamAddressTypes.add.status' /> <Required /></Label>
                                                <FormikSelect
                                                    id='status_TeamAddressTypesAddModal'
                                                    name='status'
                                                    search={false}
                                                    loadingStatus='success'
                                                    defaultData={[
                                                        { id: 'status-active', label: 'misc.active', translateLabel: true, value: '1' },
                                                        { id: 'status-inactive', label: 'misc.inactive', translateLabel: true, value: '0' },
                                                    ]}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                    <Button color='primary' type='button' outline onClick={props.toggleAddAddressType} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </OverlayLoader>
                        </Form>
                    )
                }}
            </Formik>
        </AnalyticsModal>
    );
}

export default TeamAddressTypesAddModal;