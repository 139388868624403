export default{
    "organization.profile.staff.modals.addStaff.members"                         : "Membres pérexistants",
    "organization.profile.staff.modals.addStaff.title"                           : "Ajouter un membre du personnel",
    "organization.profile.staff.modals.addStaff.btn.selectMember"                : "Sélectionner ce membre",
    "organization.profile.staff.modals.addStaff.btn.createMember"                : "Créer un nouveau member du personnel",
    "organization.profile.staff.modals.addStaff.validation.contactType.required" : "Le rôle est requis",
    "organization.profile.staff.modals.addStaff.no.members.found"                : "Aucun membre trouvé",

    "organization.profile.staff.spordleTable.noResult"      : "Aucun membre du personnel n'a été trouvé.",
    "organization.profile.staff.spordleTable.label.role"    : "Rôle(s)",
    "organization.profile.staff.spordleTable.label.contact" : "Coordonnées",

    "organization.profile.staff.minimum.role" : "Un rôle minimum.",

    "organization.profile.staff.sidePanel.tab.member"          : "Membre",
    "organization.profile.staff.sidePanel.formik.label.number" : "Numéro",
    "organization.profile.staff.sidePanel.formik.label.yrs"    : "ans",
    "organization.profile.staff.sidePanel.delete"              : "Vous êtes sur le point de supprimer ce membre du personnel",

    "organization.profile.staff.formik.label.contactTypes"                  : "Rôles",
    "organization.profile.staff.formik.label.contactTypes.singular"         : "Rôle",
    "organization.profile.staff.formik.label.contactTypes.role.required"    : "Un rôle est requis",
    "organization.profile.staff.formik.label.contactTypes.role.noDuplicate" : "Un membre du personnel ne peut pas avoir de rôle en double ",
    "organization.profile.staff.formik.label.contactTypes.add"              : "Ajouter rôle",
    "organization.profile.staff.formik.isElected"                           : "Rôle élu",
    "organization.profile.staff.formik.electedUntil"                        : "Élu jusqu'au",
    "organization.profile.staff.formik.electedUntil.valid"                  : "La date doit être valide.",
    "organization.profile.staff.formik.elected"                             : "Élu",

    "organization.profile.staff.show.on" : "Afficher ce contact sur le",
    "organization.profile.staff.website" : "site web",

    "organization.profile.staff.empty" : "Aucun personnel",

}