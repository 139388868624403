import { FormikDateTime } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { fail, success } from '@spordle/toasts';
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { Button, Col, Collapse, Label, Row } from "reactstrap";
import { mixed, object } from 'yup';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import CustomAlert from '../../../../../../components/CustomAlert';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';

const TransferSettingsForm = ({ isLoading, setIsLoading, setIsEditing, ...props }) => {
    const orgContext = useContext(OrganizationContext);

    return (
        <Formik
            initialValues={{
                expiryDate: orgContext.settings.default_share_expiration_date?.value || '',
            }}
            validationSchema={object().shape({
                expiryDate: mixed().nullable().isDate(),
            })}
            onSubmit={(values, { setStatus }) => {
                setIsLoading(true);
                return orgContext.updateSettings(orgContext.organisation_id, {
                    default_share_expiration_date: values.expiryDate?.format('YYYY-MM-DD') || '',
                })
                    .then(async() => {
                        setIsLoading(false);
                        setIsEditing(false);
                        success();
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            setIsLoading(false);
                            console.error(error.message);
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <Row>
                        <Col sm='6'>
                            <Label for='expiryDate' className='text-muted'><Translate id='settings.transfer.expiryDate' /></Label>
                            <FormikDateTime
                                id='expiryDate'
                                name='expiryDate'
                                dateFormat='YYYY-MM-DD'
                                timeFormat={false}
                            />
                        </Col>
                    </Row>
                    <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                        {formik.status &&
                            <CustomAlert className='mt-3' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                        }
                    </Collapse>

                    <div className='d-flex justify-content-end'>
                        <Button color='primary' type='submit' className='mr-2' disabled={isLoading}><Translate id='misc.confirm' /></Button>
                        <Button color='primary' outline type='button' onClick={() => setIsEditing(false)} disabled={isLoading}><Translate id='misc.cancel' /></Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default TransferSettingsForm;