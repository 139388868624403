import { Button, Card, Col, Row } from "reactstrap";
import { useContext } from "react";
import Message from "./components/message/Message";
import { AppContext } from '../../contexts/contexts';
import withContexts from "../../helpers/withContexts";
import Translate from "@spordle/intl-elements";
import Inbox, { InboxContext } from "./components/inboxContext/InboxContext";
import SendCommModal from "../../components/communications/SendCommModal";
import { stringBuilder } from "@spordle/helpers";
import ViewHeaderV2 from "../../components/viewHeader/ViewHeaderV2";
import CommunicationsRoutes from "../../routes/routerRoutes/communicationsRoutes";
import SubRouteRenderer from "../../components/subSidebar/SubRouteRenderer";

const Communications = () => {
    const { isMobileDevice } = useContext(AppContext);

    return (
        <Inbox>
            <InboxContext.Consumer>
                {({ selectedMsg, showTools, setShowTools, sendMsgIsOpen, sentMsgIsOpenHandlers }) => (
                    <div className={"page-content p-0 d-flex flex-column"}>

                        <ViewHeaderV2
                            title="communications.title"
                        />

                        <SendCommModal
                            id="communicationsPage"
                            isOpen={!!sendMsgIsOpen}
                            toggle={sentMsgIsOpenHandlers.close}
                        />
                        <div
                            tabIndex={-1}
                            onClick={(e) => {
                                if(showTools && !e.currentTarget.contains(e.relatedTarget)){
                                    setShowTools(false);
                                }
                            }}
                            onBlur={(e) => {
                                if(showTools && !e.currentTarget.contains(e.relatedTarget)){
                                    setShowTools(false);
                                }
                            }}
                            className={stringBuilder("px-4 d-flex flex-column", { "flex-fill": !isMobileDevice })}
                        >
                            <Row form className={stringBuilder({ "flex-fill": !isMobileDevice })}>
                                {isMobileDevice && !selectedMsg &&
                                    <Col xs="12" className="mb-3">
                                        <Button color="primary" block type="button" onClick={sentMsgIsOpenHandlers.open}>
                                            <Translate id="communications.btn.compose" />
                                        </Button>
                                    </Col>
                                }
                                <Col
                                    className={stringBuilder("mb-3 mb-lg-0", { "d-none": isMobileDevice && !!selectedMsg })}
                                    xs="12"
                                    lg={3}
                                >
                                    <Card
                                        className="card-shadow mb-0 h-100"
                                    >
                                        <SubRouteRenderer routes={CommunicationsRoutes} />
                                    </Card>
                                </Col>
                                <Col className={stringBuilder({ "d-none": isMobileDevice && !selectedMsg })} xs="12" lg={9}>
                                    <Message msg={selectedMsg} />
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            </InboxContext.Consumer>
        </Inbox>
    );
};

export default withContexts(AppContext)(Communications);