export default{
    "components.assignOfficiatingPermissionsModal.officiatingCoach"            : "Officiating Coach",
    "components.assignOfficiatingPermissionsModal.btn"                         : "Manage officiating coach",
    "components.assignOfficiatingPermissionsModal.removeAccess.btn"            : "Remove access",
    "components.assignOfficiatingPermissionsModal.title"                       : "Manage officiating coach permissions",
    "components.assignOfficiatingPermissionsModal.msg"                         : "Management of officiating coach permissions. Give access to the select supervision types.",
    "components.assignOfficiatingPermissionsModal.supervisionTypes.selected"   : "Selected supervision types",
    "components.assignOfficiatingPermissionsModal.supervisionTypes.count"      : "{count, plural, =0 {# selected supervision type} one {# selected supervision type} other {# selected supervision types}}",
    "components.assignOfficiatingPermissionsModal.supervisionTypes"            : "Supervision types",
    "components.assignOfficiatingPermissionsModal.supervisionTypes.validation" : "Please select at least one supervision type",
    "components.assignOfficiatingPermissionsModal.isNational"                  : "Is a national officiating coach",
    "components.assignOfficiatingPermissionsModal.isNotNational"               : "Is not a national officiating coach",
    "components.assignOfficiatingPermissionsModal.canSupervise"                : "Can supervise",
    "components.assignOfficiatingPermissionsModal.cannotSupervise"             : "Cannot supervise",
    "components.assignOfficiatingPermissionsModal.success.msg.edit"            : "The officiating coach access to these supervision types have been updated:",
    "components.assignOfficiatingPermissionsModal.success.msg.create"          : "The officiating coach access to these supervision types have been created:",
    "components.assignOfficiatingPermissionsModal.success.delete"              : "Officiating coach access removed",
    "components.assignOfficiatingPermissionsModal.success.delete.msg"          : "All access to supervision types have been removed from {name}, who is no longer flagged as an officiating coach.",

    "components.assignOfficiatingPermissionsModal.delete.warning.title" : "Warning",
    "components.assignOfficiatingPermissionsModal.delete.warning"       : "By removing all accesses, the member won't have officiating coach rights anymore.",
}