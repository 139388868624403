import { FormikError } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Button, Card } from 'reactstrap';
import { displayName } from './helpers';
import { FieldArray, useFormikContext } from 'formik';
import generatePassword from '../../../helpers/passwordGenerator';
import DisplayHeaderCardRow from './DisplayHeaderCardRow';

const DisplayHeaderCard = ({ createMode, duplicate, teamSettings }) => {
    const formikContext = useFormikContext();

    const emptyTeamDivision = {
        teamCategoryId: [],
        divisionId: '',
    }

    return (
        <Card body className='card-shadow'>
            <div className="bg-white">
                <div className="">
                    {createMode || duplicate ?
                        <FieldArray name='teamCategoryDivision'>
                            {(fieldArrayHelper) => (
                                <div>
                                    {formikContext.values.teamCategoryDivision.map((teamCategoryDivision, index) => (
                                        <DisplayHeaderCardRow fieldArrayHelper={fieldArrayHelper} teamCategoryDivision={teamCategoryDivision} index={index} key={teamCategoryDivision.id} />
                                    ))}
                                    <FormikError name='teamCategoryDivision'>
                                        {(msg) => !Array.isArray(msg) && <div className='small text-danger'>{msg}</div>}
                                    </FormikError>
                                    <Button
                                        color='link' type="button"
                                        onClick={fieldArrayHelper.handlePush({ ...emptyTeamDivision, id: generatePassword() })}
                                    >
                                        <i className="mdi mdi-plus mr-1" /><Translate id='settings.teamsSettings.teamCategory.add' />
                                    </Button>
                                </div>
                            )}
                        </FieldArray>
                        :
                        <div className='d-sm-flex justify-content-between align-items-center'>
                            <div className="font-medium">
                                <div className='h3 mb-0'>
                                    {displayName(teamSettings)}
                                </div>
                            </div>
                            <Button color='primary' type='submit' className='mt-1 mt-sm-0' disabled={formikContext.isSubmitting}><Translate id={createMode || duplicate ? 'misc.create' : 'misc.save'} /></Button>
                        </div>
                    }
                </div>
            </div>
        </Card>
    )
}

export default DisplayHeaderCard;