import { FormikInputMasked, FormikRadioButton, FormikSelect } from '@spordle/formik-elements';
import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { Col, Fade, FormGroup, Label, Row } from 'reactstrap';
import Required from '../../../../../components/formik/Required';

const EditableExpirationSection = ({ qualificationExpiration, disabled, ...props }) => {
    const formik = useFormikContext();

    const [ dateTimeKey, setDateTimeKey ] = useState(false);

    const fillQualExpiration = () => {
        formik.setFieldValue('expirationType', qualificationExpiration.expirationType);
        formik.setFieldValue('expirationDuration', qualificationExpiration.expirationDuration);
        formik.setFieldValue('expirationDate', qualificationExpiration.expirationDate);
        setDateTimeKey(!dateTimeKey);
    }

    return (
        <div className={props.wizard ? 'mb-5' : 'mb-4'}>
            <div className={stringBuilder('d-flex justify-content-between align-items-end mb-3', { 'card-title border-bottom pb-1': props.wizard })}>
                <div className={stringBuilder('font-bold mb-0', { 'h3': props.wizard, 'h5': !props.wizard })}>
                    <Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.expiration' />
                </div>
                <div className='ml-auto'>
                    <Fade in={!!qualificationExpiration}>
                        <button type='button' className='reset-btn text-link' onClick={() => fillQualExpiration()}><Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.qualification' /></button>
                    </Fade>
                </div>
            </div>

            <FormGroup>
                <Label className='text-muted'><Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.type' /></Label>

                <div className='d-flex'>
                    <FormikRadioButton
                        className='' id='expirationTypeNoExpiration' name='expirationType'
                        value='NO_EXPIRATION'
                        translateLabel
                        label='clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.NO_EXPIRATION'
                        disabled={disabled}
                    />
                    <FormikRadioButton
                        className=' ml-3' id='expirationTypeFixedPeriod' name='expirationType'
                        value='FIXED_PERIOD'
                        translateLabel
                        label='clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.FIXED_PERIOD'
                        disabled={disabled}
                    />
                    <FormikRadioButton
                        className=' ml-3' id='expirationTypeFixedDate' name='expirationType'
                        value='FIXED_DATE'
                        translateLabel={false}
                        label={<Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.FIXED_DATE' />}
                        disabled={disabled}
                    />
                </div>
            </FormGroup>

            <Row form>
                <Col sm='6' md='3'>
                    <FormGroup>
                        <Label className='text-muted'>
                            <Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.duration' />
                            {formik.values.expirationType !== 'NO_EXPIRATION' &&
                                <Required className='ml-1' />
                            }
                        </Label>
                        <FormikSelect
                            id='expirationDuration' name='expirationDuration'
                            search={false}
                            disabled={formik.values.expirationType === 'NO_EXPIRATION' || disabled}
                            loadingStatus='success'
                            defaultData={Array(5).fill('').map((temp, index) => ({
                                value: `${index + 1}`,
                                label: 'clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.duration.years',
                            }))}
                            renderOption={(option) => <Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.duration.years' values={{ number: option.option.value }} />}
                        />
                    </FormGroup>
                </Col>
                <Col sm='6' md='3'>
                    <FormGroup>
                        <Label className='text-muted'>
                            <Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.date' />
                            {formik.values.expirationType === 'FIXED_DATE' &&
                                <Required className='ml-1' />
                            }
                        </Label>
                        <FormikInputMasked
                            key={dateTimeKey}
                            id='expirationDate'
                            name='expirationDate'
                            mask='00-00'
                            placeholder='clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.placeholder'
                            disabled={formik.values.expirationType !== 'FIXED_DATE' || disabled}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    )
}

export default EditableExpirationSection