import { useContext, useState } from "react";
import { useSWRConfig } from "swr";
import { AxiosIsCancelled } from "../../api/CancellableAPI";
import { IdentityRolesContext } from "../../contexts/IdentityRolesContext";
import { NewsContext } from "../../contexts/NewsContext";
import { RolesContext } from "../../contexts/RolesContext";
import { USER_LIST_KEY } from "./newsHelpers";

export function useReadNews(notices){
    const [ isReading, setIsReading ] = useState(false);
    const { mutate } = useSWRConfig();
    const { markNewsAsRead } = useContext(NewsContext);
    const { organisation_id, role_id } = useContext(RolesContext);
    const { identity_role_id } = useContext(IdentityRolesContext);

    const readNotices = (listToRead) => {
        if(!isReading){ // Prevents spam
            setIsReading(true);

            Promise.all(
                listToRead.map(({ news_id }) => markNewsAsRead(news_id, { read: 1, identity_role_id })),
            )
                .catch((e) => {
                    if(!AxiosIsCancelled(e.message)){
                        console.error(e);
                    }
                })
                .finally(() => {
                    mutate([ organisation_id, role_id, USER_LIST_KEY ], (notices || []).map((n) => ({ ...n, read: 1 })), { rollbackOnError: true });
                    setIsReading(false);
                })
        }
    }

    return [ readNotices, isReading ]
}