export default{
    "members.profile.memberProfile.title"                          : "Membre",
    "members.profile.memberProfile.header.yearsOld"                : "{age} ans",
    "members.profile.memberProfile.header.age"                     : "Âge",
    "members.profile.memberProfile.header.status"                  : "Statut",
    "members.profile.memberProfile.header.primaryOrg"              : "Organisation primaire",
    "members.profile.memberProfile.header.sharedOrg"               : "Organisation(s) partagée(s)",
    "members.profile.memberProfile.header.sharedOrg.nrp"           : "Organisation(s) partagée(s) - Joueur non résident",
    "members.profile.memberProfile.header.memberType"              : "Type de membre",
    "members.profile.memberProfile.header.memberTypes"             : "Types de membre",
    "members.profile.memberProfile.header.position"                : "Position",
    "members.profile.memberProfile.header.positions"               : "Positions",
    "members.profile.memberProfile.header.positions.currentPeriod" : "Saison actuelle",
    "members.profile.memberProfile.header.positions.lastPeriod"    : "Saison précédente",
    "members.profile.memberProfile.header.coachLevel"              : "Niveau d'entraîneur",
    "members.profile.memberProfile.header.refereeLevel"            : "Niveau d'arbitre",
    "members.profile.memberProfile.header.playerDivision"          : "Division de joueur",
    "members.profile.memberProfile.header.statusReason"            : "Raison du statut",
    "members.profile.memberProfile.header.statusNote"              : "Note du statut",
    "members.profile.memberProfile.header.statusImposedBy"         : "Statut imposé par",
    "members.profile.memberProfile.header.officiatingCoach"        : "Superviseur d'officiels",

    "members.profile.memberProfile.header.updated_at"            : "Date: ",
    "members.profile.memberProfile.header.updated_by"            : "Mis à jour par: ",
    "members.profile.memberProfile.header.systemUpdate"          : "Système",
    "members.profile.memberProfile.header.merged"                : "Fusionné",
    "members.profile.memberProfile.header.organisation_deciding" : "Imposé par: ",

    "members.profile.memberProfile.header.info"             : "Information sur le statut {status}: ",
    "members.profile.memberProfile.header.info.BLOCKED"     : "Information sur le statut bloqué: ",
    "members.profile.memberProfile.header.info.INELIGIBLE"  : "Information sur le statut inéligible: ",
    "members.profile.memberProfile.header.info.UNCONFIRMED" : "Information sur le statut non confirmé: ",
    "members.profile.memberProfile.header.info.CONFIRMED"   : "Information sur le statut confirmé: ",
    "members.profile.memberProfile.header.info.DRAFT"       : "Information sur le statut temporaire: ",

    "members.profile.memberProfile.header.btns.requestTransfer" : "Demander un {transfer}",
    "members.profile.memberProfile.header.btns.releaseMember"   : "Libérer le membre",
    "members.profile.memberProfile.header.btns.copyUrl"         : "Copier le lien",
    "members.profile.memberProfile.header.btns.sendMessage"     : "Envoyer un message",

    "members.profile.memberProfile.header.tags.medicalAttention" : "Attention médicale",
    "members.profile.memberProfile.header.tags.suspendedMember"  : "Membre suspendu",

    "members.profile.memberProfile.header.card.transactions"                : "Transactions",
    "members.profile.memberProfile.header.card.amountPaid"                  : "Montant payé",
    "members.profile.memberProfile.header.card.amountDue"                   : "Montant dû",
    "members.profile.memberProfile.header.card.amountScheduled"             : "Versement planifié",
    "members.profile.memberProfile.header.card.creditAmount"                : "Crédit restant",
    "members.profile.memberProfile.header.card.outstandingBalance"          : "Solde impayé",
    "members.profile.memberProfile.header.card.outstandingBalance.required" : "Le solde impayé est requis",
    "members.profile.memberProfile.header.card.outstandingBalance.title"    : "Changer le solde impayé",
    "members.profile.memberProfile.header.card.outstandingBalance.success"  : "Solde impayé a été mis à jour!",
    "members.profile.memberProfile.header.picture.upload.need.approval"     : "Vous pouvez télécharger une image via les documents du membres. La photo de profil sera mise à jour une fois approuvé.",
    "members.profile.memberProfile.header.picture.expiry"                   : "Expire le",

    "members.profile.memberProfile.header.card.quitour.title"       : "Confirmer ce membre",
    "members.profile.memberProfile.header.card.quitour.message"     : "Ce membre est prêt à être confirmé. Une fois qu'un profil de membre est terminé et n'a plus de champs manquants, vous pouvez cliquer sur ce bouton pour confirmer le membre.",
    "members.profile.memberProfile.header.badges.quicktour.title"   : "Indicateurs de statuts",
    "members.profile.memberProfile.header.badges.quicktour.message" : "Ces indicateurs de statuts représentent certaines lacunes qu'un profil de membre peut avoir. Vous pouvez cliquer sur ceux-ci comme raccourci pour gérer la lacunes. Tous les badges doivent être effacés pour pouvoir confirmer le statut du membre.",


    "members.profile.memberProfile.tabs.overview"       : "Sommaire",
    "members.profile.memberProfile.tabs.registration"   : "Inscriptions",
    "members.profile.memberProfile.tabs.transactions"   : "Transactions",
    "members.profile.memberProfile.tabs.team"           : "Équipes",
    "members.profile.memberProfile.tabs.qualifications" : "Qualifications",
    "members.profile.memberProfile.tabs.history"        : "Historique",
    "members.profile.memberProfile.tabs.waivers"        : "Dispenses",
    "members.profile.memberProfile.tabs.invoice"        : "Facture",
    "members.profile.memberProfile.tabs.receipt"        : "Reçu",
    "members.profile.memberProfile.tabs.usa"            : "USA",
    "members.profile.memberProfile.tabs.international"  : "International",
    "members.profile.memberProfile.tabs.cti"            : "CTI",

    "members.profile.memberProfile.notFound.title"       : "Profil introuvable",
    "members.profile.memberProfile.notFound.description" : "Le profil que vous recherchez n'a pas été trouvé, il a peut-être été déplacé ou supprimé.",
    "members.profile.memberProfile.notFound.backButton"  : "Retour",

    "members.profile.header.back" : "Retour à la liste des membres",

    "members.profile.confirmModal.title"                        : "Confirmer membre",
    "members.profile.unconfirmModal.title"                      : "Rendre le membre non confirmé",
    "members.profile.unconfirmModal.message"                    : "Ceci rendra le statut du membre non confirmé.",
    "members.profile.preConfirmModal.message"                   : "Ce membre est prêt à être confirmé.",
    "members.profile.confirmModal.message"                      : "Ceci confirmera le membre.",
    "members.profile.action.confirmModal.successText"           : "Ce membre est maintenant confirmé.",
    "members.profile.action.unconfirmModal.successText"         : "Ce membre est maintenant non confirmé.",
    "members.profile.action.remove.ineligibility.btn"           : "Supprimer l'inéligibilité",
    "members.profile.action.unconfirm"                          : "Rendre le membre non confirmé",
    "members.profile.blockModal.title"                          : "Bloquer membre",
    "members.profile.unblockModal.title"                        : "Débloquer membre",
    "members.profile.blockModal.field.note"                     : "Note",
    "members.profile.memberType.suspendedUntil"                 : "Suspendu(e) jusqu'au {date}",
    "members.profile.memberType.pendingRemovalRequest"          : "Requête de retrait en attente",
    "members.profile.memberType.manage.title"                   : "Gestion des types de membre",
    "members.profile.memberType.manage.success"                 : "Les types de membre ont été mis à jour",
    "members.profile.memberType.status.decidedBy"               : "Décidé par",
    "members.profile.memberType.status.edit"                    : "Changer le statut",
    "members.profile.memberType.changeStatus"                   : "Mettre le statut du type à jour",
    "members.profile.memberType.toApprove"                      : "Types à changer",
    "members.profile.blockModal.field.note.required"            : "Une note est nécessaire bloquer un membre.",
    "members.profile.ineligibleModal.field.note.required"       : "Une note est nécessaire rendre un membre inéligible.",
    "members.profile.ineligibleModal.title"                     : "Rendre un membre inéligible",
    "members.profile.changeStatus.title"                        : "Changer le statut du membre",
    "members.profile.statusChange.reason"                       : "Raison du changement de statut",
    "members.profile.statusChange.reason.short"                 : "Raison",
    "members.profile.statusChange.organization"                 : "Organisation imposante",
    "members.profile.ineligibleModal.message"                   : "Ceci rendra le statut du membre inéligible, bloquant toute activité sur la plate-forme.",
    "members.profile.changeStatus.notification.message"         : "Soumettre ce changement de statut déclenchera une notification.",
    "members.profile.removeIneligibleModal.field.note.required" : "Une note est nécessaire pour supprimer le statut d'inéligibilité de ce membre",
    "members.profile.action.unblock.btn"                        : "Débloquer",
    "members.profile.action.unblock.modalText"                  : "Vous êtes sur le point de débloquer ce membre.",
    "members.profile.action.remove.ineligible.modalText"        : "Vous êtes sur le point de supprimer le statut d'inéligibilité de ce membre.",
    "members.profile.action.remove.unblock.status.info"         : "Ceci retournera le membre à leur statut précédent: ",
    "members.profile.action.unblock.successText"                : "Débloqué le membre avec succès.",
    "members.profile.action.remove.ineligibility.successText"   : "Suppression de statut d'inéligibilité réussis. ",
    "members.profile.action.block.successText"                  : "Bloqué le membre avec succès .",
    "members.profile.action.ineligibile.successText"            : "Ce membre est maintenant inéligible.",
    "members.profile.action.changestatus.successText"           : "Le statut du membre a été changé avec succès.",

    "members.profile.action.changeStatus"    : "Changer le statut",
    "members.profile.action.status.required" : "Statut est requis.",
    "members.profile.action.note.required"   : "La note est requise.",
    "members.profile.action.reason.required" : "La raison est requise.",
    "members.profile.action.org.required"    : "L'organisation est requise.",

    // Requests
    "members.profile.action.requestMandatoryChanges"          : "Demande de modifications",
    "members.profile.action.requestClassificationChanges"     : "Demande de modification de classification",
    "members.profile.action.view.requestMandatoryChanges"     : "Voir les demande de modifications",
    "members.profile.action.requestClassificationChange"      : "Demande de changement de classification",
    "members.profile.action.view.requestClassificationChange" : "Voir la demande de changement de classification",
    "members.profile.action.classificationChange"             : "Changement de classification",
    "members.profile.action.requestRelease"                   : "Demande de libération permanente",
    "members.profile.action.viewRequestRelease"               : "Voir demande de libération permanente",
    "members.profile.action.ctiRequest"                       : "Demande CTI",

    "members.profile.action.requestMandatoryChanges.success"      : "Votre demande de modifications a été soumise",
    "members.profile.action.requestClassificationChanges.success" : "La demande de changement de classification a été soumise",
    "members.profile.action.requestRelease.success"               : "Votre demande de libération permanente a été soumise",
    "members.profile.action.ctiRequest.success"                   : "Votre demande CTI a été soumise",

    "members.profile.create.RequestRelease"   : "Ceci créera une demande pour libérer en permanence le membre.",
    "members.profile.has.RequestRelease"      : "Libération permanente",
    "members.profile.has.RequestRelease.from" : "Libération permanente de",

    "members.profile.badge.mutatedYear"            : "{mutated} {count}",
    "members.profile.badge.mutatedYear.expiration" : "Date d'expiration",

    "members.profile.badge.pendingRequestMandatoryChanges"      : "Demande de modifications en attente",
    "members.profile.badge.pendingRequestClassificationChanges" : "Demande de modifications de classification",
    "members.profile.badge.pendingRequestRelease"               : "Demande de libération permanente en attente",
    "members.profile.badge.pendingCtiRequest"                   : "Demande CTI en attente",

    "members.profile.header.note.NEW_PROFILE"         : "Nouveau profile.",
    "members.profile.header.note.ADDRESS_CHNAGE"      : "Une adresse a changé.",
    "members.profile.header.note.ADDRESS_CHANGE"      : "Une adresse a changé.",
    "members.profile.header.note.BASIC_FIELDS_CHANGE" : "Un champ de base a changé.",
    "members.profile.header.note.HISTORICAL_SANCTION" : "Le membre a déjà été sanctionné.",


    "members.profile.action.reactivate.successText"        : "Ce membre a été réactivé avec succès.",
    "members.profile.action.remove.reactivate.btn"         : "Réactiver membre",
    "members.profile.action.remove.reactivate.modalText"   : "Réactiver ce membre",
    "members.profile.action.remove.reactivate.successText" : "Le membre a été réactiver avec succès.",


    "members.profile.mandatory.change.fields.first_name"             : "Prénom",
    "members.profile.mandatory.change.fields.last_name"              : "Nom",
    "members.profile.mandatory.change.fields.birthdate"              : "Date de naissance",
    "members.profile.mandatory.change.fields.relation"               : "Relation",
    "members.profile.mandatory.change.fields.gender"                 : "Identité de genre",
    "members.profile.mandatory.change.fields.primary_language"       : "Langue maternelle",
    "members.profile.mandatory.change.fields.secondary_language"     : "Langue seconde",
    "members.profile.mandatory.change.fields.nationality"            : "Citoyenneté",
    "members.profile.mandatory.change.fields.birth_country"          : "Pays de naissance",
    "members.profile.mandatory.change.fields.email"                  : "Courriel",
    "members.profile.mandatory.change.fields.identity_as_indigenous" : "S'identifier comme Autochtone",
    "members.profile.mandatory.change.fields.indigenous_group"       : "Groupe Autochtone",
    "members.profile.mandatory.change.fields.ethnicity"              : "Ethnicité",
    "members.profile.mandatory.change.fields.phone"                  : "Numéro de téléphone",
    "members.profile.mandatory.change.fields.extension"              : "Extension",
    "members.profile.mandatory.change.fields.phone_type"             : "Type de téléphone",
    "members.profile.mandatory.change.fields.address"                : "Adresse",
    "members.profile.mandatory.change.fields.address_type"           : "Type d'adresse",
    "members.profile.mandatory.change.fields.move_in_year"           : "Année d'emménagement",
    "members.profile.mandatory.change.fields.address2"               : "Appartement, suite, unité, étage, bâtiment, etc.",

    "members.profile.mandatory.change.new.request"    : "Nouvelle demande de modification",
    "members.profile.new.request"                     : "Nouvelle demande",
    "members.profile.mandatory.change.cancel.success" : "Demande de modification annulé avec succès.",
    "members.profile.request.cancel.success"          : "Demande annulée avec succès.",
    "members.profile.mandatory.change.make.changes"   : "Des modifications doivent être apportées à au moins un champ pour soumettre une demande de modification.",

    "members.profile.classification.change.fields.type.label"               : "Type de requête",
    "members.profile.classification.change.fields.type.classification"      : "Classification",
    "members.profile.classification.change.fields.type.overage"             : "Sur-classé",
    "members.profile.classification.change.fields.type.underage"            : "Sous-classé",
    "members.profile.classification.change.fields.from.division.label"      : "Division d'origine ",
    "members.profile.classification.change.fields.to.division.label"        : "Division cible",
    "members.profile.classification.change.fields.from.team.category.label" : "Catégorie d'équipe d'origine",
    "members.profile.classification.change.fields.to.team.category.label"   : "Catégorie d'équipe cible",
    "members.profile.classification.change.fields.division.required"        : "La division est requise.",
    "members.profile.classification.change.fields.type.required"            : "Veuillez sélectionner un type",
    "members.profile.classification.change.fields.team.category.required"   : "La catégorie d'équipe est requise.",
    "members.profile.classification.change.noAvailableDivisions"            : "Il n'y a pas de divisions disponibles pour ce type de requête.",
    "members.profile.classification.view.fields.division"                   : "Division",
    "members.profile.classification.view.fields.team.category"              : "Catégorie d'équipe",
    "members.profile.classification.view.fields.division.birth.years"       : "Né entre {minyear} et {maxyear}.",

    "members.profile.status.logs.sidepanel.title" : "Changement de statut",

    "members.setting.organisation_imposing_ineligibility.missing" : "Il manque le setting 'organisation_imposing_ineligibility'",

    "members.profile.multipleorganization.title" : "2<sup>ième</sup> Club",
}