import Translate from '@spordle/intl-elements';
import React, { } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Button,
    ModalBody,
    ModalFooter
} from "reactstrap";
import UserDisplay from '../../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../../components/UserImg';

class ClinicProfileRegistrationAdd4 extends React.Component{

    goToInvoice = () => {
        this.props.toggle();
        this.props.history.push(`invoice?invoiceNumber=${this.props.invoiceNumber}`);
    }

    render(){
        return (
            <>
                <ModalBody className="text-center">
                    {this.props.member &&
                        <UserDisplay card className="p-2 d-flex">
                            <UserDisplay.Container>
                                <UserImg
                                    abbr={this.props.member.first_name}
                                    src={this.props.member.picture?.full_path}
                                    filePos={this.props.member.picture?.file_position}
                                    alt={this.props.member.first_name + ' ' + this.props.member.last_name}
                                    width="50"
                                />
                            </UserDisplay.Container>
                            <UserDisplay.Container className="d-flex align-center justify-content-between w-100">
                                <div>
                                    <UserDisplay.Title>{this.props.member.first_name + ' ' + this.props.member.last_name}</UserDisplay.Title>
                                    <UserDisplay.Subtitle className="font-medium">{this.props.member.unique_identifier ? ` #${this.props.member.unique_identifier}` : '-'}</UserDisplay.Subtitle>
                                </div>
                                <div><i className="mdi mdi-check-circle font-24 text-success" /></div>
                            </UserDisplay.Container>
                        </UserDisplay>
                    }
                    <div className="h4 font-bold mb-2"><Translate id='memberProfile.registration.add.confirmation.title' /></div>
                    {this.props.invoiceNumber &&
                        <>
                            <p className="mb-1"><Translate id='memberProfile.registration.add.confirmation.confirmed' /></p>
                            <button type='button' className='reset-btn text-link' onClick={this.goToInvoice}><p className="text-primary"># {this.props.invoiceNumber}</p></button>
                        </>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.goToInvoice} color='primary'><Translate id='memberProfile.registration.add.confirmation.seeInvoice' /></Button>
                    <Button color='primary' outline onClick={this.props.toggle}><Translate id='misc.close' /></Button>
                </ModalFooter>
            </>
        );
    }
}

export default withRouter(ClinicProfileRegistrationAdd4);