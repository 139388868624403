import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import { Form, Formik } from 'formik';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { stringBuilder } from '@spordle/helpers';
import { DisplayI18n, displayI18n } from '../../../../../helpers/i18nHelper';
import QuickViewButton from '../../../../../components/quickView/QuickViewButton';
import DisplayBirthdateAge from '../../../../../components/DisplayBirthdateAge';
import moment from 'moment';
import MemberOverviewStatus from '../../../profile/profileHeader/components/MemberOverviewStatus';
import { FormattedPlural } from 'react-intl';
import { getGenericLocale } from '../../../../../contexts/I18nContext';
import DisplayOrg from '../../../../../components/DisplayOrg';
import DisplayMemberTypeIcon from '../../../../../components/display/DisplayMemberTypeIcon';
import { getLocation } from '../../../../teams/TeamHelpers';
import InlineCopy from '../../../../../components/inlineCopy/InlineCopy';
import CrossFade from '../../../../../components/crossFade/CrossFade';
import FormikApiError from '../../../../../components/formik/FormikApiError';

const MemberSearchValidation = ({ toggle, members, setKeptMember, previous, next, isSameOrg }) => {
    const { mergeMembers } = useContext(MembersContext);
    const [ hovering, setHovering ] = useState('');

    // confirm step

    /**
     * Component that shows which member is merged into which
     * @param {Object} props
     * @param {object} props.from Member that will overwrite
     * @param {object} props.to Member that will be overwritten
     * @returns {Translate}
     */
    const MergeInto = ({ from, to }) => (
        <Translate
            id='members.search.memberMerge.modal.mergeFromTo'
            values={{
                from: from.first_name + ' ' + from.last_name,
                fromNb: from.unique_identifier,
                to: to.first_name + ' ' + to.last_name,
                toNb: to.unique_identifier,
                span: (chunks) => (
                    <span className="text-dark font-medium">
                        {chunks}
                    </span>
                ),
            }}
        />
    )

    /**
     * @param {Object} props
     * @param {object} props.member
     * @returns {JSX.Element}
     */
    const MemberCard = ({ member, setFieldValue, className }) => {
        const date = moment(member.picture_expiration_date);
        const expirationDate = date.isValid() ? date : null;
        const color = date.isBefore(moment().add(1, 'year')) ? 'danger' : 'secondary';

        return (

            <div
                className={stringBuilder("flex-grow-1 align-self-stretch transition", className)}
                style={{
                    opacity: !!hovering && hovering !== member.member_id ? 0.5 : 1,
                }}
                onMouseEnter={() => setHovering(member.member_id)}
                onMouseLeave={() => setHovering('')}
            >
                <Card
                    className="mb-0 h-100 w-100 border shadow-sm"
                    style={{ minWidth: 'auto' }}
                >
                    <CardBody className='d-flex flex-wrap flex-md-nowrap'>
                        <UserDisplay.Container className='mb-auto mr-md-3 w-100 w-md-auto'>
                            <UserImg
                                abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                src={member.picture?.full_path}
                                filePos={member.picture?.file_position}
                                width={100}
                                alt={member.first_name + ' ' + member.last_name}
                                className='mx-auto'
                            />
                            {expirationDate &&
                                <div className={stringBuilder("font-medium p-1 small text-center w-100 text-nowrap", { 'text-medium': color === 'secondary', 'text-danger': color === 'danger' })}>
                                    <i className={stringBuilder("mdi mdi-information-outline mr-1", { 'text-primary': color === 'secondary', 'text-danger': color === 'danger' })} /><Translate id='members.profile.memberProfile.header.picture.expiry' /><span className="ml-1"><DateFormat value={expirationDate} /></span>
                                </div>
                            }
                        </UserDisplay.Container>
                        <UserDisplay.Container className='mb-auto'>
                            <div className='text-dark mb-2'>
                                <MemberOverviewStatus lastLog={member.lastLog} />
                                <div className='h4 font-bold mb-0 mt-1'>
                                    <QuickViewButton member={{ member_id: member.member_id }}>
                                        {member.first_name} {member.last_name}
                                    </QuickViewButton>
                                </div>
                                <div className='font-medium'>
                                    <InlineCopy toCopy={member.unique_identifier}>
                                    #{member.unique_identifier}
                                    </InlineCopy>
                                </div>
                                <div className='font-medium'><DisplayBirthdateAge birthdate={member.birthdate} age={member.age} /></div>
                            </div>
                            <div className='text-dark mb-2'>
                                <div className="text-muted"><Translate id='members.profile.memberProfile.header.primaryOrg' /></div>
                                <div className='font-medium'>
                                    <DisplayI18n
                                        field="name"
                                        defaultValue={member.organisation?.organisation_name}
                                        i18n={member.organisation?.i18n}
                                    />
                                </div>
                            </div>
                            {member.shownMemberTypes.length > 0 &&
                            <div className='text-dark mb-2'>
                                <div className="text-muted">
                                    <FormattedPlural
                                        value={member.shownMemberTypes.length}
                                        one={<Translate id='members.profile.memberProfile.header.memberType' />}
                                        other={<Translate id='members.profile.memberProfile.header.memberTypes' />}
                                    />
                                    {member.isLastPeriod && <small className='ml-1'>(<Translate id='members.profile.memberProfile.header.positions.lastPeriod' />)</small> }
                                </div>
                                {member.shownMemberTypes?.map((type) => (
                                    <div key={type.member_type?.member_type_id} className='d-flex font-medium'>
                                        <DisplayMemberTypeIcon memberType={type} />
                                        <div className="d-flex align-items-center flex-wrap">
                                            <DisplayI18n defaultValue={type.member_type.name} i18n={type.member_type.i18n} field='name' />
                                            {type.organisation &&
                                                <div className="text-muted ml-1 small">
                                                    (<DisplayOrg org={type.organisation} />)
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                            }
                            {member.addresses?.length > 0 &&
                            <div className='text-dark mb-2'>
                                <div className='text-muted'>
                                    <Translate id='members.profile.overview.memberAddressPhone.primaryAddress' />
                                </div>
                                <div className='font-medium'>
                                    {getLocation(member.addresses.find((address) => address.default_address == 1) || member.addresses[0])}
                                </div>
                            </div>
                            }
                        </UserDisplay.Container>
                    </CardBody>
                    {setFieldValue &&
                    <CardFooter className='bg-white border-top text-dark font-medium text-center'>
                        <Button
                            color='primary'
                            onClick={() => {
                                setFieldValue('selectedMemberId', member.member_id)
                            }}
                            block
                        >
                            <Translate id='members.search.memberMerge.modal.keep' />
                        </Button>
                    </CardFooter>
                    }
                </Card>
            </div>
        )
    }

    const formatInitialValues = (members) => {
        const formattedMembers = members.map((member) => {
            // checker setting
            const statusLogs = member.member_status_logs;

            const memberTypes = (member.member_types || [])
                .sort((a, b) => {
                    const orgA = a.organisation;
                    const orgB = b.organisation;

                    if(orgA && orgB){
                        const orgNameA = displayI18n('name', orgA.i18n, orgA.organisation_name, getGenericLocale()) || "";
                        const orgNameB = displayI18n('name', orgB.i18n, orgB.organisation_name, getGenericLocale()) || "";

                        return orgNameA.localeCompare(orgNameB);
                    }

                    return 0;
                });

            const currentSeasonTypes = memberTypes.filter((m) => m.current_season == 1);
            const lastSeasonTypes = memberTypes.filter((m) => m.last_season == 1);

            const shownMemberTypes = currentSeasonTypes.length > 0 ? currentSeasonTypes : lastSeasonTypes;
            const isLastPeriod = currentSeasonTypes.length <= 0 && lastSeasonTypes.length > 0;

            const primaryAddress = member.addresses?.length > 0 ? member.addresses.find((address) => address.default_address == 1) : null;

            return {
                ...member,
                lastLog: statusLogs ? statusLogs[statusLogs.length - 1] : null,
                shownMemberTypes: shownMemberTypes,
                isLastPeriod: isLastPeriod,
                primaryAddress: primaryAddress,
            }
        })

        return {
            member1: formattedMembers[0],
            member2: formattedMembers[1],
            selectedMemberId: '',
        }
    }

    const getFinalMember = (values) => {
        const { member1, member2 } = values

        const hasPicture = (member) => !!member.picture;
        const hasExpirationDate = (member) => moment(member.picture_expiration_date).isValid();

        const formatWithNewPicture = (member) => ({
            ...values.selectedMemberId === values.member1.member_id ? member1 : member2,
            picture: member.picture,
            picture_expiration_date: member.picture_expiration_date,
        })

        if(hasPicture(member1) && !hasPicture(member2)){
            // member 1 has picture but not member 2, we take member1's picture
            return formatWithNewPicture(member1)
        }else if(!hasPicture(member1) && hasPicture(member2)){
            // member 2 has picture but not member 1, we take member2's picture
            return formatWithNewPicture(member2)
        }else if(hasPicture(member1) && hasExpirationDate(member1) && hasPicture(member2) && hasExpirationDate(member2)){
            // both have picture and valid expiration date, we take most recent
            const expiration1 = moment(member1.picture_expiration_date);
            const expiration2 = moment(member2.picture_expiration_date);

            return formatWithNewPicture(expiration1.isAfter(expiration2) ? member1 : member2)
        }

        // no member has a picture or a valid expiration date, we keep the default pictures
        return values.selectedMemberId === values.member1.member_id ? member1 : member2
    }

    return (
        <Formik
            initialValues={formatInitialValues(members)}
            onSubmit={({ member1, member2, selectedMemberId }, { setSubmitting, setStatus }) => {
                /**
                 * @param {object} keep Member to keep
                 * @param {object} del Member overwritten
                 * @returns {object} formatted values for api
                 */
                const formatVal = (keep, del) => {
                    setKeptMember(keep);

                    return {
                        member_to_keep_id: keep.member_id,
                        member_to_delete_id: del.member_id,
                    }
                }

                const newValues = selectedMemberId === member1.member_id ? formatVal(member1, member2) : formatVal(member2, member1);

                mergeMembers(newValues)
                    .then(next)
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            setSubmitting(false);
                            let errorMsg;
                            if(error.i18n){
                                errorMsg = <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />;
                            }else{
                                let msgId;
                                switch (error.message){
                                    case '3045':
                                        msgId = `contexts.membersContext.${error.message}`;
                                        break;
                                    default:
                                        msgId = 'misc.error';
                                        break;
                                }
                                errorMsg = <Translate id={msgId} />
                            }
                            setStatus({ error: errorMsg, showError: true });
                        }
                    })
            }}
        >
            {({ values, isSubmitting, setFieldValue }) => (
                <Form>
                    <ModalHeader tag='div' toggle={toggle}>
                        {values.selectedMemberId ?
                            <Translate id='members.search.memberMerge.modal.title' />
                            :
                            <QuickViewButton member={members}>
                                <Translate id='members.search.memberMerge.modal.title' />
                            </QuickViewButton>
                        }
                    </ModalHeader>
                    <OverlayLoader isLoading={isSubmitting}>
                        <CrossFade isVisible={!values.selectedMemberId}>
                            <ModalBody onMouseEnter={() => setHovering('')}>
                                <Row form>
                                    <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                        <MemberCard className="h-100" member={values.member2} setFieldValue={setFieldValue} />
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <MemberCard className="h-100" member={values.member1} setFieldValue={setFieldValue} />
                                    </Col>
                                </Row>
                            </ModalBody>
                        </CrossFade>
                        <CrossFade isVisible={!!values.selectedMemberId}>
                            <ModalBody className="text-center">
                                <div className="h4 font-bold mt-2 mb-3"><Translate id="members.search.memberMerge.modal.confirm" /></div>
                                <p className='mb-3'>
                                    <MergeInto {... values.selectedMemberId === values.member2.member_id ? { from: values.member1, to: values.member2 } : { from: values.member2, to: values.member1 }} />
                                    <span className='d-block'>
                                        <Translate id='members.search.memberMerge.modal.picture' />
                                    </span>
                                    <u className='d-block text-dark font-medium'>
                                        <Translate id='members.search.memberMerge.modal.deleted' values={{ name: values.selectedMemberId === values.member1.member_id ? values.member2.first_name + ' ' + values.member2.last_name : values.member1.first_name + ' ' + values.member1.last_name }} />
                                    </u>
                                </p>
                                <div className='h4 font-bold mb-3'><Translate id='members.search.memberMerge.modal.final' /></div>
                                <MemberCard member={getFinalMember(values)} className='mx-auto text-left mb-3 w-100 w-lg-50' />
                                <FormikApiError />
                            </ModalBody>
                        </CrossFade>
                        <ModalFooter>
                            <Button disabled={isSubmitting} type="button" color='primary' onClick={values.selectedMemberId ? () => setFieldValue('selectedMemberId', null) : previous} outline><Translate id='misc.previous' /></Button>
                            {values.selectedMemberId &&
                                <Button color="primary" type="submit" className="ml-auto"><Translate id="members.search.memberMerge.merge.action" /></Button>
                            }
                            <Button disabled={isSubmitting} type="button" color='primary' onClick={toggle} outline className={values.selectedMemberId ? undefined : 'ml-auto'}><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default MemberSearchValidation;