import { FormikDateTime, FormikError, FormikInputNumber, FormikInputText, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Alert,
    Button,
    Card,
    CardBody,
    Col,
    Collapse,
    FormGroup,
    Label,
    Row
} from "reactstrap";
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import CardSectionTitle from '../../../../../../../components/CardSectionTitle';
import Required from '../../../../../../../components/formik/Required';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import AddTransferDurationsSelect from '../../../../../../../components/transferRequest/add/AddTransferDurationsSelect';
import AddTransferExtensionsSelect from '../../../../../../../components/transferRequest/add/AddTransferExtensionsSelect';
import AddTransferFederationInput from '../../../../../../../components/transferRequest/add/AddTransferFederationInput';
import AddTransferStatusSelect from '../../../../../../../components/transferRequest/add/AddTransferStatusSelect';
import { PeriodsContext } from '../../../../../../../contexts/contexts';
import { I18nContext } from '../../../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../../../contexts/MembersContext';
import FileUpload from '../../../../../../../components/uploader/fileUpload/FileUpload';
import { separateFilesPerSize } from '../../../../../../../components/uploader/uploadHelpers';
import { displayI18n, DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import { parseJSON } from '../../../../../../../helpers/helper';
import { OrganizationContext } from '../../../../../../../contexts/OrganizationContext';
import { TransferContext } from '../../../../../../../contexts/TransferContext';
import IsGod from '../../../../../../../components/permissions/IsGod';
import { array, mixed, number, object, string } from 'yup';
import moment from 'moment';

const MemberProfileTransferCTIAdd = ({ memberId, refreshTransfers }) => {
    const periodsContext = useContext(PeriodsContext);
    const organizationContext = useContext(OrganizationContext);
    const membersContext = useContext(MembersContext);
    const { getGenericLocale } = useContext(I18nContext);
    const transferContext = useContext(TransferContext);
    const history = useHistory();

    const [ showPowerUserCodes, setShowPowerUserCodes ] = useState(false);

    const getDynamicForm = () => {
        return Object.values(parseJSON(organizationContext.settings.international_transfer_fields?.value, {}))
    }

    const renderField = (field, formik) => {
        switch (field.type){
            // Generic Fields
            case "TEXT":
                return <FormikInputText id={field.code} name={field.code} trim />
            case "TEXT_AREA":
                return <FormikTextArea id={field.code} name={field.code} rows='4' trim />
            case "DATE":
                return <FormikDateTime id={field.code} name={field.code} timeFormat={false} />
            case "SELECT":
                return (
                    <FormikSelect
                        id={field.code} name={field.code}
                        renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                        options={Object.keys(field.field_options).reduce((options, optionKey) => {
                            const option = field.field_options[optionKey]
                            options.push({
                                label: option.name,
                                i18n: option.i18n,
                                value: option.code,
                            })
                            return options;
                        }, [])}
                    />
                )
            case "FILE":
                return (
                    <>
                        <FileUpload
                            onFileSelect={(files) => {
                                formik.setFieldValue(field.code, files);
                            }}
                            dropzoneProps={{
                                multiple: true,
                                accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                            }}
                        />
                        <FormikError name={field.code} />
                    </>
                )
            case "MONEY":
                return (
                    <I18nContext.Consumer>
                        {(i18n) => (
                            <FormikInputNumber
                                allowLeadingZeros fixedDecimalScale
                                id={field.code}
                                name={field.code}
                                allowNegative={false}
                                decimalScale={2}
                                thousandSeparator=' '
                                decimalSeparator={i18n.getGenericLocale() === 'fr' ? ',' : '.'}
                                suffix={i18n.getGenericLocale() === 'fr' ? '$' : undefined}
                                prefix={i18n.getGenericLocale() !== 'fr' ? '$' : undefined}
                            />
                        )}
                    </I18nContext.Consumer>
                )

            // Specific Fields
            case "ORGANISATION_SELECT":
                return <AddTransferFederationInput id={field.code} name={field.code} international direction={field.code === "target_organisation_id" ? 'outgoing' : 'incoming'} />
            case "PERIOD_SELECT":
                return (
                    <FormikSelect
                        id={field.code} name={field.code}
                        searchKeys={[
                            `i18n.${getGenericLocale()}.name`,
                        ]}
                        renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                        options={periodsContext.periods.reduce((keptPeriods, period) => {
                            if(period.active == 1){
                                keptPeriods.push({
                                    label: period.name,
                                    i18n: period.i18n,
                                    value: period.period_id,
                                })
                            }
                            return keptPeriods;
                        }, [])}
                    />
                )
            case "DURATION_SELECT":
                return <AddTransferDurationsSelect id={field.code} name={field.code} />
            case "DIRECTION_SELECT":
                return (
                    <FormikSelect
                        id={field.code} name={field.code}
                        renderOption={({ option: transferDirection }) => <DisplayI18n field='name' defaultValue={transferDirection.label} i18n={transferDirection.i18n} />}
                        loadData={(from) => {
                            switch (from){
                                case 'CDM':
                                    return transferContext.getTransferDirections({ active: 1 })
                                        .then((transferDirections) => {
                                            return transferDirections.sort((durationA, durationB) => parseInt(durationA.display_order) - parseInt(durationB.display_order))
                                                .reduce((newArray, transferDirection) => {
                                                    if(transferDirection.active == 1){
                                                        newArray.push({
                                                            value: transferDirection.transfer_direction_id,
                                                            label: transferDirection.name,
                                                            i18n: transferDirection.i18n,
                                                        })
                                                    }
                                                    return newArray
                                                }, [])
                                        })
                                default:
                                    break;
                            }
                        }}
                    />
                )
            case "TRANSFER_STATUS_SELECT":
                return <AddTransferStatusSelect id={field.code} name={field.code} international />
            case "EXT_SELECT":
                return <AddTransferExtensionsSelect id={field.code} name={field.code} />

            default:
                return field.code;
        }
    }

    const getFieldCol = (field) => {
        switch (field.type){
            case "FILE":
            case "TEXT_AREA":
                return "12";
            default:
                return "4";
        }
    }

    const getDynamicFormInitialValues = () => {
        const init = {}
        getDynamicForm().forEach((group, key) => {
            Object.keys(group.fields || {}).forEach((field_code, key) => {
                const field = group.fields[field_code]
                if(field.show_in_form == 1){
                    switch (field.type){
                        case "MONEY":
                            init[field_code] = "0.00"
                        case "FILE":
                            init[field_code] = []
                        default:
                            init[field_code] = ""
                    }
                }
            })
        })
        return init
    }

    const getYupType = (field) => {
        switch (field.type){
            case "MONEY":
                return number()
            case "FILE":
                return array()
            case "DATE":
                return mixed().test({
                    name: 'date-format',
                    message: <Translate id='form.validation.date.format' />,
                    test: function(date){
                        return date ? moment.isMoment(date) : true
                    },
                })
            default:
                return string()
        }
    }

    const getDynamicFormValidationShema = () => {
        const validationObj = {}
        getDynamicForm().forEach((group) => {
            Object.keys(group.fields || {}).forEach((field_code) => {
                const field = group.fields[field_code]
                if(field.show_in_form == 1){
                    validationObj[field_code] = (field.mandatory == 1) ? getYupType(field).required(<Translate id="form.validation.field.required" values={{ field: displayI18n(field, field.i18n, field.name, getGenericLocale()) }} />) : getYupType(field)
                }
            })
        })
        return object().shape(validationObj)
    }

    return (
        <Card className="card-shadow">
            <CardBody>
                <Formik
                    initialValues={getDynamicFormInitialValues()}
                    validationSchema={getDynamicFormValidationShema()}
                    onSubmit={({
                        attachments,
                        ...values
                    }, { setSubmitting, setStatus }) => {
                        const formattedFiles = separateFilesPerSize((attachments && attachments.length > 0) ? attachments : []);

                        const apiValues = {
                            ...values,
                            attachments: formattedFiles.splice(0, 1)[0],
                        }

                        // Format
                        Object.keys(values).forEach((v) => {
                            if(v.indexOf("date") !== -1 && moment.isMoment(values[v]))
                                apiValues[v] = values[v].format('YYYY-MM-DD')
                            if(v.indexOf("fee") !== -1)
                                apiValues[v] = Math.round(values[v] * 100);
                        })

                        setStatus();
                        membersContext.createMemberTransferCTIRequest(memberId, apiValues)
                            .then(async(memberTransferId) => {
                                if(formattedFiles.length > 0){
                                    await Promise.all(
                                        formattedFiles.map((subArr) => {
                                            return membersContext.createMemberTransferAttachment(memberId, memberTransferId, subArr);
                                        }),
                                    )
                                }
                            })
                            .then(() => {
                                refreshTransfers();
                                history.replace('history');
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    if(error.i18n){
                                        setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                    }else{
                                        switch (error.message){
                                            case '3335':// The member is already in a transfer
                                                setStatus(<Translate id={`members.profile.transfers.add.error.${error.message}`} />);
                                                break;
                                            case '3403':// The transfer couldn’t be initiated because the requested member has an active outstanding balance to settle
                                                setStatus(<Translate id={`transfer.error.${error.message}`} />);
                                                break;
                                            case '3416':// No transfer workflow found
                                            case '3417':// No transfer workflow found
                                                setStatus(<Translate id={`members.profile.transfers.add.error.${error.message}`} />);
                                                break;
                                            case '3441':// Taregt Team Transfers are blocked
                                                setStatus(<Translate id={`members.profile.transfers.add.error.${error.message}`} />);
                                                break;
                                            default:
                                                setStatus(<Translate id='misc.error' />);
                                                break;
                                        }
                                    }
                                    setSubmitting(false);
                                }
                            })
                    }}
                >
                    {(formik) => (
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <CardSectionTitle title={`members.profile.transfers.form.title.cti`} className="d-flex">
                                <div className='ml-auto'><IsGod><div className='clickable' onClick={() => setShowPowerUserCodes(!showPowerUserCodes)}><i className='mdi mdi-eye' /></div></IsGod></div>
                            </CardSectionTitle>
                            <Form>
                                {getDynamicForm().map((group, key) => {
                                    const fields = group.fields || {}
                                    if(!Object.keys(fields).some((field_code) => fields[field_code].show_in_form == 1))return null
                                    return (
                                    // eslint-disable-next-line react/no-array-index-key
                                        <div key={group.name + key}>
                                            <div className="h5 font-bold mb-3"><DisplayI18n field='name' defaultValue={group.name} i18n={group.i18n} /></div>
                                            <Row form>
                                                {Object.keys(fields).map((field_code, key) => {
                                                    const field = fields[field_code]
                                                    if(field.show_in_form == 0)return null
                                                    return (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <Col key={field_code + key} lg={getFieldCol(field)}>
                                                            <FormGroup>
                                                                <Label for={field_code} className='text-muted'>
                                                                    <DisplayI18n field='name' defaultValue={field.name} i18n={field.i18n} /> {field.madatory == 1 && <Required />}
                                                                    {(showPowerUserCodes) && <IsGod><div className='small'>{field.code}:{field.type}</div></IsGod>}
                                                                </Label>
                                                                {renderField(field, formik)}
                                                            </FormGroup>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </div>
                                    )
                                })}

                                <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                    <Alert color='danger'>{formik.status}</Alert>
                                </Collapse>

                                <div className="mt-3 text-right">
                                    <Button color="primary" className="mr-2" type='submit' disabled={formik.isSubmitting}><Translate id='misc.create' /></Button>
                                </div>
                            </Form>
                        </OverlayLoader>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
}

MemberProfileTransferCTIAdd.propTypes = {
    international: PropTypes.bool,
}

MemberProfileTransferCTIAdd.defaultProps = {
    international: false,
}

export default MemberProfileTransferCTIAdd;