import { useSpordleTable } from "@spordle/datatables";
import { FieldArray, Form, Formik } from "formik";
import { Alert, Button, Collapse, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { array, object } from "yup";
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import SpordleDragDropContext from "../../../../../components/DragNDrop/SpordleDragDropContext";
import SpordleDraggable from "../../../../../components/DragNDrop/SpordleDraggable";
import SpordleDroppable from "../../../../../components/DragNDrop/SpordleDroppable";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { ReportsContext } from "../../../../../contexts/ReportsContext";
import { useContext } from "react";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { AppContext } from "../../../../../contexts/contexts";

const EditColumnsModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal size="lg" isOpen={isOpen} analyticsName="reportsEditColumnsOrder">
            <EditColumnsModalInner {...props} />
        </AnalyticsModal>
    );
}

const EditColumnsModalInner = ({ toggle, report }) => {
    const { generateId } = useSpordleTable();
    const columns = report.headers;
    const { updateRouteKey } = useContext(AppContext);
    const sortedColumns = [ ...columns ].sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order));
    const { updateReportHeaders } = useContext(ReportsContext);

    const handleOnDragEnd = (result) => {
        const { source, destination } = result;

        if(destination){
            const isSameSection = source.droppableId === destination.droppableId;

            if(isSameSection){
                source.payload.move(source.index, destination.index);
            }
        }

    }

    const checkIfDiff = (oldCols, newCols) => {
        let isDiff = false;

        for(let i = 0; i < oldCols.length && !isDiff; i++){
            const oldCol = oldCols[i];
            const newCol = newCols[i];

            isDiff = oldCol.header_id !== newCol.header_id;
        }

        return isDiff;

    }

    return (
        <Formik
            initialStatus={{
                isVisible: false,
                msg: null,
            }}
            initialValues={{
                columns: sortedColumns || [],
            }}
            validationSchema={object().shape({
                columns: array(),
            })}
            onSubmit={(values, { setStatus }) => {
                const hasChanged = checkIfDiff(sortedColumns, values.columns);

                if(hasChanged){
                    const apiValues = [];

                    for(let c = 0; c < values.columns.length; c++){
                        const col = values.columns[c];

                        apiValues.push({
                            ...col,
                            display_order: c * 10,
                        });
                    }

                    updateReportHeaders(report.report_id, { headers: apiValues })
                        .then(updateRouteKey)
                        .catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                console.error(e);
                                setStatus({ isVisible: true, msg: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} /> })
                            }
                        });
                }
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={toggle}>
                            Edit columns order
                        </ModalHeader>
                        <ModalBody>
                            <FieldArray name='columns'>
                                {(columnsHelper) => (
                                    <SpordleDragDropContext onDragEnd={handleOnDragEnd}>
                                        <SpordleDroppable
                                            droppableId="available"
                                            direction="vertical"
                                            payload={columnsHelper}
                                        >
                                            {(dropProvided) => (
                                                <div
                                                    {...dropProvided.droppableProps}
                                                    ref={dropProvided.innerRef}
                                                    className='w-100'
                                                    id={generateId('available', 'droppable')}
                                                >
                                                    {formik.values.columns.map((column, index) => (
                                                        <SpordleDraggable
                                                            key={column.header_id}
                                                            draggableId={column.header_id}
                                                            index={index}
                                                            payload={column}
                                                        >
                                                            {(dragProvided) => (
                                                                <div
                                                                    ref={dragProvided.innerRef}
                                                                    {...dragProvided.draggableProps}
                                                                    {...dragProvided.dragHandleProps}
                                                                    id={generateId('available', column.header_id)}
                                                                >
                                                                    <UserDisplay card block hover className="mb-2">
                                                                        <UserDisplay.Container className="mr-2">
                                                                            <i className='mdi mdi-drag text-muted' />
                                                                        </UserDisplay.Container>
                                                                        <UserDisplay.Container className="h3 mb-0 font-medium mr-3">
                                                                            {index + 1}
                                                                        </UserDisplay.Container>
                                                                        <UserDisplay.Container>
                                                                            <UserDisplay.Title>
                                                                                <DisplayI18n field='name' i18n={column.i18n} defaultValue={column.name} />
                                                                            </UserDisplay.Title>
                                                                            <UserDisplay.Subtitle>
                                                                                <DisplayI18n field='description' i18n={column.i18n} defaultValue={column.description} />
                                                                            </UserDisplay.Subtitle>
                                                                        </UserDisplay.Container>
                                                                    </UserDisplay>
                                                                </div>
                                                            )}
                                                        </SpordleDraggable>
                                                    ))}
                                                    {dropProvided.placeholder}
                                                </div>
                                            )}
                                        </SpordleDroppable>
                                    </SpordleDragDropContext>
                                )}
                            </FieldArray>
                            <Collapse isOpen={formik.status.isVisible && formik.status.msg}>
                                <Alert className="mb-0" color="danger" toggle={() => formik.setStatus({ ...formik.status, isVisible: false })}>
                                    {formik.status.msg}
                                </Alert>
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="submit" color="primary">Submit</Button>
                            <Button type="button" onClick={toggle} color="primary" outline>Cancel</Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default EditColumnsModal;