const Clock = ({ color, ...props }) => {
    return (
        <svg {...props} id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.7 19.7">
            <g id="clock">
                <path
                    className="svg-current-color"
                    id="Tracé_776"
                    data-name="Tracé 776"
                    d="M9.84,0A9.85,9.85,0,1,0,19.7,9.85,9.85,9.85,0,0,0,9.84,0Zm0,17.73a7.88,7.88,0,1,1,7.88-7.88h0A7.88,7.88,0,0,1,9.84,17.73Z"
                />
                <path
                    className="svg-current-color"
                    id="Tracé_777"
                    data-name="Tracé 777"
                    d="M13.78,8.86H10.83V5.91a1,1,0,1,0-2,0V9.85a1,1,0,0,0,1,1h3.94a1,1,0,1,0,0-2Z"
                />
            </g>
        </svg>
    )
}

export default Clock