import { createContext } from "react";

/** @type {React.Context<Omit<import('./AuthContext').default, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>> & import('./AuthContext').default['state']} */
export const AuthContext = createContext();
AuthContext.displayName = 'AuthContext';

/** @type {React.Context<Omit<import('./PeriodsContext').default, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & import('./PeriodsContext').default['state']} */
export const PeriodsContext = createContext();
PeriodsContext.displayName = 'PeriodsContext';

/** @type {React.Context<Omit<import('./AppContext').default, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & import('./AppContext').default['state']} */
export const AppContext = createContext();
AppContext.displayName = 'AppContext';