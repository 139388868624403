import SpordleTableProvider, { Refresh, SearchInput, SpordleTableContext, SpordleTableView } from "@spordle/datatables";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { Badge, Button, Card, Col, Collapse, CustomInput, FormGroup, Row } from "reactstrap";
import { AxiosCancelTransferCalls, AxiosIsCancelled } from "../../../api/CancellableAPI";
import OpenSidePanelOnSearch from "../../../components/sidePanel/OpenSidePanelOnSearch";
import SpordlePanelTable from "../../../components/sidePanel/SpordlePanel";
import TransferSidepanel from "../../../components/sidePanel/transfers/TransferSidepanel";
import { I18nContext } from "../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { PeriodsContext } from "../../../contexts/contexts";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import queryString from 'query-string';
import useSavedSearch from "../../../components/customHooks/useSavedSearch";
import { getActualStatus, getTransferStatusBadge } from "../../../helpers/transferHelper";
import EmptyLayout from "../../../components/table/EmptyLayout";
import SpordleSelect from "@spordle/spordle-select";
import SavedSearchCue from "../../../components/savedSearchCue/SavedSearchCue";

const MemberCTIRequests = (props) => {
    const organizationContext = useContext(OrganizationContext);
    const identityRolesContext = useContext(IdentityRolesContext)
    const i18nContext = useContext(I18nContext);
    const periodsContext = useContext(PeriodsContext);
    const location = useLocation();
    const isFederation = organizationContext.organisation_category.name === "NSO";

    // prevents search/filter with certain organization categories
    const isUnsearchable = isFederation || [ 'Member branches', 'Member partner' ].includes(organizationContext.organisation_category.name)

    const [ needFilter, setNeedFilter ] = useState(false);
    const [ search, setSearch ] = useState(queryString.parse(location.search).search);

    const { savedSearch, saveSearch, clearSavedSearch } = useSavedSearch(`cti-transfer-search-filters-${identityRolesContext.identity_role_id}-${organizationContext.organisation_id}-${periodsContext.selectedPeriod.period_id}`);

    return (
        <SpordlePanelTable
            allowOutsideClick
            sidePanel={(props) => <TransferSidepanel {...props} from='tasks' />}
            dataIndex='member_transfer_id'
            table={(panelProps) => {
                return (
                    <SpordleTableProvider
                        id='transfers'
                        tableHover bordered striped
                        clickable
                        ref={panelProps.spordleTableRef}
                        desktopWhen
                        dataIndex='member_transfer_id'
                        pagination={20}
                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                        defaultSearchValue={!isUnsearchable ? search : null} // needed for notification redirect
                        searchKeys={[
                            'member_transfer_id', // needed for notification redirect
                            'member.unique_identifier',
                            'member.last_name',
                            'member.first_name',
                            `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                            'organisation.organisation_name',
                            `target_organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                            'target_organisation.organisation_name',
                            `transfer_reason_type.i18n.${i18nContext.getGenericLocale()}.name`,
                            'transfer_reason_type.name',
                            'transfer_type',
                            'created_at',
                        ]}
                        defaultSorting='-date'
                        initFilter={{
                            onlyShowActionRequired: !search,
                            status: savedSearch?.status || '',
                            type: 'INTERNATIONAL',
                            direction: savedSearch?.direction || '',
                        }}
                        loadData={(from, { filters }, spordleTable) => {
                            switch (from){
                                case 'FILTER':
                                    saveSearch(filters);
                                case 'REFRESH':
                                    spordleTable.setLoading();
                                case 'CDM':
                                    if(isUnsearchable && !search){
                                        if(!filters.status && !filters.type && !filters.direction){ // Doesn't have a filter when Fed OR branch -> No transfers
                                            return Promise.resolve([]);
                                        }
                                    }


                                    // return organizationContext.getOrganisationTransfers(
                                    //     undefined,
                                    //     {
                                    //         direction: filters.direction,
                                    //         request_state: [ "OPEN", "CLOSED" ].includes(filters.status) ? filters.status : "",
                                    //         transfer_approval_state: filters.status === "REJECTED" ? filters.status : "",
                                    //         transfer_type: filters.type,
                                    //         member_transfer_id: search || null,
                                    //     },
                                    // )
                                    return props.getData(filters, search)
                                        .then((transfers) => {
                                            setSearch(false);
                                            return transfers.map((transfer) => ({ ...transfer, actualStatus: getActualStatus(transfer) }))
                                        }).catch((error) => {
                                            if(AxiosIsCancelled(error.message)){
                                                return true;
                                            }
                                        });
                                default:
                                    break;
                            }
                        }}
                        // filterJSCallback={actionFilter}
                        columns={[
                            {
                                label: <Translate id='transfer.table.header.date' />,
                                key: 'date',
                                sortKey: 'created_at',
                                sortable: true,
                                mobile: true,
                                className: 'th-shrink',
                                dataClassName: 'td-shrink',
                            },
                            {
                                label: <Translate id='transfer.table.header.details' />,
                                key: 'details',
                                sortable: false,
                                mobile: true,
                            },
                            {
                                label: <Translate id='transfer.table.header.member' />,
                                key: 'member',
                                sortKey: 'member.last_name',
                                sortable: true,
                                mobile: true,
                            },
                            {
                                label: <Translate id='transfer.table.header.organization' />,
                                key: 'organization',
                                mobile: true,
                            },
                            {
                                label: <Translate id='transfer.table.header.status' />,
                                key: 'status',
                                sortKey: 'actualStatus',
                                sortable: true,
                                mobile: true,
                                className: 'th-shrink',
                                dataClassName: 'td-shrink',
                            },
                        ]}
                        renderRow={(columnKey, transfer) => {
                            switch (columnKey){
                                case 'date':
                                    return (
                                        <DateFormat format={'YYYY-MM-DD h:mm A'} value={transfer.created_at} />
                                    )
                                case 'member':
                                    return (
                                        <>
                                            <div className="text-dark font-medium">{transfer.member?.first_name} {transfer.member?.last_name}</div>
                                            <Link className="text-nowrap" to={`/members/profile/${transfer.member?.member_id}`}>#{transfer.member?.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></Link>
                                        </>
                                    )
                                case 'details':
                                    if(transfer.transfer_reason_type){
                                        return (
                                            <>
                                                {transfer.transfer_type && <div className='small'><Translate id={`transfer.type.${transfer.transfer_type}`} /></div>}
                                                <DisplayI18n
                                                    field='name'
                                                    defaultValue={transfer.transfer_reason_type.name}
                                                    i18n={transfer.transfer_reason_type.i18n}
                                                />
                                            </>
                                        )
                                    }
                                    return (
                                        <>
                                            {transfer.transfer_type && <Translate id={`transfer.type.${transfer.transfer_type}`} />}
                                        </>
                                    )

                                case 'organization':
                                    return (
                                        <>
                                            <div className="small"><Translate id='transfer.currentOrg' /></div>
                                            <div className="text-dark font-medium">
                                                <DisplayI18n
                                                    field='name'
                                                    defaultValue={transfer.organisation?.organisation_name}
                                                    i18n={transfer.organisation?.i18n}
                                                />
                                            </div>
                                            <div className="small"><Translate id='transfer.newOrg' /></div>
                                            {transfer.target_organisation ?
                                                <div className="text-dark font-medium">
                                                    <DisplayI18n
                                                        field='name'
                                                        defaultValue={transfer.target_organisation.organisation_name}
                                                        i18n={transfer.target_organisation.i18n}
                                                    />
                                                </div>
                                                :
                                                <div>-</div>
                                            }
                                        </>
                                    )
                                case 'status':
                                    if(transfer.expired_share)
                                        return <Badge color='light'><Translate id="transfer.table.header.status.EXPIRED_SHARE" /></Badge>
                                    return getTransferStatusBadge(transfer.transfer_status, transfer.previous_action_done)
                                default:
                                    break;
                            }
                        }}
                        onColumnClick={(e, data) => {
                            switch (e.button){
                                case 0: // Left mouse button
                                    panelProps.onSingleSelectChange(data);
                                    break;
                            }
                        }}
                        rowIsHighlighted={(transfer) => !!transfer.checked}
                        emptyLayout={<EmptyLayout />}
                    >
                        {(spordleTable) => (
                            <>
                                <Card body className='card-shadow'>
                                    <Row form className='align-items-center'>
                                        <Col md={(isFederation || !isUnsearchable) ? 4 : 3}>
                                            <FormGroup className='mb-md-0'>
                                                <SpordleSelect
                                                    name='filterStatus'
                                                    id='filterStatus'
                                                    clearable search={false}
                                                    placeholder="transfer.table.filters.status.placeholder"
                                                    onOptionSelected={([ value ]) => {
                                                        spordleTable.filterChange('status', value || '', !isUnsearchable);

                                                        if(value) setNeedFilter(false);
                                                    }}
                                                    loadingStatus='success'
                                                    defaultData={[
                                                        { value: 'OPEN', translateLabel: true, label: 'transfer.table.header.status.OPEN', selected: spordleTable.getFilters().status === 'OPEN' },
                                                        { value: 'CLOSED', translateLabel: true, label: 'transfer.table.header.status.CLOSED', selected: spordleTable.getFilters().status === 'CLOSED' },
                                                        { value: 'REJECTED', translateLabel: true, label: 'transfer.table.header.status.REJECTED', selected: spordleTable.getFilters().status === 'REJECTED' },
                                                    ]}
                                                    defaultValues={[ savedSearch?.status || '' ]}
                                                />
                                            </FormGroup>
                                        </Col>
                                        {!isFederation &&
                                            <Col md={isUnsearchable ? 3 : 4}>
                                                <FormGroup className='mb-md-0'>
                                                    <SpordleSelect
                                                        name='filterDirection'
                                                        id='filterDirection'
                                                        clearable search={false}
                                                        placeholder="transfer.table.filters.direction.placeholder"
                                                        onOptionSelected={([ value ]) => {
                                                            spordleTable.filterChange('direction', value || '', !isUnsearchable);

                                                            if(value) setNeedFilter(false);
                                                        }}
                                                        loadingStatus='success'
                                                        defaultData={[
                                                            { value: 'OUTGOING', translateLabel: true, label: "transfer.table.header.direction.OUTGOING", selected: spordleTable.getFilters().status === 'OUTGOING' },
                                                            { value: 'INCOMING', translateLabel: true, label: "transfer.table.header.direction.INCOMING", selected: spordleTable.getFilters().status === 'INCOMING' },
                                                        ]}
                                                        defaultValues={[ savedSearch?.direction || '' ]}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        }
                                        {/* <Col md={(isFederation || !isUnsearchable) ? 4 : 3}>
                                            <FormGroup className='mb-md-0'>
                                                <SpordleSelect
                                                    name='filterType'
                                                    id='filterType'
                                                    clearable search={false}
                                                    placeholder="transfer.table.filters.type.placeholder"
                                                    onOptionSelected={([ value ]) => {
                                                        spordleTable.filterChange('type', value || '', !isUnsearchable);
                                                        if(value) setNeedFilter(false);
                                                    }}
                                                    defaultData={[
                                                        { translateLabel: true, value: 'ASSOCIATION', label: 'transfer.type.ASSOCIATION', selected: spordleTable.getFilters().type === 'ASSOCIATION' },
                                                        { translateLabel: true, value: 'BRANCH', label: 'transfer.type.BRANCH', selected: spordleTable.getFilters().type === 'BRANCH' },
                                                        { translateLabel: true, value: 'USA', label: 'transfer.type.USA', selected: spordleTable.getFilters().type === 'USA' },
                                                        { translateLabel: true, value: 'INTERNATIONAL', label: 'transfer.type.INTERNATIONAL', selected: spordleTable.getFilters().type === 'INTERNATIONAL' },
                                                    ]}
                                                    loadingStatus='success'
                                                    defaultValues={[ savedSearch?.type || '' ]}
                                                />
                                            </FormGroup>
                                        </Col> */}
                                        {isUnsearchable &&
                                            <Col md={isFederation ? 4 : 3}>
                                                <SpordleTableContext.Consumer>
                                                    {(spordleTable) => (
                                                        <Button
                                                            id='transferSearch' color='primary' className='w-100'
                                                            // disabled={spordleTable.state.loadingState === 'loading'}
                                                            onClick={() => {
                                                                AxiosCancelTransferCalls();
                                                                if(!spordleTable.getFilters().status && !spordleTable.getFilters().type && !spordleTable.getFilters().direction){
                                                                    //Show error
                                                                    setNeedFilter(true)
                                                                }else{
                                                                    setNeedFilter(false)
                                                                    spordleTable.filterChange('somethingToTriggerSearch', '');
                                                                }
                                                            }}
                                                        >
                                                            <i className='fas fa-search mr-1' /><Translate id='misc.search' />
                                                        </Button>
                                                    )}
                                                </SpordleTableContext.Consumer>
                                            </Col>
                                        }
                                    </Row>
                                    <Collapse isOpen={needFilter}>
                                        <div className="text-danger small mt-1"><Translate id="transfer.table.filters.atLeastOne" /></div>
                                    </Collapse>
                                    <SavedSearchCue className="pt-2" clearSavedSearch={clearSavedSearch} savedSearch={savedSearch} />
                                </Card>
                                <Card body className='card-shadow'>
                                    <div className='mb-3'>
                                        <div className='align-items-center d-flex flex-wrap'>
                                            <SearchInput />
                                            <FormGroup className={!isUnsearchable ? 'ml-3 mb-0' : 'ml-3 mb-md-0'}>
                                                <CustomInput
                                                    type='checkbox' id='filterAction' key={spordleTable.getFilters().onlyShowActionRequired}
                                                    label={<Translate id='transfer.table.filters.action.label' />}
                                                    checked={spordleTable.getFilters().onlyShowActionRequired}
                                                    onChange={(e) => {
                                                        spordleTable.filterChange('onlyShowActionRequired', e.target.checked);
                                                    }}
                                                />
                                            </FormGroup>
                                            <div className='ml-auto mb-lg-0 mb-2'>
                                                <Refresh />
                                            </div>
                                        </div>
                                    </div>
                                    <SpordleTableView />
                                    <OpenSidePanelOnSearch />
                                </Card>
                            </>
                        )}
                    </SpordleTableProvider>
                )
            }}
        />
    );
};


export default MemberCTIRequests;
