import { useDisclosure } from "@mantine/hooks";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useContext, useState, useEffect } from "react";
import { Card } from "reactstrap";
import CardSectionTitle from "../../../../../../components/CardSectionTitle";
import CrossFade from "../../../../../../components/crossFade/CrossFade";
import { IdentityRolesContext } from "../../../../../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import ConfirmationMsgForm from "./ConfirmationMsgForm";
import ConfirmationMsgView from "./ConfirmationMsgView";
import I18nHelperContextProvider from '../../../../../../helpers/i18nHelper';
import { MessagesContext } from "../../../../../../contexts/MessagesContext";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { success } from "@spordle/toasts";
import SettingsError from "../SettingsError";

export const REGISTRATION_CONFIRM_SETTING = "registration_confirmation_screen_message";
export const CLINIC_CONFIRM_SETTING = "clinic_confirmation_screen_message";

const MessagesSettingsConfirmation = () => {
    const [ { messages, ...data }, setData ] = useState({
        isLoading: true,
        messages: {},
    });
    const [ isEditing, { open: startEditing, close: stopEditing } ] = useDisclosure(false);
    const { isGod } = useContext(IdentityRolesContext);
    const { settings } = useContext(OrganizationContext);
    const messageContext = useContext(MessagesContext);

    const clinicSetting = settings[CLINIC_CONFIRM_SETTING] || {};
    const registrationSetting = settings[REGISTRATION_CONFIRM_SETTING] || {};

    const canEditClinic = clinicSetting.can_manage == 1;
    const canEditRegistration = registrationSetting.can_manage == 1;

    const canEdit = canEditClinic || canEditRegistration;

    const registrationMsg = messages[registrationSetting.value];
    const clinicMsg = messages[clinicSetting.value];

    const getMessages = (msgIds) => {
        Promise.all(
            msgIds.map((id) => (
                id ? messageContext.getSettingMessage(id).then(([ msg ]) => msg) : Promise.resolve()
            )),
        )
            .then((messages) => {
                setData((prev) => {
                    const formattedMsg = messages.reduce((formatted, msg) => {
                        if(msg){
                            formatted[msg.message_id] = msg;
                        }

                        return formatted;
                    }, {});

                    return ({
                        ...prev,
                        isLoading: false,
                        messages: formattedMsg,
                    })
                })
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                    setData((prev) => ({
                        ...prev,
                        error: e,
                        isLoading: false,
                    }))
                }
            })
    }

    const handleOnSuccess = async(msgIds) => {
        await getMessages(msgIds);
        success();
        stopEditing();
    }

    useEffect(() => {
        getMessages([ clinicSetting.value, registrationSetting.value ]);
    }, []);

    return (
        <Card body className="card-shadow">
            <CardSectionTitle title="organization.settings.registrationSettings.confirmation.title" className='d-flex'>
                {!isEditing && !data.error &&
                    <div className={stringBuilder('ml-auto', { 'text-link': canEdit })}>
                        <button
                            disabled={(!canEdit && !isGod()) || data.isLoading}
                            onClick={startEditing}
                            className={stringBuilder("reset-btn align-self-end", { "text-purple": (isGod() && !canEdit) })}
                            type='button'
                        >
                            <Translate id='misc.edit' />
                        </button>
                    </div>
                }

            </CardSectionTitle>
            <I18nHelperContextProvider fields={[ 'body' ]}>
                {!data.error &&
                    <CrossFade unmountOnExit isVisible={isEditing}>
                        <ConfirmationMsgForm
                            stopEditing={stopEditing}
                            registrationMsg={registrationMsg}
                            clinicMsg={clinicMsg}
                            canEditClinic={canEditClinic || isGod()}
                            canEditRegistration={canEditRegistration || isGod()}
                            onSuccess={handleOnSuccess}
                        />
                    </CrossFade>
                }
                <CrossFade isVisible={!isEditing}>
                    {data.error ?
                        <SettingsError />
                        :
                        <ConfirmationMsgView
                            registrationMsg={registrationMsg}
                            clinicMsg={clinicMsg}
                            isLoading={data.isLoading}
                        />
                    }
                </CrossFade>
            </I18nHelperContextProvider>
        </Card>
    );
}

export default MessagesSettingsConfirmation;