import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import { fail, updateToast } from "@spordle/toasts";
import { AuthContext } from "../../../../../../contexts/contexts";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { ReportsContext } from "../../../../../../contexts/ReportsContext";
import { delay } from "../../../../../../helpers/helper";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { BC_ID } from "../../gameIncidentHelper";

const PdfExport = ({ gir }) => {
    const { getReports, createExportableReport } = useContext(ReportsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { account } = useContext(AuthContext);

    /**
     * Gets the reports and finds the report with the game incident code
     * @returns {Promise.<string>}
     * @throws {Error}
     */
    async function getGirReportId(){
        return getReports({ type: "TEAM" }).then((reports) => reports.find((r) => r.code === "game_incidents")?.report_id)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    /**
     * Function that handles the export to pdf logic
     * @async
     */
    async function handleOnExport(){
        try{
            const girReportId = await getGirReportId();
            await createExportableReport({
                organisation_id: BC_ID,
                language_code: getGenericLocale(),
                total_count: 1,
                requested_by: account.userName,
                report_id: girReportId,
                request_parameter: JSON.stringify({ period_id: gir.period?.period_id, game_incident_number: gir.game_incident_number, report_type: 'PDF' }),
            }, true).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        }catch(e){
            if(!AxiosIsCancelled(e.message)){
                const info = (() => {
                    switch (e.message){
                        case '3382':
                            return 'gameIncident.sidePanel.export.error.' + e.message;
                        default:
                            return false;
                    }
                })();

                // This avoids changes that occur too fast and make weird visual glitches
                await delay(250);
                updateToast({
                    id: "gameIncidentReportToast",
                    theme: "danger",
                    msg: 'misc.error',
                    info: info,
                    icon: false,
                    permanent: false,
                });

                console.error(e);
            }
        }

    }

    return (
        <SidePanel.MenuAction onClick={handleOnExport}>
            <Translate id='gameIncident.sidePanel.export.pdf' />
        </SidePanel.MenuAction>
    );
}

export default PdfExport;