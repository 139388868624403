import Translate from "@spordle/intl-elements";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { getRecursiveKeyVal } from "../../reportsEngineHelpers";

const SelectOptionRender = ({ dataKey, type, allOptionInfo, className }) => {
    // returns [ value, object entry containing final value ] so we can get i18ns
    const getKeyedValue = (key) => {
        let defaultValue;
        let newObj;

        key.split('.').forEach((keyPortion, index) => {
            if(index == key.split('.').length - 1)
                newObj = defaultValue;
            if(index == 0)
                defaultValue = allOptionInfo?.[keyPortion]
            else
                defaultValue = defaultValue?.[keyPortion]
        })
        return [ defaultValue, newObj ];
    }

    const getTranslation = (key) => {
        const dataKey = key.key || key;
        const append = typeof key === "object" && key.appendKey ? getRecursiveKeyVal(allOptionInfo, key.appendKey) : "";

        return <Translate id={`${dataKey}${append ? `.${append}` : ""}`} defaultMessage={append || dataKey} />;
    }

    const getDisplayI18n = (dataKey) => {
        const splitKey = dataKey.split('.');
        const field = splitKey[splitKey.length - 1];
        const keyedValue = getKeyedValue(dataKey);

        if(keyedValue[0] && keyedValue[1]){
            return <DisplayI18n field={field === "organisation_name" ? "name" : field} defaultValue={keyedValue[0]} i18n={keyedValue[1].i18n} />
        }

        return "";

    }

    const displayOption = (key) => {
        if(!dataKey.condition || (dataKey.condition && allOptionInfo.option?.[dataKey.condition.key] === dataKey.condition.value)){
            const myKey = key?.key || key;
            switch (key?.type || type){
                case "DisplayI18n":
                    return getDisplayI18n(myKey);
                case "Translate":
                    return getTranslation(key);
                default:
                    return getKeyedValue(myKey)[0]
            }
        }
        return null;
    }

    const getOptionInner = (key) => {
        const displayedOption = displayOption(key);

        if(displayedOption){
            return (
                <span className={key.className || ''} key={key.key}>
                    {key.prefix && <span dangerouslySetInnerHTML={{ __html: key.prefix }} />}
                    {displayedOption}
                    {key.suffix && <span dangerouslySetInnerHTML={{ __html: key.suffix }} />}
                </span>
            )
        }

        return "";

    }

    if(Array.isArray(dataKey)){
        return (
            <div className={className}>
                {dataKey.map(getOptionInner)}
            </div>
        )
    }
    return <div className={className}>{getOptionInner(dataKey)}</div>
}

export default SelectOptionRender