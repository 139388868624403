export default{
    "catalog.clinicItems.sidepanel.form.status"          : "Status",
    "catalog.clinicItems.sidepanel.form.status.active"   : "Active",
    "catalog.clinicItems.sidepanel.form.status.inactive" : "Inactive",
    "catalog.clinicItems.sidepanel.form.type"            : "Type",
    "catalog.clinicItems.sidepanel.form.name.required"   : "Name is required",
    "catalog.clinicItems.sidepanel.form.description"     : "Description",
    "catalog.clinicItems.sidepanel.form.price"           : "Price",
    "catalog.clinicItems.sidepanel.form.tax"             : "tax",
    "catalog.clinicItems.sidepanel.form.SKU"             : "SKU",

    "catalog.clinicItems.sidepanel.delete.message" : "Are you sure you want to delete",
}