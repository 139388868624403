export default{
    "registration.settings.class.sidePanelRegistrationClasses.title" : "Informations de Classe",

    "registration.settings.class.sidePanelRegistrationClasses.status"          : "Statut",
    "registration.settings.class.sidePanelRegistrationClasses.status.active"   : "Actif",
    "registration.settings.class.sidePanelRegistrationClasses.status.inactive" : "Inactif",
    "registration.settings.class.sidePanelRegistrationClasses.status.required" : "Le Statut est requis",

    "registration.settings.class.sidePanelRegistrationClasses.update.success.text" : "Classe mise à jour",
    "registration.settings.class.sidePanelRegistrationClasses.update.fail.text"    : "Une erreur est survenue lors de la mise à jour de la Classe, veuillez rafraichir la page et réessayer",

    // actions
    "registration.settings.class.sidePanelRegistrationClasses.actions.remove"         : "Retirer",
    "registration.settings.class.sidePanelRegistrationClasses.actions.remove.topText" : "Vous êtes sur le point de retirer cette Classe de votre Organisation",

    // tabs
    "registration.settings.class.sidePanelRegistrationClasses.tabs.details" : "Détails",
}