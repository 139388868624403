import { useContext, useState } from "react";
import {
    Card,
    Row,
    Col,
    Button
} from 'reactstrap';


import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from '@spordle/datatables';
import Translate from "@spordle/intl-elements";

import AddOrganizationVenuesModal from './modals/AddOrganizationVenuesModal';
import { OrganizationContext } from "../../../../contexts/OrganizationContext";


const OrganizationVenues = () => {
    const organizationContext = useContext(OrganizationContext);
    const [ addOrganizationVenuesModalOpen, setAddOrganizationVenuesModalOpen ] = useState(false);

    const toggleAddOrganizationVenuesModal = () => setAddOrganizationVenuesModalOpen((prev) => !prev);

    const formatVenues = () => {
        if(!organizationContext.venues || !organizationContext.locations)
            return []
        return organizationContext.locations.reduce((newLocations, location) => {

            // Verify if we have already looped this location
            if(!newLocations.some((l) => l.location_id_public === location.location_id_public)){
                const newLoc = { ...location }
                // Fill in venue data
                newLoc.venue = organizationContext.venues.find((venue) => location.venue_id_public === venue.venue_id_public)

                // Loop location playing surfaces
                newLoc.playingSurfaces = organizationContext.locations.filter((l) => l.location_id_public === location.location_id_public)?.map((l) => ({ ...l, venue: newLoc.venue }))

                newLocations.pushArray(newLoc.playingSurfaces)
            }

            return newLocations
        }, [])
    }

    const getAddress = (row) => {
        const venue = row.venue;
        if(venue){
            const formattedAddress = `${venue.address1}, ${venue.city_name}, ${venue.postal_code}, ${venue.country_code}`;
            const url = venue.map_url || `https://maps.google.com/?q=${formattedAddress}`;
            return (
                <address className="d-flex">
                    {/* <i className="mdi mdi-map-marker mr-1" /> */}
                    <a className="small" href={url} target='_blank' rel='noopener noreferrer'>
                        <span>{venue.address1 && <>{venue.address1}, </>}{venue.city_name}</span>
                        <span className="d-block">{venue.postal_code}, {venue.country_code}<i className="mdi mdi-launch ml-1" /></span>
                    </a>
                </address>
            )
        }
        return null;

    }

    return (
        <>
            <AddOrganizationVenuesModal isOpen={addOrganizationVenuesModalOpen} toggle={toggleAddOrganizationVenuesModal} />
            <Card body className="card-shadow">
                {/* <SpordlePanelTable
                    allowOutsideClick
                    sidePanel={(props) => <SidePanelOrganizationVenues {...props} />}
                    dataIndex='playing_surface_id_public'
                    table={(panelProps) => {
                        return ( */}
                <SpordleTableProvider
                    id='OrganizationVenuesTable'
                    tableHover bordered striped

                    // tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                    dataIndex='playing_surface_id_public'
                    defaultSorting='+venue'
                    emptyLayout={<div className="h6 font-bold"><Translate id='organization.profile.venues.organizationVenues.table.noresults' /></div>}
                    columns={[
                        {
                            label: <Translate id="organization.profile.venues.organizationVenues.table.location" />,
                            key: "location_name",
                            mobile: true,
                            sortable: true,
                        },
                        {
                            label: <Translate id="organization.profile.venues.organizationVenues.table.playingSurface" />,
                            key: "playing_surface_name",
                            mobile: true,
                            sortable: true,
                        },
                        {
                            label: <Translate id="organization.profile.venues.organizationVenues.table.details" />,
                            key: "details",
                            mobile: true,
                            sortable: true,
                        },
                    ]}
                    renderRow={(columnKey, data) => {
                        switch (columnKey){
                            case 'location_name':
                                return (
                                    <>
                                        <div className="font-medium">{data.location_name}</div>
                                        <div><Translate id="organization.profile.venues.organizationVenues.table.venue" />: {data.venue?.name || "-"}</div>
                                        {getAddress(data)}
                                    </>
                                )
                            case 'details':
                                return (
                                    <>
                                        <div><Translate id="organization.profile.venues.organizationVenues.table.activity" />: {data.playing_surface_activity || "-"}</div>
                                        <div><Translate id="organization.profile.venues.organizationVenues.table.size" />: {data.playing_surface_size_type || "-"}</div>
                                    </>
                                )
                            default:
                                break;
                        }
                    }}
                    desktopWhen='md'
                    pagination={10}
                    // onColumnClick={(e, location) => {
                    //     switch (e.button){
                    //         case 0: // Left mouse button
                    //             panelProps.onSingleSelectChange(location);
                    //             break;
                    //     }
                    // }}
                    loadData={async(from, filterData, spordleTable) => {
                        switch (from){
                            case 'REFRESH':
                                spordleTable.setLoading(true);
                                await organizationContext.setCurrentOrg(organizationContext.organisation_id, { with_venues: 1 })
                            case 'CDM':
                                return Promise.resolve(formatVenues())
                            default:
                                break;
                        }
                    }}
                >
                    <TopSection toggleAddOrganizationVenuesModal={toggleAddOrganizationVenuesModal} />
                    <SpordleTableView />
                </SpordleTableProvider>
                {/* )
                    }}
                /> */}
            </Card>
        </>
    );
}

const TopSection = ({ toggleAddOrganizationVenuesModal }) => {
    return (
        <Row className='mb-3'>
            <Col md='6'>
                <SearchInput />
            </Col>
            <Col md='6'>
                <div className="d-flex justify-content-end align-items-center">
                    <Refresh />
                    <Button disabled color='primary' className='ml-2' onClick={toggleAddOrganizationVenuesModal}><i className='mdi mdi-plus' /> <Translate id='misc.add' /></Button>
                </div>
            </Col>
        </Row>
    );
}

export default OrganizationVenues;