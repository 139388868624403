export default{
    "organization.settings.organizationPositions.table.empty"                : "Aucune position",
    "organization.settings.organizationPositions.table.column.status"        : "Statut",
    "organization.settings.organizationPositions.table.column.managed"       : "Gérée",
    "organization.settings.organizationPositions.add.title"                  : "Ajouter une position",
    "organization.settings.organizationPositions.add.status"                 : "Statut",
    "organization.settings.organizationPositions.add.status.active"          : "actif",
    "organization.settings.organizationPositions.add.status.inactive"        : "inactif",
    "organization.settings.organizationPositions.add.name.required"          : "Le nom est obligatoire",
    "organization.settings.organizationPositions.add.status.required"        : "Le statut est obligatoire",
    "organization.settings.organizationPositions.table.column.abbreviation"  : "Abréviation",
    "organization.settings.organizationPositions.table.column.positionGroup" : "Groupe de position",
    "organization.settings.organizationPositions.add.abbreviation"           : "Abréviation",
    "organization.settings.organizationPositions.add.abbreviation.max"       : "Vous dépassez la limite maximale de caractères",
    "organization.settings.organizationPositions.add.managePosition"         : "Gérer cette position pour ma branche",
    "organization.settings.organizationPositions.add.group.required"         : "Le groupe de position est requis.",

}