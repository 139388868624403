import React from "react";
import {
    Button,
    Card
} from "reactstrap";
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';

import SidePanelOrganizationTeamTravelTypes from './SidePanelOrganizationTeamTravelTypes';
import Translate from "@spordle/intl-elements";
import SpordlePanelTable from "../../../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../../../components/table/EmptyLayout";
import TeamTravelTypesAddModal from "./TeamTravelTypesAddModal";
import CardSectionTitle from "../../../../../components/CardSectionTitle";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { TeamsContext } from '../../../../../contexts/TeamsContext';
import I18nHelperContextProvider, { I18nHelperContext } from '../../../../../helpers/i18nHelper';
import withContexts from "../../../../../helpers/withContexts";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import { I18nContext } from "../../../../../contexts/I18nContext";

class OrganizationTeamTravelTypes extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            addTravelTypeModalOpen: false,
            isFederation: this.props.OrganizationContext.organisation_id === this.props.IdentityRolesContext.federation.organisation_id,
        }
    }

    toggleAddTravelType = () => {
        this.setState((prevState) => ({ addTravelTypeModalOpen: !prevState.addTravelTypeModalOpen }));
    }

    render(){
        return (
            <I18nHelperContextProvider fields={[ 'name' ]}>
                <I18nHelperContext.Consumer>
                    {(i18nHelper) => (
                        <div>
                            <SpordlePanelTable
                                allowOutsideClick
                                sidePanel={(props) => <SidePanelOrganizationTeamTravelTypes {...props} tableRef={this.travelTypesTableRef} isFederation={this.state.isFederation} i18n={i18nHelper} />}
                                dataIndex='travel_type_id'
                                table={(panelProps) => {
                                    return (
                                        <Card body className='card-shadow'>
                                            <CardSectionTitle title='settings.tabs.teamTravelTypes' />
                                            <SpordleTableProvider
                                                id='OrganizationTeamTravelTypesTable'
                                                tableHover clickable striped
                                                bordered
                                                ref={(r) => { this.travelTypesTableRef = r; panelProps.spordleTableRef(r); }}
                                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                                dataIndex='travel_type_id'
                                                searchKeys={[
                                                    `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                    'name',
                                                ]}
                                                columns={[
                                                    ...i18nHelper.getTableColumns('name'),
                                                    {
                                                        label: <Translate id='organization.settings.organizationTeamTravelTypes.table.active' />,
                                                        key: "active",
                                                        mobile: true,
                                                        sortable: true,
                                                    },
                                                ]}
                                                onColumnClick={(e, travelType) => {
                                                    switch (e.button){
                                                        case 0: // Left mouse button
                                                            panelProps.onSingleSelectChange(travelType);
                                                            break;
                                                    }
                                                }}
                                                desktopWhen='md'
                                                emptyLayout={<EmptyLayout />}
                                                renderRow={(columnKey, travelType, spordleTable) => {
                                                    switch (columnKey){
                                                        case 'active':
                                                            return (
                                                                <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                                    {travelType.active == '1' ?
                                                                        <i className="text-primary mdi mdi-check" />
                                                                        :
                                                                        <i className="text-danger mdi mdi-close" />
                                                                    }
                                                                </div>
                                                            )
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                rowIsHighlighted={(travelType) => !!travelType.checked}
                                                loadData={(from, data, spordleTable) => {
                                                    switch (from){
                                                        case 'REFRESH':
                                                            spordleTable.setLoading()
                                                        case 'CDM':
                                                            return this.props.TeamsContext.getTravelTypes({ organisation_id: this.props.OrganizationContext.organisation_id });
                                                        default:
                                                            break;
                                                    }
                                                }}
                                            >
                                                <TopSection toggleAddTravelType={this.toggleAddTravelType} panelProps={panelProps} addTravelTypeModalOpen={this.state.addTravelTypeModalOpen} />
                                                <SpordleTableView />
                                            </SpordleTableProvider>
                                        </Card>
                                    )
                                }}
                            />
                        </div>
                    )}
                </I18nHelperContext.Consumer>
            </I18nHelperContextProvider>
        )
    }
}

const TopSection = (props) => {
    return (
        <div className="mb-2">
            <div className='d-flex justify-content-between'>
                <SpordleTableProvider.SearchInput />
                <div className='d-flex ml-auto text-right'>
                    <SpordleTableProvider.Refresh />
                    <Button color='primary' className='ml-2' onClick={props.toggleAddTravelType}><i className='mdi mdi-plus' /> <Translate id='misc.add' /></Button>
                    <TeamTravelTypesAddModal addTravelTypeModalOpen={props.addTravelTypeModalOpen} toggleAddTravelType={props.toggleAddTravelType} />
                </div>
            </div>
        </div>
    )
}

export default withContexts(TeamsContext, OrganizationContext, IdentityRolesContext, I18nContext)(OrganizationTeamTravelTypes);