const MobileTask = ({ color, ...props }) => {
    return (
        <svg id="MobileTask" data-name="MobileTask" xmlns="http://www.w3.org/2000/svg" viewBox={props.viewBox ?? "0 0 40 40"} width={props.width ?? "36"} height={props.height ?? "28"} fill="currentColor">
            <g id="QHLOmq.tif">
                <path d="M32.36,31.5H18a1.1,1.1,0,0,1-.68-1.19c0-.44,0-.88,0-1.32a.88.88,0,0,1,1-1H32a.84.84,0,0,1,.94.91c0,.45,0,.9,0,1.35A1.15,1.15,0,0,1,32.36,31.5Z" />
                <path d="M13.24,31.5H11a1.12,1.12,0,0,1-.67-1.19c0-.45,0-.9,0-1.36a.87.87,0,0,1,1-.93h1.61a.84.84,0,0,1,.9.9c0,.45,0,.9,0,1.35A1.16,1.16,0,0,1,13.24,31.5Z" />
                <path d="M33,22.59c0,.77-.32,1.08-1.09,1.08H18.44c-.79,0-1.11-.32-1.11-1.1,0-.44,0-.87,0-1.31,0-.75.31-1.07,1.05-1.07H31.79c1,0,1.2.26,1.2,1.22Z" />
                <path d="M33,14.86a.88.88,0,0,1-1,1H20.9a.87.87,0,0,1-.95-1c0-.5,0-1,0-1.49a.86.86,0,0,1,1-1h11c.82,0,1.1.28,1.11,1.09C33,13.93,33,14.39,33,14.86Z" />
                <path d="M18.74,10.7l-3.52,3.51L12.4,17a1.3,1.3,0,0,1-2.13,0L7.39,14.16c-.52-.52-.52-.78,0-1.31L8,12.22a.66.66,0,0,1,1.07,0c.38.36.75.73,1.11,1.1l1.11,1.14,5.23-5.24c1-1,1-1,1.94,0l.24.24A.75.75,0,0,1,18.74,10.7Z" />
                <path d="M13.84,22.75a.85.85,0,0,1-.85.91,16.83,16.83,0,0,1-1.74,0,.86.86,0,0,1-.88-.9c0-.55,0-1.1,0-1.66a.85.85,0,0,1,.91-.9q.83,0,1.65,0a.84.84,0,0,1,.91.93c0,.19,0,.38,0,.57s0,.16,0,.24,0,.15,0,.22S13.85,22.55,13.84,22.75Z" />
            </g>
        </svg>
    )
}
export default MobileTask