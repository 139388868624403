import Translate from '@spordle/intl-elements'
import MemberRegistrationCard from '../../../../../components/memberRegistration/MemberRegistrationCard'
import QuickViewActionButton from '../../../../../components/quickView/QuickViewActionButton'
import QuickViewButton from '../../../../../components/quickView/QuickViewButton'
import SidePanel from '../../../../../components/sidePanel/SidePanel'
import OnlineStoreMultiConfirmRegistration from './OnlineStoreMultiConfirmRegistration'

const OnlineStoreRegistrationMultiSidePanel = ({ selectedRows, onMultiSelectChange, forceCloseSidePanel, tableRef, getRegistrationFeesAndOtherFees }) => {
    return (
        <>
            <SidePanel.Header>
                <div className='d-flex mb-2 align-items-center'>
                    <SidePanel.ToggleButton />
                    <SidePanel.ActionsMenu action='EDIT' componentCode='page' componentPermissionCode='online_store_manage'>
                        <OnlineStoreMultiConfirmRegistration
                            registrations={selectedRows}
                            refreshTable={() => {
                                tableRef.refreshTable();
                                forceCloseSidePanel();
                                getRegistrationFeesAndOtherFees();
                            }}
                        />
                        <QuickViewActionButton member={selectedRows.map((reg) => (reg.memberRegistration.member))} />
                    </SidePanel.ActionsMenu>
                </div>
                <SidePanel.Title className='d-flex'>
                    <QuickViewButton member={selectedRows.map((reg) => (reg.memberRegistration.member))}>{selectedRows.length} <Translate id='memberProfile.registration.sidepanel.selectedAmount' /></QuickViewButton>
                </SidePanel.Title>
            </SidePanel.Header>
            <SidePanel.Body>
                {selectedRows.map((registration) => (
                    <MemberRegistrationCard key={registration.key} registration={registration} onRemove={() => onMultiSelectChange(registration)} />
                ))}
            </SidePanel.Body>
        </>
    )
}

export default OnlineStoreRegistrationMultiSidePanel