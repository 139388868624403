import Translate from "@spordle/intl-elements";
import { useState } from "react";
import { Button, ModalBody, ModalFooter } from "reactstrap";
import OverlayLoader from "../loading/OverlayLoader";
import PropTypes from 'prop-types';
import { useMountedState } from "../../helpers/mountedState";
import AnalyticsModal from "../../analytics/AnalyticsModal";
import { useIntl } from "react-intl";

/**
 * @param {Object} props
 * @param {string} [props.className]
 * @param {string} [props.color=danger]
 * @param {string} [props.icon=mdi-alert-outline]
 * @param {function(function):React.ReactElement} [props.toggler]
 * @param {boolean} [props.disableConfirm=false]
 * @param {string|JSX.Element} [props.modalTitle]
 * @param {string|JSX.Element} [props.modalMsg]
 * @param {boolean} [props.translateModalTitle=false]
 * @param {boolean} [props.translateModalMsg=false]
 * @param {() => Promise|void} [props.onConfirm]
 * @param {Promise|function} [props.onCancel]
 * @param {JSX.Element} [props.modalContent]
 * @returns {JSX.Element}
 * @example
 * <ConfirmModal
 *      toggler={(fn) => (
 *          <Button onClick={fn}>Custom Button</Button>
 *      )}
 *      onConfirm={() => handleDelete()}
 * >
 *      This is custom modal content
 * </ConfirmModal>
 * @example
 * <ConfirmModal
 *      translateModalMsg
 *      translateModalTitle
 *      modalMsg='misc.youAreAboutToDelete'
 *      modalTitle='misc.title'
 *      onConfirm={() => handleDelete()}
 * />
 * @example
 * <ConfirmModal
 *      modalMsg={
 *          <div>
 *              <div>Msg</div>
 *              <div>super message</div>
 *          </div>
 *      }
 *      modalTitle='misc.title'
 *      translateModalTitle
 *      onConfirm={() => handleDelete()}
 *      onCancel={() => handleCancel()}
 * />
 */
const ConfirmModal = ({ toggler, className, godAccess, ...props }) => {
    const [ isOpen, setIsOpen ] = useState(false);
    const closeModal = () => setIsOpen(false);
    const openModal = () => setIsOpen(true);
    const isMounted = useMountedState();

    return (
        <>
            {toggler ?
                toggler(openModal)
                :
                <Button color={godAccess ? 'purple' : "primary"} onClick={openModal}><Translate id="misc.delete" /></Button>
            }
            {isMounted &&
                <AnalyticsModal analyticsName='ConfirmModal' isOpen={isOpen}>
                    <ConfirmModalInner toggle={closeModal} {...props} />
                </AnalyticsModal>
            }
        </>
    )
}

export const ConfirmModalInner = ({
    children,
    color,
    toggle,
    onCancel,
    onConfirm,
    modalTitle,
    disableConfirm,
    translateModalTitle,
    translateModalMsg,
    translateActionButtonText,
    modalMsg,
    modalContent,
    actionButtonText,
    icon,
}) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const isMounted = useMountedState();
    const { formatMessage } = useIntl();

    const handleOnCancel = async() => {
        if(onCancel){
            await onCancel();
        }
        isMounted && toggle(); // Avoids error Msg if SidePanel is closed too fast
    }

    const handleOnConfirm = async() => {
        setIsLoading(true);
        if(onConfirm){
            await onConfirm();
        }
        isMounted && toggle(); // Avoids error Msg if SidePanel is closed too fast
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalBody className="text-center position-relative">
                <button onClick={toggle} type="button" aria-label={formatMessage({ id: 'misc.close' })} className="close position-absolute top-0 right-0 p-3">
                    <span aria-hidden>×</span>
                </button>
                <i style={{ fontSize: 75 }} className={`mdi ${icon} text-${color}`} />
                {!!modalMsg && <div className="mb-2">{translateModalMsg ? <Translate id={modalMsg} /> : modalMsg}</div>}
                {!!modalTitle && <div className="font-bold h4">{translateModalTitle ? <Translate id={modalTitle} /> : modalTitle}</div>}
                {modalContent || children}
            </ModalBody>
            <ModalFooter className="border-top-0 justify-content-center">
                <Button disabled={disableConfirm || isLoading} color={color} onClick={handleOnConfirm}>
                    {actionButtonText ?
                        translateActionButtonText ? <Translate id={actionButtonText} /> : actionButtonText
                        :
                        <Translate id="misc.delete" />
                    }
                </Button>
                <Button color="primary" outline onClick={handleOnCancel}>
                    <Translate id="misc.cancel" />
                </Button>
            </ModalFooter>
        </OverlayLoader>
    )
}

export default ConfirmModal;

ConfirmModal.propTypes = {
    toggler: PropTypes.func,
    color: PropTypes.string,
    icon: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    disableConfirm: PropTypes.bool,
    translateModalTitle: PropTypes.bool,
    translateModalMsg: PropTypes.bool,
    translateActionButtonText: PropTypes.bool,
    modalTitle: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
    modalMsg: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
    actionButtonText: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
}

ConfirmModal.defaultProps = {
    color: "danger",
    icon: "mdi-alert-outline",
    disableConfirm: false,
    translateModalTitle: false,
    translateModalMsg: false,
    translateActionButtonText: false,
}