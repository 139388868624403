import { useMemo, useContext } from "react";
import { useTeamCategoryQualificationRulesSWR } from "../settings/teamSettingsConfigurationHelper";
import { DisplayCategory } from "../../../../teams/TeamHelpers";
import { Button, Card, CardHeader, ListGroup, ListGroupItem } from "reactstrap";
import { DisplayI18n, displayI18n } from "../../../../../helpers/i18nHelper";
import ClinicCategoryBuilder from "../../../../clinics/ClinicCategoryBuilder";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { clinicCategoryBuilder } from "../../../../clinics/clinicHelpers";
import { Skeleton, Tooltip } from "@mantine/core";
import Translate from "@spordle/intl-elements";
import { useIntl } from "react-intl";
import CanDoAction from "../../../../../components/permissions/CanDoAction";

const ConfigurationQualificationView = ({ startEditing }) => {
    const { data: rules, isValidating } = useTeamCategoryQualificationRulesSWR();
    const { getGenericLocale } = useContext(I18nContext);
    const { formatMessage } = useIntl();
    const isLoading = (!!rules && isValidating);
    const formattedRules = useMemo(() => {
        const sortedRules = (rules || []).sort((a, b) => {
            const displayI18nName = (res) => displayI18n("name", res?.i18n, res?.name, getGenericLocale()) || "";
            const getI18nName = (res) => {
                const cat = displayI18nName(res.team_category);
                const qual = clinicCategoryBuilder(res.qualification, getGenericLocale());
                const posName = formatMessage({ id: res.position ? "members.profile.teams.position" : "reports.column.header.position_group_name" });
                const pos = displayI18nName(res.position || res.position_group);

                return cat + qual + posName + pos;
            };

            return getI18nName(a).localeCompare(getI18nName(b));
        })

        return Object.entries((sortedRules).reduce((formatted, rule) => {
            const teamCatId = rule.team_category?.team_category_id;
            const qualId = rule.qualification?.qualification_id;

            if(teamCatId && qualId){
                if(formatted[teamCatId]){
                    if(formatted[teamCatId].rules[qualId]){
                        formatted[teamCatId].rules[qualId].push(rule);
                    }else{
                        formatted[teamCatId].rules[qualId] = [ rule ];
                    }
                }else{
                    formatted[teamCatId] = { category: rule.team_category, rules: { [qualId]: [ rule ] } };
                }
            }

            return formatted;
        }, {}));
    }, [ rules?.length, getGenericLocale() ]);

    return (
        <>
            {isLoading ?
                <>
                    <Card className="border mb-2">
                        <CardHeader className="h5 font-weight-bold"><Translate id="misc.loading" /></CardHeader>
                        <Skeleton height="75px" className="m-1" />
                    </Card>
                    <Card className="border mb-2">
                        <CardHeader className="h5 font-weight-bold"><Translate id="misc.loading" /></CardHeader>
                        <Skeleton height="75px" className="m-1" />
                        <Skeleton height="75px" className="mx-1 mb-1" />
                    </Card>
                </>
                :
                formattedRules.length === 0 ?
                    <div className="bg-light-inverse text-center text-muted rounded border p-3">
                        <div className="h4 font-medium text-body mb-1"><Translate id="settings.tabs.teams.configuration.qualifications.empty" /></div>
                        <div className="mb-3"><Translate id="settings.tabs.teams.configuration.qualifications.empty.txt" /></div>
                        <CanDoAction action="EDIT" componentCode="teams" componentPermissionCode="team_settings" className="ml-auto">
                            <Button size="sm" type="button" color="primary" onClick={startEditing} className="mdi mdi-plus with-icon"><Translate id="misc.add" /></Button>
                        </CanDoAction>
                    </div>
                    :
                    formattedRules.map(([ categoryId, qualification ]) => (
                        <Card className="border mb-2" key={categoryId}>
                            <CardHeader className="h5 font-weight-bold">
                                <DisplayCategory category={qualification.category} />
                                {(qualification.category?.active != 1) &&
                                    <Tooltip label={<Translate id="misc.inactive" />}>
                                        <i className="ml-1 mdi mdi-alert-outline text-warning" />
                                    </Tooltip>
                                }
                            </CardHeader>
                            <ListGroup flush style={(qualification.category?.active != 1) ? { opacity: 0.5 } : void 0}>
                                {Object.entries(qualification.rules)
                                    .map(([ qualificationId, rules ]) => (
                                        <ListGroupItem key={qualificationId}>
                                            <div className="font-medium text-dark">
                                                <ClinicCategoryBuilder qualification={rules[0].qualification} />
                                                {rules[0].qualification.active != 1 &&
                                                    <Tooltip label={<Translate id="misc.inactive" />}>
                                                        <i className="ml-1 mdi mdi-alert-outline text-warning" />
                                                    </Tooltip>
                                                }
                                            </div>
                                            <ul className="pl-4 mb-0">
                                                {rules
                                                    .map((rule) => (
                                                        <li key={rule.team_category_qualification_rule_id}>
                                                            {rule.position_group ?
                                                                <>
                                                                    <Translate id="reports.column.header.position_group_name" />{": "}
                                                                    <DisplayI18n
                                                                        field="name"
                                                                        i18n={rule.position_group?.i18n}
                                                                        defaultValue={rule.position_group?.name}
                                                                    />
                                                                </>
                                                                :
                                                                <>
                                                                    <Translate id="members.profile.teams.position" />{": "}
                                                                    <DisplayI18n
                                                                        field="name"
                                                                        i18n={rule.position?.i18n}
                                                                        defaultValue={rule.position?.name}
                                                                    />
                                                                </>
                                                            }
                                                            <span className="mx-1">&bull;</span>
                                                            <Translate id="misc.minimum" values={{ value: rule.applies_to }} />
                                                        </li>
                                                    ))}
                                            </ul>
                                        </ListGroupItem>
                                    ))}
                            </ListGroup>
                        </Card>
                    ))}
        </>
    );
}

export default ConfigurationQualificationView;