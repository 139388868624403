import MemberAttachmentApprobationList from '../../views/tasks/memberAttachmentApprobation/MemberAttachmentApprobationList';
import MemberChangeRequests from '../../views/tasks/memberChangeRequests/MemberChangeRequests';
import MemberCTIRequests from '../../views/tasks/memberCTIRequests/MemberCTIRequests';
import UnconfirmedOrganizationList from '../../views/tasks/unconfirmedOrganization/UnconfirmedOrganizationList';
import MemberPermanentReleaseRequests from '../../views/tasks/memberPermanentReleaseRequests/MemberPermanentReleaseRequests';
import ClassificationChangeRequests from '../../views/tasks/classificationChangeRequests/ClassificationChangeRequests';
import MemberTypeValidation from '../../views/tasks/memberTypeValidation/MemberTypeValidation';
import MemberTypeRemoval from '../../views/tasks/memberTypeRemoval/MemberTypeRemoval';

/**
 * @typedef {object} TabRoutes
 * @property {React.ReactNode} [name]
 * @property {boolean} [redirect]
 */

/** @type {Array.<import('react-router-dom').RouteProps & TabRoutes>} */
const tasksManageRequestsTabsRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    // Requests
    {
        path: '/member-change-requests',
        component: MemberChangeRequests,
        name: 'task.mandatoryChangesRequests.title',
        permissions: { code: 'members', permissionCode: 'approve_request_mandatory_fields' },
    },
    {
        path: '/classification-change-requests',
        component: ClassificationChangeRequests,
        name: 'task.classificationChangesRequests.title',
        permissions: { code: 'members', permissionCode: 'manage_classification_request' },
    },
    {
        path: '/member-cti-requests',
        component: MemberCTIRequests,
        name: 'task.ctiRequests.title',
        permissions: { code: 'members', permissionCode: 'manage_international_transfer_dates' },
    },
    {
        path: '/unconfirmed-organizations',
        component: UnconfirmedOrganizationList,
        name: 'task.unsactioned.club.title',
        permissions: { code: 'organization', permissionCode: 'review_organisation_status' },
    },
    {
        path: '/member-attachment-approval',
        component: MemberAttachmentApprobationList,
        name: 'task.member.attachment.approval.title',
        permissions: { code: 'members', permissionCode: 'system_document_type_need_approbation' },
    },
    {
        path: '/member-permanent-release-requests',
        component: MemberPermanentReleaseRequests,
        name: 'task.permanentReleaseRequests.title',
        permissions: { code: 'members', permissionCode: 'approve_permanent_release' },
    },
    {
        path: '/member-type-approval',
        component: MemberTypeValidation,
        name: 'tasks.memberTypeValidation.title',
        permissions: { code: 'members', permissionCode: 'manage_member_type_approbation' },
        showWhenSetting: 'manage_member_type_approbation',
    },
    {
        path: '/member-type-removal',
        component: MemberTypeRemoval,
        name: 'tasks.memberTypeRemoval.title',
        showWhenSetting: 'enable_member_type_removal_request',
    },
];
export default tasksManageRequestsTabsRoutes;