import { SpordleTableContext } from '@spordle/datatables';
import { FormikDateTime, FormikError, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form as FormikForm, Formik } from 'formik';
import moment from 'moment';
import { useContext, useRef, useState } from 'react';
import { Button, Col, Collapse, FormGroup,
    Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { array, mixed, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import Required from '../../../../../components/formik/Required';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import { success } from '@spordle/toasts';
import FileUpload from '../../../../../components/uploader/fileUpload/FileUpload';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import { CrcContext } from '../../../../../contexts/CrcContext';
import { RolesContext } from '../../../../../contexts/RolesContext';
import CustomAlert from '../../../../../components/CustomAlert';
import CanDoAction from '../../../../../components/permissions/CanDoAction';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';

const CriminalCriminalCheckModal = ({ allStatus, toggle, backgroundChecks }) => {
    const memberContext = useContext(MembersContext);
    const { getGenericLocale } = useContext(I18nContext);
    const spordleTable = useContext(SpordleTableContext);
    const organizationContext = useContext(OrganizationContext);
    const crcContext = useContext(CrcContext);
    const { isGod } = useContext(IdentityRolesContext);
    const { canDoAction } = useContext(RolesContext);

    const [ filesAreRequired, setFilesAreRequired ] = useState(false);
    const [ memberTypes, setMemberTypes ] = useState(null);
    const [ backgroundCheckTypes, setBackgroundCheckTypes ] = useState(null);

    /**  @type {React.MutableRefObject<import('@spordle/spordle-select/dist/npm/types/components/SpordleSelect').default>} */
    const backgroundCheckTypeSelectRef = useRef();
    /**  @type {React.MutableRefObject<import('@spordle/spordle-select/dist/npm/types/components/SpordleSelect').default>} */
    const memberTypeSelectRef = useRef();

    const handleTypesOnChange = (memberTypeId, backgroundCheckTypeId) => {
        const bgc = backgroundChecks.find((_bgc) => _bgc.member_type.member_type_id === memberTypeId && _bgc.background_check_type.background_check_type_id === backgroundCheckTypeId);
        setFilesAreRequired(bgc?.need_document == 1 || false);
    }

    return (
        <Formik
            initialValues={{
                background_check_type_id: '',
                issue_date: '',
                criminal_record_check_status_id: '',
                files: [],
                member_type_id: '',
            }}
            validationSchema={object().shape({
                background_check_type_id: string().required(<Translate id='members.profile.crc.form.validation.type.required' />),
                member_type_id: string().required(<Translate id='members.profile.crc.form.validation.memberType.required' />),
                issue_date: mixed()
                    .required(<Translate id='members.profile.crc.form.validation.date.required' />)
                    .test({
                        name: 'dateIsValid',
                        message: <Translate id='form.validation.date.format' />,
                        test: moment.isMoment,
                    }),
                criminal_record_check_status_id: string().required(<Translate id='members.profile.crc.form.validation.status.required' />),
                files: array().of(mixed()).test({
                    name: 'doesNotMatter',
                    message: <Translate id='members.profile.crc.form.validation.file.required' />,
                    test: function(val){
                        if(filesAreRequired){
                            return Array.isArray(val) && val.length > 0;
                        }

                        return true;
                    },
                }),
            })}
            onSubmit={(values, { setStatus }) => {
                const newVal = { ...values };

                newVal.issue_date = values.issue_date.format('YYYY-MM-DD');// issue_date will already be a moment

                return memberContext.createMemberCrc(memberContext.currentMember.member_id, organizationContext.organisation_id, newVal)
                    .then(() => {
                        memberContext.getMember(memberContext.currentMember.member_id)
                    })
                    .then(toggle)
                    .then(spordleTable.refreshTable)
                    .then(success)
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            if(error.i18n){
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            }else{

                                let msg;
                                switch (error.message){
                                    case '3331':
                                    case '3332':
                                    case '3189':
                                        msg = `components.uploader.imgUpload.error.${error.message}`;
                                        break;
                                    default:
                                        msg = 'misc.error';
                                        break;
                                }
                                setStatus(msg);
                            }
                        }
                    });
            }}
        >
            {(formik) => (
                <OverlayLoader isLoading={formik.isSubmitting}>
                    <FormikForm>
                        <ModalHeader toggle={toggle}>
                            <Translate id='members.profile.crc.add.title' />
                        </ModalHeader>
                        <ModalBody>
                            <CustomAlert
                                color='info'
                                text={
                                    <Translate
                                        id='members.profile.crc.form.expirationDate.alert'
                                        values={{
                                            expirationDate: <b><Translate id='members.profile.crc.expirationDate' /></b>,
                                        }}
                                    />
                                }
                                translateText={false}
                            />
                            <Row form>
                                <Col className="mb-3" sm="6">
                                    <Label for='issue_date' className='text-muted'><Translate id='members.profile.crc.validOn' /> <Required /></Label>
                                    <FormikDateTime
                                        name='issue_date'
                                        id='issue_date'
                                        timeFormat={false}
                                    />
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for='criminal_record_check_status_id' className='text-muted'><Translate id="misc.status" /> <Required /></Label>
                                        <FormikSelect
                                            search={false}
                                            renderOption={({ option: status }) => <DisplayI18n field='name' defaultValue={status.label} i18n={status.i18n} />}
                                            name='criminal_record_check_status_id'
                                            id='criminal_record_check_status_id'
                                            isLoading={!allStatus}
                                            options={allStatus ?
                                                allStatus
                                                    .map((status) => ({
                                                        label: status.name,
                                                        i18n: status.i18n,
                                                        value: status.criminal_record_check_status_id,
                                                    }))
                                                :
                                                []
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="member_type_id" className="text-muted"><Translate id='members.profile.crc.memberType' /> <Required /></Label>
                                        <FormikSelect
                                            ref={memberTypeSelectRef}
                                            key={backgroundChecks}
                                            isLoading={!backgroundChecks}
                                            name='member_type_id'
                                            id='member_type_id'
                                            renderOption={({ option: memberType }) => <DisplayI18n field='name' defaultValue={memberType.label} i18n={memberType.i18n} />}
                                            searchKeys={[
                                                `i18n.${getGenericLocale()}.name`,
                                            ]}
                                            initFilter={{
                                                backgroundCheckTypeId: '',
                                            }}
                                            onOptionSelected={(values) => {
                                                formik.setFieldTouched('files', false);
                                                handleTypesOnChange(values[0], formik.values.background_check_type_id)
                                                backgroundCheckTypeSelectRef.current?.getSpordleTable().filterChange('memberTypeId', values[0], true);
                                            }}
                                            loadData={(from, { filters }) => {
                                                switch (from){
                                                    case 'FILTER':
                                                        return Promise.resolve(memberTypes?.filter((row) => !!backgroundChecks.find((bgc) => bgc.background_check_type.background_check_type_id === filters.backgroundCheckTypeId && bgc.member_type.member_type_id === row.value)) || [])
                                                    case 'CDM':
                                                        if(backgroundChecks){
                                                            return memberContext.getMemberTypes(organizationContext.organisation_id)
                                                                .then((memberTypes) => {
                                                                    const filteredMemberTypes = memberTypes.reduce((newArray, type) => {
                                                                        if(type.system_type !== 'SPECIAL' && backgroundChecks.find((bgc) => bgc.member_type.member_type_id === type.member_type_id)){
                                                                            newArray.push({
                                                                                value: type.member_type_id,
                                                                                label: type.name,
                                                                                i18n: type.i18n,
                                                                            })
                                                                        }
                                                                        return newArray
                                                                    }, [])
                                                                    setMemberTypes(filteredMemberTypes)
                                                                    return filteredMemberTypes
                                                                })
                                                        }
                                                        return Promise.resolve([])
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col className="mb-3" sm="6">
                                    <Label for='background_check_type_id' className='text-muted'><Translate id='members.profile.crc.type' /> <Required /></Label>
                                    <FormikSelect
                                        ref={backgroundCheckTypeSelectRef}
                                        key={backgroundChecks}
                                        isLoading={!backgroundChecks}
                                        name='background_check_type_id'
                                        id='background_check_type_id'
                                        disabled={!formik.values.member_type_id}
                                        initFilter={{
                                            memberTypeId: '',
                                        }}
                                        onOptionSelected={(values) => {
                                            handleTypesOnChange(formik.values.member_type_id, values[0]);
                                            formik.setFieldTouched('files', false);
                                            memberTypeSelectRef.current?.getSpordleTable().filterChange('backgroundCheckTypeId', values[0], true);
                                        }}
                                        loadData={(from, { filters }, spordleTable) => {
                                            switch (from){
                                                case 'FILTER':
                                                    return Promise.resolve(backgroundCheckTypes?.filter((row) => {
                                                        const bc = backgroundChecks.find((bgc) => bgc.background_check_type.background_check_type_id === row.value && bgc.member_type.member_type_id === filters.memberTypeId)
                                                        if(bc && (isGod() || bc.need_document == 0 || (bc.need_document == 1 && canDoAction('ADD', 'members', 'manage_background_check_attachments'))))
                                                            return true
                                                        return false

                                                        //return !!backgroundChecks.find((bgc) => bgc.background_check_type.background_check_type_id === row.value && bgc.member_type.member_type_id === filters.memberTypeId)
                                                    }) || [])
                                                case 'CDM':
                                                    if(backgroundChecks){
                                                        return crcContext.getBackgroundCheckTypes(organizationContext.organisation_id)
                                                            .then((types) => {
                                                                const filteredBackgroundCheckTypes = types.reduce((newArray, type) => {
                                                                    if(backgroundChecks.find((bgc) => bgc.background_check_type.background_check_type_id === type.background_check_type_id)){
                                                                        newArray.push({
                                                                            value: type.background_check_type_id,
                                                                            label: type.name,
                                                                            i18n: type.i18n,
                                                                        })
                                                                    }
                                                                    return newArray
                                                                }, [])
                                                                setBackgroundCheckTypes(filteredBackgroundCheckTypes)
                                                                return filteredBackgroundCheckTypes
                                                            })
                                                    }
                                                    return Promise.resolve([])
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>

                            <CanDoAction action='ADD' componentCode='members' componentPermissionCode='manage_background_check_attachments'>
                                <FormGroup>
                                    <div className='text-muted mb-2'>
                                        <Translate id='members.profile.crc.supportiveDocument' />
                                        {filesAreRequired && <Required className='ml-1' />}
                                    </div>
                                    <FileUpload
                                        onFileSelect={(files) => formik.setFieldValue('files', files || [])}
                                        dropzoneProps={{
                                            multiple: true,
                                            accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx",
                                        }}
                                    />
                                    <FormikError name="files" />
                                    {!formik.values.files && (
                                        <p className="small mb-0">
                                            <strong><Translate id='members.profile.crc.supportiveDocument.helper.bold' /></strong> <Translate id='members.profile.crc.supportiveDocument.helper' />
                                        </p>
                                    )}
                                </FormGroup>
                            </CanDoAction>

                            <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                <CustomAlert color='danger' withTitle text={formik.status} translateText={false} />
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='primary' type="submit"><Translate id='misc.add' /></Button>
                            <Button color='primary' type="button" onClick={toggle} outline><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </FormikForm>
                </OverlayLoader>
            )}
        </Formik>
    );
}

export default CriminalCriminalCheckModal;