import { Button, Card, CardBody, Col, Row } from "reactstrap";
import Dashboard from "../../../../../assets/images/tour/dashbord.png";
import Dots from "../../../../../assets/images/tour/dots.png";
import styles from "./WelcomeCard.module.scss";
import Translate from "@spordle/intl-elements";
import { useNavigationTour } from "../../../../../components/tour/newNavigation/useNavigationTour";
import { useContext } from "react";
import { AppContext } from "../../../../../contexts/contexts";
import { stringBuilder } from "@spordle/helpers";

const WelcomeCard = () => {
    const [ startTheTour ] = useNavigationTour();
    const { isMobileDevice } = useContext(AppContext);

    return (
        <Card className="card-shadow mb-0 h-100 overflow-hidden">
            <CardBody>
                <img style={{ opacity: 0.25, transform: "translate(0, -10%)" }} className="position-absolute bottom-0 right-0 max-h-100 max-w-100 rounded-lg" src={Dots} alt="Welcome" />
                <img className={styles.imgDashboard + " position-absolute bottom-0 max-h-100 max-w-100 rounded-lg"} src={Dashboard} alt="Welcome" />
                <Row form className="h-100">
                    <Col md="10">
                        <h2 className={stringBuilder("text-primary font-bold", styles.title)}><Translate id="dashboard.widget.welcome.title" /></h2>
                        <p className="text-dark h4 mb-3 font-medium"><Translate id="dashboard.widget.welcome.subtitle" /></p>
                        <p className="mb-1"><Translate id="dashboard.widget.welcome.text.1" /></p>
                        <ul>
                            <li><Translate id="dashboard.widget.welcome.text.2" /></li>
                            <li><Translate id="dashboard.widget.welcome.text.3" /></li>
                        </ul>
                        {!isMobileDevice &&
                            <div className="mt-auto mt-md-4">
                                <Button style={{ boxShadow: "0 20px 20px rgb(57 148 255 / 30%)" }} onClick={startTheTour} type="button" color="primary"><Translate id="dashboard.widget.welcome.btn.tour" /></Button>
                            </div>
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

export default WelcomeCard;