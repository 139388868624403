import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import I18nHelperContextProvider from "../../../../../helpers/i18nHelper";
import FilterForm from "./FilterForm";

const AddFilterModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal size="lg" isOpen={isOpen} analyticsName="addReportsFilters">
            <I18nHelperContextProvider languages={[ "en", "fr" ]} fields={[ 'name' ]}>
                <FilterForm mode="CREATE" {...props} />
            </I18nHelperContextProvider>
        </AnalyticsModal>
    );
}

export default AddFilterModal;