export default{
    "catalog.otherItems.sidepanel.form.status"      : "Statut",
    "catalog.otherItems.sidepanel.form.type"        : "Type",
    "catalog.otherItems.sidepanel.form.description" : "Description",
    "catalog.otherItems.sidepanel.form.price"       : "Prix",
    "catalog.otherItems.sidepanel.form.tax"         : "Taxe",
    "catalog.otherItems.sidepanel.form.sku"         : "SKU",

    "catalog.otherItems.sidepanel.error.3197" : "Cet article est déjà en cours d'utilisation.",
    "catalog.otherItems.sidepanel.error.3191" : "Permissions insuffisantes.",
    "catalog.otherItems.sidepanel.error.3179" : "Accès interdit.",

    "catalog.otherItems.sidepanel.validation.name.required" : "Le nom est obligatoire",
}