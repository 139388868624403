import React from "react";
import {
    Button,
    Label
} from "reactstrap";
import { FormikSelect, FormikInputNumber, FormikInputText, FormikTextArea } from '@spordle/formik-elements';
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from "../../../../helpers/i18nHelper";
import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import FormikEditable from "../../../../components/formik/FormikEditable";
import withContexts from "../../../../helpers/withContexts";
import ImgUploadModal from '../../../../components/uploader/imgUpload/ImgUploadModal';

// Contexts
import { I18nContext } from "../../../../contexts/I18nContext";
import { OtherFeesContext } from '../../../../contexts/OtherFeesContext';
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import NoImgItem from '../../../../assets/images/NoImage_Item.svg'
import UserImg from "../../../../components/UserImg";
import { RolesContext } from "../../../../contexts/RolesContext";
import SidePanel from "../../../../components/sidePanel/SidePanel";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import { success, fail } from '@spordle/toasts';
import { number, object, string } from "yup";
import { stringBuilder } from "@spordle/helpers";
import { UtilsContext } from "../../../../contexts/UtilsContext";

class OtherItemsSidepanel extends React.Component{
    state = {
        loadingState: 'lazy',
        isLoading: false,
        isOpen: false,
    };

    toggleState = (bool) => {
        this.setState((prevState) => ({ isLoading: bool ?? !prevState.isLoading }))
    }

    toggleModal = () => { !this.props.readOnly ? this.setState((prev) => ({ ...prev, isOpen: !prev.isOpen })) : false }

    componentDidMount(){
        this.setState({ loadingState: 'success' })
    }

    render(){
        const readOnly = this.props.readOnly;
        const canEdit = this.props.RolesContext.canDoAction("EDIT", "catalog", "other_fees");
        return (
            <I18nHelperContext.Consumer>
                { (i18nHelper) => (
                    <OverlayLoader isLoading={this.state.isLoading}>
                        {!this.props.readOnly &&
                            <SidePanel.Header>
                                <div className='d-flex mb-2'>
                                    <SidePanel.ToggleButton />
                                    <SidePanel.ActionsMenu action='DELETE' componentCode='catalog' componentPermissionCode='other_fees'>
                                        <SidePanel.MenuDelete
                                            translateModalMsg
                                            modalMsg='catalog.clinicItems.sidepanel.delete.message'
                                            modalTitle={<DisplayI18n field="name" i18n={this.props.selectedRows[0].i18n} defaultValue={this.props.selectedRows[0].name} />}
                                            onConfirm={() => {
                                                return this.props.OtherFeesContext.deleteOtherItems(this.props.selectedRows[0].other_fee_id)
                                                    .then(() => {
                                                        this.props.tableRef.deleteRow(this.props.selectedRows[0].other_fee_id).then(this.props.toggle)
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                            this.toggleState(false);
                                                        }
                                                    })
                                            }}
                                        />
                                    </SidePanel.ActionsMenu>
                                </div>
                                <SidePanel.Title>
                                    <DisplayI18n
                                        field='name'
                                        defaultValue={this.props.selectedRows[0].name}
                                        i18n={this.props.selectedRows[0].fee.i18n}
                                    />
                                </SidePanel.Title>
                            </SidePanel.Header>
                        }
                        <div className={stringBuilder({ "p-3": !readOnly })}>
                            <div className="d-flex align-items-center mb-3">
                                {/* always display img here (imgDefault if img is empty) */}
                                <button type='button' className="reset-btn mr-2" onClick={this.toggleModal}>
                                    <UserImg
                                        className="rounded-lg border shadow-none"
                                        width={60}
                                        src={this.props.selectedRows[0].image?.full_path || NoImgItem}
                                        filePos={this.props.selectedRows[0].image?.file_position}
                                        alt={this.props.selectedRows[0]?.fee?.name}
                                    />
                                </button>
                                <div className="d-flex align-items-center">
                                    <ImgUploadModal
                                        disabled={readOnly}
                                        onSave={async(file, pos) => {
                                            if(file){
                                                if(this.props.selectedRows[0].image?.full_path)await this.props.OtherFeesContext.deleteOtherItemImg(this.props.selectedRows[0].other_fee_id)
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })

                                                return this.props.OtherFeesContext.createOtherItemImg(this.props.selectedRows[0].other_fee_id, { image: file, file_position: pos })
                                                    .then((img) => {
                                                        this.props.syncRows(this.props.createNewValues({ image: img }));
                                                        this.toggleState(false)
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                            this.toggleState(false)
                                                        }
                                                    })
                                            }
                                            return Promise.resolve();
                                        }}
                                        cropperProps={{ cropShape: 'rect' }}
                                        isOpen={this.state.isOpen}
                                        toggle={this.toggleModal}
                                    />
                                    {(canEdit && !readOnly) &&
                                        <>
                                            <Button type="button" color="link" className="mdi mdi-pencil" onClick={this.toggleModal} />
                                            { this.props.selectedRows[0].image?.full_path &&
                                                <>
                                                    <span className="border-left align-self-stretch mx-2" />
                                                    <Button
                                                        type="button"
                                                        color="link text-danger"
                                                        className="mdi mdi-delete border-0"
                                                        onClick={() => {
                                                            this.toggleState(true);
                                                            this.props.OtherFeesContext.deleteOtherItemImg(this.props.selectedRows[0].other_fee_id)
                                                                .then(() => {
                                                                    this.props.syncRows(this.props.createNewValues({ image: null }));
                                                                    success();
                                                                })
                                                                .catch((error) => {
                                                                    if(!AxiosIsCancelled(error.message)){
                                                                        console.error(error.message)
                                                                        fail({
                                                                            msg: 'misc.error',
                                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                            skipInfoTranslate: true,
                                                                        })
                                                                    }
                                                                })
                                                                .finally(() => this.toggleState(false))
                                                        }}
                                                    />
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="mb-3">
                                <Label for="status" className="text-muted"><Translate id='catalog.otherItems.sidepanel.form.status' /></Label>
                                <FormikEditable
                                    id="status"
                                    noConfirmation
                                    disabled={!canEdit}
                                    readOnly={readOnly}
                                    initialValues={{
                                        status: this.props.selectedRows[0].active,
                                    }}
                                    onSubmit={(values, actions) => {
                                        if(values.status !== this.props.selectedRows[0].active){
                                            this.toggleState(true);
                                            const newValues = this.props.createNewValues({ active: values.status });
                                            this.props.OtherFeesContext.updateOtherItems(this.props.selectedRows[0].other_fee_id, values)
                                                .then(() => {
                                                    this.props.syncRows(newValues);
                                                    success();
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                                .finally(() => {
                                                    this.toggleState(false);
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing, options) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>{this.props.selectedRows[0].active === '1' ? <Translate id="misc.active" /> : <Translate id="misc.inactive" />}</div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                search={false}
                                                name='status'
                                                id='status'
                                                autoFocus
                                                menuIsDefaultOpen
                                                onOptionSelected={options.stopEditing}
                                                loadingStatus='success'
                                                defaultData={[
                                                    {
                                                        label: 'misc.active',
                                                        translateLabel: true,
                                                        value: '1',
                                                    },
                                                    {
                                                        label: 'misc.inactive',
                                                        translateLabel: true,
                                                        value: '0',
                                                    },
                                                ]}
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </div>
                            <RenderI18nForm field="name">
                                {({ fieldName, fieldLabel }) => {
                                    return (
                                        <div className="mb-3" key={fieldName}>
                                            <Label for={fieldName} className="text-muted">{fieldLabel}</Label>
                                            <FormikEditable
                                                id={fieldName}
                                                disabled={!canEdit}
                                                readOnly={readOnly}
                                                initialValues={
                                                    i18nHelper.getInitialValues(this.props.selectedRows[0].fee)
                                                }
                                                validationSchema={object().shape(
                                                    i18nHelper.getValidationSchema({ name: string().required(<Translate id='catalog.otherItems.sidepanel.validation.name.required' />) }),
                                                )}
                                                onSubmit={(values) => {
                                                    const newValues = this.props.createNewValues({ fee: { ...this.props.selectedRows[0].fee, ...values } });

                                                    this.toggleState(true);
                                                    this.props.OtherFeesContext.updateOtherItems(this.props.selectedRows[0].other_fee_id, i18nHelper.getAPIValues(values))
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            success();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                        .finally(() => {
                                                            this.toggleState(false);
                                                        })
                                                }}
                                            >
                                                {(isEditing) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className='font-medium text-dark'>
                                                                <DisplayI18n
                                                                    field={fieldName}
                                                                    defaultValue={this.props.selectedRows[0].name || '-'}
                                                                    i18n={this.props.selectedRows[0].fee.i18n}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikInputText id={fieldName} name={fieldName} autoFocus />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    )
                                }}
                            </RenderI18nForm>
                            <RenderI18nForm field="description">
                                {({ fieldName, fieldLabel }) => {
                                    return (
                                        <div className="mb-3" key={fieldName}>
                                            <Label for={fieldName} className="text-muted">{fieldLabel}</Label>
                                            <FormikEditable
                                                id={fieldName}
                                                disabled={!canEdit}
                                                readOnly={readOnly}
                                                initialValues={i18nHelper.getInitialValues(this.props.selectedRows[0].fee)}
                                                validationSchema={object().shape(
                                                    i18nHelper.getValidationSchema({ description: string() }),
                                                )}
                                                onSubmit={(values) => {
                                                    const newValues = this.props.createNewValues({ fee: { ...this.props.selectedRows[0].fee, ...values } });

                                                    this.toggleState(true);
                                                    this.props.OtherFeesContext.updateOtherItems(this.props.selectedRows[0].other_fee_id, i18nHelper.getAPIValues(values))
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            success();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                        .finally(() => {
                                                            this.toggleState(false);
                                                        })
                                                }}
                                            >
                                                {(isEditing) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className='font-medium text-dark text-truncate'>
                                                                <DisplayI18n
                                                                    field={fieldName}
                                                                    defaultValue={this.props.selectedRows[0].description || '-'}
                                                                    i18n={this.props.selectedRows[0].fee.i18n}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikTextArea id={fieldName} name={fieldName} rows="6" />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    )
                                }}
                            </RenderI18nForm>
                            <div className="mb-3 pb-3">
                                <Label for="amount" className="text-muted"><Translate id='catalog.otherItems.sidepanel.form.price' /></Label>
                                <FormikEditable
                                    id='amount'
                                    disabled={!canEdit}
                                    readOnly={readOnly}
                                    initialValues={{
                                        amount: this.props.selectedRows[0].fee.amount / 100,
                                    }}
                                    validationSchema={object().shape({
                                        amount: number().required(),
                                    })}
                                    onSubmit={(values) => {
                                        const newAmount = Math.round(values.amount * 100);
                                        if(newAmount !== this.props.selectedRows[0].fee.amount){

                                            this.toggleState(true);
                                            const newValues = this.props.createNewValues({ fee: { ...this.props.selectedRows[0].fee, amount: newAmount } });

                                            this.props.OtherFeesContext.updateOtherItems(this.props.selectedRows[0].other_fee_id, { amount: newAmount })
                                                .then(() => {
                                                    this.props.syncRows(newValues);
                                                    success();
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                                .finally(() => {
                                                    this.toggleState(false);
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>
                                                    <CurrencyFormat value={this.props.selectedRows[0].fee.amount / 100} />
                                                </div>
                                            )
                                        }
                                        return (
                                            <I18nContext.Consumer>
                                                {({ getGenericLocale }) => (
                                                    <FormikInputNumber
                                                        allowLeadingZeros fixedDecimalScale
                                                        name='amount'
                                                        id='amount'
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        thousandSeparator=' '
                                                        suffix={getGenericLocale() === 'fr' ? '$' : undefined}
                                                        prefix={getGenericLocale() !== 'fr' ? '$' : undefined}
                                                        decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                    />
                                                )}
                                            </I18nContext.Consumer>
                                        )

                                    }}
                                </FormikEditable>
                            </div>
                            <div className="mb-3">
                                <Label for="taxable" className="text-muted mb-0"><Translate id='catalog.registrationFees.add.form.taxable' /></Label>
                                <FormikEditable
                                    id="taxable"
                                    noConfirmation
                                    disabled={!canEdit}
                                    readOnly={readOnly}
                                    initialValues={{
                                        taxable: this.props.selectedRows[0].fee.tax_class ? '1' : '0',
                                    }}
                                    onSubmit={async(values) => {
                                        const taxable = values.taxable == 1
                                        if(taxable !== !!this.props.selectedRows[0].fee.tax_class){
                                            this.toggleState(true);
                                            const taxClasses = taxable
                                                ?
                                                await this.props.UtilsContext.getTaxClasses()
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                                :
                                                [];
                                            const taxClass = taxClasses.filter((taxClass) => taxClass.active == '1')[0];
                                            const newValues = this.props.createNewValues({ fee: { ...this.props.selectedRows[0].fee, tax_class: taxable ? taxClass.tax_class_id : null } })

                                            await this.props.OtherFeesContext.updateOtherItems(this.props.selectedRows[0].other_fee_id, { tax_class_id: taxable ? taxClass.tax_class_id : "" })
                                                .then(() => {
                                                    success();
                                                    this.props.syncRows(newValues);
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                                .finally(() => {
                                                    this.toggleState(false);
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing, options, formik) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>
                                                    <Translate id={this.props.selectedRows[0].fee.tax_class ? 'misc.yes' : 'misc.no'} />
                                                </div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                name='taxable'
                                                id='taxable'
                                                search={false}
                                                autoFocus
                                                menuIsDefaultOpen
                                                onOptionSelected={(values) => {
                                                    formik.setFieldValue('taxable', values[0], false)
                                                        .then(options.stopEditing)
                                                    return true
                                                }}
                                                defaultData={[
                                                    {
                                                        label: 'misc.yes',
                                                        value: '1',
                                                        translateLabel: true,
                                                    },
                                                    {
                                                        label: 'misc.no',
                                                        value: '0',
                                                        translateLabel: true,
                                                    },
                                                ]}
                                                loadingStatus='success'
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </div>
                            {/* <div className="mb-3">
                                <Label for="taxes" className="text-muted"><Translate id='catalog.otherItems.sidepanel.form.tax'/></Label>
                                <FormikEditable
                                    initialValues={{
                                        taxes: ''
                                    }}
                                    onSubmit={(values) => {

                                    }}
                                >
                                    {isEditing => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'></div>
                                            )
                                        } else {
                                            return (
                                                <FormikSelect name='taxes' id='taxes'>
                                                    i have no idea what to put here
                                                </FormikSelect>
                                            )
                                        }
                                    }}
                                </FormikEditable>
                            </div> */}
                            {/* <div className="mb-3">
                                <Label for="sku" className="text-muted"><Translate id='catalog.otherItems.sidepanel.form.sku'/></Label>
                                <FormikEditable
                                    initialValues={{
                                        sku: ''
                                    }}
                                    onSubmit={(values) => {

                                    }}
                                >
                                    {isEditing => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>{this.state.item.sku}</div>
                                            )
                                        } else {
                                            return (
                                                <FormikSelect name='sku' id='sku'>
                                                    <SpordleSelect.Option id='sku-4152' label='4152 - Registration' value='4152' selected/>
                                                </FormikSelect>
                                            )
                                        }
                                    }}
                                </FormikEditable>
                            </div> */}
                        </div>
                    </OverlayLoader>
                )}
            </I18nHelperContext.Consumer>
        )
    }
}

export default withContexts(OrganizationContext, OtherFeesContext, RolesContext, UtilsContext)(OtherItemsSidepanel);