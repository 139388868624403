import { Tooltip } from "@mantine/core";
import { stringBuilder } from "@spordle/helpers";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { useContext } from "react";
import { DEMOGRAPHIC_SETTING_NAME } from "../../../../settings/organization/components/messages/demographicSettings/MessagesSettingsDemographic";
import { useMemberFieldsSWR } from "../../../../../components/customHooks/useMessagesSWR";
import { displayI18n } from "../../../../../helpers/i18nHelper";
import { I18nContext } from "../../../../../contexts/I18nContext";

const MemberAddIndigenousInfo = ({ className }) => {
    const org = useContext(OrganizationContext);
    const setting = org.settings[DEMOGRAPHIC_SETTING_NAME];
    const { data: message } = useMemberFieldsSWR(setting?.value);
    const { getGenericLocale } = useContext(I18nContext);

    if(!message){
        return null;
    }

    return (
        <Tooltip
            width={350}
            label={
                <div
                    className="text-wrap text-break"
                    dangerouslySetInnerHTML={{ __html: displayI18n("body", message.i18n, message.body, getGenericLocale()) }}
                />
            }
            withinPortal={false}
        >
            <i className={stringBuilder(className, "mdi mdi-information-outline text-primary")} />
        </Tooltip>
    );
}

export default MemberAddIndigenousInfo;