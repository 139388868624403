import React from "react";
import {
    Label
} from "reactstrap";
import FormikEditable from "../../../../../../components/formik/FormikEditable";
import { FormikInputNumber, FormikSelect } from '@spordle/formik-elements';
import { success, fail } from '@spordle/toasts';

// Context
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import withContexts from "../../../../../../helpers/withContexts";

// Language
import Translate from "@spordle/intl-elements";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { RolesContext } from "../../../../../../contexts/RolesContext";
import { DocumentTypesContext } from "../../../../../../contexts/DocumentTypesContext";
import { number, object } from "yup";

class SidePanelOrganizationDocumentExpirations extends React.Component{
    state = {
        isLoading: false,
        canEdit: this.props.RolesContext.canDoAction('EDIT', 'settings', 'document_types'),
        readOnly: this.props.selectedRows[0].organisation.organisation_id !== this.props.OrganizationContext.organisation_id,
    };

    toggleLoading = (bool) => {
        this.setState((prevState) => ({ isLoading: bool ?? !prevState.isLoading }))
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <SidePanel.Header>
                    <div className='d-flex mb-2 align-items-center'>
                        <SidePanel.ToggleButton />
                        <SidePanel.ActionsMenu action='DELETE' componentCode='settings' componentPermissionCode='contact_type'>
                            <SidePanel.MenuDelete
                                disabled={this.state.readOnly}
                                translateModalMsg
                                modalMsg='settings.documentTypeExpiration.delete'
                                modalTitle={<DisplayI18n field="name" i18n={this.props.getCommonValue('document_type')?.i18n} defaultValue={this.props.getCommonValue('document_type')?.name} />}
                                onConfirm={() => {
                                    return this.props.DocumentTypesContext.deleteDocumentTypeExpiration(this.props.getCommonValue('document_type_expiration_id'))
                                        .then(() => {
                                            this.props.tableRef.deleteRow(this.props.getCommonValue('document_type_expiration_id'))
                                                .then(() => {
                                                    success();
                                                    this.props.forceCloseSidePanel();
                                                })
                                        })
                                        .catch((e) => {
                                            if(!AxiosIsCancelled(e.message)){
                                                console.error(e);
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                }}
                            />
                        </SidePanel.ActionsMenu>
                    </div>
                    <SidePanel.Title>
                        <DisplayI18n
                            field='name'
                            i18n={this.props.selectedRows[0].document_type.i18n}
                            defaultValue={this.props.selectedRows[0].document_type.name}
                        />
                    </SidePanel.Title>
                    <div className="small">
                        <DisplayI18n
                            field='name'
                            i18n={this.props.selectedRows[0].organisation.i18n}
                            defaultValue={this.props.selectedRows[0].organisation.organisation_name}
                        />
                    </div>
                </SidePanel.Header>
                <div className='p-3'>
                    {/* AGE RANGE DISPLAY */}
                    <div className="mb-3">
                        <Label className="text-muted"><Translate id='reports.filters.ageOverUnder' /></Label>
                        <div className='font-medium text-dark'>{this.props.selectedRows[0].min_age} - {this.props.selectedRows[0].max_age}</div>
                    </div>
                    <div className="mb-3">
                        <Label for="expiration_duration" className="text-muted"><Translate id='settings.documentTypeExpiration.expiration_duration.row' /></Label>
                        <FormikEditable
                            disabled={!this.state.canEdit}
                            readOnly={this.state.readOnly}
                            id="delete_after_expiration"
                            validationSchema={object().shape({
                                expiration_duration: number().min(1, <Translate id='settings.documentTypeExpiration.expiration_duration.validation.minimum' />),
                            })}
                            initialValues={{
                                expiration_duration: this.props.selectedRows[0].expiration_duration,
                            }}
                            onSubmit={(values, actions) => {
                                if(values.expiration_duration !== this.props.selectedRows[0].expiration_duration){
                                    this.toggleLoading(true);
                                    const newValues = this.props.createNewValues({ expiration_duration: values.expiration_duration });
                                    this.props.DocumentTypesContext.updateDocumentTypeExpiration(this.props.getCommonValue('document_type_expiration_id'), { expiration_duration: values.expiration_duration })
                                        .then(() => {
                                            this.props.syncRows(newValues);
                                            this.props.tableRef.refreshTable();
                                            success();
                                            this.toggleLoading(false);
                                        })
                                        .catch((error) => {
                                            console.error(error.message)
                                            this.toggleLoading(false);
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        })
                                }
                            }}
                        >
                            {(isEditing, options) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{this.props.selectedRows[0].expiration_duration} <Translate id={this.props.selectedRows[0].expiration_duration == 1 ? 'settings.documentTypeExpiration.expiration_duration.row.month' : 'settings.documentTypeExpiration.expiration_duration.row.months'} /></div>
                                    )
                                }
                                return (
                                    <FormikInputNumber
                                        id='expiration_duration' name='expiration_duration'
                                        autoFocus
                                        translatePlaceholder
                                        placeholder="settings.documentTypeExpiration.expiration_duration.row.months"
                                    />
                                )

                            }}
                        </FormikEditable>
                    </div>
                    <div className="mb-3">
                        <Label for="delete_after_expiration" className="text-muted"><Translate id='settings.documentTypeExpiration.delete_after_expiration.row' /></Label>
                        <FormikEditable
                            disabled={!this.state.canEdit}
                            readOnly={this.state.readOnly}
                            id="delete_after_expiration"
                            search={false}
                            noConfirmation
                            initialValues={{
                                delete_after_expiration: this.props.selectedRows[0].delete_after_expiration,
                            }}
                            onSubmit={(values, actions) => {
                                if(values.delete_after_expiration !== this.props.selectedRows[0].delete_after_expiration){
                                    this.toggleLoading(true);
                                    const newValues = this.props.createNewValues({ delete_after_expiration: values.delete_after_expiration });
                                    this.props.DocumentTypesContext.updateDocumentTypeExpiration(this.props.getCommonValue('document_type_expiration_id'), { delete_after_expiration: values.delete_after_expiration })
                                        .then(() => {
                                            this.props.syncRows(newValues);
                                            this.props.tableRef.refreshTable();
                                            success();
                                            this.toggleLoading(false);
                                        })
                                        .catch((error) => {
                                            console.error(error.message)
                                            this.toggleLoading(false);
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        })
                                }
                            }}
                        >
                            {(isEditing, options) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{this.props.selectedRows[0].delete_after_expiration === '1' ? <Translate id='misc.yes' /> : <Translate id='misc.no' />}</div>
                                    )
                                }
                                return (
                                    <FormikSelect
                                        id='delete_after_expiration' name='delete_after_expiration'
                                        autoFocus menuIsDefaultOpen
                                        onOptionSelected={() => {
                                            options.stopEditing()
                                        }}
                                        defaultData={[
                                            { value: '1', label: 'misc.yes', translateLabel: true },
                                            { value: '0', label: 'misc.no', translateLabel: true },
                                        ]}
                                        loadingStatus='success'
                                    />
                                )

                            }}
                        </FormikEditable>
                    </div>
                </div>

            </OverlayLoader>
        )
    }
}

export default withContexts(OrganizationContext, RolesContext, DocumentTypesContext)(SidePanelOrganizationDocumentExpirations);
