import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import IsGod from '../../../../../../components/permissions/IsGod';
import Translate from '@spordle/intl-elements';
import CrossFade from '../../../../../../components/crossFade/CrossFade';
import { Formik, Form } from 'formik';
import { array, object } from 'yup';
import { FormikSelect } from '@spordle/formik-elements';
import { useContext } from 'react';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { fail } from '@spordle/toasts';
import CanDoAction from '../../../../../../components/permissions/CanDoAction';
import { RolesContext } from '../../../../../../contexts/RolesContext';
import { IdentityRolesContext } from '../../../../../../contexts/IdentityRolesContext';

const DocumentSettingsForm = ({ isDocumentsLoading, toggleIsEditing, insuranceDocumentTypes, documentTypes, memberRegistrationDocumentTypes, clinicRegistrationDocumentTypes, travelPermitDocumentTypes }) => {
    const organizationContext = useContext(OrganizationContext);
    const rolesContext = useContext(RolesContext);
    const identityRolesContext = useContext(IdentityRolesContext);

    return (
        <Formik
            initialValues={{
                memberRegistrationDocumentTypes: memberRegistrationDocumentTypes.map((type) => type.document_type_id) ?? [],
                clinicRegistrationDocumentTypes: clinicRegistrationDocumentTypes.map((type) => type.document_type_id) ?? [],
                insuranceDocumentTypes: insuranceDocumentTypes.map((type) => type.document_type_id) ?? [],
                travelPermitDocumentTypes: travelPermitDocumentTypes.map((type) => type.document_type_id) ?? [],
            }}
            validationSchema={object().shape({
                memberRegistrationDocumentTypes: array(),
                clinicRegistrationDocumentTypes: array(),
                insuranceDocumentTypes: array(),
                travelPermitDocumentTypes: array(),
            })}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                const apiValues = {}
                if(rolesContext.canDoAction("ADD", "settings", "member_registration_document_type_configuration") || identityRolesContext.isGod())
                    apiValues.attachment_for_new_member_in_registration = values.memberRegistrationDocumentTypes || [];
                if(rolesContext.canDoAction("ADD", "settings", "clinic_document_type_configuration") || identityRolesContext.isGod())
                    apiValues.attachment_for_new_member_in_clinic_registration = values.clinicRegistrationDocumentTypes || [];
                if(rolesContext.canDoAction("ADD", "settings", "insurance_document_type_configuration") || identityRolesContext.isGod())
                    apiValues.document_type_list_insurance = values.insuranceDocumentTypes || [];
                if(rolesContext.canDoAction("ADD", "teams", "team_travel_permits") || identityRolesContext.isGod())
                    apiValues.attachment_for_travel_permit = values.travelPermitDocumentTypes || [];

                organizationContext.updateSettings(organizationContext.organisation_id, apiValues)
                    .then(toggleIsEditing)
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message);
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                            setSubmitting(false);
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <Row className="mb-n3">
                            <CanDoAction tag={Col} sm="6" className='mb-3' action='ADD' componentCode='settings' componentPermissionCode='member_registration_document_type_configuration'>
                                <Card className="card-shadow mb-0">
                                    <CardHeader className="bg-white">
                                        <div className="h5 font-bold mb-1"><Translate id="settings.documentSettings.member.registration.title" /></div>
                                        <div className="small font-medium mb-0"><Translate id="settings.documentSettings.member.registration.desc" /></div>
                                        <IsGod><div className="small mt-1 font-medium text-purple">attachment_for_new_member_in_registration</div></IsGod>
                                    </CardHeader>
                                    <CardBody>
                                        <CrossFade isVisible={!isDocumentsLoading}>
                                            <FormikSelect
                                                multi clearable
                                                options={documentTypes.map(((type) => ({ ...type, label: type.name, value: type.document_type_id }))) ?? []}
                                                isLoading={isDocumentsLoading}
                                                name='memberRegistrationDocumentTypes'
                                                id='memberRegistrationDocumentTypes'
                                                dataIndex="document_type_id"
                                                renderOption={({ option }) => {
                                                    return (
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={option.name}
                                                            i18n={option.i18n}
                                                        />
                                                    )

                                                }}
                                            />
                                        </CrossFade>
                                    </CardBody>
                                </Card>
                            </CanDoAction>

                            <CanDoAction tag={Col} sm="6" className='mb-3' action='ADD' componentCode='settings' componentPermissionCode='clinic_document_type_configuration'>
                                <Card className="card-shadow mb-0">
                                    <CardHeader className="bg-white">
                                        <div className="h5 font-bold mb-1"><Translate id="settings.documentSettings.clinic.registration.title" /></div>
                                        <div className="small font-medium mb-0"><Translate id="settings.documentSettings.clinic.registration.desc" /></div>
                                        <IsGod><div className="small mt-1 font-medium text-purple">attachment_for_new_member_in_clinic_registration</div></IsGod>
                                    </CardHeader>
                                    <CardBody>
                                        <CrossFade isVisible={!isDocumentsLoading}>
                                            <FormikSelect
                                                multi clearable
                                                options={documentTypes.map(((type) => ({ ...type, label: type.name, value: type.document_type_id }))) ?? []}
                                                isLoading={isDocumentsLoading}
                                                name='clinicRegistrationDocumentTypes'
                                                id='clinicRegistrationDocumentTypes'
                                                dataIndex="document_type_id"
                                                renderOption={({ option }) => {
                                                    return (
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={option.name}
                                                            i18n={option.i18n}
                                                        />
                                                    )

                                                }}
                                            />
                                        </CrossFade>
                                    </CardBody>
                                </Card>
                            </CanDoAction>

                            <CanDoAction tag={Col} sm="6" className='mb-3' action='ADD' componentCode='settings' componentPermissionCode='insurance_document_type_configuration'>
                                <Card className="card-shadow mb-0">
                                    <CardHeader className="bg-white">
                                        <div className="h5 font-bold mb-1"><Translate id="settings.documentSettings.insurance.title" /></div>
                                        <div className="small font-medium mb-0"><Translate id="settings.documentSettings.insurance.desc" /></div>
                                        <IsGod><div className="small mt-1 font-medium text-purple">document_type_list_insurance</div></IsGod>
                                    </CardHeader>
                                    <CardBody>
                                        <CrossFade isVisible={!isDocumentsLoading}>
                                            <FormikSelect
                                                multi clearable
                                                options={documentTypes.map(((type) => ({ ...type, label: type.name, value: type.document_type_id }))) ?? []}
                                                isLoading={isDocumentsLoading}
                                                name='insuranceDocumentTypes'
                                                id='insuranceDocumentTypes'
                                                dataIndex="document_type_id"
                                                renderOption={({ option }) => {
                                                    return (
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={option.name}
                                                            i18n={option.i18n}
                                                        />
                                                    )

                                                }}
                                            />
                                        </CrossFade>
                                    </CardBody>
                                </Card>
                            </CanDoAction>


                            <CanDoAction tag={Col} sm="6" className='mb-3' action='ADD' componentCode='teams' componentPermissionCode='team_travel_permits'>
                                <Card className="card-shadow mb-0">
                                    <CardHeader className="bg-white">
                                        <div className="h5 font-bold mb-1"><Translate id="settings.documentSettings.travelPermit.title" /></div>
                                        <div className="small font-medium mb-0"><Translate id="settings.documentSettings.travelPermit.desc" /></div>
                                        <IsGod><div className="small mt-1 font-medium text-purple">attachment_for_travel_permit</div></IsGod>
                                    </CardHeader>
                                    <CardBody>
                                        <CrossFade isVisible={!isDocumentsLoading}>
                                            <FormikSelect
                                                multi clearable
                                                options={documentTypes.map(((type) => ({ ...type, label: type.name, value: type.document_type_id }))) ?? []}
                                                isLoading={isDocumentsLoading}
                                                name='travelPermitDocumentTypes'
                                                id='travelPermitDocumentTypes'
                                                dataIndex="document_type_id"
                                                renderOption={({ option }) => {
                                                    return (
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={option.name}
                                                            i18n={option.i18n}
                                                        />
                                                    )

                                                }}
                                            />
                                        </CrossFade>
                                    </CardBody>
                                </Card>
                            </CanDoAction>

                        </Row>

                        <div className="text-right">
                            <Button color="primary" className="mx-2" type='submit' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                            <Button color="primary" outline onClick={toggleIsEditing} type='button' disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                        </div>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default DocumentSettingsForm;