import { FormikError, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import {
    Alert, Button, Col, Collapse, FormGroup,
    Label, ModalBody,
    ModalFooter, Row
} from "reactstrap";
import { array, object, string } from 'yup';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import { AppealsContext } from '../../contexts/AppealsContext';
import { I18nContext } from '../../contexts/I18nContext';
import { DisplayI18n } from '../../helpers/i18nHelper';
import Required from '../formik/Required';
import { fail } from "@spordle/toasts";
import FileUpload from '../uploader/fileUpload/FileUpload';
// contexts
import { AppealsAddContext } from './AppealsAddContext';
import CrossFade from '../crossFade/CrossFade';


const AppealsAdd2 = ({ previous, toggle }) => {
    // contexts
    const appealsAddContext = useContext(AppealsAddContext);
    const appealsContext = useContext(AppealsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const [ selectedType, setSelectedType ] = useState(null);

    const getStatus = async() => {
        if(appealsAddContext.state.status){
            return Promise.resolve(formatStatus(appealsAddContext.state.status))
        }
        return appealsContext.getAppealStatus()
            .then((_status) => {
                appealsAddContext.setStatus(_status)
                return formatStatus(_status);
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })

    }

    function formatStatus(_status){
        return _status.reduce((newArray, status) => {
            if(status.active == 1){
                newArray.push({
                    value: status.appeal_status_id,
                    label: status.name,
                    i18n: status.i18n,
                })
            }
            return newArray
        }, [])
    }

    const getApprove = async() => {
        if(appealsAddContext.state.approves){
            return Promise.resolve(formatApprove(appealsAddContext.state.approves))
        }
        return appealsContext.getAppealApproves()
            .then((_approve) => {
                appealsAddContext.setApproves(_approve)
                return formatApprove(_approve)
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })

    }

    function formatApprove(_approve){
        return _approve.reduce((newArray, approve) => {
            if(approve.active == 1){
                newArray.push({
                    value: approve.appeal_approve_id,
                    label: approve.name,
                    i18n: approve.i18n,
                })
            }
            return newArray
        }, [])
    }

    const getType = async() => {
        if(appealsAddContext.state.types){
            return Promise.resolve(formatType(appealsAddContext.state.types))
        }
        return appealsContext.getAppealTypes()
            .then((_type) => {
                appealsAddContext.setTypes(_type)
                return formatType(_type)
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })

    }

    function formatType(_type){
        return _type.reduce((newArray, type) => {
            const formattedType = {
                value: type.appeal_type_id,
                label: type.name,
                i18n: type.i18n,
                with_note: type.with_note,
            }
            if(type.active == 1){
                newArray.push(formattedType)
            }
            if(type.appeal_type_id === appealsAddContext.state.formData.appeal_type_id){
                setSelectedType(formattedType)
            }
            return newArray
        }, [])
    }

    return (
        <Formik
            initialValues={{
                type: appealsAddContext.state.formData.appeal_type_id || '',
                typeNote: appealsAddContext.state.formData.appeal_type_note || '',
                status: appealsAddContext.state.formData.appeal_status_id || '',
                approve: appealsAddContext.state.formData.appeal_approve_id || '',
                notes: appealsAddContext.state.formData.comment || '',
                attachments: appealsAddContext.state.formData.otherFiles?.map((attachment) => attachment.file) || [],
            }}
            validationSchema={object().shape({
                type: string(),
                typeNote: string(),
                status: string().required(<Translate id='members.profile.appeals.sidepanel.add.status.required' />),
                approve: string().required(<Translate id='members.profile.appeals.sidepanel.add.approve.required' />),
                notes: string(),
                attachments: array(),
            })}
            onSubmit={(values, { setStatus }) => {
                appealsAddContext.setData({
                    ...appealsAddContext.state.formData,

                    appeal_type_id: values.type,
                    appeal_type_note: values.typeNote,
                    appeal_status_id: values.status,
                    appeal_approve_id: values.approve,
                    comment: values.notes,
                    otherFiles: values.attachments.map((file) => ({ file: file, type: 'OTHER' })),
                })
                appealsAddContext.goToView(appealsAddContext.views.invoiceInfo2)
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <div className="mt-2">
                            <div className="h6 font-bold"><Translate id='members.profile.appeals.status' /></div>
                            <Row form>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Label for='type' className='text-muted'><Translate id='members.profile.appeals.type' /></Label>
                                        <FormikSelect
                                            name='type'
                                            id='type'
                                            renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                            searchKeys={[
                                                `i18n.${getGenericLocale()}.name`,
                                            ]}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return getType();
                                                    default:
                                                        break;
                                                }
                                            }}
                                            onOptionSelected={(values, spordleSelect) => {
                                                if(values[0]){
                                                    setSelectedType(spordleSelect.getSpordleTable().getData().find((data) => data.value === values[0]))
                                                }else{
                                                    setSelectedType(null)
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm='6'>
                                    <CrossFade isVisible={selectedType?.with_note == 1}>
                                        <FormGroup>
                                            <Label for='typeNote' className='text-muted'><Translate id='members.profile.appeals.typeNote' /></Label>
                                            <FormikTextArea id='typeNote' name='typeNote' rows={1} trim />
                                        </FormGroup>
                                    </CrossFade>
                                </Col>
                            </Row>
                            <Row form>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for='status' className='text-muted'><Translate id='members.profile.appeals.status' /> <Required /></Label>
                                        <FormikSelect
                                            name='status'
                                            id='status'
                                            renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                            searchKeys={[
                                                `i18n.${getGenericLocale()}.name`,
                                            ]}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return getStatus();
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for='approve' className='text-muted'><Translate id='members.profile.appeals.approved' /> <Required /></Label>
                                        <FormikSelect
                                            name='approve'
                                            id='approve'
                                            searchKeys={[
                                                `i18n.${getGenericLocale()}.name`,
                                            ]}
                                            renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return getApprove();
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for='notes' className='text-muted'><Translate id='members.profile.appeals.note' /> </Label>
                                        <FormikTextArea id='notes' name='notes' rows='3' />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for='attachments' className='text-muted'><Translate id='members.profile.appeals.attachments' /> </Label>
                                        <FileUpload
                                            defaultFiles={formik.initialValues.attachments || []}
                                            onFileSelect={(files) => {
                                                formik.setFieldValue('attachments', files);
                                            }}
                                            dropzoneProps={{
                                                multiple: true,
                                                accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                                                maxSize: 20971520,
                                            }}
                                        />
                                        <FormikError name='attachments' />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <Collapse isOpen={formik.status?.includes('error')}>
                            <Alert color='danger' toggle={() => formik.setStatus('')}>
                                {formik.status && // to prevent error message in console when status is undefined
                                        <Translate id={'contexts.membersContext.' + formik.status?.split('-')?.[1]} defaultMessageId='misc.error' />
                                }
                            </Alert>
                        </Collapse>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' onClick={() => appealsAddContext.goToView(appealsAddContext.views.appealInfo)} outline className="mr-auto"><Translate id='misc.previous' /></Button>
                        <Button color='primary' type='submit'><Translate id='misc.next' /></Button>
                        <Button color='primary' onClick={appealsAddContext.toggleModal} outline><Translate id='misc.cancel' /></Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
}

export default AppealsAdd2;