import { FormikCurrencyInput, FormikDateTime, FormikInputNumber, FormikInputText, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";
import moment from 'moment';
import React, { useContext } from "react";
import {
    Button,
    Col,
    Collapse,
    FormGroup, Label, Row
} from "reactstrap";
import { array, mixed, number, object, string } from 'yup';
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import FormikEditable from '../../../../../../../components/formik/FormikEditable';
import FormikEditableFile from "../../../../../../../components/formik/FormikEditableFile";
import OverlayLoader from "../../../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../../../components/sidePanel/SidePanel";
import { fail, success } from '@spordle/toasts';
import { compareAndFormatFiles, triggerDownload } from "../../../../../../../components/uploader/uploadHelpers";
import UserDisplay from "../../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../../components/UserImg";
import { I18nContext } from '../../../../../../../contexts/I18nContext';
import { MembersContext } from "../../../../../../../contexts/MembersContext";
import { OrganizationContext } from "../../../../../../../contexts/OrganizationContext";
import { PositionsContext } from "../../../../../../../contexts/PositionsContext";
import { RolesContext } from '../../../../../../../contexts/RolesContext';
// contexts
import { SuspensionsContext } from '../../../../../../../contexts/SuspensionsContext';
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import withContexts from '../../../../../../../helpers/withContexts';
import Required from '../../../../../../../components/formik/Required';
import InlineCopy from '../../../../../../../components/inlineCopy/InlineCopy';
import MemberSuspensionActivateModal from './MemberSuspensionActivateModal';


class MemberSuspensionsSidepanel extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isLoading: false,

            suspensions: false,
            memberTeams: false,
            positions: false,
            currentSuspensionDuration: '',

            activateModalIsOpen: false,
        }
    }

    getSuspensions = () => {
        return this.props.SuspensionsContext.getSuspensions(this.props.OrganizationContext.organisation_id)
            .then((_suspensions) => {
                const suspensions = this.formatSuspensions(_suspensions)
                this.setState(() => ({ suspensions: suspensions }))
                return suspensions
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    formatSuspensions = (_suspensions) => {
        return _suspensions.reduce((newArray, suspension) => {
            if(suspension.active == 1){
                newArray.push({
                    value: suspension.suspension_id,
                    label: suspension.name,
                    i18n: suspension.i18n,
                    suspension_type: suspension.suspension_type,
                })
            }
            return newArray
        }, [])
    }

    suspensionAttachmentsOnSubmit = async(files) => {
        const { toKeep, toDelete, toCreate } = compareAndFormatFiles(this.props.selectedRows[0].attachments, files);
        let newVal = { attachments: [] };

        if(toDelete?.length > 0){
            await Promise.all(
                toDelete.map((att) => (
                    this.props.MembersContext.deleteMemberSuspensionAttachment(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_suspension_id, att.member_suspension_attachment_id)
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                            }
                        })
                )),
            )
            this.props.syncRows(this.props.createNewValues({ attachments: toKeep })); // necessary in case of error later
        }

        if(toCreate?.length > 0){
            // separateFilesPerSize returns an array of subarray of files in which the total size does not exceed 14MB.
            // Avoid php errors for the api
            await Promise.all(
                toCreate.map((subArr) => {
                    return this.props.MembersContext.createMemberSuspensionAttachment(
                        this.props.MembersContext.currentMember.member_id,
                        this.props.selectedRows[0].member_suspension_id,
                        subArr,
                    ).catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                        }
                    });
                }),
            );

            newVal = {
                attachments: await this.props.MembersContext.getMemberSuspensionAttachments(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_suspension_id)
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    }),
            };

            this.props.syncRows(this.props.createNewValues(newVal));
        }
    }

    deleteMemberSuspension = () => {
        this.props.MembersContext.deleteMemberSuspension(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_suspension_id)
            .then(() => {
                success();
                this.props.tableRef.refreshTable();
                this.props.forceCloseSidePanel();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    /**
     * @description Checks if the code of the suspension corresponds to the discrimination suspension
     * @param {object} suspension Id of the suspension
     * @returns {boolean}
     */
    isDiscriminatory = (suspension) => suspension?.code === "GRM21: 11.4"; //TODO: HARDCODED

    isAdministrative = () => this.props.selectedRows[0].suspension?.system_type === 'ADMINISTRATIVE';

    componentDidUpdate(prevProps){
        if(prevProps.selectedRows[0].member_suspension_id !== this.props.selectedRows[0].member_suspension_id){
            this.setState(() => ({ currentSuspensionDuration: this.props.selectedRows[0].duration }))
        }
    }

    componentDidMount(){
        this.setState(() => ({ currentSuspensionDuration: this.props.selectedRows[0].duration }))
    }

    toggleActivateModal = () => {
        this.setState((prev) => ({ activateModalIsOpen: !prev.activateModalIsOpen }))
    }

    onSuccess = (values) => {
        const newValues = this.props.createNewValues({
            end_date: values.endDate ? values.endDate.format('YYYY-MM-DD') : null,
            duration: values.duration,
        })
        this.props.syncRows(newValues);
        this.props.tableRef.refreshTable();
    }

    render(){
        const currentMember = this.props.MembersContext.currentMember;
        return (
            <RolesContext.Consumer>
                {({ canDoAction }) => {
                    const canEdit = canDoAction('EDIT', 'members', 'members_suspensions');

                    return (
                        <OverlayLoader isLoading={this.state.isLoading}>
                            <MemberSuspensionActivateModal
                                isOpen={this.state.activateModalIsOpen}
                                toggle={this.toggleActivateModal}
                                suspensionId={this.props.selectedRows[0].member_suspension_id}
                                onSuccess={this.onSuccess}
                                startDate={this.props.selectedRows[0].start_date}
                            />

                            <SidePanel.Header>
                                <div className='d-flex mb-2'>
                                    <SidePanel.ToggleButton />

                                    <SidePanel.ActionsMenu action={[ 'DELETE' ]} componentCode='members' componentPermissionCode='members_suspensions'>
                                        <HandleSuspensionPDFExport memberSuspensionId={this.props.selectedRows[0]?.member_suspension_id} />

                                        {this.isAdministrative() && this.props.getStatus(this.props.selectedRows[0].duration, this.props.selectedRows[0].end_date).string === 'expired' &&
                                            <SidePanel.MenuAction
                                                action='EDIT' componentCode='members' componentPermissionCode='members_suspensions'
                                                onClick={() => {
                                                    this.setState(() => ({
                                                        activateModalIsOpen: true,
                                                    }))
                                                }}
                                            >
                                                <Translate id='members.profile.suspensions.activate.btn' />
                                            </SidePanel.MenuAction>
                                        }
                                        {this.isAdministrative() && this.props.getStatus(this.props.selectedRows[0].duration, this.props.selectedRows[0].end_date).string === 'active' &&
                                            <SidePanel.MenuAction
                                                action='EDIT' componentCode='members' componentPermissionCode='members_suspensions'
                                                onClick={() => {
                                                    this.setState(() => ({ isLoading: true }))
                                                    this.props.MembersContext.updateMemberSuspensionPartial(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_suspension_id, {
                                                        duration: 'DEFINITE',
                                                        end_date: moment().format('YYYY-MM-DD'),
                                                    })
                                                        .then(() => {
                                                            this.onSuccess({
                                                                duration: 'DEFINITE',
                                                                endDate: moment(),
                                                            })
                                                            this.setState(() => ({ isLoading: false }))
                                                        })
                                                        .catch((error) => {
                                                            this.setState(() => ({ isLoading: false }))
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        })
                                                }}
                                            >
                                                <Translate id='members.profile.suspensions.deactivate.btn' />
                                            </SidePanel.MenuAction>
                                        }

                                        <SidePanel.MenuDelete
                                            action='DELETE' componentCode='members' componentPermissionCode='members_suspensions'
                                            translateModalMsg translateActionButtonText
                                            label='misc.delete'
                                            modalMsg='members.profile.suspensions.delete.message'
                                            actionButtonText='misc.delete'
                                            onConfirm={this.deleteMemberSuspension}
                                            modalContent={
                                                <UserDisplay className='justify-content-center'>
                                                    <UserDisplay.Container>
                                                        <UserImg
                                                            abbr={currentMember.first_name.charAt(0) + currentMember.last_name.charAt(0)}
                                                            src={currentMember.picture?.full_path}
                                                            filePos={currentMember.picture?.file_position}
                                                            width={40}
                                                            height={40}
                                                            alt={currentMember.first_name + ' ' + currentMember.last_name}
                                                        />
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container>
                                                        <UserDisplay.Title>{currentMember.first_name + ' ' + currentMember.last_name}</UserDisplay.Title>
                                                            #{currentMember.unique_identifier}
                                                        <UserDisplay.Subtitle><DateFormat value={currentMember.birthdate} /></UserDisplay.Subtitle>
                                                    </UserDisplay.Container>
                                                </UserDisplay>
                                            }
                                        />
                                    </SidePanel.ActionsMenu>
                                </div>
                                <div className="d-flex flex-wrap align-items-center">
                                    <div>
                                        <SidePanel.Title><Translate id='members.profile.suspensions.sidepanel.title' /></SidePanel.Title>
                                        <div className="font-medium text-dark">
                                            <DateFormat value={this.props.selectedRows[0].received_date || this.props.selectedRows[0].start_date} utc />
                                            <span className='mx-1'>-</span>
                                            {this.props.selectedRows[0].duration === 'DEFINITE' ?
                                                this.props.selectedRows[0].game_suspended ?
                                                    <>
                                                        <Translate id='members.profile.suspensions.duration.games' values={{ games: this.props.selectedRows[0].game_suspended }} />
                                                        { this.props.selectedRows[0].end_date &&
                                                            <span className='small ml-1'>(<DateFormat value={this.props.selectedRows[0].end_date} utc />)</span>
                                                        }
                                                    </>
                                                    :
                                                    <DateFormat value={this.props.selectedRows[0].end_date} utc />
                                                :
                                                <Translate id='members.profile.suspensions.duration.indefinite' />
                                            }
                                        </div>
                                    </div>
                                    <div className="mb-3 ml-3">
                                        {this.props.getStatus(this.props.selectedRows[0].duration, this.props.selectedRows[0].end_date).render}
                                    </div>
                                </div>
                                <div className="font-medium text-dark">
                                    <DisplayI18n field='name' defaultValue={this.props.selectedRows[0].organisation.name} i18n={this.props.selectedRows[0].organisation.i18n} />
                                </div>
                                {this.props.selectedRows[0].member_type &&
                                    <div className="font-medium text-dark">
                                        <DisplayI18n field='name' defaultValue={this.props.selectedRows[0].member_type.name} i18n={this.props.selectedRows[0].member_type.i18n} />
                                    </div>
                                }
                                {this.props.selectedRows[0].reference_number ?
                                    <InlineCopy toCopy={this.props.selectedRows[0].reference_number}>
                                        {this.props.selectedRows[0].reference_number}
                                    </InlineCopy>
                                    :
                                    '-'
                                }
                            </SidePanel.Header>
                            <div className="p-3">
                                <Row>
                                    <Col sm="12">
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='members.profile.suspensions.infraction' /></div>
                                            <FormikEditable
                                                id='infraction'
                                                disabled // default disabled
                                                noConfirmation
                                                initialValues={{
                                                    suspensionId: this.props.selectedRows[0].suspension_id,
                                                }}
                                                validationSchema={object().shape({
                                                    suspensionId: string().required(<Translate id='members.profile.suspensions.infraction.required' />),
                                                })}
                                                onSubmit={(values) => {
                                                    if(values.suspensionId !== this.props.selectedRows[0].suspension.suspension_id){
                                                        this.setState(() => ({ isLoading: true }))
                                                        const newValues = this.props.createNewValues({ suspension: this.state.suspensions.find((s) => s.suspension_id === values.suspensionId) })
                                                        this.props.MembersContext.updateMemberSuspensionPartial(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_suspension_id, {
                                                            suspension_id: values.suspensionId,
                                                        })
                                                            .then(() => {
                                                                this.props.syncRows(newValues);
                                                                success();
                                                                this.setState(() => ({ isLoading: false }))
                                                            })
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                    this.setState(() => ({ isLoading: false }))
                                                                }
                                                            })
                                                    }
                                                }}
                                            >
                                                {(isEditing, options, formik) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className="font-medium text-dark">
                                                                {this.props.selectedRows[0].other_infraction ?
                                                                    this.props.selectedRows[0].other_infraction
                                                                    :
                                                                    <DisplayI18n field='name' defaultValue={this.props.selectedRows[0].suspension.name} i18n={this.props.selectedRows[0].suspension.i18n} />
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    // if this is ever editable, manage the other_infraction part (see add modal step 1)
                                                    return (
                                                        <FormikSelect
                                                            id='suspensionId'
                                                            name='suspensionId'
                                                            renderOption={({ option }) => (
                                                                <>
                                                                    <div className='mb-0'><DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} /></div>
                                                                    {option.suspension_type && <div className='small'><DisplayI18n field="name" defaultValue={option.suspension_type.name} i18n={option.suspension_type.i18n} /></div>}
                                                                </>
                                                            )}
                                                            autoFocus menuIsDefaultOpen
                                                            onOptionSelected={(values) => {
                                                                formik.setFieldValue('suspensionId', values[0], false)
                                                                    .then(options.stopEditing)
                                                                return true
                                                            }}
                                                            searchKeys={[
                                                                `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                            ]}
                                                            loadData={this.getSuspensions}
                                                            // options={this.state.suspensions ?
                                                            //     this.state.suspensions.reduce((keptSuspensions, suspension) => {
                                                            //         if(suspension.active == 1){
                                                            //             keptSuspensions.push({
                                                            //                 value: suspension.suspension_id,
                                                            //                 i18n: suspension.i18n,
                                                            //                 label: suspension.name,
                                                            //                 suspension_type: suspension.suspension_type,
                                                            //             })
                                                            //         }
                                                            //         return keptSuspensions;
                                                            //     }, [])
                                                            //     :
                                                            //     []
                                                            // }
                                                        />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    </Col>
                                    {this.isDiscriminatory(this.props.selectedRows[0].suspension) &&
                                        <Col sm="12">
                                            <FormGroup>
                                                <div className="text-muted">
                                                    <Translate id='discrimination.global.discriminatoryGrounds' />
                                                </div>
                                                {/* <FormikEditable
                                                    id='discrimination_reason'
                                                    noConfirmation
                                                    disabled={!canEdit}
                                                    initialValues={{
                                                        discrimination_reason: this.props.selectedRows[0].discrimination_reason.split(','),
                                                    }}
                                                    validationSchema={object().shape({
                                                        discrimination_reason: array().min(1, <Translate id='discrimination.form.discriminatoryGrounds.min' />)
                                                    })}
                                                    onSubmit={({discrimination_reason}) => {
                                                        const joinedReasons = discrimination_reason.join(',');
                                                        if(joinedReasons !== this.props.selectedRows[0].discrimination_reason) {
                                                            this.setState(() => ({isLoading: true}))
                                                            this.props.MembersContext.updateMemberSuspensionPartial(
                                                                this.props.MembersContext.currentMember.member_id,
                                                                this.props.selectedRows[0].member_suspension_id, {
                                                                discrimination_reason: joinedReasons,
                                                            })
                                                                .then(() => {
                                                                    this.props.syncRows(this.props.createNewValues({discrimination_reason: joinedReasons}));
                                                                    this.setState(() => ({isLoading: false}));
                                                                    success();
                                                                })
                                                                .catch(e => {
                                                                    if(!AxiosIsCancelled(e.message)){
                                                                        console.error(e.message);
                                                                        fail();
                                                                        this.setState(() => ({isLoading: false}));
                                                                    }
                                                                })
                                                        }
                                                    }}
                                                >
                                                    {(isEditing, options) => {
                                                        if(!isEditing){
                                                            return <Translate
                                                                values={{count: this.props.selectedRows[0].discrimination_reason.split(',').length}}
                                                                id='discrimination.addModal.form.select.groundsSelected'
                                                            />;
                                                        } else {
                                                            return (
                                                                <FormikSelect
                                                                    isMulti
                                                                    autoFocus
                                                                    openMenuOnFocus
                                                                    closeMenuOnSelect={false}
                                                                    hideSelectedOptions={false}
                                                                    textWhen={1}
                                                                    textCountLabel='discrimination.addModal.form.select.groundsSelected'
                                                                    name="discrimination_reason"
                                                                    placeholder={<Translate id='misc.select.placeholder'/>}
                                                                    noOptionsMessage={() => <Translate id='misc.noSelection'/>}
                                                                >
                                                                    {DISCRIMINATION_REASONS.map(reason => (
                                                                        <SpordleSelect.Option
                                                                            key={reason}
                                                                            selected={this.props.selectedRows[0].discrimination_reason.includes(reason)}
                                                                            translateLabel
                                                                            id={reason}
                                                                            value={reason}
                                                                            label={'discrimination.global.discriminatoryGrounds.'+reason}
                                                                        />
                                                                    ))}
                                                                </FormikSelect>
                                                            )
                                                        }
                                                    }}
                                                </FormikEditable> */}
                                                {this.props.selectedRows[0].discrimination_reason &&
                                                    <div className="bg-light mt-2 p-2 border rounded">
                                                        <div className="font-medium mb-1 text-dark">
                                                            <Translate id='discrimination.sidePanel.list.groundsSelected' />
                                                        </div>
                                                        <ul className="mb-0 pl-3">
                                                            {this.props.selectedRows[0].discrimination_reason.split(',').map((reason) => (
                                                                <li key={'list' + reason}><Translate id={'discrimination.global.discriminatoryGrounds.' + reason} /></li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                }
                                            </FormGroup>
                                        </Col>
                                    }
                                    <Col sm='6'>
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='members.profile.suspensions.fine' /></div>
                                            <FormikEditable
                                                id='fine'
                                                initialValues={{
                                                    fine: this.props.selectedRows[0].fine ? this.props.selectedRows[0].fine / 100 : '',
                                                }}
                                                validationSchema={object().shape({
                                                    fine: number(),
                                                })}
                                                onSubmit={(values) => {
                                                    const newFine = (values.fine * 100).toFixed(0);
                                                    if(newFine != this.props.selectedRows[0].fine){
                                                        this.setState(() => ({ isLoading: true }))
                                                        const newValues = this.props.createNewValues({ fine: newFine == 0 ? null : newFine })
                                                        this.props.MembersContext.updateMemberSuspensionPartial(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_suspension_id, {
                                                            fine: newFine,
                                                        })
                                                            .then(() => {
                                                                this.props.syncRows(newValues);
                                                                success();
                                                            })
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                }
                                                            })
                                                            .finally(() => this.setState(() => ({ isLoading: false })))
                                                    }
                                                }}
                                            >
                                                {(isEditing, options, formik) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className="font-medium text-dark">
                                                                {this.props.selectedRows[0].fine ?
                                                                    <CurrencyFormat value={this.props.selectedRows[0].fine / 100} />
                                                                    :
                                                                    '-'
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikCurrencyInput id='fine' name='fine' />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    </Col>
                                    <Col sm='6'>
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='members.profile.suspensions.fee' /></div>
                                            <FormikEditable
                                                id='fee'
                                                initialValues={{
                                                    fee: this.props.selectedRows[0].fee ? this.props.selectedRows[0].fee / 100 : '',
                                                }}
                                                validationSchema={object().shape({
                                                    fee: number(),
                                                })}
                                                onSubmit={(values) => {
                                                    const newFee = (values.fee * 100).toFixed(0);
                                                    if(newFee != this.props.selectedRows[0].fee){
                                                        this.setState(() => ({ isLoading: true }))
                                                        const newValues = this.props.createNewValues({ fee: newFee == 0 ? null : newFee })
                                                        this.props.MembersContext.updateMemberSuspensionPartial(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_suspension_id, {
                                                            fee: newFee,
                                                        })
                                                            .then(() => {
                                                                this.props.syncRows(newValues);
                                                                success();
                                                            })
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                }
                                                            })
                                                            .finally(() => this.setState(() => ({ isLoading: false })))
                                                    }
                                                }}
                                            >
                                                {(isEditing, options, formik) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className="font-medium text-dark">
                                                                {this.props.selectedRows[0].fee ?
                                                                    <CurrencyFormat value={this.props.selectedRows[0].fee / 100} />
                                                                    :
                                                                    '-'
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikCurrencyInput id='fee' name='fee' />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    </Col>
                                    {!this.isAdministrative() &&
                                        <>
                                            <Col sm="12">
                                                <div className="mb-3">
                                                    <div className="text-muted"><Translate id='members.profile.suspensions.team' /></div>
                                                    <FormikEditable
                                                        id='team'
                                                        readOnly
                                                        noConfirmation
                                                        initialValues={{
                                                            teamId: this.props.selectedRows[0].team.team_id,
                                                        }}
                                                        validationSchema={object().shape({
                                                            teamId: string().required(<Translate id='members.profile.suspensions.team.required' />),
                                                        })}
                                                        onSubmit={(values) => {
                                                            if(values.teamId !== this.props.selectedRows[0].team.team_id){
                                                                this.setState(() => ({ isLoading: true }))
                                                                const newTeam = this.state.memberTeams.find((mT) => mT.team.team_id === values.teamId)
                                                                const newValues = this.props.createNewValues({ team: {
                                                                    // because everything else in the team is useless, keep it simple
                                                                    name: newTeam?.name,
                                                                    i18n: newTeam?.i18n,
                                                                    team_id: newTeam?.team_id,
                                                                } })
                                                                this.props.MembersContext.updateMemberSuspensionPartial(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_suspension_id, {
                                                                    team_id: values.teamId,
                                                                })
                                                                    .then(() => {
                                                                        this.props.syncRows(newValues);
                                                                        success();
                                                                    })
                                                                    .catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            console.error(error.message)
                                                                            fail({
                                                                                msg: 'misc.error',
                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                skipInfoTranslate: true,
                                                                            })
                                                                        }
                                                                    })
                                                                    .finally(() => this.setState(() => ({ isLoading: false })))
                                                            }
                                                        }}
                                                    >
                                                        {(isEditing, options, formik) => {
                                                            if(!isEditing){
                                                                return (
                                                                    <div className="font-medium text-dark"><DisplayI18n field='name' defaultValue={this.props.selectedRows[0].team.name} i18n={this.props.selectedRows[0].team.i18n} /></div>
                                                                )
                                                            }
                                                            return (
                                                                <FormikSelect
                                                                    id='teamId' name='teamId'
                                                                    renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                                    autoFocus menuIsDefaultOpen
                                                                    searchKeys={[
                                                                        `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                                    ]}
                                                                    onOptionSelected={(values) => {
                                                                        formik.setFieldValue('teamId', values[0], false)
                                                                            .then(options.stopEditing)
                                                                        return true
                                                                    }}
                                                                    isLoading={!this.state.memberTeams}
                                                                    options={this.state.memberTeams ?
                                                                        this.state.memberTeams.reduce((keptMembers, member) => {
                                                                            if(member.active == 1){
                                                                                keptMembers.push({
                                                                                    value: member.team.team_id,
                                                                                    i18n: member.team.i18n,
                                                                                    label: member.team.name,
                                                                                })
                                                                            }
                                                                            return keptMembers;
                                                                        }, [])
                                                                        :
                                                                        []
                                                                    }
                                                                />
                                                            )

                                                        }}
                                                    </FormikEditable>
                                                </div>
                                            </Col>
                                            <Col sm="12">
                                                <div className="mb-3">
                                                    <div className="text-muted"><Translate id='members.profile.suspensions.opposingTeamName' /></div>
                                                    <FormikEditable
                                                        id='opposingTeamName'
                                                        disabled={!canEdit}
                                                        initialValues={{
                                                            opposingTeamName: this.props.selectedRows[0].opposing_team_name || '',
                                                        }}
                                                        validationSchema={object().shape({
                                                            opposingTeamName: string(),
                                                        })}
                                                        onSubmit={(values) => {
                                                            if(values.opposingTeamName !== this.props.selectedRows[0].opposing_team_name || ''){
                                                                this.setState(() => ({ isLoading: true }))
                                                                const newValues = this.props.createNewValues({ opposing_team_name: values.opposingTeamName })
                                                                this.props.MembersContext.updateMemberSuspensionPartial(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_suspension_id, {
                                                                    opposing_team_name: values.opposingTeamName,
                                                                })
                                                                    .then(() => {
                                                                        this.props.syncRows(newValues);
                                                                        success();
                                                                    })
                                                                    .catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            console.error(error.message)
                                                                            fail({
                                                                                msg: 'misc.error',
                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                skipInfoTranslate: true,
                                                                            })
                                                                        }
                                                                    })
                                                                    .finally(() => this.setState(() => ({ isLoading: false })))
                                                            }
                                                        }}
                                                    >
                                                        {(isEditing) => {
                                                            if(!isEditing){
                                                                return (
                                                                    <div className="font-medium text-dark">{this.props.selectedRows[0].opposing_team_name || '-'}</div>
                                                                )
                                                            }
                                                            return (
                                                                <FormikInputText id='opposingTeamName' name='opposingTeamName' trim />
                                                            )

                                                        }}
                                                    </FormikEditable>
                                                </div>
                                            </Col>
                                            <Col sm="6">
                                                <div className="mb-3">
                                                    <div className="text-muted"><Translate id='members.profile.suspensions.position' /></div>
                                                    <FormikEditable
                                                        id='position'
                                                        disabled
                                                        noConfirmation
                                                        initialValues={{
                                                            positionId: this.props.selectedRows[0].position.position_id,
                                                        }}
                                                        validationSchema={object().shape({
                                                            positionId: string().required(<Translate id='members.profile.suspensions.position.required' />),
                                                        })}
                                                        onSubmit={(values) => {
                                                            if(values.positionId !== this.props.selectedRows[0].position.position_id){
                                                                this.setState(() => ({ isLoading: true }))
                                                                const newValues = this.props.createNewValues({ position: this.state.positions.find((p) => p.position_id === values.positionId) })
                                                                this.props.MembersContext.updateMemberSuspensionPartial(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_suspension_id, {
                                                                    position_id: values.positionId,
                                                                })
                                                                    .then(() => {
                                                                        this.props.syncRows(newValues);
                                                                        success();
                                                                    })
                                                                    .catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            console.error(error.message)
                                                                            fail({
                                                                                msg: 'misc.error',
                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                skipInfoTranslate: true,
                                                                            })
                                                                        }
                                                                    })
                                                                    .finally(() => this.setState(() => ({ isLoading: false })))
                                                            }
                                                        }}
                                                    >
                                                        {(isEditing, options) => {
                                                            if(!isEditing){
                                                                return (
                                                                    <div className="font-medium text-dark"><DisplayI18n field='name' defaultValue={this.props.selectedRows[0].position.name} i18n={this.props.selectedRows[0].position.i18n} /></div>
                                                                )
                                                            }
                                                            return (
                                                                <FormikSelect
                                                                    id='positionId' name='positionId'
                                                                    renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                                    autoFocus menuIsDefaultOpen
                                                                    onOptionSelected={() => options.stopEditing()}
                                                                    isLoading={!this.state.positions}
                                                                    searchKeys={[
                                                                        `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                                    ]}
                                                                    options={this.state.positions ?
                                                                        this.state.positions.reduce((keptPos, pos) => {
                                                                            if(pos.active == 1){
                                                                                keptPos.push({
                                                                                    value: pos.position_id,
                                                                                    i18n: pos.i18n,
                                                                                    label: pos.name,
                                                                                })
                                                                            }
                                                                            return keptPos;
                                                                        }, [])
                                                                        :
                                                                        []
                                                                    }
                                                                />
                                                            )

                                                        }}
                                                    </FormikEditable>
                                                </div>
                                            </Col>
                                            <Col sm="12">
                                                <div className="mb-3">
                                                    <div className="text-muted"><Translate id='members.profile.suspensions.suspensionType' /></div>
                                                    <FormikEditable
                                                        id='incidentDate'
                                                        disabled={!canEdit}
                                                        initialValues={{
                                                            duration: this.props.selectedRows[0].duration,
                                                            game_suspended: this.props.selectedRows[0].game_suspended || '',
                                                            end_date: moment(this.props.selectedRows[0].end_date),
                                                        }}
                                                        validationSchema={object().shape({
                                                            duration: string().required(<Translate id='members.profile.suspensions.duration.required' />),
                                                            game_suspended: number().when('duration', {
                                                                is: 'DEFINITE',
                                                                then: number().test({
                                                                    name: 'shouldBeRequired',
                                                                    message: <Translate id='members.profile.suspensions.games.required' />,
                                                                    test: function(games){
                                                                        return !!this.parent.end_date || !!games;
                                                                    },
                                                                }),
                                                            }),
                                                            end_date: mixed().when('duration', {
                                                                is: 'DEFINITE',
                                                                then: mixed()
                                                                    .test({
                                                                        name: 'shouldBeRequired',
                                                                        message: <Translate id='members.profile.suspensions.endDate.required' />,
                                                                        test: function(date){
                                                                            return !!this.parent.game_suspended || !!date;
                                                                        },
                                                                    })
                                                                    .test({
                                                                        name: 'endDateFormatTest',
                                                                        message: <Translate id='form.validation.date.format' />,
                                                                        test: function(date){
                                                                            return !date || moment.isMoment(date);
                                                                        },
                                                                    })
                                                                    .test({
                                                                        name: 'endDateAfterReceivedTest',
                                                                        message: <Translate id='members.profile.suspensions.endDate.afterReceived' />,
                                                                        test: function(date){
                                                                            if(moment.isMoment(date) && this.parent.received_date && moment.isMoment(this.parent.received_date)){
                                                                                return moment(date).isAfter(moment(this.parent.received_date))
                                                                            }
                                                                            return true
                                                                        },
                                                                    }),
                                                            }),
                                                        })}
                                                        onSubmit={(values) => {
                                                            if(values.duration !== this.props.selectedRows[0].duration || values.game_suspended !== this.props.selectedRows[0].game_suspended || values.end_date !== this.props.selectedRows[0].end_date){
                                                                this.setState(() => ({ isLoading: true }))
                                                                const newValues = this.props.createNewValues({
                                                                    duration: values.duration,
                                                                    game_suspended: values.duration === 'DEFINITE' ? values.game_suspended : '',
                                                                    end_date: values.duration === 'DEFINITE' && values.end_date && values.end_date.isValid() ? values.end_date.format('YYYY-MM-DD') : '',
                                                                })
                                                                this.props.MembersContext.updateMemberSuspensionPartial(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_suspension_id, {
                                                                    duration: values.duration,
                                                                    game_suspended: values.duration === 'DEFINITE' ? values.game_suspended.toString() : '',
                                                                    end_date: values.duration === 'DEFINITE' && values.end_date && values.end_date.isValid() ? values.end_date.format('YYYY-MM-DD') : '',
                                                                })
                                                                    .then(() => {
                                                                        this.props.syncRows(newValues);
                                                                        success();
                                                                    })
                                                                    .catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            console.error(error.message)
                                                                            fail({
                                                                                msg: 'misc.error',
                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                skipInfoTranslate: true,
                                                                            })
                                                                        }
                                                                    })
                                                                    .finally(() => this.setState(() => ({ isLoading: false })))
                                                            }
                                                        }}
                                                    >
                                                        {(isEditing, options, formik) => {
                                                            if(!isEditing){
                                                                return (
                                                                    <div>
                                                                        <div className="font-medium text-dark"><Translate id={`members.profile.suspensions.duration.${this.props.selectedRows[0].duration.toLowerCase()}`} /></div>
                                                                        {this.props.selectedRows[0].duration === 'DEFINITE' ?
                                                                            this.props.selectedRows[0].game_suspended ?
                                                                                <>
                                                                                    <Translate id='members.profile.suspensions.duration.games' values={{ games: this.props.selectedRows[0].game_suspended }} />
                                                                                    { this.props.selectedRows[0].end_date &&
                                                                                        <span className='small ml-1'>(<DateFormat value={this.props.selectedRows[0].end_date} utc />)</span>
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <DateFormat value={this.props.selectedRows[0].end_date} utc />
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            return (
                                                                <div>
                                                                    <FormGroup>
                                                                        <FormikSelect
                                                                            name='duration'
                                                                            id='duration'
                                                                            search={false}
                                                                            loadingStatus='success'
                                                                            defaultData={[
                                                                                {
                                                                                    label: 'members.profile.suspensions.duration.definite',
                                                                                    translateLabel: true,
                                                                                    value: 'DEFINITE',
                                                                                },
                                                                                {
                                                                                    label: 'members.profile.suspensions.duration.indefinite',
                                                                                    translateLabel: true,
                                                                                    value: 'INDEFINITE',
                                                                                },
                                                                            ]}
                                                                            onOptionSelected={(option) => {
                                                                                this.setState(() => ({ currentSuspensionDuration: option[0] }))
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                    <Collapse isOpen={this.state.currentSuspensionDuration === 'DEFINITE'}>
                                                                        <Row form>
                                                                            <Col xs="6">
                                                                                <FormGroup className="mb-0 pb-3">
                                                                                    <Label for='game_suspended' className='text-muted'><Translate id='members.profile.suspensions.duration.numberOfGames' /> {!formik.values.end_date && <Required />}</Label>
                                                                                    <FormikInputNumber
                                                                                        id='game_suspended' name='game_suspended'
                                                                                        allowNegative={false}
                                                                                        decimalSeparator={false}
                                                                                        allowLeadingZeros={false}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col xs="6">
                                                                                <FormGroup>
                                                                                    <Label for='end_date' className='text-muted'><Translate id='members.profile.suspensions.endDate' /> {!formik.values.game_suspended && <Required />}</Label>
                                                                                    <FormikDateTime
                                                                                        id='end_date' name='end_date'
                                                                                        timeFormat={false}
                                                                                        dateFormat='YYYY-MM-DD'
                                                                                        isValidDate={(current) => this.props.selectedRows[0].received_date ? moment(current).isAfter(moment(this.props.selectedRows[0].received_date)) : true}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                    </Collapse>
                                                                </div>
                                                            )

                                                        }}
                                                    </FormikEditable>
                                                </div>
                                            </Col>
                                            <Col sm="6">
                                                <div className="mb-3">
                                                    <div className="text-muted"><Translate id='members.profile.suspensions.incidentDate' /></div>
                                                    <FormikEditable
                                                        id='incidentDate'
                                                        disabled={!canEdit}
                                                        initialValues={{
                                                            incidentDate: this.props.selectedRows[0].incident_date ? moment(this.props.selectedRows[0].incident_date) : '',
                                                        }}
                                                        validationSchema={object().shape({
                                                            incidentDate: mixed().required(<Translate id='members.profile.suspensions.incidentDate.required' />).test({
                                                                name: 'incidentDateFormatTest',
                                                                message: <Translate id='form.validation.date.format' />,
                                                                test: moment.isMoment,
                                                            }).test({
                                                                name: 'beforeReceivedDateTest',
                                                                message: <Translate id='members.profile.suspensions.incidentDate.beforeReceived' />,
                                                                test: (date) => {
                                                                    if(moment.isMoment(date)){
                                                                        return moment(date).isBefore(moment(this.props.selectedRows[0].received_date).add(1, 'day'))
                                                                    }
                                                                    return false
                                                                },
                                                            }),
                                                        })}
                                                        onSubmit={(values) => {
                                                            const newDate = moment(values.incidentDate).format('YYYY-MM-DD');
                                                            if(newDate !== this.props.selectedRows[0].incident_date){
                                                                this.setState(() => ({ isLoading: true }))
                                                                const newValues = this.props.createNewValues({ incident_date: newDate })
                                                                this.props.MembersContext.updateMemberSuspensionPartial(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_suspension_id, {
                                                                    incident_date: newDate,
                                                                })
                                                                    .then(() => {
                                                                        this.props.syncRows(newValues);
                                                                        success();
                                                                    })
                                                                    .catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            console.error(error.message)
                                                                            fail({
                                                                                msg: 'misc.error',
                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                skipInfoTranslate: true,
                                                                            })
                                                                        }
                                                                    })
                                                                    .finally(() => this.setState(() => ({ isLoading: false })))
                                                            }
                                                        }}
                                                    >
                                                        {(isEditing) => {
                                                            if(!isEditing){
                                                                return (
                                                                    <div className="font-medium text-dark"><DateFormat value={this.props.selectedRows[0].incident_date} utc /></div>
                                                                )
                                                            }
                                                            return (
                                                                <FormikDateTime
                                                                    id='incidentDate' name='incidentDate'
                                                                    className='date-picker-left'
                                                                    timeFormat={false}
                                                                    dateFormat='YYYY-MM-DD'
                                                                    isValidDate={(current) => moment(current).isBefore(moment(this.props.selectedRows[0].received_date).add(1, 'day'))}
                                                                />
                                                            )

                                                        }}
                                                    </FormikEditable>
                                                </div>
                                            </Col>
                                            <Col sm="6">
                                                <div className="mb-3">
                                                    <div className="text-muted"><Translate id='members.profile.suspensions.location' /></div>
                                                    <FormikEditable
                                                        id='location'
                                                        disabled={!canEdit}
                                                        initialValues={{
                                                            location: this.props.selectedRows[0].location,
                                                        }}
                                                        validationSchema={object().shape({
                                                            location: string().required(<Translate id='members.profile.suspensions.location.required' />),
                                                        })}
                                                        onSubmit={(values) => {
                                                            if(values.location !== this.props.selectedRows[0].location){
                                                                this.setState(() => ({ isLoading: true }))
                                                                const newValues = this.props.createNewValues({ location: values.location })
                                                                this.props.MembersContext.updateMemberSuspensionPartial(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_suspension_id, {
                                                                    location: values.location,
                                                                })
                                                                    .then(() => {
                                                                        this.props.syncRows(newValues);
                                                                        success();
                                                                    })
                                                                    .catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            console.error(error.message)
                                                                            fail({
                                                                                msg: 'misc.error',
                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                skipInfoTranslate: true,
                                                                            })
                                                                        }
                                                                    })
                                                                    .finally(() => this.setState(() => ({ isLoading: false })))
                                                            }
                                                        }}
                                                    >
                                                        {(isEditing) => {
                                                            if(!isEditing){
                                                                return (
                                                                    <div className="font-medium text-dark">{this.props.selectedRows[0].location}</div>
                                                                )
                                                            }
                                                            return (
                                                                <FormikInputText id='location' name='location' trim />
                                                            )

                                                        }}
                                                    </FormikEditable>
                                                </div>
                                            </Col>
                                        </>
                                    }
                                    <Col sm="6">
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='members.profile.suspensions.submitted_by' /></div>
                                            <div className="font-medium text-dark">{`${this.props.selectedRows[0].submitted_by.name} ${this.props.selectedRows[0].submitted_by.family_name}`}</div>
                                        </div>
                                    </Col>
                                    <Col sm="6">
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='members.profile.suspensions.submitted_email' /></div>
                                            <a href={`mailto:${this.props.selectedRows[0].submitted_email}`}>{this.props.selectedRows[0].submitted_email}</a>
                                        </div>
                                    </Col>
                                    <Col sm="12">
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='members.profile.suspensions.notes' /></div>
                                            <FormikEditable
                                                id='notes'
                                                disabled={!canEdit}
                                                initialValues={{
                                                    comment: this.props.selectedRows[0].comment,
                                                }}
                                                validationSchema={object().shape({
                                                    comment: string(),
                                                })}
                                                onSubmit={(values) => {
                                                    if(values.comment !== this.props.selectedRows[0].comment || ''){
                                                        this.setState(() => ({ isLoading: true }))
                                                        const newValues = this.props.createNewValues({ comment: values.comment })
                                                        this.props.MembersContext.updateMemberSuspensionPartial(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_suspension_id, {
                                                            comment: values.comment,
                                                        })
                                                            .then(() => {
                                                                this.props.syncRows(newValues);
                                                                success();
                                                            })
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                }
                                                            })
                                                            .finally(() => this.setState(() => ({ isLoading: false })))
                                                    }
                                                }}
                                            >
                                                {(isEditing) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className="font-medium text-dark">{this.props.selectedRows[0].comment || '-'}</div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikTextArea id='comment' name='comment' rows={3} trim maxLength={255} />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    </Col>
                                    {!this.isAdministrative() &&
                                        <Col sm="12">
                                            <div className="mb-3">
                                                <div className="text-muted"><Translate id='members.profile.suspensions.attachments' /></div>
                                                <FormikEditableFile
                                                    id='attachments'
                                                    name="files"
                                                    initialValues={{
                                                        files: this.props.selectedRows[0].attachments,
                                                    }}
                                                    validationSchema={object().shape({
                                                        files: array().of(mixed()),
                                                    })}
                                                    dropzoneProps={{
                                                        multiple: true,
                                                        accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                                                    }}
                                                    onFileClick={(file) => {
                                                        return this.props.MembersContext.downloadMemberSuspensionAttachment(
                                                            this.props.MembersContext.currentMember.member_id,
                                                            this.props.selectedRows[0].member_suspension_id,
                                                            file.member_suspension_attachment_id,
                                                        ).catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        });
                                                    }}
                                                    onSubmit={(values) => {
                                                        this.setState(() => ({ isLoading: true }))
                                                        this.suspensionAttachmentsOnSubmit(values.files || [])
                                                            .then(() => {
                                                                success({ info: 'components.uploader.fileUpload.toast.success.info' });
                                                                this.setState(() => ({ isLoading: false }));
                                                            })
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message);
                                                                    fail();
                                                                    this.setState(() => ({ isLoading: false }));
                                                                }
                                                            })
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    }
                                </Row>

                                {this.isAdministrative() && this.props.getStatus(this.props.selectedRows[0].duration, this.props.selectedRows[0].end_date).string === 'expired' && canEdit &&
                                    <Button
                                        color='success' type='button'
                                        outline block
                                        onClick={() => {
                                            this.setState(() => ({
                                                activateModalIsOpen: true,
                                            }))
                                        }}
                                    >
                                        <Translate id='members.profile.suspensions.activate.btn' />
                                    </Button>
                                }
                                {this.isAdministrative() && this.props.getStatus(this.props.selectedRows[0].duration, this.props.selectedRows[0].end_date).string === 'active' && canEdit &&
                                    <Button
                                        color='danger' type='button'
                                        outline block
                                        onClick={() => {
                                            this.setState(() => ({ isLoading: true }))
                                            this.props.MembersContext.updateMemberSuspensionPartial(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_suspension_id, {
                                                duration: 'DEFINITE',
                                                end_date: moment().format('YYYY-MM-DD'),
                                            })
                                                .then(() => {
                                                    this.onSuccess({
                                                        duration: 'DEFINITE',
                                                        endDate: moment(),
                                                    })
                                                    this.setState(() => ({ isLoading: false }))
                                                })
                                                .catch((error) => {
                                                    this.setState(() => ({ isLoading: false }))
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                })
                                        }}
                                    >
                                        <Translate id='members.profile.suspensions.deactivate.btn' />
                                    </Button>
                                }
                            </div>
                        </OverlayLoader>
                    )
                }}
            </RolesContext.Consumer>
        )
    }
}

const HandleSuspensionPDFExport = ({ memberSuspensionId }) => {
    const membersContext = useContext(MembersContext);

    const handleSuspensionPDFExport = () => {
        membersContext.getMemberSuspensionLetter(memberSuspensionId)
            .then((suspension_link) => {
                if(suspension_link){
                    triggerDownload(suspension_link)
                }
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    return (
        <SidePanel.MenuAction onClick={handleSuspensionPDFExport}>
            <Translate id='members.profile.suspensions.sidepanel.PDF' />
        </SidePanel.MenuAction>
    )
}

export default withContexts(SuspensionsContext, MembersContext, OrganizationContext, PositionsContext, I18nContext)(MemberSuspensionsSidepanel);