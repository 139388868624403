export default{
    "organization.settings.sidePanelOrganizationPeriods.title" : "Informations de périodes",

    "organization.settings.sidePanelOrganizationPeriods.dates"                  : "Dates",
    "organization.settings.sidePanelOrganizationPeriods.dates.start"            : "Début",
    "organization.settings.sidePanelOrganizationPeriods.dates.end"              : "Fin",
    "organization.settings.sidePanelOrganizationPeriods.startDate"              : "Date de début",
    "organization.settings.sidePanelOrganizationPeriods.startDate.format"       : "La date de début doit être d'un format valide",
    "organization.settings.sidePanelOrganizationPeriods.startDate.required"     : "La date de début est requise",
    "organization.settings.sidePanelOrganizationPeriods.startDate.before"       : "La date de début doit être avant la date de fin",
    "organization.settings.sidePanelOrganizationPeriods.endDate"                : "Date de fin",
    "organization.settings.sidePanelOrganizationPeriods.endDate.format"         : "La date de fin doit être d'un format valide",
    "organization.settings.sidePanelOrganizationPeriods.endDate.required"       : "La date de fin est requise",
    "organization.settings.sidePanelOrganizationPeriods.endDate.after"          : "La date de fin doit être après la date de début",
    "organization.settings.sidePanelOrganizationPeriods.sharedDate"             : "Date partagée",
    "organization.settings.sidePanelOrganizationPeriods.sharedDate.format"      : "La date partagée doit être d'un format valide",
    "organization.settings.sidePanelOrganizationPeriods.calculationDate"        : "Date de calcul",
    "organization.settings.sidePanelOrganizationPeriods.calculationDate.format" : "La date de calcul doit être d'un format valide",
    "organization.settings.sidePanelOrganizationPeriods.status"                 : "Statut",
    "organization.settings.sidePanelOrganizationPeriods.status.active"          : "Actif",
    "organization.settings.sidePanelOrganizationPeriods.status.inactive"        : "Inactif",
    "organization.settings.sidePanelOrganizationPeriods.name.required"          : "Le nom est requis",
    "organization.settings.sidePanelOrganizationPeriods.description.required"   : "La description est requise",
    "organization.settings.sidePanelOrganizationPeriods.status.required"        : "Le Statut est requis",
    "organization.settings.sidePanelOrganizationPeriods.display_order.required" : "L'ordre d'affichage est requis",


    "organization.settings.sidePanelOrganizationPeriods.update.success.text" : "Période mise à jour",
    "organization.settings.sidePanelOrganizationPeriods.update.fail.text"    : "Une erreur est survenue lors de la mise à jour de la période, veuillez rafraichir la page et réessayer",

    // actions
    "organization.settings.sidePanelOrganizationPeriods.actions.remove"         : "Retirer",
    "organization.settings.sidePanelOrganizationPeriods.actions.remove.topText" : "Vous êtes sur le point de retirer cette période de votre organisation",

    // tabs
    "organization.settings.sidePanelOrganizationPeriods.tabs.details" : "Détails",
}