export default{
    "clinics.search.title" : "Stages",

    // top section
    "clinics.search.createClinic"   : "Créer un stage",
    "clinics.search.advancedSearch" : "Recherche avancée",

    // table
    "clinics.search.table.favorite"                            : "Favoris",
    "clinics.search.table.id"                                  : "N{number} de stage",
    "clinics.search.table.qualification"                       : "Qualification",
    "clinics.search.table.season"                              : "Saison",
    "clinics.search.table.status"                              : "Statut",
    "clinics.search.table.startDate"                           : "Date de début",
    "clinics.search.table.location"                            : "Format",
    "clinics.search.table.host"                                : "Organisation hôte",
    "clinics.search.table.clinic_attendee_count"               : "Participants",
    "clinics.search.table.emptyLayout.noResult"                : "Aucun résultat...",
    "clinics.search.table.emptyLayout.noResultTip"             : "Vous pouvez effectuer une recherche ou utiliser la recherche avancée en haut de cette page",
    "clinics.search.table.column.noStartDate"                  : "Pas de date de début",
    "clinics.search.table.column.favorite.favorite"            : "Favoris",
    "clinics.search.table.column.favorite.unfavorite"          : "Défavorisé",
    "clinics.search.table.column.location.ONLINE"              : "En ligne - Sur demande",
    "clinics.search.table.column.location.ONLINE_LIVE"         : "En ligne - En direct",
    "clinics.search.table.column.location.ONSITE"              : "En personne",
    "clinics.search.table.column.location.UNDEFINED"           : "Non défini",
    "clinics.search.table.column.status.OPEN_PUBLIC_SHOW_LIST" : "Ouvert au public",
    "clinics.search.table.column.status.OPEN_PUBLIC_SIGNUP"    : "Inscription ouverte",
    "clinics.search.table.column.status.PRIVATE"               : "Privée",
    "clinics.search.table.column.status.DRAFT"                 : "Brouillon",
    "clinics.search.table.column.status.UNDER_CREATION"        : "En création",
    "clinics.search.table.paginationMessage"                   : "Stages {itemStartPos} à {itemEndPos} sur un total de {totalItems} stages",

    // Tabs
    "clinics.search.tabs.search"  : "Résultat de recherche",
    "clinics.search.tabs.starred" : "Favoris",

    // Calendar filter
    "clinics.search.calendarFilter.today" : "Aujourd'hui",
    "clinics.search.calendarFilter.back"  : "Précédent",
    "clinics.search.calendarFilter.next"  : "Suivant",
    "clinics.search.calendarFilter.month" : "Mois",
    "clinics.search.calendarFilter.week"  : "Semaine",
    "clinics.search.calendarFilter.day"   : "Jour",
}