export default{
    "organization.settings.organizationMemberTypes.table.type"          : "Type",
    "organization.settings.organizationMemberTypes.table.type.SPECIAL"  : "Spécial",
    "organization.settings.organizationMemberTypes.table.active"        : "Actif",
    "organization.settings.organizationMemberTypes.table.empty"         : "Aucun Type de Membre",
    // add
    "organization.settings.organizationMemberTypes.add.title"           : "Ajouter un Type de Membre",
    "organization.settings.organizationMemberTypes.add.name.required"   : "Le nom est requis",
    "organization.settings.organizationMemberTypes.add.status"          : "Statut",
    "organization.settings.organizationMemberTypes.add.status.active"   : "Actif",
    "organization.settings.organizationMemberTypes.add.status.inactive" : "Inactif",
    "organization.settings.organizationMemberTypes.add.status.required" : "Le Statut est requis",
}