import { useContext, useEffect, useRef, useState } from 'react';
import { FormikCurrencyInput, FormikDateTime, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import { Formik, Form } from "formik";
import { Alert, Button, Col, Collapse, Fade, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import Required from '../../../../../../components/formik/Required';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import moment from 'moment';

// Contexts
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { UtilsContext } from '../../../../../../contexts/UtilsContext';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { fail } from '@spordle/toasts';
import AnalyticsModal from '../../../../../../analytics/AnalyticsModal';
import { mixed, number, object, string } from 'yup';
import { formatSelectData } from '@spordle/spordle-select';
import CrossFade from '../../../../../../components/crossFade/CrossFade';

const OnlineStoreTransactionPaymentReceptionModal = ({ isOpen, allPaymentMethods = false, toggle, transaction, onPaymentReception, memberFunding, memberFundingLoading, ...props }) => {
    const membersContext = useContext(MembersContext);
    const utilsContext = useContext(UtilsContext);
    const organizationContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext)
    const formikRef = useRef(null)

    //funding
    const [ selectedFundingAmount, setSelectedFundingAmount ] = useState(0);
    const [ selectedFundingMember, setSelectedFundingMember ] = useState('');

    // credits
    const [ memberCredits, setMemberCredits ] = useState([]);
    const [ selectedCredit, setSelectedCredit ] = useState(null);
    const [ memberCreditLoading, setMemberCreditLoading ] = useState(false)

    // amounts
    const [ amountToReceive, setAmountToReceive ] = useState(0);
    const [ membersAmount, setMembersAmounts ] = useState(null);
    const [ memberMaxAmount, setMemberMaxAmount ] = useState(null);

    // payment methods
    const [ paymentMethods, setPaymentMethods ] = useState(null);
    const [ paymentMethodsLoading, setPaymentMethodsLoading ] = useState(true)

    useEffect(() => {
        if(isOpen){
            membersContext.getInvoiceDues(transaction.invoice_number)
                .then((response) => {
                    const globalAmountToReceive = response?.global_amount_to_receive ?? 0;
                    if(transaction.due != 0){
                        setAmountToReceive(globalAmountToReceive);
                        formikRef.current?.setFieldValue('amount', globalAmountToReceive / 100);
                    }
                    setMembersAmounts(response?.members || null);
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                });

            setPaymentMethodsLoading(true)
            utilsContext.getPaymentMethods(true, true)
                .then(async(_paymentMethods) => {
                    const paymentMethodsOptions = _paymentMethods.filter((method) => {
                        if(transaction.due == 0){
                            return method.code === 'FUNDING';
                        }
                        return method.code !== 'FUNDING';
                    }).reduce((newArray, pM) => {
                        if((pM.category === 'MANUAL' || allPaymentMethods) && pM.active == 1){
                            newArray.push({
                                id: pM.code,
                                value: pM.code,
                                label: pM.name,
                                i18n: pM.i18n,
                            })
                        }
                        return newArray
                    }, []);

                    //if(hasRegistration() || hasClinic()){
                    // Always show member credit option except when amountToReceive is 0, then only display FUNDING option
                    if(transaction.due != 0){
                        paymentMethodsOptions.push({
                            id: 'MEMBER_CREDIT',
                            value: 'MEMBER_CREDIT',
                            label: 'MEMBER_CREDIT',
                        });
                    }
                    await setPaymentMethods(paymentMethodsOptions);
                    setPaymentMethodsLoading(false);
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        setPaymentMethodsLoading(false);
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }
    }, [ isOpen ])

    const getMemberCredits = () => {
        const members = [];
        transaction.invoice_items.forEach((item) => {
            if(!members.find((m) => m.member_id === item.member.member_id))
                members.push(item.member)
        })

        if(members.length > 0){
            if(memberCredits.length == 0){
                setMemberCreditLoading(true)
                Promise.all(members.map((m) => membersContext.getMemberCredits(organizationContext.organisation_id, { member_id: m.member_id })))
                    .then((creditsPromises) => {
                        setMemberCredits(formatSelectData(creditsPromises.flat(), {
                            getLabel: (credit) => credit.isGroup() ? `${credit.member.first_name} ${credit.member.last_name}` : credit.balance,
                            getValue: (credit) => credit.member_credit_id,
                            getGroupId: (credit) => credit.member.member_id,
                            createGroupWithId: (credit, groupId) => ({ label: `${credit.member.first_name} ${credit.member.last_name}` }),
                        }))
                        setMemberCreditLoading(false)
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                            setMemberCreditLoading(false)
                        }
                    })
            }
        }
    }


    const getMaxAmount = () => {
        return memberCredits.find((credit) => credit.member_credit_id === formikRef.current?.values.member_credit_id)?.balance / 100
    }

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='OnlineStoreTransactionPaymentReceptionModal'>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    amount: '',
                    payment_method: '',
                    member_credit_id: '',
                    member_funding: '',
                    received_at: moment(),
                    note: '',
                }}
                validationSchema={object().shape({
                    amount: number().required(<Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.amount.required' />)
                        .positive(<Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.amount.positive' />)
                        .test({
                            name: 'maxAmountCredit',
                            message: <Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.amount.max.credit' />,
                            test: function(amount){
                                if(this.parent.payment_method === 'MEMBER_CREDIT' && this.parent.member_credit_id){
                                    return amount <= getMaxAmount()
                                }
                                return true
                            },
                        }).test({
                            name: 'maxAmountForMember',
                            message: <Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.amount.max.due.member' values={{ maxAmount: <CurrencyFormat value={memberMaxAmount / 100} /> }} />,
                            test: function(amount){
                                if((this.parent.payment_method === 'MEMBER_CREDIT' && this.parent.member_credit_id)){
                                    if(memberMaxAmount !== null){
                                        return amount <= memberMaxAmount
                                    }
                                }
                                return true
                            },
                        }).test({
                            name: 'maxAmountForFunding',
                            message: <Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.amount.max.funding.member' values={{ maxAmount: <CurrencyFormat value={selectedFundingAmount / 100} /> }} />,
                            test: function(amount){
                                if(this.parent.payment_method === 'FUNDING'){
                                    if(memberMaxAmount !== null){
                                        return amount <= selectedFundingAmount / 100
                                    }
                                }
                                return true
                            },
                        }).test({
                            name: 'maxAmountDue',
                            message: <Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.amount.max.due' values={{ maxAmount: <CurrencyFormat value={transaction.due / 100} /> }} />,
                            test: function(amount){
                                if(this.parent.payment_method !== 'FUNDING'){
                                    return amount <= transaction.due / 100
                                }
                                return true;
                            },
                        }),
                    payment_method: string().required(<Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.paymentMethod.required' />),
                    received_at: mixed().required(<Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.date.required' />)
                        .test({
                            name: 'validDateTest',
                            message: <Translate id='form.validation.date.format' />,
                            test: moment.isMoment,
                        }).test({
                            name: 'afterTransactionDateTest',
                            message: <Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.date.after' />,
                            test: (date) => moment(date).isAfter(moment(transaction.invoice_date).subtract(1, 'day')),
                        }),
                    member_credit_id: string().when('payment_method', {
                        is: 'MEMBER_CREDIT',
                        then: string().required(<Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.memberCredit.required' />),
                    }),
                    member_funding: string().when('payment_method', {
                        is: 'FUNDING',
                        then: string().required(<Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.memberFunding.required' />),
                    }),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    membersContext.createPaymentReception(transaction.invoice_number, {
                        ...values,
                        payment_method: values.payment_method === 'MEMBER_CREDIT' ? '' : values.payment_method,
                        amount: Math.round(values.amount * 100),
                        received_at: moment(values.received_at).format('YYYY-MM-DD'),
                        member_id: values.payment_method == 'FUNDING' ? selectedFundingMember : '',
                    })
                        .then((paymentReceptionId) => {
                            // will close the sidepanel and refresh the table, no need to send anything
                            onPaymentReception()
                            if(values.payment_method == 'FUNDING'){
                                props.refreshTable?.();
                                props.getFundingAmounts?.();
                            }
                            setSubmitting(false);
                            toggle?.();
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                setSubmitting(false);
                            }
                        })
                }}
            >
                {(formik) => (
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <Form>
                            <ModalHeader toggle={toggle}>
                                <Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.title' />
                            </ModalHeader>
                            <ModalBody>
                                <Row form>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label for='received_at'><Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.date' /> <Required /></Label>
                                            <FormikDateTime
                                                id='received_at' name='received_at'
                                                timeFormat={false}
                                                isValidDate={(current) => moment(current).isAfter(moment(transaction.invoice_date).subtract(1, 'day'))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label><Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.paymentMethod' /> <Required /></Label>
                                            <FormikSelect
                                                name='payment_method'
                                                id='payment_method_payment_reception_modal'
                                                isLoading={paymentMethodsLoading}
                                                options={paymentMethods}
                                                renderOption={(option) => {
                                                    if(option.option.id === 'MEMBER_CREDIT'){
                                                        return (
                                                            <Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.memberCredit' />
                                                        )
                                                    }
                                                    return (
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={option.option.label}
                                                            i18n={option.option.i18n}
                                                        />
                                                    )
                                                }}
                                                onOptionSelected={(select) => {
                                                    if(select[0] === 'MEMBER_CREDIT'){
                                                        getMemberCredits()
                                                    }
                                                    formik.setFieldValue('member_credit_id', '')
                                                }}
                                                searchKeys={[
                                                    'label',
                                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                                ]}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {formik.values.payment_method === 'FUNDING' &&
                                        <CrossFade isVisible={formik.values.payment_method === 'FUNDING'} tag={Col} sm={6}>
                                            <FormGroup>
                                                <Label for='member_funding_payment_reception_modal'><Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.memberFunding' /> <Required /></Label>
                                                <FormikSelect
                                                    name='member_funding'
                                                    id='member_funding_payment_reception_modal'
                                                    options={memberFunding}
                                                    isLoading={memberFundingLoading}
                                                    search={false}
                                                    renderOption={(option) => {
                                                        return (
                                                            <div>
                                                                <div>{option.option.label}</div>
                                                                <div className='small'><CurrencyFormat value={option.option.fundingValue / 100} /></div>
                                                            </div>
                                                        )
                                                    }}
                                                    onOptionSelected={(funding) => {
                                                        const selectedFunding = memberFunding.find((fund) => funding[0] == fund.value)
                                                        setMemberMaxAmount(selectedFunding.fundingValue);
                                                        setAmountToReceive(selectedFunding.fundingValue);
                                                        setSelectedFundingAmount(selectedFunding.fundingValue);
                                                        setSelectedFundingMember(selectedFunding.memberId)
                                                        // formik.setFieldValue('amount', selectedFunding.fundingValue / 100);
                                                    }}
                                                />
                                            </FormGroup>
                                        </CrossFade>
                                    }
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label for='amount'><Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.amount' /> <Required /></Label>
                                            <FormikCurrencyInput
                                                name="amount" id="amount"
                                                helper={
                                                    formik.values.payment_method === 'MEMBER_CREDIT' ?
                                                        <div className="small text-muted">
                                                            <Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.amount.applicableAmount' />:
                                                            <button type="button" onClick={() => formik.setFieldValue('amount', amountToReceive / 100 > selectedCredit?.label ? selectedCredit?.label : amountToReceive / 100)} className="reset-btn text-primary ml-1">
                                                                <CurrencyFormat
                                                                    value={amountToReceive / 100 > selectedCredit?.label ? selectedCredit?.label : amountToReceive / 100}
                                                                />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="small text-muted">
                                                            <Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.amount.applicableAmount' />:
                                                            <button type="button" onClick={() => formik.setFieldValue('amount', amountToReceive / 100)} className="reset-btn text-primary ml-1">
                                                                <CurrencyFormat
                                                                    value={amountToReceive / 100}
                                                                />
                                                            </button>
                                                        </div>
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    {formik.values.payment_method === 'MEMBER_CREDIT' &&
                                        <Fade in={formik.values.payment_method === 'MEMBER_CREDIT'} tag={Col} sm={6}>
                                            <FormGroup>
                                                <Label for='member_credit_id_payment_reception_modal'><Translate id='onlineStore.profile.transactions.modals.paymentReceptionModal.memberCredit' /> <Required /></Label>
                                                <FormikSelect
                                                    name='member_credit_id'
                                                    id='member_credit_id_payment_reception_modal'
                                                    options={memberCredits}
                                                    isLoading={memberCreditLoading}
                                                    search={false}
                                                    renderOption={(option) => {
                                                        if(option.option.isGroup){
                                                            return option.option.label
                                                        }
                                                        return <CurrencyFormat value={option.option.balance / 100} />

                                                    }}
                                                    onOptionSelected={(credit) => {
                                                        setSelectedCredit(credit[0]);

                                                        const currentlySelectedCredit = memberCredits.find((memberCredit) => memberCredit.member_credit_id === credit[0])
                                                        const selectedCreditAmount = parseInt(currentlySelectedCredit.balance) / 100;

                                                        formik.setFieldValue('amount', amountToReceive / 100 > selectedCreditAmount ? selectedCreditAmount : amountToReceive / 100);
                                                        setMemberMaxAmount(membersAmount.find((m) => m.member_id === currentlySelectedCredit.member.member_id)?.amount_to_receive ?? null);
                                                    }}
                                                />
                                            </FormGroup>
                                        </Fade>
                                    }
                                </Row>
                                <FormGroup>
                                    <FormikTextArea name='note' id='note' trim rows='2' label='onlineStore.profile.transactions.modals.paymentReceptionModal.note' />
                                </FormGroup>

                                <Collapse isOpen={formik.status === 'error'}>
                                    <Alert color='danger' toggle={() => formik.setStatus('')}><Translate id='misc.error' /></Alert>
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button type="submit" color="primary" disabled={formik.isSubmitting || !isOpen}>
                                    <Translate id='misc.confirm' />
                                </Button>
                                <Button type="button" color="primary" onClick={toggle} outline disabled={formik.isSubmitting || !isOpen}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </Form>
                    </OverlayLoader>
                )}
            </Formik>
        </AnalyticsModal>
    )
}

export default OnlineStoreTransactionPaymentReceptionModal;