import attendees from "./attendees";
import communications from "./communications";
import customFormEditor from "./customFormEditor";
import instructors from "./instructors";
import overview from "./overview";
import refund from "./refund";
import waitingList from "./waitingList";
import clinicProfile from "./clinicProfile";

export default{
    ...attendees,
    ...communications,
    ...customFormEditor,
    ...instructors,
    ...overview,
    ...refund,
    ...waitingList,
    ...clinicProfile,
}