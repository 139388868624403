import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Translate from '@spordle/intl-elements';
import moment from 'moment';
import {
    Card,
    CardBody,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledButtonDropdown,
    Badge,
    Spinner,
    UncontrolledDropdown
} from "reactstrap";
import { CSVLink } from 'react-csv';
import SpordleTableProvider, { SpordleTableView, useSpordleTable } from '@spordle/datatables';
import SpordlePanelTable from '../../../../../components/sidePanel/SpordlePanel';
import OnlineStoreRegistrationsSidepanel from './OnlineStoreRegistrationsSidepanel';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import { displayI18n, DisplayI18n } from '../../../../../helpers/i18nHelper';
import { getConfirmCancelled } from "../../../../members/profile/registration/components/Sidepanel/components/ConfirmCancelledBy";
import EmptyLayout from '../../../../../components/table/EmptyLayout';


// Contexts
import withcontexts from '../../../../../helpers/withContexts';
import { OnlineStoreContext } from '../../../../../contexts/OnlineStoreContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { PeriodsContext } from '../../../../../contexts/contexts';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { useIntl } from 'react-intl';
import { ReportsContext } from './../../../../../contexts/ReportsContext';
import { IdentityRolesContext } from './../../../../../contexts/IdentityRolesContext';
import { handleExcelExport } from '../../../../../helpers/reportsHelper';
import { waiverNotSigned } from '../../../../../helpers/waiversHelper';
import OnlineStoreRegistrationFilters from './OnlineStoreRegistrationFilters';
import OnlineStoreRegistrationMultiSidePanel from './OnlineStoreRegistrationMultiSidePanel';

const OnlineStoreRegistrationsList = (props) => {
    const organizationContext = useContext(OrganizationContext);
    const onlineStoreContext = useContext(OnlineStoreContext);
    const periodsContext = useContext(PeriodsContext);
    const i18nContext = useContext(I18nContext);

    const formatData = (invoices) => {
        // invoices
        const formattedInvoices = invoices.reduce((builtArray, invoice) => {
            const creditAmount = invoice.credits.reduce((sum, credit) => sum += parseInt(credit.amount), 0);

            invoice.invoice_items.filter((item) => item.registration_fee !== null).forEach((registrationFee, index) => {
                const mergedWaivers = invoice.invoice_items.reduce((waivers, item) => {
                    if(item.member.member_id === registrationFee.member.member_id){
                        item.waivers.forEach((waiver) => {
                            waivers.push({ ...waiver, invoiceItem: item });
                        })
                    }
                    return waivers;
                }, []);

                builtArray.push({
                    ...invoice,
                    key: invoice.invoice_number + '-' + index,

                    memberRegistration: registrationFee,
                    registrationStatus: getConfirmCancelled(registrationFee),

                    affiliation_fees: invoice.invoice_items.filter((item) => item.affiliation_fee !== null),
                    registrations: invoice.invoice_items.filter((item) => item.registration_fee !== null),
                    otherItems: invoice.invoice_items.filter((item) => item.other_fee !== null),

                    waivers: mergedWaivers,

                    creditAmount: creditAmount,
                    total: parseInt(invoice.total_amount),
                    paid: parseInt(creditAmount) + parseInt(invoice.invoice_items.reduce((sum, item) => sum += parseInt(item.payments.reduce((paymentSum, payment) => paymentSum += parseInt(payment.status === "COMPLETED" ? payment.amount : 0), 0)), 0)),
                    due: invoice.invoice_items.reduce((sum, item) => sum += parseInt(item.payments.reduce((paymentSum, payment) => paymentSum += parseInt((payment.status !== "REFUNDED" && payment.status !== "COMPLETED") ? payment.amount : 0), 0)), 0),
                })
            })
            return builtArray;
        }, []);

        return formattedInvoices;
    }

    return (
        <Card className="card-shadow mt-4">
            <CardBody>
                <div className="h4 font-bold pb-1 border-bottom mb-3 card-title">
                    <Translate id='onlineStore.profile.overview.tabs.registration' />
                </div>
                {!onlineStoreContext.state.cachedStore.invoices &&
                    <div className='text-center'>
                        <Spinner color='primary' type='grow' />
                        <div className='h4'><Translate id='misc.loading' /></div>
                    </div>
                }
                {onlineStoreContext.state.cachedStore.invoices &&
                        <SpordlePanelTable
                            allowOutsideClick
                            dataIndex="key"
                            sidePanel={(sProps) => sProps.selectedRows.length > 1 ?
                                <OnlineStoreRegistrationMultiSidePanel {...props} {...sProps} />
                                :
                                <OnlineStoreRegistrationsSidepanel {...props} {...sProps} />
                            }
                            table={(panelProps) => {
                                return (
                                    <SpordleTableProvider
                                        id='OnlineStoreRegistrationListTable'
                                        key={props.forceRefresh}
                                        tableHover bordered striped
                                        clickable
                                        dataIndex='key'
                                        defaultSorting='-invoice_date'
                                        ref={panelProps.spordleTableRef}
                                        desktopWhen
                                        pagination={20}
                                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                        searchKeys={[
                                            { name: 'invoice_number', weight: 3 },
                                            'memberRegistration.member.unique_identifier',
                                            `memberRegistration.registration_fee.fee.i18n.${i18nContext.getGenericLocale()}.name`,
                                            'memberRegistration.member.last_name',
                                            'memberRegistration.member.first_name',
                                            'invoice_date',
                                            'identity.family_name',
                                            'identity.name',
                                        ]}
                                        loadData={(from, _, spordleTable) => {
                                            switch (from){
                                                case 'REFRESH':
                                                    spordleTable.setLoading()
                                                case 'CDM':
                                                    return onlineStoreContext.getOnlineStoreInvoices({
                                                        organisation_id: organizationContext.organisation_id,
                                                        period_id: onlineStoreContext.state.cachedStore.period.period_id,
                                                        online_store_id: onlineStoreContext.state.cachedStore.online_store_id,
                                                    }, from === 'REFRESH') // Get the invoices loaded in the initial profile load unless we are refreshing the table, then get "fromApi")
                                                        .then((invoices) => {
                                                            from === 'REFRESH' && props.setForceRefresh(); // for transaction tab
                                                            return formatData(invoices);
                                                        })
                                                default:
                                                    break;
                                            }
                                        }}
                                        columns={[
                                            {
                                                label: '-',
                                                key: 'checkbox',
                                                className: 'th-shrink',
                                                dataClassName: 'td-shrink',
                                            },
                                            {
                                                label: <Translate id='memberProfile.registration.form.registrationDate' />,
                                                key: 'invoice_date',
                                                fallbackSortKey: 'invoice_number',
                                                sortable: true,
                                            },
                                            {
                                                label: <Translate id='memberProfile.registration.form.member' />,
                                                key: 'member',
                                                sortable: true,
                                                sortKey: 'memberRegistration.member.last_name',
                                                fallbackSortKey: 'memberRegistration.member.first_name',
                                            },
                                            {
                                                label: <Translate id='memberProfile.registration.form.registration' />,
                                                key: 'category',
                                                sortable: false,
                                            },
                                            // {
                                            //     label: 'Date of Birth',
                                            //     key: 'memberDob',
                                            //     // className: '',
                                            //     // dataClassName: '',
                                            //     sortable: true
                                            // },
                                            // {
                                            //     label: 'New Member',
                                            //     key: 'newMember',
                                            //     // className: '',
                                            //     dataClassName: 'text-center',
                                            //     sortable: true
                                            // },
                                            {
                                                label: <Translate id="memberProfile.registration.form.position" />,
                                                key: 'position',
                                                sortable: false,
                                            },
                                            {
                                                label: <Translate id='memberProfile.registration.form.waivers' />,
                                                key: 'waivers',
                                                sortable: false,
                                            },
                                            {
                                                label: <Translate id='memberProfile.registration.form.status' />,
                                                key: 'status',
                                                sortable: true,
                                                sortKey: 'registrationStatus',
                                            },
                                        ]}
                                        renderRow={(columnKey, registration) => {
                                            switch (columnKey){
                                                case 'checkbox':
                                                    return (
                                                        <label
                                                            className='my-auto'
                                                            htmlFor={'checkbox-' + registration.key}
                                                            onClick={(e) => e.stopPropagation()}
                                                        >
                                                            <input
                                                                onChange={() => panelProps.onMultiSelectChange(registration)}
                                                                id={'checkbox-' + registration.key}
                                                                type='checkbox'
                                                                checked={!!registration.checked}
                                                            />
                                                            <span className='table-checkbox' />
                                                        </label>
                                                    )
                                                case 'invoice_date':
                                                    return (
                                                        <>
                                                            <div className="small text-muted"><Translate id={`memberProfile.registration.form.status.${registration.origin?.toUpperCase()}`} /></div>
                                                            <div className="small">{moment(registration.invoice_date).format('YYYY-MM-DD H:mm')}</div>
                                                            <Link className="text-nowrap" to={`invoice?invoiceNumber=${registration.invoice_number}`}>#{registration.invoice_number}<i className='mdi mdi-chevron-right' /></Link>
                                                        </>
                                                    )
                                                case 'category':
                                                    return (
                                                        <>
                                                            <DisplayI18n
                                                                field='name'
                                                                defaultValue={registration.memberRegistration.registration_fee.fee.name}
                                                                i18n={registration.memberRegistration.registration_fee.fee.i18n}
                                                            />
                                                            <div className="small text-muted">
                                                                <DisplayI18n
                                                                    field='name'
                                                                    defaultValue={periodsContext.selectedPeriod.name}
                                                                    i18n={periodsContext.selectedPeriod.i18n}
                                                                />
                                                            </div>
                                                        </>
                                                    );
                                                case "position":
                                                    const positionItem = registration.memberRegistration.positions[0];

                                                    if(positionItem){
                                                        return (
                                                            <>
                                                                <div>
                                                                    <DisplayI18n field="name" i18n={positionItem.position_group.i18n} defaultValue={positionItem.position_group.name} />
                                                                </div>
                                                                {positionItem.position &&
                                                                    <small className="text-muted">
                                                                        <DisplayI18n field="name" i18n={positionItem.position.i18n} defaultValue={positionItem.position.name} />
                                                                    </small>
                                                                }
                                                            </>
                                                        )
                                                    }

                                                    return "-";
                                                case 'member':
                                                    return (
                                                        <UserDisplay>
                                                            <UserDisplay.Container>
                                                                <UserImg
                                                                    abbr={registration.memberRegistration.member.first_name.substring(0, 1) + registration.memberRegistration.member.last_name.substring(0, 1)}
                                                                    src={undefined}
                                                                    width={30}
                                                                    height={30}
                                                                    alt={registration.memberRegistration.member.first_name + ' ' + registration.memberRegistration.member.last_name}
                                                                />
                                                            </UserDisplay.Container>
                                                            <UserDisplay.Container>
                                                                <UserDisplay.Title>{registration.memberRegistration.member.first_name + ' ' + registration.memberRegistration.member.last_name}</UserDisplay.Title>
                                                                <UserDisplay.Subtitle>#{registration.memberRegistration.member.unique_identifier}</UserDisplay.Subtitle>
                                                            </UserDisplay.Container>
                                                        </UserDisplay>
                                                    )
                                                    // case 'memberDob':
                                                    //     return(
                                                    //         <>
                                                    //             <div className="small ">{data.memberAge} yrs old</div>
                                                    //             <div className="font-medium">{data.memberDob}</div>
                                                    //         </>
                                                    //     )
                                                    // case 'newMember':
                                                    //     return !!data.newMember && <i className="mdi mdi-check text-success"></i>
                                                case 'waivers':
                                                    // if(registration.waivers.length > 0){
                                                    //     const acceptedWaivers = registration.memberRegistration.waivers?.reduce((sum, waiver) => {
                                                    //         if(waiver.answer === "YES")
                                                    //             sum++
                                                    //         return sum
                                                    //     }, 0)

                                                    //     if(acceptedWaivers === 0){
                                                    //         return <span className="font-medium text-danger">{acceptedWaivers} / {registration.waivers.length}</span>
                                                    //     }else if(acceptedWaivers < registration.waivers.length){
                                                    //         return <span className="font-medium text-warning">{acceptedWaivers} / {registration.waivers.length}</span>
                                                    //     }else {
                                                    //         return <span className="font-medium text-success">{acceptedWaivers} / {registration.waivers.length}</span>
                                                    //     }
                                                    // }else{
                                                    //     return <span className="font-medium text-muted">-</span>
                                                    // }

                                                    if(registration.waivers && registration.waivers.length > 0){
                                                        const acceptedWaivers = registration.waivers?.reduce((sum, waiver) => {
                                                            let _sum = sum
                                                            if(waiver.answer !== waiverNotSigned)
                                                                _sum++
                                                            return _sum
                                                        }, 0)
                                                        if(acceptedWaivers === 0){
                                                            return <span className="font-medium text-danger">{acceptedWaivers} / {registration.waivers.length}</span>
                                                        }else if(acceptedWaivers < registration.waivers.length){
                                                            return <span className="font-medium text-warning">{acceptedWaivers} / {registration.waivers.length}</span>
                                                        }
                                                        return <span className="font-medium text-success">{acceptedWaivers} / {registration.waivers.length}</span>

                                                    }
                                                    return <span className="font-medium text-muted">-</span>

                                                case 'status':
                                                    switch (registration.registrationStatus){
                                                        case "CONFIRMED":
                                                            return <Badge color="success"><Translate id='memberProfile.registration.status.confirmed' /></Badge>
                                                        case "PENDING":
                                                            return <Badge color="warning"><Translate id='memberProfile.registration.status.pending' /></Badge>
                                                        case "CANCELLED":
                                                            return <Badge color="danger"><Translate id='memberProfile.registration.status.cancelled' /></Badge>
                                                        default:
                                                            return ''
                                                    }
                                                default:
                                                    break;
                                            }
                                        }}
                                        onColumnClick={(e, attendee, _spordleTable, columnKey) => {
                                            switch (e.button){
                                                case 0: // Left mouse button
                                                    if(columnKey === 'checkbox'){
                                                        panelProps.onMultiSelectChange(attendee);
                                                    }else{
                                                        panelProps.onSingleSelectChange(attendee);
                                                    }
                                                    break;
                                            }
                                        }}
                                        rowIsHighlighted={(data) => data.checked}
                                        emptyLayout={<EmptyLayout hideArrow translateMsg msg='memberProfile.registration.table.emptyLayout.noResult' />}
                                        initFilter={{
                                            status: null,
                                            registrationPackage: null,
                                            paymentMethod: null,
                                        }}
                                        filterJSCallback={(registration, { status, registrationPackage, paymentMethod }) => {
                                            let result = true;
                                            if(status && status !== getConfirmCancelled(registration.memberRegistration)){
                                                result = false;
                                            }
                                            if(registrationPackage && !registrationPackage.includes(registration.memberRegistration.registration_fee.registration_fee_id)){
                                                result = false;
                                            }
                                            if(paymentMethod && !paymentMethod.includes(registration.invoice_payment_method)){
                                                result = false;
                                            }

                                            return result;
                                        }}
                                    >
                                        <div className='mb-2'>
                                            <OnlineStoreRegistrationFilters />
                                            <div className='d-flex flex-wrap justify-content-between'>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle color='primary' className='d-flex align-items-center mr-2' caret>
                                                        <div className='align-self-center custom-checkbox custom-control'>
                                                            <input
                                                                id='topCheckID'
                                                                type='checkbox'
                                                                className='custom-control-input d-none'
                                                                checked={panelProps.topChecked}
                                                                onChange={panelProps.onTopCheckChange}
                                                            />
                                                            <label className='custom-control-label' htmlFor='topCheckID' />
                                                        </div>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={panelProps.checkAll}><Translate id='misc.all' /></DropdownItem>
                                                        <DropdownItem onClick={panelProps.uncheckAll}><Translate id='misc.none' /></DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                                <SpordleTableProvider.SearchInput />
                                                <div className='d-flex ml-auto text-right'>
                                                    <SpordleTableProvider.Refresh />
                                                    <UncontrolledButtonDropdown className='ml-2'>
                                                        <DropdownToggle color='primary' outline caret>
                                                            <i className='fas fa-download' /> <Translate id='misc.export' />
                                                        </DropdownToggle>
                                                        <DropdownMenu right>
                                                            <ToCSV />
                                                            <ToExcel />
                                                            <DropdownItem disabled><i className='fas fa-file-pdf mr-1' /><Translate id='misc.pdf' /></DropdownItem>
                                                            <DropdownItem disabled><i className='fas fa-print mr-1' /><Translate id='misc.print' /></DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <SpordleTableView />
                                    </SpordleTableProvider>
                                )
                            }}
                        />
                }
            </CardBody>
        </Card>
    );
}

const ToExcel = () => {
    const organizationContext = useContext(OrganizationContext);
    const onlineStoreContext = useContext(OnlineStoreContext);
    const reportsContext = useContext(ReportsContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const periodsContext = useContext(PeriodsContext);
    const { getGenericLocale } = useContext(I18nContext);

    const { getData } = useSpordleTable();

    const reportType = 'registration_v2';

    return (
        <DropdownItem
            onClick={() => handleExcelExport(
                () => {},
                reportType,
                { organisation_id: organizationContext.organisation_id, period_id: periodsContext.selectedPeriod.period_id, online_store_id: onlineStoreContext.state.cachedStore.online_store_id },
                getData().length,
                reportsContext,
                identityRolesContext.identity_role_id,
                identityRolesContext.identity.identity_id,
                getGenericLocale(),
                organizationContext.organisation_id,
            )}
        >
            <i className='fas fa-file-excel mr-1' /><Translate id='misc.excel' />
        </DropdownItem>
    )
}

const ToCSV = () => {
    const onlineStoreContext = useContext(OnlineStoreContext);
    const i18nContext = useContext(I18nContext);
    const spordleTable = useSpordleTable();
    const intl = useIntl();

    const getStatus = (status) => {
        switch (status){
            case "COMPLETED":
            case "PAID":
                return intl.formatMessage({ id: 'memberProfile.registration.status.paid' });
            case "PARTIALLY_PAID":
                return intl.formatMessage({ id: 'memberProfile.registration.status.partially_paid' });
            case "PENDING":
                return intl.formatMessage({ id: 'memberProfile.registration.status.pending' });
            case "CANCELED":
                return intl.formatMessage({ id: 'memberProfile.registration.status.cancelled' });
            default:
                return 'N/A';
        }
    }

    const formatData = () => {
        return spordleTable.getData().map((invoice) => {
            return ({
                invoice_date: moment(invoice.invoice_date).format('YYYY-MM-DD H:mm'),
                invoice_number: invoice.invoice_number,
                invoice_payment_method: displayI18n("name",
                                                    invoice.invoice_payment_method?.i18n,
                                                    invoice.invoice_payment_method?.name,
                                                    i18nContext.getGenericLocale()) || '',
                registration: displayI18n("name",
                                          invoice.memberRegistration.registration_fee.fee?.i18n,
                                          invoice.memberRegistration.registration_fee.fee?.name,
                                          i18nContext.getGenericLocale()) || '',
                position_group: displayI18n("name",
                                            invoice.memberRegistration.positions?.[0]?.position_group?.i18n,
                                            invoice.memberRegistration.positions?.[0]?.position_group?.name,
                                            i18nContext.getGenericLocale()) || '',
                position: displayI18n("name",
                                      invoice.memberRegistration.positions?.[0]?.position?.i18n,
                                      invoice.memberRegistration.positions?.[0]?.position?.name,
                                      i18nContext.getGenericLocale()) || '',
                status: getStatus(invoice.memberRegistration.invoice_item_payment_status),
            })
        });
    }

    return (
        <CSVLink
            className={"dropdown-item"} separator=','
            filename={`${displayI18n("name", onlineStoreContext.state.cachedStore.i18n, onlineStoreContext.state.cachedStore.name, i18nContext.getGenericLocale())}-Registrations-${moment().format('YYYY-MM-DD H:mm')}.csv`}
            headers={[
                {
                    label: intl.formatMessage({ id: 'memberProfile.registration.form.registrationDate' }),
                    key: 'invoice_date',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.transactions.memberProfileTransactions.csv.invoiceNumber' }),
                    key: 'invoice_number',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.transactions.memberProfileTransactions.csv.paymentMethod' }),
                    key: 'invoice_payment_method',
                },
                {
                    label: intl.formatMessage({ id: 'memberProfile.registration.form.registration' }),
                    key: 'registration',
                },
                {
                    label: intl.formatMessage({ id: 'memberProfile.registration.form.groupPosition' }),
                    key: 'position_group',
                },
                {
                    label: intl.formatMessage({ id: 'memberProfile.registration.form.position' }),
                    key: 'position',
                },
                {
                    label: intl.formatMessage({ id: 'memberProfile.registration.form.status' }),
                    key: 'status',
                },
            ]}
            data={formatData()}
        >
            <i className='fas fa-file-excel mr-1' />CSV
        </CSVLink>
    )
}

export default withcontexts(OnlineStoreContext)(OnlineStoreRegistrationsList);