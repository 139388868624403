import { fail } from "@spordle/toasts";
import { useContext } from "react";
import useSWR from "swr";
import { AxiosIsCancelled } from "../api/CancellableAPI";
import { OrganizationContext } from "../contexts/OrganizationContext";
import { DisplayI18n } from "./i18nHelper";

const useMissingMandatoryContactTypes = () => {
    const organizationContext = useContext(OrganizationContext)

    const getMissingMandatoryOrgContactTypes = (contactTypes) => {
        const mandatoryContactTypes = contactTypes.filter((contactType) => contactType.mandatory_in_organisation == 1);

        // go grab all the contacts from org state, make an array from all the contacts functions (can be multiple) (functions each contain one contact type),
        // flatten that array to be able to filter easily to check for missing contact types/roles
        const allAssignedContactTypes = organizationContext.state.organizationContacts?.map(({ organisation_contact_functions }) => organisation_contact_functions).flat();

        // find mandatoryContactTypes which that are not
        return mandatoryContactTypes.filter(({ organisation_contact_type_id }) => !allAssignedContactTypes?.some(({ organisation_contact_type }) => organisation_contact_type.organisation_contact_type_id === organisation_contact_type_id));
    }

    return useSWR(
        'getMissingMandatoryRoles',
        () => organizationContext.getOrganizationContactTypes(organizationContext.organisation_id)
            .then((contactTypes) => {
                return getMissingMandatoryOrgContactTypes(contactTypes);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        , {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        },
    );
}

export default useMissingMandatoryContactTypes