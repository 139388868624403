import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { FormsContext } from '../../../../../../contexts/FormsContext';
import { useFormikContext } from 'formik';
import { nakedOption } from '../../CustomFormEditor';
import Translate from '@spordle/intl-elements';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import { fail } from "@spordle/toasts";
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';

/**
 * @description Drop down for question types
 * @param {object} props
 * @param {string} props.id
 * @param {number} props.sectionIndex
 * @param {number} props.questionIndex
 */
const QuestionTypePicker = (props) => {
    /** @type {[import('../../../../../../contexts/FormsContext').FormField[], React.Dispatch<any>]} */
    const [ fieldsType, setFieldsType ] = useState([]);
    const forms = useContext(FormsContext);
    const formik = useFormikContext();
    const question = formik.values.sections[props.sectionIndex].questions[props.questionIndex];

    useEffect(() => {
        forms.getFormFields()
            .then((fields) => { setFieldsType(fields) })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, []);

    return (
        <UncontrolledDropdown className="w-100">
            <DropdownToggle className="w-100 d-flex align-items-center justify-content-between" color='secondary' outline caret>
                <Translate id={`catalog.forms.components.formEditor.questionType.questionTypePicker.${question.formFieldCode}`} />
            </DropdownToggle>
            <DropdownMenu className="w-100">
                {fieldsType.filter((field) => field.active === '1').map((field) => (
                    <DropdownItem
                        key={field.form_field_id}
                        onClick={() => {
                            if(field.with_option === '1'){ // Has options
                                if(question.options.length <= 1){ // Was coming from a no option field type
                                    formik.setFieldValue(`${props.id}.options`, [ nakedOption ]);
                                }
                            }else{ // No options
                                formik.setFieldValue(`${props.id}.options`, []);
                            }

                            formik.setFieldValue(`${props.id}.max`, null, false);
                            formik.setFieldValue(`${props.id}.min`, null, false);

                            formik.setFieldValue(`${props.id}.formFieldCode`, field.code, false);
                        }}
                        active={field.code === question.formFieldCode}
                    >
                        <Translate id={`catalog.forms.components.formEditor.questionType.questionTypePicker.${field.code}`} />
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

QuestionTypePicker.propTypes = {
    id: PropTypes.string.isRequired,
    sectionIndex: PropTypes.number.isRequired,
    questionIndex: PropTypes.number.isRequired,
}

export default QuestionTypePicker;