import { stringBuilder } from "@spordle/helpers";
import { useContext } from "react";
import { InboxContext } from "../inboxContext/InboxContext";
import styles from "./MobileCheckbox.module.scss";

const MobileCheckbox = ({ id, className, name, checked, indeterminate, ...props }) => {
    const { showTools } = useContext(InboxContext);

    return (
        <div
            className={stringBuilder(styles.Container, className, { [styles.Show]: showTools })}
        >
            <label
                className={stringBuilder(
                    "mdi mdi-check",
                    styles.Label,
                    { [styles.Checked]: checked },
                    { [styles.Indeterminate]: indeterminate },
                )}
                htmlFor={id}
            >
                <input type="checkbox" checked={checked} className={styles.Input} name={name} id={id} {...props} />
            </label>
        </div>
    );
}

export default MobileCheckbox;