import Translate from '@spordle/intl-elements';
import querystring from 'query-string';
import React from 'react';
import Sid from '../../../assets/images/logos/Sid_the_Sloth.png';
import { Link } from 'react-router-dom';
import {
    Dropdown,
    DropdownItem, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown
} from 'reactstrap';
import HCLogo from '../../../assets/images/logos/HC@2x.png';
import SpordleLogo from '../../../assets/images/logos/red_s_logo.svg';
import DBSelector from '../../../components/viewHeader/DBSelector';
import { AuthContext } from '../../../contexts/contexts.js';
// Context Providers
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { RolesContext } from '../../../contexts/RolesContext';
import { AppContext } from '../../../contexts/contexts';
// I18N
import Notifications from './notifications/Notifications';
import RolePicker from './RolePicker';

import navigationRoutes from '../../../routes/navigationRoutes.js';

import { stringBuilder } from '@spordle/helpers';
import withContexts from '../../../helpers/withContexts';
import { withTour } from '@reactour/tour'

import { activeRoute, isActiveSidebar, sortRoutesAlphabetically } from '../../../helpers/navigationHelper';
import HelpCenterV2StateWrapper from '../../../components/helpCenter/HelpCenterStateWrapper';
import QuickViewNav from './QuickViewNav';
import { injectIntl } from 'react-intl';
import LangSwitcherNav from './langSwitcherNav/LangSwitcherNav';
import ProfileNav from './profileNav/ProfileNav';
import GodToolsNav from './godToolsNav/GodToolsNav';
import MessagesNav from './messagesNav/MessagesNav';
import NoticesNav from './noticesNav/NoticesNav';
import EnvFeatureFlag from '../../../components/featureFlag/EnvFeatureFlag';
import EnvBanner from '../../../components/EnvBanner';
import ChecklistNav from '../../../views/tasks/checklists/components/ChecklistNav.jsx';
import HeaderApiRoutes from './HeaderApiRoutes';

class Header extends React.Component{
    constructor(props){
        super(props);
        this.toggle = this.toggle.bind(this);
        this.searchtoggle = this.searchtoggle.bind(this);
        this.showMobilemenu = this.showMobilemenu.bind(this);
        this.sidebarHandler = this.sidebarHandler.bind(this);
        this.menuRef = React.createRef();
        this.moreRef = React.createRef();
        this.headerRef = React.createRef();
        this.menuLinksRef = React.createRef();
        this.state = {
            isOpen: false,
            collapse: false,
            lastMoreIndex: 0,
            moreWidths: [],
            moreIsOpen: false,
        };
    }

    /*--------------------------------------------------------------------------------*/
    /* To open NAVBAR in MOBILE VIEW                                                   */
    /*--------------------------------------------------------------------------------*/
    toggle(){
        this.setState((state) => ({
            isOpen: !state.isOpen,
        }));
    }

    searchtoggle(){
        this.setState((state) => ({
            collapse: !state.collapse,
        }))
    }

    /*--------------------------------------------------------------------------------*/
    /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
    /*--------------------------------------------------------------------------------*/
    showMobilemenu(){
        document.getElementById('main-wrapper').classList.toggle('show-sidebar');
    }

    sidebarHandler = () => {
        const element = document.getElementById('main-wrapper');
        const activeSidebarType = "full";

        switch (activeSidebarType){
            case 'full':
            case 'iconbar':
                element.classList.toggle('mini-sidebar');
                if(element.classList.contains('mini-sidebar')){
                    element.setAttribute('data-sidebartype', 'mini-sidebar');
                }else{
                    element.setAttribute(
                        'data-sidebartype',
                        activeSidebarType,
                    );
                }
                break;

            case 'overlay':
            case 'mini-sidebar':
                element.classList.toggle('full');
                if(element.classList.contains('full')){
                    element.setAttribute('data-sidebartype', 'full');
                }else{
                    element.setAttribute(
                        'data-sidebartype',
                        activeSidebarType,
                    );
                }
                break;
            default:
        }
    };

    /*--------------------------------------------------------------------------------*/
    /* Grouped Routes & Collapse in sidebar                                           */
    /*--------------------------------------------------------------------------------*/
    hasGroupedRoutes = (routes) => {
        return routes?.some((r) => r.navlabel)
    }

    componentDidMount(){
        this.onResize();
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        const parentWidth = this.headerRef.current.offsetWidth;
        const children = Array.from(this.menuRef.current.childNodes);

        if(children?.length > 0){
            const totalWidth = children.reduce((totalWidth, child) => totalWidth += child.offsetWidth, 0);

            if(totalWidth > parentWidth){
                const visibleLinks = Array.from(this.menuLinksRef.current.querySelectorAll('li.sid-nav-item.movable'));

                if(visibleLinks.length > 0){
                    const lastChildWidth = visibleLinks[visibleLinks.length - 1].offsetWidth;
                    const nextIndex = Math.min(this.state.lastMoreIndex + 1, navigationRoutes.length - 1);

                    this.setState((prev) => ({
                        ...prev,
                        lastMoreIndex: nextIndex,
                        moreWidths: [ ...prev.moreWidths, ...lastChildWidth ? [ lastChildWidth ] : [] ],
                    }), () => {
                        if(totalWidth - lastChildWidth > parentWidth && nextIndex < navigationRoutes.length - 1){
                            this.onResize();
                        }
                    });

                }
            }else if(this.state.lastMoreIndex > 0){
                const childWidth = this.state.moreWidths[this.state.lastMoreIndex - 1];
                const nextIndex = Math.max(this.state.lastMoreIndex - 1, 0);
                const buffer = 10;


                if(totalWidth + childWidth + buffer < parentWidth){
                    this.setState((prev) => ({
                        ...prev,
                        moreWidths: prev.moreWidths.slice(0, -1),
                        lastMoreIndex: nextIndex,
                    }), () => {
                        if(nextIndex > 0){
                            const nextChildW = this.state.moreWidths[nextIndex - 1];

                            if(totalWidth + nextChildW + buffer < parentWidth){
                                this.onResize();
                            }
                        }
                    });

                }
            }
        }
    }

    isRenderableLink = (route) => {
        return !(route.hiddenRoute || route.hiddenRouteDesktop || route.redirect || ((route.devOnly && process.env.REACT_APP_ENVIRONMENT === 'prod')));
    }

    renderRoute = (prop, key, isInMore = false) => {
        if(!this.isRenderableLink(prop)){
            return null;
        }

        const showFor = this.props.RolesContext.validateRouteAccess(prop);
        const hasGroupedRoutes = this.hasGroupedRoutes(prop.subPermissionsRoutes);

        if(showFor){
            const sortRoutes = (info) => {
                return info.sortAlphabetical ? sortRoutesAlphabetically(info.subPermissionsRoutes || [], this.props.intl.formatMessage) : info.subPermissionsRoutes || []
            };
            const dropdownRoutes = sortRoutes(prop);

            return (
                <UncontrolledDropdown tag={isInMore ? "div" : "li"} nav className={stringBuilder(`sid-nav-item movable`, { "mr-0": isInMore })} inNavbar key={prop.name}>
                    <EnvFeatureFlag env={prop.envFeatureFlag}>
                        <DropdownToggle nav className={stringBuilder(`sid-nav-link ${"header-link-" + key}`, prop.tourCSSClass, { "w-100 justify-content-between text-left rounded-0": isInMore, "is-active": isActiveSidebar(prop.subPermissionsRoutes) })}>
                            <RouteName route={prop} showFor={showFor} />
                            <i className={`fa fa-chevron-${isInMore ? "right" : "down"} ml-1 sid-nav-chevron`} />
                        </DropdownToggle>
                    </EnvFeatureFlag>

                    <DropdownMenu
                        style={isInMore ? { top: 0, left: "100%" } : {}}
                        className={stringBuilder("mt-0 tour-all-dropdowns", { [prop.tourCSSClass + '-dropdown']: prop.tourCSSClass })}
                    >
                        {hasGroupedRoutes ?
                            dropdownRoutes.map((group) => {
                                const showSubFor = this.props.RolesContext.validateRouteAccess(group);
                                if(group.api){
                                    return (
                                        <HeaderApiRoutes
                                            key={group.name}
                                            navGroup={group}
                                            label={
                                                <DropdownItem header disabled>
                                                    <RouteName route={group} showFor={showSubFor} />
                                                </DropdownItem>
                                            }
                                            ItemComponent={({ route }) => (
                                                <DropdownItem onClick={() => this.setState((prev) => ({ ...prev, moreIsOpen: false }))} key={route.path + route.name} className={stringBuilder({ 'selected': route.isActive })} disabled={route.disabled} tag={Link} to={route.path}>
                                                    <RouteName route={route} showFor={showSubFor} />
                                                </DropdownItem>
                                            )}
                                        />
                                    )
                                }
                                const subRoutes = sortRoutes(group);

                                return showSubFor && this.isRenderableLink(group) && (
                                    <React.Fragment key={group.name}>
                                        <EnvFeatureFlag env={group.envFeatureFlag}>
                                            <DropdownItem header disabled>
                                                <RouteName route={group} showFor={showSubFor} />
                                            </DropdownItem>
                                        </EnvFeatureFlag>
                                        {subRoutes.map((route) => {
                                            const showSubFor = this.props.RolesContext.validateRouteAccess(route);

                                            return showSubFor && this.isRenderableLink(route) && (
                                                <EnvFeatureFlag key={route.path + (route.name)} env={route.envFeatureFlag}>
                                                    <DropdownItem onClick={() => this.setState((prev) => ({ ...prev, moreIsOpen: false }))} key={route.path + (route.name)} className={stringBuilder({ 'selected': activeRoute(route.path) })} disabled={route.disabled} tag={Link} to={route.path}>
                                                        <RouteName route={route} showFor={showSubFor} />
                                                    </DropdownItem>
                                                </EnvFeatureFlag>
                                            )
                                        })}
                                    </React.Fragment>
                                )
                            })
                            :
                            dropdownRoutes.map((route) => {
                                const showSubFor = this.props.RolesContext.validateRouteAccess(route);

                                if(showSubFor && this.isRenderableLink(route)){
                                    return (
                                        route.navlabel ?
                                            <EnvFeatureFlag env={route.envFeatureFlag} key={route.path + (route.name)}>
                                                <DropdownItem
                                                    onClick={() => this.setState((prev) => ({ ...prev, moreIsOpen: false }))}
                                                    header
                                                    key={route.path + (route.name)}
                                                    disabled
                                                >
                                                    <RouteName route={route} showFor={showSubFor} />
                                                </DropdownItem>
                                            </EnvFeatureFlag>
                                            :
                                            <EnvFeatureFlag env={route.envFeatureFlag} key={route.path + route.name}>
                                                <DropdownItem
                                                    onClick={() => this.setState((prev) => ({ ...prev, moreIsOpen: false }))}
                                                    key={route.path + route.name}
                                                    className={stringBuilder({ 'selected': activeRoute(route.path) })}
                                                    disabled={route.disabled}
                                                    tag={Link}
                                                    to={route.path}
                                                >
                                                    <RouteName route={route} showFor={showSubFor} />
                                                </DropdownItem>
                                            </EnvFeatureFlag>
                                    )
                                }

                                return null;
                            })
                        }
                    </DropdownMenu>
                </UncontrolledDropdown>
            );
        }
        return null;
    }

    getLogo = (isPrank) => {
        if(isPrank){
            return (
                <img
                    src={Sid}
                    alt="Ice Age Sid"
                    className="mw-100 light-logo"
                    width="60"
                />
            )
        }else if(process.env.REACT_APP_ENVIRONMENT === 'prod' && process.env.REACT_APP_VERSION_CLIENT === 'EIHA'){
            return (
                <img
                    src='/eiha/EIHA.png'
                    alt="EIHA"
                    className="mw-100 light-logo"
                    width="40"
                />
            )
        }else if(process.env.REACT_APP_ENVIRONMENT === 'int' || process.env.REACT_APP_ENVIRONMENT === 'stage'){
            return (
                <img
                    src={SpordleLogo}
                    alt="Spordle"
                    className="mw-100 light-logo"
                    width="40"
                />
            )
        }

        return (
            <img
                src={HCLogo}
                alt="Federation  Logo"
                className="mw-100 light-logo"
                width="60"
            />
        )
    }

    render(){
        return (
            <AuthContext.Consumer>
                {({ accessToken, isPrank }) => (
                    <>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Top Header Menu                                                                */}
                        {/*--------------------------------------------------------------------------------*/}
                        <header ref={this.headerRef} className="topbar navbarbg sid-top-bar" data-navbarbg="skin6" style={{ zIndex: 1032 }}>
                            <EnvBanner />
                            <nav ref={this.menuRef} className="top-navbar navbar-light navbar navbar-expand-md sid-nav-bar">
                                <ul ref={this.menuLinksRef} className="navbar-nav flex-shrink-0">
                                    <li className="sid-nav-item-logo" id="logobg" data-logobg="skin6">
                                        <Link to='/dashboard'>
                                            {this.getLogo(isPrank())}
                                        </Link>
                                    </li>
                                    <li className="nav-item sid-nav-item org-navbar-tour-class">
                                        <Link to='/dashboard' className={stringBuilder('sid-nav-link sid-nav-dashboard nav-link', { "is-active is-dashboard": this.props.location.pathname === "/dashboard" })}>
                                            <span className="sid-nav-dashboard-icon">
                                                <i className="sid-nav-dashboard-icon-top-squares" />
                                                <i className='sid-nav-dashboard-icon-bottom-squares' />
                                            </span>
                                        </Link>
                                    </li>
                                    {navigationRoutes.reduce((navs, route, index) => {
                                        if(index < navigationRoutes.length - 1 - this.state.lastMoreIndex){
                                            navs.push(this.renderRoute(route, index));
                                        }
                                        return navs;
                                    }, [])}
                                    <Dropdown
                                        isOpen={this.state.moreIsOpen}
                                        nav
                                        toggle={() => this.setState((prev) => ({ ...prev, moreIsOpen: !prev.moreIsOpen }))}
                                        inNavbar
                                        className={stringBuilder({ "d-none": this.state.lastMoreIndex === 0 })}
                                        id="more"
                                    >
                                        <DropdownToggle nav className="sid-nav-link">
                                            <span><Translate id="header.more" /></span>
                                            <i className='fa fa-chevron-down ml-1 sid-nav-chevron' />
                                        </DropdownToggle>
                                        <DropdownMenu className="text-left">
                                            <div ref={this.moreRef}>
                                                {navigationRoutes.reduce((navs, route, index) => {
                                                    if(index >= navigationRoutes.length - 1 - this.state.lastMoreIndex){
                                                        navs.push(this.renderRoute(route, index, true));
                                                    }
                                                    return navs;
                                                }, [])}
                                            </div>
                                        </DropdownMenu>
                                    </Dropdown>
                                </ul>


                                <Nav className="ml-auto flex-shrink-0" navbar>
                                    {(process.env.REACT_APP_ENVIRONMENT === 'int' || process.env.REACT_APP_ENVIRONMENT === 'dev') &&
                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav className="text-danger"><i className="mdi mdi-worker font-18" /></DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem tag={Link} to='/review' className="text-danger">
                                                    <span>Lang Review</span>
                                                </DropdownItem>
                                                <DropdownItem tag={Link} to='/templateui' className="text-danger">
                                                    <span>UI Template</span>
                                                </DropdownItem>
                                                <DropdownItem tag={Link} to='/spordletable-fun' className="text-danger">
                                                    <span>SpordleTable playground</span>
                                                </DropdownItem>
                                                <IdentityRolesContext.Consumer>
                                                    {({ identity_role_id }) => (
                                                        <DropdownItem
                                                            tag={Link} className="text-danger"
                                                            to={{
                                                                pathname: '/test-api',
                                                                search: `?${querystring.stringify({
                                                                    headers: JSON.stringify({
                                                                        'X-Identity-Role': identity_role_id,
                                                                        'X-Access-Token': accessToken,
                                                                    }, undefined, 4),
                                                                })}`,
                                                            }}
                                                        >
                                                            <span>API Tester</span>
                                                        </DropdownItem>
                                                    )}
                                                </IdentityRolesContext.Consumer>
                                                <DropdownItem tag={Link} to='/context-tester' className="text-danger">
                                                    <span>Context Tester</span>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    }

                                    <IdentityRolesContext.Consumer>
                                        {({ wasGod }) => wasGod &&
                                            <>
                                                <DBSelector />
                                                <RolePicker />
                                                <GodToolsNav />
                                            </>
                                        }
                                    </IdentityRolesContext.Consumer>

                                    <ChecklistNav />
                                    <NoticesNav />
                                    <QuickViewNav />
                                    <MessagesNav />
                                    <Notifications />
                                    <HelpCenterV2StateWrapper />
                                    <LangSwitcherNav />
                                    <ProfileNav />
                                </Nav>

                            </nav>
                        </header>
                    </>
                )}
            </AuthContext.Consumer>
        );
    }
}

const RouteName = ({ route, showFor }) => (
    <span className={stringBuilder({ 'text-purple': showFor === 'ADMIN' })}>
        {route.skipTranslate ? route.name : <Translate id={route.name} />}
    </span>
)

export default injectIntl(withTour(withContexts(OrganizationContext, AppContext, RolesContext)(Header)));
