import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    Card,
    CardBody,
    Button,
    Alert,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import SpordlePanelTable from '../../../../../components/sidePanel/SpordlePanel';
import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from '@spordle/datatables';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import TeamActiveRosterSidepanel from './TeamActiveRosterSidepanel';
import TeamRosterAdd from '../TeamrosterAdd/TeamRosterAdd';
import { getTeamMemberStatus } from '../../../TeamHelpers';
import { useIntl } from 'react-intl';
import { displayI18n, DisplayI18n } from '../../../../../helpers/i18nHelper';
import EmptyLayout from '../../../../../components/table/EmptyLayout';
import HasAccessTo from '../../../../../components/permissions/HasAccessTo';

// contexts
import { I18nContext } from '../../../../../contexts/I18nContext';
import CanDoAction from '../../../../../components/permissions/CanDoAction';
import MemberTeamStatus from '../../../../members/profile/teams/MemberTeamStatus';
import { RolesContext } from '../../../../../contexts/RolesContext';
import moment from 'moment';
import MemberStatusIcons from '../../../../members/search/components/MemberStatusIcons';
import CrossFade from '../../../../../components/crossFade/CrossFade';
import { TeamsContext } from '../../../../../contexts/TeamsContext';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';
import { stringBuilder } from '@spordle/helpers';
import { useDisclosure } from '@mantine/hooks';
import SendCommModal from '../../../../../components/communications/SendCommModal';
import TeamMemberAge from '../components/TeamMemberAge';
import TeamRosterTableHeader from '../components/TeamRosterTableHeader';
import GenderLabel from '../../../../../components/GenderLabel';

const TeamActiveRoster = ({ teamId, playerLimit, uniqueJerseySetting, lockedRoster, lockedRosterDate }) => {
    const intl = useIntl();
    const spordleTableRef = useRef(null);
    const history = useHistory();
    const i18nContext = useContext(I18nContext);
    const rolesContext = useContext(RolesContext);
    const teamsContext = useContext(TeamsContext)
    const { isGod } = useContext(IdentityRolesContext);
    const canEditJersey = !lockedRoster || rolesContext.canDoAction('EDIT', 'teams', 'bypass_lock_roster_date');
    const canAddToRoster = !lockedRoster || rolesContext.canDoAction('ADD', 'teams', 'bypass_lock_roster_date') || isGod();
    const canSendComm = rolesContext.canDoAction("ADD", "communications", "communications_manage");

    const [ sendCommIsOpen, sendCommIsOpenHandlers ] = useDisclosure(false);
    const [ addModal, setAddModal ] = useState(false)
    const toggleAddModal = () => setAddModal(!addModal)

    const [ isOpen, isOpenHandlers ] = useDisclosure(false)

    const getJerseyNumber = (numberHome, numberAway) => {
        // if away is null, it means we have only 1 jersey number for both away and home
        if(numberHome){
            return (
                <div className='text-center'>
                    <div>
                        <span className=""><Translate id='teams.profile.roster.table.jerseyNumber.home' /> : </span>
                        <span className="font-medium text-dark">{numberHome}</span>
                    </div>
                    <div>
                        <span className=""><Translate id='teams.profile.roster.table.jerseyNumber.away' /> : </span>
                        <span className="font-medium text-dark">{numberAway || numberHome}</span>
                    </div>
                </div>
            )
        }
        return '';
    }

    const goToEditRoster = () => {
        teamsContext.getRosterTablesData(teamId, null, true);
        history.push('edit');
    }

    useEffect(() => {
        if(teamsContext.rosterTablesLoading){
            spordleTableRef.current?.setLoading();
        }
    }, [ teamsContext.rosterTablesLoading ])

    useEffect(() => {
        if(teamsContext.rosterTablesDataError){
            spordleTableRef.current?.setError();
        }
    }, [ teamsContext.rosterTablesDataError ])

    useEffect(() => {
        if(teamsContext.rosterTablesData){
            spordleTableRef.current?.setData(teamsContext.formatDataActive(teamsContext.rosterTablesData, intl), 'success')
        }
    }, [ teamsContext.rosterTablesData ])

    const displayLockNotice = (alert = false) => {
        const displayAlert = (text) => (
            <Alert color="secondary">
                <div><i className="w-100 mr-1 mdi mdi-lock-outline" />{text}</div>
                {(rolesContext.canDoAction('ADD', 'teams', 'bypass_lock_roster_date') || isGod()) &&
                    <div className='small text-muted'>
                        <i className='mdi mdi-star text-warning' /> <Translate id='teams.profile.roster.lock.bypass' />
                    </div>
                }
            </Alert>
        )
        const displayLabel = (text) => <div className='font-medium small text-muted'><i className="mr-1 mdi mdi-lock-open-outline" />{text}</div>

        if(!lockedRoster && lockedRosterDate){ // show date
            if(alert){
                return displayAlert(<><Translate id="teams.profile.roster.lockdate" />: {moment(lockedRosterDate).format('lll')}</>)
            }
            return displayLabel(<><Translate id="teams.profile.roster.lockdate" />: {moment(lockedRosterDate).format('lll')}</>)
        }else if(lockedRoster){
            if(alert){
                return displayAlert(<Translate id="teams.profile.roster.locked" />)
            }
            return displayLabel(<Translate id="teams.profile.roster.locked" />)
        }
    }

    const displayCount = (spordleTable) => {
        return (
            <span className='ml-2'>
                (
                {spordleTable.getData().length}
                {(!!teamsContext.filters && Object.keys(teamsContext.filters)?.length !== 0) &&
                    ' / ' + teamsContext.formatDataActive(teamsContext.completeRoster, intl).length
                }
                )
            </span>
        )
    }

    return (
        <Card className={stringBuilder("card-shadow", { "clickable": !isOpen })} onClick={() => !isOpen ? isOpenHandlers.toggle() : null}>
            {/* loading for when the card is collapsed */}
            <OverlayLoader isLoading={(teamsContext.rosterTablesLoading && !isOpen)}>
                <CardBody>
                    <SpordlePanelTable
                        allowOutsideClick
                        sidePanel={(props) => <TeamActiveRosterSidepanel lockedRoster={lockedRoster} uniqueJerseySetting={uniqueJerseySetting} {...props} />}
                        dataIndex='team_member_id'
                        table={(panelProps) => {
                            return (
                                <SpordleTableProvider
                                    tableHover bordered striped
                                    clickable
                                    id='teamActiveRoster'
                                    ref={(r) => { spordleTableRef.current = r; panelProps.spordleTableRef(r) }}
                                    desktopWhen
                                    dataIndex='team_member_id'
                                    emptyLayout={<EmptyLayout />}
                                    searchKeys={[
                                        { name: 'member.unique_identifier', weight: 2 },
                                        'name',
                                        'member.last_name',
                                        'member.first_name',
                                        'option',
                                        `position.i18n.${i18nContext.getGenericLocale()}.name`,
                                        'position.name',
                                        `team_member_status.i18n.${i18nContext.getGenericLocale()}.name`,
                                        'team_member_status.name',
                                        'registration_date',
                                        'jersey_number',
                                        'jersey_number_alternate',
                                    ]}
                                    // paginationMessage='members.search.memberSearch.table.pagination.message'
                                    tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                    loadData={(from, data, spordleTable) => {
                                        switch (from){
                                            case 'REFRESH':
                                                teamsContext.getRosterTablesData(teamId)
                                            default:
                                                break;
                                        }
                                    }}
                                    columnSorting={(teamMemberA, teamMemberB, _spordleTable, order) => {
                                        switch (order.column){
                                            case 'position':
                                                const valueAPosition = displayI18n('name', teamMemberA.position.i18n, teamMemberA.position.name, i18nContext.getGenericLocale())
                                                const valueBPosition = displayI18n('name', teamMemberB.position.i18n, teamMemberB.position.name, i18nContext.getGenericLocale())
                                                return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueAPosition, valueBPosition) * (order.order === 'ASC' ? 1 : -1);
                                            case 'playerStatus':
                                                const valueAPlayerStatus = displayI18n('name', teamMemberA.team_member_status?.i18n, teamMemberA.team_member_status?.name, i18nContext.getGenericLocale())
                                                const valueBPlayerStatus = displayI18n('name', teamMemberB.team_member_status?.i18n, teamMemberB.team_member_status?.name, i18nContext.getGenericLocale())
                                                return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueAPlayerStatus, valueBPlayerStatus) * (order.order === 'ASC' ? 1 : -1);
                                        }
                                    }}
                                    defaultSorting='+name'
                                    columns={[
                                        {
                                            label: <Translate id='teams.profile.roster.table.addedDate' />,
                                            key: 'addedDate',
                                            className: 'th-shrink',
                                            dataClassName: 'td-shrink',
                                            sortKey: 'registration_date',
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='form.fields.name' />,
                                            key: 'name',
                                            sortable: true,
                                            sortKey: 'member.last_name',
                                        },
                                        {
                                            label: <Translate id='form.fields.dateOfBirth' />,
                                            key: 'age',
                                            sortKey: 'member.birthdate',
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='teams.profile.roster.table.position' />,
                                            key: 'position',
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='teams.profile.roster.table.jerseyNumber' />,
                                            key: 'jerseyNumber',
                                        },
                                        {
                                            label: <Translate id='teams.profile.roster.table.playerStatus' />,
                                            key: 'playerStatus',
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='teams.profile.roster.table.status' />,
                                            key: 'status',
                                            sortable: true,
                                        },
                                    ]}
                                    renderRow={(columnKey, teamMember) => {
                                        switch (columnKey){
                                            case 'addedDate':
                                                return (
                                                    teamMember.registration_date ?
                                                        <DateFormat value={teamMember.registration_date} />
                                                        : '-'
                                                )
                                            case 'name':
                                                return (
                                                    <UserDisplay>
                                                        <UserDisplay.Container>
                                                            <UserImg
                                                                abbr={teamMember.member.first_name.charAt(0) + teamMember.member.last_name.charAt(0)}
                                                                src={teamMember.member.picture?.full_path}
                                                                filePos={teamMember.member.picture?.file_position}
                                                                width={40}
                                                                alt={teamMember.member.first_name + ' ' + teamMember.member.last_name}
                                                            />
                                                        </UserDisplay.Container>
                                                        <UserDisplay.Container>
                                                            <UserDisplay.Title>
                                                                <div className='d-flex'>
                                                                    {teamMember.member.first_name + ' ' + teamMember.member.last_name}
                                                                    {/* {(teamMember.crc_is_expired == 1) && // if criminal record check is expired or non existant
                                                                        <HasAccessTo className='ml-1' componentCode='members' componentPermissionCode='member_crc'>
                                                                            <UserDisplay.Container className="ml-auto">
                                                                                <img src={CRC_Red} alt="Criminal Record Check Required" height='20'/>
                                                                            </UserDisplay.Container>
                                                                        </HasAccessTo>
                                                                    } */}
                                                                </div>
                                                            </UserDisplay.Title>
                                                            <UserDisplay.Subtitle>
                                                                <HasAccessTo componentCode='members' componentPermissionCode='members_profile'>
                                                                    <Link className="text-nowrap" to={"/members/profile/" + teamMember.member.member_id}>#{teamMember.member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></Link>
                                                                </HasAccessTo>
                                                            </UserDisplay.Subtitle>
                                                            <UserDisplay.Subtitle>
                                                                <GenderLabel gender={teamMember.member.gender} />
                                                            </UserDisplay.Subtitle>
                                                        </UserDisplay.Container>
                                                        <UserDisplay.Container className="ml-auto">
                                                            {/* crc_is_expired 0 because we don't want to show that here */}
                                                            <MemberStatusIcons member={{ ...teamMember.member, crc_is_expired: 0 }} />
                                                        </UserDisplay.Container>
                                                    </UserDisplay>
                                                )
                                            case 'age':
                                                return (<TeamMemberAge member={teamMember} />)
                                            case 'position':
                                                return (
                                                    <>
                                                        {teamMember.position ?
                                                            <DisplayI18n field='name' defaultValue={teamMember.position.name} i18n={teamMember.position.i18n} />
                                                            : '-'}
                                                    </>
                                                )
                                            case 'jerseyNumber':
                                                return getJerseyNumber(teamMember.jersey_number, teamMember.jersey_number_alternate);
                                            case 'playerStatus':
                                                if(teamMember.team_member_status){
                                                    return <DisplayI18n field='name' defaultValue={teamMember.team_member_status.name} i18n={teamMember.team_member_status.i18n} />
                                                }
                                                return '-'
                                            case 'status':
                                                return (
                                                    teamMember.status || teamMember.import == 1 || teamMember.tryout == 1 ?
                                                        <>
                                                            {teamMember.status &&
                                                                <div className="mb-2">
                                                                    {getTeamMemberStatus(teamMember.status)}
                                                                </div>
                                                            }
                                                            {teamMember.tryout == 1 &&
                                                                <MemberTeamStatus tip='teams.profile.roster.table.tryout' className="teamRoster-status mr-2">
                                                                    <Translate id='teams.profile.roster.table.tryout.short' />
                                                                </MemberTeamStatus>
                                                            }
                                                            {teamMember.import == 1 &&
                                                                <MemberTeamStatus
                                                                    className="teamRoster-status mr-2"
                                                                    tip="teams.profile.roster.table.imported"
                                                                >
                                                                    <Translate id='teams.profile.roster.table.imported.short' />
                                                                </MemberTeamStatus>
                                                            }
                                                            {/* TODO: HARDCODED */}
                                                            {teamMember.team_member_status?.team_member_status_id === '527e78d1-d9e0-11eb-b24f-06b0069ada2c' &&
                                                                <MemberTeamStatus
                                                                    className="teamRoster-status teamRoster-status-danger mr-2"
                                                                    tip="teams.profile.roster.table.injured"
                                                                >
                                                                    <Translate id='teams.profile.roster.table.injured.short' />
                                                                </MemberTeamStatus>
                                                            }
                                                        </>
                                                        : '-'
                                                )
                                            default:
                                                break;
                                        }
                                    }}
                                    onColumnClick={(e, data) => {
                                        switch (e.button){
                                            case 0: // Left mouse button
                                                panelProps.onSingleSelectChange(data);
                                                break;
                                        }
                                    }}
                                    rowIsHighlighted={(data) => data.checked}
                                >
                                    {(spordleTable) => (
                                        <>
                                            {/* Collapse Card Roster Table Header */}
                                            <TeamRosterTableHeader
                                                title={<Translate id='teams.profile.roster.table.activeRoster.title' />}
                                                isOpen={isOpen}
                                                isOpenHandlers={isOpenHandlers}
                                                spordleTable={spordleTable}
                                                displayCount={displayCount}
                                                displayLockNotice={displayLockNotice}
                                            />

                                            {/* Roster table view and header search & actions */}
                                            <CrossFade isVisible={isOpen}>
                                                <div className='mb-2'>
                                                    {displayLockNotice(true)}
                                                    <div className='d-flex flex-wrap justify-content-between'>
                                                        <SearchInput />
                                                        <div className='d-flex ml-auto text-right'>
                                                            <Refresh />

                                                            {spordleTable.getData()?.length > 0 && ((canSendComm || isGod()) || canEditJersey) &&
                                                                <UncontrolledDropdown inNavbar>
                                                                    <DropdownToggle caret className="ml-2" color="primary" outline>
                                                                        <Translate id="misc.actions" />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu right>
                                                                        <CanDoAction action="ADD" componentCode='communications' componentPermissionCode='communications_manage'>
                                                                            <DropdownItem onClick={sendCommIsOpenHandlers.open} className={stringBuilder({ "text-purple": isGod() })} id={spordleTable.generateId('send-communication')}>
                                                                                <Translate id="communications.sendMessage" />
                                                                            </DropdownItem>
                                                                            <SendCommModal
                                                                                isOpen={!!sendCommIsOpen}
                                                                                toggle={sendCommIsOpenHandlers.close}
                                                                                recipients={spordleTable.getData().map((participant) => ({ ...participant, ...participant.member }))}
                                                                            />
                                                                        </CanDoAction>
                                                                        {canEditJersey &&
                                                                            <CanDoAction action='EDIT' componentCode='teams' componentPermissionCode='team_members'>
                                                                                <DropdownItem onClick={goToEditRoster} id={spordleTable.generateId('edit')}><Translate id='teams.profile.roster.table.editRoster' /></DropdownItem>
                                                                            </CanDoAction>
                                                                        }
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            }
                                                            <TeamRosterAdd isOpen={addModal} toggle={toggleAddModal} refreshTable={() => spordleTable.refreshTable()} />

                                                            {isGod() ?
                                                                // this condition is only to display the button in purple and have the tooltip when the user is power user
                                                                // we don't want to use just CanDoAction because we want the button to be disabled, not hidden
                                                                <CanDoAction action='ADD' componentCode='teams' componentPermissionCode='bypass_lock_roster_date'>
                                                                    <Button
                                                                        id={spordleTable.generateId('add')}
                                                                        className='ml-2'
                                                                        color='primary'
                                                                        onClick={toggleAddModal}
                                                                    >
                                                                        <i className='ti-plus' /> <Translate id='misc.add' />
                                                                    </Button>
                                                                </CanDoAction>
                                                                :
                                                                <Button
                                                                    id={spordleTable.generateId('add')}
                                                                    className='ml-2'
                                                                    color='primary'
                                                                    onClick={toggleAddModal}
                                                                    disabled={!canAddToRoster}
                                                                >
                                                                    <i className={`${!canAddToRoster ? 'mdi mdi-lock-outline' : 'ti-plus'}`} /> <Translate id='misc.add' />
                                                                </Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <SpordleTableView />
                                            </CrossFade>
                                        </>
                                    )}
                                </SpordleTableProvider>
                            )
                        }}
                    />
                </CardBody>
            </OverlayLoader>
        </Card>
    );
}

export default TeamActiveRoster;