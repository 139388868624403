import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Card } from "reactstrap";
import SpordlePanelTable from "../../../components/sidePanel/SpordlePanel";
import SpordlePanelControl from "../../../components/sidePanel/SpordlepanelControl";
import TablePagination from "../../../components/table/TablePagination";
import ViewHeaderV2 from "../../../components/viewHeader/ViewHeaderV2";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import DisplayMember from "../../../components/display/DisplayMember";
import RenewMembersFilters from "./RenewMembersFilters";
import { getUnaffiliatedMembers } from "../../../api/client/unaffiliatedMembers";
import { PeriodsContext } from "../../../contexts/contexts";
import GenderLabel from "../../../components/GenderLabel";
import UserDisplay from "../../../components/userDisplay/UserDisplay";
import RenewMembersSidePanel from "./sidepanel/RenewMembersSidePanel";

const RenewMembers = ({ changeRequestStatuses, ...props }) => {
    const organizationContext = useContext(OrganizationContext);
    const { selectedPeriod } = useContext(PeriodsContext)

    return (
        <>
            <ViewHeaderV2 title='task.renewMembers.title' />
            <div className="px-4">
                <SpordlePanelTable
                    allowOutsideClick
                    sidePanel={(props) => (
                        <RenewMembersSidePanel {...props} />
                    )}
                    dataIndex='member_id'
                    table={(panelProps) => {
                        return (
                            <SpordleTableProvider
                                id='unaffiliatedMembers'
                                tableHover bordered striped
                                clickable
                                ref={panelProps.spordleTableRef}
                                desktopWhen
                                dataIndex='member_id'
                                pagination={25}
                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                defaultSorting='+created_at'
                                onColumnClick={(e, data, _spordleTable, columnKey) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            if(columnKey === 'checkbox'){
                                                panelProps.onMultiSelectChange(data);
                                            }else{
                                                panelProps.onSingleSelectChange(data);
                                            }
                                            break;
                                    }
                                }}
                                initFilter={{
                                    form: {
                                        memberTypeId: '',
                                        divisionId: '',
                                    },
                                }}
                                searchKeys={[
                                    `unique_identifier`,
                                    `first_name`,
                                    `last_name`,
                                    `gender`,
                                ]}
                                loadData={(from, { filters }, spordleTable) => {
                                    switch (from){
                                        case 'FILTER':
                                        case 'REFRESH':
                                            spordleTable.setLoading();
                                        case 'CDM':
                                            if(filters.form.memberTypeId && filters.form.divisionId){
                                                return getUnaffiliatedMembers({
                                                    organisation_id: organizationContext.organisation_id,
                                                    period_id: selectedPeriod.period_id,
                                                    member_type_id: filters.form.memberTypeId,
                                                    division_id: filters.form.divisionId,
                                                })
                                            }
                                            return Promise.resolve([])
                                        default:
                                            break;
                                    }
                                }}
                                columns={[
                                    {
                                        label: '-',
                                        key: 'checkbox',
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink',
                                    },
                                    {
                                        label: <Translate id='form.fields.name' />,
                                        key: "last_name",
                                        mobile: true,
                                        sortable: true,
                                        sortKey: 'last_name',
                                    },
                                    {
                                        label: <Translate id='task.renewMembers.affiliated.has' />,
                                        key: "affiliated",
                                        mobile: true,
                                        sortable: true,
                                        className: 'td-shrink',
                                        dataClassName: 'td-shrink text-center',
                                    },
                                    {
                                        label: <Translate id='task.renewMembers.memberType.has' />,
                                        key: "has_member_type",
                                        mobile: true,
                                        sortable: true,
                                        className: 'td-shrink',
                                        dataClassName: 'td-shrink text-center',
                                    },
                                ]}
                                renderRow={(key, member, spordleTable) => {
                                    switch (key){
                                        case 'checkbox':
                                            return (
                                                <label className='my-auto' htmlFor={'checked-' + member.member_id} onClick={(e) => { e.stopPropagation() }}>
                                                    <input
                                                        id={'checked-' + member.member_id} type="checkbox" checked={!!member.checked}
                                                        onClick={() => { panelProps.onMultiSelectChange(member); }}
                                                        onChange={function(){}}// This needs to be here to remove the console error
                                                    />
                                                    <span className="table-checkbox" />
                                                </label>
                                            )
                                        case 'last_name':
                                            return (
                                                <DisplayMember
                                                    member={member}
                                                    extra={{
                                                        infoBottom: (
                                                            <UserDisplay.Subtitle>
                                                                <GenderLabel gender={member.gender} />
                                                            </UserDisplay.Subtitle>
                                                        ),
                                                    }}
                                                    quickViewProps={{
                                                        disabled: true,
                                                    }}
                                                />
                                            )
                                        case 'affiliated':
                                            if(member.affiliated == 1)
                                                return <i className="mdi mdi-check text-success" />
                                            return <i className="mdi mdi-close text-danger" />
                                        case 'has_member_type':
                                            if(member.has_member_type == 1)
                                                return <i className="mdi mdi-check text-success" />
                                            return <i className="mdi mdi-close text-danger" />
                                        default:
                                            break;
                                    }
                                }}
                            >
                                {(spordleTable) => (
                                    <>
                                        <RenewMembersFilters />

                                        <Card body className="card-shadow">
                                            <div className="mb-2">
                                                <div className='d-flex mb-2'>
                                                    <SpordlePanelControl />
                                                    <SearchInput />

                                                    <div className='ml-auto'>
                                                        <TablePagination className='ml-2' paginationArray={[ 25, 50, 100 ]} />
                                                        <Refresh className='ml-2' />
                                                    </div>
                                                </div>
                                            </div>
                                            <SpordleTableView />
                                        </Card>
                                    </>
                                )}
                            </SpordleTableProvider>
                        )
                    }}
                />
            </div>
        </>
    );
};

export default RenewMembers;
