export default{
    "tasks.memberDuplicates.title"                             : "Potential Member Duplicates",
    "tasks.memberDuplicate"                                    : "Potential Duplicate",
    "tasks.memberDuplicates.manage.title"                      : "Manage Potential Member Duplicates",
    "tasks.memberDuplicates.potentialDuplicates"               : "Potential Duplicates",
    "tasks.memberDuplicates.potentialDuplicates.count"         : "Potential Duplicate{count, plural, =1 { (#)} other {s (#)}}",
    "tasks.memberDuplicates.potentialDuplicates.match"         : "Match",
    "tasks.memberDuplicates.potentialDuplicates.match.EXACT"   : "Exact",
    "tasks.memberDuplicates.potentialDuplicates.match.PARTIAL" : "Partial",
    "tasks.memberDuplicates.potentialDuplicates.match.FUZZY"   : "Similar",
    "tasks.memberDuplicates.lastSubmitted"                     : "Last Submitted",
    "tasks.memberDuplicates.duplicates"                        : "Duplicates",

    "tasks.memberDuplicates.manage"               : "Manage",
    "tasks.memberDuplicates.manage.step1"         : "First select the member profile to keep.",
    "tasks.memberDuplicates.manage.step2"         : "Now, select which members will be merged and which won't.",
    "tasks.memberDuplicates.manage.missingKeep"   : "Please specify which profile will be kept.",
    "tasks.memberDuplicates.manage.missingChoice" : "Please specify if this member is a duplicate and should be merged or if it isn't and should be ignored.",

    "tasks.memberDuplicates.manage.mergedMember"       : "Conserved Member(s)",
    "tasks.memberDuplicates.manage.mergedMember.error" : "Could not merge",
    "tasks.memberDuplicates.manage.noMergedMember"     : "No members were merged into this profile.",

    "tasks.memberDuplicates.manage.ignoredMember"       : "Ignored Member(s)",
    "tasks.memberDuplicates.manage.ignoredMember.error" : "Could not ignore",

    "tasks.memberDuplicates.manage.keep"   : "Keep",
    "tasks.memberDuplicates.manage.merge"  : "Merge",
    "tasks.memberDuplicates.manage.ignore" : "Ignore",
}