import OnlineStoreTransactionList from './components/OnlineStoreTransactionsList';

const OnlineStoreTransaction = (props) => {

    return (
        <>
            {/* <OnlineStoreTransactionDetails /> */}
            <OnlineStoreTransactionList {...props} />
        </>
    );
}

export default OnlineStoreTransaction;