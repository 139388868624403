export default{
    "organization.settings.sidePanelOrganizationPeriods.title" : "Periods Information",

    "organization.settings.sidePanelOrganizationPeriods.dates"                  : "Dates",
    "organization.settings.sidePanelOrganizationPeriods.dates.start"            : "Start",
    "organization.settings.sidePanelOrganizationPeriods.dates.end"              : "End",
    "organization.settings.sidePanelOrganizationPeriods.startDate"              : "Start Date",
    "organization.settings.sidePanelOrganizationPeriods.startDate.format"       : "Start Date must be of a valid format",
    "organization.settings.sidePanelOrganizationPeriods.startDate.required"     : "Start Date is required",
    "organization.settings.sidePanelOrganizationPeriods.startDate.before"       : "The Start Date must be before the End Date",
    "organization.settings.sidePanelOrganizationPeriods.endDate"                : "End Date",
    "organization.settings.sidePanelOrganizationPeriods.endDate.format"         : "End Date must be of a valid format",
    "organization.settings.sidePanelOrganizationPeriods.endDate.required"       : "End Date is required",
    "organization.settings.sidePanelOrganizationPeriods.endDate.after"          : "End Date must be after the Start Date",
    "organization.settings.sidePanelOrganizationPeriods.sharedDate"             : "Shared Date",
    "organization.settings.sidePanelOrganizationPeriods.sharedDate.format"      : "Shared Date must be of a valid format",
    "organization.settings.sidePanelOrganizationPeriods.calculationDate"        : "Calculation Date",
    "organization.settings.sidePanelOrganizationPeriods.calculationDate.format" : "Calculation Date must be of a valid format",
    "organization.settings.sidePanelOrganizationPeriods.status"                 : "Status",
    "organization.settings.sidePanelOrganizationPeriods.status.active"          : "Active",
    "organization.settings.sidePanelOrganizationPeriods.status.inactive"        : "Inactive",
    "organization.settings.sidePanelOrganizationPeriods.name.required"          : "Name is required",
    "organization.settings.sidePanelOrganizationPeriods.description.required"   : "Description is required",
    "organization.settings.sidePanelOrganizationPeriods.status.required"        : "Status is required",
    "organization.settings.sidePanelOrganizationPeriods.display_order.required" : "Display order is required",

    "organization.settings.sidePanelOrganizationPeriods.update.success.text" : "Period updated",
    "organization.settings.sidePanelOrganizationPeriods.update.fail.text"    : "An error occurred during the Period update, please refresh the page et try again",

    // actions
    "organization.settings.sidePanelOrganizationPeriods.actions.remove"         : "Remove",
    "organization.settings.sidePanelOrganizationPeriods.actions.remove.topText" : "You are about to remove this Period from your Organization",

    // tabs
    "organization.settings.sidePanelOrganizationPeriods.tabs.details" : "Details",
}