import { FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Col, Row } from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import { getCategoryTypeChoices, getCategoryTypes } from "../officialSupervisionHelper";
import FormikEditable from "../../../../components/formik/FormikEditable";

const SupervisionTypeResults = ({ noFormik, supervision, updateSupervisionReport, isReadOnly }) => {
    const supervisionCategories = getCategoryTypes(supervision.supervision_type?.name);
    const supervisionChoices = getCategoryTypeChoices(supervision.supervision_type?.name);
    const selectChoices = supervisionChoices.map((choice) => ({ label: `officialSupervision.categories.choice.${choice}`, translateLabel: true, value: choice }));

    return (
        <>
            <div className="border rounded-lg bg-light-inverse mb-3">
                <div className="p-3 border-bottom">
                    <Row form className="d-none d-sm-flex">
                        <Col className="font-bold text-body" sm="6">
                            <Translate id='officialSupervision.supervisionType.categories.label' />
                        </Col>
                        <Col className="font-bold text-body text-right" sm="6">
                            <Translate id="misc.result" />
                        </Col>
                    </Row>
                </div>
                <div className="px-3 border-bottom border-top">

                    {supervisionCategories.map((supCategory, index) => (
                        <Row form className={stringBuilder("py-2 align-items-center", { "border-bottom": index !== supervisionCategories.length - 1 })} key={`${supCategory}-formik-editable`}>
                            <Col sm="6" className="text-muted position-relative">
                                <div className="d-sm-block d-none form-control" style={{ opacity: 0 }} />
                                <span className="d-none d-sm-flex position-absolute top-0 bottom-0 align-items-center">
                                    <Translate id={`officialSupervision.categories.${supCategory}`} />
                                </span>
                                <span className="d-block d-sm-none">
                                    <Translate id={`officialSupervision.categories.${supCategory}`} />
                                </span>
                            </Col>
                            <Col sm="6">
                                {noFormik ?
                                    <div className="font-medium text-right">{supervision[supCategory] ? <Translate id={`officialSupervision.categories.choice.${supervision[supCategory]}`} /> : "-"}</div>
                                    :
                                    <FormikEditable
                                        id={supCategory}
                                        disabled={isReadOnly}
                                        noConfirmation
                                        className="justify-content-sm-end editable-transparent"
                                        initialValues={{
                                            [supCategory]: supervision[supCategory] || "",
                                        }}
                                        onSubmit={(values) => {
                                            if(values[supCategory] !== supervision[supCategory]){
                                                updateSupervisionReport(values);
                                            }
                                        }}
                                    >
                                        {(isEditing, options, formik) => {
                                            if(!isEditing){
                                                return (
                                                    <div className="font-medium">{supervision[supCategory] ? <Translate defaultMessage={supervision[supCategory]} id={`officialSupervision.categories.choice.${supervision[supCategory]}`} /> : "-"}</div>
                                                )
                                            }
                                            return (
                                                <FormikSelect
                                                    id={supCategory}
                                                    menuIsDefaultOpen
                                                    autoFocus
                                                    onOptionSelected={options.stopEditing}
                                                    name={supCategory}
                                                    defaultData={selectChoices}
                                                    loadingStatus="success"
                                                />
                                            )
                                        }}
                                    </FormikEditable>
                                }
                            </Col>
                        </Row>
                    ))}
                </div>
                <div className="p-3">
                    <Row form className="align-items-center">
                        <Col sm="6" className="font-medium">
                            <div className="d-sm-block d-none form-control" style={{ opacity: 0 }} />
                            <span className="d-none d-sm-flex position-absolute top-0 bottom-0 align-items-center">
                                <Translate id='officialSupervision.overallScore.label' />
                            </span>
                            <span className="d-block d-sm-none">
                                <Translate id='officialSupervision.overallScore.label' />
                            </span>
                        </Col>
                        <Col sm="6">
                            {noFormik ?
                                <div className="font-medium text-right">{supervision.overall_score ? <Translate id={`officialSupervision.categories.choice.${supervision.overall_score}`} /> : "-"}</div>
                                :
                                <FormikEditable
                                    id="overall-score"
                                    noConfirmation
                                    disabled={isReadOnly}
                                    className="justify-content-sm-end editable-transparent"
                                    initialValues={{
                                        "overall_score": supervision.overall_score || "",
                                    }}
                                    onSubmit={(values) => {
                                        if(values.overall_score !== supervision.overall_score){
                                            updateSupervisionReport(values);
                                        }
                                    }}
                                >
                                    {(isEditing, options) => {
                                        if(!isEditing){
                                            return (
                                                <div className="font-medium">{supervision.overall_score ? <Translate id={`officialSupervision.categories.choice.${supervision.overall_score}`} /> : "-"}</div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                id="overall-score"
                                                menuIsDefaultOpen
                                                autoFocus
                                                onOptionSelected={options.stopEditing}
                                                name="overall_score"
                                                defaultData={selectChoices}
                                                loadingStatus="success"
                                            />
                                        )
                                    }}
                                </FormikEditable>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default SupervisionTypeResults;