import Translate, { InputIntl } from '@spordle/intl-elements';
import { useContext } from 'react';
import { stringBuilder } from "@spordle/helpers";
import {
    Row,
    Col,
    Button
} from "reactstrap";
import SpordleTableProvider, { SpordleTableContext, SpordleTableView } from '@spordle/datatables';
import { JiraContext } from '../../../../contexts/JiraContext';
import { Link } from 'react-router-dom';
import moment from 'moment';

const SubmitTicketSearchResult = ({ back, toggle }) => {
    const jiraContext = useContext(JiraContext);

    return (
        <SpordleTableProvider
            id="SubmitTicketSearchResult"
            pagination={5}
            emptyLayout={<div className='text-center w-100'><Translate id="misc.search.empty.title" /></div>}
            searchKeys={[
                'key',
                'fields.summary',
                'fields.description',
                'fields.created',
                'fields.status.name',
                'fields.status.statusCategory.name',
                'fields.priority.name',
                'fields.issuetype.name',
            ]}
            viewMode='GRID'
            gridConfig={{
                col: { xs: 12 },
                gridRender: (data) => (
                    <div className='border-top p-3 container-fluid'>
                        <Row className='align-items-center'>
                            <Col xs='6'>
                                <Link onClick={toggle} className="d-flex" to={'/supportRequest/' + data.key}>
                                    <span className="text-truncate" title={data.fields.summary}>{data.fields.summary}</span>
                                    <i className='mdi mdi-chevron-right' />
                                </Link>
                                <div className="text-muted small">#{data.key}</div>
                            </Col>
                            <Col xs='6'>
                                <div><Translate id='helpCenter.submitTicket.search.updated' /> {moment(data.fields.updated).fromNow()}</div>
                            </Col>
                        </Row>
                    </div>
                ),
            }}
            loadData={(from) => {
                switch (from){
                    case 'CDM':
                        return jiraContext.getRequests()
                            .then(({ issues }) => {
                                return issues || [];
                            })
                    default:
                        break;
                }
            }}
        >
            <div className='mb-2'>
                <SpordleTableContext.Consumer>
                    {(spordleTable) => (
                        <div className='search-wrapper'>
                            <InputIntl type='search' placeholder='misc.SimpleSearchTable.search.placeholder' value={spordleTable.getInputValue()} onChange={spordleTable.searchInputChange} />
                            <button type='button' className={stringBuilder('reset-btn text-link search-clear', { 'd-none': !spordleTable.getInputValue() })} onClick={() => spordleTable.setInputValue()}><i className='mdi mdi-close' /></button>
                        </div>
                    )}
                </SpordleTableContext.Consumer>
            </div>
            <SpordleTableView />
            <div className="mt-3 border-top pt-3 d-flex justify-content-between">
                <Button onClick={() => back()} type="button" color="primary" outline><Translate id="misc.previous" /></Button>
                <Link to={"/supportRequest"} className="btn btn-link" onClick={toggle}><Translate id='helpCenter.submitTicket.search.gotoSupportRequests' /><i className="ml-1 mdi mdi-chevron-right" /></Link>
            </div>
        </SpordleTableProvider>
    );
}

export default SubmitTicketSearchResult;