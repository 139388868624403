import React, { useState } from 'react';
import SpordleSelect from "@spordle/spordle-select";
import { Button, ModalBody, ModalHeader, ModalFooter, UncontrolledCollapse } from "reactstrap";
import Translate, { getTranslation } from '@spordle/intl-elements';
import SearchResult from './SearchResult';

const AddOrganizationVenuesModalSelect = ({ toggle, toggleAddLocationForm }) => {
    const [ locations, setLocation ] = useState({});
    // const [ city, setCity ] = useState(''); // For init value googlePlaces

    const dummySearchResults = [ { id: 1 }, { id: 2 }, { id: 3 } ];

    // add a location in locations state
    const addLocation = (id, val) => {
        setLocation((prev) => {
            return prev[id] ? prev : { ...prev, [id]: val }
        });
    }

    // Removes location from locations state
    const removeLocation = (id) => {
        setLocation((prev) => {
            const { [id]: _val, ...res } = prev;
            return res;
        });

        // temp
        const checkbox = document.getElementById(id);
        if(checkbox?.checked) checkbox.click();
    }

    const validate = (e) => {
        e.preventDefault();
    }

    return (
        <>
            <ModalHeader toggle={toggle}><Translate id='organization.profile.venues.modals.components.addOrganizationVenuesModalSelect.search.title' /></ModalHeader>
            <ModalBody>
                <form id='addLocation'>
                    <div className="mb-3 pb-3 border-bottom">
                        {/* TODO: replace with FormikAddress (wrap this whole thing in a formik first) */}
                        {/* <GooglePlaces
                            types={[ '(cities)' ]}
                            initValue={city.address?.fullAddress}
                            onPlaceChanged={(newPlace) => setCity(newPlace.city.long_name)}
                        >
                            {({ inputValues }) => (
                                <input
                                    {...inputValues}
                                    className='w-100 mb-2 form-control'
                                    placeholder={getTranslation('organization.profile.venues.modals.components.addOrganizationVenuesModalSelect.search.city')}
                                    type="search"
                                    autoComplete='new-address'
                                />
                            )}
                        </GooglePlaces> */}
                        <SpordleSelect
                            className="w-50 mb-2"
                            name="venuesType"
                            id="venues_type"
                            isSearchable={false}
                            placeholder={getTranslation('organization.profile.venues.modals.components.createVenueStep1.location.type')}
                        >
                            <SpordleSelect.Option id='all' value='all' label='All' />
                            <SpordleSelect.Option id='ice_rink' value='ice_rink' label='Ice Rink (interior)' />
                            <SpordleSelect.Option id='soccer_field' value='soccer_field' label='Soccer Field' />
                            <SpordleSelect.Option id='baseball_field' value='baseball_field' label='Baseball Field' />
                        </SpordleSelect>
                        <div className="d-flex justify-content-end">
                            <Button color="primary"><i className="ti ti-search" /> <Translate id='misc.SimpleSearchTable.search.placeholder' /></Button>
                        </div>
                    </div>
                    <div className="mb-3 border-bottom">
                        <div className="h6 mb-3"><Translate id='organization.profile.venues.modals.components.addOrganizationVenuesModalSelect.search.results' /></div>
                        <ul className="list-unstyled mb-0">
                            {dummySearchResults.map((obj, i) => (
                                <React.Fragment key={`search-result-${obj.id}`}>
                                    <SearchResult add={addLocation} remove={removeLocation} id={obj.id} />
                                </React.Fragment>
                            ))
                            }
                        </ul>
                        <div className="text-danger small" />
                    </div>
                    {
                        Object.keys(locations).length > 0 &&
                            <div className="border-bottom mb-3">
                                <button type='button' id="results-selected" className="text-link border-0 bg-transparent mb-3">{Object.keys(locations).length} locations selected</button>
                                <UncontrolledCollapse tag="ul" className="list-unstyled mb-0" toggler="results-selected">
                                    {Object.keys(locations).map((keyName, i) => (
                                        <React.Fragment key={keyName}>
                                            <SearchResult checked add={addLocation} remove={removeLocation} id={`${keyName}-selected`} dataId={keyName} />
                                        </React.Fragment>
                                    ))}
                                </UncontrolledCollapse>
                            </div>
                    }
                    <div className='small'>
                        <Translate id='organization.profile.venues.modals.components.addOrganizationVenuesModalSelect.search.find' />
                        <button type='button' onClick={toggleAddLocationForm} className="text-link border-0 bg-transparent">
                            <Translate id='organization.profile.venues.modals.components.addOrganizationVenuesModalSelect.search.btn.request' />
                        </button>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button className='mr-2' color='primary' type='button' outline onClick={toggle}><Translate id='misc.cancel' /></Button>
                <Button onClick={validate} form="addLocation" color='primary'><Translate id='misc.add' /></Button>
            </ModalFooter>
        </>
    )
}

export default AddOrganizationVenuesModalSelect;