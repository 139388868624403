import Translate from "@spordle/intl-elements"
import AnalyticsModal from "../../../../../analytics/AnalyticsModal"
import FormikModalForm from "../../../../../components/formik/FormikModalForm"
import { mixed, object, string } from "yup"
import { Button, Col, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import FormikApiError from "../../../../../components/formik/FormikApiError"
import ApiErrorMsg from "../../../../../components/formik/ApiErrorMsg"
import moment from "moment"
import { MembersContext } from "../../../../../contexts/MembersContext"
import { useContext } from 'react';
import CustomAlert from "../../../../../components/CustomAlert"
import { FormikDateTime, FormikSelect } from "@spordle/formik-elements"
import { DisplayI18n } from "../../../../../helpers/i18nHelper"
import Required from "../../../../../components/formik/Required"
import { I18nContext } from "../../../../../contexts/I18nContext"
import { OrganizationContext } from "../../../../../contexts/OrganizationContext"
import { RolesContext } from "../../../../../contexts/RolesContext"

const BackgroundCheckEditModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal analyticsName="backgroundCheckEditModal" isOpen={isOpen}>
            <BackgroundCheckEditModalInner {...props} />
        </AnalyticsModal>
    )
}

const BackgroundCheckEditModalInner = ({ toggle, crc, panelProps, allStatus, backgroundChecks }) => {
    const membersContext = useContext(MembersContext);
    const { getGenericLocale } = useContext(I18nContext);
    const organizationContext = useContext(OrganizationContext);
    const { canDoAction } = useContext(RolesContext);
    const canEditIssueDate = canDoAction('EDIT', 'members', 'manage_background_check_issue_date', true);

    return (
        <FormikModalForm
            toggle={toggle}
            successText={<p><Translate id="members.profile.crc.editModal.success" /></p>}
            initialValues={{
                status: crc.criminal_record_check_status?.criminal_record_check_status_id,
                issueDate: moment(crc.issue_date),
                memberTypeId: '',
            }}
            validationSchema={object().shape({
                status: string().required(<Translate id='members.profile.crc.form.validation.status.required' />),
                issueDate: mixed()
                    .required(<Translate id='members.profile.crc.form.validation.date.required' />)
                    .test({
                        name: 'dateIsValid',
                        message: <Translate id='form.validation.date.format' />,
                        test: moment.isMoment,
                    }),
                memberTypeId: string().required(<Translate id='members.profile.crc.form.validation.memberType.required' />),
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                const newDate = values.issueDate.format('YYYY-MM-DD');
                const newValues = {
                    criminal_record_check_status_id: values.status,
                    issue_date: newDate,
                    member_type_id: values.memberTypeId,
                };

                if(!canEditIssueDate)
                    delete newValues.issue_date

                return Promise.all([
                    Object.keys(newValues).length > 0 ?
                        membersContext.partiallyUpdateMemberCrc(
                            crc.member.member_id,
                            crc.crc_id,
                            newValues,
                        )
                        :
                        Promise.resolve(),
                ])
                    .then(async() => {
                        // This will refresh the header of the member profile
                        await membersContext.getMemberCrcInfo(crc.member.member_id)
                            .then((memberCrcs) => {
                                // Need to sync new crc because the expiration can change when the issue date changes
                                const currentCrc = memberCrcs.find(((c) => c.crc_id === crc.crc_id))
                                if(currentCrc)
                                    panelProps.syncRows(panelProps.createNewValues(currentCrc))
                            })

                        setStatus({
                            showError: false,
                            error: null,
                            isSuccess: true,
                        });
                    })
                    .catch((e) => {
                        setSubmitting(false);
                        setStatus({
                            showError: true,
                            isSuccess: false,
                            error: Array.isArray(e) ? e : <ApiErrorMsg error={e} />,
                        })
                    })
            }}
        >
            {(formik) => (
                <>
                    <ModalHeader toggle={toggle}><Translate id="members.profile.crc.editModal.title" /></ModalHeader>
                    <ModalBody>
                        <CustomAlert
                            color='info'
                            text={
                                <Translate
                                    id='members.profile.crc.form.expirationDate.alert'
                                    values={{
                                        expirationDate: <b><Translate id='members.profile.crc.expirationDate' /></b>,
                                    }}
                                />
                            }
                            translateText={false}
                        />
                        <Row form>
                            <Col sm="6" className="mb-3">
                                <Label for='status' className='text-muted'><Translate id="misc.status" /> <Required /></Label>
                                <FormikSelect
                                    search={false}
                                    renderOption={({ option: status }) => <DisplayI18n field='name' defaultValue={status.label} i18n={status.i18n} />}
                                    name='status'
                                    id='status'
                                    isLoading={!allStatus}
                                    options={allStatus ?
                                        allStatus
                                            .map((status) => ({
                                                label: status.name,
                                                i18n: status.i18n,
                                                value: status.criminal_record_check_status_id,
                                            }))
                                        :
                                        []
                                    }
                                />
                            </Col>

                            {canEditIssueDate &&
                                <Col sm="6" className="mb-3">
                                    <Label for='issueDate' className='text-muted'><Translate id='members.profile.crc.validOn' /> <Required /></Label>
                                    <FormikDateTime
                                        name='issueDate'
                                        id='issueDate'
                                        timeFormat={false}
                                    />
                                </Col>
                            }

                            <Col sm="6" className="mb-3">
                                <Label for="memberTypeId" className="text-muted"><Translate id='members.profile.crc.memberType' /> <Required /></Label>
                                <FormikSelect
                                    key={backgroundChecks}
                                    isLoading={!backgroundChecks}
                                    name='memberTypeId'
                                    id='memberTypeId'
                                    renderOption={({ option: memberType }) => <DisplayI18n field='name' defaultValue={memberType.label} i18n={memberType.i18n} />}
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    initFilter={{
                                        backgroundCheckTypeId: crc.background_check_type.background_check_type_id,
                                    }}
                                    loadData={(from, { filters }) => {
                                        switch (from){
                                            case 'CDM':
                                                if(backgroundChecks){
                                                    return membersContext.getMemberTypes(organizationContext.organisation_id)
                                                        .then((memberTypes) => {
                                                            const filteredMemberTypes = memberTypes.reduce((newArray, type) => {
                                                                if(type.system_type !== 'SPECIAL' && backgroundChecks.find((bgc) => bgc.member_type.member_type_id === type.member_type_id && bgc.background_check_type.background_check_type_id === crc.background_check_type.background_check_type_id)){
                                                                    newArray.push({
                                                                        value: type.member_type_id,
                                                                        label: type.name,
                                                                        i18n: type.i18n,
                                                                    })
                                                                }
                                                                return newArray
                                                            }, [])
                                                            return filteredMemberTypes
                                                        })
                                                }
                                                return Promise.resolve([])
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <FormikApiError />
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" color="primary" disabled={formik.isSubmitting || formik.status.isSuccess}>
                            <Translate id="misc.submit" />
                        </Button>
                        <Button
                            disabled={formik.isSubmitting || formik.status.isSuccess}
                            type="button"
                            color="primary"
                            outline
                            onClick={toggle}
                        >
                            <Translate id="misc.cancel" />
                        </Button>
                    </ModalFooter>
                </>
            )}
        </FormikModalForm>
    )
}

export default BackgroundCheckEditModal