import Invoice from '../../components/invoice/Invoice';
import OnlineStoreOverview from '../../views/onlineStore/profile/overview/OnlineStoreOverview';
import OnlineStoreItemsAndFees from '../../views/onlineStore/profile/itemsAndFees/OnlineStoreItemsAndFees';
import OnlineStoreRegistrations from '../../views/onlineStore/profile/registrations/OnlineStoreRegistrations';
import OnlineStoreTransaction from '../../views/onlineStore/profile/transactions/OnlineStoreTransactions';
import Receipt from '../../components/invoice/Receipt';

/**
 * @typedef {object} TabRoutes
 * @property {React.ReactNode} [name]
 * @property {boolean} [redirect]
 */

/** @type {Array.<import('react-router-dom').RouteProps & TabRoutes>} */
const onlineStoreTabsRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        path: '/overview',
        name: 'onlineStore.profile.overview.tabs.overview',
        component: OnlineStoreOverview,
    },
    {
        path: '/items-and-fees',
        name: 'onlineStore.profile.overview.tabs.items',
        component: OnlineStoreItemsAndFees,
    },
    {
        path: '/registration',
        name: 'onlineStore.profile.overview.tabs.registration',
        component: OnlineStoreRegistrations,
    },
    {
        path: '/transaction',
        name: 'onlineStore.profile.overview.tabs.transaction',
        component: OnlineStoreTransaction,
    },
    {
        path: '/invoice',
        name: 'onlineStore.profile.overview.tabs.invoice',
        component: Invoice,
        tempTab: true,
    },
    {
        path: '/receipt',
        name: 'onlineStore.profile.overview.tabs.receipt',
        component: Receipt,
        tempTab: true,
    },
    // {
    //     path: '/fullinvoice',
    //     name: 'fullInvoice',
    //     component: FullInvoice,
    //     powerUserOnly: true,
    // },
    {
        hiddenTab: true,
        path: '/onlineregistration/create',
        name: 'misc.create',
    },
    {
        redirect: true,
        from: '/onlineregistration/profile/:onlineStoreId',
        to: '/overview',
    },
];
export default onlineStoreTabsRoutes;