import { stringBuilder } from "@spordle/helpers";
import Svg from "../Svg";
import UserDisplay from "../userDisplay/UserDisplay";
import styles from "./BtnDisplay.module.scss";

/**
 * @description Large button with dashed border used in several places
 * @param {Object} props
 * @param {string} [props.icon]
 * @param {string|JSX.Element} props.title
 * @param {string|JSX.Element} [props.subtitle]
 * @param {string} [props.className]
 * @param {function} [props.onClick]
 * @param {number|string} [props.iconSize]
 * @param {object} [props.svgProps]
 * @returns {JSX.Element}
 */
const BtnDisplay = ({ icon, iconSize, title, subtitle, className, svgProps, ...props }) => {
    return (
        <UserDisplay tag="button" card hover block className={stringBuilder(styles.Container, className)} type="button" {...props}>
            {icon &&
                <UserDisplay.Container>
                    <div style={{ width: iconSize, height: iconSize }} className={styles.SvgContainer}>
                        <Svg className={styles.Svg} icon={icon} {...svgProps} />
                    </div>
                </UserDisplay.Container>
            }
            <UserDisplay.Container>
                <UserDisplay.Title tag="span" className={styles.Title}>
                    {title}
                </UserDisplay.Title>
                <UserDisplay.Subtitle tag="span" className={styles.Subtitle}>
                    {subtitle}
                </UserDisplay.Subtitle>
            </UserDisplay.Container>
        </UserDisplay>
    );
}

export default BtnDisplay;