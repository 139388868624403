import { DisplayPhoneNumber, FormikRadioButton } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import { useFormikContext } from "formik";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import UserDisplay from "../../../../userDisplay/UserDisplay";

const AddressCard = ({ address }) => {
    const formik = useFormikContext();

    return (
        <UserDisplay
            card
            hover
            block
            tag="label"
            htmlFor={address.member_address_id}
            key={address.member_address_id}
            className={stringBuilder("mb-3", { "border-primary": formik.values.memberAddressId === address.member_address_id })}
        >
            <UserDisplay.Container>
                <FormikRadioButton
                    id={address.member_address_id}
                    name='memberAddressId'
                    value={address.member_address_id}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <div className="text-uppercase small text-muted mb-1">
                    <DisplayI18n
                        field="name"
                        i18n={address.address_type?.i18n}
                        name={address.address_type?.name}
                    />
                </div>
                <div>
                    {address.street_number && <span>{address.street_number}, </span>}
                    {address.street && <span>{address.street}, </span>}
                    {address.unit_number && <span>#{address.unit_number}</span>}
                </div>
                <div>
                    {address.city && <span>{address.city}, </span>}
                    {address.province_id && <span>{address.province_id}, </span>}
                    {address.country_id && <span>{address.country_id}</span>}
                </div>
                {address.zip_code && <div>{address.zip_code}</div>}
                {address.phones?.[0] && <DisplayPhoneNumber phoneString={address.phones[0].phone_number} format='INTERNATIONAL' />}
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default AddressCard;