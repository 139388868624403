import { FormikError, FormikInputNumber, FormikSelect } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import { FieldArray, useField, useFormikContext } from "formik";
import { useIntl } from "react-intl";
import { Button, Col, Row } from "reactstrap";
import Required from "../../../../../components/formik/Required";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { useContext } from "react";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import Translate from "@spordle/intl-elements";
import { Tooltip } from "@mantine/core";

const ConfigurationQualificationException = ({ name, getInitSubRule, positions, positionGroups, qualifications }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const { formatMessage } = useIntl();
    const formik = useFormikContext();
    const [ field ] = useField(name)
    const values = field.value || [];

    return (
        <FieldArray name={name}>
            {(arrayHelper) => (
                <div className="border-top mt-3 pt-3">
                    {values.map((val, i) => (
                        <Row key={val.key} form className={stringBuilder({ "pt-2": i > 0 })}>
                            <Col sm={3}>
                                <div className={stringBuilder({ "d-md-none": i > 0 }, "text-muted mb-1")}>
                                    <Translate id="teams.profile.teamSetting.settings.qualifications.qualificationName" /> <Required />
                                </div>
                                <FormikSelect
                                    id={`${name}.${i}.qualification_id`}
                                    name={`${name}.${i}.qualification_id`}
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    renderOption={({ option }) => (
                                        <div className="d-flex justify-content-between">
                                            <DisplayI18n
                                                field="name"
                                                i18n={option.i18n}
                                                defaultValue={option.label}
                                            />
                                            {option.active == 0 &&
                                                <Tooltip
                                                    label={<Translate id='misc.inactive' />}
                                                >
                                                    <i className="mdi mdi-alert-outline text-warning ml-1" />
                                                </Tooltip>
                                            }
                                        </div>
                                    )}
                                    manualError
                                    options={qualifications}
                                    disabled={val.delete == 1}
                                    className={val.delete == 1 ? "opacity-50" : undefined}
                                />
                            </Col>
                            <Col sm={3}>
                                <div className={stringBuilder({ "d-md-none": i > 0 }, "text-muted mb-1")}>
                                    <Translate id="reports.column.header.position_group_name" /> <Required />
                                </div>
                                <FormikSelect
                                    id={`${name}.${i}.position_group_id`}
                                    name={`${name}.${i}.position_group_id`}
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    clearable
                                    manualError
                                    options={positionGroups}
                                    disabled={val.delete == 1 || !!val.position_id}
                                    renderOption={({ option }) => (
                                        <DisplayI18n
                                            field="name"
                                            i18n={option.i18n}
                                            defaultValue={option.label}
                                        />
                                    )}
                                    className={val.delete == 1 ? "opacity-50" : void 0}
                                />
                            </Col>
                            <Col sm={3}>
                                <div className={stringBuilder({ "d-md-none": i > 0 }, "text-muted mb-1")}>
                                    <Translate id="members.profile.teams.position" /> <Required />
                                </div>
                                <FormikSelect
                                    id={`${name}.${i}.position_id`}
                                    name={`${name}.${i}.position_id`}
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    renderOption={({ option }) => (
                                        <DisplayI18n
                                            field="name"
                                            i18n={option.i18n}
                                            defaultValue={option.label}
                                        />
                                    )}
                                    clearable
                                    manualError
                                    options={positions}
                                    disabled={val.delete == 1 || !!val.position_group_id}
                                    className={val.delete == 1 ? "opacity-50" : void 0}
                                />
                            </Col>
                            <Col sm={3}>
                                <div className={stringBuilder({ "d-md-none": i > 0 }, "text-muted mb-1")}><Translate id="memberProfile.registration.questionnaires.form.label.min" /> <Required /></div>
                                <div className="d-flex">
                                    <FormikInputNumber
                                        id={`${name}.${i}.count`}
                                        name={`${name}.${i}.count`}
                                        className={stringBuilder("flex-grow-1", { "opacity-50": val.delete == 1 })}
                                        manualError
                                        disabled={val.delete == 1}
                                    />
                                    <Button
                                        title={formatMessage({ id: "misc.remove" })}
                                        color="link"
                                        onClick={() => formik.setFieldValue(`${name}.${i}.delete`, val.delete == 1 ? 0 : 1)}
                                        type="button"
                                        className={stringBuilder({ 'mdi mdi-close text-danger': val.delete == 0, 'mdi mdi-undo text-primary': val.delete == 1 }, "ml-auto px-3 mr-n3")}
                                    />
                                </div>
                            </Col>
                            <Col sm={12}>
                                <FormikError name={`${name}.${i}.count`} />
                                <FormikError name={`${name}.${i}.qualification_id`} />
                                <FormikError name={`${name}.${i}.position_id`} />
                            </Col>
                        </Row>
                    ))}
                    {((positions || []).length) > values.length &&
                        <Button
                            type="button"
                            color="link"
                            block
                            className="mdi mdi-plus with-icon pb-0 pt-3"
                            onClick={() => arrayHelper.push(getInitSubRule())}
                        >
                            <Translate id="settings.teamsSettings.configuration.addRule" />
                        </Button>
                    }
                </div>
            )}
        </FieldArray>
    );
}

export default ConfigurationQualificationException;