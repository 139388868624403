import { useContext, useEffect } from "react";
import { AppContext } from "../../contexts/contexts";
import { TourManagerContext } from "./TourManager";

/**
 * Triggers a specified tour whenever it hasn't been seen for the first time.
 *
 * @param {object} props
 * @param {string} props.name
 * @param {object} props.tourProps
 * @param {import("@reactour/tour").StepType[]} props.tourProps.steps
 * @returns {null}
 */
const TourTriggerer = ({ name, tourProps }) => {
    const { triggerTour, endTour } = useContext(TourManagerContext);
    const { getFrontEndParams } = useContext(AppContext);

    useEffect(() => {
        if(getFrontEndParams("tours")[name] == 1){
            triggerTour({ ...tourProps, name: name });
        }

        return () => endTour({ name: name })
    }, []);

    return null;
}

export default TourTriggerer;