import { useContext, Fragment } from 'react';
import { MembersContext } from '../../../../contexts/MembersContext';
import { DisplayI18n, displayI18n } from '../../../../helpers/i18nHelper';
import { I18nContext } from '../../../../contexts/I18nContext';
import DisplayMemberTypeIcon from '../../../../components/display/DisplayMemberTypeIcon';
import DisplayOrg from '../../../../components/DisplayOrg';
import { Skeleton } from '@mantine/core';
import useSWR from 'swr';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { fail } from '@spordle/toasts';
import Translate from '@spordle/intl-elements';
import { getMemberTypeKey } from '../../../../helpers/memberTypeHelper';

const MemberTypesList = ({ requestMemberTypes, memberId, skipStrikeThrough }) => {
    const membersContext = useContext(MembersContext);
    const { getGenericLocale } = useContext(I18nContext);

    const { data: memberMemberTypes, isValidating } = useSWR(
        [ "getMemberMemberTypesSWR", memberId ],
        (_, id) => id ? membersContext.getMember(id, true)
            .then((member) => {
                const sorted = member.member_types.sort((a, b) => {
                    const orgA = a.organisation;
                    const orgB = b.organisation;

                    if(orgA && orgB){
                        const orgNameA = displayI18n('name', orgA.i18n, orgA.organisation_name, getGenericLocale()) || "";
                        const orgNameB = displayI18n('name', orgB.i18n, orgB.organisation_name, getGenericLocale()) || "";

                        return orgNameA.localeCompare(orgNameB);
                    }

                    return 0;
                });

                const currentSeasonTypes = sorted.filter((m) => m.current_season == 1);
                const lastSeasonTypes = sorted.filter((m) => m.last_season == 1);

                const isLastPeriod = currentSeasonTypes.length <= 0 && lastSeasonTypes.length > 0;
                const shownMemberTypes = currentSeasonTypes.length > 0 ? currentSeasonTypes : lastSeasonTypes;

                return shownMemberTypes.map((data) => ({
                    ...data,
                    isLastPeriod: isLastPeriod,
                }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
            :
            Promise.resolve(),
        {
            revalidateOnFocus: false,
            dedupingInterval: 60000,
        },
    );

    const Removed = (props) => <s className='text-muted' {...props} />

    return (
        <ul className="list-unstyled no-style font-medium pl-0 mb-0">
            {(!isValidating && memberMemberTypes) ?
                memberMemberTypes.length > 0 ?
                    (memberMemberTypes.map((memberType) => {
                        const Wrapper = requestMemberTypes.find((type) => getMemberTypeKey(type) === getMemberTypeKey(memberType)) && !skipStrikeThrough ? Removed : Fragment;

                        return (
                            <li key={getMemberTypeKey(memberType) + memberType.member_member_type_id} className="d-flex align-items-start">
                                <DisplayMemberTypeIcon memberType={memberType} />
                                <div className="d-flex align-items-center flex-wrap">
                                    <Wrapper>
                                        <DisplayI18n defaultValue={memberType.member_type.name} i18n={memberType.member_type.i18n} field='name' />
                                    </Wrapper>
                                    {memberType.organisation &&
                                        <div className="text-muted ml-1 small d-flex align-items-center">
                                            (<DisplayOrg org={memberType.organisation} />)
                                        </div>
                                    }
                                </div>
                            </li>
                        )
                    }))
                    :
                    <Translate id='tasks.memberTypeRemoval.sidepanel.none' />
                :
                <li>
                    <Skeleton height='1em' />
                </li>
            }
        </ul>
    )
}

export default MemberTypesList