export default{
    "newnav.toast.msg"         : "Try our new navigation!",
    "newnav.toast.info"        : "We are currently working to enhance your user experience. <link>Click here to learn more.</link>",
    "newnav.toast.btn.try"     : "Try it now",
    "newnav.toast.btn.dismiss" : "Dismiss",


    "newnav.feedbackModal.lvl.terrible" : "Terrible",
    "newnav.feedbackModal.lvl.ok"       : "Ok",
    "newnav.feedbackModal.lvl.great"    : "Great",
    "newnav.feedbackModal.title"        : "How was your experience?",
    "newnav.feedbackModal.subtitle"     : "We would love to hear your feedback to improve your experience!",
    "newnav.feedbackModal.skip"         : "Skip and don't show again",

    "newnav.feedbackModal.thankYou.title"    : "Thank you for your feedback!",
    "newnav.feedbackModal.thankYou.subtitle" : "We'll look at your comments to improve.",
    "newnav.feedbackModal.thankYou.btn"      : "Continue to old navigation",

    "newnav.recentlyViewed"        : "Recently Viewed",
    "newnav.noRecentlyViewed"      : "No Recently Viewed",
    "newnav.recentlyViewedHistory" : "Recent history",
}