import { FormikGroup } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, Label, ModalBody, ModalFooter } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { MembersContext } from "../../../../../contexts/MembersContext";
import AddressCard from "./AddressCard";
import { object, string } from 'yup';
import Skeleton from "react-loading-skeleton";
import { fail, success } from "@spordle/toasts";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";

const InvoiceEditAddress = ({ step, isLoading, previous, getInvoice, invoice, setIsLoading, toggle }) => {
    const [ memberAddresses, setMemberAddresses ] = useState(null);
    const { getMemberAddresses, updateMemberInvoice } = useContext(MembersContext);

    useEffect(() => {
        if(step === 2 && memberAddresses === null){
            getMemberAddresses(invoice.invoice_items[0]?.member.member_id)
                .then((addresses) => {
                    setMemberAddresses(addresses);
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                        setMemberAddresses([]);
                    }
                })
        }
    }, [ step ])

    return (
        <Formik
            initialValues={{
                memberAddressId: '',
            }}
            validationSchema={object().shape({
                memberAddressId: string().required(<Translate id='invoice.changeAddress.addresses.required' />),
            })}
            onSubmit={(values) => {
                const address = memberAddresses.find((address) => address.member_address_id === values.memberAddressId)

                setIsLoading(true);
                updateMemberInvoice(invoice.invoice_number, {
                    street: address?.street,
                    street_number: address?.street_number,
                    country_id: address?.country_id,
                    province_id: address?.province_id,
                    city: address?.city,
                    zip_code: address?.zip_code,
                    phone_number: address?.phones?.[0]?.phone_number || '',
                })
                    .then(async() => {
                        await getInvoice();
                        success({ msg: 'invoice.editModal.editAddress.success.msg', info: 'invoice.editModal.editAddress.success.info' })
                        toggle();
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            setIsLoading(false);
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <Label className="mb-3"><Translate id='invoice.changeAddress.addresses' /></Label>
                        {isLoading && memberAddresses === null ?
                            <Skeleton className="mb-1" width="100%" height={118} />
                            :
                            <FormikGroup name='memberAddressId' rowProps={{ className: 'mx-0 d-block' }}>
                                {memberAddresses?.map((address) => (
                                    <AddressCard key={address.member_address_id} address={address} />
                                ))}
                            </FormikGroup>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={isLoading || memberAddresses === null} className="mr-auto" color="primary" type="button" outline onClick={previous}><Translate id="misc.previous" /></Button>
                        <Button disabled={isLoading || memberAddresses === null} color='primary' type='submit'><Translate id='misc.confirm' /></Button>
                        <Button disabled={isLoading || memberAddresses === null} color='primary' outline type='button' onClick={toggle} className="ml-2"><Translate id='misc.cancel' /></Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
}

export default InvoiceEditAddress;