import { stringBuilder } from "@spordle/helpers";
import IncompleteProfile from "./IncompleteProfile";
import InvalidCrc from "./InvalidCrc";
import CriticalWaivers from "./CriticalWaivers";
import IncompleteAddress from "./IncompleteAddress";
import MissingDocBadge from "./MissingDocBadge";
import MissingRequiredFields from "./MissingRequiredFields";
import UnconfirmedMember from "./UnconfirmedMember";
import PendingRequest from "./PendingRequest";

const icons = {
    "crc": InvalidCrc,
    "profile": IncompleteProfile,
    "waivers": CriticalWaivers,
    "address": IncompleteAddress,
    "documents": MissingDocBadge,
    "fields": MissingRequiredFields,
    "unconfirmed": UnconfirmedMember,
    "pendingRequest": PendingRequest,
}

/**
 * @param {crc|profile|waivers|address|documents|fields} icon
 * @returns
 */
const MemberAlertsIcon = ({ icon, className, width, height }) => {
    const AlertIcon = icons[icon];

    if(AlertIcon){
        return (
            <span className={stringBuilder(className, "text-danger")}>
                <AlertIcon width={width || 10} height={height || 10} />
            </span>
        );
    }

    return null;

}

export default MemberAlertsIcon;