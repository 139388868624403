import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import { AppContext } from "../../contexts/contexts";
import { TourManagerContext } from "../tour/TourManager";
import Popover from "../popover/Popover";

/**
 * @param {string} quickTourName name of the quickTour (this is what goes in AppContext)
 * @param {string or component} title component for the title
 * @param {string or component} message message of the quickTour
 * @param {function} onClose function to run onClose
 * @description !IMPORTANT! you must add the quickTourName to the _defaultParams and _frontEndParamsSchema in AppContext.js
 */
const QuickTour = ({ children, quickTourName, title, message, onClose, disabled }) => {
    const { getFrontEndParams, isMobileDevice } = useContext(AppContext);

    const isSeenFirstTime = getFrontEndParams("tours")[quickTourName] == 1;

    return (isMobileDevice || !isSeenFirstTime || disabled) ?
        children
        :
        <QuickTourInner
            quickTourName={quickTourName}
            title={title}
            message={message}
            onClose={onClose}
        >
            {children}
        </QuickTourInner>
}

const QuickTourInner = ({ children, quickTourName, title, message, onClose }) => {
    const { queue, quickTourQueue, triggerQuickTour, endQuickTour } = useContext(TourManagerContext);
    const { updateParamsTours } = useContext(AppContext);

    const isUpdating = useRef(false);
    const [ queueIndex, setQueueIndex ] = useState(-2);

    const handleOnClose = () => {
        if(!isUpdating.current){
            isUpdating.current = true;
            updateParamsTours({ [quickTourName]: 0 }).catch(console.error);
            endQuickTour(quickTourName);
            onClose?.();
        }
    }

    useEffect(() => {
        if(queueIndex === -2)
            setQueueIndex(triggerQuickTour(quickTourName)); //will return -1 if the same is already in the queue

        () => {
            endQuickTour(quickTourName);
        }
    }, [ queueIndex, !!quickTourQueue?.[queueIndex], quickTourQueue.length, queue.length ]);

    const isOpen = !!quickTourQueue?.[queueIndex] && quickTourQueue?.[queueIndex] === quickTourName;
    return (
        <Popover
            isOpen={isOpen}
            toggle={handleOnClose}
            tip={
                <div className="small">
                    <div className='h5 font-bold'>
                        {title}
                    </div>
                    <div className='h6 mb-3'>{message}</div>
                    <Button
                        onClick={handleOnClose}
                        type="button"
                        color="primary"
                        size="sm"
                    >
                        <Translate id="tour.step.gotIt.btn" />
                    </Button>
                </div>
            }
        >
            <div>
                {children}
            </div>
        </Popover>
    )
}

export default QuickTour
