import Translate from "@spordle/intl-elements"
import { useContext, useState } from "react"
import { ModalHeader } from "reactstrap"
import AnalyticsModal from "../../../../../analytics/AnalyticsModal"
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI"
import OverlayLoader from "../../../../../components/loading/OverlayLoader"
import { PeriodsContext } from "../../../../../contexts/contexts"
import { OrganizationContext } from "../../../../../contexts/OrganizationContext"
import { DisplayI18n } from "../../../../../helpers/i18nHelper"
import OrgSelectionStep from "./addModal/components/OrgSelectionStep"

const AddOrganizationModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal isOpen={isOpen} analyticsName="addOrganizationModal">
            <AddOrganizationModalInner {...props} />
        </AnalyticsModal>
    )
}

const AddOrganizationModalInner = ({ toggle, organizationId, from, parentOrgId, affiliatedOrgs, onSuccess, ...props }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const orgContext = useContext(OrganizationContext);
    const periodContext = useContext(PeriodsContext);

    const createGrouping = (data, setStatus, isDisabled) => {
        setIsLoading(true);

        // filter on data.orgs is used to make sure that not all divisions that are being added are already added (in this case we don't post that organization)
        // since we don't post divisions which are already added to a specific org if they all already are added, we would send nothing which causes an error
        try{
            const affiliatedOrgs = data.orgs
                .filter((orgId) => !data.divisions.every((divId) => isDisabled(orgId, divId)))
                .reduce((newArray, orgId) => {
                    data.divisions.forEach(({ divisionId, categoryIds }) => {
                        if(categoryIds.length > 0){
                            categoryIds.forEach((categoryId) => {
                                if(!isDisabled(orgId, divisionId, categoryId)){
                                    newArray.push({
                                        linked_organisation_id: orgId,
                                        division_id: divisionId,
                                        team_category_id: categoryId,
                                        active: true,
                                    })
                                }
                            })
                        }else if(!isDisabled(orgId, divisionId)){
                            newArray.push({
                                linked_organisation_id: orgId,
                                division_id: divisionId,
                                active: true,
                            })
                        }
                    })
                    return newArray
                }, [])

            if(affiliatedOrgs.length === 0){
                throw{
                    isFrontEnd: true,
                    message: 'organization.grouping.addOrganizationModal.error.' + from,
                }
            }

            orgContext.createAffiliatedOrganization({
                organisation_id: organizationId,
                period_id: periodContext.selectedPeriod.period_id,
                affiliated_organisations: affiliatedOrgs,
            })
                .then(() => {
                    onSuccess();
                    toggle();
                    setIsLoading(false)
                })
        }catch(error){
            if(!AxiosIsCancelled(error.message)){
                setIsLoading(false)
                console.error(error)
                if(error.isFrontEnd){
                    setStatus(<Translate id={error.message} />)
                }else{
                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                }
            }
        }
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalHeader toggle={toggle}>
                <Translate id='organization.grouping.addOrganizationModal.title' />
            </ModalHeader>
            <OrgSelectionStep
                from={from}
                // formData to send the parent org id (used for tree)
                formData={{
                    newOrg: {
                        organisation_id_parent: parentOrgId,
                    },
                }}
                onSubmit={createGrouping}
                toggle={toggle}
                affiliatedOrgs={affiliatedOrgs}
                actionButtonText='misc.submit'
                organizationId={organizationId}
            />
        </OverlayLoader>
    )
}

export default AddOrganizationModal