import API_SPORDLE from "../API-Spordle";
import queryString from "query-string";
import { serverError } from "../CancellableAPI";
import { jsObjectToApi } from "@spordle/helpers";

/**
 * Create an ineligible member
 * @param {string} values Values required to create an ineligible member
 * @returns {Promise<string>}
 */
export function createIneligibleMember(values){
    const params = new URLSearchParams();

        params.append('organisation_id', values.organisation_id);
        params.append('first_name', values.first_name);
        params.append('last_name', values.last_name);
        params.append('member_status_reason_id', values.member_status_reason_id);
        params.append('note', values.note);
        params.append('organisation_deciding', values.organisation_deciding);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `members/permanently-ineligible` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_id;
                }
                throw response.data.errors[0];
            }, serverError)
}

/**
 * Create an ineligible member
 * @param {string} memberId
 * @param {object} values Values required to create an ineligible member
 * @param {string} values.organisation_id
 * @param {string} values.member_type_id
 * @param {string} values.period_id
 * @param {string} values.notes
 * @returns {Promise<string>}
 */
export function shareInOrganization(memberId, values){
    const params = new URLSearchParams();

    params.append('organisation_id', values.organisation_id);
    params.append('period_id', values.period_id);
    params.append('member_type_id', values.member_type_id);
    params.append('notes', values.notes);

    return API_SPORDLE.post(queryString.stringifyUrl({ url: `members/${memberId}/multi-organisations` }), params)
        .then((response) => {
            if(response.data.status){
                return response.data.member_id;
            }
            throw response.data.errors;
        }, serverError)
}


/**
 * Get the available divisions for a member
 * @param {string} memberId Member ID we want to get divisions for
 * @returns {Promise<string>}
 */
export function getMemberDivisions(memberId, params) {
    return API_SPORDLE.get(queryString.stringifyUrl({
        url: `/members/${memberId}/divisions`,
        query: params,
    }))
        .then((response) => {
            if (response.data.status) {
                // Lite Format
                const overage_divisions = response.data.overage_divisions || [];
                const underage_divisions = response.data.underage_divisions || [];
                const divisions: Record<string, Record<string, any>> = response.data.divisions || {};
                let full_current_division: Record<string, any> | null = null;

                // Build resources
                if(response.data.current_division_id && divisions[response.data.current_division_id]){
                    full_current_division = divisions[response.data.current_division_id];
                    full_current_division.division_id = response.data.current_division_id;
                }

                const full_overage_divisions = overage_divisions.reduce((newArray, div_id) => {
                    // one-dimensional array of just division_ids
                    if(divisions[div_id]){
                        newArray.push({
                            ...divisions[div_id],
                            division_id: div_id,
                        });
                    }

                    // ** No cleanup necessary as the main ressource is a simple uuid

                    return newArray;
                }, []);

                const full_underage_divisions = underage_divisions.reduce((newArray, div_id) => {
                    // one-dimensional array of just division_ids
                    if(divisions[div_id]){
                        newArray.push({
                            ...divisions[div_id],
                            division_id: div_id,
                        });
                    }

                    // ** No cleanup necessary as the main ressource is a simple uuid

                    return newArray;
                }, []);

                return {
                    current_division: full_current_division,
                    overage_divisions: full_overage_divisions,
                    underage_divisions: full_underage_divisions,
                }
            }
            throw response.data.errors;
        }, serverError)
}

export function createMemberAdministrativeSuspension(memberId, values = {}){
    const apiParams = new URLSearchParams();

    jsObjectToApi(values, apiParams);

    return API_SPORDLE.post(queryString.stringifyUrl({
        url: `/members/${memberId}/member-administrative-suspensions`,
    }), apiParams)
        .then((response) => {
            if(response.data.status){
                return response.data.member_suspension_id;
            }
            throw response.data.errors[0];
        }, serverError);
}