import { UAParser } from "ua-parser-js";

export const organisationCategoryUSA = [ "USA" ];
export const organisationCategoryIntl = [ "Member branches", "NSO", "International", "Member partner" ];
export const organisationCategoryIntlFinances = [ "NSO", "International" ];

// transfer statuses
// because we need to display them in a specific color
export const transferStatuses = {
    // green
    closed: 'd1e8c900-b7cc-11eb-b6f2-06b680167fa2',
    approvedOverride: 'c4f5ae89-1adf-11ec-a4ea-02537a7c0320',

    // yellow
    open: 'd1e8b839-b7cc-11eb-b6f2-06b680167fa2',
    pending: '25adefc8-b7cd-11eb-b6f2-06b680167fa2',
    pendingHCReview: '25ae05a2-b7cd-11eb-b6f2-06b680167fa2',
    pendingHCApproval: '25ae0f97-b7cd-11eb-b6f2-06b680167fa2',
    pendingUSAApproval: '25ae1b27-b7cd-11eb-b6f2-06b680167fa2',
    pendingHCBranchReview: '25ae2584-b7cd-11eb-b6f2-06b680167fa2',

    // red
    rejected: '25adfa76-b7cd-11eb-b6f2-06b680167fa2',
    rejectedOverride: 'c4f5c7f4-1adf-11ec-a4ea-02537a7c0320',
    expired: '32884dd6-b7cd-11eb-b6f2-06b680167fa2',
}

export const mobileView = 767;
export const tabletView = 991.98;
export const smallDesktopView = 1170;

const parser = new UAParser();
const device = parser?.getDevice();
export const isMobileDevice = device?.type === 'mobile' || device?.type === 'console' || device?.type === 'smarttv' || device?.type === 'wearable'