export default{
    "members.profile.overview.memberContacts.title"       : "Contacts",
    "members.profile.overview.memberContacts.edit.title"  : "Edit contacts",
    "members.profile.overview.memberContacts.edit.save"   : "Save changes",
    "members.profile.overview.memberContacts.edit.cancel" : "Cancel changes",

    // errors
    "members.profile.overview.memberContacts.error.contactType" : "Please specify the contact type of {count, plural, =0 {this member} one {this member} other {these members}}: {names}",

    // general
    "members.profile.overview.memberContacts.emergencyContact"            : "Emergency contact",
    "members.profile.overview.memberContacts.taxReceiptRecipient"         : "Issue Tax Receipt",
    "members.profile.overview.memberContacts.taxReceiptRecipient.tooltip" : "This contact will receive the tax receipts by email.",
    "members.profile.overview.memberContacts.allow_communication"         : "Allow communication",
    "members.profile.overview.memberContacts.allow_communication.tooltip" : "This contact will receive communications by email.",
    "members.profile.overview.memberContacts.contactTypes"                : "Contact type",
    "members.profile.overview.memberContacts.contactTypes.required"       : "Contact type is required",
    "members.profile.overview.memberContacts.addPhone"                    : "Add phone number",
    "members.profile.overview.memberContacts.lastUpdated"                 : "Last updated",
    "members.profile.overview.memberContacts.sort.emergency"              : "Emergency contact",
    "members.profile.overview.memberContacts.sort"                        : "Sorting",

    // table specifics
    "members.profile.overview.memberContacts.table.noData" : "No contacts",

    // add modal specifics
    "members.profile.overview.memberContacts.addContact.title"         : "Add contact",
    "members.profile.overview.memberContacts.addContact.tabs.new"      : "New contact",
    "members.profile.overview.memberContacts.addContact.tabs.deleted"  : "Removed contact",
    "members.profile.overview.memberContacts.addContact.alreadyExists" : "A contact already exists with this information",
    "members.profile.overview.memberContacts.addContact.3078"          : "The contact type could not be found",
    "members.profile.overview.memberContacts.addContact.3172"          : "The phone type could not be found",
    "members.profile.overview.memberContacts.addContact.3229"          : "The organization does not match",
    "members.profile.overview.memberContacts.addContact.success"       : "Contact added!",

    "members.profile.overview.memberContacts.addContact.updateEmail" : "This member does not have an email address, do you want to add this one?",
    "members.profile.overview.memberContacts.addContact.confirm"     : "Member's email address has been updated!",

    // side panel specifics
    "members.profile.overview.memberContacts.sidePanel.contactType.required"      : "A contact type is required. Please add one before proceeding with other changes.",
    "members.profile.overview.memberContacts.sidePanel.phoneNumbers"              : "Phone number(s)",
    "members.profile.overview.memberContacts.sidePanel.phoneNumbers.none"         : "No phone numbers",
    "members.profile.overview.memberContacts.sidePanel.emergency.no"              : "This contact is not an emergency contact",
    "members.profile.overview.memberContacts.sidePanel.emergency.yes"             : "This contact is an emergency contact",
    "members.profile.overview.memberContacts.sidePanel.tax_receipt_recipient.yes" : "This contact is a tax receipt recipient",
    "members.profile.overview.memberContacts.sidePanel.tax_receipt_recipient.no"  : "This contact is not a tax receipt recipient",
    "members.profile.overview.memberContacts.sidePanel.allow_communication.yes"   : "This contact will receive communications",
    "members.profile.overview.memberContacts.sidePanel.allow_communication.no"    : "This contact won't receive communications",
    "members.profile.overview.memberContacts.sidePanel.emergency.phone"           : "At least one phone number must be specified to activate this option",
    "members.profile.overview.memberContacts.sidePanel.remove.text"               : "You are about to delete this contact.",
    "members.profile.overview.memberContacts.sidePanel.remove.success"            : "Contact deleted!",
    "members.profile.overview.memberContacts.sidePanel.edit.success"              : "Contact updated!",
    "members.profile.overview.memberContacts.sidePanel.contactInfo"               : "Contact info",
    "members.profile.overview.memberContacts.sidePanel.identity"                  : "Linked identity",
    "members.profile.overview.memberContacts.sidePanel.identity.none"             : "No identity linked",
    "members.profile.overview.memberContacts.sidePanel.identity.modal.title"      : "Link an identity",
    "members.profile.overview.memberContacts.sidePanel.identity.modal.link"       : "Link",
}