export default{
    // General
    "onlineStore.components.onlineStoreItemsFees.section.name"           : "Ajouter des articles et des frais",
    "onlineStore.components.onlineStoreItemsFees.status.SOLD_OUT"        : "Vendu",
    "onlineStore.components.onlineStoreItemsFees.status.ACTIVE"          : "Actif",
    "onlineStore.components.onlineStoreItemsFees.status.INACTIVE"        : "Inactif",
    "onlineStore.components.onlineStoreItemsFees.status.SUSPENDED"       : "Suspendu",
    "onlineStore.components.onlineStoreItemsFees.validation.oneRequired" : "Vous devez ajouter au moins une inscription ou au moins un autre frais.",

    "onlineStore.components.onlineStoreItemsFees.registrationFee.inactive" : "Le frais d'inscription est inactif",
    "onlineStore.components.onlineStoreItemsFees.registrationFee.goTo"     : "Aller au catalogue / frais d'inscription",
    "onlineStore.components.onlineStoreItemsFees.membershipFees.warning"   : "Configuration des frais d'affiliation nécessaire",
    "onlineStore.components.onlineStoreItemsFees.membershipFees.tooltip"   : "Les frais d'affiliation doivent être configurés pour la division et le type de membre pour l'organisation actuelle ainsi que pour toutes les organisations parents.",
    "onlineStore.components.onlineStoreItemsFees.membershipFees.goTo"      : "Gérer les frais de membre",
    "onlineStore.components.onlineStoreItemsFees.otherFee.inactive"        : "L'article est inactif",
    "onlineStore.components.onlineStoreItemsFees.otherFee.goTo"            : "Aller au catalogue / Autres frais",
    "onlineStore.components.onlineStoreItemsFees.item"                     : "Article",
    "onlineStore.components.onlineStoreItemsFees.add.activity.periods"     : "Ceci est la période d'activité définie sur cet article.",

    // Registration section
    "onlineStore.components.onlineStoreItemsFees.registration.title"                          : "Inscriptions",
    "onlineStore.components.onlineStoreItemsFees.registration.table.registration"             : "Inscription",
    "onlineStore.components.onlineStoreItemsFees.registration.table.price"                    : "Prix",
    "onlineStore.components.onlineStoreItemsFees.registration.table.registration.sold"        : "{amount} vendus",
    "onlineStore.components.onlineStoreItemsFees.registration.table.registration.available"   : "{amount} place(s) disponible(s)",
    "onlineStore.components.onlineStoreItemsFees.registration.table.registration.unlimited"   : "Places illimitées",
    "onlineStore.components.onlineStoreItemsFees.registration.table.status"                   : "Statut",
    "onlineStore.components.onlineStoreItemsFees.registration.table.display_order"            : "Ordre d'affichage",
    "onlineStore.components.onlineStoreItemsFees.registration.table.hint1"                    : "Vous pouvez ajouter des frais d'inscription.",
    "onlineStore.components.onlineStoreItemsFees.registration.table.hint2"                    : "Cliquez ici pour ajouter un frais d'inscription",
    "onlineStore.components.onlineStoreItemsFees.registration.table.empty"                    : "Aucune inscription",
    "onlineStore.components.onlineStoreItemsFees.registration.table.items_sold"               : "Articles vendus",
    "onlineStore.components.onlineStoreItemsFees.registration.modal.title"                    : "Ajouter des inscriptions",
    "onlineStore.components.onlineStoreItemsFees.registration.modal.season.label"             : "Saison",
    "onlineStore.components.onlineStoreItemsFees.registration.modal.registration.label"       : "Sélectionnez les inscriptions",
    "onlineStore.components.onlineStoreItemsFees.registration.modal.registration.placeholder" : "Sélectionnez les inscriptions",
    "onlineStore.components.onlineStoreItemsFees.registration.modal.registration.empty"       : "Aucune inscription",
    "onlineStore.components.onlineStoreItemsFees.registration.modal.registration.textWhen"    : "{count} inscriptions sélectionnées",
    "onlineStore.components.onlineStoreItemsFees.registration.sidePanel.date.label"           : "Date",
    "onlineStore.components.onlineStoreItemsFees.registration.sidePanel.time.label"           : "Heure",
    "onlineStore.components.onlineStoreItemsFees.registration.sidePanel.early.label"          : "Inscription anticipée (avant)",
    "onlineStore.components.onlineStoreItemsFees.registration.sidePanel.late.label"           : "Inscription tardive (après)",

    // Other items section
    "onlineStore.components.onlineStoreItemsFees.otherItems.title"                         : "Autres frais",
    "onlineStore.components.onlineStoreItemsFees.otherItems.table.item"                    : "Article",
    "onlineStore.components.onlineStoreItemsFees.otherItems.table.status"                  : "Statut",
    "onlineStore.components.onlineStoreItemsFees.otherItems.table.items_sold"              : "Articles vendus",
    "onlineStore.components.onlineStoreItemsFees.otherItems.table.price"                   : "Prix",
    "onlineStore.components.onlineStoreItemsFees.otherItems.table.display_order"           : "Ordre d'affichage",
    "onlineStore.components.onlineStoreItemsFees.otherItems.table.hint1"                   : "Vous pouvez ajouter d'autres frais.",
    "onlineStore.components.onlineStoreItemsFees.otherItems.table.hint2"                   : "Cliquez ici pour ajouter un autre frais",
    "onlineStore.components.onlineStoreItemsFees.otherItems.table.empty"                   : "Aucun autre frais",
    "onlineStore.components.onlineStoreItemsFees.otherItems.modal.title"                   : "Ajouter un article",
    "onlineStore.components.onlineStoreItemsFees.otherItems.modal.searchLabel"             : "Rechercher un article",
    "onlineStore.components.onlineStoreItemsFees.otherItems.modal.selectedItems"           : "{count} article(s) sélectionné(s)",
    "onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.link.label"          : "Rendre obligatoire avec un frais d'inscription",
    "onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.column.registration" : "Inscription",
    "onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.column.mandatory"    : "Obligatoire",
    "onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.column.link"         : "Lier",
    "onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.column.link.all"     : "Tout obligatoire",
    "onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.column.unlink.all"   : "Tout décocher",
}