import Translate from '@spordle/intl-elements';
import {
    Button,
    FormGroup,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import { FormikCheckedButton } from '@spordle/formik-elements';
import { Form, Formik } from 'formik';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';

const MemberProfileRegistrationConfirmWaivers = ({ isOpen, toggle }) => {

    return (
        <Formik
            initialValues={{

            }}
        >
            {(formik) => (
                // Don't forget to use formatAndSyncRows from MemberProfileRegistrationSidepanel instead of forcing a refresh of the table
                <Form>
                    <AnalyticsModal isOpen={isOpen} analyticsName='MemberProfileRegistrationConfirmWaivers'>
                        <ModalHeader tag="div" toggle={toggle}>
                            <Translate id='memberProfile.registration.sidepanel.confirmWaivers' />
                        </ModalHeader>
                        <ModalBody>
                            {/* foreach org */}
                            <FormGroup>
                                <div className="font-medium h5">Hockey Quebec</div>
                                {/* if waiver is already signed */}
                                <div className="d-flex align-items-center">
                                    <i className="mdi mdi-check text-success" />
                                    <span className="ml-2">Politique visant la vie privé (2.0) (v3)</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <i className="mdi mdi-check text-success" />
                                    <span className="ml-2">Politique visant la vie privé (2.0) (v3)</span>
                                </div>

                                {/* if waiver has not been signed */}
                                <FormikCheckedButton className="mb-1" id='waiver-1' name='waiver-1' label='Politique visant la vie privé (2.0) (v3)' translateLabel={false} />

                            </FormGroup>
                            {/* end foreach org */}
                            <p className="mb-0 small"><Translate id='memberProfile.registration.confirmWaivers.hint' /></p>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='primary' onClick={toggle} outline><Translate id='misc.cancel' /></Button>
                            <Button color='primary' onClick={toggle} className="ml-2"><Translate id='misc.save' /></Button>
                        </ModalFooter>
                    </AnalyticsModal>
                </Form>
            )}
        </Formik>
    );
}

export default MemberProfileRegistrationConfirmWaivers;