import React from "react";
import {
    Row,
    Col,
    FormGroup,
    Label
} from "reactstrap";
import { FormikDateTime, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import FormikEditable from "../../../../components/formik/FormikEditable";
import RebatesTypes from "./RebatesTypes";
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import moment from 'moment';
import * as Yup from 'yup';

// Contexts
import { RebatesContext } from "../../../../contexts/RebatesContext";
import withContexts from "../../../../helpers/withContexts";
import { I18nHelperContext, RenderI18nForm, DisplayI18n } from "../../../../helpers/i18nHelper";
import { RolesContext } from "../../../../contexts/RolesContext";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import SidePanel from "../../../../components/sidePanel/SidePanel";
import { fail, success } from '@spordle/toasts';
import RebatesEditPostalCodes from "./RebatesEditPostalCodes";

class RebatesSidepanel extends React.Component{
    state = {
        isLoading: false,
        editPostalCodesOpen: false,
    }

    toggleEditPostalCodes = () => {
        this.setState((prevState) => ({ editPostalCodesOpen: !prevState.editPostalCodesOpen }))
    }

    toggleState = (bool) => {
        this.setState((prevState) => ({ isLoading: bool ?? !prevState.isLoading }))
    };

    render(){
        const canEdit = this.props.RolesContext.canDoAction("EDIT", "catalog", "rebate");
        return (
            <OverlayLoader isLoading={this.state.isLoading}>

                <RebatesEditPostalCodes
                    isOpen={this.state.editPostalCodesOpen}
                    toggle={this.toggleEditPostalCodes}
                    sidepanelProps={this.props}
                />

                <SidePanel.Header>
                    <div className='d-flex mb-2'>
                        <SidePanel.ToggleButton />
                        <SidePanel.ActionsMenu action='DELETE' componentCode='catalog' componentPermissionCode='rebate'>
                            <SidePanel.MenuDelete
                                translateModalMsg
                                modalMsg='catalog.rebates.sidePanel.delete'
                                modalTitle={<DisplayI18n field='title' i18n={this.props.selectedRows[0].i18n} defaultValue={this.props.selectedRows[0].title} />}
                                onConfirm={() => {
                                    return this.props.RebatesContext.deleteRebate(this.props.getCommonValue('rebate_id'))
                                        .then(() => {
                                            this.props.tableRef.deleteRow(this.props.getCommonValue('rebate_id')).then(this.props.toggle)
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                }}
                            />
                        </SidePanel.ActionsMenu>
                    </div>
                    <SidePanel.Title>
                        <DisplayI18n
                            field='title'
                            i18n={this.props.selectedRows[0].i18n}
                            defaultValue={this.props.selectedRows[0].title}
                        />
                    </SidePanel.Title>
                </SidePanel.Header>
                <div className="p-3">
                    <I18nHelperContext.Consumer>
                        {(i18nHelper) => (
                            <Row form>
                                <RenderI18nForm field="title">
                                    {({ fieldName, fieldLabel }) => (
                                        <Col md="6" key={fieldName} className="form-group">
                                            <Label for={fieldName} className="text-muted">{fieldLabel}</Label>
                                            <FormikEditable
                                                id={fieldName}
                                                disabled={!canEdit}
                                                initialValues={
                                                    i18nHelper.getInitialValues(this.props.selectedRows[0])
                                                }
                                                validationSchema={Yup.object().shape(
                                                    i18nHelper.getValidationSchema({ title: Yup.string().required(<Translate id='catalog.rebates.form.validation.title.required' />) }),
                                                )}
                                                onSubmit={(values) => {
                                                    const newValues = this.props.createNewValues(values);
                                                    this.toggleState(true);

                                                    this.props.RebatesContext.partialUpdateRebate(this.props.getCommonValue('rebate_id'), i18nHelper.getAPIValues(values))
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            success();
                                                            this.toggleState(false);
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                                this.toggleState(false);
                                                            }
                                                        })
                                                }}
                                            >
                                                {(isEditing) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className='font-medium text-dark'>
                                                                <DisplayI18n
                                                                    field={fieldName}
                                                                    defaultValue={this.props.selectedRows[0].title || '-'}
                                                                    i18n={this.props.selectedRows[0].i18n}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikInputText id={fieldName} name={fieldName} autoFocus />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </Col>
                                    )}
                                </RenderI18nForm>
                            </Row>
                        )}
                    </I18nHelperContext.Consumer>
                    <Row form>
                        <Col sm="6">
                            <FormGroup>
                                <Label for='expiration_date' className='text-muted mb-0'><Translate id='catalog.rebates.form.expDate' /></Label>
                                <FormikEditable
                                    id='expiration_date'
                                    disabled={!canEdit}
                                    initialValues={{
                                        expiration_date: this.props.selectedRows[0].expiration_date ? moment(this.props.selectedRows[0].expiration_date) : '',
                                    }}
                                    validationSchema={Yup.object().shape({
                                        expiration_date: Yup.mixed().required()
                                            .test({
                                                name: 'dateIsValid',
                                                message: <Translate id='form.validation.date.format' />,
                                                test: function(val){
                                                    return moment.isMoment(val)
                                                },
                                            }),
                                    })}
                                    onSubmit={(values) => {
                                        if(this.props.selectedRows[0].expiration_date !== values.expiration_date){
                                            this.toggleState(true);
                                            const newValues = this.props.createNewValues(values);

                                            this.props.RebatesContext.partialUpdateRebate(this.props.selectedRows[0].rebate_id, { expiration_date: values.expiration_date.toISOString() })
                                                .then(() => {
                                                    this.props.syncRows(newValues);
                                                    success();
                                                    this.toggleState(false);
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                        this.toggleState(false);
                                                    }
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>{this.props.selectedRows[0].expiration_date ? moment(this.props.selectedRows[0].expiration_date).format('YYYY-MM-DD') : '-'}</div>
                                            )
                                        }
                                        return (
                                            <FormikDateTime
                                                inputProps={{ className: "right-0" }}
                                                className="right-0"
                                                name='expiration_date'
                                                id='expiration_date'
                                                timeFormat={false}
                                                dateFormat='YYYY-MM-DD'
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                <Label for='active' className='text-muted mb-0'><Translate id='catalog.rebates.form.status' /></Label>
                                <FormikEditable
                                    id='active'
                                    noConfirmation
                                    disabled={!canEdit}
                                    initialValues={{
                                        active: this.props.selectedRows[0].active,
                                    }}
                                    onSubmit={(values) => {
                                        if(values.active !== this.props.selectedRows[0].active){
                                            this.toggleState(true);
                                            const newValues = this.props.createNewValues(values);

                                            this.props.RebatesContext.partialUpdateRebate(this.props.selectedRows[0].rebate_id, { active: values.active })
                                                .then(() => {
                                                    this.props.syncRows(newValues);
                                                    success();
                                                    this.toggleState(false);
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                        this.toggleState(false);
                                                    }
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing, options) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>
                                                    <Translate id={`misc.${this.props.selectedRows[0].active === "1" ? 'active' : 'inactive' }`} />
                                                </div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                search={false}
                                                name='active'
                                                id='active'
                                                autoFocus
                                                menuIsDefaultOpen
                                                onOptionSelected={options.stopEditing}
                                                loadingStatus='success'
                                                defaultData={[
                                                    {
                                                        label: 'misc.active',
                                                        translateLabel: true,
                                                        value: '1',
                                                    },
                                                    {
                                                        label: 'misc.inactive',
                                                        translateLabel: true,
                                                        value: '0',
                                                    },
                                                ]}
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    <Row form>
                        <Col sm="6" className="form-group">
                            <div className="text-muted"><Translate id='catalog.rebates.form.type' /></div>
                            <div className="font-medium text-dark">
                                <Translate
                                    id={this.props.selectedRows[0].type == RebatesTypes.type.promo.tag ?
                                        RebatesTypes.type.promo.label
                                        : this.props.selectedRows[0].type == RebatesTypes.type.multi.tag ?
                                            RebatesTypes.type.multi.label
                                            :
                                            RebatesTypes.type.postalCode.label
                                    }
                                />
                            </div>
                        </Col>
                        {this.props.selectedRows[0].type === RebatesTypes.type.postalCode.tag &&
                            <Col sm="6" className="form-group">
                                <div className="text-muted"><Translate id='catalog.rebates.form.postalCodesLabel' /></div>
                                <div className='d-flex font-medium text-dark editable' onClick={() => this.toggleEditPostalCodes()}>
                                    <Translate id='catalog.rebates.form.postalCodes' values={{ number: this.props.selectedRows[0].rules[0].postal_codes.length }} />
                                    <i className="text-primary mdi mdi-pencil ml-1" />
                                </div>
                            </Col>
                        }
                        {this.props.selectedRows[0].type !== RebatesTypes.type.promo.tag ?
                            <Col sm="12">
                                <div className="text-muted"><Translate id='catalog.rebates.form.rules' /></div>
                                <ul className="list-unstyled">
                                    {this.props.selectedRows[0].rules.map((rule, key) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <li key={key} className="font-medium text-dark">
                                            <Translate id='catalog.rebates.table.multi.rule.1' />
                                            {this.props.selectedRows[0].mode === RebatesTypes.mode.amount.tag ?
                                                <CurrencyFormat value={rule.rule_value / 100} />
                                                :
                                                <>{rule.rule_value}%</>
                                            }
                                            <Translate id='catalog.rebates.table.multi.rule.2' values={{ nbParticipant: rule.rule_registration_number }} />
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                            :
                            <>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='catalog.rebates.form.discount' /></div>
                                        <div className="font-medium text-dark">
                                            {this.props.selectedRows[0].mode === RebatesTypes.mode.amount.tag ?
                                                <CurrencyFormat value={this.props.selectedRows[0].value / 100} />
                                                :
                                                <>{this.props.selectedRows[0].value}%</>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col sm="12">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='catalog.rebates.form.promoCode' /></div>
                                        <div className="font-medium text-dark">{this.props.selectedRows[0].promo_code}</div>
                                    </div>
                                </Col>
                            </>
                        }
                    </Row>
                </div>
            </OverlayLoader>
        )
    }
}

export default withContexts(RebatesContext, RolesContext)(RebatesSidepanel);