import ManageSingleNews from "../../views/news/manageSingleNews/ManageSingleNews";
import News from "../../views/news/News";

// Header
const newsRoutes = [
    {
        exact: true,
        name: "sidebar.news",
        path: '/news/create',
        skipRecents: true,
        permissions: { code: 'communications', permissionCode: 'communication_news_manage', actions: [ 'ADD' ] },
        component: ManageSingleNews,
    },
    {
        exact: true,
        name: "sidebar.news",
        path: '/news/edit',
        skipRecents: true,
        permissions: { code: 'communications', permissionCode: 'communication_news_manage', actions: [ 'EDIT' ] },
        component: ManageSingleNews,
    },
    {
        name: "sidebar.news",
        path: '/news',
        component: News,
    },
];
export default newsRoutes;
