import { useContext } from 'react';
import { IdentityRolesContext } from '../../contexts/IdentityRolesContext';
import { stringBuilder } from "@spordle/helpers";
import { Tooltip } from '@mantine/core';

/**
 * Display the children only if the user is admin OR if we are on dev/int. This is a JSX implementation of IdentityRolesContext.isAdmin method.
 * It always apply the class `text-purple`
 * "is_admin" is for users that can overwrite permissions that are "System".
 * This feature is only used for Settings->Platform->Roles.
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @param {boolean} props.extraValidation
 * @returns
 */
const IsAdmin = ({ tag: Tag = 'div', children, extraValidation, ...props }) => {
    const identityRole = useContext(IdentityRolesContext);


    return identityRole.isAdmin() && (extraValidation == null ? true : extraValidation) &&
        <Tag {...props} className={stringBuilder('text-purple', props.className)}>
            <Tooltip
                withArrow
                position='top'
                label={'Admin only'}
            >
                <span>
                    {children}
                </span>
            </Tooltip>
        </Tag>
}

export default IsAdmin;