import Translate from "@spordle/intl-elements";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import { DisplayCategory } from "../../teams/TeamHelpers";

export function displayName(teamSettings){
    const teamSettingCategory = teamSettings[0].setting.team_setting_category
    if(teamSettingCategory.division){
        return (
            <>
                <DisplayI18n
                    field='short_name'
                    defaultValue={teamSettingCategory.division.short_name}
                    i18n={teamSettingCategory.division.i18n}
                />
            </>
        )
    }
    return (
        <>
            <DisplayCategory category={teamSettingCategory.team_category} short />
            {teamSettingCategory.team_category.gender &&
                <div className='small text-muted'>
                    <Translate
                        id={`form.fields.gender.${teamSettingCategory.team_category.gender.toLowerCase()}`}
                        defaultMessage={teamSettingCategory.team_category.gender}
                    />
                </div>
            }
        </>
    )
}

/**
 * Represents a list of team settings codes to be displayed in the following order.
 * If the restriction's code is not in the list, it should be displayed after the following list in an order that doesn't matter
 */
export const RESTRICTION_ORDER = [
    'player_allotment',
    'maximum_number_players',
    'maximum_import_registrations',
    'maximum_affiliate_registrations',
    'maximum_released_registrations',
    'maximum_tryout_registrations',
]

/**
 * Will sort the team settings codes according to the `RESTRICTION_ORDER` list.
 * This function can be passed to the sort function from Arrays
 * @param {string} settingCodeA
 * @param {string} settingCodeB
 * @returns {number}
 * @example
 * someTeamRestrictionCodesArray.sort(sortRestrictionKeys)
 */
export function sortRestrictionKeys(settingCodeA, settingCodeB){
    const settingAOrder = RESTRICTION_ORDER.indexOf(settingCodeA);
    const settingBOrder = RESTRICTION_ORDER.indexOf(settingCodeB);

    const actualSettingAOrder = settingAOrder == -1 ? RESTRICTION_ORDER.length : settingAOrder;
    const actualSettingBOrder = settingBOrder == -1 ? RESTRICTION_ORDER.length : settingBOrder;

    return actualSettingAOrder - actualSettingBOrder;
}