export default{
    "organization.settings.organizationTeamCategoryTypes.tab.title" : "Types de catégories d'équipe",

    "organization.settings.organizationTeamCategoryTypes.table.active" : "Active",
    "organization.settings.organizationTeamCategoryTypes.table.empty"  : "Pas de type de catégorie d'équipe",

    // add
    "organization.settings.organizationTeamCategoryTypes.add.title"           : "Ajouter un type de catégorie d'équipe",
    "organization.settings.organizationTeamCategoryTypes.add.status"          : "Statut",
    "organization.settings.organizationTeamCategoryTypes.add.status.active"   : "Actif",
    "organization.settings.organizationTeamCategoryTypes.add.status.inactive" : "Inactif",
    "organization.settings.organizationTeamCategoryTypes.add.status.required" : "Le statut est requis",

    //sidepanel
    "organization.settings.sidePanelOrganizationTeamCategoryTypes.title" : "Informations de type de catégorie d'équipe",

    "organization.settings.sidePanelOrganizationTeamCategoryTypes.status"          : "Statut",
    "organization.settings.sidePanelOrganizationTeamCategoryTypes.status.active"   : "Actif",
    "organization.settings.sidePanelOrganizationTeamCategoryTypes.status.inactive" : "Inactif",
    "organization.settings.sidePanelOrganizationTeamCategoryTypes.status.required" : "Le Statut est requis",

    "organization.settings.sidePanelOrganizationTeamCategoryTypes.update.success.text" : "Type de catégorie d'équipe mise à jour",
    "organization.settings.sidePanelOrganizationTeamCategoryTypes.update.fail.text"    : "Une erreur est survenue lors de la mise à jour du type de catégorie, veuillez rafraichir la page et réessayer",

    // actions
    "organization.settings.sidePanelOrganizationTeamCategoryTypes.actions.remove"         : "Retirer",
    "organization.settings.sidePanelOrganizationTeamCategoryTypes.actions.remove.topText" : "Vous êtes sur le point de retirer ce type de catégorie d'équipe de votre organisation",

    // tabs
    "organization.settings.sidePanelOrganizationTeamCategoryTypes.tabs.details" : "Détails",

}