import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from "@spordle/datatables";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import { Button, Card } from "reactstrap";
import SpordlePanelTable from "../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../components/table/EmptyLayout";
import UserDisplay from "../../../components/userDisplay/UserDisplay";
import DiscriminationAddModal from "./components/modal/DiscriminationAddModal";
import DiscriminationSidePanel from "./components/sidePanel/DiscriminationSidePanel";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { stringBuilder } from "@spordle/helpers";
import CanDoAction from "../../../components/permissions/CanDoAction";
import { RolesContext } from "../../../contexts/RolesContext";
import { AxiosCancelDiscriminationCalls, AxiosIsCancelled } from "../../../api/CancellableAPI";
import { ClaimStatus, DiscriminationOutcomes, DiscriminationReasons, isAnonymousComplaint } from "./components/DiscriminationHelper";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import { IdentityRolesContext } from "../../../contexts/IdentityRolesContext";
import { PeriodChangeHandler } from "../../../helpers/periodHelper";
import { fail } from "@spordle/toasts";
import ViewHeaderV2 from "../../../components/viewHeader/ViewHeaderV2";
import OpenSidePanelOnSearch from "../../../components/sidePanel/OpenSidePanelOnSearch";
import DiscriminationSearch from "./components/DiscriminationSearch";
import useSavedSearch from "../../../components/customHooks/useSavedSearch";
import { I18nContext } from "../../../contexts/I18nContext";
import { DisplayCategory } from "../../teams/TeamHelpers";
import RecentMaltreatmentComplaints from "./components/maltreatmentOverview/RecentMaltreatmentComplaints";
import DiscriminationContextProvider from "./components/DiscriminationContext";

const Discrimination = () => {
    const [ addModalIsOpen, setAddModalIsOpen ] = useState(false);
    const [ status, setStatus ] = useState([]);
    const { getDiscriminations, getDiscriminationStatus, organisation_id } = useContext(OrganizationContext);
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const canEdit = canDoAction("EDIT", "organization", "discrimination_claims") || isGod();
    const { identity_role_id } = useContext(IdentityRolesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { savedSearch, clearSavedSearch, saveSearch } = useSavedSearch(`maltreatment-search-filters-${organisation_id}-${identity_role_id}`);

    useEffect(() => {
        getDiscriminationStatus({ active: 1 })
            .then(setStatus)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, []);


    return (
        <>
            {/* Task View Header */}
            <ViewHeaderV2 title='discrimination.title' />

            <div className="px-4">
                <SpordlePanelTable
                    id='TasksDiscriminationTable'
                    dataIndex='discrimination_id'
                    allowOutsideClick
                    sidePanel={(props) => <DiscriminationSidePanel status={status} canEdit={canEdit} {...props} />}
                    table={(panelProps) => (
                        <SpordleTableProvider
                            id='discrimination_table'
                            tableHover bordered striped
                            clickable
                            ref={panelProps.spordleTableRef}
                            dataIndex='discrimination_id'
                            searchKeys={[
                                { name: "sequential_number", weight: 2 },
                                { name: "reference_number", weight: 2 },
                                'discrimination_id',
                                'complaint_by_first_name',
                                'complaint_by_last_name',
                                'complaint_by_member.unique_identifier',
                                'discrimination_reason',
                                `maltreatment_outcomes.outcome.i18n.${getGenericLocale()}.name`,
                                'complaint_by',
                                'incident_date',
                                `discrimination_member.first_name`,
                                `discrimination_member.last_name`,
                                `discrimination_member.member.unique_identifier`,
                            ]}
                            filterJSCallback={(maltreatment, filters) => {
                                if(!!filters.status && maltreatment.discrimination_status?.discrimination_status_id !== filters.status){
                                    return false;
                                }

                                if(!!filters.type && maltreatment.maltreatment_type?.maltreatment_type_id !== filters.type){
                                    return false;
                                }

                                if(filters.outcomes){
                                    if(!Array.isArray(maltreatment.maltreatment_outcomes)){
                                        return false;
                                    }

                                    if(!maltreatment.maltreatment_outcomes.some(({ outcome }) => outcome.outcome_id == filters.outcomes)){
                                        return false;
                                    }
                                }

                                return true;
                            }}
                            defaultSorting={savedSearch?.sort != undefined && typeof savedSearch.sort === "string" ? savedSearch.sort : '-sequential_number'}
                            initFilter={savedSearch?.filters || {
                                status: '',
                                type: '',
                                outcomes: '',
                            }}
                            emptyLayout={<EmptyLayout />}
                            desktopWhen
                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                            loadData={(from, { filters }, spordleTable) => {
                                switch (from){
                                    case 'FILTER':
                                        saveSearch({
                                            filters: filters,
                                            sort: spordleTable.state.orderingBy.column ? `${spordleTable.state.orderingBy.order === "DESC" ? "+" : "-"}${spordleTable.state.orderingBy.column}` : "",
                                        });
                                        break;
                                    case 'REFRESH':
                                        spordleTable.setLoading()
                                    case 'CDM':
                                        AxiosCancelDiscriminationCalls();
                                        return getDiscriminations();
                                    default:
                                        break;
                                }
                            }}
                            columns={[
                                {
                                    label: '#',
                                    key: 'sequential_number',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='discrimination.global.incidentDate' />,
                                    key: 'incident_date',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='discrimination.label.submittedBy' />,
                                    key: 'created_by',
                                    sortKey: 'complaint_by_first_name',
                                    fallbackSortKey: 'complaint_by_last_name',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='discrimination.label.complainees' />,
                                    key: 'target',
                                    sortable: false,
                                },
                                {
                                    label: <Translate id='discrimination.global.discriminatoryGrounds' />,
                                    key: 'origin',
                                    sortable: false,
                                },
                                {
                                    label: <Translate id='discrimination.label.outcomes' />,
                                    key: 'outcomes',
                                    sortable: false,
                                },
                                {
                                    label: <Translate id="misc.status" />,
                                    key: 'status',
                                    sortable: false,
                                },
                            ]}
                            renderRow={(columnKey, complaint) => {
                                switch (columnKey){
                                    case 'sequential_number':
                                        return (
                                            <span className="text-nowrap">
                                                <div className="font-medium">
                                                    {complaint.sequential_number}
                                                </div>
                                                {complaint.reference_number &&
                                                    <div className="font-medium text-muted small mt-2">
                                                        <Translate id="discrimination.global.itpReferenceNumber" />:
                                                        <div>#{complaint.reference_number}</div>
                                                    </div>
                                                }
                                            </span>
                                        )
                                    case 'incident_date':
                                        return (
                                            <span className="text-nowrap font-medium">
                                                <DateFormat value={complaint.incident_date} />
                                            </span>
                                        )
                                    case 'created_by':
                                        return (
                                            isAnonymousComplaint(complaint) ?
                                                <UserDisplay.Title><Translate id='gameIncident.label.anonymous' /> <i className="mdi mdi-lock-outline" /></UserDisplay.Title>
                                                :
                                                <>
                                                    {complaint.complaint_by &&
                                                        <UserDisplay.Subtitle><Translate id={'discrimination.global.roles.' + complaint.complaint_by} /></UserDisplay.Subtitle>
                                                    }
                                                    <UserDisplay.Title>
                                                        {complaint.complaint_by_first_name} {complaint.complaint_by_last_name}
                                                    </UserDisplay.Title>
                                                    {complaint.complaint_by_member?.unique_identifier && <UserDisplay.Subtitle>#{complaint.complaint_by_member.unique_identifier}</UserDisplay.Subtitle>}

                                                </>
                                        )
                                    case 'target':
                                        const members = (complaint.discrimination_member || []).filter((member) => member.first_name);
                                        return (
                                            members.length > 0 ?
                                                <>
                                                    <UserDisplay.Subtitle className="text-uppercase mb-2"><Translate id='discrimination.label.members' /></UserDisplay.Subtitle>
                                                    {
                                                        members
                                                            .filter((member) => member.first_name)
                                                            .map((accused, index) => (
                                                                <UserDisplay className={stringBuilder("d-flex", { "pt-2 mt-2 border-top": index !== 0 })} key={accused.discrimination_member_id}>
                                                                    <UserDisplay.Container>
                                                                        <UserDisplay.Title>{accused.first_name + ' ' + accused.last_name}</UserDisplay.Title>
                                                                        {accused.member?.unique_identifier &&
                                                                            <UserDisplay.Subtitle>#{accused.member?.unique_identifier}</UserDisplay.Subtitle>
                                                                        }
                                                                        {!!accused.member?.organisation?.organisation_name && (
                                                                            <div className="text-body font-12">
                                                                                <DisplayI18n
                                                                                    field="name"
                                                                                    defaultValue={accused.member.organisation.organisation_name}
                                                                                    i18n={accused.member.organisation.i18n}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </UserDisplay.Container>
                                                                </UserDisplay>
                                                            ))
                                                    }
                                                </>
                                                :
                                                (complaint.discrimination_member || [])
                                                    .map((team) => (
                                                        <UserDisplay key={team.discrimination_member_id}>
                                                            <UserDisplay.Container>
                                                                <UserDisplay.Subtitle className="text-uppercase mb-1"><Translate id='discrimination.label.team' /></UserDisplay.Subtitle>
                                                                {team.team ?
                                                                    <>
                                                                        <UserDisplay.Title>
                                                                            {team.team.name}
                                                                        </UserDisplay.Title>
                                                                        <UserDisplay.Subtitle>
                                                                            #{team.team.unique_identifier}
                                                                        </UserDisplay.Subtitle>
                                                                        {team.team.category &&
                                                                            <div className="text-body font-12">
                                                                                <DisplayCategory short category={team.team.category} />
                                                                            </div>
                                                                        }
                                                                    </>
                                                                    :
                                                                    team.team_name
                                                                }
                                                            </UserDisplay.Container>
                                                        </UserDisplay>
                                                    ))
                                        )
                                    case 'origin':
                                        return <DiscriminationReasons reasons={complaint.discrimination_reason.split(',')} />
                                    case 'outcomes':
                                        return <DiscriminationOutcomes outcomes={complaint.maltreatment_outcomes || []} />
                                    case 'status':
                                        return <ClaimStatus discriminationStatus={complaint.discrimination_status} />
                                    default:
                                        break;
                                }
                            }}
                            onColumnClick={(e, data) => {
                                switch (e.button){
                                    case 0: // Left mouse button
                                        panelProps.onSingleSelectChange(data);
                                        break;
                                }
                            }}
                            rowIsHighlighted={(data) => data.checked}
                        >
                            {(spordleTable) => (
                                <DiscriminationContextProvider refreshTable={spordleTable.refreshTable}>
                                    <RecentMaltreatmentComplaints />
                                    <DiscriminationSearch
                                        savedSearch={savedSearch}
                                        clearSavedSearch={clearSavedSearch}
                                    />
                                    <Card body className='card-shadow'>
                                        <PeriodChangeHandler useRefreshTable />
                                        <div className='d-flex flex-wrap justify-content-between mb-2'>
                                            <SearchInput />
                                            <div className='d-flex ml-auto text-right flex-wrap flex-md-nowrap'>
                                                <Refresh />
                                                <CanDoAction
                                                    action="ADD"
                                                    componentCode="organization"
                                                    componentPermissionCode="discrimination_claims"
                                                >
                                                    <Button type="button" className='ml-2' color='primary' onClick={() => setAddModalIsOpen(true)}>
                                                        <i className='ti-plus' /> <Translate id='misc.add' />
                                                    </Button>
                                                </CanDoAction>
                                            </div>
                                        </div>
                                        <DiscriminationAddModal status={status} isOpen={addModalIsOpen} toggle={() => setAddModalIsOpen(false)} />
                                        <SpordleTableView />
                                        <OpenSidePanelOnSearch />
                                    </Card>
                                </DiscriminationContextProvider>
                            )}
                        </SpordleTableProvider>
                    )}
                />
            </div>
        </>
    )
}

export default Discrimination;