import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Spinner } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import CollapsibleCard from "../../../../../components/collapsibleCard/CollapsibleCard";
import CrossFade from "../../../../../components/crossFade/CrossFade";
import CustomAnimatedIcon from "../../../../../components/customAnimatedIcon/CustomAnimatedIcon";
import InlineCopy from "../../../../../components/inlineCopy/InlineCopy";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../components/UserImg";
import { TeamsContext } from "../../../../../contexts/TeamsContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { DisplayCategory, getStatus } from "../../../TeamHelpers";

const TeamCard = ({ team, withStatus = false, isSubmitting, comment, action, updatePromises, ...props }) => {
    const teamResource = team.team;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ status, setStatus ] = useState(null);
    const [ error, setError ] = useState(null);
    const [ errorType, setErrorType ] = useState('simple');

    const teamsContext = useContext(TeamsContext);

    const updateApprobation = (skipValidation) => {
        setIsLoading(true);
        teamsContext.updateTeamApprobation(team.team.team_id, action, comment || null, true, skipValidation)
            .then(() => {
                setError(null);
                setIsLoading(false);
                setStatus('success');
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    setStatus('error');
                    console.error(error.error.message)
                    setIsLoading(false);

                    if(error.error.code == '3556' && hasInvalidReasons(error.invalid_reasons)){
                        setErrorType('reasons')
                        setError(
                            <>
                                {Object.keys(error.invalid_reasons).reduce((newArray, key) => {
                                    if(error.invalid_reasons[key].length > 0){
                                        newArray.push(
                                            <CollapsibleCard key={key} error arrowIsRight title={<Translate id={'teams.profile.header.approbation.reasons.' + key} />} className='mb-3'>
                                                <ul className='mb-0'>
                                                    {error.invalid_reasons[key].map((message, index) => (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <li key={index} className='text-left'>
                                                            {message}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </CollapsibleCard>,
                                        )
                                    }
                                    return newArray
                                }, [])}
                                {error.overwrite_approval == 1 &&
                                    <Button
                                        color='primary'
                                        type='button'
                                        block
                                        onClick={() => {
                                            updateApprobation(true)
                                        }}
                                    >
                                        <div>
                                            <Translate
                                                id='teams.profile.header.approbation.bypass.title'
                                                values={{ action: <Translate id={`teams.profile.header.approbation.confirm.${action.toString().toLowerCase()}`} /> }}
                                            />
                                        </div>
                                        <Translate id='teams.profile.header.approbation.bypass.text' />
                                    </Button>
                                }
                            </>,
                        )
                    }else{
                        setErrorType('simple')
                        setError(<DisplayI18n field='message' defaultValue={error.error.message} i18n={error.error.i18n} />)
                    }
                }
            })
            .finally(() => {
                updatePromises?.(teamResource.team_id)
            })
    }


    const hasInvalidReasons = (invalidReasons) => {
        return Object.keys(invalidReasons || {}).some((key) => {
            return invalidReasons[key]?.length > 0;
        })
    }

    useEffect(() => {
        if(isSubmitting){
            setIsLoading(true);
            updateApprobation(false);
        }
    }, [ isSubmitting ])

    return (
        <OverlayLoader isLoading={(isLoading && error)}>
            <UserDisplay card className={stringBuilder("d-flex w-100", { "mb-0 border-bottom-0": error, "mb-2": !error })}>
                <UserDisplay.Container>
                    <UserImg
                        abbr={teamResource.abbreviation || teamResource.short_name || teamResource.name}
                        src={teamResource.logo?.full_path}
                        filePos={teamResource.logo?.file_position}
                        alt={teamResource.name}
                        width={40}
                    />
                </UserDisplay.Container>
                <UserDisplay.Container className="w-100">
                    <UserDisplay.Title className="d-flex justify-content-between">
                        <div>{teamResource.name}</div>
                        {withStatus &&
                        <div>{getStatus(teamResource.team_status)}</div>
                        }
                    </UserDisplay.Title>
                    <UserDisplay.Subtitle>
                        <DisplayCategory category={teamResource.team_category} />
                    </UserDisplay.Subtitle>
                    {teamResource.unique_identifier &&
                        <UserDisplay.Subtitle>
                            <div className="d-inline-flex">
                                <div className="mr-4 d-flex align-items-center">
                                    <InlineCopy tag='div' toCopy={teamResource.unique_identifier}>
                                        <Link className="text-nowrap" to={{ pathname: `/teams/profile/${teamResource.team_id}`, data: teamResource }}>#{teamResource.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></Link>
                                    </InlineCopy>
                                </div>
                            </div>
                        </UserDisplay.Subtitle>
                    }
                </UserDisplay.Container>
                <UserDisplay.Container>
                    {isLoading &&
                        <Spinner color='primary' style={{ width: '25px', height: '25px', borderWidth: '1px' }} />
                    }
                    {((!isLoading && status)) &&
                        <CustomAnimatedIcon
                            id={'customStatusIcon-' + teamResource.team_id}
                            size='25px'
                            icon={status === 'success' ? 'checkmark' : 'error'}
                            withCircle
                            className={status === 'success' ? 'text-success' : 'text-danger'}
                        />
                    }
                </UserDisplay.Container>
            </UserDisplay>

            <CrossFade isVisible={!!error} appear mountOnEnter unmountOnExit>
                <UserDisplay card className="d-flex w-100 mb-2">
                    <UserDisplay.Container className="w-100">
                        {errorType === 'simple' ?
                            <Alert color='danger' className='text-left'>{error}</Alert>
                            :
                            error
                        }
                    </UserDisplay.Container>
                </UserDisplay>
            </CrossFade>
        </OverlayLoader>
    )
}

export default TeamCard