import { SpordleTableContext } from '@spordle/datatables';
import { FormikCheckedButton, FormikError, FormikInputNumber, FormikInputText, FormikTextArea } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import {
    Button, FormGroup, Alert,
    Label, ModalBody, ModalFooter, ModalHeader, Collapse
} from "reactstrap";
import { bool, number, object, string } from 'yup';
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import Required from '../../../../components/formik/Required';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import { fail } from "@spordle/toasts";
import { ClinicsContext } from '../../../../contexts/ClinicsContext';
import { I18nContext } from '../../../../contexts/I18nContext';
import { UtilsContext } from '../../../../contexts/UtilsContext';
import I18nHelperContextProvider, { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../helpers/i18nHelper';

const ClinicItemsAdd = ({ isOpen, toggle }) => {
    const utilsContext = useContext(UtilsContext);
    const clinicContext = useContext(ClinicsContext);
    const spordleTable = useContext(SpordleTableContext);

    return (
        <I18nHelperContextProvider fields={[ 'name', 'description' ]}>
            <I18nHelperContext.Consumer>
                {(i18nHelper) => (
                    <AnalyticsModal analyticsName='ClinicItemsAdd' isOpen={isOpen}>
                        <Formik
                            initialValues={{
                                ...i18nHelper.getInitialValues(),
                                amount: 0,
                                sku: '',
                                taxable: false,
                            }}
                            validationSchema={object().shape({
                                ...i18nHelper.getValidationSchema({ name: string().required(<Translate id='catalog.clinicItems.add.form.validation.name.required' />) }),
                                amount: number().required(<Translate id='catalog.clinicItems.add.form.validation.amout.required' />),
                                sku: string(),
                                taxable: bool(),
                            })}
                            onSubmit={async({ taxable, ...values }, { setStatus }) => {
                                setStatus();
                                const taxClasses = taxable
                                    ?
                                    await utilsContext.getTaxClasses()
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                    :
                                    [];
                                const taxClass = taxClasses.filter((taxClass) => taxClass.active == '1')[0];
                                const apiValues = {
                                    ...values,
                                    type: 'CLINIC', // Clinic item will always be a type CLINIC
                                    active: '1',
                                    //amount must be the total cents -> 10.00$ will be 1000
                                    amount: Math.round(values.amount * 100),
                                    ...i18nHelper.getAPIValues(values),
                                }

                                if(taxable){
                                    apiValues.tax_class_id = taxClass.tax_class_id;
                                }

                                const newItem = { ...apiValues } // data that will be sent back to table
                                newItem.tax_class = taxClass;

                                // data to send to api
                                delete apiValues.i18n;

                                return clinicContext.createClinicItems(apiValues)
                                    .then((fee_id) => {
                                        const tableData = {
                                            fee_id: fee_id,
                                            ...newItem,
                                        }
                                        spordleTable.addRow(tableData).then(toggle);
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            if(error.i18n){
                                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                            }else{
                                                switch (error.message){
                                                    case '3045':
                                                        setStatus(<Translate id={`catalog.clinicItems.add.form.error.${error.message}`} />)
                                                        break;
                                                    default:
                                                        setStatus(<Translate id='misc.error' />)
                                                        break;
                                                }
                                            }
                                        }
                                    })
                                    .catch((e) => {
                                        if(!AxiosIsCancelled(e.message)){
                                            console.error(e.message);
                                        }
                                    })
                            }}
                        >
                            {(formik) => (
                                <Form>
                                    <OverlayLoader isLoading={formik.isSubmitting}>
                                        <ModalHeader toggle={toggle}>
                                            <Translate id='catalog.clinicItems.add.title' />
                                        </ModalHeader>
                                        <ModalBody>
                                            <RenderI18nForm field="name">
                                                {({ fieldName, fieldLabel }) => (
                                                    <FormGroup key={fieldName}>
                                                        <Label for={fieldName} className='text-muted'>{fieldLabel} <Required /></Label>
                                                        <FormikInputText id={fieldName} name={fieldName} trim />
                                                    </FormGroup>
                                                )}
                                            </RenderI18nForm>

                                            <RenderI18nForm field="description">
                                                {({ fieldName, fieldLabel }) => (
                                                    <FormGroup key={fieldName}>
                                                        <Label for={fieldName} className='text-muted'>{fieldLabel}</Label>
                                                        <FormikTextArea id={fieldName} name={fieldName} rows="5" trim />
                                                    </FormGroup>
                                                )}
                                            </RenderI18nForm>

                                            <FormGroup>
                                                <Label for="amount" className="text-muted"><Translate id='catalog.clinicItems.add.form.price' /></Label>
                                                <div className="d-flex align-items-center">
                                                    <I18nContext.Consumer>
                                                        {({ getGenericLocale }) => (
                                                            <FormikInputNumber
                                                                id='amount' name='amount' className="w-25"
                                                                manualError
                                                                allowNegative={false} allowLeadingZeros
                                                                fixedDecimalScale decimalScale={2}
                                                                thousandSeparator=' '
                                                                decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                            />
                                                        )}
                                                    </I18nContext.Consumer>
                                                    <span className="ml-3">CAD</span>
                                                </div>
                                                <FormikError name='amount' />
                                                <FormikCheckedButton id='taxable' name='taxable' label='catalog.clinicItems.add.form.taxable' className='mt-2' />
                                            </FormGroup>
                                            {/* <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label for="sku" className="text-muted"><Translate id='catalog.clinicItems.add.form.sku'/></Label>
                                                        <FormikSelect name='sku' id='sku'>
                                                            <SpordleSelect.Option id='sku-4152' label='4152 - Registration' value='4152' />
                                                        </FormikSelect>
                                                    </FormGroup>
                                                </Col>
                                            </Row> */}

                                            <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                                                <Alert color='danger' className='mb-0'>
                                                    {formik.status}
                                                </Alert>
                                            </Collapse>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="primary" type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                            <Button color="primary" onClick={toggle} type='button' outline disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                        </ModalFooter>
                                    </OverlayLoader>
                                </Form>
                            )}
                        </Formik>
                    </AnalyticsModal>
                )}
            </I18nHelperContext.Consumer>
        </I18nHelperContextProvider>
    );
}

export default ClinicItemsAdd;