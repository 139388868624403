import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import CustomForms from './CustomFormEditor';

class FormsCreate extends Component{
    render(){
        return (
            <FormattedMessage id='catalog.forms.components.formsCreate.formName.default'>
                {(message) => <CustomForms {...this.props} newForm formName={message[0]} />}
            </FormattedMessage>
        );
    }
}

export default FormsCreate;