import { FormikDateTime } from '@spordle/formik-elements'
import Translate from '@spordle/intl-elements'
import { Form, Formik } from 'formik'
import moment, { isMoment } from 'moment'
import { useContext, useState } from 'react'
import { Button, Collapse, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { mixed, object } from 'yup'
import AnalyticsModal from '../../../../../analytics/AnalyticsModal'
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI'
import CustomAlert from '../../../../../components/CustomAlert'
import Required from '../../../../../components/formik/Required'
import OverlayLoader from '../../../../../components/loading/OverlayLoader'
import SidePanel from '../../../../../components/sidePanel/SidePanel'
import { success } from '@spordle/toasts'
import { MembersContext } from '../../../../../contexts/MembersContext'
import { DisplayI18n } from '../../../../../helpers/i18nHelper'

const MemberQualificationCertify = ({ memberId, memberQualificationId, refreshTable, closeSidePanel }) => {
    const { certify } = useContext(MembersContext);
    const [ isOpen, setIsOpen ] = useState(false);

    const toggle = () => { setIsOpen(!isOpen); };

    return (
        <>
            <SidePanel.MenuAction onClick={() => { setIsOpen(true) }} action='EDIT' componentCode='teams' componentPermissionCode='member_qualification_certify'>
                <Translate id='members.profile.qualifications.sidePanel.actions.certify' />
            </SidePanel.MenuAction>
            <AnalyticsModal analyticsName='ConfirmModal' isOpen={isOpen}>
                <Formik
                    initialValues={{
                        date: moment(),
                    }}
                    validationSchema={object().shape({
                        date: mixed().required(<Translate id='form.required' />)
                            .isDate()
                            .test({
                                name: 'beforeToday',
                                message: <Translate id='members.profile.qualifications.sidePanel.certifyModal.certification.validation.beforeToday' />,
                                test: (date) => {
                                    if(isMoment(date)){
                                        return date.isBefore(new Date());
                                    }
                                    return true;
                                },
                            }),
                    })}
                    onSubmit={({ date }, { setStatus }) => {
                        return certify(memberId, memberQualificationId, { completed_date: date.format('YYYY-MM-DD') })
                            .then(toggle)
                            .then(success)
                            .then(closeSidePanel)
                            .then(refreshTable)
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.code);
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                                }
                            })
                    }}
                >
                    {(formik) => (
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <Form>
                                <ModalHeader toggle={toggle}>
                                    <Translate id='members.profile.qualifications.sidePanel.certifyModal.title' />
                                </ModalHeader>
                                <ModalBody>
                                    <Label for='certify-date'><Translate id='members.profile.qualifications.sidePanel.certifyModal.certification.label' /> <Required /></Label>
                                    <FormikDateTime
                                        name='date'
                                        inputProps={{ id: 'certify-date', autoComplete: 'off' }}
                                        timeFormat={false}
                                        isValidDate={(date) => date.isBefore(new Date())}
                                    />
                                    <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                                        <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status || ''} translateText={false} toggle={() => formik.setStatus()} />
                                    </Collapse>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" type='submit' disabled={formik.isSubmitting}>
                                        <Translate id="misc.save" />
                                    </Button>
                                    <Button color="primary" type='button' outline disabled={formik.isSubmitting} onClick={toggle}>
                                        <Translate id="misc.cancel" />
                                    </Button>
                                </ModalFooter>
                            </Form>
                        </OverlayLoader>
                    )}
                </Formik>
            </AnalyticsModal>
        </>
    )
}

export default MemberQualificationCertify
