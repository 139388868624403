export default{
    "catalog.forms.components.formEditor.questionType.questionTypePicker.TEXT"            : "Réponse courte",
    "catalog.forms.components.formEditor.questionType.questionTypePicker.TEXT_AREA"       : "Réponse longue",
    "catalog.forms.components.formEditor.questionType.questionTypePicker.RADIO"           : "Bouton radio",
    "catalog.forms.components.formEditor.questionType.questionTypePicker.CHECKBOX"        : "Case à cocher",
    "catalog.forms.components.formEditor.questionType.questionTypePicker.SELECTBOX"       : "Sélection simple",
    "catalog.forms.components.formEditor.questionType.questionTypePicker.SELECTBOX_MULTI" : "Sélection multiple",
    "catalog.forms.components.formEditor.questionType.questionTypePicker.DATE"            : "Date",
    "catalog.forms.components.formEditor.questionType.questionTypePicker.DATETIME"        : "Temps",
    "catalog.forms.components.formEditor.questionType.questionTypePicker.NUMBER"          : "Nombre",
}