export default{
    "components.fileViewer.loading"                     : "Chargement du PDF en cours...",
    "components.fileViewer.error"                       : "Une erreur est survenu lors du chargement du PDF",
    "components.fileViewer.download"                    : "Télécharger",
    "components.fileViewer.noData"                      : "Aucun PDF",
    "components.fileViewer.noPreview.title"             : "Aucun aperçu",
    "components.fileViewer.noPreview.text"              : "Nous ne pouvons afficher un aperçu du fichier.",
    "components.fileViewer.noPreview.downloadAvailable" : "Vous pouvez télécharger le fichier:",

    "components.fileViewer.noPDF.text"  : "Le fichier PDF n'a pas été trouvé.<br></br> L'aperçu et le lien de téléchargement n'ont pas pu être générés.",
    "components.fileViewer.noPDF.close" : "Fermer aperçu",

    "components.fileViewer.zoom.in"  : "Agrandir",
    "components.fileViewer.zoom.out" : "Dézoomer",

    "components.fileViewer.accessibility.loading.label" : "Chargement du fichier {fileName}...",
}