import Draggable from "react-draggable";
import { Card } from "reactstrap";
import { useContext, useState } from "react";
import ChecklistAssistantStepsList from "./ChecklistAssistantStepsList";
import { ChecklistsContext } from "../../../../contexts/ChecklistsContext";

const ChecklistAssistant = () => {
    const { closeAssistant, isOpen } = useContext(ChecklistsContext);
    const defaultPosition = { x: window.innerWidth / 2, y: 70 };
    const [ draggablePosition, setDraggablePosition ] = useState(defaultPosition); // default starting position, set it here as backup also

    if(!isOpen){
        return null;
    }

    return (
        <div style={{ zIndex: 10000, pointerEvents: 'none' }} className="position-fixed w-100 top-0 bottom-0 left-0 right-0">
            <Draggable
                axis="both"
                handle="#checklist-assistant-container"
                scale={1}
                bounds="parent"
                defaultPosition={defaultPosition}
                position={draggablePosition}
                cancel=".help-center-resize-handle"
                onStop={(_event, targetElement) => {
                    setDraggablePosition({ x: targetElement.x, y: targetElement.y });
                    // boundingRect contains information about the target elements height, width and position
                    const boundingRect = targetElement.node.getBoundingClientRect();

                    if(boundingRect.height + targetElement.y > window.innerHeight){
                        setDraggablePosition({ x: targetElement.x, y: window.innerHeight - boundingRect.height })
                    }
                }}
            >
                <Card id="checklist-assistant-container" style={{ width: 400, cursor: 'move', pointerEvents: 'all', maxHeight: '85vh' }} className='border shadow-lg mb-0 d-flex flex-column'>
                    <ChecklistAssistantStepsList closeAssistant={closeAssistant} />
                    {/* <div className='d-flex justify-content-between p-3 border-top'>
                        <Button color="primary" type="button" outline>Previous</Button>
                        <Button color="primary" type="button" outline>Next</Button>
                    </div> */}
                </Card>
            </Draggable>
        </div>
    );
}

export default ChecklistAssistant;