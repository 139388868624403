import { useContext, useState } from "react";
import { Button, Collapse, Fade, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useSWR from "swr";
import AnalyticsModal from "../../../../../../analytics/AnalyticsModal";
import ManualRegistrationMemberIdentities from "../../../../../../components/manualRegistration/ManualRegistrationMemberIdentities";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { ClinicsContext } from "../../../../../../contexts/ClinicsContext";
import { fail } from '@spordle/toasts';
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import Translate from "@spordle/intl-elements";
import Skeleton from "react-loading-skeleton";
import Required from "../../../../../../components/formik/Required";
import FormClinicSelectPermission from "../AddContactModal/FormClinicSelectPermission";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";

const InstructorsAddIdentity = (props) => {
    const [ isOpen, setIsOpen ] = useState(false);

    return (
        <>
            <div
                onClick={() => setIsOpen(true)}
                className="clickable text-primary small font-medium stretched-link mt-2"
            >
                <i className="mdi mdi-chevron-right" /><Translate id='clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.giveAccess.btn' />
            </div>
            <AnalyticsModal isOpen={isOpen} analyticsName="clinicsProfileInstructorsSidePanelAddIdentity">
                <InstructorsAddIdentityInner toggle={() => setIsOpen(false)} {...props} />
            </AnalyticsModal>
        </>
    );
}

const STEP_IDENTITY = 1;
const STEP_PERMISSION = 2;

const InstructorsAddIdentityInner = ({ toggle, instructor, tableRef, forceCloseSidePanel }) => {
    const { clinicInstructorId, member } = instructor;
    const [ newInstructor, setNewInstructor ] = useState({ ...instructor, member_id: member.member_id });
    const [ step, setStep ] = useState(STEP_IDENTITY);
    const [ isLoading, setIsLoading ] = useState(false);
    const membersContext = useContext(MembersContext);
    const clinicsContext = useContext(ClinicsContext);

    const { data, isValidating } = useSWR(
        [ 'getMemberIdentitiesInstructorsSidePanel', member?.member_id ],
        () => member?.member_id ?
            membersContext.getMemberAccounts(member.member_id)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
            :
            null,
    );

    const handlePermissionSubmit = (values, { setStatus }) => {
        setIsLoading(true);

        return clinicsContext.deleteInstructor(clinicsContext.currentClinic.clinic_id, clinicInstructorId)
            .then(async() => {
                await clinicsContext.createInstructor(
                    clinicsContext.currentClinic.clinic_id,
                    {
                        ...newInstructor,
                        ...values,
                    },
                );
                tableRef?.refreshTable();
                toggle();
                forceCloseSidePanel();
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    setIsLoading(false);
                    console.error(e.message);
                    setStatus(<DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />)
                }
            })
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <Collapse isOpen={step === STEP_IDENTITY}>
                <Fade in={step === STEP_IDENTITY}>
                    <ModalHeader toggle={toggle}>
                        <Translate id='clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.giveAccess.modal.title' />
                    </ModalHeader>
                    {isValidating ?
                        <>
                            <ModalBody>
                                <Label className='text-muted'><Translate id='memberProfile.registration.memberIdentities.label' /> <Required /></Label>
                                <Skeleton className="mb-2" height={70} count={1} />
                                <Skeleton className="mb-2" height={48} count={1} />
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={toggle} type="button" color="primary" outline>
                                    <Translate id="misc.cancel" />
                                </Button>
                            </ModalFooter>
                        </>
                        :
                        <ManualRegistrationMemberIdentities
                            from='instructor'
                            memberIdentities={data}
                            member={member}
                            toggle={toggle}
                            submitLabel="misc.link"
                            hidePreviousButton
                            onSubmit={(identityId) => {
                                setStep(STEP_PERMISSION);
                                return setNewInstructor({
                                    ...newInstructor,
                                    identityId: identityId,
                                    memberId: member.member_id,
                                });
                            }}
                        />
                    }
                </Fade>
            </Collapse>
            <Collapse isOpen={step === STEP_PERMISSION}>
                <Fade in={step === STEP_PERMISSION}>
                    <FormClinicSelectPermission
                        previous={() => setStep(STEP_IDENTITY)}
                        onSubmit={handlePermissionSubmit}
                        member={newInstructor}
                        toggle={toggle}
                        isLoading={isLoading}
                    />
                </Fade>
            </Collapse>
        </OverlayLoader>
    )
}

export default InstructorsAddIdentity;