/* eslint-disable react/no-unknown-property */
import { useContext, useLayoutEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { Card } from "reactstrap";
import AnalyticsModal from '../../analytics/AnalyticsModal';
import { QuickViewContext } from '../../contexts/QuickViewContext';

import { AuthContext, PeriodsContext, AppContext } from "../../contexts/contexts";
import { IdentityRolesContext } from "../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { RolesContext } from "../../contexts/RolesContext";
import { I18nContext } from "../../contexts/I18nContext";
import { useTour } from '@reactour/tour';

const QuickView = () => {
    const {
        activeTabState,
        isOpen,
        iframeIsLoaded,
    } = useContext(QuickViewContext);

    const [ initSrc, setInitSrc ] = useState(activeTabState?.url || "");
    const [ initHideQuickView, setInitHideQuickView ] = useState(true);
    const { isOpen: tourIsOpen } = useTour();
    const canShowModal = !initHideQuickView && iframeIsLoaded;

    useLayoutEffect(() => {
        if(isOpen && initSrc === "" && (activeTabState?.url || ""))
            setInitSrc(activeTabState?.url || "")
    }, [ isOpen ])

    useLayoutEffect(() => {
        if(iframeIsLoaded){
            setTimeout(() => {
                setInitHideQuickView(false);
            }, 400);
        }else{
            setInitHideQuickView(true);
        }
    }, [ iframeIsLoaded ]);

    return (
        <>
            <AnalyticsModal
                id="quick-view-modal"
                analyticsName='quickViewModal'
                size="xl"
                isOpen={(isOpen || !iframeIsLoaded) && !tourIsOpen}
                unmountOnClose={false}
                className={canShowModal ? '' : 'invisible d-none'}
                wrapClassName={canShowModal ? '' : 'invisible d-none'}
                modalClassName={canShowModal ? '' : 'invisible d-none'}
                backdropClassName={canShowModal ? '' : 'invisible d-none'}
                contentClassName={canShowModal ? 'bg-light quick-view-modal border-0' : 'invisible d-none'}
                zIndex={999999}
            >
                <QuickViewModalInner initSrc={initSrc} />
            </AnalyticsModal>
        </>
    );
}

const QuickViewModalInner = ({ initSrc }) => {
    const appContext = useContext(AppContext);
    const i18nContext = useContext(I18nContext);
    const authContext = useContext(AuthContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const organizationContext = useContext(OrganizationContext);
    const periodsContext = useContext(PeriodsContext);
    const rolesContext = useContext(RolesContext);
    const {
        setIframeIsLoaded,
    } = useContext(QuickViewContext);

    return (
        <>
            <Card body className="m-0 p-0">

                {/*--------------------------------------------------------------------------------*/}
                {/* Quick View Iframe that will load our app                                      */}
                {/*--------------------------------------------------------------------------------*/}
                <iframe
                    id="quick-view-iframe"
                    style={{ width: "100%", height: "90vh" }}
                    frameBorder="no"
                    src={initSrc}
                    appcontext={JSON.stringify(appContext.state)}
                    i18ncontext={JSON.stringify(i18nContext.state)}
                    authcontext={JSON.stringify(authContext.state)}
                    identityrolescontext={JSON.stringify(identityRolesContext.state)}
                    organizationcontext={JSON.stringify(organizationContext.state)}
                    periodscontext={JSON.stringify(periodsContext.state)}
                    rolescontext={JSON.stringify(rolesContext.state)}
                    onLoad={() => setIframeIsLoaded(true)}
                />
            </Card>
        </>
    )
}

export default withRouter(QuickView);