import API_SPORDLE from "../API-Spordle";
import { serverError } from "../CancellableAPI";
import queryString from "query-string";

/**
 * Gets all the lexicon from the DB
 */
export function getPlatformLexicon(){
    return API_SPORDLE.get('/platform-lexicons')
        .then((response) => {
            if(response.data.status){
                return response.data.platform_lexicons;
            }
            throw response.data.errors[0];
        }, serverError);
}

/**
 * [POST] Update the about us
 * @param {object} values
 */
export function createPlatformLexicon(values: object) {
    const params = new URLSearchParams();

    for(const key in values){
        switch (key){
            default:
                params.append(key, values[key]);
                break;
        }
    }

    return API_SPORDLE.post(queryString.stringifyUrl({ url: `platform-lexicons` }), params)
        .then((response) => {
            if(response.data.status){
                return response.data.platform_lexicon_id;
            }
            throw response.data.errors[0];
        }, serverError)
}

/**
 * [PATCH] Update platform lexicon row
 * @param {string} platformLexiconId
 * @param {object} values
 */
 export function updatePlatformLexicon(platformLexiconId: string, values: object) {
    const params = new URLSearchParams();
    for(const key in values){
        switch (key){
            default:
                params.append(key, values[key]);
                break;
        }
    }
    return API_SPORDLE.patch(queryString.stringifyUrl({ url: `platform-lexicons/${platformLexiconId}` }), params)
        .then((response) => {
            if(response.data.status){
                return true;
            }
            throw response.data.errors[0];
        }, serverError)
}

/**
 * [DELETE] Delete platform lexicon row
 * @param {string} platformLexiconId
 */
 export function deletePlatformLexicon(platformLexiconId: string) {
    return API_SPORDLE.delete(queryString.stringifyUrl({ url: `platform-lexicons/${platformLexiconId}` }))
        .then((response) => {
            if(response.data.status){
                return true;
            }
            throw response.data.errors[0];
        }, serverError)
}