import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext } from 'react';
import {
    Col
} from "reactstrap";
import { MembersContext } from '../../../../../../../../../contexts/MembersContext';
import moment from 'moment';
import { object, mixed, string, array } from 'yup';
import FormikEditable from '../../../../../../../../../components/formik/FormikEditable';
import { FormikDateTime, FormikTextArea } from '@spordle/formik-elements';
import FormikEditableFile from '../../../../../../../../../components/formik/FormikEditableFile';
import { AxiosIsCancelled } from '../../../../../../../../../api/CancellableAPI';
import { fail, success } from "@spordle/toasts";
import { DisplayI18n } from '../../../../../../../../../helpers/i18nHelper';

const AppealsSidepanel1Notes = (props) => {
    const membersContext = useContext(MembersContext);

    const handleError = (error) => {
        if(!AxiosIsCancelled(error.message)){
            console.error(error)
            fail({
                msg: 'misc.error',
                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                skipInfoTranslate: true,
            })
            props.setNotesLoading(false);
        }
    }

    return (
        <>
            <Col sm="6">
                <div className="text-muted"><Translate id='members.profile.appeals.hearingDate' /></div>
                <FormikEditable
                    id='approveDate'
                    initialValues={{
                        approveDate: props.selectedRows[0].appeal_approve_date ? moment(props.selectedRows[0].appeal_approve_date) : '',
                    }}
                    validationSchema={object().shape({
                        approveDate: mixed()
                            .test({
                                name: 'approveDateFormat',
                                message: <Translate id='form.validation.date.format' />,
                                test: (date) => {
                                    if(date){
                                        return moment.isMoment(date)
                                    }
                                    return true // not required
                                },
                            }).test({
                                name: 'approveDateAfterSentDate',
                                message: <Translate id='members.profile.appeals.hearingDate.beforeSent' />,
                                test: (date) => {
                                    if(date && props.selectedRows[0].appeal_sent_date){
                                        return moment(date).isSameOrBefore(moment(props.selectedRows[0].appeal_sent_date))
                                    }
                                    return true
                                },
                            }).test({
                                name: 'approveDateAfterSubmittedDate',
                                message: <Translate id='members.profile.appeals.hearingDate.afterSubmitted' />,
                                test: (date) => {
                                    if(date && props.selectedRows[0].appeal_date){
                                        return moment(date).isAfter(moment(props.selectedRows[0].appeal_date))
                                    }
                                    return true
                                },
                            }),
                    })}
                    onSubmit={(values) => {
                        const newDate = values.approveDate ? values.approveDate.toISOString() : null;
                        if(newDate !== props.selectedRows[0].appeal_approve_date){
                            props.setNotesLoading(true)
                            const newValues = props.createNewValues({ appeal_approve_date: newDate })
                            membersContext.updateMemberAppealPartial(
                                membersContext.currentMember.member_id,
                                props.selectedRows[0].member_appeal_id,
                                {
                                    appeal_approve_date: newDate,
                                },
                            )
                                .then(() => {
                                    props.syncRows(newValues);
                                    success();
                                    props.setNotesLoading(false)
                                })
                                .catch(handleError)
                        }
                    }}
                >
                    {(isEditing) => {
                        if(!isEditing){
                            return (
                                <div className="font-medium text-dark">
                                    {props.selectedRows[0].appeal_approve_date ?
                                        <DateFormat value={props.selectedRows[0].appeal_approve_date} />
                                        : '-'}
                                </div>
                            )
                        }
                        return (
                            <FormikDateTime
                                id='approveDate' name='approveDate'
                                timeFormat={false}
                                dateFormat='YYYY-MM-DD'
                                isValidDate={(current) => {
                                    const sentDateValid = props.selectedRows[0].appeal_sent_date ? moment(current).isSameOrBefore(moment(props.selectedRows[0].appeal_sent_date)) : true
                                    const submittedDateValid = props.selectedRows[0].appeal_date ? moment(current).isAfter(moment(props.selectedRows[0].appeal_date)) : true
                                    return sentDateValid && submittedDateValid
                                }}
                            />
                        )
                    }}
                </FormikEditable>
            </Col>
            <Col sm="6">
                <div className="text-muted"><Translate id='members.profile.appeals.sentDate' /></div>
                <FormikEditable
                    id='sentDate'
                    initialValues={{
                        sentDate: props.selectedRows[0].appeal_sent_date ? moment(props.selectedRows[0].appeal_sent_date) : '',
                    }}
                    validationSchema={object().shape({
                        sentDate: mixed()
                            .test({
                                name: 'sentDateFormat',
                                message: <Translate id='form.validation.date.format' />,
                                test: (date) => {
                                    if(date){
                                        return moment.isMoment(date)
                                    }
                                    return true // not required
                                },
                            }).test({
                                name: 'sentDateAfterAppealDate',
                                message: <Translate id='members.profile.appeals.sentDate.afterSubmitted' />,
                                test: (date) => {
                                    if(date){
                                        return moment(date).isAfter(moment(props.selectedRows[0].appeal_date))
                                    }
                                    return true
                                },
                            }).test({
                                name: 'sentDateBeforeHearingDate',
                                message: <Translate id='members.profile.appeals.sentDate.afterHearing' />,
                                test: (date) => {
                                    if(date && props.selectedRows[0].appeal_approve_date){
                                        return moment(date).isSameOrAfter(moment(props.selectedRows[0].appeal_approve_date))
                                    }
                                    return true
                                },
                            }),
                    })}
                    onSubmit={(values) => {
                        const newDate = values.sentDate ? values.sentDate.toISOString() : null;
                        if(newDate !== props.selectedRows[0].appeal_sent_date){
                            props.setNotesLoading(true)
                            const newValues = props.createNewValues({ appeal_sent_date: newDate })
                            membersContext.updateMemberAppealPartial(
                                membersContext.currentMember.member_id,
                                props.selectedRows[0].member_appeal_id,
                                {
                                    appeal_sent_date: newDate,
                                },
                            )
                                .then(() => {
                                    props.syncRows(newValues);
                                    success();
                                    props.setNotesLoading(false);
                                })
                                .catch(handleError);
                        }
                    }}
                >
                    {(isEditing) => {
                        if(!isEditing){
                            return (
                                <div className="font-medium text-dark">
                                    {props.selectedRows[0].appeal_sent_date ?
                                        <DateFormat value={props.selectedRows[0].appeal_sent_date} />
                                        : '-'}
                                </div>
                            )
                        }
                        return (
                            <FormikDateTime
                                id='sentDate' name='sentDate'
                                className='date-picker-left'
                                timeFormat={false}
                                dateFormat='YYYY-MM-DD'
                                isValidDate={(current) => {
                                    return props.selectedRows[0].appeal_approve_date ? moment(current).isSameOrAfter(moment(props.selectedRows[0].appeal_approve_date)) : true
                                }}
                            />
                        )

                    }}
                </FormikEditable>
            </Col>
            <Col sm="12">
                <hr className="mb-3" />
            </Col>
            <Col sm="12">
                <div className="mb-3">
                    <div className="text-muted"><Translate id='members.profile.appeals.note' /></div>
                    <FormikEditable
                        id='notes'
                        initialValues={{
                            notes: props.selectedRows[0].comment,
                        }}
                        validationSchema={object().shape({
                            notes: string(),
                        })}
                        onSubmit={(values) => {
                            if(values.notes !== props.selectedRows[0].comment){
                                props.setNotesLoading(true)
                                const newValues = props.createNewValues({ comment: values.notes })
                                membersContext.updateMemberAppealPartial(
                                    membersContext.currentMember.member_id,
                                    props.selectedRows[0].member_appeal_id,
                                    {
                                        comment: values.notes,
                                    },
                                )
                                    .then(() => {
                                        props.syncRows(newValues);
                                        success();
                                        props.setNotesLoading(false);
                                    })
                                    .catch(handleError);
                            }
                        }}
                    >
                        {(isEditing) => {
                            if(!isEditing){
                                return (
                                    <div className="font-medium text-dark text-truncate">{props.selectedRows[0].comment ? props.selectedRows[0].comment : '-'}</div>
                                )
                            }
                            return (
                                <FormikTextArea id='notes' name='notes' trim rows='3' />
                            )

                        }}
                    </FormikEditable>
                </div>
            </Col>
            <Col sm="12">
                <div className="mb-3">
                    <div className="text-muted"><Translate id='members.profile.appeals.attachments' /></div>
                    <FormikEditableFile
                        id='attachments'
                        name="files"
                        initialValues={{
                            files: props.selectedRows[0].attachments.filter((attachment) => attachment.type === 'OTHER'),
                        }}
                        validationSchema={object().shape({
                            files: array().of(mixed()),
                        })}
                        dropzoneProps={{
                            multiple: true,
                            accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx",
                            maxSize: 20971520,
                        }}
                        onFileClick={props.handleFileClick}
                        onSubmit={(values) => {
                            props.setLoading(true);
                            props.appealAttachmentsOnSubmit(values.files || [], 'OTHER')
                                .then(() => {
                                    success({ info: 'components.uploader.fileUpload.toast.success.info' });
                                    props.setLoading(false);
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message);
                                        fail();
                                        props.setLoading(false);
                                    }
                                })
                        }}
                    />
                </div>
            </Col>
        </>
    );
}

export default AppealsSidepanel1Notes;