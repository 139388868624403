export default{
    "clinics.createClinic.title" : "Créer un stage",

    // wizard titles
    "clinics.createClinic.steps.clinicInfo"     : "Informations sur le stage",
    "clinics.createClinic.steps.sessionInfo"    : "Informations de(s) séance(s)",
    "clinics.createClinic.steps.communications" : "Communications",
    "clinics.createClinic.steps.paymentOptions" : "Option(s) de paiement",
    "clinics.createClinic.steps.review"         : "Révision",

    // under creation
    "clinics.createClinic.underCreation.topText1" : "Vous avez un stage en création!",
    "clinics.createClinic.underCreation.topText2" : "Voulez-vous continuer la création de ce stage là où vous vous êtes arrêté ou en commencer un nouveau?",
    "clinics.createClinic.underCreation.continue" : "Continuer le stage",
    "clinics.createClinic.underCreation.new"      : "Créer un nouveau stage",

    // duplicate
    "clinics.createClinic.duplication.simple.title"        : "Dupliquer cette clinique",
    "clinics.createClinic.duplication.simple.desc"         : "Cliquez ici pour dupliquer la clinique sélectionnée. Vous allez dupliquer le profil de la clinique.",
    "clinics.createClinic.duplication.withAttendees.title" : "Dupliquer cette clinique avec les participants",
    "clinics.createClinic.duplication.withAttendees.desc"  : "Cliquez ici pour dupliquer la clinique sélectionnée avec ses participants. Les participants seront copiés en tant que nouveaux participants.",
    "clinics.createClinic.duplication.topText1"            : "Vous êtes sur le point de dupliquer ce stage!",
    "clinics.createClinic.duplication.topText2"            : "Vous devrez compléter cette première étape pour initier la duplication et ainsi débloquer les étapes suivantes.",
    "clinics.createClinic.duplication.continue"            : "Continuer",
}