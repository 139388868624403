import { Tooltip } from "@mantine/core"
import { useSpordleTable } from "@spordle/datatables"
import Translate from "@spordle/intl-elements"
import { useContext } from "react"
import { Card, CardBody } from "reactstrap"
import QuickTour from "../../../../../components/quickTour/QuickTour"
import { AppContext } from "../../../../../contexts/contexts"
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext"
import { DiscriminationContext } from "../DiscriminationContext"
import { ClaimStatus } from "../DiscriminationHelper"

const RecentMaltreatmentComplaints = () => {
    const appContext = useContext(AppContext)
    const discriminationContext = useContext(DiscriminationContext)
    const identityRolesContext = useContext(IdentityRolesContext)
    const QUICKTOURNAME = "quickTourRecentMaltreatmentComplaints";
    const hasQuickTour = appContext.getFrontEndParams("tours")[QUICKTOURNAME] == 1;
    const spordleTable = useSpordleTable()

    return (

        <div className="d-flex">
            {appContext.getRecentMaltreatmentComplaints()
                .filter((maltreatmentComplaint) => maltreatmentComplaint && (!maltreatmentComplaint.identityRoleId || maltreatmentComplaint.identityRoleId === identityRolesContext.identity_role_id))
                .map((maltreatmentComplaint, index) => {
                    const tableRow = spordleTable.getData().find((row) => (row.reference_number === maltreatmentComplaint.referenceNumber))
                    return (
                        <Card
                            key={maltreatmentComplaint.referenceNumber}
                            className="card-shadow card-hover clickable text-center w-100 h-100 mb-3 mr-3 font-bold"
                            style={{ minWidth: 180, maxWidth: 180, zIndex: hasQuickTour ? "1000" : "unset" }}
                            onClick={() => {
                                discriminationContext.setRecentMaltreatmentComplaint(maltreatmentComplaint)
                                discriminationContext.toggleMaltreatmentOverviewModal(true)
                            }}
                        >
                            <CardBody>
                                <Tooltip
                                    withArrow
                                    label={<Translate id="discrimination.overview.recentComplaints.recentlyCreated" />}
                                    disabled={hasQuickTour}
                                    zIndex="1001"
                                >
                                    <div>
                                        <QuickTour
                                            quickTourName={QUICKTOURNAME}
                                            title={<Translate id="discrimination.overview.recentComplaints.title" />}
                                            message={<Translate id="discrimination.overview.recentComplaints.quickTour" values={{ br: () => <br />, bold: (e) => <b>{e}</b> }} />}
                                            disabled={appContext.getRecentMaltreatmentComplaints().length <= 0 || index > 0}
                                        >
                                            <div>
                                                <Translate id='discrimination.overview.itp' values={{ number: maltreatmentComplaint.referenceNumber }} />
                                            </div>
                                            <div>
                                                <Translate id='discrimination.overview.members' values={{ count: maltreatmentComplaint.numberOfMembers }} />
                                            </div>
                                            <ClaimStatus discriminationStatus={tableRow?.discrimination_status} />
                                        </QuickTour>
                                    </div>
                                </Tooltip>
                            </CardBody>
                        </Card>
                    )
                })}
        </div>
    )
}

export default RecentMaltreatmentComplaints