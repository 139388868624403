import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import React from 'react';
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../components/UserImg";
import Translate from "@spordle/intl-elements";
import EditCompetitionModal from "../../components/EditCompetitionModal";
import { DisplayCategory } from "../../../../../teams/TeamHelpers";
import AddCompetitionTeamModal from "../../../../../teams/competitionTeamsSearch/modal/AddCompetitionTeamModal";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import withContexts from "../../../../../../helpers/withContexts";
import { LEAGUES } from "../../groupingsConstants";

class CompetitionsSidepanel extends React.Component{
    state = {
        editModalIsOpen: false,
        addTeamModalIsOpen: false,
    }

    toggleAddTeamModal = () => {
        this.setState((prev) => ({
            addTeamModalIsOpen: !prev.addTeamModalIsOpen,
        }))
    }

    toggleEditModal = () => {
        this.setState((prev) => ({
            editModalIsOpen: !prev.editModalIsOpen,
        }))
    }

    addModalOnSuccess = () => {
        this.props.OrganizationContext.getCompetitionTeams(this.props.OrganizationContext.organisation_id)
            .then((competitionsTeams) => {
                const teams = competitionsTeams.teams.filter((team) => team.competition_id === this.props.selectedRows[0].competition_id)
                this.props.syncRows(this.props.createNewValues({ teams: teams }))
            })
    }

    render(){
        return (
            <>
                <EditCompetitionModal
                    isOpen={this.state.editModalIsOpen}
                    toggle={this.toggleEditModal}
                    onSubmit={(values) => {
                        this.props.tableRef.refreshTable();
                        this.props.syncRows(this.props.createNewValues(values))
                    }}
                    competition={this.props.selectedRows[0]}
                />

                <AddCompetitionTeamModal
                    isOpen={this.state.addTeamModalIsOpen}
                    toggle={this.toggleAddTeamModal}
                    orgId={this.props.selectedRows[0].organisation_id}
                    onSuccess={() => this.addModalOnSuccess()}
                    allCompetitionTeams={this.props.selectedRows[0]?.teams || []}
                    selectedCompetition={this.props.selectedRows[0]}
                />

                <SidePanel.Header className="mb-3">
                    <div className='d-flex mb-2'>
                        <SidePanel.ToggleButton />
                    </div>
                    <SidePanel.Title className='editable' onClick={() => this.toggleEditModal(true)}>
                        <DisplayI18n
                            field='name'
                            defaultValue={this.props.selectedRows[0].name}
                            i18n={this.props.selectedRows[0].i18n}
                        />
                        <i className="mdi mdi-pencil text-primary ml-2" />
                    </SidePanel.Title>
                    <SidePanel.Subtitle>
                        {this.props.selectedRows[0].team_category ?
                            <DisplayCategory category={this.props.selectedRows[0].team_category} short />
                            :
                            <DisplayI18n field='short_name' defaultValue={this.props.selectedRows[0].division.short_name} i18n={this.props.selectedRows[0].division.i18n} />
                        }
                    </SidePanel.Subtitle>
                </SidePanel.Header>
                {this.props.selectedRows[0].teams.map((team) => (
                    <UserDisplay key={team.competition_team_id} className='mb-3 px-3 pb-3 border-bottom'>
                        <UserDisplay.Container>
                            <UserImg
                                abbr={team.team.abbreviation || team.team.short_name || team.team.name}
                                alt={team.team.name}
                                filePos={team.team.logo?.file_position}
                                src={team.team.logo?.full_path}
                                width={40}
                            />
                        </UserDisplay.Container>
                        <UserDisplay.Container>
                            <UserDisplay.Title>{team.team.name}</UserDisplay.Title>
                            {team.team.unique_identifier && (
                                <UserDisplay.Subtitle>
                                    #{team.team.unique_identifier}
                                </UserDisplay.Subtitle>
                            )}
                            <UserDisplay.Subtitle>
                                <DisplayI18n field='name' defaultValue={team.team.organisation.organisation_name} i18n={team.team.organisation.i18n} />
                            </UserDisplay.Subtitle>
                        </UserDisplay.Container>
                    </UserDisplay>
                ))}
                <div
                    className="clickable text-link text-center"
                    onClick={() => {
                        this.toggleAddTeamModal();
                    }}
                >
                    + <Translate id='organization.grouping.sidePanel.addTeam' />
                    <div className="small text-muted"><Translate id={'organization.grouping.sidePanel.addTeam.subtitle.' + LEAGUES} /></div>
                </div>
            </>
        )
    }
}

export default withContexts(OrganizationContext)(CompetitionsSidepanel);