export default{
    //misc
    "reports.dashboard.open" : "Ouvrir",

    // DASHBOARD
    "reports.dashboard.title"                     : "Rapports",
    "reports.dashboard.search.placeholder"        : "Trouver un rapport",
    "reports.dashboard.add.custom.report"         : "Nouveau rapport personalisé",
    "reports.dashboard.add.custom.error.title"    : "Une erreur est survenu!",
    "reports.dashboard.add.custom.error.subtitle" : "Réessayez plus tard. Veuillez contacter le support si le problème persiste.",

    // groups
    "reports.dashboard.group.favourites"    : "Favoris",
    "reports.dashboard.group.savedCustom"   : "Personnalisés",
    "reports.dashboard.group.registrations" : "Inscriptions",
    "reports.dashboard.group.tasks"         : "Tâches",
    "reports.dashboard.group.teams"         : "Équipes",
    "reports.dashboard.group.members"       : "Membres",
    "reports.dashboard.group.all"           : "Tous",
    "reports.dashboard.group.financial"     : "Finance",

    "reports.header.registration"           : "Rapport d'inscriptions détaillé",
    "reports.header.membershipFee"          : "Rapport de frais d'affiliation détaillé",
    "reports.header.waivers"                : "Rapport de dispenses",
    "reports.header.multi_branch"           : "Rapport de branches détaillé",
    "reports.header.questionnaire"          : "Rapport de questionnaire",
    "reports.header.qualifications"         : "Rapport de qualifications",
    "reports.header.deficiency"             : "Rapport de déficience de qualification",
    "reports.header.refunds"                : "Rapport de remboursement",
    "reports.header.transactions"           : "Rapport de transactions",
    "reports.header.credits"                : "Rapport des crédits",
    "reports.header.exports"                : "Exportations",
    "reports.header.crc"                    : "Rapport de vérification d'antécédents",
    "reports.header.branch_summary"         : "Rapport de branches sommaire",
    "reports.header.registration_count"     : "Rapport d'inscriptions sommaire",
    "reports.header.clinic_attendees"       : "Rapport des participants cliniques",
    "reports.header.transfer"               : "Rapport des transferts",
    "reports.header.discrimination"         : "Rapport de discrimination",
    "reports.header.double.carding"         : "Rapport de joueurs sur plusieurs équipes",
    "reports.header.not_on_team"            : "Rapport de membres sans équipes",
    "reports.header.previously_on_team"     : "Rapport de membres précédemment sur une équipe",
    "reports.header.injuries"               : "Rapport de blessures",
    "reports.filters.previous_injury"       : "Blessures historique",
    "reports.filters.penalty_involved"      : "Pénalité impliqué",
    "reports.filters.submitted"             : "Soumis",
    "reports.header.team_list"              : "Liste d'équipe détaillé",
    "reports.header.game_incident"          : "Rapport d'incident de jeu",
    "reports.header.team_roster"            : "Rapport de liste d'équipes",
    "reports.header.suspensions"            : "Rapport de suspensions",
    "reports.header.appeals"                : "Rapport des appels",
    "reports.header.membershipFees_summary" : "Rapport de frais d'affiliation sommaire",
    "reports.header.team_list_summary"      : "Liste d'équipe sommaire",

    "reports.filters.with_draft"                  : "Brouillon",
    "reports.filters.gender"                      : "Identité de genre",
    "reports.filters.team_gender"                 : "Identité de genre de l'équipe",
    "reports.filters.period"                      : "Période",
    "reports.filters.reference_period_id"         : "Période de référence",
    "reports.filters.origin"                      : "Origine",
    "reports.filters.online_store"                : "Boutique en ligne",
    "reports.filters.reg_type"                    : "Type d'inscription",
    "reports.filters.reg_status"                  : "Status d'inscription",
    "reports.filters.start_date"                  : "Date de début",
    "reports.filters.end_date"                    : "Date de fin",
    "reports.filters.created_start_date"          : "Date de début de création",
    "reports.filters.created_end_date"            : "Date de fin de création",
    "reports.filters.expiration_start_date"       : "Date de début d'expiration",
    "reports.filters.expiration_end_date"         : "Date de fin d'expiration",
    "reports.filters.custom_form_id"              : "Formulaire",
    "reports.filters.registration_fee_id"         : "Inscription",
    "reports.filters.affiliation_fee_id"          : "Affiliation ",
    "reports.filters.expiration_date"             : "Date d'expiration",
    "reports.filters.created_at"                  : "Créé le",
    "reports.filters.active"                      : "Crédits actifs uniquement",
    "reports.filters.credit_type"                 : "Type de crédit",
    "reports.filters.active_label"                : "Afficher",
    "reports.filters.crc_status"                  : "Statut",
    "reports.filters.on_a_team_roster"            : "Dans une équipe",
    "reports.filters.child_organisation_id.count" : "{count} organisation(s) sélectionnée(s)",
    "reports.filters.divisions.count"             : "{count} division(s) sélectionnée(s)",
    "reports.filters.waivers.count"               : "{count} dispense(s) sélectionnée(s)",
    "reports.filters.qualifications.count"        : "{count} qualification(s) sélectionnée(s)",
    "reports.filters.clinics.count"               : "{count} clinique(s) sélectionnée(s)",
    "reports.filters.teams.count"                 : "{count} équipe(s) sélectionnée(s)",
    "reports.filters.registrationFees.count"      : "{count} inscription(s) sélectionnée(s)",
    "reports.filters.membershipFees.count"        : "{count} affiliation(s) sélectionnée(s)",
    "reports.filters.qualification_id"            : "Qualifications",
    "reports.filters.division"                    : "Division",
    "reports.filters.member_type_id"              : "Type de membre",
    "reports.filters.expiration_status"           : "Statut d'expiration",
    "reports.filters.status"                      : "Statut",
    "reports.filters.with_balance"                : "Avec solde uniquement",
    "reports.filters.organisation_id"             : "Organisation",
    "reports.filters.attended_start_date"         : "Date début de participation",
    "reports.filters.attended_end_date"           : "Date fin de participation",
    "reports.filters.passed_start_date"           : "Date début de passage",
    "reports.filters.passed_end_date"             : "Date fin de passage",
    "reports.filters.certified_start_date"        : "Date début de certification",
    "reports.filters.certified_end_date"          : "Date fin de certification",
    "reports.filters.member_attendee_status"      : "Statut des participants",
    "reports.filters.clinic_id"                   : "Clinique",
    "reports.filters.transfer_type"               : "Type de transfert",
    "reports.filters.transfer_status"             : "Statut de transfert",
    "reports.filters.transfer_reason_type_id"     : "Type de raison de transfert",
    "reports.filters.transfer_duration"           : "Durée de transfert",
    "reports.filters.refund_status"               : "Statut de remboursement",
    "reports.filters.invoice_numbers"             : "Numéro(s) de facture(s)",
    "reports.filters.accident_start_date"         : "Date de début des accidents",
    "reports.filters.accident_end_date"           : "Date de fin des accidents",
    "reports.filters.delivered_start_date"        : "Date de début de livraison",
    "reports.filters.delivered_end_date"          : "Date de fin de livraison",
    "reports.filters.team_id"                     : "Équipe",
    "reports.filters.division_id"                 : "Division",
    "reports.filters.class_id"                    : "Classe",
    "reports.filters.team_category_id"            : "Catégorie d'équipe",
    "reports.filters.position_group_id"           : "Groupe de position",
    "reports.filters.position_id"                 : "Position",
    "reports.filters.team_status_id"              : "État de l'équipe",
    "reports.filters.birthdate_start_date"        : "Date de naissance - Du",
    "reports.filters.birthdate_end_date"          : "Date de naissance - Jusqu'au",
    "reports.filters.specific_date"               : "Date Spécifique",
    "reports.filters.duration"                    : "Durée",
    "reports.filters.suspension_type_id"          : "Type de suspension",
    "reports.filters.appeal_status_id"            : "Statut de l'appel",
    "reports.filters.appeal_regulation_id"        : "Règlement",
    "reports.filters.appeal_to_organisation_id"   : "Appel à l'organisation",
    "reports.filters.appeal_from_organisation_id" : "Appel de l'organisation",
    "reports.filters.min_age"                     : "Âge minimum",
    "reports.filters.max_age"                     : "Âge maximum ",
    "reports.filters.agreement_type"              : "Type d'accord",
    "reports.filters.transaction_status"          : "Transactions à afficher",
    "reports.filters.injury_type"                 : "Type de blessure",
    "reports.filters.payment_type"                : "Type de paiement",
    "reports.filters.injury_status_id"            : "Statut de blessure",

    "reports.filters.waiver_status"     : "Réponse des dispenses",
    "reports.filters.waiver_id"         : "Dispenses",
    "reports.filters.with_registration" : "Avec inscription",
    "reports.filters.with_team"         : "Avec équipe",

    "reports.payment_type.INITIAL_PAYMENT"     : "Paiement initial",
    "reports.payment_type.REFUND"              : "Remboursement",
    "reports.payment_type.INSTALLMENT"         : "Versement",
    "reports.payment_type.ORGANISATION_CREDIT" : "Crédit d'organisation",

    "reports.filters.declined.transactions.only.accepted" : "Transactions acceptées seulement",
    "reports.filters.declined.transactions.only.declined" : "Transactions refusées seulement",
    "reports.filters.declined.transactions.all"           : "Toutes les transactions",

    "reports.injury_type.CLAIM" : "Réclamation",
    "reports.injury_type.INFO"  : "Information",


    "reports.filters.period.required"           : "Période est obligatoire.",
    "reports.filters.reference.period.required" : "La période référence est obligatoire.",
    "reports.filters.waiver.required"           : "Dispense est obligatoire.",
    "reports.filters.clinic.required"           : "Clinique est obligatoire.",
    "reports.filters.form.required"             : "Formulaire est obligatoire.",
    "reports.filters.child_organisation_id"     : "Organisations enfants",
    "reports.filters.division.required"         : "Division est obligatoire.",
    "reports.filters.start_date.required"       : "La date de début est requise.",
    "reports.filters.end_date.required"         : "La date de fin est requise.",
    "reports.filters.child_org_min"             : "Sélectionner au moins une organisation.",
    "reports.filters.child_org"                 : "Un maximum de 50 organisations peuvent être sélectionnées. Laisser vide pour inclure toutes les organisations.",
    "reports.filters.multi_org"                 : "Un maximum de 50 organisations peuvent être sélectionnées.",
    "reports.filters.multi_team_category"       : "Un maximum de 50 catégories d'équipes peuvent être sélectionnées.",
    "reports.filters.max_50"                    : "Un maximum de 50 peuvent être sélectionnées.",
    "reports.filters.multi_org_required"        : "L'organisation est requise.",
    "reports.filters.start_date_required"       : "La date de début est requise.",
    "reports.filters.end_date_required"         : "La date de fin est requise.",
    "reports.filters.clinic_max"                : "Un maximum de 50 cliniques peuvent être sélectionnées individuellement, utiliser l'option «Tout sélectionner» pour plus de 50 cliniques.",
    "reports.filters.qualifications_max"        : "Un maximum de 50 qualifications peuvent être sélectionnées.",
    "reports.filters.qualifications_min"        : "Vous devez sélectionner au moins une qualification.",
    "reports.filters.period.is_after_reference" : "La période doit être après la période de référence.",
    "reports.filters.allSelected"               : "Tous sélectionnés",
    "reports.column.header.organisation_id"     : "Organisations",
    "reports.filters.verified"                  : "Vérifié",
    "reports.filters.ageOverUnder"              : "Tranche d'âge",
    "reports.filters.team_member_status_id"     : "Statut du membre",
    "reports.filters.all_sub_organisations"     : "Toutes les sous-organisations",
    "reports.filters.start_birthdate"           : "Début intervalle date de fête",
    "reports.filters.end_birthdate"             : "Fin intervalle date de fête",
    "reports.filters.not_aware"                 : "Pas au courant des dispenses",
    "reports.filters.signature_type"            : "Type de consentement",

    "reports.filters.expired.EXPIRED" : "Expiré",
    "reports.filters.expired.VALID"   : "Valide",
    "reports.filters.expired.MISSING" : "Manquant",

    "reports.member_attendee.status.REGISTERED" : "Inscrit",
    "reports.member_attendee.status.ATTENDED"   : "Assisté",
    "reports.member_attendee.status.PASSED"     : "Passé",
    "reports.member_attendee.status.CERTIFIED"  : "Certifié",

    "reports.column.header.invoice_number"                            : "Numéro de facture",
    "reports.column.header.invoice_date"                              : "Date de facturation",
    "reports.column.header.hcr_number"                                : "{unique_identifier}",
    "reports.column.header.unique_identifier"                         : "{unique_identifier}",
    "reports.column.header.member_unique_identifier"                  : "{unique_identifier}",
    "reports.column.header.member_first_name"                         : "Prénom",
    "reports.column.header.member_last_name"                          : "Nom de famille",
    "reports.column.header.first_name"                                : "Prénom",
    "reports.column.header.last_name"                                 : "Nom de famille",
    "reports.column.header.email"                                     : "E-mail",
    "reports.column.header.birthdate"                                 : "Date de naissance",
    "reports.column.header.gender"                                    : "Identité de genre",
    "reports.column.header.address"                                   : "Adresse",
    "reports.column.header.parent_name"                               : "Prénom du parent",
    "reports.column.header.parent_family_name"                        : "Nom du parent",
    "reports.column.header.fee_type"                                  : "Type d'inscription",
    "reports.column.header.fee_name"                                  : "Inscription",
    "reports.column.header.registration_amount"                       : "Montant d'inscription",
    "reports.column.header.registration_early_amount"                 : "Montant hâtif",
    "reports.column.header.early_amount"                              : "Montant hâtif",
    "reports.column.header.early_amount_until"                        : "Date de tarifation hâtive",
    "reports.column.header.late_amount"                               : "Montant tardif",
    "reports.column.header.registration_late_amount"                  : "Montant tardif",
    "reports.column.header.late_amount_after"                         : "Date de tarifation tardive",
    "reports.column.header.rebate_amount"                             : "Rabais",
    "reports.column.header.amount_paid"                               : "Montant payé",
    "reports.column.header.amount_to_paid"                            : "Montant",
    "reports.column.header.amount"                                    : "Montant",
    "reports.column.header.amount_due"                                : "Montant dû",
    "reports.column.header.amount_owed"                               : "Montant dû restant",
    "reports.column.header.father_name"                               : "Nom père",
    "reports.column.header.father_family_name"                        : "Nom de famille père ",
    "reports.column.header.father_email"                              : "Courriel père",
    "reports.column.header.father_phone"                              : "Téléphone père",
    "reports.column.header.mother_name"                               : "Nom mère",
    "reports.column.header.mother_family_name"                        : "Nom de famille mère",
    "reports.column.header.mother_email"                              : "Courriel mère",
    "reports.column.header.mother_phone"                              : "Téléphone mère",
    "reports.column.header.scheduled_installment"                     : "Acompte planifié",
    "reports.column.header.division_name"                             : "Division",
    "reports.column.header.class_name"                                : "Classe",
    "reports.column.header.team_category_name"                        : "Catégorie d'équipe",
    "reports.column.header.payment_method"                            : "Mode de remboursement",
    "reports.column.header.organisation_name"                         : "Organisation",
    "reports.column.header.child_organisation"                        : "Organisations enfants",
    "reports.column.header.confirmed_at"                              : "Inscription confirmée",
    "reports.column.header.street_number"                             : "Numéro d'adresse",
    "reports.column.header.street"                                    : "Adresse",
    "reports.column.header.city"                                      : "Ville",
    "reports.column.header.province"                                  : "Province",
    "reports.column.header.zip_code"                                  : "Code Postal",
    "reports.column.header.unit_number"                               : "Numéro d'unité",
    "reports.column.header.registration_name"                         : "Nom de l'inscription",
    "reports.column.header.registration_date_status"                  : "Dernière mise à jour",
    "reports.column.header.registration_organisation_name"            : "Nom d'organisation de l'inscription",
    "reports.column.header.registration_status"                       : "Statut de l'inscription",
    "reports.column.header.affiliation_paid"                          : "Affiliation payée",
    "reports.column.header.affiliation_to_paid"                       : "Affiliation dû",
    "reports.column.header.affiliation_due"                           : "Affiliation dû",
    "reports.column.header.primary_organisation_name"                 : "Organisation primaire",
    "reports.column.header.province_code"                             : "Province",
    "reports.column.header.country_code"                              : "Pays",
    "reports.column.header.country"                                   : "Pays",
    "reports.column.header.waiver_name"                               : "Dispense",
    "reports.column.header.waiver_status"                             : "Signé",
    "reports.column.header.signed_by_identity_name"                   : "Signé par",
    "reports.column.header.signature_date"                            : "Date de signature ",
    "reports.column.header.signed_label"                              : "Libellé de la dispense",
    "reports.column.header.waiver_organisation_name"                  : "Organisation de la dispense",
    "reports.column.header.is_mandatory"                              : "Obligatoire",
    "reports.column.header.credit_amount"                             : "Total",
    "reports.column.header.credit_type_description"                   : "Description Type Crédit",
    "reports.column.header.active"                                    : "Actif",
    "reports.column.header.item_rebate_amount"                        : "Rabais",
    "reports.column.header.item_paid_amount"                          : "Montant payé",
    "reports.column.header.item_amount"                               : "Montant item",
    "reports.column.header.item_due_amount"                           : "Montant dû",
    "reports.column.header.item_owed_amount"                          : "Montant dû restant",
    "reports.column.header.registration_count"                        : "Nb d'inscription",
    "reports.column.header.item_left"                                 : "Articles restants",
    "reports.column.header.item_sold"                                 : "Articles vendus",
    "reports.column.header.item_type"                                 : "Type d'article",
    "reports.column.header.available_place"                           : "Espaces restant",
    "reports.column.header.item_name"                                 : "Nom d'item",
    "reports.column.header.clinic_name"                               : "Nom de clinique",
    "reports.column.header.clinic_organisation_name"                  : "Organisation de clinique",
    "reports.column.header.clinic_first_session_date"                 : "Date de première session",
    "reports.column.header.member_attendee_status"                    : "Statut du participant",
    "reports.column.header.attended"                                  : "Participé",
    "reports.column.header.attended_date"                             : "Date de participation",
    "reports.column.header.passed"                                    : "Passé",
    "reports.column.header.passed_date"                               : "Date de passage",
    "reports.column.header.cancelled_at"                              : "Annulée le",
    "reports.column.header.cancelled_by"                              : "Participation annulée par",
    "reports.column.header.cancellation_reason"                       : "Raison d'annulation",
    "reports.column.header.certified"                                 : "Certifié",
    "reports.column.header.certified_date"                            : "Date de certification",
    "reports.column.header.invoice_status"                            : "Statut de facturation",
    "reports.column.header.total_amount"                              : "Montant Total",
    "reports.column.header.clinic_amount"                             : "Montant de clinique",
    "reports.column.header.clinic_additional_fee"                     : "Frais additionnel",
    "reports.column.header.due_amount"                                : "Montant dû",
    "reports.column.header.paid_amount"                               : "Montant payé",
    "reports.column.header.owed_amount"                               : "Montant dû restant",
    "reports.column.header.organisation"                              : "Organisation",
    "reports.column.header.target_organisation"                       : "Organisation cible",
    "reports.column.header.updated_at"                                : "Mis à jour",
    "reports.column.header.teams_info"                                : "Informations d'équipes",
    "reports.column.header.expiration_date"                           : "Date d'expiration",
    "reports.column.header.shared_expiration_date"                    : "Date d'expiration partagée",
    "reports.column.header.transfer_duration"                         : "Durée de transfert",
    "reports.column.header.transfer_type"                             : "Type de transfert",
    "reports.column.header.transfer_reason_type"                      : "Raison de transfert",
    "reports.column.header.transfer_request_state"                    : "État du transfert",
    "reports.column.header.transfer_status"                           : "Statut de transfert",
    "reports.column.header.current_step"                              : "Étape actuelle",
    "reports.column.header.period"                                    : "Période",
    "reports.column.header.injury_case_number"                        : "Numéro de cas",
    "reports.column.header.injury_type"                               : "Type de réclamation",
    "reports.column.header.participant_type"                          : "Type de participant",
    "reports.column.header.description"                               : "Description",
    "reports.column.header.guardian"                                  : "Tuteur légal",
    "reports.column.header.accident_date"                             : "Date d'accident",
    "reports.column.header.delivered_date"                            : "Date livrée",
    "reports.column.header.body_part_name"                            : "Partie du corps",
    "reports.column.header.injury_nature_name"                        : "Nature des blessures",
    "reports.column.header.injury_on_site_care_name"                  : "Soins sur site",
    "reports.column.header.venue_name"                                : "Lieu",
    "reports.column.header.injury_environment_other"                  : "Environnement autre",
    "reports.column.header.injury_environment_name"                   : "Environnement",
    "reports.column.header.injury_condition_name"                     : "État",
    "reports.column.header.injury_condition_other"                    : "État autre",
    "reports.column.header.correct_age"                               : "Âge précis",
    "reports.column.header.sanctionned_event"                         : "Evénement sanctionné",
    "reports.column.header.injury_cause_name"                         : "Cause",
    "reports.column.header.event_type"                                : "Type d'événement",
    "reports.column.header.event_type_other"                          : "Type d'événement autre",
    "reports.column.header.injury_equipment_name"                     : "Équipement",
    "reports.column.header.previous_injury"                           : "Blessure précédente",
    "reports.column.header.previous_injury_other"                     : "Blessure précédente autre",
    "reports.column.header.penalty_involved"                          : "Pénalité impliquée",
    "reports.column.header.injury_absence_name"                       : "Absence",
    "reports.column.header.official_unique_identifier"                : "{unique_identifier} de l'officiel",
    "reports.column.header.official_first_name"                       : "Prénom de l'officiel",
    "reports.column.header.official_last_name"                        : "Nom de l'officiel",
    "reports.column.header.official_birthdate"                        : "Date de naissance de l'officiel",
    "reports.column.header.official_gender"                           : "Identité de genre de l'officiel",
    "reports.column.header.official_email"                            : "Courriel de l'officiel",
    "reports.column.header.injury_status_name"                        : "Statut de blessure",
    "reports.column.header.employed"                                  : "Employé",
    "reports.column.header.employer_name"                             : "Employeur",
    "reports.column.header.provincial_coverage"                       : "Couverture provinciale",
    "reports.column.header.health_insurance_number"                   : "Numéro d'assurance maladie",
    "reports.column.header.other_insurance"                           : "Autre assurance maladie",
    "reports.column.header.submitted"                                 : "Soumis",
    "reports.column.header.injury_pay_to_name"                        : "Payer à",
    "reports.column.header.injury_pay_to_other"                       : "Payer à autre",
    "reports.column.header.reserve"                                   : "Réserve",
    "reports.column.header.reported_by_name"                          : "Rapporté par nom",
    "reports.column.header.reported_by_email"                         : "Rapporté par email",
    "reports.column.header.member_type_name"                          : "Type de membre",
    "reports.column.header.qualification"                             : "Qualification",
    "reports.column.header.certification_expiration"                  : "Expiration de certification",
    "reports.column.header.certification_number"                      : "Numéro de certification",
    "reports.column.header.member_organisation"                       : "Organisation du membre",
    "reports.column.header.province_id"                               : "Province",
    "reports.column.header.country_id"                                : "Pays",
    "reports.column.header.qualification_name"                        : "Nom de qualification",
    "reports.column.header.qualification_category"                    : "Catégorie de qualification",
    "reports.column.header.qualification_level"                       : "Niveau de qualification",
    "reports.column.header.qualification_organisation"                : "Organisation de la clinique",
    "reports.column.header.qualification_location"                    : "Emplacement de la clinique",
    "reports.column.header.qualification_city"                        : "Cité de la qualification",
    "reports.column.header.qualification_province"                    : "Province de la qualification",
    "reports.column.header.memo"                                      : "Memo",
    "reports.column.header.taken_date"                                : "Date de la qualififcation",
    "reports.column.header.status"                                    : "Statut",
    "reports.column.header.origin"                                    : "Origine",
    "reports.column.header.balance"                                   : "Solde",
    "reports.column.header.note"                                      : "Note",
    "reports.column.header.credit_note"                               : "Note",
    "reports.column.header.created_by"                                : "Créé par",
    "reports.column.header.created_at"                                : "Créé le",
    "reports.column.header.credit_type"                               : "Type de crédit",
    "reports.column.header.credit_type_name"                          : "Type de crédit",
    "reports.column.header.crc_type"                                  : "Type de vérification d'antécédents",
    "reports.column.header.issue_date"                                : "Date d'émission",
    "reports.column.header.sequential_number"                         : "Sequential Number",
    "reports.column.header.incident_date"                             : "Date d'incident",
    "reports.column.header.arena"                                     : "Aréna",
    "reports.column.header.home_team_name"                            : "Équipe d'accueil",
    "reports.column.header.away_team_name"                            : "Équipe visiteur",
    "reports.column.header.belongs_to"                                : "Appartient à",
    "reports.column.header.complaint_by"                              : "Plainte par",
    "reports.column.header.complaint_by_last_name"                    : "Plainte par nom de famille",
    "reports.column.header.complaint_by_first_name"                   : "Plainte par prénom",
    "reports.column.header.complaint_by_email"                        : "Plainte par courriel",
    "reports.column.header.complaint_by_fax"                          : "Plainte par fax",
    "reports.column.header.complaint_by_address"                      : "Plainte par adresse",
    "reports.column.header.complaint_by_city"                         : "Plainte par ville",
    "reports.column.header.complaint_by_postal_code"                  : "Plainte par code postal",
    "reports.column.header.complaint_by_province_id"                  : "Plainte par province",
    "reports.column.header.complaint_by_phone"                        : "Plainte par téléphone",
    "reports.column.header.discrimination_reason"                     : "Raison de la discrimination",
    "reports.column.header.role"                                      : "Rôle",
    "reports.column.header.members_infos"                             : "Informations des membres",
    "reports.column.header.identity_first_name"                       : "Prénom d'identité",
    "reports.column.header.identity_family_name"                      : "Nom d'identité",
    "reports.column.header.identity_name"                             : "Nom d'identité",
    "reports.column.header.identity_email"                            : "Courriel d'identité",
    "reports.column.header.discrimination_approbation_identity_name"  : "Nom de l'approbateur de discrimination",
    "reports.column.header.discrimination_approbation_identity_email" : "Courriel d'approbateur de discrimination",
    "reports.column.header.discrimination_approbation_status"         : "Statut d'approbation de la discrimination",
    "reports.column.header.discrimination_status_name"                : "Statut de discrimination",
    "reports.column.header.team_name"                                 : "Nom d'équipe",
    "reports.column.header.on_behalf_first_name"                      : "Au nom de prénom",
    "reports.column.header.on_behalf_last_name"                       : "Au nom de nom de famille",
    "reports.column.header.on_behalf_birthdate"                       : "Au nom de la date de naissance",
    "reports.column.header.type"                                      : "Type",
    "reports.column.header.closed_reason"                             : "Raison fermée",
    "reports.column.header.detailed_summary"                          : "Résumé détaillé",
    "reports.column.header.invoice_identity_name"                     : "Nom sur la facture",
    "reports.column.header.invoice_identity_email"                    : "Courriel sur la facture",
    "reports.column.header.internal_note"                             : "Note interne",
    "reports.column.header.external_note"                             : "Note externe",
    "reports.column.header.refund_amount"                             : "Montant remboursé",
    "reports.column.header.refund_date"                               : "Date de remboursement",
    "reports.column.header.position_group_name"                       : "Groupe de position",
    "reports.column.header.team_organisation_name"                    : "Organisation de l'équipe",
    "reports.column.header.affiliate"                                 : "Affilié",
    "reports.column.header.tryout"                                    : "Essaie",
    "reports.column.header.import"                                    : "Importé",
    "reports.column.header.registration_date"                         : "Date d'inscription",
    "reports.column.header.position_name"                             : "Position",
    "reports.column.header.team_number"                               : "Numéro d'équipe",
    "reports.column.header.team_organisation"                         : "Organisation",
    "reports.column.header.team_status"                               : "Statut d'équipe",
    "reports.column.header.team_gender"                               : "Identité de genre",
    "reports.column.header.category_name"                             : "Catégorie",
    "reports.column.header.player_count"                              : "Compte joueur",
    "reports.column.header.bench_staff_count"                         : "Compte du personnel de banc",
    "reports.column.header.other_staff_count"                         : "Autres membres du personnel",
    "reports.column.header.team_organisation_path"                    : "Chemin d'organisation d'équipe",
    "reports.column.header.suspension_name"                           : "Suspension Name",
    "reports.column.header.suspension_type_name"                      : "Suspension Type",
    "reports.column.header.game_incident_number"                      : "Incident Number",
    "reports.column.header.primary_official_unique_identifier"        : "Principal Officiel {unique_identifier}",
    "reports.column.header.primary_official_first_name"               : "Prénom officiel principal ",
    "reports.column.header.primary_official_last_name"                : "Nom officiel principal",
    "reports.column.header.primary_official_email"                    : "Email officiel principal",
    "reports.column.header.game_officials"                            : "Officiels de jeu",
    "reports.column.header.game_number"                               : "Numéro de partie",
    "reports.column.header.home_team_coach_first_name"                : "Prénom entraîneur accueil",
    "reports.column.header.home_team_coach_last_name"                 : "Nom de famille entraîneur accueil",
    "reports.column.header.home_team_manager_first_name"              : "Prénom gestionnaire accueil",
    "reports.column.header.home_team_manager_last_name"               : "Nom gestionnaire accueil",
    "reports.column.header.away_team_coach_first_name"                : "Prénom entraîneur visiteur",
    "reports.column.header.away_team_coach_last_name"                 : "Nom entraîneur visiteur",
    "reports.column.header.away_team_manager_first_name"              : "Prénom gestionnaire visiteur",
    "reports.column.header.away_team_manager_last_name"               : "Nom gestionnaire visiteur",
    "reports.column.header.game_incident_status_name"                 : "Statut d'incident",
    "reports.column.header.verified"                                  : "Vérifié",
    "reports.column.header.created_by_name"                           : "Créé par",
    "reports.column.header.created_by_email"                          : "Créé par courriel",
    "reports.column.header.teams_staff"                               : "Personnel pour",
    "reports.column.header.teams_player"                              : "Joueur pour",
    "reports.column.header.teams_coach"                               : "Entraîneur pour",
    "reports.column.header.jersey_number"                             : "Numéro de chandail",
    "reports.column.header.team_short_name"                           : "Nom abrégé d'équipe",
    "reports.column.header.team_fee"                                  : "Frais d'équipe",
    "reports.column.header.period_name"                               : "Période",
    "reports.column.header.organisation_path"                         : "Arbre d'organisation",
    "reports.column.header.overwrite_restriction"                     : "Remplacement de la restriction",
    "reports.column.header.team_home_color"                           : "Couleur de la maison d'équipe",
    "reports.column.header.team_away_color"                           : "Couleur de l'équipe",
    "reports.column.header.clinic_first_session_location"             : "Emplacement de première session",
    "reports.column.header.team_organisation_id"                      : "ID d'organisation de l'équipe",
    "reports.column.header.release_date"                              : "Date de libération",
    "reports.column.header.comments"                                  : "Commentaires",
    "reports.column.header.comment"                                   : "Commentaires",
    "reports.column.header.player_status"                             : "Statut du joueur",
    "reports.column.header.team_member_status_id"                     : "Id du statut du membre",
    "reports.column.header.third_party_registration"                  : "Inscription de tierce partie",
    "reports.column.header.member_primary_organisation "              : "Organisation principale du membre ",
    "reports.column.header.region_fee"                                : "Région des frais",
    "reports.column.header.branch_fee"                                : "Frais de branche",
    "reports.column.header.federation_fee"                            : "Frais de fédération",
    "reports.column.header.is_double_carded"                          : "Joueur sur deux équipes",
    "reports.column.header.double_carding_team"                       : "Équipe a des joueurs sur multiples équipes",
    "reports.column.header.other_organisation_fee"                    : "Frais d'autre organisation",
    "reports.column.header.member_primary_organisation"               : "Organisation principale du membre",
    "reports.column.header.suspension_code"                           : "Code de suspension",
    "reports.column.header.other_infraction"                          : "Autre infraction",
    "reports.column.header.duration"                                  : "Durée",
    "reports.column.header.start_date"                                : "Date de début",
    "reports.column.header.end_date"                                  : "Date de fin",
    "reports.column.header.opposing_team_name"                        : "Nom de l'équipe adverse",
    "reports.column.header.penality_minutes"                          : "Minutes de pénalité",
    "reports.column.header.game_suspended"                            : "Jeu suspendu",
    "reports.column.header.received_date"                             : "Date de réception",
    "reports.column.header.printed_date"                              : "Date d'impression",
    "reports.column.header.submited_by"                               : "Rapport par",
    "reports.column.header.location"                                  : "Emplacement",
    "reports.column.header.official_name"                             : "Nom de l'officiel",
    "reports.column.header.reference_number"                          : "Numéro de réference",
    "reports.column.header.submitted_email"                           : "Courriel soumis",
    "reports.column.header.appeal_to_organisation_name"               : "Appel à l'organisation",
    "reports.column.header.appeal_to_team_name"                       : "Appel à l'équipe",
    "reports.column.header.appeal_from_organisation_name"             : "Appel provenant de l'organisation",
    "reports.column.header.appeal_from_team_name"                     : "Appel provenant de l'équipe",
    "reports.column.header.appeal_status_name"                        : "Statut d'appel",
    "reports.column.header.appeal_approve_name"                       : "Appel approuvé par",
    "reports.column.header.appeal_sent_date"                          : "Date d'envois de l'appel",
    "reports.column.header.appeal_approve_date"                       : "Date d'approbation d'appel",
    "reports.column.header.appeal_date"                               : "Date d'appel",
    "reports.column.header.payment_method_name"                       : "Mode de paiement",
    "reports.column.header.appeal_amount_owed"                        : "Montant dû",
    "reports.column.header.appeal_amount_paid"                        : "Montant payé",
    "reports.column.header.appeal_amount_organisation_name"           : "Organisation du montant d'appel",
    "reports.column.header.appeal_regulation_name"                    : "Nom de réglement de l'appel",
    "reports.column.header.nb_players"                                : "Nombre de joueurs",
    "reports.column.header.nb_male_players"                           : "Nombre de joueur masculin",
    "reports.column.header.nb_female_players"                         : "Nombre de joueur féminin",
    "reports.column.header.nb_pnt_players"                            : "Nombre de joueur de genre X",
    "reports.column.header.nb_coach"                                  : "Nombre d'entraîneurs",
    "reports.column.header.nb_male_coach"                             : "Nombre d'entraîneurs masculin",
    "reports.column.header.nb_female_coach"                           : "Nombre d'entraîneurs féminin",
    "reports.column.header.nb_pnt_coach"                              : "Nombre d'entraîneurs de genre X",
    "reports.column.header.nb_staff"                                  : "Nombre de membres du personnel",
    "reports.column.header.nb_male_staff"                             : "Nombre de membres du personnel masculin",
    "reports.column.header.nb_female_staff"                           : "Nombre de membres du personnel féminin",
    "reports.column.header.nb_pnt_staff"                              : "Nombre de membres du personnel de genre X",
    "reports.column.header.nb_psd_staff"                              : "Nombre de membres du personnel préférant se décrire",
    "reports.column.header.nb_psd_coach"                              : "Nombre de d'entraîneurs préférant se décrire",
    "reports.column.header.nb_psd_players"                            : "Nombre de joueurs préférant se décrire",
    "reports.column.header.category_age_min"                          : "Âge minimum de catégorie",
    "reports.column.header.category_age_max"                          : "Âge maximum de catégorie",
    "reports.column.header.team_comments"                             : "Commentaires sur l'équipe",
    "reports.column.header.regular_player_count"                      : "Nombre de joueur régulier",
    "reports.column.header.affiliated_player_count"                   : "Nombre de joueurs affiliés",
    "reports.column.header.number_male_players"                       : "Nombre de joueurs masculin",
    "reports.column.header.male_player_count"                         : "Nombre de joueurs masculin",
    "reports.column.header.number_female_players"                     : "Nombre de joueurs féminin",
    "reports.column.header.female_player_count"                       : "Nombre de joueurs féminin",
    "reports.column.header.number_x_players"                          : "Nombre de joueurs X",
    "reports.column.header.number_prefer_not_to_say_players"          : "Nombre de joueurs préférant ne pas répondre",
    "reports.column.header.prefer_not_to_say_player_count"            : "Nombre de joueurs préférant ne pas répondre",
    "reports.column.header.number_prefer_to_self_describe_players"    : "Nombre de joueurs préférant se décrire",
    "reports.column.header.prefer_to_self_describe_player_count"      : "Nombre de joueurs préférant se décrire",
    "reports.column.header.number_release_players"                    : "Nombre de joueurs libéré",
    "reports.column.header.number_release_bench"                      : "Nombre d'employés de banc libéré",
    "reports.column.header.clinic_reference"                          : "Référence de clinique",
    "reports.column.header.member_primary_organisation_name"          : "Organisation principale du membre",
    "reports.column.header.member_shared_organisation_name"           : "Organisations partagées du membre",
    "reports.column.header.shared_team_name"                          : "Équipe partagée",
    "reports.column.header.outstanding_balance"                       : "Solde restante",
    "reports.column.header.tax_amount"                                : "Montant des taxes",
    "reports.column.header.phone"                                     : "Téléphone du contact",
    "reports.column.header.crc_is_expired"                            : "Vérification d'antécédents",
    "reports.column.header.api_partner_name"                          : "Partenaire API",
    "reports.column.header.signature_type"                            : "Type d'accord",
    "reports.column.header.signed_with_organisation_name"             : "Signé avec organisation",
    "reports.column.header.cancelled_by_identity_name"                : "Annulé par",
    "reports.column.header.confirm_label"                             : "Étiquette de confirmation",
    "reports.column.header.decline_label"                             : "Étiquette de refus",
    "reports.column.header.current_member_status"                     : "Statut actuel du membre",
    "reports.column.header.payment_type"                              : "Type de paiement",
    "reports.column.header.invoice_payment_id"                        : "Identifiant de paiement de la facture",
    "reports.column.header.invoice_amount"                            : "Montant de la facture",
    "reports.column.header.transaction_date"                          : "Date de la transaction",
    "reports.column.header.provider"                                  : "Fournisseur",
    "reports.column.header.merchant_account"                          : "Compte Marchand",
    "reports.column.header.payment_status"                            : "Statut du paiement",
    "reports.column.header.registration_member_type_name"             : "Type de membre de l'organisation",
    "reports.column.header.amount_to_pay"                             : "Montant à payer",
    "reports.column.header.affiliation_amount"                        : "Montant d'affiliation",
    "reports.column.header.team_member_info"                          : "Information du membre de l'équipe",

    "reports.column.origin.ADMIN"  : "Administrateur",
    "reports.column.origin.ONLINE" : "Transaction en ligne",
    "reports.column.origin.IMPORT" : "Import",

    "reports.registration.transfer_status.OPEN"   : "Ouvert",
    "reports.registration.transfer_status.CLOSED" : "Fermé",

    "reports.column.misc.unit" : "Unité",

    "reports.switch.to.detailed" : "Allez au rapport détaillé",
    "reports.switch.to.overview" : "Allez au rapport général",


    "reports.column.status.DRAFT"          : "Brouillon",
    "reports.column.status.PENDING"        : "En attente",
    "reports.column.status.COMPLETED"      : "Confirmé",
    "reports.column.status.PARTIALLY_PAID" : "Payé partiellement",
    "reports.column.status.CANCELLED"      : "Annulé",
    "reports.column.status.REGISTERED"     : "Enregistré",
    "reports.column.status.PASSED"         : "Passé",

    "reports.column.clinic_status.REGISTERED" : "Inscrit",
    "reports.column.clinic_status.ATTENDED"   : "Assisté",
    "reports.column.clinic_status.PASSED"     : "Passé",
    "reports.column.clinic_status.CERTIFIED"  : "Certifié",

    "reports.filter.period.allSelected" : "Tous sélectionnés",

    "reports.registration.status.CONFIRMED" : "Confirmé",
    "reports.registration.status.CANCELLED" : "Annulé",
    "reports.registration.status.PENDING"   : "En attente",

    "reports.registration.transfer_duration.PERMANENT" : "Permanent",
    "reports.registration.transfer_duration.SHARE"     : "Share",

    "reports.registration.transfer_type.ASSOCIATION"   : "Association",
    "reports.registration.transfer_type.BRANCH"        : "Branch",
    "reports.registration.transfer_type.INTERNATIONAL" : "International",
    "reports.registration.transfer_type.USA"           : "USA",

    "reports.with_registration.YES"                      : "Avec",
    "reports.with_registration.YES_ANYWHERE"             : "Oui - de n'importe où",
    "reports.with_registration.YES_MY_ORGANISATION_ONLY" : "Oui - de mon organisation seulement",
    "reports.with_registration.NO"                       : "Sans",
    "reports.with_registration.ALL"                      : "Toutes les options",
    "reports.with_registration.NO_INFO"                  : "Aucune Information",

    "reports.filter.with_team.YES" : "Avec",
    "reports.filter.with_team.NO"  : "Sans",
    "reports.filter.with_team.ALL" : "Les deux",

    "reports.waiver_status.YES"            : "Oui",
    "reports.waiver_status.NO"             : "Non",
    "reports.waiver_status.NOT_SIGNED_YET" : "Pas encore signé",

    "reports.ageOverUnder.ALL"      : "Tout",
    "reports.ageOverUnder.overAge"  : "Surclassement",
    "reports.ageOverUnder.OVERAGE"  : "Surclassement",
    "reports.ageOverUnder.underAge" : "Sous-classement",
    "reports.ageOverUnder.UNDERAGE" : "Sous-classement",

    "reports.claim_type.CLAIM" : "Réclamation",
    "reports.claim_type.INFO"  : "Information",

    "reports.waivers.status.SIGNED"         : "Signé",
    "reports.waivers.status.NOT_SIGNED"     : "Pas signé",
    "reports.waivers.status.ALL"            : "Toutes les dispenses",
    "reports.waivers.status.YES"            : "Oui",
    "reports.waivers.status.NO"             : "Non",
    "reports.waivers.status.NOT_SIGNED_YET" : "Pas Encore signée",

    "reports.waivers.aggreement.type.VERBAL"   : "Consentement verbal",
    "reports.waivers.aggreement.type.PHYSICAL" : "Consentement physique",
    "reports.waivers.aggreement.type.ONLINE"   : "Consentement en ligne",

    "reports.discrimination.reason.RACE"            : "Race",
    "reports.discrimination.reason.GENDER_IDENTITY" : "Identité de genre",
    "reports.discrimination.reason.MARITAL_STATUS"  : "État civil",
    "reports.discrimination.reason.RELIGION"        : "Religion",
    "reports.discrimination.reason.AGE"             : "Âge",
    "reports.discrimination.reason.GENETIC"         : "Génétique",
    "reports.discrimination.reason.DISABILITY"      : "Handicap",

    "reports.discrimination.type.ON_ICE" : "Sur la glace",
    "reports.discrimination.type.OTHER"  : "Autre",

    "reports.discrimination.closed_reasons.WRITTEN_WARNING"          : "Avertissement écrit",
    "reports.discrimination.closed_reasons.EDUCATION"                : "Éducation",
    "reports.discrimination.closed_reasons.PROBATION"                : "Probation",
    "reports.discrimination.closed_reasons.ELIGIBILITY_RESTRICTIONS" : "Restrictions d'éligibilité",
    "reports.discrimination.closed_reasons.OTHER_SANCTIONS"          : "Autres sanctions",
    "reports.discrimination.closed_reasons.COMPLAINT_DISMISSED"      : "Plainte rejetée",

    "reports.crc.expired.status.EXPIRED" : "Vérification d'antédécents Expirée",
    "reports.crc.expired.status.VALID"   : "Vérification d'antédécents Valide ",
    "reports.crc.expired.status.NO_CRC"  : "Aucune Vérification d'antédécents",

    "reports.member.status.available.STAFF"  : "Personnel",
    "reports.member.status.available.PLAYER" : "Joueur",

    "reports.discrimination.member_type.PLAYER"      : "Joueur",
    "reports.discrimination.member_type.BENCH"       : "Personnel / Entraîneur",
    "reports.discrimination.member_type.BENCH_STAFF" : "Personnel / Entraîneur",
    "reports.discrimination.member_type.VOLUNTEER"   : "Bénévole",
    "reports.discrimination.member_type.PARENT"      : "Parente",
    "reports.discrimination.member_type.OFFICIAL"    : "Officielle",
    "reports.discrimination.member_type.EMPLOYEE"    : "Employée",
    "reports.discrimination.member_type.OTHER"       : "Autre",
    "reports.discrimination.member_type.ANONYMOUS"   : "Anonyme",

    "reports.discrimination.belongs.HOME" : "Maison",
    "reports.discrimination.belongs.AWAY" : "Visiteur",

    "reports.refunds.status.PENDING"   : "En attente",
    "reports.refunds.status.COMPLETED" : "Complété",

    "reports.filter.with_registration.YES" : "Oui",
    "reports.filter.with_registration.NO"  : "Non",
    "reports.filter.with_registration.ALL" : "Tous",


    "reports.registration.type.REGISTRATION" : "Inscription",
    "reports.registration.type.OTHER"        : "Autre",
    "reports.registration.type.CLINIC"       : "Clinique",

    "reports.qualification.expiration_status.EXPIRED_ONLY" : "Expirées seulement",
    "reports.qualification.expiration_status.VALID_ONLY"   : "Valides uniquement",
    "reports.qualification.expiration_status.ALL"          : "Tout",

    "reports.column.duration.ALL"        : "Tous",
    "reports.column.duration.DEFINITE"   : "Définie",
    "reports.column.duration.INDEFINITE" : "Indéfinie",

    "reports.filter.status.REGISTERED" : "Enregistré",
    "reports.filter.status.ATTENDED"   : "Assisté",
    "reports.filter.status.PASSED"     : "Passé",
    "reports.filter.status.CERTIFIED"  : "Certifié",
    "reports.filter.status.COMPLETED"  : "Complété",
    "reports.filter.status.TRAINED"    : "Entraîné",

    "reports.spordleTable.pagination.message" : "Entrées {itemStartPos} à {itemEndPos} sur un total de {totalItems} entrées.",

    "reports.tooltip.waivers.organisation"          : "Choisir une organisation cherchera pour les dispenses dans ses organisations enfants en plus de ses propres dispenses.",
    "reports.tooltip.waivers.notAware"              : "Cette option va afficher toutes les transactions complétées avant l’ajout de cette dispense sur l’inscription.",
    "reports.tooltip.waivers.no.select.selects.all" : "Rien sélectionner pour afficher tout les résultats.",

    "reports.customize.title"                        : "Personnalisation du rapport",
    "reports.customize.fitResults"                   : "Adapter les résultats à la page",
    "reports.customize.allResults"                   : "Afficher tous les résultats",
    "reports.customize.displayStyle"                 : "Style d'affichage",
    "reports.customize.pagination"                   : "Nombre de résultats par page",
    "reports.customize.columns"                      : "Définir les colonnes",
    "reports.customize.availableColumns"             : "Colonnes disponibles",
    "reports.customize.selectedColumns"              : "Colonnes sélectionnées",
    "reports.customize.tooltip.fullscreen"           : "Plein écran",
    "reports.customize.tooltip.quitFullscreen"       : "Quitter plein écran",
    "reports.customize.tooltip.refresh"              : "Rafraîchir",
    "reports.customize.tooltip.pagination"           : "Nombre d'éléments par page",
    "reports.customize.modal.triggerModal"           : "Nouveau rapport personnalisé",
    "reports.customize.modal.triggerModal.edit"      : "Modifier le rapport personnalisé",
    "reports.customize.modal.title.edit"             : "Modification de rapport personnalisé",
    "reports.customize.modal.title"                  : "Création de rapport personnalisé",
    "reports.customize.modal.note"                   : "Les colonnes de questions seront automatiquement ajoutés à la fin de ce rapport.",
    "reports.customize.modal.deleteTitle"            : "Vous êtes sur le point de supprimer le rapport suivant:",
    "reports.customize.modal.deleteTitle.hint"       : "Seuls les rapports personnalisés sont supprimables",
    "reports.customize.modal.label.columns"          : "Colonnes",
    "reports.customize.modal.label.columns.required" : "Veuillez sélectionner au moins une colonne pour votre rapport.",
    "reports.customize.modal.edit.label"             : "Le rapport personnalisé a été modifié avec succès",
    "reports.customize.modal.confirm.label"          : "Le rapport personnalisé a été créé avec succès",
    "reports.customize.modal.confirm.action.view"    : "Voir le nouveau rapport",
    "reports.customize.modal.confirm.action.stay"    : "Rester sur ce rapport",
    "reports.customize.modal.save.filter.values"     : "Enregistrer les valeurs du filtre",
    "reports.customize.modal.save.filter.tooltip"    : "Cela vous permet d'enregistrer les valeurs des filtres afin que vous n'ayez pas à les réentrer pour exécuter une même recherche.",


    "reports.exports.expires"           : "Exp.",
    "reports.exports.created"           : "Créé",
    "reports.exports.pending_download"  : "En attente du téléchargement",
    "reports.exports.expired_download"  : "Expiré",
    "reports.exports.noData"            : "Aucune donnée",
    "reports.exports.download"          : "Télécharger mon rapport",
    "reports.exports.generating"        : "Génération du rapport...",
    "reports.exports.generationSuccess" : "Rapport généré!",
    "reports.exports.generatingQueue"   : "Vous avez {numberOfReports, plural, =0 {# rapport} one {# rapport} other {# rapports}} en génération.",

    "reports.exports.errors.3370"         : "Le rapport est expiré.",
    "reports.exports.errors.3385"         : "Le statut de demande de demande n'est pas valide.",
    "reports.exports.errors.3386"         : "Le lien n'a pas encore été généré pour ce rapport de demande.",
    "reports.exports.errors.3045"         : "Données incompatibles.",
    "reports.exports.errors.3015"         : "Utilisateur non trouvé.",
    "reports.exports.errors.declined"     : "Un rapport a échoué",
    "reports.exports.errors.declinedText" : "Un ou plusieurs de vos rapports récemment générés ont échoué. Veuillez réessayer.",

    "reports.exports.errors.noData"     : "Un rapport est vide",
    "reports.exports.errors.noDataText" : "Un ou plusieurs de vos rapports récemment générés sont vides. Veuillez changer les filtres et réessayer.",

    "reports.exports.noExports" : "Vous n'avez aucun rapports exportés.",

    "reports.limit.message1" : "Votre rapport est trop lourd, ",
    "reports.limit.message2" : "veuillez télécharger",
    "reports.limit.message3" : "pour voir toutes les données.",

    "reports.exports.messages.noData" : "Ce rapport est vide.",

    "reports.teamListSummary.ageRange.tooltip" : "Pour rechercher un âge spécifique, entrez le même chiffre dans les champs minimum et maximum.",

    "reports.validation.maxAge.required"                          : "L'âge maximum est requis.",
    "reports.validation.minAge.required"                          : "L'âge minimum est requis.",
    "reports.validation.maxAge.mustBeLarger.minAge"               : "L'âge maximum doit être égal ou supérieur à l'âge minimum.",
    "reports.validation.age.mustBePositive"                       : "L'âge doit être un nombre positif.",
    "reports.validation.no.affiliation.when.registration"         : "Un frais d'affiliation ne peut être sélectionné en même temps qu'un frais d'inscription.",
    "reports.validation.period_id.required.when.on_a_team_roster" : "Une période est requise lorsqu'on vérifie si un membre est sur un cahier d'équipe.",

    "reports.questionnaire.form.tooltip" : "Les formulaires peuvent être utilisés par les enregistrements, les enregistrements ou les cliniques.",

    "reports.engine.date.placeholder" : "AAAA-MM-JJ",

    "reports.engine.validation.single.date.time" : "Veuillez entrer une date ou une heure valide.",
    "reports.engine.validation.start.date"       : "Veuillez entrer une date de début et une date de fin de valides. Cette date doit être avant la date de fin. ",
    "reports.engine.validation.end.date"         : "Veuillez entrer une date de début et une date de fin de valides. Cette date doit être après la date de début.",
    "reports.engine.validation.start.number"     : "Veuillez entrer un numéro de départ valide.",
    "reports.engine.validation.end.number"       : "Veuillez entrer un numéro de fin valide. Ce numéro doit être supérieur au numéro de départ.",
    "reports.engine.validation.number"           : "Veuillez entrer un nombre valide.",
    "reports.engine.validation.single.select"    : "Veuillez sélectionner une option.",
    "reports.engine.validation.multi.select"     : "Veuillez sélectionner une ou plusieurs options.",
    "reports.submit.button.dropdown.detailed"    : "Aller au rapport détaillé",
    "reports.submit.button.dropdown.summary"     : "Aller au rapport sommaire",
    "reports.submit.button.search.summary"       : "Rechercher le sommaire",
    "reports.submit.button.search.detailed"      : "Rechercher le détaillé",

    "reports.validation.start_date.required.end_date" : "Une date de début doit avoir une date de fin.",
    "reports.validation.end_date.required.start_date" : "Une date de fin doit avoir une date de début.",

    "report.dashboard.custom.report.tooltip"            : "Rapport personnalisé",
    "report.dashboard.custom.report.deprecated.tooltip" : "Rapport obsolète",
    "report.dashboard.summary.detailed.report.tooltip"  : "Version sommaire/détaillée disponible",

    "report.just.export.excel" : "Exportation Excel ",

    "engine.report.not.found"             : "Rapport introuvable",
    "engine.report.not.found.description" : "Le rapport que vous recherchez n'a pas été trouvé, il a peut-être été déplacé ou supprimé.",
    "engine.report.not.found.return"      : "Retourner au tableau de bord",

    "engine.report.no.filters.or.headers" : "Ce rapport semble être mal configurer. Veuillez contacter le support.",
    "engine.report.no.filters"            : "Erreur: aucun filtres liés à ce rapport.",
    "engine.report.no.headers"            : "Erreur: aucunes colonnes liées à ce rapport.",
}

