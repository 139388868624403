import { Button, Input } from "reactstrap";
import PropTypes from 'prop-types';
import Cropper from "react-easy-crop";
import Dropzone from "react-dropzone";
import { useCallback, useEffect, useState } from "react";
import Translate from "@spordle/intl-elements";
import { FormattedNumber } from "react-intl";
import { bytesToMb } from '../uploadHelpers';

export const defaultDrop = {
    multiple: false,
    maxSize: 10485760, // 10 MB
    accept: "image/jpeg, image/png, image/jpg",
};

const defaultCrop = {
    restrictPosition: false,
    showGrid: false,
    cropSize: {
        width: 150,
        height: 150,
    },
    aspect: 1,
    cropShape: 'round',
}

/**
 * @callback OnDataChange
 * @param {FileDataType} fileData
 * @returns {void}
 */
/**
 * @typedef {object} FileDataType
 * @property {object} cropSize Width + height
 * @property {number} zoom
 * @property {object} crop x + y
 * @property {object} dimensions width + height of image
 */
/**
 * Image uploader in a modal
 * @param {Object} [props]
 * @param {import("react-easy-crop").CropperProps} [props.cropperProps] Refer to {@link https://github.com/ricardo-ch/react-easy-crop|Documentation}
 * @param {import("react-dropzone").DropzoneProps} [props.dropzoneProps] Refer to {@link https://react-dropzone.js.org/#src|Documentation}
 * @param {OnDataChange} [props.onChange] Returns current values for cropping
 * @returns {React.ReactNode}
 */
const ImgUpload = ({ dropzoneProps, cropperProps, onChange }) => {
    const [ selectedFile, setSelectedFile ] = useState({ crop: { x: 0, y: 0 }, cropSize: cropperProps?.cropSize || defaultCrop.cropSize, zoom: 1 });
    const clearFiles = () => setSelectedFile({ crop: { x: 0, y: 0 }, cropSize: cropperProps?.cropSize || defaultCrop.cropSize, zoom: 1 });

    const addFiles = useCallback((files) => {
        setSelectedFile((prev) => ({ ...prev, data: files[0] }));

        const reader = new FileReader();
        reader.onabort = () => console.error('file reading was aborted');
        reader.onerror = () => console.error('file reading has failed');
        reader.onload = (e) => setSelectedFile((prev) => ({ ...prev, src: reader.result }));
        reader.readAsDataURL(files[0]);
    }, []);

    useEffect(() => {
        onChange && onChange(selectedFile);
    }, [ selectedFile ]);

    return (
        selectedFile?.src ?
            <>
                <div className="position-relative rounded-lg overflow-hidden mb-2" style={{ height: selectedFile.cropSize.height + 50 }}>
                    <Button
                        size="sm"
                        color="light"
                        onClick={clearFiles}
                        className="position-absolute top-0 right-0 z-index-fixed mt-1 mr-1 mdi mdi-close"
                    />
                    <Cropper
                        {...{ ...defaultCrop, ...cropperProps }}
                        zoomSpeed={0.1}
                        image={selectedFile.src}
                        minZoom={0.1}
                        maxZoom={3}
                        crop={selectedFile.crop}
                        zoom={selectedFile.zoom}
                        onCropChange={(crop) => setSelectedFile((prev) => ({ ...prev, crop: crop }))}
                        onZoomChange={(zoom) => setSelectedFile((prev) => ({ ...prev, zoom: zoom }))}
                        onMediaLoaded={(mediaSize) => {
                            // fixed zoom, it was set super zoomed out initially
                            setSelectedFile((prev) => ({ ...prev, zoom: 1, dimensions: { width: mediaSize.width, height: mediaSize.height } }));
                        }}
                    />
                </div>
                <div className="d-flex">
                    <button className="reset-btn" type="button" onClick={() => (selectedFile.zoom - 0.1) >= 0.1 && setSelectedFile((prev) => ({ ...prev, zoom: prev.zoom - 0.1 }))}>
                        <i className="text-primary font-20 mdi mdi-magnify-minus-outline" />
                    </button>
                    <Input
                        value={selectedFile.zoom}
                        onChange={(e) => {
                            setSelectedFile((prev) => ({ ...prev, zoom: e.target.value }))
                        }}
                        type="range"
                        min={0.1}
                        max={3}
                        step={0.1}
                    />
                    <button className="reset-btn" type="button" onClick={() => (selectedFile.zoom + 0.1) <= 3 && setSelectedFile((prev) => ({ ...prev, zoom: prev.zoom + 0.1 }))}>
                        <i className="text-primary font-20 mdi mdi-magnify-plus-outline" />
                    </button>
                </div>
            </>
            :
            <Dropzone
                {...{ ...defaultDrop, ...dropzoneProps }}
                onDropAccepted={addFiles}
            >
                {(dropzone) => (
                    <div style={{ height: selectedFile.cropSize.height + 50 }} className={`text-center position-relative border-dashed rounded-lg overflow-hidden d-flex justify-content-center align-items-center p-3 clickable${dropzone.isDragActive ? ' border-primary' : ''}`} {...dropzone.getRootProps()}>
                        <div className={`${dropzone.isDragActive ? '' : 'd-none'}`} onClick={(e) => e.stopPropagation()}>
                            <div className='position-absolute left-0 top-0 bg-light h-100 w-100 d-flex align-items-center justify-content-center z-index-1'>
                                <div className='font-medium text-primary h3'>
                                    <Translate id='components.uploader.imgUpload.modal.drop' />
                                </div>
                            </div>
                        </div>
                        <div>
                            <input name="logo" {...dropzone.getInputProps()} />
                            <i style={{ fontSize: "80px", lineHeight: 1 }} className="text-link mdi mdi-file-image" />
                            <div className="d-none d-md-block">
                                <button type='button' className="ml-2 reset-btn text-link">
                                    <i className="mdi mdi-upload" /> <Translate id='components.uploader.imgUpload.modal.btn' />
                                </button> <Translate id='components.uploader.imgUpload.modal.or' /> <Translate id='components.uploader.imgUpload.modal.drag' />
                            </div>
                            <div className="small text-muted">
                                <Translate id='components.uploader.imgUpload.modal.maxSize' /> <FormattedNumber unitDisplay="narrow" style="unit" maximumFractionDigits={2} value={bytesToMb(dropzoneProps?.maxSize || defaultDrop.maxSize)} unit="megabyte" />
                            </div>
                        </div>
                    </div>
                )}
            </Dropzone>
    )
}

export default ImgUpload;

ImgUpload.propTypes = {
    onChange: PropTypes.func,
    cropperProps: PropTypes.object,
    dropzoneProps: PropTypes.object,
}

ImgUpload.defaultProps = {
    cropperProps: {
        restrictPosition: false,
        showGrid: false,
        cropSize: {
            width: 150,
            height: 150,
        },
        aspect: 1,
        cropShape: 'round',
    },
    dropzoneProps: {
        multiple: false,
        maxSize: 10485760,
        accept: "image/jpeg, image/png, image/jpg",
    },
}