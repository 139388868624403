import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';


////////////////////////////////////////////////////////////////////////////////////
// Sections (CTRL + F)
// - Positions
// - Position Groups
////////////////////////////////////////////////////////////////////////////////////

/** @type {React.Context<Omit<PositionsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const PositionsContext = createContext();
PositionsContext.displayName = 'PositionsContext';

class PositionsContextProvider extends React.Component{

    ////////////////////////////////////////////////////////////////////////////////////
    // Positions
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * GET[ALL] - Get position by organisation id
     * @param {string} organisation_id
     * @param {object} [queryParams] The query params for that call
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Positions/b9691fc1746092e051e92f1a91443f6c documentation}
     * @returns {Promise.<Array>} The `display_order` sorted array of positions
     */
    getPositions = (organisation_id, queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/positions`,
            query: Object.assign({
                organisation_id: organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.positions.sort((positionA, positionB) => parseInt(positionA.display_order) - parseInt(positionB.display_order));
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get a specific position
     * @param {string} position_id
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Positions/138cd077dd0868d276ff7942493de9bd documentation}
     * @returns {Promise.<Array>}
     */
    getPosition = (position_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/positions/${position_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.positions[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a Position
     * @param {array} values
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Positions/010b4017e24fc51fd1aaa3e1fa4a2214 documentation}
     * @returns {Promise}
     */
    createPosition = (values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'organisation_id':
                    params.append('organisation_id', values.organisation_id);
                    break;
                case 'position_group_id':
                    params.append('position_group_id', values.position_group_id);
                    break;
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'abbreviation':
                    params.append('abbreviation', values.abbreviation);
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/positions` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.position_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Updates a Position
    * @param {string} position_id
    * @param {object} values
    * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Positions/59686fbedceecb4b37bc67afa9446c77 documentation}
    * @returns {Promise}
    */
    updatePosition = (position_id, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'position_group_id':
                    params.append('position_group_id', values.position_group_id);
                    break;
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'abbreviation':
                    params.append('abbreviation', values.abbreviation);
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `positions/${position_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Updates a specific position
    * @param {string} position_id
    * @param {object} values
    * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Positions/bf7e7867e79c07c5a2b0d89b5aef86c3 documentation}
    * @returns {Promise}
    */
    updatePositionPartial = (position_id, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'position_group_id':
                    params.append('position_group_id', values.position_group_id);
                    break;
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'abbreviation':
                    params.append('abbreviation', values.abbreviation);
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `positions/${position_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Deletes a specific position
    * @param {string} position_id
    * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Positions/1ead6cb8fdf000a639b203173e0951c0 documentation}
    * @returns {Promise}
    */
    deletePosition = (position_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `positions/${position_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    ////////////////////////////////////////////////////////////////////////////////////
    // Position Groups
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * GET[ALL] - Get position groups by organisation id
     * @param {string} organisation_id
     * @param {object} [queryParams] The query params for that call
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Position%20Groups/Apicontroller%5CTeams%5CPositiongroups%3A%3Aindex|documentation}
     * @returns {Promise.<Array>}
     */
    getPositionGroups = (organisation_id, queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/position-groups`,
            query: Object.assign({
                organisation_id: organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.position_groups;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get a specific position group
     * @param {string} position_group_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Position%20Groups/Apicontroller%5CTeams%5CPositiongroups%3A%3AgetPositiongroupDetail|documentation}
     * @returns {Promise.<Array>}
     */
    getPositionGroup = (position_group_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/position-groups/${position_group_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.position_groups[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a Position Group
     * @param {array} values
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Position%20Groups/Apicontroller%5CTeams%5CPositiongroups%3A%3AcreatePositiongroup|documentation}
     * @returns {Promise}
     */
    createPositionGroup = (values) => {
        const params = new URLSearchParams();

        for(const key in values){
            switch (key){
                case 'organisation_id':
                    params.append('organisation_id', values.organisation_id);
                    break;
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'abbreviation':
                    params.append('abbreviation', values.abbreviation);
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/position-groups` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.position_group_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Updates a Position Group
    * @param {string} position_group_id
    * @param {object} values
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Position%20Groups/Apicontroller%5CTeams%5CPositiongroups%3A%3AupdatePositiongroup|documentation}
    * @returns {Promise}
    */
    updatePositionGroup = (position_group_id, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'abbreviation':
                    params.append('abbreviation', values.abbreviation);
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `position-groups/${position_group_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Updates a specific position group
    * @param {string} position_group_id
    * @param {object} values
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Position%20Groups/Apicontroller%5CTeams%5CPositiongroups%3A%3ApatchPositiongroup|documentation}
    * @returns {Promise}
    */
    updatePositionGroupPartial = (position_group_id, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'abbreviation':
                    params.append('abbreviation', values.abbreviation);
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `position-groups/${position_group_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Deletes a specific position group
    * @param {string} position_group_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Position%20Groups/Apicontroller%5CTeams%5CPositiongroups%3A%3AdeletePositiongroup|documentation}
    * @returns {Promise}
    */
    deletePositionGroup = (position_group_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `position-groups/${position_group_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //////////////////////////////////////////////////////////////////////

    /**
     * Update an invoice item position
     * @param {string} invoiceItemPositionId Invoice item position ID to update
     * @param {object} values Values to update with
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Invoice%20Item%20Positions/41caf63b9e05ffe39d35784b78fa7318|documentation}
     * @returns {Promise}
     */
    updateInvoicePosition = (invoiceItemPositionId, values) => {
        const params = new URLSearchParams();
        params.append('position_id', values.positionId);
        params.append('position_group_id', values.positionGroupId);

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/invoice-item-positions/${invoiceItemPositionId}`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0]
            }, serverError)
    }

    render(){
        return (
            <PositionsContext.Provider value={{ ...this }}>
                {this.props.children}
            </PositionsContext.Provider>
        )
    }
}

export default PositionsContextProvider