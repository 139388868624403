import { useContext, useRef } from 'react';
import { RolesContext } from '../../../../contexts/RolesContext';
import TeamSettingsQualifications from './TeamSettingsQualifications';
import TeamSettingsRestrictions from './TeamSettingsRestrictions';
import TeamSettingsCategoryQualifications from './categoryQualificationRule/TeamSettingsCategoryQualifications';

const TeamSettings = ({ error, isValidating, teamsSettings, teamId }) => {
    const canSeeMember = useRef(useContext(RolesContext).hasAccessTo('members', 'members_profile'));

    return (
        <>
            <TeamSettingsRestrictions error={error} isValidating={isValidating} teamsSettings={teamsSettings} canSeeMember={canSeeMember.current} />
            <TeamSettingsQualifications error={error} isValidating={isValidating} teamsSettings={teamsSettings} canSeeMember={canSeeMember.current} />
            <TeamSettingsCategoryQualifications teamId={teamId} canSeeMember={canSeeMember.current} />
        </>
    )
}

export default TeamSettings
