import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import {
    Button,
    CustomInput,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import { JiraContext } from '../../../contexts/JiraContext';
import OverlayLoader from '../../loading/OverlayLoader';
import UserDisplay from '../../userDisplay/UserDisplay';
import SubmitTicket2 from './components/SubmitTicket2';
import SubmitTicketSuccess from './components/SubmitTicketSuccess';
import SubmitTicketTypeData from './components/SubmitTicketTypeData';
import AnalyticsModal from "../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import { fail } from "@spordle/toasts";
import { DisplayI18n } from '../../../helpers/i18nHelper';
import { Link } from 'react-router-dom';

/**
* This is only the step1 / step2 toggler
* step1 manages most of the logic based on the selected type
*/
const SubmitTicket = ({ isOpen, toggle, onSuccess, defaultType = null }) => {
    const jiraContext = useContext(JiraContext);
    const [ view, setView ] = useState(1) // 1 || 2 || 3
    const [ newIssueId, setNewIssueId ] = useState(false);
    const [ types, setTypes ] = useState(false);
    const [ type, setType ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    const toggleView = (view) => {
        switch (view){
            case 1:
            case 2:
            case 3:
                setView(view)
                break;

            default:
                console.error(view, 'View not found')
                break;
        }
    }

    const success = (issueId) => {
        onSuccess?.();
        toggleView(3);
        setNewIssueId(issueId);
    }

    useEffect(() => {
        if(view <= 1){
            jiraContext.getRequestTypes()
                .then((types) => {
                    setTypes(types)
                    if(types.length == 1){
                        setType(types[0])
                        toggleView(2)
                    }
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }
    }, [ view ]);

    return (
        <AnalyticsModal analyticsName='SubmitTicket' isOpen={isOpen} onClosed={() => toggleView(1)}>
            <OverlayLoader isLoading={isLoading}>
                <ModalHeader tag="div" toggle={toggle}>
                    <Translate id='helpCenter.submitTicket.title' />
                </ModalHeader>
                {view === 1 &&
                    <>
                        <ModalBody>
                            {types && types.sort((_t) => {
                                if(_t.id == SubmitTicketTypeData.bug)
                                    return -1
                                return 0
                            }).map((_t, i) => {
                                if((_t.id == SubmitTicketTypeData.bug) && !type) setType(_t);

                                return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <UserDisplay tag="label" htmlFor={_t.id} key={_t.id + i} card className={`mb-2 w-100 select-request${type?.id === _t?.id ? ' border-primary' : ''}`}>
                                        <UserDisplay.Container>
                                            <CustomInput id={_t.id + i} defaultChecked={!type ? _t.id === SubmitTicketTypeData.bug : type.id == _t?.id} type="radio" name="type" onChange={(e) => setType(_t)} />
                                        </UserDisplay.Container>
                                        <UserDisplay.Container>
                                            <UserDisplay.Title>
                                                {_t.name}
                                            </UserDisplay.Title>
                                            { _t.description &&
                                                <UserDisplay.Subtitle>
                                                    {_t.description}
                                                </UserDisplay.Subtitle>
                                            }
                                        </UserDisplay.Container>
                                    </UserDisplay>
                                )
                            })}
                        </ModalBody>
                        <ModalFooter>
                            <Link to={"/supportRequest"} className="btn btn-link mr-auto" onClick={toggle}><i className="mr-1 mdi mdi-chevron-left" /><Translate id='helpCenter.submitTicket.search.gotoSupportRequests' /></Link>
                            <Button color="primary" type="button" onClick={() => toggleView(2)} disabled={!type || !isOpen}><Translate id="misc.next" /></Button>
                            <Button color="primary" type="button" outline onClick={() => toggle()}><Translate id="misc.cancel" /></Button>
                        </ModalFooter>
                    </>
                }
                {view === 2 &&
                    <SubmitTicket2 toggle={toggle} toggleLoading={(bool) => setIsLoading(bool)} type={type} types={types} back={() => toggleView(1)} success={success} />
                }
                {view === 3 &&
                    <SubmitTicketSuccess toggle={toggle} back={() => toggleView(1)} issueId={newIssueId} />
                }
            </OverlayLoader>
        </AnalyticsModal>
    );
}

export default SubmitTicket;