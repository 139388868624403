import API_SPORDLE from "../API-Spordle";
import queryString from "query-string";
// import { jsObjectToApi } from "@spordle/helpers";
import { serverError } from "../CancellableAPI";
import { jsObjectToApi } from "@spordle/helpers";

/**
 * Creates a meta member for the given identity
 * @param {string} memberId Member ID to create a meta member for
 * @param {string} identityId Identity ID to create a meta member for
 * @returns {Promise<string>}
 */
export function getPotentialIneligibleMembers(queryParams) {
    return API_SPORDLE.get(queryString.stringifyUrl({
        url: `/member-potential-ineligibles`,
        query: Object.assign({
            organisation_id: queryParams.organisation_id,
            status: queryParams.status,
        }, queryParams)
    }))
        .then((response) => {
            if (response.data.status) {
                return {
                    data: response.data.member_potential_ineligibles.map((potentialIneligible) => ({
                        ...potentialIneligible,
                        member: {
                            ...response.data.members[potentialIneligible.member_id],
                            member_id: potentialIneligible.member_id,
                            member_status: {
                                ...response.data.member_status[potentialIneligible.member_status_id],
                                member_status_id: potentialIneligible.member_status_id,
                            }
                        },
                        organisation: response.data.organisations[potentialIneligible.organisation_id],
                    })),
                    member_status: response.data.member_status,
                }
            }
            throw response.data.errors[0];
        }, serverError)
}

export function getMemberStatusChangeReason(query) {
    return API_SPORDLE.get(queryString.stringifyUrl({
        url: `/member-status-reasons`,
        query: query,
    }))
        .then((response) => {
            if (response.data.status) {
                return response.data.member_status_reasons;
            }
            throw response.data.errors[0];
        }, serverError)
}

export function releasePotentialIneligibleMember(member_potential_ineligible_id, values) {
    const params = new URLSearchParams()
    jsObjectToApi(values, params)

    return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/member-potential-ineligibles/${member_potential_ineligible_id}` }), params)
        .then((response) => {
            if (response.data.status) {
                return true
            }
            throw response.data.errors[0];
        }, serverError)
}
// /**
//  * gets statuses for mandatory field change requests
//  * @returns {Promise<string>}
//  */
// export function getMemberRequestChangesStatus() {
//     return API_SPORDLE.get(queryString.stringifyUrl({
//         url: `/member-request-changes-status`,
//     }))
//         .then((response) => {
//             if (response.data.status) {
//                 return response.data.member_request_change_status;
//             }
//             throw response.data.errors[0];
//         }, serverError)
// }


// /**
//  * Create a member suspension attachment
//  * @param {string} memberId
//  * @param {Array} data_to_change
//  * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Suspension%20Attachments/Apicontroller%5CMembers%5CMembersuspensionattachments%3A%3AcreateMemberSuspensionAttachments|documentation}
//  * @returns {Promise.<Array>}
//  */
// export function createMemberRequestChange(memberId, data_to_change) {
//     const params = new URLSearchParams();
//     jsObjectToApi(data_to_change, params);
//     return API_SPORDLE.post(queryString.stringifyUrl({ url: `/members/${memberId}/member-request-changes` }), params)
//         .then((response) => {
//             if (response.data.status) {
//                 return response.data.member_request_id;
//             }
//             throw response.data.errors[0];
//         }, serverError)
// }