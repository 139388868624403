import { Button, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import AnalyticsModal from '../../analytics/AnalyticsModal';
import { FieldArray, Form, Formik } from 'formik';
import { array, object, string } from 'yup';
import Translate from '@spordle/intl-elements';
import { FormikCheckedButton, FormikError, FormikInputText, FormikTextArea } from '@spordle/formik-elements';
import UserDisplay from '../userDisplay/UserDisplay';
import CustomAnimatedIcon from '../customAnimatedIcon/CustomAnimatedIcon';
import OverlayLoader from '../loading/OverlayLoader';
import useSWR, { useSWRConfig } from 'swr';
import { useContext, useState } from 'react';
import { ReportsContext } from '../../contexts/ReportsContext';
import { DisplayI18n } from '../../helpers/i18nHelper';
import Required from '../formik/Required';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Skeleton from 'react-loading-skeleton';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import CustomAlert from '../CustomAlert';
import { IdentityRolesContext } from '../../contexts/IdentityRolesContext';
import CrossFade from '../crossFade/CrossFade';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../../contexts/contexts';
import { useSpordleTable } from '@spordle/datatables';
import { parse } from 'query-string';
import { Tooltip } from '@mantine/core';
import { getReportQueryParamsFromFilters } from '../../views/reports/reportEngine/reportsEngineHelpers';

const SaveReports = ({ onEdit, isOpen, isOpenHandlers, formikRef }) => {
    const location = useLocation();
    const { currentReport } = useContext(ReportsContext);
    const isCustomReport = currentReport.report_id ? !!currentReport.custom_report_id : !!parse(location.search).custom_report_id

    return (
        <>
            <Button id="reports-save-custom-btn" color='primary' outline className='mr-2' onClick={isOpenHandlers.open} disabled={!currentReport.report_id}>
                {isCustomReport ?
                    <Translate id='reports.customize.modal.triggerModal.edit' />
                    :
                    <Translate id='reports.customize.modal.triggerModal' />
                }
            </Button>
            <AnalyticsModal id="reports-save-custom-modal" analyticsName='Save-Reports-Modal' isOpen={isOpen}>
                <InnerModal
                    toggle={isOpenHandlers.close}
                    isEdit={!!currentReport.custom_report_id}
                    onEdit={onEdit}
                    formikRef={formikRef}
                />
            </AnalyticsModal>
        </>
    )
}

const InnerModal = ({ toggle, isEdit, onEdit, formikRef }) => {
    const { currentReport, getSpecificReport, createCustomReport, updateCustomReport } = useContext(ReportsContext);
    const { identity_role_id } = useContext(IdentityRolesContext);
    const [ newCustomReportId, setNewCustomReportId ] = useState(null);
    const { updateRouteKey } = useContext(AppContext);
    const spordleTable = useSpordleTable();
    const { mutate: globalMutate } = useSWRConfig();

    const { data: headers, isValidating, mutate } = useSWR(
        [ 'saveCustomReport', currentReport.report_id, isEdit ],
        () => getSpecificReport({ report_id: currentReport.report_id })
            .then((report) => {
                if(isEdit){
                    const filteredHeaders = report.headers
                        .filter((header) => header.display_on_screen == '1' && header.is_additional_header != '1' && !currentReport.headers.find((h) => h.header_id === header.header_id))
                        .sort((h1, h2) => parseInt(h1.display_order) - parseInt(h2.display_order));
                    return [ ...currentReport.headers, ...filteredHeaders ]
                }
                return report.headers
                    .filter((header) => header.display_on_screen == '1' && header.is_additional_header != '1')
                    .sort((h1, h2) => parseInt(h1.display_order) - parseInt(h2.display_order));
            })
        ,
        {
            fallbackData: [],
        },
    );

    const moveItem = (list, from, to) => {
        const element = list[from];
        list.splice(from, 1);
        list.splice(to, 0, element);
        return list;
    }

    return (
        <Formik
            initialValues={{
                name: isEdit ? currentReport.name || '' : '',
                description: isEdit ? currentReport.description || '' : '',
                presetFilter: !!currentReport.preset_filter,
                columns: isEdit ? currentReport.headers.map((h) => h.header_id) : [],
            }}
            validationSchema={object().shape({
                name: string().required(<Translate id='form.validation.name.required' />),
                description: string(),
                columns: array().of(string()).min(1, <Translate id='reports.customize.modal.label.columns.required' />),
            })}
            onSubmit={async({ columns, ...values }, { setStatus }) => {
                setStatus();
                const finalColumns = headers.filter((h) => columns.includes(h.header_id) && h.is_additional_header != '1');

                let presetFilter = null;
                if(values.presetFilter){
                    presetFilter = JSON.stringify(getReportQueryParamsFromFilters(formikRef.current?.values, currentReport.filters))
                }

                if(isEdit){
                    return updateCustomReport(currentReport.custom_report_id, {
                        name: values.name,
                        description: values.description,
                        active: 1,
                        headers: finalColumns.map((h, index) => {
                            return {
                                header_id: h.header_id,
                                active: h.active,
                                display_order: index,
                                name: h.name,
                                description: h.description,
                                i18n: { ...h.i18n },
                            }
                        }),
                        preset_filter: presetFilter,
                    })
                        .then(() => {
                            setNewCustomReportId(currentReport.custom_report_id);
                            return onEdit().then(() => {
                                spordleTable.setSuccess();
                            });
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            }
                        })
                }

                const initialValues = {
                    identity_role_id: identity_role_id,
                    report_id: currentReport.report_id,
                    name: values.name,
                    description: values.description,
                    active: 1,
                    preset_filter: presetFilter,
                }
                return createCustomReport({
                    ...initialValues,
                    headers: finalColumns.map((h, index) => {
                        return {
                            header_id: h.header_id,
                            active: h.active,
                            display_order: index,
                            name: h.name,
                            description: h.description,
                            i18n: { ...h.i18n },
                        }
                    }),
                })
                    .then(setNewCustomReportId)
                    .then(() => {
                        globalMutate([ 'getAllReportsList', identity_role_id ])// updates the sidebar
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                        }
                    })
            }}
        >
            {(formik) => (
                <OverlayLoader isLoading={isValidating || formik.isSubmitting}>
                    <ModalHeader toggle={toggle}>
                        <Translate id={isEdit ? 'reports.customize.modal.title.edit' : 'reports.customize.modal.title'} />
                    </ModalHeader>
                    <CrossFade mountOnEnter unmountOnExit isVisible={!newCustomReportId}>
                        <Form>
                            <ModalBody>
                                <FormGroup>
                                    <Label for='new-report-name'><Translate id='form.fields.name' /> <Required /></Label>
                                    <FormikInputText name='name' id='new-report-name' trim />
                                </FormGroup>
                                <FormGroup>
                                    <Label for='new-report-description'><Translate id='form.fields.description' /></Label>
                                    <FormikTextArea name='description' id='new-report-description' trim rows='2' />
                                </FormGroup>
                                <FormGroup className='d-flex'>
                                    <FormikCheckedButton
                                        id={'presetFilter'}
                                        name={'presetFilter'}
                                        label={'reports.customize.modal.save.filter.values'}
                                        translateLabel
                                        className={`w-100`}
                                        checked={formik.values.presetFilter}
                                    />
                                    <Tooltip wrapLines withArrow width={200} position="top" zIndex={2000} label={<Translate id='reports.customize.modal.save.filter.tooltip' />}>
                                        <i className="ml-2 mdi mdi-information-outline text-primary" />
                                    </Tooltip>
                                </FormGroup>
                                <FormGroup className='mb-0'>
                                    <div className='d-flex align-items-center justify-content-between mb-2'>
                                        <Label className='mb-0'><Translate id='reports.customize.modal.label.columns' /> <Required /></Label>
                                        <button
                                            className='reset-btn text-link small'
                                            type='button'
                                            onClick={() => {
                                                if(headers.length === formik.values.columns.length){
                                                    formik.setFieldValue('columns', []);
                                                }else{
                                                    formik.setFieldValue('columns', headers.map((h) => h.header_id));
                                                }
                                            }}
                                        >
                                            {headers.length === formik.values.columns.length ?
                                                <Translate id='misc.unselect.all' />
                                                :
                                                <Translate id='misc.select.all' />
                                            }
                                        </button>
                                    </div>
                                    {isValidating ?
                                        <Skeleton count={7} height={56} className='mb-2' />
                                        :
                                        <FieldArray name='columns'>
                                            {(arrayFieldHelpers) => (
                                                <DragDropContext
                                                    onDragEnd={(result) => {
                                                        if(result.destination){
                                                            mutate(moveItem([ ...headers ], result.source.index, result.destination.index), false);
                                                        }
                                                    }}
                                                >
                                                    <Droppable droppableId='columnHeaders' direction='vertical'>
                                                        {(dropProvided) => (
                                                            <div
                                                                ref={dropProvided.innerRef}
                                                                {...dropProvided.droppableProps}
                                                            >
                                                                {headers.filter((h) => h.is_additional_header != '1').map((header, headerIndex) => (
                                                                    <Draggable key={header.header_id} draggableId={`draggable-column-${header.header_id}`} index={headerIndex}>
                                                                        {(dragProvided) => (
                                                                            <div
                                                                                ref={dragProvided.innerRef}
                                                                                {...dragProvided.draggableProps}
                                                                                {...dragProvided.dragHandleProps}
                                                                                className='mb-2'
                                                                            >
                                                                                <UserDisplay
                                                                                    id={`customReport-column-${header.header_id}`}
                                                                                    card hover block
                                                                                    className='min-w-0 mb-0 py-2'
                                                                                    onClick={() => {
                                                                                        const formikValueIndex = formik.values.columns.indexOf(header.header_id);
                                                                                        if(formikValueIndex !== -1){ // Is already selected
                                                                                            arrayFieldHelpers.remove(formikValueIndex);
                                                                                        }else{
                                                                                            arrayFieldHelpers.push(header.header_id);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <UserDisplay.Container>
                                                                                        <i className='mdi mdi-drag' />
                                                                                    </UserDisplay.Container>
                                                                                    <UserDisplay.Container>
                                                                                        <UserDisplay.Title>
                                                                                            <DisplayI18n field='name' i18n={header.i18n} defaultValue={header.name} />
                                                                                        </UserDisplay.Title>
                                                                                        <UserDisplay.Subtitle>
                                                                                            <DisplayI18n field='description' i18n={header.i18n} defaultValue={header.description} />
                                                                                        </UserDisplay.Subtitle>
                                                                                    </UserDisplay.Container>
                                                                                    <UserDisplay.Container className='ml-auto'>
                                                                                        {formik.values.columns.includes(header.header_id) ?
                                                                                            <CustomAnimatedIcon icon='checkmark' className='text-primary' withCircle size={30} strokeWidth='6px' />
                                                                                            :
                                                                                            <div className='border rounded-circle' style={{ width: 30, height: 30 }} />
                                                                                        }
                                                                                    </UserDisplay.Container>
                                                                                </UserDisplay>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {dropProvided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            )}
                                        </FieldArray>
                                    }
                                </FormGroup>
                                <FormikError name='columns' />

                                <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                                    {formik.status &&
                                        <CustomAlert className='mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                    }
                                </Collapse>

                                {currentReport.code === 'custom_form' &&
                                    <div className='d-flex align-items-center bg-light-inverse p-1 mt-3 rounded'>
                                        <i className='text-primary mdi mdi-information-outline mx-2' />
                                        <div className='small'>
                                            <Translate id='reports.customize.modal.note' />
                                        </div>
                                    </div>
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type='submit' disabled={formik.isSubmitting}>
                                    <Translate id='misc.save' />
                                </Button>
                                <Button color='primary' outline onClick={toggle} type='button' disabled={formik.isSubmitting}>
                                    <Translate id='misc.cancel' />
                                </Button>
                            </ModalFooter>
                        </Form>
                    </CrossFade>
                    <CrossFade mountOnEnter unmountOnExit isVisible={!!newCustomReportId}>
                        <ModalBody className='text-center'>
                            <CustomAnimatedIcon className='text-success mb-3' icon='checkmark' withCircle size={50} />
                            <p className='h5'>
                                <Translate id={isEdit ? 'reports.customize.modal.edit.label' : 'reports.customize.modal.confirm.label'} />
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            {isEdit ?
                                <Button color='primary' outline onClick={toggle} type='button'>
                                    <Translate id='misc.close' />
                                </Button>
                                :
                                <>
                                    <Button color='primary' tag={Link} to={`?custom_report_id=${newCustomReportId}`} onClick={updateRouteKey}>
                                        <Translate id='reports.customize.modal.confirm.action.view' />
                                    </Button>
                                    <Button color='primary' outline onClick={toggle} type='button'>
                                        <Translate id='reports.customize.modal.confirm.action.stay' />
                                    </Button>
                                </>
                            }
                        </ModalFooter>
                    </CrossFade>
                </OverlayLoader>
            )}
        </Formik>
    )
}

export default SaveReports