import { useEffect, useRef } from "react"

/**
 * @param {function} func
 * @param {array} deps
 */
export default function useDidUpdate(func, deps){
    const didMount = useRef(false);

    useEffect(() => {
        if(!didMount.current){
            didMount.current = true;
        }else{
            func();
        }
    }, deps)
}