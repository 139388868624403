import { useContext, useEffect, useState } from "react";
import {
    Button,
    Card
} from "reactstrap";
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';

import Translate from "@spordle/intl-elements";

// Contexts
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { PositionsContext } from '../../../../../contexts/PositionsContext';
import I18nHelperContextProvider, { DisplayI18n, I18nHelperContext } from "../../../../../helpers/i18nHelper";
import SidePanelOrganizationPositions from "./SidePanelOrganizationPositions";
import OrganizationPositionsAdd from "./OrganizationPositionsAdd";
import EmptyLayout from "../../../../../components/table/EmptyLayout";
import CardSectionTitle from "../../../../../components/CardSectionTitle";
import CanDoAction from "../../../../../components/permissions/CanDoAction";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";
import SpordlePanelTable from "../../../../../components/sidePanel/SpordlePanel";

const OrganizationPositions = () => {

    const positionsContext = useContext(PositionsContext);
    const organizationContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext);
    const [ apiData, setApiData ] = useState({});

    const isBranch = organizationContext.organisation_category.name === 'Member branches' // TODO: HARDCODED

    useEffect(() => {
        positionsContext.getPositionGroups(organizationContext.organisation_id, { active: '1' })
            .then((response) => {
                setApiData({
                    positionGroups: response,
                })
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, []);

    const [ modalAdd, setModalAdd ] = useState(false);
    const toggleModalAdd = () => setModalAdd(!modalAdd);

    const getColumns = (i18nHelper) => {
        const defaultColumns = [
            ...i18nHelper.getTableColumns('name'),
            {
                label: <Translate id='organization.settings.organizationPositions.table.column.abbreviation' />,
                key: "abbreviation",
                mobile: true,
                sortable: true,
            },
            {
                label: <Translate id='organization.settings.organizationPositions.table.column.positionGroup' />,
                key: "position_group.name",
                mobile: true,
                sortable: true,
            },
            {
                label: <Translate id='organization.settings.organizationPositions.table.column.status' />,
                key: "active",
                mobile: true,
                sortable: true,
            },
        ]

        if(isBranch){
            defaultColumns.push({
                label: <Translate id='organization.settings.organizationPositions.table.column.managed' />,
                key: "managed",
                mobile: true,
                sortable: true,
            })
        }

        return defaultColumns
    }

    return (
        <I18nHelperContextProvider fields={[ 'name' ]}>
            <I18nHelperContext.Consumer>
                {(i18nHelper) => (
                    <div>
                        <SpordlePanelTable
                            allowOutsideClick
                            sidePanel={(props) => <SidePanelOrganizationPositions {...props} />}
                            dataIndex='position_id'
                            table={(panelProps) => {
                                return (
                                    <Card body className="card-shadow">
                                        <CardSectionTitle title='settings.tabs.positions' />
                                        <SpordleTableProvider
                                            id='OrganizationPositionsTable'
                                            tableHover clickable striped
                                            bordered
                                            ref={panelProps.spordleTableRef}
                                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                            dataIndex='position_id'
                                            searchKeys={[
                                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                                'name',
                                                'abbreviation',
                                                'position_group.name',
                                            ]}
                                            columns={getColumns(i18nHelper)}
                                            onColumnClick={(e, positions) => {
                                                switch (e.button){
                                                    case 0: // Left mouse button
                                                        panelProps.onSingleSelectChange(positions);
                                                        break;
                                                }
                                            }}
                                            defaultSorting={isBranch ? '-managed' : undefined}
                                            desktopWhen='md'
                                            emptyLayout={<EmptyLayout translateTitle title='organization.settings.organizationPositions.table.empty' />}
                                            renderRow={(columnKey, position, spordleTable) => {
                                                switch (columnKey){
                                                    case 'active':
                                                        return (
                                                            <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                                {position.active == '1' ?
                                                                    <i className="text-primary mdi mdi-check" />
                                                                    :
                                                                    <i className="text-danger mdi mdi-close" />
                                                                }
                                                            </div>
                                                        )
                                                    case 'managed':
                                                        return (
                                                            <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                                {position.managed ?
                                                                    <i className="text-primary mdi mdi-check" />
                                                                    :
                                                                    <i className="text-danger mdi mdi-close" />
                                                                }
                                                            </div>
                                                        )
                                                    default:
                                                        break;
                                                }
                                            }}
                                            rowIsHighlighted={(positions) => !!positions.checked}
                                            loadData={(from, filterData, spordleTable) => {
                                                switch (from){
                                                    case 'REFRESH':
                                                        spordleTable.setLoading();
                                                    case 'CDM':
                                                        return positionsContext.getPositions(organizationContext.organisation_id, {
                                                            ignore_allowed: organizationContext.organisation_category.name === 'Member branches' ? 1 : 0, // TODO: HARDCODED
                                                        })
                                                            .then((positions) => {
                                                                return positions.map((position) => ({
                                                                    ...position,
                                                                    managed: !!organizationContext.settings.allowed_positions?.value?.find((pId) => pId === position.position_id) || false,
                                                                }))
                                                            })
                                                    default:
                                                        break;
                                                }
                                            }}
                                        >
                                            <div className="mb-2">
                                                <div className='d-flex justify-content-between'>
                                                    <SpordleTableProvider.SearchInput />
                                                    <div className='d-flex ml-auto text-right'>
                                                        <SpordleTableProvider.Refresh />
                                                        <CanDoAction action='ADD' componentCode='teams' componentPermissionCode='team_positions'>
                                                            <OrganizationPositionsAdd isOpen={modalAdd} toggle={toggleModalAdd} positionGroups={apiData.positionGroups} />
                                                            <Button className='ml-2' color='primary' onClick={toggleModalAdd}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                        </CanDoAction>
                                                    </div>
                                                </div>
                                            </div>
                                            <SpordleTableView />
                                        </SpordleTableProvider>
                                    </Card>
                                )
                            }}
                        />
                    </div>
                )}
            </I18nHelperContext.Consumer>
        </I18nHelperContextProvider>

    )

}

export default OrganizationPositions;
