import { DisplayI18n } from "../helpers/i18nHelper";

const DisplayOrg = ({ org }) => {
    if(!org){
        return null;
    }

    return <DisplayI18n field="name" i18n={org.i18n} defaultValue={org.organisation_name} />;
}

export default DisplayOrg;