import { Button, ModalBody, ModalFooter } from "reactstrap";
import Translate, { getTranslation } from "@spordle/intl-elements";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import * as Yup from 'yup';
import CreateVenueStep2Group from './CreateVenueStep2Group';
import PerfectScrollbar from 'react-perfect-scrollbar';

const CreateVenueStep2 = ({ venueData, dataStructure, onSubmit, changeStep }) => {
    return (
        <Formik
            initialValues={{ venues: venueData.venues }}
            validationSchema={Yup.object().shape({
                venues: Yup.array().of(Yup.object().shape({
                    venueAbbr: Yup.string().required(getTranslation('organization.profile.venues.modals.components.createVenueStep2.venue.error.abbr')),
                    venueName: Yup.string().required(getTranslation('organization.profile.venues.modals.components.createVenueStep2.venue.error.name')),
                    venueType: Yup.string().required(getTranslation('organization.profile.venues.modals.components.createVenueStep2.venue.error.size')),
                    venueSize: Yup.string().required(getTranslation('organization.profile.venues.modals.components.createVenueStep2.venue.error.size')),
                })),
            })}
            onSubmit={(values) => onSubmit(values)}
        >
            { (formik) => (
                <FormikForm id="venues">
                    <ModalBody>
                        <FieldArray name="venues">
                            { (arrayHelper) => (
                                <>
                                    <PerfectScrollbar>
                                        <div className="max-vh-50">
                                            { formik.values.venues.map((venue, index) => (
                                                <CreateVenueStep2Group
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key={`venues.${index}`}
                                                    id={`venues.${index}`}
                                                    venueIndex={index}
                                                    cloneVenue={arrayHelper.handlePush(venue)}
                                                    deleteVenue={arrayHelper.handleRemove(index)}
                                                />
                                            ))}
                                        </div>
                                    </PerfectScrollbar>
                                    <button
                                        type="button"
                                        onClick={() => arrayHelper.push(dataStructure)}
                                        className="btn btn-link"
                                    >
                                    + <Translate id='organization.profile.venues.modals.components.createVenueStep2.venue.add' />
                                    </button>
                                </>
                            )}
                        </FieldArray>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='primary' outline onClick={() => {
                                formik.values && onSubmit(formik.values);
                                changeStep(1);
                            }}
                        ><Translate id='misc.previous' />
                        </Button>
                        <Button form="venues" type="submit" color='primary'><Translate id='misc.add' /></Button>
                    </ModalFooter>
                </FormikForm>
            )}
        </Formik>
    )
}

export default CreateVenueStep2;