import Translate from '@spordle/intl-elements'
import { Badge } from 'reactstrap'
import { DisplayI18n } from '../../../../../helpers/i18nHelper'
import { getStatusTheme } from './CRCHelper'

const CRCStatus = ({ crc, ...props }) => {
    return (
        <Badge color={getStatusTheme((crc.crc_is_expired == '1' || crc.missing_background_check == '1') ? 'EXPIRED' : crc.criminal_record_check_status?.name)} {...props}>
            {(crc.crc_is_expired == '1' || crc.missing_background_check == '1') ?
                <Translate id='members.profile.crc.status.expired' />
                :
                <DisplayI18n
                    field="name"
                    defaultValue={crc.criminal_record_check_status?.name}
                    i18n={crc.criminal_record_check_status?.i18n}
                />
            }
        </Badge>
    )
}

export default CRCStatus
