import CanDoAction from '../../../../components/permissions/CanDoAction';
import TeamContact from '../TeamInfo/components/TeamContact/TeamContact';
import TeamLog from '../TeamInfo/components/TeamLog';
import TeamComments from './components/teamComments/TeamComments';
import TeamGeneralInfo from './components/TeamGeneralInfo/TeamGeneralInfo';

const TeamInfo = ({ teamId }) => {
    return (
        <>
            <TeamGeneralInfo teamId={teamId} />
            {/* <TeamAddress teamId={teamId} /> */}
            <CanDoAction action='READ' componentCode='teams' componentPermissionCode='team_contacts'>
                <TeamContact teamId={teamId} />
            </CanDoAction>
            <CanDoAction action='READ' componentCode='teams' componentPermissionCode='team_comments_manage'>
                <TeamComments teamId={teamId} />
            </CanDoAction>
            <TeamLog teamId={teamId} />
        </>
    );
}

export default TeamInfo;