import { useDisclosure } from "@mantine/hooks"
import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from "@spordle/datatables"
import { stringBuilder } from "@spordle/helpers"
import Translate from "@spordle/intl-elements"
import { useContext } from "react"
import { Button, Card } from "reactstrap"
import CardSectionTitle from "../../../../../components/CardSectionTitle"
import useSavedSearch from "../../../../../components/customHooks/useSavedSearch"
import SpordlePanelTable from "../../../../../components/sidePanel/SpordlePanel"
import EmptyLayout from "../../../../../components/table/EmptyLayout"
import TablePagination from "../../../../../components/table/TablePagination"
import { PeriodsContext } from "../../../../../contexts/contexts"
import { I18nContext } from "../../../../../contexts/I18nContext"
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext"
import { OrganizationContext } from "../../../../../contexts/OrganizationContext"
import { RolesContext } from "../../../../../contexts/RolesContext"
import { DisplayI18n } from "../../../../../helpers/i18nHelper"
import OrganizationGroupingAddModal from "../components/addModal/OrganizationGroupingAddModal"
import Wrapper from "../components/Wrapper"
import { ZONES, ZONES_CATEGORY_NAME } from "../groupingsConstants"
import ZonesParentSidepanel from "./ZonesParentSidepanel"

const OrganizationZones = (props) => {
    const orgContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext);
    const rolesContext = useContext(RolesContext);
    const { isGod, identity_role_id } = useContext(IdentityRolesContext);
    const periodsContext = useContext(PeriodsContext);

    const hasAccess = rolesContext.canDoAction('ADD', 'organization', 'organization_profile') && rolesContext.canDoAction('ADD', 'organization', 'affiliated_organization') && rolesContext.canDoAction('ADD', 'organization', 'organization_users')

    const [ isOpen, { toggle } ] = useDisclosure(false);

    const { savedSearch, saveSearch } = useSavedSearch(`organization-parent-zones-${orgContext.organisation_id}-${identity_role_id}-${periodsContext.selectedPeriod.period_id}`);

    return (
        <Card body className="card-shadow">
            <CardSectionTitle title='organisation.profile.tabs.zones' />

            <SpordlePanelTable
                allowOutsideClick
                sidePanel={(sPanelProps) => <ZonesParentSidepanel {...sPanelProps} />}
                dataIndex='organisation_id'
                table={(panelProps) => {
                    return (
                        <SpordleTableProvider
                            id={'organization-groupings-zones'}
                            tableHover bordered striped
                            clickable
                            ref={panelProps.spordleTableRef}
                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                            searchKeys={[
                                'organisation_name',
                                `i18n.${i18nContext.getGenericLocale()}.name`,
                            ]}
                            dataIndex='organisation_id'
                            pagination={savedSearch?.paginationValue || 10}
                            emptyLayout={<EmptyLayout />}
                            columns={[
                                {
                                    label: <Translate id='form.fields.name' />,
                                    key: "name",
                                    mobile: true,
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='misc.status' />,
                                    key: "status",
                                    mobile: true,
                                    sortable: true,
                                    dataClassName: 'text-center',
                                },
                            ]}
                            renderRow={(columnKey, data) => {
                                switch (columnKey){
                                    case 'name':
                                        return (
                                            <>
                                                <DisplayI18n
                                                    field="name"
                                                    defaultValue={data.organisation_name}
                                                    i18n={data.i18n}
                                                />
                                            </>
                                        )
                                    case 'status':
                                        return (
                                            data.active == "1" ? <i className="mdi mdi-check text-primary" /> : <i className="mdi mdi-close text-danger" />
                                        )
                                    default: break;
                                }
                            }}
                            desktopWhen
                            onColumnClick={(e, data) => {
                                switch (e.button){
                                    case 0: // Left mouse button
                                        panelProps.onSingleSelectChange(data);
                                        break;
                                }
                            }}
                            rowIsHighlighted={(data) => !!data.checked}
                            loadData={(from, _, spordleTable) => {
                                switch (from){
                                    case 'REFRESH':
                                        spordleTable.setLoading();
                                    case 'CDM':
                                        return orgContext.getOrganizationsTree(true, true, true)
                                            .then((organizations) => {
                                                const flatTree = organizations.flattenArray('sub_organisations', (org) => ({ ...org }))
                                                return flatTree.filter((org) => org?.organisation_category?.name === ZONES_CATEGORY_NAME)
                                            })
                                    default:
                                        break;
                                }
                            }}
                        >
                            {(spordleTable) => (
                                <>
                                    <div className="d-flex justify-content-between mb-2">
                                        <SearchInput />

                                        <div className="ml-auto">
                                            <TablePagination
                                                className='ml-auto mr-2'
                                                paginationArray={[ 10, 25, 50, 0 ]}
                                                onChange={(val) => {
                                                    saveSearch({ ...savedSearch, paginationValue: val })
                                                }}
                                            />
                                            <Refresh />

                                            {(hasAccess || isGod) &&
                                                <>
                                                    <OrganizationGroupingAddModal
                                                        isOpen={isOpen}
                                                        toggle={toggle}
                                                        from={ZONES}
                                                        onSuccess={spordleTable.refreshTable}
                                                    />
                                                    <Wrapper>
                                                        <Button
                                                            color='primary' type='button'
                                                            className={stringBuilder('ml-2', { 'btn-purple': isGod && !hasAccess })}
                                                            onClick={() => toggle()}
                                                        >
                                                            <i className='mdi mdi-plus mr-1' /><Translate id='misc.add' />
                                                        </Button>
                                                    </Wrapper>
                                                    {/* <CanDoAction action="ADD" componentCode="organization" componentPermissionCode="organization_profile">
                                                        <CanDoAction action="ADD" componentCode="organization" componentPermissionCode="affiliated_organization">
                                                            <CanDoAction action="ADD" componentCode="organization" componentPermissionCode="organization_users">
                                                                <Button
                                                                    color='primary' type='button'
                                                                    className={stringBuilder('ml-2', { 'btn-purple': isGod && !hasAccess })}
                                                                    onClick={() => toggle()}
                                                                >
                                                                    <i className='mdi mdi-plus mr-1' /><Translate id='misc.add' />
                                                                </Button>
                                                            </CanDoAction>
                                                        </CanDoAction>
                                                    </CanDoAction> */}
                                                </>
                                            }
                                        </div>
                                    </div>

                                    <SpordleTableView />
                                </>
                            )}
                        </SpordleTableProvider>
                    )
                }}
            />
        </Card>
    )
}

export default OrganizationZones