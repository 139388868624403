import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Fade } from "reactstrap";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { DisplayI18n } from "../../helpers/i18nHelper";
import { RefreshButton } from "../viewHeader/ViewHeaderV2";
import { useLocation } from "react-router";
import { ReportsContext } from "../../contexts/ReportsContext";

/**
 * @prop {string} title the displayed title: translation key or a string if using skipTitleTranslate
 * @prop {string} name fallback name if using i18n
 * @prop {string} i18n i18n object
 * @prop {boolean} skipTitleTranslate skips translating the title
 * @prop {boolean} hideOrg hides the organization
 *
 * @copyright Spordle
 */
const ReportHeader = ({ title, hideOrg, name, i18n, currentReport }) => {
    const organizationContext = useContext(OrganizationContext)
    const [ visibleRefreshKey, showRefreshKey ] = useState(false);

    return (
        <div className="mb-3 align-items-center">
            <div className="d-flex align-items-center" onMouseEnter={() => showRefreshKey(true)} onMouseLeave={() => showRefreshKey(false)}>
                <div className="h3 mb-0 font-medium">
                    <ReportHeaderInner title={title} />
                </div>
                <Fade in={visibleRefreshKey}>
                    <RefreshButton buttonClassName='ml-1 font-20' />
                </Fade>
            </div>
            {!hideOrg &&
                <div className='h5 mb-0 font-medium'>{organizationContext.organisation_name}</div>
            }
        </div>
    )
}

export const ReportHeaderInner = ({ title }) => {
    const { currentReport } = useContext(ReportsContext);
    const { name, i18n } = currentReport;
    const location = useLocation();

    return (
        <>
            {location.state?.report ?
                <div className="d-flex">
                    <DisplayI18n field="name" defaultValue={location.state.report.name} i18n={location.state.report.i18n} />
                    {location.state?.report?.summary_report
                        ?
                        <Translate id={'reportsDashboard.reportHeader.detailed'} />
                        : location.state?.report?.detailed_report
                            ?
                            <Translate id={'reportsDashboard.reportHeader.summary'} /> : null
                    }
                </div>
                : title
                    ? <Translate id={title} />
                    : name
                        ?
                        <div className="d-flex">
                            <DisplayI18n field="name" defaultValue={name} i18n={i18n} />
                            {currentReport.summary_report
                                ?
                                <Translate id={'reportsDashboard.reportHeader.detailed'} />
                                : currentReport.detailed_report
                                    ?
                                    <Translate id={'reportsDashboard.reportHeader.summary'} /> : null
                            }
                        </div>
                        :
                        <Skeleton count={1} height={25} width={400} />
            }
        </>
    )
}

export default ReportHeader