import { useId } from "@mantine/hooks";
import { useSpordleTable } from "@spordle/datatables";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Button, Card } from "reactstrap";
import useSWR from "swr";
import { IdentityRolesContext } from "../../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { ReportsContext } from "../../../contexts/ReportsContext";
import { counterAnim } from "../../../helpers/uiHelper";
import { reportsGroups } from "./reportsGroups";
import styles from "./ReportsDashboardFilterCards.module.scss";

const ReportsDashboardFilterCards = ({ allReports, error, getReportsList }) => {
    const spordleTable = useSpordleTable();
    const orgContext = useContext(OrganizationContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const reportsContext = useContext(ReportsContext);
    const squareSize = 136;// 136px
    const currentGroupSelected = spordleTable.getFilters().groupFilter.groupCode;
    const isLoading = spordleTable.state.loadingState === 'lazy' || spordleTable.state.loadingState === 'loading'

    const { data: availableExportedReportsCount } = useSWR(
        [ 'ReportsDashboard-getExportsReportList', orgContext.organisation_id, identityRolesContext.identity.identity_id ],
        () => reportsContext.getExportsReportList({ action: 'EXPORT' })
            .then((exports) => {
                return exports.filter((report) => !(report.request_status === 'PENDING' ? false : new Date(report.expired_at) - new Date() < 0)).length;
            })
            .catch(() => {
                return 0;
            }),
    );

    return (isLoading ?
        <Skeleton height={squareSize} width='100%' className="mt-2 mb-4" />
        :
        error ?
            <div
                className="mt-2 mb-4 d-flex flex-column flex-center text-center border border-danger rounded card-hover clickable bg-light-danger"
                style={{ height: squareSize }}
                onClick={() => {
                    spordleTable.setLoading();
                    getReportsList(null);
                }}
                id='reportsDashboardFilterCards-retry'
            >
                <span className="h4 mb-2">
                    <Translate id='reports.dashboard.add.custom.error.title' />
                </span>
                <Translate id='reports.dashboard.add.custom.error.subtitle' />
                <Button color='danger' type="button" size="sm" className="mt-2" id='reportsDashboardFilterCards-retryBtn'>
                    <Translate id='misc.retry' /><i className="ml-1 mdi mdi-refresh" />
                </Button>
            </div>
            :
            <div className="d-flex mx-n1 mt-2 mb-4 flex-wrap flex-lg-nowrap">
                {reportsGroups
                    .map((group) => {
                        if(group.hidden){
                            return null
                        }
                        const isExport = group.groupCode === 'exports';
                        return (
                            <div className='w-50 w-sm-25 w-lg-100 px-1 my-2' key={group.groupCode}>
                                <Card
                                    style={{ width: squareSize, position: 'relative' }}
                                    className={stringBuilder('card-shadow square-element card-hover clickable text-center w-100 mb-0', { 'border-primary': currentGroupSelected === group.groupCode })}
                                    onClick={() => {
                                        spordleTable.filterChange('groupFilter', { groupCode: group.groupCode });
                                    }}
                                    id={`reportsDashboardFilterCard-${group.groupCode}`}
                                >
                                    {isExport && availableExportedReportsCount > 0 &&
                                        <div
                                            className="bg-primary font-16 position-absolute text-light border rounded px-1" style={{ top: '-10px', right: '-10px' }}
                                        >
                                            {/* number of exported reports available for download */}
                                            {availableExportedReportsCount}
                                        </div>
                                    }
                                    <div className={stringBuilder("square-element-inner p-2", styles.Card)}>
                                        {isExport ?
                                            <>
                                                {group.groupIcon}
                                                <div className="mt-2"><Translate id={group.groupTitle} /></div>
                                            </>
                                            :
                                            <ReportsDashboardFilterCardsInner group={group} allReports={allReports || []} />
                                        }
                                    </div>
                                </Card>
                            </div>
                        )
                    })}
            </div>
    )
}

const ReportsDashboardFilterCardsInner = ({ group, allReports }) => {
    const currentGroupLength = group.groupCode == 'ALL' ? allReports.length : group.groupCode == 'CUSTOM' ? allReports.filter((report) => report.is_custom == 1).length : allReports.filter((report) => report.type == group.groupCode).length;
    const id = useId();

    useEffect(() => {
        counterAnim(id, 0, currentGroupLength || 0, 400) // start at current count and go to next number
    }, [ currentGroupLength ]);

    return (
        <>
            {group.groupIcon}
            <div className="d-flex mt-2">
                <Translate id={group.groupTitle} />
                <div className="text-muted ml-2">
                    (<span id={id}>0</span>)
                </div>
            </div>
        </>
    )
}

export default ReportsDashboardFilterCards