import memberGeneral from "./memberGeneral";
import memberAddressPhone from "./memberAddressPhone";
import memberContacts from "./memberContacts";
import memberDocuments from "./memberDocuments";
import memberFamilyRelations from "./memberFamilyRelations";
import memberMedicalNotes from "./memberMedicalNotes";
import sidePanelMemberMedicalNotes from "./sidePanelMemberMedicalNotes";
import memberMemos from "./memberMemos";
import memberLogs from "./memberLogs";
import sidePanelMemberLogs from "./sidePanelMemberLogs"

export default{
    ...memberGeneral,
    ...memberAddressPhone,
    ...memberContacts,
    ...memberDocuments,
    ...memberFamilyRelations,
    ...memberMedicalNotes,
    ...sidePanelMemberMedicalNotes,
    ...memberMemos,
    ...memberLogs,
    ...sidePanelMemberLogs,
}