import { useContext } from "react";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { PositionsContext } from "../../contexts/PositionsContext";
import { RolesContext } from "../../contexts/RolesContext";
import { IdentityRolesContext } from "../../contexts/IdentityRolesContext";
import useSWR from "swr";
import { I18nContext } from "../../contexts/I18nContext";
import { displayI18n } from "../../helpers/i18nHelper";

export function useTeamPositionsSWR(){
    // @ts-ignore
    const { organisation_id } = useContext(OrganizationContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { getPositions } = useContext(PositionsContext);
    const { hasAccessTo } = useContext(RolesContext);
    // @ts-ignore
    const { isGod } = useContext(IdentityRolesContext);

    const canFetch = hasAccessTo("teams", "team_positions") || isGod();

    return useSWR(
        [ "getPositions", canFetch ? organisation_id : null ],
        (_, id) => id ? getPositions(id)
            .then((positions) => {
                return positions.sort((a, b) => {
                    const order = parseInt(a.display_order || 0 ) - parseInt(b.display_order || 0);

                    if(order != 0) {
                        return order;
                    }

                    const nameA = displayI18n("name", a.i18n, a.name, getGenericLocale()) || "";
                    const nameB = displayI18n("name", b.i18n, b.name, getGenericLocale()) || "";

                    return nameA.localeCompare(nameB);

                })
            })
            :
            Promise.resolve([]),
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false
        }
    );
}