import Translate from "@spordle/intl-elements"
import { Form, Formik } from "formik"
import { useContext, useState, useEffect } from "react"
import { Button, Collapse, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import AnalyticsModal from "../../../../../analytics/AnalyticsModal"
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI"
import CustomAlert from "../../../../../components/CustomAlert"
import OverlayLoader from "../../../../../components/loading/OverlayLoader"
import { OrganizationContext } from "../../../../../contexts/OrganizationContext"
import { TeamsContext } from "../../../../../contexts/TeamsContext"
import { DisplayI18n } from "../../../../../helpers/i18nHelper"
import { FormikSelect } from '@spordle/formik-elements';
import CrossFade from "../../../../../components/crossFade/CrossFade"
import UserImg from "../../../../../components/UserImg"
import TripleArrow from "../../../../../assets/images/Triple_Arrow.png"
import Skeleton from 'react-loading-skeleton';
import CustomAnimatedIcon from "../../../../../components/customAnimatedIcon/CustomAnimatedIcon"
import DisplayOrganization from "../../../../../components/organization/DisplayOrganization"
import { Link } from "react-router-dom"

const ChangeTeamOrgModal = ({ toggle, isOpen, teamId }) => {


    return (
        <AnalyticsModal analyticsName="ChangeTeamOrgModal" isOpen={isOpen}>
            {isOpen && <ChangeTeamOrgModalInner toggle={toggle} teamId={teamId} />}
        </AnalyticsModal>
    )
}

const ChangeTeamOrgModalInner = ({ toggle, teamId }) => {
    const teamsContext = useContext(TeamsContext);
    const { getOrganizationAffiliations } = useContext(OrganizationContext)
    // 0 is selection screen 1 is submit screen, 2 is confirmation and redirect to team search
    const [ step, setStep ] = useState(0);
    const [ selectedOrg, setSelectedOrg ] = useState(false);

    const [ orgs, setOrgs ] = useState([]);
    const [ orgsLoading, setOrgsLoading ] = useState(true);

    useEffect(() => {
        if(teamsContext.cachedTeam){
            setOrgsLoading(true)
            getOrganizationAffiliations({
                organisation_id: teamsContext.cachedTeam?.organisation?.organisation_id,
                division_id: teamsContext.cachedTeam?.team_category?.division?.division_id,
            })
                .then((orgs) => {
                    setOrgs(orgs.map((org) => ({
                        value: org.organisation_id,
                        ...org,
                    })));
                    setOrgsLoading(false);
                }).catch((e) => {
                    if(!AxiosIsCancelled(e.message)){
                        console.error(e);
                        setOrgsLoading(false)
                    }
                });
        }
    }, [ teamId ])

    return (
        <Formik
            initialValues={{
                organizationId: '',
            }}
            onSubmit={({ organizationId }, { setStatus }) => {
                return teamsContext.transferTeamOrganization(teamId, organizationId).then(() => {
                    setStep(2);
                }).catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        if(error.i18n){
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                        }
                    }
                })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={() => { toggle(); setStep(0); }}>
                            <Translate id='teams.profile.actionsMenu.transfer.title' />
                        </ModalHeader>
                        {/* SELECTION STEP */}
                        <CrossFade isVisible={step === 0}>
                            <ModalBody>
                                <CustomAlert color="info" text="teams.profile.actionsMenu.transfer.alert" translateText />
                                <FormikSelect
                                    id='organizationId'
                                    name='organizationId'
                                    renderOption={(option) => {
                                        // return (<>{option.option.organisation_name}</>)
                                        return (
                                            <div className='d-flex align-items-center'>
                                                <div className="d-flex justify-content-center align-items-center mr-2">
                                                    <UserImg
                                                        abbr={option.option.abbreviation}
                                                        src={option.option.logo?.full_path}
                                                        filePos={option.option.logo?.file_position}
                                                        width={'20'}
                                                        height={'20'}
                                                        alt={option.option.organisation_name + ' logo'}
                                                        className="p-1"
                                                    />
                                                </div>
                                                <div>
                                                    <span className='font-medium'><DisplayI18n field='name' defaultValue={option.option.organisation_name} i18n={option.option.i18n} /></span>
                                                    {option.option.abbreviation &&
                                                        <span className='text-muted ml-1'>({option.option.abbreviation})</span>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }}
                                    isLoading={orgsLoading}
                                    options={orgs}
                                    onOptionSelected={(selectedOrg) => {
                                        if(selectedOrg.organisation_id !== selectedOrg[0])
                                            setSelectedOrg(orgs.find((org) => org.organisation_id === selectedOrg[0]))
                                    }}

                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    // disabled={teamsContext.cachedTeam?.organisation?.organisation_id === formik.values.organizationId}
                                    color='primary'
                                    onClick={() => {
                                        setStep(1)
                                    }}
                                >
                                    <Translate id='misc.next' />
                                </Button>
                                <Button color='primary' onClick={() => { toggle(); setStep(0); }} outline><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </CrossFade>
                        {/* SUBMIT STEP */}
                        <CrossFade isVisible={step === 1}>
                            <ModalBody>
                                <div className="d-flex justify-content-around">
                                    <div className="w-50 text-center">
                                        <UserImg
                                            className="mx-auto mb-3"
                                            width={70}
                                            abbr={teamsContext.cachedTeam?.organisation?.abbr}
                                            src={teamsContext.cachedTeam?.organisation?.logo?.full_path}
                                            filePos={teamsContext.cachedTeam?.organisation?.logo?.file_position}
                                            alt={teamsContext.cachedTeam?.organisation?.organisation_name}
                                        />
                                        <div className="h6 font-medium"><DisplayI18n field='name' defaultValue={teamsContext.cachedTeam?.organisation?.organisation_name} i18n={teamsContext.cachedTeam?.organisation?.i18n} /></div>
                                    </div>

                                    <div className="flex-shrink-0 px-3 align-self-center">
                                        <img width="30" src={TripleArrow} />
                                    </div>
                                    {!selectedOrg ?
                                        <div className="w-50 text-center">
                                            <Skeleton className="mb-3" circle width={68} height={68} />
                                            <Skeleton className='h6 font-medium' width={150} height={11} />
                                        </div>
                                        :
                                        <div className="w-50 text-center">
                                            <UserImg
                                                className="mx-auto mb-3"
                                                width={70}
                                                abbr={selectedOrg?.abbreviation}
                                                src={selectedOrg?.logo?.full_path}
                                                filePos={selectedOrg?.logo?.file_position}
                                                alt={selectedOrg?.organisation_name + ' logo'}
                                            />
                                            <div className="h6 font-medium"><DisplayI18n field='name' defaultValue={selectedOrg?.organisation_name} i18n={selectedOrg?.i18n} /></div>
                                        </div>
                                    }
                                </div>
                                <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                    {formik.status &&
                                        <CustomAlert color='danger' text={formik.status} translateText={false} withTitle toggle={() => formik.setStatus(null)} />
                                    }
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' onClick={() => setStep(0)} outline><Translate id='misc.previous' /></Button>
                                <Button color='primary' type='submit'><Translate id='misc.submit' /></Button>
                            </ModalFooter>
                        </CrossFade>
                        {/* CONFIRMATION STEP */}
                        <CrossFade isVisible={step === 2}>
                            <ModalBody>
                                <div>
                                    <CustomAnimatedIcon
                                        icon="checkmark"
                                        id="convertion-success"
                                        withCircle
                                        strokeWidth="10px"
                                        size={50}
                                        className='text-success mb-2'
                                    />
                                    <div className="text-center">
                                        <div className="font-bold">
                                            <Translate id='teams.profile.actionsMenu.transfer.success.1' />
                                            <span className="font-bold ml-1">
                                                <DisplayOrganization organisation={{ organisation_id: selectedOrg?.organisation_id }}>
                                                    <DisplayI18n defaultValue={selectedOrg?.organisation_name} i18n={selectedOrg?.i18n} field='name' />
                                                </DisplayOrganization>
                                            </span>
                                        </div>
                                        <div>
                                            <Translate id='teams.profile.actionsMenu.transfer.success.2' />
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Link color='primary' to={'/teams'} outline><Button color='primary'><Translate id='misc.close' /></Button></Link>
                            </ModalFooter>
                        </CrossFade>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}


export default ChangeTeamOrgModal