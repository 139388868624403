export default{
    "settings.documentSettings.tab"   : "Documents",
    "settings.documentSettings.title" : "Document Settings",

    "settings.documentSettings.member.registration.title" : "Member Registration Documents",
    "settings.documentSettings.member.registration.desc"  : "These document types will need to be provided during the member registration process.",

    "settings.documentSettings.clinic.registration.title" : "Clinic  Registration Documents",
    "settings.documentSettings.clinic.registration.desc"  : "These document types will need to be provided during the clinic registration process.",

    "settings.documentSettings.insurance.title" : "Insurance Documents",
    "settings.documentSettings.insurance.desc"  : "These document types can be provided during the insurance claim process.",

    "settings.documentSettings.travelPermit.title" : "Travel Permit Documents",
    "settings.documentSettings.travelPermit.desc"  : "These document types can be provided for a travel permit.",

    "settings.documentSettings.documents.notDefined" : "No documents currently required.",

    "settings.documentTypes.title"       : "Document Types",
    "settings.documentTypes.status"      : "Status",
    "settings.documentTypes.empty"       : "No document types",
    "settings.documentTypes.add.title"   : "Add a document type",
    "settings.documentTypes.delete.text" : "Are you sure you want to delete",

    "settings.documentExpirations.title"                : "Document Expirations",
    "settings.documentExpirations.add.title"            : "Add Document Expiration",
    "settings.documentExpirations.add.docType.required" : "Document type is required.",
    "settings.documentExpirations.add.months.required"  : "Months until expiration is a required field.",
    "settings.documentExpirations.add.min.smaller"      : "Minimum age must be smaller than the maximum age.",
    "settings.documentExpirations.add.max.larger"       : "Maximum age must be greater than the minimum age.",

    "settings.documentTypeExpiration.document_types.row"                     : "Document Type",
    "settings.documentTypeExpiration.delete_after_expiration.row"            : "Delete After Expiration",
    "settings.documentTypeExpiration.organization.row"                       : "Organization",
    "settings.documentTypeExpiration.expiration_duration.row"                : "Months until expiration",
    "settings.documentTypeExpiration.expiration_duration.row.months"         : "Months",
    "settings.documentTypeExpiration.expiration_duration.row.month"          : "Month",
    "settings.documentTypeExpiration.expiration_duration.validation.minimum" : "The minimum number of months before expiration is 1.",
    "settings.documentTypeExpiration.min_age.row"                            : "Minimum Age",
    "settings.documentTypeExpiration.max_age.row"                            : "Maximum Age",
    "settings.documentTypeExpiration.delete"                                 : "Are you sure you want to delete the document expiration rule for",
}