import { FormikInputNumber, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import {
    Col,
    FormGroup,
    Label, Row
} from "reactstrap";
import useSWR from 'swr';
import { mixed, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { I18nContext } from '../../../../contexts/I18nContext';
import { IdentityRolesContext } from '../../../../contexts/IdentityRolesContext';
import { MembersContext } from '../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { organisationCategoryIntlFinances } from '../../../../helpers/constants';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import FormikEditable from '../../../formik/FormikEditable';
import OverlayLoader from '../../../loading/OverlayLoader';
import { fail, success } from '@spordle/toasts';
import UserImg from '../../../UserImg';

const FinancesInternationalTab = ({ transfer, ...props }) => {
    const membersContext = useContext(MembersContext);
    const organizationContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext)
    const identityRolesContext = useContext(IdentityRolesContext);

    const [ isLoading, setLoading ] = useState(false)

    const filterCategory = (catArr, category) => {
        if(organisationCategoryIntlFinances.includes(category.default_name)){
            catArr.push(category.category_id);
        }

        return catArr;
    }

    const { data: orgs, isValidating } = useSWR(
        [ 'getBillableOrganizations', organizationContext.organisation_id ],
        () => {
            return new Promise(async(resolve) => {
                const categories = await organizationContext.getOrganizationCategory(organizationContext.organisation_id)
                    .then((cats) => cats.reduce(filterCategory, []))
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    });

                resolve(
                    Promise.all([
                        organizationContext.getBillableOrganizations(),
                        organizationContext.getOrganizationByCategories(identityRolesContext.federation.organisation_id, { organisation_category_id: categories }),
                    ])
                        .then(([ billableOrgs, internationalOrgs ]) => {
                            return [ ...billableOrgs.filter((org) => org.active == 1), ...internationalOrgs.filter((org) => org.active == 1) ]
                        }).catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        }),
                )
            })
        },
    );

    const updateData = (values) => {
        if(props.mutate){
            props.mutate();
        }else{
            props.syncRows(props.createNewValues(values))
        }
    }

    return (
        <OverlayLoader isLoading={isLoading || isValidating}>
            <div className="p-3">
                <Row>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='teamReleaseFees' className='text-muted mb-0'><Translate id='members.profile.transfers.add.fields.releaseFee.label' /></Label>
                            <FormikEditable
                                id='teamReleaseFees'
                                initialValues={{
                                    teamReleaseFees: transfer.team_release_fee ? transfer.team_release_fee / 100 : '',
                                }}
                                validationSchema={object().shape({
                                    teamReleaseFees: mixed(),
                                })}
                                onSubmit={(values) => {
                                    const newFees = values.teamReleaseFees ? Math.round(values.teamReleaseFees * 100) : null
                                    if(newFees !== transfer.team_release_fee){
                                        setLoading(true)
                                        membersContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                            team_release_fee: newFees,
                                        })
                                            .then(() => {
                                                updateData({
                                                    team_release_fee: newFees,
                                                });
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setLoading(false))
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>
                                                {transfer.team_release_fee ? <CurrencyFormat value={transfer.team_release_fee / 100} /> : '-'}
                                            </div>
                                        )
                                    }
                                    return (
                                        <I18nContext.Consumer>
                                            {({ getGenericLocale }) => (
                                                <FormikInputNumber
                                                    autoFocus
                                                    allowLeadingZeros
                                                    fixedDecimalScale
                                                    thousandSeparator=' '
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    name="teamReleaseFees"
                                                    id="teamReleaseFees"
                                                    decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                    suffix={getGenericLocale() === 'fr' ? ' $' : ''}
                                                    prefix={getGenericLocale() !== 'fr' ? '$ ' : ''}
                                                />
                                            )}
                                        </I18nContext.Consumer>
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='teamNameReleaseFees' className='text-muted mb-0'><Translate id='members.profile.transfers.add.fields.releaseFeeTeam.label' /></Label>
                            <FormikEditable
                                id='teamNameReleaseFees'
                                initialValues={{
                                    teamNameReleaseFees: transfer.release_fee_team_name || '',
                                }}
                                validationSchema={object().shape({
                                    teamNameReleaseFees: string(),
                                })}
                                onSubmit={(values) => {
                                    const newName = values.teamNameReleaseFees || null
                                    if(newName !== transfer.release_fee_team_name){
                                        setLoading(true)
                                        membersContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                            release_fee_team_name: newName,
                                        })
                                            .then(() => {
                                                updateData({
                                                    release_fee_team_name: newName,
                                                });
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setLoading(false))
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{transfer.release_fee_team_name || '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikInputText id='teamNameReleaseFees' name='teamNameReleaseFees' trim autoFocus />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                </Row>

                <div className='h4 font-bold mb-3'><Translate id='members.profile.transfers.sidepanel.finances.receivable' /></div>
                <Row>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='iihfCard' className='text-muted mb-0'><Translate id='members.profile.transfers.sidepanel.finances.iihfCard' /></Label>
                            <FormikEditable
                                id='iihfCard'
                                noConfirmation
                                initialValues={{
                                    iihfCard: transfer.iihf_card_amount ? transfer.iihf_card_amount / 100 : '',
                                }}
                                validationSchema={object().shape({
                                    iihfCard: mixed(),
                                })}
                                onSubmit={(values) => {
                                    const newIIHFCard = values.iihfCard ? Math.round(values.iihfCard * 100) : null
                                    if(newIIHFCard != transfer.iihf_card_amount){
                                        setLoading(true)
                                        membersContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                            iihf_card_amount: newIIHFCard,
                                        })
                                            .then(() => {
                                                updateData({
                                                    iihf_card_amount: newIIHFCard,
                                                });
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setLoading(false))
                                    }
                                }}
                            >
                                {(isEditing, options) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{transfer.iihf_card_amount ? <CurrencyFormat value={transfer.iihf_card_amount / 100} /> : '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikSelect
                                            name="iihfCard"
                                            id="iihfCard"
                                            search={false}
                                            renderOption={({ option }) => <CurrencyFormat value={option.value} />}
                                            onOptionSelected={options.stopEditing}
                                            menuIsDefaultOpen autoFocus
                                            defaultData={[
                                                {
                                                    value: '0',
                                                    label: '0',
                                                },
                                                {
                                                    value: '100',
                                                    label: '100',
                                                },
                                                {
                                                    value: '300',
                                                    label: '300',
                                                },
                                                {
                                                    value: '450',
                                                    label: '450',
                                                },
                                            ]}
                                            loadingStatus='success'
                                        />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='HCApp' className='text-muted mb-0'><Translate id='members.profile.transfers.sidepanel.finances.HCApp' /></Label>
                            <FormikEditable
                                id='HCApp'
                                noConfirmation
                                initialValues={{
                                    HCApp: transfer.hcapp_amount ? transfer.hcapp_amount / 100 : '',
                                }}
                                validationSchema={object().shape({
                                    HCApp: mixed(),
                                })}
                                onSubmit={(values) => {
                                    const newHCApp = values.HCApp ? Math.round(values.HCApp * 100) : null
                                    if(newHCApp != transfer.hcapp_amount){
                                        setLoading(true)
                                        membersContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                            hcapp_amount: newHCApp,
                                        })
                                            .then(() => {
                                                updateData({
                                                    hcapp_amount: newHCApp,
                                                });
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setLoading(false))
                                    }
                                }}
                            >
                                {(isEditing, options) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{transfer.hcapp_amount ? <CurrencyFormat value={transfer.hcapp_amount / 100} /> : '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikSelect
                                            name="HCApp" id="HCApp" search={false}
                                            renderOption={({ option }) => <CurrencyFormat value={option.value} />}
                                            onOptionSelected={options.stopEditing}
                                            menuIsDefaultOpen autoFocus
                                            loadingStatus='success'
                                            defaultData={[
                                                {
                                                    value: '0',
                                                    label: '0',
                                                },
                                                {
                                                    value: '100',
                                                    label: '100',
                                                },
                                                {
                                                    value: '300',
                                                    label: '300',
                                                },
                                                {
                                                    value: '450',
                                                    label: '450',
                                                },
                                            ]}
                                        />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='iihfAmount' className='text-muted mb-0'><Translate id='members.profile.transfers.sidepanel.finances.formerFederation' /></Label>
                            <FormikEditable
                                id='iihfAmount'
                                noConfirmation
                                initialValues={{
                                    iihfAmount: transfer.iihf_amount ? transfer.iihf_amount / 100 : '',
                                }}
                                validationSchema={object().shape({
                                    iihfAmount: mixed(),
                                })}
                                onSubmit={(values) => {
                                    const newIihfAmmount = values.iihfAmount ? Math.round(values.iihfAmount * 100) : null
                                    if(newIihfAmmount != transfer.iihf_amount){
                                        setLoading(true)
                                        membersContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                            iihf_amount: newIihfAmmount,
                                        })
                                            .then(() => {
                                                updateData({
                                                    iihf_amount: newIihfAmmount,
                                                });
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setLoading(false))
                                    }
                                }}
                            >
                                {(isEditing, options) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{transfer.iihf_amount ? <CurrencyFormat value={transfer.iihf_amount / 100} /> : '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikSelect
                                            name="iihfAmount" id="iihfAmount" search={false}
                                            renderOption={({ option }) => <CurrencyFormat value={option.value} />}
                                            onOptionSelected={options.stopEditing}
                                            menuIsDefaultOpen autoFocus
                                            loadingStatus='success'
                                            defaultData={[
                                                {
                                                    value: '0',
                                                    label: '0',
                                                },
                                                {
                                                    value: '100',
                                                    label: '100',
                                                },
                                                {
                                                    value: '300',
                                                    label: '300',
                                                },
                                                {
                                                    value: '450',
                                                    label: '450',
                                                },
                                            ]}
                                        />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='hcInvoice' className='text-muted mb-0'><Translate id='members.profile.transfers.sidepanel.finances.HCInvoice' /></Label>
                            <FormikEditable
                                id='hcInvoice'
                                initialValues={{
                                    hcInvoice: transfer.hc_invoice_number || '',
                                }}
                                validationSchema={object().shape({
                                    hcInvoice: string(),
                                })}
                                onSubmit={(values) => {
                                    const newInvoice = values.hcInvoice || null
                                    if(newInvoice !== transfer.hc_invoice_number){
                                        setLoading(true)
                                        membersContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                            hc_invoice_number: newInvoice,
                                        })
                                            .then(() => {
                                                updateData({
                                                    hc_invoice_number: newInvoice,
                                                });
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setLoading(false))
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{transfer.hc_invoice_number || '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikInputText id='hcInvoice' name='hcInvoice' trim autoFocus />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup>
                            <Label for='receivableFederation' className='text-muted mb-0'><Translate id='members.profile.transfers.sidepanel.finances.federationId' /></Label>
                            <FormikEditable
                                id='receivableFederation'
                                noConfirmation
                                initialValues={{
                                    receivableFederation: transfer.account_receivable_federation?.organisation_id || '',
                                }}
                                validationSchema={object().shape({
                                    receivableFederation: string(),
                                })}
                                onSubmit={(values) => {
                                    if(values.receivableFederation !== transfer.account_receivable_federation?.organisation_id){
                                        setLoading(true)
                                        membersContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                            account_receivable_federation_id: values.receivableFederation || null,
                                        })
                                            .then(() => {
                                                const newOrg = orgs.find((org) => org.organisation_id === values.receivableFederation)
                                                updateData({
                                                    account_receivable_federation: newOrg ? {
                                                        organisation_id: newOrg.organisation_id,
                                                        organisation_name: newOrg.organisation_name,
                                                        i18n: newOrg.i18n,
                                                        logo: newOrg.logo,
                                                        abbreviation: newOrg.abbreviation,
                                                    } : null,
                                                });
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setLoading(false))
                                    }
                                }}
                            >
                                {(isEditing, options, formik) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark text-truncate'>
                                                {!isValidating && transfer.account_receivable_federation ?
                                                    <DisplayI18n
                                                        field='name'
                                                        defaultValue={transfer.account_receivable_federation.organisation_name}
                                                        i18n={transfer.account_receivable_federation.i18n}
                                                    />
                                                    :
                                                    '-'
                                                }
                                            </div>
                                        )
                                    }
                                    return (
                                        <FormikSelect
                                            id='receivableFederation'
                                            name='receivableFederation'
                                            isLoading={isValidating}
                                            searchKeys={[
                                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                            ]}
                                            onOptionSelected={() => {
                                                options.stopEditing();
                                            }}
                                            menuIsDefaultOpen clearable autoFocus
                                            backspaceRemovesValue={false}
                                            options={orgs ?
                                                orgs.map((org) => ({
                                                    value: org.organisation_id,
                                                    label: org.organisation_name,
                                                    logo: org.logo,
                                                    abbreviation: org.abbreviation,
                                                }))
                                                :
                                                []
                                            }
                                        />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                </Row>

                <div className='h4 font-bold mb-3'><Translate id='members.profile.transfers.sidepanel.finances.payable' /></div>
                <Row>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='federationAmount' className='text-muted mb-0'><Translate id='members.profile.transfers.sidepanel.finances.federationAmount' /></Label>
                            <FormikEditable
                                id='federationAmount'
                                noConfirmation
                                initialValues={{
                                    federationAmount: transfer.federation_amount ? transfer.federation_amount / 100 : '',
                                }}
                                validationSchema={object().shape({
                                    federationAmount: mixed(),
                                })}
                                onSubmit={(values) => {
                                    const newFederationAmount = values.federationAmount ? Math.round(values.federationAmount * 100) : null
                                    if(newFederationAmount != transfer.federation_amount){
                                        setLoading(true)
                                        membersContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                            federation_amount: newFederationAmount,
                                        })
                                            .then(() => {
                                                updateData({
                                                    federation_amount: newFederationAmount,
                                                });
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setLoading(false))
                                    }
                                }}
                            >
                                {(isEditing, options) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{transfer.federation_amount ? <CurrencyFormat value={transfer.federation_amount / 100} /> : '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikSelect
                                            name="federationAmount" id="federationAmount" search={false}
                                            renderOption={({ option }) => <CurrencyFormat value={option.value} />}
                                            onOptionSelected={options.stopEditing}
                                            menuIsDefaultOpen autoFocus
                                            loadingStatus='success'
                                            defaultData={[
                                                {
                                                    value: 0,
                                                    label: '0',
                                                },
                                                {
                                                    value: 100,
                                                    label: '100',
                                                },
                                                {
                                                    value: 300,
                                                    label: '300',
                                                },
                                                {
                                                    value: 450,
                                                    label: '450',
                                                },
                                            ]}
                                        />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='federationInvoice' className='text-muted mb-0'><Translate id='members.profile.transfers.sidepanel.finances.federationInvoice' /></Label>
                            <FormikEditable
                                id='federationInvoice'
                                initialValues={{
                                    federationInvoice: transfer.federation_invoice_number || '',
                                }}
                                validationSchema={object().shape({
                                    federationInvoice: string(),
                                })}
                                onSubmit={(values) => {
                                    const newInvoice = values.federationInvoice || null
                                    if(newInvoice !== transfer.federation_invoice_number){
                                        setLoading(true)
                                        membersContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                            federation_invoice_number: newInvoice,
                                        })
                                            .then(() => {
                                                updateData({
                                                    federation_invoice_number: newInvoice,
                                                });
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setLoading(false))
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{transfer.federation_invoice_number || '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikInputText id='federationInvoice' name='federationInvoice' trim autoFocus />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='paid' className='text-muted mb-0'><Translate id='members.profile.transfers.sidepanel.finances.paid' /></Label>
                            <FormikEditable
                                id='paid'
                                noConfirmation
                                initialValues={{
                                    paid: transfer.account_payable_paid_amount ? transfer.account_payable_paid_amount / 100 : '',
                                }}
                                validationSchema={object().shape({
                                    paid: mixed(),
                                })}
                                onSubmit={(values) => {
                                    const newPaid = values.paid ? Math.round(values.paid * 100) : null
                                    if(newPaid != transfer.account_payable_paid_amount){
                                        setLoading(true)
                                        membersContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                            account_payable_paid_amount: newPaid,
                                        })
                                            .then(() => {
                                                updateData();
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setLoading(false))
                                    }
                                }}
                            >
                                {(isEditing, options) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{transfer.account_payable_paid_amount ? <CurrencyFormat value={transfer.account_payable_paid_amount / 100} /> : '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikSelect
                                            name="paid" id="paid" search={false}
                                            renderOption={({ option }) => <CurrencyFormat value={option.value} />}
                                            onOptionSelected={options.stopEditing}
                                            menuIsDefaultOpen autoFocus
                                            loadingStatus='success'
                                            defaultData={[
                                                {
                                                    value: 0,
                                                    label: '0',
                                                },
                                                {
                                                    value: 100,
                                                    label: '100',
                                                },
                                                {
                                                    value: 300,
                                                    label: '300',
                                                },
                                                {
                                                    value: 450,
                                                    label: '450',
                                                },
                                            ]}
                                        />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup>
                            <Label for='payableFederation' className='text-muted mb-0'><Translate id='members.profile.transfers.sidepanel.finances.federationId' /></Label>
                            <FormikEditable
                                id='payableFederation'
                                noConfirmation
                                initialValues={{
                                    payableFederation: transfer.account_payable_federation?.organisation_id || '',
                                }}
                                validationSchema={object().shape({
                                    payableFederation: string(),
                                })}
                                onSubmit={(values) => {
                                    if(values.payableFederation !== transfer.account_payable_federation?.organisation_id){
                                        setLoading(true)
                                        membersContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                            account_payable_federation_id: values.payableFederation || null,
                                        })
                                            .then(() => {
                                                const newOrg = orgs.find((org) => org.organisation_id === values.payableFederation)
                                                updateData({
                                                    account_payable_federation: newOrg ? {
                                                        organisation_id: newOrg.organisation_id,
                                                        organisation_name: newOrg.organisation_name,
                                                        i18n: newOrg.i18n,
                                                        logo: newOrg.logo,
                                                        abbreviation: newOrg.abbreviation,
                                                    } : null,
                                                });
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setLoading(false))
                                    }
                                }}
                            >
                                {(isEditing, options, formik) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark text-truncate'>
                                                {!isValidating && transfer.account_payable_federation ?
                                                    <DisplayI18n
                                                        field='name'
                                                        defaultValue={transfer.account_payable_federation.organisation_name}
                                                        i18n={transfer.account_payable_federation.i18n}
                                                    />
                                                    :
                                                    '-'
                                                }
                                            </div>
                                        )
                                    }
                                    return (
                                        <FormikSelect
                                            id='payableFederation' name='payableFederation' isLoading={isValidating}
                                            searchKeys={[
                                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                            ]}
                                            onOptionSelected={() => {
                                                options.stopEditing();
                                            }}
                                            menuIsDefaultOpen clearable autoFocus
                                            backspaceRemovesValue={false}
                                            renderSelectedOption={(option) => (
                                                <div className='d-flex align-items-center'>
                                                    <div className="d-flex justify-content-center align-items-center mr-2">
                                                        <UserImg
                                                            abbr={option.abbreviation}
                                                            src={option.logo?.full_path}
                                                            filePos={option.logo?.file_position}
                                                            width={20}
                                                            alt={option.name + ' logo'}
                                                            className="p-1"
                                                        />
                                                    </div>
                                                    <div>
                                                        <span className='font-medium'>
                                                            <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                        </span>
                                                        {option.abbreviation &&
                                                                <span className='text-muted ml-1'>({option.abbreviation})</span>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                            renderOption={({ option }) => (
                                                <div className='d-flex align-items-center'>
                                                    <div className="d-flex justify-content-center align-items-center mr-2">
                                                        <UserImg
                                                            abbr={option.abbreviation}
                                                            src={option.logo?.full_path}
                                                            filePos={option.logo?.file_position}
                                                            width={40}
                                                            alt={option.name + ' logo'}
                                                            className="p-1"
                                                        />
                                                    </div>
                                                    <div>
                                                        <span className='font-medium'>
                                                            <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                        </span>
                                                        {option.abbreviation &&
                                                                <span className='text-muted ml-1'>({option.abbreviation})</span>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                            options={orgs ?
                                                orgs.map((org) => ({
                                                    value: org.organisation_id,
                                                    label: org.organisation_name,
                                                    i18n: org.i18n,
                                                    logo: org.logo,
                                                    abbreviation: org.abbreviation,
                                                }))
                                                :
                                                []
                                            }
                                        />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </OverlayLoader>
    );
}

export default FinancesInternationalTab;