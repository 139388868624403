import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import { Button, Fade } from "reactstrap";
import { lockBodyScroll, unlockBodyScroll } from "../../../helpers/uiHelper";
import { NEWS_ID_PREFIX } from "../newsHelpers";
import Notice from "./notice/Notice";
import styles from "./NoticesList.module.scss";

/**
 * @param {object} props
 * @param {boolean} props.canManageNews
 * @param {boolean} props.isOpen
 * @param {(isRedirect: bool) => {}} props.toggle
 * @param {array} props.notices
 */
const NoticesList = ({ canManageNews = false, isOpen, toggle, notices = [] }) => {
    const SCROLL_BUFFER = 25;
    const containerRef = useRef();
    const [ activeNotice, setActiveNotice ] = useState(0);
    const scrollTimeout = useRef();
    const history = useHistory();

    const buildNewsId = (id) => `${NEWS_ID_PREFIX}${id}`;

    const isScrolledBottom = () => {
        return containerRef.current.scrollHeight - containerRef.current.scrollTop - SCROLL_BUFFER <= containerRef.current.clientHeight;
    }

    /**
     * Returns the nearest element to the scroll top of the container.
     * @returns {HTMLElement}
     */
    const getNearestNotice = () => {
        const toObserve = containerRef.current.querySelectorAll('[data-observe]');
        let diff;
        let element;

        toObserve.forEach((observable) => {
            const observedDiff = Math.abs(containerRef.current.scrollTop - observable.offsetTop);

            if(typeof diff !== "number" || observedDiff < diff){
                diff = observedDiff;
                element = observable;
            }
        });

        return element;
    }

    const getNearestNoticeIndex = () => {
        if(isScrolledBottom()){
            return notices.length - 1;
        }

        const nearestNews = getNearestNotice();
        const newsIndex = parseInt(nearestNews.getAttribute("data-observe"));

        if(!isNaN(newsIndex)){
            return newsIndex;
        }

    }

    const updateActiveNotice = () => {
        const nearestIndex = getNearestNoticeIndex();

        if(typeof nearestIndex === "number"){
            setActiveNotice(nearestIndex);
        }
    }

    /**
     * @param {JSX.Element} node
     * @param {object} options
     */
    const scrollTo = (options = {}) => {
        containerRef.current.scrollTo({
            top: 0,
            behavior: "smooth",
            ...options,
        });
    }

    const goTo = (nb) => {
        if(nb <= 0){
            scrollTo();
        }else if(nb >= notices.length - 1){
            scrollTo({ top: containerRef.current.scrollHeight });
        }else if(notices[nb]){
            const newsId = buildNewsId(notices[nb].news_id);
            const newsEl = containerRef.current.querySelector(`#${newsId}`);

            if(newsEl){
                scrollTo({ top: newsEl.offsetTop });
            }
        }
    }

    const navigate = (dir = 1) => {
        const nearestIndex = getNearestNoticeIndex();
        const index = typeof nearestIndex === "number" ? nearestIndex : activeNotice;
        goTo(index + dir);
    }

    const next = () => {
        navigate();
    }

    const previous = () => {
        navigate(-1);
    }

    const handleOnEdit = () => {
        history.push("/news");
        toggle(true);
    }

    const handleOnScroll = (e) => {
        clearTimeout(scrollTimeout.current);

        // Timeout will resolve when stopped scrolling
        scrollTimeout.current = setTimeout(() => {
            updateActiveNotice();
        }, 20);
    }

    useEffect(() => {
        if(isOpen){
            lockBodyScroll();
        }else{
            unlockBodyScroll();
            setActiveNotice(0);
        }

        return () => {
            clearTimeout(scrollTimeout.current);
        }
    }, [ isOpen ]);

    return (
        isOpen ?
            createPortal(
                <aside id="plateform-notice" className={styles.Container}>
                    <div
                        ref={containerRef}
                        className={styles.ContainerInner}
                        onScroll={notices.length > 1 ? handleOnScroll : void 0}
                    >
                        <header className={styles.Header}>
                            <Fade in className={styles.Btns}>
                                <Button
                                    color="light"
                                    className="mb-1 mdi mdi-close"
                                    type="button"
                                    onClick={() => toggle(false)}
                                />
                                {canManageNews &&
                                    <Button
                                        color="light"
                                        onClick={handleOnEdit}
                                        type="button"
                                        className="mb-1 fa fa-cog"
                                    />
                                }
                                {notices.length > 1 &&
                                    <>
                                        <Button
                                            disabled={activeNotice === 0}
                                            onClick={previous}
                                            color="primary"
                                            className="mb-1 mdi mdi-arrow-up"
                                            type="button"
                                        />
                                        <Button
                                            disabled={activeNotice === notices.length - 1}
                                            onClick={next}
                                            color="primary"
                                            className="mb-1 mdi mdi-arrow-down"
                                            type="button"
                                        />
                                    </>
                                }
                            </Fade>
                        </header>
                        <ul className={styles.ContainerNotices}>
                            {notices.map((notice, index) => (
                                <Notice
                                    index={index}
                                    key={notice.news_id}
                                    notice={notice}
                                    noticeNb={index}
                                    setActiveNotice={setActiveNotice}
                                />
                            ))}
                        </ul>
                    </div>
                </aside>,
                document.getElementById("root"),
            )
            :
            null
    )
}

export default NoticesList;