import { useSpordleTable } from '@spordle/datatables';
import { addExtension } from '@spordle/formik-elements';
import Translate from "@spordle/intl-elements";
import { FieldArray, Form, Formik } from "formik";
import moment from "moment";
import { useContext } from "react";
import { Collapse, ModalBody, UncontrolledAlert } from "reactstrap";
import { array, bool, number, object, string } from 'yup';
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import BtnDisplay from '../../../../../../../components/input/BtnDisplay';
import { GameIncidentsContext } from "../../../../../../../contexts/GameIncidentsContext";
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import generatePassword from "../../../../../../../helpers/passwordGenerator";
import { isPending } from '../../../gameIncidentHelper';
import StepFooter from "../../components/StepFooter";
import StepTitle from "../../components/StepTitle";
import IncidentListItem from './components/IncidentListItem';

export const incidentInit = (id) => ({
    id: id,
    description: '',
    jersey_number: '',
    member_id: '',
    suspension_id: '',
    suspension_type_id: '',
    team_id: '',
    game_period: '',
    minutes: '',
    seconds: '',
    injured: '',
    returned_to_game: '',
    memo: '',
})

export const incidentSchema = object().shape({
    description: string(),
    member_id: string().required(<Translate id='gameIncident.addModal.validation.player' />),
    jersey_number: number()
        .typeError(<Translate id="form.validation.number" />)
        .test({
            name: 'requirePositive',
            message: <Translate id="form.validation.number.positive" />,
            test: (number) => {
                const isMinusZero = (value) => (1 / value === -Infinity)
                if(number === '' || number === undefined)return true // let empty value pass
                return parseInt(number) >= 0 && !isMinusZero(number) && Math.sign(number).toString() !== "-1" // prevent -0
            },
        }),
    suspension_id: string().required(<Translate id='gameIncident.addModal.validation.penalty' />),
    suspension_type_id: string().required(<Translate id='gameIncident.addModal.validation.penaltyType' />),
    game_period: string(),
    minutes: number().max(20, <Translate id='gameIncident.addModal.validation.time.max' values={{ max: 20 }} />).min(0, <Translate id='gameIncident.addModal.validation.time.min' />),
    seconds: number().max(59, <Translate id='gameIncident.addModal.validation.time.max' values={{ max: 60 }} />).min(0, <Translate id='gameIncident.addModal.validation.time.min' />),
    injured: bool(),
    returned_to_game: bool(),
    memo: string(),
    team_id: string(),
});

const AddGameIncidentStepIncident = ({ updateGirData, girData, member, next, suspensions, suspensionTypes, statuses, setIsLoading, previous }) => {
    const { createGameIncident } = useContext(GameIncidentsContext);
    const { refreshTable } = useSpordleTable();
    const players = mergePlayers();

    /**
     * @description Merges the players of the home & away team and makes sure they aren't duplicated
     * Also filters on position_type "PLAYER"
     * @returns {object[]}
     */
    function mergePlayers(){
        const mergedPlayers = {
            home: [],
            away: [],
        };

        const isAlreadyMerged = (theMember, field) => mergedPlayers[field].some((mP) => mP.member.member_id === theMember.member.member_id);

        // Just in case there's an issue and the two teams have a duplicated member.
        // It *****probably***** won't happen, but it's a prevention
        const sortAndMergeMembers = (theMembers, field = 'home') => {
            for(let m = 0; m < theMembers.length; m++){
                const mMember = theMembers[m];
                if(!isAlreadyMerged(mMember, field)){
                    mergedPlayers[field]?.push(mMember);
                }
            }
        };

        if(!!girData.home_team?.members && girData.home_team?.existing == "1"){
            sortAndMergeMembers(girData.home_team.members);
        }

        if(!!girData.away_team?.members && girData.away_team.existing == "1"){
            sortAndMergeMembers(girData.away_team?.members, 'away');
        }

        return mergedPlayers;
    }

    return (
        <Formik
            initialValues={{
                incidents: [ incidentInit(generatePassword()) ],
            }}
            validationSchema={object().shape({
                incidents: array().of(incidentSchema)
                    .min(1, 'Add at least one incident'),
            })}
            onSubmit={async({ incidents }, { setStatus }) => {
                setIsLoading(true);
                const apiData = {};

                const formatTeam = (pos = 'home') => {
                    if(girData[pos + '_team'].existing == "1"){
                        apiData[pos + '_team_id'] = girData[pos + '_team'].team_id;
                    }else{
                        apiData[pos + '_team_name'] = girData[pos + '_team'].team_name;
                    }

                    if(girData[pos + '_team'].coach === "OTHER" || girData[pos + '_team'].existing != "1"){
                        apiData[pos + '_team_coach_name'] = girData[pos + '_team'].coach_name;
                    }else{
                        apiData[pos + '_team_coach_id'] = girData[pos + '_team'].coach;
                    }

                    if(girData[pos + '_team'].manager === "OTHER" || girData[pos + '_team'].existing != "1"){
                        apiData[pos + '_team_manager_name'] = girData[pos + '_team'].manager_name;
                    }else{
                        apiData[pos + '_team_manager_id'] = girData[pos + '_team'].manager;
                    }
                }

                // REPORT
                apiData.game_incident_date = moment(girData.game_incident_date).format('YYYY-MM-DD');
                apiData.game_incident_status_id = statuses.find(isPending)?.game_incident_status_id;
                apiData.game_number = girData.game_number;
                apiData.league_name = girData.league_name;
                apiData.official_id = girData.officials[0].member_id;
                apiData.organisation_id = girData.organisation_id;
                apiData.period_id = girData.period_id;
                apiData.team_category_id = girData.team_category_id;

                // TEAMS
                formatTeam();
                formatTeam('away');

                // PLAYERS
                for(let i = 0; i < incidents.length; i++){
                    const incident = incidents[i];
                    const getPPref = (field) => `players[${i}][${field}]`;

                    apiData[getPPref('description')] = incident.description;
                    apiData[getPPref('game_period')] = incident.game_period;
                    apiData[getPPref('injured')] = incident.injured ? '1' : '0';
                    apiData[getPPref('jersey_number')] = incident.jersey_number;
                    apiData[getPPref('member_id')] = incident.member_id;
                    apiData[getPPref('period_minutes')] = incident.minutes;
                    apiData[getPPref('period_seconds')] = incident.seconds;
                    apiData[getPPref('suspension_id')] = incident.suspension_id;
                    apiData[getPPref('suspension_type_id')] = incident.suspension_type_id;
                    apiData[getPPref('team_id')] = incident.team_id;

                    if(incident.injured){
                        apiData[getPPref('returned_to_game')] = incident.returned_to_game ? '1' : '0';
                    }
                }

                // OFFICIALS
                const officials = girData.officials;
                for(let o = 0; o < officials.length; o++){
                    const off = officials[o];
                    const getOPref = (field) => `officials[${o}][${field}]`;
                    const phone = await addExtension(off.phone_number, off.phoneExt);

                    apiData[getOPref('member_id')] = off.member_id;
                    apiData[getOPref('official_type')] = off.official_type;
                    apiData[getOPref('email')] = off.email;
                    apiData[getOPref('phone_number')] = phone || "";
                }

                createGameIncident(apiData)
                    .then((gameIncidentId) => {
                        refreshTable();
                        updateGirData({ game_incident_id: gameIncidentId });
                        setIsLoading(false);
                        next();
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            setIsLoading(false);
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <StepTitle title='gameIncident.addModal.steps.incident.title' />
                        <FieldArray
                            name="incidents"
                        >
                            {(arrayHelpers) => (
                                <>
                                    {formik.values.incidents.map((incident, index) => (
                                        <Collapse isOpen appear={index !== 0} key={incident.id}>
                                            <IncidentListItem
                                                suspensions={suspensions}
                                                suspensionTypes={suspensionTypes}
                                                index={index}
                                                helper={arrayHelpers}
                                                players={players}
                                            />
                                        </Collapse>
                                    ))}
                                    <BtnDisplay
                                        icon="tasks"
                                        iconSize={50}
                                        title={<Translate id='gameIncident.addModal.incident.title' />}
                                        subtitle={<Translate id='gameIncident.addModal.incident.btn.subtitle' />}
                                        onClick={() => arrayHelpers.push(incidentInit(generatePassword()))}
                                    />
                                </>
                            )}
                        </FieldArray>
                        {formik.status &&
                            <Collapse isOpen appear>
                                <UncontrolledAlert color="danger">
                                    <Translate id={formik.status} />
                                </UncontrolledAlert>
                            </Collapse>
                        }
                    </ModalBody>
                    <StepFooter previous={previous} submitLabel="misc.confirm" />
                </Form>
            )}
        </Formik>
    )
}

export default AddGameIncidentStepIncident;