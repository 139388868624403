import { useDisclosure } from "@mantine/hooks";
import { stringBuilder } from "@spordle/helpers";
import { Button, Collapse, CustomInput } from "reactstrap";
import UserDisplay from "../../../../components/userDisplay/UserDisplay";
import { Link } from 'react-router-dom';
import { useContext, useState } from "react";
import { ChecklistsContext } from "../../../../contexts/ChecklistsContext";
import Translate from "@spordle/intl-elements";
import { DisplayI18n, displayI18n } from "../../../../helpers/i18nHelper";
import { I18nContext } from "../../../../contexts/I18nContext";
import { FormattedDate } from "react-intl";
import { useStepDataSWR } from "../../../../components/checklist/data/useStepDataSWR";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import { RolesContext } from "../../../../contexts/RolesContext";

const ChecklistAssistantStep = ({ className, step, index, isOpen, onClick, ...props }) => {
    const checklistsContext = useContext(ChecklistsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { isGod } = useContext(IdentityRolesContext);
    const { canDoAction } = useContext(RolesContext);
    const [ isChecked, { toggle: toggleChecked } ] = useDisclosure(false);
    const [ isLoading, setIsLoading ] = useState(false);

    const item = step.checklist_item;
    const permissions = step.component_permission;
    const canManage = (!permissions || canDoAction(step.action, permissions?.component?.code, permissions?.code) || isGod());

    const completedData = checklistsContext.getCompletedData(checklistsContext.activeChecklist, step.checklist_step_id)?.[0];
    const data = useStepDataSWR(step, checklistsContext.activeChecklist, canManage);

    const isComplete = !!completedData;
    const canComplete = canManage && !isComplete && (!data || data.canComplete);
    const approbationText = displayI18n("approbation_text", item.i18n, item.approbation_text, getGenericLocale());

    const isDisabled = isLoading || data?.isDisabled;

    const complete = () => {
        setIsLoading(true);
        checklistsContext.completeStep(step)
            .then(() => {
                onClick();
                setIsLoading(false);
            });
    }

    return (
        <UserDisplay
            block
            card
            className={stringBuilder(className, { "has-loading-overlay": isLoading }, "w-100 flex-wrap p-0 text-wrap")}
            {...props}
            style={isDisabled ? { opacity: 0.5 } : void 0}
        >
            <div className="w-100 d-flex align-items-center p-2" onClick={isDisabled ? void 0 : onClick}>
                <UserDisplay.Container className="flex-shrink-0">
                    <i
                        className={
                            stringBuilder(
                                `h4 mb-0 mdi`,
                                `mdi-checkbox-${isComplete ? "marked" : "blank"}-circle-outline`,
                                `text-${isComplete || canComplete ? "success" : "muted"}`,
                            )
                        }
                    />
                </UserDisplay.Container>
                <UserDisplay.Container>
                    <UserDisplay.Title
                        className={stringBuilder({ "text-muted": isComplete })}
                        style={isComplete ? { textDecoration: "line-through" } : void 0}
                    >
                        {index + 1}.{" "}
                        <DisplayI18n
                            field="name"
                            i18n={item.i18n}
                            defaultValue={item.name}
                        />
                    </UserDisplay.Title>
                </UserDisplay.Container>
                <UserDisplay.Container className="ml-auto">
                    <i className={stringBuilder({ "rotate-180": isOpen }, "mdi mdi-chevron-down transition d-inline-block")} />
                </UserDisplay.Container>
            </div>
            <Collapse isOpen={isOpen} className="w-100">
                <div className="px-2 pb-2">
                    <div className="mb-2 border-top pt-2">
                        <b>{data?.label}</b>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: displayI18n("description", item.i18n, item.name, getGenericLocale()),
                            }}
                        />
                    </div>
                    {isComplete ?
                        <>
                            <div className="mb-2 small bg-light rounded border p-1">
                                <Translate id="checklists.approvedBy" />: {completedData.identity?.name} {completedData.identity?.family_name}<br />
                                <Translate id="checklists.date" />:{" "}
                                <FormattedDate
                                    value={completedData.created_at}
                                    hour="numeric"
                                    minute="numeric"
                                    year="numeric"
                                    day="numeric"
                                    month="numeric"
                                />
                            </div>
                            {/* <div className="border-top pt-2" />
                            <ConfirmBtn
                                onClick={markIncomplete}
                                size="sm"
                                label="By marking this as incomplete, the checklist cannot be completed."
                                color="dark"
                                block
                                type="button"
                            >
                                <Translate id="checklists.markIncomplete" values={{ b: (c) => <b>{c}</b> }} /> <i className="mdi mdi-close" />
                            </ConfirmBtn> */}
                        </>
                        :
                        canComplete ?
                            <>
                                <div className="border-top pt-2" />
                                {approbationText &&
                                    <label className="small d-flex mb-1">
                                        <CustomInput
                                            checked={isChecked}
                                            onClick={(e) => toggleChecked(e.target.checked)}
                                            onChange={() => {}}
                                            bsSize="sm"
                                            id={`optin-${step.id}`}
                                            type="checkbox"
                                            disabled={isDisabled}
                                        />
                                        {approbationText}
                                    </label>
                                }
                                <Button
                                    onClick={complete}
                                    size="sm"
                                    color="success"
                                    block
                                    disabled={(!isChecked && !!approbationText) || isDisabled}
                                >
                                    <Translate id="checklists.markComplete" values={{ b: (c) => <b>{c}</b> }} /> <i className="mdi mdi-check" />
                                </Button>
                            </>
                            :
                            !canManage &&
                                <>
                                    <div className="border-top pt-2" />
                                    <Button
                                        block
                                        disabled
                                        size="sm"
                                        color="light"
                                    >
                                        <Translate id="checklists.waitingForCompletion" />
                                    </Button>
                                    <small className="text-muted text-center d-block mt-1">
                                        <Translate id="checklists.noAccess" />
                                    </small>
                                </>
                    }
                    {canManage && item.tool_link &&
                        <Link to={item.tool_link} className="btn btn-sm btn-link btn-block pb-0 mt-0">
                            <Translate id="checklists.goToModule" /> <i className="mdi mdi-arrow-right" />
                        </Link>
                    }
                </div>
            </Collapse>
        </UserDisplay>
    );
}

export default ChecklistAssistantStep;