import { useSpordleTable } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { useState, useContext } from "react";
import { Alert, Button, Collapse, ModalBody, ModalFooter } from "reactstrap";
import CustomAnimatedIcon from "../../../../../../components/customAnimatedIcon/CustomAnimatedIcon";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { MaltreatmentOverview } from "../../maltreatmentOverview/MaltreatmentOverviewModal";
import { DiscriminationAddContext } from "../components/DiscriminationAddContext";
import { CrossFade } from "../DiscriminationAddModal";

const DiscriminationAddModalStepConfirm = ({ stepNb }) => {
    const [ isSuccess, setIsSuccess ] = useState(false);
    const [ error, setError ] = useState({
        show: false,
        msg: null,
    });
    const [ created, setCreated ] = useState([]);
    const { complaint, previous, toggle, setLoading, isLoading, submitComplaint, setComplaint, step } = useContext(DiscriminationAddContext);
    const { refreshTable } = useSpordleTable();

    const handleOnEditTeam = () => {
        if(complaint.complainees[0]?.type == "TEAM"){
            setComplaint('complainees', [ { ...(complaint.complainees?.[0] || {}), team_id: '', organisation_id: '' } ]);
        }

        setError({ show: false });
        previous();
    }

    const handleOnConfirm = () => {
        setLoading(true);

        submitComplaint()
            .then((created) => {
                setLoading(false);
                setIsSuccess(true);
                refreshTable();
                setCreated(Array.isArray(created) ? created : []);
            })
            .catch((e) => {
                console.error(e);
                const error = e.i18n ? (
                    <>
                        <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />
                        {e.code == 3699 &&
                            <button type="button" onClick={handleOnEditTeam} className="font-weight-bold text-decoration-underline mt-2 text-danger reset-btn text-center d-block w-100">
                                <Translate id="discrimination.addModal.stepConfirm.changeTeam" />
                            </button>
                        }
                    </>
                )
                    :
                    <Translate id="misc.error" />;
                setLoading(false);
                setError({ show: true, msg: error });
            })

    }

    return (
        <>
            <CrossFade isVisible={!isSuccess}>
                <ModalBody className="text-center">
                    <p><Translate id="discrimination.addModal.stepConfirm.text" /></p>
                    <div className="text-muted">
                        <Translate id='discrimination.global.itpReferenceNumber' />
                    </div>
                    #{complaint.incident.reference_number}
                    <Collapse isOpen={error.show}>
                        <Alert className="mb-0 mt-3" color="danger" toggle={() => setError((prev) => ({ ...prev, show: false }))}>
                            {error.msg}
                        </Alert>
                    </Collapse>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        color="primary"
                        className="mr-auto"
                        onClick={previous}
                        disabled={isLoading || stepNb !== step}
                        outline
                    >
                        <Translate id="misc.previous" />
                    </Button>
                    <Button
                        disabled={isLoading || stepNb !== step}
                        type="button"
                        color="primary"
                        onClick={handleOnConfirm}
                    >
                        <Translate id="misc.confirm" />
                    </Button>
                </ModalFooter>
            </CrossFade>
            <CrossFade isVisible={isSuccess}>
                <ModalBody className="text-center">
                    <CustomAnimatedIcon
                        className="text-success"
                        icon="checkmark"
                        withCircle
                        size="60"
                        strokeWidth="8px"
                    />
                    <div className="h4 font-bold mt-2"><Translate id="misc.success" />!</div>
                    <p><Translate id="discrimination.addModal.stepConfirm.success.text" /></p>

                    {stepNb === step && isSuccess &&
                        <MaltreatmentOverview toggle={toggle} referenceNumber={complaint.incident.reference_number} numberOfMembers={created.length} />
                    }
                </ModalBody>
            </CrossFade>
        </>
    );
}

export default DiscriminationAddModalStepConfirm;