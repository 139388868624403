import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';
import { PeriodsContext } from './contexts';

import * as Sentry from "@sentry/react";

/** @type {React.Context<Omit<OnlineStoreContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const OnlineStoreContext = createContext();
OnlineStoreContext.displayName = 'OnlineStoreContext';

class OnlineStoreContextProvider extends React.Component{
    state = {
        cachedStore: {},
    }

    componentDidUpdate(){
        Sentry.setContext('onlineStore', this.state.cachedStore.online_store_id ? {
            onlineStoreId: this.state.cachedStore.online_store_id,
            onlineStoreName: this.state.cachedStore.name,
            startDate: this.state.cachedStore.start_date,
            endDate: this.state.cachedStore.end_date,
            isActive: this.state.cachedStore.active,
        } : null);
        Sentry.setTag('onlineStoreId', this.state.cachedStore.online_store_id);
    }

    updateCachedStore = (newData) => {
        this.setState((prevState) => ({ cachedStore: { ...prevState.cachedStore, ...newData } }))
    }

    /**
     * Get the Online Stores within an organization
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AgetAllOnlineStore|documentation}
     * @returns {Promise.<Array>}
     */
    getOnlineStores = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/online-stores',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.online_stores;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get a specific online store
     * @param {string} onlineStoreId The online store's id
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AgetOnlineStoreDetail|documentation}
     * @param {boolean} [fromApi=false] If we want to fetch the online store from the api
     * @returns {Promise.<object>}
     */
    getOnlineStore = (onlineStoreId, queryParams = {}, fromApi = false) => {
        if(!fromApi && this.state.cachedStore.online_store_id === onlineStoreId){
            if(this.state.cachedStore.organisation.some((org) => org.organisation_id === this.props.OrganizationContext.organisation_id))
                return Promise.resolve(this.state.cachedStore);
            this.setState(() => ({
                cachedStore: {},
            }));
            return Promise.reject(new Error('Organization can\'t see this online store'));
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/online-stores/${onlineStoreId}`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    if(response.data.online_stores[0].organisation.some((org) => org.organisation_id === this.props.OrganizationContext.organisation_id)){
                        this.setState(() => ({
                            cachedStore: {
                                ...response.data.online_stores[0],
                                invoices: null, // force invoices refresh
                            },
                        }));
                        return response.data.online_stores[0];
                    }
                    this.setState(() => ({
                        cachedStore: {},
                    }));
                    throw new Error('Organization can\'t see this online store');
                }
                this.setState(() => ({
                    cachedStore: {},
                }));
                throw response.data.errors[0];
            }, serverError)

    }

    /**
     * Create an online-store
     * @param {object} [formData] The formData for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AcreateOnlineStore|documentation}
     * @returns {Promise.<string>}
     */
    createOnlineStore = (formData = {}) => {
        const data = new URLSearchParams({
            period_id: this.props.PeriodsContext.selectedPeriod.period_id,
            organisation_id: this.props.OrganizationContext.organisation_id,
        })
        for(const key in formData){
            if(Object.hasOwnProperty.call(formData, key)){
                switch (key){
                    case 'languages':
                        formData[key].forEach((lang, index) => {
                            data.append(`languages[${index}]`, lang);
                        })
                        break;
                    case 'registrationDateStart':
                        data.append('start_date', formData[key]);
                        break;
                    case 'registrationDateEnd':
                        data.append('end_date', formData[key]);
                        break;
                    case 'name':// Key where it is the same in our code and in the api
                    case 'confirmation_message_email':
                    case 'description':
                    case 'email':
                    case 'activity_period_id':
                        if(formData[key])
                            data.append(key, formData[key]);
                        break;
                    case 'skip_postal_code_validation':
                    case 'send_email_cc':
                        data.append(key, (formData[key] == '1') >>> 0);
                        break;

                    default:
                        if(key.includes('i18n'))
                            data.append(key, formData[key])
                        break;
                }
            }
        }
        return API_SPORDLE.post('/online-stores', data)
            .then((response) => {
                if(response.data.status){
                    return response.data.online_store_id;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Share an online store with another organization
     * @returns {Promise.<string>}
     */
    shareOnlineStore = (onlineStoreId, sharedOrganizations) => {
        const data = new URLSearchParams()
        sharedOrganizations.forEach((organizationId, index) => {
            data.append(`shared_organisations[${index}]`, organizationId);
        })
        return API_SPORDLE.post(`/online-stores/${onlineStoreId}/shared-organisations`, data)
            .then((response) => {
                if(response.data.status){
                    return response.data.online_store_id;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Delete a shared organization from online-store
     * @param {string} onlineStoreId The online store id
     * @param {string} onlineStoreSharedOrgId The online store id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AdeleteOnlineStore|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteOnlineStoreSharedOrg = (onlineStoreId, onlineStoreSharedOrgId) => {
        return API_SPORDLE.delete(`/online-stores/${onlineStoreId}/shared-organisations/${onlineStoreSharedOrgId}`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Update an online-store
     * @param {string} onlineStoreId The online store id
     * @param {object} [formData] The formData for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AupdateOnlineStore|documentation}
     * @returns {Promise.<boolean>}
     */
    updateOnlineStore = (onlineStoreId, formData = this.state.cachedStore) => {
        const data = new URLSearchParams({
            period_id: this.props.PeriodsContext.selectedPeriod.period_id,
            organisation_id: this.props.OrganizationContext.organisation_id,
        })
        for(const key in formData){
            if(Object.hasOwnProperty.call(formData, key)){
                switch (key){
                    case 'languages':
                        formData[key].forEach((lang, index) => {
                            data.append(`languages[${index}]`, lang);
                        })
                        break;
                    case 'registrationDateStart':
                        data.append('start_date', formData[key]);
                        break;
                    case 'registrationDateEnd':
                        data.append('end_date', formData[key]);
                        break;
                    case 'merchant_account':
                        if(formData[key]){ // merchant account can be null
                            data.append('merchant_account_id', formData[key].merchant_account_id)
                        }
                        break;
                    case 'name':// Key where it is the same in our code and in the api
                    case 'confirmation_message_email':
                    case 'description':
                    case 'activity_period_id':
                    case 'email':
                    case 'status':
                        if(formData[key])
                            data.append(key, formData[key]);
                        break;
                    case 'skip_postal_code_validation':
                    case 'send_email_cc':
                        data.append(key, (formData[key] == '1') >>> 0);
                        break;
                    default:
                        if(key.includes('i18n')){
                            data.append(key, formData[key])
                        }
                        break;
                }
            }
        }
        return API_SPORDLE.put(`/online-stores/${onlineStoreId}`, data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Update an online-store partially
     * @param {string} onlineStoreId The online store id
     * @param {object} [formData] The formData for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3ApatchOnlineStore|documentation}
     * @returns {Promise.<boolean>}
     */
    partialUpdateOnlineStore = (onlineStoreId, formData = {}) => {
        const data = new URLSearchParams()
        for(const key in formData){
            if(Object.hasOwnProperty.call(formData, key)){
                switch (key){
                    case 'languages':
                        formData[key].forEach((lang, index) => {
                            data.append(`languages[${index}]`, lang);
                        })
                        break;
                    case 'registrationDateStart':
                        data.append('start_date', formData[key]);
                        break;
                    case 'registrationDateEnd':
                        data.append('end_date', formData[key]);
                        break;
                    case 'name':// Key where it is the same in our code and in the api
                    case 'confirmation_message_email':
                    case 'description':
                    case 'email':
                    case 'status':
                    case 'display_order':
                    case 'merchant_account_id':
                    case 'skip_postal_code_validation':
                        data.append(key, formData[key]);
                        break;

                    default:
                        if(key.includes('i18n'))
                            data.append(key, formData[key])
                        break;
                }
            }
        }
        return API_SPORDLE.patch(`/online-stores/${onlineStoreId}`, data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Delete an online-store
     * @param {string} onlineStoreId The online store id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AdeleteOnlineStore|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteOnlineStore = (onlineStoreId) => {
        return API_SPORDLE.delete(`/online-stores/${onlineStoreId}`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Gets all online-store's registration fees
     * @param {string} onlineStoreId The online store id
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AgetRegistrationFee|documentation}
     * @returns {Promise.<Array>}
     */
    getOnlineStoreRegistrationFee = (onlineStoreId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/online-stores/${onlineStoreId}/registration-fees`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.registration_fees;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Links registration fees to an online-store
     * @param {string} onlineStoreId The online store id
     * @param {object[]} [registrationFees] The data for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AputRegistrationFee|documentation}
     * @returns {Promise.<boolean>}
     */
    updateOnlineStoreRegistrationFee = (onlineStoreId, registrationFees = []) => {
        const data = new URLSearchParams();
        registrationFees.forEach((registration, index) => {
            data.append(`registrations[${index}][registration_fee_id]`, registration.registration_fee_id);
            data.append(`registrations[${index}][status]`, registration.status || 'ACTIVE');
            data.append(`registrations[${index}][active]`, 1);
            data.append(`registrations[${index}][display_order]`, registration.display_order);
        })

        return API_SPORDLE.put(`/online-stores/${onlineStoreId}/registration-fees`, data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Gets all online-store's other items
     * @param {string} onlineStoreId The online store id
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AgetOtherFee|documentation}
     * @returns {Promise.<Array>}
     */
    getOnlineStoreOtherFee = (onlineStoreId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/online-stores/${onlineStoreId}/other-fees`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.other_fees;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Links other items to an online-store
     * @param {string} onlineStoreId The online store id
     * @param {object[]} [otherItems] The data for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AputOtherFee|documentation}
     * @returns {Promise.<boolean>}
     */
    updateOnlineStoreOtherFee = (onlineStoreId, otherItems = []) => {
        const data = new URLSearchParams();
        otherItems.forEach((item, index) => {
            data.append(`other_fees[${index}][other_fee_id]`, item.other_fee_id);
            data.append(`other_fees[${index}][active]`, item.active);
            data.append(`other_fees[${index}][display_order]`, item.display_order || '0');
            if(Array.isArray(item.linked_to)){
                item.linked_to.forEach((registration, registrationIndex) => {
                    // Need to force check if the mandatory value is string since it comes from api, if not we manage from our ui controls
                    const isMandatory = (registration.mandatory === "1" || registration.mandatory === "0") ? registration.mandatory === "1" : registration.mandatory;
                    data.append(`other_fees[${index}][link][${registrationIndex}][active]`, 1);
                    data.append(`other_fees[${index}][link][${registrationIndex}][mandatory]`, !!isMandatory >>> 0);
                    data.append(`other_fees[${index}][link][${registrationIndex}][registration_fee_id]`, registration.registration_fee_id);
                })
            }
        })
        return API_SPORDLE.put(`/online-stores/${onlineStoreId}/other-fees`, data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Gets all online-store's rebates
     * @param {string} onlineStoreId The online store id
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AgetRebate|documentation}
     * @returns {Promise.<Array>}
     */
    getOnlineStoreRebates = (onlineStoreId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/online-stores/${onlineStoreId}/rebates`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.rebates.map((rebate) => rebate.rebate);
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Link rebates to the online store
     * @param {string} onlineStoreId The online store id
     * @param {string[]} [rebatesIds] The rebate ids to link - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AputRebate|documentation}
     * @returns {Promise.<boolean>}
     */
    updateOnlineStoreRebates = (onlineStoreId, rebatesIds = []) => {
        const data = new URLSearchParams();
        (!Array.isArray(rebatesIds) ? [ rebatesIds ] : rebatesIds).forEach((rebateId, index) => {
            if(rebateId)
                data.append(`rebates[${index}][rebate_id]`, rebateId);
        })
        return API_SPORDLE.put(`/online-stores/${onlineStoreId}/rebates`, data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Gets all online-store's terms & conditions
     * @param {string} onlineStoreId The online store id
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AgetTermsAndConditionsLinkToOnlineStore|documentation}
     * @returns {Promise.<Array>}
     */
    getOnlineStoreTerms = (onlineStoreId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/online-stores/${onlineStoreId}/terms-and-conditions`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.terms_and_conditions.map((termCondition) => termCondition.term_and_condition);
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Link terms & conditions to the online store
     * @param {string} onlineStoreId The online store id
     * @param {string[]} [termsConditionsIds] The terms & conditions ids to link - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AlinkTermsAndConditionsToStore|documentation}
     * @returns {Promise.<boolean>}
     */
    updateOnlineStoreTerms = (onlineStoreId, termsConditionsIds = []) => {
        const data = new URLSearchParams();
        termsConditionsIds.forEach((termsConditionId, index) => {
            data.append(`terms_and_conditions[${index}]`, termsConditionId);
        })
        return API_SPORDLE.put(`/online-stores/${onlineStoreId}/terms-and-conditions`, data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Updates the payment methods of an online store
     * @param {string} onlineStoreId The online store id
     * @param {string[]} [paymentMethods] The payment methods - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3ApaymentMethods|documentation}
     * @returns {Promise.<boolean>}
     */
    updateOnlineStorePaymentMethods = (onlineStoreId, paymentMethods = []) => {
        const data = new URLSearchParams();
        paymentMethods.forEach((method, index) => {
            data.append(`payment_methods[${index}]`, method);
        })
        return API_SPORDLE.put(`/online-stores/${onlineStoreId}/payment-methods`, data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Gets all online-store's registration fees
     * @param {string} onlineStoreId The online store id
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AgetOnlineStorePurchases|documentation}
     * @returns {Promise.<Array>}
     */
    getOnlineStorePurchases = (onlineStoreId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/online-stores/${onlineStoreId}/purchases`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.invoice_items;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Gets all online-store invoices
     * @param {string} onlineStoreId The online store id
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AgetOnlineStorePurchases|documentation}
     * @returns {Promise.<Array>}
     */
    getOnlineStoreInvoices = (queryParams = {}, fromApi = false) => {
        if(!fromApi && this.state.cachedStore.online_store_id === queryParams.online_store_id && this.state.cachedStore.invoices){
            // Used cached store, no loading when we click back :)
            return Promise.resolve(this.state.cachedStore.invoices)
        }
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/invoices`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    const {
                        affiliation_fees,
                        identities,
                        organisations,
                        other_fees,
                        periods,
                        rebates,
                        registration_fees,
                        stores,
                        waivers,
                    } = response.data;

                    response.data.invoices.map((invoice) => {

                        invoice.identity = identities[invoice.identity_id];
                        invoice.initiated_by = identities[invoice.initiated_by];
                        delete invoice.identity_id;

                        invoice.online_store = stores[invoice.online_store_id];
                        delete invoice.online_store_id;

                        invoice.period = periods[invoice.period_id];
                        delete invoice.period_id;

                        invoice.invoice_items.map((invoice_item, index) => {
                            invoice_item.organisation = organisations[invoice_item.organisation_id];
                            delete invoice_item.organisation_id;

                            invoice_item.affiliation_fee = affiliation_fees[invoice_item.affiliation_fee_id] || null;
                            delete invoice_item.affiliation_fee_id;

                            invoice_item.other_fee = other_fees[invoice_item.other_fee_id] || null;
                            delete invoice_item.other_fee_id;

                            invoice_item.registration_fee = registration_fees[invoice_item.registration_fee_id] || null;
                            delete invoice_item.registration_fee_id;

                            invoice_item.rebate = rebates[invoice_item.rebate_id];
                            delete invoice_item.rebate_id;

                            invoice_item.waivers = invoice_item.waivers.map((waiver) => {
                                const waiverSubRessource = waivers[waiver.waiver_id]
                                waiverSubRessource.identity = identities[waiver.identity_id]
                                return { ...waiver, ...waiverSubRessource }
                            })

                        });
                    })


                    this.setState((prevState) => ({ cachedStore: {
                        ...prevState.cachedStore,
                        invoices: response.data.invoices,
                        hasValidInvoices: response.data.invoices.some((invoice) => invoice.status === 'PARTIALLY_PAID' || invoice.status === 'COMPLETED' || invoice.status === 'PENDING'),
                    } }))
                    return response.data.invoices;
                }
                throw response.data.errors[0];
            }, serverError);

    }

    /**
     * Get all online-store invoices
     * @param {string} invoiceNb The online store id
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Online%20Stores/Apicontroller%5CPages%5COnlinestores%3A%3AgetOnlineStorePurchases|documentation}
     * @returns {Promise.<Array>}
     */
    getInvoice = (invoiceNb, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/invoices`,
            query: {
                invoice_number: invoiceNb,
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.invoices[0];
                }
                throw response.data.errors[0];
            }, serverError);
    }

    render(){
        return (
            // Speading `this` is very important because it creates a new value object so it rerenders the Consumers
            // If we don't spead, react/javascript sees it as the same object thus, not rerendering the Consumers
            <OnlineStoreContext.Provider value={{ ...this }}>
                {this.props.children}
            </OnlineStoreContext.Provider>
        )
    }
}

export default withContexts(OrganizationContext, PeriodsContext)(OnlineStoreContextProvider);