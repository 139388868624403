import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { Alert, Button, Card, Col, Collapse, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { object, string } from 'yup';
import { MembersContext } from "../../../../../../../../contexts/MembersContext";
import { AxiosIsCancelled } from "../../../../../../../../api/CancellableAPI";
import { CrossFade } from "../../../DiscriminationAddModal";
import DiscriminationSearchResults from "../../../components/DiscriminationSearchResults";
import { DiscriminationAddContext } from "../../../components/DiscriminationAddContext";
import generatePassword from "../../../../../../../../helpers/passwordGenerator";
import { formikComplaineeInit, MEMBER_TYPE } from "../../../../DiscriminationHelper";
import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Required from "../../../../../../../../components/formik/Required";
import { stringBuilder } from "@spordle/helpers";
import { DisplayI18n } from "../../../../../../../../helpers/i18nHelper";
import { IdentityRolesContext } from "../../../../../../../../contexts/IdentityRolesContext";

const GuiltyMemberSearch = ({ nextGuiltyStep, previous }) => {
    const STEP_SEARCH = 1;
    const STEP_RESULTS = 2;

    const { isLoading, setLoading, complaint, setComplaint, fromMemberView } = useContext(DiscriminationAddContext);
    const { federation } = useContext(IdentityRolesContext);
    const { getMembers } = useContext(MembersContext);

    const [ results, setResults ] = useState(null);
    const [ searchMemberStep, setSearchMemberStep ] = useState(STEP_SEARCH);

    const addComplainee = (member) => {
        const complaineesNoTeam = complaint.complainees.filter((complainee) => complainee.type !== "TEAM");
        setComplaint('complainees', [ ...complaineesNoTeam, { ...member, team_id: '', team_name: '', belongs_to: '', temp_id: member.member_id ?? generatePassword() } ]);
        nextGuiltyStep();
    }

    return (
        <Formik
            initialStatus={{
                error: null,
                showError: false,
            }}
            initialValues={{ ...formikComplaineeInit, unique_identifier: '' }}
            validationSchema={object().shape({
                first_name: string().when('unique_identifier', {
                    is: (val) => !val,
                    then: (schema) => schema.required(<Translate id='form.validation.firstName.required' />),
                }),
                last_name: string().when('unique_identifier', {
                    is: (val) => !val,
                    then: (schema) => schema.required(<Translate id='form.validation.lastName.required' />),
                }),
                role: string().when('unique_identifier', {
                    is: (val) => !val,
                    then: (schema) => schema.required(<Translate id='discrimination.label.validation.role.required' />),
                }),
                unique_identifier: string(),
            })}
            onSubmit={(values, { setStatus }) => {
                setLoading(true);
                const apiValues = {
                    organisation_id: federation.organisation_id,
                    all: 1,
                }

                if(values.unique_identifier){
                    apiValues.unique_identifier = values.unique_identifier;
                }else{
                    apiValues.first_name = values.first_name;
                    apiValues.last_name = values.last_name;
                }

                getMembers(apiValues)
                    .then((members) => {
                        if(members.length > 0){
                            setResults(members);
                            setSearchMemberStep(STEP_RESULTS);
                            setStatus({ showError: false, error: null });
                        }else{
                            setStatus({ showError: true, error: <Translate id='discrimination.addModal.stepComplainer.title.official.error' /> });
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            setLoading(false);
                            setStatus({ showError: true, error: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} /> });
                        }
                    });
            }}
        >
            {(formik) => (
                <Form>
                    {complaint.complainees.filter((complainee) => !complainee.type || complainee.type !== "TEAM").length > 0 &&
                        <Card body className="mb-0 mx-3 mt-3 card-shadow mb-2 flex-row align-items-center">
                            <Translate
                                id="discrimination.addModal.stepGuilty.selected"
                                values={{
                                    count: complaint.complainees.filter((complainee) => !complainee.type || complainee.type !== "TEAM").length,
                                    b: (c) => <b className="mr-2 h2 mb-0">{c}</b>,
                                }}
                            />
                            <div className="text-right ml-auto">
                                <Button
                                    type="button"
                                    onClick={() => {
                                        nextGuiltyStep();
                                        formik.resetForm();
                                    }}
                                    className="px-0"
                                    color="link"
                                >
                                    <Translate id='discrimination.addModal.stepGuilty.review.btn.list' /> <i className="mdi mdi-arrow-right" />
                                </Button>
                            </div>
                        </Card>
                    }
                    {/* SEARCH RESULTS STEP */}
                    <CrossFade isVisible={searchMemberStep === STEP_RESULTS}>
                        <DiscriminationSearchResults
                            previous={() => setSearchMemberStep(STEP_SEARCH)}
                            results={results}
                            onMemberSelect={(member) => {
                                addComplainee({ ...member, role: formik.values.role });
                                setResults(null);
                                setSearchMemberStep(STEP_SEARCH);
                                formik.resetForm();
                            }}
                            next={
                                Array.isArray(complaint.complainees) && complaint.complainees.filter((complainee) => !complainee.type || complainee.type !== "TEAM").length > 0 && complaint.complainees.every((c) => (results || []).some(({ member_id }) => member_id == c.member_id)) ?
                                    () => {
                                        nextGuiltyStep();
                                        formik.resetForm();
                                    }
                                    :
                                    void 0
                            }
                            subtitle={<Translate id='discrimination.addModal.matchingProfiles.step3.subtitle' values={{ count: results?.length || 0 }} />}
                            isDisabled={(member) => complaint.complainees.some((c) => c.member_id === member.member_id)}
                        />
                    </CrossFade>

                    {/* COMPLAINEE SEARCH FORM STEP */}
                    <CrossFade isVisible={searchMemberStep === 1}>
                        <ModalBody>
                            <div className={stringBuilder({ "card card-shadow p-3 mb-2": !fromMemberView })}>
                                <div className="font-bold h5 mb-2">
                                    <Translate id='discrimination.addModal.stepGuilty.title' />
                                </div>
                                <p>
                                    <Translate id='discrimination.addModal.stepGuilty.subtitle' />
                                </p>
                                <Row form>
                                    <Col className="form-group" sm="6">
                                        <Label className="text-muted" for="first_name"><Translate id="form.fields.firstName" /> {!formik.values.unique_identifier && <Required />}</Label>
                                        <FormikInputText trim name="first_name" />
                                    </Col>
                                    <Col className="form-group" sm="6">
                                        <Label className="text-muted" for="last_name"><Translate id="form.fields.lastName" /> {!formik.values.unique_identifier && <Required />}</Label>
                                        <FormikInputText trim name="last_name" />
                                    </Col>
                                    <Col className="form-group" sm="6">
                                        <Label className="text-muted" for="role"><Translate id='discrimination.label.role' /> {!formik.values.unique_identifier && <Required />}</Label>
                                        <FormikSelect
                                            key={searchMemberStep !== 3} // temp & quick hack to reset select
                                            id="role"
                                            name="role"
                                            search={false}
                                            defaultData={MEMBER_TYPE.reduce((keptTypes, type) => {
                                                if(type !== "EMPLOYEE"){
                                                    keptTypes.push({
                                                        label: 'discrimination.global.roles.' + type,
                                                        value: type,
                                                        translateLabel: true,
                                                    })
                                                }
                                                return keptTypes;
                                            }, [])}
                                            loadingStatus='success'
                                        />
                                    </Col>
                                    <Col className="form-group" sm="12">
                                        <div className="d-flex align-items-center mb-3"><div className="border-top flex-grow-1" /><div className="px-3 font-medium"><Translate id="members.search.memberAdvanceSearch.filters.or" /></div><div className="border-top flex-grow-1" /></div>
                                        <Label className="text-muted" for="guilty-unique_identifier"><Translate id="components.validateMember.uniqueIdentifier" /> {(!formik.values.role && !formik.values.first_name && !formik.values.last_name) && <Required />}</Label>
                                        <FormikInputText id='guilty-unique_identifier' name="unique_identifier" />
                                    </Col>
                                </Row>
                            </div>
                            <Collapse isOpen={formik.status.showError}>
                                <Alert className="mb-0" color="danger" toggle={() => formik.setStatus({ ...formik.status, showError: false })}>
                                    {formik.status.error}
                                </Alert>
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button disabled={isLoading} type="button" onClick={previous} outline color="primary" className="mr-auto"><Translate id="misc.previous" /></Button>
                            <Button disabled={isLoading} type="submit" color="primary"><Translate id="misc.next" /></Button>
                        </ModalFooter>
                    </CrossFade>
                </Form>
            )}
        </Formik>
    );
}

export default GuiltyMemberSearch;