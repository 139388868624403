export const getTaxRateUnit = (type) => {
    switch (type){
        case "PERCENTAGE":
            return "%";
        case "AMOUNT":
            return "$";
        default:
            return "";
    }
}

export const TAX_RATE_SPLITTER = `_____`;

/**
 * Name is not unique, but (name + province code) is
 * */
export const buildTaxRateId = (taxRate) => `${taxRate.name}${TAX_RATE_SPLITTER}${taxRate.province.code}`;