import Translate from '@spordle/intl-elements';
import { useState, useContext } from 'react';
import {
    Card,
    CardBody,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledButtonDropdown,
    UncontrolledDropdown
} from "reactstrap";
import SpordlePanelTable from '../../../components/sidePanel/SpordlePanel';
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import InstallmentSidepanel from './components/sidepanel/InstallmentSidepanel';
import InstallmentAdd from './components/InstallmentAdd';

// Contexts
import I18nHelperContextProvider from '../../../helpers/i18nHelper';
import { InstallmentModesContext } from '../../../contexts/InstallmentModesContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import EmptyLayout from '../../../components/table/EmptyLayout';
import CanDoAction from '../../../components/permissions/CanDoAction';
import { PeriodsContext } from '../../../contexts/contexts';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import { useDisclosure } from '@mantine/hooks';
import RolloverInstallmentModal from './components/rolloverModal/RolloverInstallmentsModal';

const Installment = () => {
    const [ addModal, setAddModal ] = useState(false);
    const toggleAddModal = () => setAddModal(!addModal);

    const [ isOpen, { toggle } ] = useDisclosure(false)

    const installmentsContext = useContext(InstallmentModesContext);
    const orgContext = useContext(OrganizationContext);
    const periodsContext = useContext(PeriodsContext);

    return (
        <I18nHelperContextProvider fields={'title'}>
            {/* Catalog View Header */}
            <ViewHeaderV2 title='catalog.installment.title' />

            <div className={"px-4"}>
                <Card className="card-shadow">
                    <CardBody>
                        <SpordlePanelTable
                            allowOutsideClick
                            dataIndex='installment_mode_id'
                            sidePanel={(props) => <InstallmentSidepanel {...props} />}
                            table={(panelProps) => {
                                return (
                                    <SpordleTableProvider
                                        id='CatalogInstallmentTable'
                                        dataIndex='installment_mode_id'
                                        tableHover bordered striped
                                        clickable
                                        ref={panelProps.spordleTableRef}
                                        desktopWhen
                                        pagination={20}
                                        emptyLayout={<EmptyLayout />}
                                        tableClassName={(panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined)}
                                        searchKeys={[
                                            'name',
                                            'initial_installment_percentage',
                                            'installment_options.installment_option_percentage',
                                            'installment_options.installment_option_percentage_date',
                                        ]}
                                        // {'installment_options.*' : [
                                        //     'installment_option_percentage',
                                        //     'installment_option_percentage_date',
                                        // ]}
                                        loadData={(from, _, spordleTable) => {
                                            switch (from){
                                                case 'REFRESH':
                                                    spordleTable.setLoading();
                                                case 'CDM':
                                                    return installmentsContext.getInstallmentModes(orgContext.organisation_id, {
                                                        period_id: periodsContext.selectedPeriod.period_id,
                                                    });
                                                default:
                                                    break;
                                            }
                                        }}
                                        columns={[
                                            {
                                                label: <Translate id='form.fields.title' />,
                                                key: 'name',
                                                sortable: true,
                                            },
                                            {
                                                label: <Translate id='catalog.installment.table.initialPayment' />,
                                                key: 'initialPayment',
                                                sortable: true,
                                            },
                                            {
                                                label: <Translate id='catalog.installment.form.installments' />,
                                                key: 'installment',
                                                sortable: true,
                                            },
                                            {
                                                label: <Translate id='misc.status' />,
                                                key: 'isActive',
                                                dataClassName: 'text-center',
                                                sortable: true,
                                            },
                                        ]}
                                        renderRow={(columnKey, data) => {
                                            switch (columnKey){
                                                case 'installment':
                                                    return (
                                                        <ul className="list-unstyled d-table mb-0">
                                                            {data.installment_options.map((opt, i) => (
                                                                <li key={opt.installment_option_id || data.installment_mode_id + opt.installment_option_percentage_date} className="d-table-row">
                                                                    <div className="d-table-cell px-1 text-nowrap">- <span className="font-medium">{opt.installment_option_percentage}%</span></div>
                                                                    <div className="d-table-cell px-1 text-nowrap"><Translate id='catalog.installment.rule.on' /></div>
                                                                    <div className="d-table-cell px-1 text-nowrap">{opt.installment_option_percentage_date}</div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )

                                                case 'initialPayment':
                                                    return (
                                                        <div><span className="font-medium">{data.initial_installment_percentage}%</span> <Translate id='catalog.installment.table.initialPayment.text' /></div>
                                                    )
                                                case 'isActive':
                                                    return data.active === "1" ? <i className="text-primary mdi mdi-check" /> : <i className="text-danger mdi mdi-close" />;
                                                default:
                                                    break;
                                            }
                                        }}
                                        onColumnClick={(e, data) => {
                                            switch (e.button){
                                                case 0: // Left mouse button
                                                    panelProps.onSingleSelectChange(data);
                                                    break;
                                            }
                                        }}
                                        rowIsHighlighted={(data) => data.checked}
                                    >
                                        {(spordleTable) => (
                                            <>
                                                <div className='mb-2'>
                                                    <div className='d-flex flex-wrap justify-content-between'>
                                                        <SpordleTableProvider.SearchInput />
                                                        <div className='d-flex ml-auto text-right'>
                                                            <UncontrolledButtonDropdown className='ml-2'>
                                                                <DropdownToggle color='primary' outline caret disabled>
                                                                    <i className='mdi mdi-download mr-1' /><Translate id='misc.export' />
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem><i className='fas fa-file-excel' /> <Translate id='misc.excel' /></DropdownItem>
                                                                    <DropdownItem><i className='fas fa-file-pdf' /> <Translate id='misc.pdf' /></DropdownItem>
                                                                    <DropdownItem><i className='fas fa-print' /> <Translate id='misc.print' /></DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown>
                                                            {/* <CanDoAction action='ADD' componentCode='catalog' componentPermissionCode='installment_mode'>
                                                                <InstallmentAdd isOpen={addModal} toggle={toggleAddModal} />
                                                                <Button className='ml-2' color='primary' onClick={toggleAddModal}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                            </CanDoAction> */}
                                                            <CanDoAction action='ADD' componentCode='catalog' componentPermissionCode='installment_mode'>
                                                                <UncontrolledDropdown className="ml-2">
                                                                    <InstallmentAdd isOpen={addModal} toggle={toggleAddModal} />
                                                                    <RolloverInstallmentModal
                                                                        isOpen={isOpen}
                                                                        toggle={toggle}
                                                                        currentSeasonInstallments={spordleTable.getData()}
                                                                        refreshTable={spordleTable.refreshTable}
                                                                    />

                                                                    <DropdownToggle caret color='primary'>
                                                                        <i className='mdi mdi-plus mr-1' />
                                                                        <Translate id='misc.add' />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu right>
                                                                        <DropdownItem onClick={toggleAddModal}><Translate id="misc.add" /></DropdownItem>
                                                                        <DropdownItem onClick={toggle}><Translate id="catalog.installment.rollover.dropdown.button" /></DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </CanDoAction>
                                                        </div>
                                                    </div>
                                                </div>
                                                <SpordleTableView />
                                            </>
                                        )}
                                    </SpordleTableProvider>
                                )
                            }}
                        />
                    </CardBody>
                </Card>
            </div>
        </I18nHelperContextProvider>
    );
}

export default Installment;