import Translate from '@spordle/intl-elements';
import { Col, Row } from 'reactstrap';
import { I18nHelperContext } from '../../../../../../helpers/i18nHelper';
import OrgSettingCardPreview from '../../OrgSettingCardPreview';
import { useContext, Fragment } from "react";
import { stringBuilder } from '@spordle/helpers';
import { Skeleton } from '@mantine/core';
import { CLINIC_CONFIRM_SETTING, REGISTRATION_CONFIRM_SETTING } from './MessagesSettingsConfirmation';

const ConfirmationMsgView = ({ registrationMsg, isLoading, clinicMsg }) => {
    const { state } = useContext(I18nHelperContext);

    return (
        <Row form>
            <Col sm='6' className='mb-3'>
                <OrgSettingCardPreview
                    title={<Translate id="organization.settings.registrationSettings.setting.name" />}
                    desc={<Translate id="organization.settings.registrationSettings.setting.desc" />}
                    code={REGISTRATION_CONFIRM_SETTING}
                >
                    {(isLoading) ?
                        <div>
                            {state.languages.map((lang, i) => (
                                <Fragment key={lang}>
                                    {state.languages.length > 1 &&
                                        <div className={stringBuilder("text-muted text-uppercase small", { "mt-3": i > 0 })}>
                                            <Translate id={`misc.${lang}.long`} />
                                        </div>
                                    }
                                    <Skeleton className="mb-1" height="1em" width="100%" />
                                    <Skeleton className="mb-1" height="1em" width="100%" />
                                    <Skeleton className="mb-1" height="1em" width="100%" />
                                    <Skeleton height="1em" width="80%" />
                                </Fragment>
                            ))}
                        </div>
                        :
                        registrationMsg ?
                            <div>
                                {state.languages.map((lang, i) => (
                                    <Fragment key={lang}>
                                        {state.languages.length > 1 && <div className={stringBuilder("text-muted text-uppercase small", { "mt-3": i > 0 })}><Translate id={`misc.${lang}.long`} /></div>}
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: registrationMsg.i18n?.[lang]?.body,
                                            }}
                                        />
                                    </Fragment>
                                ))}
                            </div>
                            :
                            <Translate id='organization.settings.registrationSettings.setting.no.selected' />
                    }
                </OrgSettingCardPreview>
            </Col>
            <Col sm='6' className='mb-3'>
                <OrgSettingCardPreview
                    title={<Translate id='organization.settings.registrationSettings.clinic.setting.name' />}
                    desc={<Translate id="organization.settings.registrationSettings.clinic.setting.desc" />}
                    code={CLINIC_CONFIRM_SETTING}
                >
                    {isLoading ?
                        <div>
                            {state.languages.map((lang, i) => (
                                <Fragment key={lang}>
                                    {state.languages.length > 1 &&
                                        <div className={stringBuilder("text-muted text-uppercase small", { "mt-3": i > 0 })}>
                                            <Translate id={`misc.${lang}.long`} />
                                        </div>
                                    }
                                    <Skeleton className="mb-1" height="1em" width="100%" />
                                    <Skeleton className="mb-1" height="1em" width="100%" />
                                    <Skeleton className="mb-1" height="1em" width="100%" />
                                    <Skeleton height="1em" width="70%" />
                                </Fragment>
                            ))}
                        </div>
                        :
                        clinicMsg ?
                            <div>
                                {state.languages.map((lang, i) => (
                                    <Fragment key={lang}>
                                        {state.languages.length > 1 && <div className={stringBuilder("text-muted text-uppercase small", { "mt-3": i > 0 })}><Translate id={`misc.${lang}.long`} /></div>}
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: clinicMsg.i18n?.[lang]?.body,
                                            }}
                                        />
                                    </Fragment>
                                ))}
                            </div>
                            :
                            <Translate id='organization.settings.registrationSettings.setting.no.selected' />
                    }
                </OrgSettingCardPreview>
            </Col>
        </Row>
    );
}

export default ConfirmationMsgView;