export default{
    "members.profile.overview.memberMemos.title" : "Notes",

    // table
    "members.profile.overview.memberMemos.table.hint.none1" : "Vous pouvez ajouter une note",
    "members.profile.overview.memberMemos.table.hint.none2" : "Ajouter une note",
    "members.profile.overview.memberMemos.table.noData"     : "Aucune note",
    "members.profile.overview.memberMemos.createdBy"        : "Créée par",


    // add
    "members.profile.overview.memberMemos.add.title" : "Ajouter une note",

    // side panel
    "members.profile.overview.memberMemos.remove"              : "Retirer",
    "members.profile.overview.memberMemos.remove.swal.success" : "Note retirée!",
    "members.profile.overview.memberMemos.update.name.success" : "Nom mis à jour!",
    "members.profile.overview.memberMemos.update.desc.success" : "Description mise à jour!",
}