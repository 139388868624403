import { stringifyUrl } from "query-string"
import API_SPORDLE from "../API-Spordle"
import { serverError } from "../CancellableAPI"
import { jsObjectToApi } from "@spordle/helpers"

export const getMemberMemberTypeRemovalRequests = (memberId: string, query: Record<string, any> = {}) => {
    return API_SPORDLE.get(stringifyUrl({
        url: `/members/${memberId}/member-type-removal-requests`,
        query: query
    }))
        .then((response) => {
            if(response.data.status){
                // Lite format
                const member_type_removal_requests = response.data.member_type_removal_requests || [];
                const members: Record<string, Record<string, any>> = response.data.members || {};
                const member_types: Record<string, Record<string, any>> = response.data.member_types || {};
                const organisations: Record<string, Record<string, any>> = response.data.organisations || {};
                const periods: Record<string, Record<string, any>> = response.data.periods || {};
                const request_change_statuses: Record<string, Record<string, any>> = response.data.request_change_statuses || {};
                const workflows: Record<string, Record<string, any>> = response.data.workflows || {};
                const identities: Record<string, Record<string, any>> = response.data.identities || {};

                // Build resources
                const fullMemberTypeRemovalRequests = member_type_removal_requests.map((removal) => {
                    if(members[removal.member_id]){
                        removal.member = members[removal.member_id];
                        removal.member.member_id = removal.member_id;
                    }

                    if(member_types[removal.member_type_id]){
                        removal.member_type = member_types[removal.member_type_id];
                        removal.member_type.member_type_id = removal.member_type_id;
                    }

                    if(organisations[removal.organisation_id]){
                        removal.organisation = organisations[removal.organisation_id];
                        removal.organisation.organisation_id = removal.organisation_id;
                    }

                    if(periods[removal.period_id]){
                        removal.period = periods[removal.period_id];
                        removal.period.period_id = removal.period_id;
                    }

                    if(request_change_statuses[removal.request_change_status_id]){
                        removal.request_change_status = request_change_statuses[removal.request_change_status_id];
                        removal.request_change_status.request_change_status_id = removal.request_change_status_id;
                    }

                    if(workflows[removal.workflow_id]){
                        removal.workflow = workflows[removal.workflow_id];
                        removal.workflow.workflow_id = removal.workflow_id;
                    }

                    if(identities[removal.created_by]){
                        const identityId = removal.created_by
                        removal.created_by = identities[identityId];
                        removal.created_by.identity_id = identityId;
                    }

                    // Cleanup resource IDs

                    // not deleting member_id because we group the rows by member_id in the sidepanel, we avoid a map this way
                    // delete removal.member_id;
                    delete removal.member_type_id;
                    delete removal.organisation_id;
                    delete removal.period_id;
                    delete removal.request_change_status_id;
                    delete removal.workflow_id;

                    return removal;
                });

                return fullMemberTypeRemovalRequests;
            }
            throw response.data.errors
        }, serverError)
}

export const getMemberTypeRemovalRequests = (orgId: string, query: Record<string, any> = {}) => {
    return API_SPORDLE.get(stringifyUrl({
        url: `/organisations/${orgId}/member-type-removal-requests`,
        query: query
    }))
        .then((response) => {
            if(response.data.status){
                // Lite format
                const member_type_removal_requests = response.data.member_type_removal_requests || [];
                const members: Record<string, Record<string, any>> = response.data.members || {};
                const member_types: Record<string, Record<string, any>> = response.data.member_types || {};
                const organisations: Record<string, Record<string, any>> = response.data.organisations || {};
                const periods: Record<string, Record<string, any>> = response.data.periods || {};
                const request_change_statuses: Record<string, Record<string, any>> = response.data.request_change_statuses || {};
                const workflows: Record<string, Record<string, any>> = response.data.workflows || {};
                const identities: Record<string, Record<string, any>> = response.data.identities || {};

                // Build resources
                const fullMemberTypeRemovalRequests = member_type_removal_requests.map((removal) => {
                    if(members[removal.member_id]){
                        removal.member = members[removal.member_id];
                        removal.member.member_id = removal.member_id;
                    }

                    if(member_types[removal.member_type_id]){
                        removal.member_type = member_types[removal.member_type_id];
                        removal.member_type.member_type_id = removal.member_type_id;
                    }

                    if(organisations[removal.organisation_id]){
                        removal.organisation = organisations[removal.organisation_id];
                        removal.organisation.organisation_id = removal.organisation_id;
                    }

                    if(periods[removal.period_id]){
                        removal.period = periods[removal.period_id];
                        removal.period.period_id = removal.period_id;
                    }

                    if(request_change_statuses[removal.request_change_status_id]){
                        removal.request_change_status = request_change_statuses[removal.request_change_status_id];
                        removal.request_change_status.request_change_status_id = removal.request_change_status_id;
                    }

                    if(workflows[removal.workflow_id]){
                        removal.workflow = workflows[removal.workflow_id];
                        removal.workflow.workflow_id = removal.workflow_id;
                    }

                    if(identities[removal.created_by]){
                        const identityId = removal.created_by
                        removal.created_by = identities[identityId];
                        removal.created_by.identity_id = identityId;
                    }

                    // Cleanup resource IDs

                    // not deleting member_id because we group the rows by member_id in the sidepanel, we avoid a map this way
                    // delete removal.member_id;
                    delete removal.member_type_id;
                    delete removal.organisation_id;
                    delete removal.period_id;
                    delete removal.request_change_status_id;
                    delete removal.workflow_id;

                    return removal;
                });

                return fullMemberTypeRemovalRequests;
            }
            throw response.data.errors
        }, serverError)
}

export const updateMemberTypeRemovalRequest = (memberRemovalRequestId, values) => {
    const params = new URLSearchParams();
    jsObjectToApi(values, params)

    return API_SPORDLE.put(stringifyUrl({
        url: `/member-type-removal-requests/${memberRemovalRequestId}`,
    }), params)
        .then((response) => {
            if(response.data.status){
                return true
            }
            throw response.data.errors
        }, serverError)
}