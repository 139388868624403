import { serverError } from "../CancellableAPI";
import queryString from "query-string";
import { jsObjectToApi } from "@spordle/helpers";
import API_SPORDLE from "../API-Spordle";
/**
 * Create a permanent release request
 * @param {string} memberId
 * @param {Array} data_to_change
 * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Suspension%20Attachments/Apicontroller%5CMembers%5CMembersuspensionattachments%3A%3AcreateMemberSuspensionAttachments|documentation}
 * @returns {Promise.<Array>}
 */
export function createPermanentReleaseRequest(data) {
    const params = new URLSearchParams();
    jsObjectToApi(data, params);
    return API_SPORDLE.post(queryString.stringifyUrl({ url: `/permanent-releases` }), params)
        .then((response) => {
            if (response.data.status) {
                return response.data.permanent_release_request_id;
            }
            throw response.data.errors[0];
        }, serverError)
}

/**
 * gets permanent releases
 * @param {organisation_id}organisation_id
 * @param {request_change_status_id}request_change_status_id
 * @param {identity_id}identity_id
 * @param {period_id}period_id
 * @param {need_approbation}need_approbation
 * @param {member_id}member_id
 * @returns {Promise<string>}
 */
export function getPermanentRelease(queryParams) {
    return API_SPORDLE.get(queryString.stringifyUrl({
        url: `/permanent-releases`,
        query: queryParams,
    }))
        .then((response) => {
            if (response.data.status) {
                return response.data.permanent_release_requests
                    .map((PRR) => ({
                        ...PRR,
                        created_by: {
                            ...response.data.identities[PRR.created_by],
                            identity_id: PRR.created_by,
                        },
                        member: response.data.members[PRR.member_id],
                        organisation: response.data.organisations[PRR.organisation_id],
                        period: response.data.periods[PRR.period_id],
                        request_change_status: response.data.request_change_status[PRR.request_change_status_id],
                        workflows: response.data.workflows[PRR.workflow_id],
                    }));
            }
            throw response.data.errors[0];
        }, serverError)
}

/**
 * gets permanent releases
 * @param {organisation_id}organisation_id
 * @param {permanent_release_id}permanent_release_id
 * @returns {Promise<string>}
 */
export function updatePermanentReleaseRequestChangeStatus(permanent_release_id, values) {
    const params = new URLSearchParams()
    jsObjectToApi(values, params)

    return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/permanent-releases/${permanent_release_id }` }), params)
        .then((response) => {
            if (response.data.status) {
                return true
            }
            throw response.data.errors[0];
        }, serverError)
}