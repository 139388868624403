import Translate from '@spordle/intl-elements';
import { useLayoutEffect, useState } from 'react';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal'
import { AxiosCancelAll } from '../../../../../api/CancellableAPI';
import { APPROVED, PENDING, REJECTED, CANCEL, DRAFT } from '../../../TeamHelpers';
import AllTeams from './AllTeams';
import ValidTeams from './ValidTeams';

/**
 * @param {{ action: 'PENDING'|'APPROVED'|'REJECTED'|'CANCEL', teams: any[], isOpen: boolean, toggle: function, toggleAfterSuccess: function }} param
 * @returns
 */
const TeamBulkUpdateModal = ({ action, teams, isOpen, toggle, toggleAfterSuccess, ...props }) => {
    const views = {
        allTeams: 'allTeams',
        validTeams: 'validTeams',
    }
    const [ view, setView ] = useState(views.allTeams);


    const { validTeams, invalidTeams } = teams.reduce((sortedTeams, team) => {
        const formatPush = (array, team) => {
            array.push({
                team: team, // team object
                isLoading: false, // used for the loading spinner when making the API call
                status: null, // used to display the status after the API call
            })
        }

        switch (action){
            case PENDING:
                if(team.team_status?.name?.toUpperCase().includes(DRAFT)){
                    formatPush(sortedTeams.validTeams, team)
                }else{
                    formatPush(sortedTeams.invalidTeams, team)
                }
                break;
            case APPROVED:
                if(team.team_status?.name?.toUpperCase().includes(PENDING)){ // Team can only be approved if the current status is pending for approval
                    formatPush(sortedTeams.validTeams, team)
                }else{
                    formatPush(sortedTeams.invalidTeams, team)
                }
                break;
            case REJECTED:
                if(team.team_status?.name?.toUpperCase().includes(PENDING) || team.team_status?.name?.toUpperCase().includes(APPROVED)){ // Team can be rejected only if the current status if pending or approved
                    formatPush(sortedTeams.validTeams, team)
                }else{
                    formatPush(sortedTeams.invalidTeams, team)
                }
                break;
            case CANCEL:
                if(team.team_status?.name){ // Team with any status can be canceled
                    formatPush(sortedTeams.validTeams, team)
                }else{
                    formatPush(sortedTeams.invalidTeams, team)
                }
                break;
            default:
                formatPush(sortedTeams.invalidTeams, team)
                break;
        }
        return sortedTeams;
    }, {
        validTeams: [],
        invalidTeams: [],
    });

    const getView = () => {
        switch (view){
            case views.allTeams:
                return (
                    <AllTeams
                        toggle={toggle}
                        views={views}
                        setView={setView}
                        validTeams={validTeams}
                        invalidTeams={invalidTeams}
                        action={action}
                        getHeaderText={getHeaderText}
                    />
                )
            case views.validTeams:
                return (
                    <ValidTeams
                        toggle={toggle}
                        views={views}
                        setView={setView}
                        validTeams={validTeams}
                        action={action}
                        toggleAfterSuccess={toggleAfterSuccess}
                        getHeaderText={getHeaderText}
                    />
                )
            default:
                return null
        }
    }

    const getHeaderText = () => {
        let actionText = null;
        switch (action){
            case 'APPROVED':
                actionText = <Translate id='teams.teamSearch.sideTable.actionsMenu.approve' />
                break;
            case 'CANCEL':
                actionText = <Translate id='teams.teamSearch.sideTable.actionsMenu.cancel' />
                break;
            case 'PENDING':
                actionText = <Translate id='teams.teamSearch.sideTable.actionsMenu.submit' />
                break;
            case 'REJECTED':
                actionText = <Translate id='teams.teamSearch.sideTable.actionsMenu.reject' />
                break;
        }

        return (
            <>
                <Translate id='teams.teamSearch.sideTable.bulkUpdate' /> - {actionText}
            </>
        )
    }

    useLayoutEffect(() => AxiosCancelAll(), [])

    return (
        <AnalyticsModal isOpen={isOpen} toggle={toggle} analyticsName='TeamBulkUpdateModal' onClosed={() => { setView(views.allTeams); }}>
            {getView()}
        </AnalyticsModal>
    )
}

export default TeamBulkUpdateModal
