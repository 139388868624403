import { FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { Button, Collapse, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { array, object } from 'yup';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import CustomAlert from '../../../../../components/CustomAlert';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import IsAdmin from '../../../../../components/permissions/IsAdmin';
import { RolesContext } from '../../../../../contexts/RolesContext';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';

const CloneToOtherRoles = ({
    componentPermission,
    previous,
    rolesList,
    roleId,
    mainPermission,
    ...props
}) => {
    const roleContext = useContext(RolesContext);

    const formatValuesForApi = (component) => {
        return component?.component_permissions
            .map((cp) => {
                // Is NOT the permission we want to bulk update -> Simply format the values to be compatible with the PUT call
                const currentRoleCP = mainPermission.component_permissions.find((mainCp) => mainCp.code === cp.code);
                const mappedValues = currentRoleCP.role_component_permissions.map((currentRCP) => {
                    const rcp = cp.role_component_permissions.find((cRCP) => cRCP.action === currentRCP.action);
                    return {
                        action: currentRCP.action,
                        role_component_permission_id: rcp?.role_component_permission_id,
                        active: currentRCP.active == '1',
                    }
                });
                mappedValues.pushArray(
                    cp.role_component_permissions.reduce((missingRCPs, rcp) => {
                        const isMissing = !mappedValues.some((v) => v.action === rcp.action);
                        if(isMissing){
                            missingRCPs.push({
                                action: rcp.action,
                                role_component_permission_id: rcp.role_component_permission_id,
                                active: false, // Always false here since it isMissing from the select
                            })
                        }
                        return missingRCPs;
                    }, []),
                );
                return {
                    key: cp.component_permission_id,
                    value: mappedValues,
                };
            }) || [];
    }

    const updateOtherRoleId = async(roleId) => {
        const { components } = await roleContext.getRolesPermissions(roleId);
        const formattedValues = formatValuesForApi(components.find((component) => component.component_id === mainPermission.component_id));
        return roleContext.updateComponentsPermissions(roleId, formattedValues);
    }

    return (
        <Formik
            initialValues={{
                otherRoles: [],
            }}
            validationSchema={object().shape({
                otherRoles: array().min(1, <Translate id='form.required' />),
            })}
            onSubmit={async({ otherRoles }, { setStatus }) => {
                setStatus();
                return Promise.all(otherRoles.map(updateOtherRoleId))
                    .then(props.updateComponents)
                    .then(props.toggle)
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message);
                            setStatus(<DisplayI18n field="message" defaultValue={error.message} i18n={error.i18n} />);
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={props.toggle}>
                            <Translate id='organization.settings.roles.modals.clone.title' />
                        </ModalHeader>
                        <ModalBody>
                            <div className="h5 font-bold">{componentPermission.title}</div>
                            <IsAdmin className='small text-monospace'>
                                code: {componentPermission.code}
                            </IsAdmin>
                            <div className="mb-3"><Translate id='organization.settings.roles.modals.clone.description' values={{ roleName: componentPermission.title }} /></div>
                            <Label className="text-muted" for="otherRoles"><Translate id='organization.settings.roles.modals.applyPermission.label.otherRoles' /></Label>
                            <FormikSelect
                                id="otherRoles"
                                name="otherRoles"
                                autoFocus
                                renderOption={({ option, isDisabled }) => (
                                    <>
                                        {option.label}
                                        {isDisabled &&
                                            <div className="text-muted small"><Translate id='organization.settings.roles.modals.clone.otherRoles.disabled' /></div>
                                        }
                                    </>
                                )}
                                clearable
                                multi
                                isOptionDisabled={({ value }) => (roleId || "").includes(value)}
                                loadData={(from) => {
                                    switch (from){
                                        case 'CDM':
                                            return Promise.resolve(
                                                rolesList ?
                                                    rolesList.map((role) => (
                                                        {
                                                            ...role,
                                                            value: role.role_id,
                                                            label: role.title,
                                                        }
                                                    )) : [],
                                            );
                                        default:
                                            break;
                                    }
                                }}
                            />

                            <div className="bg-light mt-3 p-2 rounded d-flex">
                                <div className="d-flex align-items-center mr-1">
                                    <i className="mr-1 mdi mdi-information-outline text-primary" />
                                </div>
                                <div className="font-medium text-dark">
                                    <p><Translate id='organization.settings.roles.modals.clone.helperText' values={{ b: (c) => <span className='font-bold'>{c}</span> }} /></p>
                                    <p className='mb-0'>
                                        <Translate id='organization.settings.roles.modals.clone.helperText2' />
                                    </p>
                                </div>
                            </div>
                            <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                                <CustomAlert
                                    color="danger"
                                    className="mt-3"
                                    text={formik.status || <Translate id='misc.error' />}
                                    translateText={false}
                                    toggle={() => { formik.setStatus() }}
                                    withTitle
                                />
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={previous} type="button" outline color="primary" className="mr-auto">
                                <Translate id="misc.previous" />
                            </Button>
                            <Button type="submit" color="primary">
                                <Translate id='organization.settings.roles.modals.clone.action.clone' />
                            </Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default CloneToOtherRoles
