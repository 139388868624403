import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button } from 'reactstrap';
import styles from "./FileViewer.module.scss";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFFile = ({ image, pageCount, fileLoading, errorView, onDocumentLoadSuccess, onPreviewFail, containerRef, reactPinchPanZoom, resetScalePoints }) => {

    useEffect(() => reactPinchPanZoom.centerView(undefined, 0), []);

    return (
        <Document
            file={image}
            onLoadSuccess={onDocumentLoadSuccess}
            renderMode="canvas"// Performant but somewhat blurrier than svg -> User can download to have full quality
            externalLinkTarget="_blank"// Links in pdf must not bail out of our website
            loading={fileLoading}
            onLoadError={() => {
                reactPinchPanZoom.setTransform(0, 0, reactPinchPanZoom.state.scale, 0);
                onPreviewFail();
            }}
            error={errorView}
            noData={<NoPdfPreview close={close} reactPinchPanZoom={reactPinchPanZoom} />}
        >
            {Array.from({ length: pageCount }, (_, index) => index + 1)
                .map((pageNumber) => (
                    <Page
                        key={pageNumber}
                        pageNumber={pageNumber}
                        className={stringBuilder({ [styles.PdfFirstPage]: pageNumber === 1 }, styles.PdfPage)}
                        onLoadSuccess={pageNumber === 1 ? (e) => {
                            const scaleValue = Math.min(1, containerRef.current.clientWidth / e.originalWidth);
                            resetScalePoints(scaleValue);
                            reactPinchPanZoom.setTransform(Math.max((containerRef.current.clientWidth - e.originalWidth) / 2, 0), 0, scaleValue, 0);
                        } : undefined}
                        renderAnnotationLayer={false}
                    />
                ))
            }
        </Document>
    )
}

const NoPdfPreview = ({ close, reactPinchPanZoom }) => {
    useEffect(() => reactPinchPanZoom.centerView(undefined, 0), []);
    return (
        <div className={styles.NoPreview} role='status'>
            <div className={stringBuilder("mdi mdi-file-hidden", styles.Icon)} />
            <div className="font-bold h1 text-body">
                <Translate id='components.fileViewer.noData' />
            </div>
            <p className="mb-3">
                <Translate id='components.fileViewer.noPDF.text' values={{ br: () => <br /> }} />
            </p>
            <Button onClick={(e) => { e.stopPropagation(); close(); }} color="dark" type="button">
                <Translate id='components.fileViewer.noPDF.close' />
            </Button>
        </div>
    )
}

export default PDFFile