import { addExtension, FormikAddress, FormikCheckedButton, FormikDateTime, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect, FormikTextArea, getFormikAddressInitialValues } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { formatSelectData } from '@spordle/spordle-select';
import { Form, Formik, useFormikContext } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Alert, Button, Col, Collapse, Fade, FormGroup, Label, ModalBody, ModalFooter, Row
} from "reactstrap";
import { array, bool, boolean, mixed, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import Required from '../../../../../components/formik/Required';
import { fail, success } from '@spordle/toasts';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import { AddressTypesContext } from '../../../../../contexts/AddressTypesContext';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { PhoneTypesContext } from '../../../../../contexts/PhoneTypesContext';
import { UtilsContext } from '../../../../../contexts/UtilsContext';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
// Contexts
import withContexts from '../../../../../helpers/withContexts';
import MemberAddViews from './MemberAddViews';
import MemberAddForms2Documents from './MemberAddForms2Documents';
import CrossFade from '../../../../../components/crossFade/CrossFade';
import { stringBuilder } from '@spordle/helpers';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';
import { DocumentTypesContext } from '../../../../../contexts/DocumentTypesContext';
import { isARequiredSpecialField } from '@spordle/cart-engine';
import GenderLabel from '../../../../../components/GenderLabel';
import MemberFieldLabel from '../../../../../components/memberRegistration/MemberFieldLabel';
import MemberAddIndigenousInfo from './MemberAddIndigenousInfo';

class MemberAddForm2 extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            addressTypes: null,
            phoneTypes: null,
            countries: null,
            documentTypes: null,
            selectedEthnicity: null,

            memberStatusCheckResult: null,
            permanentlyIneligibleStatus: null,
        }
    }

    componentDidMount(){
        this.props.MembersContext.getStatuses()
            .then((statuses) => {
                this.setState(() => ({
                    permanentlyIneligibleStatus: statuses.find((status) => (status.code === "INELIGIBLE")),
                }));
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({ possibleStatuses: [] }))
                }
            })

        this.getCheckMemberStatus(
            this.props.memberInfo?.firstName,
            this.props.memberInfo?.lastName,
            this.props.memberInfo?.dateOfBirth ? (moment.isMoment(this.props.memberInfo?.dateOfBirth)) ? this.props.memberInfo?.dateOfBirth.format("YYYY-MM-DD") : moment(this.props.memberInfo?.dateOfBirth).format("YYYY-MM-DD") : null,
        );

        this.props.AddressTypesContext.getAddressTypes()
            .then((addressTypes) => {
                // Creating the groups for the options according to the org
                this.setState(() => ({
                    addressTypes: addressTypes.map((addressType, index) => (
                        {
                            value: addressType.address_type_id,
                            label: addressType.name,
                            i18n: addressType.i18n,
                        }
                    )),
                }));
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({ addressTypes: [] }))
                }
            })

        this.props.UtilsContext.getCountries()
            .then((countries) => {
                const countryOptions = formatSelectData(countries, {
                    getGroupId: (c) => [ 'CA', 'US' ].includes(c.code) ? 'suggested' : undefined,
                    getLabel: (c) => c.code,
                    getValue: (c) => c.code,
                    newGroupIndexes: {
                        0: {
                            groupId: 'suggested',
                            isGroup: true,
                            label: 'Suggested',
                        },
                    },
                });

                this.setState(() => ({ countries: countryOptions }))
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                    this.setState(() => ({ countries: [] }))

                }
            })

        this.props.PhoneTypesContext.getPhoneTypes()
            .then((phoneTypes) => {
                this.setState(() => ({
                    phoneTypes: phoneTypes.reduce((newArray, phoneType) => {
                        if(phoneType.active == 1){
                            newArray.push({
                                value: phoneType.phone_type_id,
                                label: phoneType.name,
                                i18n: phoneType.i18n,
                            })
                        }
                        return newArray
                    }, []),
                }));
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({ phoneTypes: [] }))
                }
            })

        const { settings } = this.props.OrganizationContext;
        const { getDocumentTypes } = this.props.DocumentTypesContext;


        getDocumentTypes()
            .then((documents) => {
                this.setState((prev) => ({
                    ...prev,
                    documentTypes: settings.attachment_for_new_member_in_registration.value.map((documentTypeId) => (
                        documents.find(({ document_type_id }) => document_type_id === documentTypeId)
                    ))
                        .filter((document) => !!document),
                }));
            })
            .catch(console.error);

    }

    isValidDob = (dob) => {
        const settings = this.props.OrganizationContext.settings;
        const minDateOfBirth = moment().subtract(parseInt(settings.member_min_age?.value || "0"), "year");
        const maxDateOfBirth = moment().subtract(parseInt(settings.member_max_age?.value || "0"), "year");
        return (!settings.member_min_age || dob.isSameOrBefore(minDateOfBirth)) && (!settings.member_max_age || dob.isAfter(maxDateOfBirth));
    }


    getCheckMemberStatus = (firstName, lastName, birthdate) => {
        this.props.MembersContext.getCheckMemberStatus({
            first_name: firstName,
            last_name: lastName,
            birthdate: birthdate,
        }).then((checkResult) => {
            this.setState(() => ({ memberStatusCheckResult: checkResult.ineligibleCheck }))

        }).catch((error) => {
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message)
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
            }
        })
    }

    render(){
        return (
            <Formik
                initialValues={{
                    organizationId: this.props.OrganizationContext.organisation_id,
                    firstName: this.props.memberInfo?.firstName,
                    lastName: this.props.memberInfo?.lastName,
                    dateOfBirth: this.props.memberInfo?.dateOfBirth || '',
                    gender: '',
                    gender_description: '',
                    primary_language: '',
                    email: '',
                    secondary_languages: [],
                    googlePlaceAddress: '',
                    addressNote: '',
                    address: getFormikAddressInitialValues(),
                    years_same_address: '',
                    address2: '',
                    addressTypeId: '',
                    nationality: this.props.memberInfo?.citizenships || [ 'CA' ], // Canadian by default
                    birth_country: 'CA', // Canada by default
                    phoneType: '',
                    phone: '',
                    extension: '',
                    documentsAreValidated: false,
                    confirmed: true,
                    documents: {},
                    step: 1,
                    identifyAsIndigenous: '', // im the jesus :)
                    identifyAsIndigenous_with_note: false,
                    indigenous_group_note: '',
                    indigenousGroupId: '',
                    ethnicityId: '',
                    ethnicityNote: '',
                }}
                validationSchema={object().shape({
                    dateOfBirth: mixed().required(<Translate id='form.validation.dateOfBirth.required' />)
                        .test({
                            name: 'dateOfBirthFormat',
                            message: <Translate id='form.validation.date.format' />,
                            test: function(dateOfBirth){
                                return moment.isMoment(dateOfBirth);
                            },
                        })
                        .test({
                            name: 'dateOfBirthNotToday',
                            message: <Translate id='members.search.memberAdd.dateOfBirth.minDate' />,
                            test: function(dateOfBirth){
                                return moment(dateOfBirth).isBefore(moment());
                            },
                        })
                        .test({
                            name: 'age-settings',
                            message: "age_settings",
                            test: (dob) => {
                                if(moment.isMoment(dob)){
                                    return this.isValidDob(dob);
                                }

                                return true;
                            },
                        }),
                    email: string().email(<Translate id='form.validation.email.valid' />).isRequiredSpecialField(isARequiredSpecialField('email', this.props.OrganizationContext.settings), <Translate id='form.validation.email.required' />),
                    gender: string().required(<Translate id='form.validation.gender.required' />),
                    gender_description: string(),
                    primary_language: string().isRequiredSpecialField(isARequiredSpecialField('primary_language', this.props.OrganizationContext.settings), <Translate id='form.validation.primaryLanguage.required' />),
                    addressTypeId: string().required(<Translate id='form.validation.addressType.required' />),
                    addressNote: string().max(255, <Translate id="form.validation.string.max" values={{ count: 255 }} />),
                    address: object().address(true, {
                        POBox: <Translate id='form.validation.POBox.required' />,
                        streetNumber: <Translate id='form.validation.streetNumber.required' />,
                        address: <Translate id='form.validation.address.required' />,
                        city: <Translate id='form.validation.city.required' />,
                        zipCode: <Translate id='form.validation.zip.required' />,
                        state: <Translate id='form.validation.province.required' />,
                        country: <Translate id='form.validation.country.required' />,
                    }),
                    years_same_address: mixed().isDate().required(<Translate id='form.validation.years_same_address.required' />),
                    nationality: array().when('does_not_matter', {
                        is: () => isARequiredSpecialField('citizenship', this.props.OrganizationContext.settings),
                        then: array().min(1, <Translate id='form.validation.nationality.required' />),
                    }),
                    birth_country: string().isRequiredSpecialField(isARequiredSpecialField('birth_country', this.props.OrganizationContext.settings), <Translate id='form.validation.birth_country.required' />),
                    phone: string()
                        .isValidPhoneNumber(<Translate id='form.validation.phone.valid' />)
                        .when([ 'phoneType', 'extension' ], {
                            is: (type, ext) => !!type || !!ext,
                            then: (schema) => schema.required(<Translate id='form.validation.phone.required' />),
                        }),
                    phoneType: string().test({
                        name: 'phoneTypeRequired',
                        message: <Translate id='form.validation.phoneType.required' />,
                        test: function(phoneType){
                            return !(!phoneType && (this.parent.extension || this.parent.phone))
                        },
                    }),
                    documentsAreValidated: bool(),

                    identifyAsIndigenous: string().isRequiredSpecialField(isARequiredSpecialField('identify_as_indigenous', this.props.OrganizationContext.settings), <Translate id='form.fields.identifyAsIndigenous.required' />),
                    indigenousGroupId: string().when('identifyAsIndigenous', {
                        is: 'YES',
                        then: string().required(<Translate id='form.fields.indigenousGroup.required' />),
                    }),
                    identifyAsIndigenous_with_note: boolean(),
                    indigenous_group_note: string(),

                    ethnicityId: string().isRequiredSpecialField(isARequiredSpecialField('ethnicity_id', this.props.OrganizationContext.settings), <Translate id='form.validation.ethnicity.required' />),
                    ethnicityNote: string(),
                })}
                onSubmit={async({ documents, step, ...values }, { setSubmitting, setStatus, setFieldValue }) => {
                    if(step === 1){ // Don't submit on step 1
                        setFieldValue('step', step + 1);
                        return;
                    }

                    const buildPhoneNumber = async() => {
                        const phone = await addExtension(values.phone, values.extension);
                        return phone || '';
                    }

                    this.props.setLoading(true);

                    const newValues = {
                        ...values,
                        dateOfBirth: (moment.isMoment(values.dateOfBirth)) ? values.dateOfBirth.format("YYYY-MM-DD") : moment(values.dateOfBirth).format("YYYY-MM-DD"),
                        years_same_address: values.years_same_address.format('YYYY'),
                        phone: {
                            phoneNumber: await buildPhoneNumber(),
                            phoneType: values.phoneType,
                        },
                        member_status: values.confirmed ? 'CONFIRMED' : 'UNCONFIRMED',
                    };

                    if(values.documentsAreValidated && values.confirmed){
                        newValues.need_address_document = 0;
                    }

                    // newValues.dateOfBirth = (moment.isMoment(values.dateOfBirth)) ? values.dateOfBirth.format("YYYY-MM-DD") : moment(values.dateOfBirth).format("YYYY-MM-DD");
                    return this.props.MembersContext.createMember(newValues)
                        .then(async({ member_id, warnings }) => {
                            await Promise.all(
                                Object.keys(documents).reduce((mappedDocuments, documentTypeId) => {
                                    const promisifiedDocument = documents[documentTypeId].map((document) => (
                                        this.props.MembersContext.createMemberAttachments(member_id, { attachment: document, document_type_id: documentTypeId })
                                    ))
                                    mappedDocuments.pushArray(promisifiedDocument);
                                    return mappedDocuments;
                                }, []),
                            );

                            success({
                                theme: (warnings || []).length > 0 ? 'info' : 'success',
                                msg: 'members.search.memberAdd.success',
                                info: (warnings || []).length > 0 ? (
                                    <>
                                        <b><Translate id="misc.warning" /></b>
                                        <ul className="mb-0 pl-3">
                                            {warnings.map((warning) => (
                                                <li key={warning.origin + warning.code}>
                                                    <DisplayI18n field="message" i18n={warning.i18n} defaultValue={warning.message} />
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                ) : void 0,
                            });

                            this.props.history.push(`/members/profile/${member_id}`);
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                        })
                        .finally(() => {
                            this.props.setLoading(false);
                            setSubmitting(false)
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <CrossFade isVisible={formik.values.step == 1}>
                            <ModalBody>
                                <CrossFade isVisible={this.state.memberStatusCheckResult}>
                                    <Alert color={'danger'} className='custom-alert-border-danger'>
                                        <div className='font-18 mr-2'>
                                            <i className={'mdi mdi-close-circle-outline'} />
                                        </div>
                                        <div className='flex-grow-1'>
                                            {this.state.permanentlyIneligibleStatus &&
                                            <Translate
                                                values={{ status: <DisplayI18n field='name' defaultValue={this.state.permanentlyIneligibleStatus.name} i18n={this.state.permanentlyIneligibleStatus.i18n} /> }}
                                                id={this.state.memberStatusCheckResult === 'POTENTIALLY_INELIGIBLE' ? 'members.search.memberAdd.potentially.ineligible' : 'members.search.memberAdd.perfect.match.ineligible'}
                                            />
                                            }
                                        </div>
                                    </Alert>
                                </CrossFade>
                                <UserDisplay block card className="mb-0">
                                    <UserDisplay.Container>
                                        <UserImg
                                            abbr={this.props.memberInfo?.firstName.charAt(0) + this.props.memberInfo?.lastName.charAt(0)} // first letter of firstname + last name
                                            src={null}
                                            alt={this.props.memberInfo?.firstName + ' ' + this.props.memberInfo?.lastName}
                                            width="30"
                                            height="30"
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container>
                                        <UserDisplay.Title>{formik.values.firstName} {formik.values.lastName}</UserDisplay.Title>
                                        <UserDisplay.Subtitle>
                                            <DisplayI18n
                                                field='name'
                                                defaultValue={this.props.OrganizationContext.organisation_name}
                                                i18n={this.props.OrganizationContext.i18n}
                                            />
                                        </UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>
                                <hr />
                                <div className="h5 font-medium"><Translate id='form.fields.generalInfo' /></div>
                                <div className="border p-3 mb-3">
                                    <Row form>
                                        <Col sm="12" className="mb-3">
                                            <MemberFieldLabel
                                                for="email"
                                                field="email"
                                                fallbackLabel={<Translate id='form.fields.email' />}
                                                withSpecialRequired
                                            />
                                            <FormikInputText name="email" id="email" />
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm="6" className="mb-3">
                                            <MemberFieldLabel
                                                for="dateOfBirth"
                                                field="birthdate"
                                                isRequired
                                                fallbackLabel={<Translate id='form.fields.dateOfBirth' />}
                                                // withSpecialRequired
                                            />
                                            <FormikDateTime
                                                name='dateOfBirth'
                                                id='dateOfBirth'
                                                dateFormat='YYYY-MM-DD'
                                                translatePlaceholder={false}
                                                timeFormat={false}
                                                manualError={formik.errors?.dateOfBirth === "age_settings"}
                                                onClose={() => formik.setFieldTouched('dateOfBirth', true, true)}
                                                isValidDate={(current) => moment(current).isBefore(new Date()) && this.isValidDob(moment(current))} // avoids having a member not yet born
                                            />
                                            {(!!this.props.OrganizationContext.settings.member_max_age || !!this.props.OrganizationContext.settings.member_min_age) &&
                                                <small className={`text-${formik.errors?.dateOfBirth === "age_settings" ? "danger" : "muted"}`}>
                                                    <Translate
                                                        id="misc.maxMinAge"
                                                        values={{
                                                            max: this.props.OrganizationContext.settings.member_max_age?.value || "-",
                                                            min: this.props.OrganizationContext.settings.member_min_age?.value || "-",
                                                        }}
                                                    />
                                                </small>
                                            }
                                        </Col>
                                        <Col sm="6" className="mb-3">
                                            <MemberFieldLabel
                                                isRequired
                                                field='gender'
                                                for='gender'
                                                fallbackLabel={<Translate id='form.fields.gender' />}
                                            />
                                            <FormikSelect
                                                name='gender'
                                                id='gender'
                                                loadingStatus='success'
                                                search={false}
                                                onOptionSelected={([ val ]) => {
                                                    if(val !== "PREFER_TO_SELF_DESCRIBE"){
                                                        formik.setFieldValue('gender_description', "");
                                                    }
                                                }}
                                                defaultData={this.props.OrganizationContext.settings.gender_selection?.value?.map((gender) => ({
                                                    value: gender,
                                                    label: <GenderLabel gender={gender} />,
                                                    translateLabel: false,
                                                })) || []}
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <Collapse isOpen={formik.values.gender === "PREFER_TO_SELF_DESCRIBE"}>
                                                <Fade in={formik.values.gender === "PREFER_TO_SELF_DESCRIBE"} className="pb-3">
                                                    <MemberFieldLabel
                                                        id="gender_description"
                                                        for="gender_description-input"
                                                        field='gender_description'
                                                        fallbackLabel={<Translate id="form.fields.genderDescription" />}
                                                    />
                                                    <FormikTextArea id='gender_description-input' name='gender_description' rows={1} trim />
                                                </Fade>
                                            </Collapse>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm="6" className="mb-3">
                                            <MemberFieldLabel
                                                field='primary_language'
                                                for='primary_language'
                                                fallbackLabel={<Translate id='form.fields.primaryLanguage' />}
                                                withSpecialRequired
                                            />
                                            <FormikSelect
                                                name='primary_language'
                                                id='primary_language'
                                                helper="members.search.memberAdd.primaryLanguage.helper"
                                                onOptionSelected={([ value ]) => {
                                                    this.secondaryLanguageSelect.getSpordleTable().filterChange('primaryLang', value || '', true);
                                                }}
                                                defaultData={[
                                                    {
                                                        label: 'misc.english',
                                                        value: 'en',
                                                        translateLabel: true,
                                                    },
                                                    {
                                                        label: 'misc.french',
                                                        value: 'fr',
                                                        translateLabel: true,
                                                    },
                                                ]}
                                                loadingStatus='success'
                                                search={false}
                                            />
                                        </Col>
                                        <Col sm="6" className="mb-3">
                                            <MemberFieldLabel
                                                field='secondary_languages'
                                                for='secondary_languages'
                                                withSpecialRequired
                                                fallbackLabel={<Translate id='form.fields.secondaryLanguages' />}
                                            />
                                            <FormikSelect
                                                ref={(r) => { this.secondaryLanguageSelect = r }}
                                                name='secondary_languages'
                                                id='secondary_languages'
                                                multi
                                                search={false}
                                                initFilter={{
                                                    primaryLang: '',
                                                }}
                                                loadData={(from, { filters, ...other }, spordleTable) => {
                                                    switch (from){
                                                        case 'FILTER':
                                                            if(formik.values.secondary_languages.includes(filters.primaryLang)){
                                                                formik.setFieldValue('secondary_languages', formik.values.secondary_languages.filter((l) => l !== filters.primaryLang));
                                                            }
                                                            return Promise.resolve(spordleTable.props.defaultData.filter((d) => d.value !== filters.primaryLang))
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                defaultData={[
                                                    {
                                                        label: 'misc.english',
                                                        value: 'en',
                                                        translateLabel: true,
                                                    },
                                                    {
                                                        label: 'misc.french',
                                                        value: 'fr',
                                                        translateLabel: true,
                                                    },
                                                ]}
                                                loadingStatus='success'
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="h5 font-medium">
                                    <Translate id='form.fields.additionalInfo' />
                                    <MemberAddIndigenousInfo className="ml-1" />
                                </div>
                                <div className="border p-3 mb-3">
                                    <Row form>
                                        <Col sm="6" className="mb-3">
                                            <MemberFieldLabel
                                                for='birth_country'
                                                fallbackLabel={<Translate id='form.fields.birth_country' />}
                                                field='birth_country'
                                                withSpecialRequired
                                            />
                                            <FormikSelect
                                                name="birth_country"
                                                id="birth_country"
                                                clearable
                                                searchKeys={[
                                                    `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                ]}
                                                renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                                isLoading={!this.state.countries}
                                                options={this.state.countries ?? []}
                                            />
                                        </Col>
                                        <Col sm="6" className="mb-3">
                                            <MemberFieldLabel
                                                field='citizenship'
                                                for="nationality"
                                                fallbackLabel={<Translate id='form.fields.nationality' />}
                                                withSpecialRequired
                                            />
                                            <FormikSelect
                                                multi
                                                clearable
                                                name="nationality"
                                                id="nationality"
                                                textWhenSetting={{
                                                    count: 2,
                                                    label: 'form.fields.nationality.selected',
                                                }}
                                                searchKeys={[
                                                    `i18n.${this.props.I18nContext.getGenericLocale()}.citizenship_name`,
                                                ]}
                                                renderOption={({ option }) => <DisplayI18n field="citizenship_name" defaultValue={option.label} i18n={option.i18n} />}
                                                isLoading={!this.state.countries}
                                                options={this.state.countries ?? []}
                                            />
                                        </Col>
                                        <Col sm='6' className="mb-3">
                                            <MemberFieldLabel
                                                field='identify_as_indigenous'
                                                for="identifyAsIndigenous"
                                                fallbackLabel={<Translate id='form.fields.identifyAsIndigenous' />}
                                                withSpecialRequired
                                            />
                                            <FormikSelect
                                                id='identifyAsIndigenous'
                                                name='identifyAsIndigenous'
                                                search={false}
                                                defaultData={[
                                                    { label: 'misc.yes', value: 'YES' },
                                                    { label: 'misc.no', value: 'NO' },
                                                    { label: 'misc.prefer_not_to_say', value: 'PREFER_NOT_TO_SAY' },
                                                ]}
                                                renderOption={(option) => <Translate id={option.option.label} />}
                                                loadingStatus="success"
                                                onOptionSelected={(values) => {
                                                    if(values[0] === 'YES'){
                                                        // TODO: HARDCODED
                                                        // set ethnicity as indigenous
                                                        formik.setFieldValue('ethnicityId', '07615f60-af8e-11ec-bed9-023f3d3ef136')
                                                    }else{
                                                        formik.setFieldValue('indigenousGroupId', "");
                                                        formik.setFieldValue('identifyAsIndigenous_with_note', false);
                                                        formik.setFieldValue('indigenous_group_note', "");
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col sm='6' className="mb-3">
                                            <MemberFieldLabel
                                                for="ethnicityId"
                                                field='ethnicity_id'
                                                fallbackLabel={<Translate id='form.fields.ethnicity' />}
                                                withSpecialRequired
                                            />
                                            <FormikSelect
                                                id='ethnicityId' name='ethnicityId'
                                                loadData={(from) => {
                                                    switch (from){
                                                        case 'CDM':
                                                            return this.props.UtilsContext.getEthnicities()
                                                                .then((ethnicities) => {
                                                                    return ethnicities.sort((a, b) => a.display_order - b.display_order).map((ethnicity) => ({
                                                                        value: ethnicity.ethnicity_id,
                                                                        label: ethnicity.name,
                                                                        i18n: ethnicity.i18n,
                                                                        withNote: ethnicity.with_note == 1,
                                                                    }))
                                                                })
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                onOptionSelected={(values, spordleSelect) => {
                                                    this.setState(() => ({
                                                        selectedEthnicity: spordleSelect.getSpordleTable().getData().find((data) => data.value === values[0]),
                                                    }))
                                                }}
                                            />
                                        </Col>
                                        <Col sm='12'>
                                            <CrossFade isVisible={!!this.state.selectedEthnicity?.withNote}>
                                                <FormGroup>
                                                    <MemberFieldLabel
                                                        for='ethnicityNote'
                                                        fallbackLabel={<Translate id='form.fields.ethnicityNote' />}
                                                        field='ethnicity_note'
                                                        withSpecialRequired
                                                    />
                                                    <FormikTextArea id='ethnicityNote' name='ethnicityNote' rows={1} trim />
                                                </FormGroup>
                                            </CrossFade>
                                        </Col>
                                        <Col sm='6'>
                                            <CrossFade isVisible={formik.values.identifyAsIndigenous === 'YES'}>
                                                <FormGroup>
                                                    <MemberFieldLabel
                                                        for='indigenousGroupId'
                                                        fallbackLabel={<Translate id='form.fields.indigenousGroup' />}
                                                        field='indigenous_group_id'
                                                        withSpecialRequired
                                                    />
                                                    <FormikSelect
                                                        id='indigenousGroupId'
                                                        name='indigenousGroupId'
                                                        loadData={(from) => {
                                                            switch (from){
                                                                case 'CDM':
                                                                    return this.props.UtilsContext.getIndigenousGroups()
                                                                        .then((groups) => {
                                                                            return groups.sort((a, b) => a.display_order - b.display_order).map((group) => ({
                                                                                ...group,
                                                                                value: group.indigenous_group_id,
                                                                                label: group.name,
                                                                                i18n: group.i18n,
                                                                            }))
                                                                        })
                                                                default:
                                                                    break;
                                                            }
                                                        }}
                                                        onOptionSelected={([ val ], select) => {
                                                            const indigenousGroup = select.getSpordleTable().getData().find(({ value }) => value === val);
                                                            const withNote = indigenousGroup?.with_note == 1;

                                                            formik.setFieldValue('identifyAsIndigenous_with_note', withNote);

                                                            if(!withNote){
                                                                formik.setFieldValue('indigenous_group_note', "");
                                                            }
                                                        }}
                                                        renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                    />
                                                </FormGroup>
                                            </CrossFade>
                                        </Col>
                                        <Col sm={6}>
                                            <Collapse isOpen={!!formik.values.identifyAsIndigenous_with_note}>
                                                <Fade in={!!formik.values.identifyAsIndigenous_with_note}>
                                                    <MemberFieldLabel
                                                        id='indigenous_group_note'
                                                        field='indigenous_group_note'
                                                        for='indigenous_group_note-input'
                                                        fallbackLabel={<Translate id="form.fields.identityGroupIndigenous" />}
                                                    />
                                                    <FormikTextArea id='indigenous_group_note-input' name='indigenous_group_note' rows={1} trim />
                                                </Fade>
                                            </Collapse>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="h5 font-medium"><Translate id='form.fields.address' /></div>
                                <div className="border p-3 mb-3">
                                    <Row form>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label for="addressTypeId"><Translate id='form.fields.addressType' /><Required className='ml-1' /></Label>
                                                <FormikSelect
                                                    name='addressTypeId'
                                                    id='addressTypeId'
                                                    isLoading={!this.state.addressTypes}
                                                    renderOption={({ option }) => (
                                                        <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                    )}
                                                    options={this.state.addressTypes ?? []}
                                                    searchKeys={[
                                                        `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                    ]}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="form-group" sm="6">
                                            <Label for='years_same_address'><Translate id='form.fields.years_same_address' /><Required className='ml-1' /></Label>
                                            <FormikDateTime
                                                dateFormat={'YYYY'}
                                                timeFormat={false}
                                                id='years_same_address'
                                                name='years_same_address'
                                                isValidDate={(current) => {
                                                    return moment(current).isSameOrBefore(new Date())
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <FormikAddress
                                        id='address' name='address'
                                        label='form.fields.address'
                                        allowManualPlace
                                        allowOpenInMaps
                                        allowPOBox
                                        required
                                    />
                                    <FormGroup>
                                        <Label for="address2"><Translate id='form.fields.address' /> 2 (<Translate id='form.fields.address2.placeholder' />)</Label>
                                        <FormikInputText id='address2' name='address2' />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="addressNote"><Translate id="form.fields.note" /></Label>
                                        <FormikTextArea
                                            name="addressNote"
                                            id="addressNote"
                                            rows={1}
                                            maxLength={255}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="h5 font-medium"><Translate id='form.fields.phone' /></div>
                                <div className="border p-3 mb-3">
                                    <Row form>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label for="addressTypeId"><Translate id='form.fields.phoneType' /></Label>
                                                <FormikSelect
                                                    name='phoneType'
                                                    id='phoneType'
                                                    isLoading={!this.state.phoneTypes}
                                                    renderOption={({ option }) => (
                                                        <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                    )}
                                                    clearable
                                                    options={this.state.phoneTypes ?? []}
                                                    searchKeys={[
                                                        `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                    ]}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='6' />
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label for='phone'><Translate id='form.fields.phone' /></Label>
                                                <FormikPhoneInput
                                                    id="phone"
                                                    name="phone"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label for='extension'><Translate id='form.fields.extension' /></Label>
                                                <FormikInputNumber id='extension' name='extension' autoComplete='tel-extension' />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                                <MemberAddForms2Documents documentTypes={this.state.documentTypes} />
                            </ModalBody>
                            <ModalFooter>
                                <Button type="button" color="primary" disabled={formik.isSubmitting} onClick={() => this.props.setActiveView(MemberAddViews.step1)} outline><Translate id='misc.back' /></Button>
                                <Button type="submit" color="primary" disabled={formik.isSubmitting} className="ml-auto"><Translate id='misc.next' /></Button>
                            </ModalFooter>
                        </CrossFade>
                        <CrossFade isVisible={formik.values.step == 2}>
                            <ModalBody>
                                <UserDisplay block card className="mb-0">
                                    <UserDisplay.Container>
                                        <UserImg
                                            abbr={this.props.memberInfo?.firstName.charAt(0) + this.props.memberInfo?.lastName.charAt(0)} // first letter of firstname + last name
                                            src={null}
                                            alt={this.props.memberInfo?.firstName + ' ' + this.props.memberInfo?.lastName}
                                            width="30"
                                            height="30"
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container>
                                        <UserDisplay.Title>{formik.values.firstName} {formik.values.lastName}</UserDisplay.Title>
                                        <UserDisplay.Subtitle>
                                            <DisplayI18n
                                                field='name'
                                                defaultValue={this.props.OrganizationContext.organisation_name}
                                                i18n={this.props.OrganizationContext.i18n}
                                            />
                                        </UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>
                                <hr />
                                <button
                                    type='button' className='reset-btn text-link mb-3'
                                    onClick={() => {
                                        formik.setFieldValue('step', 1);
                                        formik.setStatus('');
                                    }}
                                >
                                    <i className="mdi mdi-arrow-left mr-1" /><Translate id='members.search.memberAdd.reviewPreviousStep' />
                                </button>
                                <UserDisplay
                                    block card hover
                                    className={stringBuilder({ "shadow-primary border-primary": formik.values.confirmed }, 'mb-2')}
                                    onClick={() => {
                                        formik.setFieldValue('confirmed', true);
                                    }}
                                >
                                    <UserDisplay.Container>
                                        <div style={{ height: 50, width: 50 }} className="border bg-light rounded-circle d-flex align-items-center justify-content-center">
                                            <i style={{ fontSize: '30px' }} className="mdi mdi-account" />
                                            <i style={{ height: '17px', width: '17px' }} className='d-flex flex-center mdi mdi-check-circle-outline line-height-1 bg-light rounded-circle position-absolute ml-3 mb-n3 z-index-1 font-16 text-success' />
                                        </div>
                                    </UserDisplay.Container>
                                    <UserDisplay.Container>
                                        <UserDisplay.Title><Translate id='members.search.memberAdd.confirmUser' /></UserDisplay.Title>
                                        <UserDisplay.Subtitle>
                                            <Translate id='members.search.memberAdd.confirmUser.subtitle' />
                                        </UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>
                                <UserDisplay
                                    block card hover
                                    className={stringBuilder({ "shadow-primary border-primary": !formik.values.confirmed }, 'mb-0')}
                                    onClick={() => {
                                        formik.setFieldValue('confirmed', false);
                                    }}
                                >
                                    <UserDisplay.Container>
                                        <div style={{ height: 50, width: 50 }} className="border bg-light rounded-circle d-flex align-items-center justify-content-center">
                                            <i style={{ fontSize: '30px' }} className="mdi mdi-account" />
                                            <i style={{ height: '17px', width: '17px' }} className='d-flex flex-center mdi mdi-close-circle-outline line-height-1 bg-light rounded-circle position-absolute ml-3 mb-n3 z-index-1 font-16 text-danger' />
                                        </div>
                                    </UserDisplay.Container>
                                    <UserDisplay.Container>
                                        <UserDisplay.Title><Translate id='members.search.memberAdd.unconfirmUser' /></UserDisplay.Title>
                                        <UserDisplay.Subtitle>
                                            <Translate id='members.search.memberAdd.unconfirmUser.subtitle' />
                                        </UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>
                                {formik.status &&
                                    <Alert color='danger' className='mb-0 mt-3'>
                                        <Translate id={'contexts.membersContext.' + formik.status} defaultMessageId='misc.error' />
                                        {formik.status == 2000 &&
                                            <Button
                                                type="button"
                                                color="link"
                                                className="reset-btn mt-1 text-decoration-underline alert-danger text-left d-block font-bold"
                                                disabled={formik.isSubmitting}
                                                onClick={() => this.props.setActiveView(MemberAddViews.step1)}
                                            >
                                                <Translate id='members.search.memberAdd.error.2000.btn' />
                                            </Button>
                                        }
                                    </Alert>
                                }
                                <CrossFade isVisible={formik.values.confirmed && Array.isArray(this.state.documentTypes) && this.state.documentTypes.length > 0}>
                                    <div className="pt-3">
                                        <FormikCheckedButton
                                            name="documentsAreValidated"
                                            id="documentsAreValidated"
                                            label="members.profile.overview.memberAddressPhone.modal.checkbox.documentsValidated"
                                        />
                                    </div>
                                </CrossFade>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    onClick={() => {
                                        formik.setFieldValue('step', 1);
                                        formik.setStatus('');
                                    }}
                                    className="mr-auto"
                                    type="button"
                                    color="primary"
                                    outline
                                >
                                    <Translate id="misc.previous" />
                                </Button>
                                <Button type="submit" color="primary"><Translate id="misc.submit" /></Button>
                            </ModalFooter>
                        </CrossFade>
                        <ScrollToError />
                    </Form>
                )}
            </Formik>
        );
    }
}

const ScrollToError = () => {
    const formik = useFormikContext();
    const count = useRef(formik.submitCount);

    useEffect(() => {
        const errorsArray = Object.keys(formik.errors);

        if(errorsArray.length > 0 && formik.submitCount !== count.current){
            const fieldId = errorsArray[0]
            if(formik.errors[fieldId]){
                const modal = document.querySelector('.modal.show');
                modal.scrollTo({
                    behavior: 'smooth',
                    top: 0,
                });
            }

            count.current = formik.submitCount;
        }

    }, [ formik.errors, formik.submitCount ])

    return null
}

export default withContexts(OrganizationContext, DocumentTypesContext, IdentityRolesContext, MembersContext, AddressTypesContext, UtilsContext, I18nContext, PhoneTypesContext)(withRouter(MemberAddForm2));