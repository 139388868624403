import { useState } from 'react';
import OnlineStoreGeneralInformation from '../../components/generalInformation/OnlineStoreGeneralInformation';
import OnlineStoreRebatesTerms from '../../components/rebatesTerms/OnlineStoreRebatesTerms';

const OnlineStoreOverview = ({ onlineStoreId }) => {
    const [ generalInfoOpen, setGeneralInfo ] = useState(false);
    const toggleGeneralInfo = () => { setGeneralInfo(!generalInfoOpen) };

    const [ termsRebatesOpen, setTermsRebates ] = useState(false);
    const toggleTermsRebates = () => { setTermsRebates(!termsRebatesOpen) };

    return (
        <>
            <OnlineStoreGeneralInformation
                toggle={toggleGeneralInfo}
                onFormSave={toggleGeneralInfo}
                isEditing={generalInfoOpen}
                onlineStoreId={onlineStoreId}
            />
            <OnlineStoreRebatesTerms toggle={toggleTermsRebates} onSubmit={toggleTermsRebates} isEditing={termsRebatesOpen} onlineStoreId={onlineStoreId} />
        </>
    )
}

export default OnlineStoreOverview;