export default{
    "organization.settings.organizationTeamTravelTypes.tab.title" : "Team Travel Types",

    "organization.settings.organizationTeamTravelTypes.table.active" : "Active",
    "organization.settings.organizationTeamTravelTypes.table.empty"  : "No Travel Type",

    // add
    "organization.settings.organizationTeamTravelTypes.add.title"           : "Add Travel Type",
    "organization.settings.organizationTeamTravelTypes.add.status"          : "Status",
    "organization.settings.organizationTeamTravelTypes.add.status.active"   : "Active",
    "organization.settings.organizationTeamTravelTypes.add.status.inactive" : "Inactive",
    "organization.settings.organizationTeamTravelTypes.add.status.required" : "Status is required",
}