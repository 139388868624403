export default{
    // Component permission
    "organization.profile.roles.sidePanelOrganizationRoles.componentPermission.title"    : "Component Permissions",
    "organization.profile.roles.sidePanelOrganizationRoles.componentPermission.cannotDo" : "You cannot update component permissions from multiple roles at the same time",
    "organization.profile.roles.sidePanelOrganizationRoles.componentPermission.disabled" : "This module is deactivated for this role",
    "organization.profile.roles.sidePanelOrganizationRoles.componentPermission.enabled"  : "This module is activated for this role",
    "organization.profile.roles.sidePanelOrganizationRoles.componentPermission.single"   : "1 component has at least the read permission",
    "organization.profile.roles.sidePanelOrganizationRoles.componentPermission.many"     : "At least {count} components the read permission",

    // Role info
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.seeSettings"           : "See in settings",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.isShared"              : "This is a shared role",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.isSytem"               : "This is a system role",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.isInactive"            : "This role is inactive",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.multi.cannot.warning"  : "One of the selected roles is shared or is a system role",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.status.value.active"   : "Active",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.status.value.inactive" : "Inactive",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.status.title"          : "Status",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.description.title"     : "Description",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.title.title"           : "Title",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo"                       : "Role info",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.dates"                 : "Dates restrictions",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.dates.none"            : "No specified date",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.from"                  : "From",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.until"                 : "Until",

    // Tabs
    "organization.profile.roles.sidePanelOrganizationRoles.tabs.details.title" : "Details",
    "organization.profile.roles.sidePanelOrganizationRoles.tabs.users.title"   : "Users",

    // Role more action
    "organization.profile.roles.sidePanelOrganizationRoles.dropdownAction.delete" : "Delete",

    // FormikEditable
    "organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.title.required"       : "Title is required",
    "organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.description.required" : "Description is required",
    "organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.active.required"      : "Active is required",
    "organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.systemRole.required"  : "System role is required",
    "organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.startDate.format"     : "Start date must be of a valid format",
    "organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.endDate.format"       : "End date must be of a valid format",
    "organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.dates"                : "The start date must be before the end date",

    // Delete Role
    "organization.profile.roles.sidePanelOrganizationRoles.deleteRole.topText" : "You are about to delete this role from your organization",

    // Users Info
    "organization.profile.roles.sidePanelOrganizationRoles.usersInfo.title"             : "Users",
    "organization.profile.roles.sidePanelOrganizationRoles.usersInfo.subTitle.plural"   : "{num} users have this role",
    "organization.profile.roles.sidePanelOrganizationRoles.usersInfo.subTitle.singular" : "1 user has this role",
    "organization.profile.roles.sidePanelOrganizationRoles.usersInfo.subTitle.none"     : "Nobody has this role",
    "organization.profile.roles.sidePanelOrganizationRoles.usersInfo.table.name"        : "Name",

    "organization.profile.roles.sidePanelOrganizationRoles.duplicate.title" : "Duplicate Role",
}
