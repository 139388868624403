export default{
    "settings.clinics.categories.title" : "Categories",


    // add
    "settings.clinics.categories.add.title"           : "Ajouter une catégorie",
    "settings.clinics.categories.add.color"           : "Couleur",
    "settings.clinics.categories.add.status"          : "Statut",
    "settings.clinics.categories.add.status.active"   : "Actif",
    "settings.clinics.categories.add.status.inactive" : "Inactif",
    "settings.clinics.categories.add.status.required" : "Le Statut est requis",
}