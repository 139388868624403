import { FormikInputText, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { formatSelectData } from "@spordle/spordle-select";
import { useContext } from "react";
import { Col, Collapse, Row } from "reactstrap";
import { array, object, string } from 'yup';
import FormikEditable from "../../../../../../components/formik/FormikEditable";
import HasAccessTo from "../../../../../../components/permissions/HasAccessTo";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { formikOtherTest, useInjurySelect, YES_NO_INFO_DATA } from "../../InsuranceHelper";
import DocumentUpload from "./components/DocumentUpload";

const InsuranceSidePanelInjury = ({
    syncNewVal,
    canEdit,
    injuryDataLists,
    injury,
    updateInjury,
    setIsLoading,
    docInfo,
    refreshTableWithApi,
}) => {
    const { getGenericLocale } = useContext(I18nContext);
    const getSelectInjuryProps = useInjurySelect();
    const { absences, bodyParts, natures, onSiteCares, causes, equipments } = injuryDataLists;

    return (
        <>
            <div className="h5 font-bold"><Translate id='insurance.label.injury' /></div>
            <Row form>
                <Col sm="6" className="form-group">
                    <div className="text-muted">
                        <Translate id='insurance.label.bodyPart' />
                    </div>
                    <FormikEditable
                        id="edit-body-part"
                        noConfirmation
                        disabled={!canEdit || !bodyParts}
                        initialValues={{
                            body_part_id: injury.body_part?.body_part_id || '',
                        }}
                        validationSchema={object().shape({
                            body_part_id: string().required(<Translate id='insurance.validation.bodyPart.required' />),
                        })}
                        onSubmit={(values) => {
                            if(values.body_part_id !== injury.body_part?.body_part_id){
                                updateInjury(values, { body_part: bodyParts.find((bP) => bP.body_part_id === values.body_part_id) });
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (
                                    injury.body_part ?
                                        <DisplayI18n
                                            field="name"
                                            i18n={injury.body_part.i18n}
                                            defaultValue={injury.body_part.name}
                                        />
                                        :
                                        '-'
                                );
                            }
                            return (
                                <FormikSelect
                                    name="body_part_id"
                                    id="body_part_id"
                                    onOptionSelected={options.stopEditing}
                                    autoFocus
                                    menuIsDefaultOpen
                                    search
                                    isLoading={!bodyParts}
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    renderOption={(opt) => (
                                        <DisplayI18n
                                            field="name"
                                            i18n={opt.option.i18n}
                                            defaultValue={opt.option.label}
                                        />
                                    )}
                                    options={bodyParts ?
                                        formatSelectData(bodyParts.sort((a, b) => {
                                            return parseInt(a.body_part_group.display_order) - parseInt(b.body_part_group.display_order)
                                        }), {
                                            getValue: (part) => part.body_part_id,
                                            getLabel: (part) => part.name,
                                            getGroupId: (part) => part.body_part_group?.body_part_group_id,
                                            createGroupWithId: (part) => ({
                                                ...part.body_part_group,
                                                label: part.name,
                                            }),
                                        })
                                        :
                                        []
                                    }
                                />
                            )

                        }}
                    </FormikEditable>
                </Col>
                <Col sm="6" className="form-group">
                    <div className="text-muted">
                        <Translate id='insurance.label.injuryNature' />
                    </div>
                    <FormikEditable
                        id="edit-injury_nature"
                        noConfirmation
                        disabled={!canEdit || !natures}
                        initialValues={{
                            injury_nature_id: injury.injury_nature?.injury_nature_id || '',
                        }}
                        onSubmit={(values) => {
                            if(values.injury_nature_id !== injury.injury_nature?.injury_nature_id){
                                updateInjury(values, { injury_nature: natures.find((n) => n.injury_nature_id === values.injury_nature_id) });
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (
                                    injury.injury_nature ?
                                        <DisplayI18n
                                            field="name"
                                            i18n={injury.injury_nature?.i18n}
                                            defaultValue={injury.injury_nature?.name}
                                        />
                                        :
                                        '-'
                                );
                            }
                            return (
                                <FormikSelect
                                    name="injury_nature_id"
                                    id="injury_nature_id"
                                    onOptionSelected={options.stopEditing}
                                    autoFocus
                                    search
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    menuIsDefaultOpen
                                    renderOption={(opt) => (
                                        <DisplayI18n
                                            field="name"
                                            i18n={opt.option.i18n}
                                            defaultValue={opt.option.name}
                                        />
                                    )}
                                    defaultData={natures.map((nature) => ({
                                        value: nature.injury_nature_id,
                                        i18n: nature.i18n,
                                        label: nature.name,
                                    }))}
                                    loadingStatus='success'
                                />
                            )

                        }}
                    </FormikEditable>
                </Col>
                <Col sm="6" className="form-group">
                    <div className="text-muted">
                        <Translate id='insurance.label.onSiteCare' />
                    </div>
                    <FormikEditable
                        id="edit-injury_onSiteCare"
                        noConfirmation
                        disabled={!canEdit || !onSiteCares}
                        initialValues={{
                            injury_on_site_care_id: injury.injury_on_site_care?.injury_on_site_care_id || "",
                        }}
                        onSubmit={(values) => {
                            if(values.injury_on_site_care_id !== injury.injury_on_site_care?.injury_on_site_care_id){
                                updateInjury(values, { injury_on_site_care: onSiteCares.find((sC) => sC.injury_on_site_care_id === values.injury_on_site_care_id) });
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (
                                    injury.injury_on_site_care ?
                                        <DisplayI18n
                                            field="name"
                                            i18n={injury.injury_on_site_care?.i18n}
                                            defaultValue={injury.injury_on_site_care?.name}
                                        />
                                        :
                                        '-'
                                );
                            }
                            return (
                                <FormikSelect
                                    name="injury_on_site_care_id"
                                    id="injury_on_site_care_id"
                                    onOptionSelected={options.stopEditing}
                                    autoFocus
                                    search
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    menuIsDefaultOpen
                                    renderOption={(opt) => (
                                        <DisplayI18n
                                            field="name"
                                            i18n={opt.option.i18n}
                                            defaultValue={opt.option.name}
                                        />
                                    )}
                                    defaultData={onSiteCares.map((care) => ({
                                        value: care.injury_on_site_care_id,
                                        i18n: care.i18n,
                                        label: care.name,
                                    }))}
                                    loadingStatus='success'
                                />
                            )

                        }}
                    </FormikEditable>
                </Col>
                <Col className="form-group" sm="6">
                    <div className="text-muted">
                        <Translate id='insurance.label.correctAge' />
                    </div>
                    <FormikEditable
                        id="edit-correct_age"
                        noConfirmation
                        disabled={!canEdit}
                        initialValues={{
                            correct_age: injury.correct_age,
                        }}
                        onSubmit={(values) => {
                            if(values.correct_age !== injury.correct_age){
                                updateInjury(values, values);
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (
                                    <Translate id={`misc.${injury.correct_age?.toLowerCase() || "noInfo"}`} />
                                );
                            }
                            return (
                                <FormikSelect
                                    name="correct_age"
                                    id="correct_age"
                                    autoFocus
                                    menuIsDefaultOpen
                                    onOptionSelected={options.stopEditing}
                                    renderOption={(option) => (
                                        <Translate id={option.option.label} />
                                    )}
                                    search={false}
                                    defaultData={[
                                        {
                                            label: "misc.yes",
                                            value: "YES",
                                        },
                                        {
                                            label: "misc.no",
                                            value: "NO",
                                        },
                                        {
                                            label: "misc.noInfo",
                                            value: "NO_INFO",
                                        },
                                    ]}
                                    loadingStatus="success"
                                />
                            )

                        }}
                    </FormikEditable>
                </Col>
                <Col className="form-group" sm="6">
                    <div className="text-muted">
                        <Translate id='insurance.label.sanctionned' />
                    </div>
                    <FormikEditable
                        id="edit-sanctionned_event"
                        noConfirmation
                        disabled={!canEdit}
                        initialValues={{
                            sanctionned_event: injury.sanctionned_event,
                        }}
                        onSubmit={(values) => {
                            if(values.sanctionned_event !== injury.sanctionned_event){
                                updateInjury(values, values);
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (
                                    <Translate id={`misc.${injury.sanctionned_event?.toLowerCase() || "noInfo"}`} />
                                );
                            }
                            return (
                                <FormikSelect
                                    name="sanctionned_event"
                                    id="sanctionned_event"
                                    menuIsDefaultOpen
                                    autoFocus
                                    search={false}
                                    renderOption={(option) => (
                                        <Translate id={option.option.label} />
                                    )}
                                    onOptionSelected={options.stopEditing}
                                    defaultData={YES_NO_INFO_DATA}
                                    loadingStatus="success"
                                />
                            )

                        }}
                    </FormikEditable>
                </Col>
                <Col sm="6" className="form-group">
                    <div className="text-muted">
                        <Translate id='insurance.label.absence' />
                    </div>
                    <FormikEditable
                        id="edit-injury_absence_id"
                        noConfirmation
                        disabled={!canEdit || !absences}
                        initialValues={{
                            injury_absence_id: injury.injury_absence?.injury_absence_id,
                        }}
                        onSubmit={(values) => {
                            if(values.injury_absence_id !== injury.injury_absence?.injury_absence_id){
                                updateInjury(values, {
                                    injury_absence: absences.find((a) => a.injury_absence_id === values.injury_absence_id),
                                });
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (
                                    injury.injury_absence ?
                                        <DisplayI18n
                                            field="name"
                                            i18n={injury.injury_absence?.i18n}
                                            defaultValue={injury.injury_absence?.name}
                                        />
                                        :
                                        '-'
                                );
                            }
                            return (
                                <FormikSelect
                                    name="injury_absence_id"
                                    id="injury_absence_id"
                                    onOptionSelected={options.stopEditing}
                                    autoFocus
                                    menuIsDefaultOpen
                                    search
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    renderOption={(opt) => (
                                        <DisplayI18n
                                            field="name"
                                            i18n={opt.option.i18n}
                                            defaultValue={opt.option.name}
                                        />
                                    )}
                                    defaultData={absences.map((abs) => ({
                                        label: abs.name,
                                        i18n: abs.i18n,
                                        value: abs.injury_absence_id,
                                    }))}
                                    loadingStatus="success"
                                />
                            )

                        }}
                    </FormikEditable>
                </Col>
                <Col sm="6" className="form-group">
                    <div className="text-muted">
                        <Translate id='insurance.label.previousInjury' />
                    </div>
                    <FormikEditable
                        id="edit-previous_injury"
                        disabled={!canEdit}
                        initialValues={{
                            previous_injury: injury.previous_injury || '',
                            previous_injury_other: injury.previous_injury_other || '',
                        }}
                        validationSchema={object().shape({
                            previous_injury: string(),
                            previous_injury_other: string().test(formikOtherTest('previous_injury')),
                        })}
                        onSubmit={(values) => {
                            if(values.previous_injury !== injury.previous_injury || values.previous_injury_other !== injury.previous_injury_other){
                                updateInjury(values, values);
                            }
                        }}
                    >
                        {(isEditing, _options, formik) => {
                            if(!isEditing){
                                return (
                                    injury.previous_injury === "YES" ?
                                        <>
                                            <Translate id="misc.yes" /> {injury.previous_injury_other}
                                        </>
                                        :
                                        <Translate id={`misc.${injury.previous_injury?.toLowerCase() || 'noInfo'}`} />
                                );
                            }
                            return (
                                <>
                                    <FormikSelect
                                        name='previous_injury'
                                        id='previous_injury'
                                        autoFocus
                                        menuIsDefaultOpen={formik.values.previous_injury !== "YES"}
                                        renderOption={(option) => (
                                            <Translate id={option.option.label} />
                                        )}
                                        defaultData={[
                                            {
                                                label: "misc.yes",
                                                value: "YES",
                                            },
                                            {
                                                label: "misc.no",
                                                value: "NO",
                                            },
                                            {
                                                label: "misc.noInfo",
                                                value: "NO_INFO",
                                            },
                                        ]}
                                        loadingStatus='success'
                                    />
                                    <Collapse isOpen={formik.values.previous_injury === "YES"}>
                                        <FormikInputText
                                            className="mt-2"
                                            helper={
                                                <span className="text-muted small">
                                                    <i className="mdi mdi-information-outline" /> <Translate id='insurance.label.previousInjury.longAgo' />
                                                </span>
                                            }
                                            translateHelper={false}
                                            bsSize="sm"
                                            id="previous_injury_other"
                                            name="previous_injury_other"
                                        />
                                    </Collapse>
                                </>
                            )

                        }}
                    </FormikEditable>
                </Col>
                <Col sm="6" className="form-group">
                    <div className="text-muted"><Translate id='insurance.label.cause' /></div>
                    <FormikEditable
                        id="edit-injury_cause"
                        disabled={!canEdit || !causes}
                        noConfirmation
                        initialValues={{
                            injury_cause_id: injury.injury_cause?.injury_cause_id || '',
                        }}
                        onSubmit={(values) => {
                            if(values.injury_cause_id !== injury.injury_cause?.injury_cause_id){
                                updateInjury(values, {
                                    injury_cause: causes.find((c) => c.injury_cause_id === values.injury_cause_id),
                                });
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (
                                    injury.injury_cause ?
                                        <DisplayI18n
                                            field="name"
                                            i18n={injury.injury_cause?.i18n}
                                            defaultValue={injury.injury_cause?.name}
                                        />
                                        :
                                        '-'
                                )
                            }
                            return (
                                <FormikSelect
                                    {...getSelectInjuryProps("injury_cause_id", causes)}
                                    autoFocus
                                    menuIsDefaultOpen
                                    onOptionSelected={options.stopEditing}
                                />
                            )

                        }}
                    </FormikEditable>
                </Col>
                <Col sm="12" className="form-group">
                    <div className="text-muted">
                        <Translate id='insurance.label.description' />
                    </div>
                    <FormikEditable
                        id="edit-injury_description"
                        disabled={!canEdit}
                        initialValues={{
                            description: injury.description,
                        }}
                        onSubmit={(values) => {
                            if(values.description !== injury.description){
                                updateInjury(values, values);
                            }
                        }}
                    >
                        {(isEditing, _options) => {
                            if(!isEditing){
                                return injury.description || '-';
                            }
                            return <FormikTextArea trim name="description" id='insurance-injury-description' autoFocus />

                        }}
                    </FormikEditable>
                </Col>
                <Col sm="12" className="form-group">
                    <div className="text-muted">
                        <Translate id='insurance.label.equipment' />
                    </div>
                    <FormikEditable
                        id="edit-injury_equipment_id"
                        noConfirmation
                        disabled={!canEdit || !equipments}
                        validationSchema={object().shape({
                            injury_equipment_id: array().of(string()).min(1, <Translate id='insurance.validation.equipment.required' />),
                        })}
                        initialValues={{
                            injury_equipment_id: injury.injury_equipment?.map((equip) => equip.injury_equipment_id) || [],
                        }}
                        onSubmit={(values) => {
                            const valuesAreDiff = (initVal = [], newVal = []) => {
                                return initVal.length !== newVal.length || initVal.some((id) => !newVal.includes(id));
                            };

                            if(valuesAreDiff(injury.injury_equipment?.map((equip) => equip.injury_equipment_id)), values.injury_equipment_id){
                                updateInjury(values, {
                                    injury_equipment: equipments.filter((e) => (values.injury_equipment_id || []).includes(e.injury_equipment_id)),
                                });
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (
                                    <Translate id='insurance.label.equipment.count' values={{ count: injury.injury_equipment?.length || 0 }} />
                                );
                            }
                            return (
                                <FormikSelect
                                    name="injury_equipment_id"
                                    id="injury_equipment_id"
                                    multi
                                    search
                                    autoFocus
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    menuIsDefaultOpen
                                    renderOption={(opt) => (
                                        <DisplayI18n
                                            field="name"
                                            i18n={opt.option.i18n}
                                            defaultValue={opt.option.name}
                                        />
                                    )}
                                    defaultData={equipments.map((equip) => ({
                                        value: equip.injury_equipment_id,
                                        i18n: equip.i18n,
                                        label: equip.name,
                                    }))}
                                    loadingStatus='success'
                                />
                            )
                        }}
                    </FormikEditable>
                    {injury.injury_equipment &&
                        <div className="bg-light rounded p-2 border mt-2">
                            <b className="mb-2 d-block"><Translate id='insurance.label.equipment' /></b>
                            <ul className="mb-0 pl-4" id='insurance-injury-equipments'>
                                {injury.injury_equipment.map((equip) => (
                                    <li key={equip.injury_equipment_id} data-equipmentId={equip.injury_equipment_id}>
                                        <DisplayI18n
                                            field="name"
                                            defaultValue={equip.name}
                                            i18n={equip.i18n}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    }
                </Col>
            </Row>
            <HasAccessTo componentCode="settings" componentPermissionCode="manage_injury_attachments">
                {docInfo.requiredTypes.length > 0 &&
                    <Row form id='insurance-injury-documents'>
                        {docInfo.requiredTypes.map((docType) => (
                            <Col key={docType.document_type_id} sm="12" data-documentTypeId={docType.document_type_id}>
                                <DocumentUpload
                                    docType={docType}
                                    syncNewVal={syncNewVal}
                                    injury={injury}
                                    canEdit={canEdit}
                                    refreshTableWithApi={refreshTableWithApi}
                                    setIsLoading={setIsLoading}
                                />
                            </Col>
                        ))}
                    </Row>
                }
            </HasAccessTo>
        </>
    )
}

export default InsuranceSidePanelInjury;