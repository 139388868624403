import { FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { useFormikContext } from "formik";
import { Button, Label, ModalBody, ModalFooter } from "reactstrap";
import { getTeamAvailableCompetitions } from "../../../../../api/client/teams";
import { PeriodsContext } from "../../../../../contexts/contexts";
import { useCallback, useContext, useEffect, useState } from "react";
import DisplayOrg from "../../../../../components/DisplayOrg";
import { displayI18n, DisplayI18n } from "../../../../../helpers/i18nHelper";
import { DisplayTeamCategory } from "../../../TeamHelpers";
import Required from "../../../../../components/formik/Required";
import FormikApiError from "../../../../../components/formik/FormikApiError";
import { I18nContext } from "../../../../../contexts/I18nContext";

const ParticipateChooseCompetition = ({ teamId, orgsAppliedTo, categoryIds, toggle }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const { selectedPeriod } = useContext(PeriodsContext);
    const [ data, setData ] = useState(null);
    const formik = useFormikContext();

    const getFilteredCompetitions = useCallback(() => {
        if(!formik.values.mode || !data){
            return [];
        }
        if(!formik.values.organisation_id){
            return data.competitions;
        }

        return data.competitions.filter(({ organisation_id }) => organisation_id === formik.values.organisation_id);

    }, [ formik.values.mode, formik.values.organisation_id, !!data ]);

    useEffect(() => {
        if(formik.values.mode && categoryIds){
            getTeamAvailableCompetitions(teamId, {
                period_id: selectedPeriod.period_id,
                organisation_category_id: categoryIds[formik.values.mode],
            })
                .then(({ competitions, organisations }) => {
                    const collator = new Intl.Collator(getGenericLocale(), { sensitivity: 'base', numeric: true });

                    const availableOrgs = organisations.map((org) => ({
                        ...org,
                        isInCompetitiveOrg: (orgsAppliedTo || []).includes(org.organisation_id),
                        value: org.organisation_id,
                        label: org.organisation_name,
                    }))
                        .sort((a, b) => {
                            if(a.isInCompetitiveOrg == b.isInCompetitiveOrg){
                                const nameA = displayI18n('name', a.i18n, a.name, getGenericLocale());
                                const nameB = displayI18n('name', b.i18n, b.name, getGenericLocale());

                                return collator.compare(nameA, nameB);
                            }

                            return a.isInCompetitiveOrg ? 1 : -1;
                        });

                    const sortedCompetitions = competitions
                        .map((comp) => ({
                            ...comp,
                            isInCompetitiveOrg: (orgsAppliedTo || []).includes(comp.organisation_id),
                            value: comp.competition_id,
                            label: comp.name,
                        }))
                        .sort((a, b) => {
                            if(a.isInCompetitiveOrg == b.isInCompetitiveOrg){
                                const nameA = displayI18n('name', a.i18n, a.name, getGenericLocale());
                                const nameB = displayI18n('name', b.i18n, b.name, getGenericLocale());

                                return collator.compare(nameA, nameB);
                            }

                            return a.isInCompetitiveOrg == 1 ? 1 : -1;
                        });

                    if(availableOrgs.length === 1 && !availableOrgs[0].isInCompetitiveOrg){
                        formik.setFieldValue('organisation_id', availableOrgs[0].value)
                    }

                    setData({
                        competitions: sortedCompetitions,
                        organisations: availableOrgs,
                    });
                })
        }else{
            formik.setFieldValue('competition_id', '');
            formik.setFieldValue('organisation_id', '');
            setData(null);
        }
    }, [ formik.values.mode ]);

    return (
        <>
            <ModalBody className="pt-0">
                <Label for='organisation_id' className='text-muted'>
                    <Translate id={`teams.profile.label.${formik.values.mode || 'organization'}`} />
                </Label>
                <FormikSelect
                    id='organisation_id'
                    name='organisation_id'
                    isLoading={!data}
                    renderOption={({ option }) => (
                        <>
                            <DisplayOrg org={option} />
                            {option.isInCompetitiveOrg &&
                                <div className="small text-muted">
                                    <Translate id="teams.teamSearch.alreadyParticipating" />
                                </div>
                            }
                        </>
                    )}
                    options={data?.organisations}
                    isOptionDisabled={(option) => option.isInCompetitiveOrg}
                    onOptionSelected={() => {
                        formik.setFieldValue('competition_id', '');
                    }}
                />
                <Label for='competition_id' className="text-muted mt-3"><Translate id="teams.teamSearch.competition" /> <Required /></Label>
                <FormikSelect
                    id='competition_id'
                    name='competition_id'
                    isLoading={!data}
                    searchKeys={[
                        `i18n.${getGenericLocale()}.name`,
                        `division.i18n.${getGenericLocale()}.name`,
                        `team_category.i18n.${getGenericLocale()}.name`,
                        `organisation.i18n.${getGenericLocale()}.name`,
                    ]}
                    renderSelectedOption={(option) => (
                        <>
                            <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.label} />
                            <small className="ml-1 text-muted">
                                (<DisplayTeamCategory ressource={option} />)
                            </small>
                        </>
                    )}
                    isOptionDisabled={(option) => option.isInCompetitiveOrg}
                    renderOption={({ option, isSelected }) => (
                        <>
                            <div className="small">
                                <DisplayOrg org={option.organisation} />
                            </div>
                            <div className="font-medium">
                                <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.label} />
                            </div>
                            <div className={`small text-${isSelected ? 'light' : 'muted'}`}>
                                <DisplayTeamCategory ressource={option} />
                            </div>
                            {option.isInCompetitiveOrg &&
                                <div className="small text-muted">
                                    <Translate id="teams.teamSearch.alreadyParticipating" />
                                </div>
                            }
                        </>
                    )}
                    options={getFilteredCompetitions()}
                />
                <Label for="note" className="text-muted mt-3">Note</Label>
                <FormikTextArea
                    id='note'
                    name="note"
                />
                <FormikApiError className="pb-0" />
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="primary"
                    outline
                    className="mr-auto"
                    onClick={() => formik.setFieldValue('mode', '')}
                >
                    <Translate id="misc.previous" />
                </Button>
                <Button
                    color="primary"
                    type="submit"
                >
                    <Translate id="misc.submit" />
                </Button>
                <Button
                    color="primary"
                    type="button" outline onClick={toggle}
                >
                    <Translate id="misc.cancel" />
                </Button>
            </ModalFooter>
        </>
    );
}

export default ParticipateChooseCompetition;