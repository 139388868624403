import API_SPORDLE from "../API-Spordle";
import queryString from "query-string";
import { serverError } from "../CancellableAPI";
import { jsObjectToApi } from "@spordle/helpers";

export function getTeamAvailableCompetitions(teamId: string, query: { period_id: string, division_id?: string, team_category_id?: string, organisation_category_id?: string }){
    return API_SPORDLE.get(queryString.stringifyUrl({ url: `/teams/${teamId}/available-competitions`, query }))
        .then((response) => {
            if(response.data.status){
                // Lite format
                const competitions = response.data.competitions || [];
                const organisations: Record<string, Record<string, any>> = response.data.organisations || {};
                const team_categories: Record<string, Record<string, any>> = response.data.team_categories || {};
                const divisions: Record<string, Record<string, any>> = response.data.divisions || {};
                const competition_levels: Record<string, Record<string, any>> = response.data.competition_levels || {};

                // Build resources
                const fullCompetitions = competitions.map((comp) => {
                    if(organisations[comp.organisation_id]){
                        comp.organisation = organisations[comp.organisation_id];
                        comp.organisation.organisation_id = comp.organisation_id;
                    }

                    if(competition_levels[comp.competition_level_id]){
                        comp.competition_level = competition_levels[comp.competition_level_id];
                        comp.competition_level.competition_level_id = comp.competition_level_id;
                    }

                    if(divisions[comp.division_id]){
                        comp.division = divisions[comp.division_id];
                        comp.division.division_id = comp.division_id;
                    }

                    if(team_categories[comp.team_category_id]){
                        comp.team_category = team_categories[comp.team_category_id];
                        comp.team_category.team_category_id = comp.team_category_id;
                    }

                    // Cleanup resource IDs
                    // ** must leave, integration was done using these
                    //delete comp.organisation_id;
                    //delete comp.competition_level_id;
                    //delete comp.division_id;
                    //delete comp.team_category_id;

                    return comp;
                });

                return {
                    competitions: fullCompetitions,
                    organisations: Object.entries(organisations || {}).map(([ key, val ]) => ({
                        ...val,
                        organisation_id: key,
                    })),
                    team_categories: Object.entries(team_categories || {}).map(([ key, val ]) => ({
                        ...val,
                        team_category_id: key,
                    })),
                }
            }

            throw response.data.errors;
        }, serverError)
}

export function addTeamToCompetition(values: { competition_id: string, team_id: string, submission_note?: string }) {
    const params = new URLSearchParams();

    jsObjectToApi(values, params, {
        skipEmptyString: true,
        skipNull: true
    })

    return API_SPORDLE.post(queryString.stringifyUrl({ url: `/competition-teams` }), params)
        .then((response) => {
            if(response.data.status){
                return response.data.member_id;
            }
            throw response.data.errors;
        }, serverError)
}

export function partialUpdateCompetitionTeam(competitionTeamId: string, values: { competition_id?: string, competition_team_status_id?: string, decision_note?: string, active?: '1'|'0' }) {
    const params = new URLSearchParams();

    jsObjectToApi(values, params)

    return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/competition-teams/${competitionTeamId}` }), params)
        .then((response) => {
            if(response.data.status){
                return true;
            }
            throw response.data.errors;
        }, serverError)
}

export function getValidatedQualificationRules(teamId: string){
    return API_SPORDLE.get(queryString.stringifyUrl({ url: `/teams/${teamId}/qualification-rules` }))
        .then((response) => {
            if(response.data.status){
                return response.data.category_qualification_rules;
            }
            throw response.data.errors;
        }, serverError)
}

export function getTeamCategoryQualificationRules(query: Record<string, any> = {}){
    return API_SPORDLE.get(queryString.stringifyUrl({ url: `/team-category-qualification-rules`, query: query }))
        .then((response) => {
            if(response.data.status){
                // Lite format
                const team_category_qualification_rules = response.data.team_category_qualification_rules || [];
                const team_categories: Record<string, Record<string, any>> = response.data.team_categories || {};
                const qualifications: Record<string, Record<string, any>> = response.data.qualifications || {};
                const qualification_levels: Record<string, Record<string, any>> = response.data.qualification_levels || {};
                const qualification_categories: Record<string, Record<string, any>> = response.data.qualification_categories || {};
                const positions: Record<string, Record<string, any>> = response.data.positions || {};
                const position_groups: Record<string, Record<string, any>> = response.data.position_groups || {};
                const periods: Record<string, Record<string, any>> = response.data.periods || {};
                const divisions: Record<string, Record<string, any>> = response.data.divisions || {};
                const classes: Record<string, Record<string, any>> = response.data.classes || {};

                // Build resources
                const fullTeamCategoryQualificationRules = team_category_qualification_rules.map((teamCatQualRule) => {
                    if(team_categories[teamCatQualRule.team_category_id]){
                        teamCatQualRule.team_category = team_categories[teamCatQualRule.team_category_id];
                        teamCatQualRule.team_category.team_category_id = teamCatQualRule.team_category_id;
                    }

                    if(divisions[teamCatQualRule.team_category.division_id]){
                        teamCatQualRule.team_category.division = divisions[teamCatQualRule.team_category.division_id];
                        teamCatQualRule.team_category.division.division_id = teamCatQualRule.team_category.division_id;
                    }

                    if(classes[teamCatQualRule.team_category.class_id]){
                        teamCatQualRule.team_category.class = classes[teamCatQualRule.team_category.class_id];
                        teamCatQualRule.team_category.class.class_id = teamCatQualRule.team_category.class_id;
                    }

                    if(qualifications[teamCatQualRule.qualification_id]){
                        teamCatQualRule.qualification = qualifications[teamCatQualRule.qualification_id];
                        teamCatQualRule.qualification.qualification_id = teamCatQualRule.qualification_id;
                    }

                    if(qualification_categories[teamCatQualRule.qualification.qualification_category_id]){
                        teamCatQualRule.qualification.qualification_category = qualification_categories[teamCatQualRule.qualification.qualification_category_id];
                        teamCatQualRule.qualification.qualification_category.qualification_category_id = teamCatQualRule.qualification.qualification_category_id;
                    }

                    if(qualification_levels[teamCatQualRule.qualification.qualification_level_id]){
                        teamCatQualRule.qualification.qualification_level = qualification_levels[teamCatQualRule.qualification.qualification_level_id];
                        teamCatQualRule.qualification.qualification_level.qualification_level_id = teamCatQualRule.qualification.qualification_level_id;
                    }

                    if(positions[teamCatQualRule.position_id]){
                        teamCatQualRule.position = positions[teamCatQualRule.position_id];
                        teamCatQualRule.position.position_id = teamCatQualRule.position_id;
                    }

                    if(position_groups[teamCatQualRule.position_group_id]){
                        teamCatQualRule.position_group = position_groups[teamCatQualRule.position_group_id];
                        teamCatQualRule.position_group.position_group_id = teamCatQualRule.position_group_id;
                    }

                    if(periods[teamCatQualRule.period_id]){
                        teamCatQualRule.period = periods[teamCatQualRule.period_id];
                        teamCatQualRule.period.period_id = teamCatQualRule.period_id;
                    }

                    // Cleanup resource IDs
                    delete teamCatQualRule.team_category_id;
                    delete teamCatQualRule.team_category.division_id;
                    delete teamCatQualRule.team_category.class_id;
                    delete teamCatQualRule.qualification_id;
                    delete teamCatQualRule.qualification.qualification_category_id;
                    delete teamCatQualRule.qualification.qualification_level_id;
                    delete teamCatQualRule.position_id;
                    delete teamCatQualRule.position_group_id;
                    delete teamCatQualRule.period_id;

                    return teamCatQualRule;
                });

                return fullTeamCategoryQualificationRules
            }
            throw response.data.errors;
        }, serverError)
}

export function updateTeamCategoryQualificationRules(values: Record<string, any>){
    const params = new URLSearchParams();

    jsObjectToApi(values, params)

    return API_SPORDLE.put(queryString.stringifyUrl({ url: `/team-category-qualification-rules` }), params)
        .then((response) => {
            if(response.data.status){
                return true;
            }
            throw response.data.errors;
        }, serverError)
}