import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { stringBuilder } from "@spordle/helpers";
import { AppContext } from '../../contexts/contexts';
import { Button, Fade } from 'reactstrap';

const ViewHeaderTitle = (props) => {
    const appContext = useContext(AppContext);
    const [ visibleRefreshKey, showRefreshKey ] = useState(false);
    const {
        tag: Tag,
        className,
        ...attributes
    } = props;

    return (
        <div className="d-flex" onMouseEnter={() => showRefreshKey(true)} onMouseLeave={() => showRefreshKey(false)}>
            <Tag {...attributes} className={stringBuilder('viewHeader-title', className)} />
            <Fade className='viewHeader-title' in={visibleRefreshKey}>
                <Button color="link" className='ml-1 mr-5 reset-btn font-18' type="button" onClick={appContext.updateRouteKey}><i className='mdi mdi-refresh mr-5' /></Button>
            </Fade>
        </div>
    );
};


export default ViewHeaderTitle;

ViewHeaderTitle.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
};
ViewHeaderTitle.defaultProps = {
    tag: 'div',
};