export default{
    "members.search.memberSearch.title" : "Membres",

    "members.search.memberSearch.header.dashboardView"         : "Vue du tableau de bord",
    "members.search.memberSearch.header.filter.previousSeason" : "Saison active vs saison précédente",
    "members.search.memberSearch.header.filter.allSeason"      : "Saison active vs tous",

    "members.search.memberSearch.header.addMember"  : "Ajouter un membre",
    "members.search.memberSearch.header.members"    : "Membres",
    "members.search.memberSearch.header.players"    : "Joueurs",
    "members.search.memberSearch.header.coaches"    : "Coachs",
    "members.search.memberSearch.header.officials"  : "Officiels",
    "members.search.memberSearch.header.volunteers" : "Bénévoles",

    "members.search.memberSearch.filters.organization" : "Organisation",
    "members.search.memberSearch.filters.type"         : "Type",
    "members.search.memberSearch.filters.status"       : "Statut",
    "members.search.memberSearch.filters.gender"       : "Identité de genre",
    "members.search.memberSearch.filters.from"         : "De",
    "members.search.memberSearch.filters.to"           : "À",
    "members.search.memberSearch.filters.or"           : "Ou",
    "members.search.memberSearch.filters.memberNumber" : "{unique_identifier}",

    "members.search.memberSearch.table.tabs.results" : "Résultats de recherche",
    "members.search.memberSearch.table.tabs.starred" : "Favoris",

    "members.search.memberSearch.table.starred"      : "Favoris",
    "members.search.memberSearch.table.status"       : "Statut",
    "members.search.memberSearch.table.organization" : "Organisation",

    "members.search.memberSearch.table.status.registered"   : "Enregistré",
    "members.search.memberSearch.table.status.unregistered" : "Non-Enregistré",
    "members.search.memberSearch.table.status.renew"        : "Renouveler",

    "members.search.memberSearch.table.member"             : "Membre",
    "members.search.memberSearch.table.member_status"      : "Statut",
    "members.search.memberSearch.table.memberTypes"        : "Type(s)",
    "members.search.memberSearch.table.registrationStatus" : "Statut d'inscription",
    "members.search.memberSearch.table.lastActiveSeason"   : "Dernière saison active",
    "members.search.memberSearch.table.branch"             : "Branche",
    "members.search.memberSearch.table.divClass"           : "Div / Classe",
    "members.search.memberSearch.table.team"               : "Équipe",
    "members.search.memberSearch.table.pagination.message" : "Membre {itemStartPos} à {itemEndPos} sur un total de {totalItems} membres",

    "members.search.memberSearch.table.gender.MALE"                    : "Masculin",
    "members.search.memberSearch.table.gender.FEMALE"                  : "Féminin",
    "members.search.memberSearch.table.gender.PREFER_NOT_TO_SAY"       : "Préfère ne pas répondre",
    "members.search.memberSearch.table.gender.PREFER_TO_SELF_DESCRIBE" : "Préfère se décrire eux-même",
    "members.search.memberSearch.table.gender.NON_BINARY"              : "Non binaire",

    "members.search.memberSearch.missingDocument.validate" : "Valider les documents",
    "members.search.memberSearch.missingDocument.confirm"  : "Valider les documents requis.",

    "members.search.memberSearch.unsignedCriticalWaiver.tooltip" : "Dispense critique à signer",
    "members.search.memberSearch.missingFields.tooltip"          : "Champs requis manquants",
    "members.search.memberSearch.missingFields.organization"     : "Votre organisation exige ces champs:",
    "members.search.memberSearch.unconfirmed.address.tooltip"    : "Nouvelle adresse à confirmer",
    "members.search.memberSearch.incomplete_profile.tooltip"     : "Profil de membre incomplet",
    "members.search.memberSearch.missingDocument"                : "Documents manquants",
    "members.search.memberSearch.crc.tooltip"                    : "Vérification d'antécédents n'existe pas ou est expirée.",
    "members.search.memberSearch.unconfirmed"                    : "Non confirmé",
    "members.search.memberSearch.unconfirmed.tooltip"            : "Non confirmé",

}