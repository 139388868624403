import { FormikSelect } from '@spordle/formik-elements';
import { stringBuilder } from '@spordle/helpers';
import Translate from "@spordle/intl-elements";
import React from "react";
import {
    Col, FormGroup, Label, Nav,
    NavItem,
    NavLink, Row, TabContent,
    TabPane
} from "reactstrap";
import { mutate } from 'swr';
import {
    object,
    string
} from 'yup';
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import FormikEditable from '../../../../../components/formik/FormikEditable';
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { fail, success } from '@spordle/toasts';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { PositionsContext } from '../../../../../contexts/PositionsContext';
import { QualificationCategoriesContext } from "../../../../../contexts/QualificationCategoriesContext";
import { RolesContext } from "../../../../../contexts/RolesContext";
import { TeamsContext } from '../../../../../contexts/TeamsContext';
import { displayI18n, DisplayI18n } from "../../../../../helpers/i18nHelper";
import withContexts from '../../../../../helpers/withContexts';
import TeamRosterSidePanelAttendees from '../components/list/TeamRosterSidePanelAttendees';
import TeamRosterSidePanelQualifications from '../components/list/TeamRosterSidePanelQualifications';
import TeamCommentsSidepanel from "../components/TeamCommentsSidepanel";
import TeamHistorySidepanel from "../components/TeamHistorySidepanel";
import TeamRosterRegistrationDate from '../components/TeamRosterRegistrationDate';
import TeamRosterSidepanelHeader from "../components/TeamRosterSidepanelHeader";

class TeamStaffSidepanel extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            // qualificationCatId: '',
            isLoading: false,
            activeTab: '1',

            positions: false,
            teamMemberStatus: false,
        }
    }

    initGet = async() => {
        // We are now showing all qualifications, not only coach ones, but I left the code in case we ever want to switch back

        // const qualCategories = await this.props.QualificationCategoriesContext.getQualificationCategories().catch(console.error);

        // Find coach qualification category id to only get the coaches categories
        // Is in set state to avoid doing the find each time the component updates

        // const catId = qualCategories?.find((cat) => cat.name.toUpperCase() === 'COACH')?.qualification_category_id;
        // this.setState({ qualificationCatId: catId });

        Promise.all([
            this.props.PositionsContext.getPositions(this.props.OrganizationContext.organisation_id, { active: "1" }),
            this.props.TeamsContext.getTeamMemberStatus({ active: '1', available_for: 'STAFF' }),
            this.props.MembersContext.getMemberQualifications(this.props.selectedRows[0]?.member?.member_id),
        ])
            .then(([ positions, teamMemberStatus ]) => {
                this.setState(() => ({
                    isLoading: false,
                    positions: positions.filter((pos) => pos.position_group?.position_type === 'STAFF' || pos.position_group?.position_type === 'COACH'),
                    teamMemberStatus: teamMemberStatus,
                }))
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({
                        isLoading: false,
                        positions: [],
                        teamMemberStatus: [],
                    }))
                }
            })
    }

    componentDidMount(){
        this.setState(() => ({ isLoading: true }));
        this.initGet();
    }

    releaseMember = () => {
        this.setState(() => ({ isLoading: true }))
        return this.props.TeamsContext.releaseTeamMember(this.props.TeamsContext.cachedTeam.team_id, this.props.selectedRows[0].team_member_id)
            .then(() => {
                mutate([ 'getTeamsSettings', this.props.TeamsContext.cachedTeam.team_id ]);
                success();
                this.setState(() => ({ isLoading: false }))
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    if(error.i18n){
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }else{
                        switch (error.code){
                            case '3381':// Team setting related error
                                fail({ info: <Translate id='teams.profile.settings.error.3381' values={{ data: error.origin }} />, skipInfoTranslate: true });
                            case '3494':
                                fail({ info: <Translate id='teams.profile.settings.error.3381' values={{ data: error.origin }} />, skipInfoTranslate: true });
                                break;
                            default:
                                fail();
                                break;
                        }

                    }
                    this.setState(() => ({ isLoading: false }))
                    throw error // throw error to catch it in the release modal to display the error
                }
            })
    }

    showError = (error) => {
        if(error.i18n){
            fail({
                msg: 'misc.error',
                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                skipInfoTranslate: true,
            })
        }else if(Array.isArray(error)){
            fail({
                msg: 'misc.error',
                info: <ul className='mb-0' style={{ 'paddingInlineStart': "15px" }}>{error.map((err) => <li key={err.code}>{displayI18n('message', err.i18n, err.message, this.props.I18nContext.getGenericLocale)}</li>)}</ul>,
                skipInfoTranslate: true,
            })
        }else{
            switch (error.code){
                case '3371':// Team setting related error
                case '3372':
                case '3373':
                case '3374':
                case '3375':
                case '3376':
                case '3380':
                case '3494':
                    fail({ info: <Translate id={`teams.profile.settings.error.${error.code}`} values={{ data: error.origin }} />, skipInfoTranslate: true });
                    break;
                default:
                    fail();
                    break;
            }
        }
    }

    /*--------------------------------------------------------------------------------*/
    /* Update the table row and sidepanel                                             */
    /* Do a GET specific after partial update then sync rows with new GET specific    */
    /* This keeps data live and prevents desync errors where F/E data != B/E data     */
    /*--------------------------------------------------------------------------------*/
    updateRowAndSidepanel = (teamMember, updateParams) => {
        this.setState(() => ({ isLoading: true }))
        this.props.TeamsContext.updateTeamMember(this.props.TeamsContext.cachedTeam.team_id, teamMember.team_member_id, updateParams, true)
            .then(() => {
                Promise.all([
                    this.props.TeamsContext.getTeamMembers(this.props.TeamsContext.cachedTeam.team_id, {
                        team_member_id: teamMember.team_member_id,
                    }),
                    this.props.TeamsContext.getTeam(this.props.TeamsContext.cachedTeam.team_id, true, { components: 'contact,address' }),
                ])
                    .then(([ team_members, team ]) => {
                        this.props.syncRows({ ...team_members[0], name: team_members[0].member?.first_name + ' ' + team_members[0].member?.last_name }); // name for table sorting
                        success();
                    })
            })
            .catch((errors) => {
                if(!AxiosIsCancelled(errors.message)){
                    console.error(errors.message);
                    this.showError(errors);
                }
            })
            .finally(() => this.setState(() => ({ isLoading: false })))
    }

    render(){
        const teamMember = this.props.selectedRows[0];
        const lockedRoster = this.props.lockedRoster;
        return (
            <RolesContext.Consumer>
                {({ canDoAction }) => {
                    const canEdit = (!lockedRoster || canDoAction('EDIT', 'teams', 'bypass_lock_roster_date')) && canDoAction('EDIT', 'teams', 'team_members');
                    const canEditTeamMemberStatus = (!lockedRoster || canDoAction('EDIT', 'teams', 'bypass_lock_roster_date')) && canDoAction('ADD', 'teams', 'teams_approbation');
                    return (
                        <OverlayLoader isLoading={this.state.isLoading}>
                            <TeamRosterSidepanelHeader lockedRoster={lockedRoster} teamMember={teamMember} releaseMember={this.releaseMember} from='STAFF' {...this.props} />

                            <Nav tabs>
                                <NavItem className="text-center" style={{ width: '33%' }}>
                                    <NavLink
                                        className={stringBuilder({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.setState(() => ({ activeTab: '1' })) }}
                                    >
                                        <Translate id='teams.profile.roster.sidePanel.tabs.general' />
                                    </NavLink>
                                </NavItem>
                                <NavItem className="text-center" style={{ width: '33%' }}>
                                    <NavLink
                                        className={stringBuilder({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.setState(() => ({ activeTab: '2' })) }}
                                    >
                                        <Translate id='teams.profile.roster.sidePanel.tabs.teamHistory' />
                                    </NavLink>
                                </NavItem>
                                <NavItem className="text-center" style={{ width: '34%' }}>
                                    <NavLink
                                        className={stringBuilder({ active: this.state.activeTab === '3' })}
                                        onClick={() => { this.setState(() => ({ activeTab: '3' })) }}
                                    >
                                        <Translate id='teams.profile.roster.sidePanel.teamMemberComments' />
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId='1'>

                                    <div className="p-3">
                                        <Row>
                                            <Col sm="12">
                                                <FormGroup>
                                                    <Label for='position' className='text-muted mb-0'><Translate id='teams.profile.roster.table.position' /></Label>
                                                    <FormikEditable
                                                        disabled={!canEdit}
                                                        id='position'
                                                        noConfirmation
                                                        initialValues={{
                                                            position: teamMember.position?.position_id,
                                                        }}
                                                        validationSchema={object().shape({
                                                            position: string().required(<Translate id='teams.profile.roster.table.position.required' />),
                                                        })}
                                                        onSubmit={(values) => {
                                                            if(values.position !== teamMember.position?.position_id){
                                                                this.updateRowAndSidepanel(teamMember, {
                                                                    position_id: values.position,
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        {(isEditing, options) => {
                                                            if(!isEditing){
                                                                return (
                                                                    <div className='font-medium text-dark'>
                                                                        {teamMember.position ?
                                                                            <DisplayI18n field='name' defaultValue={teamMember.position.name} i18n={teamMember.position.i18n} />
                                                                            : '-'}
                                                                    </div>
                                                                )
                                                            }
                                                            return (
                                                                <FormikSelect
                                                                    name='position' id='position'
                                                                    renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                                    autoFocus menuIsDefaultOpen
                                                                    onOptionSelected={() => options.stopEditing()}
                                                                    searchKeys={[
                                                                        `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                                    ]}
                                                                    isLoading={!this.state.positions}
                                                                    options={this.state.positions?.reduce((keptPos, pos) => {
                                                                        if(pos.active == 1){
                                                                            keptPos.push({
                                                                                value: pos.position_id,
                                                                                i18n: pos.i18n,
                                                                                label: pos.name,
                                                                            })
                                                                        }
                                                                        return keptPos;
                                                                    }, [])}
                                                                />
                                                            )

                                                        }}
                                                    </FormikEditable>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label for='teamMemberStatus' className='text-muted mb-0'><Translate id='teams.profile.roster.sidePanel.actions.teamMemberStatus.staff' /></Label>
                                                    <FormikEditable
                                                        disabled={!canEditTeamMemberStatus}
                                                        id='teamMemberStatus'
                                                        noConfirmation
                                                        initialValues={{
                                                            teamMemberStatus: teamMember.team_member_status?.team_member_status_id,
                                                        }}
                                                        validationSchema={object().shape({
                                                            teamMemberStatus: string(),
                                                        })}
                                                        onSubmit={(values) => {
                                                            if(values.teamMemberStatus !== teamMember.team_member_status?.team_member_status_id){
                                                                this.updateRowAndSidepanel(teamMember, {
                                                                    team_member_status_id: values.teamMemberStatus,
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        {(isEditing, options) => {
                                                            if(!isEditing){
                                                                return (
                                                                    <div className='font-medium text-dark'>
                                                                        {teamMember.team_member_status ?
                                                                            <DisplayI18n field='name' defaultValue={teamMember.team_member_status.name} i18n={teamMember.team_member_status.i18n} />
                                                                            : '-'}
                                                                    </div>
                                                                )
                                                            }
                                                            return (
                                                                <FormikSelect
                                                                    clearable
                                                                    name='teamMemberStatus' id='teamMemberStatus'
                                                                    renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                                    autoFocus menuIsDefaultOpen
                                                                    searchKeys={[
                                                                        `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                                    ]}
                                                                    onOptionSelected={() => options.stopEditing()}
                                                                    isLoading={!this.state.teamMemberStatus}
                                                                    options={this.state.teamMemberStatus?.map((stat) => ({
                                                                        label: stat.name,
                                                                        i18n: stat.i18n,
                                                                        value: stat.team_member_status_id,
                                                                    }))}
                                                                />
                                                            )

                                                        }}
                                                    </FormikEditable>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label for='registrationDate' className='text-muted mb-0'><Translate id='teams.profile.roster.sidePanel.actions.addedToTeam' /></Label>
                                                    <TeamRosterRegistrationDate
                                                        teamMember={teamMember}
                                                        hasPermissionToEdit={canEdit}
                                                        updateRowAndSidepanel={this.updateRowAndSidepanel}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="12">
                                                <div className="mb-3">
                                                    <TeamRosterSidePanelAttendees
                                                        memberId={this.props.selectedRows[0].member?.member_id}
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm="12">
                                                <div className="mb-3">
                                                    <TeamRosterSidePanelQualifications
                                                        memberId={this.props.selectedRows[0].member?.member_id}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </TabPane>
                                <TabPane tabId='2' className='p-3'>
                                    { this.state.activeTab == "2" &&
                                        // Avoids useless api calls
                                        <TeamHistorySidepanel teamMember={teamMember} sidePanelProps={this.props} />
                                    }
                                </TabPane>
                                <TabPane tabId="3" className="p-3">
                                    { this.state.activeTab == "3" &&
                                        <TeamCommentsSidepanel teamMember={teamMember} />
                                    }
                                </TabPane>
                            </TabContent>
                        </OverlayLoader>
                    )
                }}
            </RolesContext.Consumer>
        )
    }
}

export default withContexts(PositionsContext, MembersContext, QualificationCategoriesContext, OrganizationContext, TeamsContext, RolesContext, I18nContext)(TeamStaffSidepanel);