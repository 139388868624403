import PerfectScrollbar from 'react-perfect-scrollbar'
import SidePanelActionsMenu from './components/SidePanelActionsMenu';
import SidePanelMenuAction from './components/SidePanelMenuAction';
import SidePanelMenuDelete from './components/SidePanelMenuDelete';
import SidePanelTitle from './components/SidePanelTitle';
import SidePanelSubtitle from './components/SidePanelSubtitle';
import SidePanelToggleButton from './components/SidePanelToggleButton';
import SidePanelHeader from './components/SidePanelHeader';
import SidePanelBody from './components/SidePanelBody';
import { useContext, useEffect, useRef } from 'react';
import { QuickViewContext } from '../../contexts/QuickViewContext';
import { stringBuilder } from '@spordle/helpers';

const SidePanel = (props) => {
    const quickViewContext = useContext(QuickViewContext);

    const scrollBarRef = useRef(null);

    useEffect(() => {
        scrollBarRef.current.scrollTop = 0;
    }, [ JSON.stringify(props.children.props.selectedRows?.[0]) ])

    return (
        <>
            {props.isOpen && props.overlayToggle &&
            <div
                className="sidePanel-backdrop" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.overlayToggle();
                }}
            />
            }
            <aside className={`${window.self !== window.top ? 'pt-0 mt-0' : ''} ${props.isOpen ? 'sidePanel isOpen' : 'sidePanel'}`}>
                {/* TODO: sidepanel's header should go here,
                so we wont need the position-sticky and
                perfectscrollbar won't get under the header */}
                <PerfectScrollbar containerRef={(ref) => scrollBarRef.current = ref}>
                    <div className={stringBuilder('pb-5 mb-5', { 'mt-5': quickViewContext.inIframe })}>
                        {props.isOpen &&
                            props.children
                        }
                    </div>
                </PerfectScrollbar>
            </aside>
        </>
    )
};

SidePanel.ActionsMenu = SidePanelActionsMenu;
SidePanel.ToggleButton = SidePanelToggleButton;
SidePanel.MenuDelete = SidePanelMenuDelete;
SidePanel.MenuAction = SidePanelMenuAction;
SidePanel.Title = SidePanelTitle;
SidePanel.Subtitle = SidePanelSubtitle;
SidePanel.Header = SidePanelHeader;
SidePanel.Body = SidePanelBody;

export default SidePanel;
