import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import moment from 'moment';
import { object, mixed, string } from 'yup';
import {
    Row,
    Col,
    Button,
    ModalBody,
    Collapse,
    Alert,
    ModalFooter
} from "reactstrap";
import { Form, Formik } from 'formik';
import { FormikDateTime, FormikInputText } from '@spordle/formik-elements';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Contexts
import withContexts from '../../../../../helpers/withContexts';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { RolesContext } from '../../../../../contexts/RolesContext';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import UserImg from '../../../../../components/UserImg';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import BtnDisplay from '../../../../../components/input/BtnDisplay';
import { Link } from 'react-router-dom';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import QuickViewButton from '../../../../../components/quickView/QuickViewButton';
import InlineCopy from '../../../../../components/inlineCopy/InlineCopy';
import CanDoAction from '../../../../../components/permissions/CanDoAction';
import { stringBuilder } from '@spordle/helpers';
import MemberFieldLabel from '../../../../../components/memberRegistration/MemberFieldLabel';
import DisplayBirthdateAge from '../../../../../components/DisplayBirthdateAge';

const MemberAddForm1 = (props) => {
    const { canDoAction } = useContext(RolesContext);
    const { federation } = useContext(IdentityRolesContext);
    const { organisation_id, settings } = useContext(OrganizationContext);
    const { getMembersElasticSearch } = useContext(MembersContext);
    const [ searchResults, setSearchResults ] = useState(null);
    const canReadIneligibleMembers = canDoAction("READ", "members", "manage_ineligible_members");

    const isValidDob = (dob) => {
        const minDateOfBirth = moment().subtract(parseInt(settings.member_min_age?.value || "0"), "year");
        const maxDateOfBirth = moment().subtract(parseInt(settings.member_max_age?.value || "0"), "year");
        return (!settings.member_min_age || dob.isSameOrBefore(minDateOfBirth)) && (!settings.member_max_age || dob.isAfter(maxDateOfBirth));
    }

    return (
        <Formik
            initialValues={{
                firstName: props.memberInfo?.firstName || '',
                lastName: props.memberInfo?.lastName || '',
                dateOfBirth: props.memberInfo?.dateOfBirth || '',
            }}
            validationSchema={object().shape({
                firstName: string().required(<Translate id='form.validation.firstName.required' />).matches(/^[a-zA-ZÀ-ÿ-'.]+( [a-zA-ZÀ-ÿ-'.]+)*$/, { message: <Translate id='members.search.memberAdd.firstName.regex' /> }),
                lastName: string().required(<Translate id='form.validation.lastName.required' />).matches(/^[a-zA-ZÀ-ÿ-'.]+( [a-zA-ZÀ-ÿ-'.]+)*$/, { message: <Translate id='members.search.memberAdd.lastName.regex' /> }),
                dateOfBirth: mixed()
                    .test({
                        name: 'dob-validation',
                        message: <Translate id='form.validation.dateOfBirth.valid' />,
                        test: function(dob){
                            if(dob){ // There is something
                                return moment.isMoment(dob);
                            }
                            return true;// Because it is optional
                        },
                    })
                    .test({
                        name: 'dob-before',
                        message: <Translate id='members.search.memberAdd.dateOfBirth.minDate' />,
                        test: function(dob){
                            if(dob){ // There is something
                                return moment.isMoment(dob) && dob.isBefore(new Date());
                            }
                            return true;// Because it is optional
                        },
                    })
                    .test({
                        name: 'age-settings',
                        message: "age_settings",
                        test: function(dob){
                            if(moment.isMoment(dob)){
                                return isValidDob(dob)
                            }

                            return true;
                        },
                    }),
            })}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                props.setLoading(true);

                const searchParams = {
                    //all: 1,
                    organisation_id: federation.organisation_id,
                    target_organisation_id: organisation_id,
                }

                if(values.firstName) searchParams.first_name = values.firstName
                if(values.lastName) searchParams.last_name = values.lastName
                if(values.dateOfBirth) searchParams.birthdate = moment(values.dateOfBirth).format("YYYY-MM-DD")

                // Search through system members
                getMembersElasticSearch(searchParams)
                    .then((members) => {
                        if(members && Array.isArray(members) && members.length > 0){
                            setSearchResults(members);
                            props.setLoading(false);
                            setSubmitting(false);
                        }else
                            props.startCreateMember(values); // Create a new user with the values inputed
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            setSubmitting(false);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            props.setLoading(false);
                        }
                    });
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <Row form>
                            <Col sm="6" className="mb-3">
                                <MemberFieldLabel for='firstName' field='first_name' isRequired fallbackLabel={<Translate id='form.fields.firstName' />} />
                                <FormikInputText id='firstName' name='firstName' trim />
                            </Col>
                            <Col sm="6" className="mb-3">
                                <MemberFieldLabel for='lastName' field='last_name' isRequired fallbackLabel={<Translate id='form.fields.lastName' />} />
                                <FormikInputText id='lastName' name='lastName' trim />
                            </Col>
                            <Col sm="6" className="mb-3">
                                <MemberFieldLabel for='dateOfBirth' field='birthdate' isRequired fallbackLabel={<Translate id='form.fields.dateOfBirth' />} />
                                <FormikDateTime
                                    name='dateOfBirth'
                                    id='dateOfBirth'
                                    translatePlaceholder={false}
                                    timeFormat={false}
                                    initialViewDate={settings.member_min_age ? moment().subtract(parseInt(settings.member_min_age?.value || "0"), "year") : void 0}
                                    manualError={formik.errors?.dateOfBirth === "age_settings"}
                                    isValidDate={(current) => moment(current).isBefore(moment()) && isValidDob(moment(current))}
                                />
                                {(!!settings.member_max_age || !!settings.member_min_age) &&
                                    <small className={`text-${formik.errors?.dateOfBirth === "age_settings" ? "danger" : "muted"}`}>
                                        <Translate id="misc.maxMinAge" values={{ max: settings.member_max_age.value || "-", min: settings.member_min_age.value || "-" }} />
                                    </small>
                                }
                            </Col>
                        </Row>
                        <Collapse className="mt-3" isOpen={searchResults !== null}>
                            {searchResults &&
                                <div className="border-top">
                                    <div className="font-weight-bold text-dark my-2">
                                        <Translate id='members.search.memberAdd.foundSearchResults' values={{ amount: searchResults.length }} />
                                    </div>
                                    <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: "380px" }} className='px-3 mx-n3'>
                                        {searchResults.map((member) => (
                                            <SearchMemberCard
                                                key={member.member_id}
                                                member={member}
                                                canReadIneligibleMembers={canReadIneligibleMembers}
                                            />
                                        ))}
                                    </PerfectScrollbar>
                                    {formik.values.firstName && formik.values.lastName &&
                                        <>
                                            <hr />
                                            <BtnDisplay
                                                icon='addMember'
                                                svgProps={{ viewBox: "0 0 50 50" }}
                                                title={<Translate id='members.search.memberAdd.createMember' />}
                                                subtitle={(
                                                    <Translate
                                                        id='members.search.memberAdd.createWithName'
                                                        values={{ lastName: formik.values.lastName, firstName: formik.values.firstName }}
                                                    />
                                                )}
                                                onClick={() => props.startCreateMember(formik.values)}
                                            />
                                        </>
                                    }
                                </div>
                            }
                        </Collapse>

                        <Collapse mountOnEnter unmountOnExit isOpen={!!formik.status}>
                            <Alert color='danger'>
                                {formik.status}
                            </Alert>
                        </Collapse>
                    </ModalBody>
                    <ModalFooter className='bg-white'>
                        <Button type="submit" color="primary" disabled={formik.isSubmitting}><Translate id='misc.search' /></Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
}

const SearchMemberCard = ({ member, getLink, canReadIneligibleMembers }) => (
    <UserDisplay className={stringBuilder("mb-2 w-100", { "bg-light": member.current_member_status === "INELIGIBLE" })} card>
        <UserDisplay.Container>
            <UserImg
                width={40}
                abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                alt={member.first_name + ' ' + member.last_name}
                src={member.picture?.full_path}
                filePos={member.picture?.file_position}
            />
        </UserDisplay.Container>
        <UserDisplay.Container>
            <UserDisplay.Title><QuickViewButton className="d-flex align-items-center" member={member}>{member.first_name + ' ' + member.last_name}</QuickViewButton></UserDisplay.Title>
            <UserDisplay.Subtitle>
                <InlineCopy toCopy={member.unique_identifier}>#{member.unique_identifier}</InlineCopy>
            </UserDisplay.Subtitle>
            <UserDisplay.Subtitle>
                <DisplayI18n
                    field='name'
                    defaultValue={member.organisation.organisation_name}
                    i18n={member.organisation.i18n}
                />
            </UserDisplay.Subtitle>
            <UserDisplay.Subtitle><DisplayBirthdateAge birthdate={member.birthdate} age={member.age} /></UserDisplay.Subtitle>

            {member.current_member_status === "INELIGIBLE" &&
                <>
                    <UserDisplay.Subtitle className="text-danger"><i className='mdi mdi-information-outline' /> <Translate id="teams.profile.roster.table.status.INELIGIBLE" />
                        {(member.current_member_status_note) &&
                            <CanDoAction tag="div" action='READ' componentCode='members' componentPermissionCode='manage_ineligible_members'>
                                {member.current_member_status_note}
                            </CanDoAction>
                        }
                    </UserDisplay.Subtitle>
                </>
            }
        </UserDisplay.Container>
        <UserDisplay.Container className="ml-auto">
            {(member.current_member_status !== "INELIGIBLE" || canReadIneligibleMembers) &&
                <Link to={`/members/profile/${member.member_id}`} className={(member.current_member_status === "INELIGIBLE" && !canReadIneligibleMembers) ? 'text-purple' : ''}><Translate id='misc.goToProfile' /> <i className="mdi mdi-chevron-right" /></Link>
            }
        </UserDisplay.Container>
    </UserDisplay>
)

export default withContexts(OrganizationContext, MembersContext, RolesContext, IdentityRolesContext)(MemberAddForm1);