import { FormikSwitch, FormikTextArea } from "@spordle/formik-elements";
import { useSpordleTable } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext } from "react";
import { Alert, Button, Collapse, FormGroup, Label, ModalBody, ModalFooter } from "reactstrap";
import { AddSupervisionFormContext } from "../components/AddSupervisionFormContext";
import { object, string, bool } from "yup";
import moment from "moment";
import { SupervisionsContext } from "../../../../../../contexts/SupervisionsContext";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";

const StepComments = () => {
    const { formData, previous, setLoading, toggle } = useContext(AddSupervisionFormContext);
    const { refreshTable } = useSpordleTable();
    const { createSupervision, sendSupervisionEmail } = useContext(SupervisionsContext);

    return (
        <Formik
            initialValues={{
                strengths: "",
                areas_of_improvements: "",
                comments: "",
                send_now: false,
            }}
            validationSchema={object().shape({
                strengths: string(),
                areas_of_improvements: string(),
                comments: string(),
                send_now: bool(),
            })}
            onSubmit={(values, { setStatus }) => {
                setLoading(true);

                const apiData = {
                    period_id: formData.global.period_id,
                    official_id: formData.official.member_id,
                    officiating_coach_id: formData.officiatingCoach.member_id,
                    supervision_type_id: formData.type.supervision_type_id,
                    league_name: formData.global.league,
                    official_type: formData.global.official_type,
                    team_category_id: formData.global.team_category_id,
                    supervision_date: moment.isMoment(formData.global.date) ? moment(formData.global.date).format('YYYY-MM-DD') : null,
                    private_comment: formData.global.private_comment,
                    away_team_name: formData.global.away_team_name,
                    home_team_name: formData.global.home_team_name,
                    ...values,
                    ...formData.levels,
                };

                createSupervision(apiData)
                    .then(async(supervision_id) => {
                        if(values.send_now){
                            await sendSupervisionEmail(supervision_id)
                                .catch(console.error);
                        }

                        refreshTable();
                        toggle();
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            setLoading(false);
                            console.error(e);
                            setStatus({
                                isShown: true,
                                msg: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />,
                            });
                        }
                    });
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody className="pt-0">
                        <FormGroup>
                            <Label className="text-muted" for="comments"><Translate id='officialSupervision.strengths.label' /></Label>
                            <FormikTextArea
                                id="strengths"
                                name="strengths"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="text-muted" for="comments"><Translate id='officialSupervision.areaOfImprovement.label' /></Label>
                            <FormikTextArea
                                id="areas_of_improvements"
                                name="areas_of_improvements"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="text-muted" for="comments"><Translate id='officialSupervision.comments.label' /></Label>
                            <FormikTextArea
                                id="comments"
                                name="comments"
                            />
                        </FormGroup>
                        <FormGroup className="border-top pt-3 d-flex align-items-center">
                            <FormikSwitch
                                name="send_now"
                                id="send_now"
                            />
                            <Label for="send_now" className="text-muted mb-0 ml-2"><Translate id='officialSupervision.sendEmail.help' /></Label>
                        </FormGroup>
                        {formik.status?.msg &&
                            <Collapse isOpen={formik.status.isShown}>
                                <Alert color="danger" toggle={() => formik.setStatus({ ...formik.status, isShown: false })}>
                                    {formik.status.msg}
                                </Alert>
                            </Collapse>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={previous} className="mr-auto w-50 w-md-auto" outline type="button" color="primary">
                            <Translate id="misc.previous" />
                        </Button>
                        <Button className="w-50 w-md-auto" color="primary" type="submit">
                            <Translate id="misc.submit" />
                        </Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
}

export default StepComments;