import { FormikDateTime, FormikInputText, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { useFormikContext } from "formik";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Col, Label, Row } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../../../../api/CancellableAPI";
import Required from "../../../../../../../../components/formik/Required";
import { PeriodsContext } from "../../../../../../../../contexts/contexts";
import { I18nContext } from "../../../../../../../../contexts/I18nContext";
import { RegistrationCategoriesContext } from "../../../../../../../../contexts/RegistrationCategoriesContext";
import { RegistrationClassesContext } from "../../../../../../../../contexts/RegistrationClassesContext";
import { RegistrationDivisionsContext } from "../../../../../../../../contexts/RegistrationDivisionsContext";
import { DisplayI18n } from "../../../../../../../../helpers/i18nHelper";
import { BC_ID } from "../../../../gameIncidentHelper";
import StepTitle from "../../../components/StepTitle";
import { fail } from "@spordle/toasts";

const StepMatchInnerForm = ({ member }) => {
    const { setFieldValue, values, setStatus } = useFormikContext();
    const { getOrganizationPeriods: getPeriods } = useContext(PeriodsContext);
    const { getRegistrationClasses: getClasses } = useContext(RegistrationClassesContext);
    const { getRegistrationDivisions: getDivisions } = useContext(RegistrationDivisionsContext);
    const { getRegistrationCategories: getTeamCategories } = useContext(RegistrationCategoriesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const [ ressources, setRessources ] = useState({
        classes: null,
        divisions: null,
        teamCat: false, // false disables
    });

    const getCategoryLabel = (option) => (
        <>
            <DisplayI18n
                field="short_name"
                defaultValue={option.option.division?.short_name}
                i18n={option.option.division?.i18n}
            />
            {option.option.class?.class_id &&
                <>
                    {' - '}
                    <DisplayI18n
                        field="short_name"
                        defaultValue={option.option.class.short_name}
                        i18n={option.option.class.i18n}
                    />
                </>
            }
        </>
    )

    /**
     * @description function that updates the data of the ressources
     * @param {object} newData
     */
    const updateData = (newData) => { setRessources((prev) => ({ ...prev, ...newData })) };


    /**
     * @description Generic function that sets a formik status error on catch
     * @param {Error} e
     */
    const handleCatch = (error) => {
        if(!AxiosIsCancelled(error.message)){
            console.error(error.message)
            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
        }
    }


    /**
     * @description Sorts a ressource array by display_order
     * @param {{display_order: string|number}[]} ressource
     * @returns {{display_order: string|number}[]} sorted ressource
     */
    const sortRessource = (ressource) => ressource.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order));


    /**
     * CLASS_ID, DIVISION_ID, PERIOD_ID USE EFFECT
     * Gets the team categories when there's a class id or a division id
     */
    useEffect(() => {
        if(values.period_id && (values.class_id || values.division_id)){
            updateData({ teamCat: null });
            getTeamCategories(
                BC_ID,
                {
                    period_id: values.period_id,
                    class_id: values.class_id,
                    division_id: values.division_id,
                    limit_to_my_organisation: 1,
                },
            )
                .then((categories) => {
                    updateData({ teamCat: sortRessource(categories) });
                })
                .catch(handleCatch)
        }
    }, [ values.class_id, values.division_id ]);

    /**
     * ON MOUNT USE EFFECT
     */
    useEffect(async() => {
        const per = await getPeriods(BC_ID).catch(handleCatch);
        const currPerId = per.find((p) => p.current == 1)?.period_id;
        const classes = await getClasses(BC_ID, { period_id: currPerId })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });
        const divs = await getDivisions(BC_ID, { period_id: currPerId })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });

        setFieldValue('period_id', currPerId);
        updateData({
            classes: sortRessource(classes),
            divisions: sortRessource(divs),
        });
    }, []);

    return (
        <>
            <StepTitle title='gameIncident.addModal.steps.match.title' />
            <Row form>
                <Col xs="6" md="4" className="form-group">
                    <Label for="division_id" className="text-muted"><Translate id='gameIncident.addModal.label.division' /> <Required /></Label>
                    <FormikSelect
                        isLoading={ressources.divisions === null}
                        name='division_id'
                        id='division_id'
                        searchKeys={[
                            `i18n.${getGenericLocale()}.short_name`,
                        ]}
                        options={ressources.divisions ?
                            ressources.divisions.map((div) => ({
                                value: div.division_id,
                                i18n: div.i18n,
                                label: div.short_name,
                            }))
                            :
                            []
                        }
                        renderOption={(opt) => (
                            <DisplayI18n
                                field="short_name"
                                i18n={opt.option.i18n}
                                defaultValue={opt.option.label}
                            />
                        )}
                    />
                </Col>
                <Col xs="6" md="4" className="form-group">
                    <Label for="class_id" className="text-muted"><Translate id='gameIncident.addModal.label.class' /></Label>
                    <FormikSelect
                        isLoading={ressources.classes === null}
                        clearable
                        searchKeys={[
                            `i18n.${getGenericLocale()}.short_name`,
                        ]}
                        name='class_id'
                        id='class_id'
                        options={ressources.classes ?
                            ressources.classes.map((theClass) => ({
                                value: theClass.class_id,
                                i18n: theClass.i18n,
                                label: theClass.short_name,
                            }))
                            :
                            []
                        }
                        renderOption={(opt) => (
                            <DisplayI18n
                                field="short_name"
                                i18n={opt.option.i18n}
                                defaultValue={opt.option.label}
                            />
                        )}
                    />
                </Col>
                <Col xs="6" md="4" className="form-group">
                    <Label for="team_category_id" className="text-muted"><Translate id='gameIncident.addModal.label.category' /> <Required /></Label>
                    <FormikSelect
                        disabled={ressources.teamCat === false}
                        isLoading={ressources.teamCat === null}
                        name='team_category_id'
                        id='team_category_id'
                        searchKeys={[
                            `division.i18n.${getGenericLocale()}.short_name`,
                            `class.i18n.${getGenericLocale()}.short_name`,
                        ]}
                        renderOption={getCategoryLabel}
                        options={ressources.teamCat ?
                            ressources.teamCat.map((cat) => (
                                {
                                    label: cat.name,
                                    value: cat.team_category_id,
                                    division: cat.division,
                                    class: cat.class,
                                }
                            ))
                            :
                            []
                        }
                    />
                </Col>
                <Col xs="6" className="form-group">
                    <Label for="game_incident_date" className="text-muted"><Translate id='gameIncident.addModal.label.date' /> <Required /></Label>
                    <FormikDateTime
                        name="game_incident_date"
                        timeFormat={false}
                        dateFormat='YYYY-MM-DD'
                        isValidDate={(current) => moment(current).isSameOrBefore(moment())}
                    />
                </Col>
                <Col sm="6" className="form-group">
                    <Label for="league_name" className="text-muted"><Translate id='gameIncident.addModal.label.league' /></Label>
                    <FormikInputText name="league_name" />
                </Col>
                <Col xs="6" className="form-group">
                    <Label for="location_name" className="text-muted"><Translate id='gameIncident.addModal.label.location' /></Label>
                    <FormikInputText name="location_name" />
                </Col>
                <Col xs="6" className="form-group">
                    <Label className="text-muted"><Translate id='gameIncident.addModal.label.game' /></Label>
                    <FormikInputText name="game_number" />
                </Col>
                <Col sm="12" className="form-group">
                    <Label className="text-muted" for="game_incident_memo"><Translate id='gameIncident.addModal.label.note' /></Label>
                    <FormikTextArea name="game_incident_memo" />
                </Col>
            </Row>
        </>
    )
}

export default StepMatchInnerForm;