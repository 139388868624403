export default{
    "registration.settings.category.title" : "Registration Settings", //'Categories, Divisions, Classes',

    "registration.settings.category.table.gender.male"   : "Male",
    "registration.settings.category.table.gender.female" : "Female",
    "registration.settings.category.table.gender.mixed"  : "Mixed",

    "registration.settings.category.sidePanel.title"                  : "Registration category",
    "registration.settings.category.sidePanel.disableAll"             : "You are about to disable this category for every season. Are you sure?",
    "registration.settings.category.sidePanel.enableAll"              : "You are about to enable this category for every season. Are you sure?",
    "registration.settings.category.sidePanel.classDisabled"          : "This class is inactive. Please choose another.",
    "registration.settings.category.sidePanel.divisionDisabled"       : "This division is inactive. Please choose another",
    "registration.settings.category.sidePanel.parentCategoryDisabled" : "This category has been deactivated by the organisation. Please choose another if you wish this category to be accessible.",
    "registration.settings.category.sidePanel.delete"                 : "You are about to delete this category",

    "registration.settings.category.modal.title" : "Add category",

    "registration.settings.category.form.division.required"       : "Please select a division.",
    "registration.settings.category.form.parentCategory.required" : "Please choose an equivalent category.",
    "registration.settings.category.form.minAge.required"         : "Please indicate a minimum age.",
    "registration.settings.category.form.maxAge.required"         : "Please indicate a maximum age.",
    "registration.settings.category.form.gender.required"         : "Please indicate a gender identity.",
    "registration.settings.category.form.minAge.smaller"          : "Minimum age must be smaller than or equal to maximum age.",
    "registration.settings.category.form.maxAge.bigger"           : "Maximum age must be greater than or equal to minimum age.",
    "registration.settings.category.form.maxAge.requirements"     : "The maximum age must be smaller than the maximum age of the parent category ({age}).",
    "registration.settings.category.form.minAge.requirements"     : "The minimum age must be bigger than the minimum age of the parent category ({age}).",

    "registration.settings.category.label.gender"                : "Gender Identity",
    "registration.settings.category.label.class"                 : "Classes",
    "registration.settings.category.label.name"                  : "Category name",
    "registration.settings.category.label.name.suggestion"       : "Suggested name",
    "registration.settings.category.label.organization"          : "Organization",
    "registration.settings.category.label.category"              : "Categories",
    "registration.settings.category.label.division"              : "Divisions",
    "registration.settings.category.label.minAge"                : "Min age",
    "registration.settings.category.label.birthYear"             : "Birth year ",
    "registration.settings.category.label.birthYearTo"           : "Birth year to",
    "registration.settings.category.label.maxAge"                : "Max age",
    "registration.settings.category.label.birthYearFrom"         : "Birth year from",
    "registration.settings.category.label.type"                  : "Type",
    "registration.settings.category.label.startDate"             : "As of",
    "registration.settings.category.label.startDate.value"       : "December 31",
    "registration.settings.category.label.federation.equivalent" : "{name} Equivalence",
    "registration.settings.category.label.settings"              : "Settings",

    "registration.settings.category.label.federation.division.equivalent" : "{name} division equivalent",
    "registration.settings.category.label.federation.linkWith"            : "Link with {name}",
}