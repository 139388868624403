import Translate, { DateFormat } from '@spordle/intl-elements';
import { success, fail } from '@spordle/toasts';
import { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Alert, Badge, Col, Fade, Row } from 'reactstrap';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import ConfirmModal from '../../../../components/confirmModal/ConfirmModal';
import HasAccessTo from '../../../../components/permissions/HasAccessTo';
import SidePanel from '../../../../components/sidePanel/SidePanel';
import { ClinicsContext } from '../../../../contexts/ClinicsContext';
import { IdentityRolesContext } from '../../../../contexts/IdentityRolesContext';
import { MembersContext } from '../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { RolesContext } from '../../../../contexts/RolesContext';
import { HoverableCopy } from '../../../../helpers/clipboardHelper';
import CancelWaitlistMemberModal from '../../../clinics/profile/waitlist/sidePanel/modals/CancelWaitlistMemberModal';
import SidePanelMemberSearch from '../../../members/search/SidePanelMemberSearch';
import queryString from 'query-string';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import QuickViewButton from '../../../../components/quickView/QuickViewButton';
import QuickViewActionButton from '../../../../components/quickView/QuickViewActionButton';
import SidePanelWaitingListMulti from './SidePanelWaitingListMulti';

const WaitingListSidePanel = (props) => {
    const membersContext = useContext(MembersContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const rolesContext = useContext(RolesContext);
    const organizationContext = useContext(OrganizationContext);
    const { approveWaitlistMember, resetWaitlistMember } = useContext(ClinicsContext);
    const history = useHistory()

    const [ cancelModalOpen, setCancelModalOpen ] = useState(false);
    const toggleCancelModal = () => { setCancelModalOpen(!cancelModalOpen) };

    return (

        <Fade in>
            {(props.selectedRows.length <= 1) ?

                <>
                    <div className='sticky-top bg-white z-index-1'>
                        <SidePanel.Header noBorder>
                            <div className='d-flex mb-2 align-items-center'>
                                <SidePanel.ToggleButton />
                                <div className="ml-auto d-flex align-items-center">
                                    <HasAccessTo componentCode='members' componentPermissionCode='members_profile'>
                                        <Link to={"/members/profile/" + props.selectedRows[0].member.member_id} className="mr-2">
                                            <Translate id='misc.goToProfile' /><i className="mdi mdi-chevron-right ml-1" />
                                        </Link>
                                    </HasAccessTo>
                                    <SidePanel.ActionsMenu>
                                        {!props.selectedRows[0].identity ?
                                            <SidePanel.MenuAction
                                                onClick={() => {
                                                    const parsedURL = queryString.stringifyUrl({
                                                        url: `/members/profile/${props.selectedRows[0].member.member_id}/registrations`,
                                                        fragmentIdentifier: 'waiting-list-return',
                                                        query: {
                                                            registrationFeeId: props.selectedRows[0].registration_fee.registration_fee_id,
                                                            memberId: props.selectedRows[0].member.member_id,
                                                            waitingListNumber: props.selectedRows[0].waiting_list_item_sequential_number,
                                                        },
                                                    })
                                                    history.push(parsedURL)
                                                }}
                                                disabled={props.selectedRows[0].status !== 'PENDING' || !rolesContext.canDoAction('ADD', 'members', 'members_registrations')}
                                            >
                                                <Translate id='task.waitlist.accept' />
                                            </SidePanel.MenuAction>
                                            :
                                            <ConfirmModal
                                                color='success'
                                                icon='mdi-check-circle-outline'
                                                modalTitle='task.waitlist.accept.title'
                                                translateModalTitle
                                                actionButtonText='misc.confirm'
                                                translateActionButtonText
                                                onConfirm={async() => {
                                                    return approveWaitlistMember(props.selectedRows[0].waiting_list_item_sequential_number)
                                                        .then(() => {
                                                            props.tableRef.refreshTable();
                                                            props.forceCloseSidePanel();
                                                            success();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                }}
                                                toggler={(onClick) => (
                                                    <SidePanel.MenuAction onClick={onClick} disabled={props.selectedRows[0].status !== 'PENDING'}>
                                                        <Translate id='task.waitlist.accept' />
                                                    </SidePanel.MenuAction>
                                                )}
                                            />
                                        }

                                        <ConfirmModal
                                            color='danger'
                                            icon='mdi-autorenew'
                                            modalTitle='task.waitlist.reset'
                                            modalMsg='task.waitlist.reset.text'
                                            translateModalTitle
                                            translateModalMsg
                                            actionButtonText='misc.confirm'
                                            translateActionButtonText
                                            onConfirm={async() => {
                                                return resetWaitlistMember(props.selectedRows[0].waiting_list_item_sequential_number)
                                                    .then(() => {
                                                        props.tableRef.refreshTable();
                                                        props.forceCloseSidePanel();
                                                        success();
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                            }}
                                            toggler={(onClick) => (
                                                <SidePanel.MenuAction onClick={onClick} disabled={props.selectedRows[0].status !== 'CANCELED'}>
                                                    <Translate id='task.waitlist.reset' />
                                                </SidePanel.MenuAction>
                                            )}
                                        />

                                        <SidePanel.MenuAction
                                            onClick={toggleCancelModal}
                                            disabled={props.selectedRows[0].status !== 'PENDING' && props.selectedRows[0].status !== 'WAITING_FOR_CLIENT'}
                                        >
                                            <CancelWaitlistMemberModal
                                                isOpen={cancelModalOpen}
                                                toggle={toggleCancelModal}
                                                waiting_list_item_sequential_number={props.selectedRows[0].waiting_list_item_sequential_number}
                                                onSuccess={() => {
                                                    props.tableRef.refreshTable();
                                                    props.forceCloseSidePanel();
                                                }}
                                            />
                                            <Translate id='task.waitlist.cancel' />
                                        </SidePanel.MenuAction>
                                        <QuickViewActionButton member={props.selectedRows[0].member} />
                                    </SidePanel.ActionsMenu>
                                </div>
                            </div>
                            <SidePanel.Title>
                                <QuickViewButton member={props.selectedRows[0].member}>
                                    {props.selectedRows[0].member.first_name} {props.selectedRows[0].member.last_name}
                                </QuickViewButton>
                            </SidePanel.Title>
                            <SidePanel.Subtitle className="mr-4 d-flex align-items-center">
                                <HoverableCopy className="text-medium" toCopy={props.selectedRows[0].member.unique_identifier}>
                                    <Link className="text-nowrap" to={`/members/profile/${props.selectedRows[0].member.member_id}`}>
                                        {props.selectedRows[0].member.unique_identifier ?
                                            <>#{props.selectedRows[0].member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                            : '-'}
                                    </Link>
                                </HoverableCopy>
                            </SidePanel.Subtitle>

                            {props.selectedRows[0].status === 'CONVERTED' ?
                                <Alert className="mb-0 mt-3 px-2 position-relative" color="success">
                                    <div className="d-flex align-items-center">
                                        <div className="mr-2">
                                            <i className="font-22 mdi mdi-check" />
                                        </div>
                                        <div className="font-medium">
                                            <Translate id='task.waitlist.status.alert.title' />
                                            <small className="d-block text-body">
                                                <Translate
                                                    id='task.waitlist.status.alert.text'
                                                    values={{
                                                        date: <DateFormat value={props.selectedRows[0].invoice?.invoice_date} format='YYYY-MM-DD HH:mm' />,
                                                    }}
                                                />
                                            </small>
                                        </div>
                                    </div>
                                </Alert>
                                :
                                <Badge color={props.getBadgeColour(props.selectedRows[0].status)}><Translate id={`task.waitlist.status.${props.selectedRows[0].status}`} /></Badge>
                            }
                        </SidePanel.Header>
                    </div>
                    <SidePanelMemberSearch
                        {...props}
                        MembersContext={membersContext}
                        IdentityRolesContext={identityRolesContext}
                        OrganizationContext={organizationContext}
                        selectedRows={[ {
                            ...props.selectedRows[0],
                            ...props.selectedRows[0].member,
                        } ]}
                        hideHeader
                    />
                    {props.selectedRows[0].cancellation_reason &&
                <div className="ml-3 pt-3 border-top">
                    <div className="h4 font-bold mb-3"><Translate id='task.waitlist.status.CANCELLED' /></div>
                    <Row>
                        <Col sm="6" className="mb-3">
                            <div className='text-muted'><Translate id='task.waitlist.cancelReason.label' /></div>
                            <div>{props.selectedRows[0].cancellation_reason}</div>
                        </Col>
                    </Row>
                </div>
                    }
                </>
                :
                <SidePanelWaitingListMulti
                    members={props.selectedRows.map((row) => (row.member))}
                    {...props}
                />
            }
        </Fade>
    );
};

export default WaitingListSidePanel;
