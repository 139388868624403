import TeamSettingsList from '../../views/settings/teams/TeamSettingsList';
import TeamSettingsConfiguration from '../../views/settings/teams/configuration/TeamSettingsConfiguration';
import TeamSettingsRestrictions from '../../views/settings/teams/restrictions/TeamSettingsRestrictions';
import teamSettingsRestrictionsRoutes from './teamSettings/teamSettingsRestrictionsRoutes';
import teamSettingsTeamContactTypesRoutes from './teamSettings/teamSettingsTeamContactTypesRoutes';
import teamSettingsTeamTravelTypesRoutes from './teamSettings/teamSettingsTeamTravelTypesRoutes';
import teamSettingsTeamCategoryTypesRoutes from './teamSettings/teamSettingsTeamCategoryTypesRoutes';
import teamSettingsTeamAddressTypesRoutes from './teamSettings/teamSettingsTeamAddressTypesRoutes';
import teamSettingsPositionGroupsRoutes from './teamSettings/teamSettingsPositionGroupsRoutes';
import teamSettingsPositionsRoutes from './teamSettings/teamSettingsPositionsRoutes';
import teamSettingsSuspensionsRoutes from './teamSettings/teamSettingsSuspensionsRoutes';
import TeamSettingsPositionGroups from '../../views/settings/teams/ressources/positionGroups/TeamSettingsPositionGroups';
import TeamSettingsPositions from '../../views/settings/teams/ressources/positions/TeamSettingsPositions';
import TeamSettingsSuspensions from '../../views/settings/teams/ressources/suspensions/TeamSettingsSuspensions';
import TeamSettingsTeamAddressTypes from '../../views/settings/teams/ressources/teamAddressTypes/TeamSettingsTeamAddressTypes';
import TeamSettingsTeamCategoryTypes from '../../views/settings/teams/ressources/teamCategoryTypes/TeamSettingsTeamCategoryTypes';
import TeamSettingsTeamTravelTypes from '../../views/settings/teams/ressources/teamTravelTypes/TeamSettingsTravelTypes';
import TeamSettingsTeamContactTypes from '../../views/settings/teams/ressources/teamContactTypes/TeamSettingsContactTypes';
import teamSettingsConfigurationRoutes from './teamSettings/teamSettingsConfigurationRoutes';

const settingsTeamsRoutes = [
    {
        path: '',
        exact: true,
        noBreadcrumb: true,
        name: 'settings.tabs.organization',
        component: TeamSettingsList,
    },
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        path: '/suspensions',
        name: 'settings.tabs.suspensions',
        component: TeamSettingsSuspensions,
        subPermissionsRoutes: teamSettingsSuspensionsRoutes,
    },
    {
        path: '/positions',
        name: 'settings.tabs.positions',
        component: TeamSettingsPositions,
        subPermissionsRoutes: teamSettingsPositionsRoutes,
    },
    {
        path: '/position-groups',
        name: 'settings.tabs.positionGroups',
        component: TeamSettingsPositionGroups,
        subPermissionsRoutes: teamSettingsPositionGroupsRoutes,
    },
    {
        path: '/team-address-types',
        name: 'organization.settings.organizationTeamAddressTypes.tab.title',
        component: TeamSettingsTeamAddressTypes,
        subPermissionsRoutes: teamSettingsTeamAddressTypesRoutes,
    },
    {
        path: '/team-category-types',
        name: 'organization.settings.organizationTeamCategoryTypes.tab.title',
        component: TeamSettingsTeamCategoryTypes,
        subPermissionsRoutes: teamSettingsTeamCategoryTypesRoutes,
    },
    {
        path: '/team-travel-types',
        name: 'organization.settings.organizationTeamTravelTypes.tab.title',
        component: TeamSettingsTeamTravelTypes,
        subPermissionsRoutes: teamSettingsTeamTravelTypesRoutes,
    },
    {
        path: '/team-contact-types',
        name: 'organization.settings.organizationTeamContactTypes.tab.title',
        component: TeamSettingsTeamContactTypes,
        subPermissionsRoutes: teamSettingsTeamContactTypesRoutes,
    },
    // {
    //     path: '/print',
    //     name: "settings.tabs.teams.print",
    //     component: TeamSettingsRessources,
    //     tempTab: true,
    //     noLink: true,
    //     subPermissionsRoutes: teamSettingsRessourcesRoutes,
    // },
    {
        path: '/configuration',
        name: "settings.tabs.teams.configuration",
        component: TeamSettingsConfiguration,
        subPermissionsRoutes: teamSettingsConfigurationRoutes,
    },
    {
        path: '/restrictions',
        name: 'settings.tabs.teams.restrictions',
        component: TeamSettingsRestrictions,
        subPermissionsRoutes: teamSettingsRestrictionsRoutes,
    },
    {
        redirect: true,
        dynamicRedirect: true,
        from: '/settings/teams',
        to: '',
    },
];

export default settingsTeamsRoutes;