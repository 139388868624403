export default{
    "organization.settings.organizationPositionGroups.table.empty"               : "No Position Groups",
    "organization.settings.organizationPositionGroups.table.column.status"       : "Status",
    "organization.settings.organizationPositionGroups.add.title"                 : "Add Position Group",
    "organization.settings.organizationPositionGroups.add.status"                : "Status",
    "organization.settings.organizationPositionGroups.add.status.active"         : "Active",
    "organization.settings.organizationPositionGroups.add.status.inactive"       : "Inactive",
    "organization.settings.organizationPositionGroups.add.name.required"         : "The name is required",
    "organization.settings.organizationPositionGroups.add.status.required"       : "The status is required",
    "organization.settings.organizationPositionGroups.table.column.abbreviation" : "Abbreviation",
}