import { FormikDateTime, FormikSelect } from '@spordle/formik-elements';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { useFormikContext } from 'formik';
import moment from 'moment';
import {
    Col, Fade, FormGroup,
    Label, Row
} from "reactstrap";
import CustomAlert from '../../../../../../../components/CustomAlert';
import Required from '../../../../../../../components/formik/Required';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';

const MemberProfileRegistrationAdd1Simple = ({ memberProfileRegistrationAddContext }) => {
    const formik = useFormikContext();

    return (
        <Fade>
            <Row>
                <Col sm="6">
                    <FormGroup>
                        <Label for="registrationDate" className="text-muted"><Translate id='memberProfile.registration.form.registration.date' /> <Required /></Label>
                        <FormikDateTime
                            name='registrationDate' id='registrationDate' timeFormat={false}
                            isValidDate={(current) => moment(current).isBefore(new Date())}
                        />
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label for="registrationTime" className="text-muted"><Translate id='memberProfile.registration.form.registration.time' /> <Required /></Label>
                        <FormikDateTime name='registrationTime' id='registrationTime' dateFormat={false} />
                    </FormGroup>
                </Col>
                <Col sm="12">
                    <FormGroup>
                        <Label for="other_fee_id" className="text-muted"><Translate id='memberProfile.registration.form.otherItem' /> <Required /></Label>
                        <FormikSelect
                            name='other_fee_id'
                            id='other_fee_id_MemberProfileregistrationAdd1Simple'
                            isLoading={!memberProfileRegistrationAddContext.viewsInfo.otherItems}
                            renderOption={(option) => (
                                <div className='d-flex w-100'>
                                    <div className='text-truncate'>
                                        <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                                    </div>
                                    <span className='ml-auto'>
                                        {parseInt(option.option.price) === 0 ?
                                            <Translate id='components.manualRegistration.manualRegistrationOtherItems.free' />
                                            :
                                            <CurrencyFormat value={option.option.price / 100} />
                                        }
                                    </span>
                                </div>
                            )}
                            isOptionDisabled={(otherItem) => {
                                return (otherItem.value === memberProfileRegistrationAddContext.viewsInfo.selectedExchangeItem.other_fee.other_fee_id)
                            }}
                            options={
                                memberProfileRegistrationAddContext.viewsInfo.otherItems?.map((otherItem) => (
                                    {
                                        key: otherItem.other_fee_id,
                                        id: otherItem.other_fee_id,
                                        value: otherItem.other_fee_id,
                                        label: otherItem.fee.name,
                                        i18n: otherItem.fee.i18n,
                                        price: otherItem.fee.amount,
                                    }
                                )) || []
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>

            {formik.status &&
                <CustomAlert
                    color='danger'
                    withTitle
                    text={formik.status}
                    translateText={false}
                    toggle={() => formik.setStatus(null)}
                />
            }
        </Fade>
    )
}

export default MemberProfileRegistrationAdd1Simple;