import { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { RolesContext } from '../../contexts/RolesContext';

const SubRouteRenderer = ({ routes }) => {
    const roleContext = useContext(RolesContext);
    const match = useRouteMatch();

    return (
        <Switch>
            {routes.map(({ redirect, ...route }) => {
                if(route.title || route.noRender){
                    return null;
                }
                if(redirect){
                    if(route.dynamicRedirect){
                        const redirectRoute = routes.filter((route) => !route.title).find(roleContext.validateRouteAccess);// Find the first available route
                        if(redirectRoute){
                            return <Redirect key={`redirect-${redirectRoute.path}`} from={match.path} to={`${match.url}${redirectRoute.path}`} />;
                        }
                        return <Redirect key={`redirect-${redirectRoute.path}`} from={match.path} to='/dashboard' />;

                    }
                    return <Redirect key={`redirect-${route.to}`} {...route} from={match.path} to={`${match.url}${route.to}`} />;

                }

                const routeAccess = roleContext.validateRouteAccess(route);
                return routeAccess && <Route key={route.path} {...route} path={`${match.path}${route.path}`} />;
            })}
        </Switch>
    );
}

export default SubRouteRenderer;