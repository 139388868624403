import { useContext } from 'react';
import Translate from '@spordle/intl-elements';

// Components

// Context Providers
import { RolesContext } from '../../../contexts/RolesContext';

import withContexts from '../../../helpers/withContexts';
import { Button } from 'reactstrap';
import TourTriggerer from '../TourTriggerer';
import { I18nContext } from '../../../contexts/I18nContext';
import RolloverEn from "../../../assets/images/tour/tour-rollover-en.png";
import RolloverFr from "../../../assets/images/tour/tour-rollover-fr.png";

const RegistrationFeesRolloverTour = () => {
    const { getGenericLocale } = useContext(I18nContext);

    const triggerDropdownInTour = () => {
        const selectedDropdown = document.querySelector('#registration-fees-add-toggle-dropdown');
        selectedDropdown.classList.add("show");
    }

    const closeDropdown = () => {
        const selectedDropdown = document.querySelector('#registration-fees-add-toggle-dropdown');
        selectedDropdown?.classList?.remove("show");
    }

    const tourSteps = [
        {
            hideDots: true,
            mutationObservables: [ '#registration-fees-add-toggle', '#registration-fees-add-toggle-dropdown' ],
            resizeObservables: [ '#registration-fees-add-toggle', '#registration-fees-add-toggle-dropdown' ],
            action: () => triggerDropdownInTour(),
            selector: '#registration-fees-add-toggle',
            highlightedSelectors: [ '#registration-fees-add-toggle', '#registration-fees-add-toggle-dropdown' ],
            content: () => (
                <>
                    <div className="h3 font-medium"><Translate id="tour.step.rollover.title" /></div>
                    <div className="h5"><Translate id="tour.step.rollover.message" /></div>
                </>
            ),
        },
        {
            action: closeDropdown,
            selector: "#main-period-select",
            content: () => (
                <>
                    <div className="h3 font-medium"><Translate id="tour.step.rollover.season.title" /></div>
                    <div className="h5"><Translate id="tour.step.rollover.season.message" /></div>
                </>
            ),
        },
        {
            bypassElem: true,
            highlightedSelectors: [],
            action: closeDropdown,
            position: 'center',
            content: ({ setIsOpen }) => (
                <>
                    <div className="mx-n4 d-flex flex-center bg-light-inverse mt-n4 py-4">
                        <div className="mt-4 d-inline-block rounded shadow overflow-hidden border">
                            <img className="max-w-100" src={getGenericLocale() === "fr" ? RolloverFr : RolloverEn} alt="Rollover" />
                        </div>
                    </div>
                    <div className="mt-4 h3 font-medium"><Translate id="tour.step.rollover.previousSeason.title" /></div>
                    <div className="h5 mb-4"><Translate id="tour.step.rollover.previousSeason.message" /></div>
                    <div className="text-center">
                        <Button
                            onClick={() => setIsOpen(false)}
                            type="button" color="primary"
                        >
                            <Translate id="tours.registrationFeesRolloverTour.step1.gotIt" />
                        </Button>
                        <a href={getGenericLocale() === "fr" ? 'https://spordle.atlassian.net/wiki/spaces/HDF/pages/2439643758/Catalogue+-+Produits+et+Services' : 'https://spordle.atlassian.net/wiki/spaces/HDNE/pages/2418508107/Catalog+-+Products+and+Services+-+Registration+Fees'} className="d-block mt-2" target='_blank' rel='noopener noreferrer'>
                            <Translate id='tours.registrationFeesRolloverTour.step1.learnMore' />
                        </a>
                    </div>
                </>
            ),
            styles: {
                popover: (styles) => ({ ...styles, width: "600px", maxWidth: "100%", overflow: "hidden" }),
                maskArea: () => ({ opacity: 0 }),
            },
        },
    ];


    return (
        <TourTriggerer
            name="registrationFeesRollover"
            tourProps={{
                steps: tourSteps,
                showPrevNextButtons: false,
                showNavigation: false,
                showCloseButton: false,
                beforeClose: closeDropdown,
            }}
        />
    );
}

export default withContexts(RolesContext)(RegistrationFeesRolloverTour);