import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import {
    Row,
    Col
} from "reactstrap";
import FormikEditable from '../../../../../../../../components/formik/FormikEditable';
import { array, mixed, number, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../../../../api/CancellableAPI';

// contexts
import { I18nContext } from '../../../../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../../../../contexts/MembersContext';
import { DisplayI18n } from '../../../../../../../../helpers/i18nHelper';
import OrganizationSearch from '../../../../../../../../components/organization/OrganizationSearch';
import { FormikInputNumber, FormikInputText, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import { fail, success } from '@spordle/toasts';
import FormikEditableFile from '../../../../../../../../components/formik/FormikEditableFile';
import { AppealsContext } from '../../../../../../../../contexts/AppealsContext';

const AppealsSidepanel2 = (props) => {

    const membersContext = useContext(MembersContext);
    const appealsContext = useContext(AppealsContext);
    const { getGenericLocale } = useContext(I18nContext)

    const [ paymentMethods, setPaymentMethods ] = useState(false);
    const [ orgCategoriesLoading, setOrgCategoriesLoading ] = useState(false);
    const [ organizationCategoriesIds, setOrganizationCategoriesIds ] = useState([]);

    const getPaymentMethods = () => {
        return appealsContext.getAppealPaymentMethods()
            .then((methods) => setPaymentMethods(methods.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setPaymentMethods([]);
                }
            })
    }

    useEffect(() => {
        props.setLoading(true)
        getPaymentMethods()
            .catch(console.error)
            .finally(() => props.setLoading(false))
    }, [])


    useEffect(() => {
        if(organizationCategoriesIds.length <= 0){
            setOrgCategoriesLoading(true);
            appealsContext.getAppealsOrgCategories()
                .then((categories) => {
                    setOrganizationCategoriesIds(categories);
                    setOrgCategoriesLoading(false);
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        setOrgCategoriesLoading(false);
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                });
        }
    }, [])

    return (
        <div className="p-3">
            <div className='mb-3'>
                <div className="text-muted"><Translate id='members.profile.appeals.paymentMethod' /></div>
                <FormikEditable
                    id='paymentMethod'
                    noConfirmation
                    initialValues={{
                        paymentMethod: props.selectedRows[0].payment_method?.code,
                    }}
                    validationSchema={object().shape({
                        paymentMethod: string(),
                    })}
                    onSubmit={(values) => {
                        if(values.paymentMethod !== props.selectedRows[0].payment_method?.code){
                            props.setLoading(true)
                            const newValues = props.createNewValues({ payment_method: paymentMethods.find((paymentMethod) => paymentMethod.code === values.paymentMethod) })
                            membersContext.updateMemberAppealPartial(
                                membersContext.currentMember.member_id,
                                props.selectedRows[0].member_appeal_id,
                                {
                                    payment_method_code: values.paymentMethod,
                                },
                            )
                                .then(() => {
                                    props.syncRows(newValues)
                                    success();
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        })
                                    }
                                })
                                .finally(() => props.setLoading(false))
                        }
                    }}
                >
                    {(isEditing, options) => {
                        if(!isEditing){
                            if(props.selectedRows[0].payment_method){
                                return (
                                    <div className="font-medium text-dark"><DisplayI18n field='name' defaultValue={props.selectedRows[0].payment_method.name} i18n={props.selectedRows[0].payment_method.i18n} /></div>
                                )
                            }
                            return '-'
                        }
                        return (
                            <FormikSelect
                                name='paymentMethod'
                                id='paymentMethod' clearable
                                renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                onOptionSelected={() => options.stopEditing()}
                                menuIsDefaultOpen
                                autoFocus
                                searchKeys={[
                                    `i18n.${getGenericLocale()}.name`,
                                ]}
                                options={paymentMethods ?
                                    paymentMethods.reduce((newArray, method) => {
                                        if(method.active == 1){
                                            newArray.push({
                                                value: method.code,
                                                i18n: method.i18n,
                                                label: method.name,
                                            })
                                        }
                                        return newArray;
                                    }, [])
                                    :
                                    []
                                }
                            />
                        )

                    }}
                </FormikEditable>
            </div>
            <Row>
                <Col sm="6">
                    <div className="mb-3">
                        <div className="text-muted"><Translate id='members.profile.appeals.owes' /></div>
                        <FormikEditable
                            id='owes'
                            initialValues={{
                                owes: props.selectedRows[0].appeal_amount_owed ? props.selectedRows[0].appeal_amount_owed / 100 : '',
                            }}
                            validationSchema={object().shape({
                                owes: number().required(<Translate id='members.profile.appeals.owes.required' />),
                            })}
                            onSubmit={(values) => {
                                const newOwes = values.owes ? Math.round(values.owes * 100) : null
                                if(newOwes !== props.selectedRows[0].appeal_amount_owed){
                                    props.setLoading(true)
                                    const newValues = props.createNewValues({ appeal_amount_owed: newOwes });
                                    membersContext.updateMemberAppealPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_appeal_id, {
                                        appeal_amount_owed: newOwes,
                                    })
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                        .finally(() => props.setLoading(false))
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{props.selectedRows[0].appeal_amount_owed ? <CurrencyFormat value={props.selectedRows[0].appeal_amount_owed / 100} /> : '-'}</div>
                                    )
                                }
                                return (
                                    <I18nContext.Consumer>
                                        {({ getGenericLocale }) => (
                                            <FormikInputNumber
                                                autoFocus
                                                allowLeadingZeros
                                                fixedDecimalScale
                                                thousandSeparator=' '
                                                allowNegative={false}
                                                decimalScale={2}
                                                name="owes"
                                                id="owes"
                                                decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                suffix={getGenericLocale() === 'fr' ? ' $' : ''}
                                                prefix={getGenericLocale() !== 'fr' ? '$ ' : ''}
                                            />
                                        )}
                                    </I18nContext.Consumer>
                                )

                            }}
                        </FormikEditable>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="mb-3">
                        <div className="text-muted"><Translate id='members.profile.appeals.paid' /></div>
                        <FormikEditable
                            id='paid'
                            initialValues={{
                                paid: props.selectedRows[0].appeal_amount_paid ? props.selectedRows[0].appeal_amount_paid / 100 : '',
                            }}
                            validationSchema={object().shape({
                                paid: number().required(<Translate id='members.profile.appeals.paid.required' />),
                            })}
                            onSubmit={(values) => {
                                const newPaid = values.paid ? Math.round(values.paid * 100) : null
                                if(newPaid !== props.selectedRows[0].appeal_amount_paid){
                                    props.setLoading(true)
                                    const newValues = props.createNewValues({ appeal_amount_paid: newPaid });
                                    membersContext.updateMemberAppealPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_appeal_id, {
                                        appeal_amount_paid: newPaid,
                                    })
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                        .finally(() => props.setLoading(false))
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{props.selectedRows[0].appeal_amount_paid ? <CurrencyFormat value={props.selectedRows[0].appeal_amount_paid / 100} /> : '-'}</div>
                                    )
                                }
                                return (
                                    <I18nContext.Consumer>
                                        {({ getGenericLocale }) => (
                                            <FormikInputNumber
                                                autoFocus
                                                allowLeadingZeros
                                                fixedDecimalScale
                                                thousandSeparator=' '
                                                allowNegative={false}
                                                decimalScale={2}
                                                name="paid"
                                                id="paid"
                                                decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                suffix={getGenericLocale() === 'fr' ? ' $' : ''}
                                                prefix={getGenericLocale() !== 'fr' ? '$ ' : ''}
                                            />
                                        )}
                                    </I18nContext.Consumer>
                                )

                            }}
                        </FormikEditable>
                    </div>
                </Col>

                <Col sm="12">
                    <div className="mb-3">
                        <div className="text-muted"><Translate id='members.profile.appeals.client' /></div>
                        <FormikEditable
                            id='client'
                            noConfirmation
                            initialValues={{
                                clientId: props.selectedRows[0].appeal_amount_org?.organisation_id || '',
                                client: props.selectedRows[0].appeal_amount_org || null,
                            }}
                            validationSchema={object().shape({
                                clientId: string().required(<Translate id='members.profile.appeals.client.required' />),
                                client: object().shape({
                                    abbreviation: string().nullable(),
                                    logo: object().nullable(),
                                    organisation_id: string(),
                                    organisation_name: string(),
                                }).nullable(),
                            })}
                            onSubmit={(values) => {
                                if(values.client?.organisation_id !== props.selectedRows[0].appeal_amount_org?.organisation_id){
                                    props.setLoading(true)
                                    const newValues = props.createNewValues({ appeal_amount_org: values.client });
                                    membersContext.updateMemberAppealPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_appeal_id, {
                                        appeal_amount_org_id: values.clientId || null,
                                    })
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                        .finally(() => props.setLoading(false))
                                }
                            }}
                        >
                            {(isEditing, options, formik) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark text-truncate'>
                                            {props.selectedRows[0].appeal_amount_org ?
                                                <DisplayI18n field='name' defaultValue={props.selectedRows[0].appeal_amount_org.organisation_name} i18n={props.selectedRows[0].appeal_amount_org.i18n} />
                                                : '-'}
                                        </div>
                                    )
                                }
                                return (
                                    <OrganizationSearch
                                        id="clientId"
                                        name="clientId"
                                        withFormik
                                        autoFocus
                                        fromIdentityRole
                                        menuIsDefaultOpen
                                        isLoading={orgCategoriesLoading}
                                        categories={organizationCategoriesIds}
                                        onOptionSelected={([ value ], table) => {
                                            const org = table.getSpordleTable().getData().find((d) => d.value === value);

                                            if(org){
                                                formik.setFieldValue('client', {
                                                    abbreviation: org.abbreviation,
                                                    logo: org.logo,
                                                    organisation_id: org.value,
                                                    organisation_name: org.label,
                                                })
                                            }else{
                                                formik.setFieldValue('client', null);
                                            }

                                            options.stopEditing();
                                        }}
                                    />
                                )

                            }}
                        </FormikEditable>
                    </div>
                </Col>
                <Col sm="12">
                    <div className="text-muted"><Translate id='members.profile.appeals.invoice' /></div>
                    <FormikEditable
                        id='invoiceNo'
                        initialValues={{
                            invoiceNo: props.selectedRows[0].hc_invoice_number || '',
                        }}
                        validationSchema={object().shape({
                            invoiceNo: string(),
                        })}
                        onSubmit={(values) => {
                            const newInvoice = values.invoiceNo || null
                            if(newInvoice !== props.selectedRows[0].hc_invoice_number){
                                props.setLoading(true)
                                const newValues = props.createNewValues({ hc_invoice_number: newInvoice });
                                membersContext.updateMemberAppealPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_appeal_id, {
                                    hc_invoice_number: newInvoice,
                                })
                                    .then(() => {
                                        props.syncRows(newValues);
                                        success();
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })
                                    .finally(() => props.setLoading(false))
                            }
                        }}
                    >
                        {(isEditing) => {
                            if(!isEditing){
                                return (
                                    <div className='font-medium text-dark'>{props.selectedRows[0].hc_invoice_number || '-'}</div>
                                )
                            }
                            return (
                                <FormikInputText id='invoiceNo' name='invoiceNo' trim autoFocus />
                            )

                        }}
                    </FormikEditable>
                </Col>
                <Col sm="12">
                    <hr className="mb-3" />
                </Col>
                <Col sm="12">
                    <div className="mb-3">
                        <div className="text-muted"><Translate id='members.profile.appeals.accountReceivableNote' /></div>
                        <FormikEditable
                            id='notes'
                            initialValues={{
                                notes: props.selectedRows[0].account_receivable_note,
                            }}
                            validationSchema={object().shape({
                                notes: string(),
                            })}
                            onSubmit={(values) => {
                                if(values.notes !== props.selectedRows[0].account_receivable_note){
                                    props.setLoading(true)
                                    const newValues = props.createNewValues({ account_receivable_note: values.notes })
                                    membersContext.updateMemberAppealPartial(
                                        membersContext.currentMember.member_id,
                                        props.selectedRows[0].member_appeal_id,
                                        {
                                            account_receivable_note: values.notes,
                                        },
                                    )
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                            props.setLoading(false);
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className="font-medium text-dark text-truncate">{props.selectedRows[0].account_receivable_note ? props.selectedRows[0].account_receivable_note : '-'}</div>
                                    )
                                }
                                return (
                                    <FormikTextArea id='notes' name='notes' trim rows='3' />
                                )

                            }}
                        </FormikEditable>
                    </div>
                </Col>
                <Col sm="12">
                    <div className="mb-3">
                        <div className="text-muted"><Translate id='members.profile.appeals.attachments.account' /></div>
                        <FormikEditableFile
                            id='attachments'
                            name="files"
                            initialValues={{
                                files: props.selectedRows[0].attachments.filter((attachment) => attachment.type === 'ACCOUNT_RECEIVABLE'),
                            }}
                            validationSchema={object().shape({
                                files: array().of(mixed()),
                            })}
                            dropzoneProps={{
                                multiple: true,
                                accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx",
                                maxSize: 20971520,
                            }}
                            onFileClick={props.handleFileClick}
                            onSubmit={(values) => {
                                props.setLoading(true);
                                props.appealAttachmentsOnSubmit(values.files || [], 'ACCOUNT_RECEIVABLE')
                                    .then(() => {
                                        success({ info: 'components.uploader.fileUpload.toast.success.info' });
                                        props.setLoading(false);
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message);
                                            fail();
                                            props.setLoading(false);
                                        }
                                    })
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default AppealsSidepanel2;