import React, { createRef } from "react";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Fade,
    Label,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    Table,
    TabPane,
    UncontrolledDropdown
} from "reactstrap";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import withContexts from "../../../../../helpers/withContexts";

// Contexts
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { RolesContext } from "../../../../../contexts/RolesContext";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";
import CrossFade from "../../../../../components/crossFade/CrossFade";
import { groupByAsArray, stringBuilder } from "@spordle/helpers";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import AddOrganizationModal from "../components/AddOrganizationModal";
import Translate from "@spordle/intl-elements";
import CanDoAction from "../../../../../components/permissions/CanDoAction";
import CollapsibleCard from "../../../../../components/collapsibleCard/CollapsibleCard";
import DisplayOrganization from "../../../../../components/organization/DisplayOrganization";
import { LEAGUES } from "../groupingsConstants";
import { PeriodsContext } from "../../../../../contexts/contexts";
import EditCompetitionModal from "../components/EditCompetitionModal";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import { DisplayCategory } from "../../../../teams/TeamHelpers";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import { withRouter } from 'react-router-dom';
import AddCompetitionTeamModal from "../../../../teams/competitionTeamsSearch/modal/AddCompetitionTeamModal";
import UserImg from "../../../../../components/UserImg";
import BtnDisplay from "../../../../../components/input/BtnDisplay";
import SpordleTableProvider, { SearchInput, SpordleTableView } from "@spordle/datatables";
import { I18nContext } from "../../../../../contexts/I18nContext";

class LeaguesParentSidepanel extends React.Component{
    state = {
        isLoading: false,
        affiliatedOrgs: null,
        addModalIsOpen: false,
        parentOrg: null,
        activeTab: '1',
        competitions: undefined,
        editModalIsOpen: false,
        selectedCompetition: null,
        addTeamModalIsOpen: false,
    };

    orgsTableRef = createRef();

    competitionsTableRef = createRef();

    componentDidMount(){
        this.getData()
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedRows[0].organisation_id !== this.props.selectedRows[0].organisation_id){
            this.getData()
        }
    }

    toggleAddModal = () => {
        this.setState((prev) => ({
            addModalIsOpen: !prev.addModalIsOpen,
        }))
    }

    toggleEditModal = () => {
        this.setState((prev) => ({
            editModalIsOpen: !prev.editModalIsOpen,
        }))
    }

    toggleAddTeamModal = () => {
        this.setState((prev) => ({
            addTeamModalIsOpen: !prev.addTeamModalIsOpen,
        }))
    }

    toggleLoading = (isLoading) => {
        this.setState(() => ({
            isLoading: isLoading,
        }))
    }

    getData = () => {
        this.toggleLoading(true);
        return Promise.all([
            this.getAffiliatedOrgs(),
            this.getOrganization(),
            this.getCompetitions(),
        ])
            .then(async() => {
                await this.getCompetitionsTeams();

                this.orgsTableRef.current?.refreshTable?.();
                this.competitionsTableRef.current?.refreshTable?.();

                this.toggleLoading(false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({
                        isLoading: false,
                    }))
                }
            })
    }

    getCompetitions = () => {
        return this.props.OrganizationContext.getCompetitions({ organisation_id: this.props.selectedRows[0].organisation_id })
            .then((competitions) => {
                return new Promise((resolve) => {
                    this.setState(() => ({
                        competitions: competitions,
                    }), () => {
                        resolve();
                    })
                })
            })
    }

    getCompetitionsTeams = () => {
        return this.props.OrganizationContext.getCompetitionTeams(this.props.selectedRows[0].organisation_id)
            .then((competitionsTeams) => {
                return new Promise((resolve) => {
                    this.setState((prev) => ({
                        competitions: prev.competitions?.map((competition) => ({
                            ...competition,
                            teams: competitionsTeams.teams.filter((team) => team.competition_id === competition.competition_id),
                        })),
                    }), () => resolve())
                })
            })
    }

    getAffiliatedOrgs = () => {
        return this.props.OrganizationContext.getAffiliatedOrganizations(this.props.selectedRows[0].organisation_id)
            .then((affiliatedOrgs) => {
                return new Promise((resolve) => {
                    this.setState(() => ({
                        affiliatedOrgs: groupByAsArray(affiliatedOrgs.map((org) => ({ ...org, orgId: org.linked_organisation.organisation_id })), 'orgId'),
                    }), () => resolve())
                })
            })
    }

    getOrganization = () => {
        return this.props.OrganizationContext.getOrganization(this.props.selectedRows[0].organisation_id)
            .then((org) => {
                return new Promise((resolve) => {
                    this.setState(() => ({
                        parentOrg: org.organisation_parent,
                    }), () => resolve())
                })
            })
    }

    updateAffiliatedOrg = (affiliatedOrgId, orgId, active) => {
        return this.props.OrganizationContext.updateAffiliatedOrganization(affiliatedOrgId, {
            active: active,
        })
            .then(() => {
                const groupIndex = this.state.affiliatedOrgs.findIndex((group) => group.key === orgId);
                const index = this.state.affiliatedOrgs[groupIndex].values.findIndex((org) => org.affiliated_organisation_id === affiliatedOrgId);
                const newAffiliatedOrgs = [ ...this.state.affiliatedOrgs ];
                newAffiliatedOrgs[groupIndex].values[index] = {
                    ...newAffiliatedOrgs[groupIndex].values[index],
                    active: active >>> 0,
                }

                return new Promise((resolve) => {
                    this.setState((prevState) => ({
                        affiliatedOrgs: newAffiliatedOrgs,
                    }), () => resolve())
                })
            })
    }

    deleteAffiliatedOrg = (affiliatedOrgId) => {
        return this.props.OrganizationContext.deleteAffiliatedOrganization(affiliatedOrgId)
            .then(() => {
                return this.getData()
            })
    }

    render(){
        const canEdit = this.props.RolesContext.canDoAction("EDIT", "organization", "affiliated_organization");
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <AddOrganizationModal
                    isOpen={this.state.addModalIsOpen}
                    toggle={this.toggleAddModal}
                    organizationId={this.props.selectedRows[0].organisation_id}
                    from={LEAGUES}
                    parentOrgId={this.state.parentOrg?.organisation_id}
                    affiliatedOrgs={this.state.affiliatedOrgs}
                    onSuccess={() => {
                        this.getData();
                    }}
                />

                <EditCompetitionModal
                    isOpen={this.state.editModalIsOpen}
                    toggle={this.toggleEditModal}
                    onSubmit={() => {
                        return this.getData();
                    }}
                    competition={this.state.selectedCompetition}
                />

                <AddCompetitionTeamModal
                    isOpen={this.state.addTeamModalIsOpen}
                    toggle={this.toggleAddTeamModal}
                    orgId={this.props.selectedRows[0].organisation_id}
                    onSuccess={() => this.getData()}
                    allCompetitionTeams={this.state.selectedCompetition?.teams || []}
                    selectedCompetition={this.state.selectedCompetition}
                />

                <SidePanel.Header noBorder>
                    <div className='d-flex mb-2'>
                        <SidePanel.ToggleButton />

                        <SidePanel.ActionsMenu action='ADD' componentCode='organization' componentPermissionCode='affiliated_organization' label="misc.actions">
                            <SidePanel.MenuAction onClick={() => this.toggleAddModal()}>
                                <Translate id='organization.grouping.sidePanel.addOrg' />
                            </SidePanel.MenuAction>
                            <SidePanel.MenuAction
                                onClick={() => {
                                    this.props.history.push({
                                        pathname: '/changeorganization',
                                        state: {
                                            selected: {
                                                identityRole: this.props.IdentityRolesContext.state,
                                                org: this.props.selectedRows[0],
                                            },
                                        },
                                    })
                                }}
                            >
                                <Translate id={'organization.grouping.add.confirmation.manage.' + LEAGUES} />
                            </SidePanel.MenuAction>
                        </SidePanel.ActionsMenu>
                    </div>
                    <SidePanel.Title>
                        <DisplayI18n
                            field='name'
                            defaultValue={this.props.selectedRows[0].organisation_name}
                            i18n={this.props.selectedRows[0].i18n}
                        />
                    </SidePanel.Title>
                </SidePanel.Header>
                <Nav tabs>
                    <NavItem className="text-center" style={{ width: '100%' }}>
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab == '1' })}
                            onClick={() => { this.setState(() => ({ activeTab: '1' })) }}
                        >
                            <Translate id='organization.grouping.sidePanel.tabs.orgs' />
                        </NavLink>
                    </NavItem>
                    <NavItem className="text-center" style={{ width: '100%' }}>
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab == '2' })}
                            onClick={() => { this.setState(() => ({ activeTab: '2' })) }}
                        >
                            <Translate id='organization.grouping.sidePanel.tabs.competitions' />
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <Fade in={this.state.activeTab == '1'}>
                            <div className='p-3'>
                                <CrossFade isVisible={!!this.state.affiliatedOrgs}>
                                    <SpordleTableProvider
                                        id='leaguesAffiliatedOrgs'
                                        ref={this.orgsTableRef}
                                        dataIndex='key'
                                        defaultData={this.state.affiliatedOrgs}
                                        viewMode="GRID"
                                        searchKeys={[
                                            `values.linked_organisation.${this.props.I18nContext.getGenericLocale()}.name`,
                                            'values.linked_organisation.organisation_name',
                                        ]}
                                        pagination={5}
                                        loadData={(from) => {
                                            switch (from){
                                                case 'REFRESH':
                                                case 'CDM':
                                                    return Promise.resolve(this.state.affiliatedOrgs)
                                            }
                                        }}
                                        gridConfig={{
                                            gridRender: (group, spordleTable) => (
                                                <CollapsibleCard
                                                    className="mt-3"
                                                    initOpen={spordleTable.getData().length === 1}
                                                    key={group.key}
                                                    title={
                                                        <div className="font-bold text-dark d-flex">
                                                            <DisplayI18n
                                                                field='name'
                                                                i18n={group.values[0].linked_organisation.i18n}
                                                                defaultValue={group.values[0].linked_organisation.organisation_name}
                                                            />
                                                            <DisplayOrganization onlyButton organisation={{ organisation_id: group.values[0].linked_organisation.organisation_id }} className="font-bold text-dark" />
                                                        </div>
                                                    }
                                                >
                                                    <Table bordered>
                                                        <tbody>
                                                            {group.values?.map((affiliatedOrg) => (
                                                                <tr key={affiliatedOrg.affiliated_organisation_id}>
                                                                    <td className="font-medium">
                                                                        {affiliatedOrg.team_category ?
                                                                            <DisplayCategory category={affiliatedOrg.team_category} short />
                                                                            :
                                                                            <DisplayI18n field='name' defaultValue={affiliatedOrg.division.name} i18n={affiliatedOrg.division.i18n} />
                                                                        }
                                                                    </td>
                                                                    <td className="text-center align-middle td-shrink">
                                                                        <Label for={`${affiliatedOrg.affiliated_organisation_id}-switch`} className='switch'>
                                                                            <input
                                                                                id={`${affiliatedOrg.affiliated_organisation_id}-switch`} type='checkbox' checked={affiliatedOrg.active == 1}
                                                                                onChange={(e) => {
                                                                                    this.toggleLoading(true);
                                                                                    this.updateAffiliatedOrg(affiliatedOrg.affiliated_organisation_id, affiliatedOrg.orgId, e.target.checked)
                                                                                        .then(() => this.toggleLoading(false))
                                                                                }}
                                                                                disabled={!canEdit}
                                                                            />
                                                                            <span className="switch-toggler" />
                                                                        </Label>
                                                                    </td>
                                                                    <CanDoAction tag="td" className="text-center align-middle td-shrink" action="DELETE" componentCode="organization" componentPermissionCode="affiliated_organization">
                                                                        <ConfirmModal
                                                                            toggler={(toggle) => (
                                                                                <button className="reset-btn text-danger" type='button' onClick={toggle}><i className="mdi mdi-delete font-18" /></button>
                                                                            )}
                                                                            modalTitle={(
                                                                                <>
                                                                                    <DisplayI18n
                                                                                        field='name'
                                                                                        i18n={group.values[0].linked_organisation.i18n}
                                                                                        defaultValue={group.values[0].linked_organisation.organisation_name}
                                                                                    />
                                                                                    <div className="text-muted font-medium"><DisplayI18n field='name' defaultValue={affiliatedOrg.division.name} i18n={affiliatedOrg.division.i18n} /></div>
                                                                                </>
                                                                            )}
                                                                            actionButtonText='misc.delete'
                                                                            translateActionButtonText
                                                                            modalMsg="organization.grouping.sidePanel.delete.msg"
                                                                            translateModalMsg
                                                                            color='danger'
                                                                            onConfirm={() => {
                                                                                this.toggleLoading(true)
                                                                                return this.deleteAffiliatedOrg(affiliatedOrg.affiliated_organisation_id)
                                                                                    .then(() => this.toggleLoading(false))
                                                                            }}
                                                                        />
                                                                    </CanDoAction>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </CollapsibleCard>
                                            ),
                                        }}
                                    >
                                        <BtnDisplay
                                            title={<Translate id='organization.grouping.sidePanel.addOrg' />}
                                            onClick={() => this.toggleAddModal()}
                                            subtitle={<Translate id={'organization.grouping.sidePanel.addOrg.subtitle.' + LEAGUES} />}
                                            icon="organisation"
                                        />
                                        {this.state.affiliatedOrgs?.length > 0 &&
                                            <>
                                                <SearchInput />
                                                <SpordleTableView />
                                            </>
                                        }
                                    </SpordleTableProvider>
                                </CrossFade>
                            </div>
                        </Fade>
                    </TabPane>
                    <TabPane tabId="2">
                        <Fade in={this.state.activeTab == '2'}>
                            <div className="p-3">
                                <CrossFade isVisible={!!this.state.competitions}>
                                    <SpordleTableProvider
                                        id='leaguesCompetitions'
                                        ref={this.competitionsTableRef}
                                        dataIndex='competition_id'
                                        viewMode="GRID"
                                        searchKeys={[
                                            `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                            'name',
                                        ]}
                                        pagination={5}
                                        loadData={(from) => {
                                            switch (from){
                                                case 'REFRESH':
                                                case 'CDM':
                                                    return Promise.resolve(this.state.competitions)
                                            }
                                        }}
                                        gridConfig={{
                                            gridRender: (competition, spordleTable) => (
                                                <CollapsibleCard
                                                    className="mt-3"
                                                    initOpen={spordleTable.getData().length === 1}
                                                    key={competition.competition_id}
                                                    title={
                                                        <div className="font-bold text-dark d-flex">
                                                            <DisplayI18n
                                                                field='name'
                                                                i18n={competition.i18n}
                                                                defaultValue={competition.name}
                                                            />
                                                        </div>
                                                    }
                                                    extraInfo={
                                                        <UserDisplay.Container className="d-flex align-items-center justify-content-end ml-auto">
                                                            <UncontrolledDropdown inNavbar>
                                                                <DropdownToggle tag='button' className='reset-btn text-link' onClick={(e) => e.stopPropagation()}><i className='mdi mdi-dots-vertical font-20' /></DropdownToggle>
                                                                <DropdownMenu right>
                                                                    <DropdownItem
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            this.setState(() => ({
                                                                                selectedCompetition: competition,
                                                                                editModalIsOpen: true,
                                                                            }))
                                                                        }}
                                                                    >
                                                                        <Translate id='misc.edit' />
                                                                    </DropdownItem>
                                                                    <DropdownItem
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            this.setState(() => ({
                                                                                selectedCompetition: competition,
                                                                                addTeamModalIsOpen: true,
                                                                            }))
                                                                        }}
                                                                    >
                                                                        <Translate id='organization.grouping.sidePanel.addTeam' />
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </UserDisplay.Container>
                                                    }
                                                >
                                                    {competition.teams?.map((team) => (
                                                        <UserDisplay key={team.competition_team_id} className='mb-3 pb-3 border-bottom'>
                                                            <UserDisplay.Container>
                                                                <UserImg
                                                                    abbr={team.team.abbreviation || team.team.short_name || team.team.name}
                                                                    alt={team.team.name}
                                                                    filePos={team.team.logo?.file_position}
                                                                    src={team.team.logo?.full_path}
                                                                    width={40}
                                                                />
                                                            </UserDisplay.Container>
                                                            <UserDisplay.Container>
                                                                <UserDisplay.Title>{team.team.name}</UserDisplay.Title>
                                                                {team.team.unique_identifier && (
                                                                    <UserDisplay.Subtitle>
                                                                        #{team.team.unique_identifier}
                                                                    </UserDisplay.Subtitle>
                                                                )}
                                                                <UserDisplay.Subtitle>
                                                                    <DisplayI18n field='name' defaultValue={team.team.organisation.organisation_name} i18n={team.team.organisation.i18n} />
                                                                </UserDisplay.Subtitle>
                                                            </UserDisplay.Container>
                                                        </UserDisplay>
                                                    ))}
                                                    <div
                                                        className="clickable text-link text-center"
                                                        onClick={() => {
                                                            this.setState(() => ({
                                                                selectedCompetition: competition,
                                                                addTeamModalIsOpen: true,
                                                            }))
                                                        }}
                                                    >
                                                        + <Translate id='organization.grouping.sidePanel.addTeam' />
                                                    </div>
                                                </CollapsibleCard>
                                            ),
                                        }}
                                    >
                                        <BtnDisplay
                                            title={<Translate id='organization.grouping.sidePanel.addOrg' />}
                                            onClick={() => this.toggleAddModal()}
                                            subtitle={<Translate id={'organization.grouping.sidePanel.addOrg.subtitle.' + LEAGUES} />}
                                            icon="organisation"
                                        />
                                        {this.state.competitions?.length > 0 &&
                                            <>
                                                <SearchInput />
                                                <SpordleTableView />
                                            </>
                                        }
                                    </SpordleTableProvider>
                                </CrossFade>
                            </div>
                        </Fade>
                    </TabPane>
                </TabContent>
            </OverlayLoader>
        )
    }
}

export default withContexts(OrganizationContext, RolesContext, PeriodsContext, IdentityRolesContext, I18nContext)(withRouter(LeaguesParentSidepanel));