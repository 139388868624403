import { useDisclosure } from "@mantine/hooks";
import { Form, Formik } from "formik";
import { Button, Input } from "reactstrap";
import { partiallyUpdateOrganizationTaxNumber } from "../../../../../../api/client/organization";
import { useSpordleTable } from "@spordle/datatables";
import { fail, success } from "@spordle/toasts";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { FormikInputText } from "@spordle/formik-elements";
import { object, string } from "yup";
import Translate from "@spordle/intl-elements";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { useContext } from "react";

const EditableTaxNumber = ({ taxNumber, setIsLoading, isGodOnly }) => {
    const { organisation_id } = useContext(OrganizationContext);
    const table = useSpordleTable();
    const [ isOpen, { open, close } ] = useDisclosure(false);

    return (
        !isOpen ?
            <div onClick={open}>
                <div style={{ top: '50%', transform: 'translateY(-50%)' }} className="editable left-0 position-absolute right-0 w-100">
                    #{taxNumber.tax_number} <i className={`mdi mdi-pencil text-${isGodOnly ? "purple" : "primary"}`} />
                </div>
                <Input className="invisible" type="text" readOnly id={`${taxNumber.tax_number_id}-dummy`} />
            </div>
            :
            <Formik
                initialValues={{
                    tax_number: taxNumber.tax_number || "",
                }}
                validationSchema={object().shape({
                    tax_number: string()
                        .max(255, <Translate id="form.validation.string.max" values={{ count: 255 }} />)
                        .required(<Translate id="organisation.profile.tabs.overview.number.required" />),
                })}
                onSubmit={(values) => {
                    if(values.tax_number !== taxNumber.tax_number){
                        setIsLoading(true);
                        return partiallyUpdateOrganizationTaxNumber(
                            organisation_id,
                            taxNumber.tax_number_id,
                            {
                                tax_number: values.tax_number,
                            },
                        )
                            .then(() => {
                                setIsLoading(false);
                                table.updateDataWith(taxNumber.tax_number_id, {
                                    ...taxNumber,
                                    tax_number: values.tax_number,
                                });
                                success();
                                close();
                            })
                            .catch((e) => {
                                if(!AxiosIsCancelled(e.message)){
                                    console.error(e);
                                    fail();
                                    setIsLoading(false)
                                }
                            })
                    }

                    success();
                    close();
                }}
            >
                {(formik) => (
                    <Form>
                        <FormikInputText name="tax_number" id={`${taxNumber.tax_number_id}`} />
                        {!formik.isSubmitting &&
                            <div className='position-absolute top-100 right-0 z-index-1 pt-1'>
                                <Button size="sm" color='primary' className='mr-1' type='submit' disabled={formik.isSubmitting} id={`${taxNumber.tax_number_id}`}>
                                    <i className='fas fa-check' />
                                </Button>
                                <Button onClick={close} size="sm" color='white' className='border bg-white' type='button' id={`${taxNumber.tax_number_id}-cancel`} disabled={formik.isSubmitting}>
                                    <i className='fas fa-times' />
                                </Button>
                            </div>
                        }
                    </Form>
                )}
            </Formik>

    );
}

export default EditableTaxNumber;