import { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';

const ReginalSettingsRead = ({ settings }) => {
    const organisationContext = useContext(OrganizationContext);

    return (
        <Row>
            <Col xs="12" sm="6" md="6" lg="6">
                <div className="text-muted">
                    <DisplayI18n
                        field='name'
                        i18n={settings?.fee_for_registration?.i18n}
                        defaultValue={settings?.fee_for_registration?.name}
                    />:
                </div>
                <div className="font-medium text-uppercase">{organisationContext.settings?.fee_for_registration?.value || "-"}</div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="6">
                <div className="text-muted">
                    <DisplayI18n
                        field='name'
                        i18n={settings?.fee_for_clinic?.i18n}
                        defaultValue={settings?.fee_for_clinic?.name}
                    />:
                </div>
                <div className="font-medium text-uppercase">{organisationContext.settings?.fee_for_clinic?.value || "-"}</div>
            </Col>
        </Row>
    );
}

export default ReginalSettingsRead;