export default{
    "organization.profile.postalCodes.modals.delete.title"   : "You are about to delete all {postalCode} postal codes.",
    "organization.profile.postalCodes.modals.delete.content" : 'If you want to delete some of the {postalCode} postal codes, use the "Edit" feature.',

    "organization.profile.postalCodes.modals.add.validation.partialPostalCode.length" : "Please provide the first 3 characters of the postal code",
    "organization.profile.postalCodes.modals.add.validation.postalCodes.required"     : "Please select an least one postal code to add",

    "organization.profile.postalCodes.modals.add.step1.title"                       : "Search postal codes",
    "organization.profile.postalCodes.modals.add.step1.fields.partialZipCode.label" : "Partial Postal Code",
    "organization.profile.postalCodes.modals.add.step1.fields.partialZipCode.hint"  : "Provide the first 3 characters of the postal code",

    "organization.profile.postalCodes.modals.step2.empty.search"      : "No postal codes were found for {searchValue}.",
    "organization.profile.postalCodes.modals.step2.empty.postalCodes" : "No postal codes correspond to the search",
    "organization.profile.postalCodes.modals.edit.step2.title"        : "Edit postal codes: {postalCode}",
    "organization.profile.postalCodes.modals.add.step2.title"         : "Add postal codes",
    "organization.profile.postalCodes.modals.edit.step2.description"  : "Select the postal codes to edit",
    "organization.profile.postalCodes.modals.add.step2.description"   : "Select the postal codes to add",

    "organization.profile.postalCodes.modals.add.step3.description" : "Postal codes added successfully",
}