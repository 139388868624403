import { Button, Col, FormGroup, Label, ModalBody, ModalFooter, Row } from 'reactstrap';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import { FormikInputText } from '@spordle/formik-elements';
import Required from '../../../../../../../components/formik/Required';

const MemberProfileRegistrationAddManualIdentity = ({ registrationContext }) => {

    return (
        <Formik
            initialValues={{
                firstName: registrationContext.viewsInfo.identityFirstName || '',
                lastName: registrationContext.viewsInfo.identityLastName || '',
            }}
            validationSchema={object().shape({
                firstName: string().required(<Translate id='form.validation.firstName.required' />),
                lastName: string().required(<Translate id='form.validation.lastName.required' />),
            })}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                registrationContext.manualIdentityOnSubmit(values.firstName, values.lastName)
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalBody>
                            <Row form>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Label className='text-muted' for='firstName'><Translate id='form.fields.firstName' /> <Required /></Label>
                                        <FormikInputText id='firstName' name='firstName' trim />
                                    </FormGroup>
                                </Col>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Label className='text-muted' for='lastName'><Translate id='form.fields.lastName' /> <Required /></Label>
                                        <FormikInputText id='lastName' name='lastName' trim />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter className='justify-content-between'>
                            <Button color='primary' outline type='button' onClick={() => registrationContext.goToViewEmmanuel(registrationContext.views.manualSelectIdentity)}><Translate id='misc.previous' /></Button>
                            <Button color='primary' type='submit'><Translate id='misc.next' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default MemberProfileRegistrationAddManualIdentity