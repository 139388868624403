import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { Button, Col, Collapse, FormGroup, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { object, string } from 'yup';
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { AuthContext } from "../../../../../contexts/contexts";
import { MembersContext } from "../../../../../contexts/MembersContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import CustomAlert from "../../../../CustomAlert";
import Required from "../../../../formik/Required";
import UserDisplay from "../../../../userDisplay/UserDisplay";
import { fail } from "@spordle/toasts";

/**
 * Create an identity form. Handles the account is already existing logic & the linking to the invoice logic
 * @param {Object} props
 * @param {function} props.toggleCreate
 * @param {object[]} props.members
 * @param {promise} props.onSubmit
 * @param {boolean} props.isLoading
 * @param {function} props.setIsLoading
 * @returns {JSX.Element}
 */
const CreateIdentity = ({ toggleCreate, members, onSubmit, isLoading, setIsLoading, ...props }) => {
    const { getUsersList, getMetaMembers } = useContext(AuthContext);
    const { inviteMembers, createMetaMember } = useContext(MembersContext);
    const [ identity, setIdentity ] = useState('');
    const [ error, setError ] = useState(null);

    /**
     * Creates meta members and links them to an existing identity
     * @param {string} idId Identity id to link the new meta members to
     * @param {object[]} theMembers The members to create meta members for
     * @returns {Promise.<>}
     */
    const createMetaMembersAndLink = async(idId, theMembers) => {
        setIsLoading(true);
        const existingMeta = await getMetaMembers(idId)// Gets all meta members of this identity
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
                return [];
            });

        const nonLinkedMembers = theMembers.filter((m) => !existingMeta.some((existing) => m.member?.member_id === existing.member?.member_id));

        // WARNING:
        // We are chaining multiple calls with conditions
        // If the user quits before the calls are finished,
        // it may end up with invalid data (empty meta members)
        return Promise.all(
            nonLinkedMembers.map((member) => (
                createMetaMember(member.member_id, idId)
            )),
        )
            .then(() => onSubmit?.(idId))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    setIsLoading(false);
                    setError(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                }
            })
    }

    return (
        identity ?
            <>
                <ModalBody>
                    <UserDisplay card block>
                        <UserDisplay.Container>
                            <UserDisplay.Title>{identity.first_name} {identity.last_name}</UserDisplay.Title>
                            <UserDisplay.Subtitle>{identity.email}</UserDisplay.Subtitle>
                        </UserDisplay.Container>
                    </UserDisplay>
                    <span className='text-dark'>
                        <Translate id='invoice.editModal.editIdentity.existing' />
                    </span>
                    <Collapse isOpen={!!error} appear mountOnEnter unmountOnExit>
                        {error &&
                            <CustomAlert className='mt-3' color='danger' withTitle text={error} translateText toggle={() => setError(null)} />
                        }
                    </Collapse>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color='primary'
                        type='button'
                        onClick={() => createMetaMembersAndLink(identity.identity_id, members)}
                        disabled={isLoading}
                    >
                        <Translate id='memberProfile.registration.memberIdentities.link' />
                    </Button>
                    <Button
                        color='primary' outline type='button'
                        onClick={() => { setIdentity(null) }}
                        disabled={isLoading}
                    >
                        <Translate id='misc.cancel' />
                    </Button>
                </ModalFooter>
            </>
            :
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    locale: '',
                }}
                validationSchema={object().shape({
                    firstName: string().required(<Translate id='form.validation.firstName.required' />),
                    lastName: string().required(<Translate id='form.validation.lastName.required' />),
                    email: string().email(<Translate id='form.validation.email.valid' />).required(<Translate id='form.validation.email.required' />),
                    locale: string().required(<Translate id='form.validation.language.required' />),
                })}
                onSubmit={async(values, { setStatus }) => {
                    setIsLoading(true);

                    // Checks if there are any Users linked to this email
                    const response = await getUsersList('email', '=', values.email).catch(console.error);

                    if(response?.Users?.length > 0){ // identity exists
                        setIsLoading(false);
                        return setIdentity({
                            identity_id: response.Users[0].Username,
                            first_name: response.Users[0].name,
                            last_name: response.Users[0].family_name,
                            email: response.Users[0].email,
                        }) // there shouldn't be more than 1 identity with this email
                    }
                    // Creates an identity and link it to members
                    return inviteMembers({
                        member_ids: members.map((m) => m.member_id).join(','),
                        email: values.email,
                        name: values.firstName,
                        family_name: values.lastName,
                        language_code: values.locale,
                    })
                        .then(({ identity_id }) => onSubmit?.(identity_id))
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                setIsLoading(false);
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            }
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <ModalBody>
                            <Row form>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Label for='firstName' className='text-muted'><Translate id='form.fields.firstName' /> <Required /></Label>
                                        <FormikInputText id='firstName' name='firstName' trim />
                                    </FormGroup>
                                </Col>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Label for='lastName' className='text-muted'><Translate id='form.fields.lastName' /> <Required /></Label>
                                        <FormikInputText id='lastName' name='lastName' trim />
                                    </FormGroup>
                                </Col>
                                <Col sm='12'>
                                    <FormGroup>
                                        <Label for='email' className='text-muted'><Translate id='form.fields.email' /> <Required /></Label>
                                        <FormikInputText id='email' name='email' trim />
                                    </FormGroup>
                                </Col>
                                <Col sm='12'>
                                    <FormGroup>
                                        <Label for='locale' className='text-muted'><Translate id='form.fields.language' /> <Required /></Label>
                                        <FormikSelect
                                            name='locale'
                                            id='locale'
                                            search={false}
                                            placeholder='form.fields.primaryLanguage.placeholder'
                                            loadingStatus='success'
                                            defaultData={[
                                                {
                                                    label: 'misc.fr.long',
                                                    value: 'fr',
                                                    translateLabel: true,
                                                },
                                                {
                                                    label: 'misc.en.long',
                                                    value: 'en',
                                                    translateLabel: true,
                                                },
                                            ]}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                {formik.status &&
                                    <CustomAlert className='mt-3' color='danger' withTitle text={formik.status} translateText toggle={() => formik.setStatus()} />
                                }
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button type='submit' color='primary' disabled={formik.isSubmitting || isLoading}>
                                <Translate id='misc.create' />
                            </Button>
                            <Button
                                type='button'
                                color='primary'
                                outline
                                onClick={() => {
                                    formik.setStatus();
                                    toggleCreate();
                                }}
                                disabled={formik.isSubmitting || isLoading}
                            >
                                <Translate id='misc.cancel' />
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
    )
}

export default CreateIdentity;