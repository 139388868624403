// contexts

import { Skeleton } from "@mantine/core"
import Translate from "@spordle/intl-elements"
import { fail } from "@spordle/toasts"
import { Form, Formik } from "formik"
import { useContext, useEffect, useState } from "react"
import { Button, Card, Collapse, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { array, object } from "yup"
import AnalyticsModal from "../../../../analytics/AnalyticsModal"
import { AxiosIsCancelled } from "../../../../api/CancellableAPI"
import CustomAlert from "../../../../components/CustomAlert"
import OverlayLoader from "../../../../components/loading/OverlayLoader"
import OrganizationSearch from "../../../../components/organization/OrganizationSearch"
import UserImg from "../../../../components/UserImg"
import { OnlineStoreContext } from "../../../../contexts/OnlineStoreContext"
import { OrganizationContext } from "../../../../contexts/OrganizationContext"
import { DisplayI18n } from "../../../../helpers/i18nHelper"
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext"
import { RolesContext } from "../../../../contexts/RolesContext"

const OnlineStoreSharedOrganizationModal = ({ onlineStore, isOpen, toggle }) => {
    const { isGod } = useContext(IdentityRolesContext);
    const { canDoAction } = useContext(RolesContext);
    const canEdit = canDoAction("EDIT", "catalog", "manage_online_store_shared") || isGod();
    const { getOnlineStore, shareOnlineStore, deleteOnlineStoreSharedOrg } = useContext(OnlineStoreContext);

    const { getOrganizationsTreePublic, organisation_id } = useContext(OrganizationContext)

    const [ loading, setIsLoading ] = useState(true)

    useEffect(() => {
        getOrganizationsTreePublic(false, true)
            .then(() => {
                setIsLoading(false)
            })
    }, [])

    return (
        <AnalyticsModal size="lg" isOpen={isOpen} analyticsName='refundCreditModal'>
            <Formik
                initialValues={{
                    organizations: onlineStore?.shared_organisations?.map((sharedOrg) => sharedOrg.organisation?.organisation_id) || [],
                    sharedOrganizations: onlineStore?.shared_organisations?.map((sharedOrg) => ({
                        value: sharedOrg.organisation.organisation_id,
                        ...sharedOrg?.organisation,
                    })) || [],
                }}
                validationSchema={object().shape({
                    organizations: array(),
                    sharedOrganizations: array(),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    // find all orgs that weren't already shared
                    const toCreate = values.organizations.filter((orgId) => !onlineStore.shared_organisations.some(({ organisation }) => organisation.organisation_id === orgId));
                    // find all orgs that were deleted from the shared
                    const toDelete = onlineStore.shared_organisations.reduce((orgsToDelete, { online_store_shared_organisation_id, organisation }) => {
                        if(!values.organizations.includes(organisation.organisation_id)){
                            orgsToDelete.push(online_store_shared_organisation_id)
                        }

                        return orgsToDelete;
                    }, []);

                    // POST ORGS THAT WERE ADDED TO THE LIST
                    if(toCreate.length > 0){
                        shareOnlineStore(onlineStore?.online_store_id, toCreate)
                            .then(() => {
                                if(toDelete.length === 0){
                                    getOnlineStore(onlineStore?.online_store_id, {}, true);
                                    toggle();
                                    setSubmitting(false);
                                }
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    const msg = <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />;

                                    fail({
                                        msg: 'misc.error',
                                        info: msg,
                                        skipInfoTranslate: true,
                                    })
                                    setStatus(msg)
                                    setSubmitting(false);
                                }
                            })
                    }

                    // DELETE THOSE THAT WERE REMOVED FROM LIST
                    if(toDelete.length > 0){
                        Promise.all(
                            toDelete.map((sharedId) => (
                                deleteOnlineStoreSharedOrg(onlineStore.online_store_id, sharedId)
                            )),
                        )
                            .then(() => {
                                getOnlineStore(onlineStore?.online_store_id, {}, true);
                                toggle();
                                setSubmitting(false);
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message);
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                    setSubmitting(false);
                                }
                            })
                    }

                    if(toDelete.length === 0 && toCreate.length === 0){
                        getOnlineStore(onlineStore?.online_store_id, {}, true);
                        toggle();
                        setSubmitting(false);
                    }
                }}
            >
                {(formik) => (
                    <Form>
                        <ModalHeader toggle={toggle}>
                            <Translate id='onlineStore.profile.share.modal.title' />
                        </ModalHeader>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalBody>
                                {canEdit &&
                                <>
                                    <CustomAlert
                                        color="info"
                                        text={'onlineStore.profile.share.modal.organization.info.bubble'}
                                        translateText
                                    />
                                    <OrganizationSearch
                                        isOptionDisabled={(option) => option.value === organisation_id}
                                        withFormik
                                        multi
                                        skipParent
                                        fromIdentityRole
                                        fromPublicApi
                                        withTree
                                        isCompact
                                        onlyActive
                                        fromApi={false}
                                        wrapperClassName='w-100 mb-3'
                                        name="organizations"
                                        id="organizations-shared-store"
                                        treeOrganizationClick={(organization) => {
                                            const isSelected = formik.values.organizations.includes(organization.organisation_id);

                                            formik.setFieldValue(
                                                'sharedOrganizations',
                                                isSelected ? formik.values.sharedOrganizations.filter(({ value }) => value !== organization.organisation_id) : [ ...formik.values.sharedOrganizations, { ...organization, value: organization.organisation_id } ],
                                            );

                                            return Promise.resolve(true);
                                        }}
                                        onOptionSelected={(values, select) => {
                                            const allOrgs = select.getSpordleTable().getData();

                                            const selectedOrgs = values.reduce((keptOrgs, val) => {
                                                const org = allOrgs.find((org) => org.value === val);

                                                if(org){
                                                    keptOrgs.push({
                                                        ...org,
                                                        organisation_name: org.label,
                                                    });
                                                }
                                                return keptOrgs;
                                            }, []);

                                            formik.setFieldValue('sharedOrganizations', selectedOrgs);
                                        }}
                                    />
                                </>
                                }
                                {loading ?
                                    <div>
                                        {onlineStore?.shared_organisations.map((sharedOrg) => <Skeleton className="mb-2" key={sharedOrg.organisation.organisation_id} height={53} />)}
                                        <Skeleton className="mb-2" height={49} />
                                    </div>
                                    :
                                    <PerfectScrollbar className="max-vh-50">
                                        {formik.values.sharedOrganizations?.map((org) => (
                                            <Card
                                                outline
                                                key={org.value}
                                                className="card-shadow p-2 mb-2 flex-row align-items-center"
                                            >
                                                <UserImg
                                                    src={org.logo?.full_path}
                                                    width={40}
                                                    alt={org.organisation_name}
                                                    abbr={org.organisation_name}
                                                    className="mr-2"
                                                />
                                                <div className="font-weight-medium">
                                                    <DisplayI18n
                                                        field="name"
                                                        i18n={org.i18n}
                                                        defaultValue={org.organisation_name}
                                                    />
                                                </div>
                                                {canEdit &&
                                                    <button
                                                        className="border-0 pr-2 pl-3 ml-auto bg-transparent"
                                                        type="button"
                                                        onClick={() => {
                                                            formik.setFieldValue("sharedOrganizations", formik.values.sharedOrganizations.filter(({ value }) => value !== org.value));
                                                            formik.setFieldValue("organizations", formik.values.organizations.filter((id) => id !== org.value));
                                                        }}
                                                    >
                                                        <i className="font-18 text-danger mdi mdi-delete" />
                                                    </button>
                                                }
                                                {/* <Button className="ml-2" color='danger' onClick={() => arrayHelpers.remove(index)}><i className="mdi mdi-delete" /></Button> */}
                                            </Card>
                                        ))}
                                    </PerfectScrollbar>
                                }
                                <Collapse isOpen={!!formik.errors?.sharedOrganizations?.props?.id} appear mountOnEnter unmountOnExit>
                                    {!!formik.errors?.sharedOrganizations?.props?.id &&
                                        <CustomAlert color='danger' text={formik.errors?.sharedOrganizations?.props?.id || ''} translateText />
                                    }
                                </Collapse>
                                <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                    {formik.status &&
                                        <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status} translateText={false} />
                                    }
                                </Collapse>
                            </ModalBody>
                        </OverlayLoader>
                        <ModalFooter>
                            {canEdit && <Button disabled={formik.isSubmitting || !isOpen} type='submit' color='primary'><Translate id='misc.confirm' /></Button>}
                            <Button disabled={formik.isSubmitting || !isOpen} type='button' color='primary' outline onClick={() => toggle()}><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    )
}

export default OnlineStoreSharedOrganizationModal