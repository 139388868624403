import Translate from "@spordle/intl-elements";
import { useTeamConfigPosGroupsSWR } from "../teamSettingsConfigurationHelper";
import ConfigurationCountPerResource from "./ConfigurationCountPerResource";

const ConfigurationSettingsFormPosGroups = ({ posGroupSetting }) => {
    const { data: posGroups } = useTeamConfigPosGroupsSWR();

    return (
        <ConfigurationCountPerResource
            name="count_per_position_group"
            setting={posGroupSetting}
            label={<Translate id="organization.settings.organizationPositions.table.column.positionGroup" />}
            options={posGroups?.map((pos) => ({
                ...pos,
                value: pos.position_group_id,
                label: pos.name,
            }))}
        />
    )
}

export default ConfigurationSettingsFormPosGroups;