import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import AnalyticsModal from '../../../../analytics/AnalyticsModal'
import { AxiosCancelAll } from '../../../../api/CancellableAPI';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { MembersContext } from '../../../../contexts/MembersContext';
import AllMembers from './AllMembers';
import ValidMembers from './ValidMembers';

const MemberBulkConfirmModal = (props) => {

    const views = {
        allMembers: 'allMembers',
        validMembers: 'validMembers',
    }

    const [ view, setView ] = useState(views.allMembers);

    return (
        <AnalyticsModal toggle={props.toggle} isOpen={props.isOpen} analyticsName='MemberBulkConfirmModal'>
            <MemberBulkConfirmModalInner {...props} view={view} setView={setView} views={views} />
        </AnalyticsModal>
    )
}

/**
 * @param {{ members: any[], isOpen: boolean, toggle: function, toggleAfterSuccess: function }} param
 * @returns
 */
const MemberBulkConfirmModalInner = ({ members, isOpen, toggle, toggleAfterSuccess, canConfirmAddress, view, setView, views, ...props }) => {

    const [ unconfirmedMemberAddresses, setUnconfirmedMemberAddresses ] = useState({});
    const organizationContext = useContext(OrganizationContext);
    const membersContext = useContext(MembersContext);

    const [ memberStatus, setMemberStatus ] = useState(false);

    const { validMembers, invalidMembers } = members.reduce((sortedMembers, member) => {
        const formatPush = (array, member) => {
            array.push({
                member: member, // member object
                isLoading: false, // used for the loading spinner when making the API call
                status: null, // used to display the status after the API call
            })
        }

        if(!member.minimalistic_view == 1 && member.member_status?.name !== 'INELIGIBLE' && member.member_status?.name !== 'INACTIVE' && member.member_status?.name !== 'BLOCKED' && member.member_status?.name !== 'CONFIRMED'){ // if the team doesn't have a status then it can be submitted for confirmation
            formatPush(sortedMembers.validMembers, member)
        }else{
            formatPush(sortedMembers.invalidMembers, member)
        }

        return sortedMembers;
    }, {
        validMembers: [],
        invalidMembers: [],
    });

    const getView = () => {
        switch (view){
            case views.allMembers:
                return (
                    <AllMembers
                        toggle={toggle}
                        views={views}
                        setView={setView}
                        validMembers={validMembers}
                        invalidMembers={invalidMembers}
                        memberStatus={memberStatus}
                        unconfirmedMemberAddresses={unconfirmedMemberAddresses}
                        setUnconfirmedMemberAddresses={setUnconfirmedMemberAddresses}
                        canConfirmAddress={canConfirmAddress}
                    />
                )
            case views.validMembers:
                return (
                    <ValidMembers
                        toggle={toggle}
                        views={views}
                        unconfirmedMemberAddresses={unconfirmedMemberAddresses}
                        setView={setView}
                        validMembers={validMembers}
                        toggleAfterSuccess={toggleAfterSuccess}
                        memberStatus={memberStatus}
                    />
                )
            default:
                return null
        }
    }

    const getMemberStatus = () => membersContext.getMemberStatus(organizationContext.organisation_id).then(setMemberStatus).catch(console.error)

    useLayoutEffect(() => AxiosCancelAll(), [])

    useEffect(getMemberStatus, [])

    return (
        <AnalyticsModal isOpen={isOpen} toggle={toggle} analyticsName='MemberBulkConfirmModal' onClosed={() => { setView(views.allMembers); }} unmountOnClose>
            {getView()}
        </AnalyticsModal>
    )
}

export default MemberBulkConfirmModal
