export default{
    "checklists.goTo"                   : "Go to Checklist",
    "checklists.goToModule"             : "Go to Module",
    "checklists.canComplete"            : "Can Complete",
    "checklists.notStarted"             : "Not Started",
    "checklists.markIncomplete"         : "Mark as <b>Incomplete</b>",
    "checklists.markComplete"           : "Mark as <b>Complete</b>",
    "checklists.waitingForCompletion"   : "Waiting For Approbation",
    "checklists.approvedBy"             : "Approved by",
    "checklists.date"                   : "Approved date",
    "checklists.startChecklist"         : "Start Checklist",
    "checklists.openChecklistAssistant" : "Open Checklist",
    "checklists.startChecklist.txt"     : "Click here to start from the beginning, or click on one of the steps to skip through.",
    "checklists.notFound"               : "Checklist Not Found",
    "checklists.notFound.txt"           : "We could not find the checklist you are looking for. It might have been moved or deleted.",
    "checklists.notSet"                 : "The checklist has not been set for this season.",
    "checklists.stepsComplete"          : "complete",
    "checklists.noAccess"               : "You do not have access to this module.",
}