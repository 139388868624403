import { Tooltip } from "@mantine/core";
import { FormikInputText } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements"
import { fail } from "@spordle/toasts";
import { Form, Formik } from "formik";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Button, Col, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { string, object } from "yup";
import AnalyticsModal from "../../../analytics/AnalyticsModal"
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import { HelpCenterContext } from "../../../contexts/HelpCenterContext";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import FormikTiny from "../../formik/FormikTiny";
import { InlineConfirmation } from "../../InlineConfirmation";
import OverlayLoader from "../../loading/OverlayLoader";
import CanDoAction from "../../permissions/CanDoAction";

const HelpCenterFormModal = ({ editMode, editModeHandlers, helpBubble, getHelpCenter }) => {
    const location = useLocation();
    const helpCenterContext = useContext(HelpCenterContext);

    // this is used to replace IDs in the page_url we send to API
    const newPath = location.pathname.split('/');
    let path = '';

    newPath.forEach((_part) => {
        if(_part.match(/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/)){
            path += '/{ID}';
        }else if(_part) path += '/' + _part
    })

    return (
        <AnalyticsModal isOpen={editMode} analyticsName={'helpCenterEditModal'} size="xl">
            <Formik
                initialValues={{
                    version: helpBubble?.version || 1,
                    titleEn: helpBubble?.i18n?.en?.title || "",
                    titleFr: helpBubble?.i18n?.fr?.title || "",
                    contentEn: helpBubble?.i18n?.en?.content || "",
                    contentFr: helpBubble?.i18n?.fr?.content || "",
                    customLinkEn: helpBubble?.i18n?.en?.custom_link || "",
                    customLinkFr: helpBubble?.i18n?.fr?.custom_link || "",
                    videoLinkEn: helpBubble?.i18n?.en?.video_link || "",
                    videoLinkFr: helpBubble?.i18n?.fr?.video_link || "",
                    videoIdEn: helpBubble?.i18n?.en?.video_id || "",
                    videoIdFr: helpBubble?.i18n?.fr?.video_id || "",
                }}
                validationSchema={
                    object().shape({
                        titleEn: string().when('customLinkEn', { // when we have a custom link we need a title
                            is: (val) => !!val,
                            then: string().required(<Translate id='help_center.editModal.validation.title.when.custom_link' />),
                            otherwise: string().test({ // if we have content but no custom link, we need a title
                                name: 'titleWhenContent',
                                message: <Translate id='help_center.editModal.validation.title.when.content' />,
                                test: function(value){
                                    if(this.parent.contentEn){
                                        return !!value;
                                    }
                                    return true;
                                },
                            }),
                        }).test({
                            name: 'titleEnWhenNoEnWhenNoOtherFields', // when no fields are filled, we show a title as required
                            message: <Translate id='help_center.editModal.validation.title.required.when.no.video.link' />,
                            test: function(value){
                                if(!this.parent.videoLinkEn && !this.parent.videoLinkFr && !this.parent.titleFr && !this.parent.contentFr && !this.parent.videoIdEn && !this.parent.videoIdFr && !this.parent.customLinkEn && !this.parent.customLinkFr){
                                    return !!value;
                                }
                                return true;
                            },
                        }),
                        contentEn: string().when('customLinkEn', { // when we have a custom link, content is required
                            is: (val) => !!val,
                            then: string().required(<Translate id='help_center.editModal.validation.content.when.custom_link' />),
                            otherwise: string().test({ // content is required when we have a title
                                name: 'contentEnWhenTitle',
                                message: <Translate id='help_center.editModal.validation.content.when.title' />,
                                test: function(value){
                                    if(this.parent.titleEn){
                                        return !!value;
                                    }
                                    return true;
                                },
                            }),
                        }),
                        customLinkEn: string().url(<Translate id='help_center.editModal.validation.custom_url' />), // custom link must be a valid URL
                        videoLinkEn: string()
                            .url(<Translate id='help_center.editModal.validation.video.link.valid.url' />) // video link must be valid URL
                            .test({
                                name: 'videoLinkEnWhenVideoId', // when we have a video ID, video link is required
                                message: <Translate id='help_center.editModal.validation.video.link.required.when.video.id' />,
                                test: function(value){
                                    if(this.parent.videoIdEn){
                                        return !!value;
                                    }
                                    return true;
                                },
                            }).test({
                                name: 'videoLinkEnWhenNoOtherFields', // a video link is shown as required when no other fields are filled
                                message: <Translate id='help_center.editModal.validation.video.link.required.when.no.title' />,
                                test: function(value){
                                    if(!this.parent.titleEn && !this.parent.videoLinkFr && !this.parent.titleFr && !this.parent.contentFr && !this.parent.contentEn && !this.parent.customLinkEn && !this.parent.customLinkFr){
                                        return !!value;
                                    }
                                    return true;
                                },
                            }),
                        videoIdEn: string().when('videoLinkEn', {
                            is: (val) => !!val, // a video id is required when we have a video link
                            then: string().required(<Translate id='help_center.editModal.validation.video.id.required.when.video.link' />),
                            otherwise: string().nullable(),
                        }),
                        // ALL validations are the same in french and in english
                        titleFr: string().when('customLinkFr', {
                            is: (val) => !!val,
                            then: string().required(<Translate id='help_center.editModal.validation.title.when.custom_link' />),
                            otherwise: string().test({
                                name: 'titleFrWhenContent',
                                message: <Translate id='help_center.editModal.validation.title.when.content' />,
                                test: function(value){
                                    if(this.parent.contentFr){
                                        return !!value;
                                    }
                                    return true;
                                },
                            }),
                        }).test({
                            name: 'titleFrWhenNoOtherFields',
                            message: <Translate id='help_center.editModal.validation.title.required.when.no.video.link' />,
                            test: function(value){
                                if(!this.parent.videoLinkFr && !this.parent.videoLinkEn && !this.parent.titleEn && !this.parent.contentEn && !this.parent.videoIdEn && !this.parent.videoIdFr && !this.parent.customLinkEn && !this.parent.customLinkFr){
                                    return !!value;
                                }
                                return true;
                            },
                        }),
                        contentFr: string().when('customLinkFr', {
                            is: (val) => !!val,
                            then: string().required(<Translate id='help_center.editModal.validation.content.when.custom_link' />),
                            otherwise: string().test({
                                name: 'contentFrWhenTitle',
                                message: <Translate id='help_center.editModal.validation.content.when.title' />,
                                test: function(value){
                                    if(this.parent.titleFr){
                                        return !!value;
                                    }
                                    return true;
                                },
                            }),
                        }),
                        customLinkFr: string().url(<Translate id='help_center.editModal.validation.custom_url' />),
                        videoLinkFr: string()
                            .url(<Translate id='help_center.editModal.validation.video.link.valid.url' />)
                            .test({
                                name: 'videoLinkFrWhenVideoId',
                                message: <Translate id='help_center.editModal.validation.video.link.required.when.video.id' />,
                                test: function(value){
                                    if(this.parent.videoIdFr){
                                        return !!value;
                                    }
                                    return true;
                                },
                            }).test({
                                name: 'videoLinkFrWhenNoOtherFields',
                                message: <Translate id='help_center.editModal.validation.video.link.required.when.no.title' />,
                                test: function(value){
                                    if(!this.parent.titleFr && !this.parent.videoLinkEn && !this.parent.titleEn && !this.parent.contentEn && !this.parent.contentFr && !this.parent.customLinkEn && !this.parent.customLinkFr){
                                        return !!value;
                                    }
                                    return true;
                                },
                            }),
                        videoIdFr: string().when('videoLinkFr', {
                            is: (val) => !!val,
                            then: string().required(<Translate id='help_center.editModal.validation.video.id.required.when.video.link' />),
                            otherwise: string().nullable(),
                        }),
                    })
                }
                onSubmit={(values) => {

                    const submitValues = {
                        help_bubble_id: helpBubble?.help_bubble_id || '', // if we have help_bubble_id this acts as an update
                        product: "SID",
                        active: 1,
                        page_url: path,
                        version: 1,
                        title: values.titleEn || '',
                        content: values.contentEn || '',
                        custom_link: values.customLinkEn || '',
                        video_link: values.videoLinkEn || '',
                        video_id: values.videoIdEn || '',
                        i18n: {
                            en: {
                                title: values.titleEn || '',
                                content: values.contentEn || '',
                                custom_link: values.customLinkEn || '',
                                video_link: values.videoLinkEn || '',
                                video_id: values.videoIdEn || '',
                            },
                            fr: {
                                title: values.titleFr || '',
                                content: values.contentFr || '',
                                custom_link: values.customLinkFr || '',
                                video_link: values.videoLinkFr || '',
                                video_id: values.videoIdFr || '',
                            },
                        },
                    }

                    return helpCenterContext.createHelpBubble(submitValues).then(() => {
                        getHelpCenter();
                        editModeHandlers.close();
                    }).catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    })

                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalHeader toggle={editModeHandlers.toggle}>
                                <div className="ml-1">
                                    <Translate id="helpCenter.editModal.title" />
                                </div>
                            </ModalHeader>
                            <ModalBody className="px-3 pt-2">
                                <div>
                                    <Row className="w-100 d-flex mb-1 mx-0">
                                        <Col md={6} className="form-group px-1">
                                            <Label for={'titleEn'}><Translate id='help.center.form.field.i18n.en.title' /></Label>
                                            <FormikInputText
                                                name={'titleEn'}
                                                id={'titleEn'}
                                            />
                                        </Col>
                                        <Col md={6} className="form-group px-1">
                                            <Label for={'titleFr'}><Translate id='help.center.form.field.i18n.fr.title' /></Label>
                                            <FormikInputText
                                                name={'titleFr'}
                                                id={'titleFr'}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="w-100 d-flex mb-1 mx-0">
                                        <Col md={6} className="form-group px-1">
                                            <Label for={'contentEn'}><Translate id='help.center.form.field.i18n.en.content' /></Label>
                                            <FormikTiny
                                                name={'contentEn'}
                                                id={'contentEn'}
                                                init={{ // removed the option for links as currently putting a custom_link is under a permission and this would make it so anyone can leave a link
                                                    height: 125,
                                                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
                                                    forced_root_block: false,
                                                }}
                                            />
                                        </Col>
                                        <Col md={6} className="form-group px-1">
                                            <Label for={'contentFr'}><Translate id='help.center.form.field.i18n.fr.content' /></Label>
                                            <FormikTiny
                                                name={'contentFr'}
                                                id={'contentFr'}
                                                init={{ // removed the option for links as currently putting a custom_link is under a permission and this would make it so anyone can leave a link
                                                    height: 125,
                                                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
                                                    forced_root_block: false,
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <CanDoAction componentPermissionCode="manage_help_bubble_confluence_page" componentCode="settings" action={[ 'EDIT', 'ADD' ]}>
                                        <Row className="w-100 d-flex mb-1 mx-0">
                                            <Col md={6} className="form-group px-1">
                                                <Label for={'customLinkEn'}>
                                                    <Translate id='help.center.form.field.i18n.en.custom_link' />
                                                    <Tooltip zIndex={999999999} wrapLines withArrow width={400} position="top" label={<Translate id='help_center.editModal.custom.link.tooltip' />}>
                                                        <i className="m-2 mdi mdi-information-outline text-primary" />
                                                    </Tooltip>
                                                </Label>
                                                <FormikInputText
                                                    name={'customLinkEn'}
                                                    id={'customLinkEn'}
                                                />
                                            </Col>
                                            <Col md={6} className="form-group px-1">
                                                <Label for={'customLinkFr'}>
                                                    <Translate id='help.center.form.field.i18n.fr.custom_link' />
                                                    <Tooltip zIndex={999999999} wrapLines withArrow width={400} position="top" label={<Translate id='help_center.editModal.custom.link.tooltip' />}>
                                                        <i className="m-2 mdi mdi-information-outline text-primary" />
                                                    </Tooltip>
                                                </Label>
                                                <FormikInputText
                                                    name={'customLinkFr'}
                                                    id={'customLinkFr'}
                                                />
                                            </Col>
                                        </Row>
                                    </CanDoAction>
                                    <div className='d-flex align-items-center mb-3'>
                                        <div className='border-top flex-grow-1' />
                                        <div className='px-3 font-medium'><Translate id='helpCenter.editModal.and.or' /></div>
                                        <div className='border-top flex-grow-1' />
                                    </div>
                                    <Row className="w-100 d-flex mb-1 mx-0">
                                        <Col md={6} className="form-group px-1">
                                            <Label for={'videoLinkEn'}>
                                                <Translate id='help.center.form.field.i18n.en.video_link' />
                                            </Label>
                                            <FormikInputText
                                                name={'videoLinkEn'}
                                                id={'videoLinkEn'}
                                            />
                                        </Col>
                                        <Col md={6} className="form-group px-1">
                                            <Label for={'videoLinkFr'}>
                                                <Translate id='help.center.form.field.i18n.fr.video_link' />
                                            </Label>
                                            <FormikInputText
                                                name={'videoLinkFr'}
                                                id={'videoLinkFr'}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="w-100 d-flex mb-1 mx-0">
                                        <Col md={6} className="form-group px-1">
                                            <Label for={'videoIdEn'}>
                                                <Translate id='help.center.form.field.i18n.en.video_id' />
                                                <Tooltip zIndex={999999999} wrapLines withArrow width={400} position="top" label={<Translate id='help_center.editModal.video.id.tooltip' />}>
                                                    <i className="m-2 mdi mdi-information-outline text-primary" />
                                                </Tooltip>
                                            </Label>
                                            <FormikInputText
                                                name={'videoIdEn'}
                                                id={'videoIdEn'}
                                            />
                                        </Col>
                                        <Col md={6} className="form-group px-1">
                                            <Label for={'videoIdFr'}>
                                                <Translate id='help.center.form.field.i18n.fr.video_id' />
                                                <Tooltip zIndex={999999999} wrapLines withArrow width={400} position="top" label={<Translate id='help_center.editModal.video.id.tooltip' />}>
                                                    <i className="m-2 mdi mdi-information-outline text-primary" />
                                                </Tooltip>
                                            </Label>
                                            <FormikInputText
                                                name={'videoIdFr'}
                                                id={'videoIdFr'}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <InlineConfirmation
                                    disabled={!helpBubble?.help_bubble_id}
                                    primaryButtonTranslation='misc.clear'
                                    onClick={() => {
                                        formik.setSubmitting(true);
                                        helpCenterContext.deleteHelpBubble(helpBubble?.help_bubble_id).then(() => {
                                            getHelpCenter();
                                            editModeHandlers.close();
                                            formik.setSubmitting(false);
                                        }).catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                                formik.setSubmitting(false);
                                            }
                                        })
                                    }}
                                />
                                <div className="ml-auto">
                                    <Button
                                        onClick={editModeHandlers.close}
                                        color="primary"
                                        outline
                                        className="mr-2"
                                    >
                                        <Translate id="misc.cancel" />
                                    </Button>
                                    <Button type="submit" color="primary"><Translate id="misc.submit" /></Button>
                                </div>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    )
}
export default HelpCenterFormModal