import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';
import { PeriodsContext } from './contexts';

/** @type {React.Context<Omit<QualificationsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const QualificationsContext = createContext();
QualificationsContext.displayName = 'QualificationsContext';

class QualificationsContextProvider extends React.PureComponent{
    /**
     * Get a list of all the Qualifications
     * @param {object} [queryParams] The params for that specific api call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Qualifications/Apicontroller%5CClinics%5CQualifications%3A%3Aindex|documentation}
     * @returns {Promise.<Array>}
     */
    getQualifications = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/qualifications`,
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
            }, queryParams), // Creates an object with an org id by default being the current org
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.qualifications;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get a specific qualification
     * @returns {Promise}
     */
    getQualification = (qualificationId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/qualifications/${qualificationId}`,
            query: {
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.qualifications[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a specified Qualification with new values
     * @param {string} qualificationId ID of the Qualification to update
     * @param {object} values Object containing the values to update - The keys to the values need to be the same as the API ones
     * @param {string} [lang] Language variable corresponding to the values - Used for the I18N logic
     * @param {object} [i18n] Object containing an array of all the languages available and an object of the initialValues for language variables - Used for the I18N logic
     * @returns {Promise}
     */
    updateQualification = (qualificationId, values, lang, i18n) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                case 'fee':
                    params.append('fee', values[key]); // to receive in minor unit
                    break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `qualifications/${qualificationId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete a qualification from a member
     * @param {string} member_id The member from whom we want to delete a qualification
     * @param {string} member_qualification_id  The member from whom we want to delete a qualification
     * @returns {Promise.<boolean>}
     */
    deleteMemberQualification = (member_id, member_qualification_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `/members/${member_id}/qualifications/${member_qualification_id}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete a certification from a member
     * @param {string} member_id The member from whom we want to delete a certification
     * @param {string} member_qualification_id  The member from whom we want to delete a certification
     * @returns {Promise.<boolean>}
     */
    deleteMemberCertification = (member_id, member_qualification_id) => {
        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/members/${member_id}/qualifications/${member_qualification_id}/remove-certification`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get a list of all the Qualifications statuses by org id and one or mulitple qualification_id
     * @param {object} [queryParams] The params for that specific api call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Qualifications/Apicontroller%5CClinics%5CQualifications%3A%3Aindex|documentation}
     * @returns {Promise.<Array>}
     */
    getQualificationsStatuses = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/qualifications-status`,
            query: Object.assign({
                organisation_id: queryParams.organisation_id || this.props.OrganizationContext.organisation_id,
            }, queryParams), // Creates an object with an org id by default being the current org
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.qualification_status;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <QualificationsContext.Provider value={this}>
                {this.props.children}
            </QualificationsContext.Provider>
        );
    }
}

export default withContexts(OrganizationContext, PeriodsContext)(QualificationsContextProvider);
