import { Fragment } from 'react';
import { IntlProvider, createIntl, createIntlCache } from 'react-intl';

import { LOCALES } from './locales';
import messages from './messages';
import { setExternalTranslate } from '@spordle/intl-elements';

const cache = createIntlCache();

const Provider = ({ children, locale = LOCALES.ENGLISH }) => {
    // Need to be here to change values when user changes language

    setExternalTranslate(createIntl({
        locale: locale,
        defaultLocale: LOCALES.ENGLISH,
        messages: messages[locale],
    }, cache));

    return (
        <IntlProvider
            locale={locale}
            defaultLocale={LOCALES.ENGLISH}
            textComponent={Fragment}
            messages={messages[locale]}
        >
            {children}
        </IntlProvider>
    )
};

export default Provider;