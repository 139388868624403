export default{
    "organization.changeOrganization.title"             : "Sélectionnez une organisation",
    "organization.changeOrganization.header.title"      : "Changer d'organisation",
    "organization.changeOrganization.manageOrg"         : "Gérer l'organisation",
    "organization.changeOrganization.swal.confirmation" : "Souhaitez-vous gérer",
    "organization.changeOrganization.swal.Ok"           : "Gérer",
    "organization.changeOrganization.swal.Cancel"       : "Annuler",
    "organization.changeOrganization.toast.text"        : "Vous gérez maintenant {name}",
    "organization.changeOrganization.recent"            : "Organisations récentes",
    "organization.changeOrganization.lastManaged"       : "Gérée la dernière fois",

    "organization.changeOrganization.selectRole"       : "Sélectionner ce rôle",
    "organization.changeOrganization.changeRole"       : "Changer de rôle",
    "organization.changeOrganization.changeRole.toast" : "{from} → {name}",
}