import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import Svg from "../../components/Svg";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { isLeague, isTournament } from "../../helpers/orgHelper";
import clinicsTabsRoutes from "../tabRoutes/clinicsTabsRoutes";
import memberProfileTabsRoutes from "../tabRoutes/memberProfileTabsRoutes";
import onlineStoreTabsRoutes from "../tabRoutes/onlineStoreTabsRoutes";
import organizationTabsRoutes from "../tabRoutes/organizationTabsRoutes";
import teamProfileTabsRoutes from "../tabRoutes/teamProfileTabsRoutes";

const OrgLabel = () => {
    const { organisation_category } = useContext(OrganizationContext);
    const orgIsLeague = isLeague(organisation_category.category_system);
    const orgIsTournament = isTournament(organisation_category.category_system);

    return (
        <Translate id={`sidebar.my${orgIsLeague ? "League" : orgIsTournament ? 'Tournament' : 'Organization'}`} />
    )
}

const searchSidebar = [
    {
        navlabel: true,
        name: "sidebar.search.profiles",
        subPermissionsRoutes: [
            {
                exact: true,
                path: '/organization',
                name: <OrgLabel />,
                skipTranslate: true,
                permissions: { code: 'organization' },
                tabBreadcrumb: 'header.breadcrumbs.profile', // Will be applied before tab routes
                icon: <Svg icon="organisation" />,
                tabRoutes: [ ...organizationTabsRoutes ],
            },
            {
                exact: true,
                path: '/members',
                name: 'sidebar.members',
                permissions: { code: 'members' },
                tabBreadcrumb: 'header.breadcrumbs.profile', // Will be applied before tab routes
                icon: <Svg icon="members" />,
                tabRoutes: memberProfileTabsRoutes,
            },
            {
                exact: true,
                path: '/teams',
                name: 'sidebar.teams',
                permissions: { code: 'teams' },
                tabBreadcrumb: 'header.breadcrumbs.profile', // Will be applied before tab routes
                icon: <Svg icon="teams" />,
                tabRoutes: teamProfileTabsRoutes,
            },
        ],
    },
    {
        navlabel: true,
        name: "sidebar.search.stores",
        subPermissionsRoutes: [
            {
                exact: true,
                path: '/clinics',
                name: 'sidebar.clinics',
                permissions: { code: 'clinics' },
                tabBreadcrumb: 'header.breadcrumbs.profile', // Will be applied before tab routes
                icon: <Svg icon="clinics" />,
                tabRoutes: clinicsTabsRoutes,
            },
            {
                exact: true,
                path: '/onlineregistration',
                name: 'sidebar.onlineStore',
                permissions: { code: 'page', permissionCode: 'online_store_manage' },
                tabBreadcrumb: 'header.breadcrumbs.profile', // Will be applied before tab routes
                icon: <Svg icon="onlineStore" />,
                tabRoutes: onlineStoreTabsRoutes,
            } ],
    },
];
export default searchSidebar;
