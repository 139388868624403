import { useSpordleTable } from "@spordle/datatables";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import generatePassword from "../../helpers/passwordGenerator";

/**
 * This component needs to be a children of SpordleTable.
 * Uses the spordleTable pagination and the following SpordleTable Filters: { currentPage: integer, totalResults: integer|null }
 * @example
 * pagination={10}
 * initFilter={{
        currentPage: 1,
        totalResults: null,
    }}
 */
const PaginationApi = (props) => {
    const spordleTable = useSpordleTable();
    const { currentPage, totalResults } = spordleTable.getFilters();
    const dataCount = spordleTable.getDisplayedData().length;
    const pageCount = Math.ceil(((spordleTable.state.searchValue || dataCount !== spordleTable.filteredData.length) ? dataCount : totalResults) / spordleTable.getPagination());

    if(!totalResults
        || spordleTable.state.loadingState !== 'success'
        || pageCount <= 1
    ){
        return null;
    }


    const handleOnPageClick = (pageIndex) => {
        const targetedPage = pageIndex >= pageCount ? pageCount : pageIndex <= 1 ? 1 : pageIndex;
        spordleTable.filterChange('currentPage', targetedPage);
    }

    const handleOnNext = () => handleOnPageClick(currentPage + 1);
    const handleOnPrevious = () => handleOnPageClick(currentPage - 1);

    return (
        <Pagination aria-label="Support Requests Pagination" {...props}>
            <PaginationItem disabled={currentPage === 1}>
                <PaginationLink previous disabled={currentPage === 1} onClick={handleOnPrevious} />
            </PaginationItem>
            <PageBtnList
                pageCount={pageCount}
                currentPage={currentPage}
                handleOnPageClick={handleOnPageClick}
            />
            <PaginationItem disabled={currentPage === pageCount}>
                <PaginationLink next disabled={currentPage === pageCount} onClick={handleOnNext} />
            </PaginationItem>
        </Pagination>
    );
}

const PageBtnList = ({ pageCount, currentPage, handleOnPageClick }) => {
    const maxBtnAmount = 5;
    const btnAmount = Math.min(pageCount, maxBtnAmount);
    const btnMiddlePos = Math.ceil(maxBtnAmount / 2);

    const buildBtnArray = (_, i) => {
        const btnPos = i + 1;
        const btnId = generatePassword() + btnPos;
        const isFirstBtn = btnPos === 1;
        const isLastBtn = btnPos === btnAmount;

        if(pageCount <= maxBtnAmount){
            return {
                id: btnId,
                page: i + 1,
            };
        }

        let page;
        let isDot = false;

        if(currentPage <= 2){
            if(btnPos <= btnMiddlePos){
                page = btnPos;
            }else{
                isDot = true;
            }
        }else if(currentPage >= pageCount - 1){
            if(btnPos >= btnMiddlePos){
                page = pageCount - Math.abs(btnPos - maxBtnAmount);
            }else{
                isDot = true;
            }
        }else{
            page = btnPos < btnMiddlePos ? currentPage - 1 : btnPos > btnMiddlePos ? currentPage + 1 : currentPage;
            isDot = btnPos < btnMiddlePos || btnPos > btnMiddlePos;
        }

        return {
            id: btnId,
            page: isFirstBtn ? 1 : isLastBtn ? pageCount : page,
            isDot: currentPage !== page && !isFirstBtn && !isLastBtn && isDot,
        }

    }

    return (
        Array
            .from({ length: btnAmount }, buildBtnArray)
            .map(({ page, isDot, id }) => (
                isDot ?
                    <PaginationItem disabled key={id}>
                        <PaginationLink type="button" disabled>
                            ...
                        </PaginationLink>
                    </PaginationItem>
                    :
                    <PageSingleBtn
                        key={id}
                        currentPage={currentPage}
                        onPageClick={handleOnPageClick}
                        page={page}
                    />
            ))
    )
}

const PageSingleBtn = ({ page, onPageClick, currentPage }) => {
    const handleOnPageClick = () => onPageClick(page);

    return (
        <PaginationItem active={currentPage === page}>
            <PaginationLink type="button" onClick={handleOnPageClick}>
                {page}
            </PaginationLink>
        </PaginationItem>
    )
}

export default PaginationApi;