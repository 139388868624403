import Translate from "@spordle/intl-elements";
import { Button, Collapse, ModalBody } from "reactstrap";
import { useContext, useState } from "react";
import { AddSupervisionFormContext } from "../components/AddSupervisionFormContext";
import SearchMembers from "../components/SearchMembers";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../components/UserImg";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { OfficiatingCoachContext } from "../../../../../../contexts/OfficiatingCoachContext";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { stringBuilder } from "@spordle/helpers";
import { Link } from "react-router-dom";

const StepFindOfficiatingCoach = () => {
    const { next, updateFormData } = useContext(AddSupervisionFormContext);

    const handleOnSelectMember = (member, types) => {
        updateFormData('officiatingCoach', member);
        updateFormData('availableTypes', types);
        next();
    }

    return (
        <>
            <ModalBody className="pb-0">
                <div className="h5 font-bold"><Translate id='officialSupervision.addSupervision.identityOfficiating' /></div>
            </ModalBody>
            <SearchMembers
                renderMember={(member) => (
                    <OfficiatingCoachCard
                        member={member}
                        onSelectMember={handleOnSelectMember}
                    />
                )}
            />
        </>
    );
}

const OfficiatingCoachCard = ({ member, onSelectMember }) => {
    const [ verifiedInfo, setVerifiedInfo ] = useState({
        isLoading: false,
        hasVerified: false,
        supervisionTypes: [],
        error: null,
    });

    const { getMemberOfficiatingCoachList } = useContext(OfficiatingCoachContext);

    const handleOnSelectMember = () => {
        setVerifiedInfo((prev) => ({ ...prev, isLoading: true }));
        getMemberOfficiatingCoachList(member.member_id, { current_period_only: 1 })
            .then((officiatingList) => {
                if(officiatingList[0]?.supervision_types && officiatingList[0].supervision_types.length > 0){
                    onSelectMember(member, officiatingList[0]?.supervision_types)
                }else{
                    setVerifiedInfo((prev) => ({ ...prev, isLoading: false, hasVerified: true }))
                }
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    setVerifiedInfo((prev) => ({ ...prev, isLoading: false, error: <DisplayI18n field="message" defaultValue={e.message} i18n={e.i18n} /> }))
                }
            })
    }

    return (
        <UserDisplay className={stringBuilder("w-100 mb-2", { "border-warning": verifiedInfo.hasVerified })} card>
            <UserDisplay.Container>
                <UserImg
                    width={45}
                    abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                    alt={member.first_name + ' ' + member.last_name}
                    src={member.picture?.full_path}
                    filePos={member.picture?.file_position}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Title>{member.first_name + ' ' + member.last_name}</UserDisplay.Title>
                <UserDisplay.Subtitle>#{member.unique_identifier}</UserDisplay.Subtitle>
                <UserDisplay.Subtitle>{member.birthdate} (<Translate id='misc.yearsOld' values={{ age: member.age }} />)</UserDisplay.Subtitle>
                <Collapse isOpen={!verifiedInfo.hasVerified}>
                    <UserDisplay.Subtitle>
                        <DisplayI18n
                            field="name"
                            defaultValue={member.organisation?.organisation_name}
                            i18n={member.organisation?.i18n}
                        />
                    </UserDisplay.Subtitle>
                </Collapse>
                {member.member_qualification &&
                    <UserDisplay.Subtitle>
                        <DisplayI18n field='name' defaultValue={member.member_qualification.qualification.name} i18n={member.member_qualification.qualification.i18n} />{(member.member_qualification.qualification_level) && <> - <DisplayI18n field='name' defaultValue={member.member_qualification.qualification_level.name} i18n={member.member_qualification.qualification_level.i18n} /></>}
                    </UserDisplay.Subtitle>
                }
                <Collapse isOpen={verifiedInfo.hasVerified}>
                    <UserDisplay.Subtitle className="px-1 mt-1 rounded-lg bg-light-warning">
                        <span className="d-inline-block mr-1 text-body"><i className="mdi text-warning mdi-alert-outline" /> <Translate id='officialSupervision.addSupervision.notOfficiating.label' /></span>
                        <Link target="_blank" rel="noopener noreferrer" to={`/members/profile/${member.member_id}`}><Translate id='officialSupervision.addSupervision.seeProfile' /> <i className="mdi mdi-launch" /></Link>
                    </UserDisplay.Subtitle>
                </Collapse>
            </UserDisplay.Container>
            <UserDisplay.Container className="ml-auto text-right">
                <Button
                    disabled={verifiedInfo.hasVerified || !member.member_qualification}
                    size="sm"
                    onClick={handleOnSelectMember}
                    color="primary"
                    type="button"
                >
                    {!member.member_qualification ?
                        <Translate id='officialSupervision.addSupervision.notOfficial.label' />
                        :
                        <Translate id='misc.select' />
                    }
                </Button>
                {verifiedInfo.error && <Collapse isOpen appear className="small text-danger">{verifiedInfo.error}</Collapse>}
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default StepFindOfficiatingCoach;