import Translate from '@spordle/intl-elements';
import {
    Alert,
    Button,
    Collapse,
    Fade,
    ModalBody,
    ModalFooter, ModalHeader
} from "reactstrap";
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import { Form, Formik } from 'formik';
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import { useContext, useState } from 'react';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import CustomAnimatedIcon from '../../../../components/customAnimatedIcon/CustomAnimatedIcon';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';

const OrganizationConfirmModal = ({ isOpen, toggle, getOrganization }) => {
    const organizationContext = useContext(OrganizationContext)
    const [ isSuccess, setIsSuccess ] = useState(false);

    return (
        <AnalyticsModal analyticsName='OrganizationConfirmModal' isOpen={isOpen}>
            <Formik
                initialValues={{}}
                onSubmit={(_, { setSubmitting, setStatus }) => {
                    return organizationContext.createApprobationRequest(organizationContext.organisation_id)
                        .then(async() => {
                            // get organization again to update the additional fields answers in the state
                            // const org = organizationContext.getOrganization(organizationContext.organisation_id);
                            // organizationContext.setCurrentState({ ...organizationContext.state, ...org })
                            getOrganization();
                            setStatus('success');
                            setSubmitting(false)
                            setIsSuccess(true);
                        }).catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            }
                            setSubmitting(false);
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalHeader
                                toggle={() => {
                                    toggle();
                                }}
                            >
                                <Translate id='organisation.profile.actions.submitApproval' />
                            </ModalHeader>
                            {(!isSuccess && formik.status !== 'success') &&
                                    <div>
                                        <ModalBody className="text-center">
                                            <UserDisplay card className='w-100'>
                                                <UserDisplay.Container>
                                                    <UserImg
                                                        className="mx-auto mb-3"
                                                        width={70}
                                                        abbr={organizationContext.abbr}
                                                        src={organizationContext.logo?.full_path}
                                                        filePos={organizationContext.logo?.file_position}
                                                        alt={organizationContext.organisation_name}
                                                    />

                                                </UserDisplay.Container>
                                                <UserDisplay.Container>
                                                    <UserDisplay.Title><DisplayI18n field='name' defaultValue={organizationContext.organisation_name} i18n={organizationContext.i18n} /></UserDisplay.Title>
                                                </UserDisplay.Container>
                                                <UserDisplay.Container className='ml-auto text-body'>
                                                    <CustomAnimatedIcon
                                                        className='text-success'
                                                        strokeWidth="10px"
                                                        size={50}
                                                        id='confirmModalCheckmark'
                                                        icon='checkmark'
                                                        withCircle
                                                    />
                                                </UserDisplay.Container>
                                            </UserDisplay>
                                            <div className='font-bold text-dark'><Translate id='organisation.profile.actions.submitApproval' /></div>
                                            <Translate id='organisation.profile.actions.submitApproval.desc' />

                                            <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                                <Alert color='danger' className='text-left mt-2'>{formik.status}</Alert>
                                            </Collapse>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color='primary' type='submit' disabled={formik.isSubmitting}>
                                                <Translate id='misc.submit' />
                                            </Button>
                                            <Button
                                                color='primary' type='button' onClick={() => {
                                                    toggle();
                                                }}
                                                outline disabled={formik.isSubmitting}
                                            ><Translate id='misc.cancel' />
                                            </Button>
                                        </ModalFooter>
                                    </div>
                            }
                            {(isSuccess && formik.status == 'success') &&
                                    <div>
                                        <ModalBody className="text-center text-dark">
                                            <Fade in>
                                                <div className='position-relative d-inline-block'>
                                                    <CustomAnimatedIcon
                                                        className='text-success'
                                                        strokeWidth="10px"
                                                        size={50}
                                                        id='confirmModalCheckmark'
                                                        icon='checkmark'
                                                        withCircle
                                                    />
                                                </div>

                                                <div className="text-center h5 font-bold pt-2">
                                                    <Translate id="misc.success" />
                                                </div>
                                                <div><Translate id='organisation.profile.actions.submitApproval.success' /></div>
                                            </Fade>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color='primary' type='button' onClick={() => { toggle(); setIsSuccess(false); }} outline disabled={formik.isSubmitting}><Translate id='misc.close' /></Button>
                                        </ModalFooter>
                                    </div>
                            }
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    );
}

export default OrganizationConfirmModal;