import Translate from "@spordle/intl-elements";
import AddInsuranceModalMemberSearch from "../components/AddInsuranceModalMemberSearch";

const InsuranceMemberSearch = ({ changeStep, setIsLoading, updateData, toggle }) => {
    const handleSelect = (member) => {
        updateData('member', member);
        changeStep(1);
    }

    return (
        <AddInsuranceModalMemberSearch
            onMemberSelect={handleSelect}
            cancel={toggle}
            setIsLoading={setIsLoading}
        >
            <p>
                <Translate id='insurance.addModal.stepTeam.member.search' />
            </p>
        </AddInsuranceModalMemberSearch>
    )
}

export default InsuranceMemberSearch;