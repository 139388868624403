import Translate from "@spordle/intl-elements"
import { useHistory } from "react-router-dom";
import { Button, ModalBody, ModalFooter } from "reactstrap"
import CustomAnimatedIcon from "../../../../../../../components/customAnimatedIcon/CustomAnimatedIcon";

const ConfirmationStep = ({ from, toggleWithOnSuccess, changeOrg, ...props }) => {
    const history = useHistory();

    return (
        <>
            <ModalBody className="text-center">
                <CustomAnimatedIcon
                    icon="checkmark"
                    size={75}
                    withCircle
                    className='text-success mb-3'
                />
                <div className="h4 font-bold mb-2"><Translate id={'organization.grouping.add.confirmation.text.' + from} /></div>
            </ModalBody>
            <ModalFooter className="justify-content-center">
                <div className="ml-auto">
                    <Button
                        color='primary' type='button' className="mr-2"
                        onClick={() => {
                            history.push({
                                pathname: '/changeorganization',
                                state: { selected: changeOrg },
                            })
                        }}
                    >
                        <Translate id={'organization.grouping.add.confirmation.manage.' + from} />
                    </Button>
                    <Button color='primary' outline type='button' onClick={toggleWithOnSuccess}><Translate id='misc.close' /></Button>
                </div>
            </ModalFooter>
        </>
    )
}

export default ConfirmationStep