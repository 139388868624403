import { useContext, useState } from "react";
import { stringBuilder } from "@spordle/helpers";
import { ModalBody } from "reactstrap";
import GuiltyMemberSearch from "./GuiltyMemberSearch";
import GuiltyTeamSearch from "./GuiltyTeamSearch";
import CrossFade from "../../../../../../../../components/crossFade/CrossFade";
import { DiscriminationAddContext } from "../../../components/DiscriminationAddContext";
import Translate from "@spordle/intl-elements";

const GuiltyStepSearch = ({ nextGuiltyStep }) => {
    const { previous, isLoading, next, setComplaint, changeStep, fromMemberView, complaint } = useContext(DiscriminationAddContext);

    const TAB_MEMBER = 1;
    const TAB_TEAM = 2;

    const [ activeTab, setActiveTab ] = useState(TAB_MEMBER);

    const handleTeamSubmit = (values) => {
        setComplaint('complainees', [ { ...values, type: 'TEAM' } ]);
        next();
    }

    const handlePrevious = () => {
        if(complaint.complaint_type === "ANONYMOUS"){
            changeStep(2);
        }else{
            previous();
        }
    }

    return (
        <>
            {!fromMemberView &&
                <>
                    <ModalBody className="pb-0">
                        <div className="h4 font-bold mb-2">
                            <Translate id='discrimination.addModal.stepGuilty.title.teamOrMEmber' />
                        </div>
                        <p><Translate id='discrimination.addModal.stepGuilty.subtitle.teamOrMEmber' /></p>
                        <div className="d-flex align-items-center">
                            <div
                                onClick={() => setActiveTab(TAB_MEMBER)}
                                className={stringBuilder("mr-2 flex-grow-1 btn btn-lg select-request", activeTab === TAB_MEMBER ? "border-primary bg-primary-light text-primary" : "border")}
                                color="light"
                            >
                                <Translate id='discrimination.label.member' />
                            </div>
                            <div
                                onClick={() => setActiveTab(TAB_TEAM)}
                                className={stringBuilder("ml-2 flex-grow-1 btn btn-lg select-request", activeTab === TAB_TEAM ? "border-primary bg-primary-light text-primary" : "border")}
                                color="light"
                            >
                                <Translate id='discrimination.label.team' />
                            </div>
                        </div>
                    </ModalBody>
                    <CrossFade isVisible={activeTab === TAB_TEAM}>
                        <GuiltyTeamSearch
                            currentTab={activeTab}
                            nextGuiltyStep={next}
                            onSubmit={handleTeamSubmit}
                            previous={handlePrevious}
                            type={complaint.complainees?.[0]?.type}
                            teamId={complaint.complainees?.[0]?.team_id}
                            isLoading={isLoading}
                        />
                    </CrossFade>
                </>
            }
            <CrossFade isVisible={activeTab === TAB_MEMBER}>
                <GuiltyMemberSearch
                    previous={handlePrevious}
                    currentTab={activeTab}
                    nextGuiltyStep={nextGuiltyStep}
                />
            </CrossFade>
        </>
    );
}

export default GuiltyStepSearch;