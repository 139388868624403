import { useContext } from "react";
import { Badge } from "reactstrap";
import { I18nContext } from "../../../../contexts/I18nContext";
import { DisplayI18n } from "../../../../helpers/i18nHelper";

export const BC_ID = '1eb5d98e-ac53-658c-b920-06ba8f7ba4e0'; // TEMP

export const OFFICIAL_TYPES = [
    "REFEREE",
    "LINESMAN",
    "SUPERVISOR",
];

export const GAME_PERIODS = [
    "FIRST",
    "SECOND",
    "THIRD",
    "OVERTIME",
    "SHOOTOUT",
    "PRE_POST_GAME",
];

const COACH_POS_GROUP = "COACH";
const MANAGER_POS_GROUP = [ "COACH", "STAFF" ];
const STATUS_NAME_SUBMITTED = "SUBMITTED";
const STATUS_NAME_PENDING = "PENDING";

export function isPending(status){
    if(!status)return false;

    return (status.name || '').toUpperCase() === STATUS_NAME_PENDING;
}

export function isSubmitted(status){
    if(!status)return false;

    return (status.name || '').toUpperCase() === STATUS_NAME_SUBMITTED;
}

export function isManager(posGroup){
    if(!posGroup)return false;

    return MANAGER_POS_GROUP.includes((posGroup.position_type || '').toUpperCase())
}

export function isCoach(posGroup){
    if(!posGroup)return false;

    return COACH_POS_GROUP.includes((posGroup.position_type || '').toUpperCase())
}

function getColor(statName){
    switch ((statName || '').toUpperCase()){
        case 'CLOSED':
            return "danger";
        case 'PENDING':
            return "warning";
        case 'SUBMITTED':
        default:
            return "info";
    }
}

export const GameIncidentStatusBadge = ({ status }) => {
    if(!status){
        console.warn('GirStatusBadge', 'Missing status');
        return null;
    }


    return (
        <Badge className="text-nowrap" color={getColor(status.name)}>
            <DisplayI18n
                field="name"
                i18n={status.i18n}
                defaultValue={status.name}
            />
        </Badge>
    )
}

export const useGirSelectProps = () => {
    const { getGenericLocale } = useContext(I18nContext);

    const getGirSelectProps = (name, obj) => ({
        name: name,
        id: name,
        search: true,
        isLoading: !obj,
        searchKeys: [
            `i18n.${getGenericLocale()}.name`,
        ],
        renderOption: (opt) => (
            <DisplayI18n
                field="name"
                i18n={opt.option.i18n}
                defaultValue={opt.option.label}
            />
        ),
        options: obj ? obj.map((part) => (
            {
                label: part.name,
                i18n: part.i18n,
                value: part[name],
            }
        )) : [],
    })

    return getGirSelectProps;

}