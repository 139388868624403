import { useContext, useEffect, useState } from "react";
import { FormGroup, Label } from "reactstrap"
import SubmitButtonDropdown from "../../../../components/reports/SubmitButtonDropdown";
import { ReportsContext } from "../../../../contexts/ReportsContext";
import { promisedParseJSON } from "../../../../helpers/helper";
import { useReportsSWR } from "../../../../components/reports/useReportsSWR";
import { getReportQueryParamsFromFilters } from "../reportsEngineHelpers";

const EngineFilterSearchButton = ({ formikRef, filter, isOpenHandlers, customReportId }) => {
    const [ switchPath, setSwitchPath ] = useState({});
    const { currentReport } = useContext(ReportsContext);
    const { data: allReports } = useReportsSWR();

    useEffect(() => {
        if(currentReport.detailed_report){
            promisedParseJSON(allReports?.find((r) => r.report_id == currentReport?.detailed_report?.report_id)?.parameter)
                .then((path) => { setSwitchPath(path) }).catch((e) => {
                    setSwitchPath({});
                });
        }else if(currentReport.summary_report){
            promisedParseJSON(allReports.find((r) => r.report_id == currentReport.summary_report?.report_id)?.parameter)
                .then((path) => { setSwitchPath(path) }).catch((e) => {
                    setSwitchPath({});
                });
        }
    }, [ allReports?.length ])

    return (
        <FormGroup name={filter?.code}>
            <Label>&nbsp;</Label>
            <SubmitButtonDropdown
                {...filter.parsedParams?.componentProps}
                isDoubleReport={(!!currentReport.detailed_report || !!currentReport.summary_report)}
                isSummary={!!currentReport.detailed_report}
                switchPath={switchPath?.route}
                getData={() => {}}
                formikRef={formikRef}
                reportType={currentReport?.code}
                formikFilters={{ ...getReportQueryParamsFromFilters(formikRef.current?.values, currentReport.filters), custom_report_id: customReportId }}
                isOpenHandlers={isOpenHandlers}
            />
        </FormGroup>
    )
}

export default EngineFilterSearchButton