import { FormikDateTime, FormikInputNumber, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import {
    Alert,
    Button,
    Col,
    Collapse,
    FormGroup,
    Label, ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import { mixed, number, object, string } from 'yup';
import AnalyticsModal from '../../../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import Required from '../../../../../../components/formik/Required';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { fail } from "@spordle/toasts";
// contexts
import { CreditTypesContext } from '../../../../../../contexts/CreditTypesContext';
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';

const MemberProfileTransactionAdd = ({ isOpen, toggle, refreshTable }) => {

    const creditTypesContext = useContext(CreditTypesContext);
    const organizationContext = useContext(OrganizationContext);
    const membersContext = useContext(MembersContext);
    const i18nContext = useContext(I18nContext);

    const [ creditTypes, setCreditTypes ] = useState(null);

    const setCreditTypesOptions = (creditTypes) => {
        setCreditTypes(creditTypes.map((type) => ({
            value: type.credit_type_id,
            label: type.name,
            i18n: type.i18n,
        })))
    }

    useEffect(() => {
        if(isOpen){ // use effect when modal opens
            setCreditTypes(null);
            creditTypesContext.getCreditTypes(membersContext.currentMember.organisation.organisation_id, { active: 1 })
                .then((creditTypes) => {
                    setCreditTypesOptions(creditTypes || [])
                }).catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        setCreditTypes([]);
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }
    }, [ isOpen, membersContext.currentMember?.organisation?.organisation_id ]) // If we refresh, state will be empty on load

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='MemberProfileTransactionAdd'>
            <Formik
                initialValues={{
                    creditTypeId: '',
                    amount: '',
                    expirationDate: '',
                    status: '1',
                    note: '',
                }}
                validationSchema={object().shape({
                    creditTypeId: string().required(<Translate id='members.profile.transactions.memberProfileTransactions.addModal.validation.creditType.required' />),
                    amount: number().required(<Translate id='members.profile.transactions.memberProfileTransactions.addModal.validation.amount.required' />),
                    expirationDate: mixed().test({
                        name: 'expirationDateTestValid',
                        message: <Translate id='members.profile.transactions.memberProfileTransactions.addModal.validation.expirationDate.valid' />,
                        test: (value) => {
                            if(value !== undefined){
                                return moment.isMoment(value)
                            }
                            return true // return true because the field is not required

                        },
                    }).test({
                        name: 'expirationDateTestAfter',
                        message: <Translate id='members.profile.transactions.memberProfileTransactions.addModal.validation.expirationDate.validAfter' />,
                        test: (value) => {
                            if(value !== undefined){
                                return moment.isMoment(value) && value.isAfter(moment().subtract(1, 'day'))
                            }
                            return true // return true because the field is not required

                        },
                    }),
                    // should never be empty
                    status: string().required(),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    setStatus();
                    membersContext.createMemberCredit(membersContext.state.currentMember.member_id, {
                        credit_type_id: values.creditTypeId,
                        amount: Math.round(values.amount * 100),
                        expiration_date: values.expirationDate ? values.expirationDate.format('YYYY-MM-DD') : '',
                        active: values.status,
                        organisation_id: organizationContext.organisation_id,
                        note: values.note,
                    })
                        .then(() => {
                            setSubmitting(false);
                            refreshTable();
                            toggle();
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                if(error.i18n){
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                }else{
                                    switch (error.message){
                                        case '3045':// The member doesn't belong to this organisation.
                                            setStatus(<Translate id={`members.profile.transactions.memberProfileTransactions.addModal.error.${error.message}`} />);
                                            break;
                                        default:
                                            setStatus(<Translate id='misc.error' />);
                                            break;
                                    }
                                }
                                setSubmitting(false);
                            }
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalHeader toggle={toggle}>
                                <Translate id='members.profile.transactions.memberProfileTransactions.add' />
                            </ModalHeader>

                            <ModalBody>
                                <Row>
                                    <Col md='6'>
                                        <FormGroup>
                                            <Label><Translate id='members.profile.transactions.memberProfileTransactions.addModal.creditType' /> <Required /></Label>
                                            <FormikSelect
                                                isLoading={!creditTypes}
                                                id='creditTypeId'
                                                name='creditTypeId'
                                                renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                searchKeys={[
                                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                                ]}
                                                options={creditTypes ?? []}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md='6'>
                                        <FormGroup>
                                            <Label><Translate id='members.profile.transactions.memberProfileTransactions.addModal.amount' /> <Required /></Label>
                                            <I18nContext.Consumer>
                                                {({ getGenericLocale }) => (
                                                    <FormikInputNumber
                                                        allowLeadingZeros fixedDecimalScale
                                                        id='amount'
                                                        name='amount'
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        thousandSeparator=' '
                                                        decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                        suffix={getGenericLocale() === 'fr' ? '$' : undefined}
                                                        prefix={getGenericLocale() !== 'fr' ? '$' : undefined}
                                                        autoComplete="off"
                                                        // autoComplete="new-password" doesn't seem to work on FormikInputNumber, use autoComplete="off"
                                                    />
                                                )}
                                            </I18nContext.Consumer>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md='6'>
                                        <FormGroup>
                                            <Label><Translate id='members.profile.transactions.memberProfileTransactions.addModal.expirationDate' /></Label>
                                            <FormikDateTime
                                                id='expirationDate'
                                                name='expirationDate'
                                                timeFormat={false}
                                                isValidDate={(current) => moment(current).isAfter(moment().subtract(1, 'day'))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md='6'>
                                        <Label><Translate id='members.profile.transactions.memberProfileTransactions.addModal.status' /></Label>
                                        <FormikSelect
                                            id='status'
                                            name='status'
                                            search={false}
                                            loadingStatus='success'
                                            defaultData={[
                                                {
                                                    value: '1',
                                                    label: 'misc.active',
                                                    translateLabel: true,
                                                },
                                                {
                                                    value: '0',
                                                    label: 'misc.inactive',
                                                    translateLabel: true,
                                                },
                                            ]}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label><Translate id='members.profile.transactions.memberProfileTransactions.addModal.note' /></Label>
                                            <FormikTextArea
                                                trim
                                                id='note'
                                                name='note'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Collapse isOpen={!!formik.status} unmountOnExit mountOnEnter>
                                    <Alert color='danger' className='mb-0'>
                                        {formik.status}
                                    </Alert>
                                </Collapse>
                            </ModalBody>

                            <ModalFooter>
                                <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                <Button color='primary' type='button' outline className='ml-2' disabled={formik.isSubmitting} onClick={toggle}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    );
}

export default MemberProfileTransactionAdd;