import { SpordleTableContext } from '@spordle/datatables';
import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import {
    Button, FormGroup,
    Label, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { object, string } from 'yup';
import { success, fail } from '@spordle/toasts';
import AnalyticsModal from '../../../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { I18nHelperContext, RenderI18nForm } from "../../../../../../helpers/i18nHelper";
import Required from '../../../../../../components/formik/Required';
import FormikTiny from '../../../../../../components/formik/FormikTiny';

const OrganizationDocumentTypesAdd = ({ isOpen, toggle }) => {
    const i18nHelper = useContext(I18nHelperContext);
    const spordleTable = useContext(SpordleTableContext);
    const organizationContext = useContext(OrganizationContext);

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='OrganizationDocumentTypesAdd'>
            <Formik
                initialValues={{
                    ...i18nHelper.getInitialValues(),
                    active: '1',
                }}
                validationSchema={object().shape({
                    ...i18nHelper.getValidationSchema({ name: string().required(<Translate id='organization.settings.organizationContactTypes.add.name.required' />) }),
                    active: string().required(<Translate id='organization.settings.organizationContactTypes.add.status.required' />),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    const apiValues = { ...values, ...i18nHelper.getAPIValues(values) };
                    delete apiValues.i18n
                    organizationContext.createOrganizationDocumentType(organizationContext.organisation_id, apiValues)
                        .then((data) => {
                            spordleTable.refreshTable();
                            toggle();
                            success();
                        })
                        .catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                console.error(e.message);
                                setSubmitting(false);
                                fail();
                            }
                        })
                }}
            >
                {(formik) => {
                    return (
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <Form>
                                <ModalHeader toggle={toggle}><Translate id='settings.documentTypes.add.title' /></ModalHeader>
                                <ModalBody>
                                    <RenderI18nForm field="name">
                                        {({ fieldName, fieldLabel }) => {
                                            return (
                                                <FormGroup key={fieldName}>
                                                    <Label for={fieldName} className='text-muted'>{fieldLabel}</Label> <Required />
                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                </FormGroup>
                                            )
                                        }}
                                    </RenderI18nForm>

                                    <RenderI18nForm field="description">
                                        {({ fieldName, fieldLabel }) => {
                                            return (
                                                <FormGroup key={fieldName}>
                                                    <Label for={fieldName} className='text-muted'>{fieldLabel}</Label>
                                                    <FormikTiny id={fieldName} name={fieldName} rows="5" trim />
                                                </FormGroup>
                                            )
                                        }}
                                    </RenderI18nForm>

                                    <FormGroup>
                                        <Label for='active' className='text-muted'><Translate id='settings.documentTypes.status' /></Label>
                                        <FormikSelect
                                            id='active' name='active'
                                            defaultData={[
                                                { value: '1', label: 'misc.active', translateLabel: true },
                                                { value: '0', label: 'misc.inactive', translateLabel: true },
                                            ]}
                                            loadingStatus='success'
                                        />
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                    <Button color='primary' type='button' outline onClick={toggle} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </Form>
                        </OverlayLoader>
                    )
                }}
            </Formik>
        </AnalyticsModal>
    )
}

export default OrganizationDocumentTypesAdd;
