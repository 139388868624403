export default{
    "organization.profile.users.title" : "Organization Users",

    "organization.profile.users.table.name"            : "Name",
    "organization.profile.users.table.role"            : "Role",
    "organization.profile.users.table.email"           : "Email",
    "organization.profile.users.table.status"          : "Active",
    "organization.profile.users.table.status.active"   : "Active",
    "organization.profile.users.table.status.inactive" : "Inactive",
    "organization.profile.users.table.status.expired"  : "Expired",
}