import { FormikSelect } from '@spordle/formik-elements';
import { useContext } from 'react';
import { I18nContext } from '../../../contexts/I18nContext';
import { TransferContext } from '../../../contexts/TransferContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';

const AddTransferExtensionsSelect = ({ id = "transfer_extension_id", name = "transfer_extension_id" }) => {
    const transferContext = useContext(TransferContext);
    const { getGenericLocale } = useContext(I18nContext);

    return (
        <FormikSelect
            id={id} name={name}
            renderOption={({ option: transferExtension }) => <DisplayI18n field='name' defaultValue={transferExtension.label} i18n={transferExtension.i18n} />}
            searchKeys={[
                `i18n.${getGenericLocale()}.name`,
            ]}
            loadData={(from) => {
                switch (from){
                    case 'CDM':
                        return transferContext.getTransferExtensions({ active: 1 }, false, true)
                            .then((transferExtensions) => {
                                return transferExtensions.sort((extensionA, extensionB) => parseInt(extensionA.display_order) - parseInt(extensionB.display_order))
                                    .map((transferStatus) => ({
                                        value: transferStatus.transfer_extension_id,
                                        label: transferStatus.name,
                                        i18n: transferStatus.i18n,
                                    }))
                            })
                    default:
                        break;
                }
            }}
        />
    );
}

export default AddTransferExtensionsSelect;