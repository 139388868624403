import { useSpordleTable } from "@spordle/datatables"
import { groupByAsArray } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import SpordleSelect from "@spordle/spordle-select"
import { useCallback, useContext } from "react";
import { Col, Row } from "reactstrap"
import { MembersContext } from "../../../../../contexts/MembersContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";

const OnlineStoreRegistrationFilters = () => {
    const spordleTable = useSpordleTable();
    const membersContext = useContext(MembersContext)

    const getPaymentOptions = useCallback(() => {
        return groupByAsArray(
            spordleTable.getData().map((data) => ({ data: data, paymentMethodCode: data.invoice_payment_method?.code })),
            'paymentMethodCode',
        ).filter((data) => !!data.paymentMethodCode).map((data) => {
            return {
                value: data.key,
                label: data.values?.[0]?.data?.invoice_payment_method?.name,
                i18n: data.values?.[0]?.data?.invoice_payment_method?.i18n,
            }
        });
    }, [ spordleTable.getData() ]);

    const getRegistrationPackagesOptions = useCallback(() => {
        return groupByAsArray(spordleTable.getData().filter((r) => r.type !== 'WAIVER' && r.type !== 'AFFILIATION').map((r) => r.memberRegistration.registration_fee), 'registration_fee_id')
            .map((data) => {
                return {
                    value: data.key,
                    label: data.values[0].name,
                    i18n: data.values[0].i18n,
                }
            });
    }, [ spordleTable.getData() ]);

    return (
        <Row className="mb-4 pb-4 border-bottom" form>
            <Col sm='4'>
                <SpordleSelect
                    id='status-filter'
                    defaultData={[
                        { value: 'PENDING', label: 'Pending' },
                        { value: 'CANCELLED', label: 'Cancelled' },
                        { value: 'CONFIRMED', label: 'Confirmed' },
                    ]}
                    renderOption={({ option }) => <Translate id={`memberProfile.registration.status.${option.value.toLowerCase()}`} />}
                    loadingStatus='success'
                    search={false} clearable
                    placeholder='memberProfile.registration.form.status'
                    onOptionSelected={([ value ]) => {
                        spordleTable.filterChange('status', value);
                    }}
                />
            </Col>
            <Col sm='4'>
                <SpordleSelect
                    id='registrationPackage-filter'
                    options={getRegistrationPackagesOptions()}
                    renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                    loadingStatus='success'
                    multi clearable
                    placeholder='memberProfile.registration.form.registration'
                    onOptionSelected={(options) => {
                        spordleTable.filterChange('registrationPackage', options.length ? options : null);
                    }}
                />
            </Col>
            {(!membersContext.currentMember?.minimalistic_view) &&
                <Col sm='4'>
                    <SpordleSelect
                        id='paymentMethod-filter'
                        options={getPaymentOptions()}
                        renderOption={({ option }) => <DisplayI18n defaultValue={option.label} i18n={option.i18n} field='name' />}
                        loadingStatus='success'
                        multi clearable search={false}
                        preventSelectedReorder
                        placeholder='invoice.paymentMethod'
                        onOptionSelected={(options) => {
                            spordleTable.filterChange('paymentMethod', options.length ? options : null);
                        }}
                    />
                </Col>
            }
        </Row>
    )
}

export default OnlineStoreRegistrationFilters