import {
    Card,
    CardBody,
    Col,
    Row
} from "reactstrap";
import { waiverNotSigned, WaiverSignatureDisplay } from "../../../../../../../../helpers/waiversHelper";
import WaiverInfo from "./WaiverInfo";

/**
* @param {object} waiver waiver data
* @param {object} signedBy signedBy data (could probably be merged in waiver's data)
* @param {string} answer the answer to the waiver
* @param {func} openModal opens waiver modal
*/
const WaiverCard = ({ waiver, signedBy, apiPartner, answer, openModal, hideIcon = false }) => {
    return (
        <Card className="card-shadow card-hover clickable mb-2" onClick={openModal}>
            <CardBody className="p-2">
                {answer === waiverNotSigned ?
                    <WaiverInfo answer={answer} waiver={waiver} hideIcon={hideIcon} />
                    :
                    <Row form>
                        <Col sm="6" className="d-flex flex-column justify-content-center">
                            <WaiverInfo answer={answer} waiver={waiver} hideIcon={hideIcon} />
                        </Col>
                        {/*  ADD THE COMPONENT HERE */}
                        <Col sm="6" className="d-flex flex-column justify-content-center">
                            <WaiverSignatureDisplay waiver={waiver} minifiedView hideIcon />
                        </Col>
                    </Row>
                }
            </CardBody>
        </Card>
    );
}

export default WaiverCard;