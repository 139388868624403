export default{
    "organization.profile.roles.addRole.title"                             : "Add Role",
    "organization.profile.roles.addRole.roleTitle"                         : "Title",
    "organization.profile.roles.addRole.roleTitle.required"                : "Title is required",
    "organization.profile.roles.addRole.description"                       : "Description",
    "organization.profile.roles.addRole.description.required"              : "Description is Required",
    "organization.profile.roles.addRole.isSystem"                          : "System Role",
    "organization.profile.roles.addRole.status"                            : "Status",
    "organization.profile.roles.addRole.status.required"                   : "Status is required",
    "organization.profile.roles.addRole.status.active"                     : "Active",
    "organization.profile.roles.addRole.status.inactive"                   : "Inactive",
    "organization.profile.roles.addRole.dates.start"                       : "This Role will be available from",
    "organization.profile.roles.addRole.dates.end"                         : "Until",
    "organization.profile.roles.addRole.dates.startDate.format.validation" : "Start Date must be of a valid format",
    "organization.profile.roles.addRole.dates.endDate.format.validation"   : "End Date must be of a valid format",
    "organization.profile.roles.addRole.dates.validation"                  : "The Start Date must be before the End Date",

    // Table
    "organization.profile.roles.table.title"       : "Title",
    "organization.profile.roles.table.description" : "Description",
    "organization.profile.roles.table.active"      : "Active",
    "organization.profile.roles.table.myRole"      : "My role",
    "organization.profile.roles.table.emptyLayout" : "No Roles",

    // Multi Select
    "organization.profile.roles.multiSelect.active"   : "Active",
    "organization.profile.roles.multiSelect.inactive" : "Inactive",
}