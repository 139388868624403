import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import withContexts from '../helpers/withContexts';
import { IdentityRolesContext } from './IdentityRolesContext';

/** @type {React.Context<Omit<QualificationCategoriesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const QualificationCategoriesContext = createContext();
QualificationCategoriesContext.displayName = 'QualificationCategoriesContext';

class QualificationCategoriesContextProvider extends React.Component{
    /** @type {?Array} */
    qualificationCategories = null;

    /**
     * Get a list of all the categories for a clinic
     * @param {boolean} [fromApi=false] Will fetch the data from the api
     * @returns {Promise.<Array>}
     */
    getQualificationCategories = (fromApi = false) => {
        if(Array.isArray(this.qualificationCategories) && !fromApi){
            return Promise.resolve(this.qualificationCategories);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/qualification-categories`, query: { organisation_id: this.props.IdentityRolesContext.federation.organisation_id } }))
            .then((response) => {
                if(response.data.status){
                    return this.qualificationCategories = response.data['qualification-categories'];
                }
                this.qualificationCategories = null;
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a specified QualificationCategory with new values
     * @param {string} qualificationCategoryId ID of the QualificationCategory to update
     * @param {object} values Object containing the values to update - The keys to the values need to be the same as the API ones
     * @returns {Promise}
     */
    updateQualificationCategory = (qualificationCategoryId, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                case 'color':
                    params.append('color', values[key].toString());
                    break;
                default: // name, description, etc
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `qualification-categories/${qualificationCategoryId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a QualificationCategory under an Organization with specified values
     * @param {string} organizationId ID of the Organization to add a QualificationCategory to
     * @param {object} values Values to create a QualificationCategory with
     * @returns {Promise}
     */
    createQualificationCategory = (organizationId, values) => {
        const params = new URLSearchParams({
            organisation_id: organizationId,
        })

        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', values.active ? 1 : 0);
                    break;
                default: // name, descritpion, etc
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `qualification-categories` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.qualification_category_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a specific QualificationCategory
     * @param {string} classId ID of the QualificationCategory to delete
     * @returns {Promise}
     */
    deleteQualificationCategory = (qualificationCategoryId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `qualification-categories/${qualificationCategoryId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    getQualificationCategory = (categoryId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/qualification-categories/${categoryId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data['qualification-categories'][0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <QualificationCategoriesContext.Provider value={this}>
                {this.props.children}
            </QualificationCategoriesContext.Provider>
        );
    }
}

export default withContexts(IdentityRolesContext)(QualificationCategoriesContextProvider);
