export default{
    "organization.settings.sidePanelOrganizationMemberTypes.title"                  : "Member Type Information",
    "organization.settings.sidePanelOrganizationMemberTypes.status"                 : "Status",
    "organization.settings.sidePanelOrganizationMemberTypes.status.active"          : "Active",
    "organization.settings.sidePanelOrganizationMemberTypes.status.inactive"        : "Inactive",
    "organization.settings.sidePanelOrganizationMemberTypes.status.required"        : "Status is required",
    "organization.settings.sidePanelOrganizationMemberTypes.forms"                  : "Questionnaire",
    "organization.settings.sidePanelOrganizationMemberTypes.waivers"                : "Waivers",
    "organization.settings.sidePanelOrganizationMemberTypes.selected"               : "{amount, plural, =0 {None selected} one {# selected} other {# selected}}",
    "organization.settings.sidePanelOrganizationMemberTypes.update.success.text"    : "Member Type updated",
    "organization.settings.sidePanelOrganizationMemberTypes.update.fail.text"       : "An error occurred during the Member Type update, please refresh the page et try again",
    // actions
    "organization.settings.sidePanelOrganizationMemberTypes.actions.remove"         : "Remove",
    "organization.settings.sidePanelOrganizationMemberTypes.actions.remove.topText" : "You are about to remove this Member Type from your Organization",
    // tabs
    "organization.settings.sidePanelOrganizationMemberTypes.tabs.details"           : "Details",
}