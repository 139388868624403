import { FormikSelect } from "@spordle/formik-elements";
// Language
import Translate from "@spordle/intl-elements";
import React from "react";
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import FormikEditable from '../../../../../../components/formik/FormikEditable';
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import { fail, success } from '@spordle/toasts';
// Contexts
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import withContexts from '../../../../../../helpers/withContexts';


class SidePanelMemberFamilyRelations extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
        }
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <div className="p-3 border-bottom">
                    <div className='d-flex mb-3 align-items-center'>
                        <button type='button' className='reset-btn p-2 text-dark' onClick={this.props.toggle}><i className='mdi mdi-arrow-right font-20' /></button>
                        <div className="ml-auto d-flex align-items-center">
                            <Link to={this.props.selectedRows[0].linkedMember.memberId === this.props.MembersContext.currentMember.member_id ? `/members/profile/${this.props.selectedRows[0].originMember.memberId}` : `/members/profile/${this.props.selectedRows[0].linkedMember.memberId}`} className="mr-2">
                                <Translate id='misc.goToProfile' /> <i className="mdi mdi-chevron-right" />
                            </Link>
                            {this.props.selectedRows[0].linkedMember.memberId !== this.props.MembersContext.currentMember.member_id &&
                                <SidePanel.ActionsMenu action='DELETE' componentCode='members' componentPermissionCode='members_profile' label="misc.action">
                                    <ConfirmModal
                                        toggler={(toggle) => (
                                            <SidePanel.MenuAction onClick={toggle}>
                                                <Translate id='misc.delete' />
                                            </SidePanel.MenuAction>
                                        )}
                                        modalTitle={<Translate id='members.profile.overview.memberFamilyRelations.sidePanel.delete.text' />}
                                        actionButtonText='misc.confirm'
                                        translateActionButtonText
                                        color='danger'
                                        onConfirm={async() => {
                                            return this.props.MembersContext.deleteMemberRelation(this.props.MembersContext.currentMember.member_id, this.props.getCommonValue('memberRelationId'))
                                                .then(() => {
                                                    success({ msg: 'members.profile.overview.memberFamilyRelations.sidePanel.delete.success' });
                                                    this.props.tableRef.deleteRow(this.props.getCommonValue('memberRelationId')).then(this.props.forceCloseSidePanel)
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                        }}
                                    />
                                </SidePanel.ActionsMenu>
                            }
                        </div>
                    </div>
                    {this.props.selectedRows[0].linkedMember.memberId === this.props.MembersContext.currentMember.member_id ?
                        <>
                            <div className='font-bold h3 mb-0'>
                                {this.props.getCommonValue('originMember').firstName} {this.props.getCommonValue('originMember').lastName}
                            </div>
                            <div className='text-muted'>
                                {this.props.getCommonValue('originMember').uniqueIdentifier ?
                                    <><Translate id='members.profile.overview.memberFamilyRelations.uniqueIdentifier' /> {this.props.getCommonValue('originMember').uniqueIdentifier}</>
                                    : ''}
                            </div>
                        </>
                        :
                        <>
                            <div className='font-bold h3 mb-0'>
                                {this.props.getCommonValue('linkedMember').firstName} {this.props.getCommonValue('linkedMember').lastName}
                            </div>
                            <div className='text-muted'>
                                {this.props.getCommonValue('linkedMember').uniqueIdentifier ?
                                    <><Translate id='members.profile.overview.memberFamilyRelations.uniqueIdentifier' /> {this.props.getCommonValue('linkedMember').uniqueIdentifier}</>
                                    : ''}
                            </div>
                        </>
                    }
                </div>
                <div className="p-3 border-bottom">
                    <div className="mb-3">
                        {/* RELATION */}
                        <div className="text-muted"><Translate id='members.profile.overview.memberFamilyRelations.relation' /></div>
                        <FormikEditable
                            id='familyRelationFormikEditable'
                            disabled={this.props.selectedRows[0].linkedMember.memberId === this.props.MembersContext.currentMember.member_id}
                            noConfirmation
                            initialValues={{
                                relationId: this.props.getCommonValue('relation').relationId,
                                relation: this.props.getCommonValue('relation'),
                            }}
                            validationSchema={Yup.object().shape({
                                relationId: Yup.string().required(<Translate id='members.profile.overview.memberFamilyRelations.relation.required' />),
                            })}
                            onSubmit={(values) => {
                                if(values.relationId !== this.props.getCommonValue('relation').relationId){
                                    this.setState(() => ({ isLoading: true }))
                                    const newValues = this.props.createNewValues({
                                        relation: {
                                            i18n: values.relation?.i18n,
                                            name: values.relation?.label,
                                            relationId: values.relationId,
                                        },
                                    });
                                    this.props.MembersContext.updateMemberRelation(this.props.MembersContext.currentMember.member_id, this.props.getCommonValue('memberRelationId'), values.relationId)
                                        .then(() => {
                                            this.props.syncRows(newValues)
                                            success({ msg: 'members.profile.overview.memberFamilyRelations.sidePanel.update.success' })
                                            this.setState(() => ({ isLoading: false }))
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                                this.setState(() => ({ isLoading: false }))
                                            }
                                        })
                                }
                            }}
                        >
                            {(isEditing, options, formik) => {
                                if(!isEditing){
                                    if(this.props.selectedRows[0].linkedMember.memberId === this.props.MembersContext.currentMember.member_id){
                                        return (
                                            <div className='font-medium'><Translate id='members.profile.overview.memberFamilyRelations.sidePanel.relation' /> <DisplayI18n field='name' defaultValue={this.props.getCommonValue('relation').name} i18n={this.props.getCommonValue('relation').i18n} /></div>
                                        )
                                    }
                                    return (
                                        <div className='font-medium'><DisplayI18n field='name' defaultValue={this.props.getCommonValue('relation').name} i18n={this.props.getCommonValue('relation').i18n} /></div>
                                    )

                                }
                                return (
                                    <FormikSelect
                                        id='relationId'
                                        name='relationId'
                                        onOptionSelected={([ value ], table) => {
                                            const select = table.getSpordleTable().getData().find((d) => d.value === value);

                                            formik.setFieldValue('relation', select)
                                            options.stopEditing()
                                        }}
                                        renderOption={({ option }) => (
                                            <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                        )}
                                        menuIsDefaultOpen autoFocus
                                        searchKeys={[
                                            `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                        ]}
                                        options={
                                            this.props.relations ?
                                                this.props.relations.map((relation) => ({
                                                    ...relation,
                                                    value: relation.relation_id,
                                                    i18n: relation.i18n,
                                                    label: relation.name,
                                                }))
                                                : []
                                        }
                                    />
                                )

                            }}
                        </FormikEditable>
                    </div>
                </div>
            </OverlayLoader>
        )
    }
}

export default withContexts(I18nContext, MembersContext)(SidePanelMemberFamilyRelations);