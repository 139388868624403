import Translate from '@spordle/intl-elements';
import { useState } from 'react';
import { Button, Collapse, Fade, ModalBody, ModalFooter } from 'reactstrap';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import { DisplayI18n } from '../../helpers/i18nHelper';
import CustomAlert from '../CustomAlert';
import CustomAnimatedIcon from '../customAnimatedIcon/CustomAnimatedIcon';
import OverlayLoader from '../loading/OverlayLoader';

const ManualRegistrationConvertInvoiceConfirm = ({
    convert,
    resetTable,
    toggle,
    goToPrevious,
    selectedMerchantAccount,
    selectedIdentity,
    selectedPaymentMethod,
    member,
    invoiceNumber,
    convertToTransactional,
    closeModal,
}) => {
    const [ status, setStatus ] = useState({
        isSuccessful: false,
        isLoading: false,
        error: null,
    })

    const confirm = () => {
        setStatus((prev) => ({ ...prev, isLoading: true }))
        convert()
            .then(async() => {
                setStatus({
                    isLoading: false,
                    isSuccessful: true,
                });

                resetTable();
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e.message);
                    setStatus({
                        isLoading: false,
                        isSuccessful: false,
                        error: <DisplayI18n field="message" defaultValue={e.message} i18n={e.i18n} />,
                    })
                }
            })
    }

    return (
        <OverlayLoader isLoading={status.isLoading}>
            {status.isSuccessful && !status.isLoading ?
                <Fade in>
                    <ModalBody className="text-center text-success">
                        <CustomAnimatedIcon
                            icon="checkmark"
                            id="convertion-success"
                            withCircle
                            strokeWidth="10px"
                            size={50}
                        />
                        <div className="text-center h5 font-bold pt-2">
                            <Translate id="misc.success" />
                        </div>
                        <p className="text-body">
                            <Translate id={`components.manualRegistration.manualRegistrationConfirmation.to${convertToTransactional ? "Transactional" : "Manual"}`} />
                        </p>
                        <Button onClick={closeModal} type="button" color="success">
                            <Translate id="misc.close" />
                        </Button>
                    </ModalBody>
                </Fade>
                :
                <>
                    <ModalBody>
                        <div className='mb-3'>
                            <div className="h5 font-bold pt-2">
                                <Translate id='components.manualRegistration.manualRegistrationConfirmation' />
                            </div>
                            <div className="border rounded p-3">
                                {convertToTransactional ?
                                    <>
                                        <div className='mb-3'>
                                            <div className="font-bold text-dark mb-1">
                                                <Translate id='components.manualRegistration.convertInvoiceConfirm.selectedMerchant' />
                                            </div>
                                            <div>
                                                {selectedMerchantAccount}
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            <div className="font-bold text-dark mb-1">
                                                <Translate id='components.manualRegistration.convertInvoiceConfirm.selectedIdentity' />
                                            </div>
                                            <div>
                                                {selectedIdentity}
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className='mb-3'>
                                        <div className="font-bold text-dark mb-1">
                                            <Translate id='components.manualRegistration.convertInvoiceConfirm.selectedPaymentMethod' />
                                        </div>
                                        <div>
                                            {/* We can leave translation + code here for now, in the props we just send the code */}
                                            <Translate id={'members.profile.transactions.memberProfileTransactions.paymentMethods.' + selectedPaymentMethod} />
                                        </div>
                                    </div>
                                }
                                <div className='mb-3'>
                                    <div className="font-bold text-dark mb-1">
                                        <Translate id='components.manualRegistration.convertInvoiceConfirm.member' />
                                    </div>
                                    <div>
                                        {member}
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="font-bold text-dark mb-1">
                                        <Translate id='components.manualRegistration.convertInvoiceConfirm.invoiceNumber' />
                                    </div>
                                    <div>
                                        {invoiceNumber}
                                    </div>
                                </div>
                            </div>

                            <Collapse isOpen={!!status.error} appear mountOnEnter unmountOnExit>
                                {status.error &&
                                    <CustomAlert className='mt-3' color='danger' withTitle text={status.error} translateText={false} toggle={() => setStatus((prev) => ({ ...prev, error: null }))} />
                                }
                            </Collapse>
                        </div>
                    </ModalBody>
                    <ModalFooter className='justify-content-between'>
                        <Button color='primary' outline type='button' onClick={goToPrevious}><Translate id='misc.previous' /></Button>

                        <div className='text-right w-100'>
                            <Button color='primary' type='button' onClick={confirm}><Translate id='misc.confirm' /></Button>
                            <Button color='primary' type='button' outline onClick={toggle} className='ml-2'><Translate id='misc.cancel' /></Button>
                        </div>
                    </ModalFooter>
                </>
            }
        </OverlayLoader>
    )
}

export default ManualRegistrationConvertInvoiceConfirm