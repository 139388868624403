import { useContext, useState } from 'react';
import RegistrationForm from "./RegistrationForm";
import ReactDOM from 'react-dom';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { I18nContext } from '../../../contexts/I18nContext';
import { IntlProvider } from 'react-intl';
import { DocumentTypesContext } from '../../../contexts/DocumentTypesContext';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import { WaiversContext } from '../../../contexts/WaiversContext';
import Translate from '@spordle/intl-elements';
import { fail, fire, removeToast } from '@spordle/toasts';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import { Spinner } from 'reactstrap';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { useGenderSettingsSWR } from '../reportEngine/reportsEngineHelpers';
import { UtilsContext } from '../../../contexts/UtilsContext';
import { PhoneTypesContext } from '../../../contexts/PhoneTypesContext';
import { MembersContext } from '../../../contexts/MembersContext';
import { getMemberFields } from '../../../api/client/memberFields';
import { MessagesContext } from '../../../contexts/MessagesContext';

function copyStyles(src, dest){
    try{
        Array.from(src.styleSheets).forEach((styleSheet) => {
            dest.head.appendChild(styleSheet.ownerNode.cloneNode(true))
        });

        Array.from(src.fonts).forEach((font) => dest.fonts.add(font))
    }catch(e){
        console.error(e);
    }
}

export const renderInWindow = (component, title, skipPrintOnLoad = false) => {
    const newWindow = window.open(
        "",
        "",
        `width=${window.innerWidth},height=${window.innerHeight},left=200,top=200`,
    );

    function printWindow(){
        newWindow.print();
    }

    newWindow.document.write(`<!DOCTYPE html><html><head><title>${title}</title></head><body><div id="react"></div></body></html>`);
    copyStyles(window.document, newWindow.document);
    ReactDOM.render(component, newWindow.document.getElementById("react"));
    newWindow.document.close();
    newWindow.focus();

    setTimeout(() => {
        newWindow.document.getElementById("printBtn").addEventListener("click", printWindow);
    }, 100);

    if(!skipPrintOnLoad){
        newWindow.onpageshow = printWindow;
    }
}

const NewWindowOpener = (props) => {
    const documentTypesContext = useContext(DocumentTypesContext);
    const { getWaivers } = useContext(WaiversContext);
    const { getGenericLocale } = useContext(I18nContext);
    const orgContext = useContext(OrganizationContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const utilsContext = useContext(UtilsContext);
    const phoneTypesContext = useContext(PhoneTypesContext);
    const membersContext = useContext(MembersContext);
    const messageContext = useContext(MessagesContext)
    const [ loading, setLoading ] = useState(false)

    const { data, mutate } = useGenderSettingsSWR()

    const formatData = (resource, id) => {
        const newI18n = {}
        if(resource.i18n){
            Object.keys(resource.i18n).forEach((lang) => {
                // do it this way because the I18n from PrintForm is different from classic DisplayI18n
                newI18n[lang] = resource.i18n[lang].name
            })
        }else{
            newI18n.en = resource.name;
            newI18n.fr = resource.name;
        }
        return {
            value: resource[id],
            i18n: newI18n,
        }
    }

    const onOpenNewPage = () => {
        // Prevent print spam
        if(loading){
            return;
        }

        const toastId = 'NewWindowOpenerToast';

        setLoading(true);

        fire({
            id: toastId,
            icon: <Spinner size='sm' color='primary' />,
            theme: 'primary',
            permanent: true,
            msg: "form.manualRegistration.print.msg",
            info: "form.manualRegistration.print.info",
        });

        Promise.all([
            Promise.all([
                ...orgContext.breadcrumbs.map(({ organisation_id }) => getWaivers({ organisation_id })),
                getWaivers({ organisation_id: orgContext.organisation_id }),
            ]),
            Object.keys(data).length > 0 ? Promise.resolve(data) : mutate(),
            documentTypesContext.getDocumentTypes(),
            utilsContext.getIndigenousGroups(),
            utilsContext.getEthnicities(),
            phoneTypesContext.getPhoneTypes(),
            membersContext.getMemberContactTypes({ organisation_id: orgContext.organisation_id }),
            getMemberFields(),
            orgContext.settings.explanation_text_for_member_collected_data?.value ? messageContext.getSettingMessage(orgContext.settings.explanation_text_for_member_collected_data.value) : Promise.resolve(),
        ])
            .then(([ waiversPromises, utilsGenders, documents, indigenousGroup, ethnicities, phoneTypes, contactTypes, fieldsSettings, messages ]) => {
                const filteredDocs = orgContext.settings.attachment_for_new_member_in_registration.value.reduce((docTypes, typeId) => {
                    const foundDoc = documents.find(({ document_type_id }) => document_type_id === typeId);

                    if(foundDoc){
                        docTypes.push(foundDoc);
                    }

                    return docTypes;
                }, []);

                const waivers = waiversPromises.flatMap((promise) => promise).reduce((criticalWaivers, waiver) => {
                    if(waiver.critical == "1" && !criticalWaivers.some((w) => w.waiver_id === waiver.waiver_id)){
                        criticalWaivers.push(waiver);
                    }
                    return criticalWaivers;
                }, []);

                const genders = orgContext.settings.gender_selection?.value?.map((genderId) => {
                    const newI18n = {}
                    Object.keys(utilsGenders[genderId].i18n).forEach((lang) => {
                        // do it this way because the I18n from PrintForm is different from classic DisplayI18n
                        newI18n[lang] = utilsGenders[genderId].i18n[lang].name
                    })

                    return {
                        value: genderId,
                        i18n: newI18n,
                        displayOrder: parseInt(utilsGenders[genderId].sort),
                    }
                }) || []
                genders.sort((a, b) => a.displayOrder - b.displayOrder)

                const sortedIndigenousGroup = indigenousGroup.sort((a, b) => a.display_order - b.display_order).map((indigenousGroup) => formatData(indigenousGroup, 'indigenous_group_id'))

                const sortedEthnicities = ethnicities.sort((a, b) => a.display_order - b.display_order).map((ethnicity) => formatData(ethnicity, 'ethnicity_id'))

                const sortedPhoneTypes = phoneTypes.sort((a, b) => a.display_order - b.display_order).map((phoneType) => formatData(phoneType, 'phone_type_id'))

                const sortedContactTypes = contactTypes.sort((a, b) => a.display_order - b.display_order).map((contactType) => formatData(contactType, 'member_contact_type_id'))

                return [ waivers, filteredDocs, genders, sortedIndigenousGroup, sortedEthnicities, sortedPhoneTypes, sortedContactTypes, fieldsSettings, messages?.[0] ];
            })
            .then(([ waivers, documents, genders, indigenousGroup, ethnicities, phoneTypes, contactTypes, fieldsSettings, demographicMessage ]) => {
                renderInWindow(
                    <div className="container py-5">
                        <IntlProvider locale={getGenericLocale()}>
                            <RegistrationForm
                                lang={getGenericLocale()}
                                org={orgContext}
                                fed={identityRolesContext.federation}
                                waivers={waivers}
                                documents={documents}
                                genders={genders}
                                indigenousGroup={indigenousGroup}
                                ethnicities={ethnicities}
                                phoneTypes={phoneTypes}
                                contactTypes={contactTypes}
                                fieldsSettings={fieldsSettings}
                                demographicMessage={demographicMessage}
                                requiredFields={orgContext.settings.member_mandatory_special_fields_for_registration?.value}
                            />
                        </IntlProvider>
                    </div>,
                    "Registration Form",
                );
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){

                    console.error(e);

                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />,
                        skipInfoTranslate: true,
                    });

                }
            })
            .finally(() => {
                removeToast(toastId);
                setLoading(false);
            })
    }

    return props.children ?
        <div className={props.className} role='button' onClick={onOpenNewPage}>{props.children}</div>
        :
        <a className={props.className || "btn btn-primary text-white"} target="_blank" onClick={onOpenNewPage}>
            <Translate id="reports.print.manualRegistration" />
        </a>
}

export default NewWindowOpener;
