import React, { useState } from "react";
import Translate, { DateFormat } from "@spordle/intl-elements";

// contexts
import withContexts from "../../../../../../helpers/withContexts";
import { ClinicsContext } from "../../../../../../contexts/ClinicsContext";
import SidePanelAttendeeSharedInfo from "./SidePanelAttendeeSharedInfo";
import { Alert, Collapse } from "reactstrap";
import { stringBuilder } from "@spordle/helpers";

class SidePanelAttendeeInfo extends React.Component{

    render(){
        return (
            <>
                <div className="h4 font-bold mb-3"><Translate id='clinics.profile.attendees.sidepanel.attendeeInfo' /></div>
                {this.props.selectedRows[0].cancelled_at &&
                    <MemberCancelledWarning
                        date={this.props.selectedRows[0].cancelled_at}
                        cancelledBy={this.props.selectedRows[0].cancelled_by}
                        reason={this.props.selectedRows[0].cancelled_reason}
                    />
                }
                <SidePanelAttendeeSharedInfo {...this.props} />

                <div className="mb-3">
                    <div className="text-muted"><Translate id='clinics.profile.attendees.sidepanel.certified.label' /></div>
                    <div className="font-medium">
                        {this.props.getCommonValue('certified') == 1 ?
                            <>
                                <i className='mdi mdi-check text-primary mr-2' /><Translate id='clinics.profile.attendees.sidepanel.certified' /><span className='small'> - <DateFormat value={this.props.getCommonValue('certified_date')} utc /></span>
                            </>
                            :
                            <Translate id='clinics.profile.attendees.sidepanel.certified.not' />
                        }
                    </div>
                </div>
            </>
        )
    }
}

const MemberCancelledWarning = ({ reason, date, cancelledBy }) => {
    const [ isOpen, setIsOpen ] = useState(false);
    const reasonPreviewLength = 130;
    const reasonIsLong = reason ? reason.length > (reasonPreviewLength + 10) : false;

    return (
        <Alert className="mb-0 mb-3 px-2 position-relative" color="secondary">
            <div className="d-flex align-items-center">
                <div className="mr-2">
                    <i className="font-22 mdi mdi-information-outline" />
                </div>
                <div className="font-medium">
                    <Translate id='clinics.profile.attendees.sidepanel.header.cancelled' />
                    <small className="d-block text-body">
                        <Translate
                            id='clinics.profile.attendees.sidepanel.header.cancelledAtBy'
                            values={{
                                date: date,
                                name:
                                    <span className="font-medium">
                                        {cancelledBy?.name + ' ' + cancelledBy?.family_name}
                                    </span>
                                ,
                            }}
                        />
                    </small>
                </div>
            </div>
            {reason &&
                <div className="pt-2 border-top mt-2">
                    {reasonIsLong ?
                        <div className="position-relative w-100">
                            <span style={{ opacity: +isOpen }} className="position-absolute bottom-0 overflow-hidden top-0 right-0 left-0">
                                {reason}
                            </span>
                            <span style={{ opacity: +!isOpen }}>
                                {reason.substring(0, reasonPreviewLength)}{(isOpen ? "" : <wbr>...</wbr>)}
                            </span>
                            <Collapse style={{ opacity: 0 }} isOpen={isOpen}>
                                {reason.substring(reasonPreviewLength, reason.length)}
                            </Collapse>
                            <button
                                className={stringBuilder({ "rotate-180": isOpen }, "position-absolute bottom-0 left-0 right-0 mx-auto reset-btn bg-white border rounded-circle mb-n4 transition")}
                                style={{ width: 25, height: 25 }}
                                type="button"
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                <i className="mdi mdi-chevron-down" />
                            </button>
                        </div>
                        :
                        reason
                    }
                </div>
            }
        </Alert>
    )
}

export default withContexts(ClinicsContext)(SidePanelAttendeeInfo)