import { FormikSelect } from '@spordle/formik-elements';
import { useField } from 'formik';
import { useContext } from 'react';
import { TransferContext } from '../../../contexts/TransferContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';

const AddTransferDirectionSelect = ({ isOutgoing, onChange }) => {
    const transferContext = useContext(TransferContext);
    const [ _field, _meta, helpers ] = useField({ name: 'transfer_direction_id' });


    return (
        <FormikSelect
            name='transfer_direction_id'
            id='transfer_direction_id'
            renderOption={({ option: transferDirection }) => <DisplayI18n field='name' defaultValue={transferDirection.label} i18n={transferDirection.i18n} />}
            onOptionSelected={onChange}
            loadData={(from) => {
                switch (from){
                    case 'CDM':
                        return transferContext.getTransferDirections({ active: 1 })
                            .then((transferDirections) => {
                                return transferDirections.sort((durationA, durationB) => parseInt(durationA.display_order) - parseInt(durationB.display_order))
                                    .reduce((newArray, transferDirection) => {
                                        if(transferDirection.active == 1){
                                            if(isOutgoing(transferDirection.name)){
                                                helpers.setValue(transferDirection.transfer_direction_id)
                                            }

                                            newArray.push({
                                                value: transferDirection.transfer_direction_id,
                                                label: transferDirection.name,
                                                i18n: transferDirection.i18n,
                                                selected: isOutgoing(transferDirection.name),
                                            })
                                        }
                                        return newArray
                                    }, [])
                            })
                    default:
                        break;
                }
            }}
        />
    );
}

export default AddTransferDirectionSelect;