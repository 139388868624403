export default{
    "clinics.profile.attendees.sidePanelActions.certify.title" : "Certify",

    "clinics.profile.attendees.sidePanelActions.certify.certification.label"    : "Certification date",
    "clinics.profile.attendees.sidePanelActions.certify.certification.required" : "Certification date is required",
    "clinics.profile.attendees.sidePanelActions.certify.certification.before"   : "Certification date must be before the expiration date",
    "clinics.profile.attendees.sidePanelActions.certify.certification.after"    : "Certification date cannot be before the passage date",

    "clinics.profile.attendees.sidePanelActions.certify.expiration.label" : "Expiration date",
    "clinics.profile.attendees.sidePanelActions.certify.expiration.after" : "Expiration date cannot be before the certification date",

    "clinics.profile.attendees.sidePanelActions.certify.memo" : "Memo",

    "clinics.profile.attendees.sidePanelActions.certify.certificationNumber" : "Certification number",
}