export default{
    "organization.grouping.category.ZONES"   : "Zone",
    "organization.grouping.category.LEAGUES" : "League",

    "organization.grouping.add.title.ZONES"                     : "Add a zone",
    "organization.grouping.add.title.TOURNAMENTS"               : "Add a tournament",
    "organization.grouping.add.title.LEAGUES"                   : "Add a league",
    "organization.grouping.add.createOrg.orgCategory"           : "We could not find the {category} category for the parent organization",
    "organization.grouping.add.selectOrg.text.ZONES"            : "Please select the organizations that will be part of your zone.",
    "organization.grouping.add.selectOrg.text.TOURNAMENTS"      : "Please select the organizations that will be part of your tournament.",
    "organization.grouping.add.selectOrg.text.LEAGUES"          : "Please select the organizations that will be part of your league.",
    "organization.grouping.add.selectOrg.alreadyAdded"          : "Already added",
    "organization.grouping.add.selectOrg.orgs.required"         : "Please select at least one organization.",
    "organization.grouping.add.selectOrg.org"                   : "Organization",
    "organization.grouping.add.selectOrg.orgs"                  : "Organizations",
    "organization.grouping.add.selectOrg.org.required"          : "Organization is required.",
    "organization.grouping.add.selectOrg.divisions"             : "Divisions",
    "organization.grouping.add.selectOrg.divisions.required"    : "Please select at least one division.",
    "organization.grouping.add.selectOrg.categories"            : "Categories",
    "organization.grouping.add.createUser.text.ZONES"           : "Please select your role in the zone and add other users if necessary.",
    "organization.grouping.add.createUser.text.TOURNAMENTS"     : "Please select your role in the tournament and add other users if necessary.",
    "organization.grouping.add.createUser.text.LEAGUES"         : "Please select your role in the league and add other users if necessary.",
    "organization.grouping.add.createUser.role.required"        : "Role is required.",
    "organization.grouping.add.confirmation.text.ZONES"         : "Your zone is created!",
    "organization.grouping.add.confirmation.text.TOURNAMENTS"   : "Your tournament is created!",
    "organization.grouping.add.confirmation.text.LEAGUES"       : "Your league is created!",
    "organization.grouping.add.confirmation.manage.ZONES"       : "Manage zone",
    "organization.grouping.add.confirmation.manage.TOURNAMENTS" : "Manage tournament",
    "organization.grouping.add.confirmation.manage.LEAGUES"     : "Manage league",

    "organization.grouping.sidePanel.tabs.orgs"                    : "Organizations",
    "organization.grouping.sidePanel.tabs.competitions"            : "Competitions",
    "organization.grouping.sidePanel.noTeams"                      : "No teams",
    "organization.grouping.sidePanel.addTeam"                      : "Add a team",
    "organization.grouping.sidePanel.addTeam.subtitle.LEAGUES"     : "Click here to add an team to your league",
    "organization.grouping.sidePanel.addTeam.subtitle.TOURNAMENTS" : "Click here to add an team to your tournament",
    "organization.grouping.sidePanel.addOrg"                       : "Add an organization",
    "organization.grouping.sidePanel.addOrg.subtitle.LEAGUES"      : "Click here to add an organization to your league",
    "organization.grouping.sidePanel.addOrg.subtitle.ZONES"        : "Click here to add an organization to your zone",
    "organization.grouping.sidePanel.addOrg.subtitle.TOURNAMENTS"  : "Click here to add an organization to your tournament",
    "organization.grouping.sidePanel.addOrg.comp.subtitle"         : "Add an organization to generate competitions",
    "organization.grouping.sidePanel.edit.title"                   : "Edit competition",
    "organization.grouping.sidePanel.edit.suggested"               : "Suggested name",
    "organization.grouping.sidePanel.edit.name.required"           : "Name is required",
    "organization.grouping.sidePanel.division"                     : "Division",
    "organization.grouping.sidePanel.active"                       : "Active",
    "organization.grouping.sidePanel.delete.msg"                   : "You are about to delete",

    "organization.grouping.addOrganizationModal.title"             : "Add an organization / division",
    "organization.grouping.addOrganizationModal.error.TOURNAMENTS" : "The categories and divisions are already added to the tournament for the selected organizations",
    "organization.grouping.addOrganizationModal.error.LEAGUES"     : "The categories and divisions are already added to the league for the selected organizations",
    "organization.grouping.addOrganizationModal.error.ZONES"       : "The categories and divisions are already added to the zone for the selected organizations",

    "organization.grouping.table.divisions.column" : "Divisions",
    "organization.grouping.table.teams.column"     : "Teams",
    "organization.grouping.table.teams.count"      : "{number, plural, =0 {No teams} one {# team} other {# teams}}",
    "organization.grouping.table.divisions"        : "{number, plural, one {# division} other {# divisions}}",
}