import Translate from "@spordle/intl-elements";
import { useState, useEffect, useContext } from "react";
import { TeamsContext } from "../../../../../contexts/TeamsContext";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import DisplayMember from "../../../../../components/display/DisplayMember";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import DisplayMemberLoading from "../../../../../components/loading/DisplayMemberLoading";
import { useDisclosure } from "@mantine/hooks";
import EditLineupModal from "./EditLineupModal";
import CanDoAction from "../../../../../components/permissions/CanDoAction";
import { Button } from "reactstrap";

const TravelPermitSidepanelLineup = ({ data }) => {
    const [ lineup, setLineup ] = useState([]);
    const [ isOpen, { open, close } ] = useDisclosure(false);
    const { getTeamTravelPermitLineup } = useContext(TeamsContext);

    const getSetLineup = () => {
        setLineup(null);
        getTeamTravelPermitLineup(data.team?.team_id, data.travel_permit_id)
            .then(setLineup)
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                    setLineup([]);
                }
            });
    }

    useEffect(() => {
        getSetLineup();
    }, [ data.travel_permit_id, data.team?.team_id ]);

    return (
        <>
            <div className="h5 font-bold d-flex align-items-center">
                <Translate id="travelPermit.lineup.title" />
                <CanDoAction action="EDIT" componentCode="teams" componentPermissionCode="team_travel_permits" className="ml-auto">
                    <EditLineupModal
                        isOpen={isOpen}
                        toggle={close}
                        data={data}
                        refresh={getSetLineup}
                        initialValues={{
                            members: (lineup || []).map(({ member, position, travel_permit_lineup_id }) => ({
                                ...member,
                                travel_permit_lineup_id,
                                position_id: position?.position_id || "",
                            })),
                        }}
                    />
                    <button onClick={open} disabled={!Array.isArray(lineup)} type="button" className="reset-btn text-primary ml-auto">
                        <small>
                            <Translate id="misc.edit" />

                        </small>
                    </button>
                </CanDoAction>
            </div>
            {Array.isArray(lineup) ?
                lineup.length > 0 ?
                    lineup.map((lineupMember) => (
                        <div key={lineupMember.travel_permit_lineup_id}>
                            <DisplayMember
                                card
                                className="mb-2"
                                extra={{
                                    infoTop: (
                                        <DisplayI18n field="name" i18n={lineupMember.position?.i18n} defaultValue={lineupMember.position?.name} />
                                    ),
                                }}
                                member={lineupMember.member}
                            />
                        </div>
                    ))
                    :
                    <div className="bg-light-inverse px-3 pt-3 pb-2 border rounded text-center">
                        <i className="h1 text-body d-block mdi mdi-account-multiple mb-0" />
                        <p className="mb-0"><Translate id="travelPermit.lineup.emptyLineup" /></p>
                        <CanDoAction action="EDIT" componentCode="teams" componentPermissionCode="team_travel_permits">
                            <Button className="mdi mdi-plus with-icon" type="button" color="link" onClick={open}><Translate id="misc.add" /></Button>
                        </CanDoAction>
                    </div>
                :
                <>
                    <DisplayMemberLoading card className="mb-2" />
                    <DisplayMemberLoading card />
                </>
            }
        </>
    );
}

export default TravelPermitSidepanelLineup;