import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { DisplayI18n } from "../../../../../../../../helpers/i18nHelper";
import { getIcon } from "../../../../../../../../helpers/waiversHelper";

const WaiverInfo = ({ waiver, answer, hideIcon = false }) => {
    return (
        <div className="d-flex align-items-center">
            {!hideIcon &&
                getIcon(answer)
            }
            <div className={stringBuilder("min-w-0", { 'ml-2': !hideIcon })}>
                <div className="text-primary font-medium text-truncate">
                    <DisplayI18n
                        field='name'
                        defaultValue={waiver.waiver.name}
                        i18n={waiver.waiver.i18n}
                    />
                </div>
                <div className='text-muted small text-truncate'>
                    <span className='small'>
                        <DisplayI18n
                            field='name'
                            defaultValue={waiver.invoiceItem.organisation.organisation_name}
                            i18n={waiver.invoiceItem.organisation.i18n}
                        />
                    </span>
                </div>
                {waiver.waiver.critical == 1 &&
                    <div className='text-muted small text-truncate'>
                        <span className='small'>
                            <Translate id='misc.critical' />
                        </span>
                    </div>
                }
            </div>
        </div>
    )
}

export default WaiverInfo;