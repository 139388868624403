import Translate from "@spordle/intl-elements";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import generatePassword from "../../../../helpers/passwordGenerator";
import { counterAnim } from "../../../../helpers/uiHelper";

const DisplayWaitlistCard = ({ selectedRows, state }) => {
    const id = generatePassword();

    useEffect(() => {
        document.getElementById(id).innerText = 0; // Reset interface count to 0
        counterAnim(id, 0, selectedRows[0]?.waiting_list_item_count || 0, 400) // start at current count and go to next number
    }, [ selectedRows[0]?.waiting_list_item_count ])

    return (
        <Card tag={selectedRows[0]?.manage_waiting_list === '1' ? Link : 'div'} to={{ pathname: `/clinics/profile/${selectedRows[0]?.clinic_id}/waitlist`, data: selectedRows[0] }} className={`card-shadow text-center mb-0 ${selectedRows[0]?.manage_waiting_list == '1' ? 'card-hover' : ''}`}>
            <CardBody>
                <div className={`h2 mb-0 font-medium ${selectedRows[0]?.manage_waiting_list === '0' ? 'text-muted' : ''}`} id={id}>
                    {0}
                </div>
                <div className={`small ${selectedRows[0]?.manage_waiting_list === '0' ? 'text-muted' : ''}`}><Translate id='clinics.search.sidePanelClinicSearch.details.waitlisted' /></div>
            </CardBody>
        </Card>
    )
}

export default DisplayWaitlistCard;
