import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from "@spordle/datatables";
import Translate, { DateFormat } from "@spordle/intl-elements";
import SpordleSelect from "@spordle/spordle-select";
import moment from "moment";
import { useContext } from "react";
import { Badge, Card } from "reactstrap";
import SpordlePanelTable from "../../../components/sidePanel/SpordlePanel";
import SpordlePanelControl from "../../../components/sidePanel/SpordlepanelControl";
import TablePagination from "../../../components/table/TablePagination";
import UserDisplay from "../../../components/userDisplay/UserDisplay";
import UserImg from "../../../components/UserImg";
import { I18nContext } from "../../../contexts/I18nContext";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { displayI18n, DisplayI18n } from "../../../helpers/i18nHelper";
import UnconfirmedOrganizationSidePanel from "./sidePanel/UnconfirmedOrganizationSidePanel";

const UnconfirmedOrganizationList = (props) => {
    const organizationContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext)

    const getBadgeColour = (status) => {
        switch (status){
            case 'PENDING':
            case 'DRAFT':
            case 'INACTIVE':
                return 'warning';
            case 'DECLINED':
                return 'danger';
            case 'APPROVED':
                return 'success';
            default:
                return 'danger';
        }
    }

    return (
        <SpordlePanelTable
            allowOutsideClick
            sidePanel={(props) => <UnconfirmedOrganizationSidePanel {...props} getBadgeColour={getBadgeColour} />}
            dataIndex='organisation_id'
            table={(panelProps) => {
                return (
                    <SpordleTableProvider
                        id='unsactionedList'
                        tableHover bordered striped
                        clickable
                        ref={panelProps.spordleTableRef}
                        desktopWhen
                        dataIndex='organisation_id'
                        pagination={25}
                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                        defaultSorting='+submissionDate'
                        onColumnClick={(e, data, _spordleTable, columnKey) => {
                            switch (e.button){
                                case 0: // Left mouse button
                                    if(columnKey === 'checkbox'){
                                        panelProps.onMultiSelectChange(data);
                                    }else{
                                        panelProps.onSingleSelectChange(data);
                                    }
                                    break;
                            }
                        }}
                        initFilter={{
                            status: [ "PENDING" ],
                        }}
                        searchKeys={[
                            `i18n.${i18nContext.getGenericLocale()}.name`,
                            'organisation_name',
                            'submission_date',
                            `organisation_status.i18n.${i18nContext.getGenericLocale()}.name`,
                        ]}
                        columns={[
                            {
                                // yes keep this
                                label: '-',
                                key: 'checkbox',
                                className: 'th-shrink',
                                dataClassName: 'td-shrink',
                            },
                            {
                                label: <Translate id='task.unconfirmedOrg.list.organization' />,
                                key: 'organization',
                                sortable: true,
                            },
                            {
                                label: <Translate id='task.unconfirmedOrg.list.submission.date' />,
                                key: 'submissionDate',
                                sortable: true,
                            },
                            {
                                label: <Translate id='form.fields.status' />,
                                key: "status",
                                mobile: true,
                                sortable: true,
                            },
                        ]}
                        columnSorting={(orgA, orgB, _spordleTable, order) => {
                            switch (order.column){
                                case 'submissionDate':
                                    // the unix() function returns a date in unix format (number)
                                    const unixA = moment(orgA.submission_date).unix();
                                    const unixB = moment(orgB.submission_date).unix();
                                    return order.order === 'ASC' ? unixA - unixB : unixB - unixA;
                                case 'organization':
                                    const valueAFrom = displayI18n('name', orgA.i18n, orgA.organisation_name, i18nContext.getGenericLocale())
                                    const valueBFrom = displayI18n('name', orgB.i18n, orgB.organisation_name, i18nContext.getGenericLocale())
                                    return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueAFrom, valueBFrom) * (order.order === 'ASC' ? 1 : -1);
                                case 'status':
                                    const statusAFrom = displayI18n('name', orgA.organisation_status.i18n, orgA.organisation_status.name, i18nContext.getGenericLocale())
                                    const statusBFrom = displayI18n('name', orgB.organisation_status.i18n, orgB.organisation_status.name, i18nContext.getGenericLocale())
                                    return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(statusAFrom, statusBFrom) * (order.order === 'ASC' ? 1 : -1);
                            }
                        }}
                        loadData={(from, { filters }, spordleTable) => {
                            switch (from){
                                case 'FILTER':
                                case 'REFRESH':
                                    spordleTable.setLoading();
                                case 'CDM':
                                    return props.getData(filters) //organizationContext.getOrganizationChildren().then((orgs) => {
                                    //return (filters.status && filters.status.length > 0) ? orgs.filter((org) => filters.status.includes(org.organisation_status?.system)) : orgs
                                    // })
                                default:
                                    break;
                            }
                        }}
                        renderRow={(columnKey, org) => {
                            switch (columnKey){
                                case 'checkbox':
                                    return (
                                        <label
                                            className='my-auto'
                                            htmlFor={'checkbox-' + org.organisation_id}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <input
                                                onChange={() => panelProps.onMultiSelectChange(org)}
                                                id={'checkbox-' + org.organisation_id}
                                                type='checkbox'
                                                checked={!!org.checked}
                                            />
                                            <span className='table-checkbox' />
                                        </label>
                                    )
                                case 'organization':
                                    return (
                                        <UserDisplay>
                                            <UserDisplay.Container>
                                                <UserImg
                                                    abbr={org.abbreviation}
                                                    src={org.logo?.full_path}
                                                    filePos={org.logo?.file_position}
                                                    alt={org.organisation_name}
                                                    width="40"
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container>
                                                <UserDisplay.Title><DisplayI18n field='name' defaultValue={org.organisation_name} i18n={org.i18n} /></UserDisplay.Title>
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    );
                                case 'status':
                                    return (
                                        <Badge
                                            color={getBadgeColour(org.organisation_status?.system || 'DRAFT')}
                                        >
                                            {org.organisation_status ?
                                                <DisplayI18n
                                                    field='name'
                                                    defaultValue={org.organisation_status.name}
                                                    i18n={org.organisation_status.i18n}
                                                />
                                                :
                                                <Translate id="reports.column.status.DRAFT" />
                                            }
                                        </Badge>
                                    )
                                case 'submissionDate':
                                    return <DateFormat value={org.submission_date} format={"YYYY-MM-DD HH:mm"} />
                                default:
                                    break;
                            }
                        }}
                    >
                        {(spordleTable) => (
                            <Card body className="card-shadow">
                                <div className="mb-2">
                                    <div className='d-flex flex-wrap justify-content-between mb-2'>
                                        <SpordlePanelControl />
                                        <SearchInput />
                                        <div className="mx-2" style={{ minWidth: "200px" }}>
                                            <SpordleSelect
                                                multi clearable
                                                name='organizationStatusFilter'
                                                id='organizationStatusFilter'
                                                placeholder='misc.status'
                                                textWhenSetting={2}
                                                onOptionSelected={(value) => {
                                                    spordleTable.filterChange("status", value)
                                                }}
                                                defaultValues={[ 'PENDING' ]}
                                                renderOption={({ option }) => {
                                                    if(option.value === 'ALL'){
                                                        return <Translate id='misc.all' />;
                                                    }
                                                    return (
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={option.label}
                                                            i18n={option.i18n}
                                                        />
                                                    )

                                                }}
                                                loadData={(from) => {
                                                    switch (from){
                                                        case 'CDM':
                                                            return organizationContext.getOrganizationsStatuses()
                                                                .then((status) => status.filter((organisation_status) => (organisation_status.system !== 'INACTIVE'))
                                                                    .map((st) => ({
                                                                        id: st.system,
                                                                        value: st.system,
                                                                        label: st.name,
                                                                        i18n: st.i18n,
                                                                    })))
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className='d-flex ml-auto'>
                                            <TablePagination className='ml-2' paginationArray={[ 25, 50, 100 ]} />
                                            <Refresh className='ml-2' />
                                        </div>
                                    </div>
                                </div>
                                <SpordleTableView />
                            </Card>
                        )}
                    </SpordleTableProvider>
                )
            }}
        />
    );
};


export default UnconfirmedOrganizationList;
