import { useContext } from 'react';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { isLeague, isTournament } from '../../../helpers/orgHelper';
import CompetitionTeamSearch from '../competitionTeamsSearch/CompetitionTeamsSearch';
import OrgTeamsSearch from './OrgTeamsSearch';

const TeamSearch = () => {
    const { organisation_category } = useContext(OrganizationContext);
    const categoryCode = organisation_category?.category_system;
    const isCompetitive = isLeague(categoryCode) || isTournament(categoryCode);

    return (
        <>
            <ViewHeaderV2 title='sidebar.teams' />
            <div className="page-content container-fluid px-4 pt-0 mt-0">
                {isCompetitive ?
                    <CompetitionTeamSearch />
                    :
                    <OrgTeamsSearch />
                }
            </div>
        </>
    );
}

export default TeamSearch;