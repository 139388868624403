import OrganizationAccounting from '../../views/organization/profile/accounting/OrganizationAccounting';
import OrganizationPostalCodes from '../../views/organization/profile/cities/OrganizationPostalCodes';
import OrganizationProfileOverview from '../../views/organization/profile/overview/OrganizationProfileOverview';
import OrganizationProfileStaff from '../../views/organization/profile/staff/OrganizationProfileStaff';
import AddMultipleUsersTab from '../../views/organization/profile/users/addMultipleUsers/AddMultipleUsersTab';
import OrganizationProfileUsers from '../../views/organization/profile/users/OrganizationProfileUsers';

/**
 * @typedef {object} TabRoutes
 * @property {React.ReactNode} [name]
 * @property {boolean} [redirect]
 */

/** @type {Array.<import('react-router-dom').RouteProps & TabRoutes>} */
const organizationTabsRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        path: '/overview',
        name: 'organisation.profile.tabs.overview',
        component: OrganizationProfileOverview,
    },
    {
        path: '/users',
        name: 'organisation.profile.tabs.users',
        component: OrganizationProfileUsers,
        permissions: { code: 'organization', permissionCode: 'organization_users' },
    },
    {
        path: '/add-multiple',
        name: 'misc.addMultiple',
        component: AddMultipleUsersTab,
        permissions: { code: 'organization', permissionCode: 'organization_users' },
        tempTab: true,
    },
    {
        path: '/postal-codes',
        name: 'organisation.profile.tabs.postalCodes',
        permissions: { code: 'organization', permissionCode: 'organization_profile' },
        component: OrganizationPostalCodes,
    },
    {
        path: '/ledger',
        name: 'organisation.profile.tabs.accounting',
        permissions: { code: 'organization', permissionCode: 'organization_profile' },
        component: OrganizationAccounting,
    },
    {
        path: '/staff',
        name: 'organisation.profile.tabs.staff',
        component: OrganizationProfileStaff,
        permissions: { code: 'organization', permissionCode: 'organization_staff' },
    },
    {
        redirect: true,
        from: '/organization',
        to: '/overview',
    },
];
export default organizationTabsRoutes;