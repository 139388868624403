import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { PeriodsContext } from './contexts';
import withContexts from '../helpers/withContexts';

/** @type {React.Context<Omit<RegistrationCategoriesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const RegistrationCategoriesContext = createContext();
RegistrationCategoriesContext.displayName = 'RegistrationCategoriesContext';

class RegistrationCategoriesContextProvider extends React.Component{

    /**
     * Get all the Categories within an organization
     * @param {string} organizationId The organization id we want to get the Categories from
     * @returns {Promise<Array>}
     */
    getRegistrationCategories = (organizationId, params) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `team-categories`,
            query: {
                organisation_id: organizationId,
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                ...params,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.team_categories;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get all the Category types within an organization
     * @param {string} organizationId The organization id we want to get the Categories from
     * @returns {Promise<Array>}
     */
    getRegistrationCategoryTypes = (organizationId, params) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `team-category-types`,
            query: {
                organisation_id: organizationId,
                ...params,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.team_category_types;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get Category information
     * @param {string} categoryId
     * @returns {Promise}
     */
    getRegistrationCategory = (categoryId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `team-categories/${categoryId}`,
            query: {
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.team_categories[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a specified Category with new values
     * @param {string} categoryId ID of the Category to update
     * @param {object} values Object containing the values to update - The keys to the values need to be the same as the API ones
     * @returns {Promise}
     */
    updateRegistrationCategory = (categoryId, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `team-categories/${categoryId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update the active/inactive status of a category within a period
     * @param {string} categoryId ID of the Category to update
     * @param {object} values Object containing the values to update - The keys to the values need to be the same as the API ones
     * @returns {Promise}
     */
    updateRegistrationCategoryPeriodStatus = (categoryId, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `team-categories/${categoryId}/period/${this.props.PeriodsContext.selectedPeriod.period_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a category under an Organization with specified values
     * @param {string} organizationId ID of the Organization to add a Category to
     * @param {object} values Values to create a Category with
     * @returns {Promise}
     */
    createRegistrationCategory = (organizationId, values) => {
        const params = new URLSearchParams({
            'organisation_id': organizationId,
            'period_id': this.props.PeriodsContext.selectedPeriod.period_id,
            active: values.active ? 1 : 0,
            ...values,
        });

        return API_SPORDLE.post('team-categories', params)
            .then((response) => {
                if(response.data.status){
                    return response.data.team_category_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a specific Category
     * @param {string} categoryId ID of the Category to delete
     * @returns {Promise}
     */
    deleteRegistrationCategory = (categoryId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `team-categories/${categoryId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <RegistrationCategoriesContext.Provider value={{
                getRegistrationCategories: this.getRegistrationCategories,
                getRegistrationCategory: this.getRegistrationCategory,
                getRegistrationCategoryTypes: this.getRegistrationCategoryTypes,
                updateRegistrationCategory: this.updateRegistrationCategory,
                updateRegistrationCategoryPeriodStatus: this.updateRegistrationCategoryPeriodStatus,
                createRegistrationCategory: this.createRegistrationCategory,
                deleteRegistrationCategory: this.deleteRegistrationCategory,
            }}
            >
                {this.props.children}
            </RegistrationCategoriesContext.Provider>
        )
    }
}

export default withContexts(PeriodsContext)(RegistrationCategoriesContextProvider);