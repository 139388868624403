import { useContext } from "react";
import { Card, CardBody } from "reactstrap";
import { DashboardContext } from "../../../../../contexts/DashboardContext";
import { NotificationsContext } from "../../../../../contexts/NotificationsContext";
import { isMobileDevice } from "../../../../../helpers/constants";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NotificationsDataManager } from "../../../../../layouts/layout-components/header/notifications/components/NotificationsDataManager";
import OrganizationNotificationList from "../../../../../layouts/layout-components/header/notifications/components/OrganizationNotificationList";
import NotifBadge from "../../../../../layouts/layout-components/header/notifBadge/NotifBadge";


const NotificationsWidget = () => {
    const notificationsContext = useContext(NotificationsContext);
    const helper = useContext(NotificationsDataManager);
    const dashboardContext = useContext(DashboardContext);

    return (
        <Card className="card-shadow h-100 mb-0 position-relative overflow-hidden">
            <CardBody className="d-flex flex-column h-100">
                {!isMobileDevice &&
                    <>
                        <div className='dashboard-grid-toolbar'>
                            <div className='dashboard-grid-toolbar-inner h-100'>
                                <i className='mdi mdi-drag dashboard-grid-drag-handle dashboard-grid-toolbar-inner-icon dashboard-grid-tool font-22' />

                                {dashboardContext.state.configureWidgetFeature &&
                                    // logic not done smile
                                    <i className='mdi mdi-settings text-muted dashboard-grid-toolbar-inner-icon dashboard-grid-tool' />
                                }
                                <div className='dashboard-grid-toolbar-icon-container'>
                                    <i className='mdi mdi-dots-vertical font-22' />
                                </div>
                            </div>
                        </div>
                        <div className='dashboard-grid-toolbar-overlay' />
                    </>
                }
                <div className="d-flex align-items-center border-bottom pb-1 mb-2">
                    <h4 className="font-bold mb-0">
                        <span className="position-relative">
                            Notifications
                            { (notificationsContext.state.notificationsCount > 0) &&
                                <NotifBadge color="primary">
                                    {notificationsContext.state.notificationsCount > 9 ? '9+' : notificationsContext.state.notificationsCount}
                                </NotifBadge>
                            }
                        </span>
                    </h4>
                </div>
                <PerfectScrollbar className="min-h-0 flex-grow-1 h-100">
                    <OrganizationNotificationList
                        emptyLayoutClassName="h-100 w-100 d-flex flex-column justify-content-center"
                        className="w-100"
                        {...helper}
                    />
                </PerfectScrollbar>
            </CardBody>
        </Card>
    );
}

export default NotificationsWidget;