import Translate from "@spordle/intl-elements";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";

import {
    DataSheetGrid,
    textColumn,
    keyColumn
} from 'react-datasheet-grid'
import { useEffect, useState } from "react";
import CustomAlert from "../../../../../components/CustomAlert";

const AddMultipleGridModal = (props) => (
    <AnalyticsModal size="lg" isOpen={props.isOpen} toggle={props.toggle} unmountOnClose backdrop="static" analyticsName='AddMultipleGridModal'>
        <AddMultipleGridInner {...props} />
    </AnalyticsModal>
)

const AddMultipleGridInner = ({ users, toggle, maxUsers, formik }) => {
    const defaultRow = { organizationId: users?.[0]?.organizationId || "", roleId: users?.[0]?.roleId || "", email: "", firstName: '', lastName: '' };
    const columns = [
        {
            ...keyColumn('email', textColumn),
            title: <Translate id='form.fields.email' />,
        },
        {
            ...keyColumn('firstName', textColumn),
            title: <Translate id='form.fields.firstName' />,
        },
        {
            ...keyColumn('lastName', textColumn),
            title: <Translate id='form.fields.lastName' />,
        },
    ]

    const [ lockRows, setLockRows ] = useState(false)
    const [ data, setData ] = useState([ defaultRow ])

    const submitGrid = () => {
        formik.setValues({ users: data.map((d, key) => {
            const newData = {
                ...defaultRow,
                ...formik.values?.users?.[key] || {},
                ...d,
            }
            delete newData.id
            return newData;
        }) })
        toggle();
    }

    useEffect(() => {
        let newData = data;
        if(users && users.length > 0){
            newData = users.map((user) => ({
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
            }));
        }
        setData(newData)
    }, [])

    return (
        <>
            <ModalHeader tag="div" toggle={toggle}><Translate id='organization.profile.users.multiple.addUser.grid' /></ModalHeader>
            <ModalBody>
                <CustomAlert color="info" icon="mdi mdi-information-outline" text='organization.profile.users.multiple.addUser.grid.message' translateText />

                <div>
                    <DataSheetGrid
                        disableContextMenu
                        lockRows={lockRows}
                        value={data}
                        columns={columns}
                        height={600}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        onChange={(newValue, operations) => {
                            for(const operation of operations){
                                if(operation.type === 'CREATE'){
                                    if((data.length + 1) >= maxUsers){
                                        setLockRows(true)
                                    }
                                }
                            }
                            setData(newValue.slice(0, maxUsers))
                        }}
                    />
                </div>

            </ModalBody>
            <ModalFooter>
                <Button color="primary" type="button" onClick={close} outline><Translate id="misc.close" /></Button>
                <Button color="primary" type="button" onClick={submitGrid} className="ml-auto"><Translate id='misc.submit' /></Button>
            </ModalFooter>
        </>
    )
}

export default AddMultipleGridModal;