import { FormikDateTime, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { FieldArray, Form, Formik } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import {
    Button, Card,
    CardBody,
    Col,
    Collapse,
    Row
} from "reactstrap";
import useSWR from 'swr';
import * as Yup from 'yup';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import OrganizationSearch from '../../../../components/organization/OrganizationSearch';
import { fail, success } from '@spordle/toasts';
import { ClinicsContext } from '../../../../contexts/ClinicsContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import generatePassword from '../../../../helpers/passwordGenerator';

const AdvancedSettings = ({ toggleVisibilitySettings }) => {
    const orgContext = useContext(OrganizationContext);
    const clinicContext = useContext(ClinicsContext);
    const formikRef = useRef();
    const urlParams = useParams();

    const { data: clinicStatuses } = useSWR(
        [ 'getClinicStates', orgContext.organisation_id ],
        () => clinicContext.getClinicStates().catch((error) => {
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message)
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
            }
        }),
    );

    useEffect(() => {
        formikRef?.current?.setValues({
            sceduledStatuses: clinicContext.currentClinic.status?.filter((status) => status.status !== 'UNDER_CREATION').map((status) => ({
                status: status.status,
                clinic_status_id: status.clinic_status_id,
                date: moment(status.start_date),
                time: moment(status.start_date),
            })) || [],
            organisations: clinicContext.currentClinic.shared_with?.map((organisation) => organisation.organisation_id) || [],
        })
    }, [ clinicContext.currentClinic.status.length ])

    return (
        <Card className="card-shadow shadow-primary mb-4 border-0">
            <CardBody>
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        sceduledStatuses: clinicContext.currentClinic.status?.filter((status) => status.status !== 'UNDER_CREATION').map((status) => ({
                            status: status.status,
                            clinic_status_id: status.clinic_status_id,
                            date: moment(status.start_date),
                            time: moment(status.start_date),
                        })) || [],
                        organisations: clinicContext.currentClinic.shared_with?.map((organisation) => organisation.organisation_id) || [],
                    }}
                    validationSchema={Yup.object().shape({
                        sceduledStatuses: Yup.array().of(Yup.object().shape({
                            status: Yup.string().required(<Translate id='clinics.profile.clinicProfile.advancedSettings.visibility.status.required' />),
                            date: Yup.mixed()
                                .required(<Translate id='clinics.profile.clinicProfile.advancedSettings.visibility.date.required' />)
                                .test({
                                    name: 'date-validation',
                                    message: <Translate id='form.validation.date.format' />,
                                    test: moment.isMoment,
                                }),
                            time: Yup.mixed()
                                .required(<Translate id='clinics.profile.clinicProfile.advancedSettings.visibility.time.required' />)
                                .test({
                                    name: 'time-validation',
                                    message: <Translate id='form.validation.time.format' />,
                                    test: moment.isMoment,
                                }),
                        })),
                        organisations: Yup.array().test({
                            name: 'org-is-not-current',
                            message: <Translate id='clinics.profile.clinicProfile.advancedSettings.sharedOrganizations.not.current' />,
                            test: (orgIds) => orgIds.every((orgId) => orgId !== orgContext.organisation_id),
                        }),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        const builtNewStatuses = values.sceduledStatuses.map((status) => {
                            /** @type {moment.Moment} */
                            const mergedMoment = status.date;
                            mergedMoment.hours(status.time.hours());
                            mergedMoment.minutes(status.time.minutes());
                            return {
                                status: status.status,
                                start_date: mergedMoment.toISOString(),
                                clinic_status_id: status.clinic_status_id.startsWith('new-status-') ? undefined : status.clinic_status_id,
                            }
                        })
                        Promise.all([
                            clinicContext.updateStatuses(urlParams.clinicId, builtNewStatuses),
                            clinicContext.shareClinicWith(urlParams.clinicId, values.organisations),
                        ])
                            .then(() => {
                                setSubmitting(false);
                                toggleVisibilitySettings();
                                success({ msg: 'misc.updated' });
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                    setSubmitting(false);
                                }
                            })
                    }}
                >
                    {(formik) => (
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <div className='border-bottom pb-1 mb-4'>
                                <div className="font-bold h4 card-title mb-0"><Translate id='clinics.profile.clinicProfile.advancedSettings.title' /></div>
                            </div>
                            <div className='mb-3'>
                                <div className="font-bold h5 mb-0"><Translate id='clinics.profile.clinicProfile.advancedSettings.visibility' /></div>
                            </div>
                            <Form>
                                {formik.values.sceduledStatuses.map((status, index) => (
                                    <Collapse key={status.clinic_status_id} appear isOpen>
                                        <Row className="align-items-center mb-4 mb-sm-3" form>
                                            <Col sm="5" className="mb-2">
                                                <div className="d-flex mb-1">
                                                    <div className="text-muted">
                                                        <Translate id='clinics.profile.clinicProfile.advancedSettings.changeVisibility' />
                                                    </div>
                                                </div>
                                                <FormikSelect
                                                    disabled={index == 0}
                                                    id={`sceduledStatuses.${index}.status`} name={`sceduledStatuses.${index}.status`}
                                                    clearable search={false} loadingStatus='success'
                                                    options={clinicStatuses?.map((status) => ({
                                                        value: status.state_code,
                                                        label: status.name,
                                                        i18n: status.i18n,
                                                    }))}
                                                    renderOption={(option) => {
                                                        return <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                                                    }}
                                                />
                                            </Col>
                                            <Col xs="6" sm="3" className="mb-2">
                                                <div className="text-muted mb-1">
                                                    <Translate id='clinics.profile.clinicProfile.advancedSettings.changeVisibility.on' />
                                                </div>
                                                {/* datepicker */}
                                                <FormikDateTime
                                                    name={`sceduledStatuses.${index}.date`}
                                                    id='date'
                                                    timeFormat={false}
                                                    inputProps={{ disabled: index == 0 }}
                                                />
                                            </Col>
                                            <Col xs="6" sm="3" className="mb-2">
                                                <div className="text-muted mb-1">
                                                    <Translate id='clinics.profile.clinicProfile.advancedSettings.changeVisibility.at' />
                                                </div>
                                                {/* timepicker */}
                                                <FormikDateTime
                                                    name={`sceduledStatuses.${index}.time`}
                                                    id='time'
                                                    dateFormat={false}
                                                    inputProps={{ disabled: index == 0 }}
                                                />
                                            </Col>
                                            {index != 0 &&
                                                <FieldArray name='sceduledStatuses'>
                                                    {(fieldArray) => (
                                                        <Col sm="auto" className="d-none d-sm-block mb-2">
                                                            <div className="mb-1">&nbsp;</div>{/* simulate label spacing to align trash icon vertically */}
                                                            <Button color="link" type='button' onClick={fieldArray.handleRemove(index)}><i className="ti-trash text-danger" /></Button>{/* dont forget mobile's remove btn */}
                                                        </Col>
                                                    )}
                                                </FieldArray>
                                            }
                                        </Row>
                                    </Collapse>
                                ))}

                                <Row className="form-row align-items-center mb-5">
                                    <Col xs="12" sm="12">
                                        <FieldArray name='sceduledStatuses'>
                                            {(fieldArray) => (
                                                <button
                                                    className="reset-btn text-primary" type='button' onClick={fieldArray.handlePush({
                                                        status: '',
                                                        date: '',
                                                        time: '',
                                                        clinic_status_id: `new-status-${generatePassword()}`,
                                                    })}
                                                >
                                                    <i className="mdi mdi-plus mr-1" /><Translate id='clinics.profile.clinicProfile.advancedSettings.changeVisibility.add' />
                                                </button>
                                            )}
                                        </FieldArray>
                                    </Col>
                                </Row>

                                <div className="font-bold h5 mb-1"><Translate id='clinics.profile.clinicProfile.advancedSettings.sharedOrganizations' /></div>
                                <div className="small text-primary mb-3"><i className="mdi mdi-information-outline mr-1" /><Translate id='clinics.profile.clinicProfile.advancedSettings.sharedOrganizations.message' /></div>
                                <div className="mb-3">
                                    <Row>
                                        <Col md='8'>
                                            <OrganizationSearch withFormik withTree fromIdentityRole fromApi fromPublicApi onlyActive name="organisations" id="organisations" multi selectedDefault={formik.initialValues.organisations} />
                                        </Col>
                                    </Row>
                                </div>

                                <div className="d-flex align-items-center justify-content-end">
                                    <Button color="primary" type='submit' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                                    <Button
                                        color="primary" type='reset'
                                        outline className='ml-1'
                                        disabled={formik.isSubmitting}
                                        onClick={toggleVisibilitySettings}
                                    >
                                        <Translate id='misc.cancel' />
                                    </Button>
                                </div>
                            </Form>
                        </OverlayLoader>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
}

export default AdvancedSettings;