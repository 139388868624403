import { FormikError, FormikInputNumber, FormikInputText, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import {
    Alert, Button, Col, Collapse, FormGroup,
    Label, ModalBody,
    ModalFooter, Row
} from "reactstrap";
import { array, number, object, string } from 'yup';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import { I18nContext } from '../../contexts/I18nContext';
import Required from '../formik/Required';
import OrganizationSearch from '../organization/OrganizationSearch';
// contexts
import { AppealsAddContext } from './AppealsAddContext';
import FileUpload from '../uploader/fileUpload/FileUpload';
import { DisplayI18n } from '../../helpers/i18nHelper';
import { fail } from '@spordle/toasts';
import { AppealsContext } from '../../contexts/AppealsContext';


const AppealsAdd3 = ({ ...props }) => {
    // contexts
    const appealsAddContext = useContext(AppealsAddContext);
    const { getGenericLocale } = useContext(I18nContext);
    const appealsContext = useContext(AppealsContext);

    const getPaymentMethods = async() => {
        if(appealsAddContext.state.paymentMethods){
            return Promise.resolve(formatPaymentMethod(appealsAddContext.state.paymentMethods))
        }
        return appealsContext.getAppealPaymentMethods()
            .then((paymentMethods) => {
                appealsAddContext.setPaymentMethods(paymentMethods)
                return formatPaymentMethod(paymentMethods)
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })

    }

    function formatPaymentMethod(paymentMethods){
        return paymentMethods.reduce((newArray, paymentMethod) => {
            if(paymentMethod.active == 1){
                newArray.push({
                    value: paymentMethod.code,
                    label: paymentMethod.name,
                    i18n: paymentMethod.i18n,
                })
            }
            return newArray
        }, [])
    }

    return (
        <Formik
            initialValues={{
                owes: '',
                paid: '',
                clientOrgId: '',
                invoiceNumber: '',
                attachments: [],
                accountReceivableNote: '',
                paymentMethodCode: '',
            }}
            validationSchema={object().shape({
                owes: number().required(<Translate id='members.profile.appeals.owes.required' />),
                paid: number().required(<Translate id='members.profile.appeals.paid.required' />),
                clientOrgId: string().required(<Translate id='members.profile.appeals.client.required' />),
                invoiceNumber: string(),
                accountReceivableNote: string(),
                attachments: array(),
                paymentMethodCode: string(),
            })}
            onSubmit={(values, { setStatus }) => {
                appealsAddContext.setLoading(true);
                appealsAddContext.createMemberAppeal({
                    ...appealsAddContext.state.formData,

                    payment_method_code: values.paymentMethodCode,
                    appeal_amount_owed: Math.round(values.owes * 100),
                    appeal_amount_paid: Math.round(values.paid * 100),
                    appeal_amount_org_id: values.clientOrgId,
                    hc_invoice_number: values.invoiceNumber,
                    account_receivable_note: values.accountReceivableNote,
                    accountReceivableFiles: values.attachments.map((file) => ({ file: file, type: 'ACCOUNT_RECEIVABLE' })),
                })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e.message);
                        }
                    })
                    .finally(() => appealsAddContext.setLoading(false))
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <div className="mt-2">
                            <div className="h6 font-bold"><Translate id='members.profile.appeals.sidepanel.tabs.account' /></div>
                            <Row form>
                                <Col sm='12'>
                                    <FormGroup>
                                        <Label for='paymentMethodCode' className='text-muted'><Translate id='members.profile.appeals.paymentMethod' /></Label>
                                        <FormikSelect
                                            name='paymentMethodCode'
                                            id='paymentMethodCode'
                                            renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                            searchKeys={[
                                                `i18n.${getGenericLocale()}.name`,
                                            ]}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return getPaymentMethods();
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for='owes' className='text-muted'><Translate id='members.profile.appeals.owes' /> <Required /></Label>
                                        <I18nContext.Consumer>
                                            {({ getGenericLocale }) => (
                                                <FormikInputNumber
                                                    allowLeadingZeros
                                                    fixedDecimalScale
                                                    thousandSeparator=' '
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    name="owes"
                                                    id="owes"
                                                    decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                    suffix={getGenericLocale() === 'fr' ? ' $' : ''}
                                                    prefix={getGenericLocale() !== 'fr' ? '$ ' : ''}
                                                />
                                            )}
                                        </I18nContext.Consumer>
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for='paid' className='text-muted'><Translate id='members.profile.appeals.paid' /> <Required /></Label>
                                        <I18nContext.Consumer>
                                            {({ getGenericLocale }) => (
                                                <FormikInputNumber
                                                    allowLeadingZeros
                                                    fixedDecimalScale
                                                    thousandSeparator=' '
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    name="paid"
                                                    id="paid"
                                                    decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                    suffix={getGenericLocale() === 'fr' ? ' $' : ''}
                                                    prefix={getGenericLocale() !== 'fr' ? '$ ' : ''}
                                                />
                                            )}
                                        </I18nContext.Consumer>
                                    </FormGroup>
                                </Col>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for='clientOrgId' className='text-muted'><Translate id='members.profile.appeals.client' /> <Required /></Label>
                                        <OrganizationSearch
                                            id="clientOrgId" name="clientOrgId" withFormik
                                            fromIdentityRole selectedDefault={appealsAddContext.state.formData.appeal_amount_org_id || ''}
                                            categories={appealsAddContext.state.organizationCategoriesIds}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for='invoiceNumber' className='text-muted'><Translate id='members.profile.appeals.invoice' /></Label>
                                        <FormikInputText id='invoiceNumber' name='invoiceNumber' />
                                    </FormGroup>
                                </Col>
                                <Col sm='12'>
                                    <FormGroup>
                                        <Label for='attachments' className='text-muted'><Translate id='members.profile.appeals.attachments.account' /> </Label>
                                        <FileUpload
                                            onFileSelect={(files) => {
                                                formik.setFieldValue('attachments', files);
                                            }}
                                            dropzoneProps={{
                                                multiple: true,
                                                accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                                                maxSize: 20971520,
                                            }}
                                        />
                                        <FormikError name='attachments' />
                                    </FormGroup>
                                </Col>
                                <Col sm='12'>
                                    <FormGroup>
                                        <Label for='accountReceivableNote' className='text-muted'><Translate id='members.profile.appeals.accountReceivableNote' /></Label>
                                        <FormikTextArea id='accountReceivableNote' name='accountReceivableNote' rows={3} trim />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <Collapse isOpen={formik.status?.includes('error')}>
                            <Alert color='danger' toggle={() => formik.setStatus('')}>
                                {formik.status && // to prevent error message in console when status is undefined
                                        <Translate id={'contexts.membersContext.' + formik.status?.split('-')?.[1]} defaultMessageId='misc.error' />
                                }
                            </Alert>
                        </Collapse>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' onClick={() => appealsAddContext.goToView(appealsAddContext.views.invoiceInfo)} outline className="mr-auto"><Translate id='misc.previous' /></Button>
                        <Button color='primary' type='submit'><Translate id='misc.add' /></Button>
                        <Button color='primary' onClick={appealsAddContext.toggleModal} outline><Translate id='misc.cancel' /></Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
}

export default AppealsAdd3;