import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { ModalBody, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import CrossFade from "../../../../../../../components/crossFade/CrossFade";
import OverlayLoader from "../../../../../../../components/loading/OverlayLoader";
import { OrganizationContext } from "../../../../../../../contexts/OrganizationContext";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import GuiltyTeamSearch from "../../../modal/stepGuilty/substeps/guiltyStepSearch/GuiltyTeamSearch";
import ComplaineeEditMember from "./components/ComplaineeEditMember";

/**
 * @description Modal that contains a form to edit a member to a descrimination claim
 * @param {Object} props
 * @param {object} props.complaint The discrimination complaint to which the member is linked
 * @param {object} props.member The discrimination member to edit
 * @param {function} props.refreshTable Function called after member was edited
 * @param {function} props.toggle Function to close the modal
 * @param {boolean} props.isOpen Modal is open or not
 * @returns {React.ReactNode}
 */
const EditComplaineeModal = (props) => {
    return (
        <AnalyticsModal analyticsName="discriminationEditComplaineeModal" isOpen={props.isOpen}>
            <EditComplaineeModalInner {...props} />
        </AnalyticsModal>
    )
}

const EditComplaineeModalInner = ({ complaint, toggle, member, refreshTable, isTeam }) => {
    const { partiallyUpdateDiscriminationMember } = useContext(OrganizationContext);
    const [ isLoading, setIsLoading ] = useState(false);
    const TAB_MEMBER = 1;
    const TAB_TEAM = 2;
    const [ activeTab, setActiveTab ] = useState(TAB_MEMBER);

    const updateMember = (values, { setStatus }) => {
        setIsLoading(true);
        const apiVal = { ...values };
        delete apiVal.member;

        if(values.member?.member_id !== member.member?.member_id) apiVal.member_id = values.member?.member_id || "";

        return partiallyUpdateDiscriminationMember(complaint.discrimination_id, member.discrimination_member_id, apiVal)
            .then(() => {
                refreshTable();
                toggle();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                    setIsLoading(false);
                    console.error(error.message)
                }
            });
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalHeader toggle={toggle}>
                <Translate id='discrimination.sidePanel.people.editModal.title' />
            </ModalHeader>
            {isTeam &&
                <ModalBody className="pb-0">
                    <p>
                        <Translate id='discrimination.addModal.stepGuilty.subtitle.teamOrMEmber' />
                    </p>
                    <div className="d-flex align-items-center">
                        <div
                            onClick={() => setActiveTab(TAB_MEMBER)}
                            className={stringBuilder("mr-2 flex-grow-1 btn btn-lg select-request", activeTab === TAB_MEMBER ? "border-primary bg-primary-light text-primary" : "border")}
                            color="light"
                        >
                            <Translate id='discrimination.label.member' />
                        </div>
                        <div
                            onClick={() => setActiveTab(TAB_TEAM)}
                            className={stringBuilder("ml-2 flex-grow-1 btn btn-lg select-request", activeTab === TAB_TEAM ? "border-primary bg-primary-light text-primary" : "border")}
                            color="light"
                        >
                            <Translate id='discrimination.label.team' />
                        </div>
                    </div>
                </ModalBody>
            }
            <CrossFade isVisible={activeTab === TAB_MEMBER}>
                <ComplaineeEditMember
                    updateMember={updateMember}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    member={member}
                    toggle={toggle}
                    isTeam={isTeam}
                />
            </CrossFade>
            <CrossFade isVisible={activeTab === TAB_TEAM}>
                <GuiltyTeamSearch onSubmit={updateMember} />
            </CrossFade>
        </OverlayLoader>
    )
}

export default EditComplaineeModal;