export default{
    // general
    "members.profile.appeals.title" : "Appels",

    "members.profile.appeals.date"     : "Date",
    "members.profile.appeals.from"     : "De",
    "members.profile.appeals.to"       : "À",
    "members.profile.appeals.type"     : "Type",
    "members.profile.appeals.typeNote" : "Note de type",
    "members.profile.appeals.status"   : "Statut",
    "members.profile.appeals.approved" : "Approuvé",

    "members.profile.appeals.period"                     : "De la saison",
    "members.profile.appeals.division"                   : "Division",
    "members.profile.appeals.class"                      : "Classe",
    "members.profile.appeals.category"                   : "Catégorie",
    "members.profile.appeals.category.required"          : "La catégorie est requise",
    "members.profile.appeals.submittedDate"              : "Date de soumission",
    "members.profile.appeals.submittedDate.required"     : "La date de soumission est requise",
    "members.profile.appeals.hearingDate"                : "Date d'appel",
    "members.profile.appeals.hearingDate.afterSubmitted" : "La date d'appel doit être après la date de soumission",
    "members.profile.appeals.hearingDate.beforeSent"     : "La date d'appel doit être avant ou égal à la date d'envoi de la lettre",
    "members.profile.appeals.sentDate"                   : "Date d'envoi de la lettre",
    "members.profile.appeals.sentDate.afterHearing"      : "La date d'envoi doit être après ou égal à la date d'appel",
    "members.profile.appeals.regulation"                 : "Règlement",
    "members.profile.appeals.regulation.required"        : "La règlement est requise",

    "members.profile.appeals.note"                : "Notes",
    "members.profile.appeals.attachments"         : "Pièces jointes",
    "members.profile.appeals.attachments.account" : "Pièces jointes de compte recevable",

    "members.profile.appeals.paymentMethod"          : "Méthode de paiement",
    "members.profile.appeals.paymentMethod.required" : "La méthode de paiement est requise",
    "members.profile.appeals.owes"                   : "Doit",
    "members.profile.appeals.owes.required"          : "La somme due est requise",
    "members.profile.appeals.paid"                   : "Payé",
    "members.profile.appeals.paid.required"          : "La somme payée est requise",
    "members.profile.appeals.client"                 : "Organisation cliente",
    "members.profile.appeals.client.required"        : "L'organisation cliente est requise",
    "members.profile.appeals.invoice"                : "Numéro de facture",
    "members.profile.appeals.invoice.required"       : "Le numéro de facture est requis",
    "members.profile.appeals.accountReceivableNote"  : "Note de compte recevable",

    // table


    // sidepanel
    "members.profile.appeals.sidepanel.tabs.general"       : "Général",
    "members.profile.appeals.sidepanel.tabs.account.short" : "Compte recevable",
    "members.profile.appeals.sidepanel.tabs.account"       : "Compte recevable",

    "members.profile.appeals.sidepanel.actions.delete.text" : "Êtes-vous sûr de vouloir supprimer cet appel?",

    // add
    "members.profile.appeals.sidepanel.add.title"                       : "Création d'un appel",
    "members.profile.appeals.sidepanel.add.appealSelection.header"      : "De qui provient l'appel",
    "members.profile.appeals.sidepanel.add.appealSelection.header.text" : "Veuillez spécifier si l'appel provient de l'équipe du membre",
    "members.profile.appeals.sidepanel.add.appealSelection.memberTeam"  : "Appel provenant de l'équipe du membre",
    "members.profile.appeals.sidepanel.add.appealSelection.otherTeam"   : "Appel provenant d'une autre équipe",
    "members.profile.appeals.sidepanel.add.details"                     : "Détails",
    "members.profile.appeals.sidepanel.add.organization"                : "Organisation",
    "members.profile.appeals.sidepanel.add.organization.required"       : "L'organisation est requise",
    "members.profile.appeals.sidepanel.add.organization.same"           : "Les organisations doivent être différentes",
    "members.profile.appeals.sidepanel.add.teamName"                    : "Nom d'équipe",
    "members.profile.appeals.sidepanel.add.teamName.manual"             : "Entrer le nom de l'équipe",
    "members.profile.appeals.sidepanel.add.teamName.select"             : "Sélectionner une équipe",
    "members.profile.appeals.sidepanel.add.teamName.select.required"    : "L'équipe est requise",
    "members.profile.appeals.sidepanel.add.teamName.required"           : "Le nom d'équipe est requis",
    "members.profile.appeals.sidepanel.add.period.required"             : "La saison est requise",
    "members.profile.appeals.sidepanel.add.status.required"             : "Le statut est requis",
    "members.profile.appeals.sidepanel.add.approve.required"            : "L'approbation est requise",
}