import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import moment from 'moment';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Col, Collapse, Row } from 'reactstrap';

import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';

const SettingsCriminalRecordCheckView = ({ settings, backgroundChecks, expirationMethods }) => {
    const intl = useIntl();
    const organisationContext = useContext(OrganizationContext);
    const enabled = organisationContext.settings.enable_background_check?.value == 1

    const getMonthDayString = (dateString) => {
        const parsedDate = new Date(moment(dateString, 'MM-DD').toISOString());
        return parsedDate.toLocaleDateString(intl.locale, { day: 'numeric', month: 'long' });
    }

    return (
        <>
            <div className="d-flex align-items-center mb-4">
                <div className="mb-0 font-medium text-dark">
                    {enabled ?
                        <>
                            <i className='mdi mdi-check text-primary mr-1' />
                            <Translate id='settings.crc.enabled' />
                            <div className='text-muted small'>
                                <Translate id='settings.crc.enabled.by' /> <DisplayI18n field='name' defaultValue={organisationContext.settings.enable_background_check.organisation.organisation_name} i18n={organisationContext.settings.enable_background_check.organisation.i18n} />
                            </div>
                        </>
                        :
                        <>
                            <i className='mdi mdi-close text-danger mr-1' />
                            <Translate id='settings.crc.disabled' />
                        </>
                    }
                </div>
            </div>

            <Collapse isOpen={!!backgroundChecks.length}>
                <div className="">
                    <div className="text-dark font-bold mb-2 h5"><Translate id='settings.crc.rules' /></div>
                    {!backgroundChecks.length &&
                        <div className='font-medium text-dark mb-3'>
                            <Translate id='settings.crc.rules.none' />
                        </div>
                    }

                    {backgroundChecks.map((check, index) => {
                        const expirationMethod = expirationMethods.find((expiration) => expiration.code === check.expiration_method);
                        const differentOrg = check.organisation && check.organisation.organisation_id !== organisationContext.organisation_id

                        return (
                            <Collapse key={check.organisation_background_check_id} isOpen appear className="pb-3">
                                <div className={stringBuilder(enabled ? "shadow-sm" : "bg-light-inverse", "p-3 border rounded position-relative")}>
                                    {differentOrg &&
                                        <div className='font-medium text-dark mb-3'><u><DisplayI18n field='name' defaultValue={check.organisation.organisation_name} i18n={check.organisation.i18n} /></u></div>
                                    }
                                    <Row form>
                                        <Col xs={6} md={4} className="mb-3 mb-md-">
                                            <div className='text-muted'><Translate id='settings.crc.validationType' /></div>
                                            <span className='font-medium text-dark'><DisplayI18n field='name' defaultValue={check.background_check_type.name} i18n={check.background_check_type.i18n} /></span>
                                        </Col>
                                        <Col xs={6} md={4}>
                                            <div className='text-muted'><Translate id='settings.crc.memberType' /></div>
                                            <span className='font-medium text-dark'><DisplayI18n field='name' defaultValue={check.member_type.name} i18n={check.member_type.i18n} /></span>
                                        </Col>
                                        <Col xs={6} md={4} className="mb-3 mb-md-">
                                            <div className='text-muted'><Translate id='settings.crc.duration' /></div>
                                            <span className='font-medium text-dark'><DisplayI18n field='name' defaultValue={check.background_check_duration.name} i18n={check.background_check_duration.i18n} /></span>
                                        </Col>
                                        <Col xs={6} md={4}>
                                            <div className="text-muted"><Translate id='settings.crc.expirationMethod' /></div>
                                            <span className='font-medium text-dark'>
                                                {expirationMethod ? <DisplayI18n field='name' defaultValue={expirationMethod.name} i18n={expirationMethod.i18n} /> : '-'}
                                                {expirationMethod?.code === 'FIXED_PERIOD' &&
                                                    <> - {getMonthDayString(check.expiration_date)}</>
                                                }
                                            </span>
                                        </Col>
                                        <Col xs={6} md={4} className='mb-3'>
                                            <div className="text-muted"><Translate id='settings.crc.complianceDate' /></div>
                                            <span className='font-medium text-dark'>
                                                {check.compliance_due_date ? getMonthDayString(check.compliance_due_date) : '-'}
                                            </span>
                                        </Col>
                                        <Col xs={6} md={4} className='mb-3'>
                                            <div className="text-muted"><Translate id='settings.crc.minAge' /></div>
                                            <span className='font-medium text-dark'>
                                                {check.min_age || '-'}
                                            </span>
                                        </Col>
                                        <Col xs={6} md={8} />
                                        <Col xs={12} md={4}>
                                            <span className="font-medium text-dark">
                                                {check.need_document == 1 ?
                                                    <>
                                                        <i className='mdi mdi-check text-primary mr-1' /><Translate id='settings.crc.supportingDocuments' />
                                                    </>
                                                    :
                                                    <>
                                                        <i className='mdi mdi-close text-danger mr-1' /><Translate id='settings.crc.supportingDocuments.notRequired' />
                                                    </>
                                                }
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        )
                    })}
                </div>
            </Collapse>
        </>
    );
}

export default SettingsCriminalRecordCheckView;