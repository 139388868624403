export default{
    "teams.profile.teamSetting.tab.title" : "Settings",

    "teams.profile.teamSetting.restrictions.title"            : "Team Restrictions",
    "teams.profile.teamSetting.restrictions.none"             : "No restrictions...",
    "teams.profile.teamSetting.restrictions.settingName"      : "Name",
    "teams.profile.teamSetting.restrictions.value"            : "Value",
    "teams.profile.teamSetting.restrictions.enforced"         : "Enforced",
    "teams.profile.teamSetting.restrictions.enforced.tooltip" : "An enforced setting will prevent a user from doing an action while a non-enforced setting will only give a warning visible on the team's profile.",

    "teams.profile.teamSetting.settings.categoryQualifications.title" : "Minimal Required Qualifications",

    "teams.profile.teamSetting.settings.qualifications.title"             : "Qualifications",
    "teams.profile.teamSetting.settings.qualifications.none"              : "No qualification...",
    "teams.profile.teamSetting.settings.qualifications.all"               : "All mandatory",
    "teams.profile.teamSetting.settings.qualifications.one"               : "At least one mandatory",
    "teams.profile.teamSetting.settings.qualifications.qualificationName" : "Qualification",

    "teams.profile.teamSetting.restrictions.description.protect_registration_date"             : "Prevents the person adding a player to edit the date they were added to the roster.",
    "teams.profile.teamSetting.restrictions.description.protect_released_date"                 : "Prevents the person adding a player to edit the protect date of the player.",
    "teams.profile.teamSetting.restrictions.description.team_fee"                              : "Fees for adding a new team.",
    "teams.profile.teamSetting.restrictions.description.new_registration_setting"              : "Allow all cards will allow any players even if they were never part of a team. Must have previous card means that they need to be part of a previous team.",
    "teams.profile.teamSetting.restrictions.description.released_registration_setting"         : "Allow to release players.",
    "teams.profile.teamSetting.restrictions.description.allow_inline_editing_of_jersey"        : "The team status will be kept if a jersey number is changed.",
    "teams.profile.teamSetting.restrictions.description.maximum_affiliate_registrations"       : "Maximum number of affiliated players.",
    "teams.profile.teamSetting.restrictions.description.maximum_import_registrations"          : "Maximum number of import players.",
    "teams.profile.teamSetting.restrictions.description.maximum_tryout_registrations"          : "Maximum number of tryout players.",
    "teams.profile.teamSetting.restrictions.description.maximum_released_registrations"        : "Maximum number of released players.",
    "teams.profile.teamSetting.restrictions.description.participants_require_pre_registration" : "Indicate if the player needs to have a previous registration.",
    "teams.profile.teamSetting.restrictions.description.send_email_notice_when_team_changes"   : "System will send an email on team change.",
    "teams.profile.teamSetting.restrictions.description.maximum_number_players"                : "Maximum number of active players.",
    "teams.profile.teamSetting.restrictions.description.player_allotment"                      : "Allowed number of players (including released players).",
    "teams.profile.teamSetting.restrictions.description.minimum_age"                           : "Minimum age for underage players («Enforced» is ignored).",
    "teams.profile.teamSetting.restrictions.description.maximum_age"                           : "Maximum age for overage players («Enforced» is ignored).",

    "teams.profile.teamSetting.settings.warnings.title" : "Warnings",

    "teams.profile.teamSetting.goTo.settings" : "Go To Team Restrictions",

}