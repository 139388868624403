import React from "react";
import {
    Label
} from "reactstrap";
import { FormikCheckedButton, FormikInputNumber, FormikInputText, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import FormikEditable from "../../../../components/formik/FormikEditable";
import { I18nContext } from "../../../../contexts/I18nContext";
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from "../../../../helpers/i18nHelper";
import withContexts from "../../../../helpers/withContexts";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { ClinicsContext } from "../../../../contexts/ClinicsContext";
import { object, string, number, bool } from 'yup';
import NoImgItem from '../../../../assets/images/NoImage_Item.svg';
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../components/sidePanel/SidePanel";
import { success, fail } from '@spordle/toasts';
import { UtilsContext } from "../../../../contexts/UtilsContext";

class ClinicItemsSidepanel extends React.Component{
    state = {
        isLoading: false,
    };

    render(){
        return (
            <I18nHelperContext.Consumer>
                {(i18nHelper) => (
                    <OverlayLoader isLoading={this.state.isLoading}>
                        <SidePanel.Header>
                            <div className='d-flex mb-2'>
                                <SidePanel.ToggleButton />
                                <SidePanel.ActionsMenu action='DELETE' componentCode='clinics' componentPermissionCode='clinics_settings'>
                                    <SidePanel.MenuDelete
                                        translateModalMsg
                                        modalMsg='catalog.clinicItems.sidepanel.delete.message'
                                        modalTitle={<DisplayI18n field="name" i18n={this.props.getCommonValue('i18n')} defaultValue={this.props.getCommonValue('name')} />}
                                        onConfirm={() => {
                                            return this.props.ClinicsContext.deleteClinicItems(this.props.getCommonValue('fee_id'))
                                                .then(() => {
                                                    this.props.tableRef.deleteRow(this.props.getCommonValue('fee_id')).then(this.props.toggle);
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        if(error.i18n){
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }else{
                                                            let msg;
                                                            switch (error.message){
                                                                case '3197':
                                                                    msg = 'contexts.feesContext.errors.3197';
                                                                    break;
                                                                default:
                                                                    msg = 'misc.error';
                                                                    break;
                                                            }
                                                            fail({ msg });
                                                        }
                                                    }
                                                })
                                        }}
                                    />
                                </SidePanel.ActionsMenu>
                            </div>
                            <SidePanel.Title>
                                <DisplayI18n
                                    field='name'
                                    defaultValue={this.props.getCommonValue('name')}
                                    i18n={this.props.getCommonValue('i18n')}
                                />
                            </SidePanel.Title>
                        </SidePanel.Header>
                        <div className="p-3">
                            <div className="d-flex align-items-center mb-3">
                                <button type='button' className="reset-btn mr-2">
                                    <img className="border" src={this.props.selectedRows[0]?.img || NoImgItem} alt={this.props.selectedRows[0]?.clinicName} width="60" height="60" />
                                </button>
                                {/* <div className="d-flex align-items-center">
                                    <Button color="link"><i className="mdi mdi-pencil"></i></Button>
                                    <span className="border-left align-self-stretch mx-2" />
                                    <Button color="link" className="text-danger"><i className="mdi mdi-delete"></i></Button>
                                </div> */}
                            </div>
                            <div className="mb-3">
                                <Label for="status" className="text-muted"><Translate id='catalog.clinicItems.sidepanel.form.status' /></Label>
                                <FormikEditable
                                    id="status"
                                    noConfirmation
                                    initialValues={{
                                        status: this.props.getCommonValue('active'),
                                    }}
                                    onSubmit={(values) => {
                                        if(values.status !== this.props.getCommonValue("active")){
                                            this.setState({ isLoading: true });
                                            const newValues = this.props.createNewValues({ active: values.status });
                                            this.props.ClinicsContext.updateClinicItems(this.props.getCommonValue('fee_id'), values)
                                                .then(() => {
                                                    this.props.syncRows(newValues);
                                                    success();
                                                    this.setState({ isLoading: false });
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                        this.setState({ isLoading: false });
                                                    }
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing, options, formik) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>
                                                    <Translate id={this.props.selectedRows[0]?.active == '1' ? 'catalog.clinicItems.sidepanel.form.status.active' : 'catalog.clinicItems.sidepanel.form.status.inactive'} />
                                                </div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                onOptionSelected={options.stopEditing}
                                                search={false}
                                                name='status'
                                                id='status'
                                                autoFocus
                                                menuIsDefaultOpen
                                                defaultData={[
                                                    {
                                                        label: 'catalog.clinicItems.sidepanel.form.status.active',
                                                        value: '1',
                                                        translateLabel: true,
                                                    },
                                                    {
                                                        label: 'catalog.clinicItems.sidepanel.form.status.inactive',
                                                        value: '0',
                                                        translateLabel: true,
                                                    },
                                                ]}
                                                loadingStatus='success'
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </div>
                            {/* <div className="mb-3">
                                <Label for="type" className="text-muted"><Translate id='catalog.clinicItems.sidepanel.form.type'/></Label>
                                <FormikEditable
                                    id="type"
                                    initialValues={{
                                        type:''
                                    }}
                                    onSubmit={(values) => {

                                    }}
                                >
                                    {isEditing => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>{this.state.item.type}</div>
                                            )
                                        } else {
                                            return (
                                                <FormikSelect name='type' id='type'>
                                                    <SpordleSelect.Option id='type-1' label='Product' value='Product' selected/>
                                                </FormikSelect>
                                            )
                                        }
                                    }}
                                </FormikEditable>
                            </div> */}

                            <RenderI18nForm field="name">
                                {({ fieldName, fieldLabel }) => {
                                    return (
                                        <div className="mb-3" key={fieldName}>
                                            <Label for={fieldName} className="text-muted">{fieldLabel}</Label>
                                            <FormikEditable
                                                id={fieldName}
                                                initialValues={i18nHelper.getInitialValues(this.props.selectedRows[0])}
                                                validationSchema={object().shape(
                                                    i18nHelper.getValidationSchema({ name: string().required(<Translate id='catalog.clinicItems.sidepanel.form.name.required' />) }),
                                                )}
                                                onSubmit={(values) => {
                                                    this.setState({ isLoading: true });
                                                    const newValues = this.props.createNewValues(values);
                                                    this.props.ClinicsContext.updateClinicItems(this.props.getCommonValue('fee_id'), i18nHelper.getAPIValues(values))
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            this.setState({ isLoading: false });
                                                            success();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                                this.setState({ isLoading: false });
                                                            }
                                                        })
                                                }}
                                            >
                                                {(isEditing) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className='font-medium text-dark'>
                                                                <DisplayI18n
                                                                    field={fieldName}
                                                                    defaultValue={this.props.selectedRows[0].name || '-'}
                                                                    i18n={this.props.selectedRows[0].i18n}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikInputText id={fieldName} name={fieldName} autoFocus />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    )
                                }}
                            </RenderI18nForm>

                            <RenderI18nForm field="description">
                                {({ fieldName, fieldLabel }) => {
                                    return (
                                        <div className="mb-3" key={fieldName}>
                                            <Label for={fieldName} className="text-muted">{fieldLabel}</Label>
                                            <FormikEditable
                                                id={fieldName}
                                                initialValues={i18nHelper.getInitialValues(this.props.selectedRows[0])}
                                                validationSchema={object().shape(
                                                    i18nHelper.getValidationSchema({ name: string().required() }),
                                                )}
                                                onSubmit={(values) => {
                                                    const newValues = this.props.createNewValues(values);
                                                    this.setState({ isLoading: true });
                                                    this.props.ClinicsContext.updateClinicItems(this.props.getCommonValue('fee_id'), i18nHelper.getAPIValues(values))
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            this.setState({ isLoading: false });
                                                            success();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                                this.setState({ isLoading: false });
                                                            }
                                                        })
                                                }}
                                            >
                                                {(isEditing) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className='font-medium text-dark'>
                                                                <DisplayI18n
                                                                    field={fieldName}
                                                                    defaultValue={this.props.selectedRows[0].description || '-'}
                                                                    i18n={this.props.selectedRows[0].i18n}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikTextArea id={fieldName} name={fieldName} rows="6" autoFocus />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    )
                                }}
                            </RenderI18nForm>


                            <div className="mb-3">
                                <Label for="price" className="text-muted"><Translate id='catalog.clinicItems.sidepanel.form.price' /></Label>
                                <FormikEditable
                                    id="price"
                                    initialValues={{
                                        price: this.props.getCommonValue('amount') / 100,
                                        taxable: !!this.props.selectedRows[0].tax_class,
                                    }}
                                    validationSchema={object().shape({
                                        price: number().required(<Translate id='catalog.clinicItems.add.form.validation.amout.required' />),
                                        taxable: bool(),
                                    })}
                                    onSubmit={async(values) => {
                                        const price = Math.round(values.price * 100);
                                        // values.price will be a number and getCommonValue a string thus != and not !==
                                        if(price != this.props.getCommonValue("amount") || values.taxable != !!this.props.selectedRows[0].tax_class){
                                            this.setState({ isLoading: true });
                                            const taxClasses = values.taxable
                                                ?
                                                await this.props.UtilsContext.getTaxClasses()
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                                :
                                                [];
                                            const taxClass = taxClasses.filter((taxClass) => taxClass.active == '1')[0];

                                            const apiValues = {
                                                price: price,
                                                tax_class_id: taxClass?.tax_class_id || '',
                                            };

                                            const newValues = this.props.createNewValues({ amount: price, tax_class: taxClass });
                                            this.props.ClinicsContext.updateClinicItems(this.props.getCommonValue('fee_id'), apiValues)
                                                .then(() => {
                                                    this.props.syncRows(newValues);
                                                    this.setState({ isLoading: false });
                                                    success();
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        this.setState({ isLoading: false });
                                                        if(error.i18n){
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }else{
                                                            switch (error.message){
                                                                case '3045':
                                                                    fail({ msg: `catalog.clinicItems.add.form.error.${error.message}` })
                                                                    break;
                                                                default:
                                                                    fail();
                                                                    break;
                                                            }
                                                        }
                                                    }
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message);
                                                    }
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>
                                                    <CurrencyFormat value={this.props.getCommonValue('amount') / 100} />
                                                    {this.props.selectedRows[0].tax_class &&
                                                        <sup className='text-muted ml-1'>(+<Translate id='catalog.clinicItems.sidepanel.form.tax' />)</sup>
                                                    }
                                                </div>
                                            )
                                        }
                                        return (
                                            <>
                                                <I18nContext.Consumer>
                                                    {({ getGenericLocale }) => (
                                                        <FormikInputNumber
                                                            name='price' id='price'
                                                            allowNegative={false} allowLeadingZeros
                                                            fixedDecimalScale decimalScale={2}
                                                            thousandSeparator=' '
                                                            suffix={getGenericLocale() === 'fr' ? '$' : undefined}
                                                            prefix={getGenericLocale() !== 'fr' ? '$' : undefined}
                                                            decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                        />
                                                    )}
                                                </I18nContext.Consumer>
                                                <FormikCheckedButton id='taxable' name='taxable' label='catalog.clinicItems.add.form.taxable' className='mt-2' />
                                            </>
                                        )

                                    }}
                                </FormikEditable>
                            </div>
                            {/* <div className="mb-3">
                                <Label for="taxes" className="text-muted"><Translate id='catalog.clinicItems.sidepanel.form.tax'/></Label>
                                <FormikEditable
                                    id="taxes"
                                    initialValues={{
                                        taxes: ''
                                    }}
                                    onSubmit={(values) => {

                                    }}
                                >
                                    {isEditing => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'></div>
                                            )
                                        } else {
                                            return (
                                                <FormikSelect name='taxes' id='taxes'>
                                                </FormikSelect>
                                            )
                                        }
                                    }}
                                </FormikEditable>
                            </div> */}
                            {/* <div className="mb-3">
                                <Label for="sku" className="text-muted"><Translate id='catalog.clinicItems.sidepanel.form.SKU'/></Label>
                                <FormikEditable
                                    id="sku"
                                    initialValues={{
                                        sku: this.props.getCommonValue('sku')
                                    }}
                                    onSubmit={(values) => {

                                    }}
                                >
                                    {isEditing => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>{this.props.getCommonValue('sku')}</div>
                                            )
                                        } else {
                                            return (
                                                <FormikSelect name='sku' id='sku'>
                                                    <SpordleSelect.Option id='sku-4152' label='4152 - Registration' value='4152' />
                                                </FormikSelect>
                                            )
                                        }
                                    }}
                                </FormikEditable>
                            </div> */}
                        </div>
                    </OverlayLoader>
                )}
            </I18nHelperContext.Consumer>
        )
    }
}

export default withContexts(OrganizationContext, ClinicsContext, UtilsContext)(ClinicItemsSidepanel);