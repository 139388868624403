import { useContext, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { QuickViewContext } from '../../contexts/QuickViewContext';


const QuickViewHistoryListener = (props) => {
    const history = useHistory();
    const quickViewContext = useContext(QuickViewContext);

    useEffect(() => history.block((location) => quickViewContext.validateQuickViewLink(location.pathname)), []);
    return null
}

export default withRouter(QuickViewHistoryListener);