const MobileManage = ({ color, ...props }) => {
    const cls1 = {
        fill: "#fff",
        opacity: 0,
    }
    return (
        <svg id="MobileManage" data-name="MobileManage" xmlns="http://www.w3.org/2000/svg" viewBox={props.viewBox ?? "0 0 40 40"} width={props.width ?? "35"} height={props.height ?? "25"} fill="currentColor">
            <g id="yel7TX.tif">
                <path d="M26.56,18.66c.1-.09.35,0,.53,0a5.22,5.22,0,0,0,4.77-2.32A5.31,5.31,0,0,0,32.35,11c-.55.55-1.07,1-1.55,1.56a.71.71,0,0,1-.86.23c-.52-.2-1.2-.21-1.56-.56s-.32-1-.52-1.53a.69.69,0,0,1,.23-.85c.52-.48,1-1,1.6-1.56l-1.6-.47H26.77a6.56,6.56,0,0,0-2.42,1,5.26,5.26,0,0,0-2.29,4.83.86.86,0,0,1-.27.74c-1,1-2.05,2-3.1,3.09-1-1-2-2-3-3.06-.37-.37-.72-.75-1.1-1.09a1.67,1.67,0,0,1-.66-1.41,2.5,2.5,0,0,0-1.23-2.2L9.91,7.85h-.1L7.18,10.49v.09c.59.89,1.2,1.76,1.78,2.65a2.79,2.79,0,0,0,2.56,1.37,1.08,1.08,0,0,1,.86.36c1.46,1.47,2.93,2.92,4.49,4.48a2.92,2.92,0,0,0-.45.32q-3.54,3.51-7.06,7a2.28,2.28,0,0,0,0,3.55c.17.17.36.34.53.53a4.53,4.53,0,0,0,1.7,1.27h.94A4.24,4.24,0,0,0,14.21,31c1.8-1.82,3.61-3.62,5.41-5.43a2.61,2.61,0,0,0,.31-.49c.58.6,1,1.07,1.5,1.54,1.45,1.45,2.91,2.89,4.35,4.35a4.24,4.24,0,0,0,1.65,1.19h.94a6.76,6.76,0,0,0,2.68-2.29,2.15,2.15,0,0,0-.31-2.94c-1.89-1.91-3.79-3.8-5.69-5.7-.16-.17-.35-.31-.56-.5C25.2,20,25.86,19.31,26.56,18.66Zm2.58,9.49c.32.32.37.53,0,.83s-.63.66-1,.95a.53.53,0,0,1-.5,0c-2.07-2-4.13-4.11-6.22-6.2l1.65-1.61a4,4,0,0,1,.31.29C25.32,24.35,27.22,26.26,29.14,28.15Zm-16.57,1.6c-.47.47-.47.47-.93,0-.3-.3-.59-.61-.9-.89s-.22-.4,0-.59A3.31,3.31,0,0,0,11,28c4.28-4.27,8.54-8.55,12.83-12.81a1.08,1.08,0,0,0,.33-1.07,3.33,3.33,0,0,1,1.49-3.73c.31,1.08.63,2.11.9,3.15a.7.7,0,0,0,.58.57c1,.27,2.07.59,3.12.89a3.08,3.08,0,0,1-3.2,1.56,1.93,1.93,0,0,0-2,.69C20.9,21.44,16.73,25.59,12.57,29.75Z" />
                <path style={cls1} d="M25.66,10.4c.31,1.08.63,2.11.9,3.15a.7.7,0,0,0,.58.57c1,.27,2.07.59,3.12.89a3.08,3.08,0,0,1-3.2,1.56,1.93,1.93,0,0,0-2,.69c-4.13,4.18-8.3,8.33-12.46,12.49-.47.47-.47.47-.93,0-.3-.3-.59-.61-.9-.89s-.22-.4,0-.59A3.31,3.31,0,0,0,11,28c4.28-4.27,8.54-8.55,12.83-12.81a1.08,1.08,0,0,0,.33-1.07A3.33,3.33,0,0,1,25.66,10.4Z" />
                <path style={cls1} d="M21.45,23.76l1.65-1.61a2.74,2.74,0,0,1,.31.29c1.91,1.91,3.81,3.82,5.73,5.71.32.31.37.53,0,.83s-.63.66-1,.95a.53.53,0,0,1-.5,0C25.6,27.92,23.54,25.85,21.45,23.76Z" />
            </g>
        </svg>
    )
}

export default MobileManage