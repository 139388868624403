import { FormikDateTime, FormikError, FormikInputNumber, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate, { CurrencyFormat, DateFormat } from '@spordle/intl-elements';
import moment from 'moment';
import React from 'react';
import {
    Col,
    Collapse, FormGroup, Label, Row, Spinner
} from "reactstrap";
import { object, string, number, mixed } from 'yup';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import FormikEditable from '../../../../../../components/formik/FormikEditable';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { fail, success } from '@spordle/toasts';
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { RegistrationFeeContext } from '../../../../../../contexts/RegistrationFeeContext';
import { RolesContext } from '../../../../../../contexts/RolesContext';
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../../../helpers/i18nHelper';
// Contexts
import withContexts from '../../../../../../helpers/withContexts';
import { Tooltip } from '@mantine/core';
import { PeriodsContext } from '../../../../../../contexts/contexts';
import { getAdditionalFieldsFormikEditableRender, getFormattedAdditionalFields } from '../../../../../../helpers/additionalFieldsHelpers';
import { UtilsContext } from '../../../../../../contexts/UtilsContext';
import { debounce } from '@spordle/helpers';
import { MerchantAccountContext } from '../../../../../../contexts/MerchantAccountContext';

class RegistrationFeesSidepanelFee extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loading: false,
            activityPeriods: [],
            platformFee: undefined,
            earlyPlatformFee: undefined,
            latePlatformFee: undefined,
            feesLoading: false,
            affiliation_fees: this.getAffiliationFees(),
        }

        const settings = props.OrganizationContext.settings;

        this.hasCreditCard = this.props.MerchantAccountContext.merchantAccounts ? this.props.MerchantAccountContext.merchantAccounts?.some((m) => m.active == 1) : false
        this.hasPlatformFees = settings.fee_for_registration?.value === 'CLIENT' && this.hasCreditCard;
        this.canManageAge = settings.manage_min_max_age_registration_fee?.value == 1;

        this.manageExcludeAffiliation = props.RolesContext.canDoAction('EDIT', 'organization', 'manage_exclude_affiliation', true);
    }

    componentDidMount(){
        if(this.props.OrganizationContext.settings.enable_organization_activity_periods?.value == '1'){
            this.props.PeriodsContext.getOrganizationActivityPeriods({ organisation_id: this.props.OrganizationContext.organisation_id, active: '1' })
                .then((activityPeriods) => {
                    this.setState({
                        activityPeriods: activityPeriods.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order)).map((activityPeriod) => ({
                            value: activityPeriod.activity_period_id,
                            label: activityPeriod.name,
                            description: activityPeriod.description,
                            i18n: activityPeriod.i18n,
                            exclude_affiliation_fee: activityPeriod.exclude_affiliation_fee == "1",
                        })),
                    })
                });
        }

        this.getFees();
    }

    componentDidUpdate(prevProps){
        if(prevProps.fee.registration_fee_id !== this.props.fee.registration_fee_id){
            this.getFees()
        }
    }


    getFormGroups = () => {
        return this.props.OrganizationContext.additionalFieldsComponents?.find((a) => a.component === "REGISTRATION_FEE")?.groups || []
    }

    getAdditionalFields = () => {
        return (this.props.fee?.additional_fields?.map((field) => ({
            fieldId: field.custom_form_field.custom_form_field_id,
            answer: field.answer,
            optionId: field.custom_form_field_option_selected,
        })) || null)
    }

    getAffiliationFees = () => {
        return {
            amount: this.props.fee.exclude_affiliation_fee == '0' ? this.props.fee.affiliation_fees?.total?.[this.props.fee.member_type?.member_type_id]?.amount || 0 : 0,
            early_amount: this.props.fee.exclude_affiliation_fee == '0' ? this.props.fee.affiliation_fees?.total?.[this.props.fee.member_type?.member_type_id]?.early_amount || 0 : 0,
            late_amount: this.props.fee.exclude_affiliation_fee == '0' ? this.props.fee.affiliation_fees?.total?.[this.props.fee.member_type?.member_type_id]?.late_amount || 0 : 0,
        }
    }

    getFees = () => {
        if(this.hasPlatformFees){
            this.setState(() => ({ feesLoading: true }))
            Promise.all([
                this.calculatePlatformFees(parseInt(this.props.fee.fee.amount) + parseInt(this.state.affiliation_fees.amount)),
                this.props.fee.fee.early_amount ? this.calculatePlatformFees((parseInt(this.props.fee.fee.early_amount) + parseInt(this.state.affiliation_fees.amount))) : Promise.resolve(),
                this.props.fee.fee.late_amount ? this.calculatePlatformFees((parseInt(this.props.fee.fee.late_amount) + parseInt(this.state.affiliation_fees.amount))) : Promise.resolve(),
            ])
                .then((fees) => {
                    this.setState(() => ({
                        platformFee: fees[0],
                        earlyPlatformFee: fees[1],
                        latePlatformFee: fees[2],
                        feesLoading: false,
                    }))
                })
        }
        this.setState(() => ({ affiliation_fees: this.getAffiliationFees() }))
    }

    debounceGetFees = debounce((amount, resolve) => {
        return this.calculatePlatformFees(amount)
            .then(resolve)
    }, 200)

    updateFeeOnChange = (amount) => {
        return new Promise((resolve) => {
            this.debounceGetFees(amount, resolve)
        });
    }

    calculatePlatformFees = (amount) => {
        return this.props.UtilsContext.calculateFees(this.props.OrganizationContext.organisation_id, amount, 'REGISTRATION')
            .then((fee) => fee.fee_spordle)
    }

    render(){
        const readOnly = this.props.readOnly;
        const canDoAction = !readOnly || this.props.RolesContext.canDoAction('EDIT', 'catalog', 'registration_fees');

        return (
            <div className='position-relative'>
                <OverlayLoader isLoading={this.state.loading}>
                    <RenderI18nForm field='name'>
                        {({ fieldName, fieldLabel, lang }) => (
                            <div className="mb-3" key={fieldName}>
                                <Label for={fieldName} className="text-muted mb-0">{fieldLabel}</Label>
                                <FormikEditable
                                    id={fieldName}
                                    disabled={!canDoAction}
                                    readOnly={readOnly}
                                    initialValues={this.props.I18nHelperContext.getInitialValues(this.props.fee)}
                                    validationSchema={object().shape(
                                        this.props.I18nHelperContext.getValidationSchema({ name: string().required(<Translate id='form.required' />) }),
                                    )}
                                    onSubmit={(values) => {
                                        const newValues = this.props.panelProps.createNewValues(values);
                                        this.props.RegistrationFeeContext.updateRegistrationFee(this.props.fee.registration_fee_id, this.props.I18nHelperContext.getAPIValues(values))
                                            .then(() => {
                                                this.props.panelProps.syncRows(newValues);
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                    }}
                                >
                                    {(isEditing) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>
                                                    <DisplayI18n lang={lang} field={fieldName} i18n={this.props.fee.i18n} defaultValue={this.props.fee.name || '-'} />
                                                </div>
                                            )
                                        }
                                        return (
                                            <FormikInputText name={fieldName} id={fieldName} trim autoFocus />
                                        )

                                    }}
                                </FormikEditable>
                            </div>
                        )}
                    </RenderI18nForm>

                    <div className="mb-3">
                        <Label for="sku" className="text-muted mb-0"><Translate id='catalog.registrationFees.sidePanel.form.sku' /></Label>
                        <FormikEditable
                            id="sku"
                            disabled={true || !canDoAction}
                            readOnly={readOnly}
                            noConfirmation
                            initialValues={{
                                sku: '0',
                            }}
                            onSubmit={(values) => {
                            }}
                        >
                            {(isEditing, options) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{this.props.fee.sku || '-'}</div>
                                    )
                                }
                                return (
                                    <FormikSelect
                                        name='sku'
                                        id='sku'
                                        menuIsDefaultOpen
                                        autoFocus
                                        onOptionSelected={options.stopEditing}
                                        defaultData={[ {
                                            label: '4587 - Registration',
                                            value: '0',
                                        } ]}
                                        loadingStatus='success'
                                    />
                                )

                            }}
                        </FormikEditable>
                    </div>
                    <Row>
                        <Col sm="6">
                            <div className="mb-3">
                                <Label for="status" className="text-muted mb-0"><Translate id='catalog.registrationFees.sidePanel.form.status' /></Label>
                                <FormikEditable
                                    id="status"
                                    noConfirmation
                                    disabled={!canDoAction}
                                    readOnly={readOnly}
                                    initialValues={{
                                        status: this.props.fee.active,
                                    }}
                                    onSubmit={(values) => {
                                        if(this.props.fee.active !== values.status){
                                            this.setState(() => ({ loading: true }))
                                            const newValues = this.props.panelProps.createNewValues({ active: values.status });

                                            this.props.RegistrationFeeContext.updateRegistrationFee(this.props.fee.registration_fee_id, { active: values.status })
                                                .then(() => {
                                                    success({ msg: 'catalog.registrationFees.sidePanel.form.updated' })
                                                    this.props.panelProps.syncRows(newValues)
                                                    this.setState(() => ({ loading: false }))
                                                })
                                                .catch((error) => {
                                                    console.error(error)
                                                    if(!AxiosIsCancelled(error.message)){
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                    this.setState(() => ({ loading: false }))
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing, options) => {
                                        if(!isEditing){
                                            return (
                                                this.props.fee.active === '1' ?
                                                    <div className='font-medium text-dark'><Translate id='misc.active' /></div>
                                                    :
                                                    <div className='font-medium text-dark'><Translate id='misc.inactive' /></div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                name='status'
                                                id='status'
                                                autoFocus
                                                menuIsDefaultOpen
                                                onOptionSelected={options.stopEditing}
                                                search={false}
                                                defaultData={[
                                                    {
                                                        label: 'misc.active',
                                                        value: '1',
                                                        translateLabel: true,
                                                    },
                                                    {
                                                        label: 'misc.inactive',
                                                        value: '0',
                                                        translateLabel: true,
                                                    },
                                                ]}
                                                loadingStatus='success'
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <Label for="availablePlaces" className="text-muted mb-0"><Translate id='catalog.registrationFees.sidePanel.form.totalPlaces' /></Label>
                                <FormikEditable
                                    id="availablePlaces"
                                    disabled={!canDoAction || !!this.props.fee.is_carding_fee}
                                    readOnly={readOnly}
                                    initialValues={{
                                        availablePlaces: this.props.fee.available_place,
                                    }}
                                    validationSchema={object().shape({
                                        availablePlaces: number().nullable().integer()
                                            .positive(<Translate id='catalog.registrationFees.add.form.positive' />)
                                            .test({
                                                name: 'waitingListTest',
                                                message: <Translate id='catalog.registrationFees.add.form.cannotWaitingList' />,
                                                test: (value) => {
                                                    if(parseInt(this.props.fee.manage_waiting_list)){
                                                        return !!value
                                                    }
                                                    return true
                                                },
                                            })
                                            .test({
                                                name: 'showPlacesTest',
                                                message: <Translate id='catalog.registrationFees.add.form.cannotShowPlaces' />,
                                                test: (value) => {
                                                    if(parseInt(this.props.fee.show_registration_count)){
                                                        return !!value
                                                    }
                                                    return true
                                                },
                                            }),
                                    })}
                                    onSubmit={(values) => {
                                        this.setState(() => ({ loading: true }))
                                        const newValues = this.props.panelProps.createNewValues({ available_place: values.availablePlaces || null });

                                        this.props.RegistrationFeeContext.updateRegistrationFee(this.props.fee.registration_fee_id, { available_place: values.availablePlaces || '' })
                                            .then(() => {
                                                success({ msg: 'catalog.registrationFees.sidePanel.form.updated' });
                                                this.props.panelProps.syncRows(newValues);
                                                this.setState(() => ({ loading: false }))
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                                this.setState(() => ({ loading: false }))
                                            })
                                    }}
                                >
                                    {(isEditing) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>{this.props.fee.available_place || <Translate id='catalog.registrationFees.sidePanel.form.unlimited' />}</div>
                                            )
                                        }
                                        return (
                                            <FormikInputNumber id='availablePlaces' name='availablePlaces' allowNegative={false} decimalSeparator={false} />
                                        )

                                    }}
                                </FormikEditable>
                            </div>
                        </Col>
                        <Col sm='6'>
                            <FormGroup>
                                <Label for="manage_waiting_list" className="text-muted mb-0"><Translate id='catalog.registrationFees.add.form.waitingList' /></Label>
                                <FormikEditable
                                    id="manage_waiting_list"
                                    noConfirmation
                                    disabled={!canDoAction || !this.props.fee.available_place}
                                    readOnly={readOnly}
                                    initialValues={{
                                        manage_waiting_list: this.props.fee.manage_waiting_list,
                                    }}
                                    validationSchema={object().shape({
                                        manage_waiting_list: string()
                                            .test({
                                                name: 'availablePlacesTest',
                                                message: <Translate id='catalog.registrationFees.add.form.needAvailablePlaces' />,
                                                test: (value) => {
                                                    if(!this.props.fee.available_place && parseInt(value)){
                                                        return false
                                                    }
                                                    return true
                                                },
                                            }),
                                    })}
                                    onSubmit={(values) => {
                                        if(values.manage_waiting_list != this.props.fee.manage_waiting_list){
                                            this.setState(() => ({ loading: true }));

                                            const newWaitingList = values.manage_waiting_list;

                                            const newValues = this.props.panelProps.createNewValues({
                                                manage_waiting_list: newWaitingList,
                                            });

                                            this.props.RegistrationFeeContext.updateRegistrationFee(this.props.fee.registration_fee_id, { manage_waiting_list: newWaitingList })
                                                .then(() => {
                                                    success({ msg: 'catalog.registrationFees.sidePanel.form.updated' });
                                                    this.props.panelProps.syncRows(newValues);
                                                    this.setState(() => ({ loading: false }))
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                        this.setState(() => ({ loading: false }))
                                                    }
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing, options, formik) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>
                                                    <Translate id={this.props.fee.manage_waiting_list == 1 ? 'misc.yes' : 'misc.no'} />
                                                </div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                name='manage_waiting_list'
                                                id='manage_waiting_list'
                                                search={false}
                                                autoFocus
                                                menuIsDefaultOpen
                                                onOptionSelected={(values) => {
                                                    formik.setFieldValue('manage_waiting_list', values[0], false)
                                                        .then(options.stopEditing)
                                                    return true
                                                }}
                                                defaultData={[
                                                    {
                                                        label: 'misc.yes',
                                                        value: '1',
                                                        translateLabel: true,
                                                    },
                                                    {
                                                        label: 'misc.no',
                                                        value: '0',
                                                        translateLabel: true,
                                                    },
                                                ]}
                                                loadingStatus='success'
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </FormGroup>
                        </Col>
                        <Col sm='6'>
                            <FormGroup>
                                <Label for="show_registration_count" className="text-muted mb-0"><Translate id='catalog.registrationFees.add.form.showPlaces' /></Label>
                                <FormikEditable
                                    id="show_registration_count"
                                    noConfirmation
                                    disabled={!canDoAction || !this.props.fee.available_place}
                                    readOnly={readOnly}
                                    initialValues={{
                                        show_registration_count: this.props.fee.show_registration_count,
                                    }}
                                    validationSchema={object().shape({
                                        show_registration_count: string()
                                            .test({
                                                name: 'availablePlacesTest',
                                                message: <Translate id='catalog.registrationFees.add.form.needAvailablePlaces' />,
                                                test: (value) => {
                                                    if(!this.props.fee.available_place && parseInt(value)){
                                                        return false
                                                    }
                                                    return true
                                                },
                                            }),
                                    })}
                                    onSubmit={(values) => {
                                        if(values.show_registration_count != this.props.fee.show_registration_count){
                                            this.setState(() => ({ loading: true }));

                                            const newShowPlaces = values.show_registration_count;

                                            const newValues = this.props.panelProps.createNewValues({
                                                show_registration_count: newShowPlaces,
                                            });

                                            this.props.RegistrationFeeContext.updateRegistrationFee(this.props.fee.registration_fee_id, { show_registration_count: newShowPlaces })
                                                .then(() => {
                                                    success({ msg: 'catalog.registrationFees.sidePanel.form.updated' });
                                                    this.props.panelProps.syncRows(newValues);
                                                    this.setState(() => ({ loading: false }))
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                        this.setState(() => ({ loading: false }))
                                                    }
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing, options, formik) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>
                                                    <Translate id={this.props.fee.show_registration_count == 1 ? 'misc.yes' : 'misc.no'} />
                                                </div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                name='show_registration_count'
                                                id='show_registration_count'
                                                search={false}
                                                autoFocus
                                                menuIsDefaultOpen
                                                onOptionSelected={(values) => {
                                                    formik.setFieldValue('show_registration_count', values[0], false)
                                                        .then(options.stopEditing)
                                                    return true
                                                }}
                                                defaultData={[
                                                    {
                                                        label: 'misc.yes',
                                                        value: '1',
                                                        translateLabel: true,
                                                    },
                                                    {
                                                        label: 'misc.no',
                                                        value: '0',
                                                        translateLabel: true,
                                                    },
                                                ]}
                                                loadingStatus='success'
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </FormGroup>
                        </Col>
                        {!this.props.fee.available_place &&
                            <Col sm="12">
                                <div className="p-2 mb-3 bg-light-inverse rounded small border">
                                    <i className="mdi mdi-information-outline text-primary" /> <Translate id='catalog.registrationFees.add.form.availablePlaces.help' />
                                </div>
                            </Col>
                        }

                        {this.canManageAge &&
                            <Col sm="12">
                                <div className="mb-3">
                                    <Row>
                                        <Col col="6"><div className="text-muted"><Translate id='registration.settings.category.label.minAge' /></div></Col>
                                        <Col col="6"><div className="text-muted"><Translate id='registration.settings.category.label.maxAge' /></div></Col>
                                    </Row>
                                    <FormikEditable
                                        id='min_age'
                                        manualIcon
                                        disabled={!canDoAction}
                                        readOnly={readOnly}
                                        clearable clearableFields={[ 'min_age', 'max_age' ]}
                                        initialValues={{
                                            min_age: this.props.fee.min_age || this.props.fee.team_category?.min_age || '',
                                            max_age: this.props.fee.max_age || this.props.fee.team_category?.max_age || '',
                                        }}
                                        validationSchema={object().shape({
                                            min_age: number().min(1, <Translate id="misc.minimum" values={{ value: 1 }} />)
                                                .integer(<Translate id='registration.settings.class.form.validation.order.integer' />)
                                                .test({
                                                    name: "smallerOrEqualToMax",
                                                    message: <Translate id='registration.settings.category.form.minAge.smaller' />,
                                                    test: function(min_age){
                                                        return this.parent.max_age ? parseInt(min_age) <= parseInt(this.parent.max_age) : true
                                                    },
                                                }),
                                            max_age: number().nullable().min(1, <Translate id="misc.minimum" values={{ value: 1 }} />)
                                                .integer(<Translate id='registration.settings.class.form.validation.order.integer' />)
                                                .test({
                                                    name: "greaterOrEqualToMin",
                                                    message: <Translate id='registration.settings.category.form.maxAge.bigger' />,
                                                    test: function(max_age){
                                                        return this.parent.min_age ? parseInt(max_age) >= parseInt(this.parent.min_age) : true
                                                    },
                                                }),
                                        })}
                                        onSubmit={({ min_age, max_age }) => {
                                            if(min_age !== this.props.fee.min_age || max_age !== this.props.fee.max_age){
                                                this.setState(() => ({ loading: true }));
                                                min_age = min_age || null; // Has to send null if empty
                                                max_age = max_age || null; // Has to send null if empty
                                                const newValues = this.props.panelProps.createNewValues({ min_age: min_age, max_age: max_age });
                                                this.props.RegistrationFeeContext.updateRegistrationFee(this.props.fee.registration_fee_id, { min_age: min_age, max_age: max_age })
                                                    .then(() => {
                                                        success({ msg: 'catalog.registrationFees.sidePanel.form.updated' });
                                                        this.props.panelProps.syncRows(newValues);
                                                        this.setState(() => ({ loading: false }))
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                            this.setState(() => ({ loading: false }))
                                                        }
                                                    })
                                            }
                                        }}
                                    >
                                        {(isEditing) => {
                                            if(!isEditing){
                                                return (
                                                    <div className='w-100'>
                                                        <Row>
                                                            <Col col="6"><div className="font-medium text-dark">{this.props.fee.min_age || '-'}<i className='mdi mdi-pencil ml-2 text-primary' /></div></Col>
                                                            <Col col="6"><div className="font-medium text-dark">{this.props.fee.max_age || '-'}<i className='mdi mdi-pencil ml-2 text-primary' /></div></Col>
                                                        </Row>
                                                    </div>
                                                )
                                            }
                                            return (
                                                <Row className='mb-5'>
                                                    <Col col="6"><FormikInputNumber allowNegative={false} id="min_age" name="min_age" autoFocus /></Col>
                                                    <Col col="6"><FormikInputNumber allowNegative={false} id="max_age" name="max_age" autoFocus disabled={readOnly || !canDoAction || !!this.props.fee.team_category} /></Col>
                                                </Row>
                                            )

                                        }}
                                    </FormikEditable>
                                </div>
                            </Col>
                        }
                        <Col sm='6'>
                            <FormGroup>
                                <Label for="with_position" className="text-muted mb-0"><Translate id='catalog.registrationFees.add.form.showPosition' /></Label>
                                <FormikEditable
                                    id="with_position"
                                    noConfirmation
                                    disabled={!canDoAction}
                                    readOnly={readOnly}
                                    initialValues={{
                                        with_position: this.props.fee.with_position,
                                    }}
                                    validationSchema={object().shape({
                                        with_position: string(),
                                    })}
                                    onSubmit={(values) => {
                                        if(values.with_position != this.props.fee.with_position){
                                            this.setState(() => ({ loading: true }));

                                            const newWithPosition = values.with_position;

                                            const newValues = this.props.panelProps.createNewValues({
                                                with_position: newWithPosition,
                                            });

                                            this.props.RegistrationFeeContext.updateRegistrationFee(this.props.fee.registration_fee_id, { with_position: newWithPosition })
                                                .then(() => {
                                                    success({ msg: 'catalog.registrationFees.sidePanel.form.updated' });
                                                    this.props.panelProps.syncRows(newValues);
                                                    this.setState(() => ({ loading: false }))
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                        this.setState(() => ({ loading: false }))
                                                    }
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing, options, formik) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>
                                                    <Translate id={this.props.fee.with_position == 1 ? 'misc.yes' : 'misc.no'} />
                                                </div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                name='with_position'
                                                id='with_position'
                                                search={false}
                                                autoFocus
                                                menuIsDefaultOpen
                                                onOptionSelected={(values) => {
                                                    formik.setFieldValue('with_position', values[0], false)
                                                        .then(options.stopEditing)
                                                    return true
                                                }}
                                                defaultData={[
                                                    {
                                                        label: 'misc.yes',
                                                        value: '1',
                                                        translateLabel: true,
                                                    },
                                                    {
                                                        label: 'misc.no',
                                                        value: '0',
                                                        translateLabel: true,
                                                    },
                                                ]}
                                                loadingStatus='success'
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </FormGroup>
                        </Col>
                        {this.props.OrganizationContext.settings.enable_organization_activity_periods?.value == '1' &&
                            <Col sm='12'>
                                <FormGroup>
                                    <Label for='activity_period_id' className='text-muted'><Translate id='form.fields.activityPeriod' /></Label>
                                    <FormikEditable
                                        id="activity_period_id"
                                        disabled={!canDoAction}
                                        readOnly={readOnly}
                                        initialValues={{
                                            activity_period_id: this.props.fee.activity_period?.activity_period_id || '',
                                        }}
                                        validationSchema={object().shape({
                                            activity_period_id: string(),
                                        })}
                                        onSubmit={(values) => {
                                            if(values.activity_period_id != this.props.fee.activity_period_id){
                                                this.setState(() => ({ loading: true }));
                                                this.props.RegistrationFeeContext.updateRegistrationFee(this.props.fee.registration_fee_id, { activity_period_id: values.activity_period_id })
                                                    .then(async() => {
                                                        success({ msg: 'catalog.registrationFees.sidePanel.form.updated' });
                                                        await this.props.refreshSidepanel();
                                                        this.getFees();
                                                        this.setState(() => ({ loading: false }));
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                            this.setState(() => ({ loading: false }))
                                                        }
                                                    })
                                            }
                                        }}
                                    >
                                        {(isEditing, options, formik) => {
                                            if(!isEditing){
                                                const currentActivityPeriod = this.state.activityPeriods.find((activityPeriod) => activityPeriod.value === this.props.fee.activity_period?.activity_period_id)
                                                return (
                                                    <div className='font-medium text-dark'>
                                                        {this.state.activityPeriods.find((activityPeriod) => activityPeriod.value === this.props.fee.activity_period?.activity_period_id)?.label ?
                                                            <>
                                                                <DisplayI18n field='name' defaultValue={currentActivityPeriod?.label} i18n={currentActivityPeriod?.i18n} />
                                                                {currentActivityPeriod.exclude_affiliation_fee &&
                                                                    <div className='text-warning small'><i className='mdi mdi-information-outline' /><Translate id='catalog.registrationFees.add.activityPeriod.excludes.membership.fees' /></div>
                                                                }
                                                            </>
                                                            :
                                                            <>-</>
                                                        }
                                                    </div>
                                                )
                                            }
                                            return (
                                                <FormikSelect
                                                    name="activity_period_id"
                                                    id="activity_period_id"
                                                    clearable
                                                    renderSelectedOption={(option) => (
                                                        <>
                                                            <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                            <div className="small text-muted"><DisplayI18n field='description' defaultValue={option.description} i18n={option.i18n} /></div>
                                                        </>
                                                    )}
                                                    renderOption={(option) => (
                                                        <>
                                                            <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                                                            <div className="small text-muted"><DisplayI18n field='description' defaultValue={option.option.description} i18n={option.option.i18n} /></div>
                                                            {option.option.exclude_affiliation_fee &&
                                                                <div className='text-warning small'><i className='mdi mdi-information-outline' /><Translate id='catalog.registrationFees.add.activityPeriod.excludes.membership.fees' /></div>
                                                            }
                                                        </>
                                                    )}
                                                    options={this.state.activityPeriods}
                                                />
                                            )

                                        }}
                                    </FormikEditable>
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                    <hr className="mt-0" />
                    <div className="font-bold text-dark mb-2"><Translate id='catalog.registrationFees.sidePanel.form.registrationFees' /></div>
                    <FormGroup>
                        <Label for="exclude_affiliation_fee" className="text-muted mb-0"><Translate id='catalog.registrationFees.add.form.excludeAffiliationFee' /></Label>
                        <FormikEditable
                            id="exclude_affiliation_fee"
                            noConfirmation
                            disabled={!canDoAction}
                            readOnly={readOnly || !this.manageExcludeAffiliation || this.state.activityPeriods.find((activityPeriod) => activityPeriod.value === this.props.fee.activity_period?.activity_period_id)?.exclude_affiliation_fee == "1"}
                            initialValues={{
                                exclude_affiliation_fee: this.props.fee.exclude_affiliation_fee,
                            }}
                            validationSchema={object().shape({
                                exclude_affiliation_fee: string(),
                            })}
                            onSubmit={(values) => {
                                if(values.exclude_affiliation_fee != this.props.fee.exclude_affiliation_fee){
                                    this.setState(() => ({ loading: true }));
                                    this.props.RegistrationFeeContext.updateRegistrationFee(this.props.fee.registration_fee_id, { exclude_affiliation_fee: values.exclude_affiliation_fee })
                                        .then(async() => {
                                            success({ msg: 'catalog.registrationFees.sidePanel.form.updated' });
                                            await this.props.refreshSidepanel();
                                            this.getFees();
                                            this.setState(() => ({ loading: false }))
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                                this.setState(() => ({ loading: false }))
                                            }
                                        })
                                }
                            }}
                        >
                            {(isEditing, options, formik) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>
                                            <Translate id={this.props.fee.exclude_affiliation_fee == 1 ? 'misc.yes' : 'misc.no'} />
                                        </div>
                                    )
                                }
                                return (
                                    <FormikSelect
                                        name='exclude_affiliation_fee'
                                        id='exclude_affiliation_fee'
                                        search={false}
                                        autoFocus
                                        menuIsDefaultOpen
                                        onOptionSelected={options.stopEditing}
                                        defaultData={[
                                            {
                                                label: 'misc.yes',
                                                value: '1',
                                                translateLabel: true,
                                            },
                                            {
                                                label: 'misc.no',
                                                value: '0',
                                                translateLabel: true,
                                            },
                                        ]}
                                        loadingStatus='success'
                                    />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount" className="text-muted mb-0"><Translate id='catalog.registrationFees.sidePanel.form.regularFee' /></Label>
                        <FormikEditable
                            id="amount"
                            disabled={!canDoAction}
                            readOnly={readOnly}
                            initialValues={{
                                amount: (parseInt(this.props.fee.fee.amount) + parseInt(this.state.affiliation_fees.amount)) / 100,
                                fee: this.state.platformFee,
                                feeLoading: false,
                            }}
                            validationSchema={object().shape({
                                amount: number()
                                    .min(this.state.affiliation_fees.amount / 100, <Translate id='catalog.registrationFees.add.form.validation.registrationFee.min' />),
                            })}
                            onSubmit={(values) => {
                                this.setState(() => ({ loading: true }))

                                // Calculate amount considering affiliation fees
                                const amount = (values.amount * 100).toFixed(0) - this.state.affiliation_fees.amount

                                const newValues = this.props.panelProps.createNewValues({ fee: { ...this.props.fee.fee,
                                                                                                 amount: amount } });

                                this.props.RegistrationFeeContext.updateRegistrationFee(this.props.fee.registration_fee_id, { amount: amount })
                                    .then(() => {
                                        success({ msg: 'catalog.registrationFees.sidePanel.form.updated' });
                                        this.props.panelProps.syncRows(newValues);
                                        this.getFees();
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })
                                    .finally(() => this.setState(() => ({ loading: false })))
                            }}
                        >
                            {(isEditing, stopEditing, formik) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>
                                            <CurrencyFormat value={(parseInt(this.props.fee.fee.amount) + parseInt(this.state.affiliation_fees.amount)) / 100} />
                                            {this.hasPlatformFees &&
                                                <div className='small'>
                                                    +<Translate id='catalog.registrationFees.sidePanel.form.platformFees' />
                                                    <span className='ml-1'>
                                                        ({this.state.feesLoading ?
                                                            <Spinner className='mx-1' style={{ width: '0.8rem', height: '0.8rem' }} size='sm' color='primary' />
                                                            :
                                                            <CurrencyFormat value={this.state.platformFee / 100} />
                                                        })
                                                    </span>
                                                </div>
                                            }
                                            {this.props.fee.fee.tax_class &&
                                                <div className='small'>
                                                    <Translate id='catalog.registrationFees.sidePanel.form.tax' />
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                                return (
                                    <>
                                        <Row form className="align-items-center">
                                            <Col sm="4">
                                                <I18nContext.Consumer>
                                                    {({ getGenericLocale }) => (
                                                        <FormikInputNumber
                                                            manualError allowLeadingZeros fixedDecimalScale
                                                            name='amount'
                                                            id='amount'
                                                            allowNegative={false}
                                                            decimalScale={2}
                                                            thousandSeparator=' '
                                                            suffix={getGenericLocale() === 'fr' ? '$' : undefined}
                                                            prefix={getGenericLocale() !== 'fr' ? '$' : undefined}
                                                            decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                            onValueChange={(e) => {
                                                                if(this.hasPlatformFees){
                                                                    formik.setFieldValue('feeLoading', true)
                                                                    this.updateFeeOnChange((e.value * 100).toFixed(0))
                                                                        .then((fee) => {
                                                                            formik.setFieldValue('fee', fee)
                                                                            formik.setFieldValue('feeLoading', false)
                                                                        })
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </I18nContext.Consumer>
                                            </Col>
                                            <Col sm="8">
                                                <Collapse isOpen={!!formik.values.amount}>
                                                    <Row form>
                                                        {/* <Col xs="3" className="text-center d-sm-none">${formik.values.amount}.00</Col> */}
                                                        <Col xs="1" className="text-center">-</Col>
                                                        <Col xs="4" className="text-center"><CurrencyFormat value={this.state.affiliation_fees.amount / 100} /></Col>
                                                        <Col xs="1" className="text-center">=</Col>
                                                        <Col xs="4" className="text-center"><CurrencyFormat value={(formik.values.amount - this.state.affiliation_fees.amount / 100)} /></Col>
                                                        <Col xs="2">
                                                            <Tooltip zIndex={3000} withArrow label={<Translate id='catalog.registrationFees.add.form.registrationFee.help' />}>
                                                                <i className="mdi mdi-help-circle-outline text-primary mx-1" />
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                </Collapse>
                                            </Col>
                                            <Col sm="12">
                                                <div className="mb-2">
                                                    <FormikError name="amount" />
                                                </div>
                                            </Col>
                                        </Row>
                                        {this.hasPlatformFees &&
                                            <div className='small'>
                                                +<Translate id='catalog.registrationFees.sidePanel.form.platformFees' />
                                                <span className='ml-1'>
                                                    ({formik.values.feeLoading ?
                                                        <Spinner className='mx-1' style={{ width: '0.8rem', height: '0.8rem' }} size='sm' color='primary' />
                                                        :
                                                        <CurrencyFormat value={formik.values.fee ? formik.values.fee / 100 : 0} />
                                                    })
                                                </span>
                                            </div>
                                        }
                                        {this.props.fee.fee.tax_class &&
                                            <div className='small'>
                                                <Translate id='catalog.registrationFees.sidePanel.form.tax' />
                                            </div>
                                        }
                                    </>
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                    <FormGroup className="position-relative">
                        <Label for="earlyFee" className="text-muted mb-0"><Translate id='catalog.registrationFees.sidePanel.form.earlyFee' /></Label>
                        <FormikEditable
                            id="earlyFee"
                            disabled={!canDoAction}
                            readOnly={readOnly}
                            clearable
                            clearableFields={[ "early_amount", "earlyFeeDate", "earlyFeeTime" ]}
                            initialValues={{
                                early_amount: this.props.fee.fee.early_amount ? (parseInt(this.props.fee.fee.early_amount) + parseInt(this.state.affiliation_fees.amount)) / 100 : '',
                                earlyFeeDate: this.props.fee.fee.early_amount_until ? moment(this.props.fee.fee.early_amount_until) : '',
                                earlyFeeTime: this.props.fee.fee.early_amount_until ? moment(this.props.fee.fee.early_amount_until) : '',
                                lateFeeDate: this.props.fee.fee.late_amount_after ? moment(this.props.fee.fee.late_amount_after) : '',
                                fee: this.state.earlyPlatformFee,
                                feeLoading: false,
                            }}
                            validationSchema={object().shape({
                                early_amount: number().required(<Translate id="catalog.registrationFees.add.form.validation.registrationFee" />)
                                    .min(this.state.affiliation_fees.amount / 100, <Translate id='catalog.registrationFees.add.form.validation.registrationFee.min' />),
                                earlyFeeDate: mixed().when('early_amount', {
                                    is: (early_amount) => !!early_amount,
                                    then: mixed().required(<Translate id='catalog.registrationFees.sidePanel.form.earlyDate.validation' />)
                                        .test({
                                            name: 'earlyFeeDateTest',
                                            message: <Translate id='form.validation.date.valid' />,
                                            test: moment.isMoment,
                                        }),
                                }),
                                earlyFeeTime: mixed().when('earlyFeeDate', {
                                    is: (earlyFeeDate) => moment.isMoment(earlyFeeDate),
                                    then: mixed().required(<Translate id='catalog.registrationFees.sidePanel.form.earlyDate.validation' />)
                                        .test({
                                            name: 'earlyFeeTimeTest',
                                            message: <Translate id='form.validation.time.valid' />,
                                            test: moment.isMoment,
                                        })
                                        .test({
                                            name: 'earlyFeeTimeTestBefore',
                                            message: <Translate id='catalog.registrationFees.add.form.validation.earlyDateBefore' />,
                                            test: function(earlyFeeTime){
                                                if(!moment.isMoment(this.parent.lateFeeDate)){
                                                    return true
                                                }
                                                if(this.parent.earlyFeeDate && earlyFeeTime &&
                                                        moment.isMoment(earlyFeeTime) && moment.isMoment(this.parent.earlyFeeDate) && moment.isMoment(this.parent.lateFeeDate) &&
                                                        this.parent.earlyFeeDate.set({ 'hour': earlyFeeTime.hour(), 'minute': earlyFeeTime.minute() }).isBefore(this.parent.lateFeeDate)
                                                ){
                                                    return true
                                                }
                                                return false


                                            },
                                        }),
                                }),
                            })}
                            onSubmit={(values) => {
                                this.setState(() => ({ loading: true }))
                                // Verify if we want to clear
                                if(values.early_amount === "" && values.earlyFeeDate === "" && values.earlyFeeTime === ""){
                                    const newValues = this.props.panelProps.createNewValues({ fee: { ...this.props.fee.fee, early_amount: "", early_amount_until: "" } });
                                    this.props.RegistrationFeeContext.updateRegistrationFee(this.props.fee.registration_fee_id, { early_amount: '', early_amount_until: '' })
                                        .then(() => {
                                            success({ msg: 'catalog.registrationFees.sidePanel.form.updated' });
                                            this.props.panelProps.syncRows(newValues);
                                            this.getFees();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                        .finally(() => this.setState(() => ({ loading: false })))
                                }else{
                                    let earlyDate = moment.isMoment(values.earlyFeeDate) ? values.earlyFeeDate.format('YYYY-MM-DD') : moment(values.earlyFeeDate).format('YYYY-MM-DD');
                                    const earlyFeeTime = moment.isMoment(values.earlyFeeTime) ? values.earlyFeeTime.format('YYYY-MM-DD HH:mm') : moment(values.earlyFeeTime).format('YYYY-MM-DD HH:mm');
                                    earlyDate = moment(earlyDate).set({ 'hour': moment(earlyFeeTime).hour(), 'minute': moment(earlyFeeTime).minute() }).toISOString();

                                    if(values.early_amount !== this.props.fee.fee.early_amount || earlyDate !== this.props.fee.fee.early_amount_until){
                                        this.setState(() => ({ loading: true }))
                                        const newAmount = values.early_amount === '' ? null : (values.early_amount * 100).toFixed(0) - this.state.affiliation_fees.amount
                                        let newDate = '';
                                        if(newAmount){
                                            newDate = earlyDate;
                                        }
                                        const newValues = this.props.panelProps.createNewValues({ fee: { ...this.props.fee.fee, early_amount: newAmount, early_amount_until: newDate } });

                                        this.props.RegistrationFeeContext.updateRegistrationFee(this.props.fee.registration_fee_id, { early_amount: newAmount ?? '', early_amount_until: newDate })
                                            .then(() => {
                                                success({ msg: 'catalog.registrationFees.sidePanel.form.updated' });
                                                this.props.panelProps.syncRows(newValues);
                                                this.getFees();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => this.setState(() => ({ loading: false })))
                                    }
                                }
                            }}
                        >
                            {(isEditing, stopEditing, formik) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>
                                            {this.props.fee.fee.early_amount_until ?
                                                <>
                                                    <CurrencyFormat value={(parseInt(this.props.fee.fee.early_amount) + parseInt(this.state.affiliation_fees.amount)) / 100} />
                                                    <span className="small ml-1">
                                                        <Translate id='catalog.registrationFees.sidePanel.form.earlyFee.hint' />
                                                        <span className='ml-1'><DateFormat className='ml-1' value={this.props.fee.fee.early_amount_until} /></span>
                                                        <span className='ml-1'><DateFormat className='ml-1' value={this.props.fee.fee.early_amount_until} format={'h:mm a'} /></span>
                                                    </span>
                                                    {this.hasPlatformFees &&
                                                        <div className='small'>
                                                            +<Translate id='catalog.registrationFees.sidePanel.form.platformFees' />
                                                            <span className='ml-1'>
                                                                ({this.state.feesLoading ?
                                                                    <Spinner className='mx-1' style={{ width: '0.8rem', height: '0.8rem' }} size='sm' color='primary' />
                                                                    :
                                                                    <CurrencyFormat value={this.state.earlyPlatformFee / 100} />
                                                                })
                                                            </span>
                                                        </div>
                                                    }
                                                    {this.props.fee.fee.tax_class &&
                                                        <div className='small'>
                                                            <Translate id='catalog.registrationFees.sidePanel.form.tax' />
                                                        </div>
                                                    }
                                                </>
                                                : '-'}
                                        </div>
                                    )
                                }
                                return (
                                    <>
                                        <Row form className="mb-2 align-items-center">
                                            <Col sm="4">
                                                <I18nContext.Consumer>
                                                    {({ getGenericLocale }) => (
                                                        <FormikInputNumber
                                                            manualError allowLeadingZeros fixedDecimalScale
                                                            name='early_amount'
                                                            id='early_amount'
                                                            placeholder='catalog.registrationFees.sidePanel.form.earlyFee.placeholder'
                                                            translatePlaceholder
                                                            allowNegative={false}
                                                            decimalScale={2}
                                                            thousandSeparator=' '
                                                            suffix={getGenericLocale() === 'fr' ? '$' : undefined}
                                                            prefix={getGenericLocale() !== 'fr' ? '$' : undefined}
                                                            decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                            onValueChange={(e) => {
                                                                if(this.hasPlatformFees){
                                                                    formik.setFieldValue('feeLoading', true)
                                                                    this.updateFeeOnChange((e.value * 100).toFixed(0))
                                                                        .then((fee) => {
                                                                            formik.setFieldValue('fee', fee)
                                                                            formik.setFieldValue('feeLoading', false)
                                                                        })
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </I18nContext.Consumer>
                                            </Col>
                                            <Col sm="8">
                                                <Collapse isOpen={!!formik.values.early_amount}>
                                                    <Row form>
                                                        {/* <Col xs="3" className="text-center d-sm-none">${formik.values.amount}.00</Col> */}
                                                        <Col xs="1" className="text-center">-</Col>
                                                        <Col xs="4" className="text-center"><CurrencyFormat value={this.state.affiliation_fees.amount / 100} /></Col>
                                                        <Col xs="1" className="text-center">=</Col>
                                                        <Col xs="4" className="text-center"><CurrencyFormat value={(formik.values.early_amount - this.state.affiliation_fees.amount / 100)} /></Col>
                                                        <Col xs="2">
                                                            <Tooltip zIndex={3000} withArrow label={<Translate id='catalog.registrationFees.add.form.registrationFee.help' />}>
                                                                <i className="mdi mdi-help-circle-outline text-primary mx-1" />
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                        {this.hasPlatformFees &&
                                            <div className='small mb-1'>
                                                +<Translate id='catalog.registrationFees.sidePanel.form.platformFees' />
                                                <span className='ml-1'>
                                                    ({formik.values.feeLoading ?
                                                        <Spinner className='mx-1' style={{ width: '0.8rem', height: '0.8rem' }} size='sm' color='primary' />
                                                        :
                                                        <CurrencyFormat value={formik.values.fee ? formik.values.fee / 100 : 0} />
                                                    })
                                                </span>
                                            </div>
                                        }
                                        {this.props.fee.fee.tax_class &&
                                            <div className='small'>
                                                <Translate id='catalog.registrationFees.sidePanel.form.tax' />
                                            </div>
                                        }
                                        <Row form className="mb-5">
                                            <Col sm="6">
                                                <FormikDateTime
                                                    manualError
                                                    name='earlyFeeDate'
                                                    id='earlyFeeDate'
                                                    timeFormat={false}
                                                    inputProps={{ disabled: formik.values.early_amount === '' && formik.values.earlyFeeDate === '' }}
                                                    isValidDate={(current) => {
                                                        if(this.props.fee.fee.late_amount_after){
                                                            return moment(current).isBefore(this.props.fee.fee.late_amount_after)
                                                        }
                                                        return true

                                                    }}
                                                />
                                            </Col>
                                            <Col sm="6">
                                                <FormikDateTime
                                                    manualError
                                                    name='earlyFeeTime'
                                                    id='earlyFeeTime'
                                                    inputProps={{ disabled: formik.values.early_amount === '' && formik.values.earlyFeeTime === '' }}
                                                    dateFormat={false}
                                                    timeFormat={'h:mm A'}
                                                    isValidDate={(current) => {
                                                        if(this.props.fee.fee.late_amount_after){
                                                            return moment(formik.values.earlyFeeDate).set({ 'hour': current.hour(), 'minute': current.minute() }).isBefore(this.props.fee.fee.late_amount_after)
                                                        }
                                                        return true

                                                    }}
                                                />
                                            </Col>
                                            <Col sm="12">
                                                <div className="mb-2">
                                                    <FormikError name="early_amount" />
                                                    <FormikError name="earlyFeeTime" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                    <FormGroup>
                        <Label for="lateFee" className="text-muted mb-0"><Translate id='catalog.registrationFees.sidePanel.form.lateFee' /></Label>
                        <FormikEditable
                            id="lateFee"
                            disabled={!canDoAction}
                            readOnly={readOnly}
                            clearable
                            clearableFields={[ "late_amount", "lateFeeDate", "lateFeeTime" ]}
                            initialValues={{
                                late_amount: this.props.fee.fee.late_amount ? (parseInt(this.props.fee.fee.late_amount) + parseInt(this.state.affiliation_fees.amount)) / 100 : '',
                                lateFeeDate: this.props.fee.fee.late_amount_after ? moment(this.props.fee.fee.late_amount_after) : '',
                                lateFeeTime: this.props.fee.fee.late_amount_after ? moment(this.props.fee.fee.late_amount_after) : '',
                                earlyFeeDate: this.props.fee.fee.early_amount_until ? moment(this.props.fee.fee.early_amount_until) : '',
                                fee: this.state.latePlatformFee,
                                feeLoading: false,
                            }}
                            validationSchema={object().shape({
                                late_amount: number().required(<Translate id="catalog.registrationFees.add.form.validation.registrationFee" />)
                                    .min(this.state.affiliation_fees.amount / 100, <Translate id='catalog.registrationFees.add.form.validation.registrationFee.min' />),
                                lateFeeDate: mixed().when('late_amount', {
                                    is: (late_amount) => !!late_amount,
                                    then: mixed().required(<Translate id='catalog.registrationFees.sidePanel.form.lateDate.validation' />)
                                        .test({
                                            name: 'lateFeeDateTest',
                                            message: <Translate id='form.validation.date.valid' />,
                                            test: moment.isMoment,
                                        }),
                                }),
                                lateFeeTime: mixed().when('lateFeeDate', {
                                    is: (lateFeeDate) => moment.isMoment(lateFeeDate),
                                    then: mixed().required(<Translate id='catalog.registrationFees.sidePanel.form.lateDate.validation' />).test({
                                        name: 'lateFeeTimeTest',
                                        message: <Translate id='form.validation.time.valid' />,
                                        test: moment.isMoment,
                                    }).test({
                                        name: 'lateFeeTimeTestBefore',
                                        message: <Translate id='catalog.registrationFees.add.form.validation.lateDateGreater' />,
                                        test: function(lateFeeTime){
                                            if(!moment.isMoment(this.parent.earlyFeeDate)){
                                                return true
                                            }
                                            if(this.parent.lateFeeDate && lateFeeTime &&
                                                    moment.isMoment(lateFeeTime) && moment.isMoment(this.parent.lateFeeDate) && moment.isMoment(this.parent.earlyFeeDate) &&
                                                    this.parent.lateFeeDate.set({ 'hour': lateFeeTime.hour(), 'minute': lateFeeTime.minute() }).isAfter(this.parent.earlyFeeDate)
                                            ){
                                                return true
                                            }
                                            return false


                                        },
                                    }),
                                }),
                            })}
                            onSubmit={(values) => {
                                this.setState(() => ({ loading: true }))
                                // Verify if we want to clear
                                if(values.late_amount === "" && values.lateFeeDate === "" && values.lateFeeTime === ""){
                                    const newValues = this.props.panelProps.createNewValues({ fee: { ...this.props.fee.fee, late_amount: "", late_amount_after: "" } });
                                    this.props.RegistrationFeeContext.updateRegistrationFee(this.props.fee.registration_fee_id, { late_amount: "", late_amount_after: "" })
                                        .then(() => {
                                            success({ msg: 'catalog.registrationFees.sidePanel.form.updated' });
                                            this.props.panelProps.syncRows(newValues);
                                            this.getFees();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                        .finally(() => this.setState(() => ({ loading: false })))
                                }else{
                                    let lateDate = moment.isMoment(values.lateFeeDate) ? values.lateFeeDate.format('YYYY-MM-DD') : moment(values.lateFeeDate).format('YYYY-MM-DD');
                                    const lateFeeTime = moment.isMoment(values.lateFeeTime) ? values.lateFeeTime.format('YYYY-MM-DD HH:mm') : moment(values.lateFeeTime).format('YYYY-MM-DD HH:mm');
                                    lateDate = moment(lateDate).set({ 'hour': moment(lateFeeTime).hour(), 'minute': moment(lateFeeTime).minute() }).toISOString();

                                    if(values.late_amount !== this.props.fee.fee.late_amount || lateDate !== this.props.fee.fee.late_amount_after){
                                        this.setState(() => ({ loading: true }))
                                        const newAmount = values.late_amount === '' ? null : (values.late_amount * 100).toFixed(0) - this.state.affiliation_fees.amount
                                        let newDate = '';
                                        if(newAmount){
                                            newDate = lateDate;
                                        }
                                        const newValues = this.props.panelProps.createNewValues({ fee: { ...this.props.fee.fee, late_amount: newAmount, late_amount_after: newDate } });

                                        this.props.RegistrationFeeContext.updateRegistrationFee(this.props.fee.registration_fee_id, { late_amount: newAmount ?? '', late_amount_after: newDate })
                                            .then(() => {
                                                success({ msg: 'catalog.registrationFees.sidePanel.form.updated' });
                                                this.props.panelProps.syncRows(newValues);
                                                this.getFees();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => this.setState(() => ({ loading: false })))
                                    }
                                }
                            }}
                        >
                            {(isEditing, stopEditing, formik) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>
                                            {this.props.fee.fee.late_amount_after ?
                                                <>
                                                    <CurrencyFormat value={(parseInt(this.props.fee.fee.late_amount) + parseInt(this.state.affiliation_fees.amount)) / 100} />
                                                    <span className="small ml-1">
                                                        <Translate id='catalog.registrationFees.sidePanel.form.lateFee.hint' />
                                                        <span className='ml-1'><DateFormat value={this.props.fee.fee.late_amount_after} /></span>
                                                        <span className='ml-1'><DateFormat value={this.props.fee.fee.late_amount_after} format={'h:mm a'} /></span>
                                                    </span>
                                                    {this.hasPlatformFees &&
                                                        <div className='small'>
                                                            +<Translate id='catalog.registrationFees.sidePanel.form.platformFees' />
                                                            <span className='ml-1'>
                                                                ({this.state.feesLoading ?
                                                                    <Spinner className='mx-1' style={{ width: '0.8rem', height: '0.8rem' }} size='sm' color='primary' />
                                                                    :
                                                                    <CurrencyFormat value={this.state.latePlatformFee / 100} />
                                                                })
                                                            </span>
                                                        </div>
                                                    }
                                                    {this.props.fee.fee.tax_class &&
                                                        <div className='small'>
                                                            <Translate id='catalog.registrationFees.sidePanel.form.tax' />
                                                        </div>
                                                    }
                                                </>
                                                : '-'}
                                        </div>
                                    )
                                }
                                return (
                                    <>
                                        <Row form className="mb-2 align-items-center">
                                            <Col sm="4">
                                                <I18nContext.Consumer>
                                                    {({ getGenericLocale }) => (
                                                        <FormikInputNumber
                                                            manualError allowLeadingZeros fixedDecimalScale
                                                            name='late_amount'
                                                            id='late_amount'
                                                            placeholder='catalog.registrationFees.sidePanel.form.lateFee.placeholder'
                                                            translatePlaceholder
                                                            allowNegative={false}
                                                            decimalScale={2}
                                                            thousandSeparator=' '
                                                            suffix={getGenericLocale() === 'fr' ? '$' : undefined}
                                                            prefix={getGenericLocale() !== 'fr' ? '$' : undefined}
                                                            decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                            onValueChange={(e) => {
                                                                if(this.hasPlatformFees){
                                                                    formik.setFieldValue('feeLoading', true)
                                                                    this.updateFeeOnChange((e.value * 100).toFixed(0))
                                                                        .then((fee) => {
                                                                            formik.setFieldValue('fee', fee)
                                                                            formik.setFieldValue('feeLoading', false)
                                                                        })
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </I18nContext.Consumer>
                                            </Col>
                                            <Col sm="8">
                                                <Collapse isOpen={!!formik.values.late_amount}>
                                                    <Row form>
                                                        {/* <Col xs="3" className="text-center d-sm-none">${formik.values.amount}.00</Col> */}
                                                        <Col xs="1" className="text-center">-</Col>
                                                        <Col xs="4" className="text-center"><CurrencyFormat value={this.state.affiliation_fees.amount / 100} /></Col>
                                                        <Col xs="1" className="text-center">=</Col>
                                                        <Col xs="4" className="text-center"><CurrencyFormat value={(formik.values.late_amount - this.state.affiliation_fees.amount / 100)} /></Col>
                                                        <Col xs="2">
                                                            <Tooltip zIndex={3000} withArrow label={<Translate id='catalog.registrationFees.add.form.registrationFee.help' />}>
                                                                <i className="mdi mdi-help-circle-outline text-primary mx-1" />
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                        {this.hasPlatformFees &&
                                            <div className='small mb-1'>
                                                +<Translate id='catalog.registrationFees.sidePanel.form.platformFees' />
                                                <span className='ml-1'>
                                                    ({formik.values.feeLoading ?
                                                        <Spinner className='mx-1' style={{ width: '0.8rem', height: '0.8rem' }} size='sm' color='primary' />
                                                        :
                                                        <CurrencyFormat value={formik.values.fee ? formik.values.fee / 100 : 0} />
                                                    })
                                                </span>
                                            </div>
                                        }
                                        {this.props.fee.fee.tax_class &&
                                            <div className='small'>
                                                <Translate id='catalog.registrationFees.sidePanel.form.tax' />
                                            </div>
                                        }
                                        <Row form className="mb-5">
                                            <Col sm="6">
                                                <FormikDateTime
                                                    manualError
                                                    name='lateFeeDate'
                                                    id='lateFeeDate'
                                                    timeFormat={false}
                                                    inputProps={{ disabled: formik.values.late_amount === '' && formik.values.lateFeeDate === '' }}
                                                    isValidDate={(current) => {
                                                        if(this.props.fee.fee.late_amount_after){
                                                            return moment(current).isAfter(this.props.fee.fee.early_amount_until)
                                                        }
                                                        return true

                                                    }}
                                                />
                                            </Col>
                                            <Col sm="6">
                                                <FormikDateTime
                                                    manualError
                                                    name='lateFeeTime'
                                                    id='lateFeeTime'
                                                    inputProps={{ disabled: formik.values.late_amount === '' && formik.values.lateFeeTime === '' }}
                                                    dateFormat={false}
                                                    timeFormat={'h:mm A'}
                                                    isValidDate={(current) => {
                                                        if(this.props.fee.fee.late_amount_after){
                                                            return moment(formik.values.lateFeeDate).set({ 'hour': current.hour(), 'minute': current.minute() }).isBefore(this.props.fee.fee.early_amount_until)
                                                        }
                                                        return true

                                                    }}
                                                />
                                            </Col>
                                            <Col sm="12">
                                                <div className="mb-2">
                                                    <FormikError name="late_amount" />
                                                    <FormikError name="lateFeeTime" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                    <Row>
                        <Col sm="6">
                            <div className="mb-3">
                                <Label for="taxable" className="text-muted mb-0"><Translate id='catalog.registrationFees.add.form.taxable' /></Label>
                                <FormikEditable
                                    id="taxable"
                                    noConfirmation
                                    disabled={!canDoAction}
                                    // readOnly={readOnly}
                                    initialValues={{
                                        taxable: this.props.fee.fee.tax_class ? '1' : '0',
                                    }}
                                    onSubmit={async(values) => {
                                        const taxable = values.taxable == 1
                                        if(taxable !== !!this.props.fee.fee.tax_class){
                                            this.setState(() => ({ loading: true }))
                                            const taxClasses = taxable
                                                ?
                                                await this.props.UtilsContext.getTaxClasses()
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                                :
                                                [];
                                            const taxClass = taxClasses.filter((taxClass) => taxClass.active == '1')[0];
                                            const newValues = this.props.panelProps.createNewValues({ fee: { ...this.props.fee.fee, tax_class: taxable ? taxClass.tax_class_id : null } })

                                            await this.props.RegistrationFeeContext.updateRegistrationFee(this.props.fee.registration_fee_id, { tax_class_id: taxable ? taxClass.tax_class_id : "" })
                                                .then(() => {
                                                    success({ msg: 'catalog.registrationFees.sidePanel.form.updated' });
                                                    this.props.panelProps.syncRows(newValues);
                                                    this.props.refreshFee?.(); // for online store items table sidepanel
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                                .finally(() => this.setState(() => ({ loading: false })))
                                        }
                                    }}
                                >
                                    {(isEditing, options, formik) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>
                                                    <Translate id={this.props.fee.fee.tax_class ? 'misc.yes' : 'misc.no'} />
                                                </div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                name='taxable'
                                                id='taxable'
                                                search={false}
                                                autoFocus
                                                menuIsDefaultOpen
                                                onOptionSelected={(values) => {
                                                    formik.setFieldValue('taxable', values[0], false)
                                                        .then(options.stopEditing)
                                                    return true
                                                }}
                                                defaultData={[
                                                    {
                                                        label: 'misc.yes',
                                                        value: '1',
                                                        translateLabel: true,
                                                    },
                                                    {
                                                        label: 'misc.no',
                                                        value: '0',
                                                        translateLabel: true,
                                                    },
                                                ]}
                                                loadingStatus='success'
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className='mb-3'>
                                <Label for="taxReceipt" className="text-muted mb-0"><Translate id='catalog.registrationFees.sidePanel.form.taxReceipt' /></Label>
                                <FormikEditable
                                    id='receipt'
                                    disabled={true || !canDoAction}
                                    readOnly={readOnly}
                                    // noConfirmation
                                    initialValues={{
                                        taxReceipt: '0',
                                    }}
                                >
                                    {(isEditing) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>
                                                    <Translate id='catalog.registrationFees.sidePanel.form.taxReceipt.no' />
                                                </div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                name='taxReceipt' id='taxReceipt'
                                                defaultData={[
                                                    { value: '0', label: 'None Taxable' },
                                                ]}
                                                loadingStatus='success'
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </div>
                        </Col>
                    </Row>
                    <I18nContext.Consumer>
                        {({ getGenericLocale }) => (
                            <>
                                {getAdditionalFieldsFormikEditableRender(
                                    this.getFormGroups(),
                                    'fields',
                                    this.getAdditionalFields(),
                                    readOnly,
                                    null,
                                    (values) => {
                                        return this.props.RegistrationFeeContext.updateRegistrationFee(
                                            this.props.fee.registration_fee_id,
                                            { fields: getFormattedAdditionalFields(values, this.getFormGroups(), getGenericLocale()) },
                                        ).then(() => {
                                            success({ msg: 'catalog.registrationFees.sidePanel.form.updated' });
                                            this.props.refreshSidepanel();
                                            this.setState(() => ({ loading: false }))
                                        })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                                this.setState(() => ({ loading: false }))
                                            })
                                    },
                                )}
                            </>
                        )}
                    </I18nContext.Consumer>
                </OverlayLoader>
            </div>
        );
    }
}

export default withContexts(OrganizationContext, RegistrationFeeContext, I18nHelperContext, RolesContext, PeriodsContext, UtilsContext, MerchantAccountContext)(RegistrationFeesSidepanelFee);