import { Badge } from "reactstrap";
import { DisplayI18n } from "../../../helpers/i18nHelper";

const CompetitionTeamStatus = ({ status, children, className }) => {
    if(!status){
        return "-";
    }

    const getColor = (code) => {
        switch (code){
            case 'DECLINED':
                return 'danger';
            case 'CONFIRMED':
            case 'APPROVED':
                return 'success';
            case 'PENDING':
                return 'warning';
            default:
                return 'light';
        }
    }
    const color = getColor(status.system);

    return (
        <Badge color={color} className={className}>
            <DisplayI18n
                field="name"
                i18n={status.i18n}
                defaultValue={status.name}
            />
            {children}
        </Badge>
    );
}

export default CompetitionTeamStatus;