import { captureEvent, withScope, Severity } from '@sentry/react';
import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import Skeleton from 'react-loading-skeleton';
import { Card, CardBody } from 'reactstrap';
import CustomAlert from '../../CustomAlert';
import WorkflowStart from './graphComponents/WorkflowStart';
import WorkflowStep from './graphComponents/WorkflowStep';
import { transferStatuses } from '../../../helpers/constants';
import moment from 'moment';
import { DisplayI18n } from '../../../helpers/i18nHelper';

const TransferRequestGraph = ({ currentStep, transfer, states, workflow, isLoading, hasError }) => {
    const isOutsideCanadaTransfer = transfer.transfer_type === 'USA' || transfer.transfer_type === 'INTERNATIONAL';
    return (
        isLoading ? // loading
            <Card className="card-shadow">
                <CardBody>
                    {/* Outside Canada transfer only have 2 steps */}
                    <Skeleton count={isOutsideCanadaTransfer ? 2 : 5} height={55} className='my-2' />
                </CardBody>
            </Card>
            : hasError ? // error
                <div className="text-center">
                    <div className="h4"><Translate id='misc.error' /></div>
                </div>
                : // success
                // displayWorkflow()
                <Workflow currentStep={currentStep} states={states} transfer={transfer} workflow={workflow} />
    );
};

const Workflow = ({ currentStep, transfer, states, workflow }) => {
    const fullWorkflow = workflow[0];
    const isRefused = transfer.transfer_request_state === 'CLOSED' && (!!states && states.findIndex((state) => state.workflow_step.step_action === 'DECLINE') !== -1);
    const overrideApproved = transfer.transfer_status.transfer_status_id === transferStatuses.approvedOverride;
    const overrideRejected = transfer.transfer_status.transfer_status_id === transferStatuses.rejectedOverride;

    const getOverrideAlertText = () => {
        const overrideStep = states?.[states.length - 1];

        return overrideStep ? (
            <>
                <div>{overrideStep?.identity?.name + ' ' + overrideStep?.identity?.family_name + ' - ' + moment(overrideStep?.updated_at).format('lll')}</div>
                <div><DisplayI18n field='name' defaultValue={overrideStep?.organisation?.name} i18n={overrideStep?.organisation?.i18n} /></div>
            </>
        ) : null
    }

    return (
        fullWorkflow.workflow_steps ?
            <Card className="card-shadow">
                <CardBody>
                    {(overrideApproved || overrideRejected) &&
                        <CustomAlert
                            className='mt-3'
                            color={overrideApproved ? 'success' : overrideRejected ? 'danger' : ''}
                            withTitle
                            translateText={false}
                            title={overrideApproved ? 'transfer.sidepanel.workflow.override.approved' : overrideRejected ? 'transfer.sidepanel.workflow.override.rejected' : ''} //Transfer Override (Rejcted)
                            text={getOverrideAlertText()}
                        />
                    }

                    <div className={stringBuilder('d-table my-n2', { 'requestBar-refused': isRefused })}>
                        {Object.values(fullWorkflow.workflow_steps || {}).sort((stepA, stepB) => parseInt(stepA[0].step) - parseInt(stepB[0].step))
                            .map((step, index) => {
                                switch (step[0].step_action){
                                    case 'START':
                                        if(step[0].step == 1){
                                            return (
                                                <WorkflowStart
                                                    key='START'
                                                    currentOrg={step[0].organisation || {}}
                                                    requestedDate={transfer.created_at}
                                                    isLastAction={!states?.length ?? false}
                                                    identity={transfer.identity}
                                                />
                                            )
                                        }
                                        // Else, threat this as CURRENT
                                    case 'APPROVE':
                                    case 'DECLINE':
                                        const workflowState = states?.find((state) => state.workflow_step.step === step[0].step); // Has there been an action on this step
                                        if(workflowState){
                                            const isLastAction = states.findIndex((state) => state.workflow_step.step === step[0].step) === states.length - 1;
                                            return (
                                                <WorkflowStep
                                                    key={workflowState.member_transfer_approbation_state_id}
                                                    currentStep={currentStep}
                                                    step={workflowState.workflow_step.step}
                                                    resquestState={workflowState.workflow_step.step_action === 'APPROVE' ? 'APPROVED' : 'REFUSED'}
                                                    isLast={index === Object.values(fullWorkflow.workflow_steps).length - 1}
                                                    organization={workflowState.organisation}
                                                    identity={workflowState.identity}
                                                    date={workflowState.created_at}
                                                    isLastAction={isLastAction}
                                                />
                                            );
                                        }
                                        const approveOrganization = step.find((s) => s.step_action === 'APPROVE')?.organisation;
                                        return (
                                            <WorkflowStep
                                                key={step[0].step}
                                                currentStep={currentStep}
                                                step={step[0].step}
                                                resquestState={transfer.transfer_request_state}
                                                isLast={index === Object.values(fullWorkflow.workflow_steps).length - 1}
                                                organization={approveOrganization}
                                            />
                                        );

                                    default:
                                        withScope((scope) => {
                                            scope.setTag('workflow-step', step[0].applies_to_which);
                                            scope.setLevel(Severity.Log);
                                            captureEvent(new Error(`Transfer workflow: unhandled workflow step -> ${step[0].applies_to_which}`))
                                        })
                                        return null;
                                }
                            })}
                    </div>
                </CardBody>
            </Card>
            : null
    )
}

export default TransferRequestGraph;
