import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Collapse, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import IsAdmin from '../../../../../components/permissions/IsAdmin';
import { RolesContext } from '../../../../../contexts/RolesContext';
import { fail } from "@spordle/toasts";
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import ApplyPermissionsRoles from './ApplyPermissionsRoles';
import ApplyToSelects from './ApplyToSelects';
import CloneToOtherRoles from './CloneToOtherRoles';
import RoleListItem from './RoleListItem';
import InlineCopy from '../../../../../components/inlineCopy/InlineCopy';

/**
 * @param {object} cp
 * @param {string} cp.visible_in_menu
 */
export const isVisibleInMenu = (cp) => cp?.visible_in_menu === "1";

/**
 * If returns false, it means it's a module permission
 * @param {boolean|object[]} permissions
 * */
export const isRegularPermission = (permissions) => Array.isArray(permissions);

const RolePermissions = ({ permission, roleId, search, rolesList = [], tableRef, fromGlobalModule, ...props }) => {
    const [ searchQuery, setSearchQuery ] = useState('');

    const [ cloneToIsOpen, setCloneToIsOpen ] = useState(false);
    const [ cloneToPermission, setCloneToPermission ] = useState("");

    const [ selectedPermissionIsOpen, setSelectedPermissionIsOpen ] = useState(false);
    const [ selectedPermission, setSelectedPermission ] = useState("");

    const intl = useIntl();

    const roleContext = useContext(RolesContext);
    const formikRef = useRef(null);
    const moduleComponent = permission.component_permissions?.find(isVisibleInMenu) || {};

    const handleOnToggle = () => {
        props.toggle();
        setCloneToIsOpen(false);
        setCloneToPermission("");
        setSelectedPermissionIsOpen(false);
        setSelectedPermission("");
    }

    /** Permissions array sorted with sort index && filtered with the search query */
    const sortedFilteredRCP = permission.component_permissions?.sort((cpa, cpb) => {
        if(isVisibleInMenu(cpa)){ // visible_in_menu = flag to indicate entire Module
            return -1;
        }
        return parseInt(cpa.sort) - parseInt(cpb.sort);// sort by sort param
    })?.filter((componentPermission) => componentPermission.code.indexOf(searchQuery.toLowerCase()) !== -1 && componentPermission.component_permission_id !== moduleComponent.component_permission_id);

    useEffect(() => {
        setSearchQuery(search);
    }, [ search ]);

    return (
        <AnalyticsModal isOpen={!!permission} analyticsName='RolePermissions'>
            {!!permission &&
                <>
                    <Collapse isOpen={!selectedPermissionIsOpen && !cloneToIsOpen}>
                        <Formik
                            // initialValues={formatInitRC(permission.component_permissions)}
                            initialValues={permission.component_permissions.reduce((initialValues, componentPermission) => {
                                // Building the initialValues object.
                                // object format for entire module -> boolean
                                // object format -> { component_permission_id: {action: READ, role_component_permission_id: asdasdasdasdads} }
                                if(componentPermission.visible_in_menu === '1'){
                                    initialValues[componentPermission.component_permission_id] = (componentPermission.role_component_permissions[0]?.active === '1' ?? false);
                                }else{
                                    initialValues[componentPermission.component_permission_id] = componentPermission.role_component_permissions.map((rcp) => ({ action: rcp.action, role_component_permission_id: rcp.role_component_permission_id, active: rcp.active === '1' }));
                                }
                                return initialValues;
                            }, {})}
                            onSubmit={(values, { setSubmitting }) => {
                                const mappedValues = Object.keys(values).map((key) => {
                                    let formattedValue = [];

                                    if(Array.isArray(values[key])){ // Is a normal permission
                                        /*
                                        * This will retreive the action and the role_component_permission_id if there is one
                                        * We can then send this to the ContextAPI that expects such a format
                                        */
                                        formattedValue = values[key].map((value) => {
                                            if(typeof value === 'string'){
                                                // If it is not a string, it means it is equal to the initialValue i.e. an object
                                                const splittedValue = value.split('---');
                                                value = {
                                                    action: splittedValue[0],
                                                    role_component_permission_id: splittedValue[1],
                                                    active: true,
                                                }
                                            }
                                            return value;
                                        });
                                    }else{ // is a module permission
                                        formattedValue.push({
                                            action: 'READ',
                                            role_component_permission_id: moduleComponent.role_component_permissions[0]?.role_component_permission_id,
                                            active: values[key],
                                        })
                                    }

                                    return { key: key, value: formattedValue }
                                });

                                /*
                                    This section of the code detects the missing permission roles
                                    It adds them to the values because we need them to execute the update
                                */
                                Object.keys(formikRef.current.initialValues).forEach((key) => { // Creates an array with all the data from the api
                                    // Do nothing if is a module permission
                                    // Is a normal permission
                                    if(Array.isArray(formikRef.current.initialValues[key])){
                                        formikRef.current.initialValues[key].forEach((rcp) => {
                                            // Detects if it's missing or not
                                            const isMissing = values[key].findIndex((value) => {
                                                if(typeof value === 'string'){
                                                    // If it is not a string, it means it is equal to the initialValue i.e. an object
                                                    const splittedValue = value.split('---');
                                                    return splittedValue[1] === rcp.role_component_permission_id;
                                                }
                                                return value.role_component_permission_id === rcp.role_component_permission_id;
                                            }) === -1;

                                            if(isMissing){
                                                mappedValues.find((mappedValue) => mappedValue.key === key).value.push({
                                                    ...rcp,
                                                    active: false,
                                                })
                                            }
                                        });
                                    }
                                });

                                return roleContext.updateComponentsPermissions(roleId, mappedValues)
                                    .then(props.updateComponents)
                                    .then(props.toggle)
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message);
                                            fail({ info: <DisplayI18n field="message" defaultValue={error.message} i18n={error.i18n} />, skipInfoTranslate: true });
                                        }
                                    });
                            }}
                            innerRef={formikRef}
                        >
                            {(formik) => (
                                <OverlayLoader isLoading={formik.isSubmitting}>
                                    <ModalHeader toggle={handleOnToggle} className="border-bottom-0">
                                        <Translate id='organization.settings.roles.modals.title' values={{ permissionName: permission.title }} />
                                        <IsAdmin className='small text-monospace'>
                                            <InlineCopy toCopy={permission.code}>
                                                code: {permission.code}
                                            </InlineCopy>
                                        </IsAdmin>
                                    </ModalHeader>
                                    <Form>
                                        <div className="px-3 pb-3 border-bottom">
                                            <div className="search-wrapper">
                                                <input
                                                    className="form-control"
                                                    type="search"
                                                    placeholder={intl.formatMessage({ id: 'misc.search' })}
                                                    value={searchQuery}
                                                    onChange={(query) => setSearchQuery(() => (query.target.value))}
                                                />
                                                {!!searchQuery &&
                                                    <button className="reset-btn text-link search-clear" type="button" onClick={() => { setSearchQuery('') }}>
                                                        <i className="mdi mdi-close" />
                                                    </button>
                                                }
                                            </div>
                                            <ApplyToSelects permission={permission} moduleComponent={moduleComponent} />
                                        </div>
                                        <ModalBody>
                                            <RoleListItem
                                                key={moduleComponent.component_permission_id}
                                                className={'mb-4'}
                                                setSelectedPermission={(cpId) => {
                                                    setSelectedPermission({
                                                        id: cpId,
                                                        ...moduleComponent,
                                                        formikValue: formik.values[cpId],
                                                    });
                                                    setSelectedPermissionIsOpen(true);
                                                }}
                                                componentPermission={moduleComponent}
                                                moduleComponent={moduleComponent}
                                                fromGlobalModule={fromGlobalModule}
                                            />
                                            {sortedFilteredRCP
                                                .map((componentPermission, index) => (
                                                    <RoleListItem
                                                        key={componentPermission.component_permission_id}
                                                        className={stringBuilder({
                                                            'mb-4': index !== permission.component_permissions.length - 1,
                                                        })}
                                                        setSelectedPermission={(cpId) => {
                                                            setSelectedPermission({
                                                                id: cpId,
                                                                ...componentPermission,
                                                                formikValue: formik.values[cpId],
                                                            });
                                                            setSelectedPermissionIsOpen(true);
                                                        }}
                                                        componentPermission={componentPermission}
                                                        moduleComponent={moduleComponent}
                                                        fromGlobalModule={fromGlobalModule}
                                                    />
                                                ))
                                            }
                                        </ModalBody>
                                        <ModalFooter>
                                            {!fromGlobalModule &&
                                                <Button color='light' type='button' className='mr-auto' disabled={formik.isSubmitting} onClick={() => { setCloneToPermission(permission); setCloneToIsOpen(true); }}>
                                                    <Translate id='organization.settings.roles.modals.action.clone' /><i className='mdi mdi-content-duplicate ml-1' />
                                                </Button>
                                            }
                                            <Button color='primary' type='submit' disabled={formik.isSubmitting}>
                                                <Translate id='organization.settings.roles.modals.action.save' />
                                            </Button>
                                            <Button color='primary' type='button' outline onClick={handleOnToggle} disabled={formik.isSubmitting}>
                                                <Translate id='organization.settings.roles.modals.action.cancel' />
                                            </Button>
                                        </ModalFooter>
                                    </Form>
                                </OverlayLoader>
                            )}
                        </Formik>
                    </Collapse>
                    <Collapse isOpen={selectedPermissionIsOpen} mountOnEnter unmountOnExit onExited={() => setSelectedPermission("")}>
                        <ApplyPermissionsRoles
                            toggle={handleOnToggle}
                            allPermissions={sortedFilteredRCP}
                            mainPermission={permission}
                            selectedPermission={selectedPermission}
                            removeSelectedPermissionId={() => setSelectedPermissionIsOpen(false)}
                            roleId={roleId}
                            rolesList={rolesList}
                            updateComponents={props.updateComponents}
                        />
                    </Collapse>
                    <Collapse isOpen={cloneToIsOpen} mountOnEnter unmountOnExit onExited={() => setCloneToPermission("")}>
                        <CloneToOtherRoles
                            toggle={handleOnToggle}
                            mainPermission={permission}
                            roleId={roleId}
                            rolesList={rolesList}
                            updateComponents={props.updateComponents}
                            previous={() => { setCloneToIsOpen(false) }}
                            componentPermission={cloneToPermission}
                        />
                    </Collapse>
                </>
            }
        </AnalyticsModal>
    )
}

export default RolePermissions;
