export default{
    "catalog.rebates.title"                          : "Discounts",
    "catalog.rebates.form.type"                      : "Type",
    "catalog.rebates.form.title"                     : "Title",
    "catalog.rebates.form.status"                    : "Status",
    "catalog.rebates.form.promoCode"                 : "Promo Code",
    "catalog.rebates.form.mode"                      : "Mode",
    "catalog.rebates.form.rules"                     : "Rules",
    "catalog.rebates.form.addRules"                  : "Add Rules",
    "catalog.rebates.form.nbParticipant"             : "Apply to participant",
    "catalog.rebates.form.nbParticipant.postalCodes" : "Starting applying from participant",
    "catalog.rebates.form.nbParticipant.number"      : "Number",
    "catalog.rebates.form.discount"                  : "Discount",
    "catalog.rebates.form.postalCodes"               : "{number, plural, 0 {# postal codes} one {# postal code} other {# postal codes}}",
    "catalog.rebates.form.postalCodesLabel"          : "Postal codes",
    "catalog.rebates.form.editPostalCodes"           : "Edit postal codes",
    "catalog.rebates.form.expDate"                   : "Expiration date",

    "catalog.rebates.form.validation.title.required"                        : "Please enter a title.",
    "catalog.rebates.form.validation.promoCode.required"                    : "Please enter a promo code",
    "catalog.rebates.form.validation.promoValue.required"                   : "Please enter an amount or a percentage.",
    "catalog.rebates.form.validation.promoCode.existing"                    : "This promo code already exists. Please choose another.",
    "catalog.rebates.form.validation.promoValue.percent.exceed"             : "The value must not exceed 100%.",
    "catalog.rebates.form.validation.participantNumber.required"            : "Please indicate the number of people required for this discount.",
    "catalog.rebates.form.validation.participantNumber.min.required"        : "The number of people must be 2 or higher.",
    "catalog.rebates.form.validation.participantNumber.min.required.postal" : "The number of people must be 1 or higher.",
    "catalog.rebates.form.validation.participantNumber.nb.required"         : "Please indicate a positive whole number.",
    "catalog.rebates.form.validation.expDate.required"                      : "Please enter an expiration date.",
    "catalog.rebates.form.validation.postalCodes.required"                  : "Please add at least one postal code",

    "catalog.rebates.sidePanel.delete" : "You are about to delete this discount",

    "catalog.rebates.table.multi.rule.1" : "- ",
    "catalog.rebates.table.multi.rule.2" : " discount for participant #{nbParticipant}",
    "catalog.rebates.table.promo.rule.1" : "Discount by ",
    "catalog.rebates.table.promo.rule.2" : " when using {code}",
    "catalog.rebates.table.rebate"       : "Discount",
    "catalog.rebates.table.empty.title"  : "No discount",

    "catalog.rebates.modal.title" : "Add Discount",

    "catalog.rebates.type.multi"      : "Multi-Participant",
    "catalog.rebates.type.postalCode" : "Postal code",
    "catalog.rebates.type.promo"      : "Promo code",
}