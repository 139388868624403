import { stringBuilder } from "@spordle/helpers";
import { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import UserDisplay from "../userDisplay/UserDisplay";
import CollapseIcon from "./CollapseIcon";
import styles from "./CollapsibleCard.module.scss";

/**
 * @param {object} props
 * @param {React.ReactElement} props.extraInfo
 * @param {React.ReactElement} props.subtitle
 * @param {React.ReactElement} props.title
 * @param {React.ReactElement} props.img
 * @param {string} props.iconColor
 * @param {boolean} props.initOpen
 * @param {boolean} props.error
 * @param {string} props.className
 * @param {string} props.innerClassName
 * @param {string} props.collapsedSectionClassName
 * @param {boolean} props.arrowIsRight
 * @param {boolean} props.isDisabled
 * @param {boolean} props.mountChildrenOnEnter
 * @param {boolean} props.withPreload
 * @returns {React.ReactElement}
 */
const CollapsibleCard = ({
    isDisabled,
    tag,
    className,
    innerClassName,
    collapsedSectionClassName,
    error = false,
    title,
    img,
    subtitle,
    initOpen = false,
    children,
    extraInfo,
    iconColor,
    mountChildrenOnEnter,
    withPreload,
    arrowIsRight = false,
}) => {
    const [ collapseIsOpen, setCollapseIsOpen ] = useState(!isDisabled && initOpen);
    const [ preload, setPreload ] = useState(false);

    const Tag = tag || 'div';

    useEffect(() => {
        if(initOpen !== collapseIsOpen) setCollapseIsOpen(initOpen);
    }, [ initOpen ]);

    return (
        <Tag className={stringBuilder(className, { "border-danger": error }, error ? styles.Invalid : "shadow-sm", "bg-light-inverse transition border rounded-lg")}>
            <UserDisplay
                card
                block
                onClick={() => {
                    if(!isDisabled){
                        setCollapseIsOpen(!collapseIsOpen);
                    }
                }}
                onMouseEnter={() => {
                    if(withPreload){
                        setPreload(true);
                    }
                }}
                onMouseLeave={() => {
                    if(withPreload){
                        setPreload(false);
                    }
                }}
                className={
                    stringBuilder(
                        innerClassName,
                        "position-relative mb-0 transition border-0",
                        isDisabled ? "bg-light" : "clickable",
                    )
                }
            >
                {!arrowIsRight &&
                    <UserDisplay.Container className="mr-2 d-flex align-items-center justify-content-start" style={{ width: 40 }}>
                        <CollapseIcon isOpen={collapseIsOpen} iconColor={iconColor} />
                    </UserDisplay.Container>
                }
                { img &&
                    <UserDisplay.Container>
                        {img}
                    </UserDisplay.Container>
                }
                <UserDisplay.Container className="mr-auto">
                    <UserDisplay.Title className={error ? "text-danger" : undefined}>{title}</UserDisplay.Title>
                    {subtitle &&
                        <UserDisplay.Subtitle>
                            {subtitle}
                        </UserDisplay.Subtitle>
                    }
                </UserDisplay.Container>
                {arrowIsRight &&
                    <UserDisplay.Container className="d-flex align-items-center justify-content-end ml-auto" style={{ width: 40 }}>
                        <CollapseIcon isOpen={collapseIsOpen} iconColor={iconColor} />
                    </UserDisplay.Container>
                }
                {extraInfo}
            </UserDisplay>
            <Collapse isOpen={collapseIsOpen} mountOnEnter={withPreload ? undefined : mountChildrenOnEnter} unmountOnExit={withPreload ? undefined : mountChildrenOnEnter}>
                <div className={`${collapsedSectionClassName || 'p-3'}`}>
                    {withPreload ? preload || collapseIsOpen ? children : undefined : children}
                </div>
            </Collapse>
        </Tag>
    );
}

export default CollapsibleCard;