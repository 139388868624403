import account from "./account";
import clinics from "./clinics";
import leagues from "./leagues";
import organization from "./organization";
import hcrGuide from "./hcrGuide";
import members from "./members";
import reports from "./reports";
import teams from "./teams";
import settings from "./settings";
import custom from "./custom";
import catalog from "./catalog";
import onlineStore from "./onlineStore";
import tasks from "./tasks";
import maintenance from "./maintenance";
import dashboard from "./dashboard";
import communications from "./communications";
import news from "./news";

export default{
    ...account,
    ...clinics,
    ...communications,
    ...leagues,
    ...organization,
    ...hcrGuide,
    ...members,
    ...maintenance,
    ...news,
    ...reports,
    ...teams,
    ...settings,
    ...custom,
    ...catalog,
    ...onlineStore,
    ...tasks,
    ...dashboard,
}