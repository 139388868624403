import React from "react";
import {
    Row,
    Col,
    Fade,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Spinner,
    FormGroup,
    Label
} from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import FormikEditable from '../../../../components/formik/FormikEditable';
import { FormikDateTime, FormikError, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import * as Yup from 'yup'
import { success, fail } from '@spordle/toasts';
import PropTypes from 'prop-types';
import moment from 'moment';
import { initSidePanelViewI18n, getFormikEditableLabel, DisplayI18n } from '../../../../helpers/i18nHelper';

// Context
import { I18nContext } from '../../../../contexts/I18nContext';
import { PeriodsContext } from '../../../../contexts/contexts';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import withContexts from '../../../../helpers/withContexts';

// Language
import Translate from "@spordle/intl-elements";
import SidePanel from "../../../../components/sidePanel/SidePanel";
import UserImg from "../../../../components/UserImg";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";

class SidePanelOrganizationPeriods extends React.Component{

    state = {
        loadingState: 'lazy',
        activeTab: '1',
    }

    toggleTab = (tab) => {
        if(this.state.activeTab !== tab){
            this.setState({ activeTab: tab });
        }
    }

    updateI18nInfo = () => {
        this.i18n = initSidePanelViewI18n(this.props.i18n.languages, 'name', this.props.getCommonValue('i18n'));
    }

    componentDidMount(){
        this.updateI18nInfo();
        this.setState({ loadingState: 'success' })
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedRows !== this.props.selectedRows){
            this.setState(() => ({ loadingState: 'lazy' }))
            this.updateI18nInfo();
            this.setState(() => ({ loadingState: 'success' }))
        }
    }

    render(){
        return (
            <>
                {this.state.loadingState === 'lazy' ?
                    <Fade in={this.state.loadingState === 'lazy'}>
                        <div className='d-flex justify-content-center p-5'>
                            <Spinner type='grow' color='primary' />
                        </div>
                    </Fade>
                    : this.state.loadingState === 'success' ?
                        <Fade in={this.state.loadingState === 'success'}>
                            <SidePanel.Header noBorder>
                                <div className='d-flex mb-2 align-items-center'>
                                    <SidePanel.ToggleButton />
                                    <SidePanel.ActionsMenu action='DELETE' componentCode='settings' componentPermissionCode='organization_periods'>
                                        <SidePanel.MenuDelete
                                            translateModalMsg
                                            modalMsg='organization.settings.sidePanelOrganizationPeriods.actions.remove.topText'
                                            modalContent={
                                                <Row className='mt-3'>
                                                    <Col md='6' className='d-flex align-items-center justify-content-center flex-column border-right'>
                                                        <div className='font-medium'>
                                                            <DisplayI18n
                                                                field="name"
                                                                i18n={this.props.getCommonValue('i18n')}
                                                                defaultValue={this.props.getCommonValue('name')}
                                                            />
                                                        </div>
                                                        <I18nContext.Consumer>
                                                            {({ getGenericLocale }) => (
                                                                <div className='small'>{moment(this.props.getCommonValue('start_date')).locale(getGenericLocale()).format('LL')} - {moment(this.props.getCommonValue('end_date')).locale(getGenericLocale()).format('LL')}</div>
                                                            )}
                                                        </I18nContext.Consumer>
                                                    </Col>
                                                    <Col md='6' className='d-flex align-items-center justify-content-center flex-column'>
                                                        <div className='mr-2'>
                                                            <UserImg
                                                                filePos={this.props.OrganizationContext.logo?.file_position}
                                                                src={this.props.OrganizationContext.logo?.full_path}
                                                                alt={this.props.OrganizationContext.organisation_name}
                                                                abbr={this.props.OrganizationContext.abbreviation}
                                                            />
                                                        </div>
                                                        <div className='font-medium'>{this.props.OrganizationContext.organisation_name}</div>
                                                    </Col>
                                                </Row>
                                            }
                                        />
                                    </SidePanel.ActionsMenu>
                                </div>
                                <SidePanel.Title>
                                    <DisplayI18n
                                        field='name'
                                        defaultValue={this.props.getCommonValue('name')}
                                        i18n={this.props.getCommonValue('i18n')}
                                    />
                                </SidePanel.Title>
                            </SidePanel.Header>

                            <Nav tabs>
                                <NavItem className="flex-grow-1 text-center w-100">
                                    <NavLink
                                        className={stringBuilder({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggleTab('1'); }}
                                    >
                                        <Translate id='organization.settings.sidePanelOrganizationPeriods.tabs.details' />
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    {/* PERIOD INFO */}
                                    <div className='p-3 border-bottom'>
                                        <div className="h4 font-bold mb-3"><Translate id='organization.settings.sidePanelOrganizationPeriods.title' /></div>

                                        <I18nContext.Consumer>
                                            {({ getGenericLocale }) => (
                                                <>
                                                    {this.i18n.languages.map((lang, index) => (
                                                        <Row key={lang}>
                                                            <Col md='12'>
                                                                <div className='mb-3'>
                                                                    <div className='text-muted'>{getFormikEditableLabel(this.i18n.languages.length > 1, lang, 'name')}</div>
                                                                    <FormikEditable
                                                                        id={index + lang}
                                                                        initialValues={this.i18n.initialValues[lang]}
                                                                        validationSchema={this.i18n.validationSchema[lang]}
                                                                        onSubmit={(values) => {
                                                                            this.setState({ loadingState: 'lazy' });

                                                                            this.i18n.initialValues[lang] = values;
                                                                            const newValues = this.props.createNewValues({ i18n: this.i18n.initialValues });

                                                                            this.props.PeriodsContext.updatePeriod(this.props.getCommonValue('period_id'), values, lang, this.i18n)
                                                                                .then(() => {
                                                                                    this.props.syncRows(newValues);
                                                                                    success();
                                                                                    this.setState({ loadingState: 'success' });
                                                                                })
                                                                                .catch((error) => {
                                                                                    if(!AxiosIsCancelled(error.message)){
                                                                                        fail({
                                                                                            msg: 'misc.error',
                                                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                            skipInfoTranslate: true,
                                                                                        })
                                                                                        console.error(error.message)
                                                                                        this.setState({ loadingState: 'success' });
                                                                                    }
                                                                                })
                                                                        }}
                                                                    >
                                                                        {(isEditing) => {
                                                                            if(!isEditing){
                                                                                return (
                                                                                    this.i18n.initialValues[lang].name ? this.i18n.initialValues[lang].name : '-'
                                                                                )
                                                                            }
                                                                            return (
                                                                                <FormikInputText id='name' name='name' trim />
                                                                            )

                                                                        }}
                                                                    </FormikEditable>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    ))}


                                                    {/* START DATE & END DATE */}
                                                    <div className='mb-3'>
                                                        <div className='text-muted'><Translate id='organization.settings.sidePanelOrganizationPeriods.dates' /></div>
                                                        <FormikEditable
                                                            id="start_end_date_form"
                                                            manualIcon
                                                            initialValues={{
                                                            // TODO
                                                            // the 'invalid format' problem [probably] comes from here, the date format from the API isn't valid -> waiting for ISO API date format to fix
                                                                start_date: moment(this.props.getCommonValue('start_date')).format('YYYY-MM-DD'),
                                                                end_date: moment(this.props.getCommonValue('end_date')).format('YYYY-MM-DD'),
                                                            }}
                                                            validationSchema={Yup.object().shape({
                                                                start_date: Yup.mixed()
                                                                    .test({
                                                                        name: 'start_date_format',
                                                                        message: <Translate id='organization.settings.sidePanelOrganizationPeriods.startDate.format' />,
                                                                        test: (value) => moment.isMoment(value),
                                                                    })
                                                                    .when('end_date', {
                                                                        is: (end_date) => moment.isMoment(end_date),
                                                                        then: Yup.mixed()
                                                                            .test({
                                                                                name: 'start_date_end_date_check',
                                                                                message: <Translate id='organization.settings.sidePanelOrganizationPeriods.startDate.before' />,
                                                                                test: function(value){ return moment(value).isBefore(this.parent.end_date) },
                                                                            }),
                                                                    }),
                                                                end_date: Yup.mixed()
                                                                    .test({
                                                                        name: 'end_date_format',
                                                                        message: <Translate id='organization.settings.sidePanelOrganizationPeriods.endDate.format' />,
                                                                        test: (value) => moment.isMoment(value),
                                                                    }),
                                                            })}
                                                            onSubmit={(values) => {
                                                                this.setState({ loadingState: 'lazy' });
                                                                const APIReadyValues = {
                                                                    start_date: moment(values.start_date).format('YYYY-MM-DD'),
                                                                    end_date: moment(values.end_date).format('YYYY-MM-DD'),
                                                                }

                                                                const newValues = this.props.createNewValues(APIReadyValues);
                                                                this.props.PeriodsContext.updatePeriod(this.props.getCommonValue('period_id'), APIReadyValues)
                                                                    .then(() => {
                                                                        this.props.syncRows(newValues);
                                                                        success();
                                                                        this.setState({ loadingState: 'success' });
                                                                    })
                                                                    .catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            console.error(error.message)
                                                                            fail({
                                                                                msg: 'misc.error',
                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                skipInfoTranslate: true,
                                                                            })
                                                                            this.setState({ loadingState: 'success' });
                                                                        }
                                                                    })
                                                            }}
                                                        >
                                                            {(isEditing, options, formik) => {
                                                                if(!isEditing){
                                                                    return (
                                                                        <div className='ml-3'>
                                                                            <div>
                                                                                <span className='text-muted'><Translate id='organization.settings.sidePanelOrganizationPeriods.dates.start' /> - </span>
                                                                                {moment(this.props.getCommonValue('start_date')).locale(getGenericLocale()).format('YYYY-MM-DD')}
                                                                                <i className='mdi mdi-pencil text-primary ml-2' />
                                                                            </div>
                                                                            <div>
                                                                                <span className='text-muted'><Translate id='organization.settings.sidePanelOrganizationPeriods.dates.end' /> - </span>
                                                                                {moment(this.props.getCommonValue('end_date')).locale(getGenericLocale()).format('YYYY-MM-DD')}
                                                                                <i className='mdi mdi-pencil text-primary ml-2' />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                return (
                                                                    <div className='ml-3'>
                                                                        <FormGroup className='flex-column mb-2'>
                                                                            <div className='d-flex align-items-center'>
                                                                                <Label for='start_date' className='text-muted mb-0 mr-2'><Translate id='organization.settings.sidePanelOrganizationPeriods.dates.start' /></Label>
                                                                                <FormikDateTime
                                                                                    id='start_date'
                                                                                    name='start_date'
                                                                                    timeFormat={false}
                                                                                    locale={getGenericLocale()}
                                                                                    onChange={(value) => {
                                                                                        formik.setFieldTouched('start_date', true, false);
                                                                                        formik.setFieldValue('start_date', value, true);
                                                                                    }}
                                                                                    manualError
                                                                                />
                                                                            </div>
                                                                            <FormikError name='start_date' />
                                                                        </FormGroup>
                                                                        <FormGroup className='flex-column'>
                                                                            <div className='d-flex align-items-center'>
                                                                                <Label for='end_date' className='text-muted mb-0 mr-2'><Translate id='organization.settings.sidePanelOrganizationPeriods.dates.end' /></Label>
                                                                                <FormikDateTime
                                                                                    id='end_date'
                                                                                    name='end_date'
                                                                                    timeFormat={false}
                                                                                    locale={getGenericLocale()}
                                                                                    onChange={(value) => {
                                                                                        formik.setFieldTouched('end_date', true, false);
                                                                                        formik.setFieldTouched('start_date', true, false); // to trigger the validation for start_date < end_date
                                                                                        formik.setFieldValue('end_date', value, true);
                                                                                    }}
                                                                                    manualError
                                                                                />
                                                                            </div>
                                                                            <FormikError name='end_date' />
                                                                        </FormGroup>
                                                                    </div>
                                                                )

                                                            }}
                                                        </FormikEditable>
                                                    </div>

                                                    {/* SHARED DATE */}
                                                    <div className='mb-3'>
                                                        <div className='text-muted'><Translate id='organization.settings.sidePanelOrganizationPeriods.sharedDate' /></div>
                                                        <FormikEditable
                                                            id="shared_date_form"
                                                            initialValues={{
                                                                shared_date: this.props.getCommonValue('shared_date') ?? '',
                                                            }}
                                                            validationSchema={Yup.object().shape({
                                                                shared_date: Yup.mixed()
                                                                    .test({
                                                                        name: 'shared_date_format',
                                                                        message: <Translate id='organization.settings.sidePanelOrganizationPeriods.sharedDate.format' />,
                                                                        test: (value) => {
                                                                            if(value !== undefined){
                                                                                return moment.isMoment(value)
                                                                            }
                                                                            return true // return true because the field is not required

                                                                        },
                                                                    }),
                                                            })}
                                                            onSubmit={(values) => {
                                                                const APIReadyValues = {
                                                                    shared_date: values.shared_date ? moment(values.shared_date).toISOString() : null,
                                                                }

                                                                if(APIReadyValues.shared_date){
                                                                    this.setState({ loadingState: 'lazy' });
                                                                    const newValues = this.props.createNewValues(APIReadyValues);
                                                                    this.props.PeriodsContext.updatePeriod(this.props.getCommonValue('period_id'), APIReadyValues)
                                                                        .then(() => {
                                                                            this.props.syncRows(newValues);
                                                                            success();
                                                                            this.setState({ loadingState: 'success' });
                                                                        })
                                                                        .catch((error) => {
                                                                            if(!AxiosIsCancelled(error.message)){
                                                                                console.error(error.message)
                                                                                fail({
                                                                                    msg: 'misc.error',
                                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                    skipInfoTranslate: true,
                                                                                })
                                                                                this.setState({ loadingState: 'success' });
                                                                            }
                                                                        })
                                                                }
                                                            }}
                                                        >
                                                            {(isEditing) => {
                                                                if(!isEditing){
                                                                    return (
                                                                        moment(this.props.getCommonValue('shared_date')).format('YYYY-MM-DD') ?? '-'
                                                                    )
                                                                }
                                                                return (
                                                                    <FormikDateTime id='shared_date' name='shared_date' timeFormat={false} dateFormat='l' locale={getGenericLocale()} />
                                                                )

                                                            }}
                                                        </FormikEditable>
                                                    </div>

                                                    {/* CALCULATION DATE */}
                                                    <div className='mb-3'>
                                                        <div className='text-muted'><Translate id='organization.settings.sidePanelOrganizationPeriods.calculationDate' /></div>
                                                        <FormikEditable
                                                            id="calculation_date_form"
                                                            initialValues={{
                                                                calculation_date: this.props.getCommonValue('calculation_date') ?? '',
                                                            }}
                                                            validationSchema={Yup.object().shape({
                                                                calculation_date: Yup.mixed()
                                                                    .test({
                                                                        name: 'calculation_date_format',
                                                                        message: <Translate id='organization.settings.sidePanelOrganizationPeriods.calculationDate.format' />,
                                                                        test: (value) => {
                                                                            if(value !== undefined){
                                                                                return moment.isMoment(value)
                                                                            }
                                                                            return true // return true because the field is not required

                                                                        },
                                                                    }),
                                                            })}
                                                            onSubmit={(values) => {
                                                                const APIReadyValues = {
                                                                    calculation_date: values.calculation_date ? moment(values.calculation_date).format('YYYY-MM-DD') : null,
                                                                }

                                                                if(APIReadyValues.calculation_date){
                                                                    this.setState({ loadingState: 'lazy' });
                                                                    const newValues = this.props.createNewValues(APIReadyValues);
                                                                    this.props.PeriodsContext.updatePeriod(this.props.getCommonValue('period_id'), APIReadyValues)
                                                                        .then(() => {
                                                                            this.props.syncRows(newValues);
                                                                            success();
                                                                            this.setState({ loadingState: 'success' });
                                                                        })
                                                                        .catch((error) => {
                                                                            if(!AxiosIsCancelled(error.message)){
                                                                                console.error(error.message)
                                                                                fail({
                                                                                    msg: 'misc.error',
                                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                    skipInfoTranslate: true,
                                                                                })
                                                                                this.setState({ loadingState: 'success' });
                                                                            }
                                                                        })
                                                                }
                                                            }}
                                                        >
                                                            {(isEditing) => {
                                                                if(!isEditing){
                                                                    return (
                                                                        this.props.getCommonValue('calculation_date') ?? '-'
                                                                    )
                                                                }
                                                                return (
                                                                    <FormikDateTime id='calculation_date' name='calculation_date' timeFormat={false} dateFormat='l' locale={getGenericLocale()} />
                                                                )

                                                            }}
                                                        </FormikEditable>
                                                    </div>

                                                    {/* STATUS */}
                                                    <div className="mb-3">
                                                        <div className="text-muted"><Translate id='organization.settings.sidePanelOrganizationPeriods.status' /></div>
                                                        <FormikEditable
                                                            noConfirmation
                                                            id="active_form"
                                                            initialValues={{
                                                                active: this.props.getCommonValue("active"),
                                                            }}
                                                            validationSchema={Yup.object().shape({
                                                                active: Yup.string().required(<Translate id='organization.settings.sidePanelOrganizationPeriods.status.required' />),
                                                            })}
                                                            onSubmit={(values) => {
                                                                if(values.active !== this.props.getCommonValue("active")){
                                                                    this.setState({ loadingState: 'lazy' });
                                                                    const newValues = this.props.createNewValues(values);
                                                                    this.props.PeriodsContext.updatePeriod(this.props.getCommonValue('period_id'), values)
                                                                        .then(() => {
                                                                            this.props.syncRows(newValues);
                                                                            success();
                                                                            this.setState({ loadingState: 'success' });
                                                                        })
                                                                        .catch((error) => {
                                                                            if(!AxiosIsCancelled(error.message)){
                                                                                console.error(error.message)
                                                                                fail({
                                                                                    msg: 'misc.error',
                                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                    skipInfoTranslate: true,
                                                                                })
                                                                                this.setState({ loadingState: 'success' });
                                                                            }
                                                                        })
                                                                }
                                                            }}
                                                        >
                                                            {(isEditing, options) => {
                                                                if(!isEditing){
                                                                    return (
                                                                        <div className="font-medium">
                                                                            <i className={`mdi ${this.props.getCommonValue("active") == '1' ? 'mdi-check text-primary' : 'mdi-close text-danger'} mr-2`} />
                                                                            <span><Translate id={`organization.settings.sidePanelOrganizationPeriods.status.${this.props.getCommonValue("active") == '1' ? 'active' : 'inactive'}`} /></span>
                                                                        </div>
                                                                    )
                                                                }
                                                                return (
                                                                    <FormikSelect
                                                                        id='active' name='active'
                                                                        autoFocus menuIsDefaultOpen
                                                                        onOptionSelected={() => {
                                                                            options.stopEditing()
                                                                        }}
                                                                        defaultData={[
                                                                            { value: '1', label: 'organization.settings.sidePanelOrganizationPeriods.status.active', translateLabel: true },
                                                                            { value: '0', label: 'organization.settings.sidePanelOrganizationPeriods.status.inactive', translateLabel: true },
                                                                        ]}
                                                                        loadingStatus='success'
                                                                    />
                                                                )

                                                            }}
                                                        </FormikEditable>
                                                    </div>
                                                </>
                                            )}
                                        </I18nContext.Consumer>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </Fade>
                        : null}
            </>
        )
    }
}

SidePanelOrganizationPeriods.propTypes = {
    tableRef: PropTypes.object.isRequired,
    i18n: PropTypes.object,
}

export default withContexts(PeriodsContext, OrganizationContext)(SidePanelOrganizationPeriods);