export const typeDimensions = {
    small: {
        xxs: {
            w: 1,
            h: 1,
        },
        xs: {
            w: 1,
            h: 1,
        },
        sm: {
            w: 1,
            h: 1,
        },
        md: {
            w: 1,
            h: 1,
        },
        lg: {
            w: 1,
            h: 1,
        },
    },
    medium: {
        xxs: {
            w: 1,
            h: 2,
        },
        xs: {
            w: 2,
            h: 2,
        },
        sm: {
            w: 2,
            h: 2,
        },
        md: {
            w: 2,
            h: 2,
        },
        lg: {
            w: 2,
            h: 2,
        },
    },
    large: {
        xxs: {
            w: 1,
            h: 3,
        },
        xs: {
            w: 2,
            h: 3,
        },
        sm: {
            w: 3,
            h: 3,
        },
        md: {
            w: 4,
            h: 3,
        },
        lg: {
            w: 4,
            h: 3,
        },
    },
    tall: {
        xxs: {
            w: 1,
            h: 4,
        },
        xs: {
            w: 2,
            h: 4,
        },
        sm: {
            w: 2,
            h: 4,
        },
        md: {
            w: 2,
            h: 4,
        },
        lg: {
            w: 2,
            h: 4,
        },
    },
}

export const initPositioning = {
    xxs: {
        x: 0,
        y: Infinity,
    },
    xs: {
        x: 0,
        y: Infinity,
    },
    sm: {
        x: 0,
        y: Infinity,
    },
    md: {
        x: 0,
        y: Infinity,
    },
    lg: {
        x: 0,
        y: Infinity,
    },
}

export const breakpoints = {
    lg: 1200,
    md: 996,
    sm: 768,
    xs: 480,
    xxs: 0,
}