import { SpordleTableContext } from "@spordle/datatables";
import { FormikError, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useState, useEffect } from "react";
import { Alert, Button, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { mixed, object, string } from 'yup';
import AnalyticsModal from "../../../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import Required from '../../../../../../components/formik/Required';
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import { success } from "@spordle/toasts";
import FileUpload from '../../../../../../components/uploader/fileUpload/FileUpload';
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { displayI18n, DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { mbToBytes } from "../../../../../../components/uploader/uploadHelpers";
import { I18nContext } from "../../../../../../contexts/I18nContext";

export const OTHER_VALUE = 'OTHER';

const ModalAddMemberDocuments = (props) => (
    <AnalyticsModal isOpen={props.isOpen} toggle={props.toggle} backdrop="static" unmountOnClose analyticsName='ModalAddMemberDocuments'>
        <ModalInner {...props} />
    </AnalyticsModal>
);

const ModalInner = ({ toggle, documentTypes, onSuccess, shouldAttemptUploadProfilePicDocument, ...props }) => {
    const { createMemberAttachments, currentMember, setShouldAttemptUploadProfilePicDocument } = useContext(MembersContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { refreshTable } = useContext(SpordleTableContext);
    const [ chosenDocType, setChosenDocType ] = useState(null);

    useEffect(() => {
        // sets the boolean that signals if we should pre-fill the document type field with member profile picture to false so as not to
        // keep re-triggering the modal on every re-render should the user quit the modal (done on a timeout so as not to interfere with initial values)
        if(shouldAttemptUploadProfilePicDocument){
            setTimeout(() => {
                setShouldAttemptUploadProfilePicDocument(false)
            }, 500)
        }
    })

    const profilePictureDocumentTypeId = documentTypes?.find((docType) => docType.system === 'MEMBER_PROFILE_PICTURE')?.document_type_id

    return (
        <Formik
            initialValues={{
                document_type_id: (shouldAttemptUploadProfilePicDocument && profilePictureDocumentTypeId) ? profilePictureDocumentTypeId : '',
                attachment: '',
                note: '',
            }}
            validationSchema={object().shape({
                document_type_id: string().required(<Translate id='members.profile.overview.memberDocuments.form.validation.documentType.required' />),
                attachment: mixed().test({
                    name: 'isFile',
                    message: <Translate id='members.profile.crc.form.validation.file.required' />,
                    test: function(val){
                        return val instanceof File
                    },
                }),
                note: string().when('document_type_id', {
                    is: (val) => val === OTHER_VALUE || chosenDocType?.with_note == 1,
                    then: string().required(<Translate id='members.profile.overview.memberDocuments.form.validation.note.required' />),
                }),
            })}
            onSubmit={({ document_type_id, note, ...values }, { setStatus }) => {
                const apiVal = {
                    ...values,
                    document_type_id: document_type_id === OTHER_VALUE ? "" : document_type_id,
                };

                if(document_type_id === OTHER_VALUE || chosenDocType?.with_note == 1){
                    apiVal.note = note;
                }else{
                    apiVal.note = null;
                }

                return createMemberAttachments(currentMember.member_id, apiVal)
                    .then(() => {
                        refreshTable();
                        onSuccess?.();
                        toggle();
                        success();
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message);
                            let msg = <Translate id='misc.error' />;

                            if(error.i18n){
                                msg = <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />;
                            }else if(error.message == 3331 || error.message == 3332){
                                msg = <Translate id={`components.uploader.imgUpload.error.${error.message}`} />;
                            }

                            setStatus(msg);
                        }
                    })
            }}
        >
            {(formik) => (
                <OverlayLoader isLoading={formik.isSubmitting}>
                    <Form>
                        <ModalHeader toggle={toggle}><Translate id='members.profile.overview.memberDocuments.modal.title' /></ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="document_type_id"><Translate id='members.profile.overview.memberDocuments.form.label.documentType' /> <Required /></Label>
                                <FormikSelect
                                    name="document_type_id"
                                    id="document_type_id"
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                        'label',
                                    ]}
                                    renderOption={({ option }) => option.value !== OTHER_VALUE && <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.label} />}
                                    options={documentTypes ?
                                        [
                                            ...documentTypes
                                                .filter((docType) => {
                                                    if(currentMember.member_status.name !== 'INELIGIBLE'){
                                                        // filter out the permanently ineligible document type if the member isn't permanently ineligible
                                                        return docType.system !== 'PERMANENTLY_INELIGIBLE'
                                                    }return true;
                                                })
                                                .map((type) => ({
                                                    value: type.document_type_id,
                                                    label: type.name,
                                                    i18n: type.i18n,
                                                })),
                                            { value: OTHER_VALUE, label: 'misc.other', translateLabel: true },
                                        ]
                                        :
                                        []
                                    }
                                    onOptionSelected={([ val ]) => {
                                        setChosenDocType(documentTypes.find((docType) => docType.document_type_id === val));
                                    }}
                                />
                            </FormGroup>
                            <Collapse isOpen={!!chosenDocType?.description}>
                                <FormGroup className="p-2 bg-light rounded-lg border small d-flex">
                                    <i className="mdi mdi-information-outline text-primary mr-1" />
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: displayI18n('description', chosenDocType?.i18n, chosenDocType?.description, getGenericLocale()),
                                        }}
                                    />
                                </FormGroup>
                            </Collapse>
                            <FormGroup>
                                <Label for="attachment"><Translate id='members.profile.overview.memberDocuments.form.label.document' /> <Required /></Label>
                                <FileUpload
                                    dropzoneProps={{
                                        maxSize: chosenDocType?.file_size ? mbToBytes(parseFloat(chosenDocType.file_size)) : undefined,
                                        accept: "image/jpg, image/jpeg, image/png, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                                    }}
                                    name="attachment"
                                    onFileSelect={(files) => formik.setFieldValue('attachment', files[0])}
                                />
                                <FormikError name="attachment" />
                            </FormGroup>
                            <Collapse tag={FormGroup} isOpen={formik.values.document_type_id === OTHER_VALUE || chosenDocType?.with_note == 1}>
                                <Label for='document_note'><Translate id='members.profile.overview.memberDocuments.form.label.note' /> <Required /></Label>
                                <FormikTextArea id='document_note' name='note' trim />
                            </Collapse>
                            {formik.status &&
                                <Collapse isOpen appear mountOnEnter unmountOnExit>
                                    <Alert color='danger' className="mb-0">
                                        {formik.status}
                                    </Alert>
                                </Collapse>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button disabled={formik.isSubmitting} type="submit" color="primary"><Translate id="misc.add" /></Button>
                            <Button disabled={formik.isSubmitting} type="button" color="primary" outline onClick={toggle}><Translate id="misc.cancel" /></Button>
                        </ModalFooter>
                    </Form>
                </OverlayLoader>
            )}
        </Formik>
    )
};

export default ModalAddMemberDocuments;