import queryString from 'query-string';
import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import withContexts from '../helpers/withContexts';
import { I18nContext } from './I18nContext';

/** @type {React.Context<Omit<GameIncidentsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const GameIncidentsContext = createContext();
GameIncidentsContext.displayName = 'GameIncidentsContext';

class GameIncidentsContextProvider extends React.Component{
    /**
     * [GET] Gets game incident reports
     * @param {string} officialId official id
     * @param {object} [queryParams={}] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Game%20Incidents/e896546833ce047c437b5021585d9a22}
     * @returns {Promise.<object[]>}
     * @throws {Error}
     */
    getGameIncidentList = (officialId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${officialId}/game-incidents`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    const formattedIncidents = [];
                    const res = response.data;

                    for(let i = 0; i < res.game_incidents.length; i++){
                        const incident = { ...res.game_incidents[i] };

                        incident.game_incident_status = res.game_incident_statuses[incident.game_incident_status_id];
                        incident.period = res.periods[incident.period_id];
                        incident.away_team = { name: incident.away_team_name, ...res.teams[incident.away_team] };
                        incident.away_team.coach = res.members[incident.away_team_coach];
                        incident.away_team.coach_name = incident.away_team_coach_name;
                        incident.away_team.manager = res.members[incident.away_team_manager];
                        incident.away_team.manager_name = incident.away_team_manager_name;

                        incident.home_team = { name: incident.home_team_name, ...res.teams[incident.home_team] };
                        incident.home_team.coach = res.members[incident.home_team_coach];
                        incident.home_team.coach_name = incident.home_team_coach_name;
                        incident.home_team.manager = res.members[incident.home_team_manager];
                        incident.home_team.manager_name = incident.home_team_manager_name;

                        incident.team_category = res.team_categories[incident.team_category_id];
                        incident.official = res.members[incident.official];
                        incident.created_by = res.identities[incident.created_by];
                        incident.organisation = res.organisations[incident.organisation_id];
                        incident.game_incident_officials = incident.game_incident_officials.map((off) => ({ ...off, member: res.members[off.member_id] }));
                        incident.game_incident_players = incident.game_incident_players.map((p) => (
                            {
                                ...p,
                                member: res.members[p.member_id],
                                suspension: res.suspensions[p.suspension_id],
                                suspension_type: res.suspension_types[p.suspension_type_id],
                                team: res.teams[p.team_id],
                            }
                        ));

                        delete incident.period_id;
                        delete incident.organisation_id;
                        delete incident.team_category_id;
                        delete incident.game_incident_status_id;

                        formattedIncidents.push(incident);

                    }

                    return formattedIncidents;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * [GET] Gets game incident reports by organisation
     * @param {string} orgId organisation_id
     * @param {object} [queryParams={}] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Game%20Incidents/e896546833ce047c437b5021585d9a22}
     * @returns {Promise.<object[]>}
     * @throws {Error}
     */
    getOrgGameIncidentList = (orgId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/organisations/${orgId}/game-incidents`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    const formattedIncidents = [];
                    const res = response.data;

                    for(let i = 0; i < res.game_incidents.length; i++){
                        const incident = { ...res.game_incidents[i] };

                        incident.game_incident_status = res.game_incident_statuses[incident.game_incident_status_id];
                        incident.period = res.periods[incident.period_id];
                        incident.away_team = { name: incident.away_team_name, ...res.teams[incident.away_team] };
                        incident.away_team.coach = res.members[incident.away_team_coach];
                        incident.away_team.coach_name = incident.away_team_coach_name;
                        incident.away_team.manager = res.members[incident.away_team_manager];
                        incident.away_team.manager_name = incident.away_team_manager_name;

                        incident.home_team = { name: incident.home_team_name, ...res.teams[incident.home_team] };
                        incident.home_team.coach = res.members[incident.home_team_coach];
                        incident.home_team.coach_name = incident.home_team_coach_name;
                        incident.home_team.manager = res.members[incident.home_team_manager];
                        incident.home_team.manager_name = incident.home_team_manager_name;

                        incident.team_category = res.team_categories[incident.team_category_id];
                        incident.official = res.members[incident.official];
                        incident.created_by = res.identities[incident.created_by];
                        incident.organisation = res.organisations[incident.organisation_id];
                        incident.game_incident_officials = incident.game_incident_officials.map((off) => ({ ...off, member: res.members[off.member_id] }));
                        incident.game_incident_players = incident.game_incident_players.map((p) => (
                            {
                                ...p,
                                member: res.members[p.member_id],
                                suspension: res.suspensions[p.suspension_id],
                                suspension_type: res.suspension_types[p.suspension_type_id],
                                team: res.teams[p.team_id],
                            }
                        ));

                        delete incident.period_id;
                        delete incident.organisation_id;
                        delete incident.team_category_id;
                        delete incident.game_incident_status_id;

                        formattedIncidents.push(incident);

                    }

                    return formattedIncidents;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * [GET] Gets game incident report statuses
     * @param {string} orgId organisation id
     * @param {object} [queryParams={}] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Game%20Incidents/f5231c9d4ee7bd04ee4ef44ce4b6a210}
     * @returns {Promise.<object[]>}
     * @throws {Error}
     */
    getGameIncidentStatusList = (orgId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/game-incident-status`,
            query: { organisation_id: orgId, active: 1, ...queryParams },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.game_incident_status;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * [GET] Gets a specific game incident report
     * @param {string} gameIncidentId The game incident id
     * @param {object} [queryParams={}] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Game%20Incidents/4527ae753a8c02f81e2165d3b4c4cfc6}
     * @throws {Error}
     * @returns {Promise.<object>}
     */
    getGameIncident = (gameIncidentId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/game-incidents/${gameIncidentId}`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.game_incidents[0];
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * [PATCH] Partially updates a game incident
     * @param {string} gameIncidentId
     * @param {object} values
     * @throws {Error}
     * @see Refer to {@link https://api.id.dev.spordle.dev/documentations/#/Game%20Incidents/93e60ed42f24308df1e0906b6130e5dc|documentation}
     * @returns {Promise.<boolean>}
     */
    partiallyUpdateGameIncident = (gameIncidentId, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/game-incidents/${gameIncidentId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PATCH] Partially updates a game incident player
     * @param {string} gameIncidentId
     * @param {string} gameIncidentPlayerId
     * @param {object} values
     * @throws {Error}
     * @see Refer to {@link https://api.id.dev.spordle.dev/documentations/#/Game%20Incidents/34469c684d44135c8849099ace9bc9a4|documentation}
     * @returns {Promise.<boolean>}
     */
    partiallyUpdateGameIncidentPlayer = (gameIncidentId, gameIncidentPlayerId, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/game-incidents/${gameIncidentId}/players/${gameIncidentPlayerId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PATCH] Partially updates a game incident official
     * @param {string} gameIncidentId
     * @param {string} gameIncidentOfficialId
     * @param {object} values
     * @throws {Error}
     * @see Refer to {@link https://api.id.dev.spordle.dev/documentations/#/Game%20Incidents/92c61399e26539607b2c7d0b621bd5d7|documentation}
     * @returns {Promise.<boolean>}
     */
    partiallyUpdateGameIncidentOfficial = (gameIncidentId, gameIncidentOfficialId, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/game-incidents/${gameIncidentId}/officials/${gameIncidentOfficialId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] Creates a game incident
     * @param {object} values
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Game%20Incidents/f5231c9d4ee7bd04ee4ef44ce4b6a210|documentation}
     * @throws {Error}
     * @returns {Promise.<string>}
     */
    createGameIncident = (values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/game-incidents` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.game_incident_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] Creates a game incident player incident
     * @param {string} gameIncidentId
     * @param {object} values
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Game%20Incidents/102e69f7719b82a387cdc61deafe502e|documentation}
     * @throws {Error}
     * @returns {Promise.<string>}
     */
    createGameIncidentPlayerIncident = (gameIncidentId, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/game-incidents/${gameIncidentId}/players`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.game_incident_player_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] Creates a game incident player incident
     * @param {string} gameIncidentId
     * @param {object} values
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Game%20Incidents/c96107c85db8cdf18386174c341d1345|documentation}
     * @throws {Error}
     * @returns {Promise.<string>}
     */
    createGameIncidentOfficial = (gameIncidentId, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/game-incidents/${gameIncidentId}/officials`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.game_incident_official_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] Deletes a game incident player incident
     * @param {string} gameIncidentId
     * @param {string} gameIncidentPlayerId
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Game%20Incidents/329060d285524799a734b5272e532154|documentation}
     * @throws {Error}
     * @returns {Promise.<boolean>}
     */
    deleteGameIncidentPlayerIncident = (gameIncidentId, gameIncidentPlayerId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `/game-incidents/${gameIncidentId}/players/${gameIncidentPlayerId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] Deletes a game incident official
     * @param {string} gameIncidentId
     * @param {string} gameIncidentPlayerId
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Game%20Incidents/20e0f7bc6ac065682ba3972a044a1476|documentation}
     * @throws {Error}
     * @returns {Promise.<boolean>}
     */
    deleteGameIncidentOfficial = (gameIncidentId, gameIncidentOfficialId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `/game-incidents/${gameIncidentId}/officials/${gameIncidentOfficialId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <GameIncidentsContext.Provider value={{ ...this }}>
                {this.props.children}
            </GameIncidentsContext.Provider>
        )
    }
}

export default withContexts(I18nContext)(GameIncidentsContextProvider);