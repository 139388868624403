import { SpordleTableContext } from '@spordle/datatables';
import { FormikDateTime, FormikInputNumber, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form as FormikForm, Formik } from 'formik';
import { useContext } from 'react';
import {
    Row,
    Button,
    Col,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label
} from "reactstrap";
import { string, object, number, array, mixed } from 'yup';
import moment from 'moment';
import { FormattedPlural } from 'react-intl';
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import Required from '../../../../components/formik/Required';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import { InstallmentModesContext } from '../../../../contexts/InstallmentModesContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { fail, success } from "@spordle/toasts";
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { PeriodsContext } from '../../../../contexts/contexts';

const InstallmentAdd = ({ toggle, isOpen }) => {
    const installmentModes = useContext(InstallmentModesContext);
    const orgContext = useContext(OrganizationContext);
    const periodsContext = useContext(PeriodsContext);
    const spordleTable = useContext(SpordleTableContext);

    /**
     * @param {number} installmentCount
     * @return {number[]}
     */
    const getSplitPercentage = (installmentCount) => {
        const splitPercentage = 100 / installmentCount;
        if(Number.isSafeInteger(splitPercentage)){
            return Array.from({ length: installmentCount }, () => splitPercentage);
        }
        /*
            Scenario:
            installmentCount = 6
            flooredPercentage -> 16
            roundTo100Count -> 3
            */
        const flooredPercentage = Math.floor(splitPercentage);
        // When adding flooredPercentage * installmentCount and does NOT add up to percentLeft then...
        // roundTo100Count will have a value to determine how many values we need to add(1) to to reach percentLeft
        const roundTo100Count = 100 - (flooredPercentage * installmentCount);
        // Creating the array with the correct values
        return Array.from({ length: installmentCount }, (_value, index) => index < roundTo100Count ? flooredPercentage + 1 : flooredPercentage);

    }

    return (
        <AnalyticsModal analyticsName='InstallmentAdd' isOpen={isOpen}>
            <Formik
                initialValues={{
                    name: '',
                    initial_installment_percentage: '',
                    installment: '1',
                    installment_options: [ {
                        installment_option_percentage: 100,
                        installment_option_percentage_date: '',
                    } ],
                }}
                validationSchema={object().shape({
                    name: string().required(<Translate id='form.validation.title.required' />),
                    initial_installment_percentage: number().integer()
                        .positive(<Translate id='form.validation.number.positive' />)
                        .required(<Translate id='catalog.installment.form.value.required' />),
                    installment: string().required(<Translate id='catalog.installment.form.installments.required' />),
                    installment_options: array().of(object().shape({
                        installment_option_percentage: number().integer()
                            .positive(<Translate id='form.validation.number.positive' />)
                            .required(<Translate id='catalog.installment.form.value.required' />)
                            .test({
                                name: 'isHundred',
                                message: <Translate id='catalog.installment.form.value.total' />,
                                test: function(){
                                    let total = 0;
                                    this.from[this.from.length - 1].value.installment_options.forEach((opt) => {
                                        total += parseInt(opt.installment_option_percentage);
                                    });
                                    return total === 100;
                                },
                            }),
                        installment_option_percentage_date: mixed()
                            .test({
                                name: 'dateIsValid',
                                message: <Translate id='form.validation.date.format' />,
                                test: moment.isMoment,
                            })
                            .test({
                                name: 'laterThanPrevious',
                                message: <Translate id='catalog.installment.form.installments.date.afterPrevious' />,
                                test: function(val){
                                    const prevDate = this.options.index !== 0 ? this.from[this.from.length - 1].value.installment_options?.[this.options.index - 1]?.installment_option_percentage_date : false;
                                    if(prevDate && moment.isMoment(prevDate) && val && moment.isMoment(val)){
                                        return val.isAfter(prevDate);
                                    }
                                    return true;

                                },
                            }),
                    })),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    const newValues = {
                        organisation_id: orgContext.organisation_id,
                        period_id: periodsContext.selectedPeriod.period_id,
                        number_of_payment: values.installment_options.length,
                        ...values,
                    };
                    newValues.installment_options.forEach((opt, i) => {
                        newValues.installment_options[i].installment_option_percentage_date = opt.installment_option_percentage_date.clone().format('YYYY-MM-DD');
                    });

                    installmentModes.createInstallmentMode(newValues)
                        .then((installment_mode_id) => {
                            spordleTable.addRow({
                                installment_mode_id: installment_mode_id,
                                active: '1',
                                ...values,
                            }).then(() => {
                                toggle();
                                success();
                            });

                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                                setSubmitting(false);
                            }
                        })
                }}
            >
                {(formik) => (
                    <FormikForm>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalHeader toggle={toggle}>
                                <Translate id='catalog.installment.add.title' />
                            </ModalHeader>
                            <ModalBody>
                                <div className="h4 font-bold"><Translate id='form.fields.general' /></div>
                                <FormGroup>
                                    <Label for='name' className='text-muted'><Translate id='catalog.installment.form.title' /> <Required /></Label>
                                    <FormikInputText id='name' name='name' helper='catalog.installment.form.title.note' />
                                </FormGroup>
                                <FormGroup>
                                    <Label for='initial_installment_percentage' className='text-muted'><Translate id='catalog.installment.form.initialValue' /> <Required /></Label>
                                    <FormikInputNumber
                                        id='initial_installment_percentage'
                                        name="initial_installment_percentage"
                                        allowNegative={false}
                                        decimalScale={0}
                                        suffix=' %'
                                        helper='catalog.installment.form.initialValue.note'
                                        isAllowed={({ floatValue }) => (floatValue == null) || (floatValue >= 0 && floatValue <= 100)}
                                    />
                                </FormGroup>
                                <div className="mb-2">
                                    <div className="h4 font-bold"><Translate id='catalog.installment.form.installments' /></div>
                                    {/* <div className="small"><i className="mdi text-primary mdi-information-outline mr-1"></i><Translate id='catalog.installment.form.installments.note' /></div> */}
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup>
                                                <FormikSelect
                                                    name='installment'
                                                    id='installment'
                                                    onOptionSelected={([ installment ]) => {
                                                        const installmentCount = parseInt(installment);
                                                        const percentageSplit = getSplitPercentage(installmentCount);
                                                        // Can use Array.from because of polyfills
                                                        formik.setFieldValue('installment_options', Array.from(
                                                            { length: installmentCount },
                                                            (_value, index) => ({
                                                                installment_option_percentage: percentageSplit[index],
                                                                installment_option_percentage_date: formik.values.installment_options[index]?.installment_option_percentage_date ?? '',
                                                            }),
                                                        ));
                                                    }}
                                                    renderOption={({ option: installment }) => (
                                                        <FormattedPlural
                                                            value={installment.value}
                                                            one={<Translate id='catalog.installment.form.installments.label.single' />}
                                                            other={<Translate id='catalog.installment.form.installments.label.plural' values={{ count: installment.value }} />}
                                                        />
                                                    )}
                                                    defaultValues={[ '1' ]}
                                                    defaultData={Array.from({ length: 12 }, (_v, i) => ({
                                                        label: i === 0 ? '1 installment' : (i + 1) + ' installments',
                                                        value: `${i + 1}`,
                                                    }))}
                                                    loadingStatus='success'
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                                <Row form>
                                    <Col xs="4" className="mb-2">
                                        <div className="text-muted"><Translate id='catalog.installment.form.value' /></div>
                                    </Col>
                                    <Col xs="4" className="mb-2">
                                        <div className="text-muted"><Translate id='catalog.installment.form.date' /></div>
                                    </Col>
                                </Row>
                                {formik.values.installment_options.map((_opt, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Row key={index} form className="align-items-center">
                                        <Col xs="4" className="mb-2">
                                            <FormikInputNumber
                                                name={`installment_options.${index}.installment_option_percentage`}
                                                allowNegative={false}
                                                decimalScale={0}
                                                suffix=' %'
                                                isAllowed={({ floatValue }) => (floatValue == null) || (floatValue >= 0 && floatValue <= 100)}
                                            />
                                        </Col>
                                        <Col xs="4" className="mb-2">
                                            <FormikDateTime
                                                name={`installment_options.${index}.installment_option_percentage_date`}
                                                id={`installment_options.${index}.installment_option_percentage_date`}
                                                placeholder='catalog.installment.form.date.helper'
                                                dateFormat='YYYY-MM-DD'
                                                timeFormat={false}
                                            />
                                        </Col>
                                    </Row>
                                ))}
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type="submit" disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                                <Button outline type="button" color='primary' onClick={toggle} disabled={formik.isSubmitting}>
                                    <Translate id='misc.cancel' />
                                </Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </FormikForm>
                )}
            </Formik>
        </AnalyticsModal>
    );
}

export default InstallmentAdd;