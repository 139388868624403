import { useSpordleTable } from "@spordle/datatables";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Button } from "reactstrap";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import { AppContext } from "../../../../contexts/contexts";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import { RolesContext } from "../../../../contexts/RolesContext";
import { InboxContext } from "../inboxContext/InboxContext";
import ToolBtn from "./ToolBtn";
import ArchiveTool from "./tools/ArchiveTool";
import FavoriteTool from "./tools/FavoriteTool";
import ReadTool from "./tools/ReadTool";
import SelectBatchTool from "./tools/SelectBatchTool";

const InboxTools = ({ selectAll, unselectAll, hideTools, checked, updateCheckedMsgs, data = {} }) => {
    const { getDisplayedData, getFilters, refreshTable, setLoading } = useSpordleTable();
    const { isMobileDevice } = useContext(AppContext);
    const { showTools, sentMsgIsOpenHandlers } = useContext(InboxContext);
    const actionIsDisabled = checked.length === 0 || getDisplayedData().length <= 0;
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const hasPermissionToEdit = canDoAction("EDIT", "communications", "communications_manage");
    const canEdit = hasPermissionToEdit || isGod();

    const refreshList = () => {
        setLoading(true);
        refreshTable();
    }

    return (
        <div className="position-relative" onClick={(e) => e.stopPropagation()}>
            {!isMobileDevice &&
                <CanDoAction action="ADD" componentCode="communications" componentPermissionCode="communications_manage">
                    {(isGodOnly) => (
                        <div className="border-bottom p-3">
                            <Button onClick={sentMsgIsOpenHandlers.open} block color={isGodOnly ? "purple" : "primary"} type="button">
                                <Translate id="communications.btn.compose" />
                            </Button>
                        </div>
                    )}
                </CanDoAction>
            }
            <div className="pl-3 transition pr-2 py-2 border-bottom d-flex align-items-center">
                {canEdit && !hideTools &&
                    <SelectBatchTool
                        selectAll={selectAll}
                        unselectAll={unselectAll}
                        checked={checked}
                        disabled={getDisplayedData().length <= 0}
                    />
                }
                {isMobileDevice &&
                    <div
                        style={{ opacity: !showTools ? 1 : 0, width: showTools ? 0 : "100%" }}
                        className={stringBuilder("text-nowrap h4 font-bold mb-0", { "pl-2": !hideTools })}
                    >
                        <Translate id="communications.messages.list.title" values={{ count: getFilters().totalResults }} />
                    </div>
                }
                <div
                    className="d-flex align-items-center"
                    style={{
                        visibility: (isMobileDevice && !showTools) ? 'hidden' : '',
                        opacity: (isMobileDevice && !showTools) ? 0 : 1,
                    }}
                >
                    <ToolBtn
                        className={!isMobileDevice && hideTools ? "ml-n2" : undefined}
                        onClick={refreshList}
                        icon="refresh"
                        label={<Translate id="misc.refresh" />}
                    />
                    {canEdit && !hideTools &&
                        <>
                            <ArchiveTool
                                checked={checked}
                                disabled={actionIsDisabled}
                                updateCheckedMsgs={updateCheckedMsgs}
                                newVal={data.archive}
                            />
                            <FavoriteTool
                                checked={checked}
                                newVal={data.favorite}
                                disabled={actionIsDisabled}
                                updateCheckedMsgs={updateCheckedMsgs}
                            />
                            <ReadTool
                                checked={checked}
                                disabled={actionIsDisabled}
                                updateCheckedMsgs={updateCheckedMsgs}
                            />
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default InboxTools;