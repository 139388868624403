export default{
    "catalog.affiliationFees.add.title"       : "Ajouter un frais d'affiliation ",
    "catalog.affiliationFees.add.membertype"  : "Type de membre",
    "catalog.affiliationFees.add.branch"      : "Branche",
    "catalog.affiliationFees.add.division"    : "Division",
    "catalog.affiliationFees.add.class"       : "Classe",
    "catalog.affiliationFees.add.category"    : "Catégorie",
    "catalog.affiliationFees.add.fee"         : "Frais",
    "catalog.affiliationFees.add.taxIncluded" : "Taxes incluses",

    "catalog.affiliationFees.branch.required"     : "La branche est requise.",
    "catalog.affiliationFees.memberType.required" : "Le type de membre est requis.",
    "catalog.affiliationFees.division.required"   : "La division est requise.",
    "catalog.affiliationFees.class.required"      : "La classe est requise.",
    "catalog.affiliationFees.fee.required"        : "Les frais sont requis.",

    "catalog.affiliationFees.add.error.3207" : "Une combinaison de branche et division existe déjà.",
    "catalog.affiliationFees.add.error.3060" : "Une division sélectionnée n'a pas été trouvé.",
}