import Translate from '@spordle/intl-elements';
import {
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap";
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import DisplayOrganization from '../../../../components/organization/DisplayOrganization';
import UserImg from '../../../../components/UserImg';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { getStatus } from '../../TeamHelpers';
import moment from 'moment';
import TeamSearchRosterCards from './TeamSearchRosterCards';
import TeamCards from '../../profile/TeamHeader/teamCards/TeamCards';

const TeamSearchSidepanelInfo = ({ team, memberPrimaryTeams, from, getLockedRosterDate, getLockedRoster }) => {
    const filteredComments = memberPrimaryTeams?.filter((primaryTeam) => !!primaryTeam.comment) || [];

    return (
        <>
            <Row>
                <Col sm="6">
                    <div className="mb-3">
                        <div className="text-muted"><Translate id="misc.status" /></div>
                        <div className="font-medium text-dark">{getStatus(team.team_status)}</div>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="mb-3">
                        <div className="text-muted"><Translate id='form.fields.short_name' /></div>
                        <div className="font-medium text-dark">{team.short_name || '-'}</div>
                    </div>
                </Col>
            </Row>
            <div className="h5 font-bold mt-2"><Translate id='teams.teamSearch.sideTable.label.teamInfo' /></div>
            <div className="mb-3">
                <div className="text-muted mb-1"><Translate id='teams.teamSearch.table.label.org' /></div>
                <UserDisplay>
                    <UserDisplay.Container>
                        <UserImg
                            abbr={team.organisation.abbreviation || team.organisation.organisation_name}
                            alt={team.organisation.organisation_name}
                            filePos={team.organisation.logo?.file_position}
                            src={team.organisation.logo?.full_path}
                            width={40}
                        />
                    </UserDisplay.Container>
                    <UserDisplay.Container>
                        <UserDisplay.Title>
                            <DisplayOrganization organisation={team.organisation}>
                                <DisplayI18n
                                    defaultValue={team.organisation.organisation_name}
                                    field="name"
                                    i18n={team.organisation.i18n}
                                />
                            </DisplayOrganization>
                        </UserDisplay.Title>
                    </UserDisplay.Container>
                </UserDisplay>
            </div>

            {filteredComments.length > 0 &&
                <>
                    <div className="h5 font-bold mt-2"><Translate id='teams.teamSearch.sideTable.label.comments' /></div>
                    <div className="mb-3">
                        {filteredComments.map((primaryTeam) => (
                            <Card key={primaryTeam.member_primary_team_id} className="card-shadow mb-3">
                                <CardBody className="p-3">
                                    <div className="border-bottom pb-1 mb-3">
                                        <div className='d-flex justify-content-between'>
                                            <div className='text-dark font-bold mb-0 text-break'>
                                                {primaryTeam.identity ?
                                                    <>{primaryTeam.identity.name} {primaryTeam.identity.family_name}</>
                                                    : primaryTeam.api_partner ?
                                                        <>{primaryTeam.api_partner.name}</>
                                                        : null}
                                            </div>
                                            <div className='small text-right flex-shrink-0 ml-1'>
                                                {primaryTeam.created_at && moment(primaryTeam.created_at).fromNow()}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {primaryTeam.comment}
                                    </div>
                                </CardBody>
                            </Card>
                        ))}
                    </div>
                </>
            }

            {from === 'teams' &&
                <>
                    <TeamCards team={team} minimal column teamId={team.team_id} />

                    <TeamSearchRosterCards team={team} getLockedRosterDate={getLockedRosterDate} getLockedRoster={getLockedRoster} />
                </>
            }
        </>
    );
}

export default TeamSearchSidepanelInfo;