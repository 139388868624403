import { ModalBody, ModalHeader, Spinner } from "reactstrap";
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import FormikModalForm from "../../../../../components/formik/FormikModalForm";
import CrossFade from "../../../../../components/crossFade/CrossFade";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import Svg from "../../../../../components/Svg";
import { useContext, useEffect, useState } from "react";
import { object, string } from "yup";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import ParticipateChooseCompetition from "./ParticipateChooseCompetition";
import Translate from "@spordle/intl-elements";
import { addTeamToCompetition } from "../../../../../api/client/teams";
import ApiErrorMsg from "../../../../../components/formik/ApiErrorMsg";
import { isLeague, isTournament } from "../../../../../helpers/orgHelper";

const ParticipateInTournamentLeagueModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal isOpen={isOpen} analyticsName="participateInTournamentLeagueModal">
            <ParticipateInTournamentLeagueModalInner {...props} />
        </AnalyticsModal>
    );
}

const ParticipateInTournamentLeagueModalInner = ({ teamId, onSuccess, orgsAppliedTo, toggle }) => {
    const { getOrganizationCategory, organisation_id } = useContext(OrganizationContext);
    const [ data, setData ] = useState({ error: false, isLoading: true, categoryIds: { league: '', tournament: '' } });

    useEffect(() => {
        setData((prev) => ({ ...prev, isLoading: true }))
        getOrganizationCategory(organisation_id)
            .then((categories) => {
                setData((prev) => ({
                    ...prev,
                    isLoading: false,
                    categoryIds: {
                        league: categories.find(({ category_system }) => isLeague(category_system))?.category_id,
                        tournament: categories.find(({ category_system }) => isTournament(category_system))?.category_id,
                    },
                }))
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                    setData((prev) => ({ ...prev, error: e, isLoading: false }))
                }
            })
    }, []);

    return (
        <FormikModalForm
            toggle={toggle}
            successText={<p><Translate id="teams.profile.participate.success" /></p>}
            initialValues={{
                mode: '',
                competition_id: '',
                organisation_id: '',
                note: '',
            }}
            validationSchema={object().shape({
                competition_id: string().required(<Translate id="teams.profile.participate.competition.required" />),
                note: string(),
            })}
            onSubmit={(values, { setStatus }) => {
                return addTeamToCompetition({
                    team_id: teamId,
                    competition_id: values.competition_id,
                    submission_note: values.note,
                })
                    .then(() => {
                        onSuccess?.();
                        setStatus({
                            isSuccess: true,
                            error: null,
                            showError: false,
                        })
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                            setStatus({
                                showError: true,
                                isSuccess: false,
                                error: Array.isArray(e) ? e : <ApiErrorMsg error={e} />,
                            });
                        }
                    })
            }}
        >
            {(formik) => (
                <>
                    <ModalHeader toggle={formik.isSubmitting ? void 0 : toggle}>
                        <Translate id={`teams.profile.participate${formik.values.mode ? `.${formik.values.mode}` : ''}`} />
                    </ModalHeader>
                    <ModalBody>
                        <CrossFade isVisible={!formik.values.mode || formik.values.mode === 'league'}>
                            <UserDisplay
                                card
                                block
                                hover={!formik.values.mode && !data.isLoading}
                                style={data.isLoading ? { opacity: 0.5 } : void 0}
                                className={`mb-${formik.values.mode ? 0 : 3}`}
                                onClick={(formik.isSubmitting || !!formik.values.mode || data.isLoading) ? void 0 : () => formik.setFieldValue('mode', 'league')}
                            >
                                <UserDisplay.Container>
                                    <Svg icon="leagues" />
                                </UserDisplay.Container>
                                <UserDisplay.Container>
                                    <UserDisplay.Title><Translate id="teams.profile.label.league" /></UserDisplay.Title>
                                    <UserDisplay.Subtitle><Translate id="teams.profile.label.league.enter" /></UserDisplay.Subtitle>
                                </UserDisplay.Container>
                                {data.isLoading &&
                                    <UserDisplay.Container className="ml-auto">
                                        <Spinner type="grow" color="primary" size="sm" />
                                    </UserDisplay.Container>
                                }
                            </UserDisplay>
                        </CrossFade>
                        <CrossFade isVisible={!formik.values.mode || formik.values.mode === 'tournament'}>
                            <UserDisplay
                                card
                                block
                                hover={!formik.values.mode && !data.isLoading}
                                className="mb-0"
                                style={data.isLoading ? { opacity: 0.5 } : void 0}
                                onClick={(formik.isSubmitting || !!formik.values.mode || data.isLoading) ? void 0 : () => formik.setFieldValue('mode', 'tournament')}
                            >
                                <UserDisplay.Container>
                                    <Svg icon="tournaments" />
                                </UserDisplay.Container>
                                <UserDisplay.Container>
                                    <UserDisplay.Title><Translate id="teams.profile.label.tournament" /></UserDisplay.Title>
                                    <UserDisplay.Subtitle><Translate id="teams.profile.label.tournament.enter" /></UserDisplay.Subtitle>
                                </UserDisplay.Container>
                                {data.isLoading &&
                                    <UserDisplay.Container className="ml-auto">
                                        <Spinner type="grow" color="primary" size="sm" />
                                    </UserDisplay.Container>
                                }
                            </UserDisplay>
                        </CrossFade>
                    </ModalBody>
                    <CrossFade isVisible={!!formik.values.mode}>
                        <ParticipateChooseCompetition
                            teamId={teamId}
                            orgsAppliedTo={orgsAppliedTo}
                            toggle={toggle}
                            categoryIds={data.categoryIds}
                        />
                    </CrossFade>
                </>
            )}
        </FormikModalForm>
    )
}

export default ParticipateInTournamentLeagueModal;