import { FormikDateTime, FormikError, FormikInputText, FormikRadioButton, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import moment from "moment";
import { useContext, useRef } from "react";
import { Button, Col, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { object, string, mixed } from "yup";
import Required from "../../../../../../components/formik/Required";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { PeriodsContext } from "../../../../../../contexts/contexts";
import { RegistrationCategoriesContext } from "../../../../../../contexts/RegistrationCategoriesContext";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { DisplayCategory } from "../../../../../teams/TeamHelpers";
import { AddSupervisionFormContext } from "../components/AddSupervisionFormContext";
import TeamCard from "./TeamCard";

const StepGlobalForm = () => {
    const { getOrganizationPeriods: getPeriods } = useContext(PeriodsContext);
    const { getRegistrationCategories: getTeamCategories } = useContext(RegistrationCategoriesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { formData, previous, next, updateFormData } = useContext(AddSupervisionFormContext);
    const teamCatRef = useRef(null);

    const handleOnPrevious = () => {
        previous();
        updateFormData('official', null);
    }

    return (
        <>
            <Formik
                initialValues={{
                    league: "",
                    date: moment(),
                    period_id: "",
                    home_team_name: "",
                    team_category_id: "",
                    official_type: "",
                    away_team_name: "",
                    private_comment: "",
                }}
                validationSchema={object().shape({
                    league: string(),
                    date: mixed().isDate().required(<Translate id='officialSupervision.addSupervision.date.required' />),
                    period_id: string().required(<Translate id='officialSupervision.addSupervision.period.required' />),
                    home_team_name: string().required(<Translate id='officialSupervision.addSupervision.teamName.required' />),
                    away_team_name: string().required(<Translate id='officialSupervision.addSupervision.teamName.required' />),
                    official_type: string().required(<Translate id="officialSupervision.addSupervision.officialType.required" />),
                    team_category_id: string().required(<Translate id='officialSupervision.addSupervision.teamCategory.required' />),
                    private_comment: string(),
                })}
                onSubmit={(values) => {
                    updateFormData('global', values);
                    next();
                }}
            >
                {(formik) => (
                    <Form>
                        <ModalBody className="pt-0">
                            <Row form>
                                <Col className="mb-3" sm="6">
                                    <Label for="period_id" className="text-muted"><Translate id='officialSupervision.season.label' /> <Required /></Label>
                                    {formData.official?.member_id &&
                                        <FormikSelect
                                            id="period_id"
                                            name="period_id"
                                            renderOption={({ option }) => (
                                                <DisplayI18n
                                                    field="name"
                                                    i18n={option.i18n}
                                                    defaultValue={option.label}
                                                />
                                            )}
                                            onOptionSelected={([ val ]) => {
                                                teamCatRef.current?.getSpordleTable().filterChange('period_id', val);
                                            }}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return getPeriods(formData.official.organisation?.organisation_id)
                                                            .then((periods) => {
                                                                const currentPeriod = periods.find((period) => period.active == 1 && period.current == 1);

                                                                if(currentPeriod){
                                                                    formik.setFieldValue('period_id', currentPeriod.period_id);
                                                                    teamCatRef.current?.getSpordleTable().filterChange('period_id', currentPeriod.period_id);
                                                                }

                                                                return periods.map((period) => ({ label: period.name, i18n: period.i18n, value: period.period_id }))
                                                            })
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                    }
                                </Col>
                                <Col className="mb-3" sm="6">
                                    <Label for="date" className="text-muted">
                                        <Translate id='form.fields.date' /> <Required />
                                    </Label>
                                    <FormikDateTime
                                        id="date"
                                        name="date"
                                        dateFormat="YYYY-MM-DD"
                                        placeholder='form.validation.date.placeholder'
                                        timeFormat={false}
                                        isValidDate={(current) => moment(current).isSameOrBefore(moment(), 'days')}
                                    />
                                </Col>
                                <Col className="mb-3" sm="6">
                                    <Label for="league" className="text-muted"><Translate id='officialSupervision.addSupervision.league.label' /></Label>
                                    <FormikInputText name="league" id="league" />
                                </Col>
                                <Col className="mb-3" sm="6">
                                    <Label for="team_category_id" className="text-muted"><Translate id='officialSupervision.teamCategory.label' /> <Required /></Label>
                                    <FormikSelect
                                        initFilter={{
                                            period_id: "",
                                        }}
                                        ref={teamCatRef}
                                        id="team_category_id"
                                        name="team_category_id"
                                        searchKeys={[
                                            `class.i18n.${getGenericLocale()}.name`,
                                            `class.i18n.${getGenericLocale()}.short_name`,
                                            `division.i18n.${getGenericLocale()}.name`,
                                            `division.i18n.${getGenericLocale()}.short_name`,
                                        ]}
                                        renderSelectedOption={(option) => <DisplayCategory category={option} short />}
                                        renderOption={({ option }) => (
                                            <>
                                                <DisplayCategory category={option} short />
                                                <div className="small">
                                                    <DisplayCategory category={option} />
                                                    <div className="text-muted">
                                                        <Translate id={option.label} />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        loadData={(from, { filters }) => {
                                            switch (from){
                                                case 'FILTER':
                                                    if(filters.period_id && formData.official?.organisation?.organisation_id){
                                                        return getTeamCategories(formData.official.organisation.organisation_id, filters.period_id, { active: 1 })
                                                            .then((teamCat) => {
                                                                return teamCat.map((theCat) => ({ ...theCat, label: `form.fields.gender.${(theCat.gender || "").toLowerCase()}`, translateLabel: true, value: theCat.team_category_id }))
                                                            })
                                                    }
                                                case 'CDM':
                                                    return Promise.resolve([]);
                                                default:
                                                    break;
                                            }
                                        }}
                                    />
                                </Col>
                                <Col className="mb-3" sm="6">
                                    <TeamCard />
                                </Col>
                                <Col className="mb-3" sm="6">
                                    <TeamCard teamPos="away" />
                                </Col>
                                <Col xs="12" className="mb-3">
                                    <Label for="official_type" className="text-muted"><Translate id="officialSupervision.officialType.label" /> <Required /></Label>
                                    <div className="d-block mb-2">
                                        <FormikRadioButton
                                            label="officialSupervision.officialType.REFEREE"
                                            name="official_type"
                                            id="referee"
                                            value="REFEREE"
                                        />
                                    </div>
                                    <div className="d-block mb-2">
                                        <FormikRadioButton
                                            label="officialSupervision.officialType.LINESPERSON"
                                            name="official_type"
                                            id="linesperson"
                                            value="LINESPERSON"
                                        />
                                    </div>
                                    <div className="d-block mb-2">
                                        <FormikRadioButton
                                            label="officialSupervision.officialType.OFFICIAL_SYSTEM"
                                            name="official_type"
                                            id="official-system"
                                            value="OFFICIAL_SYSTEM"
                                        />
                                    </div>
                                    <FormikError name="official_type" />
                                </Col>
                            </Row>
                            <Label for="private_comment" className="text-muted">
                                <Translate id='officialSupervision.privateComment.label' />
                            </Label>
                            <FormikTextArea
                                name="private_comment"
                                id="private_comment"
                            />
                            <div className="mt-1 small text-muted"><i className="mdi mdi-information-outline text-primary" /> <Translate id='officialSupervision.addSupervision.privateComment.helper' /></div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={handleOnPrevious} type="button" className="w-50 w-md-auto mr-auto" outline color="primary">
                                <Translate id="misc.previous" />
                            </Button>
                            <Button type="submit" color="primary" className="w-50 w-md-auto">
                                <Translate id="misc.next" />
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default StepGlobalForm;