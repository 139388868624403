import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';

import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';
import { IdentityRolesContext } from './IdentityRolesContext';
import { PeriodsContext } from './contexts';
import { jsObjectToApi } from '@spordle/helpers';

/** @type {React.Context<Omit<AffiliationFeesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & AffiliationFeesContextProvider['state']>} */
export const AffiliationFeesContext = createContext();
AffiliationFeesContext.displayName = 'AffiliationFeesContext';

class AffiliationFeesContextProvider extends React.Component{

    /**
     * Gets all the organization's other items
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Affiliation%20Fees/Apicontroller%5CPricing%5CAffiliationfees%3A%3AgetAllAffiliationFee|documentation}
     * @returns {Promise.<Array>}
     */
    getAffiliationFees = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/affiliation-fees',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.affiliation_fees;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get Affiliation Fee information
     * @param {string} affiliationFeeId
     * @returns {Promise}
     */
    getAffiliationFee = (affiliationFeeId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/affiliation-fees/${affiliationFeeId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.affiliation_fees[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a specified Affiliation Fee with new values
     * @param {string} affiliationFeeId ID of the Affiliation Fee to update
     * @param {object} values Object containing the values to update - Refer to the {@link https://api.id.spordle.dev/documentations/#/Affiliation%20Fees/Apicontroller%5CPricing%5CAffiliationfees%3A%3AupdatePartiallyAffiliationFee|documentation}
     * @returns {Promise}
     */
    updateAffiliationFeePartial = (affiliationFeeId, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                default: // name, description, etc
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/affiliation-fees/${affiliationFeeId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a Affiliation Fee under an Organization with specified values
     * @param {string} organizationId ID of the Organization to add a Affiliation Fee to
     * @param {object} [values] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Memos/Apicontroller%5CMembers%5CMembermemos%3A%3AcreateMemberMemo|documentation}
     * @returns {Promise}
     */
    createAffiliationFee = (organizationId, periodId, values) => {
        const params = new URLSearchParams({
            organisation_id: organizationId,
            period_id: periodId,
        })

        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', values.active ? 1 : 0);
                    break;
                case 'division_id':
                    if(Array.isArray(values[key])){
                        values[key].forEach((divisionId, index) => {
                            params.append(`division_id[${index}]`, divisionId);
                        });
                    }else{
                        params.append(`division_id[0]`, values[key]);
                    }
                    break;
                case 'apply_for_organisation':
                    if(Array.isArray(values[key])){
                        values[key].forEach((orgId, index) => {
                            params.append(`apply_for_organisation[${index}]`, orgId);
                        });
                    }else{
                        params.append(`apply_for_organisation[0]`, values[key]);
                    }
                    break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/affiliation-fees` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.affiliation_fee_id; // Returns array of affilitation_fee_ids because possibility to create multiple with array of divisions
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a specific Affiliation Fee
     * @param {string} affiliationFeeId ID of the Affiliation Fee to delete
     * @returns {Promise}
     */
    deleteAffiliationFee = (affiliationFeeId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/affiliation-fees/${affiliationFeeId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PUT] Updates the waivers list associated with the affiliation-fee
     * @param {string} affiliationFeeId ID of the affiliation fee to update
     * @param {object} waivers Object containing the values to update
     * @returns {Promise}
     */
    updateAffiliationFeeWaiver = (affiliationFeeId, waivers) => {
        const params = {};

        if(Array.isArray(waivers)){
            waivers.map((value, i) => {
                params[`waivers[${i}]`] = value;
            });
        }

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `affiliation-fees/${affiliationFeeId}/waivers` }), new URLSearchParams(params))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    updateAffiliationFees = (updatedFees) => {
        const params = new URLSearchParams();
        jsObjectToApi(updatedFees, params, { skipEmptyString: true, skipNull: true });
        return API_SPORDLE.put(queryString.stringifyUrl({ url: `affiliation-fees` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    render(){
        return (
            <AffiliationFeesContext.Provider value={{ ...this }}>
                {this.props.children}
            </AffiliationFeesContext.Provider>
        )
    }
}

export default withContexts(OrganizationContext, IdentityRolesContext, PeriodsContext)(AffiliationFeesContextProvider)