import { useContext } from "react";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import { useDisclosure } from "@mantine/hooks";
import MultiOrganizationModal from "./MultiOrganizationModal";
import Translate from "@spordle/intl-elements";

const MultiOrganization = ({ member }) => {
    const { settings } = useContext(OrganizationContext);
    const [ isOpen, { open, close } ] = useDisclosure(false);

    const availableShareableTypes = Array.isArray(settings.available_member_type_for_multi_organisation?.value) ? settings.available_member_type_for_multi_organisation.value : [];

    const memberShareableTypes = (member.member_types || []).filter(({ current_season, active, member_type, organisation }) => {
        const isActive = active == 1;
        const isCurrentSeason = current_season == 1;
        const isPrimaryOrgMemberType = organisation?.organisation_id === member.organisation.organisation_id;
        const canBeShared = !!member_type && availableShareableTypes.includes(member_type.member_type_id);

        return isActive && isCurrentSeason && isPrimaryOrgMemberType && canBeShared;
    });

    const canShare = availableShareableTypes.length > 0 && memberShareableTypes.length > 0;
    // const coachSettings = {
    //     minAge: settings.coach_multi_organisation_min_age, // API will manage this error
    //     orgLimit: settings.coach_multi_organisation_limit, // API will manage this error
    //     workflow: settings.coach_multi_organisation_workflow,
    // }

    // const playerSettings = {
    //     minAge: settings.player_multi_organisation_min_age, // API will manage this error
    //     orgLimit: settings.player_multi_organisation_limit, // API will manage this error
    //     workflow: settings.player_multi_organisation_workflow,
    // }

    if(!canShare){
        return null;
    }

    return (
        <>
            <MultiOrganizationModal isOpen={isOpen} toggle={close} member={member} memberShareableTypes={memberShareableTypes} />
            <SidePanel.MenuAction
                id='member-actions-multi-organization'
                action='ADD'
                componentCode='members'
                componentPermissionCode='members_request_transfer'
                onClick={open}
            >
                <Translate id="members.profile.multipleorganization.title" values={{ sup: (t) => <sup>{t}</sup> }} />
            </SidePanel.MenuAction>
        </>
    );
}

export default MultiOrganization;