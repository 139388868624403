import { Tooltip } from "@mantine/core";
import Translate from "@spordle/intl-elements";
import { useState } from "react";
import { Button } from "reactstrap";

/**
 * @deprecated Use ConfirmBtn instead
 */
export const InlineConfirmation = ({ onClick, disabled, primaryButtonTranslation, color, confirmationTooltipTitle, confirmationTooltipText, tooltipZIndex, solidConfirmButton }) => {
    const [ isConfirming, setIsConfirming ] = useState(false);

    const handleOnClick = () => {
        if(isConfirming){
            onClick();
        }else{
            setIsConfirming(true);
        }
    }

    return (
        <Tooltip
            className="mr-auto"
            withArrow={false}
            zIndex={tooltipZIndex || 3000}
            opened={isConfirming}
            label={
                <div className="text-center">
                    <div className="font-medium h5 mb-0 text-white"><Translate id={confirmationTooltipTitle || 'helpCenter.deleteConfirmation.tooltip.title'} /></div>
                    <div>
                        <Translate id={confirmationTooltipText || 'helpCenter.deleteConfirmation.tooltip.text'} values={{ br: () => <br /> }} />
                    </div>
                </div>
            }
        >
            <Button className="mr-1" disabled={disabled} onClick={handleOnClick} type="button" color={color || "danger"} outline={!isConfirming && !solidConfirmButton}>
                {isConfirming ? <Translate id='misc.confirm' /> : <Translate id={primaryButtonTranslation || 'misc.delete'} />}
            </Button>
            {isConfirming &&
                <Button outline color={color || "danger"} type="button" onClick={() => setIsConfirming(false)}><Translate id='misc.cancel' /></Button>
            }
        </Tooltip>
    )
}