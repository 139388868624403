import ArchivedMessages from "../../views/communications/ArchivedMessages";
import FavoriteMessages from "../../views/communications/FavoriteMessages";
import ReceivedMessages from "../../views/communications/ReceivedMessages";
import SentMessages from "../../views/communications/SentMessages";

const communicationsSidebar = [
    {
        navlabel: true,
        name: 'communications.inbox',
        subPermissionsRoutes: [
            {
                exact: true,
                path: '/communications/received',
                name: "communications.inbox.received",
                skipRecents: true,
                component: ReceivedMessages,
            },
            {
                exact: true,
                path: '/communications/sent',
                name: "communications.inbox.sent",
                skipRecents: true,
                component: SentMessages,
            },
            {
                exact: true,
                skipRecents: true,
                name: "communications.inbox.favorites",
                path: '/communications/favorites',
                component: FavoriteMessages,
            },
            {
                exact: true,
                skipRecents: true,
                name: "communications.inbox.archived",
                path: '/communications/archived',
                component: ArchivedMessages,
            },
        ],
    },
];

export default communicationsSidebar;