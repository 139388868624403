import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { Collapse } from "reactstrap";
import BlinkDot from "../blinkDot/BlinkDot";

const SavedSearchCue = ({ savedSearch, clearSavedSearch, className }) => {
    return (
        <Collapse isOpen={!!savedSearch} unmountOnExit className={stringBuilder("small text-muted", className)}>
            <BlinkDot size="sm" className="mr-2" /><Translate id="components.savedSearchCue.msg" /> <button onClick={clearSavedSearch} type="button" className="reset-btn text-primary"><Translate id="components.savedSearchCue.clear" /></button>
        </Collapse>
    );
}

export default SavedSearchCue;