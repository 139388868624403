import Translate from "@spordle/intl-elements"
import { Form, Formik } from "formik"
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { object, string } from "yup"
import AnalyticsModal from "../../../../analytics/AnalyticsModal"
import { WaiverContent, WaiverFields, WaiverHeader, WaiverMandatoryHelper } from "../../../../helpers/waiversHelper"

const WaiverPreviewModal = ({ isOpen, toggle, waiver }) => {

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='waiverPreviewModal'>
            <Formik
                initialValues={{
                    answer: '',
                }}
                validationSchema={object().shape({
                    answer: string().required(<Translate id='form.fields.waiver.required' />),
                })}
                onSubmit={() => {
                    toggle();
                }}
            >
                <Form>
                    <ModalHeader tag='div' toggle={toggle}>
                        <WaiverHeader waiver={waiver} />
                    </ModalHeader>
                    <ModalBody>
                        <WaiverContent waiver={waiver} />

                        <WaiverMandatoryHelper waiver={waiver?.waiver} />

                        <WaiverFields waiver={waiver?.waiver} fieldName='answer' allowSkip={waiver?.waiver.mandatory == 0} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' type='submit'><Translate id='misc.save' /></Button>
                        <Button color='primary' type='button' onClick={toggle} outline><Translate id='misc.cancel' /></Button>
                    </ModalFooter>
                </Form>
            </Formik>
        </AnalyticsModal>
    )
}

export default WaiverPreviewModal