import Translate from '@spordle/intl-elements';
import moment from "moment";
import { Badge } from "reactstrap";
import { exportToCsv } from "../../helpers/export";
import { DisplayI18n, displayI18n } from "../../helpers/i18nHelper";

export const genders = [
    'MALE',
    'FEMALE',
    'MIXED',
];

// In case it changes, so it avoids going through all the code
// just to change some constants
export const APPROVED = "APPROVED";
export const REJECTED = "REJECTED";
export const PENDING = "PENDING";
export const PENDING_APPROVAL = "PENDING APPROVAL";
export const DRAFT = "DRAFT";
export const CANCEL = "CANCEL"; // used to update status tu null/draft

/**
* @param {Object} stat Status object
* @returns {JSX.Element}
*/
export const getStatus = (stat) => {
    if(stat){
        const content = <DisplayI18n field="name" i18n={stat.i18n} defaultValue={stat.name} />;

        switch (stat.name?.toLowerCase()){
            case 'draft': // just in case
                return <Badge color='light'>{content}</Badge>
            case 'pending':
            case 'pending approval':
                return <Badge color='warning'>{content}</Badge>
            case 'rejected':
                return <Badge color='danger'>{content}</Badge>
            case 'approved':
            case 'active':
                return <Badge color='success'>{content}</Badge>
            case 'submitted':
            default:
                return <Badge color="info">{content}</Badge>
        }
    }else{
        return <Badge color="light"><Translate id='teams.teamSearch.select.status.DRAFT' /></Badge>;
    }
}

export const getTeamMemberStatus = (status) => {
    switch (status){
        case 'APPROVED':
            return <Badge color='success'><Translate id={'teams.profile.roster.table.status.' + status} /></Badge>
        case 'PENDING':
            return <Badge color='warning'><Translate id={'teams.profile.roster.table.status.' + status} /></Badge>
        case 'DECLINED':
        case 'INELIGIBLE':
        case 'PENDING_DEFICIENT':
            return <Badge color='danger'><Translate id={'teams.profile.roster.table.status.' + status} /></Badge>
    }
}

/**
 * Returns address joined with ,
 * @param {Object} data
 * @param {string} [data.street]
 * @param {string} [data.street_number]
 * @param {string} [data.po_box]
 * @param {string} [data.zip_code]
 * @param {string} [data.country_code]
 * @param {string} [data.city]
 * @param {string} [data.province_code]
 * @param {string} [data.address_mode]
 * @returns {string}
 */
export const getLocation = (data) => {
    const address = [];

    if(data?.street){
        if(data.street_number) address.push(data.street_number);
        address.push(data.street);
        if(data.city) address.push(data.city);
        if(data.province_code || data.province_id) address.push(data.province_code || data.province_id);
        if(data.zip_code) address.push(data.zip_code);
        if(data.country_code || data.country_id) address.push(data.country_code || data.country_id);
    }else if(data?.po_box){
        if(data.po_box) address.push(data.po_box);
        if(data.city) address.push(data.city);
        if(data.province_code || data.province_id) address.push(data.province_code || data.province_id);
        if(data.zip_code) address.push(data.zip_code);
        if(data.country_code || data.country_id) address.push(data.country_code || data.country_id);
    }

    return address.length > 0 ? address.join(', ') : '';
}

/**
 *
 * @param {Object} props
 * @param {object} props.category Category that contains class + division
 * @param {object} props.category.division
 * @param {object} [props.category.class]
 * @param {boolean} [props.short=false] Will display short name if set to true
 * @param {string} [props.field="short_name"] Will be name if short to true
 * @returns {JSX.Element|string}
 */
export const DisplayCategory = ({ category, short = false, field = short ? "short_name" : "name" }) => {
    return (
        category ?
            <>
                {category.i18n ?
                    <DisplayI18n i18n={category.i18n} defaultValue={category.name || '-'} field={"name"} />
                    :
                    <>
                        {category.division && <DisplayI18n i18n={category.division.i18n} defaultValue={category.division[field]} field={field} />}
                        { category.class &&
                            <>{' - '}<DisplayI18n i18n={category.class.i18n} defaultValue={category.class[field]} field={field} /></>
                        }
                    </>
                }
            </>
            :
            '-'
    )
}

export const DisplayTeamCategory = ({ ressource }) => {
    if(!ressource){
        return null;
    }

    const subressource = ressource.team_category?.i18n ? ressource.team_category : ressource.division;

    if(!subressource){
        return "-"
    }

    return (
        <DisplayI18n field="name" i18n={subressource.i18n} defaultValue={subressource.name} />
    )
}

/**
 *
 * @param {object} category Category that contains class + division
 * @param {string} field Field to display (short_name, name, etc.)
 * @param {string} locale Generic locale
 * @returns {string}
 */
export function displayCategory(category, field, locale){
    let returnString = '';

    if(category){
        if(category.division){
            returnString = returnString + displayI18n(field, category.division.i18n, category.division[field], locale)
        }
        if(category.class){
            returnString = returnString + ' - ' + displayI18n(field, category.class.i18n, category.class[field], locale)
        }
    }
    return returnString
}

export const exportTeamsToCSV = (teams, formatMessage) => {
    exportToCsv(`SpordleID-TeamsSearch ${moment().format('YYYY-MM-DD H:mm')}.csv`, [
        [// Headers
            formatMessage({ id: 'teams.teamSearch.table.label.category' }),
            formatMessage({ id: 'teams.teamSearch.table.label.team' }),
            formatMessage({ id: 'teams.teamSearch.table.label.org' }),
            formatMessage({ id: 'misc.status' }),
        ],
        ...teams.map((team) => (
            [
                team.team_category.division?.name + team.team_category.class?.name ? ' - ' + team.team_category.class?.name || '' : '',
                team.name,
                team.organisation?.organisation_name,
                team.team_status?.name || formatMessage({ id: 'teams.teamSearch.select.status.DRAFT' }),
            ]
        )),
    ]);
};