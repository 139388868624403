import Translate from '@spordle/intl-elements';
import { Formik } from 'formik';
import { useContext, useState } from 'react';
import { Button, Card, CardBody, Container } from 'reactstrap';
import ContactsTable from './components/ContactsSection/ContactsTable';
import InstructorsTable from './components/InstructorsSection/InstructorsTable';
import SessionsTable from './components/SessionsSection/SessionsTable';
import { object, number } from 'yup';
import { FormikError } from '@spordle/formik-elements';
import { ClinicsContext } from '../../../../contexts/ClinicsContext';

const ClinicSessionInformation = (props) => {

    const clinicsContext = useContext(ClinicsContext);

    const inCreateClinic = props.wizard;

    const [ instructorsKey, setInstructorsKey ] = useState(false);
    const updateInstructorsKey = () => setInstructorsKey(!instructorsKey);

    return (
        <Formik
            initialValues={{
                numberOfContacts: clinicsContext.currentClinic?.contacts?.length || 0,
                numberOfSessions: clinicsContext.currentClinic?.sessions?.length || 0,
            }}
            validationSchema={object().shape({
                numberOfContacts: number().min(1, <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.minimum' />),
                numberOfSessions: number().min(1, <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.minimum' />),
            })}
            onSubmit={() => {
                props.exited();
            }}
        >
            {(formik) => (
                <Container>
                    <Card className='card-shadow'>
                        <CardBody>
                            {!inCreateClinic &&
                                <div className="border-bottom pb-1 mb-4 d-flex align-items-end">
                                    <div className="card-title font-bold h4 mb-0"><Translate id='clinics.clinicSessionInformation.clinicSessionInformation.title' /></div>
                                </div>
                            }

                            <div className={inCreateClinic ? 'mb-5' : 'mb-4'}>
                                <ContactsTable
                                    wizard={inCreateClinic}
                                    clinicId={props.clinicId}
                                    updateInstructorsKey={updateInstructorsKey}
                                    setNumberOfContacts={(number) => formik.setFieldValue('numberOfContacts', number)}
                                />

                                <FormikError name='numberOfContacts' />
                            </div>
                            <div className={inCreateClinic ? 'mb-5' : 'mb-4'}>
                                <InstructorsTable wizard={inCreateClinic} clinicId={props.clinicId} instructorsKey={instructorsKey} />
                            </div>
                            <div>
                                <SessionsTable wizard={inCreateClinic} clinicId={props.clinicId} setNumberOfSessions={(number) => formik.setFieldValue('numberOfSessions', number)} />

                                <FormikError name='numberOfSessions' />
                            </div>
                        </CardBody>
                    </Card>
                    {props.wizard &&
                        <div className="d-flex mb-5">
                            <Button color="primary" outline type='button' onClick={props.previousStep}><Translate id='misc.previous' /></Button>
                            <Button color="primary" className="ml-auto" type='button' onClick={() => formik.submitForm()}><Translate id='misc.next' /></Button>
                        </div>
                    }
                </Container>
            )}
        </Formik>
    )
}

export default ClinicSessionInformation