import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { formatSelectData } from "@spordle/spordle-select";
import { useContext, useEffect, useState } from "react";
import { Label } from "reactstrap";
import { object, string } from 'yup';
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import CollapsibleCard from "../../../../../../../components/collapsibleCard/CollapsibleCard";
import CrossFade from "../../../../../../../components/crossFade/CrossFade";
import FormikEditable from "../../../../../../../components/formik/FormikEditable";
import { fail } from "@spordle/toasts";
import { TeamsContext } from "../../../../../../../contexts/TeamsContext";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import { isCoach, isManager } from "../../../gameIncidentHelper";

/**
 * @param {Object} props
 * @param {object} team
 * @param {'home'|'away'} [pos='home]
 * @returns {JSX.Element}
 */
const GameIncidentTeamCard = ({ updateValues, team, pos = 'home', canEdit }) => {
    const { getTeamMembers } = useContext(TeamsContext);
    const [ teamMembers, setTeamMembers ] = useState([]);
    const fieldsPrefix = pos + '_team';
    const isAnExistingTeam = !!team.team_id;

    useEffect(() => {
        if(isAnExistingTeam){
            getTeamMembers(team.team_id)
                .then((members) => {
                    const players = members
                        .sort((a, b) => {
                            const aName = a.member.first_name + ' ' + a.member.last_name;
                            const bName = b.member.first_name + ' ' + b.member.last_name;
                            return aName.localeCompare(bName);
                        })
                        .reduce((data, mem) => {
                            if(isManager(mem.position?.position_group) || isCoach(mem.position?.position_group)){
                                data.push(mem);
                            }
                            return data;
                        }, []);

                    setTeamMembers(players);
                }).catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                        setTeamMembers([]);
                    }
                })
        }
    }, [ team.team_id ]);

    return (
        <CollapsibleCard
            className="mb-3"
            initOpen
            title={team.name}
            subtitle={
                <>
                    <span className="d-block">
                        {team.short_name}
                    </span>
                    {!!team.unique_identifier &&
                        <span>
                            #{team.unique_identifier}
                        </span>
                    }
                </>
            }
        >
            <Label className="text-muted mb-1"><Translate id='gameIncident.addModal.label.coach' /></Label>
            <FormikEditable
                disabled={!canEdit}
                id={fieldsPrefix + "coach"}
                initialValues={{
                    [fieldsPrefix + '_coach_id']: team.coach?.member_id ? team.coach.member_id : team.coach_name ? "OTHER" : '',
                    [fieldsPrefix + '_coach_name']: team.coach?.member_id ? '' : team.coach_name || '',
                }}
                className="editable-transparent"
                validationSchema={object().shape({
                    [fieldsPrefix + '_coach']: string(),
                    [fieldsPrefix + '_coach_name']: string(),
                })}
                onSubmit={(values) => {
                    if(values[fieldsPrefix + '_coach_id'] !== team.coach?.member_id || values[fieldsPrefix + '_coach_name'] !== team.coach_name){
                        const apiValues = {};
                        const tableValues = { ...team };

                        if(!isAnExistingTeam || values[fieldsPrefix + '_coach_id'] === "OTHER"){
                            apiValues[fieldsPrefix + '_coach_name'] = values[fieldsPrefix + '_coach_name'];
                            apiValues[fieldsPrefix + '_coach_id'] = "";
                            tableValues.coach_name = values[fieldsPrefix + '_coach_name'];
                            tableValues.coach = null;
                        }else{
                            const member = teamMembers.find((m) => m.member.member_id === values[fieldsPrefix + '_coach_id']);
                            apiValues[fieldsPrefix + '_coach_id'] = values[fieldsPrefix + '_coach_id'];
                            tableValues.coach = member.member;
                        }

                        updateValues(apiValues, { [fieldsPrefix]: tableValues });
                    }
                }}
            >
                {(isEditing, _options, formik) => {
                    if(!isEditing){
                        return (
                            <div>{team.coach?.first_name ? team.coach.first_name + ' ' + team.coach.last_name : team.coach_name || '-'}</div>
                        )
                    }
                    return (
                        <>
                            {isAnExistingTeam &&
                                    <FormikSelect
                                        name={fieldsPrefix + '_coach_id'}
                                        id={fieldsPrefix + '_coach_id'}
                                        clearable
                                        defaultMenuIsOpen={formik?.values[fieldsPrefix + '_coach_id'] !== "OTHER"}
                                        autoFocus={formik?.values[fieldsPrefix + '_coach_id'] !== "OTHER"}
                                        isLoading={teamMembers === null}
                                        search
                                        renderOption={(opt) => (
                                            opt.option.value !== 'OTHER' && !opt.option.isGroup ?
                                                opt.option.label
                                                :
                                                <Translate id={opt.option.label} />
                                        )}
                                        options={teamMembers ?
                                            formatSelectData(
                                                teamMembers.reduce((staffList, staff) => {
                                                    if(isCoach(staff.position?.position_group)){
                                                        staffList.push({
                                                            value: staff.member.member_id,
                                                            label: staff.member.first_name + ' ' + staff.member.last_name,
                                                        })
                                                    }
                                                    return staffList;
                                                }, [ {
                                                    value: 'OTHER',
                                                    label: 'gameIncident.addModal.label.coach.option.other',
                                                    translateLabel: true,
                                                } ]),
                                                {
                                                    getGroupId: (member) => member.value == 'OTHER' ? undefined : 'coachList',
                                                    newGroupIndexes: {
                                                        1: {
                                                            groupId: 'coachList',
                                                            value: 'coach',
                                                            label: 'gameIncident.addModal.label.coachList',
                                                            isGroup: true,
                                                        },
                                                    },
                                                },

                                            )
                                            : []
                                        }
                                    />
                            }
                            <CrossFade isVisible={formik?.values[fieldsPrefix + '_coach_id'] === "OTHER" || !isAnExistingTeam}>
                                <FormikInputText
                                    className="mt-2"
                                    name={fieldsPrefix + '_coach_name'}
                                    placeholder='gameIncident.addModal.label.coach.other'
                                    trim
                                />
                            </CrossFade>
                        </>
                    )

                }}
            </FormikEditable>

            <Label className="text-muted mb-1 mt-2"><Translate id='gameIncident.addModal.label.manager' /></Label>
            <FormikEditable
                disabled={!canEdit}
                id={fieldsPrefix + "manager"}
                initialValues={{
                    [fieldsPrefix + '_manager_id']: team.manager?.member_id ? team.manager.member_id : team.manager_name ? "OTHER" : '',
                    [fieldsPrefix + '_manager_name']: team.manager?.member_id ? '' : team.manager_name || '',
                }}
                className="editable-transparent"
                validationSchema={object().shape({
                    [fieldsPrefix + '_manager']: string(),
                    [fieldsPrefix + '_manager_name']: string(),
                })}
                onSubmit={(values) => {
                    if(values[fieldsPrefix + '_manager_id'] !== team.manager?.member_id || values[fieldsPrefix + '_manager_name'] !== team.manager_name){
                        const apiValues = {};
                        const tableValues = { ...team };

                        if(!isAnExistingTeam || values[fieldsPrefix + '_manager_id'] === "OTHER"){
                            apiValues[fieldsPrefix + '_manager_name'] = values[fieldsPrefix + '_manager_name'];
                            apiValues[fieldsPrefix + '_manager_id'] = "";
                            tableValues.manager_name = values[fieldsPrefix + '_manager_name'];
                            tableValues.manager = null;
                        }else{
                            const member = teamMembers.find((m) => m.member.member_id === values[fieldsPrefix + '_manager_id']);
                            apiValues[fieldsPrefix + '_manager_id'] = values[fieldsPrefix + '_manager_id'];
                            tableValues.manager = member.member;
                        }

                        updateValues(apiValues, { [fieldsPrefix]: tableValues });
                    }
                }}
            >
                {(isEditing, _options, formik) => {
                    if(!isEditing){
                        return (
                            <div>{team.manager?.first_name ? team.manager.first_name + ' ' + team.manager.last_name : team.manager_name || '-'}</div>
                        )
                    }
                    return (
                        <>
                            {isAnExistingTeam &&
                                    <FormikSelect
                                        name={fieldsPrefix + '_manager_id'}
                                        id={fieldsPrefix + '_manager_id'}
                                        clearable
                                        search
                                        menuIsDefaultOpen={formik?.values[fieldsPrefix + '_manager_id'] !== "OTHER"}
                                        autoFocus={formik?.values[fieldsPrefix + '_manager_id'] !== "OTHER"}
                                        isLoading={teamMembers === null}
                                        renderOption={(opt) => (
                                            opt.option.value !== 'OTHER' && !opt.option.isGroup ?
                                                opt.option.label
                                                :
                                                <Translate id={opt.option.label} />
                                        )}
                                        options={teamMembers ?
                                            formatSelectData(
                                                teamMembers.reduce((staffList, staff) => {
                                                    if(isManager(staff.position?.position_group)){
                                                        staffList.push({
                                                            value: staff.member.member_id,
                                                            label: staff.member.first_name + ' ' + staff.member.last_name,
                                                        })
                                                    }
                                                    return staffList;
                                                }, [ {
                                                    value: 'OTHER',
                                                    label: 'gameIncident.addModal.label.coach.option.other',
                                                    translateLabel: true,
                                                } ]),
                                                {
                                                    getGroupId: (member) => member.value == 'OTHER' ? undefined : 'coachList',
                                                    newGroupIndexes: {
                                                        1: {
                                                            groupId: 'coachList',
                                                            value: 'coach',
                                                            label: 'gameIncident.addModal.label.coachList',
                                                            isGroup: true,
                                                        },
                                                    },
                                                },

                                            )
                                            : []
                                        }
                                    />
                            }
                            <CrossFade isVisible={formik?.values[fieldsPrefix + '_manager_id'] === "OTHER" || !isAnExistingTeam}>
                                <FormikInputText
                                    className="mt-2"
                                    name={fieldsPrefix + '_manager_name'}
                                    placeholder='gameIncident.addModal.label.manager.other'
                                    trim
                                />
                            </CrossFade>
                        </>
                    )

                }}
            </FormikEditable>
        </CollapsibleCard>
    );
}


export default GameIncidentTeamCard;