import React from "react";

import MemberGeneral from "./components/MemberGeneral";
import MemberAddressPhone from "./components/memberAddressPhone/MemberAddressPhone";
import MemberContacts from './components/memberContacts/MemberContacts';
import MemberFamilyRelations from './components/memberFamilyRelations/MemberFamilyRelations';
import MemberMemos from './components/memberMemos/MemberMemos';
import MemberLogs from './components/memberLogs/MemberLogs';

// contexts
import { MembersContext } from '../../../../contexts/MembersContext';
import withContexts from '../../../../helpers/withContexts';
import MemberDocuments from "./components/memberDocuments/MemberDocuments";
import HasAccessTo from "../../../../components/permissions/HasAccessTo";
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import MemberLinkedAccounts from "./components/memberFamilyRelations/MemberLinkedAccounts";
import MemberDemographicInfo from "./components/MemberDemographicInfo";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import AddressConfirmationModal from "./components/memberAddressPhone/components/AddressConfirmationModal";
import MemberAddressPhoneModal from "./components/memberAddressPhone/components/MemberAddressPhoneModal";

class MemberProfileOverview extends React.Component{
    state = {
        confirmationModalOpen: false,
        currentAddress: {},
        isPrimary: false,
        addresses: this.props.MembersContext.currentMember?.addresses || [],
        isCurrentOrganizationOrParent: false,

        createEditModalOpen: false,
    };

    // component code is 'addressComponentMemberProfile' to scroll to address and phones component
    // component code is 'profileComponentMemberPrimaryInfo' to scroll to general info
    scrollToComponent = (componentCode) => {
        const card = document.getElementById(componentCode);

        if(card){
            window.scrollTo({
                behavior: 'smooth', // not supported on mac?
                top: card.getBoundingClientRect().top - 150, // 2 * header(70 px high) + 10px(give some edge)
            })
        }
    }

    componentDidMount(){
        const location = this.props.location;
        const componentCode = queryString.parse(location.search).goto;

        if(componentCode){
            // without the setTimeout the card component we are attempting to scroll to is found at position 0,0 therefore we need a timeount, even with it at 0 it works
            setTimeout(() => { this.scrollToComponent(componentCode) }, 0);
        }else{
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }, 0);
        }

        this.getAddresses()
        if(this.props.MembersContext.currentMember?.organisation?.organisation_id){
            this.props.OrganizationContext.isCurrentOrParentOrg(this.props.MembersContext.currentMember?.organisation?.organisation_id)
                .then((isCurrentOrParentOrg) => {
                    this.setState((prevState) => ({ isCurrentOrganizationOrParent: isCurrentOrParentOrg }))
                })
                .catch((error) => { console.error(error.message) });
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.confirmationModalOpen !== this.state.confirmationModalOpen)
            this.getAddresses();
    }

    GENERAL_FIELDS = [
        'first_name',
        'last_name',
        'birthdate',
        'email',
        'primary_language',
        'secondary_languages',
        'gender',
        // wouldn't make sense to have member types
    ]

    DEMOGRAPHIC_FIELDS = [
        'birth_country',
        'citizenship',
        'identify_as_indigenous',
        'ethnicity',
    ]

    fieldIsValid = (field) => {
        switch (field){
            case 'citizenship':
            case 'secondary_languages':
                return !!this.props.MembersContext.currentMember[field] && Array.isArray(this.props.MembersContext.currentMember[field]) && this.props.MembersContext.currentMember[field].length > 0
            default:
                return !!this.props.MembersContext.currentMember[field]
        }
    }

    showRedBorder = (section) => {
        let show = false
        if(this.props.MembersContext.currentMember.incomplete_profile == 1){
            for(let i = 0; i < this.props.OrganizationContext.settings.member_mandatory_special_fields_for_registration?.value?.length; i++){
                const field = this.props.OrganizationContext.settings.member_mandatory_special_fields_for_registration?.value[i];
                if(section === 'GENERAL' && this.GENERAL_FIELDS.indexOf(field) != -1 && !this.fieldIsValid(field)){
                    show = true;
                    break;
                }else if(section === 'DEMOGRAPHIC' && this.DEMOGRAPHIC_FIELDS.indexOf(field) != -1 && !this.fieldIsValid(field)){
                    show = true;
                    break;
                }
            }
        }
        return show
    }

    toggleAddressConfirmationModal = (address) => {
        this.setState((prevState) => ({ confirmationModalOpen: !prevState.confirmationModalOpen, currentAddress: address || {} }))
        this.getAddresses();
    }

    toggleCreateEditModal = (address, isPrimary) => {
        this.setState((prevState) => ({ createEditModalOpen: !prevState.createEditModalOpen, currentAddress: address || {}, isPrimary: isPrimary || false }))
    }

    getAddresses = () => {
        return this.props.MembersContext.getMemberAddresses(this.props.MembersContext.currentMember.member_id, { with_address_status_log: '1' })
            .then((addr) => {
                this.setState(() => ({ addresses: addr }))
            }).catch(console.error)
    }

    showRequestChange = (fields) => {
        for(let i = 0; i < fields.length; i++){
            const field = fields[i];
            if(this.props.OrganizationContext.settings?.request_mandatory_change_fields?.value?.some((_field) => (_field === field))){
                return true
            }
        }
    }

    render(){
        const primaryAddress = this.props.MembersContext.currentMember?.addresses?.find((addr) => addr.default_address == 1) || this.props.MembersContext.currentMember?.addresses?.find((addr) => addr.status !== 'REJECTED');
        return (
            <>
                <AddressConfirmationModal
                    toggle={this.toggleAddressConfirmationModal}
                    isOpen={this.state.confirmationModalOpen}
                    currentAddress={this.state.currentAddress}
                    allAddresses={this.state.addresses}
                    getAddresses={this.getAddresses}
                />
                <MemberAddressPhoneModal
                    isOpen={this.state.createEditModalOpen}
                    toggle={this.toggleCreateEditModal}
                    getAddresses={this.getAddresses}
                    address={this.state.currentAddress}
                    isPrimary={this.state.isPrimary}
                />

                {/*--------------------------------------------------------------------------------*/}
                {/* These form components manage their own edit state                              */}
                {/*--------------------------------------------------------------------------------*/}
                <MemberGeneral
                    scrollToComponent={this.scrollToComponent}
                    refreshMemberProfile={this.props.refreshMemberProfile}
                    showRedBorder={this.showRedBorder('GENERAL')}
                    primaryAddress={primaryAddress}
                    isCurrentOrganizationOrParent={this.state.isCurrentOrganizationOrParent}
                    toggleCreateEditModal={this.toggleCreateEditModal}
                    toggleAddressConfirmationModal={this.toggleAddressConfirmationModal}
                    getAddresses={this.getAddresses}
                    showRequestChange={this.showRequestChange(this.GENERAL_FIELDS)}
                />

                <MemberDemographicInfo
                    refreshMemberProfile={this.props.refreshMemberProfile}
                    showRedBorder={this.showRedBorder('DEMOGRAPHIC')}
                    showRequestChange={this.showRequestChange(this.DEMOGRAPHIC_FIELDS)}
                />

                {!this.props.minimalView &&
                    <>
                        <HasAccessTo componentCode='members' componentPermissionCode='members_address'>
                            <MemberAddressPhone
                                addresses={this.state.addresses.filter((addr) => addr.member_address_id !== primaryAddress?.member_address_id)}
                                isCurrentOrganizationOrParent={this.state.isCurrentOrganizationOrParent}
                                toggleCreateEditModal={this.toggleCreateEditModal}
                                toggleAddressConfirmationModal={this.toggleAddressConfirmationModal}
                                getAddresses={this.getAddresses}
                            />
                        </HasAccessTo>

                        <HasAccessTo componentCode='members' componentPermissionCode='members_documents'>
                            <MemberDocuments refreshMemberProfile={this.props.refreshMemberProfile} />
                        </HasAccessTo>

                        {/* Remains READ on member_profile */}
                        <MemberContacts refreshMemberProfile={this.props.refreshMemberProfile} />

                        <MemberFamilyRelations />

                        <MemberLinkedAccounts />

                        {/* For legal reasons, we cannot display this in the UI, this might change in the future */}
                        {/* <MemberMedicalNotes/> */}
                        <HasAccessTo componentCode='members' componentPermissionCode='members_memo'>
                            <MemberMemos />
                        </HasAccessTo>
                        <MemberLogs />
                    </>
                }
            </>

        )
    }
}

export default withRouter(withContexts(MembersContext, OrganizationContext)(MemberProfileOverview));