import { useContext } from 'react';
import HasAccessTo from '../../../../../components/permissions/HasAccessTo';
import { MembersContext } from '../../../../../contexts/MembersContext';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import OutstandingBalanceModal from './OutstandingBalanceModal';
import { useDisclosure } from '@mantine/hooks';
import { RolesContext } from '../../../../../contexts/RolesContext';
import { stringBuilder } from '@spordle/helpers';
import { Tooltip } from '@mantine/core';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';

const MemberHeaderMinimalBadges = ({ outstandingBalance, mutate }) => {
    const { organisation_id } = useContext(OrganizationContext);
    const membersContext = useContext(MembersContext);
    const rolesContext = useContext(RolesContext)
    const [ isOpen, { open, close } ] = useDisclosure(false);
    const canEdit = rolesContext.canDoAction("EDIT", "members", "members_outstanding_balance", true);

    const currentOutstandingDetails = membersContext.currentMember.outstanding_balance_details?.find((detail) => detail.organisation.organisation_id === organisation_id) || null;

    return (
        <div className='d-flex flex-wrap py-2'>
            <OutstandingBalanceModal
                isOpen={isOpen}
                toggle={close}
                outstandingBalance={outstandingBalance}
                onSuccess={() => mutate()}
            />

            <Tooltip
                key={outstandingBalance}
                withArrow
                label={
                    <>
                        {currentOutstandingDetails &&
                        <div key={currentOutstandingDetails.member_outstanding_balance_id} className='d-flex justify-content-between'>
                            <DisplayI18n field='name' defaultValue={currentOutstandingDetails.organisation.organisation_name} i18n={currentOutstandingDetails.organisation.i18n} />
                            <div className='ml-3'><CurrencyFormat value={currentOutstandingDetails.outstanding_balance / 100} /></div>
                        </div>
                        }
                    </>
                }
                disabled={!currentOutstandingDetails}
                zIndex={2000} // to be in front of sidebar
            >
                <div className={`text-center text-md-left mb-2 mr-2`}>
                    <HasAccessTo componentCode='members'>
                        <div
                            className={stringBuilder("btn btn-sm rounded-pill bg-white font-medium shadow-sm", { "clickable": canEdit })}
                            onClick={() => {
                                if(canEdit)
                                    open();
                            }}
                        >
                            <i className='text-danger mdi mdi-scale-balance mr-2' />
                            <span className='mr-1'><Translate id='members.profile.memberProfile.header.card.outstandingBalance' />:</span>
                            <CurrencyFormat value={(currentOutstandingDetails?.outstanding_balance || 0) / 100} />
                        </div>
                    </HasAccessTo>
                </div>
            </Tooltip>
        </div>
    );
}

export default MemberHeaderMinimalBadges;