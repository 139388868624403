import { FormikInputText, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { formatSelectData } from "@spordle/spordle-select";
import { Form, Formik } from "formik";
import { useContext } from "react";
import { Button, Col, Collapse, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { array, object, string } from 'yup';
import Required from "../../../../../../components/formik/Required";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { formikOtherTest, useInjurySelect, YES_NO_INFO_DATA } from "../../InsuranceHelper";

const StepInjury = ({ changeStep, updateData, next, previous, injuryDataLists }) => {
    const { absences, bodyParts, natures, onSiteCares, causes, equipments } = injuryDataLists;
    const getSelectInjuryProps = useInjurySelect();
    const { getGenericLocale } = useContext(I18nContext);

    return (
        <Formik
            initialValues={{
                body_part_id: '',
                correct_age: '',
                description: '',
                injury_cause_id: '',
                injury_absence_id: '',
                injury_equipment_id: [],
                injury_nature_id: '',
                injury_on_site_care_id: '',
                previous_injury: '',
                previous_injury_other: '',
                sanctionned_event: '',
            }}
            validationSchema={object().shape({
                body_part_id: string().required(<Translate id='insurance.validation.bodyPart.required' />),
                correct_age: string(),
                description: string(),
                injury_nature_id: string().required(<Translate id='insurance.validation.nature.required' />),
                injury_absence_id: string().required(<Translate id='insurance.validation.absence.required' />),
                injury_cause_id: string().required(<Translate id='insurance.validation.injuryCause.required' />),
                injury_equipment_id: array().of(string()).min(1, <Translate id='insurance.validation.equipment.required' />),
                injury_on_site_care_id: string().required(<Translate id='insurance.validation.onSiteCare.required' />),
                previous_injury: string(),
                previous_injury_other: string().test(formikOtherTest('previous_injury')),
                sanctionned_event: string(),
            })}
            onSubmit={(values) => {
                updateData('injury', values);
                next();
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <div className="h4 font-bold">
                            <Translate id='insurance.label.injury' />
                        </div>
                        <Row form>
                            <Col className="form-group" sm="6">
                                <Label for="body_part_id" className="text-muted">
                                    <Translate id='insurance.label.bodyPart' /> <Required />
                                </Label>
                                <FormikSelect
                                    name="body_part_id"
                                    id="body_part_id"
                                    search
                                    isLoading={!bodyParts}
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    renderOption={(opt) => (
                                        <DisplayI18n
                                            field="name"
                                            i18n={opt.option.i18n}
                                            defaultValue={opt.option.label}
                                        />
                                    )}
                                    options={bodyParts ?
                                        formatSelectData(bodyParts.sort((a, b) => {
                                            return parseInt(a.body_part_group.display_order) - parseInt(b.body_part_group.display_order)
                                        }), {
                                            getValue: (part) => part.body_part_id,
                                            getLabel: (part) => part.name,
                                            getGroupId: (part) => part.body_part_group?.body_part_group_id,
                                            createGroupWithId: (part) => ({
                                                ...part.body_part_group,
                                                label: part.name,
                                            }),
                                        })
                                        :
                                        []
                                    }
                                />
                            </Col>
                            <Col className="form-group" sm="6">
                                <Label for="injury_nature_id" className="text-muted"><Translate id='insurance.label.injuryNature' /> <Required /></Label>
                                <FormikSelect {...getSelectInjuryProps('injury_nature_id', natures)} />
                            </Col>
                            <Col className="form-group" sm="6">
                                <Label for="injury_on_site_care_id" className="text-muted"><Translate id='insurance.label.onSiteCare' /> <Required /></Label>
                                <FormikSelect {...getSelectInjuryProps('injury_on_site_care_id', onSiteCares)} />
                            </Col>
                            <Col sm="6" className="form-group">
                                <Label for="injury_cause_id" className="text-muted"><Translate id='insurance.label.cause' /> <Required /></Label>
                                <FormikSelect {...getSelectInjuryProps("injury_cause_id", causes)} />
                            </Col>
                            <Col className="form-group" sm="6">
                                <Label for="correct_age" className="text-muted"><Translate id='insurance.label.correctAge' /></Label>
                                <FormikSelect
                                    clearable
                                    name="correct_age"
                                    id="correct_age"
                                    isClearable
                                    isSearchable={false}
                                    loadingStatus="success"
                                    defaultData={YES_NO_INFO_DATA}
                                />
                            </Col>
                            <Col className="form-group" sm="6">
                                <Label for="sanctionned_event" className="text-muted"><Translate id='insurance.label.sanctionned' /></Label>
                                <FormikSelect
                                    clearable
                                    name="sanctionned_event"
                                    id="sanctionned_event"
                                    isClearable
                                    isSearchable={false}
                                    loadingStatus="success"
                                    defaultData={YES_NO_INFO_DATA}
                                />
                            </Col>
                            <Col className="form-group" sm="6">
                                <Label for="injury_equipment_id" className="text-muted"><Translate id='insurance.label.equipment' /> <Required /></Label>
                                <FormikSelect
                                    multi
                                    {...getSelectInjuryProps('injury_equipment_id', equipments)}
                                />
                            </Col>
                            <Col className="form-group" sm="6">
                                <Label for="previous_injury" className="text-muted"><Translate id='insurance.label.previousInjury' /> </Label>
                                <FormikSelect
                                    clearable
                                    name="previous_injury"
                                    id="previous_injury"
                                    isClearable
                                    isSearchable={false}
                                    loadingStatus="success"
                                    defaultData={YES_NO_INFO_DATA}
                                />
                                <Collapse isOpen={formik.values.previous_injury === "YES"}>
                                    <FormikInputText
                                        className="mt-2"
                                        helper={
                                            <span className="text-muted small">
                                                <i className="mdi mdi-information-outline" /> <Translate id='insurance.label.previousInjury.longAgo' />
                                            </span>
                                        }
                                        translateHelper={false}
                                        bsSize="sm"
                                        id="previous_injury_other"
                                        name="previous_injury_other"
                                    />
                                </Collapse>
                            </Col>
                            <Col className="form-group" sm="6">
                                <Label for="injury_absence_id" className="text-muted"><Translate id='insurance.label.absence' /> <Required /></Label>
                                <FormikSelect {...getSelectInjuryProps('injury_absence_id', absences)} />
                            </Col>
                            <Col className="form-group" sm="12">
                                <Label for="description" className="text-muted"><Translate id='insurance.label.description' /></Label>
                                <FormikTextArea name="description" />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="mr-auto" color="primary" type="button" outline onClick={previous}><Translate id="misc.previous" /></Button>
                        <Button color="primary" type="submit"><Translate id="misc.next" /></Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    )
}

export default StepInjury;