import { Tooltip } from '@mantine/core';
import Translate from '@spordle/intl-elements';
import { fail } from '@spordle/toasts';
import { useContext, useEffect, useState } from 'react';
import {
    Card
} from "reactstrap";
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import CardSectionTitle from '../../../../../../components/CardSectionTitle';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import CanDoAction from '../../../../../../components/permissions/CanDoAction';
import IsGod from '../../../../../../components/permissions/IsGod';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { UtilsContext } from '../../../../../../contexts/UtilsContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import SettingsCriminalRecordCheckForm from './SettingsCriminalRecordCheckForm';
import SettingsCriminalRecordCheckView from './SettingsCriminalRecordCheckView';

const SettingsCriminalRecordCheck = () => {
    const { getSettings } = useContext(UtilsContext);
    const { getOrganizationBackgroundChecks, organisation_id } = useContext(OrganizationContext)

    const [ isLoading, setLoading ] = useState(true);
    const [ settings, setSettings ] = useState(false);
    const [ expirationMethods, setExpirationMethods ] = useState([]);
    const [ backgroundChecks, setBackgroundChecks ] = useState([]);
    const [ isEditing, setEditing ] = useState(false);
    const toggleEditMode = () => {
        setEditing(!isEditing)
    };

    useEffect(() => {
        Promise.all([
            getSettings({ active: '1' }),
            getBackgroundChecks(),
        ])
            .then(([ settings ]) => {
                setSettings(settings);
                setExpirationMethods(Object.keys(settings.background_check_validation_type?.value || {}).map((validationType) => ({
                    ...settings.background_check_validation_type.value[validationType],
                    code: validationType.toUpperCase(),
                }))
                    .sort((validationTypeA, validationTypeB) => parseInt(validationTypeA.order) - parseInt(validationTypeB.order)))
                setLoading(false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    setLoading(false);
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, []);

    const getBackgroundChecks = () => {
        return getOrganizationBackgroundChecks(organisation_id)
            .then(setBackgroundChecks)
    }

    return (
        <Card body className="card-shadow" id="profileComponentCRCSettings">
            <OverlayLoader isLoading={isLoading}>
                <CardSectionTitle title='settings.crc.title' className='d-flex'>
                    <EditButton toggleEditMode={toggleEditMode} isEditing={isEditing} />
                </CardSectionTitle>
                {isEditing ?
                    <SettingsCriminalRecordCheckForm
                        toggleIsEditing={toggleEditMode}
                        getBackgroundChecks={getBackgroundChecks}
                        backgroundChecks={backgroundChecks}
                        settings={settings}
                        expirationMethods={expirationMethods}
                    />
                    :
                    <SettingsCriminalRecordCheckView
                        backgroundChecks={backgroundChecks}
                        settings={settings}
                        expirationMethods={expirationMethods}
                    />
                }
            </OverlayLoader>
        </Card>
    );
}

const EditButton = ({ isEditing, toggleEditMode }) => {
    const orgContext = useContext(OrganizationContext);
    const getButton = () => (
        <button onClick={toggleEditMode} className="reset-btn align-self-end" type='button'><Translate id='misc.edit' /></button>
    )

    if(orgContext.settings.enable_background_check?.can_manage == 1 || orgContext.settings.enable_background_check?.value == 1){
        return (
            <CanDoAction className='ml-auto text-link' componentCode='settings' componentPermissionCode='organization_background_checks' action='EDIT'>
                {!isEditing &&
                    getButton()
                }
            </CanDoAction>
        )
    }
    return (
        <div className='ml-auto text-link'>
            {!isEditing &&
                <Tooltip
                    label={
                        <>
                            <u>Power user only</u>
                            <div className='text-nowrap'>
                                setting: enable_background_check - can_manage
                            </div>
                        </>
                    }
                >
                    <IsGod tooltipOff>
                        {getButton()}
                    </IsGod>
                </Tooltip>
            }
        </div>
    )
}

export default SettingsCriminalRecordCheck;