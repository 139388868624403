import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from '@spordle/datatables';
import Translate, { CurrencyFormat, DateFormat } from '@spordle/intl-elements';
import { useState, useContext, useEffect } from 'react';
import queryString from 'query-string';
import {
    Card,
    CardBody,
    Collapse,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown
} from "reactstrap";
import SpordlePanelTable from '../../../components/sidePanel/SpordlePanel';
import RegistrationFeesAdd from './components/modal/RegistrationFeesAdd';
import RegistrationFeesSidepanel from './components/RegistrationFeeSidepanel/RegistrationFeesSidepanel';

// Contexts
import { RegistrationFeeContext } from '../../../contexts/RegistrationFeeContext';
import { MembersContext } from '../../../contexts/MembersContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { RegistrationCategoriesContext } from '../../../contexts/RegistrationCategoriesContext';
import { RegistrationDivisionsContext } from '../../../contexts/RegistrationDivisionsContext';
import I18nHelperContextProvider, { DisplayI18n, I18nHelperContext } from '../../../helpers/i18nHelper';
import { FormsContext } from '../../../contexts/FormsContext';
import { WaiversContext } from '../../../contexts/WaiversContext';
import { I18nContext } from '../../../contexts/I18nContext';
import { InstallmentModesContext } from '../../../contexts/InstallmentModesContext';
import EmptyLayout from '../../../components/table/EmptyLayout';
import { PeriodsContext } from '../../../contexts/contexts';
import CanDoAction from '../../../components/permissions/CanDoAction';
import { useLocation } from 'react-router-dom';
import { PeriodChangeHandler } from '../../../helpers/periodHelper';
import OpenSidePanelOnSearch from '../../../components/sidePanel/OpenSidePanelOnSearch';
import { fail } from "@spordle/toasts";
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import RolloverModal from './components/rolloverModal/RolloverModal';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import RegistrationFeesRolloverTour from '../../../components/tour/registrationFeesRollover/RegistrationFeesRolloverTour';
import { Tooltip } from '@mantine/core';
import { RolesContext } from '../../../contexts/RolesContext';
import { MerchantAccountContext } from '../../../contexts/MerchantAccountContext';

const RegistrationFees = () => {
    // Contexts
    const orgContext = useContext(OrganizationContext);
    const registrationFeeContext = useContext(RegistrationFeeContext);
    const membersContext = useContext(MembersContext);
    const categoryContext = useContext(RegistrationCategoriesContext);
    const divisionContext = useContext(RegistrationDivisionsContext);
    const formsContext = useContext(FormsContext);
    const waiversContext = useContext(WaiversContext);
    const i18nContext = useContext(I18nContext);
    const installmentModeContext = useContext(InstallmentModesContext);
    const periodsContext = useContext(PeriodsContext);
    const rolesContext = useContext(RolesContext);
    const merchantAccountContext = useContext(MerchantAccountContext);

    const location = useLocation();

    // States
    const [ apiData, setApiData ] = useState({});
    const [ helpCollapse, setHelpCollapse ] = useState(false)
    const [ modalAdd, setModalAdd ] = useState(false);
    const [ duplicateRegistration, setDuplicateRegistration ] = useState({});
    const [ rolloverModalIsOpen, setRolloverModalIsOpen ] = useState(false);

    const toggleHelpCollapse = () => setHelpCollapse(!helpCollapse)
    const toggleModalAdd = () => setModalAdd(!modalAdd);

    const toggleDuplicate = (registration) => {
        setDuplicateRegistration(registration);
        toggleModalAdd();
    }

    const toggleRolloverModal = () => {
        setRolloverModalIsOpen(!rolloverModalIsOpen);
    }

    const getData = () => {
        Promise.all([
            membersContext.getMemberTypes(orgContext.organisation_id, { active: '1' }),
            categoryContext.getRegistrationCategories(orgContext.organisation_id, { active: '1' }),
            divisionContext.getRegistrationDivisions(orgContext.organisation_id, { active: '1', period_id: periodsContext.selectedPeriod.period_id, only_defined_affiliation_price: '1' }), // period_id returns affiliation fee data linked to disvisions
            formsContext.getForms(),
            waiversContext.getWaivers(),
            installmentModeContext.getInstallmentModes(orgContext.organisation_id, { active: '1', period_id: periodsContext.selectedPeriod.period_id }),
        ])
            .then((promises) => {
                setApiData({
                    memberTypes: promises[0],
                    categories: promises[1],
                    divisions: promises[2],
                    forms: promises[3],
                    waivers: promises[4],
                    installmentModes: promises[5],
                })
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    const getTeamCategoryAges = (registrationFee) => {
        if(registrationFee.team_category.min_age != registrationFee.team_category.max_age)
            return <Translate id="misc.ageRange" values={{ from: registrationFee.team_category.min_age, to: registrationFee.team_category.max_age }} />
        return <Translate id='misc.yearsOld' values={{ age: registrationFee.team_category.min_age }} />
    }

    const getRowAges = (registrationFee) => {
        if(registrationFee.min_age != registrationFee.max_age)
            return <Translate id="misc.ageRange" values={{ from: registrationFee.min_age, to: registrationFee.max_age }} />
        return <Translate id='misc.yearsOld' values={{ age: registrationFee.min_age }} />
    }

    useEffect(() => {
        getData();

        // to fetch the merchant accounts in case they were not fetched before
        if(rolesContext.hasAccessTo('settings', 'settings_merchant_account')){
            merchantAccountContext.getMerchantAccountsByOrganization(orgContext.organisation_id)
        }
    }, []);

    return (
        <>
            <ViewHeaderV2
                title={<><Translate id='catalog.registrationFees.title' /> <button type='button' onClick={toggleHelpCollapse} className="reset-btn"><i className="mdi mdi-help-circle-outline " /></button></>}
                skipTranslate
            />

            <div className="px-4">
                <Collapse isOpen={helpCollapse}>
                    <div className="pb-1"> {/* padding-bottom to stop the collapse+margin glich  */}
                        <p><Translate id='catalog.registrationFees.help.1' /></p>
                        <p>
                            <Translate id='catalog.registrationFees.help.2' />
                            <br />
                            <Translate id='catalog.registrationFees.help.3' />
                        </p>
                    </div>
                </Collapse>
                <I18nHelperContextProvider fields={[ 'name', 'description' ]}>
                    <I18nHelperContext.Consumer>
                        {(i18nHelper) => (
                            <Card className="card-shadow">
                                <CardBody>
                                    <SpordlePanelTable
                                        allowOutsideClick
                                        dataIndex="registration_fee_id"
                                        sidePanel={(props) => <RegistrationFeesSidepanel toggleDuplicate={toggleDuplicate} waivers={apiData.waivers} forms={apiData.forms} divisions={apiData.divisions} installmentModes={apiData.installmentModes} memberTypes={apiData.memberTypes} categories={apiData.categories} {...props} />}
                                        table={(panelProps) => (
                                            <SpordleTableProvider
                                                id={'CatalogRegistrationFeesTable'}
                                                dataIndex="registration_fee_id"
                                                tableHover bordered striped
                                                clickable
                                                ref={panelProps.spordleTableRef}
                                                desktopWhen
                                                emptyLayout={<EmptyLayout />}
                                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                                defaultSearchValue={queryString.parse(location.search).search} // needed for notification redirect
                                                searchKeys={[
                                                    'registration_fee_id',
                                                    `team_category.class.i18n.${i18nContext.getGenericLocale()}.short_name`,
                                                    `team_category.class.i18n.${i18nContext.getGenericLocale()}.name`,
                                                    `division.i18n.${i18nContext.getGenericLocale()}.short_name`,
                                                    `division.i18n.${i18nContext.getGenericLocale()}.name`,
                                                    `fee.i18n.${i18nContext.getGenericLocale()}.name`,
                                                    'team_category.name',
                                                    'team_category.gender',
                                                    'team_category.class.short_name',
                                                    'team_category.class.name',
                                                    'division.short_name',
                                                    'division.name',
                                                    'registration_fee_id', // needed for notification redirect
                                                ]}
                                                loadData={(from, _filterData, spordleTable) => {
                                                    switch (from){
                                                        case 'REFRESH':
                                                            spordleTable.setLoading();
                                                        case 'CDM':
                                                            return registrationFeeContext.getRegistrationFees();
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                columns={[
                                                    ...i18nHelper.getTableColumns('name'),
                                                    {
                                                        label: <Translate id='catalog.registrationFees.category' />,
                                                        key: 'team_category',
                                                        sortable: true,
                                                        sortKey: `division.${i18nContext.getGenericLocale()}.short_name`,
                                                        fallbackSortKey: 'division.short_name',
                                                        mobile: true,
                                                    },
                                                    {
                                                        label: <Translate id='catalog.registrationFees.registrationFees' />,
                                                        key: 'fee',
                                                        sortable: true,
                                                        sortKey: 'fee.amount',
                                                        mobile: true,
                                                    },
                                                    {
                                                        label: <Translate id='catalog.registrationFees.availablePlaces' />,
                                                        key: 'available_place',
                                                        sortable: true,
                                                    },
                                                    {
                                                        label: <Translate id='catalog.registrationFees.total_sold' />,
                                                        key: 'total_sold',
                                                        sortable: true,
                                                    },
                                                    {
                                                        label: <Translate id='catalog.registrationFees.status' />,
                                                        key: 'active',
                                                        dataClassName: 'text-center',
                                                        sortable: true,
                                                        mobile: true,
                                                    },
                                                ]}
                                                renderRow={(columnKey, registrationFee) => {
                                                    if(columnKey.includes('i18n')){
                                                        return (
                                                            <DisplayI18n
                                                                field={columnKey}
                                                                defaultValue={registrationFee.fee.name}
                                                                i18n={registrationFee.fee.i18n}
                                                            />
                                                        )
                                                    }
                                                    switch (columnKey){
                                                        case 'team_category':
                                                            return (
                                                                <>
                                                                    <DisplayI18n
                                                                        field='short_name'
                                                                        i18n={registrationFee.division?.i18n}
                                                                        defaultValue={registrationFee.division?.short_name}
                                                                    />
                                                                    {registrationFee.team_category?.class &&
                                                                        <>
                                                                            <span className='mx-1'>-</span>
                                                                            <DisplayI18n
                                                                                field='short_name'
                                                                                i18n={registrationFee.team_category?.class?.i18n}
                                                                                defaultValue={registrationFee.team_category?.class?.short_name}
                                                                            />
                                                                        </>
                                                                    }
                                                                    {
                                                                        (registrationFee.division?.active === "0"
                                                                        || registrationFee.team_category?.active === "0"
                                                                        || registrationFee.team_category?.active_in_this_period === "0"
                                                                        || registrationFee.team_category?.class?.active === "0") &&
                                                                        <div className="small text-warning">
                                                                            <i className="mdi mdi-alert-outline" /> <Translate id='catalog.registrationFees.inactiveCategory' />
                                                                        </div>
                                                                    }
                                                                    <div className='small text-muted'><DisplayI18n field='name' defaultValue={registrationFee.member_type.name} i18n={registrationFee.member_type.i18n} /></div>

                                                                    {registrationFee.team_category ?
                                                                        <>
                                                                            {registrationFee.team_category.gender &&
                                                                                <div className='small text-muted'>
                                                                                    <Translate
                                                                                        id={`form.fields.gender.${registrationFee.team_category.gender.toLowerCase()}`}
                                                                                        defaultMessage={registrationFee.team_category.gender}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                            {(registrationFee.team_category.min_age && registrationFee.team_category.max_age) &&
                                                                                <div className='small text-muted'>
                                                                                    {(registrationFee.min_age && registrationFee.max_age && (registrationFee.min_age !== registrationFee.team_category.min_age || registrationFee.max_age !== registrationFee.team_category.max_age)) ?
                                                                                        <>
                                                                                            <Tooltip
                                                                                                withArrow wrapLines
                                                                                                zIndex={3000}
                                                                                                width={200}
                                                                                                label={<Translate id="catalog.registrationFees.modifiedAges" />}
                                                                                            >
                                                                                                <>
                                                                                                    <div><i className="mdi mdi-information-outline text-primary mr-1" /><del>{getTeamCategoryAges(registrationFee)}</del></div>
                                                                                                    <div>{getRowAges(registrationFee)}</div>
                                                                                                </>
                                                                                            </Tooltip>
                                                                                        </>
                                                                                        :
                                                                                        getTeamCategoryAges(registrationFee)
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </>
                                                                        :
                                                                        (registrationFee.min_age && registrationFee.max_age) ?
                                                                            <div className='small text-muted'>
                                                                                <div>{getRowAges(registrationFee)}</div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </>
                                                            )
                                                        case 'fee':
                                                            const affiliationFees = registrationFee.affiliation_fees?.total?.[registrationFee.member_type?.member_type_id];
                                                            const affiliation_fees = registrationFee.exclude_affiliation_fee == '1' ?
                                                                {
                                                                    amount: 0,
                                                                    early_amount: 0,
                                                                    late_amount: 0,
                                                                }
                                                                :
                                                                {
                                                                    amount: affiliationFees?.amount || 0, //only amount is used on affiliation fees (no early or late amount)
                                                                    early_amount: affiliationFees?.early_amount || 0, // unused ^^
                                                                    late_amount: affiliationFees?.late_amount || 0, // unused ^^
                                                                }

                                                            return (
                                                                <div>
                                                                    <div className="font-medium">
                                                                        <CurrencyFormat value={(parseInt(registrationFee.fee?.amount) + parseInt(affiliation_fees.amount)) / 100} />
                                                                        {registrationFee.fee.tax_class &&
                                                                            <sup className='ml-1'>(<Translate id='catalog.registrationFees.add.form.tax' />)</sup>
                                                                        }
                                                                    </div>
                                                                    { !!registrationFee.fee.early_amount &&
                                                                        <div className={`small mt-1 d-inline-block${registrationFee.fee.late_amount ? ' mr-2 pr-2 border-right' : ''}`}>
                                                                            <div className="font-medium"><Translate id='catalog.registrationFees.add.form.earlyFee' /> : <CurrencyFormat value={(parseInt(registrationFee.fee.early_amount) + parseInt(affiliation_fees.amount)) / 100} /></div>
                                                                            <span><Translate id='catalog.registrationFees.add.form.before' /> <DateFormat value={registrationFee.fee.early_amount_until} /> <DateFormat value={registrationFee.fee.early_amount_until} format={'h:mm a'} /></span>
                                                                        </div>
                                                                    }
                                                                    { !!registrationFee.fee.late_amount &&
                                                                        <div className="small mt-1 d-inline-block">
                                                                            <div className="font-medium"><Translate id='catalog.registrationFees.add.form.lateFee' /> : <CurrencyFormat value={(parseInt(registrationFee.fee.late_amount) + parseInt(affiliation_fees.amount)) / 100} /></div>
                                                                            <span><Translate id='catalog.registrationFees.add.form.after' /> <DateFormat value={registrationFee.fee.late_amount_after} /> <DateFormat value={registrationFee.fee.late_amount_after} format={'h:mm a'} /></span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            );
                                                        case 'available_place':
                                                            return <AvailablePlacesRender registrationFee={registrationFee} />
                                                        case 'active':
                                                            return (
                                                                registrationFee.active === "1" ?
                                                                    (registrationFee.division?.active === "0"
                                                                    || registrationFee.team_category?.active === "0"
                                                                    || registrationFee.team_category?.active_in_this_period === "0") ?
                                                                        <i className="mdi mdi-alert-outline text-warning" /> : <i className="text-primary mdi mdi-check" />
                                                                    :
                                                                    <i className="text-danger mdi mdi-close" />
                                                            );
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                onColumnClick={(e, data) => {
                                                    switch (e.button){
                                                        case 0: // Left mouse button
                                                            panelProps.onSingleSelectChange(data);
                                                            break;
                                                    }
                                                }}
                                                rowIsHighlighted={(data) => panelProps.selectedRows[0]?.registration_fee_id === data.registration_fee_id}
                                            >
                                                {(spordleTable) => (
                                                    <>
                                                        <div className='mb-2'>
                                                            <div className='d-flex flex-wrap justify-content-between'>
                                                                <SearchInput />
                                                                <PeriodChangeHandler useRefreshTable onPeriodChange={getData} />
                                                                <div className='d-flex ml-auto text-right nowrap'>
                                                                    <Refresh />
                                                                    <CanDoAction action='ADD' componentCode='catalog' componentPermissionCode='registration_fees'>
                                                                        <UncontrolledDropdown className="ml-2">
                                                                            {apiData.divisions &&
                                                                                <RegistrationFeesAdd duplicateRegistration={duplicateRegistration} installments={apiData.installmentModes} waivers={apiData.waivers} forms={apiData.forms} divisions={apiData.divisions} categories={apiData.categories} memberTypes={apiData.memberTypes} toggle={toggleModalAdd} isOpen={modalAdd} setDuplicateRegistration={setDuplicateRegistration} />
                                                                            }
                                                                            <RolloverModal
                                                                                isOpen={rolloverModalIsOpen}
                                                                                toggle={toggleRolloverModal}
                                                                                currentSeasonFees={spordleTable.getData()}
                                                                                divisions={apiData.divisions}
                                                                                categories={apiData.categories}
                                                                                refreshTable={spordleTable.refreshTable}
                                                                            />

                                                                            <DropdownToggle
                                                                                disabled={spordleTable.state.loadingState !== 'success'}
                                                                                caret
                                                                                color='primary'
                                                                                id="registration-fees-add-toggle"
                                                                            >
                                                                                <i className='mdi mdi-plus mr-1' />
                                                                                <Translate id='misc.add' />
                                                                            </DropdownToggle>
                                                                            <DropdownMenu id="registration-fees-add-toggle-dropdown" right>
                                                                                <DropdownItem onClick={toggleModalAdd}><Translate id="catalog.registrationFees.add.dropdown.button" /></DropdownItem>
                                                                                <DropdownItem id="registration-fees-toggle-rollover-modal" onClick={toggleRolloverModal}><Translate id="catalog.registrationFees.rollover.dropdown.button" /></DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                        {spordleTable.state.loadingState === 'success' && <RegistrationFeesRolloverTour />}
                                                                    </CanDoAction>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <SpordleTableView />
                                                        <OpenSidePanelOnSearch />
                                                    </>
                                                )}
                                            </SpordleTableProvider>
                                        )}
                                    />
                                </CardBody>
                            </Card>
                        )}
                    </I18nHelperContext.Consumer>
                </I18nHelperContextProvider>
            </div>
        </>
    );
}

const AvailablePlacesRender = ({ registrationFee }) => {
    if(registrationFee.is_carding_fee && registrationFee.available_place){
        return (registrationFee.available_place - registrationFee.number_of_cards_used) + ' / ' + registrationFee.available_place
    }else if(registrationFee.manage_waiting_list == 1 && registrationFee.available_place){
        return (
            <>
                <div>
                    {registrationFee.available_place}
                </div>
                <Tooltip withArrow label={<Translate id='catalog.registrationFees.add.form.waitingList.tooltip' />}>
                    <div className='small'>
                        <i className='text-primary mdi mdi-clock-alert-outline mr-1' />
                        <Translate id='catalog.registrationFees.add.form.waitingList' />
                    </div>
                </Tooltip>

            </>
        )
    }
    return registrationFee.available_place || <Translate id='catalog.registrationFees.unlimited' />
}

export default RegistrationFees;