export default{
    "registration.settings.class.table.active" : "Actif",
    "registration.settings.class.table.empty"  : "Aucune Classe",
    "registration.settings.class.delete"       : "Vous êtes sur le point de supprimer cette classe",

    // add
    "registration.settings.class.modal.title"         : "Ajouter une Classe",
    "registration.settings.class.form.label.status"   : "Statut",
    "registration.settings.class.add.status.active"   : "Actif",
    "registration.settings.class.add.status.inactive" : "Inactif",
    "registration.settings.class.add.status.required" : "Le Statut est requis",

    // form validation
    "registration.settings.class.form.label.order"                : "Ordre",
    "registration.settings.class.form.validation.order.integer"   : "L'ordre ne doit pas avoir de décimales.",
    "registration.settings.class.form.validation.order.moreThan0" : "L'ordre ne doit être plus grand que 0.",
    "registration.settings.class.form.validation.order.required"  : "L'ordre est requis.",
}