import { useDisclosure } from "@mantine/hooks";
import Translate from "@spordle/intl-elements";
import { DropdownItem } from "reactstrap";
import ParticipateInTournamentLeagueModal from "../participateInTournamentLeague/ParticipateInTournamentLeagueModal";

const TeamHeaderActionParticipate = (props) => {
    const [ isOpen, { open, close } ] = useDisclosure(false);

    return (
        <>
            <DropdownItem onClick={open} id="team-profile-header-add-to-league-tournament">
                <Translate id="teams.profile.header.participate" />
            </DropdownItem>
            <ParticipateInTournamentLeagueModal {...props} isOpen={isOpen} toggle={close} />
        </>
    );
}

export default TeamHeaderActionParticipate;