import { stringBuilder } from '@spordle/helpers'
import { useState } from 'react'
import { Collapse, Fade, ListGroupItem } from 'reactstrap'
import { DisplayI18n } from '../../../../helpers/i18nHelper'
import EnforcedLabel from './EnforcedLabel'
import TeamSettingWarningsMembers from './TeamSettingWarningsMembers'

const TeamSettingsQualificationsRow = ({ qualification, canSeeMember }) => {
    const [ showWarnings, setShowWarnings ] = useState(false);
    return (
        <ListGroupItem className={qualification.warnings.length ? 'bg-light-warning' : undefined}>
            <div className={qualification.warnings.length ? 'clickable' : undefined} onClick={qualification.warnings.length ? () => { setShowWarnings(!showWarnings) } : undefined}>
                <div className='d-flex justify-content-between align-items-center'>
                    <DisplayI18n field='name' defaultValue={qualification.name} i18n={qualification.i18n} />
                    {qualification.qualification_level &&
                        <>
                            {" - "}
                            <DisplayI18n
                                field="name"
                                defaultValue={qualification.qualification_level.name}
                                i18n={qualification.qualification_level.i18n}
                            />
                        </>
                    }
                    {qualification.warnings.length > 0 && <i className={stringBuilder(`mdi mdi-chevron-down d-inline-block transition`, { "rotate-180": showWarnings })} />}
                </div>
                {qualification.enforced == '1' && <EnforcedLabel />}
            </div>
            <Collapse isOpen={showWarnings} className='py-1'>
                <Fade in={showWarnings}>
                    <TeamSettingWarningsMembers warnings={qualification.warnings} canSeeMember={canSeeMember} />
                </Fade>
            </Collapse>
        </ListGroupItem>
    )
}

export default TeamSettingsQualificationsRow
