import { stringBuilder } from "@spordle/helpers";
import UserDisplay from "../../../userDisplay/UserDisplay";
import UserImg from "../../../UserImg";
import InlineCopy from "../../../inlineCopy/InlineCopy";

const DisplayRecipient = ({ children, recipient, suptitle, subtitle, className, ...props }) => {
    return (
        <UserDisplay className={stringBuilder("p-2 position-static", className)} block card {...props}>
            <UserDisplay.Container>
                <UserImg
                    width={40}
                    src={null}
                    alt={`${recipient.first_name} ${recipient.last_name}`}
                    abbr={`${recipient.first_name.charAt(0)}${recipient.last_name.charAt(0)}`}
                />
            </UserDisplay.Container>
            <UserDisplay.Container className="min-w-0">
                {suptitle}
                <UserDisplay.Title className="text-truncate">{recipient.first_name} {recipient.last_name}</UserDisplay.Title>
                {recipient.unique_identifier &&
                    <UserDisplay.Subtitle>
                        <InlineCopy toCopy={recipient.unique_identifier}>
                            #{recipient.unique_identifier}
                        </InlineCopy>
                    </UserDisplay.Subtitle>
                }
                {subtitle}
            </UserDisplay.Container>
            {children}
        </UserDisplay>
    );
}

export default DisplayRecipient;