export default{
    "organization.profile.users.addUser.title"          : "Ajouter un utilisateur",
    "organization.profile.users.mutilple.addUser.title" : "Sommaire",

    // Initial form
    "organization.profile.users.addUser.fields.organization"               : "Organisation",
    "organization.profile.users.addUser.fields.role"                       : "Rôle",
    "organization.profile.users.addUser.fields.noRole"                     : "Aucun rôle",
    "organization.profile.users.addUser.fields.role.placeholder"           : "Sélectionnez un rôle",
    "organization.profile.users.addUser.fields.role.inactive"              : "Ce rôle est inactif",
    "organization.profile.users.addUser.verifyEmail"                       : "Vérifier le courriel",
    "organization.profile.users.addUser.validation.role.required"          : "Le rôle est requis",
    "organization.profile.users.addUser.validation.organization.required"  : "L'organisation est requise",
    "organization.profile.users.addUser.dates.optional"                    : "Optionnel",
    "organization.profile.users.addUser.dates.start"                       : "L'utilisateur aura accès à ce rôle du",
    "organization.profile.users.addUser.dates.end"                         : "Jusqu'au",
    "organization.profile.users.addUser.dates.startDate.format.validation" : "La date de début doit être d'un format valide",
    "organization.profile.users.addUser.dates.endDate.format.validation"   : "La date de fin doit être d'un format valide",
    "organization.profile.users.addUser.dates.validation"                  : "La date de début doit être avant la date de fin",

    // User doesn't exist
    "organization.profile.users.addUser.create.title"         : "Créer un utilisateur",
    "organization.profile.users.addUser.doesntExist.topText"  : "Aucun utilisateur n'est associé avec cette adresse courriel",
    "organization.profile.users.addUser.doesntExist.topText2" : "Veuillez remplir le formulaire pour créer un utilisateur et le lié au rôle et à l'organisation sélectionné",

    // User exists
    "organization.profile.users.addUser.exists.topText"  : "Cette adresse courriel est déjà enregistrée dans le système.",
    "organization.profile.users.addUser.exists.topText2" : "Voulez-vous lier l'utilisateur avec le rôle et l'organisation sélectionné?",
    "organization.profile.users.addUser.exists.topText3" : "Cet usager a été lié à ce rôle et cette organisation.",

    "organization.profile.users.addUser.linked"           : "L'utilisateur a été lié au rôle et à l'organisation sélectionné",
    "organization.profile.users.addUser.createdAndLinked" : "Cet utilisateur a été créé et lié au rôle et à l'organisation sélectionné",

    // Add multiple users
    "organization.profile.users.multiple.addUser.card.title"         : "Nouvel utilisateur",
    "organization.profile.users.multiple.addUser.modal.btn"          : "Ajouter d'autres utilisateurs",
    "organization.profile.users.multiple.addUser.modal.msg"          : "Sommaire des utilisateurs ajoutés.",
    "organization.profile.users.multiple.addUser.modal.error.create" : "Une erreur s'est produite lors de la création de cet usager.",
    "organization.profile.users.multiple.addUser.modal.error.link"   : "Une erreur s'est produite lors de la liaison de cet usager.",
    "organization.profile.users.multiple.addUser.linked"             : "Lié",
    "organization.profile.users.multiple.addUser.createdAndLinked"   : "Créé et lié",
    "organization.profile.users.multiple.addUser.duplicate"          : "L'adresse courriel doit être unique.",
    "organization.profile.users.multiple.addUser.grid"               : "Ajouter à l'aide d'une grille (grid)",
    "organization.profile.users.multiple.addUser.grid.message"       : "Vous pouvez ajouter plusieurs utilisateurs à l'aide de cette grille. Les informations seront soumises à la vue principale pour la validation de l'information et votre confirmation.",
}