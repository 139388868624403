import { Fragment, useCallback, useEffect, useState } from "react";
import { Col, Row, Spinner } from "reactstrap";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import TransferWidgetPreview from "./previews/TransferWidgetPreview";
import PerfectScrollbar from 'react-perfect-scrollbar';
import SupportRequestWidgetPreview from "./previews/SupportRequestWidgetPreview";

const PreviewDataType = ({ data, widget, getApiData }) => {
    const [ theData, setTheData ] = useState(data);
    const [ isLoading, setIsLoading ] = useState(widget.fromApi);

    const getContent = useCallback(() => {
        switch (data.contentType){
            case 'transfer':
                return TransferWidgetPreview;
            case 'supportRequest':
                return SupportRequestWidgetPreview;
            default:
                return Fragment;
        }
    }, [ data.contentType ]);

    const Content = getContent();

    const fetchData = () => {
        setIsLoading(true);

        return getApiData(widget.name)
            .then((data) => {
                setIsLoading(false);
                setTheData(data);
            })
    }

    useEffect(() => {
        if(widget.fromApi){
            fetchData();
        }else{
            setTheData(data);
        }
    }, [ data.contentType ])

    return (
        <>
            {theData.headers.length > 0 &&
                <div>
                    <PerfectScrollbar options={{ suppressScrollY: true }}>
                        <Row tag='ul' form className="list-unstyled mx-0">
                            {theData.headers.map((header) => (
                                <Col className="mb-3 mb-sm-0" xs="12" sm="6" tag='li' key={header.headerId}>
                                    <h3 className="font-bold" aria-labelledby={`preview-${data.contentType}_${widget.widgetId}_${header.headerId}`}>
                                        {isLoading ? <Spinner size="sm" type="grow" /> : header.data || 0}
                                    </h3>
                                    <div id={`preview-${data.contentType}_${widget.widgetId}_${header.headerId}`} className='text-nowrap'>
                                        <DisplayI18n field="label" defaultValue={header.label} i18n={header.i18n} />
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </PerfectScrollbar>
                </div>
            }
            <Content getApiData={fetchData} isLoading={isLoading} content={theData.content} />
        </>
    )
}

export default PreviewDataType