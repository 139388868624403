export default{
    "dashboard.widgets.remove" : "Vous êtes sur le point de supprimer",
    "dashboard.widgets.view"   : "Voir",

    "dashboard.btn.changeLayout" : "Change Layout",

    "dashboard.addModal.title"             : "Ajouter un widget",
    "dashboard.addModal.dataType.required" : "Veuillez sélectionner un type de widget",
    "dashboard.addModal.widget.required"   : "Veuillez sélectionner un widget",
    "dashboard.addModal.widget.preview"    : "Aperçu du widget",

    "dashboard.widget.welcome.title"         : "Bienvenue !",
    "dashboard.widget.welcome.subtitle"      : "Nous sommes heureux de vous souhaiter la bienvenue sur votre nouveau tableau de bord.",
    "dashboard.widget.welcome.text.1"        : "Bientôt, vous pourrez:",
    "dashboard.widget.welcome.text.2"        : "Ajouter des nouveaux widgets",
    "dashboard.widget.welcome.text.3"        : "Personnaliser votre tableau de bord",
    "dashboard.widget.welcome.btn.tour"      : "Voir les nouveautés",
    "dashboard.widget.welcome.btn.learnMore" : "En savoir plus",

    "dashboard.widget.preview.supportRequest.empty.title"    : "Aucune demande de soutien",
    "dashboard.widget.preview.supportRequest.empty.subtitle" : "Aucune demande de soutien n'a été soumise sur votre compte.",
    "dashboard.widget.preview.supportRequest.empty.btn"      : "Créer une demande de soutien",
}