export default{
    "catalog.forms.components.customFormEditor.formName.required"    : "Name is required",
    "catalog.forms.components.customFormEditor.formName.description" : "The name of the questionnaire will not be visible to the public",

    "catalog.forms.components.customFormEditor.form.validation.section.name"                 : "You must enter a section title",
    "catalog.forms.components.customFormEditor.form.validation.question.minLength"           : "A section must have at least one question",
    "catalog.forms.components.customFormEditor.form.validation.question.max.moreThan"        : "Must be more than the minimum",
    "catalog.forms.components.customFormEditor.form.validation.question.max.dateFormat"      : "Invalid date format",
    "catalog.forms.components.customFormEditor.form.validation.question.max.dateFormat.more" : "Must be after the minimum date",
    "catalog.forms.components.customFormEditor.form.validation.question.min.lessThan"        : "Must be less than the maximum",
    "catalog.forms.components.customFormEditor.form.validation.question.min.dateFormat"      : "Invalid date format",
    "catalog.forms.components.customFormEditor.form.validation.question.min.dateFormat.less" : "Must be before the maximum date",
    "catalog.forms.components.customFormEditor.form.validation.question.title.required"      : "Please enter a question",
    "catalog.forms.components.customFormEditor.form.validation.option.fieldOption.required"  : "You must enter an option",

    "catalog.forms.components.customFormEditor.create.fail"    : "Error while creating the questionnaire",
    "catalog.forms.components.customFormEditor.create.success" : "Questionnaire successfully created",
    "catalog.forms.components.customFormEditor.update.fail"    : "Error while updating the questionnaire",
    "catalog.forms.components.customFormEditor.update.success" : "Questionnaire successfully updated",
}