export default{
    "helpCenter.resources.title"      : "Resources",
    "helpCenter.editModal.title"      : "Edit Help Center",
    "helpCenter.edit.center"          : "Edit Help Center",
    "helpCenter.editAlertModal.title" : "Edit Alert",
    "helpCenter.editModal.add.alert"  : "Add Alert",

    // help center form
    "help_center.editModal.video.id.tooltip"    : "The video id is the identifier for a youtube video. It can be found by looking at the URL of a video page and copying the part of the URL found after 'watch?v='. An example of a valid video id is 'W85oD8FEF78'.",
    "help_center.editModal.custom.link.tooltip" : "A custom link appears under the video, turning the title and content into a button that will redirect to the entered URL.",
    "helpCenter.editModal.and.or"               : "And / Or",

    // help center form validation
    "help_center.editModal.validation.title.when.custom_link"            : "A title is required when using a custom link.",
    "help_center.editModal.validation.title.when.content"                : "A title is required when entering content.",
    "help_center.editModal.validation.content.when.custom_link"          : "Content is required when using a custom link.",
    "help_center.editModal.validation.content.when.title"                : "Content is required when entering a title.",
    "help_center.editModal.validation.custom_url"                        : "A custom URL must be a valid URL. Example: 'https://www.spordle.com'.",
    "help_center.editModal.validation.video.id.required.when.video.link" : "A video identifier is required when entering a video link.",
    "help_center.editModal.validation.video.link.required.when.video.id" : "A video link is required when entering a video identifier.",
    "help_center.editModal.validation.video.link.required.when.no.title" : "A video link and identifier is required if not entering a title and content.",
    "help_center.editModal.validation.title.required.when.no.video.link" : "A title and content is required if not entering a video link and video identifier.",
    "help_center.editModal.validation.video.link.valid.url"              : "A video link must be a valid URL. Example: 'https://www.youtube.com/watch?v=W85oD8FEF78'.",

    // help center alert form validation
    "help_center.alert.editModal.validation.title.required"       : "A title is required.",
    "help_center.alert.editModal.validation.content.required"     : "Content is required.",
    "help_center.alert.editModal.validation.type.required"        : "Alert type is required.",
    "help_center.alert.editModal.validation.display.for.required" : "An organization for whom to display an alert is required.",

    // Help center additional (alert) form fields
    "help_center_additional.editModal.alert.section.title"   : "Help Center Alerts",
    "help_center_additional.editModal.alert.color"           : "Alert Type",
    "help_center_additional.editModal.alert.organisation_id" : "Display alert for:",

    // colors
    "helpCenter.editModal.alert.color.0" : "Danger (Red)",
    "helpCenter.editModal.alert.color.1" : "Warning (Yellow)",
    "helpCenter.editModal.alert.color.2" : "Success (Green)",
    "helpCenter.editModal.alert.color.3" : "Info (Blue)",

    // delete confirmation in modals
    "helpCenter.deleteConfirmation.tooltip.title"          : "Confirm that you want to clear the Help Center.",
    "helpCenter.deleteConfirmation.tooltip.text"           : "Warning, this can't be undone!",
    "helpCenter.deleteConfirmation.delete.if.submit.empty" : "Please use the clear function.",


    // help center form
    "help.center.form.field.i18n.en.title"       : "Title (EN)",
    "help.center.form.field.i18n.fr.title"       : "Title (FR)",
    "help.center.form.field.i18n.en.content"     : "Content (EN)",
    "help.center.form.field.i18n.fr.content"     : "Content (FR)",
    "help.center.form.field.i18n.en.custom_link" : "Custom Link (EN)",
    "help.center.form.field.i18n.fr.custom_link" : "Custom Link (FR)",
    "help.center.form.field.i18n.en.video_link"  : "Video Link (EN)",
    "help.center.form.field.i18n.fr.video_link"  : "Video Link (FR)",
    "help.center.form.field.i18n.en.video_id"    : "Video Id (EN)",
    "help.center.form.field.i18n.fr.video_id"    : "Video Id (FR)",

}