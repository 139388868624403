import Translate from '@spordle/intl-elements';
import PropTypes from 'prop-types';
import { useState } from 'react';
import WaiverCard from './WaiverCard';
import WaiversModal from './WaiversModal';

const Waivers = ({ registration, refreshTable }) => {
    const [ selectedWaiver, setSelectedWaiver ] = useState(null);

    return (
        <>
            <div className="h4 font-bold"><Translate id='memberProfile.registration.form.waivers' /></div>
            <WaiversModal
                item={registration.memberRegistration.registration_fee}
                signedBy={{ ...selectedWaiver?.identity, invoice_date: selectedWaiver?.updated_at }}
                apiPartner={selectedWaiver?.api_partner?.name}
                waiver={selectedWaiver}
                answer={selectedWaiver?.answer}
                invoiceNumber={registration.invoice_number}
                closeModal={() => {
                    setSelectedWaiver(null);
                }}
                onSubmit={refreshTable}
            />
            {registration?.waivers?.map(((waiver) => (

                <WaiverCard
                    key={waiver.waiver.waiver_id}
                    waiver={waiver}
                    signedBy={{ ...waiver.identity, invoice_date: waiver.updated_at }}
                    apiPartner={waiver.api_partner?.name}
                    answer={waiver.answer}
                    openModal={() => {
                        setSelectedWaiver({ ...waiver });
                    }}
                />
            )))}
        </>
    );
}

Waivers.propTypes = {
    registration: PropTypes.object.isRequired,
};

export default Waivers;