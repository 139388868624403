import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useRef, useState } from 'react';
import {
    Row,
    Button,
    Card,
    Col,
    FormGroup,
    Label,
    CardBody,
    CardHeader
} from "reactstrap";
import { FieldArray, Form, Formik, useFormikContext } from 'formik';
import { FormikInputText, FormikSelect } from '@spordle/formik-elements';
import * as Yup from 'yup';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import OrganizationSearch from '../../../../../components/organization/OrganizationSearch';
import { RolesContext } from '../../../../../contexts/RolesContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import generatePassword from '../../../../../helpers/passwordGenerator';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';
import { AuthContext } from '../../../../../contexts/contexts';
import AddMultipleModal from './AddMultipleModal';
import AddMultipleGridModal from './AddMultipleGridModal';
import { Link } from 'react-router-dom';
import { fail } from '@spordle/toasts';
import Required from '../../../../../components/formik/Required';
import { displayI18n, DisplayI18n } from '../../../../../helpers/i18nHelper';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { stringBuilder } from '@spordle/helpers';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';


const AddMultipleUsersTab = (props) => {
    const maxUsers = 20;
    const organizationContext = useContext(OrganizationContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const rolesContext = useContext(RolesContext);
    const authContext = useContext(AuthContext);
    const { getGenericLocale } = useContext(I18nContext)

    const [ createdUsers, setCreatedUsers ] = useState(null);
    const [ isOpen, setIsOpen ] = useState(false);
    const [ isGridOpen, setIsGridOpen ] = useState(false);

    const emptyUser = {
        organizationId: organizationContext.organisation_id,
        role: '',
        email: '',
        firstName: '',
        lastName: '',
    }

    // first step
    const verifyEmail = async(user) => {
        const dataUser = await authContext.getUsersList('email', '=', user.email);
        const dataOrg = await organizationContext.getOrganization(user.organizationId);
        const dataRole = await rolesContext.getRole(user.roleId);
        return { ...user, organization: dataOrg, role: dataRole, existingUser: dataUser.Users?.[0] ? dataUser.Users[0] : null }
    }

    // Checks if only needs to link or create too
    const addUser = (user) => {
        if(user.existingUser){ // If user exists, only link
            return linkUser(user, user.organizationId, user.roleId);
        } // else create user
        return createAndLinkUser(user);

    }

    // links existing user to role
    const linkUser = (user, orgId, roleId, isNew) => (
        identityRolesContext.linkIdentityToRole(user.existingUser.Username, orgId, roleId)
            .then(() => ({ ...user, isNew: isNew }))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    let msg;
                    if(error.i18n){
                        msg = displayI18n('message', error.i18n, error.message, getGenericLocale())
                    }else{
                        switch (error.message){
                            case '3090':
                            case '3096':
                                msg = `contexts.identityRolesContext.errors.${error.message}`;
                                break;
                            default:
                                msg = 'organization.profile.users.multiple.addUser.modal.error.link';
                                break;
                        }
                    }
                    return { ...user, error: msg }
                }
            })
    )

    // Creates user
    const createAndLinkUser = (user) => (
        authContext.createUser({ name: user.firstName, familyName: user.lastName, email: user.email })
            .then((newUser) => linkUser({ ...user, existingUser: newUser }, user.organizationId, user.roleId, true))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    return { ...user, error: 'organization.profile.users.multiple.addUser.modal.error.create' }
                }
            })
    )

    return (
        <Formik
            initialValues={{
                users: [ {
                    organizationId: organizationContext.organisation_id,
                    id: generatePassword(),
                    roleId: '',
                    email: '',
                    firstName: '',
                    lastName: '',
                } ],
            }}
            validationSchema={Yup.object().shape({
                users: Yup.array().of(Yup.object().shape({
                    organizationId: Yup.string().required(<Translate id='organization.profile.users.addUser.validation.organization.required' />),
                    roleId: Yup.string().required(<Translate id='organization.profile.users.addUser.validation.role.required' />),
                    email: Yup.string().required(<Translate id='form.validation.email.required' />).email(<Translate id='form.validation.email.valid' />).
                        test({
                            name: 'no-duplicate-email',
                            message: <Translate id='organization.profile.users.multiple.addUser.duplicate' />,
                            test: function(){
                                const emailArr = this.from[this.from.length - 1].value.users.map((user) => user.email);
                                return (!emailArr.some((email, index) => {
                                    return emailArr.indexOf(email) != index; // checks that the occurence of email has the same index as current index (confirming that it's unique)
                                }));
                            },
                        }),
                    firstName: Yup.string().required(<Translate id='form.validation.firstName.required' />),
                    lastName: Yup.string().required(<Translate id='form.validation.lastName.required' />),
                })),
            })}
            onSubmit={(values, { setSubmitting }) => {
                Promise.all([
                    ...values.users.map((user) => (
                        new Promise(async(resolve) => {
                            const verifiedUser = await verifyEmail(user);
                            const addedUser = await addUser(verifiedUser);
                            resolve(addedUser);
                        })
                    )),
                ])
                    .then((promises) => {
                        setCreatedUsers(promises);
                        setIsOpen(true);
                        setSubmitting(false);
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                            setSubmitting(false);
                        }
                    });

            }}
        >
            {(formik) => (
                <OverlayLoader isLoading={formik.isSubmitting}>
                    <Form name="addRebate" id="addRebate">

                        <AddMultipleModal resetForm={formik.resetForm} createdUsers={createdUsers} isOpen={isOpen} toggle={() => setIsOpen(false)} />
                        <AddMultipleGridModal users={formik.values.users} formik={formik} isOpen={isGridOpen} toggle={() => setIsGridOpen(false)} maxUsers={maxUsers} />

                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <FormGroup>
                                <FieldArray name="users">
                                    {(arrayHelper) => (
                                        <>
                                            {formik.values.users.map((user, index) => (
                                                <AddUserCard
                                                    arrayHelper={arrayHelper}
                                                    removeCard={arrayHelper.handleRemove(index)}
                                                    openGrid={() => setIsGridOpen(true)}
                                                    index={index}
                                                    length={formik.values.users.length}
                                                    key={user.id}
                                                />
                                            ))}
                                            { formik.values?.users?.length < maxUsers &&
                                                <UserDisplay
                                                    card hover
                                                    onClick={arrayHelper.handlePush({ ...emptyUser, id: generatePassword() })}
                                                    // className="btn btn-link"
                                                    className={stringBuilder('hover w-100 mb-0 border-primary h-100 mb-4')} style={{ minWidth: 'unset', borderStyle: 'dashed', borderWidth: '1px' }}
                                                >
                                                    <UserDisplay.Container className="text-center w-100 text-primary">
                                                        <i className={'mdi mdi-plus mr-1'} /><Translate id='organization.profile.users.addUser.title' />
                                                    </UserDisplay.Container>
                                                </UserDisplay>
                                            }
                                        </>
                                    )}
                                </FieldArray>
                            </FormGroup>
                            <div className="d-flex justify-content-end mb-phat">
                                <Button form="addRebate" color='primary' type="submit" className={'mr-1'}><Translate id='misc.save' /></Button>
                                <Button color='primary' tag={Link} to={'/organization/users'} outline><Translate id='misc.cancel' /></Button>
                            </div>
                        </OverlayLoader>
                    </Form>
                </OverlayLoader>
            )}
        </Formik>
    );
}

const AddUserCard = ({ index, length, removeCard, openGrid }) => {
    const formik = useFormikContext();
    const organizationContext = useContext(OrganizationContext);
    const rolesContext = useContext(RolesContext);

    const [ currentOrg, setCurrentOrg ] = useState(formik.values.users[index].organizationId);
    const [ currentRole, setCurrentRole ] = useState(formik.values.users[index].active);

    const roleSelectRef = useRef();

    useEffect(() => {
        roleSelectRef.current?.getSpordleTable().filterChange();
    }, [ currentOrg ])

    return (
        <Card className="card-shadow">
            <CardHeader className="d-flex align-items-center">
                <div className="font-bold h5 mb-0">
                    <Translate id='organization.profile.users.multiple.addUser.card.title' /> ({index + 1} / {length})
                </div>
                { index === 0 &&
                    <button type="button" onClick={openGrid} className="ml-auto btn btn-link px-0 text-primary">
                        <Translate id='organization.profile.users.multiple.addUser.grid' />
                    </button>
                }
                { index !== 0 &&
                    <button type="button" onClick={removeCard} className="ml-auto btn btn-link px-0 text-danger">
                        <Translate id="misc.remove" />
                    </button>
                }
            </CardHeader>
            <CardBody>
                <FormGroup>
                    <Label for={`users.${index}.organizationId`} className='text-muted'><Translate id='organization.profile.users.addUser.fields.organization' /> <Required /></Label>
                    <OrganizationSearch
                        selectedDefault={organizationContext.organisation_id}
                        name={`users.${index}.organizationId`}
                        id={`users.${index}.organizationId`}
                        onChange={(org) => {
                            setCurrentOrg(org.value);
                            formik.setFieldValue(`users.${index}.roleId`, '');
                        }}
                        withFormik
                        manualError
                    />
                </FormGroup>
                <Row form>
                    <Col sm="6">
                        <FormGroup>
                            <Label for={`users.${index}.roleId`} className='text-muted'><Translate id='organization.profile.users.addUser.fields.role' /> <Required /></Label>
                            <FormikSelect
                                name={`users.${index}.roleId`}
                                id={`users.${index}.roleId_AddMultipleUsersTab`}
                                noOptionsMessage='organization.profile.users.addUser.fields.noRole'
                                placeholder='organization.profile.users.addUser.fields.role.placeholder'
                                disabled={formik.values.users[index].organizationId === ''}
                                ref={roleSelectRef}
                                onOptionSelected={(role) => setCurrentRole(role[0])}
                                renderOption={(option) => (
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <span>{option.option.label}</span>
                                        {option.option.active == 0 &&
                                    <i className='text-warning mdi mdi-alert-outline' />
                                        }
                                    </div>
                                )}
                                loadData={(from) => {
                                    switch (from){
                                        case 'CDM':
                                        case 'FILTER':
                                            return rolesContext.getOrganizationRoles(currentOrg)
                                                .then((_roles) => {
                                                    return (_roles.map((role) => ({
                                                        id: role.role_id,
                                                        value: role.role_id,
                                                        label: role.title,
                                                        active: role.active,
                                                    })))
                                                })
                                        default:
                                            break;
                                    }
                                }}
                            />
                            {currentRole?.active == 0 &&
                        <span className='text-warning small'><Translate id='organization.profile.users.addUser.fields.role.inactive' /></span>
                            }
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label className='text-muted'><Translate id='form.fields.email' /> <Required /></Label>
                            <FormikInputText
                                name={`users.${index}.email`}
                                id={`users.${index}.email`}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col sm="6">
                        <FormGroup>
                            <Label className='text-muted'><Translate id='form.fields.firstName' /> <Required /></Label>
                            <FormikInputText
                                name={`users.${index}.firstName`}
                                id={`users.${index}.firstName`}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label className='text-muted'><Translate id='form.fields.lastName' /> <Required /></Label>
                            <FormikInputText
                                name={`users.${index}.lastName`}
                                id={`users.${index}.lastName`}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )


}

export default AddMultipleUsersTab;