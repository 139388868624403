export default{
    "organization.settings.sidePanelOrganizationTeamTravelTypes.title" : "Travel Type Information",

    "organization.settings.sidePanelOrganizationTeamTravelTypes.status"          : "Status",
    "organization.settings.sidePanelOrganizationTeamTravelTypes.status.active"   : "Active",
    "organization.settings.sidePanelOrganizationTeamTravelTypes.status.inactive" : "Inactive",
    "organization.settings.sidePanelOrganizationTeamTravelTypes.status.required" : "Status is required",

    "organization.settings.sidePanelOrganizationTeamTravelTypes.update.success.text" : "Travel Type updated",
    "organization.settings.sidePanelOrganizationTeamTravelTypes.update.fail.text"    : "An error occurred during the Travel Type update, please refresh the page et try again",

    // actions
    "organization.settings.sidePanelOrganizationTeamTravelTypes.actions.remove"         : "Remove",
    "organization.settings.sidePanelOrganizationTeamTravelTypes.actions.remove.topText" : "You are about to remove this Travel Type from your Organization",

    // tabs
    "organization.settings.sidePanelOrganizationTeamTravelTypes.tabs.details" : "Details",
}