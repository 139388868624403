import PropTypes from 'prop-types';
import { stringBuilder } from "@spordle/helpers";

/**
* @param {React.ComponentType} [tag='div'] The desired htmlElement of the container.
* @param {string} [className]
* @example
<FormGroup>
    <Label for='name' className='text-muted'>Name <Required/></Label>
    <FormikInputText id='name' name='name'/>
</FormGroup>
*/
const Required = ({
    tag: Tag,
    className,
    ...attributes
}) => {

    return (
        <Tag {...attributes} className={stringBuilder('text-danger', className)}>*</Tag>
    );
};

export default Required;

Required.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
};
Required.defaultProps = {
    tag: 'span',
};