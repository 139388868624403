import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { AxiosIsCancelled, serverError } from '../api/CancellableAPI';
import queryString from 'query-string';

import withContexts from '../helpers/withContexts';
import { I18nContext } from './I18nContext';

import * as Sentry from "@sentry/react";
import { jsObjectToApi } from '@spordle/helpers';
import { fail } from "@spordle/toasts";
import { DisplayI18n } from '../helpers/i18nHelper';
import { PeriodsContext } from './contexts';
import { OrganizationContext } from './OrganizationContext';

/** @type {React.Context<Omit<CartsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const CartsContext = createContext();
CartsContext.displayName = 'CartsContext';

class CartsContextProvider extends React.Component{

    //////////////////////////////////////////////////////////////////////////////
    // Carts context
    // You'll notice that the createCart only takes in an item and an optionnal cart ID.
    // The reason for that is that the cart is created with the access token that is sent in the headers.
    // That means that the getCarts call will fetch all the carts for the access token,
    // and we agreed to have a similar workflow as the clinic under creation one.
    // idk, maybe, TODO: delete other carts
    // A user will always have a single cart, when we get all carts, we will select the first one, delete every other ones,
    // and we will ask the user if he wants to restart or continue his cart, if he restarts, we will empty the cart with the PATCH.
    //////////////////////////////////////////////////////////////////////////////

    state = {
        cachedCart: {},
    }

    componentDidUpdate(){
        Sentry.setContext('cart', this.state.cachedCart.shopping_cart_id ? {
            cartId: this.state.cachedCart.shopping_cart_id,
            invoiceNumber: this.state.cachedCart.invoice_number,
            cartSummary: JSON.stringify(this.state.cachedCart.cart_summary),
            cartDetails: JSON.stringify(this.state.cachedCart.cart_detail),
            createdBy: this.state.cachedCart.created_by?.identity_id,
            cartTermsId: this.state.cachedCart.term_and_condition?.term_and_condition_id,
        } : null);
        Sentry.setTag('cartId', this.state.cachedCart.shopping_cart_id);
    }

    updateCachedCart = (newData) => {
        this.setState((prevState) => ({ cachedCart: { ...prevState.cachedCart, ...newData } }))
    }

    emptyCachedCart = () => {
        this.setState({
            cachedCart: {},
        });
    }

    /**
     * Gets all unfinished carts linked to the access token of the user, deletes all carts except for the most recent one, returns the remaining one
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AgetAllCartInProgress|documentation}
     * @returns {Promise}
     */
    getCarts = () => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/carts` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.carts;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET[SPECIFIC] - Cart
     * @param {string} shopping_cart_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AGetCartDetail|documentation}
     * @returns {Promise}
     */
    getCart = (shopping_cart_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/carts/${shopping_cart_id}` }))
            .then((response) => {
                if(response.data.status){
                    this.updateCachedCart(response.data.carts[0])
                    return response.data.carts[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Adds an item to a cart, if the cart doesn't exist, the system creates a cart
     * @param {object} values Values containing a cart ID (optionnal) and information about the item do add
     * @param {string} [values.shopping_cart_id] ID of the store to add an item to
     * @param {object} values.item Item to add to the cart
     * @param {string} values.item.item_id Item ID
     * @param {'OTHER'|'REGISTRATION'|'CLINIC'|'AFFILIATION'} values.item.type Type of the item, must be OTHER, REGISTRATION, CLINIC or AFFILIATION
     * @param {string} values.item.member_id Member ID
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AAddToCart|documentation}
     * @returns {Promise}
     */
    createCart = (values) => {
        const params = new URLSearchParams();

        if(values.shopping_cart_id){
            params.append('shopping_cart_id', values.shopping_cart_id);
        }
        params.append('item[item_id]', values.item.item_id)
        params.append('item[type]', values.item.type)
        params.append('item[member_id]', values.item.member_id)
        params.append('skip_mandatory_fields_check', 1)
        if(values.item.overwrite_restrictions)
            params.append('item[overwrite_restrictions]', 1)//bitwise OR operator
        if(values.item.waiting_list_item_id)
            params.append('item[waiting_list_item_id]', values.item.waiting_list_item_id)
        if(values.item.custom_price != null)// number 0 is a valid value -> We can't do if(values.item.custom_price) because number 0 will be false
            params.append('item[custom_price]', values.item.custom_price)

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/carts` }), params)
            .then((response) => {
                if(response.data.status){
                    this.updateCachedCart({ shopping_cart_id: response.data.shopping_cart_id })
                    return response.data.shopping_cart_id;
                }
                throw response.data.errors[0];
                // throw full error object so we can display the translated message in origin
                // throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Exchange an invoice item with another item
     * @param {string} invoiceItemId Invoice item ID to exchange
     * @param {string} itemId New item ID
     * @param {bool} returnFullError Wether or not to return the full error in the throw
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Carts/10fa93573aa3ff902d044cde0db16435|documentation}
     * @returns {Promise}
     */
    exchangeCart = (invoiceItemId, itemId, overwrite_restrictions = false) => {
        const params = new URLSearchParams();

        params.append('invoice_item_id', invoiceItemId);
        params.append('item_id', itemId);
        if(overwrite_restrictions)
            params.append('overwrite_restrictions', 1);

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: '/carts/package-change',
        }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.shopping_cart_id
                }
                throw response.data.errors[0];
                // throw full error object so we can display the translated message in origin

            }, serverError)
    }

    /**
     * Sign all waivers for a division
     * @param {Object} values
     * @returns {Promise}
     */
    signDivisionWaivers = (values) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params, {
            skipNull: true,
            skipEmptyString: true,
        })

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: '/members/sign-waivers',
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Associate terms and condition to a cart.
     * @param {String} shopping_cart_id
     * @param {String} terms_and_condition_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AaddTermsToCart|documentation}
     * @returns {Promise}
     */
    associateCart = (shopping_cart_id, terms_and_condition_id) => {
        const params = new URLSearchParams();

        params.append("term_and_condition_id", terms_and_condition_id)
        params.append("language_code", this.props.I18nContext.getGenericLocale())

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/carts/${shopping_cart_id}/terms-and-conditions`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Use this call to associate position or position-goup to a cart item.
     * @param {String} cartId
     * @param {String} rowId
     * @param {Object} values
     * @see https://api.id.dev.spordle.dev/documentations/#/Carts/e37f7b8275397470e960e03b2987970f
     * @returns {Promise}
     */
    associateItemPosition = (cartId, rowId, values = {}) => {
        const params = new URLSearchParams();

        params.append("position_group_id", values.position_group_id);
        if(values.position_id)
            params.append("position_id", values.position_id);

        return API_SPORDLE.put(`/carts/${cartId}/items/${rowId}/positions`, params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update answers for an item's form
     * @param {string} shopping_cart_id
     * @param {string} row_id
     * @param {Object} values The values for that call
     * @param {string} values.custom_form_id The values for that call
     * @param {Array} values.fields The custom form's fields
     * @param {string} values.fields.custom_form_field_id Field's ID
     * @param {string|Array} [values.fields.custom_form_field_option_id] Field's select option ID - Optionnal - Used for radio buttons, selects, etc.
     * @param {string|Array} values.fields.answer Field's answer - Send the answer in the language that the user answered in - If the fields has options, send the text value of that option in the language that the user answered in
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AaddCustomFormToCart|documentation}
     * @returns {Promise.<boolean>}
     */
    updateCartItemForm = (shopping_cart_id, row_id, values) => {
        const params = new URLSearchParams()

        params.append('custom_form_id', values.custom_form_id);

        values.fields.forEach((field, index) => {
            params.append(`fields[${index}][custom_form_field_id]`, field.custom_form_field_id);

            // array.toString() formats it to a string with a comma separating the values
            if(field.custom_form_field_option_id !== undefined && field.custom_form_field_option_id !== null)
                params.append(`fields[${index}][custom_form_field_option_id]`, Array.isArray(field.custom_form_field_option_id) ? field.custom_form_field_option_id.toString() : field.custom_form_field_option_id);

            params.append(`fields[${index}][answer]`, Array.isArray(field.answer) ? field.answer.toString() : field.answer);
        })

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/carts/${shopping_cart_id}/items/${row_id}/forms`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Associate waiver to a cart item
     * @param {string} shopping_cart_id
     * @param {string} row_id
     * @param {object} [values] The values for that call
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AaddWaiverToCartItem|documentation}
     * @returns {Promise.<boolean>}
     */
    updateCartItemWaiver = (shopping_cart_id, row_id, values) => {
        const params = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'waiver_id':
                    params.append('waiver_id', values.waiver_id);
                    break;
                case 'language_code':
                    params.append('language_code', values.language_code);
                    break;
                case 'answer':
                    params.append('answer', values.answer);
                    break;
                case 'signature_type':
                    if(values.signature_type) // dont want to send if null
                        params.append('signature_type', values.signature_type);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/carts/${shopping_cart_id}/items/${row_id}/waivers`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Associate installment mode to a cart item.
     * @param {string} shopping_cart_id
     * @param {string} row_id
     * @param {string} [installment_mode_id]
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AapplyInstallmentModeToCartItem|documentation}
     * @returns {Promise.<boolean>}
     */
    updateCartItemInstallment = (shopping_cart_id, row_id, installment_mode_id) => {
        const data = new URLSearchParams();
        if(installment_mode_id)
            data.append('installment_mode_id', installment_mode_id)

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/carts/${shopping_cart_id}/items/${row_id}/installments`,
        }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Delete an installment mode from a cart item.
     * @param {string} shopping_cart_id
     * @param {string} row_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AremoveInstallementModeFromCartItem|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteCartItemInstallment = (shopping_cart_id, row_id) => {
        return API_SPORDLE.delete(`/carts/${shopping_cart_id}/items/${row_id}/installments`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Empties cart
     * @param {string} shopping_cart_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AemptyCart|documentation}
     * @returns {Promise.<boolean>}
     */
    emptyCart = (shopping_cart_id) => {
        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/carts/${shopping_cart_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Change quantity on an item in an existing cart, quantity can only be modify for item with Type OTHER that are not link to a registration
     * @param {string} shopping_cart_id
     * @param {string} item_row_id
     * @param {Object} values
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Carts/2c7737336cdf19d64ed562391ff547b1|documentation}
     * @returns {Promise.<boolean>}
     */
    updateCart = (shopping_cart_id, item_row_id, values) => {
        const params = new URLSearchParams()

        for(const key in values){
            params.append(key, values[key])
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/carts/${shopping_cart_id}/items/${item_row_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }


    /**
    * Deletes a cart
    * @param {string} shopping_cart_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AdeleteCart|documentation}
    * @returns {Promise}
    */
    deleteCart = (shopping_cart_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `carts/${shopping_cart_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
    * Deletes a item in existing cart
    * @param {string} shopping_cart_id
    * @param {string} item_row_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3AdeleteCartItem|documentation}
    * @returns {Promise}
    */
    deleteCartItem = (shopping_cart_id, item_row_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `carts/${shopping_cart_id}/items/${item_row_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Cleares carts for the user and returns a new empty cart id
    * @returns {Promise}
    */
    clearCarts = () => {
        this.emptyCachedCart();
        return this.getCarts()
            .then((carts) => {
                carts.forEach((cart) => {
                    this.deleteCart(cart.shopping_cart_id).catch(console.error)
                })
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    /**
     * Checkout
     * @param {string} shopping_cart_id
     * @param {object} values
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Carts/Apicontroller%5CCarts%5CCarts%3A%3Acheckout|documentation}
     * @returns {Promise}
     */
    checkoutCart = (shopping_cart_id, values) => {
        const params = new URLSearchParams();

        params.append("language_code", this.props.I18nContext.getGenericLocale());
        if(values.payment_type){
            params.append("payment_type", values.payment_type);
        }
        if(values.payment_type === 'CREDITCARD'){
            // When "online" payment method
            params.append("redirect_url", window.location.href);

            // Add pre-init data
            params.append("pre_init", 1);
            if(values.merchant_account_id)
                params.append("merchant_account_id", values.merchant_account_id);
            if(values.identity_id)
                params.append("identity_id", values.identity_id);
        }

        if(process.env.REACT_APP_ENVIRONMENT !== 'prod'){
            params.append("test_mode", 1);
        }

        if(values.invoice_date){
            params.append("invoice_date", values.invoice_date);
        }

        if(values.period_id){
            params.append("period_id", values.period_id);
        }

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/carts/${shopping_cart_id}/checkout`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Converts an invoice to credit card
     * @param {string} invoiceNumber Invoice number to convert
     * @param {Object} values Object containing the values - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Invoices/0d8b2affc7ebd412e1423ff2b01510d4|documentation}
     * @returns {Promise}
     */
    convert = (invoiceNumber, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params);

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/invoices/${invoiceNumber}/convert-to-cc`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Converts an invoice to manual
     * @param {string} invoiceNumber Invoice number to convert
     * @param {Object} values Object containing the values - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Invoices/5086f21847e7ed5fe8a9655513bc3b89|documentation}
     * @returns {Promise}
     */
    convertToManual = (invoiceNumber, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params);

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/invoices/${invoiceNumber}/convert-to-manual`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Credits an invoice
     * @param {string} invoiceNumber Invoice number
     * @param {Object} values Object containing the values - internal_note, external_note
     * @returns {Promise}
     */
    creditInvoice = (invoiceNumber, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params);

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/invoices/${invoiceNumber}/credit`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Credits an invoice
     * @param {string} invoiceNumber Invoice number
     * @param {Object} values Object containing the values - internal_note, external_note
     * @see https://api.id.dev.spordle.dev/documentations/index.php#/Invoices/fc894d3db1a8b67e804991de2d4ccb1d
     * @returns {Promise}
     */
    confirmInvoicePayment = (invoiceNumber) => {
        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/invoices/${invoiceNumber}/confirm-payment`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                if(response.data.provider_return_data){
                    throw{
                        message: response.data.provider_return_data.message,
                        i18n: {},
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get waiting list items
     * @param {Object} params
     * @returns {Promise.<Array>}
     * @see https://api.id.dev.spordle.dev/documentations/#/Waiting%20List%20Items/d371567b6068153668f258d8defd23e8
     */
    getWaitingListItems = (params) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/waiting-list-items`,
            query: {
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                organisation_id: this.props.OrganizationContext.organisation_id,
                ...params,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.waiting_list_items;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            // Speading `this` is very important because it creates a new value object so it rerenders the Consumers
            // If we don't spead, react/javascript sees it as the same object thus, not rerendering the Consumers
            <CartsContext.Provider value={{ ...this }}>
                {this.props.children}
            </CartsContext.Provider>
        )
    }
}

export default withContexts(I18nContext, PeriodsContext, OrganizationContext)(CartsContextProvider);