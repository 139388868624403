import PropTypes from 'prop-types';
import { stringBuilder } from "@spordle/helpers";

/**
 * @param {React.ComponentType} [tag='div'] The desired htmlElement of the container.
 * @param {string} [className]
*/
const UserDisplayContainer = ({ tag: Tag,
                                className,
                                ...attributes }) => {

    return (
        <Tag {...attributes} className={stringBuilder('userDisplay-container', className)} />
    );
};

export default UserDisplayContainer;

UserDisplayContainer.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
};
UserDisplayContainer.defaultProps = {
    tag: 'div',
};