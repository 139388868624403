export default{
    // exchange
    "clinics.profile.attendees.addModal.exchange.categories"                                : "Categories",
    "clinics.profile.attendees.addModal.exchange.qualifications"                            : "Qualifications",
    "clinics.profile.attendees.addModal.exchange.errors.3420"                               : "The member has already attended the current clinic",
    "clinics.profile.attendees.addModal.exchange.currentClinic"                             : "Current clinic",
    "clinics.profile.attendees.addModal.exchange.soldOut"                                   : "Sold out",
    // waiting list
    "clinics.profile.attendees.addModal.waitingList.title"                                  : "The clinic is sold out",
    "clinics.profile.attendees.addModal.waitingList.text"                                   : "Click {confirm} to register the member to the waiting list.",
    // waiting list confirmation
    "clinics.profile.attendees.addModal.waitingListConfirmation.number"                     : "Waiting list number",
    "clinics.profile.attendees.addModal.waitingListConfirmation.goToWaitlist"               : "Go to waiting list",
    // waiting list return
    "clinics.profile.attendees.addModal.waitingListReturn.title"                            : "Complete registration",
    "clinics.profile.attendees.addModal.waitingListReturn.text"                             : "Complete the registration for {firstName} {lastName}.",
    "clinics.profile.attendees.addModal.waitingListReturn.manual"                           : "Manual registration",
    "clinics.profile.attendees.addModal.waitingListReturn.manual.desc"                      : "Complete the registration manually for this member.",
    "clinics.profile.attendees.addModal.waitingListReturn.identity"                         : "Select an identity",
    "clinics.profile.attendees.addModal.waitingListReturn.identity.desc"                    : "Send an email to a user to complete the registration.",
    // waiting list return confirmation
    "clinics.profile.attendees.addModal.waitingListReturnConfirmation.title"                : "You are almost done!",
    "clinics.profile.attendees.addModal.waitingListReturnConfirmation.text"                 : "Click {confirm} to send an email to {name}",
    "clinics.profile.attendees.addModal.waitingListReturnConfirmation.confirmation"         : "Congratulations!",
    "clinics.profile.attendees.addModal.waitingListReturnConfirmation.confirmationMessage"  : "The email has been sent. The member is now waiting for the client to complete the registration.",
    // clinic infos
    "clinics.profile.attendees.addModal.prerequisitesRequired"                              : "Prerequisites",
    "clinics.profile.attendees.addModal.prerequisites.qualification"                        : "Qualification's prerequisites",
    "clinics.profile.attendees.addModal.prerequisites.organization"                         : "Organization's prerequisites",
    "clinics.profile.attendees.addModal.prerequisites.none"                                 : "No prerequisites",
    "clinics.profile.attendees.addModal.prerequisites.restrictions.all"                     : "All mandatory",
    "clinics.profile.attendees.addModal.prerequisites.restrictions.one"                     : "Only one mandatory",
    "clinics.profile.attendees.addModal.tax"                                                : "tax",
    "clinics.profile.attendees.addModal.fees"                                               : "fees",
    "clinics.profile.attendees.addModal.priceOneParticipant"                                : "Price for one participant",
    "clinics.profile.attendees.addModal.until"                                              : "Until",
    "clinics.profile.attendees.addModal.form.registration.date"                             : "Registration date",
    "clinics.profile.attendees.addModal.form.registration.date.before"                      : "The date must be before today",
    "clinics.profile.attendees.addModal.form.registration.time"                             : "Registration time",
    "clinics.profile.attendees.addModal.form.registration.time.before"                      : "The time must be before now",
    // member select
    "clinics.profile.attendees.addModal.memberSelect.title"                                 : "Registration",
    "clinics.profile.attendees.addModal.memberSelect.or"                                    : "Or",
    "clinics.profile.attendees.addModal.memberSelect.memberNumber"                          : "{unique_identifier}",
    "clinics.profile.attendees.addModal.memberSelect.memberNumber.hint"                     : "The validation will ignore the other fields if this one is specified",
    "clinics.profile.attendees.addModal.memberSelect.noData"                                : "No results",
    "clinics.profile.attendees.addModal.memberSelect.select"                                : "Select",
    // warning messages
    "clinics.profile.attendees.addModal.warnings.clinic_inactive"                           : "The clinic is inactive",
    "clinics.profile.attendees.addModal.warnings.clinic_must_have_period"                   : "The clinic must be linked to a period",
    "clinics.profile.attendees.addModal.warnings.clinic_period_doesnt_exist_or_inactive"    : "The clinic's period does not exist or is inactive",
    "clinics.profile.attendees.addModal.warnings.invalid_clinic_status"                     : "The clinic's status is invalid",
    "clinics.profile.attendees.addModal.warnings.clinic_already_started"                    : "The clinic has already started",
    "clinics.profile.attendees.addModal.warnings.mandatory_fees_needed_for_clinic"          : "Mandatory fees are needed for the clinic",
    "clinics.profile.attendees.addModal.warnings.clinic_soldout"                            : "The clinic is sold out",
    "clinics.profile.attendees.addModal.warnings.clinic_must_have_terms_and_conditions"     : "The clinic must have terms and conditions",
    "clinics.profile.attendees.addModal.warnings.member_has_already_clinic_qualification"   : "The clinic's qualification is already obtained by this member",
    "clinics.profile.attendees.addModal.warnings.inactive_member"                           : "This member is inactive",
    "clinics.profile.attendees.addModal.warnings.temporary_member"                          : "This member is a temporary member",
    "clinics.profile.attendees.addModal.warnings.clinic_unavailable_in_member_organisation" : "The clinic is not available for this member's organization",
    "clinics.profile.attendees.addModal.warnings.member_need_background_check"              : "This member needs a background check.",
    "clinics.profile.attendees.addModal.warnings.link.member_need_crc"                      : "Manage background checks",
    "clinics.profile.attendees.addModal.warnings.member_had_already_purchased_this_clinic"  : "This member has already purchased the clinic",
    "clinics.profile.attendees.addModal.warnings.missing_birthdate"                         : "This member is missing a birthdate",
    "clinics.profile.attendees.addModal.warnings.member_doesnt_respect_min_age"             : "This member does not meet the minimum age requirement for this clinic",
    "clinics.profile.attendees.addModal.warnings.member_doesnt_respect_max_age"             : "This member does not meet the maximum age requirement for this clinic",
    "clinics.profile.attendees.addModal.warnings.need_all_prerequisites"                    : "This member must have all the prerequisites for this clinic",
    "clinics.profile.attendees.addModal.warnings.member_had_an_unwanted_qualification"      : "This member had an unwanted qualification",
    "clinics.profile.attendees.addModal.warnings.member_need_prerequisite"                  : "This member must have the necessary prerequisites for this clinic",
}