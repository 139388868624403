import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { Button, ModalFooter } from "reactstrap"

/**
 * @description More mobile friendly modal footer for accessibility
 * @param {Object} props
 * @param {function} [props.previous]
 * @param {string} [props.submitLabel]
 * @returns {JSX.Element}
 */
const StepFooter = ({ previous, previousLabel, submitLabel, disabled = false, hideSubmit = false }) => {
    return (
        <ModalFooter>
            {previous &&
                <Button disabled={disabled} outline color="primary" type="button" onClick={previous} className="mr-md-auto d-block w-100 w-md-auto d-md-inline-block">
                    <Translate id={previousLabel ?? "misc.previous"} />
                </Button>
            }
            {!hideSubmit &&
                <Button disabled={disabled} color="primary" type="submit" className={stringBuilder(`d-block w-100 w-md-auto d-md-inline-block`)}>
                    <Translate id={submitLabel ?? "misc.next"} />
                </Button>
            }
        </ModalFooter>
    )
}

export default StepFooter;