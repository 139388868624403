import { Tooltip } from "@mantine/core";
import { useContext } from "react";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";

const Wrapper = ({ children }) => {
    const { isGod } = useContext(IdentityRolesContext);

    if(isGod()){
        return (
            <Tooltip
                label={
                    <div>
                        <u>Permissions required</u>
                        <div className="text-nowrap">organization → organization_profile → ADD</div>
                        <div className="text-nowrap">organization → affiliated_organization → ADD</div>
                        <div className="text-nowrap">organization → organization_users → ADD</div>
                    </div>
                }
            >
                {children}
            </Tooltip>
        )
    }
    return children
}

export default Wrapper