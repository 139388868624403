import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { Button } from "reactstrap";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";
import { InjuriesContext } from "../../../../contexts/InjuriesContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import PdfSettingsModal from "./PdfSettingsModal";

const ExportCheques = ({ canPrint, printCheques, printWorksheet }) => {
    const { getInsuranceChequeList } = useContext(InjuriesContext);
    const { organisation_id } = useContext(OrganizationContext);
    const [ printModal, setPrintModal ] = useState({
        isOpen: false,
        settings: {},
        printCheques: handlePrintCheques,
    });

    async function handlePrintCheques(apiVal){
        return getInsuranceChequeList(organisation_id, { is_printed: '0' })
            .then((cheques) => {
                if(cheques.length > 0){
                    printCheques({
                        ...apiVal,
                        injury_cheque_id: cheques.map((c) => c.injury_cheque_id).join(','),
                    });
                }else{
                    throw new Error('nocheques');
                }
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    async function handlePrintWorksheet(params){
        if(params){
            printWorksheet(params)
        }else{
            throw new Error('Missing info');
        }
    }

    return (
        <>
            {canPrint &&
                <>
                    <Button
                        color="primary"
                        outline
                        type="button"
                        className="ml-2"
                        onClick={() => {
                            setPrintModal({
                                isOpen: true,
                                settings: {
                                    initMode: 'unprinted',
                                    printCheques: handlePrintCheques,
                                    disabledModes: [ 'chequeNb' ],
                                },
                            })
                        }}
                    >
                        <i className='fas fa-file-pdf mr-1' /> <Translate id='insurance.export.cheques.pdf' />
                    </Button>
                    <Button
                        color="primary"
                        outline
                        type="button"
                        className="ml-2"
                        onClick={() => {
                            setPrintModal({
                                isOpen: true,
                                settings: {
                                    initMode: 'chequeNb',
                                    printCheques: handlePrintWorksheet,
                                    disabledModes: [ 'unprinted' ],
                                },
                            })
                        }}
                    >
                        <i className='fas fa-file-pdf mr-1' /> <Translate id='insurance.export.worksheet.pdf' />
                    </Button>
                </>
            }
            <PdfSettingsModal
                isOpen={printModal.isOpen}
                toggle={() => setPrintModal((prev) => ({ ...prev, isOpen: false }))}
                {...printModal.settings}
            />
        </>
    );
}

export default ExportCheques;