import Translate from '@spordle/intl-elements';
import { useState } from 'react';
import { ModalHeader } from "reactstrap";
import CreateVenueStep1 from './CreateVenueStep1';
import CreateVenueStep2 from './CreateVenueStep2';

const AddOrganizationVenuesModalSelect = ({ toggle, toggleAddLocationForm }) => {
    const venueStructure = {
        venueAbbr: '',
        venueName: '',
        venueType: '',
        venueSize: '',
    }

    const [ venueData, setVenueData ] = useState({
        venues: [ venueStructure ],
    });
    const [ currentStep, setCurrentStep ] = useState(1);

    const changeStep = (step) => {
        setCurrentStep(step);
    }

    const addData = (data) => {
        setVenueData((prev) => {
            return { ...prev, ...data };
        });
    }

    return (
        <>
            <ModalHeader toggle={toggle}>
                <div><Translate id={`organization.profile.venues.modals.components.createVenueStep${currentStep}.title`} /></div>
            </ModalHeader>
            { currentStep === 1 ?
                <CreateVenueStep1 toggleAddLocationForm={toggleAddLocationForm} venueData={venueData} onSubmit={addData} changeStep={changeStep} />
                :
                <CreateVenueStep2 dataStructure={venueStructure} venueData={venueData} onSubmit={addData} changeStep={changeStep} />
            }
        </>
    )
}

export default AddOrganizationVenuesModalSelect;