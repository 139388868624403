export default{
    "organisation.profile.title"                     : "Organization",
    "organisation.profile.header.memberNumber"       : "{unique_identifier}",
    "organisation.profile.header.image.upload.hint"  : "We recommend using an logo of you're organization of 200x200 minimum. Smaller images might have issues with social media link sharing.",
    "organisation.profile.header.image.upload.title" : "Upload a logo for you're organization",

    "organisation.profile.badge.missingMerchantAccount" : "Missing Merchant Account",
    "organisation.profile.badge.missingCRCSettings"     : "Missing Background Check Settings",
    "organisation.profile.badge.missingMandatoryRoles"  : "Missing Mandatory Organization Roles",
    "organisation.profile.badge.unsanctioned"           : "Unconfirmed",

    "organisation.profile.action.print.memorandum" : "Print Club Memorandum",
    "organisation.profile.action.print.insurace"   : "Print Insurance Certificate",

    "organisation.profile.header.note" : "Note",

    "organisation.profile.tabs.overview"     : "General",
    "organisation.profile.tabs.roles"        : "Roles",
    "organisation.profile.tabs.users"        : "Users",
    "organisation.profile.tabs.staff"        : "Staff",
    "organisation.profile.tabs.zones"        : "Zones",
    "organisation.profile.tabs.leagues"      : "Leagues",
    "organisation.profile.tabs.tournaments"  : "Tournaments",
    "organisation.profile.tabs.orgs"         : "Competition Organizations",
    "organisation.profile.tabs.competitions" : "Competitions",
    "organisation.profile.tabs.postalCodes"  : "Postal Codes",
    "organisation.profile.tabs.venues"       : "Location & Venues",
    "organisation.profile.tabs.accounting"   : "Accounting",

    "organisation.profile.actions.copy"                   : "Copy public link",
    "organisation.profile.actions.goToPage"               : "View public page",
    "organisation.profile.actions.submitApproval"         : "Submit for approval",
    "organisation.profile.actions.submitApproval.desc"    : "Submit this organization for approval by a parent organization.",
    "organisation.profile.actions.submitApproval.success" : "Successfully submitted this organization for approval.",

    "organisation.profile.tabs.overview.tax"             : "Tax",
    "organisation.profile.tabs.overview.noTaxNumber"     : "No Tax Numbers",
    "organisation.profile.tabs.overview.noTaxNumber.txt" : "No tax numbers in this organization.",
    "organisation.profile.tabs.overview.tax.required"    : "Please specify the tax",
    "organisation.profile.tabs.overview.number.required" : "Please specify the tax number",
    "organisation.profile.tabs.overview.addTax"          : "Add Tax Number",
}