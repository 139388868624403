export default{
    "organization.settings.organizationTeamAddressTypes.tab.title" : "Types d'adresses d'équipe",

    "organization.settings.organizationTeamAddressTypes.table.active" : "Actif",
    "organization.settings.organizationTeamAddressTypes.table.empty"  : "Aucun Type d'Adresse",

    // add
    "organization.settings.organizationTeamAddressTypes.add.title"           : "Ajouter un type d'adresse",
    "organization.settings.organizationTeamAddressTypes.add.status"          : "Statut",
    "organization.settings.organizationTeamAddressTypes.add.status.active"   : "Actif",
    "organization.settings.organizationTeamAddressTypes.add.status.inactive" : "Inactif",
    "organization.settings.organizationTeamAddressTypes.add.status.required" : "Le Statut est requis",
}