import React from "react";
import {
    Card,
    Col,
    Collapse,
    Container,
    ListGroup,
    ListGroupItem,
    Row
} from 'reactstrap';
import { stringBuilder } from '@spordle/helpers';

import OrganizationSearch from "../../../components/organization/OrganizationSearch";
import ViewHeaderV2 from "../../../components/viewHeader/ViewHeaderV2";
import moment from 'moment';

// Contexts
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { AuthContext, AppContext } from "../../../contexts/contexts";
import withContexts from '../../../helpers/withContexts';
import { AxiosCancelAll, AxiosIsCancelled } from "../../../api/CancellableAPI";
import { IdentityRolesContext } from "../../../contexts/IdentityRolesContext";
import { RolesContext } from "../../../contexts/RolesContext";

// Lang
import Translate, { DateFormat } from '@spordle/intl-elements';
import UserDisplay from "../../../components/userDisplay/UserDisplay";
import UserImg from "../../../components/UserImg";
import ChangeOrgModal from "./ChangeOrgModal";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import { fail } from "@spordle/toasts"
import { RoleCardAdditionalRoles } from "./RoleCardAdditionalRoles";

class ChangeOrganization extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isOpen: false,
            selected: null,
            identityRoles: null,
            recentOrgs: null,
            orgSearchValues: [ this.props.OrganizationContext.organisation_id ],
        }
    }

    orgSearchTreeClickOrganization = async(org) => {
        return this.setState({ isOpen: true, selected: { identityRole: this.props.IdentityRolesContext, org: org } });
    }

    orgSearchTriggerChangeOrganization = async(org) => {
        return this.setState({ isOpen: true, selected: { identityRole: this.props.IdentityRolesContext, org: { organisation_id: org.value, organisation_name: org.label, abbreviation: org.abbreviation, logo: org.logo, i18n: org.i18n } } });
    }

    changeOrg = (identityRole, organization) => {
        this.setState({ isOpen: true, selected: { identityRole: identityRole, org: organization } })
    }

    componentDidMount(){
        this.props.AuthContext.getUserIdentityRole(true) // get user IdentityRoles from API so they are refreshed in case of role changes
            .then((identityRoles) => {
                this.setState(() => ({ identityRoles: identityRoles.filter((ir) => {
                    if(ir.active === undefined || ir.active === '0' || ir.role.active === undefined || ir.role.active === '0'){
                        return false;
                    }else if(!ir.start_date && !ir.end_date){
                        // both fields are empty strings/null/undefined
                        return true;
                    }else if(!!ir.start_date && !!ir.end_date && moment().isBetween(moment(ir.start_date), moment(ir.end_date), undefined, '[]')){
                        // both dates are valid and now is between them
                        return true;
                    }
                    const start = ir.start_date ? moment(ir.start_date).isBefore(moment()) : true;
                    const end = ir.end_date ? moment(ir.end_date).isAfter(moment()) : true;

                    // we want to return false if only one of them is false
                    // start can be false only if moment.now() is before, so the start date is in the future
                    // end can be false only if moment.now() is after, so the end date is in the past
                    // any other cases where they both are ''/null/undefined or valid with moment.now() is a valid case and we return true
                    return start && end;

                }) }), () => {
                    const recentOrgs = this.props.AppContext.getRecentOrganizations()?.filter(async(recent) => {
                        if(!this.state.identityRoles.some((ir) => ir.identity_role_id === recent.identity_role.identity_role_id)){
                            await this.props.AppContext.removeRecentOrg(recent.id);
                            return false
                        }
                        return true
                    })
                    this.setState(() => ({ recentOrgs: recentOrgs }))
                })
            })
            .then(() => {
                if(this.props.location.state?.selected){
                    this.setState({ isOpen: true, selected: this.props.location.state?.selected });
                }
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.location.state?.selected !== this.props.location.state?.selected && this.props.location.state?.selected){
            this.setState({ isOpen: true, selected: this.props.location.state?.selected });
        }
    }

    componentWillUnmount(){
        AxiosCancelAll();
    }

    render(){
        return (
            <>
                {/*--------------------------------------------------------------------------------*/}
                {/* View Header                                                                  */}
                {/*--------------------------------------------------------------------------------*/}
                <ViewHeaderV2 title='organization.changeOrganization.header.title' />

                <IdentityRolesContext.Consumer>
                    {(identityRoleContext) => (
                        <div className="page-content container-fluid px-4 pt-0">
                            <ChangeOrgModal
                                setRecentOrg={() => {
                                    this.setState({ recentOrgs: this.props.AppContext.getRecentOrganizations() })
                                }}
                                onConfirm={(orgId) => {
                                    this.setState({ orgSearchValues: [ orgId ] })
                                }}
                                isOpen={this.state.isOpen}
                                selected={this.state.selected}
                                toggle={() => this.setState({ isOpen: false })}
                                fromChangeOrgPage
                            />

                            <Container className="ml-0">
                                <div className="mb-1">
                                    <Collapse isOpen={Array.isArray(this.state.identityRoles)}>
                                        <Row form>
                                            {this.state.identityRoles?.map((identityRole) => (
                                                <Col
                                                    md="6"
                                                    xl="4"
                                                    className="mb-3"
                                                    key={identityRole.identity_role_id}
                                                >
                                                    <UserDisplay
                                                        card hover block
                                                        className={stringBuilder("w-100 min-w-0 mb-0 h-100", { 'shadow-primary border-primary': identityRole.identity_role_id === identityRoleContext.identity_role_id })}
                                                        onClick={() => { this.changeOrg(identityRole, identityRole.organisation) }}
                                                    >
                                                        <UserDisplay.Container className="mr-3">
                                                            <UserImg
                                                                abbr={identityRole.organisation.abbreviation}
                                                                src={identityRole.organisation.logo?.full_path}
                                                                filePos={identityRole.organisation.logo?.file_position}
                                                                width="60"
                                                                alt={identityRole.organisation.organisation_name + ' logo'}
                                                            />
                                                        </UserDisplay.Container>
                                                        <UserDisplay.Container>
                                                            <UserDisplay.Title className="h5 mb-0">{identityRole.role.title}</UserDisplay.Title>
                                                            <UserDisplay.Subtitle><DisplayI18n field='name' defaultValue={identityRole.organisation.organisation_name} i18n={identityRole.organisation.i18n} /></UserDisplay.Subtitle>
                                                            <div className="text-link"><Translate id='organization.changeOrganization.selectRole' /></div>
                                                        </UserDisplay.Container>
                                                        {identityRole.additional_role &&
                                                            <div className="position-absolute" style={{ top: "5px", right: "8px" }}>
                                                                <RoleCardAdditionalRoles identityRole={identityRole} />
                                                            </div>
                                                        }
                                                    </UserDisplay>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Collapse>
                                </div>
                                <Card className='card-shadow' body>
                                    <div className='h4 mb-3 font-bold pb-1 border-bottom card-title'>
                                        <Translate id="organization.changeOrganization.title" />
                                        <span className="mx-1">-</span>
                                        <span className="h5 font-medium small text-muted">
                                            <DisplayI18n field='name' defaultValue={identityRoleContext.organisation.organisation_name} i18n={identityRoleContext.organisation.i18n} />
                                            <span className="ml-1">
                                                {identityRoleContext.role.title}
                                            </span>
                                        </span>
                                    </div>

                                    <div>
                                        <OrganizationSearch
                                            id='changeOrg'
                                            key={identityRoleContext.identity_role_id}
                                            fromIdentityRole
                                            withTree
                                            fromApi
                                            treeOrganizationClick={this.orgSearchTreeClickOrganization}
                                            onOptionSelected={(orgs, spordleSelect) => {
                                                this.orgSearchTriggerChangeOrganization(spordleSelect.getSpordleTable().getData().find((org) => org.value === orgs[0]));
                                                return true;
                                            }}
                                            values={this.state.orgSearchValues}
                                            onlyActive
                                        />
                                    </div>

                                    {(this.state.recentOrgs && this.state.recentOrgs?.length > 0) &&
                                        <>
                                            <div className='mt-5 pl-3 h5 font-medium'><Translate id='organization.changeOrganization.recent' /><i className="mdi mdi-history ml-1" /></div>
                                            <ListGroup flush>
                                                {this.state.recentOrgs.map((recent) => {
                                                    if(recent.id !== `${this.props.IdentityRolesContext.identity_role_id}${this.props.OrganizationContext.organisation_id}`){
                                                        return (
                                                            <ListGroupItem
                                                                key={recent.id}
                                                                action
                                                                onClick={() => {
                                                                    this.changeOrg(recent.identity_role, recent.organisation)
                                                                }}
                                                            >
                                                                <Row className='align-items-center'>
                                                                    <Col md='6'>
                                                                        <UserDisplay>
                                                                            <UserDisplay.Container>
                                                                                <UserImg
                                                                                    abbr={recent.organisation.abbreviation}
                                                                                    src={recent.organisation.logo?.full_path}
                                                                                    filePos={recent.organisation.logo?.file_position}
                                                                                    alt={recent.organisation.organisation_name}
                                                                                    width="60"
                                                                                />
                                                                                {recent.organisation.organisation_id !== recent.identity_role.organisation.organisation_id &&
                                                                                    <UserImg
                                                                                        className="position-absolute bottom-0 left-0 ml-2"
                                                                                        abbr={recent.identity_role.organisation.abbreviation}
                                                                                        src={recent.identity_role.organisation.logo?.full_path}
                                                                                        filePos={recent.identity_role.organisation.logo?.file_position}
                                                                                        alt={recent.identity_role.organisation.organisation_name}
                                                                                        width="30"
                                                                                    />
                                                                                }
                                                                            </UserDisplay.Container>
                                                                            <UserDisplay.Container>
                                                                                <UserDisplay.Title><DisplayI18n field='name' defaultValue={recent.organisation.organisation_name} i18n={recent.organisation.i18n} /></UserDisplay.Title>
                                                                                <UserDisplay.Subtitle>
                                                                                    <DisplayI18n field="name" defaultValue={recent.identity_role.organisation.organisation_name} i18n={recent.identity_role.organisation.i18n} />
                                                                                    <span className="ml-1">{recent.identity_role.role.title}</span>
                                                                                </UserDisplay.Subtitle>
                                                                            </UserDisplay.Container>
                                                                        </UserDisplay>
                                                                    </Col>
                                                                    <Col md='6'>
                                                                        <div className="text-muted small"><Translate id='organization.changeOrganization.lastManaged' /></div>
                                                                        <div className="font-medium"><DateFormat value={recent.date} format={DateFormat.formats.shortMonthDayYear} /></div>
                                                                    </Col>
                                                                </Row>
                                                            </ListGroupItem>
                                                        )
                                                    }
                                                })}
                                            </ListGroup>
                                        </>
                                    }
                                </Card>
                            </Container>
                        </div>
                    )}
                </IdentityRolesContext.Consumer>
            </>
        );
    }
}

export default withContexts(OrganizationContext, AuthContext, IdentityRolesContext, RolesContext, AppContext)(ChangeOrganization);