import {
    CardBody,
    Spinner
} from 'reactstrap';
import TabRoutesPanes from "../../../components/subSidebar/TabRoutesPanes";
import tasksManageRequestsTabsRoutes from "../../../routes/tabRoutes/tasksManageRequestsTabsRoutes";
import ViewHeaderV2 from "../../../components/viewHeader/ViewHeaderV2";
import Translate from '@spordle/intl-elements';
import { stringBuilder } from '@spordle/helpers';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useContext, useEffect, useState } from 'react';
import { history } from '../../../helpers';
import { MembersContext } from '../../../contexts/MembersContext';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import { counterAnim } from '../../../helpers/uiHelper';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { RolesContext } from '../../../contexts/RolesContext';
import { getMemberClassificationRequestStatus, getPendingClassificationRequestChanges } from '../../../api/client/classificationRequestChanges';
import { getPendingMemberRequestChanges } from '../../../api/client/memberRequestChanges';
import { AppContext, PeriodsContext } from '../../../contexts/contexts';
import { getPermanentRelease } from '../../../api/client/permanentReleaseRequests';
import { getMemberTypeApprobations } from '../../../api/client/memberTypeValidation';
import { getMemberTypeRemovalRequests } from '../../../api/client/memberTypeRemoval';

const ManageRequests = () => {
    const squareSize = 180;// 232 - 4 cards
    const isLoading = false;

    const organizationContext = useContext(OrganizationContext);
    const membersContext = useContext(MembersContext);
    const rolesContext = useContext(RolesContext);
    const periodsContext = useContext(PeriodsContext)

    const { getFrontEndParams, updateParamsManageRequestTabs } = useContext(AppContext);


    const [ currentTabs, setCurrentTabs ] = useState(tasksManageRequestsTabsRoutes);


    /**
   * **********************************************************************************************************************
   * Header Cards get functions
   * **********************************************************************************************************************
   */
    const [ stats, setStats ] = useState({});
    const getMemberChangeRequests = () => (
        getPendingMemberRequestChanges()
            .then((member_request_changes) => {
                counterAnim('/member-change-requests', stats["member-change-requests"] || 0, member_request_changes.length)
                setStats({ "member-change-requests": member_request_changes.length })
                return member_request_changes;
            })
            .catch((error) => {
                if(AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    // return true to let the table know that the call is cancelled and not put hte table in an error state
                    return true;
                }
            })
    );

    const [ changeRequestStatuses, setChangeRequestStatuses ] = useState([])

    const getChangeRequestStatuses = () => {
        if(changeRequestStatuses.length === 0)
            return getMemberClassificationRequestStatus()
                .then((statuses) => {
                    setChangeRequestStatuses(statuses);
                    return statuses;
                }).catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                    }
                })
        return Promise.resolve(changeRequestStatuses)
    }


    const getClassificationRequestChanges = () => {
        return getChangeRequestStatuses().then((statuses) => (
            getPendingClassificationRequestChanges({ request_change_status_id: statuses.find((status) => status.system === 'PENDING').request_change_status_id })
                .then((classification_request_changes) => {
                    counterAnim('/classification-change-requests', stats["classification-change-requests"] || 0, classification_request_changes.length);
                    setStats({ "classification-change-requests": classification_request_changes.length });
                    return classification_request_changes;
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message);
                        // return true to let the table know that the call is cancelled and not put hte table in an error state
                    }
                    return true;
                })
        ))
    };
    const getPermanentReleaseRequests = () => {
        return getChangeRequestStatuses().then((statuses) => {
            const pendingStatusId = statuses.find((status) => status.system === 'PENDING').request_change_status_id
            return (getPermanentRelease({
                request_change_status_id: pendingStatusId,
                organisation_id: organizationContext.organisation_id,
                period_id: periodsContext.selectedPeriod.period_id,
                need_approbation: '1',
            })
                .then((permanentReleaseRequests) => {
                    // had to add local filter cause API is broken <3
                    const pendingRequests = permanentReleaseRequests.filter((request) => (request.request_change_status_id === pendingStatusId))

                    counterAnim('/member-permanent-release-requests', stats["member-permanent-release-requests"] || 0, pendingRequests.length);
                    setStats({ "member-permanent-release-requests": pendingRequests.length });
                    return pendingRequests;
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message);
                        // return true to let the table know that the call is cancelled and not put hte table in an error state
                    }
                    return true;
                })
            )
        })

    };

    const getUnconfirmedOrganizations = (filters) => {
        return organizationContext.getOrganizationChildren().then((orgs) => {
            const orgsList = (filters && filters.status && filters.status.length > 0) ? orgs.filter((org) => filters.status.includes(org.organisation_status?.system)) : orgs;
            counterAnim('/unconfirmed-organizations', stats["unconfirmed-organizations"] || 0, orgsList.length);
            setStats({ "unconfirmed-organizations": orgsList.length })
            return orgsList;
        })
    };

    const getMemberDocumentApproval = (filters) => {
        return membersContext.getMemberAttachmentsApprobation({
            organisation_id: organizationContext.organisation_id,
        }).then((approbations) => {
            counterAnim('/member-attachment-approval', stats["member-attachment-approval"] || 0, approbations.length);
            setStats({ "member-attachment-approval": approbations.length })
            return approbations;
        })
    };

    const getCTIRequests = (filters, search) => {
        return organizationContext.getOrganisationTransfers(
            undefined,
            {
                direction: filters.direction,
                request_state: [ "OPEN", "CLOSED" ].includes(filters.status) ? filters.status : "",
                transfer_approval_state: filters.status === "REJECTED" ? filters.status : "",
                transfer_type: filters.type,
                member_transfer_id: search || null,
            },
        ).then((transfers) => {
            const newTransfers = transfers.filter((transfer) => {
                if(filters.onlyShowActionRequired){
                    return (transfer.transfer_request_state === 'OPEN' && transfer.current_workflow_step === organizationContext.organisation_id) ? transfer : null;
                }
                return transfer
            })
            counterAnim('/member-cti-requests', stats["member-cti-requests"] || 0, newTransfers.length);
            setStats({ "member-cti-requests": newTransfers.length })
            return newTransfers;
        })
    }

    const getMemberTypeApproval = (filters) => {
        return getMemberTypeApprobations(filters)
            .then((approbations) => {
                counterAnim('/member-type-approval', stats["member-type-approval"] || 0, approbations.length);
                setStats({ "member-type-approval": approbations.length })
                return approbations;
            })
    }

    const getMemberTypeRemoval = (filters) => {
        return getMemberTypeRemovalRequests(organizationContext.organisation_id, filters)
            .then((removals) => {
                counterAnim('/member-type-removal', stats["member-type-removal"] || 0, removals.length);
                setStats({ "member-type-removal": removals.length })
                return removals;
            })
    }


    /**
   * **********************************************************************************************************************
   * Header Cards Drag and Drop functions
   * **********************************************************************************************************************
   */
    useEffect(() => {
        // Get Tabs from localstorage
        const localTabs = getFrontEndParams().manageRequestTabs;
        if(localTabs){
            // If tabs have been set, use them to sort our real tabs
            // This is done to show new tabs or prevent using a old tab (tasksManageRequestsTabsRoutes is source of truth)
            const myTabs = tasksManageRequestsTabsRoutes.sort((tabA, tabB) => {
                const indexA = localTabs.findIndex((lTab) => lTab.path == tabA.path)
                const indexB = localTabs.findIndex((lTab) => lTab.path == tabB.path)
                return indexA - indexB
            })
            // Set tabs after sorting
            setCurrentTabs(myTabs)
        }

        // redirect from main route to the first request management tab
        if(window.location.pathname === '/tasks/manage-requests' && localTabs?.[0]){
            history.push('/tasks/manage-requests' + localTabs[0].path)
        }else if(window.location.pathname === '/tasks/manage-requests' && tasksManageRequestsTabsRoutes?.[0]){
            history.push('/tasks/manage-requests' + tasksManageRequestsTabsRoutes[0].path)
        }
    }, [])

    const onDragEnd = (result) => {
        // dropped outside the list
        if(!result.destination){
            return;
        }

        const items = reorder(
            currentTabs,
            result.source.index,
            result.destination.index,
        );

        setCurrentTabs(items)
        updateParamsManageRequestTabs(items);
    }

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [ removed ] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result && result.length > 0 ? result : tasksManageRequestsTabsRoutes;
    };

    // remove certain routes depending on org settings
    if(organizationContext.settings.enable_permanent_release?.value == 0)
        currentTabs
    return (
        <>
            {/* Settings View Header */}
            <ViewHeaderV2 title='task.manageRequests.title' />

            <div className='px-4'>
                <div className='bg-light rounded-lg shadow-inset border mb-3'>
                    {/* <ScrollArea> */}
                    <div className='p-3 overflow-auto pretty-scrollbar'>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable" direction="horizontal">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        className={"d-flex"}
                                        {...provided.droppableProps}
                                    >
                                        {currentTabs.filter((route) => !route.redirect).map((route, index) => {
                                            const path = '/tasks/manage-requests' + route.path;
                                            const currentPath = window.location.pathname === '/tasks/manage-requests' + route.path;
                                            const showFor = rolesContext.validateRouteAccess(route);
                                            if(!showFor || route.redirect || ((route.devOnly && process.env.REACT_APP_ENVIRONMENT === 'prod')))
                                                return null;
                                            return (
                                                <Draggable key={route.path} draggableId={route.path} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            key={path}
                                                            ref={provided.innerRef}
                                                            className="mx-1"
                                                            {...provided.draggableProps}
                                                        >
                                                            <div
                                                                className={stringBuilder("card card-shadow card-hover text-center w-100 h-100 mb-0 ", { 'border-primary shadow-primary': currentPath })}
                                                                style={{ minWidth: squareSize, maxWidth: squareSize }}
                                                            >
                                                                <CardBody className={stringBuilder({ "bg-light": route.disabled })}>
                                                                    <Link to={!route.disabled ? path : '#'}>
                                                                        {/* Value is generated in JS */}
                                                                        <Spinner className={!isLoading ? 'd-none' : 'd-inline-block'} style={{ height: '26px', width: '26px' }} color='primary' type='grow' />
                                                                        <div id={route.path} className={`h2 mb-0 font-medium ${isLoading ? 'd-none' : 'd-inline'}`}>{0}</div>
                                                                        <div className={stringBuilder({ "text-muted": route.disabled })}><Translate id={route.name} /></div>
                                                                    </Link>
                                                                    <div
                                                                        className='position-absolute p-2'
                                                                        style={{ top: 5, right: 5 }}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <div className='p-2' />
                                                                        <i className='font-20 mdi mdi-drag position-absolute' style={{ top: 0, right: 0 }} />
                                                                    </div>
                                                                </CardBody>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    {/* </ScrollArea> */}
                </div>

                <div>
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Tabs                                                                  */}
                    {/*--------------------------------------------------------------------------------*/}
                    {/* <TabRoutesNavs routes={currentTabs.filter((t) => t.component && !t.disabled && !t.redirect)} /> */}
                    <TabRoutesPanes
                        routes={currentTabs.filter((t) => t.component && !t.disabled && !t.redirect)}
                        getComponentProps={(route) => {
                            switch (route.path){
                                case '/member-change-requests':
                                    return { getData: getMemberChangeRequests };
                                case '/classification-change-requests':
                                    return { getData: getClassificationRequestChanges, changeRequestStatuses: changeRequestStatuses };
                                case '/member-permanent-release-requests':
                                    return { getData: getPermanentReleaseRequests, changeRequestStatuses: changeRequestStatuses };
                                case '/unconfirmed-organizations':
                                    return { getData: getUnconfirmedOrganizations };
                                case '/member-attachment-approval':
                                    return { getData: (data) => getMemberDocumentApproval(data) };
                                case '/member-cti-requests':
                                    return { getData: (data) => getCTIRequests(data) };
                                case '/member-type-approval':
                                    return { getData: (data) => getMemberTypeApproval(data) };
                                case '/member-type-removal':
                                    return { getData: (data) => getMemberTypeRemoval(data) };
                                default:
                                    return { getData: () => null }
                            }
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default ManageRequests;