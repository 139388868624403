export default{
    // Review
    "clinics.clinicCommunications.formClinicCommunications.review.title"         : "Edit communications information",
    "clinics.clinicCommunications.formClinicCommunications.review.saveChanges"   : "Save changes",
    "clinics.clinicCommunications.formClinicCommunications.review.cancelChanges" : "Cancel changes",

    // registration
    "clinics.clinicCommunications.formClinicCommunications.registration.title"                  : "Registration confirmation",
    "clinics.clinicCommunications.formClinicCommunications.registration.message"                : "Message",
    "clinics.clinicCommunications.formClinicCommunications.registration.message.required"       : "Message is required",
    "clinics.clinicCommunications.formClinicCommunications.registration.attachments"            : "Attachment(s)",
    "clinics.clinicCommunications.formClinicCommunications.registration.attachments.comingSoon" : "Module in development",

    // confirmation
    "clinics.clinicCommunications.formClinicCommunications.confirmation.title"            : "Confirmation email",
    "clinics.clinicCommunications.formClinicCommunications.confirmation.message"          : "Message",
    "clinics.clinicCommunications.formClinicCommunications.confirmation.message.required" : "Message is required",
    "clinics.clinicCommunications.formClinicCommunications.confirmation.attachments"      : "Attachment(s)",

    // forms
    "clinics.clinicCommunications.formClinicCommunications.custom_forms.title"   : "Questionnaires",
    "clinics.clinicCommunications.formClinicCommunications.custom_forms.message" : "Link a questionnaire to your clinic",
    "clinics.clinicCommunications.formClinicCommunications.terms.title"          : "Terms and Conditions",
    "clinics.clinicCommunications.formClinicCommunications.terms.required"       : "Please select a term and condition",
    "clinics.clinicCommunications.formClinicCommunications.waivers.title"        : "Waivers",
    "clinics.clinicCommunications.formClinicCommunications.waivers.textWhen"     : "{count} waivers selected",
}