import React, { useContext } from 'react';
import { IdentityRolesContext } from '../../contexts/IdentityRolesContext';
import { RolesContext } from '../../contexts/RolesContext';
import IsGod from './IsGod';

/**
 * JSX implementation of RolesContext.hasAccessTo
 * @param {object} props
 * @param {React.ReactNode|Function} props.children
 * @param {string} props.componentCode
 * @param {string} props.componentPermissionCode
 * @returns
 */
const HasAccessTo = ({ componentCode, componentPermissionCode, children, tag: Tag, ...props }) => {
    const roleContext = useContext(RolesContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    if(roleContext.hasAccessTo(componentCode, componentPermissionCode)){
        return (
            <Tag {...props}>
                {typeof children === 'function' ? children(false) : children}
            </Tag>
        )
    }else if(identityRolesContext.isGod()){ // Has access
        return (
            <IsGod {...props} tag={Tag} componentCode={componentCode} componentPermissionCode={componentPermissionCode}>
                {typeof children === 'function' ? children(true) : React.Children.map(children, (child) => {
                    if(React.isValidElement(child)){
                        return React.cloneElement(child, {
                            godAccess: true,
                        });
                    }
                    return child;
                })}
            </IsGod>
        )
    }// Don't show
    return null

}

HasAccessTo.defaultProps = {
    tag: 'span',
}

export default HasAccessTo;