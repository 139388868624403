import React from "react";
import {
    Container
} from 'reactstrap';
import { AppContext } from '../../../contexts/contexts';
import withContexts from "../../../helpers/withContexts";
import ViewHeaderV2 from "../../../components/viewHeader/ViewHeaderV2";
import SubRouteRenderer from "../../../components/subSidebar/SubRouteRenderer";
import settingsTeamsRoutes from "../../../routes/tabRoutes/settingsTeamsTabsRoutes";

class TeamSettings extends React.Component{
    render(){
        return (
            <>
                {/* Settings View Header */}
                <ViewHeaderV2 title='sidebar.settings.teams' />

                <div className="px-4">
                    <Container>
                        <SubRouteRenderer routes={settingsTeamsRoutes} />
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Tabs                                                                           */}
                        {/*--------------------------------------------------------------------------------*/}
                        {/* <TabRoutesNavs routes={settingsTeamsTabsRoutes} />
                        <TabRoutesPanes routes={settingsTeamsTabsRoutes} /> */}
                    </Container>
                </div>
            </>
        );
    }
}

export default withContexts(AppContext)(TeamSettings);