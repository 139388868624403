import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { object, mixed, string } from 'yup';
import { useContext } from 'react';
import { Button, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AnalyticsModal from '../../analytics/AnalyticsModal';
import OverlayLoader from '../loading/OverlayLoader';
import moment from 'moment';
import { FormikDateTime, FormikTextArea } from '@spordle/formik-elements';
import CustomAlert from '../CustomAlert';
import { success, fail } from '@spordle/toasts';

// contexts
import { MembersContext } from '../../contexts/MembersContext';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import Required from '../formik/Required';
import { DisplayI18n } from '../../helpers/i18nHelper';

const EditPaymentReceptionModal = ({ isOpen, toggle, payment, invoiceNumber, paymentReceptionId, onEdit }) => {
    const membersContext = useContext(MembersContext);

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='editPaymentReceptionModal' size='sm'>
            <ModalHeader toggle={toggle}>
                <Translate id='components.transaction.editPaymentReceptionModal.header' />
            </ModalHeader>

            <Formik
                initialValues={{
                    received_at: moment(payment?.received_at, 'YYYY-MM-DD'),
                    note: payment?.note || '',
                }}
                validationSchema={object().shape({
                    received_at: mixed().required(<Translate id='components.transaction.editPaymentReceptionModal.date.required' />).test({
                        name: 'receivedDateValidTest',
                        message: <Translate id='form.validation.date.format' />,
                        test: moment.isMoment,
                    }),
                    note: string(),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    const newData = { received_at: moment(values.received_at).format('YYYY-MM-DD'), note: values.note }

                    membersContext.updatePaymentReception(invoiceNumber, paymentReceptionId, newData)
                        .then(() => {
                            onEdit(newData);
                            setSubmitting(false);
                            toggle();
                            success();
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                                if(error.i18n){
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                }else{
                                    setStatus(error.message)
                                    switch (error.message){
                                        case '3191':
                                            setStatus(<Translate id='contexts.membersContext.3191' />)
                                            break;
                                        case '3060':
                                            setStatus(<Translate id='contexts.membersContext.3060' />)
                                            break;
                                        case '3179':
                                            setStatus(<Translate id='contexts.membersContext.3179' />)
                                            break;
                                        default:
                                            setStatus(<Translate id='misc.error' />)
                                            break;
                                    }
                                }
                                setSubmitting(false);
                            }
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalBody>
                                <FormGroup>
                                    <Label for='received_at' className='text-muted'><Translate id='components.transaction.editPaymentReceptionModal.date.label' /> <Required /></Label>
                                    <FormikDateTime
                                        id='received_at' name='received_at'
                                        timeFormat={false}
                                        isValidDate={(current) => moment(current).isAfter(moment())}
                                        initialViewDate={moment()} //payment?.due_date
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for='note' className='text-muted'><Translate id='components.transaction.editPaymentReceptionModal.note.label' /> <Required /></Label>
                                    <FormikTextArea id='note' name='note' rows='3' />
                                </FormGroup>
                                <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                    {formik.status &&
                                        <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                    }
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button type='submit' color='primary'><Translate id='misc.confirm' /></Button>
                                <Button type='button' color='primary' outline onClick={() => toggle()}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    )
}

export default EditPaymentReceptionModal