export default{
    "organization.settings.organizationTeamCategoryTypes.tab.title" : "Team Category Types",

    "organization.settings.organizationTeamCategoryTypes.table.active" : "Active",
    "organization.settings.organizationTeamCategoryTypes.table.empty"  : "No Team Category Type",

    // add
    "organization.settings.organizationTeamCategoryTypes.add.title"           : "Add Team Category Type",
    "organization.settings.organizationTeamCategoryTypes.add.status"          : "Status",
    "organization.settings.organizationTeamCategoryTypes.add.status.active"   : "Active",
    "organization.settings.organizationTeamCategoryTypes.add.status.inactive" : "Inactive",
    "organization.settings.organizationTeamCategoryTypes.add.status.required" : "Status is required",

    // sidepanel
    "organization.settings.sidePanelOrganizationTeamCategoryTypes.title" : "Team Category Type Information",

    "organization.settings.sidePanelOrganizationTeamCategoryTypes.status"          : "Status",
    "organization.settings.sidePanelOrganizationTeamCategoryTypes.status.active"   : "Active",
    "organization.settings.sidePanelOrganizationTeamCategoryTypes.status.inactive" : "Inactive",
    "organization.settings.sidePanelOrganizationTeamCategoryTypes.status.required" : "Status is required",

    "organization.settings.sidePanelOrganizationTeamCategoryTypes.update.success.text" : "Category Type updated",
    "organization.settings.sidePanelOrganizationTeamCategoryTypes.update.fail.text"    : "An error occurred during the team category Type update, please refresh the page et try again",

    // actions
    "organization.settings.sidePanelOrganizationTeamCategoryTypes.actions.remove"         : "Remove",
    "organization.settings.sidePanelOrganizationTeamCategoryTypes.actions.remove.topText" : "You are about to remove this team category type from your organization",

    // tabs
    "organization.settings.sidePanelOrganizationTeamCategoryTypes.tabs.details" : "Details",
}