export default{
    "news.title"                         : "Nouvelles",
    "news.delete.modal.msg"              : "Vous êtes sur le point de supprimer cette nouvelle :",
    "news.delete.toast.success.title"    : "Nouvelle supprimée !",
    "news.delete.toast.success.subtitle" : "La nouvelle a été supprimée avec succès.",

    "news.table.sortBy"          : "Trier par",
    "news.table.sortBy.asc"      : "Basculer en ordre croissant",
    "news.table.sortBy.desc"     : "Basculer en ordre décroissant",
    "news.table.filter.liveOnly" : "Nouvelles visibles seulement",
    "news.table.status.live"     : "Visible",

    "news.nav.manage" : "Gérer les nouvelles",
    "news.nav.view"   : "Voir les nouvelles",

    "news.form.create.success.msg"        : "La nouvelle a été créée avec succès !",
    "news.form.create.confirm.msg"        : "Vous êtes sur le point de publier cette nouvelle à <b>{count, plural, =0 {cette organisation et tous ses enfants} one {# organisation} other {# organisations}}</b>.",
    "news.form.create.confirm.date.from"  : "Cette nouvelle sera visible à partir de <b>{date}</b> à <b>{time}</b>",
    "news.form.create.confirm.date.until" : "jusqu'au <b>{date}</b> à <b>{time}</b>.",
    "news.form.create.confirm.notActive"  : "Cette nouvelle n'est pas active et ne sera pas visible.",
    "news.form.create.confirm.visible"    : "Cette nouvelle sera visible lorsqu'elle sera publiée.",

    "news.form.title.create"                   : "Ajout d'une nouvelle",
    "news.form.title.edit"                     : "Éditer la nouvelle",
    "news.form.title.confirm"                  : "Confirmation de la publication",
    "news.form.title.confirm.edit"             : "Confirmation des modifications",
    "news.form.title.content"                  : "Contenu",
    "news.form.field.startDate"                : "Date de début",
    "news.form.field.endDate"                  : "Date de fin",
    "news.form.field.from"                     : "De",
    "news.form.field.to"                       : "À",
    "news.form.field.to.placeholder"           : "Toutes les organisations",
    "news.form.field.to.helper"                : "Si aucune organisation n'est sélectionnée, la nouvelle sera affichée pour toutes les organisations enfants.",
    "news.form.field.active"                   : "Nouvelle est active",
    "news.form.field.active.helper"            : "Les nouvelles inactives ne seront pas affichées.",
    "news.form.field.endDate.isAfterStart"     : "La date de fin devrait être après la date de début",
    "news.form.field.banner.required"          : "Veuillez sélectionner une bannière pour votre nouvelle",
    "news.form.field.startDate.required"       : "La date de début est requise",
    "news.form.field.type"                     : "Type",
    "news.form.field.type.critical"            : "Critique",
    "news.form.field.type.warning"             : "Avertissement",
    "news.form.field.type.information"         : "Information",
    "news.form.field.type.required"            : "Le type de nouvelle est requis.",
    "news.form.field.banner"                   : "Bannière",
    "news.form.field.banner.none"              : "Aucune bannière",
    "news.form.field.banner.none.msg"          : "Sélectionner la bannière",
    "news.form.field.banner.modal.title"       : "Sélectionner la bannière",
    "news.form.field.org.required"             : "Veuillez sélectionner au moins une organisation.",
    "news.form.field.banner.modal.preview.msg" : "Sélectionnez une image pour en voir son aperçu.",
    "news.form.helper"                         : "Pour en savoir plus sur le système de nouvelles, vous pouvez lire la <a>documentation ici</a>.",

    "news.form.btn.preview"     : "Aperçu",
    "news.form.btn.preview.tip" : "Voir un aperçu",
    "news.form.btn.back"        : "Retour à la liste des nouvelles",
}