import Translate from "@spordle/intl-elements";
import { Component } from "react";
import {
    Collapse,
    ListGroup,
    ListGroupItem
} from "reactstrap";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import IsAdmin from "../../../../components/permissions/IsAdmin";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { RolesContext } from "../../../../contexts/RolesContext";
import withContexts from "../../../../helpers/withContexts";
import { injectIntl } from 'react-intl';
import { fail } from "@spordle/toasts";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import Skeleton from "react-loading-skeleton";
import { stringBuilder } from "@spordle/helpers";

class SidePanelOrganizationRolesPermission extends Component{
    static contextType = RolesContext;

    state = {
        isOpen: true,
        permissions: 'loading',
        searchQuery: '',
    }

    /**
     * Open or close the Collapse
     */
    toggleCollapse = () => {
        this.setState((prevState) => ({ isOpen: !prevState.isOpen }))
    }

    /**
     * This function counts the total number of each action and sort them DESC
     * @param {Array.<object>} permissions The component_permissions of the component
     * @returns {number}
     */
    getPermissionCount = (permissions) => {
        let countObject = 0;
        for(let index = 0; index < permissions.length; index++){
            const permission = permissions[index];
            if(permission.visible_in_menu !== '1'){ // Count only if it is not a module component
                if(permission.role_component_permissions.some((action) => action.action === 'READ' && action.active === '1'))
                    countObject++;
            }
        }
        return countObject;
    }

    /**
     * Function that will fetch data from the server
     */
    fetchRolesPermissions = (resetCurrentRole = false) => {
        this.setState(() => ({ permissions: 'loading' }))
        this.context.getRolesPermissions(this.props.roleIds[0])
            .then((role) => {
                if(resetCurrentRole && role.role_id === this.context.role_id){ // Update the ui on edit with the new role
                    this.context.setCurrentRole(role);
                }
                this.canEdit = role.is_system != '1' && role.organisation_id === this.props.OrganizationContext.organisation_id;
                this.setState(() => ({ permissions: role }));
                if(this.props.setRole)
                    this.props.setRole(role);
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({ permissions: 'error' }))
                }
            })
    }

    componentDidMount(){
        if(this.props.roleIds.length < 2){
            this.fetchRolesPermissions();
        }else{
            // Multiple roles selected
            this.setState(() => ({ permissions: { components: [] } }))// Not loading
        }
    }

    render(){
        return (
            <div className="border-bottom">
                <div className="p-3 d-flex clickable" onClick={this.toggleCollapse}>
                    <div className="align-self-center">
                        <div className="h4 font-bold mb-0"><Translate id='organization.profile.roles.sidePanelOrganizationRoles.componentPermission.title' /></div>
                    </div>
                    <i className={`ml-auto font-22 mdi ${this.state.isOpen ? 'mdi-chevron-down' : 'mdi-chevron-up'}`} />
                </div>

                <Collapse isOpen={this.state.isOpen}>
                    <div className="search-wrapper mx-4 mb-4 mt-0">
                        <input
                            className="form-control" type="search" placeholder={this.props.intl.formatMessage({ id: 'misc.search' })}
                            value={this.state.searchQuery} onChange={(query) => {
                                this.setState(() => ({ searchQuery: query.target.value }));
                            }}
                        />
                        <button type='button' className={stringBuilder("reset-btn text-link search-clear", { "d-none": !this.state.searchQuery })} onClick={() => this.setState(() => ({ searchQuery: '' }))}><i className="mdi mdi-close" /></button>
                    </div>
                    <div className="px-3 pb-3">
                        <ListGroup flush className="border-top">
                            {
                                this.state.permissions === 'loading' ?
                                    Array.from({ length: 6 }, (_, i) => (
                                        // eslint-disable-next-line space-infix-ops
                                        <ListGroupItem key={'skeleton'+i}>
                                            <Skeleton width={130} height={20} />
                                            <Skeleton containerClassName="line-height-1" className="mb-0" width={90} height={13} />
                                            <Skeleton width={200} height={18} />
                                        </ListGroupItem>
                                    ))
                                    : this.state.permissions === 'error' ?
                                        <div className='d-flex justify-content-center p-3'>
                                            <Translate id='misc.error' />
                                        </div>
                                        :
                                        this.state.permissions.components.filter((component) => {
                                            const lowerCasedSearch = this.state.searchQuery.toLowerCase();
                                            return component.code.indexOf(lowerCasedSearch) !== -1
                                                || component.component_permissions.some((componentPermission) => componentPermission.code.indexOf(lowerCasedSearch) !== -1);
                                        }).map((permission) => {
                                            // Finds if the component activation is active
                                            /** @type {boolean} */
                                            const isActive = permission.component_permissions.findIndex((cp) => cp.visible_in_menu === '1' && cp.role_component_permissions.findIndex((rcp) => rcp.action === 'READ' && rcp.active === '1') !== -1) !== -1;
                                            const permissionCount = isActive ? this.getPermissionCount(permission.component_permissions) : 0;

                                            return (
                                                <IdentityRolesContext.Consumer key={permission.component_id}>
                                                    {({ isAdmin }) => (
                                                        <ListGroupItem
                                                            className={(isAdmin() || this.canEdit) ? "clickable" : undefined}
                                                            onClick={(isAdmin() || this.canEdit) ? () => { this.props.openPermissionsModal(permission, this.state.searchQuery) } : undefined}
                                                        >
                                                            <div className="d-flex">
                                                                <div className="h5 mb-0 font-medium"><Translate id={`sidebar.${permission.code.toLowerCase()}`} /></div>
                                                                {(isAdmin() || this.canEdit) &&
                                                                    <div className="ml-auto small">
                                                                        <span className="align-middle"><i className={`mdi mdi-pencil ${isAdmin() ? 'text-purple' : 'text-primary'} ml-2 font-14`} /></span>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <IsAdmin className='small text-monospace'>
                                                                code: {permission.code}
                                                            </IsAdmin>
                                                            <div className="d-flex flex-wrap">
                                                                {isActive ?
                                                                    permissionCount === 0 ?
                                                                        <Translate id='organization.profile.roles.sidePanelOrganizationRoles.componentPermission.enabled' />
                                                                        : permissionCount === 1 ?
                                                                            <Translate id='organization.profile.roles.sidePanelOrganizationRoles.componentPermission.single' />
                                                                            :
                                                                            <Translate id='organization.profile.roles.sidePanelOrganizationRoles.componentPermission.many' values={{ count: permissionCount }} />
                                                                    :
                                                                    // Not activated
                                                                    <div>
                                                                        <i className='text-danger mdi mdi-alert-outline mr-1' />
                                                                        <Translate id='organization.profile.roles.sidePanelOrganizationRoles.componentPermission.disabled' />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </ListGroupItem>
                                                    )}
                                                </IdentityRolesContext.Consumer>
                                            );
                                        })}
                            {this.props.roleIds.length > 1 &&
                                <div className='d-flex justify-content-center p-3'>
                                    <Translate id='organization.profile.roles.sidePanelOrganizationRoles.componentPermission.cannotDo' />
                                </div>
                            }
                        </ListGroup>
                    </div>
                </Collapse>
            </div>
        )
    }
}

export default injectIntl(withContexts(OrganizationContext)(SidePanelOrganizationRolesPermission), { forwardRef: true });