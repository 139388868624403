export default{
    "onlineStore.search.title" : "Online Registration",

    "onlineStore.search.btn.create" : "Create a new online store",

    "onlineStore.search.delete.confirmMessage" : "Are you sure you want to delete:",

    "onlineStore.search.status"                : "Status",
    "onlineStore.search.status.UNDER_CREATION" : "Under Creation",
    "onlineStore.search.status.ACTIVE"         : "Active",
    "onlineStore.search.status.DRAFT"          : "Draft",
    "onlineStore.search.status.CLOSE"          : "Closed",

    // Table
    "onlineStore.search.table.column.title"        : "Name",
    "onlineStore.search.table.column.organization" : "Organization",
    "onlineStore.search.table.column.startDate"    : "Start Date",
    "onlineStore.search.table.column.endDate"      : "End Date",

    "onlineStore.search.table.emptyLayout.noResult"        : "No results...",
    "onlineStore.search.table.emptyLayout.noResultMessage" : "You can create new online registration stores at the top of the page.",
    "onlineStore.search.table.paginationMessage"           : "Store {itemStartPos} to {itemEndPos} out of a total of {totalItems} stores",

    // Sidepanel
    "onlineStore.search.sidepanel.tab.general"      : "General",
    "onlineStore.search.sidepanel.tab.registration" : "Registration",

    "onlineStore.search.sidepanel.title.registrations"        : "Registrations",
    "onlineStore.search.sidepanel.title.registrations.sold"   : "Registrations sold",
    "onlineStore.search.sidepanel.title.registrations.listed" : "Registrations listed",
    "onlineStore.search.sidepanel.registrations.number"       : "{num} registrations",
    "onlineStore.search.sidepanel.registrations.none"         : "No registrations",
    "onlineStore.search.sidepanel.title.otherItems"           : "Other Items",
    "onlineStore.search.sidepanel.title.otherItems.sold"      : "Other items sold",
    "onlineStore.search.sidepanel.title.otherItems.listed"    : "Other items listed",
    "onlineStore.search.sidepanel.otherItems.number"          : "{num} other items",
    "onlineStore.search.sidepanel.otherItems.none"            : "No other items",
    "onlineStore.search.sidepanel.items.sold"                 : "Sold",
    "onlineStore.search.sidepanel.items.listed"               : "Listed",

    "onlineStore.search.sidepanel.status.SOLD_OUT"  : "Sold out",
    "onlineStore.search.sidepanel.status.ACTIVE"    : "Active",
    "onlineStore.search.sidepanel.status.SUSPENDED" : "Suspended",

    "onlineStore.search.sidepanel.languages"          : "Languages",
    "onlineStore.search.sidepanel.languages.min"      : "Please select at least one language",
    "onlineStore.search.sidepanel.email"              : "Registrar email address",
    "onlineStore.search.sidepanel.registration.start" : "Registration period (Start)",
    "onlineStore.search.sidepanel.registration.end"   : "Registration period (End)",
    "onlineStore.search.sidepanel.registration.date"  : "Date",
    "onlineStore.search.sidepanel.registration.time"  : "Time",
}