export default{
    "task.renewMembers.title"                  : "Renouveler les membres",
    "task.renewMembers.affiliated.has"         : "Affilié",
    "task.renewMembers.memberType"             : "Type de membre",
    "task.renewMembers.memberType.has"         : "A le type de membre",
    "task.renewMembers.memberType.required"    : "Le type de membre est requis",
    "task.renewMembers.division"               : "Division",
    "task.renewMembers.division.required"      : "La division est requise",
    "task.renewMembers.renew"                  : "Renouveler",
    "task.renewMembers.text"                   : "Veuillez choisir une méthode de paiement pour renouveler les membres suivants",
    "task.renewMembers.paymentMethod"          : "Méthode de paiement",
    "task.renewMembers.paymentMethod.required" : "La méthode de paiement est requise",
    "task.renewMembers.members"                : "{count, plural, one {Membre (#)} other {Membres (#)}}",
    "task.renewMembers.members.desc"           : "{count, plural, one {Ce membre sera renouvelé} other {Ces membres seront renouvelés}}",
    "task.renewMembers.renew.success"          : "Tous les membres ont été renouvelés!",
}