import Translate from '@spordle/intl-elements';
import { stringifyUrl } from 'query-string';
import { useContext, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Button, Card, CardBody, Col, Container,
    DropdownItem,
    DropdownMenu, DropdownToggle,
    Row,
    Spinner, UncontrolledDropdown
} from "reactstrap";
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import DisplayOrganization from '../../../components/organization/DisplayOrganization';
import CanDoAction from '../../../components/permissions/CanDoAction';
import TabRoutesNavs from '../../../components/subSidebar/TabRoutesNavs';
import TabRoutesPanes from '../../../components/subSidebar/TabRoutesPanes';
import { success, fail } from '@spordle/toasts';
// View components / tabs
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import { AuthContext, PeriodsContext, AppContext } from '../../../contexts/contexts';
import { OnlineStoreContext } from '../../../contexts/OnlineStoreContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { ReportsContext } from '../../../contexts/ReportsContext';
import { copyToClipBoard } from '../../../helpers/clipboardHelper';
import getReferrer from '../../../helpers/getReferer';
import { displayI18n, DisplayI18n } from '../../../helpers/i18nHelper';
import { counterAnim } from '../../../helpers/uiHelper';
import onlineStoreTabsRoutes from '../../../routes/tabRoutes/onlineStoreTabsRoutes';
import { Tooltip } from "@mantine/core";
import OnlineStoreSharedOrganizationModal from './components/OnlineStoreSharedOrganizationModal';
import { FormattedPlural } from 'react-intl';
import { RolesContext } from '../../../contexts/RolesContext';
import HasAccessTo from '../../../components/permissions/HasAccessTo';

const OnlineStoreProfile = (props) => {
    const authContext = useContext(AuthContext);
    const organizationContext = useContext(OrganizationContext);
    const onlineStoreContext = useContext(OnlineStoreContext);
    const periodsContext = useContext(PeriodsContext);
    const reportsContext = useContext(ReportsContext);
    const { updateRecentlyViewed } = useContext(AppContext);
    const { canDoAction } = useContext(RolesContext);

    const [ sharedOrgModalIsOpen, setSharedOrgModalIsOpen ] = useState(false);

    // To be used as a key, force refresh to trigger refresh on next table load in different tab. Used for "add regsitration" to update transactions tab
    const [ forceRefresh, setForceRefresh ] = useReducer((x) => x + 1, 0);
    const [ state, setState ] = useState({});

    useEffect(() => {
        getRegistrationFeesAndOtherFees(props.match.params.onlineStoreId);
        onlineStoreContext.getOnlineStore(props.match.params.onlineStoreId, {}, true)
            .then((store) => {
                updateRecentlyViewed({
                    label: store.name,
                    path: props.location.pathname,
                    i18n: {
                        en: {
                            label: displayI18n("name", store.i18n, store.name, "en"),
                        },
                        fr: {
                            label: displayI18n("name", store.i18n, store.name, "fr"),
                        },
                    },
                });

                return periodsContext.getPeriod(store.period.period_id)
                    .then(async(myPeriod) => {
                        periodsContext.setSelectedPeriod(myPeriod);

                        await onlineStoreContext.getOnlineStoreInvoices({
                            organisation_id: organizationContext.organisation_id,
                            period_id: store.period.period_id,
                            online_store_id: props.match.params.onlineStoreId,
                        });
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    })
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    props.history.replace('/onlineregistration/404');
                }
            })
    }, []);

    const handleStatus = (status) => () => {
        onlineStoreContext.partialUpdateOnlineStore(props.match.params.onlineStoreId, { status: status })
            .then(() => {
                onlineStoreContext.updateCachedStore({ status: status });
                success({ msg: 'onlineStore.profile.overview.status.updated.success' });
                getRegistrationFeesAndOtherFees(props.match.params.onlineStoreId);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    if(error.i18n){
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }else{
                        fail({ msg: 'onlineStore.profile.overview.status.updated.error' });
                    }
                }
            })
    }

    const getRegistrationFeesAndOtherFees = (onlineStoreId) => {
        setState(() => ({ registrationsTabLoading: true }))
        Promise.all([
            onlineStoreContext.getOnlineStoreRegistrationFee(onlineStoreId),
            onlineStoreContext.getOnlineStoreOtherFee(onlineStoreId),
            reportsContext.getRegistrationCountReport({ online_store_id: onlineStoreId, type: 'REGISTRATION' }), // Quick cards in general tab
            reportsContext.getRegistrationCountReport({ online_store_id: onlineStoreId, type: 'OTHER' }), // Quick cards in general tab
        ])
            .then((promises) => {
                setState((prevState) => {
                    const registrationFeesCount = promises[2].reduce((count, row) => (count + row.item_sold), 0);
                    const otherFeesCount = promises[3].reduce((count, row) => (count + row.item_sold), 0);
                    counterAnim('registrationFeesCount', prevState.registrationFeesCount, registrationFeesCount); // Quick cards counter animation in general tab
                    counterAnim('otherFeesCount', prevState.otherFeesCount, otherFeesCount); // Quick cards counter animation  in general tab

                    //Map report count to each listed item
                    const registrationFees = promises[0].map((fee) => {
                        return ({
                            ...fee,
                            item_sold: promises[2].filter((row) => (fee.registration_fee?.team_category?.team_category_id === row.team_category_id))?.[0]?.item_sold ?? 0,
                        })
                    })

                    const otherFees = promises[1].map((fee) => {
                        return ({
                            ...fee,
                            item_sold: promises[3].filter((row) => (fee.other_fee?.other_fee_id === row.item_id))?.[0]?.item_sold ?? 0,
                        })
                    })


                    return {
                        registrationFees: registrationFees,
                        otherFees: otherFees,
                        registrationFeesCount: registrationFeesCount || 0,
                        otherFeesCount: otherFeesCount || 0,
                        registrationFeesCountData: promises[2] || [],
                        otherFeesCountData: promises[3] || [],
                        registrationsTabLoading: false,
                    }
                })

            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    const getPageReferrer = (withAuth = false) => {
        return stringifyUrl({
            url: organizationContext.deploy_on == 'PAGE' ?
            `${getReferrer('PAGE-VERCEL-2')}/${organizationContext.short_name}/participant/${props.match.params.onlineStoreId}`
                : organizationContext.deploy_on == 'VERCEL' ?
                `${getReferrer('PAGE-VERCEL')}/${organizationContext.short_name}/participant/${props.match.params.onlineStoreId}`
                    :
                `${getReferrer('PAGE')}/page/${organizationContext.short_url}/participant/${props.match.params.onlineStoreId}`,
            query: {
                accessToken: withAuth ? authContext.accessToken : undefined,
            },
        }, {
            skipEmptyString: true,
            skipNull: true,
        })
    }

    const canShareStore = canDoAction('ADD', 'page', 'online_store_manage')

    return (
        <>
            <OnlineStoreSharedOrganizationModal onlineStore={onlineStoreContext.state.cachedStore} isOpen={sharedOrgModalIsOpen} toggle={() => setSharedOrgModalIsOpen(!sharedOrgModalIsOpen)} />
            {/*--------------------------------------------------------------------------------*/}
            {/* View Header                                                                  */}
            {/*--------------------------------------------------------------------------------*/}
            <ViewHeaderV2
                breadcrumbsProps={{
                    disablePeriodSelect: true,
                    favoriteData: onlineStoreContext.state.cachedStore ? {
                        label: onlineStoreContext.state.cachedStore.name,
                        path: props.location.pathname,
                        i18n: {
                            en: {
                                label: displayI18n("name", onlineStoreContext.state.cachedStore.i18n, onlineStoreContext.state.cachedStore.name, "en"),
                            },
                            fr: {
                                label: displayI18n("name", onlineStoreContext.state.cachedStore, onlineStoreContext.state.cachedStore.name, "fr"),
                            },
                        },
                        translate: false,
                    } : {},
                }}
            />

            <div className="page-content container-fluid p-4">
                <Container>
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Profile Header                                                                 */}
                    {/*--------------------------------------------------------------------------------*/}
                    <div className="d-flex text-white mb-3 align-items-center">
                        <div className="font-medium">
                            <div className="h2 mb-0 font-bold">
                                {onlineStoreContext.state.cachedStore.online_store_id ?
                                    <DisplayI18n i18n={onlineStoreContext.state.cachedStore.i18n} field='name' defaultValue={onlineStoreContext.state.cachedStore.name} />
                                    :
                                    <Spinner type='grow' color='primary' style={{ width: '2rem', height: '2rem' }} />
                                }
                            </div>
                            <div className='text-dark'>
                                <DisplayOrganization organisation={onlineStoreContext.state.cachedStore.organisation?.[0]}><DisplayI18n field='name' defaultValue={onlineStoreContext.state.cachedStore.organisation?.[0].organisation_name} i18n={onlineStoreContext.state.cachedStore.organisation?.[0].i18n} /></DisplayOrganization>
                            </div>
                            {onlineStoreContext.state.cachedStore.shared_organisations?.length > 0 &&
                                <HasAccessTo componentCode='catalog' componentPermissionCode='manage_online_store_shared'>
                                    {(godOnly) => (
                                        <Tooltip
                                            label={
                                                <SharedTooltip shared={onlineStoreContext.state.cachedStore.shared_organisations} />
                                            }
                                            withArrow
                                            position='right'
                                        >
                                            <div
                                                className={`text-${godOnly ? 'purple' : 'primary'} ${canShareStore ? 'clickable' : ''}`}
                                                onClick={() => {
                                                    if(onlineStoreContext.state.cachedStore.online_store_id && canShareStore)
                                                        setSharedOrgModalIsOpen(true)
                                                }}
                                            >
                                                <span className='mr-1'>
                                                    {onlineStoreContext.state.cachedStore.shared_organisations?.length}
                                                </span>
                                                <FormattedPlural
                                                    value={onlineStoreContext.state.cachedStore.shared_organisations?.length}
                                                    one={<Translate id='onlineStore.profile.overview.singular.shared' />}
                                                    other={<Translate id='onlineStore.profile.overview.plural.shared' />}
                                                />
                                                <i className='ml-1 mdi mdi-information-outline' />
                                            </div>
                                        </Tooltip>
                                    )}
                                </HasAccessTo>
                            }
                        </div>
                        <div className="ml-auto">
                            <div className="d-flex align-items-center">
                                <CanDoAction action="EDIT" componentCode='catalog' componentPermissionCode='manage_online_store_shared'>
                                    {(godOnly) => (
                                        <Tooltip withArrow label={<Translate id='onlineStore.profile.overview.tooltip.share' />}>
                                            <Button
                                                id='online-store-share-button'
                                                className="ml-2" color={godOnly ? "purple" : "primary"} outline
                                                onClick={() => { setSharedOrgModalIsOpen(true) }}
                                                disabled={!onlineStoreContext.state.cachedStore.online_store_id}
                                            >
                                                <Translate id='onlineStore.profile.button.share' />
                                            </Button>
                                        </Tooltip>
                                    )}
                                </CanDoAction>

                                <CanDoAction className="ml-2" tag={UncontrolledDropdown} componentCode='members' componentPermissionCode='member_transactions' action='EDIT'>
                                    <Tooltip withArrow label={<Translate id='onlineStore.profile.overview.tooltip.status' />}>
                                        <DropdownToggle id='online-store-status-toggle-btn' caret color="primary" outline>
                                            {onlineStoreContext.state.cachedStore.online_store_id ?
                                                <Translate id={`onlineStore.profile.overview.status.${onlineStoreContext.state.cachedStore.status}`} />
                                                :
                                                <Spinner type='grow' color='primary' size='sm' />
                                            }
                                        </DropdownToggle>
                                    </Tooltip>


                                    <DropdownMenu right>
                                        <DropdownItem
                                            id='online-store-active-status'
                                            onClick={handleStatus('ACTIVE')}
                                            disabled={onlineStoreContext.state.cachedStore.status === 'ACTIVE'}
                                        >
                                            <Translate id='onlineStore.profile.overview.status.ACTIVE' />
                                        </DropdownItem>
                                        <DropdownItem
                                            id='online-store-draft-status'
                                            onClick={handleStatus('DRAFT')}
                                            disabled={onlineStoreContext.state.cachedStore.status === 'DRAFT'}
                                        >
                                            <Translate id='onlineStore.profile.overview.status.DRAFT' />
                                        </DropdownItem>
                                        <DropdownItem
                                            id='online-store-closed-status'
                                            onClick={handleStatus('CLOSE')}
                                            disabled={onlineStoreContext.state.cachedStore.status === 'CLOSE'}
                                        >
                                            <Translate id='onlineStore.profile.overview.status.CLOSE' />
                                        </DropdownItem>
                                    </DropdownMenu>
                                </CanDoAction>

                                <Tooltip withArrow label={<Translate id='onlineStore.profile.overview.tooltip.open' />}>
                                    <Button
                                        id='online-store-open-store-link'
                                        tag='a'
                                        color='primary'
                                        outline
                                        className="ml-2"
                                        target="_blank" rel="noreferrer noopener"
                                        href={getPageReferrer(true)}
                                    >
                                        <i className="mdi mdi-cart-outline" />
                                    </Button>
                                </Tooltip>

                                <Tooltip withArrow label={<Translate id='onlineStore.profile.overview.tooltip.copyUrl' />}>
                                    <Button
                                        id='online-store-copy-url-btn'
                                        className="ml-2" color="primary" outline
                                        onClick={() => {
                                            copyToClipBoard(organizationContext.is_vercel == 1 ?
                                                `${getReferrer('PAGE-VERCEL')}/${organizationContext.short_name}/participant/${props.match.params.onlineStoreId}`
                                                :
                                                `${getReferrer('PAGE')}/page/${organizationContext.short_url}/participant/${props.match.params.onlineStoreId}`)
                                        }}
                                    >
                                        <i className="mdi mdi-content-copy" />
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>

                    <div className="">
                        <Row form>
                            <Col md="3" xs='6'>
                                <Card className="card-shadow card-hover text-center mb-3 clickable">
                                    <CardBody>
                                        {/* Value is generated in JS in parent component -> getRegistrationFeesAndOtherFees() */}
                                        <Link to={{ pathname: `/onlineregistration/profile/${props.match.params.onlineStoreId}/registration` }}>
                                            {state.registrationsTabLoading &&
                                                <Spinner className='mb-2' style={{ height: '28px', width: '28px' }} color="primary" type='grow' />
                                            }
                                            <div id="registrationFeesCount" className={`h2 mb-2 font-bold ${state.registrationsTabLoading ? 'd-none' : 'd-block'}`}>{0}</div>
                                            <div className="font-medium"><Translate id='onlineStore.search.sidepanel.title.registrations.sold' /></div>
                                        </Link>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="3" xs='6'>
                                <Card className="card-shadow card-hover text-center mb-3 clickable">
                                    <CardBody>
                                        {/* Value is generated in JS in parent component -> getRegistrationFeesAndOtherFees() */}
                                        <Link to={{ pathname: `/onlineregistration/profile/${props.match.params.onlineStoreId}/transaction` }}>
                                            {state.registrationsTabLoading &&
                                                <Spinner className='mb-2' style={{ height: '28px', width: '28px' }} color="primary" type='grow' />
                                            }
                                            <div id="otherFeesCount" className={`h2 mb-2 font-bold ${state.registrationsTabLoading ? 'd-none' : 'd-block'}`}>{0}</div>
                                            <div className="font-medium"><Translate id='onlineStore.search.sidepanel.title.otherItems.sold' /></div>
                                        </Link>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>

                    <div>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Nav Tabs                                                                  */}
                        {/*--------------------------------------------------------------------------------*/}
                        <TabRoutesNavs routes={onlineStoreTabsRoutes} />
                        <TabRoutesPanes
                            routes={onlineStoreTabsRoutes}
                            getComponentProps={((route) => {
                                switch (route.path){
                                    case '/overview':
                                    case '/items-and-fees':
                                        return { onlineStoreId: props.match.params.onlineStoreId }
                                    case '/registration':
                                    case '/transaction':
                                        return {
                                            forceRefresh: forceRefresh,
                                            setForceRefresh: setForceRefresh,
                                            getRegistrationFeesAndOtherFees: () => getRegistrationFeesAndOtherFees(props.match.params.onlineStoreId),
                                            ...props,
                                        };
                                    case '/invoice':
                                        return { invoiceNb: props.location.state?.invoice_number };
                                    default:
                                        break;
                                }
                            })}
                        />
                    </div>
                </Container>
            </div>
        </>
    );
}

const SharedTooltip = ({ shared }) => {
    const MAX = 6;
    const [ shown, more ] = shared.length > MAX ? shared.reduce((split, org, i) => {
        if(i <= MAX){
            split[0].push(org);
        }else{
            split[1].push(org);
        }
        return split;
    }, [ [], [] ]) : [ shared, [] ];

    return (
        <>
            <ul className='pretty-scrollbar pl-3 mb-0'>
                {shown.map((sharedOrg) => (
                    <li key={sharedOrg.online_store_shared_organisation_id}>
                        <DisplayI18n field='name' defaultValue={sharedOrg.organisation.organisation_name} i18n={sharedOrg.organisation.i18n} />
                    </li>
                ))}
            </ul>
            {more.length > 0 &&
                <b>+ <Translate id="misc.countMore" values={{ count: more.length }} /></b>
            }
        </>
    )
}

export default OnlineStoreProfile;