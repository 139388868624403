import { useContext, useRef } from "react";
import { NewsContext } from "../../contexts/NewsContext";
import Translate from '@spordle/intl-elements';
import ViewHeaderV2 from '../../components/viewHeader/ViewHeaderV2';
import { Button, Card, CardBody, Row, Col } from "reactstrap";
import EmptyLayout from "../../components/table/EmptyLayout";
import SpordleTableProvider, { SearchInput, SpordleTableView } from "@spordle/datatables";
import CanDoAction from "../../components/permissions/CanDoAction";
import I18nHelperContextProvider, { I18nHelperContext } from "../../helpers/i18nHelper";
import { Link } from "react-router-dom";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { IdentityRolesContext } from "../../contexts/IdentityRolesContext";
import { I18nContext } from "../../contexts/I18nContext";
import { RolesContext } from "../../contexts/RolesContext";
import Skeleton from 'react-loading-skeleton';
import NewsPreview from "./components/newsPreview/NewsPreview";
import SortTableSelect from "../../components/table/SortTableSelect";
import { newsIsLive } from "../../components/noticesList/newsHelpers";
import useSavedSearch from "../../components/customHooks/useSavedSearch";
import SavedSearchCue from "../../components/savedSearchCue/SavedSearchCue";
import moment from "moment";

const News = () => {
    const { getGenericLocale } = useContext(I18nContext);
    const { organisation_id } = useContext(OrganizationContext);
    const { canDoAction } = useContext(RolesContext);
    const { identity_role_id } = useContext(IdentityRolesContext);
    const { getNewsList } = useContext(NewsContext);
    const { savedSearch, clearSavedSearch, saveSearch } = useSavedSearch(`news-search-filters-${organisation_id}-${identity_role_id}`);

    const tableRef = useRef();

    const canEdit = canDoAction("EDIT", "communications", "communication_news_manage");
    const canDelete = canDoAction("DELETE", "communications", "communication_news_manage");


    return (
        <>
            <ViewHeaderV2 title="sidebar.news" />
            <div className="page-content px-4 pt-0">
                <I18nHelperContextProvider fields={[ "description", "title" ]}>
                    <I18nHelperContext.Consumer>
                        {() => (
                            <SpordleTableProvider
                                id='newslist_table'
                                ref={tableRef}
                                tableHover bordered striped
                                clickable
                                viewMode="GRID"
                                gridConfig={{
                                    col: {
                                        xs: 12,
                                    },
                                    gridRender: (news) => (
                                        <NewsPreview news={news} canEdit={canEdit} canDelete={canDelete} />
                                    ),
                                }}
                                initFilter={savedSearch?.filters || {
                                    liveOnly: false,
                                }}
                                defaultSorting={savedSearch?.sort != void 0 && typeof savedSearch.sort === "string" ? savedSearch.sort : '-active'}
                                dataIndex='news_id'
                                pagination={20}
                                emptyLayout={<EmptyLayout />}
                                desktopWhen
                                loadData={(from, { filters }, spordleTable) => {
                                    switch (from){
                                        case 'FILTER':
                                            saveSearch({
                                                filters: filters,
                                                sort: spordleTable.state.orderingBy.column ? `${spordleTable.state.orderingBy.order === "DESC" ? "+" : "-"}${spordleTable.state.orderingBy.column}` : "",
                                            });
                                            break;
                                        case 'REFRESH':
                                            spordleTable.setLoading()
                                        case 'CDM':
                                            return getNewsList({
                                                organisation_id,
                                                identity_role_id,
                                            })
                                                .then(({ news }) => news)
                                        default:
                                            break;
                                    }
                                }}
                                searchKeys={[
                                    `type`,
                                    'title',
                                    `i18n.${getGenericLocale()}.title`,
                                ]}
                                columnSorting={(colA, colB, spordleTable) => {
                                    const orderingData = spordleTable.state.orderingBy; // Last param of columnSorting is returning undefined


                                    if(orderingData?.column === "active"){
                                        const getOrderDate = (a, b) => {
                                            return Math.sign(moment(b.published_at || b.created_at).valueOf() - moment(a.published_at || a.created_at).valueOf());
                                        }

                                        const orderDir = orderingData.order === "DESC" ? 1 : -1;
                                        const aIsLive = newsIsLive(colA);
                                        const bIsLive = newsIsLive(colB);

                                        if(aIsLive && bIsLive){ // BOTH are live -> order by date
                                            return orderDir * getOrderDate(colA, colB);
                                        }

                                        if(aIsLive){
                                            return orderDir * -1;
                                        }

                                        if(bIsLive){
                                            return orderDir * 1;
                                        }

                                        if(colA.active == 1 && colB.active == 1){ // BOTH are active -> order by date
                                            return orderDir * getOrderDate(colA, colB);
                                        }

                                        if(colA.active == 1){
                                            return orderDir * -1;
                                        }

                                        if(colB.active == 1){
                                            return orderDir * 1;
                                        }

                                        return orderDir * getOrderDate(colA, colB); // BOTH inactive -> order by date

                                    }
                                }}
                                filterJSCallback={(news, { liveOnly }) => !liveOnly || newsIsLive(news)}
                                columns={[
                                    {
                                        label: <Translate id="news.form.field.startDate" />,
                                        key: 'published_at',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id="news.form.field.endDate" />,
                                        key: 'published_until',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id="news.form.field.type" />,
                                        key: 'type',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id="form.fields.title" />,
                                        key: `title`,
                                        sortKey: `i18n.${getGenericLocale()}.title`,
                                        fallbackSortKey: 'title',
                                        sortable: true,
                                    },
                                    {
                                        label: "Created by",
                                        key: "created_by",
                                    },
                                    {
                                        label: <Translate id="misc.active" />,
                                        key: "active",
                                        sortable: true,
                                    },
                                ]}
                            >
                                {(spordleTable) => (
                                    <Card className='card-shadow'>
                                        <CardBody>
                                            <Row form className='mb-3'>
                                                <Col sm="6" lg="3" className="mb-3 mb-sm-0">
                                                    <SearchInput />
                                                </Col>
                                                <Col sm="6" lg="3" className="mb-3 mb-md-0">
                                                    <SortTableSelect
                                                        onOptionSelected={(newVal) => {
                                                            saveSearch({
                                                                filters: {
                                                                    liveOnly: spordleTable.getFilters().liveOnly,
                                                                },
                                                                sort: newVal || "",
                                                            });
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm="6" lg="3" className="d-flex align-items-center">
                                                    <label htmlFor="liveOnly" className="switch">
                                                        <input
                                                            name="liveOnly"
                                                            onChange={() => {
                                                                spordleTable.filterChange('liveOnly', !spordleTable.getFilters().liveOnly);
                                                            }}
                                                            id="liveOnly"
                                                            type="checkbox"
                                                            checked={spordleTable.getFilters().liveOnly}
                                                        />
                                                        <span className="switch-toggler" />
                                                    </label>
                                                    <label className="text-muted mb-0 ml-1" htmlFor="liveOnly">
                                                        <Translate id="news.table.filter.liveOnly" />
                                                    </label>
                                                </Col>
                                                <Col lg="3" md="6" className='d-flex order-2 order-md-0 mt-3 mt-lg-0 ml-auto justify-content-end flex-wrap flex-md-nowrap'>
                                                    <SpordleTableProvider.Refresh />
                                                    <CanDoAction
                                                        action="ADD"
                                                        componentCode="communications"
                                                        componentPermissionCode="communication_news_manage"
                                                    >
                                                        <Button className="ml-2" tag={Link} to="/news/create" color="primary">
                                                            <i className='ti-plus' /> <Translate id='misc.add' />
                                                        </Button>
                                                    </CanDoAction>
                                                </Col>
                                                <Col xs="12" className="order-1 order-md-0">
                                                    <SavedSearchCue
                                                        className="pt-2"
                                                        savedSearch={savedSearch}
                                                        clearSavedSearch={() => {
                                                            spordleTable.filterChange('liveOnly', false);
                                                            spordleTable.setColumnSorting("-active");
                                                            clearSavedSearch();
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            {(spordleTable.state.loadingState === "loading") ?
                                                <>
                                                    <div className="d-none d-md-block">
                                                        <Skeleton
                                                            inline
                                                            wrapper={({ children }) => <div>{children}</div>}
                                                            className="d-block mb-2"
                                                            count={5}
                                                            width="100%"
                                                            height={100}
                                                        />
                                                    </div>
                                                    <div className="d-md-none">
                                                        {Array.from({ length: 3 }, (_, i) => i).map((count) => (
                                                            <div key={count} className="border rounded-lg shadow bg-white mb-2">
                                                                <Skeleton
                                                                    inline
                                                                    wrapper={({ children }) => (
                                                                        <div className="w-100 h-0 position-relative mb-2 border-bottom">
                                                                            <div style={{ paddingBottom: "66%" }} />
                                                                            {children}
                                                                        </div>
                                                                    )}
                                                                    className="line-height-0 rounded-0 d-block position-absolute top-0 right-0 left-0 bottom-0"
                                                                    width="100%"
                                                                    height="100%"
                                                                />
                                                                <div className="p-3">
                                                                    <Skeleton inline containerClassName="d-block mb-1" className="d-block" width={100} height={13} />
                                                                    <Skeleton
                                                                        inline
                                                                        containerClassName="d-block mb-1"
                                                                        className="d-block"
                                                                        width={150}
                                                                        height={17}
                                                                    />
                                                                    <Skeleton inline containerClassName="d-block" className="d-block" width='100%' height={13} />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                                :
                                                spordleTable.state.loadingState === "error" ?
                                                    <div className="text-center bg-light-danger border rounded-lg p-3">
                                                        <div className="h5 text-danger font-medium">Oops!<i className="mdi mdi-close-circle-outline ml-1" /></div>
                                                        <p className="mb-1">An error occured.</p>
                                                        <button onClick={() => spordleTable.refreshTable()} type="button" className="reset-btn text-link">
                                                            <span className="small">
                                                                <Translate id="misc.refresh" /><i className="ml-1 mdi mdi-refresh" />
                                                            </span>
                                                        </button>
                                                    </div>
                                                    :
                                                    <SpordleTableView />
                                            }
                                        </CardBody>
                                    </Card>
                                )}
                            </SpordleTableProvider>
                        )}
                    </I18nHelperContext.Consumer>
                </I18nHelperContextProvider>
            </div>
        </>
    );
}

export default News;