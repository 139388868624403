import { stringBuilder } from '@spordle/helpers'
import { useState } from 'react'
import { Collapse, Fade, ListGroupItem } from 'reactstrap'
import { DisplayI18n } from '../../../../../helpers/i18nHelper'
import TeamSettingsCategoryQualificationsMembers from './TeamSettingsCategoryQualificationsMembers'

const TeamSettingsCategoryQualificationsRow = ({ categoryQualificationRule, canSeeMember }) => {
    const [ showMembers, setShowMembers ] = useState(false);
    const canOpen = categoryQualificationRule.current_count > 0

    return (
        <ListGroupItem className={parseInt(categoryQualificationRule.current_count) < parseInt(categoryQualificationRule.applies_to) ? 'bg-light-warning' : 'bg-light-success'}>
            <div className={canOpen ? 'clickable' : undefined} onClick={canOpen ? () => { setShowMembers(!showMembers) } : undefined}>
                <div className='d-flex justify-content-between align-items-center'>
                    <DisplayI18n field='name' defaultValue={categoryQualificationRule.qualification.name} i18n={categoryQualificationRule.qualification.i18n} />
                    {categoryQualificationRule.qualification.qualification_level &&
                        <>
                            {" - "}
                            <DisplayI18n
                                field="name"
                                defaultValue={categoryQualificationRule.qualification.qualification_level.name}
                                i18n={categoryQualificationRule.qualification.qualification_level.i18n}
                            />
                        </>
                    }
                    {categoryQualificationRule.qualification.description &&
                        <>
                            {" - "}
                            <DisplayI18n
                                field="description"
                                defaultValue={categoryQualificationRule.qualification.name}
                                i18n={categoryQualificationRule.qualification.i18n}
                            />
                        </>
                    }
                    <div>
                        <span className='font-medium'>
                            {categoryQualificationRule.current_count} / {categoryQualificationRule.applies_to}
                        </span>
                        {canOpen &&
                            <i className={stringBuilder(`mdi mdi-chevron-down d-inline-block transition ml-3`, { "rotate-180": showMembers })} />
                        }
                    </div>
                </div>
            </div>
            <Collapse isOpen={showMembers} className='py-1'>
                <Fade in={showMembers}>
                    <TeamSettingsCategoryQualificationsMembers members={categoryQualificationRule.members} canSeeMember={canSeeMember} />
                </Fade>
            </Collapse>
        </ListGroupItem>
    )
}

export default TeamSettingsCategoryQualificationsRow
