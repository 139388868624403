import { Component, createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { OrganizationContext } from './OrganizationContext';
import queryString, { stringifyUrl } from 'query-string';
import { serverError } from '../api/CancellableAPI';
import moment from 'moment';

/** @type {React.Context<Omit<FormsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const FormsContext = createContext();
FormsContext.displayName = 'FormsContext';

/**
 * @typedef {object} FormField
 * @property {string} form_field_id
 * @property {'0'|'1'} with_option
 * @property {'0'|'1'} active
 * @property {'TEXT'|'TEXT_AREA'|'CHECKBOX'|'RADIO'|'SELECTBOX'|'SELECTBOX_MULTI'|'DATE'|'DATETIME'|'NUMBER'} code
 */

class FormsContextProvider extends Component{
    static contextType = OrganizationContext;

    /** @type {React.ContextType<OrganizationContext>} */
    context;

    state = {
        /** @type {FormField[]} */
        formFields: [],
    }

    /**
     * Get all the system's form fields
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Forms/Apicontroller%5CForms%5CForms%3A%3AgetAllFormField|documentation}
     * @returns {Promise.<FormField[]>}
     */
    getFormFields = () => {
        if(this.state.formFields.length !== 0)
            return Promise.resolve(this.state.formFields);

        return API_SPORDLE.get('/forms/fields')
            .then((response) => {
                if(response.data.status){
                    this.setState(() => ({ formFields: response.data.form_fields }));
                    return response.data.form_fields;
                }
                throw response.data.errors[0];
            }, function(){})
    }

    /**
     * Get all the forms define for an organization
     * @param {string} [organizationId] The organization id we want to get the forms from. It uses the current org by default
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Forms/Apicontroller%5CForms%5CForms%3A%3AgetAllForms|documentation}
     * @returns {Promise.<Array>}
     */
    getForms = (organizationId = this.context.organisation_id, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: 'forms',
            query: Object.assign({
                organisation_id: organizationId,
            }, queryParams),
        })).then((response) => {
            if(response.data.status){
                return response.data.forms;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get a specific form
     * @param {string} formId The specific form we want to get
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Forms/Apicontroller%5CForms%5CForms%3A%3AgetFormDetail|documentation}
     * @returns {Promise.<array>}
     */
    getForm = (formId) => {
        return API_SPORDLE.get(`/forms/${formId}`)
            .then((response) => {
                if(response.data.status){
                    return response.data.form_groups;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Get a form clinics
     * @param {string} customFormId required the form you are looking for
     * @param {string} periodId the form you are looking for
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Forms/Apicontroller%5CForms%5CForms%3A%3AgetFormDetail|documentation}
     * @returns {Promise.<array>}
     */
    getFormClinics = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/forms/clinics',
            query: Object.assign({
                custom_form_id: queryParams.customFormId,
                period_id: queryParams.periodId || '',
            }, queryParams),
        })).then((response) => {
            if(response.data.status){
                return response.data.clinics;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get a specific form
     * @param {string} custom_form_id The specific form we want to get the registration fees from
     * @param {string} period_id The period id to fetch from
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Forms/Apicontroller%5CForms%5CForms%3A%3AgetFormDetail|documentation}
     * @returns {Promise.<array>}
     */
    getRegistrationFeesFromForm = (custom_form_id, period_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/forms/registration-fees',
            query: Object.assign({
                custom_form_id: custom_form_id,
                period_id: period_id,
            }),
        })).then((response) => {
            if(response.data.status){
                return response.data.registration_fees;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get a specific form
     * @param {string} custom_form_id The specific form we want to get the registration fees from
     * @param {string} period_id The period id to fetch from
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Forms/Apicontroller%5CForms%5CForms%3A%3AgetFormDetail|documentation}
     * @returns {Promise.<array>}
     */
    getAffiliationFeesFromForm = (custom_form_id, period_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/forms/${custom_form_id}/affiliation-fees`,
            query: Object.assign({
                period_id: period_id,
            }),
        })).then((response) => {
            if(response.data.status){
                return response.data.affiliation_fees;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Update a specific form
     * @param {string} formId The specific form we want to update
     * @param {import('../views/catalog/forms/components/CustomFormEditor').CustomFormSection[]} sections
     * @param {string} formName The specific form we want to get
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Forms/Apicontroller%5CForms%5CForms%3A%3AupdateForm|documentation}
     * @returns {Promise.<any>}
     */
    updateForm = (formId, sections, formName) => {
        const params = new URLSearchParams({
            name: formName,
            active: 1,
        });
        sections.forEach((section, sectionIndex) => {
            if(section.formGroupId){
                params.append(`group[${sectionIndex}][form_group_id]`, section.formGroupId);
            }
            params.append(`group[${sectionIndex}][name]`, this.context.hasEnglish() ? section.i18n.en.name : section.i18n.fr.name);
            params.append(`group[${sectionIndex}][sort]`, sectionIndex + 1);
            params.append(`group[${sectionIndex}][i18n][fr][name]`, section.i18n.fr.name);
            params.append(`group[${sectionIndex}][i18n][en][name]`, section.i18n.en.name);

            section.questions.forEach((question, questionIndex) => {
                if(question.formFieldId){
                    params.append(`group[${sectionIndex}][fields][${questionIndex}][form_field_id]`, question.formFieldId);
                }
                params.append(`group[${sectionIndex}][fields][${questionIndex}][form_field_code]`, question.formFieldCode);
                params.append(`group[${sectionIndex}][fields][${questionIndex}][is_admin]`, !!question.is_admin >>> 0);
                params.append(`group[${sectionIndex}][fields][${questionIndex}][mandatory]`, !!question.mandatory >>> 0);
                if(question.min){ // Checks for null and undefined -> https://stackoverflow.com/a/5515385
                    if(moment.isMoment(question.min)){
                        if(question.formFieldCode === 'DATETIME'){
                            params.append(`group[${sectionIndex}][fields][${questionIndex}][min]`, question.min.toISOString());
                        }else{
                            params.append(`group[${sectionIndex}][fields][${questionIndex}][min]`, question.min.format('YYYY-MM-DD'));
                        }
                    }else{
                        params.append(`group[${sectionIndex}][fields][${questionIndex}][min]`, question.min);
                    }
                }
                if(question.max){ // Checks for null and undefined -> https://stackoverflow.com/a/5515385
                    if(moment.isMoment(question.max)){
                        if(question.formFieldCode === 'DATETIME'){
                            params.append(`group[${sectionIndex}][fields][${questionIndex}][max]`, question.max.toISOString());
                        }else{
                            params.append(`group[${sectionIndex}][fields][${questionIndex}][max]`, question.max.format('YYYY-MM-DD'));
                        }
                    }else{
                        params.append(`group[${sectionIndex}][fields][${questionIndex}][max]`, question.max);
                    }
                }
                params.append(`group[${sectionIndex}][fields][${questionIndex}][sort]`, questionIndex + 1);

                // params.append(`group[${sectionIndex}][fields][${questionIndex}][placeholder]`, question.placeholder);
                // params.append(`group[${sectionIndex}][fields][${questionIndex}][i18n][fr][placeholder]`, question.i18n.fr.placeholder);
                // params.append(`group[${sectionIndex}][fields][${questionIndex}][i18n][en][placeholder]`, question.i18n.en.placeholder);
                params.append(`group[${sectionIndex}][fields][${questionIndex}][title]`, this.context.hasEnglish() ? question.i18n.en.title : question.i18n.fr.title);
                params.append(`group[${sectionIndex}][fields][${questionIndex}][i18n][fr][title]`, question.i18n.fr.title);
                params.append(`group[${sectionIndex}][fields][${questionIndex}][i18n][en][title]`, question.i18n.en.title);

                if(this.state.formFields.find((formField) => question.formFieldCode === formField.code)?.with_option === '1'){
                    question.options.forEach((option, optionIndex) => {
                        if(option.fieldOptionId){
                            params.append(`group[${sectionIndex}][fields][${questionIndex}][option][${optionIndex}][field_option_id]`, option.fieldOptionId);
                        }
                        params.append(`group[${sectionIndex}][fields][${questionIndex}][option][${optionIndex}][field_option]`, this.context.hasEnglish() ? option.i18n.en.fieldOption : option.i18n.fr.fieldOption);
                        params.append(`group[${sectionIndex}][fields][${questionIndex}][option][${optionIndex}][i18n][fr][field_option]`, option.i18n.fr.fieldOption);
                        params.append(`group[${sectionIndex}][fields][${questionIndex}][option][${optionIndex}][i18n][en][field_option]`, option.i18n.en.fieldOption);
                        params.append(`group[${sectionIndex}][fields][${questionIndex}][option][${optionIndex}][sort]`, optionIndex + 1);
                        params.append(`group[${sectionIndex}][fields][${questionIndex}][option][${optionIndex}][default]`, !!option.default >>> 0);
                    })
                }
            })
        })
        return API_SPORDLE.put(`/forms/${formId}`, params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * This creates a form
     * @param {import('../views/catalog/forms/components/CustomFormEditor').CustomFormSection[]} sections
     * @param {string} formName
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Forms/Apicontroller%5CForms%5CForms%3A%3AcreateForm|documentation}
     * @returns {Promise}
     */
    createForm = (sections, formName = 'New Form') => {
        const params = new URLSearchParams({
            name: formName,
            organisation_id: this.context.organisation_id,
        });
        sections.forEach((section, sectionIndex) => {
            params.append(`group[${sectionIndex}][name]`, this.context.hasEnglish() ? section.i18n.en.name : section.i18n.fr.name);
            params.append(`group[${sectionIndex}][sort]`, sectionIndex + 1);
            params.append(`group[${sectionIndex}][i18n][fr][name]`, section.i18n.fr.name);
            params.append(`group[${sectionIndex}][i18n][en][name]`, section.i18n.en.name);

            section.questions.forEach((question, questionIndex) => {
                params.append(`group[${sectionIndex}][fields][${questionIndex}][form_field_code]`, question.formFieldCode);
                params.append(`group[${sectionIndex}][fields][${questionIndex}][is_admin]`, !!question.is_admin >>> 0);
                params.append(`group[${sectionIndex}][fields][${questionIndex}][mandatory]`, !!question.mandatory >>> 0);
                if(question.min){ // Checks for null and undefined -> https://stackoverflow.com/a/5515385
                    if(moment.isMoment(question.min)){
                        if(question.formFieldCode === 'DATETIME'){
                            params.append(`group[${sectionIndex}][fields][${questionIndex}][min]`, question.min.toISOString());
                        }else{
                            params.append(`group[${sectionIndex}][fields][${questionIndex}][min]`, question.min.format('YYYY-MM-DD'));
                        }
                    }else{
                        params.append(`group[${sectionIndex}][fields][${questionIndex}][min]`, question.min);
                    }
                }
                if(question.max){ // Checks for null and undefined -> https://stackoverflow.com/a/5515385
                    if(moment.isMoment(question.max)){
                        if(question.formFieldCode === 'DATETIME'){
                            params.append(`group[${sectionIndex}][fields][${questionIndex}][max]`, question.max.toISOString());
                        }else{
                            params.append(`group[${sectionIndex}][fields][${questionIndex}][max]`, question.max.format('YYYY-MM-DD'));
                        }
                    }else{
                        params.append(`group[${sectionIndex}][fields][${questionIndex}][max]`, question.max);
                    }
                }
                params.append(`group[${sectionIndex}][fields][${questionIndex}][sort]`, questionIndex + 1);

                // params.append(`group[${sectionIndex}][fields][${questionIndex}][placeholder]`, question.placeholder);
                // params.append(`group[${sectionIndex}][fields][${questionIndex}][i18n][fr][placeholder]`, question.i18n.fr.placeholder);
                // params.append(`group[${sectionIndex}][fields][${questionIndex}][i18n][en][placeholder]`, question.i18n.en.placeholder);
                params.append(`group[${sectionIndex}][fields][${questionIndex}][title]`, this.context.hasEnglish() ? question.i18n.en.title : question.i18n.fr.title);
                params.append(`group[${sectionIndex}][fields][${questionIndex}][i18n][fr][title]`, question.i18n.fr.title);
                params.append(`group[${sectionIndex}][fields][${questionIndex}][i18n][en][title]`, question.i18n.en.title);

                if(this.state.formFields.find((formField) => question.formFieldCode === formField.code)?.with_option === '1'){
                    question.options.forEach((option, optionIndex) => {
                        params.append(`group[${sectionIndex}][fields][${questionIndex}][option][${optionIndex}][field_option]`, this.context.hasEnglish() ? option.i18n.en.fieldOption : option.i18n.fr.fieldOption);
                        params.append(`group[${sectionIndex}][fields][${questionIndex}][option][${optionIndex}][i18n][fr][field_option]`, option.i18n.fr.fieldOption);
                        params.append(`group[${sectionIndex}][fields][${questionIndex}][option][${optionIndex}][i18n][en][field_option]`, option.i18n.en.fieldOption);
                        params.append(`group[${sectionIndex}][fields][${questionIndex}][option][${optionIndex}][sort]`, optionIndex + 1);
                        params.append(`group[${sectionIndex}][fields][${questionIndex}][option][${optionIndex}][default]`, !!option.default >>> 0);
                    })
                }
            })
        })
        return API_SPORDLE.post('/forms', params)
            .then((response) => {
                if(response.data.status){
                    return {
                        formId: response.data.form_id,
                        formName: formName,
                        active: '1',
                    };
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Delete a form.
     * @param {string} formId The form to delete
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/index.php#/Forms/22c06514f6cd616b751f12f437115bfa|documentation}
     * @returns {Promise}
     */
    deleteForm = (formId) => {
        return API_SPORDLE.delete(`/forms/${formId}`)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Create a link between a clinic and a form.
     * The PUT is used to create link / update link
     * Update will overwrite the forms linked to the given clinic
     * @param {string} [formId] The form id we would like to link to a clinic
     * @param {string} [clinicId] The clinic id we would like to link to a form
     * @returns {Promise}
     */
    formClinicLink = (formId, clinicId) => {
        if(formId){
            const params = new URLSearchParams({
                "clinics[0]": clinicId, // api receives an array of clinic ids but we will only ever link one form to a clinic
            });
            return API_SPORDLE.put(`/forms/${formId}/clinics`, params)
                .then((response) => {
                    if(response.data.status){
                        return true;
                    }
                    throw response.data.errors[0];
                }, serverError);
        }
        return Promise.resolve();

    }

    /**
     * Create a link between a clinic and a form.
     * The PUT is used to create link / update link / delete a link
     * Delete a link: PUT with empty body
     * @param {string} [clinicId] The clinic id we would like to link to a form
     * @returns {Promise}
     */
    deleteFormClinicLink = (clinicId) => {
        return API_SPORDLE.delete(`/clinics/${clinicId}/forms`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Create a link between a member type and a form.
     * The PUT is used to create link / update link
     * Update will overwrite the forms linked to the given clinic
     * @param {string} formId The form id we would like to link to a clinic
     * @param {string} memberTypeId The member type id we would like to link to a form
     * @param {string} periodId The period id of the link
     * @returns {Promise}
     */
    formMemberTypeLink = (formId, memberTypeId, periodId) => {
        const params = new URLSearchParams({
            "member_types[0]": memberTypeId, // api receives an array of member type ids but we will only ever link one form to a clinic
            "period_id": periodId,
        });
        return API_SPORDLE.put(`/forms/${formId}/member-types`, params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError);
    }

    /**
     * Gets customs forms for an invoice
     * @param {string} [invoiceNumber]
     * @returns {Promise.<Array>}
     */
    getInvoiceForms = (invoiceNumber, queryParams) => {
        return API_SPORDLE.get(stringifyUrl({
            url: `/invoices/${invoiceNumber}/forms`,
            query: queryParams,
        }, {
            skipNull: true,
            skipEmptyString: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.invoice_custom_forms;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    render(){
        return (
            <FormsContext.Provider value={this}>
                {this.props.children}
            </FormsContext.Provider>
        );
    }
}

export default FormsContextProvider;
