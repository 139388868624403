import { captureException, withScope, Severity } from '@sentry/react';
import { FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import SpordleSelect from '@spordle/spordle-select';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, CustomInput, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AnalyticsModal from "../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import { I18nContext } from '../../contexts/I18nContext';
import { IdentityRolesContext } from '../../contexts/IdentityRolesContext';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { DisplayI18n } from '../../helpers/i18nHelper';
import UserImg from '../UserImg';
import OrganizationTree from './OrganizationTree';
import { fail } from '@spordle/toasts'
import styles from "./OrganizationSearch.module.scss";
import { stringBuilder } from '@spordle/helpers';
import { Tooltip } from '@mantine/core';

/**
 * This component will map and get all the sub organization of the current organization.
 * A SpordleSelect is always displayed. Optionally, there can be a OrganizationTree displayed(See props)
 *
 * @component OrganizationSearch
 * @prop {boolean} [withFormik] This will render a FormikSelect instead of a SpordleSelect so you can use this component with Formik
 * @prop {boolean} [multi] When true, will return a multi select.
 * @prop {boolean} [withTree] This will render a button to show the OrganizationTree
 * @prop {function} [treeOrganizationClick] Callback function for when an organization in selected inside the Tree
 * @prop {string|string[]} [selectedDefault] This will select by default the current organization in the list
 * @prop {boolean} [hasManualError] If we want to handle the formik error manually
 * @prop {boolean} [fromIdentityRole] Will use the organisation from the identity role
 * @prop {boolean} [fromApi] Will fetch the data from the api
 * @prop {boolean} [fromPublicApi] Will fetch the data from the PUBLIC api (fromApi must be true)
 * @prop {boolean} [onlyActive] Will fetch the active organizations only
 * @prop {string} [parentOrgId] Will fetch the organizations under this organization id
 * @prop {Array} [categories] Array of the categories ID to filter the tree with
 * @prop {boolean} [skipParent] Excludes the current org from the search
 * @prop {boolean} [skipFederation] Excludes the federation from the search
 * @prop {function} [onTreeClose] On tree modal close
 * @prop {string} [wrapperClassName] className of the top level tag
 * @prop {boolean} [isCompact] Compacts the "view tree" button
 *
 *
 * @example
 * <OrganizationSearch treeOrganizationClick={selectHostOrganization}/>
 * <OrganizationSearch fromIdentityRole withFormik withTree hasManualError multi closeMenuOnSelect={false} treeOrganizationClick={selectHostOrganization} className='w-50' name="organizations" id="organizations"/>
 */
const OrganizationSearch = React.forwardRef(({ fromIdentityRole, withFormik, withTree, treeOrganizationClick, children, selectedDefault, hasManualError, fromApi = true, parentOrgId = '', categories, fromPublicApi, onlyActive, skipParent, skipFederation, ...props }, ref) => {
    const selectRef = useRef(null);
    const orgContext = useContext(OrganizationContext);
    const identityRoleContext = useContext(IdentityRolesContext);
    const i18nContext = useContext(I18nContext);

    let formikContext = undefined;
    if(withFormik)// Connects to Formik when needed
        formikContext = useFormikContext();

    const [ isLoading, setLoading ] = useState(true);
    const [ showTree, setShowTree ] = useState(false);
    const [ data, setData ] = useState([]);
    const [ searchData, setSearchData ] = useState([]);
    const [ showOrg, setShowOrg ] = useState();
    const [ exactMatch, setExactMatch ] = useState(false);

    /**
     * Determines if the given org id is selected or not
     * @param {string} orgId
     * @returns {boolean}
     */
    const organizationIsSelected = (orgId) => {
        if(Array.isArray(selectedDefault)){
            return selectedDefault.includes(orgId);
        }
        return selectedDefault === orgId;
    }

    /**
     * Function that ask for conmfirmation if needed
     * @param {Object} org The organization that has been clicked on
     */
    const confirmOrgClick = (org) => {
        const orgSearchValues = formikContext?.values[props.name];

        if(treeOrganizationClick){
            treeOrganizationClick(org)?.then((isConfirmed) => {
                if(isConfirmed){
                    // if the org is included in the array, we must remove it from formik if we receive it again
                    if(props.multi && orgSearchValues?.includes(org.organisation_id)){
                        // finding the index of the org to remove and removing it with splice
                        const removeIndex = orgSearchValues.findIndex((orgId) => org.organisation_id === orgId);
                        orgSearchValues.splice(removeIndex, 1);
                        formikContext?.setFieldValue(props.name, [ ...orgSearchValues ]);
                    }else{
                        formikContext?.setFieldValue(props.name, props.multi ? [ ...formikContext.values[props.name], org.organisation_id ] : org.organisation_id);
                    }
                }
            });
        }else if(props.multi && orgSearchValues?.includes(org.organisation_id)){
            // finding the index of the org to remove and removing it with splice
            const removeIndex = orgSearchValues.findIndex((orgId) => org.organisation_id === orgId);
            orgSearchValues.splice(removeIndex, 1);
            formikContext?.setFieldValue(props.name, [ ...orgSearchValues ]);
        }else{
            formikContext?.setFieldValue(props.name, props.multi ? [ ...formikContext.values[props.name], org.organisation_id ] : org.organisation_id);
            if(!props.multi){
                setShowTree(false);
            }
        }
    }

    /**
     * Function called when we click the "Select all" option in the View tree
     * @param {Array} orgs The organization that need to be proccessed by the Select all action
     * @param {boolean} select If to select all or not
     */
    const clickOnAll = (orgs, select) => {
        const orgSearchValues = formikContext?.values[props.name];
        if(orgSearchValues){
            const selectOrgIds = orgs.flattenArray('sub_organisations', (org) => org?.organisation_id) //orgs.map((o) => o.organisation_id);
            if(select){ // Select all
                formikContext.setFieldValue(props.name, [ ...orgSearchValues, ...selectOrgIds ].removeDuplicates());
            }else{ // Unselect all
                formikContext.setFieldValue(props.name, orgSearchValues.filter((orgId) => !selectOrgIds.includes(orgId)));
            }
        }
    }

    const getTree = () => {
        if(fromPublicApi){
            return orgContext.getOrganizationsTreePublic(fromApi, onlyActive)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                });
        }
        return orgContext.getOrganizationsTree(!fromIdentityRole, fromApi, onlyActive, parentOrgId || undefined)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });
    }

    useEffect(() => {
        setShowTree(false);
    }, [ orgContext.organisation_id ]);

    const Select = withFormik ? FormikSelect : SpordleSelect;

    return (
        <div className={`d-flex ${props.wrapperClassName}`}>
            {props.isCompact && withTree &&
                <Tooltip
                    zIndex={3000}
                    label={<Translate id='organizationTree.organizationSearch.action.seeTree' />}
                >
                    <Button
                        className={`${styles.compactBtn} w-auto bg-light mdi mdi-file-tree form-control`}
                        color="light"
                        type='button'
                        onClick={() => { setShowTree(!showTree) }} disabled={isLoading}
                    />
                </Tooltip>
            }
            <div className='flex-grow-1'>
                <Select
                    {...props}
                    className={stringBuilder(props.className, { [styles.compactSelect]: props.isCompact })}
                    search ref={selectRef}
                    noOptionLayout={<Translate id='organizationTree.organizationSearch.select.noOrg' />}
                    placeholder={props.placeholder || 'organizationTree.organizationSearch.select.placeholder'}
                    textWhenSetting={{
                        count: 2,
                        label: 'organizationTree.organizationSearch.select.valueContainer.plural',
                    }}
                    renderOption={(option) => (
                        <CustomOptionRender name={option.option.label} abbreviation={option.option.abbreviation} logo={option.option.logo} i18n={option.option.i18n} parentOrg={option.option.parentOrgs} inDropDown />
                    )}
                    renderSelectedOption={(option) => (
                        <CustomOptionRender name={option.label} abbreviation={option.abbreviation} logo={option.logo} i18n={option.i18n} />
                    )}
                    searchKeys={[
                        `i18n.${i18nContext.getGenericLocale()}.name`,
                        'parentOrgs',
                        'abbreviation',
                    ]}
                    loadData={(from) => {
                        switch (from){
                            case 'CDM':
                                return getTree()
                                    .then(async(organisations) => {
                                        const orgsWithParent = [ {
                                            'organisation_id': fromIdentityRole ? identityRoleContext.organisation.organisation_id : orgContext.organisation_id,
                                            'organisation_name': fromIdentityRole ? identityRoleContext.organisation.organisation_name : orgContext.organisation_name,
                                            'logo': fromIdentityRole ? identityRoleContext.organisation.logo : orgContext.logo,
                                            'i18n': fromIdentityRole ? identityRoleContext.organisation.i18n : orgContext.i18n,
                                            'abbreviation': fromIdentityRole ? identityRoleContext.organisation.abbreviation : orgContext.abbreviation,
                                            'sub_organisations': organisations,
                                        } ]

                                        /**
                                         * Recursive function to flattenArray + build the trail
                                         * @param {Array} orgArray
                                         * @param {Array.<string>} [parentsTrail=[]]
                                         */
                                        const buildOptions = (orgArray, parentsTrail = []) => {
                                            return orgArray.reduce((buildedArray, org) => {
                                                if(categories && Array.isArray(categories)){
                                                    if(categories.includes(org.organisation_category?.organisation_category_id) && org?.organisation_id){
                                                        buildedArray.push({
                                                            value: org.organisation_id,
                                                            label: org.organisation_name,
                                                            logo: org.logo,
                                                            i18n: org.i18n,
                                                            abbreviation: org.abbreviation,
                                                            selected: !!selectedDefault && organizationIsSelected(org.organisation_id),
                                                            parentOrgs: parentsTrail.join(' / '),
                                                            category: org.organisation_category,
                                                        });
                                                    }
                                                }else if(org?.organisation_id){
                                                    buildedArray.push({
                                                        value: org.organisation_id,
                                                        label: org.organisation_name,
                                                        logo: org.logo,
                                                        i18n: org.i18n,
                                                        abbreviation: org.abbreviation,
                                                        selected: !!selectedDefault && organizationIsSelected(org.organisation_id),
                                                        parentOrgs: parentsTrail.join(' / '),
                                                        category: org.organisation_category,
                                                    });
                                                }
                                                if(Array.isArray(org.sub_organisations) && org.sub_organisations.length !== 0){
                                                    buildedArray.pushArray(buildOptions(org.sub_organisations, [ ...parentsTrail, org.organisation_name ]))
                                                }
                                                return buildedArray;
                                            }, []);
                                        }

                                        const currentOrgs = skipParent ? organisations : orgsWithParent;

                                        // set data before flattening
                                        setData(currentOrgs);

                                        // Build flattened org options
                                        const builtOptions = buildOptions(currentOrgs);

                                        if(skipFederation){
                                            // looked into it and this is the fastest approach
                                            // filter is 0.4ms, this is approx 0 to 0.1ms
                                            const fedIndex = builtOptions.findIndex((org) => org.value == identityRoleContext.federation.organisation_id);
                                            if(builtOptions[fedIndex])
                                                builtOptions.splice(fedIndex, 1);
                                        }

                                        // searchData is in the select
                                        setSearchData(builtOptions);
                                        setLoading(false);

                                        return builtOptions;
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error);
                                            withScope((scope) => {
                                                scope.addBreadcrumb({
                                                    type: 'http',
                                                    category: "api.call",
                                                    level: Severity.Debug,
                                                    message: `Api call failed: ${error.message}`,
                                                    data: {
                                                        orgId: fromIdentityRole ? identityRoleContext.organisation.organisation_id : orgContext.organisation_id,
                                                        orgName: fromIdentityRole ? identityRoleContext.organisation.organisation_name : orgContext.organisation_name,
                                                    },
                                                })
                                                scope.setLevel(Severity.Debug);
                                                captureException(new Error('Get tree failed'));
                                            });
                                            setLoading(false);
                                        }
                                    });
                            default:
                                break;
                        }
                    }}
                    defaultValues={selectedDefault ? Array.isArray(selectedDefault) ? selectedDefault : [ selectedDefault ] : undefined}
                    manualError={hasManualError}
                />
            </div>
            {withTree &&
                <div className='flex-grow-0'>
                    {!props.isCompact && <Button className="ml-2" color="primary" type='button' onClick={() => { setShowTree(!showTree) }} disabled={isLoading}><Translate id='organizationTree.organizationSearch.action.seeTree' /></Button>}
                    <AnalyticsModal
                        size='lg'
                        analyticsName='OrganizationSearch'
                        isOpen={showTree}
                        onClosed={() => {
                            setShowOrg();
                            props.onTreeClose?.();
                            setExactMatch(false);
                        }}
                    >
                        <ModalHeader toggle={() => { setShowTree(!showTree) }} className="font-medium">
                            <div className="h4 mb-0 font-medium">
                                <Translate id='organizationTree.organizationSearch.modal.org.tree.title' />
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div className='mb-3'>
                                <div className='d-flex justify-content-between'>
                                    <Label for={`${props.id}-orgTree-search`}>
                                        <Translate id='organizationTree.organizationSearch.modal.org.tree.searchLabel' />
                                    </Label>
                                    <CustomInput
                                        id='exactMatchCheckbox'
                                        type='checkbox'
                                        checked={exactMatch}
                                        onChange={(e) => setExactMatch(e.target.checked)}
                                        label={<Translate id='organizationTree.organizationSearch.modal.org.tree.exactMatch' />}
                                    />
                                </div>
                                <SpordleSelect
                                    values={showOrg ? [ showOrg ] : []}
                                    id={`${props.id}-orgTree-search`}
                                    search isLoading={isLoading} clearable
                                    noOptionLayout={<Translate id='organizationTree.organizationSearch.select.noOrg' />}
                                    renderOption={(option) => (
                                        <CustomOptionRender name={option.option.label} abbreviation={option.option.abbreviation} logo={option.option.logo} i18n={option.option.i18n} parentOrg={option.option.parentOrgs} inDropDown />
                                    )}
                                    renderSelectedOption={(option) => (
                                        <CustomOptionRender name={option.label} abbreviation={option.abbreviation} logo={option.logo} i18n={option.i18n} />
                                    )}
                                    searchKeys={[
                                        `i18n.${i18nContext.getGenericLocale()}.name`,
                                        'parentOrgs',
                                        'abbreviation',
                                    ]}
                                    onOptionSelected={([ org ]) => {
                                        setShowOrg(org);
                                        return true;
                                    }}
                                    loadData={(from, extraData, spordleTable) => {
                                        switch (from){
                                            case 'CDM':
                                                return Promise.resolve(searchData)
                                            case 'SEARCH':
                                                if(exactMatch && extraData.searchValue){
                                                    const regex = new RegExp(`(?<='\\")(.*)(?=\\")`)
                                                    const formattedValue = extraData.searchValue.match(regex)
                                                    return Promise.resolve(searchData.filter((option) => (option.i18n?.[i18nContext.getGenericLocale()]?.name?.toLowerCase() || option.label.toLowerCase()) === formattedValue[1].toLowerCase()))
                                                }
                                                return Promise.resolve(searchData)
                                        }
                                    }}
                                />
                            </div>
                            <OrganizationTree
                                organizationClick={confirmOrgClick}
                                clickOnAll={clickOnAll}
                                showOrg={showOrg}
                                treeData={data}
                                multi={!!props.multi}
                                selectedOrgs={selectRef.current?.state.selectedOptions || []}
                            />
                        </ModalBody>
                        {props.multi &&
                            <ModalFooter>
                                <Button type='button' color='primary' onClick={() => { setShowTree(!showTree) }}>
                                    <Translate id='misc.close' />
                                </Button>
                            </ModalFooter>
                        }
                    </AnalyticsModal>
                </div>
            }
        </div>
    )
})

/**
 * Component that render an image and thew organization's name and the parent organization if needed
 */
const CustomOptionRender = ({ name, logo, abbreviation, parentOrg, inDropDown, i18n }) => (
    <div className='d-flex align-items-center'>
        <div className="d-flex justify-content-center align-items-center mr-2">
            <UserImg
                abbr={abbreviation}
                src={logo?.full_path}
                filePos={logo?.file_position}
                width={inDropDown ? '40' : '20'}
                height={inDropDown ? '40' : '20'}
                alt={name + ' logo'}
                className="p-1"
            />
        </div>
        <div>
            <span className='font-medium'><DisplayI18n field='name' defaultValue={name} i18n={i18n} /></span>
            {abbreviation &&
                <span className='text-muted ml-1'>({abbreviation})</span>
            }
            {parentOrg &&
                <div className='small text-muted'>
                    {parentOrg}
                </div>
            }
        </div>
    </div>
)

OrganizationSearch.propTypes = {
    fromIdentityRole: PropTypes.bool,
    fromPublicApi: PropTypes.bool,
    withFormik: PropTypes.bool,
    withTree: PropTypes.bool,
    organizationClick: PropTypes.func,
    selectedDefault: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    id: PropTypes.string.isRequired,
}

OrganizationSearch.displayName = 'OrganizationSearch';

OrganizationSearch.defaultProps = {
    fromApi: true,
};

CustomOptionRender.propTypes = {
    name: PropTypes.string.isRequired,
    parentOrg: PropTypes.string,
    inDropDown: PropTypes.bool,
    isSelected: PropTypes.bool,
    abbreviation: PropTypes.string,
}

export default OrganizationSearch;
