import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from 'react-dom';
import { FormattedNumber, useIntl } from "react-intl";
import { Button } from "reactstrap";
import { UAParser } from "ua-parser-js";
import { getIcon } from "../uploader/uploadHelpers";
import PortalLifeCycle from "./PortalLifeCycle";
import styles from "./FileViewer.module.scss";
import FileViewerPicker from "./FileViewerPicker";
import PDFFile from "./PDFFile";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

/**
 * @param {object} props
 * @param {string} props.type
 * @param {string|(() => Promise.<any>)|File} props.fullPath
 * @param {string} props.fileName
 * @param {string} props.creationDate
 * @param {boolean} props.isOpen
 * @param {Function} props.close
 * @param {Record.<string, string>} [props.createdBy]
 * @param {Record.<string, string>} [props.defaultImage]
 * @returns
 */
const FileViewer = ({ type, fullPath, fileName, createdBy, creationDate, isOpen, close, fromJIRA }) => {
    const intl = useIntl();
    const [ image, setImage ] = useState(fromJIRA ? fullPath : '');
    const [ fetchedType, setFetchedType ] = useState(type);
    const [ pageCount, setPageNumber ] = useState(1);
    const [ shownControls, setShownControls ] = useState(true);
    const containerRef = useRef(null);
    const mouseCooldown = useRef(null);
    const [ previewInfo, setPreviewInfo ] = useState({ isVisible: true, naturalWidth: 0, naturalHeight: 0 });
    const canDownload = !(fullPath instanceof File) && image;
    const isPhone = useRef(new UAParser().getDevice().type === 'mobile');
    const isLoading = useRef(false);

    const [ isPdf, setIsPdf ] = useState(type === 'application/pdf' || type === 'pdf');
    const isTextFile = Array.isArray(image);
    const isVideoFile = image?.type === 'video';
    const isAudioFile = image?.type === 'audio';
    // const isImageFile = typeof image === 'string' && !isPdf;

    useEffect(() => {
        setIsPdf(type === 'application/pdf' || type === 'pdf');
    }, [ type ])

    function hideControls(){
        setShownControls(false);
        clearTimeout(mouseCooldown.current);
    }

    function showControls(cooldown = 2000){
        setShownControls(true);
        clearTimeout(mouseCooldown.current);
        mouseCooldown.current = setTimeout(hideControls, cooldown);
    }

    function getInitialScale(){
        return isPhone.current ? 0.6 : 1;
    }

    /**
     * @param {number} initialScale
     * @returns {number[]}
     */
    function buildScalePoints(initialScale = 1){
        const jumpStep = 1.5;// 50%
        const points = [ initialScale ];
        let lowerPoint = initialScale, higherPoint = initialScale;
        for(let index = 0; index < 4; index++){ // 4 points lower scale and 4 points higher scale
            points.pushArray([ lowerPoint /= jumpStep, higherPoint *= jumpStep ]);
        }
        return points.sort();
    }

    const scalePoints = useRef(buildScalePoints(getInitialScale()));

    function onDocumentLoadSuccess({ numPages }){
        setPageNumber(numPages);
        setPreviewInfo({ isVisible: true });
    }

    function downloadFile(){
        const a = document.createElement('a');
        a.style = 'display: none;';
        a.download = fileName;
        a.href = isTextFile ? 'data:text/plain;charset=utf-8,' + encodeURIComponent(image.join('')) : image;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    function onPreviewFail(){
        setPreviewInfo({ isVisible: false });
    }

    // string when image and pdf
    const canZoomInOut = useCallback(() => image && previewInfo.isVisible && typeof image === 'string', [ image, previewInfo.isVisible, typeof image ]);

    function hasScroll(){
        if(!containerRef.current)
            return false;
        return containerRef.current.clientHeight < containerRef.current.scrollHeight || containerRef.current.clientWidth < containerRef.current.scrollWidth
    }

    function getVideoImageValue(content){
        return {
            type: 'video',
            src: URL.createObjectURL(content),
        }
    }
    function getAudioImageValue(content){
        return {
            type: 'audio',
            src: URL.createObjectURL(content),
        }
    }

    useEffect(() => {
        if(isOpen){
            if(isLoading.current)return;

            showControls(2000);

            if(image){
                // Do nothing -> Image has already been loaded
            }else if(typeof fullPath === "string"){
                isLoading.current = true;
                fetch(fullPath)
                    .then((r) => {
                        if(/text\/*/.test(type)){
                            return r.text().then((text) => text.split('\n'));
                        }else if(/video\/*/.test(type)){
                            return r.blob().then(getVideoImageValue);
                        }else if(/audio\/*/.test(type)){
                            return r.blob().then(getAudioImageValue);
                        }
                        return r.blob().then(URL.createObjectURL);
                    })
                    .then(setImage)
                    .catch(() => {
                        // Needed to show the "No preview" state of the FileViewer
                        // Will trigger the loading of the ressource to fail thus, showing no preview available
                        setImage('something_that_will_fail_the_download');
                    })
                    .finally(() => {
                        isLoading.current = false;
                    })
            }else if(typeof fullPath === 'function'){
                isLoading.current = true;
                fullPath()
                    .then((data) => {
                        setFetchedType(data.original_file_mime_type);
                        if(data.preview_full_path)
                            setIsPdf(true);
                        return fetch(data.preview_full_path || data.full_path)
                            .then((r) => {
                                if(/text\/*/.test(data.original_file_mime_type)){
                                    return r.text().then((text) => text.split('\n'));
                                }else if(/video\/*/.test(data.original_file_mime_type)){
                                    return r.blob().then(getVideoImageValue);
                                }else if(/audio\/*/.test(data.original_file_mime_type)){
                                    return r.blob().then(getAudioImageValue);
                                }
                                return r.blob().then(URL.createObjectURL);
                            })
                    })
                    .then(setImage)
                    // .then(() => setImage('something_that_will_fail_the_download'))
                    .catch(() => {
                        // Needed to show the "No preview" state of the FileViewer
                        // Will trigger the loading of the ressource to fail thus, showing no preview available
                        setImage('something_that_will_fail_the_download');
                    })
                    .finally(() => {
                        isLoading.current = false;
                    })
            }else if(/text\/*/.test(fullPath.type)){ // Is text file
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                    setImage(reader.result.split('\n'));
                })
                reader.addEventListener('error', onPreviewFail)
                reader.readAsText(fullPath);
            }else if(/video\/*/.test(fullPath.type)){
                setImage(getVideoImageValue(fullPath));
            }else if(/audio\/*/.test(fullPath.type)){
                setImage(getAudioImageValue(fullPath));
            }else{
                setImage(URL.createObjectURL(fullPath));
            }
        }
    }, [ fullPath, isOpen ]);

    useEffect(() => {
        const handleEsc = (event) => {
            if(event.keyCode === 27){
                close();
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    return isOpen && createPortal(
        <TransformWrapper
            disabled={!image || !previewInfo.isVisible || isTextFile || isVideoFile || isAudioFile}
            minScale={scalePoints.current[0]}
            maxScale={scalePoints.current[scalePoints.current.length - 1]}
            initialScale={1}
            limitToBounds
        >
            {(reactPinchPanZoom) => (
                <aside
                    ref={containerRef}
                    className={styles.Container}
                    onMouseMove={() => { showControls(); }}
                    role='alertdialog'
                    aria-describedby='FileViewerHeaderInfo'
                    tabIndex={0}// Makes the onKeyDown listener works
                    onKeyDown={(e) => {
                        if(e.ctrlKey){
                            // CTRL + e.code
                            switch (e.code){
                                case 'Equal':
                                case 'NumpadEqual':
                                case 'NumpadAdd':
                                    if(canZoomInOut())
                                        reactPinchPanZoom.zoomIn();
                                    e.preventDefault();
                                    break;
                                case 'Minus':
                                case 'NumpadSubtract':
                                    if(canZoomInOut())
                                        reactPinchPanZoom.zoomOut();
                                    e.preventDefault();
                                    break;
                                case 'Semicolon':
                                case 'IntlRo':
                                case 'IntlYen':
                                    e.preventDefault();
                                    break;
                                default:
                                    break;
                            }
                        }
                    }}
                >
                    <PortalLifeCycle
                        container={containerRef}
                        resetScalePoints={() => { scalePoints.current = buildScalePoints(1) }}
                    />
                    <header className={stringBuilder(styles.Bar, styles.BarTop, { [styles.IsVisible]: shownControls })}>
                        <div style={{ fontSize: 50 }} className={stringBuilder(getIcon(fetchedType), "text-muted line-height-1 mr-2")} />
                        <div className={styles.BarTopFileInfo} id='FileViewerHeaderInfo'>
                            <div role='heading' className={styles.BarTopFileName}>
                                {fileName}
                            </div>
                            {createdBy &&
                                <div role='note' className="small">{createdBy.name} {createdBy.family_name}</div>
                            }
                            <div role='note' className="small">
                                {creationDate}
                            </div>
                        </div>
                        <div className={styles.BarTopBtns}>
                            {canDownload &&
                                <Button
                                    className="mr-2" color="secondary" type="button"
                                    onClick={downloadFile}
                                    onDoubleClick={(e) => { e.stopPropagation() }}// Prevents zoom on double click
                                    title={intl.formatMessage({ id: 'misc.download' })}
                                    onBlur={() => { containerRef.current.focus(); }}
                                >
                                    <i className="font-22 mdi mdi-download" />
                                </Button>
                            }
                            <Button
                                color="dark" type="button"
                                onClick={(e) => { e.stopPropagation(); close(); }}
                                onDoubleClick={(e) => { e.stopPropagation() }}// Prevents zoom on double click
                                title={intl.formatMessage({ id: 'misc.close' })}
                                onBlur={() => { containerRef.current.focus(); }}
                            >
                                <i className="font-22 mdi mdi-window-close" />
                            </Button>
                        </div>
                    </header>
                    <TransformComponent
                        wrapperClass={stringBuilder(
                            canZoomInOut() ? styles.PreviewContainerZoom : styles.PreviewContainer,
                            {
                                [styles.WithText]: isTextFile,
                                'flex-center': !image || !previewInfo.isVisible,
                            },
                        )}
                    >
                        {isPdf ?
                            image ?
                                <PDFFile
                                    image={image}
                                    fileLoading={<FileLoading type={fetchedType} fileName={fileName} />}
                                    errorView={<PreviewFailed canDownload={canDownload} downloadFile={downloadFile} type='pdf' />}
                                    onDocumentLoadSuccess={onDocumentLoadSuccess}
                                    onPreviewFail={onPreviewFail}
                                    pageCount={pageCount}
                                    containerRef={containerRef}
                                    reactPinchPanZoom={reactPinchPanZoom}
                                    resetScalePoints={(initialScale) => { scalePoints.current = buildScalePoints(initialScale) }}
                                />
                                :
                                <FileLoading type={fetchedType} fileName={fileName} />
                            :
                            !image ?
                                <FileLoading type={fetchedType} fileName={fileName} />
                                : previewInfo.isVisible ?
                                    <FileViewerPicker
                                        fileData={image}
                                        fileName={fileName}
                                        onPreviewFail={onPreviewFail}
                                        setPreviewInfo={setPreviewInfo}
                                        onLoad={(scaleValue) => {
                                            scalePoints.current = buildScalePoints(scaleValue);
                                            reactPinchPanZoom.centerView(scaleValue, 0);
                                        }}
                                        containerRef={containerRef}
                                        canDownload={canDownload}
                                        downloadFile={downloadFile}
                                        fileType={fetchedType}
                                        hasScroll={hasScroll}
                                    />
                                    :
                                    <PreviewFailed type={fetchedType || type} canDownload={canDownload} downloadFile={downloadFile} />
                        }
                    </TransformComponent>
                    {canZoomInOut() &&
                        <footer className={stringBuilder(styles.Bar, styles.BarBottom, { [styles.IsVisible]: shownControls })}>
                            <Button
                                color="dark" type="button" className="mr-1"
                                onClick={() => { reactPinchPanZoom.zoomOut() }}
                                title={intl.formatMessage({ id: 'components.fileViewer.zoom.out' })}
                                aria-controls='FileViewerZoomLevel'
                                onDoubleClick={(e) => { e.stopPropagation() }}// Prevents zoom on double click
                                onBlur={() => { containerRef.current.focus(); }}
                            >
                                <i className="font-22 mdi mdi-magnify-minus-outline" />
                            </Button>
                            <Button
                                color="dark" type="button" className="ml-1"
                                onClick={() => { reactPinchPanZoom.zoomIn() }}
                                title={intl.formatMessage({ id: 'components.fileViewer.zoom.in' })}
                                aria-controls='FileViewerZoomLevel'
                                onDoubleClick={(e) => { e.stopPropagation() }}// Prevents zoom on double click
                                onBlur={() => { containerRef.current.focus(); }}
                            >
                                <i className="font-22 mdi mdi mdi-magnify-plus-outline" />
                            </Button>
                            <div className="text-white mt-2" id='FileViewerZoomLevel'><FormattedNumber value={reactPinchPanZoom.state.scale} style="percent" /></div>
                        </footer>
                    }
                </aside>
            )}
        </TransformWrapper>
        , document.body,
    )
}

const FileLoading = ({ type, fileName }) => {
    const { formatMessage } = useIntl();
    return (
        <i
            className={stringBuilder(getIcon(type), styles.NoPreview, styles.Loading)}
            role='status'
            aria-label={formatMessage({ id: 'components.fileViewer.accessibility.loading.label' }, { fileName })}
        />
    );
}

export const PreviewFailed = ({ canDownload, downloadFile, type }) => {
    return (
        <div className={styles.NoPreview} role='status'>
            <div className={stringBuilder(getIcon(type), styles.Icon)} />
            <div className="font-bold h1 text-body">
                <Translate id='components.fileViewer.noPreview.title' />
            </div>
            <Translate id='components.fileViewer.noPreview.text' />
            {canDownload &&
                <div>
                    <div className="mb-3">
                        <Translate id='components.fileViewer.noPreview.downloadAvailable' />
                    </div>
                    <Button type="button" onClick={downloadFile}><Translate id='components.fileViewer.download' /><i className="mdi mdi-download ml-1" /></Button>
                </div>
            }
        </div>
    )
}

export default FileViewer
