import Translate from '@spordle/intl-elements';
import { Link } from "react-router-dom";
import { useContext, useState } from 'react';
import {
    ListGroup,
    ListGroupItem
} from "reactstrap";
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import SidePanel from '../../../../components/sidePanel/SidePanel';
import { APPROVED, DisplayCategory, PENDING, REJECTED, CANCEL, getStatus } from '../../TeamHelpers';
import { ReportsContext } from '../../../../contexts/ReportsContext';
import { IdentityRolesContext } from '../../../../contexts/IdentityRolesContext';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { I18nContext } from '../../../../contexts/I18nContext';
import { triggerDownload } from '../../../../components/uploader/uploadHelpers';
import { fail } from "@spordle/toasts";
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import TeamBulkUpdateModal from './TeamBulkUpdateModal/TeamBulkUpdateModal';

const TeamSearchSidepanelMulti = ({ handleDelete, handleExport, teams, ...props }) => {
    const teamsArr = teams.map((team) => team.team_id);

    const reportsContext = useContext(ReportsContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const organizationContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext);

    const [ modalAction, setModalAction ] = useState('');
    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const toggle = () => setModalIsOpen(!modalIsOpen);
    const openModal = (action) => {
        setModalAction(action);
        toggle();
    }

    // handles PDF exporting with multi
    const handleRosterExport = () => {
        const reportType = 'team_rosters';
        // gets all report types
        reportsContext.getReports({ identity_role_id: identityRolesContext.identity_role_id })
            .then(async(report_type) => {
                // grabbing the correct report type ID with the find
                const reportTypeId = report_type.find((type) => type.code === reportType).report_id;
                // getting the report itself for the line count
                const reports = await reportsContext.getTeamRosterReport({ team_id: teamsArr })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    })

                // creates the exportable report (adds to library and creates the download link as well as generating the report)
                if(reports.report_count > 0){
                    const data = await reportsContext.createExportableReport({ // Total count limit at 10 for team books (default 300 for reports)
                        organisation_id: organizationContext.organisation_id,
                        total_count: teams.length || 0,
                        language_code: i18nContext.getGenericLocale(),
                        report_id: reportTypeId,
                        requested_by: identityRolesContext.identity.identity_id,
                        request_parameter: JSON.stringify({ team_id: teamsArr.toString(), report_type: 'PDF' }),
                    }, true).catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    })

                    if(data.download_link){
                        triggerDownload(data.download_link);
                    }
                }else{
                    fail({ msg: 'teams.teamSearch.export.emptyTeams' });
                }

            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    return (
        <>
            <TeamBulkUpdateModal
                isOpen={modalIsOpen}
                toggle={toggle}
                action={modalAction}
                teams={teams}
                toggleAfterSuccess={() => {
                    props.tableRef.refreshTable();
                    props.toggle();
                }}
            />

            <SidePanel.Header>
                <div className='d-flex mb-2'>
                    <SidePanel.ToggleButton />
                    <SidePanel.ActionsMenu id='teamSearch-multi-actions-btn'>
                        {/* Submit for approval */}
                        <SidePanel.MenuAction
                            componentCode="teams"
                            componentPermissionCode="submit_team_approval"
                            action="ADD"
                            skipAccess
                            id={'teamSearch-multi-actions-submit-approval'}
                            onClick={() => openModal(PENDING)}
                        >
                            <Translate id='teams.teamSearch.sideTable.actionsMenu.submit' />
                        </SidePanel.MenuAction>

                        {/* Approve team */}
                        <SidePanel.MenuAction
                            id={'teamSearch-multi-actions-approve'}
                            componentCode="teams"
                            componentPermissionCode="teams_approbation"
                            action="EDIT"
                            skipAccess
                            onClick={() => openModal(APPROVED)}
                        >
                            <Translate id='teams.teamSearch.sideTable.actionsMenu.approve' />
                        </SidePanel.MenuAction>

                        {/* Reject */}
                        <SidePanel.MenuAction
                            id={'teamSearch-multi-actions-reject'}
                            componentCode="teams"
                            componentPermissionCode="teams_approbation"
                            action="EDIT"
                            skipAccess
                            onClick={() => openModal(REJECTED)}
                        >
                            <Translate id='teams.teamSearch.sideTable.actionsMenu.reject' />
                        </SidePanel.MenuAction>

                        {/* Cancel Approbation */}
                        <SidePanel.MenuAction
                            id={'teamSearch-multi-actions-cancel-approval'}
                            componentCode="teams"
                            componentPermissionCode="submit_team_approval"
                            action="ADD"
                            skipAccess
                            onClick={() => openModal(CANCEL)}
                        >
                            <Translate id='teams.teamSearch.sideTable.actionsMenu.cancel' />
                        </SidePanel.MenuAction>

                        <SidePanel.MenuDelete
                            id={'teamSearch-multi-actions-delete-team'}
                            componentCode="teams"
                            componentPermissionCode="teams_profile"
                            action="DELETE"
                            onConfirm={handleDelete}
                            translateModalMsg
                            modalMsg='teams.teamSearch.sideTable.delete.multiple.msg'
                            modalContent={
                                <div className="w-50 mx-auto mt-3">
                                    {teams.map((team) => (
                                        <UserDisplay key={team.team_id} className="mb-2">
                                            <UserDisplay.Container>
                                                <UserImg
                                                    abbr={team.abbreviation || team.short_name || team.name}
                                                    src={team.logo?.full_path}
                                                    filePos={team.logo?.file_position}
                                                    alt={team.name}
                                                    width={40}
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container>
                                                <UserDisplay.Title>{team.name}</UserDisplay.Title>
                                                {/* <Link to="/team/profile">{team.team_id}</Link> */}
                                                <UserDisplay.Subtitle>
                                                    <DisplayCategory category={team.team_category} />
                                                </UserDisplay.Subtitle>
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    ))}
                                </div>
                            }
                        />
                        <SidePanel.MenuAction divider />
                        {/* PDF REPORT */}
                        <SidePanel.MenuAction
                            id={'teamSearch-multi-actions-export-pdf'}
                            onClick={handleRosterExport} disabled={teamsArr.length > 10}
                        >
                            <i className='fas fa-file-pdf mr-1' /><Translate id='teams.profile.roster.button.export.pdf' />
                            {teamsArr.length > 10 &&
                                <div className='small text-muted text-wrap'><Translate id='teams.teamSearch.export.maxTenTeams' /></div>
                            }
                        </SidePanel.MenuAction>
                    </SidePanel.ActionsMenu>
                </div>
                <SidePanel.Title>{teams.length} <Translate id='teams.teamSearch.sideTable.selectedTeams' /></SidePanel.Title>
            </SidePanel.Header>
            <ListGroup>
                {teams.map((team) => (
                    <ListGroupItem key={team.team_id}>
                        <Link to={`/teams/profile/${team.team_id}`}>
                            <UserDisplay>
                                <UserDisplay.Container>
                                    <UserImg
                                        abbr={team.abbreviation || team.short_name || team.name}
                                        src={team.logo?.full_path}
                                        filePos={team.logo?.file_position}
                                        alt={team.name}
                                        width={40}
                                    />
                                </UserDisplay.Container>
                                <UserDisplay.Container className="w-100">
                                    <UserDisplay.Title className="d-flex justify-content-between">
                                        <div>{team.name}</div>
                                        <div>{getStatus(team.team_status)}</div>
                                    </UserDisplay.Title>
                                    {/* <Link to="/team/profile">{team.team_id}</Link> */}
                                    <UserDisplay.Subtitle>
                                        <DisplayCategory category={team.team_category} />
                                    </UserDisplay.Subtitle>
                                </UserDisplay.Container>
                            </UserDisplay>
                        </Link>
                    </ListGroupItem>
                ))}
            </ListGroup>

        </>
    );
}

export default TeamSearchSidepanelMulti;