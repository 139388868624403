import { FormikError } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Fragment, useContext } from 'react';
import { Button, ListGroup, ModalBody,
    ModalFooter,
    ModalHeader } from "reactstrap";
import { array, object, string } from 'yup';
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import { releaseMemberPotentialDuplicates } from '../../../../api/client/potentialMemberDuplicates';
import CrossFade from '../../../../components/crossFade/CrossFade';
import FormikModalForm from '../../../../components/formik/FormikModalForm';
import { MembersContext } from '../../../../contexts/MembersContext';
import PotentialMemberListItem from '../sidePanel/PotentialMemberListItem';
import FormikApiError from '../../../../components/formik/FormikApiError';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import ManagePotentialDuplicateSuccess from './ManagePotentialDuplicateSuccess';

const PotentialMemberDuplicatesModal = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName='memberPermanentReleaseModal'>
            <PotentialMemberDuplicatesModalInner {...props} />
        </AnalyticsModal>
    )
}

const PotentialMemberDuplicatesModalInner = ({ toggle, potentialDuplicates = [], onSuccess }) => {
    const { mergeMembers } = useContext(MembersContext);

    const getSplitProfiles = (values) => {
        const memberIdToKeep = values.keep;

        return values.duplicates.reduce((splitDuplicated, duplicate) => {
            if(duplicate.member_id === memberIdToKeep){
                splitDuplicated.toKeep = duplicate;
            }else if(duplicate.result === "MERGE"){
                splitDuplicated.toMergeList.push(duplicate);
            }else{
                splitDuplicated.toIgnoreList.push(duplicate);
            }

            return splitDuplicated;
        }, { toKeep: null, toMergeList: [], toIgnoreList: [] })
    }


    const handleMemberMerges = async(toKeep, toMergeList, toReleaseList) => {
        const asyncReduce = (list, promiseCallback) => {
            return list.reduce(async(results, member) => {
                const currentResults = await results;
                const result = await promiseCallback(member)
                    .then(() => ({ success: true }))
                    .catch((e) => {
                        console.error(e);
                        return { error: e, member: member };
                    });

                currentResults.push(result);
                return currentResults;
            }, Promise.resolve([]))
        }

        // Cannot be a promise.all, because we need to wait until the merge is done to merge another
        const merged = await asyncReduce(
            toMergeList,
            (toMerge) => mergeMembers({
                member_to_keep_id: toKeep.member_id,
                member_to_delete_id: toMerge.member_id,
            }),
        )

        // Cannot be a promise.all, because we need to wait until the release is done to release another
        const released = await asyncReduce(
            toReleaseList,
            (toRelease) => releaseMemberPotentialDuplicates({
                member_to_keep_id: toKeep.member_id,
                member_to_release_id: toRelease.member_id,
                released_at: new Date().toISOString().split('T')[0],
            }),
        );

        return [ ...merged, ...released ];
    }

    return (
        <FormikModalForm
            toggle={toggle}
            initialValues={{
                keep: "",
                duplicates: potentialDuplicates.flatMap((potentialDuplicate) => {
                    return ([
                        {
                            ...potentialDuplicate.member,
                            member_potential_duplicate_id: potentialDuplicate.member_potential_duplicate_id,
                            result: "",
                        },
                        ...potentialDuplicate.duplicate_members.map((duplicate) => ({
                            ...duplicate.member,
                            member_potential_duplicate_id: duplicate.member_potential_duplicate_id,
                            result: "",
                        })),
                    ])
                }),
            }}
            validationSchema={object().shape({
                keep: string().required(<Translate id="tasks.memberDuplicates.manage.missingKeep" />),
                duplicates: array().of(object().shape({
                    result: string().test({
                        message: <Translate id="tasks.memberDuplicates.manage.missingChoice" />,
                        name: 'shouldBeRequired',
                        test: function(val){
                            const rootValues = this.options.from[this.options.from.length - 1].value;
                            return (rootValues.keep === this.parent.member_id) || !!val;
                        },
                    }),
                })),
            })}
            onSubmit={async(values, { showSuccess, showError }) => {
                const { toKeep, toMergeList, toIgnoreList } = getSplitProfiles(values);

                return handleMemberMerges(toKeep, toMergeList, toIgnoreList)
                    .then((results) => {
                        const errors = results.filter(({ error }) => !!error);
                        showSuccess({ fails: errors });
                        onSuccess?.(values.keep);
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                            showError(e);
                        }
                    });
            }}
            successText={() => <ManagePotentialDuplicateSuccess getSplitProfiles={getSplitProfiles} />}
        >
            {(formik) => (
                <>
                    <ModalHeader toggle={toggle}>
                        <Translate id="tasks.memberDuplicates.manage.title" />
                    </ModalHeader>
                    <ModalBody>
                        <div className="text-center mb-3 h5">
                            <CrossFade isVisible={!formik.values.keep}>
                                <p className="mb-0">
                                    <Translate id="tasks.memberDuplicates.manage.step1" />
                                </p>
                            </CrossFade>
                            <CrossFade isVisible={!!formik.values.keep}>
                                <p className="mb-0">
                                    <Translate id="tasks.memberDuplicates.manage.step2" />
                                </p>
                            </CrossFade>
                        </div>
                        <ListGroup flush className="mb-2 border-bottom">
                            {potentialDuplicates.map((potentialDuplicate, i) => (
                                <Fragment key={potentialDuplicate.member.member_id}>
                                    <PotentialMemberListItem
                                        member={potentialDuplicate.member}
                                        index={i}
                                    />
                                    {potentialDuplicate.duplicate_members.map((duplicate, j) => (
                                        <PotentialMemberListItem
                                            key={duplicate.member_potential_duplicate_id}
                                            member={duplicate.member}
                                            index={i + 1 + j}
                                        />
                                    ))}
                                </Fragment>
                            ))}
                        </ListGroup>
                        <FormikError name="keep" />
                        <FormikApiError />
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={formik.isSubmitting || !formik.values.keep} type="submit" color="primary"><Translate id="misc.submit" /></Button>
                        <Button disabled={formik.isSubmitting} type="button" color="primary" onClick={toggle} outline><Translate id="misc.cancel" /></Button>
                    </ModalFooter>
                </>
            )}
        </FormikModalForm>
    );
}

export default PotentialMemberDuplicatesModal;