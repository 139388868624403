import { FormikCurrencyInput, FormikError, FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { fail, success } from "@spordle/toasts";
import { FieldArray, useFormikContext } from "formik"
import { useContext, useLayoutEffect, useState } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { array, object, string, mixed } from "yup";
import CollapsibleCard from "../../../components/collapsibleCard/CollapsibleCard";
import CrossFade from "../../../components/crossFade/CrossFade";
import Required from "../../../components/formik/Required";
import BtnDisplay from "../../../components/input/BtnDisplay";
import { I18nContext } from "../../../contexts/I18nContext";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { RegistrationCategoriesContext } from "../../../contexts/RegistrationCategoriesContext";
import generatePassword from "../../../helpers/passwordGenerator";
import { DisplayCategory } from "../TeamHelpers";

const fieldName = 'batchCreateTeams';
const maxTeams = 10;

const BatchCreateForm = ({ defaultCategory }) => {
    const formik = useFormikContext();

    const emptyTeam = {
        name: '',
        shortName: '',
        categoryId: defaultCategory ? defaultCategory.team_category_id : '',
        homeColor: '',
        awayColor: '',
        teamFee: '',
    }

    return (
        <>
            <FieldArray name={fieldName}>
                {(helper) => (
                    <>
                        {formik.values[fieldName].map((team, index) => (
                            <ListItem
                                key={team.id}
                                handleRemove={helper.handleRemove(index)}
                                hasErrors={!!formik.errors[fieldName]?.[index]}
                                index={index}
                                fieldNamePrefix={fieldName + '.' + index + '.'}
                                showDuplicate={formik.values[fieldName].length < maxTeams}
                                handleDuplicate={helper.handlePush({ ...formik.values[fieldName][index], id: generatePassword() })}
                                name={formik.values[fieldName][index].name}
                            />
                        ))}
                        <CrossFade isVisible={formik.values[fieldName].length < maxTeams}>
                            <BtnDisplay
                                onClick={helper.handlePush({ ...emptyTeam, id: generatePassword() })}
                                title={<Translate id='teams.teamBatchCreate.addButon.title' />}
                                subtitle={<Translate id='teams.teamBatchCreate.addButon.subtitle' />}
                                iconSize={50}
                                icon="teams"
                            />
                        </CrossFade>
                        {!Array.isArray(formik.errors[fieldName]) &&
                            <FormikError name={fieldName} />
                        }
                    </>
                )}
            </FieldArray>
        </>
    )
}

const ListItem = ({ handleRemove, hasErrors, index, fieldNamePrefix, handleDuplicate, name, showDuplicate }) => {

    const i18n = useContext(I18nContext);
    const registrationCategoriesContext = useContext(RegistrationCategoriesContext);
    const orgContext = useContext(OrganizationContext);

    const [ crossFadeOpen, setCrossFadeOpen ] = useState(false);

    const hasError = hasErrors;

    useLayoutEffect(() => {
        setCrossFadeOpen(true)
    }, [])

    return (
        <CrossFade isVisible={crossFadeOpen} onExited={() => handleRemove()}>
            <CollapsibleCard
                className="mb-3"
                error={hasError}
                initOpen
                title={
                    <>
                        <Translate id='teams.teamBatchCreate.teamCard.title' values={{ index: index + 1 }} />
                        {name &&
                            <span className="text-muted ml-2">({name})</span>
                        }
                    </>
                }
                extraInfo={
                    <>
                        {showDuplicate &&
                            <button
                                onClick={(e) => {
                                    // to prevent the card from opening or closing
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleDuplicate()
                                }}
                                type="button" className="reset-btn mr-3"
                            >
                                <i className="mdi mdi-content-copy" />
                            </button>
                        }
                        <button onClick={() => setCrossFadeOpen(false)} type="button" className="reset-btn text-danger">
                            <i className="mdi mdi-delete" />
                        </button>
                    </>
                }
            >
                <Row form>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='name' className='text-muted'><Translate id='teams.profile.teamInfos.general.teamName' /> <Required /></Label>
                            <FormikInputText id='name' name={fieldNamePrefix + 'name'} />
                        </FormGroup>
                    </Col>
                    <Col sm="3">
                        <FormGroup>
                            <Label for='shortName' className='text-muted'><Translate id='teams.profile.teamInfos.general.shortName' /> <Required /></Label>
                            <FormikInputText id='shortName' name={fieldNamePrefix + 'shortName'} />
                        </FormGroup>
                    </Col>
                    <Col sm="3">
                        <FormGroup>
                            <Label for='teamFee' className='text-muted'><Translate id='teams.profile.teamInfos.general.teamFee' /></Label>
                            <FormikCurrencyInput id='teamFee' name={fieldNamePrefix + 'teamFee'} allowNegative={false} />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='categoryId' className='text-muted'><Translate id='teams.profile.teamInfos.general.category' /> <Required /></Label>
                            <FormikSelect
                                id='categoryId'
                                name={fieldNamePrefix + 'categoryId'}
                                clearable
                                searchKeys={[
                                    `category.division.i18n.${i18n.getGenericLocale()}.short_name`,
                                    `category.class.i18n.${i18n.getGenericLocale()}.short_name`,
                                ]}
                                renderOption={({ option }) => (
                                    <>
                                        <div className="mb-0">
                                            <DisplayCategory category={option.category} short />
                                        </div>
                                        {option.category?.gender && <div className="small">{<Translate id={`form.fields.gender.${option.category?.gender.toLowerCase()}`} />}</div>}
                                    </>
                                )}
                                renderSelectedOption={(option) => <DisplayCategory category={option.category} short />}
                                loadData={(from) => {
                                    switch (from){
                                        case 'CDM':
                                            return registrationCategoriesContext.getRegistrationCategories(orgContext.organisation_id)
                                                .then((allCat) => {
                                                    return allCat
                                                        .filter((cat) => cat.active === "1")
                                                        .sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))
                                                        .map((category) => {
                                                            return {
                                                                value: category.team_category_id,
                                                                label: `form.fields.gender.${category.gender.toLowerCase()}`,
                                                                translateLabel: true,
                                                                category: category,
                                                            };
                                                        });
                                                })
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm='6'>
                        <Row form>
                            <Col sm='6'>
                                <FormGroup>
                                    <Label for='homeColor' className='text-muted'><Translate id='teams.profile.teamInfos.general.colorHome' /></Label>
                                    <FormikInputText id='homeColor' name={fieldNamePrefix + 'homeColor'} />
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup>
                                    <Label for='awayColor' className='text-muted'><Translate id='teams.profile.teamInfos.general.colorAway' /></Label>
                                    <FormikInputText id='awayColor' name={fieldNamePrefix + 'awayColor'} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CollapsibleCard>
        </CrossFade>
    )
}

const BatchCreateTitle = () => {
    const formik = useFormikContext();

    return (
        <Translate id='teams.teamBatchCreate.cardSectionTitle' values={{ count: formik.values[fieldName].length, max: maxTeams }} />
    )
}

const batchCreateInitialValues = {
    [fieldName]: [],
}

const getBatchCreateValidationSchema = (withMin) => {
    return {
        [fieldName]: array().min(withMin ? 1 : 0, <Translate id='teams.teamBatchCreate.teamCard.min' />).of(object().shape({
            name: string().required(<Translate id='teams.profile.teamInfos.general.teamName.required' />),
            shortName: string().required(<Translate id='teams.profile.teamInfos.general.shortName.required' />),
            homeColor: string(),
            awayColor: string(),
            categoryId: string().required(<Translate id='teams.profile.teamRoster.activeRoster.add.validation.category' />),
            teamFee: mixed(),
        })),
    }
}

const batchCreateOnSubmit = (values, teamsContext) => {

    return Promise.all(values[fieldName].map((team) => {
        const newValues = {
            name: team.name,
            short_name: team.shortName,
            home_color: team.homeColor,
            away_color: team.awayColor,
            team_category_id: team.categoryId,
        }

        if(team.teamFee)
            newValues.team_fee = (team.teamFee * 100).toFixed(0)

        return new Promise((resolve) => {
            // resolve no matter what happens, we dont want to block the user because some teams might be created and some won't
            // catch the error and return the team name to show in the toast telling the user which team wasn't created
            teamsContext.createTeam(newValues)
                .then(() => resolve({ created: true, name: newValues.name }))
                .catch(() => resolve({ created: false, name: newValues.name }))
        })
    }))
        .then((promises) => {
            const { createdTeams, uncreatedTeams } = promises.reduce(({ createdTeams, uncreatedTeams }, promise) => {
                if(promise.created){
                    createdTeams.push(promise.name)
                }else{
                    uncreatedTeams.push(promise.name)
                }
                return {
                    createdTeams: createdTeams,
                    uncreatedTeams: uncreatedTeams,
                }
            }, {
                createdTeams: [],
                uncreatedTeams: [],
            })

            if(createdTeams.length > 0){
                success({
                    msg: 'teams.teamBatchCreate.teamCard.success',
                    info:
                        <ul className="mb-0">
                            {createdTeams.map((teamName, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <li key={index}>{teamName}</li>
                            ))}
                        </ul>,
                    skipMsgTranslate: false,
                    skipInfoTranslate: true,
                })
            }
            if(uncreatedTeams.length > 0){
                fail({
                    permanent: true,
                    msg: 'teams.teamBatchCreate.teamCard.fail',
                    info:
                        <ul className="mb-0">
                            {uncreatedTeams.map((teamName, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <li key={index}>{teamName}</li>
                            ))}
                        </ul>,
                    skipMsgTranslate: false,
                    skipInfoTranslate: true,
                })
            }
        })
}

export{ BatchCreateForm, batchCreateInitialValues, getBatchCreateValidationSchema, batchCreateOnSubmit, BatchCreateTitle };