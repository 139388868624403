export default{
    "task.navigation.unsanctionedClubs" : "Organisation non-confirmée",

    "task.unconfirmedOrg.list.organization"    : "Organisation",
    "task.unconfirmedOrg.list.submission.date" : "Date de soumission",

    "task.unconfirmedOrg.sidepanel.manage.approval" : "Gérer l'approbation",
    "task.unconfirmedOrg.sidepanel.submission.date" : "Date de soumission",

    "task.unconfirmedOrg.modal.title"           : "Gérer l'approbation de l'organisation",
    "task.unconfirmedOrg.modal.multi.collapse"  : "{count} organisations sélectionnées",
    "task.unconfirmedOrg.modal.multi.info"      : "Toutes les organisations seront affecté par le changement de statut.",
    "task.unconfirmedOrg.modal.success"         : "Le statut a été mis à jour avec succès.",
    "task.unconfirmedOrg.modal.note.multi.info" : "Cette note sera appliquée sur toutes les organisations.",
    "task.unconfirmedOrg.modal.note.required"   : "Une note est nécessaire pour refuser une organisation.",

}