import { useSpordleTable } from '@spordle/datatables';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useContext } from 'react';
import { Row } from 'reactstrap';
import { AxiosCancelReportCalls } from '../../../api/CancellableAPI';
import FormikObserver from '../../../components/formik/FormikObserver';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import HasMoreRowsDownload from '../../../components/reports/HasMoreDownloads';
import AddFilter from './filterComponents/manageFilters/AddFilter';
import EngineFilterSwitch from './filterComponents/switchComponents/EngineFilterSwitch';
import { filterHasValue, getReportQueryParamsFromFilters } from './reportsEngineHelpers';
import { PeriodsContext } from '../../../contexts/contexts';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { array, mixed, number, object, string } from 'yup';
import Translate from '@spordle/intl-elements';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { ReportsContext } from '../../../contexts/ReportsContext';
import useSavedSearch from '../../../components/customHooks/useSavedSearch';
import SavedSearchCue from '../../../components/savedSearchCue/SavedSearchCue';

const ReportsEngineFiltersCard = ({ formikRef, setFiltersHaveChanged, setFiltersCardLoaded, hasMoreRows, customReportId, isOpenHandlers, setCustomFormId, customFormId }) => {
    const { filterChange, generateId } = useSpordleTable();
    const { organisation_id } = useContext(OrganizationContext);
    const { selectedPeriod } = useContext(PeriodsContext);
    const { federation, identity_role_id } = useContext(IdentityRolesContext);
    const { currentReport } = useContext(ReportsContext);
    const periodsContext = useContext(PeriodsContext);
    const location = useLocation()

    const { savedSearch, clearSavedSearch, saveSearch } = useSavedSearch(`reportsEngine-v1-${currentReport?.code}-${organisation_id}-${identity_role_id}-${periodsContext.selectedPeriod.period_id}`);

    // sets when loaded initial filters so excel exports don't fail without clicking search
    useEffect(() => {
        // filterChange -> we should do a  filter change here for initial load (if the report has the correct initial values) so that excel exports without clicking search don't fail
        setFiltersCardLoaded(true);
    }, [])

    // array of refs, must set the refs for each filter code on initial render
    const filterRefs = useRef(
        Object.assign({}, ...currentReport.filters.map((filter) => ({ [filter.code]: null }))),
    );

    const getOrgInit = (initVal) => {
        switch (initVal){
            case "{{CURRENT}}":
                return organisation_id;
            case "{{FEDERATION}}":
                return federation.organisation_id;
            default:
                return initVal || "";
        }
    }

    const getInitialValues = (allFilters, clearValues) => {
        const initValues = {};

        if(currentReport.preset_filter && !clearValues){
            // grabs pre-filled filters from a custom report preset_filter
            try{
                const presetFilter = JSON.parse(currentReport.preset_filter)
                allFilters.forEach((filter) => {
                    switch (filter.type){
                        case 'ORGANIZATION_SELECT':
                        case 'PERIOD_SELECT':
                        case 'GENDER_SELECT':
                        case 'SELECT':
                        case 'NUMBER':
                            initValues[filter.code] = presetFilter[filter.code] || '';
                            break;
                        case 'TIME':
                        case 'DATE':
                            initValues[filter.code] = presetFilter[filter.code] ? moment(presetFilter[filter.code]) : '';
                            break;
                        case 'MULTI_ORGANIZATION_SELECT':
                            initValues[filter.code] = presetFilter[filter.code] ? presetFilter[filter.code]?.split(',') : [];
                            break;
                        case 'MULTI_SELECT':
                        case 'GENDER_MULTI_SELECT':
                            initValues[filter.code] = presetFilter[filter.code] ? presetFilter[filter.code]?.split(',') : [];
                            break;
                        case 'NUMBER_BETWEEN':
                            initValues[`start_${filter.code}`] = presetFilter[filter.code]?.split(',')[0] ? presetFilter[filter.code]?.split(',')[0] : '';
                            initValues[`end_${filter.code}`] = presetFilter[filter.code]?.split(',')[1] ? presetFilter[filter.code]?.split(',')[1] : '';
                            break;
                        case 'DATE_BETWEEN':
                            initValues[`start_${filter.code}`] = presetFilter[filter.code]?.split(',')[0] ? moment(presetFilter[filter.code]?.split(',')[0]) : '';
                            initValues[`end_${filter.code}`] = presetFilter[filter.code]?.split(',')[1] ? moment(presetFilter[filter.code]?.split(',')[1]) : '';
                            break;
                        case 'CHECKBOX':
                            initValues[filter.code] = presetFilter[filter.code] == 1 || false;
                            break;
                        case 'QUESTIONNAIRE_FORM':
                            initValues.custom_form_id = presetFilter.custom_form_id || '';
                            initValues.registration_fee_id = presetFilter.registration_fee_id || [];
                            initValues.affiliation_fee_id = presetFilter.affiliation_fee_id || [];
                            initValues.clinic_id = presetFilter.clinic_id || [];
                            break;
                        case 'PERIOD_AND_REFERENCE_PERIOD':
                            initValues.period_id = presetFilter.period_id || '';
                            initValues.reference_period_id = presetFilter.reference_period_id || '';
                            break;
                    }
                });
            }catch(e){
                return {};
            }
        }else if(Object.keys(location.data || {}).length > 0 && !clearValues){
            // grabs pre-filled filters from a summary<-->detailed switch and sets them in formik
            allFilters.forEach((filter) => {
                switch (filter.type){
                    case 'ORGANIZATION_SELECT':
                    case 'PERIOD_SELECT':
                    case 'GENDER_SELECT':
                    case 'SELECT':
                    case 'NUMBER':
                        initValues[filter.code] = location.data[filter.code] || '';
                        break;
                    case 'TIME':
                    case 'DATE':
                        initValues[filter.code] = location.data[filter.code] ? moment(location.data[filter.code]) : '';
                        break;
                    case 'MULTI_ORGANIZATION_SELECT':
                        initValues[filter.code] = location.data[filter.code] ? location.data[filter.code]?.split(',') : [];
                        break;
                    case 'MULTI_SELECT':
                    case 'GENDER_MULTI_SELECT':
                        initValues[filter.code] = location.data[filter.code] ? location.data[filter.code]?.split(',') : [];
                        break;
                    case 'NUMBER_BETWEEN':
                        initValues[`start_${filter.code}`] = location.data[filter.code]?.split(',')[0] ? location.data[filter.code]?.split(',')[0] : '';
                        initValues[`end_${filter.code}`] = location.data[filter.code]?.split(',')[1] ? location.data[filter.code]?.split(',')[1] : '';
                        break;
                    case 'DATE_BETWEEN':
                        initValues[`start_${filter.code}`] = location.data[filter.code]?.split(',')[0] ? moment(location.data[filter.code]?.split(',')[0]) : '';
                        initValues[`end_${filter.code}`] = location.data[filter.code]?.split(',')[1] ? moment(location.data[filter.code]?.split(',')[1]) : '';
                        break;
                    case 'CHECKBOX':
                        initValues[filter.code] = location.data[filter.code] == 1 || false;
                        break;
                    case 'QUESTIONNAIRE_FORM':
                        if(location.data.custom_form_id)
                            setCustomFormId(location.data.custom_form_id)

                        initValues.custom_form_id = location.data.custom_form_id || '';
                        initValues.registration_fee_id = location.data.registration_fee_id?.split(',') || [];
                        initValues.affiliation_fee_id = location.data.affiliation_fee_id?.split(',') || [];
                        initValues.clinic_id = location.data.clinic_id?.split(',') || [];
                        break;
                    case 'PERIOD_AND_REFERENCE_PERIOD':
                        initValues.period_id = location.data.period_id || '';
                        initValues.reference_period_id = location.data.reference_period_id || '';
                        break;
                }
            });
        }else if(Object.keys(savedSearch || {}).length > 0 && !clearValues){
            // saved search values
            allFilters.forEach((filter) => {
                switch (filter.type){
                    case 'ORGANIZATION_SELECT':
                    case 'PERIOD_SELECT':
                    case 'GENDER_SELECT':
                    case 'SELECT':
                    case 'NUMBER':
                        initValues[filter.code] = savedSearch[filter.code] || '';
                        break;
                    case 'TIME':
                    case 'DATE':
                        initValues[filter.code] = savedSearch[filter.code] ? moment(savedSearch[filter.code]) : '';
                        break;
                    case 'MULTI_ORGANIZATION_SELECT':
                        initValues[filter.code] = savedSearch[filter.code] ? savedSearch[filter.code]?.split(',') : [];
                        break;
                    case 'MULTI_SELECT':
                    case 'GENDER_MULTI_SELECT':
                        initValues[filter.code] = savedSearch[filter.code] ? savedSearch[filter.code]?.split(',') : [];
                        break;
                    case 'NUMBER_BETWEEN':
                        initValues[`start_${filter.code}`] = savedSearch[filter.code]?.split(',')[0] ? savedSearch[filter.code]?.split(',')[0] : '';
                        initValues[`end_${filter.code}`] = savedSearch[filter.code]?.split(',')[1] ? savedSearch[filter.code]?.split(',')[1] : '';
                        break;
                    case 'DATE_BETWEEN':
                        initValues[`start_${filter.code}`] = savedSearch[filter.code]?.split(',')[0] ? moment(savedSearch[filter.code]?.split(',')[0]) : '';
                        initValues[`end_${filter.code}`] = savedSearch[filter.code]?.split(',')[1] ? moment(savedSearch[filter.code]?.split(',')[1]) : '';
                        break;
                    case 'CHECKBOX':
                        initValues[filter.code] = savedSearch[filter.code] == 1 || false;
                        break;
                    case 'QUESTIONNAIRE_FORM':
                        if(savedSearch.custom_form_id)
                            setCustomFormId(savedSearch.custom_form_id)

                        initValues.custom_form_id = savedSearch.custom_form_id || '';
                        initValues.registration_fee_id = savedSearch.registration_fee_id?.split(',') || [];
                        initValues.affiliation_fee_id = savedSearch.affiliation_fee_id?.split(',') || [];
                        initValues.clinic_id = savedSearch.clinic_id?.split(',') || [];
                        break;
                    case 'PERIOD_AND_REFERENCE_PERIOD':
                        initValues.period_id = savedSearch.period_id || '';
                        initValues.reference_period_id = savedSearch.reference_period_id || '';
                        break;
                }
            });
        }else{
            allFilters.forEach((filter) => {
                // grabs default values from the filter json and sets it in formik
                const initVal = filter.default_value;

                switch (filter.type){
                    case 'ORGANIZATION_SELECT':
                        initValues[filter.code] = getOrgInit(initVal);
                        break;
                    case 'PERIOD_SELECT':
                        initValues[filter.code] = initVal === "{{CURRENT}}" ? selectedPeriod.period_id : initVal || "";
                        break;
                    case 'SELECT':
                    case 'GENDER_SELECT':
                    case 'TIME':
                    case 'DATE':
                    case 'NUMBER':
                        initValues[filter.code] = initVal || '';
                        break;
                    case 'MULTI_ORGANIZATION_SELECT':
                        const multiOrgSelectInit = getOrgInit(initVal);
                        initValues[filter.code] = multiOrgSelectInit ? [ multiOrgSelectInit ] : [];
                        break;
                    case 'MULTI_SELECT':
                    case 'GENDER_MULTI_SELECT':
                        initValues[filter.code] = initVal ? initVal.split(',') : [];
                        break;
                    case 'NUMBER_BETWEEN':
                    case 'DATE_BETWEEN':
                        initValues[`start_${filter.code}`] = initVal || '';
                        initValues[`end_${filter.code}`] = initVal || '';
                        break;
                    case 'CHECKBOX':
                        initValues[filter.code] = initVal == 1 || false;
                        break;
                    case 'QUESTIONNAIRE_FORM':
                        initValues.custom_form_id = '';
                        initValues.registration_fee_id = [];
                        initValues.affiliation_fee_id = [];
                        initValues.clinic_id = [];
                        break;
                    case 'PERIOD_AND_REFERENCE_PERIOD':
                        initValues.period_id = initVal === "{{CURRENT}}" ? selectedPeriod.period_id : initVal || "";
                        initValues.reference_period_id = '';
                        break;
                }
            });
        }

        return initValues;
    }

    const checkIfIsRendered = (filter, values) => {
        return !(filter.parsedParams?.renderConditionally) || filter.parsedParams.renderConditionally.every((code) => values[code]);
    }

    // Returns true if requiredWhen and the all requiredWhen have values
    const testRequiredWhen = (filter, values) => {
        if(filter.parsedParams?.requiredWhenValue?.value && filter.parsedParams?.requiredWhenValue?.filter)
            return filterHasValue(filter.parsedParams?.requiredWhenValue?.filter, filter.parsedParams?.requiredWhenValue?.value, formikRef.current)
        if(!filter.parsedParams?.requiredWhen)
            return false
        return !!filter.parsedParams?.requiredWhen?.every((code) => values[code]);
    }

    const getValidationSchema = (allFilters) => {
        const validationObject = {};
        allFilters.forEach((filter) => {
            let parsedParams = {};

            try{
                const parsed = JSON.parse(filter.param);
                parsedParams = parsed;
            }catch(e){
                console.error(e);
            }

            switch (filter.type){
                case 'PERIOD_SELECT':
                    validationObject[filter.code] = string().test({
                        name: `period_select_engine_validation_${filter.code}`,
                        message: <Translate id='reports.filters.period.required' />,
                        test: function(value){
                            const isRendered = checkIfIsRendered({ ...filter, parsedParams }, this.parent)
                            if(isRendered && (filter.required == '1' || testRequiredWhen({ ...filter, parsedParams }, this.parent)) && !value)
                                return false; // error if required and no value
                            return true; // valid
                        },
                    })
                    break;
                case 'DATE':
                case 'TIME':
                    validationObject[filter.code] = mixed().test({
                        name: `date_time_engine_validation_${filter.code}`,
                        message: <Translate id='reports.engine.validation.single.date.time' />,
                        test: function(value){
                            const isRendered = checkIfIsRendered({ ...filter, parsedParams }, this.parent)
                            if(isRendered && (filter.required == '1' || testRequiredWhen({ ...filter, parsedParams }, this.parent)))
                                return moment.isMoment(value, 'YYYY-MM-DD', true).isValid();
                            else if(isRendered && value)
                                return moment(value, 'YYYY-MM-DD', true).isValid();
                            else if(filter.required == '0' && !value || !isRendered)
                                return true;
                            return false
                        },
                    })
                    break;
                case 'DATE_BETWEEN': // TODO:
                    validationObject[`start_${filter.code}`] = mixed().test({
                        name: `start_${filter.code}_date_engine_validation`,
                        message: <Translate id='reports.engine.validation.start.date' />,
                        test: function(date){
                            const isRendered = checkIfIsRendered({ ...filter, parsedParams }, this.parent)
                            if(isRendered && (filter.required == '1' || testRequiredWhen({ ...filter, parsedParams }, this.parent)))
                                return moment(date, 'YYYY-MM-DD', true).isValid() && this.parent?.[`end_${filter.code}`];
                            else if(isRendered && date)
                                return moment(date, 'YYYY-MM-DD', true).isValid() && this.parent?.[`end_${filter.code}`];
                            else if(filter.required == '0' && !date || !isRendered)
                                return true;
                            return false
                        },
                    })

                    validationObject[`end_${filter.code}`] = mixed().test({
                        name: `end_${filter.code}_date_engine_validation`,
                        message: <Translate id='reports.engine.validation.end.date' />,
                        test: function(date){
                            const isRendered = checkIfIsRendered({ ...filter, parsedParams }, this.parent)
                            if(isRendered && (filter.required == '1' || testRequiredWhen({ ...filter, parsedParams }, this.parent)))
                                return moment(date, 'YYYY-MM-DD', true).isValid() && moment(date).isAfter(this.parent[`start_${filter.code}`]) && this.parent?.[`start_${filter.code}`];
                            else if(isRendered && date && this.parent[`start_${filter.code}`]) // if not required but present with start_date, check valid and after
                                return moment(date, 'YYYY-MM-DD', true).isValid() && moment(date).isAfter(this.parent[`start_${filter.code}`]) && this.parent?.[`start_${filter.code}`];
                            else if(filter.required == '0' && !date || !isRendered)
                                return true;
                            return false
                        },
                    })
                    break;
                case 'ORGANIZATION_SELECT':
                case 'SELECT':
                case 'GENDER_SELECT':
                    validationObject[filter.code] = string().test({
                        name: `select_engine_validation_${filter.code}`,
                        message: <Translate id='reports.engine.validation.single.select' />,
                        test: function(value){
                            const isRendered = checkIfIsRendered({ ...filter, parsedParams }, this.parent)
                            if(isRendered && (filter.required == '1' || testRequiredWhen({ ...filter, parsedParams }, this.parent)))
                                return !!value;
                            return true
                        },
                    })
                    break;
                case 'NUMBER':
                    validationObject[filter.code] = number().test({
                        name: `select_engine_validation_${filter.code}`,
                        message: <Translate id='reports.engine.validation.number' />,
                        test: function(value){
                            const isRendered = checkIfIsRendered({ ...filter, parsedParams }, this.parent)
                            if(isRendered && (filter.required == '1' || testRequiredWhen({ ...filter, parsedParams }, this.parent)))
                                return !!value && !isNaN(value);
                            if(value)
                                return !isNaN(value);
                            return true;
                        },
                    })
                    break;
                case 'MULTI_SELECT':
                case 'GENDER_MULTI_SELECT':
                case 'MULTI_ORGANIZATION_SELECT':
                    validationObject[filter.code] = array().test({
                        name: `multi_select_engine_validation_${filter.code}`,
                        message: <Translate id='reports.engine.validation.multi.select' />,
                        test: function(value){
                            const isRendered = checkIfIsRendered({ ...filter, parsedParams }, this.parent)
                            if(isRendered && (filter.required == '1' || testRequiredWhen({ ...filter, parsedParams }, this.parent)))
                                return value.length > 0;
                            return true
                        },
                    })
                    break;
                case 'QUESTIONNAIRE_FORM':
                    validationObject.custom_form_id = string().required(<Translate id='reports.filters.form.required' />)
                    break;
                case 'PERIOD_AND_REFERENCE_PERIOD':
                    validationObject.period_id = string().required(<Translate id='reports.filters.period.required' />).test({
                        name: 'is_after_reference_period',
                        message: <Translate id='reports.filters.period.is_after_reference' />,
                        test: (period_id, { parent }) => { // TODO: finish this validation
                            // makes sure period is after reference_period by finding the start_date of each with their IDs and using moment.isAfter
                            const selected_period = periodsContext.activePeriods.find((p) => p.period_id === period_id)
                            const reference_period = periodsContext.activePeriods.find((p) => p.period_id === parent.reference_period_id)

                            if(selected_period && reference_period)
                                return moment(selected_period?.start_date).isAfter(reference_period?.start_date)
                            else if(selected_period && !reference_period)
                                return true
                            return false
                        },
                    });
                    validationObject.reference_period_id = string().required(<Translate id='reports.filters.reference.period.required' />);
                    break;
                case 'NUMBER_BETWEEN':
                    validationObject[`start_${filter.code}`] = number().test({
                        name: `start_${filter.code}_number_engine_validation`,
                        message: <Translate id='reports.engine.validation.start.number' />,
                        test: function(value){
                            if(!checkIfIsRendered({ ...filter, parsedParams }, this.parent)){
                                return true;
                            }

                            if(filter.required == '1' || testRequiredWhen({ ...filter, parsedParams }, this.parent))
                                return !!value && !isNaN(value);
                            else if(value)
                                return !isNaN(value);
                            else if(filter.required == '0' && !value)
                                return true;
                            return false
                        },
                    }),

                    validationObject[`end_${filter.code}`] = number().test({
                        name: `end_${filter.code}_number_engine_validation`,
                        message: <Translate id='reports.engine.validation.end.number' />,
                        test: function(value){
                            if(!checkIfIsRendered({ ...filter, parsedParams }, this.parent)){
                                return true;
                            }
                            if(filter.required == '1' || testRequiredWhen({ ...filter, parsedParams }, this.parent)) // if required check valid and after start
                                return !isNaN(value) && value > this.parent[`start_${filter.code}`];
                            else if(value && this.parent[`start_${filter.code}`]) // if not required but present with start_date, check valid and after
                                return !isNaN(value) && value > this.parent[`start_${filter.code}`];
                            else if(value && !this.parent[`start_${filter.code}`]) // if no required and no start_date, check valid
                                return !isNaN(value);
                            else if(filter.required == '0' && !value)
                                return true;
                            return false
                        },
                    })
                    break;
            }
        })

        return object().shape(validationObject)
    }

    return (
        <div>
            <Formik
                innerRef={formikRef}
                initialValues={getInitialValues(currentReport.filters, false)}
                onSubmit={(values, { setSubmitting }) => {
                    AxiosCancelReportCalls();
                    setFiltersHaveChanged(false);
                    saveSearch(getReportQueryParamsFromFilters(values, currentReport.filters));
                    filterChange('reportsFilter', getReportQueryParamsFromFilters(values, currentReport.filters));
                    setSubmitting(false);
                }}
                validationSchema={getValidationSchema(currentReport.filters)}
                className='mb-2'
            >
                {(formik) => (
                    <Form id={generateId('filter', 'form')}>
                        <FormikObserver value={formik.values} onChange={() => setFiltersHaveChanged(true)} />
                        <Row form>
                            {currentReport.filters
                                .sort((a, b) => (a.display_order - b.display_order))
                                .map((filter) => {
                                    const defaultParams = { col: {}, componentProps: {} };
                                    let parsedParams = {};
                                    let parsedOptions = [];

                                    if(filter.param){
                                        try{
                                            const parsed = JSON.parse(filter.param);

                                            if(parsed){
                                                parsedParams = parsed;
                                            }
                                        }catch(e){
                                            console.error(e);
                                        }
                                    }

                                    if(filter.option){
                                        try{
                                            const parsed = JSON.parse(filter.option);

                                            if(parsed){
                                                parsedOptions = parsed;
                                            }
                                        }catch(e){
                                            console.error(e);
                                        }
                                    }

                                    const formattedFilter = { ...filter, parsedOptions: parsedOptions, parsedParams: { ...defaultParams, ...parsedParams } };

                                    return (
                                        <EngineFilterSwitch
                                            formikRef={formikRef}
                                            customReportId={customReportId}
                                            isOpenHandlers={isOpenHandlers}
                                            key={'filter_switch' + formattedFilter.code}
                                            currentFilter={formattedFilter}
                                            filterRefs={filterRefs}
                                            setCustomFormId={setCustomFormId}
                                            customFormId={customFormId}
                                        />
                                    );
                                })
                            }
                        </Row>
                        <div className='d-flex flex-wrap flex-md-nowrap'>
                            <SavedSearchCue
                                className="w-100 order-1 order-md-0 my-2 my-md-0 mr-md-2"
                                savedSearch={savedSearch}
                                clearSavedSearch={() => {
                                    clearSavedSearch();
                                    formik.setValues(getInitialValues(currentReport.filters, true));
                                }}
                            />
                            <button
                                type='button'
                                className='reset-btn ml-auto text-danger w-100 w-md-auto font-12 text-nowrap'
                                onClick={() => {
                                    clearSavedSearch();
                                    formik.setValues(getInitialValues(currentReport.filters, true));
                                }}
                            >
                                <Translate id='members.search.memberAdvanceSearch.resetFilters' />
                            </button>
                        </div>
                        <AddFilter />
                    </Form>
                )}
            </Formik>
            <HasMoreRowsDownload
                hasMoreRows={hasMoreRows}
                filters={() => ({ ...getReportQueryParamsFromFilters(formikRef.current?.values, currentReport.filters), custom_report_id: customReportId })}
                className='mb-3'
            />
        </div>
    )
}

export default ReportsEngineFiltersCard;