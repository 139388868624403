import Translate from "@spordle/intl-elements";
import { Button, Collapse, ModalBody, ModalFooter } from "reactstrap";
import { useState, useContext, useMemo, isValidElement } from "react";
import TravelPermitAddLineupSearch from "./TravelPermitAddLineupSearch";
import CrossFade from "../../../../../../components/crossFade/CrossFade";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import { FieldArray, Form, Formik } from "formik";
import DisplayMember from "../../../../../../components/display/DisplayMember";
import { FormikSelect } from "@spordle/formik-elements";
import { array, object, string } from "yup";
import Required from "../../../../../../components/formik/Required";
import { Tooltip } from "@mantine/core";
import { formatSelectData } from "@spordle/spordle-select";
import { DisplayI18n, displayI18n } from "../../../../../../helpers/i18nHelper";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { stringBuilder } from "@spordle/helpers";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import ApiErrorMsg from "../../../../../../components/formik/ApiErrorMsg";
import FormikApiError from "../../../../../../components/formik/FormikApiError";
import { useTeamPositionsSWR } from "../../../../../../api/useSWRHelpers/positionsSWR";

const VIEW_LINEUP = 0;
const VIEW_SEARCH = 1;

const TravelPermitAddLineup = ({ onSubmit, initialValues, toggle, previous, teamId }) => {
    const [ view, setView ] = useState(VIEW_LINEUP);
    const { getGenericLocale } = useContext(I18nContext);

    const { data: positions } = useTeamPositionsSWR();
    const positionOptions = useMemo(() => formatSelectData(
        (positions || [])
            .filter(({ active }) => active == 1)
            .sort((a, b) => {
                const groupA = displayI18n("name", a.position_group?.i18n, a.position_group?.name, getGenericLocale()) || "";
                const groupB = displayI18n("name", b.position_group?.i18n, b.position_group?.name, getGenericLocale()) || "";

                return groupA.localeCompare(groupB);
            }),
        {
            createGroupWithId: (pos) => pos.position_group,
            getGroupId: (pos) => pos.position_group?.position_group_id,
            getLabel: (pos) => pos.name,
            getValue: (pos) => pos.position_id,
        },
    ), [ (positions || []).length, getGenericLocale() ]);

    return (
        <>
            <ModalBody className="pb-0">
                <div className="h4 font-bold"><Translate id="travelPermit.lineup.title" /></div>
                <p className="mb-0"><Translate id="travelPermit.lineup.helper" /></p>
            </ModalBody>
            <Formik
                initialStatus={{
                    error: null,
                    showError: false,
                }}
                initialValues={{
                    members: initialValues?.members || [],
                }}
                validationSchema={object().shape({
                    members: array().of(object().shape({
                        position_id: string().required(<Translate id="travelPermit.lineup.position.validation" />),
                    }))
                        .min(1, <Translate id="travelPermit.lineup.minimum.validation" />),
                })}
                onSubmit={(values, { setStatus }) => {
                    return onSubmit(values)
                        .catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                setStatus({ error: Array.isArray(e) ? e : <ApiErrorMsg error={e} />, showError: true })
                            }
                        });
                }}
            >
                {(formik) => (
                    <FieldArray name="members">
                        {(arrayHelpers) => (
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <Form>
                                    <CrossFade isVisible={view === VIEW_LINEUP}>
                                        <ModalBody>
                                            {formik.values.members.map((member, i) => (
                                                <DisplayMember
                                                    card
                                                    member={member}
                                                    extra={{
                                                        left: (
                                                            <div className="align-self-start ml-auto">
                                                                <Tooltip
                                                                    label={<Translate id="misc.delete" />}
                                                                >
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            arrayHelpers.remove(i);
                                                                        }}
                                                                        className="fa reset-btn text-danger fa-trash"
                                                                    />
                                                                </Tooltip>
                                                            </div>
                                                        ),
                                                    }}
                                                    key={member.member_id}
                                                    className="mb-2 flex-wrap"
                                                >
                                                    <div className="w-100 pt-3">
                                                        <label htmlFor={`members.${i}.position_id`} className="text-muted mb-1">
                                                            <Translate id="members.profile.memberProfile.header.position" /> <Required />
                                                        </label>
                                                        <FormikSelect
                                                            isLoading={!positions}
                                                            renderOption={({ option }) => <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.name} />}
                                                            options={positionOptions}
                                                            searchKeys={[
                                                                `i18n.${getGenericLocale()}.name`,
                                                                `name`,
                                                                `position_group.i18n.${getGenericLocale()}.name`,
                                                                `position_group.name`,
                                                            ]}
                                                            id={`members.${i}.position_id`}
                                                            name={`members.${i}.position_id`}
                                                        />
                                                    </div>
                                                </DisplayMember>
                                            ))}
                                            <UserDisplay
                                                card
                                                hover
                                                block
                                                onClick={() => setView(VIEW_SEARCH)}
                                                className={stringBuilder(isValidElement(formik.errors.members) ? "border-danger text-danger" : "border-primary text-primary", "border-dashed mdi mdi-plus with-icon justify-content-center mb-0")}
                                            >
                                                <Translate id="members.search.memberSearch.header.addMember" />
                                            </UserDisplay>
                                            {isValidElement(formik.errors.members) &&
                                                <Collapse isOpen appear>
                                                    <small className="text-danger">
                                                        {formik.errors.members}
                                                    </small>
                                                </Collapse>
                                            }
                                            <FormikApiError />
                                        </ModalBody>
                                        <ModalFooter>
                                            {previous &&
                                                <Button color="primary" className="mr-auto" outline onClick={previous} disabled={view !== VIEW_LINEUP}>
                                                    <Translate id="misc.previous" />
                                                </Button>
                                            }
                                            <Button color="primary" type="submit" disabled={view !== VIEW_LINEUP}>
                                                <Translate id="misc.continue" />
                                            </Button>
                                            {toggle &&
                                                <Button type="button" onClick={toggle} color="primary" outline>
                                                    <Translate id="misc.cancel" />
                                                </Button>
                                            }
                                        </ModalFooter>
                                    </CrossFade>
                                </Form>
                                <CrossFade isVisible={view !== VIEW_LINEUP}>
                                    <TravelPermitAddLineupSearch
                                        addMembers={(members) => {
                                            members.forEach((member) => {
                                                if(!formik.values.members.some(({ member_id }) => member_id == member.member_id)){
                                                    arrayHelpers.push(member);
                                                }
                                            })
                                        }}
                                        teamId={teamId}
                                        addedMembers={formik.values.members}
                                        memberIsSelected={(member) => formik.values.members.find(({ member_id }) => member_id === member.member_id)}
                                        previous={() => setView(VIEW_LINEUP)}
                                    />
                                </CrossFade>
                            </OverlayLoader>
                        )}
                    </FieldArray>
                )}
            </Formik>
        </>
    );
}

export default TravelPermitAddLineup;