import Translate from '@spordle/intl-elements';
import React, { } from 'react';
import {
    Button,
    Collapse,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import CrossFade from '../crossFade/CrossFade';
import CustomAlert from '../CustomAlert';
import CustomAnimatedIcon from '../customAnimatedIcon/CustomAnimatedIcon';
import { ClinicsContext } from '../../contexts/ClinicsContext';
import { DisplayI18n } from '../../helpers/i18nHelper';
import withContexts from '../../helpers/withContexts';
import { fail } from "@spordle/toasts"
class ManualRegistrationWaitingListReturnConfirmation extends React.Component{

    state = {
        isApproved: false,
        errorMessage: null,
    }

    sendEmail = () => {
        this.props.setModalLoading(true)
        this.props.ClinicsContext.approveWaitlistMember(this.props.waitingListNumber, this.props.selectedIdentityId)
            .then(() => {
                this.props.setModalLoading(false);
                this.setState(() => ({
                    isApproved: true,
                }))
                this.props.setFinal(true);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({
                        errorMessage: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    }))
                    this.props.setModalLoading(false)
                }
            })
    }

    render(){
        return (
            <>
                <CrossFade isVisible={!this.state.isApproved}>
                    <ModalBody className="text-center">
                        <i className='text-primary mdi mdi-information-outline' style={{ fontSize: 75 }} />
                        <div className="h4 font-bold mb-2"><Translate id='components.manualRegistration.waitingListReturnConfirmation.title' /></div>
                        <Translate
                            id='components.manualRegistration.waitingListReturnConfirmation.text'
                            values={{
                                confirm: <Translate id='misc.confirm' />,
                                name: this.props.selectedIdentityName,
                            }}
                        />
                        <Collapse isOpen={this.state.errorMessage} appear mountOnEnter unmountOnExit className='text-left'>
                            {this.state.errorMessage &&
                                <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={this.state.errorMessage} translateText={false} toggle={() => this.setState(() => ({ errorMessage: null }))} />
                            }
                        </Collapse>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.sendEmail} color='primary'><Translate id='misc.confirm' /></Button>
                        <Button color='primary' outline onClick={this.props.toggle}><Translate id='misc.cancel' /></Button>
                    </ModalFooter>
                </CrossFade>
                <CrossFade isVisible={this.state.isApproved}>
                    <ModalBody className="text-center">
                        <CustomAnimatedIcon
                            icon='checkmark'
                            size={75}
                            withCircle
                            className='text-success mb-3'
                        />
                        {/* <i className='text-primary mdi mdi-information-outline' style={{ fontSize: 75 }} /> */}
                        <div className="h4 font-bold mb-2"><Translate id='components.manualRegistration.waitingListReturnConfirmation.confirmation' /></div>
                        <Translate id='components.manualRegistration.waitingListReturnConfirmation.confirmationMessage' />

                        <div className='mt-3'>
                            <Button color='primary' onClick={this.props.toggle}><Translate id='misc.close' /></Button>
                        </div>
                    </ModalBody>
                </CrossFade>
            </>
        );
    }
}

export default withContexts(ClinicsContext)(ManualRegistrationWaitingListReturnConfirmation);