import Translate from '@spordle/intl-elements';
import {
    Button,
    Card,
    Collapse,
    Fade,
    ModalBody,
    ModalFooter, ModalHeader
} from "reactstrap";
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import { Form, Formik } from 'formik';
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import { useContext, useEffect, useRef, useState } from 'react';
import CustomAnimatedIcon from '../../../../../components/customAnimatedIcon/CustomAnimatedIcon';
import InlineCopy from '../../../../../components/inlineCopy/InlineCopy';
import { object } from 'yup';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import { MembersContext } from '../../../../../contexts/MembersContext';
import CustomAlert from '../../../../../components/CustomAlert';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';
import { fail } from '@spordle/toasts';
import { createPermanentReleaseRequest, getPermanentRelease, updatePermanentReleaseRequestChangeStatus } from '../../../../../api/client/permanentReleaseRequests';
import { PeriodsContext } from '../../../../../contexts/contexts';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import DisplayOrganization from '../../../../../components/organization/DisplayOrganization';
import DisplayBirthdateAge from '../../../../../components/DisplayBirthdateAge';
import GenderLabel from '../../../../../components/GenderLabel';

const RequestPermanentReleaseModal = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName='RequestClassificationChangesModal'>
            <RequestPermanentReleaseModalInner {...props} />
        </AnalyticsModal>
    )
}

const RequestPermanentReleaseModalInner = ({ isOpen, toggle, member, refreshMemberProfile, forceCloseSidepanel, changeRequestStatuses }) => {
    const membersContext = useContext(MembersContext);
    const periodsContext = useContext(PeriodsContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const organizationContext = useContext(OrganizationContext);

    const formikRef = useRef(null);

    const [ isSuccess, setIsSuccess ] = useState(false);
    const [ isCancelSuccess, setIsCancelSuccess ] = useState(false);

    // loading status for cancellation API call
    const [ cancellationPending, setCancellationPending ] = useState(false);

    useEffect(() => {
        // set success state to false when opening the modal
        if(isOpen === true){
            setIsSuccess(false);
        }
    }, [ isOpen ])

    // TODO: somehting broken here
    const releaseRequest = membersContext.permanentReleaseRequests.permanent_release_requests.find((release) => release.request_change_status.system === 'PENDING') || null; // find pending release request

    const updateReleaseRequest = () => {
        getPermanentRelease({
            member_id: membersContext.currentMember.member_id,
            organisation_id: organizationContext.organisation_id,
            period_id: periodsContext.selectedPeriod.period_id,
        })
            .then((data) => {
                membersContext.setPermanentReleaseRequests({
                    permanent_release_requests: data.filter((release) => release.member_id === membersContext.currentMember.member_id),
                    pending_permanent_release_requests: data.filter((release) => release.member_id === membersContext.currentMember.member_id).some((release) => release.request_change_status.system === 'PENDING'),
                }) // filter cause member_id filter doesn't work
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                }
            });
    }

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{}}
            validationSchema={object().shape({})}
            onSubmit={(_values, { setSubmitting, setStatus }) => {
                return createPermanentReleaseRequest({ member_id: membersContext.currentMember.member_id, period_id: periodsContext.selectedPeriod.period_id })
                    .then(() => {
                        setStatus("success");
                        setSubmitting(false);
                        setIsSuccess(true);
                        refreshMemberProfile();
                        updateReleaseRequest();
                    }).catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                        }
                        setSubmitting(false);
                    });
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={toggle}>
                            <Translate id='members.profile.action.requestRelease' />
                        </ModalHeader>
                        {/* CREATE CHANGE REQUEST */}
                        {(!isSuccess && formik.status !== 'success' && !releaseRequest?.permanent_release_request_id && !isCancelSuccess) &&
                            <>
                                <ModalBody>
                                    <UserDisplay card className='w-100'>
                                        <UserDisplay.Container>
                                            <UserImg
                                                abbr={member.first_name[0] + member.last_name[0]}
                                                src={null}
                                                width={50}
                                                height={50}
                                                alt={`${member.first_name} ${member.last_name}`}
                                            />
                                        </UserDisplay.Container>
                                        <UserDisplay.Container>
                                            <UserDisplay.Title>{member.first_name} {member.last_name}</UserDisplay.Title>
                                            <UserDisplay.Subtitle className="font-medium text-dark"><InlineCopy tag='div' toCopy={member.unique_identifier} className="font-medium">{'#' + member.unique_identifier || '-'}</InlineCopy></UserDisplay.Subtitle>
                                            <UserDisplay.Subtitle><DisplayBirthdateAge birthdate={member.birthdate} age={member.age} /></UserDisplay.Subtitle>
                                            <UserDisplay.Subtitle><GenderLabel gender={member.gender} /></UserDisplay.Subtitle>
                                        </UserDisplay.Container>
                                    </UserDisplay>

                                    <div className='mb-2'>
                                        <Translate id='members.profile.create.RequestRelease' />
                                    </div>

                                    {formik.status &&
                                        <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                            <CustomAlert className='mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                        </Collapse>
                                    }
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color='primary'
                                        type='submit'
                                        disabled={formik.isSubmitting}
                                    >
                                        <Translate id='misc.submit' />
                                    </Button>
                                    <Button
                                        color='primary' type='button' onClick={toggle}
                                        outline disabled={formik.isSubmitting}
                                    >
                                        <Translate id='misc.cancel' />
                                    </Button>
                                </ModalFooter>
                            </>
                        }
                        {/* CHANGE REQUEST CREATED SUCCESSFULLY */}
                        {(isSuccess && formik.status == 'success') &&
                            <>
                                <ModalBody className="text-center text-dark">
                                    <Fade in>
                                        <div className='position-relative d-inline-block'>
                                            <CustomAnimatedIcon
                                                className='text-success'
                                                strokeWidth="10px"
                                                size={50}
                                                id='confirmModalCheckmark'
                                                icon='checkmark'
                                                withCircle
                                            />
                                        </div>

                                        <div className="text-center h5 font-bold pt-2">
                                            <Translate id="misc.success" />
                                        </div>
                                        <div><Translate id='members.profile.action.requestRelease.success' /></div>
                                    </Fade>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='button' onClick={() => { toggle(); }} outline disabled={formik.isSubmitting}><Translate id='misc.close' /></Button>
                                </ModalFooter>
                            </>
                        }
                        {/* VIEW/CANCEL CHANGE REQUEST */}
                        {(releaseRequest?.permanent_release_request_id && !isSuccess && !isCancelSuccess) &&
                            <OverlayLoader isLoading={cancellationPending}>
                                <ModalBody>
                                    <div className='h5 font-bold'><Translate id='task.mandatoryChangesRequests.sidepanel.request.requested_by' /></div>
                                    <div>
                                        {releaseRequest.created_by.name} {releaseRequest.created_by.family_name} - <a href={"mailto:" + releaseRequest.created_by.email}>{releaseRequest.created_by.email}</a>
                                    </div>
                                    <div className='mt-2 h5 font-bold'><Translate id='task.mandatoryChangesRequests.sidepanel.request.data' /></div>
                                    <Card body className='card-shadow'>
                                        <Translate id='members.profile.has.RequestRelease.from' />:
                                        <div className='font-bold text-dark'>
                                            <DisplayOrganization organisation={{ organisation_id: releaseRequest.organisation_id }}>
                                                <DisplayI18n i18n={releaseRequest.organisation.i18n} defaultValue={releaseRequest.organisation.name} field='name' />
                                            </DisplayOrganization>
                                        </div>
                                    </Card>
                                </ModalBody>
                                <ModalFooter>
                                    {identityRolesContext.identity.identity_id == releaseRequest.created_by.identity_id &&
                                        <Button
                                            disabled={identityRolesContext.identity.identity_id !== releaseRequest.created_by.identity_id}
                                            color='danger'
                                            onClick={() => {
                                                setCancellationPending(true);
                                                return updatePermanentReleaseRequestChangeStatus(releaseRequest.permanent_release_request_id, { request_change_status_id: changeRequestStatuses.find((status) => status.system === 'CANCELLED')?.request_change_status_id })
                                                    .then(() => {
                                                        refreshMemberProfile();
                                                        setCancellationPending(false);
                                                        setIsCancelSuccess(true)
                                                        updateReleaseRequest();
                                                    }).catch((error) => {
                                                        formik.setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                                        if(!AxiosIsCancelled(error.message)){
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                            setCancellationPending(false);
                                                            console.error(error.message);
                                                            formik.setSubmitting(false);
                                                        }
                                                    })
                                            }}
                                        >
                                            <Translate id='task.mandatoryChangesRequests.modal.cancel.btn' />
                                        </Button>
                                    }
                                    <Button color='primary' type='button' onClick={() => { toggle(); }} outline disabled={formik.isSubmitting}><Translate id='misc.close' /></Button>
                                </ModalFooter>
                            </OverlayLoader>
                        }
                        {/* CHANGE REQUEST CANCELLATION SUCCESS */}
                        {(isCancelSuccess) &&
                            <>
                                <ModalBody className="text-center text-dark">
                                    <Fade in>
                                        <div className='position-relative d-inline-block'>
                                            <CustomAnimatedIcon
                                                className='text-success'
                                                strokeWidth="10px"
                                                size={50}
                                                id='confirmModalCheckmark'
                                                icon='checkmark'
                                                withCircle
                                            />
                                        </div>

                                        <div className="text-center h5 font-bold pt-2">
                                            <Translate id="misc.success" />
                                        </div>
                                        <div><Translate id='members.profile.request.cancel.success' /></div>
                                    </Fade>
                                </ModalBody>
                                <ModalFooter>
                                    {/* don't think having a new request button is necessary for this one since there are no parameter */}
                                    {/* <Button color='primary' type='button' onClick={() => { setIsCancelSuccess(false); }} disabled={formik.isSubmitting}><Translate id='members.profile.new.request' /></Button> */}
                                    <Button color='primary' type='button' onClick={() => { toggle(); }} outline disabled={formik.isSubmitting}><Translate id='misc.close' /></Button>
                                </ModalFooter>
                            </>
                        }
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default RequestPermanentReleaseModal;
