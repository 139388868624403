import { useState, useContext } from 'react';
import {
    Row,
    Col,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    FormGroup,
    Collapse,
    Alert,
    FormText
} from "reactstrap";
import { Formik, Form as FormikForm } from "formik";
import { FormikInputNumber, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate from "@spordle/intl-elements";
import { success } from '@spordle/toasts';

// Contexts
import { displayI18n, DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../helpers/i18nHelper';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { RegistrationCategoriesContext } from '../../../../contexts/RegistrationCategoriesContext';
import { useSpordleTable } from '@spordle/datatables';
import { I18nContext } from '../../../../contexts/I18nContext';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import { DisplayCategory } from '../../../teams/TeamHelpers';
import { number, object, string } from 'yup';
import Required from '../../../../components/formik/Required';
import { IdentityRolesContext } from '../../../../contexts/IdentityRolesContext';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';

const ModalFormBranch = ({ classDivision, types, toggleModal }) => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const [ preview, setPreview ] = useState(null);
    // eslint-disable-next-line unused-imports/no-unused-vars
    const [ isLoading, setIsLoading ] = useState({
        types: true,
        equivalent: false,
    });
    const orgContext = useContext(OrganizationContext);
    const categoryContext = useContext(RegistrationCategoriesContext);
    const idRoleContext = useContext(IdentityRolesContext);
    const spordleTable = useSpordleTable();
    const i18n = useContext(I18nContext);
    const i18nHelper = useContext(I18nHelperContext);

    const [ parentCategory, setParentCategory ] = useState(null);

    const createSuggestedName = (divisionId, classId, lang) => {
        if(divisionId){
            const division = (classDivision.divisions || []).find((division) => division.division_id === divisionId);

            if(division){
                let suggestedName = displayI18n('name', division.i18n, division.name, lang);
                if(classId){
                    const classFound = classDivision.classes.find((_class) => _class.class_id === classId);
                    if(classFound){
                        suggestedName += ` - ${displayI18n('name', classFound.i18n, classFound.name, lang)}`;
                    }
                }
                return suggestedName;
            }
        }
        return '';
    }

    /**
    * @param {string} categoryId
    */
    // const updateCategoryPreview = (categoryId) => {
    //     setIsLoading((prev) => ({ ...prev, equivalent: true }))

    //     categoryContext.getRegistrationCategory(categoryId)
    //         .then(setPreview)
    //         .catch(console.error)
    //         .finally(() => {
    //             setIsLoading((prev) => ({ ...prev, equivalent: false }))
    //         })
    // }

    return (
        <Formik
            initialValues={{
                ...i18nHelper.getInitialValues(),
                division_id: '',
                class_id: '',
                display_order: spordleTable.getData().length + 1,
                team_category_id_parent: '',
                team_category_type_id: '',
                min_age: '',
                max_age: '',
            }}
            validationSchema={object().shape({
                ...i18nHelper.getValidationSchema({ 'name': string() }),
                team_category_id_parent: string().required(<Translate id='registration.settings.category.form.parentCategory.required' />),
                division_id: string().required(<Translate id='registration.settings.category.form.division.required' />),
                class_id: string(),
                display_order: number()
                    .integer(<Translate id='registration.settings.class.form.validation.order.integer' />)
                    .moreThan(0, <Translate id='registration.settings.class.form.validation.order.moreThan0' />),
                min_age: number().required(<Translate id='registration.settings.category.form.minAge.required' />)
                    .test({
                        name: "smallerOrEqualToMax",
                        message: <Translate id='registration.settings.category.form.minAge.smaller' />,
                        test: function(minAge){
                            return parseInt(minAge) <= parseInt(this.parent.max_age)
                        },
                    })
                    .test({
                        name: 'min-age-requirement',
                        message: () => <Translate id='registration.settings.category.form.minAge.requirements' values={{ age: parentCategory?.min_age }} />,
                        test: function(age){
                            if(this.parent.team_category_id_parent){
                                const { min_age } = spordleTable.getData().find((cat) => cat.team_category_id === this.parent.team_category_id_parent);
                                return parseInt(age) >= parseInt(min_age);
                            }
                            return true;
                        },
                    }),
                max_age: number().required(<Translate id='registration.settings.category.form.maxAge.required' />)
                    .test({
                        name: "greaterOrEqualToMin",
                        message: <Translate id='registration.settings.category.form.maxAge.bigger' />,
                        test: function(maxAge){
                            return parseInt(maxAge) >= parseInt(this.parent.min_age)
                        },
                    })
                    .test({
                        name: 'max-age-requirement',
                        message: () => <Translate id='registration.settings.category.form.maxAge.requirements' values={{ age: parentCategory?.max_age }} />,
                        test: function(age){
                            if(this.parent.team_category_id_parent){
                                const { max_age } = spordleTable.getData().find((cat) => cat.team_category_id === this.parent.team_category_id_parent);
                                return parseInt(age) <= parseInt(max_age);
                            }
                            return true;
                        },
                    }),
            })}
            onSubmit={(values, { setStatus }) => {
                setStatus();
                values.active = '1';

                // logic to apply suggested value when name fields are empty
                for(const lang in values.i18n){
                    if(!values.i18n[lang].name)
                        values.i18n[lang].name = createSuggestedName(values.division_id, values.class_id, lang)
                }

                const apiValues = {
                    ...values,
                    division_id: values.division_id,
                    class_id: values.class_id || '',
                    ...i18nHelper.getAPIValues(values),
                }
                delete apiValues.i18n // remove i18n object

                return categoryContext.createRegistrationCategory(orgContext.organisation_id, apiValues)
                    .then((team_category_id) => {
                        spordleTable.refreshTable()
                        toggleModal();
                        success();
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                            setStatus(<DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />);
                        }
                    })
            }}
        >
            {(formik) => (
                <FormikForm id="createBranchCategory">
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={toggleModal}>
                            <Translate id='registration.settings.category.modal.title' />
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="division_id" className="text-muted"><Translate id='registration.settings.category.label.division' /> <Required /></Label>
                                <FormikSelect
                                    name="division_id" id="division_id"
                                    searchKeys={[
                                        `i18n.${i18n.getGenericLocale()}.short_name`,
                                        `i18n.${i18n.getGenericLocale()}.name`,
                                    ]}
                                    renderOption={(option) => (
                                        <>
                                            <DisplayI18n field='short_name' defaultValue={option.option.label} i18n={option.option.i18n} />
                                            <small className='d-block text-muted'><DisplayI18n field='name' defaultValue={option.option.name} i18n={option.option.i18n} /></small>
                                        </>
                                    )}
                                    options={classDivision?.divisions?.sort((a, b) => a.display_order - b.display_order).map((division) => ({
                                        label: division.short_name,
                                        name: division.name,
                                        value: division.division_id,
                                        i18n: division.i18n,
                                    }))}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="class_id" className="text-muted">
                                    <Translate id='registration.settings.category.label.class' />
                                </Label>
                                <FormikSelect
                                    name="class_id" id="class_id"
                                    clearable
                                    searchKeys={[
                                        `i18n.${i18n.getGenericLocale()}.short_name`,
                                    ]}
                                    renderOption={(option) => <DisplayI18n field='short_name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                    options={classDivision?.classes?.map((singleClass) => ({
                                        label: singleClass.short_name,
                                        value: singleClass.class_id,
                                        i18n: singleClass.i18n,
                                    }))}
                                />
                            </FormGroup>

                            <FormGroup>
                                <div className="mb-2"><Translate id='registration.settings.category.label.name' /></div>
                                <div className="border p-3 rounded">
                                    <RenderI18nForm field='name'>
                                        {({ fieldName, fieldLabel, lang, index }) => {
                                            const suggestedName = createSuggestedName(formik.values.division_id, formik.values.class_id, lang);
                                            return (
                                                <FormGroup key={fieldName} className={index == 1 ? 'mb-0' : ''}>
                                                    <Label for={fieldName} className='text-muted'>{fieldLabel}</Label>
                                                    <FormikInputText
                                                        placeholder={suggestedName}
                                                        translatePlaceholder={false}
                                                        id={fieldName} name={fieldName}
                                                        trim disabled={!formik.values.division_id}
                                                    />
                                                    {suggestedName &&
                                                        <FormText>
                                                            <Translate id='registration.settings.category.label.name.suggestion' />:
                                                            <button
                                                                className='reset-btn text-link ml-1' type='button'
                                                                onClick={() => {
                                                                    formik.setFieldValue(fieldName, suggestedName);
                                                                }}
                                                            >
                                                                {suggestedName}
                                                            </button>
                                                        </FormText>
                                                    }
                                                </FormGroup>
                                            )
                                        }}
                                    </RenderI18nForm>
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <Label for="display_order" className="text-muted"><Translate id='registration.settings.class.form.label.order' /></Label>
                                <FormikInputNumber
                                    id='display_order'
                                    name='display_order'
                                    allowNegative={false}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="team_category_type_id" className="text-muted"><Translate id='registration.settings.category.label.type' /></Label>
                                <FormikSelect
                                    search={false} name='team_category_type_id' id='team_category_type_id'
                                    renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                    options={types?.map((type) => ({
                                        value: type.team_category_type_id,
                                        label: type.name,
                                        i18n: type.i18n,
                                    }))}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="team_category_id_parent" className="text-muted">
                                    <Translate
                                        id='registration.settings.category.label.federation.linkWith'
                                        values={{ name: displayI18n('name', idRoleContext.federation.i18n, idRoleContext.federation.organisation_name, i18n.getGenericLocale()) }}
                                    /> <Required />
                                </Label>
                                <FormikSelect
                                    name='team_category_id_parent' id='team_category_id_parent'
                                    renderOption={(option) => (
                                        <>
                                            <div className='d-flex'><DisplayCategory category={option.option} short /> - <Translate id={`form.fields.gender.${option.option.gender.toLowerCase()}`} /></div>
                                            <small className='text-muted'><DisplayCategory category={option.option} /></small>
                                            {(option.option.min_age && option.option.max_age) &&
                                                <small className='text-muted ml-1'>
                                                    (
                                                    {option.option.min_age != option.option.max_age ?
                                                        <Translate id="misc.ageRange" values={{ from: option.option.min_age, to: option.option.max_age }} />
                                                        :
                                                        <Translate id='misc.yearsOld' values={{ age: option.option.min_age }} />
                                                    }
                                                    )
                                                </small>
                                            }
                                        </>
                                    )}
                                    options={spordleTable?.getData()
                                        .reduce((newArray, cat) => {
                                            if(cat.organisation.organisation_id.includes(idRoleContext.federation.organisation_id) && cat.active == 1){
                                                newArray.push({
                                                    value: cat.team_category_id,
                                                    label: cat.name,
                                                    division: cat.division,
                                                    class: cat.class,
                                                    gender: cat.gender,
                                                    min_age: cat.min_age,
                                                    max_age: cat.max_age,
                                                })
                                            }
                                            return newArray
                                        }, [])
                                    }
                                    onOptionSelected={([ catId ]) => {
                                        setParentCategory(spordleTable.getData().find((cat) => cat.team_category_id === catId));
                                    }}
                                />
                            </FormGroup>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="min_age" className="text-muted"><Translate id='registration.settings.category.label.minAge' /> <Required /></Label>
                                        <FormikInputNumber id='min_age' name='min_age' allowNegative={false} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="max_age" className="text-muted"><Translate id='registration.settings.category.label.maxAge' /> <Required /></Label>
                                        <FormikInputNumber id='max_age' name='max_age' allowNegative={false} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Collapse isOpen={!!parentCategory} mountOnEnter unmountOnExit>
                                <div className="font-medium mb-2">
                                    <Translate
                                        id='registration.settings.category.label.federation.equivalent'
                                        values={{ name: displayI18n('name', parentCategory?.organisation.i18n, parentCategory?.organisation.organisation_name, i18n.getGenericLocale()) }}
                                    />
                                </div>
                                <div className="border bg-light p-2">
                                    <Row>
                                        <Col xs="4">
                                            <div className="mb-3">
                                                <div className=""><Translate id='registration.settings.category.label.division' /></div>
                                                <div className="font-medium text-dark">{parentCategory?.division?.short_name}</div>
                                            </div>
                                        </Col>
                                        <Col xs="4">
                                            <div className="mb-3">
                                                <div className=""><Translate id='registration.settings.category.label.class' /></div>
                                                <div className="font-medium text-dark">{parentCategory?.class?.short_name}</div>
                                            </div>
                                        </Col>
                                        <Col xs="4">
                                            <div className="mb-3">
                                                <div className=""><Translate id='registration.settings.category.label.gender' /></div>
                                                <div className="font-medium text-dark">
                                                    {parentCategory?.gender ?
                                                        <Translate id={`registration.settings.category.table.gender.${parentCategory?.gender.toLowerCase()}`} />
                                                        :
                                                        '-'
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs="4">
                                            <div className="mb-3">
                                                <div className=""><Translate id='registration.settings.category.label.minAge' /></div>
                                                <div className="font-medium text-dark">{parentCategory?.min_age || '-'}</div>
                                            </div>
                                        </Col>
                                        <Col xs="4">
                                            <div className="mb-3">
                                                <div className=""><Translate id='registration.settings.category.label.maxAge' /></div>
                                                <div className="font-medium text-dark">{parentCategory?.max_age || '-'}</div>
                                            </div>
                                        </Col>
                                        {/* <Col xs="4">
                                            <div className="mb-3">
                                                <div className=""><Translate id='registration.settings.category.label.startDate' /></div>
                                                <div className="font-medium text-dark">December 31</div>
                                            </div>
                                        </Col> */}
                                    </Row>
                                </div>
                            </Collapse>
                            <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                                <Alert color='danger' className='mb-0'>
                                    {formik.status}
                                </Alert>
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" type="submit"><Translate id='misc.save' /></Button>
                            <Button type="button" color="primary" onClick={toggleModal} outline><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </FormikForm>
            )}
        </Formik>
    )
}

export default ModalFormBranch;