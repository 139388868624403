import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { useContext, useEffect, useRef, useState } from 'react';
import { stringBuilder } from "@spordle/helpers";
import {
    Card,
    CardBody,
    Button,
    ModalHeader,
    ModalBody,
    Label,
    ModalFooter,
    Collapse,
    Input,
    Fade
} from "reactstrap";
import SpordleTableProvider, { SpordleTableContext, SpordleTableView, useSpordleTable } from '@spordle/datatables';
import { OtherFeesContext } from '../../../../../../contexts/OtherFeesContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserDisplay from '../../../../../../components/userDisplay/UserDisplay';
import { useFormikContext } from 'formik';
import NoImgItem from '../../../../../../assets/images/NoImage_Item.svg'
import UserImg from '../../../../../../components/UserImg';
import AnalyticsModal from '../../../../../../analytics/AnalyticsModal';
import { I18nContext } from '../../../../../../contexts/I18nContext';

import * as Sentry from "@sentry/react";

const OnlineStoreOtherItemsAdd = ({ isOpen, toggle }) => {
    const otherFeesContext = useContext(OtherFeesContext);
    const i18nContext = useContext(I18nContext);
    const tableRef = useRef(null);
    const perfectScrollBarRef = useRef(null);
    const otherItemListTable = useSpordleTable();
    const formik = useFormikContext();
    const [ collapseIsOpen, setCollapseIsOpen ] = useState(false);

    useEffect(() => {
        Sentry.addBreadcrumb({
            type: 'info',
            message: `Opened Add Modal - OnlineStoreOtherItemsAdd`,
            level: Sentry.Severity.Log,
            category: 'OnlineStore',
        });
    }, [])

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='OnlineStoreOtherItemsAdd'>
            <ModalHeader toggle={toggle}>
                <Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.modal.title' />
            </ModalHeader>
            <SpordleTableProvider
                id='OnlineStoreOtherItemsAddTable'
                ref={tableRef}
                searchKeys={[
                    `fee.i18n.${i18nContext.getGenericLocale()}.name`,
                    'fee.name',
                    'fee.sku',
                ]}
                dataIndex='other_fee_id'
                viewMode='GRID'
                gridConfig={{
                    col: { xs: 12 },
                    gridRender: (otherItem) => (
                        <Card className="card-shadow mb-2 clickable">
                            <CardBody>
                                <div className='d-flex align-items-center'>
                                    <div className="custom-control custom-checkbox" onClick={(e) => { e.preventDefault() }}>
                                        <input
                                            type="checkbox" id={`checkbox-otherItem-${otherItem.other_fee_id}`} className="custom-control-input"
                                            onChange={function(){}}// Prevents having an error in the console because this is a controlled input
                                            checked={!!otherItem.checked}// This props makes it the input a controlled input
                                        />
                                        <label className="custom-control-label" htmlFor={`checkbox-otherItem-${otherItem.other_fee_id}`} />
                                    </div>
                                    <UserImg
                                        className="rounded-lg border shadow-none"
                                        width={60}
                                        src={otherItem.image?.full_path || NoImgItem}
                                        filePos={otherItem?.image?.file_position}
                                        alt={otherItem.fee.name}
                                    />
                                    <div className='mr-auto ml-2'>
                                        <div className="d-flex align-items-center">
                                            <div className="font-bold h6 mb-0"><DisplayI18n i18n={otherItem.fee.i18n} field='name' defaultValue={otherItem.fee.name} /></div>
                                        </div>
                                        {
                                            otherItem?.active == '0' ?
                                                <div>  <div className='text-danger small'><i className='mdi mdi-alert-outline mr-1' /><Translate id='onlineStore.components.onlineStoreItemsFees.status.INACTIVE' /></div></div>
                                                :
                                                null
                                        }
                                        <div className='text-muted'>
                                            <DisplayI18n i18n={otherItem.fee.i18n} field='description' defaultValue={otherItem.fee.description} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="font-medium h6"><CurrencyFormat value={otherItem.fee.amount / 100} /></div>
                                    </div>


                                </div>
                            </CardBody>
                        </Card>
                    ),
                }}
                onColumnClick={(e, otherItem, spordleTable) => {
                    switch (e.button){
                        case 0: // Left mouse button
                            spordleTable.updateDataWith(otherItem.other_fee_id, { checked: !otherItem.checked })
                            break;
                    }
                }}
                loadData={(from) => {
                    switch (from){
                        case 'SEARCH':
                            perfectScrollBarRef.current.scrollTop = 0
                            perfectScrollBarRef.current.updateScroll();
                            break;
                        case 'CDM':
                            return otherFeesContext.getOtherItems().then((otherItems) => {
                                // Because cards rendered in gridRender are rendered over the scrollbar which is problematic
                                // TODO: Find a cleaner solution if possible
                                perfectScrollBarRef.current._ps.scrollbarYRail.style.zIndex = 10;
                                // Default checkes the items that are already in the list view
                                return otherItems.map((otherItem) => {
                                    const itemFromList = otherItemListTable.getData().find((item) => item.other_fee_id === otherItem.other_fee_id);

                                    if(itemFromList){
                                        return { ...itemFromList, checked: true };
                                    }
                                    return otherItem
                                })
                            });
                        default:
                            break;
                    }
                }}
            >
                <ModalBody>
                    <div className='mb-3'>
                        <Label for="search" className="text-muted"><Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.modal.searchLabel' /></Label>
                        <SpordleTableContext.Consumer>
                            {(spordleTable) => (
                                <div className='search-wrapper'>
                                    <Input type='search' value={spordleTable.getInputValue()} onChange={spordleTable.searchInputChange} />
                                    <button type='button' className={stringBuilder('reset-btn text-link search-clear', { 'd-none': !spordleTable.getInputValue() })} onClick={() => spordleTable.setInputValue()}><i className='mdi mdi-close' /></button>
                                </div>
                            )}
                        </SpordleTableContext.Consumer>
                    </div>
                    <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: "380px" }} ref={perfectScrollBarRef}>
                        <SpordleTableView />
                    </PerfectScrollbar>
                    <SpordleTableContext.Consumer>
                        {(spordleTable) => {
                            const checkedData = spordleTable.getData().filter((item) => !!item.checked);
                            // Displaying only if there is selected items
                            if(checkedData.length === 0)
                                return (
                                    <div className="border-top pt-3">
                                        <Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.modal.selectedItems' values={{ count: 0 }} />
                                    </div>
                                )
                            return (
                                <div className="border-top pt-3">
                                    <button type='button' onClick={() => setCollapseIsOpen(!collapseIsOpen)} className="reset-btn text-link">
                                        <Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.modal.selectedItems' values={{ count: checkedData.length }} />
                                        <i style={{ transform: `rotate(${collapseIsOpen ? "180" : "0"}deg)` }} className="ml-1 d-inline-block mdi mdi-chevron-down transition" />
                                    </button>
                                    <Collapse isOpen={collapseIsOpen} tag="ul" className="list-unstyled mb-0">
                                        {checkedData.map((otherItem) => (
                                            <Collapse className="pt-3" tag='li' appear isOpen key={otherItem.other_fee_id}>
                                                <Fade>
                                                    <Card className="card-shadow mb-2">
                                                        <CardBody className='d-flex align-items-center'>
                                                            <UserDisplay>
                                                                <UserDisplay.Container>
                                                                    <UserImg
                                                                        className="rounded-lg border shadow-none"
                                                                        width={60}
                                                                        src={otherItem.image?.full_path || NoImgItem}
                                                                        filePos={otherItem?.image?.file_position}
                                                                        alt={otherItem.fee.name}
                                                                    />
                                                                </UserDisplay.Container>
                                                                <UserDisplay.Container className="min-w-0">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="font-bold h6 mb-0"><DisplayI18n i18n={otherItem.fee.i18n} field='name' defaultValue={otherItem.fee.name} /></div>
                                                                        <div className="mx-2">|</div>
                                                                        <div className="font-medium"><CurrencyFormat value={otherItem.fee.amount / 100} /></div>
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        <DisplayI18n i18n={otherItem.fee.i18n} field='description' defaultValue={otherItem.fee.description} />
                                                                    </div>
                                                                    {
                                                                        otherItem?.active == '0' ?
                                                                            <div>  <div className='text-danger small'><i className='mdi mdi-alert-outline mr-1' /><Translate id='onlineStore.components.onlineStoreItemsFees.status.INACTIVE' /></div></div>
                                                                            :
                                                                            null
                                                                    }
                                                                </UserDisplay.Container>
                                                            </UserDisplay>
                                                            <button type='button' className='btn btn-link text-danger btn-sm ml-auto' onClick={() => { spordleTable.updateDataWith(otherItem.other_fee_id, { checked: !otherItem.checked }) }}>
                                                                <i className='fas fa-trash' />
                                                            </button>
                                                        </CardBody>
                                                    </Card>
                                                </Fade>
                                            </Collapse>
                                        ))}
                                    </Collapse>
                                </div>
                            )
                        }}
                    </SpordleTableContext.Consumer>
                </ModalBody>
            </SpordleTableProvider>
            <ModalFooter>
                <SpordleTableContext.Consumer>
                    {(spordleTable) => (
                        <Button
                            color='primary' onClick={() => {
                            // Removing the checked props which was used in this view but is not usefull in the list view.
                                spordleTable.setData(tableRef.current.getData()
                                    .filter((item) => !!item.checked)
                                    .map(({ checked, ...item }) => ({ display_order: '0', ...item, other_fee: item, linked_to: item.linked_to || [] })))
                                    .then(() => {
                                        formik.setFieldValue('otherItems', spordleTable.getData())
                                    })
                                    .then(toggle)
                            }}
                        >
                            <Translate id='misc.add' />
                        </Button>
                    )}
                </SpordleTableContext.Consumer>
                <Button color='primary' onClick={toggle} outline><Translate id='misc.cancel' /></Button>
            </ModalFooter>
        </AnalyticsModal>
    );
}

export default OnlineStoreOtherItemsAdd;