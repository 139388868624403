import { FormikCheckedButton } from "@spordle/formik-elements"
import { useFormikContext } from "formik"
import { FormGroup } from "reactstrap"
import { filterHasValue, formikCodesHaveValues, handleFilterChange } from "../reportsEngineHelpers"
import FilterLabel from "./FilterLabel"

const EngineCheckbox = ({ filter, filterRefs }) => {
    const formik = useFormikContext()
    return (
        <FormGroup className="">
            <FormikCheckedButton
                {...filter.parsedParams?.componentProps}
                id={filter.code}
                name={filter.code}
                label={<FilterLabel skipWrapper filter={filter} />}
                translateLabel={false}
                disabled={!formikCodesHaveValues(filter.parsedParams?.enabledWhen, formik) || !filterHasValue(filter.parsedParams?.enabledWhenValue?.filter, filter.parsedParams?.enabledWhenValue?.value, formik)}
                onChange={(e) => {
                    handleFilterChange(filter, formik, e.target.checked, filterRefs);
                    formik.setFieldValue(filter.code, e.target.checked);
                }}
                className={`w-100 ${filter.parsedParams?.componentProps.className}`}
                checked={formik.values[filter.code]}
            />
        </FormGroup>
    )
}

export default EngineCheckbox