import { Card } from "reactstrap";
import CardSectionTitle from "../../../../../../components/CardSectionTitle";
import { Skeleton } from "@mantine/core";
import Translate from "@spordle/intl-elements";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";
import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import { getOrganizationTaxNumbers } from "../../../../../../api/client/organization";
import { useContext } from "react";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import AddOrgTaxNumberModal from "./AddOrgTaxNumberModal";
import { useDisclosure } from "@mantine/hooks";
import OrganizationProfileTaxItem from "./OrganizationProfileTaxItem";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { displayI18n } from "../../../../../../helpers/i18nHelper";

const OrganizationProfileTaxes = () => {
    const { organisation_id } = useContext(OrganizationContext);
    const { getGenericLocale } = useContext(I18nContext);
    const [ isOpen, { open, close } ] = useDisclosure(false);

    return (
        <SpordleTableProvider
            dataIndex="tax_number_id"
            viewMode="GRID"
            gridConfig={{
                col: {
                    xs: 12,
                },
                row: {
                    form: true,
                },
                gridRender: (data) => {
                    return (
                        <OrganizationProfileTaxItem taxNumber={data} />
                    )
                },
            }}
            loadingMessage={<Skeleton height="1.5em" width="100%" />}
            emptyLayout={
                <div className="bg-light-inverse rounded border text-center px-3 pt-3">
                    <div className="h5 font-bold mb-1"><Translate id="organisation.profile.tabs.overview.noTaxNumber" /></div>
                    <p className="mb-0"><Translate id="organisation.profile.tabs.overview.noTaxNumber.txt" /></p>
                    <CanDoAction action="ADD" componentCode="organization" componentPermissionCode="organization_profile">
                        <button type="button" onClick={open} className="mdi mdi-plus with-icon btn btn-link pb-3"><Translate id="misc.add" /></button>
                    </CanDoAction>
                </div>
            }
            errorMessage={({ spordleTable }) => (
                <div className="bg-light-danger text-center rounded p-3 border border-danger text-danger">
                    <div className="h4 font-medium text-danger">Oops!</div>
                    <p className="mb-2">
                        <Translate id="misc.error" />
                    </p>
                    <button onClick={() => spordleTable.refreshTable()} type="button" className="btn btn-sm btn-danger rounded"><Translate id="misc.refresh" /> <i className="mdi mdi-refresh" /></button>
                </div>
            )}
            loadData={(from) => {
                switch (from){
                    case 'CDM':
                    case 'REFRESH':
                        const collator = new Intl.Collator(getGenericLocale());
                        return getOrganizationTaxNumbers(organisation_id)
                            .then((taxNumbers) => {
                                const numbers = [ ...taxNumbers ];
                                numbers.sort((a, b) => {
                                    const taxRateA = a.tax_rate;
                                    const taxRateB = b.tax_rate;
                                    const countryA = displayI18n("name", taxRateA.country.i18n, taxRateA.country.code, getGenericLocale());
                                    const countryB = displayI18n("name", taxRateB.country.i18n, taxRateB.country.code, getGenericLocale());

                                    const provinceA = displayI18n("name", taxRateA.province.i18n, taxRateA.province.code, getGenericLocale());
                                    const provinceB = displayI18n("name", taxRateB.province.i18n, taxRateB.province.code, getGenericLocale());

                                    const nameA = `${countryA}${provinceA}${displayI18n("name", taxRateA.i18n, taxRateA.name, getGenericLocale())}`;
                                    const nameB = `${countryB}${provinceB}${displayI18n("name", taxRateB.i18n, taxRateB.name, getGenericLocale())}`;

                                    return collator.compare(nameA, nameB);
                                })

                                return numbers;
                            })
                    default:
                        break;
                }
            }}
        >
            {(table) => (
                <Card body className="card-shadow">
                    <AddOrgTaxNumberModal
                        isOpen={isOpen}
                        toggle={close}
                        taxNumberList={table.getData()}
                        onSuccess={() => table.refreshTable()}
                    />
                    <CardSectionTitle className="d-flex align-items-center" title="settings.general.taxes.organizationRegionalTaxes.title">
                        <CanDoAction action="ADD" componentCode="organization" componentPermissionCode="organization_profile" className="ml-auto">
                            <button type="button" onClick={open} className="mdi mdi-plus ml-auto with-icon text-primary reset-btn"><Translate id="misc.add" /></button>
                        </CanDoAction>
                    </CardSectionTitle>
                    <SpordleTableView />
                </Card>
            )}
        </SpordleTableProvider>
    );
}

export default OrganizationProfileTaxes;