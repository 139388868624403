import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import CrossFade from "../../../../../components/crossFade/CrossFade";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../components/UserImg";
import { SupervisionsContext } from "../../../../../contexts/SupervisionsContext";
import { HoverableCopy } from "../../../../../helpers/clipboardHelper";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import SupervisionTypeResults from "../SupervisionTypeResults";

const AllOfficialReportsModal = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName="allOfficialReportsModal">
            <AllOfficialReportsModalInner {...props} />
        </AnalyticsModal>
    );
}

const AllOfficialReportsModalInner = (props) => {
    const [ isLoading, setIsLoading ] = useState(false);

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalHeader toggle={props.toggle}>
                <Translate id='officialSupervision.allMemberReports' values={{ name: `${props.member.first_name} ${props.member.last_name}` }} />
            </ModalHeader>
            <ModalBody>
                <AllOfficialReportsModalForm setIsLoading={setIsLoading} {...props} />
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.toggle} outline type="button" color="primary"><Translate id="misc.cancel" /></Button>
            </ModalFooter>
        </OverlayLoader>
    )
}

export const AllOfficialReportsModalForm = ({ toggle, pagination = 5, member = {} }) => {
    const { getSupervisionsList } = useContext(SupervisionsContext);
    const [ selectedForm, setSelectedForm ] = useState({
        info: null,
        isVisible: false,
    });

    return (
        <div>
            <SpordleTableProvider
                viewMode="GRID"
                dataIndex="supervision_id"
                id="supervisionListOfAnOfficial"
                emptyLayout={({ emptySearch }) => (
                    <div className="text-center py-2">
                        <i className="mdi mdi-book-open-page-variant fa-3x" />
                        <div className="font-bold h5 text-body"><Translate id='officialSupervision.allMemberReports.noReports.title' /></div>
                        <p className="text-muted">
                            <Translate id={`officialSupervision.allMemberReports.noReports.search.subtitle`} />
                        </p>
                    </div>
                )}
                loadingMessage={
                    <Skeleton count={pagination} height={110} />
                }
                pagination={pagination}
                gridConfig={{
                    col: {
                        xs: 12,
                    },
                    gridRender: (supervisionForm, spordleTable) => (
                        <UserDisplay card block className="mb-2 flex-wrap flex-md-nowrap">
                            <UserDisplay.Container className="mr-3">
                                <div className="bg-light border rounded-circle d-flex align-items-center justify-content-center" style={{ height: 50, width: 50 }}>
                                    <i className="text-dark fa-2x mdi mdi-format-list-bulleted" />
                                </div>
                            </UserDisplay.Container>
                            <UserDisplay.Container>
                                <UserDisplay.Subtitle>
                                    {supervisionForm.supervision_date}
                                </UserDisplay.Subtitle>
                                <UserDisplay.Title className="font-bold h5">
                                    <DisplayI18n field="name" defaultValue={supervisionForm.supervision_type.name} i18n={supervisionForm.supervision_type.i18n} />
                                </UserDisplay.Title>
                                <UserDisplay.Subtitle>
                                    <Translate id='officialSupervision.supervisedBy.label' /> <br /><span className="font-medium">{supervisionForm.officiating_coach.first_name} {supervisionForm.officiating_coach.last_name}</span>
                                </UserDisplay.Subtitle>
                            </UserDisplay.Container>
                            <UserDisplay.Container className="flex-shrink-0 w-100 w-md-auto ml-md-auto mt-md-0 mt-2">
                                <Button onClick={() => setSelectedForm({ info: supervisionForm, isVisible: true })} color="primary" type="button" size="sm">
                                    <Translate id="misc.results" /> <i className="mdi mdi-arrow-right" />
                                </Button>
                            </UserDisplay.Container>
                        </UserDisplay>
                    ),
                }}
                loadData={(from) => {
                    switch (from){
                        case 'CDM':
                            return getSupervisionsList({ member_id: member.member_id })
                                .then((reports) => {
                                    return reports.filter((r) => r.official.member_id === member.member_id)
                                });
                        default:
                            break;
                    }
                }}
            >
                <CrossFade isVisible={!selectedForm.isVisible || !selectedForm.info}>
                    <SpordleTableProvider.SearchInput className="mb-3" />
                    <SpordleTableView />
                </CrossFade>
                <CrossFade isVisible={!!selectedForm.isVisible && !!selectedForm.info}>
                    <OfficialReportPreview previous={() => setSelectedForm((prev) => ({ ...prev, isVisible: false }))} supervision={selectedForm.info} />
                </CrossFade>
            </SpordleTableProvider>
        </div>
    )
}

const OfficialReportPreview = ({ previous, supervision }) => {
    return (
        supervision ?
            <>
                <button onClick={previous} className="text-primary reset-btn mb-3" type="button">
                    <i className="mdi mdi-arrow-left" /> <Translate id='officialSupervision.allMemberReports.backToList' />
                </button>
                <div className="font-bold h3 mb-3">
                    <DisplayI18n field="name" defaultValue={supervision.supervision_type.name} i18n={supervision.supervision_type.i18n} />
                </div>
                <div className="font-bold h5"><Translate id='officialSupervision.officiatingCoach.label' /></div>
                <UserDisplay card block>
                    <UserDisplay.Container>
                        <UserImg
                            className="text-uppercase"
                            width={45}
                            alt={`${supervision.officiating_coach.first_name} ${supervision.officiating_coach.last_name}`}
                            abbr={`${supervision.officiating_coach.first_name.charAt(0)}${supervision.officiating_coach.last_name.charAt(0)}`}
                        />
                    </UserDisplay.Container>
                    <UserDisplay.Container>
                        <UserDisplay.Title>{supervision.officiating_coach.first_name} {supervision.officiating_coach.last_name}</UserDisplay.Title>
                        <UserDisplay.Subtitle>
                            <HoverableCopy toCopy={supervision.officiating_coach.unique_identifier}>
                                #{supervision.officiating_coach.unique_identifier}
                            </HoverableCopy>
                        </UserDisplay.Subtitle>
                    </UserDisplay.Container>
                </UserDisplay>
                <div className="font-bold h5">Info</div>
                <Row form className="mb-1">
                    <Col xs="6" className="mb-3">
                        <div className="text-muted"><Translate id='officialSupervision.homeTeam.label' /></div>
                        <div>{supervision.home_team?.short_name || supervision.home_team_name || "-"}</div>
                    </Col>
                    <Col xs="6" className="mb-3">
                        <div className="text-muted"><Translate id='officialSupervision.awayTeam.label' /></div>
                        <div>{supervision.away_team?.short_name || supervision.away_team_name || "-"}</div>
                    </Col>
                    <Col xs="6" className="mb-3">
                        <div className="text-muted"><Translate id='officialSupervision.season.label' /></div>
                        <div><DisplayI18n field="name" i18n={supervision.period.i18n} defaultValue={supervision.period.name} /></div>
                    </Col>
                    <Col xs="6" className="mb-3">
                        <div className="text-muted">Date</div>
                        <div>{supervision.supervision_date}</div>
                    </Col>
                    <Col xs="12" className="mb-3">
                        <div className="text-muted"><Translate id='officialSupervision.teamCategory.label' /></div>
                        <div>{supervision.team_category?.name}</div>
                    </Col>
                </Row>
                <SupervisionTypeResults noFormik supervision={supervision} isReadOnly />
                <div className="font-bold h5">Notes</div>
                <div className="text-muted"><Translate id='officialSupervision.strengths.label' /></div>
                <textarea className="form-control bg-light-inverse mb-3" readOnly value={supervision.strengths || ""} />
                <div className="text-muted"><Translate id='officialSupervision.areaOfImprovement.label' /></div>
                <textarea className="form-control bg-light-inverse mb-3" readOnly value={supervision.areas_of_improvements || ""} />
                <div className="text-muted"><Translate id='officialSupervision.comments.label' /></div>
                <textarea className="form-control bg-light-inverse mb-3" readOnly value={supervision.comments || ""} />
                <div className="text-muted"><Translate id='officialSupervision.privateComment.label' /></div>
                <textarea className="form-control bg-light-inverse mb-3" readOnly value={supervision.private_comment || ""} />
            </>
            :
            null
    )
}

export default AllOfficialReportsModal;