import { FormikDateTime, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Translate, { DateFormat } from '@spordle/intl-elements';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import {
    Col, Row
} from "reactstrap";
import { object, mixed, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../../../../../api/CancellableAPI';
import FormikEditable from '../../../../../../../../../components/formik/FormikEditable';
import { fail, success } from '@spordle/toasts';
import { AppealsContext } from '../../../../../../../../../contexts/AppealsContext';
import { PeriodsContext } from '../../../../../../../../../contexts/contexts';
import { I18nContext } from '../../../../../../../../../contexts/I18nContext';
// contexts
import { MembersContext } from '../../../../../../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../../../../../../contexts/OrganizationContext';
import { displayI18n, DisplayI18n } from '../../../../../../../../../helpers/i18nHelper';
import { RegistrationCategoriesContext } from '../../../../../../../../../contexts/RegistrationCategoriesContext';

const AppealsSidepanel1General = (props) => {
    const membersContext = useContext(MembersContext);
    const appealsContext = useContext(AppealsContext);
    const periodsContext = useContext(PeriodsContext);
    const organizationContext = useContext(OrganizationContext);
    const { getGenericLocale } = useContext(I18nContext);
    const categoriesContext = useContext(RegistrationCategoriesContext);

    const [ appealStatus, setAppealStatus ] = useState(false);
    const [ periods, setPeriods ] = useState(false);
    const [ categories, setCategories ] = useState(false);
    const [ regulations, setRegulations ] = useState(false);
    const [ approves, setApproves ] = useState(false);
    const [ types, setTypes ] = useState(false);
    const [ showNote, setShowNote ] = useState(props.selectedRows[0].appeal_type?.with_note == 1);

    const getAppealStatus = () => {
        return appealsContext.getAppealStatus()
            .then((status) => setAppealStatus(status.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setAppealStatus([]);
                }
            })
    }

    const getPeriods = () => {
        return periodsContext.getOrganizationPeriods(organizationContext.organisation_id)
            .then(setPeriods)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setPeriods([]);
                }
            })
    }

    const getCategories = () => {
        return categoriesContext.getRegistrationCategories(organizationContext.organisation_id)
            .then((cats) => setCategories(cats.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setCategories([]);
                }
            })
    }

    const getRegulations = () => {
        return membersContext.getAppealRegulations()
            .then((regulations) => setRegulations(regulations.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setRegulations([]);
                }
            })
    }

    const getApproves = () => {
        return appealsContext.getAppealApproves()
            .then((approves) => setApproves(approves.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setApproves([]);
                }
            })
    }

    const getTypes = () => {
        return appealsContext.getAppealTypes()
            .then((types) => setTypes(types.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setTypes([]);
                }
            })
    }

    useEffect(() => {
        props.setGeneralLoading(true)
        Promise.all([
            getAppealStatus(),
            getPeriods(),
            getCategories(),
            getRegulations(),
            getApproves(),
            getTypes(),
        ])
            .catch(console.error)
            .finally(() => props.setGeneralLoading(false))
    }, [])

    return (
        <>
            <Col sm="6">
                <div className="mb-3">
                    <div className="text-muted"><Translate id='members.profile.appeals.submittedDate' /></div>
                    <FormikEditable
                        readOnly // not updateable in the API
                        id='appealDate'
                        initialValues={{
                            appealDate: moment(props.selectedRows[0].appeal_date),
                        }}
                        validationSchema={object().shape({
                            appealDate: mixed().required(<Translate id='members.profile.appeals.submittedDate.required' />)
                                .test({
                                    name: 'appealDateFormat',
                                    message: <Translate id='form.validation.date.format' />,
                                    test: moment.isMoment,
                                }),
                        })}
                        onSubmit={(values) => {
                            const newDate = values.appealDate.toISOString();
                            if(newDate !== props.selectedRows[0].appeal_date){
                                props.setGeneralLoading(true)
                                const newValues = props.createNewValues({ appeal_date: newDate })
                                membersContext.updateMemberAppealPartial(
                                    membersContext.currentMember.member_id,
                                    props.selectedRows[0].member_appeal_id,
                                    {
                                        appeal_date: newDate,
                                    },
                                )
                                    .then(() => {
                                        props.syncRows(newValues)
                                        success();
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })
                                    .finally(() => props.setGeneralLoading(false))
                            }
                        }}
                    >
                        {(isEditing) => {
                            if(!isEditing){
                                return (
                                    <div className="font-medium text-dark"><DateFormat value={props.selectedRows[0].appeal_date} /></div>
                                )
                            }
                            return (
                                <FormikDateTime
                                    id='appealDate' name='appealDate'
                                    timeFormat={false}
                                />
                            )

                        }}
                    </FormikEditable>
                </div>
            </Col>

            <Col sm="6">
                <div className="mb-3">
                    <div className="text-muted"><Translate id='members.profile.appeals.status' /></div>
                    <FormikEditable
                        id='appealStatus'
                        noConfirmation
                        initialValues={{
                            appealStatus: props.selectedRows[0].appeal_status.appeal_status_id,
                        }}
                        validationSchema={object().shape({
                            appealStatus: string().required(<Translate id='members.profile.appeals.sidepanel.add.status.required' />),
                        })}
                        onSubmit={(values) => {
                            if(values.appealStatus !== props.selectedRows[0].appeal_status.appeal_status_id){
                                props.setGeneralLoading(true)
                                const newValues = props.createNewValues({ appeal_status: appealStatus.find((status) => status.appeal_status_id === values.appealStatus) })
                                membersContext.updateMemberAppealPartial(
                                    membersContext.currentMember.member_id,
                                    props.selectedRows[0].member_appeal_id,
                                    {
                                        appeal_status_id: values.appealStatus,
                                    },
                                )
                                    .then(() => {
                                        props.syncRows(newValues)
                                        success();
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })
                                    .finally(() => props.setGeneralLoading(false))
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (
                                    <div className="font-medium text-dark"><DisplayI18n field='name' defaultValue={props.selectedRows[0].appeal_status.name} i18n={props.selectedRows[0].appeal_status.i18n} /></div>
                                )
                            }
                            return (
                                <FormikSelect
                                    name='appealStatus'
                                    id='appealStatus'
                                    renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                    onOptionSelected={() => options.stopEditing()}
                                    menuIsDefaultOpen
                                    autoFocus
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    options={appealStatus ?
                                        appealStatus.reduce((keptStatus, stat) => {
                                            if(stat.active == 1){
                                                keptStatus.push({
                                                    value: stat.appeal_status_id,
                                                    i18n: stat.i18n,
                                                    label: stat.name,
                                                })
                                            }
                                            return keptStatus;
                                        }, [])
                                        :
                                        []
                                    }
                                />
                            )

                        }}
                    </FormikEditable>
                </div>
            </Col>

            <Col sm="6">
                <div className="mb-3">
                    <div className="text-muted"><Translate id='members.profile.appeals.period' /></div>
                    <FormikEditable
                        readOnly // not updateable in the API
                        id='period'
                        noConfirmation
                        initialValues={{
                            period: props.selectedRows[0].period.period_id,
                        }}
                        validationSchema={object().shape({
                            period: string().required(<Translate id='members.profile.appeals.sidepanel.add.period.required' />),
                        })}
                        onSubmit={(values) => {
                            if(values.period !== props.selectedRows[0].period.period_id){
                                props.setGeneralLoading(true)
                                const newValues = props.createNewValues({ period: periods.find((period) => period.period_id === values.period) })
                                membersContext.updateMemberAppealPartial(
                                    membersContext.currentMember.member_id,
                                    props.selectedRows[0].member_appeal_id,
                                    {
                                        period_id: values.period,
                                    },
                                )
                                    .then(() => {
                                        props.syncRows(newValues)
                                        success();
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })
                                    .finally(() => props.setGeneralLoading(false))
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (
                                    <div className="font-medium text-dark"><DisplayI18n field='name' defaultValue={props.selectedRows[0].period.name} i18n={props.selectedRows[0].period.i18n} /></div>
                                )
                            }
                            return (
                                <FormikSelect
                                    name='period'
                                    id='period'
                                    renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                    onOptionSelected={() => options.stopEditing()}
                                    menuIsDefaultOpen autoFocus
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    options={periods ?
                                        periods.reduce((keptPeriods, period) => {
                                            if(period.active == 1){
                                                keptPeriods.push({
                                                    label: period.name,
                                                    i18n: period.i18n,
                                                    value: period.period_id,
                                                })
                                            }
                                            return keptPeriods;
                                        }, [])
                                        :
                                        []
                                    }
                                />
                            )

                        }}
                    </FormikEditable>
                    <div className="font-medium text-dark">{props.selectedRows[0].fromSeason}</div>
                </div>
            </Col>
            <Col sm='6'>
                <div className="mb-3">
                    <div className="text-muted"><Translate id='members.profile.appeals.category' /></div>
                    <FormikEditable
                        id='category'
                        noConfirmation
                        initialValues={{
                            category: props.selectedRows[0].team_category?.team_category_id || '',
                        }}
                        validationSchema={object().shape({
                            category: string().required(<Translate id='members.profile.appeals.category.required' />),
                        })}
                        onSubmit={(values) => {
                            if(values.category !== props.selectedRows[0].team_category?.team_category_id){
                                props.setGeneralLoading(true)
                                const newValues = props.createNewValues({ team_category: categories.find((cat) => cat.team_category_id === values.category) })
                                membersContext.updateMemberAppealPartial(
                                    membersContext.currentMember.member_id,
                                    props.selectedRows[0].member_appeal_id,
                                    {
                                        team_category_id: values.category,
                                    },
                                )
                                    .then(() => {
                                        props.syncRows(newValues);
                                        success();
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })
                                    .finally(() => props.setGeneralLoading(false))
                            }
                        }}
                    >
                        {(isEditing, options, formik) => {
                            if(!isEditing){
                                return (
                                    <div className="font-medium text-dark">
                                        <DisplayI18n field='short_name' defaultValue={props.selectedRows[0].team_category?.division?.short_name} i18n={props.selectedRows[0].team_category?.division?.i18n} />
                                        {props.selectedRows[0].team_category?.class &&
                                            <>
                                                {' - '}<DisplayI18n field='short_name' defaultValue={props.selectedRows[0].team_category.class.short_name} i18n={props.selectedRows[0].team_category.class.i18n} />
                                            </>
                                        }
                                    </div>
                                )
                            }
                            return (
                                <FormikSelect
                                    name='category'
                                    id='category'
                                    onOptionSelected={() => options.stopEditing()}
                                    menuIsDefaultOpen
                                    autoFocus
                                    loadData={(from) => {
                                        switch (from){
                                            case 'CDM':
                                            case 'FILTER':
                                                return Promise.resolve(categories.reduce((keptCat, category) => {
                                                    if(category.active == 1){
                                                        const divisionLabel = displayI18n('short_name', category.division.i18n, category.division.short_name, getGenericLocale())

                                                        keptCat.push({
                                                            value: category.team_category_id,
                                                            label: category.class ?
                                                                divisionLabel + ' - ' + displayI18n('short_name', category.class.i18n, category.class.short_name, getGenericLocale())
                                                                : divisionLabel,
                                                        })
                                                    }
                                                    return keptCat;
                                                }, []));
                                            default:
                                                break;
                                        }
                                    }}
                                />
                            )
                        }}
                    </FormikEditable>
                </div>
            </Col>
            <Col sm='6'>
                <div className="mb-3">
                    <div className="text-muted"><Translate id='members.profile.appeals.regulation' /></div>
                    <FormikEditable
                        id='regulation'
                        readOnly // not updateable in the API
                        noConfirmation
                        initialValues={{
                            regulationId: props.selectedRows[0].appeal_regulation?.appeal_regulation_id || '',
                        }}
                        validationSchema={object().shape({
                            regulationId: string().required(<Translate id='members.profile.appeals.regulation.required' />),
                        })}
                        onSubmit={(values) => {
                            if(values.regulationId !== props.selectedRows[0].appeal_regulation?.appeal_regulation_id){
                                props.setGeneralLoading(true)
                                const newValues = props.createNewValues({ appeal_regulation: regulations.find((reg) => reg.appeal_regulation_id === values.regulationId) })
                                membersContext.updateMemberAppealPartial(
                                    membersContext.currentMember.member_id,
                                    props.selectedRows[0].member_appeal_id,
                                    {
                                        appeal_regulation_id: values.regulationId,
                                    },
                                )
                                    .then(() => {
                                        props.syncRows(newValues);
                                        success();
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })
                                    .finally(() => props.setGeneralLoading(false))
                            }
                        }}
                    >
                        {(isEditing, options, formik) => {
                            if(!isEditing){
                                if(props.selectedRows[0].appeal_regulation){
                                    return (
                                        <div className='font-medium text-dark'>
                                            <DisplayI18n field='name' defaultValue={props.selectedRows[0].appeal_regulation?.name} i18n={props.selectedRows[0].appeal_regulation?.i18n} />
                                        </div>
                                    )
                                }
                                return '-'
                            }
                            return (
                                <FormikSelect
                                    name='regulationId'
                                    id='regulationId'
                                    renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                    onOptionSelected={() => options.stopEditing()}
                                    menuIsDefaultOpen
                                    autoFocus
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    options={regulations ?
                                        regulations.reduce((newArray, regulation) => {
                                            if(regulation.active == 1){
                                                newArray.push({
                                                    value: regulation.appeal_regulation_id,
                                                    label: regulation.name,
                                                    i18n: regulation.i18n,
                                                })
                                            }
                                            return newArray;
                                        }, [])
                                        :
                                        []
                                    }
                                />
                            )
                        }}
                    </FormikEditable>
                </div>
            </Col>
            <Col sm='6'>
                <div className="mb-3">
                    <div className="text-muted"><Translate id='members.profile.appeals.approved' /></div>
                    <FormikEditable
                        id='approved'
                        noConfirmation
                        initialValues={{
                            approveId: props.selectedRows[0].appeal_approved?.appeal_approve_id || '',
                        }}
                        validationSchema={object().shape({
                            approveId: string().required(<Translate id='members.profile.appeals.sidepanel.add.approve.required' />),
                        })}
                        onSubmit={(values) => {
                            if(values.approveId !== props.selectedRows[0].appeal_approved?.appeal_approve_id){
                                props.setGeneralLoading(true)
                                const newValues = props.createNewValues({ appeal_approved: approves.find((app) => app.appeal_approve_id === values.approveId) })
                                membersContext.updateMemberAppealPartial(
                                    membersContext.currentMember.member_id,
                                    props.selectedRows[0].member_appeal_id,
                                    {
                                        appeal_approve_id: values.approveId,
                                    },
                                )
                                    .then(() => {
                                        props.syncRows(newValues);
                                        success();
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })
                                    .finally(() => props.setGeneralLoading(false))
                            }
                        }}
                    >
                        {(isEditing, options, formik) => {
                            if(!isEditing){
                                if(props.selectedRows[0].appeal_approved){
                                    return (
                                        <div className='font-medium text-dark'>
                                            <DisplayI18n field='name' defaultValue={props.selectedRows[0].appeal_approved?.name} i18n={props.selectedRows[0].appeal_approved?.i18n} />
                                        </div>
                                    )
                                }
                                return '-'
                            }
                            return (
                                <FormikSelect
                                    name='approveId'
                                    id='approveId'
                                    renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                    onOptionSelected={() => options.stopEditing()}
                                    menuIsDefaultOpen
                                    autoFocus
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    options={approves ?
                                        approves.reduce((newArray, approve) => {
                                            if(approve.active == 1){
                                                newArray.push({
                                                    value: approve.appeal_approve_id,
                                                    label: approve.name,
                                                    i18n: approve.i18n,
                                                })
                                            }
                                            return newArray;
                                        }, [])
                                        :
                                        []
                                    }
                                />
                            )
                        }}
                    </FormikEditable>
                </div>
            </Col>
            <Col sm='12'>
                <div className="mb-0">
                    <FormikEditable
                        id='type'
                        manualIcon
                        initialValues={{
                            typeId: props.selectedRows[0].appeal_type?.appeal_type_id || '',
                            typeNote: props.selectedRows[0].appeal_type_note || '',
                        }}
                        validationSchema={object().shape({
                            typeId: string(),
                            typeNote: string(),
                        })}
                        onSubmit={(values) => {
                            const typeChanged = values.typeId !== props.selectedRows[0].appeal_type?.appeal_type_id;
                            const noteChanged = values.typeNote !== props.selectedRows[0].appeal_type_note
                            if(typeChanged || noteChanged){
                                props.setGeneralLoading(true)
                                const newValues = props.createNewValues({
                                    appeal_type: types.find((type) => type.appeal_type_id === values.typeId),
                                    appeal_type_note: values.typeNote,
                                })
                                membersContext.updateMemberAppealPartial(
                                    membersContext.currentMember.member_id,
                                    props.selectedRows[0].member_appeal_id,
                                    {
                                        appeal_type_id: values.typeId,
                                        appeal_type_note: values.typeNote,
                                    },
                                )
                                    .then(() => {
                                        props.syncRows(newValues);
                                        success();
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })
                                    .finally(() => props.setGeneralLoading(false))
                            }
                        }}
                    >
                        {(isEditing, options, formik) => {
                            if(!isEditing){
                                if(props.selectedRows[0].appeal_type){
                                    return (
                                        <div className='font-medium text-dark w-100'>
                                            <Row form>
                                                <Col sm='6'>
                                                    <div className="text-muted font-normal"><Translate id='members.profile.appeals.type' /></div>
                                                    <DisplayI18n field='name' defaultValue={props.selectedRows[0].appeal_type?.name} i18n={props.selectedRows[0].appeal_type?.i18n} />
                                                </Col>
                                                <Col sm='6' className='d-flex align-items-center'>
                                                    {showNote &&
                                                        <div>
                                                            <div className="text-muted font-normal"><Translate id='members.profile.appeals.typeNote' /></div>
                                                            {props.selectedRows[0].appeal_type_note}
                                                        </div>
                                                    }
                                                    <i className='mdi mdi-pencil text-primary ml-2' />
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                }
                                return '-'
                            }
                            return (
                                <>
                                    <div className='mb-3'>
                                        <Row form>
                                            <Col sm='6'>
                                                <div className="text-muted"><Translate id='members.profile.appeals.type' /></div>
                                                <FormikSelect
                                                    name='typeId'
                                                    id='typeId'
                                                    renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                                    onOptionSelected={(values) => {
                                                        setShowNote(types.find((type) => type.appeal_type_id === values?.[0])?.with_note == 1)
                                                        formik.setFieldValue('typeNote', '');
                                                    }}
                                                    clearable
                                                    searchKeys={[
                                                        `i18n.${getGenericLocale()}.name`,
                                                    ]}
                                                    options={types ?
                                                        types.reduce((newArray, type) => {
                                                            if(type.active == 1){
                                                                newArray.push({
                                                                    value: type.appeal_type_id,
                                                                    label: type.name,
                                                                    i18n: type.i18n,
                                                                })
                                                            }
                                                            return newArray;
                                                        }, [])
                                                        :
                                                        []
                                                    }
                                                />
                                            </Col>
                                            <Col sm='6'>
                                                {showNote &&
                                                    <>
                                                        <div className="text-muted"><Translate id='members.profile.appeals.typeNote' /></div>
                                                        <FormikTextArea name='typeNote' id='typeNote' rows={1} trim />
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                            )
                        }}
                    </FormikEditable>
                </div>
            </Col>
            {/* <Col sm='12'>
                <CrossFade isVisible={showNote}>
                    <div className="mb-0">
                        <div className="text-muted"><Translate id='members.profile.appeals.typeNote' /></div>
                        <FormikEditable
                            id='typeNote'
                            initialValues={{
                                typeNote: props.selectedRows[0].appeal_type_note || '',
                            }}
                            validationSchema={object().shape({
                                typeNote: string(),
                            })}
                            onSubmit={(values) => {
                                if(values.typeNote !== props.selectedRows[0].appeal_type_note){
                                    props.setGeneralLoading(true)
                                    const newValues = props.createNewValues({ appeal_type_note: values.typeNote })
                                    membersContext.updateMemberAppealPartial(
                                        membersContext.currentMember.member_id,
                                        props.selectedRows[0].member_appeal_id,
                                        {
                                            appeal_type_note: values.typeNote,
                                        },
                                    )
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                        .finally(() => props.setGeneralLoading(false))
                                }
                            }}
                        >
                            {(isEditing, options, formik) => {
                                if(!isEditing){
                                    if(props.selectedRows[0].appeal_type_note){
                                        return (
                                            <div className='font-medium text-dark'>
                                                {props.selectedRows[0].appeal_type_note}
                                            </div>
                                        )
                                    }
                                    return '-'
                                }
                                return (
                                    <FormikTextArea name='typeNote' id='typeNote' rows={1} trim />
                                )
                            }}
                        </FormikEditable>
                    </div>
                </CrossFade>
            </Col> */}
        </>
    );
}

export default AppealsSidepanel1General;