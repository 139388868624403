import { useSpordleTable } from '@spordle/datatables';
import { addExtension, FormikAddress, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect, getFormikAddressInitialValues } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import {
    Button,
    Col,
    Collapse,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import { object, string } from 'yup';
import AnalyticsModal from "../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import Required from '../../../components/formik/Required';
import OverlayLoader from "../../../components/loading/OverlayLoader";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { success } from '@spordle/toasts';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import CustomAlert from '../../../components/CustomAlert';
import { I18nContext } from '../../../contexts/I18nContext';

const PayeesAddModal = ({ isOpen, toggle }) => {

    const spordleTable = useSpordleTable()
    const organizationContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext)

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='PayeesAddModal'>
            <Formik
                initialValues={{
                    organisation_id: organizationContext.organisation_id,
                    first_name: '',
                    last_name: '',
                    business_name: '',
                    service_type_id: '',
                    phone: '',
                    extension: '',
                    email: '',
                    prefix: '',
                    unit_number: '',
                    address: getFormikAddressInitialValues(),
                }}
                validationSchema={object().shape({
                    first_name: string().when('business_name', {
                        is: (val) => !val,
                        then: (schema) => schema.required(<Translate id='form.validation.firstName.required' />),
                    }),
                    last_name: string().when('business_name', {
                        is: (val) => !val,
                        then: (schema) => schema.required(<Translate id='form.validation.lastName.required' />),
                    }),
                    business_name: string(),
                    service_type_id: string(),
                    address: object().address(true, {
                        streetNumber: <Translate id='form.validation.streetNumber.required' />,
                        address: <Translate id='form.validation.address.required' />,
                        city: <Translate id='form.validation.city.required' />,
                        zipCode: <Translate id='form.validation.zip.required' />,
                        state: <Translate id='form.validation.province.required' />,
                        country: <Translate id='form.validation.country.required' />,
                    }),
                    phone: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />),
                    email: string().email(<Translate id='form.validation.email.valid' />),
                })}
                onSubmit={async({ address, ...values }, { setSubmitting, setStatus }) => {
                    const apiValues = {
                        ...values,
                        phone: await addExtension(values.phone, values.extension),
                        street_number: address.streetNumber,
                        street: address.address,
                        city: address.city,
                        zip_code: address.zipCode,
                        country_code: address.country,
                        province_code: address.state,
                        map_url: address.mapsUrl,
                        origin_address: address.origin,
                    };

                    await organizationContext.createPayee(apiValues)
                        .then(() => {
                            spordleTable.refreshTable();
                            toggle();
                            success();
                        })
                        .catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                console.error(e.message);
                                setStatus(<DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />)
                            }
                        })
                }}
            >
                {(formik) => {
                    return (
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <Form>
                                <ModalHeader toggle={toggle}><Translate id='settings.payees.add.title' /></ModalHeader>
                                <ModalBody>
                                    <Row form>
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label for='first_name' className='text-muted'><Translate id='form.fields.firstName' /> {!formik.values.business_name && <Required />}</Label>
                                                <FormikInputText id='first_name' name='first_name' trim />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label for='last_name' className='text-muted'><Translate id='form.fields.lastName' /> {!formik.values.business_name && <Required />}</Label>
                                                <FormikInputText id='last_name' name='last_name' trim />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label for='email' className='text-muted'><Translate id='form.fields.email' /></Label>
                                                <FormikInputText id='email' name='email' trim />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label for='service_type_id' className='text-muted'><Translate id='settings.payees.serviceType' /></Label>
                                                <FormikSelect
                                                    id='service_type_id' name='service_type_id'
                                                    searchKeys={[
                                                        `i18n.${i18nContext.getGenericLocale()}.name`,
                                                    ]}
                                                    renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                    loadData={(from, extra, spordleTable) => {
                                                        switch (from){
                                                            case 'CDM':
                                                                return organizationContext.getServiceTypes()
                                                                    .then((types) => types.sort((aT, bT) => aT.display_order - bT.display_order).map((type) => ({
                                                                        value: type.service_type_id,
                                                                        label: type.name,
                                                                        i18n: type.i18n,
                                                                    })))
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label for='prefix' className='text-muted'><Translate id='settings.payees.prefix' /></Label>
                                                <FormikSelect
                                                    id='prefix' name='prefix'
                                                    loadingStatus='success'
                                                    clearable
                                                    defaultData={[
                                                        { value: 'Dr.', label: 'settings.payees.prefix.DR.', translateLabel: true },
                                                        { value: 'Mr.', label: 'settings.payees.prefix.MR.', translateLabel: true },
                                                        { value: 'Mrs.', label: 'settings.payees.prefix.MRS.', translateLabel: true },
                                                    ]}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label for='businessName' className='text-muted'><Translate id='settings.payees.businessName' /> {!formik.values.first_name && !formik.values.last_name && <Required />}</Label>
                                                <FormikInputText
                                                    id="businessName"
                                                    name="business_name"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label for='phone' className='text-muted'><Translate id='form.fields.phone' /></Label>
                                                <FormikPhoneInput id='phone' name='phone' />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label for='extension' className='text-muted'><Translate id='form.fields.extension' /></Label>
                                                <FormikInputNumber id='extension' name='extension' allowNegative={false} />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <FormikAddress
                                        id='address' name='address'
                                        required
                                        allowManualPlace
                                        allowOpenInMaps
                                        label='form.fields.address'
                                        labelClassName='text-muted'
                                    />

                                    <FormGroup>
                                        <Label for='unit_number' className='text-muted'><Translate id='form.fields.address' /> 2 (<Translate id='form.fields.address2.placeholder' />)</Label>
                                        <FormikInputText id='unit_number' name='unit_number' trim />
                                    </FormGroup>

                                    <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                        {formik.status &&
                                            <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                        }
                                    </Collapse>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                    <Button color='primary' type='button' outline onClick={toggle} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </Form>
                        </OverlayLoader>
                    )
                }}
            </Formik>
        </AnalyticsModal>
    )
}

export default PayeesAddModal;