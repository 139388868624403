import React from "react";
import {
    Card, CardBody
} from 'reactstrap';
import { MembersContext } from '../../../../../../contexts/MembersContext';
import withContexts from '../../../../../../helpers/withContexts';
import Translate from '@spordle/intl-elements';
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../components/UserImg";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import AddFamilyRelation from './AddFamilyRelation';
import SidePanelMemberFamilyRelations from './SidePanelMemberFamilyRelations';
import { stringBuilder } from "@spordle/helpers";
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { UtilsContext } from '../../../../../../contexts/UtilsContext';
import images from '@spordle/ui-kit';
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import SpordlePanelTable from "../../../../../../components/sidePanel/SpordlePanel";
import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import CardSectionTitle from "../../../../../../components/CardSectionTitle";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";
const noProfileLogo = images.noprofile;

class MemberFamilyRelations extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            addFamilyRelation: false,
            relations: [],

            loading: true,
        };
    }

    toggleAddFamilyRelation = () => {
        this.setState((prevState) => ({ addFamilyRelation: !prevState.addFamilyRelation }))
    }

    relationAlreadyExists = (originMemberId, linkedMemberId) => {
        return !!this.relationsTableRef.getData().find((tableRelation) => {
            return tableRelation.memberRelationId !== 'template' &&
            ((tableRelation.linkedMember.memberId === linkedMemberId && tableRelation.originMember.memberId === originMemberId) || // exact same relation
            (tableRelation.linkedMember.memberId === originMemberId && tableRelation.originMember.memberId === linkedMemberId)) // same relation, but switched, was created from another member
        })
    }

    componentDidMount(){
        this.props.UtilsContext.getRelations()
            .then((relations) => {
                this.setState(() => ({ relations: relations, loading: false }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    render(){
        return (
            <>
                <AddFamilyRelation
                    isOpen={this.state.addFamilyRelation}
                    toggle={this.toggleAddFamilyRelation}
                    relationAlreadyExists={this.relationAlreadyExists}
                    refreshTable={this.relationsTableRef?.refreshTable}
                    relations={this.state.relations}
                />

                <Card className="card-shadow">
                    <OverlayLoader isLoading={this.state.loading}>
                        <CardBody>
                            <CardSectionTitle title='members.profile.overview.memberFamilyRelations.title' />

                            <SpordlePanelTable
                                allowOutsideClick
                                sidePanel={(props) => <SidePanelMemberFamilyRelations {...props} relations={this.state.relations} />}
                                dataIndex='memberRelationId'
                                table={(panelProps) => {
                                    return (
                                        <SpordleTableProvider
                                            key={this.props.MembersContext.currentMember.relations?.length}
                                            id='family-relations'
                                            tableHover clickable
                                            viewMode='GRID'
                                            ref={(r) => { this.relationsTableRef = r; panelProps.spordleTableRef(r); }}
                                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                            dataIndex='memberRelationId'
                                            onColumnClick={(e, relation) => {
                                                switch (e.button){
                                                    case 0: // Left mouse button
                                                        if(relation.memberRelationId === 'template'){
                                                            this.toggleAddFamilyRelation();
                                                        }else{
                                                            panelProps.onSingleSelectChange(relation);
                                                        }
                                                        break;
                                                }
                                            }}
                                            gridConfig={{
                                                col: {
                                                    sm: 12,
                                                    md: 6,
                                                    lg: 4,
                                                    className: 'mb-3',
                                                },
                                                gridRender: (relation) => {
                                                    if(relation.memberRelationId === 'template'){
                                                        return (
                                                            <UserDisplay
                                                                card
                                                                hover
                                                                className={stringBuilder('w-100 mb-0 border-primary', { 'shadow-danger': relation.errors })}
                                                                style={{ minWidth: 'unset', borderStyle: 'dashed', borderWidth: '1px' }}
                                                            >
                                                                <UserDisplay.Container>
                                                                    <div className="border rounded-circle shadow-sm p-2">
                                                                        <img className="object-fit-contain" src={relation.logo ?? noProfileLogo} width="30" height="30" alt={`${relation.firstName} ${relation.lastName}`} />
                                                                    </div>
                                                                </UserDisplay.Container>
                                                                <UserDisplay.Container>
                                                                    <UserDisplay.Title tag="span" className='text-link'><i className='mdi mdi-plus' /> <Translate id='members.profile.overview.memberFamilyRelations.add.title' /></UserDisplay.Title>
                                                                </UserDisplay.Container>
                                                            </UserDisplay>
                                                        )
                                                    }else if(relation.linkedMember.memberId !== this.props.MembersContext.currentMember.member_id){ // relation created from this member
                                                        return (
                                                            <UserDisplay card hover className='w-100 mb-0' style={{ minWidth: 'unset' }}>
                                                                <UserDisplay.Container>
                                                                    <UserImg
                                                                        abbr={`${relation.linkedMember.firstName.charAt(0)}${relation.linkedMember.lastName.charAt(0)}`}
                                                                        src={relation.linkedMember.logo}
                                                                        width='50'
                                                                        height='50'
                                                                        alt={`${relation.linkedMember.firstName} ${relation.linkedMember.lastName}`}
                                                                        className="p-2"
                                                                    />
                                                                </UserDisplay.Container>
                                                                <UserDisplay.Container>
                                                                    <UserDisplay.Title>{relation.linkedMember.firstName} {relation.linkedMember.lastName}</UserDisplay.Title>
                                                                    <UserDisplay.Subtitle>{relation.linkedMember.uniqueIdentifier ? '# ' + (relation.linkedMember.uniqueIdentifier) : ''}</UserDisplay.Subtitle>
                                                                    <UserDisplay.Subtitle><DisplayI18n field='name' defaultValue={relation.relation?.name} i18n={relation.relation?.i18n} /></UserDisplay.Subtitle>
                                                                </UserDisplay.Container>
                                                            </UserDisplay>
                                                        )
                                                    } // relation created from another member
                                                    return (
                                                    // no "hover" prop because this relation shouldn't be deleted or modified from this member, no need to click
                                                        <UserDisplay card hover className='w-100 mb-0' style={{ minWidth: 'unset' }}>
                                                            <UserDisplay.Container>
                                                                <UserImg
                                                                    abbr={`${relation.originMember.firstName.charAt(0)}${relation.originMember.lastName.charAt(0)}`}
                                                                    src={relation.originMember.logo}
                                                                    width='40'
                                                                    height='40'
                                                                    alt={`${relation.originMember.firstName} ${relation.originMember.lastName}`}
                                                                    className="p-2"
                                                                />
                                                            </UserDisplay.Container>
                                                            <UserDisplay.Container>
                                                                <UserDisplay.Title>{relation.originMember.firstName} {relation.originMember.lastName}</UserDisplay.Title>
                                                                <UserDisplay.Subtitle>{relation.originMember.uniqueIdentifier ? '# ' + (relation.originMember.uniqueIdentifier) : ''}</UserDisplay.Subtitle>
                                                                {/* other relation logic needs to be implemented here */}
                                                                {/* if "x" member adds "y" member as Father, than this relation needs to be displayed on "y" member's profile as Son/Daughter/Child */}
                                                                {/* <UserDisplay.Subtitle><DisplayI18n field='name' defaultValue={relation.relation?.name} i18n={relation.relation?.i18n} /></UserDisplay.Subtitle> */}
                                                            </UserDisplay.Container>
                                                        </UserDisplay>
                                                    )

                                                },
                                            }}
                                            desktopWhen='md'
                                            rowIsHighlighted={(relation) => !!relation.checked}
                                            loadData={(from, _, spordleTable) => {
                                                const formatRelations = (relations) => {
                                                    const relationsArray = relations.map((relation) => ({
                                                        linkedMember: {
                                                            firstName: relation.linked_member.first_name,
                                                            lastName: relation.linked_member.last_name,
                                                            uniqueIdentifier: relation.linked_member.unique_identifier,
                                                            memberId: relation.linked_member.member_id,
                                                        },
                                                        originMember: {
                                                            firstName: relation.origin_member.first_name,
                                                            lastName: relation.origin_member.last_name,
                                                            uniqueIdentifier: relation.origin_member.unique_identifier,
                                                            memberId: relation.origin_member.member_id,
                                                        },
                                                        relation: {
                                                            i18n: relation.relation?.i18n,
                                                            name: relation.relation?.name,
                                                            relationId: relation.relation?.relation_id,
                                                        },
                                                        memberRelationId: relation.member_relation_id,
                                                    }))
                                                    relationsArray.push({ memberRelationId: 'template' })
                                                    return relationsArray
                                                }

                                                switch (from){
                                                    case 'CDM':
                                                        if(this.props.MembersContext.currentMember.relations?.length > 0){
                                                            return Promise.resolve(formatRelations(this.props.MembersContext.currentMember.relations))
                                                        }
                                                        return Promise.resolve(formatRelations([]))

                                                    case 'REFRESH':
                                                        return this.props.MembersContext.getMember(this.props.MembersContext.currentMember.member_id)
                                                            .then((members) => formatRelations(members.relations))
                                                    default:
                                                        break;
                                                }
                                            }}
                                        >
                                            <SpordleTableView />
                                        </SpordleTableProvider>
                                    )
                                }}
                            />
                        </CardBody>
                    </OverlayLoader>
                </Card>
            </>
        )
    }
}

export default withContexts(MembersContext, UtilsContext, I18nContext)(MemberFamilyRelations);