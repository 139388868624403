
import useSWR from "swr";
import { useContext } from "react";
import { UtilsContext } from "../../contexts/UtilsContext";

export function useCountriesSWR(){
    const { getCountries } = useContext(UtilsContext);

    return useSWR(
        "getCountries",
        () => getCountries(),
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            refreshWhenHidden: false,
            dedupingInterval: (1000 * 60) * 60, // 1 hour
        }
    );
}

export function useProvincesSWR(countryId: string){
    const { getProvinces } = useContext(UtilsContext);

    return useSWR(
        ["getProvinces", countryId ],
        (_, id) => id ? getProvinces(id).then(([ country ]) => country?.sub_divisions || []) : Promise.resolve([]),
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            refreshWhenHidden: false,
            dedupingInterval: (1000 * 60) * 60, // 1 hour
        }
    );
}