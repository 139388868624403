import { useDisclosure } from "@mantine/hooks";
import { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../contexts/contexts";

/** @type {React.Context<Omit<Inbox, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const InboxContext = createContext();
InboxContext.displayName = 'InboxContext';

const Inbox = (props) => {
    const PAGINATION = 12;
    const history = useHistory();
    const { isMobileDevice } = useContext(AppContext);
    const [ sendMsgIsOpen, sentMsgIsOpenHandlers ] = useDisclosure(false);
    const [ selectedMsg, setSelectedMsg ] = useState(null);
    const [ showTools, setShowTools ] = useState(false);

    const selectMsg = (msg) => {
        setSelectedMsg(msg);

        if(isMobileDevice){
            if(msg){
                history.push({
                    hash: msg.message_id,
                });
            }else if(history.location.hash){
                history.push({
                    hash: null,
                })
            }
        }
    }

    const updateSelectedMsg = (partialData) => {
        setSelectedMsg((prev) => ({ ...prev, ...partialData }))
    }

    const emptySelectedMsg = () => {
        selectMsg(null);
    }

    const handleOnSetShowTools = (bool) => {
        if(isMobileDevice){
            setShowTools(bool);

            if(!bool){
                history.push({
                    hash: null,
                });
            }
        }
    }

    useEffect(() => {
        if(isMobileDevice && showTools && !history.location.hash){
            setShowTools(false);
        }
    }, [ history.location.hash ])

    return (
        <InboxContext.Provider
            value={{
                selectedMsg,
                selectMsg,
                sendMsgIsOpen,
                sentMsgIsOpenHandlers,
                emptySelectedMsg,
                updateSelectedMsg,
                showTools,
                setShowTools: handleOnSetShowTools,
                pagination: PAGINATION,
            }}
        >
            {props.children}
        </InboxContext.Provider>
    )
}

export default Inbox;