import React from "react";
import { Link } from "react-router-dom";
import {
    Row,
    Col,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    FormGroup,
    Label,
    Card,
    TabPane,
    TabContent,
    NavItem,
    Nav,
    NavLink
} from "reactstrap";

import { FormikInputNumber, FormikSelect } from '@spordle/formik-elements';
import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";
import FormikEditable from "../../../../../../components/formik/FormikEditable";
import I18nHelperContextProvider, { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { connect } from "formik";
import { RegistrationFeeContext } from "../../../../../../contexts/RegistrationFeeContext";
import withContexts from "../../../../../../helpers/withContexts";
import RegistrationFeesSidepanelFee from "../../../../../catalog/registrationFees/components/RegistrationFeeSidepanel/components/RegistrationFeesSidepanelFee";
import { stringBuilder } from "@spordle/helpers";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import RegistrationFeesSidepanelCatalog from "../../../../../catalog/registrationFees/components/RegistrationFeeSidepanel/components/RegistrationFeesSidepanelCatalog";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";


class OnlineStoreRegistrationSidepanel extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            activeTab: '1',
            isLoading: false,
            registrationFee: null,
        }
    }

    toggleTab(tab){
        if(this.state.activeTab !== tab){
            this.setState({
                activeTab: tab,
            });
        }
    }

    removeItem = () => {
        this.props.tableRef.deleteRow(this.props.selectedRows[0].registration_fee_id)
            .then(() => {
                this.props.formik.setFieldValue('registrationFees', this.props.tableRef.getData())
            })
            .then(() => {
                this.props.saveItems();
            })
            .then(this.props.toggle)
    }

    getRegistrationFee = () => {
        this.setState(() => ({ isLoading: true }));

        this.props.RegistrationFeeContext.getRegistrationFee(this.props.selectedRows[0].registration_fee_id)
            .then((registrationFee) => {
                this.setState(() => ({ registrationFee: registrationFee }));
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
            .finally(() => this.setState(() => ({ isLoading: false })));
    }

    componentDidMount(){
        this.getRegistrationFee();
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedRows[0].registration_fee_id != this.props.selectedRows[0].registration_fee_id){
            this.getRegistrationFee();
        }
    }

    render(){
        return (
            <>
                <div className="p-3">
                    <div className='d-flex mb-2'>
                        <button type='button' className='reset-btn p-2 text-dark' onClick={this.props.toggle}><i className='mdi mdi-arrow-right font-20' /></button>

                        <div className="ml-auto d-flex align-items-center">
                            <UncontrolledDropdown tag="span">
                                <DropdownToggle tag="button" caret className="btn btn-primary">
                                    <Translate id='misc.actions' />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem onClick={this.removeItem}>
                                        <Translate id='misc.remove' />
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="font-bold h4 mb-0">
                            <DisplayI18n field='name' i18n={this.props.selectedRows[0].fee.i18n} defaultValue={this.props.selectedRows[0].fee.name} />
                        </div>
                        <div className="mx-2">|</div>
                        <div className="font-medium text-dark"><CurrencyFormat value={(this.props.selectedRows[0].affiliation_fees ? parseInt(this.props.selectedRows[0].affiliation_fees.total?.[this.props.selectedRows[0].member_type?.member_type_id]?.amount || 0) + parseInt(this.props.selectedRows[0].fee.amount) : this.props.selectedRows[0].fee.amount) / 100} /></div>
                    </div>
                    {this.props.selectedRows[0].team_category &&
                        <>
                            {this.props.selectedRows[0].team_category.gender &&
                                <div className="small">
                                    <Translate
                                        id={`form.fields.gender.${this.props.selectedRows[0].team_category.gender.toLowerCase()}`}
                                        defaultMessage={this.props.selectedRows[0].team_category.gender}
                                    />
                                </div>
                            }
                            {this.props.selectedRows[0].team_category.min_age && this.props.selectedRows[0].team_category.max_age &&
                                <div className="small">
                                    {this.props.selectedRows[0].team_category.min_age != this.props.selectedRows[0].team_category.max_age ?
                                        <Translate id="misc.ageRange" values={{ from: this.props.selectedRows[0].team_category.min_age, to: this.props.selectedRows[0].team_category.max_age }} />
                                        :
                                        <Translate id='misc.yearsOld' values={{ age: this.props.selectedRows[0].team_category.min_age }} />
                                    }
                                </div>
                            }
                        </>
                    }
                    {this.props.selectedRows[0].available_place != null ?
                        <span className="small"><Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.registration.available' values={{ amount: this.props.selectedRows[0].available_place }} /></span>
                        :
                        <span className="small"><Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.registration.unlimited' /></span>
                    }
                    {this.state.registrationFee?.member_type?.name &&
                        <div className="small"><DisplayI18n field='name' defaultValue={this.state.registrationFee.member_type.name} i18n={this.state.registrationFee.member_type.i18n} /></div>
                    }
                    <div className="small"><Link to="/catalog/registrationfees" className="reset-btn text-primary"><Translate id="onlineStore.components.onlineStoreItemsFees.registrationFee.goTo" /></Link></div>

                </div>
                <Nav tabs>
                    <NavItem className="w-50 text-center">
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggleTab('1'); }}
                        >
                            <Translate id='onlineStore.components.onlineStoreItemsFees.item' />
                        </NavLink>
                    </NavItem>
                    <NavItem className="w-50 text-center" disabled={!this.state.registrationFee}>
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggleTab('2'); }}
                        >
                            <Translate id='catalog.registrationFees.sidePanel.tab.fee' />
                        </NavLink>
                    </NavItem>
                    <NavItem className="w-50 text-center">
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '3' })}
                            onClick={() => { this.toggleTab('3'); }}
                        >
                            <Translate id='catalog.registrationFees.sidePanel.tab.catalog' />
                        </NavLink>
                    </NavItem>
                </Nav>
                <div className="p-3 mb-5">
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <div>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for='status' className='text-muted'><Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.status' /></Label>
                                            <FormikEditable
                                                id='status'
                                                noConfirmation
                                                disabled={this.props.selectedRows[0].registration_fee.active == 0} // Overwrite if fee is active=0
                                                initialValues={{
                                                    status: this.props.selectedRows[0].status || 'ACTIVE',
                                                }}
                                                onSubmit={async(values) => {
                                                    await this.props.syncRows(this.props.createNewValues(values));
                                                    this.props.saveItems();
                                                }}
                                            >
                                                {(isEditing, options) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className='font-medium text-dark'>
                                                                <Translate id={`onlineStore.components.onlineStoreItemsFees.status.${this.props.selectedRows[0].registration_fee.active == 0 ? 'INACTIVE' : this.props.selectedRows[0].status || 'ACTIVE'}`} />
                                                                {this.props.selectedRows[0].registration_fee.active == 0 &&
                                                                <div>
                                                                    <div className="small font-medium"><i className="mdi mdi-alert-outline mr-1 text-danger" /><Translate id="onlineStore.components.onlineStoreItemsFees.registrationFee.inactive" /></div>
                                                                </div>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikSelect
                                                            name='status' id='status_OnlineStoreRegistrationSidepanel' loadingStatus='success'
                                                            autoFocus
                                                            menuIsDefaultOpen onOptionSelected={options.stopEditing} search={false}
                                                            defaultData={[
                                                                { id: 'ACTIVE', label: 'onlineStore.components.onlineStoreItemsFees.status.ACTIVE', translateLabel: true, value: 'ACTIVE', selected: (this.props.selectedRows[0].status || 'ACTIVE') === 'ACTIVE' },
                                                                { id: 'SUSPENDED', label: 'onlineStore.components.onlineStoreItemsFees.status.SUSPENDED', translateLabel: true, value: 'SUSPENDED', selected: this.props.selectedRows[0].status === 'SUSPENDED' },
                                                                { id: 'SOLD_OUT', label: 'onlineStore.components.onlineStoreItemsFees.status.SOLD_OUT', translateLabel: true, value: 'SOLD_OUT', selected: this.props.selectedRows[0].status === 'SOLD_OUT' },
                                                            ]}
                                                        />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for='display_order' className='text-muted'><Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.display_order' /></Label>
                                            <FormikEditable
                                                id='display_order'
                                                noConfirmation
                                                initialValues={{
                                                    display_order: this.props.selectedRows[0].display_order || 0,
                                                }}
                                                onSubmit={async(values) => {
                                                    await this.props.syncRows(this.props.createNewValues(values));
                                                    this.props.saveItems();
                                                }}
                                            >
                                                {(isEditing) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className='font-medium text-dark'>
                                                                {this.props.selectedRows[0].display_order}
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikInputNumber
                                                            autoFocus
                                                            name='display_order'
                                                            id='display_order_OnlineStoreRegistrationSidepanel'
                                                            allowNegative={false}
                                                        />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {this.props.selectedRows[0].fee.early_amount != null &&
                                <Card body className='card-shadow mt-4 mb-3'>
                                    <div className='mb-3'>
                                        <div className="h5 font-bold mb-0"><Translate id='onlineStore.components.onlineStoreItemsFees.registration.sidePanel.early.label' /></div>
                                        <div className="small"><CurrencyFormat value={this.props.selectedRows[0].fee.early_amount / 100} /></div>
                                    </div>
                                    <Row form>
                                        <Col sm="6">
                                            <FormGroup className='mb-0'>
                                                <Label for='earlyRegistrationDate' className='text-muted'><Translate id='onlineStore.components.onlineStoreItemsFees.registration.sidePanel.date.label' /></Label>
                                                <div>
                                                    {this.props.selectedRows[0].fee.early_amount_until ? <DateFormat value={this.props.selectedRows[0].fee.early_amount_until} /> : '-'}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup className='mb-0'>
                                                <Label for='earlyRegistrationTime' className='text-muted'><Translate id='onlineStore.components.onlineStoreItemsFees.registration.sidePanel.time.label' /></Label>
                                                <div>
                                                    {this.props.selectedRows[0].fee.early_amount_until ? <DateFormat value={this.props.selectedRows[0].fee.early_amount_until} format={'HH:mm a'} /> : '-'}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Card>
                                }
                                {this.props.selectedRows[0].fee.late_amount != null &&
                                <Card body className='card-shadow mt-4 mb-3'>
                                    <div className='mb-3'>
                                        <div className="h5 font-bold mb-0"><Translate id='onlineStore.components.onlineStoreItemsFees.registration.sidePanel.late.label' /></div>
                                        <div className="small"><CurrencyFormat value={this.props.selectedRows[0].fee.late_amount / 100} /></div>
                                    </div>
                                    <Row form>
                                        <Col sm="6">
                                            <FormGroup className='mb-0'>
                                                <Label for='lateRegistrationDate' className='text-muted'><Translate id='onlineStore.components.onlineStoreItemsFees.registration.sidePanel.date.label' /></Label>
                                                <div>
                                                    {this.props.selectedRows[0].fee.early_amount_until ? <DateFormat value={this.props.selectedRows[0].fee.late_amount_after} /> : '-'}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup className='mb-0'>
                                                <Label for='lateRegistrationTime' className='text-muted'><Translate id='onlineStore.components.onlineStoreItemsFees.registration.sidePanel.time.label' /></Label>
                                                <div>
                                                    {this.props.selectedRows[0].fee.early_amount_until ? <DateFormat value={this.props.selectedRows[0].fee.late_amount_after} format={'HH:mm a'} /> : '-'}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Card>
                                }
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <OverlayLoader isLoading={this.state.isLoading}>
                                {!!this.state.registrationFee &&
                                    <I18nHelperContextProvider fields={[ 'name', 'description' ]}>
                                        <RegistrationFeesSidepanelFee
                                            readOnly
                                            panelProps={this.props}
                                            fee={this.state.registrationFee}
                                            refreshFee={this.getRegistrationFee}
                                            memberTypes={[]}
                                            divisions={[]}
                                        />
                                    </I18nHelperContextProvider>
                                }
                            </OverlayLoader>
                        </TabPane>
                        <TabPane tabId="3">
                            <OverlayLoader isLoading={this.state.isLoading}>
                                {!!this.state.registrationFee &&
                                    <RegistrationFeesSidepanelCatalog
                                        readOnly
                                        installmentModes={[]}
                                        fee={this.state.registrationFee}
                                        forms={[]}
                                        waivers={[]}
                                    />
                                }
                            </OverlayLoader>
                        </TabPane>
                    </TabContent>
                </div>
            </>
        )
    }
}

export default withContexts(RegistrationFeeContext)(connect(OnlineStoreRegistrationSidepanel));