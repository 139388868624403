export default{
    "organization.settings.organizationContactTypes.table.empty"                            : "Aucun type de contact",
    "organization.settings.organizationContactTypes.table.column.status"                    : "Statut",
    "organization.settings.organizationContactTypes.table.column.mandatory_in_organisation" : "Rôle de personnel obligatoire",
    "organization.settings.organizationContactTypes.add.title"                              : "Ajouter un type de contact",
    "organization.settings.organizationContactTypes.add.status"                             : "Statut",
    "organization.settings.organizationContactTypes.add.status.active"                      : "actif",
    "organization.settings.organizationContactTypes.add.status.inactive"                    : "inactif",
    "organization.settings.organizationContactTypes.add.name.required"                      : "Le nom est obligatoire",
    "organization.settings.organizationContactTypes.add.status.required"                    : "Le statut est obligatoire",
    "organization.settings.organizationContactTypes.add.mandatory_in_organisation"          : "Ce rôle est obligatoire dans une organisation",

    "organization.settings.contactStaffSettings.table.column.mandatory_in_organisation.plural" : "Rôles de personnel obligatoire",
    "organization.settings.contactStaffSettings.table.column.mandatory_in_organisation.desc"   : "Rôles de personnel qui sont obligatoire pour une organisation.",

    "organization.settings.contactStaffSettings.table.column.contactMustBeMember.desc" : "Ce paramètre détermine si un contact d'organisation doit être membre pour être ajouté. ",


    "organization.settings.organizationContactTypes.tab.title" : "Types de contacts",

    "organization.settings.organizationContactTypes.mandatory_in_organisation.yes" : "Obligatoire",
    "organization.settings.organizationContactTypes.mandatory_in_organisation.no"  : "Optionnel",

    "organization.settings.contactStaffSettings.card.title" : "Paramètres de contact et personnel",

}