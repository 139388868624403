export default{
    "transfer.title"                       : "{transfer_title}",
    "transfer.requestType"                 : "Type de demande",
    "transfer.from"                        : "De",
    "transfer.to"                          : "À",
    "transfer.to.alt"                      : "Transférer à",
    "transfer.type"                        : "Type",
    "transfer.currentOrg"                  : "Organisation courante",
    "transfer.newOrg"                      : "Nouvelle organisation",
    "transfer.newOrg.required"             : "Veuillez sélectionner une nouvelle organisation",
    "transfer.newOrg.isNotDifferent"       : "Ne peut pas être l'organisation actuelle du membre",
    "transfer.types"                       : "Type de {transfer}",
    "transfer.types.required"              : "Veuillez sélectionner un type de {transfer}",
    "transfer.status"                      : "Statut",
    "transfer.status.required"             : "Veuillez sélectionner un statut",
    "transfer.sharedExpiration"            : "Expiration de la date de partage",
    "transfer.sharedExpiration.required"   : "La date d'expiration est requise.",
    "transfer.sharedExpiration.afterToday" : "La date d'expiration ne doit pas être avant aujourd'hui.",
    "transfer.documentType"                : "Type de document",
    "transfer.documentType.required"       : "Le type de document est requis",
    "transfer.documentType.helper"         : "Le type de document requis est manquant, veuillez l'ajouter au documents d'inscriptions pour les membres dans les paramètres d'organisation.",
    "transfer.documents.required"          : "Au moins 1 document est requis",
    "transfer.requestedOn"                 : "Initié le",
    "transfer.createdBy"                   : "Créé par",
    "transfer.reason"                      : "Raison",
    "transfer.reason.required"             : "Veuillez sélectionner une raison",
    "transfer.invoice"                     : "Facture",
    "transfer.invoice.CHARGE"              : "Facturer",
    "transfer.invoice.NO_CHARGE"           : "Ne pas facturer",
    "transfer.targetTeam"                  : "Équipe cible",
    "transfer.fromTeam"                    : "Équipe de départ",
    "transfer.targetAffiliate"             : "Affilier",
    "transfer.targetPosition"              : "Position cible",
    "transfer.targetTryout"                : "Essaie",
    "transfer.targetImport"                : "Import",
    "transfer.organization"                : "Organisation cible",
    "transfer.organization.required"       : "Veuillez sélectionner une organisation",
    "transfer.organization.different"      : "L'organisation cible doit être différente de l'organisation du membre",
    "transfer.detail"                      : "Détail",
    "transfer.detail.required"             : "Veuillez fournir plus de détail",
    "transfer.supportingDocument"          : "Documents de support",
    "transfer.supportingDocument.required" : "Vous devez téléverser au moins un fichier de support",
    "transfer.uploadSupportingDocument"    : "Téléverser le(s) document(s) de support(s)",
    "transfer.sendCopyTo"                  : "Envoyer une copie à :",
    "transfer.submit"                      : "Soumettre",

    "transfer.sidepanel.tabs.transfer.title"                    : "Détails",
    "transfer.sidepanel.tabs.team.title"                        : "Équipes",
    "transfer.sidepanel.tabs.team.none"                         : "Il n'y a pas d'autre équipe associée à ce membre",
    "transfer.sidepanel.tabs.comment.title"                     : "Commentaires",
    "transfer.sidepanel.tabs.comment.none"                      : "Il n'y a aucun commentaire sur ce {transfer}",
    "transfer.sidepanel.actions.removeShare"                    : "Enlever le partage",
    "transfer.sidepanel.actions.removeShare.msg"                : "Vous êtes sur le point de supprimer le partage pour",
    "transfer.sidepanel.actions.removeShare.error.3404"         : "Vous n'avez pas la permission de supprimer le partage",
    "transfer.sidepanel.actions.removeShare.error.3405"         : "Il n'y a pas de partage sur ce {transfer}",
    "transfer.sidepanel.actions.removeShare.success"            : "Partage enlever",
    "transfer.sidepanel.actions.removeShare.refresh.fail.title" : "Erreur lors du rafraîchissant du membre ",
    "transfer.sidepanel.actions.removeShare.refresh.fail.msg"   : "Nous ne pouvions pas rafraîchir les informations du membre. Nous vous recommandons de quitter le profil du membre et de revenir.",
    "transfer.sidepanel.actions.addComment"                     : "Ajouter un commentaire",
    "transfer.sidepanel.modal.addComment.comment"               : "Commentaire",
    "transfer.sidepanel.modal.addComment.comment.required"      : "Veuillez saisir un commentaire",
    "transfer.sidepanel.review"                                 : "Réviser",
    "transfer.sidepanel.approveRequest"                         : "Approuver la demande",
    "transfer.sidepanel.approveRequest.override"                : "Approuvé - Administrateur",
    "transfer.sidepanel.reviewRequest"                          : "Réviser la demande",
    "transfer.sidepanel.refuseRequest"                          : "Refuser la demande",
    "transfer.sidepanel.refuseRequest.override"                 : "Refusé - Administrateur",
    "transfer.sidepanel.refuseDetail"                           : "Raison du refus",
    "transfer.sidepanel.workflow.step.APPROVED"                 : "Approuvé",
    "transfer.sidepanel.workflow.step.REFUSED"                  : "Refusé",
    "transfer.sidepanel.workflow.step.PENDING"                  : "En attente",
    "transfer.sidepanel.address.none"                           : "Ce membre n'a pas d'adresse",

    "transfer.sidepanel.workflow.override.approved" : "{transfer_title} Administrateur (Approuvé)",
    "transfer.sidepanel.workflow.override.rejected" : "{transfer_title} Administrateur (Refusé)",

    "transfer.modal.approve.message"         : "Vous êtes sur le point de confirmer la demande suivante",
    "transfer.modal.approve.approve"         : "Approuver",
    "transfer.modal.refuse.message"          : "Vous êtes sur le point de refuser la demande suivante",
    "transfer.modal.refuse.details"          : "Détails",
    "transfer.modal.refuse.details.required" : "Veuillez fournir quelques détails",
    "transfer.modal.refuse.refuse"           : "Refuser",

    "transfer.validation.isPositionSelected" : "Une position est obligatoire lorsqu'une équipe est sélectionnée.",

    "transfer.error.3335" : "Le membre a déjà soumis une demande de {transfer}",
    "transfer.error.3191" : "Permissions insufisantes",
    "transfer.error.3179" : "Accès refusé",
    "transfer.error.3045" : "Les données semblent être incompatibles",
    "transfer.error.3060" : "La ressource n'a pas pu être trouvée",
    "transfer.error.3229" : "L'organisation ne correspond pas",
    "transfer.error.3332" : "Extension de fichier invalide",
    "transfer.error.3331" : "La taille du fichier dépasse la limite",
    "transfer.error.3330" : "Le système de stockage n'a pas pu supprimer le fichier",
    "transfer.error.3403" : "Impossible d'initier un {transfer} pendant que le membre a un solde impayé",

    "transfer.table.header.date"                 : "Date",
    "transfer.table.header.details"              : "Détails",
    "transfer.table.header.member"               : "Membre",
    "transfer.table.header.organization"         : "Organisation",
    "transfer.table.header.status"               : "Statut",
    "transfer.table.header.status.OPEN"          : "Ouvert",
    "transfer.table.header.status.CLOSED"        : "Fermé",
    "transfer.table.header.status.REFUSED"       : "Refusé",
    "transfer.table.header.status.COMPLETED"     : "Complété",
    "transfer.table.header.status.REJECTED"      : "Rejeté",
    "transfer.table.header.status.EXPIRED"       : "Expiré",
    "transfer.table.header.status.EXPIRED_SHARE" : "Expiré",
    "transfer.table.header.direction.OUTGOING"   : "Sortant",
    "transfer.table.header.direction.INCOMING"   : "Entrant",

    "transfer.table.filters.status.placeholder"    : "Filtrer par statut",
    "transfer.table.filters.type.placeholder"      : "Filtrer par type",
    "transfer.table.filters.direction.placeholder" : "Filtrer par direction",
    "transfer.table.filters.action.label"          : "Action requise",
    "transfer.table.filters.atLeastOne"            : "Doit avoir au moins un filtre sur statut ou type.",

    "transfer.type.ASSOCIATION"            : "Association",
    "transfer.type.BRANCH"                 : "Branche",
    "transfer.type.USA"                    : "USA",
    "transfer.type.INTERNATIONAL"          : "International",
    "transfer.type.transfer.ASSOCIATION"   : "{transfer_title} d'association",
    "transfer.type.transfer.BRANCH"        : "{transfer_title} de branche",
    "transfer.type.transfer.USA"           : "{transfer_title} États-Unis",
    "transfer.type.transfer.INTERNATIONAL" : "{transfer_title} International",
}