import { FormikDateTime, FormikError, FormikInputNumber, FormikSelect } from "@spordle/formik-elements"
import { stringBuilder } from "@spordle/helpers"
import Translate, { CurrencyFormat } from "@spordle/intl-elements"
import { Form, Formik } from "formik"
import moment from "moment"
import { useContext, useState } from "react"
import { Button, Col, Collapse, Fade, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import useSWR from "swr"
import { array, mixed, number, object, string } from 'yup'
import AnalyticsModal from "../../analytics/AnalyticsModal"
import { AxiosIsCancelled } from "../../api/CancellableAPI"
import { I18nContext } from "../../contexts/I18nContext"
import { MembersContext } from "../../contexts/MembersContext"
import { UtilsContext } from "../../contexts/UtilsContext"
import { DisplayI18n } from "../../helpers/i18nHelper"
import CustomAlert from "../CustomAlert"
import OverlayLoader from "../loading/OverlayLoader"
import { fail, success } from "@spordle/toasts"
import UserDisplay from "../userDisplay/UserDisplay"
import UserImg from "../UserImg"
import { PaymentStatus } from "./TransactionHelper"
import InlineCopy from "../inlineCopy/InlineCopy"

/**
 * @description Modal that shows all installments and their input fields
 * @param {Object} props
 * @param {function} props.toggle
 * @param {bool} props.isOpen
 * @param {object} props.registration
 * @param {object[]} props.sortedPayments
 */
const EditTransactionIntallmentsModal = (props) => {
    return (
        <AnalyticsModal size="lg" analyticsName="editTransactionInstallmentsModal" isOpen={props.isOpen}>
            <EditTransactionIntallmentsModalInner {...props} />
        </AnalyticsModal>
    )
}

const EditTransactionIntallmentsModalInner = ({ toggle, invoiceNb, registration, refreshTable, sortedPayments, toggleSidepanel }) => {
    const { updateInvoiceInstallments } = useContext(MembersContext);
    const { getPaymentMethods } = useContext(UtilsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { data: paymentMethods, _error, isValidating } = useSWR(
        [ 'getPaymentMethodsInstallmentsModal' ],
        () => getPaymentMethods()
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        ,
        {
            fallbackData: [],
        },
    );
    const [ step, setStep ] = useState(1);
    const initPayments = sortedPayments.map((payment) => ({ ...payment, dueDate: moment(payment.due_date), parsed_amount: parseInt(payment.amount) / 100 }));

    /**
     * @description Checks if can edit the installment. Can only edit installments if type INSTALLMENT.
     * @param {object} payment Payment object
     * @returns {bool}
     */
    const canEditInstallment = (payment) => (
        payment.payment_type === 'INSTALLMENT' && payment.status !== "COMPLETED"
    );

    /**
     * @description Checks if installement is uneditable. Disabled if status is COMPLETED.
     * @param {object} payment Payment object
     * @returns {bool}
     */
    const editInstallmentIsDisabled = (payment) => (
        payment.status === 'COMPLETED'
    );

    /**
     * @description Cals the total amount of an array of payments (calulations in minor)
     * @param {object[]} payments
     */
    const calcTotal = (payments) => Math.round(payments.reduce((total, payment) => total + payment.parsed_amount * 100, 0));

    /**
     * @description Checks if initial values && current values are different. If they are, returns true
     * @param {object} payment Current Payment object
     * @param {integer} index Index of the payment in the payments array
     * @return {bool}
     */
    const valuesAreDifferent = (payment, index) => {
        const initValue = initPayments[index];
        return initValue.parsed_amount != payment.parsed_amount || !initValue.dueDate.isSame(payment.dueDate, 'days') || initValue.payment_method !== payment.payment_method;
    };

    /**
     * @description updates installments with new values
     * @param {object} newValues
     */
    const updateInstallments = (newValues) => {
        return updateInvoiceInstallments(invoiceNb, newValues)
            .then(() => {
                refreshTable();
                toggleSidepanel();
                success();
                toggle();
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });
    }

    return (
        <Formik
            initialValues={{
                payments: initPayments.map((payment) => ({
                    ...payment,
                    payment_method: payment.payment_method.code,
                })), // payment amounts in major because of input number
                total: calcTotal(initPayments || []), // minor units
            }}
            validationSchema={object().shape({
                payments: array().of(object().shape({
                    parsed_amount: number().min(0),
                    dueDate: mixed().required(<Translate id='form.validation.date.required' />)
                        .isDate()
                        .test({
                            name: 'paymentDateValidTest',
                            message: <Translate id='components.transaction.editInstallmentsModal.date.validation.after' />,
                            test: function(dueDate){
                                if(moment.isMoment(dueDate) && this.parent.payment_type === 'INSTALLMENT' && this.parent.status !== 'COMPLETED'){
                                    return moment(dueDate).isSameOrAfter(new Date(), 'days');
                                }
                                return true;
                            },
                        }),
                    payment_method: string().required(),
                })),
                total: number().test({
                    name: 'isRightAmount',
                    message: <Translate id='components.transaction.editInstallmentsModal.total.wrong' values={{ price: calcTotal(initPayments || []) / 100 }} />, // minor units
                    test: function(total){
                        return calcTotal(this.parent.payments) === (total); // minor units
                    },
                }),

            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                let showWarning = false;
                const valuesToUpdate = {};

                for(let i = 0; i < values.payments.length; i++){
                    const payment = values.payments[i];
                    if(valuesAreDifferent(payment, i) && payment.payment_type === "INSTALLMENT" && payment.status !== "COMPLETED"){

                        // API Logic changed: we always need to send amount therefor always send the invoice_payment_id
                        const initValue = initPayments[i];
                        const updateProp = (field, val) => valuesToUpdate[`payments[${i}][${field}]`] = val;

                        // Payment id
                        updateProp('invoice_payment_id', payment.invoice_payment_id);

                        // Add amount - as soon as we have a different value, we must send amount
                        updateProp('amount', Math.round(payment.parsed_amount * 100));

                        // If due date changed
                        if(!initValue.dueDate.isSame(payment.dueDate, 'days')){
                            updateProp('payment_date', moment(payment.dueDate).format('YYYY-MM-DD'));
                        }

                        // If payment method changed
                        if(initValue.payment_method !== payment.payment_method){
                            if(initValue.payment_method === "CREDITCARD" && !showWarning){
                                showWarning = true;
                            }
                            updateProp('payment_method', payment.payment_method);
                        }

                        // Update payment method to CASH if amount if 0 or status CANCELLED to for manual payment
                        if((initValue.parsed_amount !== payment.parsed_amount && Math.round(payment.parsed_amount) === 0) || initValue.status === 'CANCELLED' || initValue.status === 'CANCELED'){ // If amount is 0 or status CANCELLED -> set payment method to CASH to force manual payment
                            updateProp('payment_method', (initValue.payment_method !== payment.payment_method && payment.payment_method !== 'CREDITCARD') ? payment.payment_method : "CASH"); // if amount is 0, force MANUAL

                            if(!showWarning)
                                showWarning = true;
                        }
                    }
                }

                if(showWarning && step === 1){
                    setSubmitting(false);
                    setStep(2);
                }else if(Object.keys(valuesToUpdate).length > 0){
                    updateInstallments(valuesToUpdate)
                        .catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                console.error(e);
                                setSubmitting(false);
                                setStatus(e);
                            }
                        });
                }else{
                    toggle();
                }
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={toggle}>
                            <Translate id='components.transaction.editInstallmentsModal.header' />
                            <DisplayI18n
                                field='name'
                                i18n={registration.registration_fee.fee.i18n}
                                defaultValue={registration.registration_fee.fee.name}
                            />
                        </ModalHeader>
                        <Collapse isOpen={step === 1}>
                            <Fade in={step === 1}>
                                <ModalBody>
                                    <UserDisplay card className="d-flex mb-3">
                                        <UserDisplay.Container>
                                            <UserImg
                                                className="text-uppercase shadow-sm"
                                                abbr={registration.member.first_name.substring(0, 1) + registration.member.last_name.substring(0, 1)}
                                                src={registration.member.picture?.full_path}
                                                filePos={registration.member.picture?.file_position}
                                                width={50}
                                                alt={registration.member.first_name + ' ' + registration.member.last_name}
                                            />
                                        </UserDisplay.Container>
                                        <UserDisplay.Container>
                                            <UserDisplay.Title>{registration.member.first_name + ' ' + registration.member.last_name}</UserDisplay.Title>
                                            <UserDisplay.Subtitle className="font-medium">
                                                <InlineCopy toCopy={registration.member.unique_identifier}>
                                                    {
                                                        registration.member.unique_identifier ?
                                                            <span>
                                                                #{registration.member.unique_identifier}
                                                            </span>
                                                            :
                                                            '-'
                                                    }
                                                </InlineCopy>
                                            </UserDisplay.Subtitle>
                                            <UserDisplay.Subtitle className="font-medium"><DisplayI18n field='name' defaultValue={registration.registration_fee.fee.name} i18n={registration.registration_fee.fee.i18n} /></UserDisplay.Subtitle>
                                        </UserDisplay.Container>
                                    </UserDisplay>
                                    <div style={{ overflowX: 'hidden' }} className="rounded-lg shadow-sm border px-1">
                                        <Row className="font-medium border-bottom py-2" form>
                                            <Col className="text-center" sm="3" md="2"><Translate id="form.fields.status" /></Col>
                                            <Col className="text-center" sm="3"><Translate id="form.fields.date" /></Col>
                                            <Col className="text-center" sm="3" md="4"><Translate id="form.fields.method" /></Col>
                                            <Col className="text-center" sm="3"><Translate id="form.fields.amount" /></Col>
                                        </Row>
                                        {formik.values.payments?.map((payment, index) => {
                                            const canEdit = canEditInstallment(payment);
                                            const isDisabled = editInstallmentIsDisabled(payment);

                                            return (
                                                <Row form className={stringBuilder("transition border-bottom", { "bg-light-inverse": !canEdit || isDisabled }, { "bg-primary-light": canEdit && !isDisabled && valuesAreDifferent(payment, index) })} key={payment.invoice_payment_id}>
                                                    <Col className="py-2 d-flex align-items-center justify-content-center" sm="3" md="2">
                                                        <PaymentStatus payment={payment} />
                                                    </Col>
                                                    <Col className="py-2" sm="3">
                                                        <FormikDateTime
                                                            inputProps={{
                                                                disabled: !canEdit || isDisabled,
                                                            }}
                                                            id={`payments.${index}.dueDate`}
                                                            name={`payments.${index}.dueDate`}
                                                            timeFormat={false}
                                                            isValidDate={(current) => current.isSameOrAfter(moment(), 'days')}
                                                            initialViewDate={moment(payment.due_date)}
                                                        />
                                                    </Col>
                                                    <Col className="py-2" sm="3" md="4">
                                                        <FormikSelect
                                                            disabled={!canEdit || isDisabled}
                                                            id={`payments.${index}.payment_method`}
                                                            name={`payments.${index}.payment_method`}
                                                            renderOption={({ option }) => <DisplayI18n field='name' i18n={option.i18n} defaultValue={option.label} />}
                                                            searchKeys={[
                                                                `i18n.${getGenericLocale()}.name`,
                                                            ]}
                                                            isLoading={isValidating}
                                                            options={paymentMethods ?
                                                                paymentMethods.reduce((keptMethods, paymentMethod) => {
                                                                    if(paymentMethod.category === "MANUAL" || payment.payment_method.code === "CREDITCARD"){
                                                                        keptMethods.push({
                                                                            value: paymentMethod.code,
                                                                            i18n: paymentMethod.i18n,
                                                                            label: paymentMethod.name,
                                                                        })
                                                                    }
                                                                    return keptMethods;
                                                                }, [])
                                                                :
                                                                []
                                                            }
                                                        />
                                                    </Col>
                                                    <Col className="py-2" sm="3">
                                                        <div className="position-relative">
                                                            <Fade in={initPayments[index].parsed_amount != payment.parsed_amount}>
                                                                <Button
                                                                    type="button"
                                                                    disabled={initPayments[index].parsed_amount == payment.parsed_amount}
                                                                    onClick={() => formik.setFieldValue(`payments.${index}.parsed_amount`, initPayments[index].parsed_amount)}
                                                                    color="white"
                                                                    className="position-absolute top-0 bottom-0 left-0"
                                                                    size="sm"
                                                                >
                                                                    <i className="mdi font-14 mdi-restore-clock" />
                                                                </Button>
                                                            </Fade>
                                                            <FormikInputNumber
                                                                disabled={!canEdit || isDisabled}
                                                                className="text-right"
                                                                allowLeadingZeros fixedDecimalScale
                                                                id={`payments.${index}.parsed_amount`}
                                                                name={`payments.${index}.parsed_amount`}
                                                                allowNegative={false}
                                                                decimalScale={2}
                                                                thousandSeparator=' '
                                                                decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                                suffix={getGenericLocale() === 'fr' ? '$' : undefined}
                                                                prefix={getGenericLocale() !== 'fr' ? '$' : undefined}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                        })}
                                        <Row form className={stringBuilder(`transition text-right border-top pt-2 pb-1`, { "text-danger": formik.errors.total })}>
                                            <Col className="font-bold text-body" sm="9"><Translate id="components.transaction.editInstallmentsModal.balance" /></Col>
                                            <Col sm="3">
                                                <CurrencyFormat value={(calcTotal(formik.values.payments || []) - formik.values.total) / 100} />
                                            </Col>
                                        </Row>
                                        <Row form className={stringBuilder("transition text-right border-bottom pt-1 pb-2", { "bg-light-danger": formik.errors.total && formik.touched.total }, { "text-danger": formik.errors.total })}>
                                            <Col className="font-bold text-body" sm="9"><Translate id="components.transaction.editInstallmentsModal.currentTotal" /></Col>
                                            <Col sm="3">
                                                <CurrencyFormat value={calcTotal(formik.values.payments || []) / 100} />
                                            </Col>
                                            <Col className="text-right" sm="12">
                                                <Collapse isOpen={formik.errors.total && formik.touched.total}>
                                                    <div className="text-right">
                                                        <FormikError name="total" />
                                                    </div>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                        <Row form className="text-right py-2 border-top font-bold">
                                            <Col sm="9"><Translate id="components.transaction.editInstallmentsModal.invoiceTotal" /></Col>
                                            <Col sm="3">
                                                <CurrencyFormat value={formik.values.total / 100} />
                                            </Col>
                                        </Row>
                                    </div>
                                    <Collapse isOpen={!!formik.status} appear>
                                        <CustomAlert className="mt-3" withTitle text="misc.error" translateText toggle={() => formik.setStatus(null)} color="danger" />
                                    </Collapse>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" type="submit">
                                        <Translate id="misc.confirm" />
                                    </Button>
                                    <Button color="primary" type="button" outline onClick={toggle}>
                                        <Translate id="misc.cancel" />
                                    </Button>
                                </ModalFooter>
                            </Fade>
                        </Collapse>
                        <Collapse isOpen={step === 2}>
                            <Fade in={step === 2}>
                                <ModalBody className="text-center">
                                    <i style={{ fontSize: 150, lineHeight: 1 }} className="text-light mdi mdi-credit-card-off" />
                                    <div className="h3 font-bold text mb-4"><Translate id='components.transaction.editInstallmentsModal.warning.title' /></div>
                                    <p className="mb-1">
                                        <Translate
                                            id='components.transaction.editInstallmentsModal.warning.subtitle1'
                                            values={{
                                                b: (chunks) => <b>{chunks}</b>,
                                            }}
                                        />
                                    </p>
                                    <p className="mb-1">
                                        <Translate
                                            id='components.transaction.editInstallmentsModal.warning.subtitle2'
                                            values={{
                                                b: (chunks) => <b>{chunks}</b>,
                                            }}
                                        />
                                    </p>
                                    <p className="mb-4">
                                        <Translate
                                            id='components.transaction.editInstallmentsModal.warning.subtitle3'
                                            values={{
                                                b: (chunks) => <b>{chunks}</b>,
                                            }}
                                        />
                                    </p>
                                    <p>
                                        <Translate
                                            id='components.transaction.editInstallmentsModal.warning.subtitle.confirm'
                                        />
                                    </p>
                                    <Collapse isOpen={!!formik.status} appear>
                                        <CustomAlert className="mt-3" withTitle text="misc.error" translateText toggle={() => formik.setStatus(null)} color="danger" />
                                    </Collapse>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        onClick={() => {
                                            setStep(1);
                                            formik.setStatus(null);
                                        }}
                                        color="primary"
                                        type="button"
                                        className="mr-auto"
                                        outline
                                    >
                                        <Translate id="misc.previous" />
                                    </Button>
                                    <Button color="primary" type="submit">
                                        <Translate id="misc.confirm" />
                                    </Button>
                                </ModalFooter>
                            </Fade>
                        </Collapse>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default EditTransactionIntallmentsModal;