import { FormikInputText } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { Button, Col, Collapse, Fade, Label, ModalBody, Row } from "reactstrap";
import { array, object, string } from 'yup';
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import CrossFade from "../../../../../../../components/crossFade/CrossFade";
import Required from "../../../../../../../components/formik/Required";
import BtnDisplay from "../../../../../../../components/input/BtnDisplay";
import { fail } from "@spordle/toasts";
import UserDisplay from "../../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../../components/UserImg";
import { MembersContext } from "../../../../../../../contexts/MembersContext";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import { BC_ID } from "../../../gameIncidentHelper";
import StepFooter from "../../components/StepFooter";
import StepTitle from "../../components/StepTitle";
import OfficialListItem from "./OfficialListItem";

const AddGameIncidentStepOfficials = ({ next, setIsLoading, updateGirData, isLoading, previous }) => {
    const [ officialsStep, setOfficialsStep ] = useState(1);
    const { getPublicMembers } = useContext(MembersContext);
    const [ results, setResults ] = useState(null);

    /**
     * @param {boolean} isVisible
     * @returns {JSX.Element}
     */
    const FieldRequired = ({ isVisible }) => (
        <Fade className="d-inline" in={isVisible}><Required /></Fade>
    );

    /**
     * @description Search schema, only used when officialsStep is set to 2
     * @return {MixedSchema}
     */
    const searchSchema = ({
        first_name: string()
            .when('unique_identifier', {
                is: (_id) => !_id,
                then: string().required(<Translate id='form.validation.firstName.required' />),
            }),
        last_name: string()
            .when('unique_identifier', {
                is: (_id) => !_id,
                then: string().required(<Translate id='form.validation.lastName.required' />),
            }),
        unique_identifier: string(),
    });

    return (
        <Formik
            initialValues={{
                search: {
                    first_name: '',
                    last_name: '',
                    unique_identifier: '',
                },
                officials: [],
            }}
            validationSchema={object().shape({
                search: object().when('_', (_, schema) => officialsStep === 1 ? schema : schema.shape(searchSchema)),
                officials: array().of(
                    object().shape({
                        member_id: string(),
                        official_type: string().test({
                            name: 'isRequired',
                            message: <Translate id='gameIncident.addModal.validation.officialType' />,
                            test: function(off){
                                return officialsStep !== 1 || !!off;
                            },
                        }),
                        email: string().email(<Translate id='form.validation.email.valid' />).test({
                            name: 'isRequired',
                            message: <Translate id='gameIncident.addModal.validation.email' />,
                            test: function(email){
                                return this.options.index !== 0 || officialsStep !== 1 || !!email;
                            },
                        }),
                        phone_number: string()
                            .when((_, schema) => officialsStep === 2 ?
                                schema
                                :
                                schema.isValidPhoneNumber(<Translate id='form.validation.phone.valid' />).test({
                                    name: 'isRequired',
                                    message: <Translate id='gameIncident.addModal.validation.phone' />,
                                    test: function(phone){
                                        return this.options.index !== 0 || !!phone;
                                    },
                                })),
                        phoneExt: string(),
                    }),
                )
                    .when('_', (_, schema) => officialsStep == 1 ? schema.min(1) : schema),
            })}
            onSubmit={({ search, officials }, { setTouched }) => {
                if(officialsStep === 1){
                    updateGirData({ officials: officials });
                    next();
                }else{
                    setIsLoading(true);
                    const apiValues = {
                        member_type_id: 'OFFICIAL',
                        organisation_id: BC_ID,
                        limit_hcr_number_search: 1,
                    };

                    if(search.unique_identifier){
                        apiValues.unique_identifier = search.unique_identifier;
                    }else{
                        apiValues.first_name = search.first_name;
                        apiValues.last_name = search.last_name;
                    }

                    getPublicMembers(apiValues)
                        .then((members) => {
                            setResults(members);
                            setIsLoading(false);
                            setTouched({
                                'officials': false,
                            }, false);

                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                                setIsLoading(false);
                            }
                        })
                }
            }}
        >
            {(formik) => (
                <Form>
                    <CrossFade isVisible={officialsStep === 1}>
                        <ModalBody>
                            <StepTitle title='gameIncident.addModal.steps.officials.title' />
                            {formik.values.officials.map((official, index) => (
                                <OfficialListItem
                                    index={index}
                                    official={official}
                                    key={official.member_id}
                                    emailPhoneRequired={index === 0}
                                />
                            ))}
                            <BtnDisplay
                                onClick={() => setOfficialsStep(2)}
                                iconSize={50}
                                icon="addMember"
                                svgProps={{ viewBox: "0 0 50 50" }}
                                title={<Translate id='gameIncident.addModal.officials.title' />}
                                subtitle={<Translate id='gameIncident.addModal.officials.btn.subtitle' />}
                            />

                            {/*
                                This is a custom handling of the error message
                                Because it crashed when there were suberrors in officials
                                At some points, errors.officials === string, and at another, errors.officials === array
                            */}
                            <Collapse isOpen={formik.errors.officials && typeof formik.errors.officials === "string" && formik.values.officials?.length <= 0}>
                                <small className="d-block text-danger">
                                    <Translate id='gameIncident.addModal.validation.officials.min' />
                                </small>
                            </Collapse>
                        </ModalBody>
                        <StepFooter previous={previous} />
                    </CrossFade>
                    <CrossFade
                        onExited={() => {
                            setResults(null);
                            formik.setFieldValue('search', {
                                first_name: '',
                                last_name: '',
                                unique_identifier: '',
                                birthdate: '',
                            });
                        }}
                        isVisible={officialsStep === 2}
                    >
                        <ModalBody>
                            <StepTitle title='gameIncident.addModal.steps.officials.add.title' />
                            <Row form>
                                <Col sm="6" className="form-group">
                                    <Label for="search.first_name" className="text-muted"><Translate id='form.fields.firstName' /> <FieldRequired isVisible={!formik.values.search.unique_identifier} /></Label>
                                    <FormikInputText disabled={!!formik.values.search.unique_identifier} name="search.first_name" />
                                </Col>
                                <Col sm="6" className="form-group">
                                    <Label for="search.last_name" className="text-muted"><Translate id='form.fields.lastName' /> <FieldRequired isVisible={!formik.values.search.unique_identifier} /></Label>
                                    <FormikInputText disabled={!!formik.values.search.unique_identifier} name="search.last_name" />
                                </Col>
                                <Col sm="12">
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="border-top flex-grow-1" />
                                        <div className="px-3 font-medium small"><Translate id='clinics.profile.attendees.addModal.memberSelect.or' /></div>
                                        <div className="border-top flex-grow-1" />
                                    </div>
                                </Col>
                                <Col sm="12" className="form-group">
                                    <Label for="search.unique_identifier" className="text-muted"><Translate id='members.search.memberAdvanceSearch.filters.memberNumber' /></Label>
                                    <FormikInputText
                                        name="search.unique_identifier"
                                        helper={
                                            <small className="text-muted">
                                                <i className="text-primary mdi mdi-information-outline" /> <Translate id='clinics.profile.attendees.addModal.memberSelect.memberNumber.hint' />
                                            </small>
                                        }
                                    />
                                </Col>
                            </Row>
                            <Collapse isOpen={!!results}>
                                { results &&
                                    <div className="pt-3 border-top">
                                        { results?.length > 0 ?
                                            <>
                                                <div className="h6 font-medium mb-3"><Translate id='misc.results' /></div>
                                                <Row form>
                                                    { results.map((result) => {
                                                        const isAlreadyInList = formik.values.officials.some((official) => official.member_id === result.member_id);

                                                        return (
                                                            <Col sm="12" key={result.member_id}>
                                                                <UserDisplay card className="w-100 mb-2">
                                                                    <UserDisplay.Container>
                                                                        <UserImg
                                                                            abbr={result.first_name.substring(0, 1) + result.last_name.substring(0, 1)}
                                                                            src={result.photo?.full_path}
                                                                            filePos={result.photo?.file_position}
                                                                            width={50}
                                                                            alt={result.first_name}
                                                                        />
                                                                    </UserDisplay.Container>
                                                                    <UserDisplay.Container>
                                                                        <div className="align-items-center d-flex">
                                                                            <UserDisplay.Title className="mr-2">{result.first_name} {result.last_name}</UserDisplay.Title>
                                                                            <UserDisplay.Subtitle>(<Translate id='misc.yearsOld' values={{ age: result.age }} />)</UserDisplay.Subtitle>
                                                                        </div>
                                                                        <UserDisplay.Subtitle>#{result.unique_identifier}</UserDisplay.Subtitle>
                                                                        <UserDisplay.Subtitle>
                                                                            <DisplayI18n
                                                                                field="name"
                                                                                i18n={result.organisation.i18n}
                                                                                defaultValue={result.organisation.organisation_name}
                                                                            />
                                                                        </UserDisplay.Subtitle>
                                                                    </UserDisplay.Container>
                                                                    <UserDisplay.Container className="ml-auto">
                                                                        <Button
                                                                            disabled={isAlreadyInList}
                                                                            size="sm"
                                                                            type="button"
                                                                            onClick={async() => {
                                                                                await formik.setFieldValue('officials', [ ...formik.values.officials, { email: '', phone_number: '', ...result, official_type: '' } ]);
                                                                                setOfficialsStep(1);
                                                                            }}
                                                                            color="primary"
                                                                            outline
                                                                        >
                                                                            {isAlreadyInList ?
                                                                                <Translate id='gameIncident.search.alreadyAdded' />
                                                                                :
                                                                                <Translate id='misc.add' />
                                                                            }
                                                                        </Button>
                                                                    </UserDisplay.Container>
                                                                </UserDisplay>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </>
                                            :
                                            <div className="text-center">
                                                <Translate id='misc.search.empty.title' />
                                            </div>
                                        }
                                    </div>
                                }
                            </Collapse>
                        </ModalBody>
                        <StepFooter disabled={isLoading} previous={() => setOfficialsStep(1)} submitLabel="misc.search" />
                    </CrossFade>
                </Form>
            )}
        </Formik>
    )
}

export default AddGameIncidentStepOfficials;