import Translate from "@spordle/intl-elements"
import { Fade, ListGroup } from "reactstrap"
import OrganizationNotification from "./OrganizationNotification"
import EmptyNotifications from '../../../../../assets/images/Empty_Notifications.png';
import { stringBuilder } from "@spordle/helpers";

const OrganizationNotificationList = ({
    notifs,
    notifsCount,
    isClearing,
    markAllAsRead,
    deleteAllNotifs,
    notifsLoading,
    toggle,
    setRead,
    setNotifs,
    setNotifsCount,
    className,
    emptyLayoutClassName,
}) => {

    return (
        <>
            {((notifs && notifs.length > 0) || notifsLoading) ?
                <>
                    <div className="text-right py-2 px-3">
                        { notifsCount > 0 ?
                            <button type='button' disabled={isClearing} className="notification-read-all" onClick={markAllAsRead}><Translate id='header.notifications.btn.clearAll' /></button>
                            :
                            <button type='button' disabled={isClearing} className="notification-read-all" onClick={deleteAllNotifs}><Translate id='header.notifications.btn.deleteAll' /></button>
                        }
                    </div>
                    <ListGroup tag="div" flush className={stringBuilder("message-center notification", className)}>
                        {!notifsLoading ?
                            notifs.map((notif) => (
                                <OrganizationNotification
                                    key={notif.notification_id + notif.read_at}
                                    isLoading={notifsLoading}
                                    notif={notif}
                                    toggle={toggle}
                                    setRead={() => setRead(notif)}
                                    isClearing={isClearing}
                                    hideNotif={() => {
                                        const notifsLeft = notifs.filter((n) => n.notification_id !== notif.notification_id)
                                        setNotifs(notifsLeft)
                                        setNotifsCount(notifsLeft.filter((n) => n.read_by == null).length)
                                    }}
                                />
                            ))
                            :
                            <OrganizationNotification
                                isLoading
                                key={"loading"}
                            />
                        }

                    </ListGroup>
                </>
                :
                <div className={stringBuilder("notification px-3 py-4 text-center border-top-0", emptyLayoutClassName)}>
                    <Fade>
                        <img className="w-25 mb-2" src={EmptyNotifications} alt="No notification" />
                        <div className="h4 font-bold mb-0"><Translate id='header.notifications.empty.title' /></div>
                        <div><Translate id='header.notifications.empty.text' /></div>
                    </Fade>
                </div>
            }
        </>
    )
}

export default OrganizationNotificationList