import { useContext, useState } from 'react';
import Translate from '@spordle/intl-elements';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Container
} from "reactstrap";

import OnlineStoreItemsAndFeesComponent from '../components/itemsAndFees/OnlineStoreItemsAndFeesComponent';
import OnlineStoreRebatesTerms from '../components/rebatesTerms/OnlineStoreRebatesTerms';
import OnlineStoreGeneralInformation from '../components/generalInformation/OnlineStoreGeneralInformation';

import AlmostDoneImage from '../../../assets/images/AlmostDone_Image.png'
import DoneImage from '../../../assets/images/Done_Image.png'
import { OnlineStoreContext } from '../../../contexts/OnlineStoreContext';
import OverlayLoader from '../../../components/loading/OverlayLoader';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import { fail } from "@spordle/toasts";
import { DisplayI18n } from '../../../helpers/i18nHelper';

const OnlineStoreReview = (props) => {
    const [ editStep1, setEditStep1 ] = useState(false);
    const toggleEditStep1 = () => { setEditStep1(!editStep1) };

    const [ editStep3, setEditStep3 ] = useState(false);
    const toggleEditStep3 = () => { setEditStep3(!editStep3) };

    const onlineStoreContext = useContext(OnlineStoreContext);
    const history = useHistory();
    const [ isSubmitting, setSubmitting ] = useState(false);

    return (
        <>
            <div className="text-center mx-auto mb-5" style={{ maxWidth: 500 }}>
                <img className="mb-3" src={AlmostDoneImage} alt="Form not confirmed" width="250" />
                <div className="h4 div font-medium"><Translate id='onlineStore.components.onlineStoreReview.almostDone' /></div>
                <p className="text-muted"><Translate id='onlineStore.components.onlineStoreReview.almostDone.more' /></p>
            </div>
            <Container>
                <div className='position-relative'>
                    <OverlayLoader isLoading={isSubmitting}>
                        <OnlineStoreGeneralInformation isEditing={editStep1} toggle={toggleEditStep1} onFormSave={toggleEditStep1} onlineStoreId={props.onlineStoreId} />
                        <OnlineStoreItemsAndFeesComponent from="REVIEW" onlineStoreId={props.onlineStoreId} />
                        <OnlineStoreRebatesTerms isEditing={editStep3} toggle={toggleEditStep3} onSubmit={toggleEditStep3} onlineStoreId={props.onlineStoreId} />

                        <div className="text-center mx-auto mb-5" style={{ maxWidth: 500 }}>
                            <img className="mb-3" src={DoneImage} alt="Form confirmed" width="250" />
                            <div className="mb-2">
                                <Button
                                    color='primary' onClick={() => {
                                        setSubmitting(true);
                                        onlineStoreContext.partialUpdateOnlineStore(props.onlineStoreId, { status: 'DRAFT' })
                                            .then(() => {
                                                onlineStoreContext.updateCachedStore({ status: 'DRAFT' });
                                                history.push('/onlineregistration');
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                    setSubmitting(false);
                                                }
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message);
                                                }
                                            })
                                    }}
                                >
                                    <Translate id='onlineStore.components.onlineStoreReview.create' />
                                </Button>
                            </div>
                            <p className="text-muted"><Translate id='onlineStore.components.onlineStoreReview.create.more' /></p>
                        </div>
                    </OverlayLoader>
                </div>
            </Container>

        </>
    );
}

export default OnlineStoreReview;

OnlineStoreReview.propTypes = {

};

OnlineStoreReview.defaultProps = {
};