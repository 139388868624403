import { useEffect } from "react"

/**
 * Component that will do thing with the actual portal's lifecycle such as mount and unmount
 * @param {Object} param
 * @param {React.LegacyRef<HTMLElement>} param.container
 * @param {Function} param.setScale
 * @param {Function} param.resetScalePoints
 */
const PortalLifeCycle = ({ container, resetScalePoints }) => {
    useEffect(() => {
        const metaViewportTag = document.querySelector('meta[name=viewport]');

        metaViewportTag.setAttribute('content', `${metaViewportTag.getAttribute('content')}, user-scalable=no`);// Prevent mobile zooming
        document.body.classList.add('overflow-hidden');// Hiding scroll

        function _preventDesktopWheelZoom(e){
            if(e.ctrlKey){
                e.preventDefault();
            }
        }
        container.current.addEventListener('wheel', _preventDesktopWheelZoom, { passive: false });// Defined here since having the onWheel event on the aside doesn't work but it works here
        container.current.focus();

        return () => {
            metaViewportTag.setAttribute('content', metaViewportTag.getAttribute('content').replace(', user-scalable=no', ''));
            document.body.classList.remove('overflow-hidden');
            resetScalePoints();
        }
    }, []);

    return null;
}

export default PortalLifeCycle