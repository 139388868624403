import { useEffect, useRef } from 'react'
import { PreviewFailed } from './FileViewer';
import styles from "./FileViewer.module.scss";

const VideoFile = ({ videoSrc, fileType, canDownload, downloadFile, onPreviewFail }) => {
    const videoTag = useRef(null)
    useEffect(() => {
        videoTag.current.volume = 0.5;
    }, []);
    return (
        <video ref={videoTag} controls autoPlay className={`${styles.Preview} ${styles.PreviewVideo}`} disablePictureInPicture>
            <source src={videoSrc} type={fileType} onError={onPreviewFail} />
            {/* Acts as a loadFail */}
            <PreviewFailed type={fileType} canDownload={canDownload} downloadFile={downloadFile} />
        </video>
    )
}

export default VideoFile
