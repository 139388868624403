import { stringBuilder } from '@spordle/helpers';
import { useContext } from 'react';
import { AppContext } from '../../contexts/contexts';
import { QuickViewContext } from '../../contexts/QuickViewContext';
import HoverableComponent from '../HoverableComponent';
import { Tooltip } from "@mantine/core";

import * as Sentry from "@sentry/react";
import QuickTour from '../quickTour/QuickTour';
import Translate from '@spordle/intl-elements';

/**
 * Component that wraps children and applies an onClick to open a given member profile in the QuickView
 * @param {jsx or string} children
 * @param {jsx or string} className
 * @param {object|Array} member
 * @param {boolean} alwaysShowIcon // ended up wanting to always show icon
 * @param {boolean} iconBeforeChild displays the icon before the child, defaults to false
 * @param {boolean} disabled deactivate the quickview butto
 * @return {React.ReactNode}
 */
const QuickViewButton = (props) => {
    const quickViewContext = useContext(QuickViewContext);
    const { isMobileDevice } = useContext(AppContext);
    const canOpenMember = props.member?.member_id || (Array.isArray(props.member) && props.member.length > 0)
    if(!!props.disabled || quickViewContext.inIframe || !canOpenMember || isMobileDevice || !document.getElementById('quick-view-iframe')?.contentWindow){
        return props.children;
    }

    return <QuickViewButtonInner {...props} />
}

export const QUICK_TOUR_NAME = "quickViewAdd";

const QuickViewButtonInner = ({ children, className, member, alwaysShowIcon = true, iconBeforeChild = false, disableTour = false }) => {
    const quickViewContext = useContext(QuickViewContext);

    const btnId = `quickview-${Array.isArray(member) ? `arr-${member[0]?.member_id}` : member.member_id}`;

    const handleOnClick = (e, theMember) => {
        if(quickViewContext.iframeIsLoaded){
            Sentry.addBreadcrumb({
                type: 'info',
                message: 'Quickview member opened',
                level: Sentry.Severity.Log,
                category: 'QuickView',
                data: {
                    btnId: btnId,
                    member: theMember,
                },
            })
            e.stopPropagation();
            quickViewContext.openMemberQuickView(theMember);
        }
    }

    return (
        <Tooltip
            withArrow
            label={<Translate id='misc.quickview.open' />}
            zIndex={3000}
        >
            <div className={className}>
                <HoverableComponent>
                    {(isHovering) => (
                        <QuickTour
                            disabled={disableTour}
                            quickTourName={QUICK_TOUR_NAME}
                            title={
                                <>
                                    <Translate id="tour.step.quickViewBtn.title" />
                                    <i className='mdi mdi-loupe ml-1' />
                                </>
                            }
                            message={<Translate id="tour.step.quickViewBtn.message" />}
                        >
                            <div className='clickable position-relative pr-4' onClick={(e) => handleOnClick(e, member)}>
                                {iconBeforeChild &&
                                <i
                                    id={btnId}
                                    //position-absolute top-0 right-0
                                    className={stringBuilder('font-medium mdi mdi-loupe mr-1', { 'invisible': (!isHovering && !alwaysShowIcon) })}
                                />
                                }
                                {children}
                                {!iconBeforeChild &&
                                <i
                                    id={btnId}
                                    className={stringBuilder('position-absolute top-0 right-0 font-medium mdi mdi-loupe ml-1', { 'invisible': (!isHovering && !alwaysShowIcon) })}
                                />
                                }
                            </div>
                        </QuickTour>
                    )}
                </HoverableComponent>
            </div>
        </Tooltip>
    );
}

export default QuickViewButton;