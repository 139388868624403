import PropTypes from 'prop-types';

import { FormikSwitch } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';

/**
 * @description Options that will show in each group in the footer
 * @param {object} props
 * @param {boolean} props.hasRequired
 * @param {string} props.id
 */
const QuestionOptions = (props) => {
    return (
        <div className="d-flex justify-content-end">
            {props.hasIsAdmin &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="mr-2 ml-2 font-12 font-medium"><Translate id='catalog.forms.components.formEditor.footerOptions.is_admin' /></div>
                    <FormikSwitch id={`${props.id}.is_admin`} name={`${props.id}.is_admin`} />
                </div>
            }
            {props.hasRequired &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="mr-2 ml-2 font-12 font-medium"><Translate id='catalog.forms.components.formEditor.footerOptions.required' /></div>
                    <FormikSwitch id={`${props.id}.mandatory`} name={`${props.id}.mandatory`} />
                </div>
            }
        </div>
    );
}

QuestionOptions.propTypes = {
    hasRequired: PropTypes.bool,
}

export default QuestionOptions;