import { useDisclosure } from "@mantine/hooks";
import Translate from "@spordle/intl-elements";
import { Button } from "reactstrap";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import AddCompetitionTeamModal from "./modal/AddCompetitionTeamModal";
import { useContext } from "react";
import { useSpordleTable } from "@spordle/datatables";

const AddCompetitionTeam = (props) => {
    const [ isOpen, { open, close } ] = useDisclosure(false);
    const { organisation_id } = useContext(OrganizationContext);

    const table = useSpordleTable();
    const allCompetitionTeams = table.getData();

    return (
        <>
            <Button
                type="button"
                color="primary"
                className="float-right mt-n5 mdi mdi-plus with-icon"
                onClick={open}
            >
                <Translate id='teams.teamSearch.addTeam.btn' />
            </Button>
            <AddCompetitionTeamModal
                orgId={organisation_id}
                isOpen={isOpen}
                toggle={close}
                allCompetitionTeams={allCompetitionTeams}
                {...props}
            />
        </>
    );
}

export default AddCompetitionTeam;