import { stringBuilder } from "@spordle/helpers";
import UserDisplay from "../../../../components/userDisplay/UserDisplay"
import Translate from "@spordle/intl-elements";
import DisplayMember from "../../../../components/display/DisplayMember";

const RenewMemberCard = ({ member, className }) => {
    return (
        <DisplayMember
            member={member}
            className={className}
            extra={{
                infoBottom: (
                    <>
                        <UserDisplay.Subtitle>
                            <span className='font-medium text-dark'><Translate id='task.renewMembers.affiliated.has' /></span>
                            <i className={stringBuilder("ml-2", member.affiliated == 1 ? "mdi mdi-check text-success" : "mdi mdi-close text-danger")} />
                        </UserDisplay.Subtitle>
                        <UserDisplay.Subtitle>
                            <span className='font-medium text-dark'><Translate id='task.renewMembers.memberType.has' /></span>
                            <i className={stringBuilder("ml-2", member.has_member_type == 1 ? "mdi mdi-check text-success" : "mdi mdi-close text-danger")} />
                        </UserDisplay.Subtitle>
                    </>
                ),
            }}
        />
    )
}

export default RenewMemberCard