import { FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import {
    FormGroup,
    Label
} from "reactstrap";
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import FormikEditable from '../../../../../components/formik/FormikEditable';
import { success, fail } from '@spordle/toasts';
import { InstallmentModesContext } from '../../../../../contexts/InstallmentModesContext';
import { RolesContext } from '../../../../../contexts/RolesContext';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import { useDisclosure } from '@mantine/hooks';
import InstallmentLinkedFees from '../InstallmentLinkedFees';

// param data is temporary only to manage it in a single place
const InstallmentSidepanelInstallment = ({ data, toggleIsLoading, ...props }) => {
    const installmentContext = useContext(InstallmentModesContext);
    const rolesContext = useContext(RolesContext);
    const canEdit = rolesContext.canDoAction("EDIT", "catalog", "installment_mode");

    const [ linkedFees, setLinkedFees ] = useState(null);
    const [ isOpen, { toggle } ] = useDisclosure(false);

    const handleError = async() => {
        await installmentContext.getInstallmentLinks(data?.installment_mode_id)
            .then((linkedFees) => {
                if(linkedFees.registration_fees?.length > 0){
                    setLinkedFees(linkedFees)
                    toggle();
                }
                toggleIsLoading(false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: 'catalog.installment.linkedFees.error',
                    })
                    toggleIsLoading(false);
                }
            })
    }

    return (
        <div className="p-3">
            <InstallmentLinkedFees
                isOpen={isOpen}
                toggle={toggle}
                linkedFees={linkedFees}
                installmentModeId={data?.installment_mode_id}
                {...props}
            />

            <FormGroup>
                <Label for='status' className='text-muted mb-0'><Translate id='catalog.installment.form.status' /></Label>
                <FormikEditable
                    id='status'
                    noConfirmation
                    disabled={!canEdit}
                    initialValues={{
                        status: data?.active,
                    }}
                    onSubmit={(values) => {
                        if(data?.active !== values.status){
                            toggleIsLoading(true);
                            const newValues = props.createNewValues({ active: values.status });
                            installmentContext.updateInstallmentModePartial(data?.installment_mode_id, { active: values.status })
                                .then(() => {
                                    props.syncRows(newValues);
                                    success();
                                    toggleIsLoading(false);
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        if(error.code == 3197){
                                            handleError();
                                        }else{
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                            toggleIsLoading(false);
                                        }
                                    }
                                })
                        }
                    }}
                >
                    {(isEditing, options) => {
                        if(!isEditing){
                            return (
                                <div className='font-medium text-dark'>{data?.active === '1' ? <Translate id='misc.active' /> : <Translate id='misc.inactive' />}</div>
                            )
                        }
                        return (
                            <FormikSelect
                                search={false}
                                name='status'
                                id='status'
                                autoFocus
                                menuIsDefaultOpen
                                onOptionSelected={options.stopEditing}
                                defaultData={[
                                    {
                                        label: 'misc.active',
                                        translateLabel: true,
                                        value: '1',
                                    },
                                    {
                                        label: 'misc.inactive',
                                        translateLabel: true,
                                        value: '0',
                                    },
                                ]}
                                loadingStatus='success'
                            />
                        )

                    }}
                </FormikEditable>
            </FormGroup>
            <FormGroup>
                <div className='text-muted mb-0'><Translate id='catalog.installment.table.initialPayment' /></div>
                <div className='font-medium text-dark'>{data?.initial_installment_percentage}%</div>
            </FormGroup>
            <div className="h5 font-bold"><Translate id='catalog.installment.form.installments' /></div>
            <ul className="list-unstyled d-table">
                {data.installment_options.map((opt) => (
                    <li key={opt.installment_option_id} className="d-table-row">
                        <div className="d-table-cell px-1">- <span className="font-medium text-dark">{opt.installment_option_percentage}%</span></div>
                        <div className="d-table-cell px-1"><Translate id='catalog.installment.rule.on' /></div>
                        <div className="d-table-cell px-1">{opt.installment_option_percentage_date}</div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default InstallmentSidepanelInstallment;