import { useSpordleTable } from '@spordle/datatables';
import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import PropTypes from 'prop-types';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';

/**
 * This component allows a user to pick the number of results per page. 0 is for all results.
 * @param {Object} props
 * @param {Array} [props.paginationArray] Array of numbers, containing customized numbers of results for pagination. Defaults are 15,25,50,100,0 (all)
 * @param {Boolean} [props.withLabel] Default true, if true shows 'per page'
 * @returns {React.ReactNode}
 * @copyright Spordle
 */
const TablePagination = ({ className, paginationArray, withLabel, disabled, onChange }) => {
    const spordleTable = useSpordleTable();
    return (
        <UncontrolledButtonDropdown className={stringBuilder(className)} id={spordleTable.generateId('tablePagination')}>
            <DropdownToggle color='primary' outline caret id={spordleTable.generateId('tablePagination', 'current')} disabled={disabled}>
                {withLabel &&
                    <span className='mr-1'>
                        <Translate id='misc.show' />:
                    </span>
                }
                <span>
                    {
                        spordleTable.getPagination() === 0 ?
                            <Translate id='misc.all' />
                            :
                            spordleTable.getPagination()
                    }
                </span>
            </DropdownToggle>
            <DropdownMenu right>
                {
                    paginationArray.map((number) => (
                        number === 0 ?
                            <DropdownItem
                                key={number}
                                id={spordleTable.generateId('tablePagination', 'all')}
                                onClick={() => {
                                    onChange?.(number);
                                    spordleTable.setPagination(number);
                                }}
                                className={stringBuilder({ 'text-primary': spordleTable.getPagination() === number })}
                            >
                                <Translate id='misc.all' />
                            </DropdownItem>
                            :
                            <DropdownItem
                                key={number}
                                id={spordleTable.generateId('tablePagination', number)}
                                onClick={() => {
                                    onChange?.(number);
                                    spordleTable.setPagination(number);
                                }}
                                className={stringBuilder({ 'text-primary': spordleTable.getPagination() === number })}
                            >
                                {spordleTable.getPagination() === number && <i className='mdi mdi-check mr-1' />}{number}
                            </DropdownItem>
                    ))
                }
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    )
}

export default TablePagination

TablePagination.propTypes = {
    paginationArray: PropTypes.arrayOf(PropTypes.number),
    withLabel: PropTypes.bool,
};

TablePagination.defaultProps = {
    paginationArray: [ 15, 25, 50, 100 ],
    withLabel: true,
}