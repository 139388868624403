export default{
    "members.profile.overview.memberFamilyRelations.title"       : "Liens familiaux",
    "members.profile.overview.memberFamilyRelations.edit.title"  : "Modifier les liens familiaux",
    "members.profile.overview.memberFamilyRelations.edit.save"   : "Sauvegarder les changements",
    "members.profile.overview.memberFamilyRelations.edit.cancel" : "Annuler les changements",

    // general
    "members.profile.overview.memberFamilyRelations.uniqueIdentifier"  : "{unique_identifier}",
    "members.profile.overview.memberFamilyRelations.age"               : "ans",
    "members.profile.overview.memberFamilyRelations.relation"          : "Relation",
    "members.profile.overview.memberFamilyRelations.relation.required" : "La relation est requise",

    // table view
    "members.profile.overview.memberFamilyRelations.table.noData" : "Aucun lien familial",

    // add modal view
    "members.profile.overview.memberFamilyRelations.add.title"              : "Ajouter un lien familial",
    "members.profile.overview.memberFamilyRelations.add.or"                 : "Ou",
    "members.profile.overview.memberFamilyRelations.add.memberNumber"       : "{unique_identifier}",
    "members.profile.overview.memberFamilyRelations.add.memberNumber.hint"  : "La vérification ignorera les autres champs si celui-ci est spécifié",
    "members.profile.overview.memberFamilyRelations.add.search"             : "Rechercher",
    "members.profile.overview.memberFamilyRelations.add.noData"             : "Aucun résultat",
    "members.profile.overview.memberFamilyRelations.add.select"             : "Sélectionner",
    "members.profile.overview.memberFamilyRelations.add.select.placeholder" : "Sélectionner...",
    "members.profile.overview.memberFamilyRelations.add.select.noOptions"   : "Aucun lien disponible",
    "members.profile.overview.memberFamilyRelations.add.alreadyExists"      : "Ce membre est déjà dans un lien familial",
    "members.profile.overview.memberFamilyRelations.add.3230"               : "Les membres ne peuvent pas être les mêmes",
    "members.profile.overview.memberFamilyRelations.add.3232"               : "La relation n'a pas pu être trouvée",
    "members.profile.overview.memberFamilyRelations.add.3231"               : "Les membres sont déjà liés",
    "members.profile.overview.memberFamilyRelations.add.success"            : "Lien familial créé!",

    // side panel view
    "members.profile.overview.memberFamilyRelations.sidePanel.remove"         : "Retirer",
    "members.profile.overview.memberFamilyRelations.sidePanel.delete.text"    : "Vous êtes sur le point de supprimer cette relation familiale.",
    "members.profile.overview.memberFamilyRelations.sidePanel.delete.success" : "Relation familiale supprimée!",
    "members.profile.overview.memberFamilyRelations.sidePanel.update.success" : "Relation familiale modifiée!",
    "members.profile.overview.memberFamilyRelations.sidePanel.relation"       : "Ce membre vous a ajouté en tant que",

    // linked accounts
    "members.profile.overview.linkedAccounts.title"     : "Comptes liés",
    "members.profile.overview.linkedAccounts.noLinked"  : "Aucun compte lié...",
    "members.profile.overview.linkedAccounts.showRoles" : "Afficher les rôles",
    "members.profile.overview.linkedAccounts.hideRoles" : "Cacher les rôles",

}