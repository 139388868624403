import React from "react";
import {
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import AppealsSidepanel1 from "./AppealsSidepanel1";
import AppealsSidepanel2 from "./AppealsSidepanel2";
import { DisplayI18n } from "../../../../../../../../helpers/i18nHelper";
import OverlayLoader from "../../../../../../../../components/loading/OverlayLoader";
import { AxiosIsCancelled } from "../../../../../../../../api/CancellableAPI";

// contexts
import { MembersContext } from '../../../../../../../../contexts/MembersContext';
import withContexts from '../../../../../../../../helpers/withContexts';
import SidePanel from "../../../../../../../../components/sidePanel/SidePanel";
import { fail } from '@spordle/toasts';
import { compareAndFormatFiles } from "../../../../../../../../components/uploader/uploadHelpers";

class AppealsSidepanel extends React.Component{

    constructor(props){
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',
            isLoading: false,
        };
    }

    setLoading = (_isLoading) => {
        this.setState(() => ({ isLoading: _isLoading }))
    }

    toggle(tab){
        if(this.state.activeTab !== tab){
            this.setState({
                activeTab: tab,
            });
        }
    }

    handleDelete = () => {
        return this.props.MembersContext.deleteMemberAppeal(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_appeal_id)
            .then(() => {
                this.props.tableRef.deleteRow(this.props.selectedRows[0].member_appeal_id)
                this.props.toggle();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    appealAttachmentsOnSubmit = async(files, type) => {
        const { toKeep, toDelete, toCreate } = compareAndFormatFiles(this.props.selectedRows[0].attachments?.filter((attachment) => attachment.type === type), files);
        let newVal = { attachments: [] };

        if(toDelete?.length > 0){
            await Promise.all(
                toDelete.map((att) => (
                    this.props.MembersContext.deleteMemberAppealAttachment(this.props.selectedRows[0].member_appeal_id, att.member_appeal_attachment_id)
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })
                )),
            )
            this.props.syncRows(this.props.createNewValues({ attachments: [ ...toKeep, ...this.props.selectedRows[0].attachments?.filter((attachment) => attachment.type !== type) || [] ] })); // necessary in case of error later
        }

        if(toCreate?.length > 0 && toCreate?.[0]?.length > 0){
            await Promise.all(
                toCreate.map((filesArr) => {
                    return (
                        this.props.MembersContext.createMemberAppealAttachment(
                            this.props.selectedRows[0].member_appeal_id,
                            filesArr.map((file) => ({
                                ...file,
                                type: type,
                            })),
                        ).catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })
                    )
                }),
            );

            newVal = {
                attachments: await this.props.MembersContext.getMemberAppealAttachments(this.props.selectedRows[0].member_appeal_id)
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    }),
            };

            this.props.syncRows(this.props.createNewValues(newVal));
        }
    }

    handleFileClick = (file) => {
        return this.props.MembersContext.downloadMemberAppealAttachment(
            this.props.selectedRows[0].member_appeal_id,
            file.member_appeal_attachment_id,
        ).catch((error) => {
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message)
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
            }
        })
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <SidePanel.Header noBorder>
                    <div className='d-flex mb-2'>
                        <SidePanel.ToggleButton />
                        <SidePanel.ActionsMenu label='misc.action' action='DELETE' componentCode='members' componentPermissionCode='member_appeal_request'>
                            <SidePanel.MenuDelete
                                translateModalMsg
                                modalMsg='members.profile.appeals.sidepanel.actions.delete.text'
                                onConfirm={this.handleDelete}
                            />
                        </SidePanel.ActionsMenu>
                    </div>
                    <Row>
                        <Col sm="6">
                            <div className='mb-3'>
                                <div className="h6 font-bold"><Translate id='members.profile.appeals.from' /></div>
                                <div className="small"><DisplayI18n field='name' defaultValue={this.props.selectedRows[0].appeal_from_org.organisation_name} i18n={this.props.selectedRows[0].appeal_from_org.i18n} /></div>
                                <div>
                                    {this.props.selectedRows[0].appeal_from_team ?
                                        this.props.selectedRows[0].appeal_from_team.name
                                        :
                                        this.props.selectedRows[0].appeal_from_team_name
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className='mb-3'>
                                <div className="h6 font-bold"><Translate id='members.profile.appeals.to' /></div>
                                <div className="small"><DisplayI18n field='name' defaultValue={this.props.selectedRows[0].appeal_to_org.organisation_name} i18n={this.props.selectedRows[0].appeal_to_org.i18n} /></div>
                                <div>
                                    {this.props.selectedRows[0].appeal_to_team ?
                                        this.props.selectedRows[0].appeal_to_team.name
                                        :
                                        this.props.selectedRows[0].appeal_to_team_name
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </SidePanel.Header>
                <Nav tabs>
                    <NavItem className="text-center w-50">
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}
                        >
                            <Translate id='members.profile.appeals.sidepanel.tabs.general' />
                        </NavLink>
                    </NavItem>
                    <NavItem className="text-center w-50">
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggle('2'); }}
                        >
                            <Translate id='members.profile.appeals.sidepanel.tabs.account.short' />
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <AppealsSidepanel1 {...this.props} setLoading={this.setLoading} appealAttachmentsOnSubmit={this.appealAttachmentsOnSubmit} handleFileClick={this.handleFileClick} />
                    </TabPane>
                    <TabPane tabId="2">
                        <AppealsSidepanel2 {...this.props} setLoading={this.setLoading} appealAttachmentsOnSubmit={this.appealAttachmentsOnSubmit} handleFileClick={this.handleFileClick} />
                    </TabPane>
                </TabContent>
            </OverlayLoader>
        )
    }
}

export default withContexts(MembersContext)(AppealsSidepanel);