import React from "react";
import {
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import FormikEditable from '../../../../../../components/formik/FormikEditable';
import { FormikInputText, FormikSelect } from '@spordle/formik-elements';
import * as Yup from 'yup'
import { success, fail } from '@spordle/toasts';
import PropTypes from 'prop-types';
import { DisplayI18n, RenderI18nForm, I18nHelperContext } from '../../../../../../helpers/i18nHelper';

// Context
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import withContexts from '../../../../../../helpers/withContexts';
import { FormsContext } from '../../../../../../contexts/FormsContext';
import { WaiversContext } from '../../../../../../contexts/WaiversContext';
import { PeriodsContext } from "../../../../../../contexts/contexts";

// Language
import Translate from "@spordle/intl-elements";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import UserImg from "../../../../../../components/UserImg";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { memberTypeSpecial } from "./components/memberTypesConstants";

class SidePanelOrganizationMemberTypes extends React.Component{

    state = {
        activeTab: '1',
        isLoading: true,
        forms: [],
        waivers: [],
        linkedForm: null,
        linkedWaivers: [],
    }

    toggleTab = (tab) => {
        if(this.state.activeTab !== tab){
            this.setState({ activeTab: tab });
        }
    }

    componentDidMount(){
        Promise.all([
            this.props.FormsContext.getForms(this.props.OrganizationContext.organisation_id),
            this.props.WaiversContext.getWaivers(),
            this.getLinkedForm(),
            this.getLinkedWaivers(),
        ])
            .then(([ forms, waivers ]) => {
                this.setState(() => ({
                    isLoading: false,
                    forms: forms.filter((f) => f.active == 1),
                    waivers: waivers.filter((w) => w.active == 1),
                }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                }
            })
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedRows[0].member_type_id !== this.props.selectedRows[0].member_type_id){
            this.getLinkedForm();
            this.getLinkedWaivers();
        }
    }

    getLinkedForm = () => {
        this.setState(() => ({ isLoading: true }));
        return this.props.MembersContext.getMemberTypeForms(this.props.selectedRows[0].member_type_id)
            .then((form) => {
                this.setState(() => ({
                    linkedForm: form,
                    isLoading: false,
                }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    this.setState(() => ({ isLoading: false }));
                }
            })
    }

    getLinkedWaivers = () => {
        this.setState(() => ({ isLoading: true }));
        return this.props.MembersContext.getMemberTypeWaivers(this.props.selectedRows[0].member_type_id)
            .then((waivers) => {
                this.setState(() => ({
                    linkedWaivers: waivers,
                    isLoading: false,
                }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    this.setState(() => ({ isLoading: false }));
                }
            })
    }

    render(){
        return (
            <>
                <OverlayLoader isLoading={this.state.isLoading}>
                    <SidePanel.Header noBorder>
                        <div className='d-flex mb-2 align-items-center'>
                            <SidePanel.ToggleButton />
                            <SidePanel.ActionsMenu action='DELETE' componentCode='members' componentPermissionCode='member_types'>
                                <SidePanel.MenuDelete
                                    translateModalMsg
                                    modalMsg='organization.settings.sidePanelOrganizationMemberTypes.actions.remove.topText'
                                    onConfirm={() => {
                                        return this.props.MembersContext.deleteMemberType(this.props.getCommonValue('member_type_id'))
                                            .then(() => {
                                                this.props.tableRef.deleteRow(this.props.getCommonValue('member_type_id')).then(this.props.toggle)
                                            })
                                            .catch((e) => {
                                                if(!AxiosIsCancelled(e.message)){
                                                    console.error(e);
                                                }
                                            })
                                    }}
                                    modalContent={
                                        <Row className='mt-3'>
                                            <Col md='6' className='d-flex align-items-center justify-content-center flex-column border-right'>
                                                <div className='font-medium'>
                                                    <DisplayI18n
                                                        field="name"
                                                        i18n={this.props.getCommonValue('i18n')}
                                                        defaultValue={this.props.getCommonValue('name')}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md='6' className='d-flex align-items-center justify-content-center flex-column'>
                                                <div className='mr-2'>
                                                    <UserImg
                                                        src={this.props.OrganizationContext.logo?.full_path}
                                                        filePos={this.props.OrganizationContext.logo?.file_position}
                                                        alt={this.props.OrganizationContext.organisation_name}
                                                        abbr={this.props.OrganizationContext.abbreviation}
                                                    />
                                                </div>
                                                <div className='font-medium'>{this.props.OrganizationContext.organisation_name}</div>
                                            </Col>
                                        </Row>
                                    }
                                />
                            </SidePanel.ActionsMenu>
                        </div>
                        <SidePanel.Title>
                            <DisplayI18n
                                field='name'
                                defaultValue={this.props.getCommonValue('name')}
                                i18n={this.props.getCommonValue('i18n')}
                            />
                        </SidePanel.Title>
                    </SidePanel.Header>

                    <Nav tabs>
                        <NavItem className="flex-grow-1 text-center w-100">
                            <NavLink
                                className={stringBuilder({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggleTab('1'); }}
                            >
                                <Translate id='organization.settings.sidePanelOrganizationMemberTypes.tabs.details' />
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            {/* MEMBER TYPE INFO */}
                            <div className='p-3 border-bottom'>
                                <div className="h4 font-bold mb-3"><Translate id='organization.settings.sidePanelOrganizationMemberTypes.title' /></div>
                                <RenderI18nForm field='name'>
                                    {({ fieldName, fieldLabel, lang }) => (
                                        <div className="mb-3" key={fieldName}>
                                            <div className='text-muted'>{fieldLabel}</div>
                                            <FormikEditable
                                                id={fieldName}
                                                initialValues={this.props.I18nHelperContext.getInitialValues(this.props.selectedRows[0])}
                                                validationSchema={Yup.object().shape(
                                                    this.props.I18nHelperContext.getValidationSchema({ name: Yup.string().required(<Translate id='form.required' />) }),
                                                )}
                                                onSubmit={(values) => {

                                                    this.setState(() => ({ isLoading: true }));
                                                    const newValues = this.props.createNewValues(values);
                                                    this.props.MembersContext.updateMemberType(this.props.getCommonValue('member_type_id'), this.props.I18nHelperContext.getAPIValues(values))
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            success();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                        .finally(() => this.setState(() => ({ isLoading: false })))
                                                }}
                                            >
                                                {(isEditing) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className='font-medium text-dark'>
                                                                <DisplayI18n lang={lang} field={fieldName} i18n={this.props.selectedRows[0].i18n} defaultValue={this.props.selectedRows[0].name || '-'} />
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikInputText name={fieldName} id={fieldName} trim autoFocus />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    )}
                                </RenderI18nForm>

                                {/* STATUS */}
                                <div className="mb-3">
                                    <div className="text-muted"><Translate id='organization.settings.sidePanelOrganizationMemberTypes.status' /></div>
                                    <FormikEditable
                                        id='active'
                                        noConfirmation
                                        initialValues={{
                                            active: this.props.getCommonValue("active"),
                                        }}
                                        validationSchema={Yup.object().shape({
                                            active: Yup.string().required(<Translate id='organization.settings.sidePanelOrganizationMemberTypes.status.required' />),
                                        })}
                                        onSubmit={(values) => {
                                            if(values.active !== this.props.getCommonValue("active")){
                                                this.setState(() => ({ isLoading: true }));
                                                const newValues = this.props.createNewValues(values);
                                                this.props.MembersContext.updateMemberType(this.props.getCommonValue('member_type_id'), values)
                                                    .then(() => {
                                                        this.props.syncRows(newValues);
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                                    .finally(() => this.setState(() => ({ isLoading: false })))
                                            }
                                        }}
                                    >
                                        {(isEditing, options) => {
                                            if(!isEditing){
                                                return (
                                                    <div className="font-medium">
                                                        <i className={`mdi ${this.props.getCommonValue("active") == '1' ? 'mdi-check text-primary' : 'mdi-close text-danger'} mr-2`} />
                                                        <span><Translate id={`organization.settings.sidePanelOrganizationMemberTypes.status.${this.props.getCommonValue("active") == '1' ? 'active' : 'inactive'}`} /></span>
                                                    </div>
                                                )
                                            }
                                            return (
                                                <FormikSelect
                                                    id='active' name='active'
                                                    autoFocus menuIsDefaultOpen
                                                    onOptionSelected={() => {
                                                        options.stopEditing()
                                                    }}
                                                    defaultData={[
                                                        { value: '1', label: 'organization.settings.sidePanelOrganizationMemberTypes.status.active', translateLabel: true },
                                                        { value: '0', label: 'organization.settings.sidePanelOrganizationMemberTypes.status.inactive', translateLabel: true },
                                                    ]}
                                                    loadingStatus='success'
                                                />
                                            )

                                        }}
                                    </FormikEditable>
                                </div>

                                {this.props.selectedRows[0].system_type === memberTypeSpecial &&
                                    <>
                                        {/* FORMS */}
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='organization.settings.sidePanelOrganizationMemberTypes.forms' /></div>
                                            <FormikEditable
                                                id='forms'
                                                noConfirmation
                                                initialValues={{
                                                    formId: this.state.linkedForm?.custom_form?.custom_form_id || '',
                                                }}
                                                onSubmit={(values) => {
                                                    if(values.formId !== (this.state.linkedForm?.custom_form?.custom_form_id || '')){
                                                        this.setState(() => ({ isLoading: true }));
                                                        Promise.all(
                                                            values.formId ?
                                                                [ this.props.FormsContext.formMemberTypeLink(values.formId, this.props.selectedRows[0].member_type_id, this.props.PeriodsContext.selectedPeriod.period_id) ]
                                                                : !values.formId && this.state.linkedForm?.custom_form?.custom_form_id ?
                                                                    [ this.props.MembersContext.deleteFormMemberTypeLink(this.props.selectedRows[0].member_type_id, this.state.linkedForm.custom_form.custom_form_id) ]
                                                                    :
                                                                    Promise.resolve(),
                                                        )
                                                            .then(async() => {
                                                                await this.getLinkedForm();
                                                            })
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                }
                                                            })
                                                            .finally(() => this.setState(() => ({ isLoading: false })))
                                                    }
                                                }}
                                            >
                                                {(isEditing, options) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className="font-medium">
                                                                {this.state.linkedForm?.custom_form?.custom_form_id ?
                                                                    this.state.linkedForm?.custom_form.name
                                                                    :
                                                                    '-'
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikSelect
                                                            id='formId' name='formId'
                                                            autoFocus menuIsDefaultOpen clearable
                                                            onOptionSelected={() => {
                                                                options.stopEditing()
                                                            }}
                                                            options={this.state.forms?.map((form) => ({
                                                                value: form.custom_form_id,
                                                                label: form.name,
                                                            }))}
                                                            loadingStatus='success'
                                                        />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>

                                        {/* WAIVERS */}
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='organization.settings.sidePanelOrganizationMemberTypes.waivers' /></div>
                                            <FormikEditable
                                                id='waivers'
                                                noConfirmation
                                                initialValues={{
                                                    waiverIds: this.state.linkedWaivers?.map((w) => w.waiver.waiver_id) || [],
                                                }}
                                                onSubmit={(values) => {
                                                    this.setState(() => ({ isLoading: true }));
                                                    this.props.MembersContext.linkMemberTypeWaivers(this.props.selectedRows[0].member_type_id, {
                                                        organisation_id: this.props.OrganizationContext.organisation_id,
                                                        period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                                                        waivers: values.waiverIds.length > 0 ? values.waiverIds : '',
                                                    })
                                                        .then(async() => {
                                                            await this.getLinkedWaivers();
                                                        })
                                                        .catch((error) => {
                                                            fail({ info: <DisplayI18n field="message" defaultValue={error.message} i18n={error.i18n} />, skipInfoTranslate: true });
                                                            console.error(error.message)
                                                        })
                                                        .finally(() => this.setState(() => ({ isLoading: false })))
                                                }}
                                            >
                                                {(isEditing, options) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className="font-medium">
                                                                <Translate
                                                                    id='organization.settings.sidePanelOrganizationMemberTypes.selected'
                                                                    values={{
                                                                        amount: this.state.linkedWaivers?.length,
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikSelect
                                                            id='waiverIds' name='waiverIds'
                                                            autoFocus menuIsDefaultOpen clearable
                                                            multi
                                                            renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                            options={this.state.waivers?.map((waiver) => ({
                                                                value: waiver.waiver_id,
                                                                label: waiver.name,
                                                                i18n: waiver.i18n,
                                                            }))}
                                                            loadingStatus='success'
                                                        />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    </>
                                }
                            </div>
                        </TabPane>
                    </TabContent>
                </OverlayLoader>
            </>
        )
    }
}

SidePanelOrganizationMemberTypes.propTypes = {
    tableRef: PropTypes.object.isRequired,
    i18n: PropTypes.object,
}

export default withContexts(MembersContext, OrganizationContext, I18nHelperContext, FormsContext, WaiversContext, PeriodsContext)(SidePanelOrganizationMemberTypes);