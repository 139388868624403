import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    CustomInput,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Spinner,
    UncontrolledButtonDropdown,
    UncontrolledDropdown
} from "reactstrap";

import SpordleTableProvider, { SpordleTableContext, SpordleTableView, useSpordleTable } from '@spordle/datatables';
import SidePanelAttendees from './sidePanel/SidePanelAttendees';
import ClinicProfileRegistrationAdd from './addModal/ClinicProfileRegistrationAdd';
import Translate, { DateFormat } from '@spordle/intl-elements';
import SpordlePanelTable from "../../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../../components/table/EmptyLayout";
import { DisplayI18n } from "../../../../helpers/i18nHelper";

// contexts
import { ClinicsContext } from '../../../../contexts/ClinicsContext';
import withContexts from '../../../../helpers/withContexts';
import { withRouter, useLocation } from "react-router";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import { I18nContext } from "../../../../contexts/I18nContext";
import SpordleSelect from "@spordle/spordle-select";
import { ReportsContext } from "../../../../contexts/ReportsContext";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { useIntl } from "react-intl";
import { exportReportsToCSV, handleExcelExport } from "../../../../helpers/reportsHelper";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import { fail } from '@spordle/toasts';
import { Link } from "react-router-dom";
import TablePagination from "../../../../components/table/TablePagination";
import { Tooltip } from "@mantine/core";
import DisplayClinicAttendeeGrade from "../../../../components/display/DisplayClinicAttendeeGrade";

class ClinicProfileAttendees extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            // Add Modal
            modalAdd: false,
        };
    }

    toggleModalAdd = () => {
        this.setState((prevState) => ({ modalAdd: !prevState.modalAdd }));
    }

    render(){
        return (
            <>
                <Card body className="card-shadow">
                    <div className="h4 font-bold pb-1 border-bottom mb-3 card-title">
                        <Translate id='clinics.profile.clinicProfile.tabs.attendees' />
                    </div>
                    <div>
                        {this.props.ClinicsContext.currentClinic?.clinic_id === this.props.clinicId ?
                            <SpordlePanelTable
                                allowOutsideClick
                                sidePanel={(props) => <SidePanelAttendees {...props} />}
                                dataIndex='clinic_attendee_id'
                                table={(panelProps) => {
                                    return (
                                        <SpordleTableProvider
                                            id='attendees'
                                            key={this.props.ClinicsContext.currentClinic?.clinic_external_identifier?.[0]?.external_id}
                                            tableHover bordered striped
                                            clickable
                                            ref={(r) => {
                                                this.props.attendeeTable.current = r;
                                                panelProps.spordleTableRef(r);
                                            }}
                                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                            searchKeys={[
                                                { name: 'member.unique_identifier', weight: 2 },
                                                'member.last_name',
                                                'member.first_name',
                                                `member.organisation.i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                'member.organisation.organisation_name',
                                                'member.birthdate',
                                                'grade',
                                                'grade_memo',
                                            ]}
                                            pagination={25}
                                            desktopWhen
                                            rowIsHighlighted={(attendee) => !!attendee.checked}
                                            emptyLayout={<EmptyLayout />}
                                            dataIndex='clinic_attendee_id'
                                            columns={[
                                                {
                                                    label: '-',
                                                    key: 'checkbox',
                                                    className: 'th-shrink',
                                                    dataClassName: 'td-shrink',
                                                },
                                                {
                                                    label: <Translate id='form.fields.name' />,
                                                    key: "name",
                                                    className: 'text-left',
                                                    dataClassName: 'text-left',
                                                    mobile: true,
                                                    sortable: true,
                                                    sortKey: 'member.first_name',
                                                },
                                                {
                                                    label: <Translate id='clinics.profile.attendees.table.organization' />,
                                                    key: "organization",
                                                    className: 'text-left',
                                                    dataClassName: 'text-left',
                                                    mobile: true,
                                                    sortable: true,
                                                    sortKey: 'member.organisation.organisation_name',
                                                },
                                                {
                                                    label: <Translate id='form.fields.dateOfBirth' />,
                                                    key: "birthdate",
                                                    className: 'text-left',
                                                    dataClassName: 'text-left',
                                                    mobile: true,
                                                    sortable: true,
                                                    sortKey: 'member.birthdate',
                                                },
                                                {
                                                    label: <Translate id='clinics.profile.attendees.table.paid' />,
                                                    key: "paid",
                                                    className: 'text-center td-shrink',
                                                    dataClassName: 'text-center td-shrink',
                                                    mobile: true,
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='clinics.profile.attendees.table.attended' />,
                                                    key: "attended",
                                                    className: 'text-center td-shrink',
                                                    dataClassName: 'text-center td-shrink',
                                                    mobile: true,
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='clinics.profile.attendees.table.passed' />,
                                                    key: "passed",
                                                    className: 'text-center td-shrink',
                                                    dataClassName: 'text-center td-shrink',
                                                    mobile: true,
                                                    sortable: true,
                                                },

                                                ...(this.props.ClinicsContext.currentClinic?.clinic_external_identifier?.[0]?.external_id ? [ {
                                                    label: <Translate id="clinics.profile.attendees.table.grade" />,
                                                    key: "grade",
                                                    className: 'text-center td-shrink',
                                                    dataClassName: 'text-center td-shrink',
                                                    mobile: true,
                                                    sortable: true,
                                                } ] : []),
                                            ]}
                                            columnSorting={(attendeeA, attendeeB, _, orderingData) => {
                                                switch (orderingData.column){
                                                    case 'paid':
                                                        const attendeeAPaid = attendeeA.invoice?.status === 'COMPLETED' || attendeeA.invoice?.status === 'PAID'
                                                        const attendeeBPaid = attendeeB.invoice?.status === 'COMPLETED' || attendeeB.invoice?.status === 'PAID'
                                                        if(attendeeAPaid === attendeeBPaid){
                                                            return 0;
                                                        }

                                                        if(orderingData.order === 'ASC'){
                                                            return attendeeAPaid && !attendeeBPaid ? 1 : -1
                                                        } // orderingData.order is 'DESC'
                                                        return attendeeAPaid && !attendeeBPaid ? -1 : 1

                                                    default:
                                                        break;
                                                }
                                            }}
                                            onColumnClick={(e, attendee, _spordleTable, columnKey) => {
                                                switch (e.button){
                                                    case 0: // Left mouse button
                                                        if(columnKey === 'checkbox'){
                                                            panelProps.onMultiSelectChange(attendee);
                                                        }else{
                                                            panelProps.onSingleSelectChange(attendee);
                                                        }
                                                        break;
                                                }
                                            }}
                                            renderRow={(columnKey, attendee) => {
                                                switch (columnKey){
                                                    case 'checkbox':
                                                        return (
                                                            <label
                                                                className='my-auto'
                                                                htmlFor={'checkbox-' + attendee.clinic_attendee_id}
                                                                onClick={(e) => e.stopPropagation()}
                                                            >
                                                                <input
                                                                    onChange={() => panelProps.onMultiSelectChange(attendee)}
                                                                    id={'checkbox-' + attendee.clinic_attendee_id}
                                                                    type='checkbox'
                                                                    checked={!!attendee.checked}
                                                                />
                                                                <span className='table-checkbox' />
                                                            </label>
                                                        )
                                                    case 'name':
                                                        return (
                                                            <NameRender attendee={attendee} />
                                                        );
                                                    case "grade":
                                                        return (
                                                            <DisplayClinicAttendeeGrade hideMemo attendee={attendee} />
                                                        )
                                                    case 'organization':
                                                        return (
                                                            <DisplayI18n field='name' defaultValue={attendee.member.organisation.organisation_name} i18n={attendee.member.organisation.i18n} />
                                                        )
                                                    case 'birthdate':
                                                        return (
                                                            <>
                                                                {(attendee.member.birthdate || attendee.member.age) ?
                                                                    <>
                                                                        {attendee.member.birthdate && <div><DateFormat value={attendee.member.birthdate} utc /></div>}
                                                                        {attendee.member.age && <div className="text-muted small"><Translate id='misc.yearsOld' values={{ age: attendee.member.age }} /></div>}
                                                                    </>
                                                                    : '-'}
                                                            </>
                                                        );
                                                    case 'paid':
                                                        if(attendee.invoice?.status === 'COMPLETED' || attendee.invoice?.status === 'PAID'){
                                                            return <i className="text-primary mdi mdi-check" />
                                                        }
                                                        return <span>-</span>
                                                    case 'attended':
                                                        if(attendee.attended == 1){
                                                            return <i className="text-primary mdi mdi-check" />
                                                        }
                                                        return <span>-</span>
                                                    case 'passed':
                                                        if(attendee.passed == 1){
                                                            return <i className="text-primary mdi mdi-check" />
                                                        }
                                                        return <span>-</span>

                                                    default:
                                                        break;
                                                }
                                            }}
                                            loadData={(from, data, spordleTable) => {
                                                switch (from){
                                                    case 'REFRESH':
                                                        spordleTable.setLoading()
                                                    case 'CDM':
                                                        return this.props.ClinicsContext.getClinicAttendees(this.props.ClinicsContext.currentClinic.clinic_id, from === 'REFRESH')
                                                    default:
                                                        break;
                                                }
                                            }}
                                            initFilter={{
                                                attendedStatus: null,
                                                paidStatus: null,
                                                passedStatus: null,
                                                unconfirmed: false,
                                                cancelled: false,
                                            }}
                                            filterJSCallback={(attendee, { attendedStatus, passedStatus, paidStatus, unconfirmed, cancelled }) => {
                                                if((unconfirmed && attendee.member.confirmed != '0') || (cancelled && !attendee.cancelled_at)){ // When we want the unconfirmed only -> return false when it is confirmed
                                                    return false;
                                                }

                                                let showResult = true;

                                                if(paidStatus){
                                                    const hasPaid = attendee.invoice?.status === 'COMPLETED' || attendee.invoice?.status === 'PAID';
                                                    showResult = paidStatus === "PAID" ? hasPaid : !hasPaid;
                                                }

                                                if(attendedStatus && showResult){
                                                    const hasAttended = attendee.attended == "1";
                                                    showResult = attendedStatus === "ATTENDED" ? hasAttended : !hasAttended;
                                                }

                                                if(passedStatus && showResult){
                                                    const hasPassed = attendee.passed == "1";
                                                    showResult = passedStatus === "PASSED" ? hasPassed : !hasPassed;
                                                }

                                                return showResult;
                                            }}
                                            paginationMessage='clinics.profile.attendees.table.paginationMessage'
                                        >
                                            {(spordleTable) => (
                                                <>
                                                    <Row className="mb-4 pb-4 border-bottom" form>
                                                        <Col sm='4'>
                                                            <CustomInput
                                                                id='unconfirmed'
                                                                name='unconfirmed'
                                                                className="d-flex align-items-center mb-2"
                                                                type='checkbox'
                                                                checked={spordleTable.getFilters().unconfirmed}
                                                                onChange={(e) => {
                                                                    spordleTable.filterChange('unconfirmed', e.target.checked);
                                                                }}
                                                                label={<Translate id='clinics.profile.attendees.table.filters.unconfirmed' />}
                                                            />
                                                        </Col>
                                                        <Col sm="8">
                                                            <CustomInput
                                                                id='cancelled'
                                                                name='cancelled'
                                                                className="d-flex align-items-center mb-2"
                                                                type='checkbox'
                                                                checked={spordleTable.getFilters().cancelled}
                                                                onChange={(e) => {
                                                                    spordleTable.filterChange('cancelled', e.target.checked);
                                                                }}
                                                                label={<Translate id='clinics.profile.attendees.table.filters.cancelled' />}
                                                            />
                                                        </Col>
                                                        <Col sm="4">
                                                            <SpordleSelect
                                                                id="paidStatus"
                                                                search={false}
                                                                clearable
                                                                defaultData={[
                                                                    { value: 'PAID', label: 'clinics.profile.attendees.table.paid', translateLabel: true },
                                                                    { value: '!PAID', label: 'clinics.profile.attendees.table.paid.not', translateLabel: true },
                                                                ]}
                                                                loadingStatus='success'
                                                                placeholder='clinics.profile.attendees.table.header.paidFilter.placeholder'
                                                                onOptionSelected={([ value ]) => {
                                                                    spordleTable.filterChange('paidStatus', value);
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col sm="4">
                                                            <SpordleSelect
                                                                id="attendedStatus"
                                                                search={false}
                                                                clearable
                                                                defaultData={[
                                                                    { value: 'ATTENDED', label: 'clinics.profile.attendees.table.attended', translateLabel: true },
                                                                    { value: '!ATTENDED', label: 'clinics.profile.attendees.table.attended.not', translateLabel: true },
                                                                ]}
                                                                loadingStatus='success'
                                                                placeholder='clinics.profile.attendees.table.header.attendedFilter.placeholder'
                                                                onOptionSelected={([ value ]) => {
                                                                    spordleTable.filterChange('attendedStatus', value);
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col sm="4">
                                                            <SpordleSelect
                                                                id="passedStatus"
                                                                search={false}
                                                                clearable
                                                                defaultData={[
                                                                    { value: 'PASSED', label: 'clinics.profile.attendees.table.passed', translateLabel: true },
                                                                    { value: '!PASSED', label: 'clinics.profile.attendees.table.passed.not', translateLabel: true },
                                                                ]}
                                                                loadingStatus='success'
                                                                placeholder='clinics.profile.attendees.table.header.passedFilter.placeholder'
                                                                onOptionSelected={([ value ]) => {
                                                                    spordleTable.filterChange('passedStatus', value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <AttendeeHandleSearch panelProps={panelProps} />
                                                    <div>
                                                        <div className='d-flex flex-wrap justify-content-between mb-2'>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle color='primary' className='d-flex align-items-center mr-2' caret>
                                                                    <div className='align-self-center custom-checkbox custom-control'>
                                                                        <input
                                                                            id='topCheckID'
                                                                            type='checkbox'
                                                                            className='custom-control-input d-none'
                                                                            checked={panelProps.topChecked}
                                                                            onChange={panelProps.onTopCheckChange}
                                                                        />
                                                                        <label className='custom-control-label' htmlFor='topCheckID' />
                                                                    </div>
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem onClick={panelProps.checkAll}><Translate id='misc.all' /></DropdownItem>
                                                                    <DropdownItem onClick={panelProps.uncheckAll}><Translate id='misc.none' /></DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                            <SpordleTableProvider.SearchInput />

                                                            <div className='d-flex ml-auto'>
                                                                <TablePagination className='ml-2' paginationArray={[ 25, 50, 100 ]} />
                                                                <SpordleTableProvider.Refresh className='ml-2' />

                                                                <ExportDropdown getData={this.props.ReportsContext.getClinicAttendeesReport} clinic={this.props.ClinicsContext.currentClinic} />
                                                                <CanDoAction action='ADD' componentCode='clinics' componentPermissionCode='clinics_manage'>
                                                                    {/* Toggles the add modal for members - leave the UncontrolledDropdown, we will eventually integrate the multiple member add */}
                                                                    <Button disabled={!this.props.ClinicsContext.currentClinic.clinic_id} className='ml-2' type='button' color='primary' onClick={this.toggleModalAdd}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                                    {this.state.modalAdd &&
                                                                        <SpordleTableContext.Consumer>
                                                                            {(spordleTable) => (
                                                                                <ClinicProfileRegistrationAdd
                                                                                    isOpen={this.state.modalAdd}
                                                                                    toggle={this.toggleModalAdd}
                                                                                    refreshTable={spordleTable.refreshTable}
                                                                                    setForceRefresh={this.props.setForceRefresh}
                                                                                    waitlistTable={this.props.waitlistTable}
                                                                                />
                                                                            )}
                                                                        </SpordleTableContext.Consumer>
                                                                    }
                                                                </CanDoAction>

                                                                {/* <UncontrolledButtonDropdown className='ml-2'>
                                                                    <DropdownToggle color='primary'>
                                                                        <i className="mdi mdi-plus"/> Add
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem onClick={this.toggleModalAdd}>Single Member</DropdownItem>
                                                                        <DropdownItem href='#multipleAdd'>Multiple Members</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown> */}
                                                                {/* <Button className='ml-2' color="primary" onClick={this.toggleModalAdd}><i className="mdi mdi-plus"/> Add</Button> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <SpordleTableView />
                                                </>
                                            )}
                                        </SpordleTableProvider>
                                    )
                                }}
                            />
                            :
                            <div className='text-center'>
                                <Spinner color='primary' type='grow' />
                                <h4><Translate id='misc.loading' /></h4>
                            </div>
                        }
                    </div>
                </Card>
            </>
        )
    }
}

const ExportDropdown = ({ getData: getReportData, clinic }) => {
    const [ isLoading, setLoading ] = useState(false);
    const reportsContext = useContext(ReportsContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const organizationContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext);

    const { getData } = useSpordleTable();

    const intl = useIntl();
    const reportType = 'clinic_attendee';

    const handleExport = () => {
        setLoading(true);
        getReportData({
            clinic_id: clinic.clinic_id,
            organisation_id: clinic.organisation.organisation_id,
        })
            .then((reports) => {
                const keys = reports[0]
                delete keys.member_id;
                delete keys.clinic_id;
                delete keys.clinic_organisation_id;
                delete keys.qualification_id;
                exportReportsToCSV(reports, Object.keys(keys).map((column) => ({ key: column })), intl);
                setLoading(false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message);
                    setLoading(false);
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });
    }

    return (
        <UncontrolledButtonDropdown className='ml-2'>
            <Tooltip withArrow label={<Translate id={'misc.export'} />}>
                <DropdownToggle color='primary' outline caret id='clinicProfileAttendeesExport'>
                    <i className='mdi mdi-download mr-1' />
                </DropdownToggle>
            </Tooltip>

            <DropdownMenu right>
                <DropdownItem
                    disabled={isLoading || clinic.clinic_attendee_count < 1}
                    onClick={handleExport}
                    id='clinicProfileAttendeesExportCSVExport'
                >
                    <i className='fas fa-file-excel mr-1' />CSV{isLoading && <Spinner size='sm' color='primary' type='grow' className='float-right' />}
                </DropdownItem>
                <DropdownItem
                    disabled={isLoading || clinic.clinic_attendee_count < 1}
                    onClick={() => handleExcelExport(
                        setLoading,
                        reportType,
                        {
                            clinic_id: clinic.clinic_id,
                            organisation_id: clinic.organisation.organisation_id,
                            period_id: clinic.period.period_id,
                        },
                        getData().length,
                        reportsContext,
                        identityRolesContext.identity_role_id,
                        identityRolesContext.identity.identity_id,
                        i18nContext.getGenericLocale(),
                        organizationContext.organisation_id,
                    )}
                    id='clinicProfileAttendeesExportExcelExport'
                >
                    <i className='fas fa-file-excel mr-1' />
                    <Translate id='misc.excel' />
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    )
}

const AttendeeHandleSearch = ({ panelProps }) => {
    const spordleTable = useSpordleTable();
    const location = useLocation();

    const getInitialSearch = () => {
        const searchAttendee = `${location.search}`;

        if(searchAttendee){
            return searchAttendee.split('?')[1];
        }
        return "";
    }

    const selectTableAttendee = (search) => {
        const attendee = spordleTable.getData().find((attendee) => attendee.member.unique_identifier === search);

        if(attendee){
            // This verification is necessary because of mutated data in cache
            // Specific case :
            // 1. Getting in the clinic attendees with search query,
            // 2. then leaving
            // 3. then coming back on the same clinic with the same search
            if(attendee.checked){
                panelProps.uncheckAll();
            }

            spordleTable.setInputValue(search);
            panelProps.onSingleSelectChange(attendee);
        }
    }

    useEffect(() => {
        if(spordleTable.state.loadingState === 'success'){
            const search = getInitialSearch();

            if(search){
                // clearURLSearch(); to be able to auto open a waitlisted members' sidepanel this needed to be commented as it was removing the member number from the URL before being consumed by the other tab
                selectTableAttendee(search);
            }

        }

    }, [ spordleTable.state.loadingState ]);

    return null;

}

const NameRender = ({ attendee }) => {
    const { member } = attendee;

    return (
        <>
            <div className='d-flex align-items-start justify-content-between'>
                <div className='flex-grow-1'>
                    <div className="font-medium">
                        {
                            attendee.cancelled_at ?
                                <s>{member.first_name + ' ' + member.last_name}</s>
                                :
                                member.first_name + ' ' + member.last_name
                        }
                    </div>
                    <Link className="text-nowrap d-inline-block" to={`/members/profile/${member.member_id}`}><div className="small">#{member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></div></Link>
                </div>
                {(member.confirmed == '0' || attendee.cancelled_by) &&
                    <div className='flex-grow-0'>
                        <Tooltip withArrow label={<Translate id={attendee.cancelled_by ? 'clinics.profile.attendees.sidepanel.header.cancelled' : 'clinics.profile.attendees.sidepanel.header.notConfirmed'} />}>
                            <i className={(attendee.cancelled_by ? 'text-dark' : 'text-danger') + ' mdi mdi-information-outline'} style={{ fontSize: '18px' }} />
                        </Tooltip>

                    </div>
                }
            </div>
            {attendee.cancelled_at &&
                <div className="mt-1 text-muted small">
                    <Translate id='clinics.profile.attendees.sidepanel.header.cancelledAt' values={{ date: attendee.cancelled_at }} />
                </div>
            }
        </>
    )
}


export default withRouter(withContexts(ClinicsContext, I18nContext, ReportsContext)(ClinicProfileAttendees));
