export default{
    "members.profile.waiver.tab" : "Waivers",

    "members.profile.waiver.table.empty"            : "No waiver for this member for the current period",
    "members.profile.waiver.table.column.invoice"   : "Invoice",
    "members.profile.waiver.table.column.reference" : "Reference",
    "members.profile.waiver.table.column.waiver"    : "Waiver",
    "members.profile.waiver.table.column.season"    : "Season",
    "members.profile.waiver.table.column.signed"    : "Signed",

    "members.profile.waiver.table.filter.onlyCritical" : "Show critical waivers only",

    "members.profile.waiver.signed.by"                : "Signed by",
    "members.profile.waiver.signed.by.YES"            : "Accepted by",
    "members.profile.waiver.signed.by.NO"             : "Declined by",
    "members.profile.waiver.signed.not"               : "Not signed",
    "members.profile.waiver.sidePanel.sign.label"     : "I agree with this waiver",
    "members.profile.waiver.signed.apiPartner"        : "API Partner",
    "members.profile.waiver.signed.confirmationLabel" : "Confirmation label: ",
    "members.profile.waiver.signed.declineLabel"      : "Declination label: ",


    "members.profile.waiver.csv.header.organisation"          : "Organisation",
    "members.profile.waiver.csv.header.signedOn"              : "Signed on",
    "members.profile.waiver.csv.header.answer.YES"            : "Yes",
    "members.profile.waiver.csv.header.answer.NO"             : "No",
    "members.profile.waiver.csv.header.answer.NOT_SIGNED_YET" : "Not signed",
}