import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';

import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';

/** @type {React.Context<Omit<WaiversContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const WaiversContext = createContext();
WaiversContext.displayName = 'WaiversContext';

class WaiversContextProvider extends React.Component{

    /**
     * Gets all waivers
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Waivers/Apicontroller%5CWaivers%5CWaivers%3A%3AgetAllWaivers|documentation}
     * @returns {Promise.<Array>}
     */
    getWaivers = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/waivers',
            query: Object.assign({
                organisation_id: queryParams.organisation_id || this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.waivers;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Get the waivers to sign for a division
     * @param {string} divisionId ID of the division to get the waivers for
     * @param {Object} queryParams
     * @returns {Promise}
     */
    getWaiversByDivisions = (divisionId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/divisions/${divisionId}/waivers`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.waivers;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get a specific waiver
     * @param {string} waiver_id
     * @see - Refer to the {@link https://api.id.spordle.dev/documentations/#/Waivers/Apicontroller%5CWaivers%5CWaivers%3A%3AgetWaiver|documentation}
     * @returns {Promise}
     */
    getWaiver = (waiver_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/waivers/${waiver_id}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.waivers;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create a waiver
     * @param {object} [values] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Waivers/Apicontroller%5CWaivers%5CWaivers%3A%3AcreateWaiver|documentation}
     * @returns {Promise.<string>}
     */
    createWaiver = (values) => {
        const params = new URLSearchParams({
            organisation_id: this.props.OrganizationContext.organisation_id,
        })

        for(const key in values){
            switch (key){
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'description':
                    params.append('description', values.description);
                    break;
                case 'confirm_label':
                    params.append('confirm_label', values.confirm_label);
                    break;
                case 'decline_label':
                    params.append('decline_label', values.decline_label);
                    break;
                case 'waiver_type_id':
                    params.append('waiver_type_id', values.waiver_type_id);
                    break;
                case 'active':
                    params.append('active', values.active ? 1 : 0);
                    break;
                case 'mandatory':
                    params.append('mandatory', values.mandatory ? 1 : 0);
                    break;
                case 'critical':
                    params.append('critical', values.critical ? 1 : 0);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/waivers`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.waiver_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update a waiver
     * @param {string} waiver_id
     * @param {object} [values] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Waivers/Apicontroller%5CWaivers%5CWaivers%3A%3AupdatePutWaiver|documentation}
     * @returns {Promise.<boolean>}
     */
    updateWaiver = (waiver_id, values) => {
        const data = new URLSearchParams({
            organisation_id: this.props.OrganizationContext.organisation_id,
        })

        for(const key in values){
            switch (key){

                case 'name':
                    data.append('name', values.name);
                    break;
                case 'description':
                    data.append('description', values.description);
                    break;
                case 'confirm_label':
                    data.append('confirm_label', values.confirm_label);
                    break;
                case 'decline_label':
                    data.append('decline_label', values.decline_label);
                    break;
                case 'waiver_type_id':
                    data.append('waiver_type_id', values.waiver_type_id);
                    break;
                case 'active':
                    data.append('active', !!values.active >>> 0);
                    break;
                case 'mandatory':
                    data.append('mandatory', !!values.mandatory >>> 0);
                    break;
                case 'critical':
                    data.append('critical', !!values.critical >>> 0);
                    break;

                default:
                    if(key.includes('i18n'))
                        data.append(key, values[key])
                    break;
            }

        }

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/waivers/${waiver_id}`,
        }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Update a waiver partially
     * @param {string} waiver_id
     * @param {object} values Object containing the values to update - Refer to the {@link https://api.id.spordle.dev/documentations/#/Waivers/Apicontroller%5CWaivers%5CWaivers%3A%3AupdatePatchWaiver|documentation}
     * @returns {Promise.<boolean>}
    */
    updateWaiverPartial = (waiver_id, values) => {
        const data = new URLSearchParams()
        for(const key in values){
            switch (key){
                case 'name':
                    data.append('name', values.name);
                    break;
                case 'description':
                    data.append('description', values.description);
                    break;
                case 'waiver_type_id':
                    data.append('waiver_type_id', values.waiver_type_id);
                    break;
                case 'active':
                    data.append('active', values.active ? 1 : 0);
                    break;
                case 'mandatory':
                    data.append('mandatory', values.mandatory ? 1 : 0);
                    break;

                default:
                    if(key.includes('i18n'))
                        data.append(key, values[key])
                    break;
            }

        }

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/waivers/${waiver_id}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Deletes a specific waiver
     * @param {string} waiver_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Waivers/Apicontroller%5CWaivers%5CWaivers%3A%3AdeleteWaiver|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteWaiver = (waiver_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `/waivers/${waiver_id}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Signs a waiver
     * @param {string} invoiceNumber
     * @param {string} invoiceItemId
     * @param {string} waiverId
     * @param {object} answer
     * @param {'YES'|'NO'} answer.answer
     * @param {string} answer.language_code
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Waiver%20Response/Apicontroller%5CInvoices%5CInvoiceitemwaivers%3A%3ApatchWaiverResponse|documentation}
     * @returns {Promise.<boolean>}
     */
    signWaiver = (invoiceNumber, invoiceItemId, waiverId, answer = {}) => {
        const data = new URLSearchParams(answer);
        return API_SPORDLE.patch(`invoices/${invoiceNumber}/items/${invoiceItemId}/waivers/${waiverId}`, data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <WaiversContext.Provider value={{ ...this }}>
                {this.props.children}
            </WaiversContext.Provider>
        )
    }
}

export default withContexts(OrganizationContext)(WaiversContextProvider);