export default{
    "components.createOrganizationModal.title"                      : "Créer une organisation",
    "components.createOrganizationModal.success.title"              : "Organisation créée!",
    "components.createOrganizationModal.success.msg"                : "Votre nouvelle organisation a bien été créée.",
    "components.createOrganizationModal.success.btn"                : "Gérer l'organisation",
    "components.createOrganizationModal.form.parent.label"          : "Organisation parente",
    "components.createOrganizationModal.form.parent.required"       : "Veuillez spécifier une organisation parente.",
    "components.createOrganizationModal.form.orgCategory.label"     : "Catégorie d'organisation",
    "components.createOrganizationModal.form.orgCategory.required"  : "Veuillez spécifier une catégorie d'organisation",
    "components.createOrganizationModal.form.abbreviation.label"    : "Abbréviation",
    "components.createOrganizationModal.form.abbreviation.required" : "Veuillez spécifier une abbréviation.",
    "components.createOrganizationModal.form.error.3045"            : "Vous ne pouvez pas créer une organisation de cette catégorie sous cette organisation parente.",
    "components.createOrganizationModal.form.error.3191"            : "Vous n'avez pas les permissions nécessaires pour créer une organisation.",
    "components.createOrganizationModal.form.error.3252"            : "L'identifiant externe de l'organisation est déjà utilisé. ",

}