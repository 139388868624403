import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UserDisplayContainer from './UserDisplayContainer';
import UserDisplayTitle from './UserDisplayTitle';
import UserDisplaySubtitle from './UserDisplaySubtitle';
import { stringBuilder } from "@spordle/helpers";
import { forwardRef } from 'react';

/**
 * @param {bool} [card] Displays in card style.
 * @param {bool} [hover] add hover effect for cards.
 * @param {bool} [block] Makes a block out of the card, which means it takes up to 100% of its container
 * @param {string} [to] value param in <Link to={}>.
 * @param {React.ComponentType} [tag='div'] The desired htmlElement of the container. This param is ignored if the param "to" has a value
 * @param {string} [className]
 * @example
    <UserDisplay card hover>
        <UserDisplay.Container>
            <UserImg
                abbr={identityRole.organisation.abbreviation}
                src={identityRole.organisation.logo?.full_path}
                width="40"
                height="40"
                alt={identityRole.organisation.organisation_name + ' logo'}
            />
        </UserDisplay.Container>
        <UserDisplay.Container>
            <UserDisplay.Title>{identityRole.role.title}</UserDisplay.Title>
            <UserDisplay.Subtitle>{identityRole.organisation.organisation_name}</UserDisplay.Subtitle>
        </UserDisplay.Container>
    </UserDisplay>
 */
const UserDisplay = forwardRef(({
    tag: Tag,
    className,
    hover,
    block,
    card,
    to,
    ...attributes
}, ref) => {

    const classNames = stringBuilder('userDisplay', { 'userDisplay-hover': hover, 'userDisplay-card': card, 'userDisplay-block': block }, className);

    return (
        (to ?
            <Link to={to} {...attributes} ref={ref} className={classNames} />
            :
            <Tag {...attributes} ref={ref} className={classNames} />
        )
    );
});

UserDisplay.Container = UserDisplayContainer;
UserDisplay.Title = UserDisplayTitle;
UserDisplay.Subtitle = UserDisplaySubtitle;
export default UserDisplay;

UserDisplay.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
    card: PropTypes.bool,
    hover: PropTypes.bool, // currently only works with card prop
    to: PropTypes.string,
};
UserDisplay.defaultProps = {
    tag: 'div',
};