import { Collapse, Fade, ModalHeader } from "reactstrap"
import AnalyticsModal from "../../../../../analytics/AnalyticsModal"
import OverlayLoader from "../../../../../components/loading/OverlayLoader"
import DiscriminationAddContextProvider, { DiscriminationAddContext } from "./components/DiscriminationAddContext"
import Translate from "@spordle/intl-elements"
import DiscriminationAddModalStepComplainer from "./stepComplainer/DiscriminationAddModalStepComplainer"
import DiscriminationAddModalStepGuilty from "./stepGuilty/DiscriminationAddModalStepGuilty"
import DiscriminationAddModalStepIncident from "./stepIncident/DiscriminationAddModalStepIncident"
import DiscriminationAddModalStepConfirm from "./stepConfirm/DiscriminationAddModalStepConfirm"
import DiscriminationAddModalStepComplaint from "./stepComplaint/DiscriminationAddModalStepComplaint"

/**
 * @description Add Modal for Discriminations
 * @param {boolean} props.fromMember Boolean that specifies if this modal is in a member profile
 * @param {function} props.toggle
 * @param {boolean} props.isOpen
 * @returns {JSX.Element}
 */
const DiscriminationAddModal = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName="DiscriminationAddModal">
            <DiscriminationAddModalInner {...props} />
        </AnalyticsModal>
    )
}

export const CrossFade = ({ isVisible, ...props }) => (
    <Collapse appear isOpen={isVisible}>
        <Fade in={isVisible} {...props} />
    </Collapse>
)

const DiscriminationAddModalInner = ({ fromMemberView = false, ...props }) => {
    const Steps = [
        // DiscriminationAddModalStepVictim,
        DiscriminationAddModalStepIncident,
        DiscriminationAddModalStepComplaint,
        DiscriminationAddModalStepComplainer,
        DiscriminationAddModalStepGuilty,
        DiscriminationAddModalStepConfirm,
    ];

    return (
        <DiscriminationAddContextProvider fromMemberView={fromMemberView} {...props}>
            <DiscriminationAddContext.Consumer>
                {({ isLoading, step, complaint }) => (
                    <OverlayLoader isLoading={isLoading}>
                        <ModalHeader toggle={props.toggle}>
                            <Translate id='discrimination.addModal.title' /> ({step}/{Steps.length})
                        </ModalHeader>
                        {Steps.map((Step, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <CrossFade key={'step' + index} isVisible={step === index + 1}>
                                <Step stepNb={index + 1} />
                            </CrossFade>
                        ))}
                    </OverlayLoader>
                )}
            </DiscriminationAddContext.Consumer>
        </DiscriminationAddContextProvider>
    )
}

export default DiscriminationAddModal;