import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { Button, Col, Collapse, FormGroup, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import AnalyticsModal from "../../../../../../../analytics/AnalyticsModal";
import { useContext, useState } from "react";
import OverlayLoader from "../../../../../../../components/loading/OverlayLoader";
import { object, string } from 'yup';
import { FormikSelect } from "@spordle/formik-elements";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import { OrganizationContext } from "../../../../../../../contexts/OrganizationContext";
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import CrossFade from "../../../../../../../components/crossFade/CrossFade";
import UserDisplay from "../../../../../../../components/userDisplay/UserDisplay";
import { MembersContext } from "../../../../../../../contexts/MembersContext";
import MemberSuspensionAddContext from "../../../../../../members/profile/history/component/suspensions/components/add/MemberSuspensionAddContext";
import CustomAlert from "../../../../../../../components/CustomAlert";

/**
 * @description Modal that contains a form to add a member to a descrimination claim
 * @param {Object} props
 * @param {object} props.maltreatment The discrimination complaint
 * @param {function} props.toggle Function to close the modal
 * @param {boolean} props.isOpen Modal is open or not
 * @returns {React.ReactNode}
 */
const AddSuspensionModal = (props) => {
    return (
        <AnalyticsModal analyticsName="addMaltreatmentSuspensionModal" isOpen={props.isOpen}>
            <AddSuspensionModalInner {...props} />
        </AnalyticsModal>
    )
}

const AddSuspensionModalInner = ({ maltreatment, complaint, forceRefresh, toggle, ...props }) => {
    const { createDiscriminationSuspension } = useContext(OrganizationContext);

    // Hardcoded to CREATION because link is not used anymore.
    // Creation form receives props linkSuspension & linkToMaltreatment that filters suspenions only for maltreatment complaints
    const [ step, setStep ] = useState(2);
    const [ suspensionFunction, setSuspensionFunction ] = useState("CREATE");

    const setSuspensionAddFunction = (type) => {
        setSuspensionFunction(type)
        setStep(2)
    }

    const linkSuspension = (newValues) => {
        return createDiscriminationSuspension(maltreatment.maltreatment_id || maltreatment.discrimination_id, newValues)
    }

    return (
        <>
            { step == 1 &&
                <>
                    <ModalHeader toggle={toggle}>
                        <Translate id='discrimination.suspensions.addSuspension' />
                    </ModalHeader>

                    <ModalBody>
                        <CrossFade isVisible={step == 1}>
                            <div className="d-flex flex-column">
                                <div
                                    onClick={() => setSuspensionAddFunction("LINK")}
                                    className="mb-3 flex-grow-1 btn btn-lg select-request border text-left"
                                    color="light"
                                >
                                    <UserDisplay>
                                        <UserDisplay.Container>
                                            <div style={{ height: 50, width: 50 }} className="border bg-light rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="mdi mdi-link-variant font-24" />
                                            </div>
                                        </UserDisplay.Container>
                                        <UserDisplay.Container>
                                            <UserDisplay.Title><Translate id="discrimination.suspensions.linkSuspension" /></UserDisplay.Title>
                                            <UserDisplay.Subtitle><Translate id="discrimination.suspensions.linkSuspension.desc" /></UserDisplay.Subtitle>
                                        </UserDisplay.Container>
                                    </UserDisplay>
                                </div>
                                <div
                                    onClick={() => setSuspensionAddFunction("CREATE")}
                                    className="mb-2 flex-grow-1 btn btn-lg select-request border text-left"
                                    color="light"
                                >
                                    <UserDisplay>
                                        <UserDisplay.Container>
                                            <div style={{ height: 50, width: 50 }} className="border bg-light rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="mdi mdi-plus font-24" />
                                            </div>
                                        </UserDisplay.Container>
                                        <UserDisplay.Container>
                                            <UserDisplay.Title><Translate id="discrimination.suspensions.createSuspension" /></UserDisplay.Title>
                                            <UserDisplay.Subtitle><Translate id="discrimination.suspensions.createSuspension.desc" /></UserDisplay.Subtitle>
                                        </UserDisplay.Container>
                                    </UserDisplay>
                                </div>
                            </div>
                        </CrossFade>
                    </ModalBody>
                </>
            }
            { step == 2 &&
                <CrossFade isVisible={step == 2}>
                    {suspensionFunction === 'CREATE' ?
                        <SuspensionCreationForm
                            maltreatment={maltreatment}
                            complaint={complaint}
                            forceRefresh={forceRefresh}
                            toggle={toggle}
                            previous={() => setStep(1)}
                            linkSuspension={linkSuspension}
                            {...props}
                        />
                        :
                        <SuspensionLinkForm
                            maltreatment={maltreatment}
                            complaint={complaint}
                            forceRefresh={forceRefresh}
                            toggle={toggle}
                            previous={() => setStep(1)}
                            linkSuspension={linkSuspension}
                            {...props}
                        />
                    }
                </CrossFade>
            }
        </>
    )

}

const SuspensionCreationForm = ({ maltreatment, ...props }) => {
    return (
        <MemberSuspensionAddContext
            isOpen
            memberId={maltreatment.discrimination_member?.[0]?.member?.member_id}
            team={maltreatment.discrimination_member?.[0]?.team}
            linkToMaltreatment // Need to chain link
            skipModal // to prevent 2 modals on top of each other
            {...props}
        />
    )
}

const SuspensionLinkForm = ({ maltreatment, complaint, forceRefresh, toggle, previous, linkSuspension, onSuccess }) => {
    const { getMemberSuspensions } = useContext(MembersContext);
    const [ isLoading, setIsLoading ] = useState(false);
    return (
        <Formik
            initialValues={{
                member_suspension_id: '',
            }}
            validationSchema={object().shape({
                member_suspension_id: string().required(),
            })}
            onSubmit={(values, { setStatus }) => {
                setIsLoading(true);

                const newValues = { member_suspensions: [ values ] };

                return linkSuspension(newValues)
                    .then(() => {
                        onSuccess?.();
                        toggle();
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            setIsLoading(false);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={isLoading}>
                        <ModalHeader toggle={toggle}>
                            <Translate id='discrimination.suspensions.linkSuspension' />
                        </ModalHeader>

                        <ModalBody>
                            <Row form>
                                <Col md="12">
                                    <FormGroup>
                                        <UserDisplay.Container className="mb-2">
                                            <UserDisplay.Title><Translate id="discrimination.suspensions.linkSuspension" /></UserDisplay.Title>
                                            <UserDisplay.Subtitle><Translate id="discrimination.suspensions.linkSuspension.desc" /></UserDisplay.Subtitle>
                                        </UserDisplay.Container>
                                        <FormikSelect
                                            name='member_suspension_id'
                                            id='member_suspension_id'
                                            renderOption={({ option }) => {
                                                const suspension = option.suspension
                                                return (
                                                    <>
                                                        {suspension.other_infraction ?
                                                            <div>
                                                                <div className="d-flex align-items-center">
                                                                    <div>{suspension.other_infraction}</div>
                                                                    <div className="small ml-1">
                                                                        (<DisplayI18n field='name' defaultValue={suspension.organisation?.name} i18n={suspension.organisation?.i18n} />)
                                                                    </div>
                                                                </div>
                                                                <div className='text-muted small'>#{suspension.reference_number}</div>
                                                                {suspension.itp_number && <div className='text-muted small'><Translate id="discrimination.global.itpReferenceNumber" />{suspension.itp_number}</div>}
                                                            </div>
                                                            :
                                                            <div>
                                                                <div className="d-flex align-items-center">
                                                                    <DisplayI18n field='name' defaultValue={suspension.suspension?.name} i18n={suspension.suspension?.i18n} />
                                                                    <div className="small ml-1">
                                                                        (<DisplayI18n field='name' defaultValue={suspension.organisation?.name} i18n={suspension.organisation?.i18n} />)
                                                                    </div>
                                                                </div>
                                                                <div className='text-muted small'>#{suspension.reference_number}</div>
                                                                {suspension.itp_number && <div className='text-muted small'><Translate id="discrimination.global.itpReferenceNumber" />{suspension.itp_number}</div>}
                                                            </div>
                                                        }

                                                    </>
                                                )
                                            }}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return getMemberSuspensions(maltreatment.discrimination_member?.[0]?.member?.member_id, { active: 1, visible_on_maltreatment: 1 })
                                                            .then((suspensions) => suspensions.map((suspension) => ({
                                                                id: suspension.member_suspension_id,
                                                                value: suspension.member_suspension_id,
                                                                suspension: suspension,
                                                            })))
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                {formik.status &&
                                    <CustomAlert className='mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                }
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='primary' type='button' className='mr-auto' outline onClick={previous}><Translate id='misc.previous' /></Button>
                            <Button color='primary' type='submit' className='mr-2'><Translate id='misc.create' /></Button>
                            <Button color='primary' type='button' onClick={toggle} outline><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default AddSuspensionModal;