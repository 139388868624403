import OrganizationRegistrationSettings from './OrganizationRegistrationSettings';
import ReginalSettings from './regionalSettings/ReginalSettings';

const OrganizationRegionalSettings = ({ settings, isLoading }) => {
    return (
        <>
            <ReginalSettings settings={settings} isLoading={isLoading} />
            <OrganizationRegistrationSettings />
        </>
    );
}

export default OrganizationRegionalSettings;