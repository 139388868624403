import settingsCategoryTabsRoutes from '../tabRoutes/settingsCategoryTabsRoutes';
import settingsClinicTabsRoutes from '../tabRoutes/settingsClinicTabsRoutes';
import settingsOrganizationTabsRoutes from '../tabRoutes/settingsOrganizationTabsRoutes';
import settingsPlatfomTabsRoutes from '../tabRoutes/settingsPlatfomTabsRoutes';
import settingsTeamsTabsRoutes from '../tabRoutes/settingsTeamsTabsRoutes';

const settingsSidebar = [
    {
        path: '/settings/platform',
        name: 'sidebar.settings.platformSettings',
        permissions: { code: 'platform' },
        subPermissionsRoutes: settingsPlatfomTabsRoutes,
    },
    {
        path: '/settings/organization',
        name: 'sidebar.settings.organizationSettings',
        permissions: { code: 'settings' },
        subPermissionsRoutes: settingsOrganizationTabsRoutes,
    },
    {
        path: '/settings/registration',
        name: 'sidebar.settings.registrationSettings', //Category, Division, Class',
        permissions: { code: 'teams' },
        subPermissionsRoutes: settingsCategoryTabsRoutes,
    },
    {
        path: '/settings/clinics',
        name: 'sidebar.settings.clinicSettings',
        permissions: { code: 'clinics', permissionCode: 'clinics_settings' },
        subPermissionsRoutes: settingsClinicTabsRoutes,
    },
    {
        path: '/settings/teams',
        name: 'sidebar.settings.teams',
        subPermissionsRoutes: settingsTeamsTabsRoutes,
    },
    {
        path: '/settings/payees',
        name: 'sidebar.settings.payees',
        permissions: { code: 'organization', permissionCode: 'payee_management' },
    },
    {
        path: '/settings/users',
        name: 'sidebar.settings.users',
        permissions: { code: 'settings', permissionCode: 'settings_view_accounts' },
    },
];
export default settingsSidebar;
