import { useContext, useState } from "react";
import { IntlProvider, useIntl } from "react-intl";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { exportToCsv } from "../../../../../helpers/export";
import { renderInWindow } from "../../../../reports/registrationForm/NewWindowOpener";
import { InboxContext } from "../../inboxContext/InboxContext";
import PrintableMsg from "../PrintableMsg";
import moment from "moment";
import { MessagesContext } from "../../../../../contexts/MessagesContext";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { success } from "@spordle/toasts";
import MessagesActionsDropdown from "./MessagesActionsDropdown";
import MessagesActionsBtns from "./MessagesActionsBtns";

const MessagesActions = ({ isDropdown = false }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const { selectedMsg: msg, updateSelectedMsg } = useContext(InboxContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { partiallyUpdateMessage } = useContext(MessagesContext);
    const { formatMessage } = useIntl();
    const ActionTag = isDropdown ? MessagesActionsDropdown : MessagesActionsBtns;

    const handleOnDownloadMsg = () => {
        /**
         * @see {https://stackoverflow.com/questions/5002111/how-to-strip-html-tags-from-string-in-javascript}
         * @param {string} str
         * @returns {string}
         */
        const stripHtml = (str) => {
            return str.replace(/<\/?[^>]+(>|$)/g, "");
        }

        exportToCsv(
            `SpordleID-Communication_${msg.title}__${moment().format('YYYY-MM-DD_H:mm')}.csv`,
            [
                [ `${formatMessage({ id: "communications.inbox.from" })}: ${msg.sent_by.name} ${msg.sent_by.family_name} (${msg.sent_by.email})` ],
                [ `Date: ${msg.created_at}` ],
                [ `${formatMessage({ id: "components.communications.modal.label.subject" })}: ${msg.title}` ],
                [ `${formatMessage({ id: "components.communications.modal.label.message" })}: ${stripHtml(msg.body)}` ],
            ],
        );
    }

    const handleOnPrintMsg = () => {
        renderInWindow(
            <IntlProvider locale={getGenericLocale()}>
                <PrintableMsg msg={msg} />
            </IntlProvider>,
            "SID Communication",
        )
    }

    const updateMsg = (newVal, successToast = {}) => {
        if(!isLoading){
            setIsLoading(true);

            partiallyUpdateMessage(msg.communication_message_id, newVal)
                .then(() => {
                    updateSelectedMsg(newVal);
                    success(successToast);
                })
                .catch((e) => {
                    if(!AxiosIsCancelled(e.message)){
                        console.error(e);
                    }
                })
                .finally(() => setIsLoading(false))
        }
    }

    const handleOnMarkAsRead = (isRead) => {
        updateMsg(
            { is_read: isRead },
            { msg: `communications.inbox.action.toast.${isRead == 1 ? "markRead" : "markUnread" }` },
        );
    }

    const handleOnArchive = (isArchived) => {
        updateMsg(
            { archive: isArchived },
            { msg: `communications.inbox.action.toast.${isArchived == 1 ? "archive" : "unarchive"}` },
        );
    }

    const handleOnFavorite = (isFavorite) => {
        updateMsg(
            { favorite: isFavorite },
            { msg: `communications.inbox.action.toast.${isFavorite == 1 ? "favorite" : "unfavorite"}` },
        );
    }

    return (
        <ActionTag
            handleOnArchive={() => handleOnArchive(msg.archive == 1 ? 0 : 1)}
            handleOnFavorite={() => handleOnFavorite(msg.favorite == 1 ? 0 : 1)}
            handleOnMarkAsRead={() => handleOnMarkAsRead(msg.is_read == 1 ? 0 : 1)}
            handleOnPrintMsg={handleOnPrintMsg}
            handleOnDownloadMsg={handleOnDownloadMsg}
            isLoading={isLoading}
        />
    );
}

export default MessagesActions;