export default{
    "members.profile.crc.tab"                            : "Background Checks",
    "members.profile.dreamComeTrue.title"                : "Dreams Come True",
    "members.profile.crc.title"                          : "Background Checks",
    "members.profile.incomplete.title"                   : "Incomplete Profile",
    "members.profile.potential.ineligible.title"         : "Potentially Ineligible",
    "members.profile.unconfirmed_address.title"          : "Unconfirmed Address",
    "members.profile.crc.createdBy"                      : "Created by",
    "members.profile.crc.memberType"                     : "Member Type",
    "members.profile.crc.type"                           : "Type",
    "members.profile.crc.type.CRC"                       : "Background Check",
    "members.profile.crc.type.VSC"                       : "Vulnerability Check",
    "members.profile.crc.type.DECLARATION"               : "Declaration",
    "members.profile.crc.type.crc"                       : "Background Check",
    "members.profile.crc.type.vsc"                       : "Vulnerability Check",
    "members.profile.crc.type.declaration"               : "Declaration",
    "members.profile.crc.validOn"                        : "Valid on",
    "members.profile.crc.expirationDate"                 : "Expiration date",
    "members.profile.crc.cannotAdd"                      : "Settings rules do not permit to add a background check for this player.",
    "members.profile.crc.expiresAt"                      : "Expires at",
    "members.profile.crc.status.expired"                 : "Expired",
    "members.profile.crc.on"                             : "On",
    "members.profile.crc.lastUpdated"                    : "Last updated",
    "members.profile.crc.supportiveDocument"             : "Supportive document",
    "members.profile.crc.supportiveDocument.helper.bold" : "Please do not upload the original background check.",
    "members.profile.crc.supportiveDocument.helper"      : "The supportive document is a document that confirms that the background check has been done.",

    "members.profile.crc.add.title"                           : "Add Background Check",
    "members.profile.crc.form.validation.type.required"       : "Type is required",
    "members.profile.crc.form.validation.memberType.required" : "Member Type is required",
    "members.profile.crc.form.validation.file.required"       : "Document is required",
    "members.profile.crc.form.validation.status.required"     : "Status is required",
    "members.profile.crc.form.validation.date.required"       : "Date is required",
    "members.profile.crc.form.expirationDate.alert"           : "The {expirationDate} will be calculated automatically by the system based on your organization's settings.",

    "members.profile.crc.sidepanel.delete" : "You are about to delete this background check",

    "members.profile.crc.editModal.title"   : "Edit Background Check",
    "members.profile.crc.editModal.success" : "Bcakground check was updated!",
}