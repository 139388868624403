import Translate from "@spordle/intl-elements";

/** Standardisation of card titles
 * @param {Object} [props]
 * @param {String|React.ReactNode} [props.title] Title
 * @param {String|React.ReactElement} [props.tag=div] Tag of the container
 * @param {String} [props.className]
 * @param {React.ReactNode} [props.children]
 * @param {Boolean} [props.translateTitle=true] Translate the title
 * @returns {React.ReactNode}
 */
const CardSectionTitle = ({ tag: Tag = 'div', title, translateTitle = true, className, children, titleClassname, ...props }) => (
    <Tag className={`card-section-title-container card-title${className ? ' ' + className : ''}`} {...props}>
        {title && <Title titleClassname={titleClassname}>{translateTitle ? <Translate id={title} /> : title}</Title>} {children}
    </Tag>
);

/** Only adds card-section-title class
 * @param {Object} [props]
 * @param {String|React.ReactElement} [props.tag=span] Tag of the title
 * @param {String} [props.className]
 * @param {React.ReactNode} [props.children]
 * @returns {React.ReactNode}
 */
const Title = ({ tag: Tag = 'span', titleClassname, ...props }) => <Tag className={`card-section-title${titleClassname ? ' ' + titleClassname : ''}`} {...props} />

CardSectionTitle.Title = Title;
export default CardSectionTitle;