import { useDisclosure } from "@mantine/hooks";
import Translate from "@spordle/intl-elements";
import { useField } from "formik";
import { useState, useContext } from "react";
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import Required from "../../../../components/formik/Required";
import BannerPreview from "./bannerPreview/BannerPreview";
import PerfectScrollbar from "react-perfect-scrollbar";
import { stringBuilder } from "@spordle/helpers";
import { FormikError } from "@spordle/formik-elements";
import styles from "./BannerSelector.module.scss";
import { NEWS_TYPE_DATA } from "../../../../components/noticesList/newsHelpers";
import { I18nContext } from "../../../../contexts/I18nContext";
import { displayI18n } from "../../../../helpers/i18nHelper";

const BannerSelector = () => {
    const [ isOpen, isOpenHandlers ] = useDisclosure(false);
    const [ { value }, { error, touched }, { setValue } ] = useField("type");
    const [ preview, setPreview ] = useState(value);
    const { getGenericLocale } = useContext(I18nContext);
    const banners = Object.entries(NEWS_TYPE_DATA).map(([ key, val ]) => ({
        path_url: displayI18n('img', val.i18n, val.img, getGenericLocale()),
        name: val.name,
        code: key,
    }));
    const selectedImg = NEWS_TYPE_DATA[value];

    const handleOnSelect = (banner) => {
        return () => {
            setPreview(banner);
        }
    }

    const handleOnConfirm = () => {
        setValue(preview);
        isOpenHandlers.close();
    }

    return (
        <>
            <div className="text-muted mb-2"><Translate id="news.form.field.banner" /> <Required /></div>
            {selectedImg ?
                <div onClick={isOpenHandlers.open} className={stringBuilder("rounded-lg border", styles.Btn)}>
                    <img className="max-w-100" src={selectedImg.img} alt={selectedImg.name} />
                    <button
                        className="reset-btn bg-white rounded text-primary position-absolute top-0 right-0 p-2"
                        type="button"
                    >
                        <Translate id="misc.edit" />
                    </button>
                </div>
                :
                <>
                    <div
                        onClick={isOpenHandlers.open}
                        className={stringBuilder("bg-light-inverse clickable rounded-lg p-3 border text-center border-dashed", { "border-danger": touched && error })}
                    >
                        <div className="h5 font-medium"><Translate id="news.form.field.banner.none" /></div>
                        <p><Translate id="news.form.field.banner.none.msg" /></p>
                        <Button type="button" color="light" className="bg-white border shadow-sm">
                            <Translate id="misc.select" />
                        </Button>
                    </div>
                    <FormikError name="type" />
                </>
            }
            <AnalyticsModal onClosed={() => setPreview(null)} isOpen={isOpen} analyticsName="newsBannerSelector">
                <ModalHeader toggle={isOpenHandlers.close}><Translate id="news.form.field.banner.modal.title" /></ModalHeader>
                <ModalBody>
                    <div className="bg-light-inverse px-2 rounded-lg border mb-3">
                        <PerfectScrollbar style={{ maxHeight: 400 }} options={{ suppressScrollX: true }}>
                            <Row form tag="ul" className="list-unstyled py-2 mb-0 align-items-center">
                                {banners.map((banner) => (
                                    <Col xs="6" md="4" tag="li" key={banner.code}>
                                        <BannerPreview
                                            isSelected={preview ? preview === banner.code : banner.code === value}
                                            onChange={handleOnSelect(banner.code)}
                                            {...banner}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </PerfectScrollbar>
                    </div>
                    <div className="h5 font-medium"><Translate id="news.form.btn.preview" /></div>
                    {NEWS_TYPE_DATA[preview] ?
                        <img className="max-w-100 rounded-lg border" src={NEWS_TYPE_DATA[preview].img} alt={NEWS_TYPE_DATA[preview].name} />
                        :
                        <p className="text-muted"><Translate id="news.form.field.banner.modal.preview.msg" /></p>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleOnConfirm} type="button" color="primary">
                        <Translate id="misc.confirm" />
                    </Button>
                    <Button
                        color="primary"
                        outline
                        type="button"
                        onClick={isOpenHandlers.close}
                    >
                        <Translate id="misc.cancel" />
                    </Button>
                </ModalFooter>
            </AnalyticsModal>
        </>
    );
}

export default BannerSelector;