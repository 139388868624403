export default{
    "clinics.clinicPaymentOptions.clinicPaymentOptions.title" : "Payment Option(s)",

    // fees
    "clinics.clinicPaymentOptions.clinicPaymentOptions.fees.title"           : "Clinic Items",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.fees.none"            : "No clinic items",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.fees.table.amount"    : "Amount",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.fees.table.taxes"     : "Taxes",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.fees.table.total"     : "Total",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.fees.table.mandatory" : "Mandatory",

    // payment methods
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.title"                : "Payment method(s)",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.online.title"         : "Online payment",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.online.title.helper"  : "Allows credit card payments",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.online.none"          : "No online payment method",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.merchant.validation"  : "Please select an online payment option",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.merchant.none"        : "No online payment options available, please contact support.",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.merchant.placeholder" : "Select an online payment option",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.merchant.noOptions"   : "No online payment options available",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.other.title"          : "Prepayment method(s)",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.other.none"           : "No prepayment method",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.CHEQUE"               : "Cheque",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.CASH"                 : "Cash",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.CREDITCARD"           : "Credit card",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.PAYMENTPROXY"         : "Credit card (payment proxy)",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.INTERAC"              : "Interac transfer",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.FUNDING"              : "Funding",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.minimum"              : "Check at least one payment method",
}