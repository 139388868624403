import useSWR from "swr";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { PositionsContext } from "../../../../../contexts/PositionsContext";
import { useContext } from 'react';
import { parseJSON } from "../../../../../helpers/helper";
import { RolesContext } from "../../../../../contexts/RolesContext";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import { QualificationsContext } from "../../../../../contexts/QualificationsContext";
import { PeriodsContext } from "../../../../../contexts/contexts";
import { getTeamCategoryQualificationRules } from "../../../../../api/client/teams";

export function useTeamCategoryQualificationRulesSWR(){
    const { selectedPeriod } = useContext(PeriodsContext);

    return useSWR(
        [ 'getTeamCategoryQualificationRules', selectedPeriod.period_id ],
        () => getTeamCategoryQualificationRules({ period_id: selectedPeriod.period_id }),
    )
}

export function useTeamConfigQualificationsSWR(){
    const { organisation_id } = useContext(OrganizationContext);
    const { getQualifications } = useContext(QualificationsContext);
    const { hasAccessTo } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);

    const canFetch = hasAccessTo("members", "members_qualifications") || isGod();

    return useSWR(
        [ "getQualifications", canFetch ? organisation_id : null ],
        (_, id) => id ? getQualifications({ organisation_id: id }) : Promise.resolve([]),
    );
}

export function useTeamConfigPosGroupsSWR(){
    const { organisation_id } = useContext(OrganizationContext);
    const { getPositionGroups } = useContext(PositionsContext);
    const { hasAccessTo } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);

    const canFetch = hasAccessTo("teams", "team_position_groups") || isGod();

    return useSWR(
        [ "getPositionGroups", canFetch ? organisation_id : null ],
        (_, id) => id ? getPositionGroups(id) : Promise.resolve([]),
    );
}

/**
 * @param {string} [val]
 * @description
* Setting val is a JSON that looks like: '{"uuid":4,"uuid2":1}'
*
* Will convert to array like so: [ [ "uuid", 4 ], [ "uuid2", 4 ] ]
*/
export const getSettingVal = (val) => Object.entries(parseJSON(val, {}) || {}) || [];