import Chart from 'react-apexcharts';

const GraphWidget = ({ graphType, data }) => {
    return (
        <div className='position-relative flex-grow-1 w-100 min-h-0'>
            <div className='position-absolute top-0 left-0 bottom-0 right-0'>
                <div className='h-100'>
                    <Chart
                        options={data.options}
                        series={data.series}
                        type={graphType}
                        width="100%"
                        height='100%'
                    />
                </div>
            </div>
        </div>
    )
}

export default GraphWidget