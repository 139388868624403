import { FormikCheckedButton, FormikSelect } from "@spordle/formik-elements";
import { Collapse, Label } from "reactstrap";
import { I18nContext } from "../../../../contexts/I18nContext";
import { DisplayCategory } from "../../TeamHelpers";
import { useContext, useEffect, useState, useCallback } from 'react';
import { formatSelectData } from "@spordle/spordle-select";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { displayI18n } from "../../../../helpers/i18nHelper";
import { useFormikContext } from "formik";
import Translate from "@spordle/intl-elements";

const AddCompetitionTeamSelect = ({ orgId, existingTeams }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const { getAvailableCompetitionTeams } = useContext(OrganizationContext);
    const { values, setFieldValue } = useFormikContext();
    const [ options, setOptions ] = useState(null);
    const selectedDivisionId = values.division?.division_id;

    const formatOptions = (teams) => {
        return formatSelectData(teams, {
            getGroupId: (data) => data.organisation?.organisation_id,
            getValue: (data) => data.team_id,
            getLabel: (data) => data.name,
            createGroupWithId: (data) => ({
                organisation: data.organisation,
                label: displayI18n("name", data.organisation?.i18n, data.organisation?.organisation_name, getGenericLocale()),
            }),
        })
    }

    const getFilteredTeams = useCallback(() => {
        if(Array.isArray(options) && values.filter && values.division){
            return options.filter((opt) => {
                return opt.team_category?.division_id === selectedDivisionId;
            })
        }

        return Array.isArray(options) ? options : [];
    }, [ selectedDivisionId, values.filter, Array.isArray(options) ]);

    const getFilteredOptions = useCallback(() => formatOptions(getFilteredTeams()), [ selectedDivisionId, values.filter, Array.isArray(options) ]);

    useEffect(() => {
        getAvailableCompetitionTeams(orgId)
            .then((teams) => {
                setOptions(
                    teams.filter((team) => !existingTeams.some(({ team_id }) => team.team_id === team_id)),
                );
            })
            .catch((e) => {
                console.error(e);
                setOptions("error");
            })
    }, []);

    useEffect(() => {
        if(values.filter && values.team_ids.length > 0){
            const availableOptions = getFilteredTeams().map(({ team_id }) => team_id);

            // Unselect options that aren't included in the newly filtered options
            setFieldValue('team_ids', values.team_ids.filter((id) => availableOptions.includes(id)))
        }
    }, [ values.filter, selectedDivisionId ]);

    return (
        <>
            <Label className="text-muted mb-0 mt-3" for="competition_id">
                <Translate id="members.search.addCompetitionTeam.availableTeams" />
                <small className="ml-1">
                    ({getFilteredTeams().length}/{(Array.isArray(options) ? options : []).length})
                </small>
            </Label>
            <Collapse isOpen={!!values.division}>
                <label className="d-flex align-items-center mb-0 text-muted small">
                    <FormikCheckedButton
                        name="filter"
                        id="filter_team_ids"
                        onChange={() => {
                            const nextVal = !values.filter;
                            setFieldValue('filter', nextVal);
                        }}
                    />
                    {values.division &&
                        <Translate
                            id="members.search.addCompetitionTeam.filterDiv"
                            values={{
                                division: displayI18n("short_name", values.division.i18n, values.division.short_name, getGenericLocale()),
                                b: (c) => <b className="mx-1">{c}</b>,
                            }}
                        />
                    }
                </label>
            </Collapse>
            <FormikSelect
                className="mt-1"
                name="team_ids"
                multi
                options={getFilteredOptions()}
                renderSelectedOption={(option) => (
                    <>
                        {option.label}
                        <small className="text-muted ml-1">
                            (
                            <Translate
                                id={`form.fields.gender.${(option.team_category?.gender || "").toLowerCase()}`}
                                defaultMessage={option.team_category?.gender}
                            />
                            {" - "}
                            <DisplayCategory category={option.team_category} />
                            )
                        </small>
                    </>
                )}
                renderOption={({ option, isSelected }) => (
                    <>
                        {option.label}
                        {!option.isGroup &&
                            <div className={`text-${isSelected ? 'light' : 'muted'} small`}>
                                <DisplayCategory category={option.team_category} />
                                <br />
                                <Translate
                                    id={`form.fields.gender.${(option.team_category?.gender || "").toLowerCase()}`}
                                    defaultMessage={option.team_category?.gender}
                                />
                            </div>
                        }
                    </>
                )}
                searchKeys={[
                    `organisation.i18n.${getGenericLocale()}.name`,
                    `team_category.division.i18n.${getGenericLocale()}.short_name`,
                    `team_category.class.i18n.${getGenericLocale()}.short_name`,
                ]}
                preventSelectedReorder
                id="team_ids"
            />
        </>
    );
}

export default AddCompetitionTeamSelect;