import Translate from "@spordle/intl-elements";
import CollapsibleCard from "../../../../../../../components/collapsibleCard/CollapsibleCard";
import CanDoAction from "../../../../../../../components/permissions/CanDoAction";
import OutcomeCard from "./OutcomeCard";
import SuspensionCard from "./SuspensionCard";

const DiscriminationSidePanelOutcomes = ({
    selectedRows,
    readOnly,
    setAddOutcomeIsOpen,
    setAddSuspensionIsOpen,
    editOutcome,
    refreshData,
}) => {
    return (
        <>
            {(selectedRows[0].maltreatment_outcomes || []).length == 0 ?
                <div className="bg-light-inverse rounded border text-center p-3 mb-3">
                    <Translate id="discrimination.outcomes.outcome.empty" />
                    <button onClick={() => setAddOutcomeIsOpen(true)} type="button" className="reset-btn text-primary text-center w-100 mt-2">
                        <i className="mdi mdi-plus" /><Translate id="discrimination.outcomes.addOutcome" />
                    </button>
                </div>
                :
                <CollapsibleCard
                    initOpen={selectedRows[0].maltreatment_outcomes?.length < 3}
                    className="mb-3"
                    title={
                        <div className="h5 mb-0 font-bold d-flex align-items-center justify-content-between">
                            <Translate id='discrimination.label.outcomes' /> ({selectedRows[0].maltreatment_outcomes?.length})
                        </div>
                    }
                    extraInfo={
                        !readOnly ?
                            <CanDoAction action="ADD" componentCode="organization" componentPermissionCode="discrimination_claims">
                                <small onClick={(e) => { e.stopPropagation(); setAddOutcomeIsOpen(true) }} className="ml-1 clickable text-link h6 mb-0">
                                    <i className="mdi mdi-plus" /> <Translate id="misc.add" />
                                </small>
                            </CanDoAction>
                            :
                            null
                    }
                >
                    {selectedRows[0].maltreatment_outcomes.map((maltreatment_outcome) => (
                        <OutcomeCard
                            key={maltreatment_outcome.maltreatment_outcome_id}
                            maltreatment_id={selectedRows[0].discrimination_id}
                            maltreatment_outcome={maltreatment_outcome}
                            editOutcome={editOutcome}
                            refreshData={refreshData}
                            readOnly={readOnly}
                        />
                    ))}

                </CollapsibleCard>

            }

            {/* Suspensions */}
            {(selectedRows[0].maltreatment_member_suspensions && selectedRows[0].maltreatment_member_suspensions?.length > 0) &&
                    <CollapsibleCard
                        initOpen={selectedRows[0].maltreatment_member_suspensions?.length < 3}
                        title={
                            <div className="h5 mb-0 font-bold d-flex align-items-center justify-content-between">
                                <Translate id='discrimination.label.suspensions' /> ({selectedRows[0].maltreatment_member_suspensions.length})
                            </div>
                        }
                        extraInfo={
                            readOnly ?
                                null
                                :
                                <CanDoAction action="ADD" componentCode="organization" componentPermissionCode="link_suspension_maltreatment_claim" skipAccess>
                                    <CanDoAction action='ADD' componentCode='members' componentPermissionCode='members_suspensions'>
                                        <small onClick={(e) => { e.stopPropagation(); setAddSuspensionIsOpen(true) }} className="ml-1 clickable text-link h6 mb-0">
                                            <i className="mdi mdi-plus" /> <Translate id="misc.add" />
                                        </small>
                                    </CanDoAction>
                                </CanDoAction>
                        }
                    >
                        {selectedRows[0].maltreatment_member_suspensions.map((maltreatment_member_suspension) => (
                            <SuspensionCard
                                key={maltreatment_member_suspension.maltreatment_member_suspension_id}
                                maltreatment={selectedRows[0]}
                                maltreatment_id={selectedRows[0].discrimination_id}
                                maltreatment_member_suspension={maltreatment_member_suspension}
                                refreshData={refreshData}
                                readOnly={readOnly}
                            />
                        ))}

                    </CollapsibleCard>
            }
        </>
    );
}

export default DiscriminationSidePanelOutcomes;