import Translate from '@spordle/intl-elements';
import React, { createContext } from 'react';
import {
    ModalHeader,
    TabContent,
    TabPane
} from "reactstrap";
import MemberProfileRegistrationAdd1 from './components/MemberProfileRegistrationAdd1';
import ManualRegistrationPaymentMethods from '../../../../../../components/manualRegistration/ManualRegistrationPaymentMethods';
import ManualRegistrationMerchantAccounts from '../../../../../../components/manualRegistration/ManualRegistrationMerchantAccounts';
import ManualRegistrationMemberIdentities from '../../../../../../components/manualRegistration/ManualRegistrationMemberIdentities';
import ManualRegistrationForms from '../../../../../../components/manualRegistration/ManualRegistrationForms';
import ManualRegistrationWaivers from '../../../../../../components/manualRegistration/ManualRegistrationWaivers';
import ManualRegistrationInstallments from './components/MemberProfileRegistrationAddInstallments';
import MemberProfileRegistrationAdd4 from './components/MemberProfileRegistrationAdd4';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import AnalyticsModal from '../../../../../../analytics/AnalyticsModal';
import { fail } from '@spordle/toasts';
import ManualRegistrationCheckout from '../../../../../../components/manualRegistration/ManualRegistrationCheckout';
import { addBreadcrumb, Severity } from "@sentry/react";
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import ManualRegistrationOtherItems from '../../../../../../components/manualRegistration/ManualRegistrationOtherItems';
import MemberProfileRegistrationAddExchange from './components/MemberProfileRegistrationAddExchange';
import MemberProfileRegistrationAddExchangeWarning from './components/MemberProfileRegistrationAddExchangeWarning';

// contexts
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { CartsContext } from '../../../../../../contexts/CartsContext';
import { MerchantAccountContext } from '../../../../../../contexts/MerchantAccountContext';
import { UtilsContext } from '../../../../../../contexts/UtilsContext'
import withContexts from '../../../../../../helpers/withContexts';
import ManualRegistrationConvertInvoiceConfirm from '../../../../../../components/manualRegistration/ManualRegistrationConvertInvoiceConfirm';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import ManualRegistrationWaitingListReturn from '../../../../../../components/manualRegistration/ManualRegistrationWaitingListReturn';
import ManualRegistrationWaitingListConfirmation from '../../../../../../components/manualRegistration/ManualRegistrationWaitingListConfirmation';
import ManualRegistrationWaitingListReturnConfirmation from '../../../../../../components/manualRegistration/ManualRegistrationWaitingListReturnConfirmation';
import MemberProfileRegistrationAddManualIdentity from './components/MemberProfileRegistrationAddManualIdentity';
import MemberProfileRegistrationAddManualSelectIdentity from './components/MemberProfileRegistrationAddManualSelectIdentity';
import MemberProfileRegistrationAddManualConfirmation from './components/MemberProfileRegistrationAddManualConfirmation';


/** @type {React.Context<Omit<MemberProfileRegistrationAddContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const MemberProfileRegistrationAddContext = createContext();
MemberProfileRegistrationAddContext.displayName = 'MemberProfileRegistrationAddContext';

class MemberProfileRegistrationAddContextProvider extends React.Component{
    constructor(props){
        super(props);

        this.exchangeMode = !!props.invoiceItems;
        this.convertMode = !!props.invoiceNumber;
        this.convertToTransactional = props.convertToTransactional;

        // sooo this mode is for the dinosaurs still using the paper registration form
        // they need to enter the signed waivers in the system, so this mode lets the user select a division
        // and it loops through the waivers for that division, it also asks the user for the identity signing the waivers
        this.emmanuelMode = false;

        // waiting list
        // eslint-disable-next-line react/no-unused-class-component-methods
        this.waitingListMode = false;
        this.waitingListReturnMode = !!this.props.waitingListNumber;
        this.waitingListReturnManual = false;
        // eslint-disable-next-line react/no-unused-class-component-methods
        this.waitingListReturnData = this.props.waitingListReturnData;

        this.state = {
            currentView:
                this.exchangeMode ?
                    'exchangeWarning'
                    : this.convertMode ?
                        this.convertToTransactional ?
                            'merchantAccounts'
                            :
                            'paymentMethods'
                        : this.waitingListReturnMode ?
                            'waitingListReturn'
                            :
                            'registrationInfo',
            isLoading: this.convertMode,
        }

        this.initViewInfo = {
            otherItems: null,
            customForms: null,
            waivers: null,
            paymentMethods: null,
            merchantAccounts: null,
            installments: null,
            memberIdentities: null,
            invoiceNumber: '',
            invoiceDate: '',
            cartPrice: 0,
            creditAmount: 0,
            customPrice: 0,
            selectedRegistration: '',
            selectedPeriod: '',
            selectedOtherItems: [],
            selectedInstallment: '',
            selectedPaymentMethod: '',
            selectedMerchantAccount: '',
            selectedIdentity: '',
            // normally if we wanted to display a selected element, we would just store the ID, and look for that ID in the array of that element
            // but since we can create an identity from the modal, there's a chance where the selected identity is not in the identities array
            selectedIdentityName: '',
            selectedExchangeItem: null,

            // for the manual emmanuel manual registration manual
            memberTypeId: '',
            divisionId: '',
            identityFirstName: '',
            identityLastName: '',
            formattedWaivers: [],
            previousView: '',

            // waiting list
            waitingListNumber: this.waitingListReturnMode ? this.props.waitingListNumber : '',

            isFinal: false, // to refresh the table or not when closing
        }

        this.viewsInfo = { ...this.initViewInfo }

        this.views = {
            exchangeWarning: 'exchangeWarning',
            exchangeItems: 'exchangeItems',
            registrationInfo: 'registrationInfo',
            otherItems: 'otherItems',
            paymentMethods: 'paymentMethods',
            merchantAccounts: 'merchantAccounts',
            customForms: 'customForms',
            waivers: 'waivers',
            installments: 'installments',
            identities: 'identities',
            checkout: 'checkout',
            confirmation: 'confirmation',
            convertInvoiceConfirm: 'convertInvoiceConfirm',

            // waiting list
            waitingListReturn: 'waitingListReturn',
            waitingListConfirmation: 'waitingListConfirmation',
            waitingListReturnConfirmation: 'waitingListReturnConfirmation',

            // manual (Emmanuel)
            manualSelectIdentity: 'manualSelectIdentity',
            manualIdentity: 'manualIdentity',
            manualConfirmation: 'manualConfirmation',
        }
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    setEmmanuelMode = (emmanuelMode) => {
        this.emmanuelMode = emmanuelMode;
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.isOpen !== this.props.isOpen){
            addBreadcrumb({
                type: 'info',
                message: `${this.props.isOpen ? 'Opening' : 'Closing'} manual registration modal`,
                level: Severity.Log,
                category: 'RegistrationModal',
                data: {
                    currentView: this.state.currentView,
                },
            })
        }else if(prevState.currentView !== this.state.currentView){
            addBreadcrumb({
                type: 'info',
                message: 'Current View changed',
                level: Severity.Log,
                category: 'RegistrationModal',
                data: {
                    currentView: this.state.currentView,
                },
            })
        }
    }

    componentDidMount(){
        if(!this.props.wireFrame){
            // console.log("should be wireframe")
            this.props.CartsContext.clearCarts().catch(console.error);

            // Get all available payment methods
            this.props.UtilsContext.getPaymentMethods()
                .then((paymentMethods) => {
                    this.viewsInfo.paymentMethods = paymentMethods.sort((_a, _b) => {
                        if(parseInt(_a.display_order) < parseInt(_b.display_order)){
                            return -1;
                        }else if(parseInt(_a.display_order > parseInt(_b.display_order))){
                            return 1;
                        }
                        return 0;
                    })
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })

            // Get all merchant accounts for pre-init
            this.props.MerchantAccountContext.getMerchantAccounts({ active: 1 })
                .then((merchantAccounts) => {
                    this.viewsInfo.merchantAccounts = merchantAccounts;
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
                .finally(() => this.setState(() => ({ isLoading: false })))

            // Get all member identities for pre-init
            this.props.MembersContext.getMemberAccounts(this.props.MembersContext.currentMember.member_id)
                .then((identities) => {
                    this.viewsInfo.memberIdentities = identities;
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }

    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    resetViewInfo = () => {
        this.viewsInfo = { ...this.initViewInfo }
    }

    resetTableIfChanges = async() => {
        if(this.viewsInfo.isFinal){ // we don't want to refresh the table if nothing changed
            await this.props.setForceRefresh?.();
            await this.props.setWaiversForceRefresh?.();
            await this.props.refreshTable(this.exchangeMode); // true to send withRefreshTable to the formatAndSyncRows function
        }
    }

    toggle = () => {
        this.resetTableIfChanges()
            .then(() => {
                this.props.toggle();

                if(this.emmanuelMode){
                    this.props.refreshMemberProfile();
                }
            });
    }

    setModalLoading = (toggle) => {
        this.setState(() => ({ isLoading: toggle }));
    }

    goToView = (newView, goBack = false, returnAsString = false) => {
        switch (newView){
            case this.views.waitingListReturn:
                if(returnAsString){
                    return newView
                }
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.exchangeWarning:
            case this.views.exchangeItems:
                if(this.exchangeMode){
                    if(returnAsString){
                        return newView
                    }
                    this.setState(() => ({ currentView: newView }))
                }
                break;
            case this.views.registrationInfo:
                // Coming back to step one needs to clear the cart
                if(this.state.currentView !== newView && !this.exchangeMode && !returnAsString){
                    this.props.CartsContext.clearCarts().catch(console.error);
                    this.resetViewInfo();
                }

                if(returnAsString){
                    return newView
                }
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.otherItems:
                if(this.viewsInfo.otherItems?.length > 0 && !this.exchangeMode){
                    if(returnAsString){
                        return newView
                    }
                    this.setState(() => ({ currentView: newView }))
                    break;
                }else{
                    return this.goToView(goBack ? this.views.registrationInfo : this.views.paymentMethods, goBack, returnAsString)
                }
            case this.views.paymentMethods:
                if(this.viewsInfo.paymentMethods?.length > 0 && this.viewsInfo.cartPrice > 0){
                    if(returnAsString){
                        return newView
                    }
                    this.setState(() => ({ currentView: newView }))
                }else{
                    if(!returnAsString)
                        this.viewsInfo.selectedPaymentMethod = 'CASH';

                    return this.goToView(goBack ? this.views.otherItems : this.views.customForms, goBack, returnAsString) // skip merchant account
                }
                break;
            case this.views.merchantAccounts:
                if(this.viewsInfo.merchantAccounts && this.viewsInfo.selectedPaymentMethod === 'CREDITCARD'){
                    if(returnAsString){
                        return newView
                    }
                    this.setState(() => ({ currentView: newView }))
                }else{
                    return this.goToView(goBack ? this.views.paymentMethods : this.views.customForms, goBack, returnAsString)
                }
                break;
            case this.views.customForms:
                if(this.viewsInfo.customForms){
                    if(returnAsString){
                        return newView
                    }
                    this.setState(() => ({ currentView: newView }))
                }else{
                    return this.goToView(goBack ? this.views.paymentMethods : this.views.waivers, goBack, returnAsString)
                }
                break;
            case this.views.waivers:
                if(this.viewsInfo.waivers){
                    if(returnAsString){
                        return newView
                    }
                    this.setState(() => ({ currentView: newView }))
                }else{
                    return this.goToView(goBack ? this.views.customForms : this.views.installments, goBack, returnAsString)
                }
                break;
            case this.views.installments:
                if(this.viewsInfo.installments){
                    if(returnAsString){
                        return newView
                    }
                    this.setState(() => ({ currentView: newView }))
                }else{
                    return this.goToView(goBack ? this.views.waivers : this.views.identities, goBack, returnAsString)
                }
                break;
            case this.views.identities:
                if(this.viewsInfo.selectedPaymentMethod === 'CREDITCARD' || (this.waitingListReturnMode && !this.waitingListReturnManual)){
                    if(returnAsString){
                        return newView
                    }
                    this.setState(() => ({ currentView: newView }))
                }else{
                    return this.goToView(goBack ? this.views.installments : this.views.checkout, goBack, returnAsString)
                }
                break;
            case this.views.checkout:
                if(returnAsString){
                    return newView
                }
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.confirmation:
                if(returnAsString){
                    return newView
                }
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.waitingListConfirmation:
                if(returnAsString){
                    return newView
                }
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.waitingListReturnConfirmation:
                if(returnAsString){
                    return newView
                }
                this.setState(() => ({ currentView: newView }))
                break;
        }
    }

    goToViewForConvertInvoice = (newView, goBack = false) => {
        if(this.convertToTransactional){
            switch (newView){
                case this.views.merchantAccounts:
                    this.setState(() => ({ currentView: newView }))
                    break;
                case this.views.identities:
                    this.setState(() => ({ currentView: newView }))
                    break;
                case this.views.convertInvoiceConfirm:
                    this.setState(() => ({ currentView: newView }))
                    break;
            }
        }else{ // convert to manual
            switch (newView){
                case this.views.paymentMethods:
                    this.setState(() => ({ currentView: newView }))
                    break;
                case this.views.convertInvoiceConfirm:
                    this.setState(() => ({ currentView: newView }))
                    break;
            }
        }
    }

    goToViewEmmanuel = (newView, goBack = false) => {
        if(this.emmanuelMode){
            switch (newView){
                case this.views.manualSelectIdentity:
                    this.setState(() => ({ currentView: newView }))
                    break;
                case this.views.manualIdentity:
                    this.setState(() => ({ currentView: newView }))
                    break;
                case this.views.identities:
                    this.setState(() => ({ currentView: newView }))
                    break;
                case this.views.waivers:
                    if(this.viewsInfo.waivers){
                        this.setState(() => ({ currentView: newView }))
                    }else{
                        return this.goToViewEmmanuel(goBack ? this.views.identities : this.views.manualConfirmation, goBack)
                    }
                    break;
                case this.views.manualConfirmation:
                    this.setState(() => ({ currentView: newView }))
                    break;
            }
        }
    }

    canGoBack = (newView) => {
        if(this.exchangeMode){
            return this.goToView(newView, true, true) !== this.views.registrationInfo
        }
        return newView !== this.views.exchangeItems
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    manualSelectIdentityOnSubmit = (manual) => {
        if(manual){
            this.goToViewEmmanuel(this.views.manualIdentity)
        }else{
            this.goToViewEmmanuel(this.views.identities)
        }
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    manualIdentityOnSubmit = (firstName, lastName) => {
        this.viewsInfo.identityFirstName = firstName;
        this.viewsInfo.identityLastName = lastName;
        this.viewsInfo.previousView = this.views.manualIdentity;
        this.viewsInfo.selectedIdentity = '';
        this.goToViewEmmanuel(this.views.waivers)
    }

    waitingListReturnOnSubmit = (manual) => {
        this.waitingListReturnMode = true;
        this.waitingListReturnManual = manual;
        if(manual){
            this.goToView(this.views.registrationInfo);
        }else{
            this.goToView(this.views.identities)
        }

        return Promise.resolve();
    }

    paymentMethodsOnSubmit = (paymentMethod) => {
        this.viewsInfo.selectedPaymentMethod = paymentMethod;
        if(this.convertMode && !this.convertToTransactional){
            this.goToViewForConvertInvoice(this.views.convertInvoiceConfirm);
        }else{
            this.goToView(this.views.merchantAccounts)
        }
    }

    merchantAccountsOnSubmit = (merchantAccount) => {
        this.viewsInfo.selectedMerchantAccount = merchantAccount
        if(this.convertMode){
            this.goToViewForConvertInvoice(this.views.identities);
        }else{
            this.goToView(this.views.customForms)
        }
    }

    formsOnSubmit = (formattedFields, setStatus, activeFormIndex, showNextForm, multipleMode) => {
        this.props.CartsContext.updateCartItemForm(
            this.props.CartsContext.state.cachedCart.shopping_cart_id,
            multipleMode ? this.viewsInfo.customForms[activeFormIndex].rowId : this.viewsInfo.customForms.rowId,
            {
                custom_form_id: multipleMode ? this.viewsInfo.customForms[activeFormIndex].customFormId : this.viewsInfo.customForms.customFormId,
                fields: formattedFields,
            },
        )
            .then(async() => {
                if(multipleMode){
                    if(activeFormIndex + 1 === this.viewsInfo.customForms.length){
                        this.setModalLoading(false)
                        this.goToView(this.views.waivers)
                    }else{
                        this.setModalLoading(false)
                        showNextForm()
                    }
                }else{
                    this.setModalLoading(false)
                    this.goToView(this.views.waivers)
                }
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    this.setModalLoading(false)
                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    waiversOnSubmit = (formattedWaivers, setStatus, activeWaiverIndex, showNextWaiver) => {
        this.setModalLoading(true)

        if(this.emmanuelMode){
            this.viewsInfo.formattedWaivers.push(formattedWaivers)
        }

        Promise.all([
            this.emmanuelMode ? Promise.resolve() : this.props.CartsContext.updateCartItemWaiver(
                this.props.CartsContext.state.cachedCart.shopping_cart_id,
                this.viewsInfo.waivers.find((w) => w.waiver_id === formattedWaivers.waiver_id)?.row_id,
                formattedWaivers,
            ),
        ])
            .then(async() => {
                if(activeWaiverIndex + 1 === this.viewsInfo.waivers.length){
                    this.setModalLoading(false)
                    if(this.emmanuelMode){
                        this.goToViewEmmanuel(this.views.manualConfirmation)
                    }else{
                        this.goToView(this.views.installments)
                    }
                }else{
                    this.setModalLoading(false)
                    showNextWaiver()
                }
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                    this.setModalLoading(false)
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    installmentsOnSubmit = () => {
        this.goToView(this.views.identities)
    }

    memberIdentityOnSubmit = async(identityId, setStatus, name) => {
        this.viewsInfo.selectedIdentity = identityId
        this.viewsInfo.selectedIdentityName = name;

        if(this.convertMode){
            this.goToViewForConvertInvoice(this.views.convertInvoiceConfirm);
        }else if(this.waitingListReturnMode && !this.waitingListReturnManual){
            this.goToView(this.views.waitingListReturnConfirmation);
        }else if(this.emmanuelMode){
            this.viewsInfo.identityFirstName = '';
            this.viewsInfo.identityLastName = '';
            this.viewsInfo.previousView = this.views.identities;
            this.goToViewEmmanuel(this.views.waivers);
        }else{
            this.goToView(this.views.checkout);
        }
    }

    convert = () => {
        return this.props.CartsContext.convert(this.props.invoiceNumber, {
            identity_id: this.viewsInfo.selectedIdentity,
            merchant_account_id: this.viewsInfo.selectedMerchantAccount,
        })
            .then((status) => {
                this.viewsInfo.isFinal = true
                this.viewsInfo.invoiceNumber = this.props.invoiceNumber;
                return status;
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    getCart = () => {
        this.props.CartsContext.getCart(this.props.CartsContext.state.cachedCart.shopping_cart_id)
            .then((cart) => {
                this.setState(() => ({
                    // eslint-disable-next-line react/no-unused-state
                    cartInfo: cart,
                }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    checkout = (shoppingCartId, paymentMethod, invoiceDate, merchantAccount, identityId) => {
        const values = {
            payment_type: paymentMethod || this.viewsInfo.selectedPaymentMethod,
            invoice_date: invoiceDate || this.viewsInfo.invoiceDate,
            merchant_account_id: merchantAccount || this.viewsInfo.selectedMerchantAccount,
            identity_id: identityId || this.viewsInfo.selectedIdentity,
            period_id: this.viewsInfo.selectedPeriod?.period_id || null,
        }
        return this.props.CartsContext.checkoutCart(shoppingCartId || this.props.CartsContext.state.cachedCart.shopping_cart_id, values)
            .then((invoiceData) => {
                this.props.MembersContext.getMember(this.props.MembersContext.currentMember.member_id)
                if(invoiceData.invoice_number){
                    this.goToView(this.views.confirmation)
                    this.viewsInfo.invoiceNumber = invoiceData.invoice_number
                }else if(invoiceData.waiting_list_number){
                    this.goToView(this.views.waitingListConfirmation)
                    this.viewsInfo.waitingListNumber = invoiceData.waiting_list_number
                }
                this.viewsInfo.isFinal = true
                return true
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });

        // for testing (no actual checkout)
        // console.log('CHECKOUT')
        // return Promise.resolve()
        //     .then((invoiceNumber) => {
        //         this.viewsInfo.invoiceNumber = '000000000000'
        //         this.goToView(this.views.confirmation)
        //         return true
        //     })
    }

    convertToManual = () => {
        return this.props.CartsContext.convertToManual(this.props.invoiceNumber, {
            payment_method: this.viewsInfo.selectedPaymentMethod,
        })
            .then((status) => {
                this.viewsInfo.isFinal = true
                this.viewsInfo.invoiceNumber = this.props.invoiceNumber;
                return status;
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    render(){
        return (
            <MemberProfileRegistrationAddContext.Provider value={{ ...this }}>
                <AnalyticsModal isOpen={this.props.isOpen} unmountOnClose analyticsName='MemberProfileRegistrationAddContextProvider'>
                    <OverlayLoader isLoading={this.state.isLoading}>
                        <ModalHeader tag="div" toggle={this.exchangeMode ? null : this.state.currentView === this.views.convertInvoiceConfirm ? this.props.closeModal : this.toggle}>
                            <Translate id={this.convertMode ? 'components.manualRegistration.convert' : 'memberProfile.registration.add.title'} />
                        </ModalHeader>

                        <TabContent activeTab={this.state.currentView}>

                            {/* Manual confirmation select */}
                            <TabPane tabId={this.views.manualSelectIdentity}>
                                {this.state.currentView === this.views.manualSelectIdentity &&
                                    <MemberProfileRegistrationAddContext.Consumer>
                                        {(registrationContext) => (
                                            <MemberProfileRegistrationAddManualSelectIdentity
                                                registrationContext={registrationContext}
                                            />
                                        )}
                                    </MemberProfileRegistrationAddContext.Consumer>
                                }
                            </TabPane>

                            {/* Manual confirmation manual identity */}
                            <TabPane tabId={this.views.manualIdentity}>
                                {this.state.currentView === this.views.manualIdentity &&
                                    <MemberProfileRegistrationAddContext.Consumer>
                                        {(registrationContext) => (
                                            <MemberProfileRegistrationAddManualIdentity
                                                registrationContext={registrationContext}
                                            />
                                        )}
                                    </MemberProfileRegistrationAddContext.Consumer>
                                }
                            </TabPane>

                            {/* Manual confirmation confirmation */}
                            <TabPane tabId={this.views.manualConfirmation}>
                                {this.state.currentView === this.views.manualConfirmation &&
                                    <MemberProfileRegistrationAddContext.Consumer>
                                        {(registrationContext) => (
                                            <MemberProfileRegistrationAddManualConfirmation
                                                registrationContext={registrationContext}
                                            />
                                        )}
                                    </MemberProfileRegistrationAddContext.Consumer>
                                }
                            </TabPane>

                            {/* Waiting list return */}
                            <TabPane tabId={this.views.waitingListReturn}>
                                {this.state.currentView === this.views.waitingListReturn &&
                                    <ManualRegistrationWaitingListReturn
                                        member={this.props.MembersContext.currentMember}
                                        onSubmit={this.waitingListReturnOnSubmit}
                                        setModalLoading={this.setModalLoading}
                                    />
                                }
                            </TabPane>

                            {/* Exchange warning */}
                            <TabPane tabId={this.views.exchangeWarning}>
                                {this.state.currentView === this.views.exchangeWarning &&
                                    <MemberProfileRegistrationAddContext.Consumer>
                                        {(memberProfileRegistrationAddContext) => (
                                            <MemberProfileRegistrationAddExchangeWarning
                                                memberProfileRegistrationAddContext={memberProfileRegistrationAddContext}
                                            />
                                        )}
                                    </MemberProfileRegistrationAddContext.Consumer>
                                }
                            </TabPane>
                            {/* Exchange */}
                            <TabPane tabId={this.views.exchangeItems}>
                                {this.state.currentView === this.views.exchangeItems && this.exchangeMode &&
                                    <MemberProfileRegistrationAddContext.Consumer>
                                        {(memberProfileRegistrationAddContext) => (
                                            <MemberProfileRegistrationAddExchange
                                                memberProfileRegistrationAddContext={memberProfileRegistrationAddContext}
                                                invoiceItems={this.props.invoiceItems}
                                                member={this.props.MembersContext.currentMember}
                                            />
                                        )}
                                    </MemberProfileRegistrationAddContext.Consumer>
                                }
                            </TabPane>
                            {/* Registration */}
                            <TabPane tabId={this.views.registrationInfo}>
                                {this.state.currentView === this.views.registrationInfo &&
                                    <MemberProfileRegistrationAddContext.Consumer>
                                        {(memberProfileRegistrationAddContext) => (
                                            <MemberProfileRegistrationAdd1
                                                // because using the withContexts from inside the component wouldn't work
                                                // kept getting 'displayName of undefined' in the withContexts
                                                memberProfileRegistrationAddContext={memberProfileRegistrationAddContext}
                                                isMulti={this.props.isMulti}
                                                wireFrame={!!this.props.wireFrame}
                                            />
                                        )}
                                    </MemberProfileRegistrationAddContext.Consumer>
                                }
                            </TabPane>
                            <TabPane tabId={this.views.otherItems}>
                                {this.state.currentView === this.views.otherItems &&
                                    <MemberProfileRegistrationAddContext.Consumer>
                                        {(memberProfileRegistrationAddContext) => (
                                            <ManualRegistrationOtherItems
                                                memberProfileRegistrationAddContext={memberProfileRegistrationAddContext}
                                                member={this.props.MembersContext.currentMember}
                                            />
                                        )}
                                    </MemberProfileRegistrationAddContext.Consumer>
                                }
                            </TabPane>
                            <TabPane tabId={this.views.paymentMethods}>
                                {this.state.currentView === this.views.paymentMethods &&
                                    <ManualRegistrationPaymentMethods
                                        paymentMethods={this.viewsInfo.paymentMethods}
                                        selectedPaymentMethod={this.viewsInfo.selectedPaymentMethod}
                                        hidePreviousButton={!this.canGoBack(this.views.otherItems)}
                                        goToPrevious={() => this.goToView(this.views.otherItems, true)}
                                        activateLoading={() => this.setModalLoading(true)}
                                        onSubmit={this.paymentMethodsOnSubmit}
                                        creditCardDisabled={this.viewsInfo.merchantAccounts?.length <= 0 || (!this.convertToTransactional && this.convertMode)}
                                    />
                                }
                            </TabPane>
                            {/* Merchant Account (pre-init => link merchant account to transaction so client can pay through MyAccount) */}
                            <TabPane tabId={this.views.merchantAccounts}>
                                {this.state.currentView === this.views.merchantAccounts &&
                                    <ManualRegistrationMerchantAccounts
                                        merchantAccounts={this.viewsInfo.merchantAccounts}
                                        selectedMerchantAccount={this.viewsInfo.selectedMerchantAccount}
                                        hidePreviousButton={this.convertMode ? true : !this.canGoBack(this.views.paymentMethods)}
                                        goToPrevious={() => this.goToView(this.views.paymentMethods, true)}
                                        onSubmit={this.merchantAccountsOnSubmit}
                                    />
                                }
                            </TabPane>
                            {/* Forms */}
                            <TabPane tabId={this.views.customForms}>
                                {this.state.currentView === this.views.customForms &&
                                    <ManualRegistrationForms
                                        customForms={this.viewsInfo.customForms}
                                        hidePreviousButton={!this.canGoBack(this.views.merchantAccounts)}
                                        goToPrevious={() => this.goToView(this.views.merchantAccounts, true)}
                                        activateLoading={() => this.setModalLoading(true)}
                                        onSubmit={this.formsOnSubmit}
                                    />
                                }
                            </TabPane>
                            {/* Waivers */}
                            <TabPane tabId={this.views.waivers}>
                                {this.state.currentView === this.views.waivers &&
                                    <ManualRegistrationWaivers
                                        waivers={this.viewsInfo.waivers}
                                        hidePreviousButton={!this.canGoBack(this.views.customForms)}
                                        goToPrevious={() => {
                                            if(this.emmanuelMode){
                                                this.goToViewEmmanuel(this.viewsInfo.previousView, true)
                                            }else{
                                                this.goToView(this.views.customForms, true)
                                            }
                                        }}
                                        onSubmit={this.waiversOnSubmit}
                                    />
                                }
                            </TabPane>
                            {/* Installments */}
                            <TabPane tabId={this.views.installments}>
                                {this.state.currentView === this.views.installments &&
                                    <MemberProfileRegistrationAddContext.Consumer>
                                        {(memberProfileRegistrationAddContext) => (
                                            <ManualRegistrationInstallments
                                                installments={this.viewsInfo.installments}
                                                memberProfileRegistrationAddContext={memberProfileRegistrationAddContext}
                                                hidePreviousButton={!this.canGoBack(this.views.waivers)}
                                                goToPrevious={() => this.goToView(this.views.waivers, true)}
                                                onSubmit={this.installmentsOnSubmit}
                                            />
                                        )}
                                    </MemberProfileRegistrationAddContext.Consumer>
                                }
                            </TabPane>
                            {/* Identity Roles to link member (pre-init => paymentMethod: CREDITCARD + merchant_account_id) */}
                            <TabPane tabId={this.views.identities}>
                                {this.state.currentView === this.views.identities &&
                                    <ManualRegistrationMemberIdentities
                                        memberIdentities={this.viewsInfo.memberIdentities}
                                        selectedIdentity={this.viewsInfo.selectedIdentity}
                                        member={this.props.MembersContext.currentMember}
                                        hidePreviousButton={!this.canGoBack(this.views.installments)}
                                        goToPrevious={() => {
                                            if(this.convertMode){
                                                this.goToViewForConvertInvoice(this.views.merchantAccounts, true)
                                            }else if(this.waitingListReturnMode && !this.waitingListReturnManual){
                                                this.goToView(this.views.waitingListReturn)
                                            }else if(this.emmanuelMode){
                                                this.goToViewEmmanuel(this.views.manualSelectIdentity, true)
                                            }else{
                                                this.goToView(this.views.installments, true)
                                            }
                                        }}
                                        onSubmit={this.memberIdentityOnSubmit}
                                    />
                                }
                            </TabPane>
                            {/* Checkout */}
                            <TabPane tabId={this.views.checkout}>
                                {this.state.currentView === this.views.checkout &&
                                    <MemberProfileRegistrationAddContext.Consumer>
                                        {(memberProfileRegistrationAddContext) => (
                                            <ManualRegistrationCheckout
                                                memberProfileRegistrationAddContext={memberProfileRegistrationAddContext}
                                            />
                                        )}
                                    </MemberProfileRegistrationAddContext.Consumer>
                                }
                            </TabPane>
                            {/* Confirmation */}
                            <TabPane tabId={this.views.confirmation}>
                                <MemberProfileRegistrationAdd4 invoiceNumber={this.viewsInfo.invoiceNumber} toggle={this.toggle} />
                            </TabPane>
                            {/* Convert invoice confirmation */}
                            {this.state.currentView === this.views.convertInvoiceConfirm &&
                                <TabPane tabId={this.views.convertInvoiceConfirm}>
                                    <ManualRegistrationConvertInvoiceConfirm
                                        toggle={this.props.toggle}
                                        convert={this.convertToTransactional ? this.convert : this.convertToManual}
                                        goToPrevious={() => this.goToViewForConvertInvoice(this.convertToTransactional ? this.views.identities : this.views.paymentMethods)}
                                        selectedMerchantAccount={this.viewsInfo.merchantAccounts.find((m) => m.merchant_account_id === this.viewsInfo.selectedMerchantAccount)?.name}
                                        selectedIdentity={this.viewsInfo.selectedIdentityName}
                                        selectedPaymentMethod={this.viewsInfo.selectedPaymentMethod}
                                        member={`${this.props.MembersContext.currentMember.first_name} ${this.props.MembersContext.currentMember.last_name}`}
                                        invoiceNumber={this.props.invoiceNumber}
                                        convertToTransactional={this.convertToTransactional}
                                        resetTable={this.resetTableIfChanges}
                                        closeModal={this.props.closeModal}
                                    />
                                </TabPane>
                            }

                            {/* Waiting list confirmation */}
                            <TabPane tabId={this.views.waitingListConfirmation}>
                                {this.state.currentView === this.views.waitingListConfirmation &&
                                    <ManualRegistrationWaitingListConfirmation
                                        member={this.props.MembersContext.currentMember}
                                        waitingListNumber={this.viewsInfo.waitingListNumber}
                                        toggle={this.toggle}
                                        waitlistComponentCode='members'
                                        waitlistComponentPermissionCode='manage_registration_waiting_list'
                                        waitlistRoute='/tasks/waiting-list'
                                    />
                                }
                            </TabPane>

                            {/* Waiting list return confirmation */}
                            <TabPane tabId={this.views.waitingListReturnConfirmation}>
                                {this.state.currentView === this.views.waitingListReturnConfirmation &&
                                    <ManualRegistrationWaitingListReturnConfirmation
                                        selectedIdentityName={this.viewsInfo.selectedIdentityName}
                                        selectedIdentityId={this.viewsInfo.selectedIdentity}
                                        waitingListNumber={this.viewsInfo.waitingListNumber}
                                        setModalLoading={this.setModalLoading}
                                        toggle={this.toggle}
                                        setFinal={(final) => this.viewsInfo.isFinal = final}
                                    />
                                }
                            </TabPane>
                        </TabContent>
                    </OverlayLoader>
                </AnalyticsModal>
            </MemberProfileRegistrationAddContext.Provider>
        );
    }
}

export default withContexts(CartsContext, UtilsContext, MerchantAccountContext, MembersContext)(MemberProfileRegistrationAddContextProvider);
