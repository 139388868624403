export default{
    "catalog.registrationFees.add.dropdown.button" : "Create a fee",

    "catalog.registrationFees.duplicate.title"       : "Duplicate",
    "catalog.registrationFees.duplicate.information" : "Duplicating the following registration fee: ",

    "catalog.registrationFees.add.title"                      : "Add Registration Fee",
    "catalog.registrationFees.add.form.waivers"               : "Waivers",
    "catalog.registrationFees.add.form.memberType"            : "Member Type",
    "catalog.registrationFees.add.form.sku"                   : "SKU",
    "catalog.registrationFees.add.form.category"              : "Category",
    "catalog.registrationFees.add.form.division"              : "Division",
    "catalog.registrationFees.add.form.division.disabled"     : "Disabled options need an affiliation fee setup.",
    "catalog.registrationFees.add.form.name"                  : "Registration Name",
    "catalog.registrationFees.add.form.name.suggestion"       : "Suggested name",
    "catalog.registrationFees.add.form.class"                 : "Class",
    "catalog.registrationFees.add.form.optional"              : "Optional",
    "catalog.registrationFees.add.form.status"                : "Status",
    "catalog.registrationFees.add.form.active"                : "Active",
    "catalog.registrationFees.add.form.inactive"              : "Inactive",
    "catalog.registrationFees.add.form.availablePlaces"       : "Available Places",
    "catalog.registrationFees.add.form.availablePlaces.help"  : "Specify a number of available places to add waiting list or registration count.",
    "catalog.registrationFees.add.form.unlimited"             : "Unlimited",
    "catalog.registrationFees.add.form.cannotWaitingList"     : "You cannot have unlimited places with a waiting list.",
    "catalog.registrationFees.add.form.cannotShowPlaces"      : "You cannot have unlimited places and show the remaining places.",
    "catalog.registrationFees.add.form.positive"              : "The number of available places must be positive.",
    "catalog.registrationFees.add.form.waitingListRequired"   : "The amount of available places is required if the waiting list is activated.",
    "catalog.registrationFees.add.form.showPlacesRequired"    : "The amount of available places is required if you want to show it.",
    "catalog.registrationFees.add.form.waitingList"           : "Waiting list",
    "catalog.registrationFees.add.form.waitingList.tooltip"   : "This registration fee can manage a waiting list",
    "catalog.registrationFees.add.form.showPosition"          : "Ask for position",
    "catalog.registrationFees.add.form.showPlaces"            : "Show remaining places",
    "catalog.registrationFees.add.form.needAvailablePlaces"   : "You need an amount of available places.",
    "catalog.registrationFees.add.form.registrationFee"       : "Registration Fee",
    "catalog.registrationFees.add.form.registrationFee.help"  : "Registration Fee - Membership Fee = Revenue",
    "catalog.registrationFees.add.form.excludeAffiliationFee" : "Exclude membership fees",
    "catalog.registrationFees.add.form.membershipFee"         : "Membership Fee",
    "catalog.registrationFees.add.form.tax"                   : "+tax",
    "catalog.registrationFees.add.form.earlyFee"              : "Early Fee",
    "catalog.registrationFees.add.form.lateFee"               : "Late Fee",
    "catalog.registrationFees.add.form.taxable"               : "Taxable",
    "catalog.registrationFees.add.form.applicableTax.none"    : "Not taxable",
    "catalog.registrationFees.add.form.taxReceipt"            : "Tax Receipt",
    "catalog.registrationFees.add.form.before"                : "Before",
    "catalog.registrationFees.add.form.after"                 : "After",
    "catalog.registrationFees.add.form.installment.number"    : "{installmentNb, plural, =0 {# installment} one {# installment} other {# installments}}",

    "catalog.registrationFees.add.form.noWaivers" : "No waivers available.",

    "catalog.registrationFees.modifiedAges" : "The ages have been modified and are different from the selected equivalence",

    // Error msg
    "catalog.registrationFees.add.form.validation.memberType"          : "Please choose a member type.",
    "catalog.registrationFees.add.form.validation.division"            : "Please choose a division.",
    "catalog.registrationFees.add.form.validation.name"                : "Please enter a name.",
    "catalog.registrationFees.add.form.validation.registrationFee"     : "Please enter a registration fee.",
    "catalog.registrationFees.add.form.validation.registrationFee.min" : "This fee cannot be lower than the membership fee.",
    "catalog.registrationFees.add.form.validation.earlyDate"           : "Please choose a date for your early amount.",
    "catalog.registrationFees.add.form.validation.lateDate"            : "Please choose a date for your late amount.",
    "catalog.registrationFees.add.form.validation.earlyDateBefore"     : "Early fee date must be before than late date.",
    "catalog.registrationFees.add.form.validation.lateDateGreater"     : "Late fee date must be greater than early date.",

    //tooltips
    "catalog.registrationFees.add.excludeAffiliationFee"    : "The membership fee represents the amount required from the participant for registration to its governing body for the season.",
    "catalog.registrationFees.add.excludeAffiliationFee2"   : "By excluding the membership fee, your organization or this specific registration is not responsible for collecting the membership fee.",
    "catalog.registrationFees.add.excludeAffiliationFee3"   : "This is usually used for <b>training camps</b> and other activities not related to registration to a season.",
    "catalog.registrationFees.add.excludeAffiliationFeeTip" : "Click here for more information",

    "catalog.registrationFees.add.disabledExcludeAffiliationFeeTip"        : "An activity period that forces the exclusion of membership fees is selected.",
    "catalog.registrationFees.add.activityPeriod.excludes.membership.fees" : "This activity period forces the exclusion of membership fees",
    "catalog.registrationFees.add.activityPeriod.warning.feesRecalculate"  : "Make sure you want to exclude membership fees.",
}