import { useDisclosure } from "@mantine/hooks"
import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables"
import Translate, { DateFormat } from "@spordle/intl-elements";
import { useEffect, useRef } from "react";
import { Label } from "reactstrap"
import FileViewer from "../../../components/fileViewer/FileViewer";
import { formatFileName, getIcon } from "../../../components/uploader/uploadHelpers";
import AddAttachmentModal from "./AddAttachmentModal";

const AttachmentsSection = ({ attachments, issueKey, getAttachments, ...props }) => {

    const [ isOpen, { toggle } ] = useDisclosure(false);

    const spordleTableRef = useRef();

    useEffect(() => {
        if(attachments){
            spordleTableRef.current.setData(attachments)
            spordleTableRef.current.setSuccess(true)
        }
    }, [ attachments ])

    return (
        <SpordleTableProvider
            id="attachments-grid-table"
            dataIndex='link'
            ref={spordleTableRef}
            loadingStatus='loading'
            emptyLayout={
                <div className="text-center">
                    <i className="mdi mdi-attachment" /> <Translate id='supportRequests.profile.attachments.none' />
                </div>
            }
            loadingMessage={
                <div className="text-center">
                    <Translate id='misc.loading' />...
                </div>
            }
            viewMode='GRID'
            gridConfig={{
                row: {
                    form: true,
                },
                col: {
                    xs: 4,
                    sm: 3,
                    lg: 2,
                },
                gridRender: (data) => <AttachmentRender data={data} key={data.link} />,
            }}
        >
            {(spordleTable) => (
                <div className="mb-5">
                    <div className="d-flex align-items-start justify-content-between">
                        <Label><Translate id='supportRequests.profile.attachments' /></Label>
                        <AddAttachmentModal isOpen={isOpen} toggle={toggle} issueKey={issueKey} getAttachments={getAttachments} />
                        <button className="reset-btn text-primary" type='button' onClick={toggle}><i className="mdi mdi-plus mr-1" /><Translate id='misc.add' /></button>
                    </div>

                    <SpordleTableView />
                </div>
            )}
        </SpordleTableProvider>
    )
}

const AttachmentRender = ({ data }) => {
    const [ isOpen, { toggle } ] = useDisclosure(false);

    return (
        <div
            onClick={toggle}
            className="mb-3 rounded-lg overflow-hidden border shadow-sm clickable card-hover d-flex align-items-center position-relative flex-column"
        >
            <div className='square-element flex-shrink-1 w-100 min-h-0'>
                <FileViewer
                    key={data.link}
                    fullPath={data.link}
                    fromJIRA
                    creationDate={<DateFormat value={data.created} format={DateFormat.formats.longMonthDayYearTime} />}
                    fileName={data.fileName}
                    type={data.fileType}
                    isOpen={isOpen}
                    close={toggle}
                />
                <div className="square-element-inner">
                    {data.thumbnail ?
                        <img src={data.thumbnail} className='h-100 w-100' style={{ objectFit: 'contain' }} />
                        :
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <i style={{ fontSize: '40px' }} className={getIcon(data.fileType)} />
                        </div>
                    }
                </div>
            </div>
            <span className="small font-medium text-dark bg-light-inverse text-center p-1 w-100 mt-auto">
                <span className="d-flex justify-content-center">{formatFileName(data.fileName)}</span>
                <span className="text-muted"><DateFormat value={data.created} utc /></span>
            </span>
        </div>
    )
}

export default AttachmentsSection