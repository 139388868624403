import Translate from "@spordle/intl-elements";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Button, Card, CardBody } from "reactstrap";
import { AxiosIsCancelled } from "../../api/CancellableAPI";
import { AuthContext } from "../../contexts/contexts";
import { IdentityRolesContext } from "../../contexts/IdentityRolesContext";
import getAccountUrl from "../../helpers/getExternalUrl";
import { fail, fire } from "@spordle/toasts";
import UserImg from "../UserImg";
import Role from "./Role";
import SLogo from "../../assets/images/logos/red_s_logo.svg";
import { useHistory } from "react-router";
import OverlayLoader from "../loading/OverlayLoader";
import { I18nContext } from "../../contexts/I18nContext";
import { LOCALES } from "../../i18n/locales";
import { DisplayI18n } from "../../helpers/i18nHelper";

/**
 * @description View that appears when user is trying to go to SID and has no identity role selected
 * @param {Object} props
 * @param {Promise} onSelect Function called when a role is selected
 * @returns {JSX.Element}
 */
const AuthRolePicker = ({ onSelect }) => {
    const { account, getUserIdentityRole, authenticate, state, updateUserInfo } = useContext(AuthContext);
    const { enablePrimaryRole } = useContext(IdentityRolesContext);
    const { replace } = useHistory();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ idRoles, setIdRoles ] = useState(null);
    const [ primary, setPrimary ] = useState(null);

    /**
     * @description handle catch
     * @param {Error} e
     */
    const handleCatch = (e) => {
        if(!AxiosIsCancelled(e.message)){
            console.error(e);
            window.location.replace(getAccountUrl('SPORDLE'));
        }
    }

    /**
     * @description Sets the select role as primary then redirects to SID
     * @param {object} role
     */
    const selectRole = async(role) => {
        setIsLoading(true);

        // Promise (see view Auth)
        await onSelect({
            ...state,
            orgId: role.organisation.organisation_id,
            identityRoleId: role.identity_role_id,
        });

        // Set primary role
        await enablePrimaryRole(account.userName, primary ?? role.identity_role_id)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });
        const toastRole = idRoles.find((r) => r.identity_role_id === primary) ?? role;

        fire({
            msg: 'components.authRolePicker.success.msg',
            skipInfoTranslate: true,
            icon:
            <UserImg
                className="shadow-sm"
                alt={toastRole.organisation.organisation_name}
                abbr={toastRole.organisation.abbreviation}
                src={toastRole.organisation.logo?.full_path}
                filePos={toastRole.organisation.logo?.file_position}
                width={40}
            />,
            info: <div className="font-medium">{toastRole.role.title}: {toastRole.organisation.organisation_name}</div>,
        });

        // Go to dashboard
        replace('/');
    }

    /**
     * @description Handles the on click of the role
     * @param {object} role
     */
    const handleSelectRole = (role) => {
        if(!isLoading){
            selectRole(role)
                .catch(handleCatch);
        }
    }

    /**
     * @description changes primary role of the user
     * @param {object} role
     */
    const changePrimaryRole = (role) => {
        setPrimary(role.identity_role_id);
    }

    /**
     * @description Checks if the role is active
     * @param {'0'|'1'|undefined} active
     * @param {{active: '0'|'1'|undefined}} role
     * @returns {boolean}
     */
    const roleIsActive = (active, role) => {
        return !(active === undefined || active === '0' || role.active === undefined || role.active === '0')
    }

    /**
     * @description Checks if role is valid base of start and end date
     * @param {string} start
     * @param {string} end
     * @returns {boolean}
     */
    const startAndEndAreValid = (start, end) => {
        const startValid = start ? moment(start).isBefore(moment()) : true;
        const endValid = end ? moment(end).isAfter(moment()) : true;

        // we want to return false if only one of them is false
        // start can be false only if moment.now() is before, so the start date is in the future
        // end can be false only if moment.now() is after, so the end date is in the past
        // any other cases where they both are ''/null/undefined or valid with moment.now() is a valid case and we return true
        return (startValid && endValid) || (!start && !end) || (!!start && !!end && moment().isBetween(moment(start), moment(end), undefined, '[]'));
    }

    /**
     * @description on mount promises
     */
    const initPromises = async() => {
        await authenticate()
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            }); // logs in

        const identityRoles = await getUserIdentityRole().catch((error) => {
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message)
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
            }
        });
        // get user roles
        const filtered = identityRoles.filter((ir) => {
            return roleIsActive(ir.active, ir.role) && (startAndEndAreValid(ir.start_date, ir.end_date));
        });

        if(filtered.length > 0){
            setPrimary(filtered.find((ir) => ir.is_primary == 1)?.identity_role_id); // sets primary if there's one
            setIdRoles(filtered);
        }else{ // No roles, go back to myaccount
            window.location.replace(getAccountUrl('SPORDLE'));
        }
    }

    useEffect(() => {
        initPromises()
            .catch(handleCatch);
    }, []);

    return (
        <div className="min-vh-100 bg-light-inverse d-flex align-items-center justify-content-center">
            <I18nContext.Consumer>
                {({ locale, setLocale }) => (
                    (locale === 'en-ca') ?
                        <Button
                            className="position-absolute top-0 right-0 m-3"
                            color="link"
                            onClick={() => {
                                setLocale(LOCALES.FRENCH)
                                updateUserInfo({
                                    ...account,
                                    locale: 'fr_CA',
                                    language_code: 'fr',
                                }).catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        })
                                    }
                                })
                            }}
                        >
                            FR
                        </Button>
                        :
                        <Button
                            className="position-absolute top-0 right-0 m-3"
                            color="link"
                            onClick={() => {
                                setLocale(LOCALES.ENGLISH)
                                updateUserInfo({
                                    ...account,
                                    locale: 'en_US',
                                    language_code: 'en',
                                }).catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        })
                                    }
                                })
                            }}
                        >
                            EN
                        </Button>
                )}
            </I18nContext.Consumer>
            <Card style={{ maxWidth: 400 }} className="d-inline-block card-shadow min-w-xl-25 text-center">
                <OverlayLoader isLoading={isLoading}>
                    <CardBody>
                        <img height={90} src={SLogo} alt="Spordle ID" />
                        <div className="h4 font-bold"><Translate id='components.authRolePicker.welcome' /></div>
                        <p className="h6 mb-3 text-muted"><Translate id='components.authRolePicker.selectRole' /></p>
                        {idRoles === null ?
                            <Skeleton className="mb-2" count={2} height={94} />
                            :
                            idRoles.map((idRole) => (
                                <Role
                                    setSelectedRole={handleSelectRole}
                                    primary={primary}
                                    setPrimary={() => changePrimaryRole(idRole)}
                                    idRole={idRole}
                                    key={idRole.identity_role_id}
                                />
                            ))
                        }
                    </CardBody>
                </OverlayLoader>
            </Card>
        </div>
    )
}

export default AuthRolePicker;