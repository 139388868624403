import { useSpordleTable } from '@spordle/datatables';
import { FormikInputText } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form as FormikForm, Formik } from 'formik';
import { useContext } from 'react';
import { Button, Col, Collapse, Label, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledAlert } from "reactstrap";
import { string, object } from 'yup';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import Required from '../../../../../components/formik/Required';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import { success } from '@spordle/toasts';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';

const AddAccountModal = ({ isOpen, toggle }) => {
    const { createOrganisationLedger, organisation_id } = useContext(OrganizationContext);
    const { addRow } = useSpordleTable();

    return (
        <AnalyticsModal backdrop='static' isOpen={isOpen} toggle={toggle} analyticsName='AddAccountModal'>
            <Formik
                initialValues={{
                    name: '',
                    number: '',
                    description: '',
                }}
                validationSchema={object().shape({
                    name: string().required(<Translate id='form.validation.name.required' />),
                    number: string().required(<Translate id='organization.profile.accounting.ledger.organizationAccountingLedger.accountNumber.required' />),
                    description: string(),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    createOrganisationLedger(organisation_id, values)
                        .then((ledgerId) => {
                            success();
                            setSubmitting(false);
                            addRow({
                                organisation_ledger_id: ledgerId,
                                active: '1',
                                ...values,
                            })
                                .then(toggle);
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                setSubmitting(false);
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            }
                        })
                }}
            >
                {(formik) => (
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <FormikForm>
                            <ModalHeader toggle={toggle}><Translate id='organization.profile.accounting.ledger.addAccountModal.title' /></ModalHeader>
                            <ModalBody>
                                <Row form>
                                    <Col sm="6" className="form-group">
                                        <Label className="text-muted mb-0" for="name">
                                            <Translate id='organization.profile.accounting.ledger.organizationAccountingLedger.accountName' /> <Required />
                                        </Label>
                                        <FormikInputText name="name" id="name" />
                                    </Col>
                                    <Col sm="6" className="form-group">
                                        <Label className="text-muted mb-0" for="number">
                                            <Translate id='organization.profile.accounting.ledger.organizationAccountingLedger.accountNumber' /> <Required />
                                        </Label>
                                        <FormikInputText name="number" id="number" />
                                    </Col>
                                    <Col sm="12" className="form-group">
                                        <Label className="text-muted mb-0" for="description">
                                            <Translate id='organization.profile.accounting.ledger.organizationAccountingLedger.accountDesc' />
                                        </Label>
                                        <FormikInputText type="textarea" name="description" id="description" />
                                    </Col>
                                </Row>
                                { formik.status &&
                                    <Collapse appear isOpen>
                                        <UncontrolledAlert color="primary">
                                            <Translate id="misc.error" />
                                        </UncontrolledAlert>
                                    </Collapse>
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button disabled={formik.isSubmitting} color="primary" type="submit"><Translate id='misc.add' /></Button>
                                <Button disabled={formik.isSubmitting} color="primary" outline type="button" onClick={toggle}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </FormikForm>
                    </OverlayLoader>
                )}
            </Formik>
        </AnalyticsModal>
    )
}

export default AddAccountModal;