import Translate from '@spordle/intl-elements';
import { FieldArray, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { object, array, string } from 'yup';
import { useContext, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import OrganizationSearch from './OrganizationSearch';
import OverlayLoader from '../loading/OverlayLoader';
import generatePassword from '../../helpers/passwordGenerator';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import { success, fail } from '@spordle/toasts';
import AnalyticsModal from "../../analytics/AnalyticsModal";
import { DisplayI18n } from '../../helpers/i18nHelper';

/**
 * Power User Only component
 * Update the parent of an organization
 *
 * @component OrganizationParentChangeModal
 * @prop {boolean} [isOpen] This will render a FormikSelect instead of a SpordleSelect so you can use this component with Formik
 * @prop {boolean} [closeModal] This will render a button to show the OrganizationTree
 */
const OrganizationParentChangeModal = ({ isOpen, closeModal }) => {
    const organizationContext = useContext(OrganizationContext);

    useEffect(() => {
        if(isOpen){
            // Re-update org tree cache for dynamic form
            organizationContext.getOrganizationsTree(false, true)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }
    }, [ isOpen ])

    return (
        <AnalyticsModal analyticsName='OrganizationParentChangeModal' isOpen={isOpen} className="modal-lg">
            <ModalHeader tag="div" toggle={closeModal}>
                Change Organization Parent <span className="text-purple">(Power User)</span>
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        organizationSwitches: [ {
                            id: generatePassword(),
                            organizationId: '',
                            organizationParentId: '',
                            currentOrganizationParents: '',
                        } ],
                    }}
                    validationSchema={object().shape({
                        organizationSwitches: array().of(object().shape({
                            organizationId: string().required("Required"),
                            organizationParentId: string().required("Required"),
                        })),
                    })}
                    onSubmit={async(values, { setSubmitting }) => {
                        try{
                            for(let index = 0; index < values.organizationSwitches.length; index++){
                                await organizationContext.updateOrganizationParent(values.organizationSwitches[index].organizationId, values.organizationSwitches[index].organizationParentId)
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })
                            }
                            success();
                            setSubmitting(false);
                            closeModal()
                        }catch(err){
                            if(!AxiosIsCancelled(err.message)){
                                fail();
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {(formik) => (
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <Form name="organizationParentChange" id="organizationParentChange">
                                <FormGroup>
                                    <FieldArray name="organizationSwitches">
                                        {(arrayHelper) => (
                                            <>
                                                {formik.values.organizationSwitches.map((orgSwitch, index) => (
                                                    <Card className="card-shadow mb-3" key={orgSwitch.id}>
                                                        <CardHeader className="d-flex align-items-center">
                                                            <div className="font-bold h5 mb-0">
                                                                Organization Parent Change ({index + 1} / {formik.values.organizationSwitches.length})
                                                            </div>
                                                            { index !== 0 &&
                                                                <button type="button" onClick={arrayHelper.handleRemove(index)} className="ml-auto btn btn-sm btn-link px-0 text-danger">
                                                                    <Translate id="misc.delete" />
                                                                </button>
                                                            }
                                                        </CardHeader>
                                                        <CardBody>
                                                            <FormGroup>
                                                                <Label>Organization</Label>
                                                                <OrganizationSearch
                                                                    fromIdentityRole
                                                                    withFormik withTree
                                                                    name={`organizationSwitches.${index}.organizationId`}
                                                                    id={`organizationSwitches.${index}.organizationId`}
                                                                    //onChange={(org) => formik.setFieldValue(`organizationSwitches.${index}.currentOrganizationParents`, org.parentOrgs)} needs to be equally plugged on tree
                                                                />
                                                                {/* {orgSwitch.currentOrganizationParents && <div className="font-medium small mt-1"><i className="mdi mdi-information-outline text-primary mr-1" />{orgSwitch.currentOrganizationParents}</div>} */}
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label>New Parent</Label>
                                                                <OrganizationSearch
                                                                    fromIdentityRole
                                                                    withFormik withTree
                                                                    name={`organizationSwitches.${index}.organizationParentId`}
                                                                    id={`organizationSwitches.${index}.organizationParentId`}
                                                                />
                                                            </FormGroup>
                                                        </CardBody>
                                                    </Card>
                                                ))}

                                                { formik.values?.organizationSwitches?.length < 3 &&
                                                    <button
                                                        type="button"
                                                        onClick={arrayHelper.handlePush({
                                                            organizationId: '',
                                                            organizationParentId: '',
                                                            id: generatePassword(),
                                                        })}
                                                        className="btn btn-link"
                                                    >
                                                        <i className={'mdi mdi-plus mr-1'} />Add change
                                                    </button>
                                                }
                                            </>
                                        )}
                                    </FieldArray>
                                </FormGroup>
                            </Form>
                        </OverlayLoader>
                    )}
                </Formik>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end">
                    <Button form="organizationParentChange" color='primary' type="submit" className={'mr-1'}><Translate id='misc.save' /></Button>
                    <Button color='primary' onClick={closeModal} outline><Translate id='misc.cancel' /></Button>
                </div>
            </ModalFooter>
        </AnalyticsModal>
    )
}

OrganizationParentChangeModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func,
}

export default OrganizationParentChangeModal;