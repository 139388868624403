import { stringBuilder } from '@spordle/helpers';
import { useCallback, useContext, useEffect } from 'react'
import useSWR from 'swr';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import { IdentityRolesContext } from '../../contexts/IdentityRolesContext';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { DisplayI18n } from '../../helpers/i18nHelper';
import ChangeOrgModal from '../../views/organization/changeOrganization/ChangeOrgModal';
import { fail } from '@spordle/toasts'
import { QuickViewContext } from '../../contexts/QuickViewContext';

const flattenedAccessCache = new Map();

/**
 * @class
 * @prop {object} organisation organisation object, if an object is sent with all necessary keys: abbreviation, organisation_name, organisation_id, logo; the component does not make any requests.
 * If the object is sent with only organisation_id onClick the component goes to get the rest of the relevant information and displays a skeleton while loading,
* @prop {boolean} smallMode Boolean setting the height of the icon to work with 'small' < - (the classname) text
 * @prop {jsx or string} children The children contained between both tags of DisplayOrganization
 * @prop {boolean} onlyButton Renders only the button, in this mode we do not need a child component, the component is now self-closing
 *
 * @requires IMPORTANT MAKE SURE TO HAVE A DEFAULT CASE THAT RETURNS data[columnKey] in renderRow
 *
 * @example with just an organisation_id
 *   <DisplayOrganization organisation={{organisation_id: membersContext.currentMember.organisation?.organisation_id}}>
 *      <DisplayI18n
 *          field='name'
 *          i18n={membersContext.currentMember.organisation?.i18n}
 *          defaultValue={membersContext.currentMember.organisation?.organisation_name}
 *      />
 *  </DisplayOrganization>
 *
 * @example with a full organisation object
 *      <DisplayOrganization organisation={this.props.selectedRows[0].organisation}>
 *          <DisplayI18n
 *              field="name"
 *              defaultValue={this.props.selectedRows[0].organisation?.organisation_name}
 *              i18n={this.props.selectedRows[0].organisation?.i18n}
 *          />
 *      </DisplayOrganization>
 *
 * @copyright Spordle
 */
const DisplayOrganization = ({ children, organisation, smallMode, onlyButton }) => {
    const organizationContext = useContext(OrganizationContext);
    const identityRoleContext = useContext(IdentityRolesContext);
    const quickViewContext = useContext(QuickViewContext);

    // the key composed by a users current organization and the target organization
    const cachedKey = `${organizationContext.organisation_id}_${organisation?.organisation_id}`

    // gets the organization tree once for all organizations, data is mirrored for all instances of this request since we're using useSWR
    const { data, isValidating } = useSWR(
        [ 'getTreeDisplayOrg', organizationContext.organisation_id ],
        () => organizationContext.getOrganizationsTree(undefined, false)
            .then((orgTree) => {
                return orgTree.flattenArray('sub_organisations', (org) => org?.organisation_id);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        , {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        },
    );

    // gets the access boolean, if the user has access to a specific this returns true
    const getHasAccess = useCallback(
        () => {
            return data?.some((orgId) => orgId == organisation?.organisation_id)
        },
        [ cachedKey, isValidating ],
    )

    const hasAccess = getHasAccess();
    const isCurrent = organizationContext.organisation_id === organisation?.organisation_id;

    useEffect(() => {
        if(!isValidating){
            flattenedAccessCache.set(cachedKey, hasAccess);
        }
    }, [ hasAccess ])

    const onClick = (e) => {
        if(quickViewContext.inIframe){ e.preventDefault(); return false; }
        if(onlyButton){ e.stopPropagation(); } // prevents the click event from affecting any components this is placed on (ex this is on a clickable table row, it will only trigger the change modal action and not the row click)

        // on click of the component we determine whether or not we have all the necessary information to display the modal (logo, name, etc).
        // If not, we fetch it, however if this information is available already in the page, use it because it will save an API call
        if(organisation.organisation_id && organisation.abbreviation && organisation.organisation_name && organisation.logo && organisation.i18n){

            organizationContext.setDisplayOrgModal(
                true,
                {
                    identityRole: identityRoleContext.state,
                    org: organisation,
                },
            )
        }else{
            organizationContext.setOrgModalLoading(true);
            organizationContext.setDisplayOrgModal(true, { org: {} })
            organizationContext.getOrganization(organisation.organisation_id).then((org) => {
                organizationContext.setDisplayOrgModal(
                    true,
                    {
                        identityRole: identityRoleContext.state,
                        org: org,
                    },
                )
                organizationContext.setOrgModalLoading(false);
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        }
    }

    if(!isValidating && identityRoleContext.isGod() && hasAccess && !isCurrent){
        if(onlyButton)
            return (
                <div
                    className={stringBuilder('d-flex', { 'clickable': !quickViewContext.inIframe })}
                    onClick={onClick}
                >
                    {!quickViewContext.inIframe &&
                        <div className={stringBuilder(`changeOrgBtn bg-purple`, { "sm": smallMode })}>
                            <i className='mdi mdi-swap-horizontal font-medium text-white' />
                        </div>
                    }
                </div>
            )
        return (
            <div
                className={stringBuilder('d-flex', { 'clickable': !quickViewContext.inIframe })}
                onClick={onClick}
            >
                {children}
                {!quickViewContext.inIframe &&
                    <div className={stringBuilder(`changeOrgBtn bg-purple`, { "sm": smallMode })}>
                        <i className='mdi mdi-swap-horizontal font-medium text-white' />
                    </div>
                }
            </div>
        )
    }
    return onlyButton ? null : children;
}


// In this component we wrap the normal ChangeOrgModal to be able to use it with less props for the DisplayOrganization component
export const DisplayOrganizationModal = () => {
    { /* ChangeOrganization.js line 134 for good example on function*/ }
    const organizationContext = useContext(OrganizationContext);
    return (
        <ChangeOrgModal
            selectedIsLoading={organizationContext.state?.isOgModalLoading}
            setRecentOrg={() => { }}
            isOpen={organizationContext.state?.isOrgChangeModalOpen}
            selected={organizationContext.state?.selectedOrg}
            toggle={() => organizationContext.setDisplayOrgModal(false)}
        />
    )
}

export default DisplayOrganization