import { stringBuilder } from "@spordle/helpers";
import styles from "./ChecklistIcon.module.scss";

const ChecklistIcon = ({ isComplete, ...props }) => {
    return (
        <img
            src={`/checklist/${stringBuilder({ "in": !isComplete })}complete.png`}
            alt="Checklist"
            {...props}
            className={stringBuilder(props.className, styles.img)}
        />
    );
}

export default ChecklistIcon;