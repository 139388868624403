import { FormikError, FormikInputText } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { FormattedMessage } from 'react-intl';
import { FieldArray, useFormikContext } from 'formik';
import { useCallback } from 'react';
import { Col, Collapse, Fade, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { nakedOption } from '../../CustomFormEditor';

/**
 * @description Component that handles questions with choices i.e. radio buttons, checkboxes, etc.
 * @param {object} props
 * @param {"CHECKBOX"|"RADIO"|"SELECTBOX"|"SELECTBOX_MULTI"} props.type
 * @param {number} props.questionIndex
 * @param {number} props.sectionIndex
 * @param {string} props.id
 */
const QuestionTypeChoices = ({ type, ...props }) => {
    const formik = useFormikContext();

    const getChoiceUiElement = useCallback((index) => {
        switch (type){
            case 'CHECKBOX':
                return <i className="mdi mdi-checkbox-blank-outline" />;
            case 'RADIO':
                return <i className="mdi mdi-radiobox-blank" />;
            case 'SELECTBOX':
            case 'SELECTBOX_MULTI':
                return `${index}.`;
            default:
                break;
        }
    }, [ type ])

    return (
        <FieldArray name={`${props.id}.options`}>
            {(optionsArrayHelpers) => (
                <>
                    <OrganizationContext.Consumer>
                        {({ hasFrench, hasEnglish }) => (
                            formik.values.sections[props.sectionIndex].questions[props.questionIndex].options.map((option, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Collapse isOpen appear mountOnEnter unmountOnExit key={index}>
                                    <FormGroup>
                                        <Row className="px-3">
                                            <Col md="1" className="p-0 d-flex align-items-center">
                                                <div className="pl-2">{getChoiceUiElement(index + 1)}</div>
                                            </Col>
                                            <Col md="10" className="p-0">
                                                {hasEnglish() &&
                                                    <FormGroup className={hasFrench() ? undefined : 'mb-0'}>
                                                        <InputGroup>
                                                            <FormattedMessage id='catalog.forms.components.formEditor.questionType.questionTypeChoices.choice'>
                                                                {(message) => (<FormikInputText name={`${props.id}.options.${index}.i18n.en.fieldOption`} trim placeholder={message + ' ' + (index + 1)} translatePlaceholder={false} manualError />)}
                                                            </FormattedMessage>
                                                            <InputGroupAddon addonType='append'>
                                                                <InputGroupText>
                                                                    EN
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                        <FormikError name={`${props.id}.options.${index}.i18n.en.fieldOption`} />
                                                    </FormGroup>
                                                }

                                                {hasFrench() &&
                                                    <FormGroup className='mb-0'>
                                                        <InputGroup>
                                                            <FormattedMessage id='catalog.forms.components.formEditor.questionType.questionTypeChoices.choice'>
                                                                {(message) => (<FormikInputText name={`${props.id}.options.${index}.i18n.fr.fieldOption`} trim placeholder={message + ' ' + (index + 1)} translatePlaceholder={false} manualError />)}
                                                            </FormattedMessage>
                                                            <InputGroupAddon addonType='append'>
                                                                <InputGroupText>
                                                                    FR
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                        <FormikError name={`${props.id}.options.${index}.i18n.fr.fieldOption`} />
                                                    </FormGroup>
                                                }
                                            </Col>
                                            <Col md="1" className="p-0 d-flex justify-content-center align-items-center">
                                                <Fade mountOnEnter unmountOnExit in={formik.values.sections[props.sectionIndex].questions[props.questionIndex].options.length > 1}>
                                                    <button className='btn' type='button' onClick={optionsArrayHelpers.handleRemove(index)}><i className="mdi mdi-close text-danger" /></button>
                                                </Fade>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Collapse>
                            ))
                        )}
                    </OrganizationContext.Consumer>
                    <FormGroup>
                        <Row className="px-3 justify-content-center">
                            <Col md="10" className="p-0">
                                <button type='button' className='reset-btn text-link' onClick={optionsArrayHelpers.handlePush(nakedOption)}>
                                    <i className="mdi mdi-plus mr-1" /><Translate id='catalog.forms.components.formEditor.questionType.questionTypeChoices.addChoice' />
                                </button>
                            </Col>
                        </Row>
                    </FormGroup>
                </>
            )}
        </FieldArray>
    );
}

export default QuestionTypeChoices;