/**
 * Show a number in an element (query selector by ID)
 * Animate the number by giving it a start and end
 * Change the duration to make the animation longer in miliseconds
 */
const counterAnim = (qSelector, start = 0, end, duration = 400) => {
    const target = document.getElementById(qSelector);
    let startTimestamp = null;
    const step = (timestamp) => {
        if(!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        if(target)
            target.innerText = Math.floor(progress * (end - start) + start);
        if(progress < 1){
            window.requestAnimationFrame(step);
        }
    };
    window.requestAnimationFrame(step);
};

function getScrollbarWidth(){
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    outer.style.msOverflowStyle = 'scrollbar';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    outer.parentNode.removeChild(outer);

    return scrollbarWidth;

}


function lockBodyScroll(){
    document.body.style.paddingRight = `${getScrollbarWidth()}px`;
    document.body.classList.add("overflow-hidden");
}

function unlockBodyScroll(){
    document.body.classList.remove("overflow-hidden");
    document.body.style.paddingRight = `0px`;
}


export{ counterAnim, lockBodyScroll, unlockBodyScroll };