import { useSpordleTable } from "@spordle/datatables";
import { stringBuilder } from "@spordle/helpers";
import { useFormikContext } from "formik";
import { Card } from "reactstrap";
import CompetitionTeamStatus from "./CompetitionTeamStatus";

const TeamsSearchFiltersCard = ({ status }) => {
    const table = useSpordleTable();
    const formik = useFormikContext();
    const competitionTeams = table.getAdditionalData().teams;
    const count = (competitionTeams || []).filter(({ competition_team_status_id }) => competition_team_status_id === status.competition_team_status_id).length;
    const isSelected = formik.values.competition_team_status_id === status.competition_team_status_id;

    const handleOnClick = () => {
        formik.setFieldValue('competition_team_status_id', status.competition_team_status_id);
        formik.handleSubmit();
    }

    return (
        <Card onClick={handleOnClick} className={stringBuilder({ "border-primary": isSelected }, "card-shadow card-hover clickable mb-3 p-3 text-center")} body>
            <div className="font-weight-bold h2 mb-2">
                {count}
            </div>
            <div>
                <CompetitionTeamStatus status={status} />
            </div>
        </Card>
    );
}

export default TeamsSearchFiltersCard;