import Translate from "@spordle/intl-elements";
import { Tooltip } from "@mantine/core";

const MandatoryLabel = () => {
    return (
        <Tooltip withArrow label={<Translate id='catalog.waivers.mandatory.tooltip' />}>
            <Translate id='misc.mandatory' /><i className="ml-2 mdi mdi-information-outline text-primary" />
        </Tooltip>
    );
}

export default MandatoryLabel;