import { FormikAddress, FormikError, FormikInputText, getFormikAddressInitialValues } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik"
import { useRef, useContext, useEffect } from "react";
import { Button, Col, Collapse, FormGroup, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { object, string } from "yup";
import CustomAlert from "../../../../../../../components/CustomAlert";
import Required from "../../../../../../../components/formik/Required";
import OrganizationSearch from "../../../../../../../components/organization/OrganizationSearch";
import { OrganizationContext } from "../../../../../../../contexts/OrganizationContext";
import { getAdditionalFieldsInitialValues, getAdditionalFieldsRender, getAdditionalFieldsValidationSchema } from "../../../../../../../helpers/additionalFieldsHelpers";
import { LEAGUES, LEAGUES_CATEGORY_NAME, TOURNAMENTS, TOURNAMENTS_CATEGORY_NAME, ZONES, ZONES_CATEGORY_NAME } from "../../../groupingsConstants";

const CreateOrgStep = ({ toggle, from, onSubmit, formData, getFormGroups }) => {
    const orgContext = useContext(OrganizationContext);
    const formikRef = useRef();

    useEffect(() => {
        if(!formData?.newOrg?.organisation_id_parent){
            getOrgCategories(orgContext.organisation_id)
        }
    }, [])

    const getOrgCategories = (orgId) => {
        orgContext.getOrganizationCategory(orgId)
            .then((categories) => {
                return categories.map((category) => {
                    if(category.default_name === ZONES_CATEGORY_NAME && from === ZONES ||
                        category.default_name === LEAGUES_CATEGORY_NAME && from === LEAGUES ||
                        category.default_name === TOURNAMENTS_CATEGORY_NAME && from === TOURNAMENTS){
                        formikRef.current?.setFieldValue('organisation_category_id', category.category_id)
                    }
                })
            })
    }

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                name: formData?.newOrg?.name || '',
                abbreviation: formData?.newOrg?.abbreviation || '',
                organisation_id_parent: formData?.newOrg?.organisation_id_parent || orgContext.organisation_id,
                organisation_category_id: formData?.newOrg?.organisation_category_id || '',
                address: formData?.newOrg?.address ? formData.newOrg.address : getFormikAddressInitialValues({ ...orgContext, state: undefined }),
                fields: formData?.newOrg?.fields || getAdditionalFieldsInitialValues(getFormGroups()),
                usual_name: formData?.newOrg?.usual_name || '',
            }}
            validationSchema={object().shape({
                name: string().required(<Translate id='form.validation.name.required' />),
                abbreviation: string().required(<Translate id='components.createOrganizationModal.form.abbreviation.required' />),
                organisation_id_parent: string().required(<Translate id='components.createOrganizationModal.form.parent.required' />),
                organisation_category_id: string().required(<Translate id='organization.grouping.add.createOrg.orgCategory' values={{ category: <b><Translate id={'organization.grouping.category.' + from} /></b> }} />),
                address: object().address(true, {
                    streetNumber: <Translate id='form.validation.streetNumber.required' />,
                    address: <Translate id='form.validation.address.required' />,
                    city: <Translate id='form.validation.city.required' />,
                    zipCode: <Translate id='form.validation.zip.required' />,
                    state: <Translate id='form.validation.province.required' />,
                    country: <Translate id='form.validation.country.required' />,
                }),
                fields: getAdditionalFieldsValidationSchema(getFormGroups()),
                usual_name: string(),
            })}
            onSubmit={onSubmit}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <div className="font-bold text-dark mb-2"><Translate id='misc.baseData' /></div>
                        <FormGroup>
                            <Label for="organisation_id_parent" className="text-muted"><Translate id='components.createOrganizationModal.form.parent.label' /> <Required /></Label>
                            <div className="position-relative z-index-popover">
                                <OrganizationSearch
                                    withFormik
                                    onOptionSelected={(values) => {
                                        getOrgCategories(values[0])
                                    }}
                                    id="organisation_id_parent"
                                    name="organisation_id_parent"
                                />
                            </div>
                            <FormikError name="organisation_category_id" />
                        </FormGroup>
                        <Row form>
                            <Col className="form-group" md="12">
                                <Label for="name" className="text-muted"><Translate id='form.fields.name' /> <Required /></Label>
                                <FormikInputText id="name" name="name" trim />
                            </Col>
                            <Col className="form-group" md="6">
                                <Label for="usual_name" className="text-muted"><Translate id='organization.profile.overview.generalInfo.form.fields.usualNameShort' /></Label>
                                <FormikInputText id="usual_name" name="usual_name" trim />
                            </Col>
                            <Col className="form-group" md="6">
                                <Label for="abbreviation" className="text-muted"><Translate id='components.createOrganizationModal.form.abbreviation.label' /> <Required /></Label>
                                <FormikInputText id="abbreviation" name="abbreviation" trim />
                            </Col>
                            <Col md="12">
                                <FormikAddress
                                    id='address' name='address'
                                    label='form.fields.address'
                                    labelClassName="text-muted"
                                    required
                                    allowCopyAddress
                                    allowManualPlace
                                    allowOpenInMaps
                                />
                            </Col>
                        </Row>
                        {getAdditionalFieldsRender(getFormGroups(), 'fields')}
                        {formik.status &&
                            <Collapse isOpen appear>
                                <CustomAlert
                                    className='mt-3 text-left'
                                    color='danger'
                                    withTitle
                                    text={formik.status}
                                    translateText={false}
                                    toggle={() => formik.setStatus('')}
                                />
                            </Collapse>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' type='submit'><Translate id='misc.next' /></Button>
                        <Button color='primary' outline type='button' onClick={toggle}><Translate id='misc.cancel' /></Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    )
}

export default CreateOrgStep