import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';
import CanDoAction from '../../../components/permissions/CanDoAction';
import QuickViewActionButton from '../../../components/quickView/QuickViewActionButton';
import SidePanel from '../../../components/sidePanel/SidePanel';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { MembersContext } from '../../../contexts/MembersContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import ValidateDocumentsAction from '../profile/overview/components/memberDocuments/ValidateDocumentsAction';
import { RolesContext } from './../../../contexts/RolesContext';
import MultiOrganization from '../profile/profileHeader/components/multiOrganization/MultiOrganization';

const MemberActionsMenu = ({
    assignOfficiatingPermissionsOnClick,
    transferOnClick,
    transferManualOnClick,
    appealsOnClick,
    mergeMembersOnClick,
    blockMemberOnClick,
    unblockMemberOnClick,
    removeIneligibleMemberOnClick,
    ineligibleMemberOnClick,
    reactivateOnClick,
    unconfirmOnClick,
    member,
    minimalisticView,
    refreshMemberProfile,
    confirmOnClick,
    validateMemberOnClick,
    sendCommOnClick,
    requestMandatoryChanges,
    requestClassificationChange,
    requestRelease,
    potentialIneligibleModalOnClick,
    ctiRequest,
    manageMemberTypesOnClick,
}) => {
    const identityRolesContext = useContext(IdentityRolesContext);
    const organizationContext = useContext(OrganizationContext);
    const rolesContext = useContext(RolesContext);
    const membersContext = useContext(MembersContext);

    const [ isCurrentOrganizationOrParent, setIsCurrentOrganizationOrParent ] = useState(false);

    const hasOutstandingBalance = member.outstanding_balance ? parseInt(member.outstanding_balance) > 0 : false;
    const allAddressesConfirmedOrRejected = member.addresses?.every((addr) => (addr.status === 'CONFIRMED' || addr.status === 'REJECTED'))

    const getHasOutstandingBalanceMessage = () => {
        return (
            hasOutstandingBalance &&
                <div className='text-muted small'><Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.outstandingBalance' /></div>
        )
    }

    useEffect(() => {
        if(member.organisation?.organisation_id){
            organizationContext.isCurrentOrParentOrg(member.organisation?.organisation_id)
                .then((isCurrentOrParentOrg) => {
                    setIsCurrentOrganizationOrParent(isCurrentOrParentOrg)
                })
                .catch((error) => { console.error(error.message) });
        }
    }, [ member.organisation?.organisation_id ])

    // This will let us manage each action seperatly, it makes it easier to manage conditions & dividers
    // minimalistic_view == 0 hides a an action when in minimalistic view and in the member search
    const actionConditions = {
        reactivateMember: !minimalisticView && member.member_status?.name !== 'INELIGIBLE' && member.member_status?.name === 'INACTIVE',
        confirmMember: member.member_status?.name !== 'INELIGIBLE' && member.member_status?.name !== 'INACTIVE' && member.member_status?.name !== 'BLOCKED' && member.member_status?.name !== 'CONFIRMED' && allAddressesConfirmedOrRejected,
        transfer: member.member_status?.name !== 'INELIGIBLE' && identityRolesContext.federation?.organisation_id !== organizationContext.organisation_id,
        manualTransfer: !minimalisticView && member.member_status?.name !== 'INELIGIBLE',
        manageOfficial: !minimalisticView && member.is_official == 1 && member.member_status?.name !== 'INELIGIBLE',
        appeals: !minimalisticView,
        merge: !minimalisticView,
        blockMember: !minimalisticView && member.member_status?.name !== 'INELIGIBLE' && member.member_status?.name !== 'INACTIVE' && (member.member_status?.name === "UNCONFIRMED" || member.member_status?.name === 'CONFIRMED'),
        unblockMember: !minimalisticView && member.member_status?.name !== 'INELIGIBLE' && member.member_status?.name !== 'INACTIVE' && member.member_status?.name == 'BLOCKED',
        makeIneligible: !minimalisticView && member.member_status?.name !== 'INELIGIBLE' && member.member_status?.name !== 'INACTIVE' && member.member_status?.name !== "INELIGIBLE" && member.member_status?.name !== "BLOCKED",
        restoreEligibility: !minimalisticView && member.member_status?.name === 'INELIGIBLE' && member.member_status?.name !== 'INACTIVE',
        validateMember: !!member.unique_identifier,
        unconfirm: !minimalisticView && member.member_status?.name !== 'UNCONFIRMED',
        // Requests
        canRequestMandatoryChanges: member.minimalistic_view == 0 && !!organizationContext.settings.request_mandatory_change_fields?.value,
        canRequestClassificationChange: member.minimalistic_view == 0 && organizationContext.settings.enable_classification_approval_process?.value == 1,
        canCtiRequest: !!organizationContext.settings.international_transfer_fields?.value == 1,
        canRequestRelease: !!organizationContext.settings.enable_permanent_release?.value == 1 && member.minimalistic_view == 0 && organizationContext.settings.enable_permanent_release.value == 1,
        potentialIneligible: member.minimalistic_view == 0 && member.potential_ineligible == 1,
        multiOrganization: member.minimalistic_view == 1,
        manageMemberTypes: member.member_status?.name !== 'INELIGIBLE',
    }
    return (
        <>
            {/* CONFIRM MEMBER */}
            <CanDoAction action='EDIT' componentCode="members" componentPermissionCode="manage_potential_ineligible_members">
                {(actionConditions.potentialIneligible) &&
                    <>
                        <SidePanel.MenuAction
                            id={'member-actions-view-'}
                            onClick={potentialIneligibleModalOnClick} disabled={minimalisticView || !isCurrentOrganizationOrParent}
                        >
                            <Translate id='task.ineligible.member.profile.modal.manage.title' />
                        </SidePanel.MenuAction>
                        <SidePanel.MenuAction divider />
                    </>
                }
            </CanDoAction>

            {/* MEMBER PROFILE REQUESTS */}
            {actionConditions.canRequestMandatoryChanges &&
                <SidePanel.MenuAction
                    id={'member-actions-requestMandatoryChanges'}
                    onClick={requestMandatoryChanges}
                    action='EDIT'
                    componentCode='members'
                    componentPermissionCode='require_mandatory_fields'
                >
                    {membersContext.currentMember.member_request_changes.length > 0 ?
                        <Translate id='members.profile.action.view.requestMandatoryChanges' />
                        :
                        <Translate id='members.profile.action.requestMandatoryChanges' />
                    }
                </SidePanel.MenuAction>
            }
            {actionConditions.canRequestClassificationChange &&
                <SidePanel.MenuAction
                    id={'member-actions-canRequestClassificationChange'}
                    onClick={requestClassificationChange}
                    action='ADD'
                    componentCode='members'
                    componentPermissionCode='members_profile'
                >
                    {membersContext.currentMember.pending_classification_request == 1 ?
                        <Translate id='members.profile.action.view.requestClassificationChange' />
                        :
                        <Translate id='members.profile.action.requestClassificationChange' />
                    }
                </SidePanel.MenuAction>
            }
            {(actionConditions.canCtiRequest) &&
                <SidePanel.MenuAction
                    id={'member-actions-ctiRequest'}
                    action='ADD'
                    componentCode='members'
                    componentPermissionCode='members_request_transfer_international'
                    tag={Link}
                    to={`/members/profile/${member.member_id}/cti`}
                    disabled={member.hasTransferRequest}
                >
                    <Translate id='members.profile.action.ctiRequest' />
                    {member.hasTransferRequest &&
                        <div className='text-muted small'><Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.hasActive' /></div>
                    }
                </SidePanel.MenuAction>
            }
            {actionConditions.canRequestRelease &&
                <SidePanel.MenuAction
                    action='ADD'
                    componentCode='members'
                    componentPermissionCode='members_release_request'
                    id={'member-actions-requestRelease'} onClick={requestRelease}
                >
                    {membersContext.permanentReleaseRequests.pending_permanent_release_requests ?
                        <Translate id='members.profile.action.viewRequestRelease' />
                        :
                        <Translate id='members.profile.action.requestRelease' />
                    }
                </SidePanel.MenuAction>
            }
            {(actionConditions.canRequestMandatoryChanges || actionConditions.canRequestRelease || actionConditions.canCtiRequest || actionConditions.canRequestClassificationChange) &&
                <DropdownItem divider />
            }

            {/* REACTIVATE MEMBER */}
            {actionConditions.reactivateMember &&
                <>
                    <SidePanel.MenuAction id={'member-actions-reactivate'} onClick={reactivateOnClick}>
                        <Translate id='members.search.reactivate.btn.label' />
                    </SidePanel.MenuAction>
                    <DropdownItem divider />
                </>
            }


            {/* CONFIRM MEMBER */}
            <CanDoAction action='EDIT' componentCode="members" componentPermissionCode="confirm_members">
                {(actionConditions.confirmMember) &&
                    <>
                        <SidePanel.MenuAction id={'member-actions-confirm'} onClick={confirmOnClick} disabled={member.incomplete_profile == '1' || minimalisticView || !isCurrentOrganizationOrParent}>
                            <Translate id='members.search.confirm.btn.label' />
                            {member.incomplete_profile == '1' &&
                                <div className='text-muted small'><Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.incomplete' /></div>
                            }
                            {minimalisticView || !isCurrentOrganizationOrParent &&
                                <div className='text-muted small'><Translate id='members.search.sidePanelMemberSearch.actions.noconfirm.shared' /></div>
                            }
                        </SidePanel.MenuAction>
                        <SidePanel.MenuAction divider />
                    </>
                }
            </CanDoAction>

            {/* TRANSFER */}
            {actionConditions.transfer &&
                <SidePanel.MenuAction
                    id={'member-actions-transfer'}
                    onClick={transferOnClick}
                    disabled={hasOutstandingBalance || member.hasTransferRequest || member.organisation?.organisation_id === organizationContext.organisation_id}
                    action='ADD'
                    componentCode='members'
                    componentPermissionCode='members_request_transfer'
                >
                    <Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer' />
                    {member.hasTransferRequest &&
                        <div className='text-muted small'><Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.hasActive' /></div>
                    }
                    {member.organisation?.organisation_id === organizationContext.organisation_id &&
                        <div className='text-muted small'><Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.currentOrg' /></div>
                    }
                    {getHasOutstandingBalanceMessage()}
                </SidePanel.MenuAction>
            }

            {/* TRANSFER USA */}
            <SidePanel.MenuAction
                id={'member-actions-transfer-usa'}
                action='ADD'
                componentCode='members'
                componentPermissionCode='members_request_transfer_usa'
                disabled={hasOutstandingBalance || member.hasTransferRequest}
                tag={Link}
                to={`/members/profile/${member.member_id}/usa`}
            >
                <Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.usa' />
                {member.hasTransferRequest &&
                    <div className='text-muted small'><Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.hasActive' /></div>
                }
                {getHasOutstandingBalanceMessage()}
            </SidePanel.MenuAction>

            {/* TRANSFER INTERNATIONAL */}
            {!actionConditions.canCtiRequest &&
                <SidePanel.MenuAction
                    id={'member-actions-transfer-international'}
                    action='ADD'
                    componentCode='members'
                    componentPermissionCode='members_request_transfer_international'
                    disabled={hasOutstandingBalance || member.hasTransferRequest}
                    tag={Link}
                    to={`/members/profile/${member.member_id}/international`}
                >
                    <Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.international' />
                    {member.hasTransferRequest &&
                        <div className='text-muted small'><Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.hasActive' /></div>
                    }
                    {getHasOutstandingBalanceMessage()}
                </SidePanel.MenuAction>
            }

            {/* MULTI ORGANIZATION */}
            {actionConditions.multiOrganization &&
                <MultiOrganization member={member} />
            }

            {/* MANUAL TRANSFER */}
            {actionConditions.manualTransfer &&
                <SidePanel.MenuAction
                    id={'member-actions-manual-transfer'}
                    action='ADD'
                    componentCode='members'
                    componentPermissionCode='participant_transfer_bypass'
                    onClick={transferManualOnClick}
                    disabled={hasOutstandingBalance || member.hasTransferRequest}
                >
                    <Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.manual' />
                    {member.hasTransferRequest &&
                        <div className='text-muted small'><Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.hasActive' /></div>
                    }
                    {getHasOutstandingBalanceMessage()}
                </SidePanel.MenuAction>
            }

            {actionConditions.manageOfficial &&
                <CanDoAction
                    action='ADD'
                    componentCode="members"
                    componentPermissionCode="manage_official_supervisions"
                >
                    <SidePanel.MenuAction divider />
                    <SidePanel.MenuAction
                        id={'member-actions-manage-official-supervisions'}
                        onClick={assignOfficiatingPermissionsOnClick}
                    >
                        <Translate id='components.assignOfficiatingPermissionsModal.btn' />
                    </SidePanel.MenuAction>
                </CanDoAction>
            }

            {(rolesContext.canDoAction('ADD', 'members', 'members_merge') || rolesContext.canDoAction('ADD', 'members', 'member_appeal_request')) &&
                <DropdownItem divider />
            }

            {/* APPEALS */}
            {(actionConditions.appeals) &&
                <SidePanel.MenuAction
                    action='ADD'
                    componentCode='members'
                    componentPermissionCode='member_appeal_request'
                    onClick={appealsOnClick}
                    id={'member-actions-appeals'}
                >
                    <Translate id='members.profile.appeals.title' />
                </SidePanel.MenuAction>
            }

            {/* MERGE MEMBER */}
            {(actionConditions.merge) &&
                <SidePanel.MenuAction
                    action='ADD'
                    componentCode='members'
                    componentPermissionCode='members_merge'
                    onClick={mergeMembersOnClick}
                    id={'member-actions-merge'}
                >
                    <Translate id='members.search.memberMerge.btn.label' />
                </SidePanel.MenuAction>
            }

            {/* MANAGE MEMBER TYPE */}
            {(actionConditions.manageMemberTypes) &&
                <SidePanel.MenuAction
                    action='EDIT'
                    componentCode='members'
                    componentPermissionCode='member_types'
                    onClick={manageMemberTypesOnClick}
                    id={'member-actions-manage-member-types'}
                >
                    <Translate id='members.profile.memberType.manage.title' />
                </SidePanel.MenuAction>
            }

            {/* -- Block / Unblock / Make Ineligible Actions -- */}
            {(actionConditions.blockMember || actionConditions.unblockMember || actionConditions.makeIneligible || actionConditions.restoreEligibility || actionConditions.unconfirm) &&
                <DropdownItem divider />
            }

            {/* BLOCK MEMBER */}
            {(actionConditions.blockMember) &&
                <SidePanel.MenuAction onClick={blockMemberOnClick} id={'member-actions-block'}>
                    <Translate id='members.search.block.btn.label' />
                </SidePanel.MenuAction>
            }

            {/* UNBLOCK MEMBER  */}
            {(actionConditions.unblockMember) &&
                <SidePanel.MenuAction onClick={unblockMemberOnClick} id={'member-actions-unblock'}>
                    <Translate id='members.profile.action.unblock.btn' />
                </SidePanel.MenuAction>
            }

            {/* MAKE MEMBER INELIGIBLE */}
            {(actionConditions.makeIneligible) &&
                <SidePanel.MenuAction
                    id={'member-actions-make-ineligible'}
                    action='ADD'
                    componentCode='members'
                    componentPermissionCode='manage_ineligible_members'
                    onClick={ineligibleMemberOnClick}
                >
                    <Translate id='members.search.ineligible.btn.label' />
                </SidePanel.MenuAction>
            }

            {/* RESTORE MEMBER ELIGIBILITY  */}
            {(actionConditions.restoreEligibility) &&
                <>
                    <SidePanel.MenuAction
                        id={'member-actions-restore-eligible'}
                        onClick={removeIneligibleMemberOnClick}
                        action='DELETE'
                        componentCode='members'
                        componentPermissionCode='manage_ineligible_members'
                    >
                        <Translate id='members.profile.action.remove.ineligibility.btn' />
                    </SidePanel.MenuAction>
                </>
            }

            {/* UNCONFIRM MEMBER  */}
            {(actionConditions.unconfirm) &&
                <>
                    <SidePanel.MenuAction
                        id={'member-actions-restore-eligible'}
                        onClick={unconfirmOnClick}
                        action='EDIT'
                        componentCode='members'
                        componentPermissionCode='members_profile'
                    >
                        <Translate id='members.profile.action.unconfirm' />
                    </SidePanel.MenuAction>
                </>
            }

            <DropdownItem divider />

            {/* validate member */}
            {(actionConditions.validateMember) &&
                <SidePanel.MenuAction
                    id={'member-actions-validate'}
                    onClick={validateMemberOnClick}
                >
                    <Translate id='components.validateMember.member.action' />
                </SidePanel.MenuAction>
            }

            {/* validate member */}
            {(member.need_address_document == 1) &&
                <ValidateDocumentsAction
                    onSuccess={refreshMemberProfile}
                    toggler={(fn) => (
                        <SidePanel.MenuAction
                            id={'member-actions-validate-documents'}
                            onClick={fn}
                        >
                            <Translate id="members.search.memberSearch.missingDocument.validate" />
                        </SidePanel.MenuAction>
                    )}
                />
            }

            {(member.minimalistic_view == 0) &&
                <SidePanel.MenuAction
                    id={'member-actions-send-message'}
                    onClick={sendCommOnClick}
                    action='ADD'
                    componentCode='communications'
                    componentPermissionCode='communications_manage'
                >
                    <Translate id="communications.sendMessage" />
                </SidePanel.MenuAction>
            }
            <QuickViewActionButton member={member} />
        </>
    )
}

export default MemberActionsMenu