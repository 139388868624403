import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { Alert, Button, Collapse, Fade, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../../../../analytics/AnalyticsModal";
import { useContext, useState } from "react";
import DiscriminationSearchResults from "../../../modal/components/DiscriminationSearchResults";
import { OrganizationContext } from "../../../../../../../contexts/OrganizationContext";
import OverlayLoader from "../../../../../../../components/loading/OverlayLoader";
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import { fail } from '@spordle/toasts';
import { MembersContext } from "../../../../../../../contexts/MembersContext";
import { ComplaineeFormikFields, formikComplaineeInit, formikComplaineeValidation } from "../../../DiscriminationHelper";
import { object, string } from 'yup';
import ComplaineeTeamStep from "./components/ComplaineeTeamStep";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";

/**
 * @description Modal that contains a form to add a member to a descrimination claim
 * @param {Object} props
 * @param {object} props.complaint The discrimination complaint to add the member to
 * @param {function} props.forceRefresh Function called after member was created
 * @param {function} props.toggle Function to close the modal
 * @param {boolean} props.isOpen Modal is open or not
 * @returns {React.ReactNode}
 */
const AddComplaineeModal = (props) => {
    return (
        <AnalyticsModal analyticsName="discriminationAddComplaineeModal" isOpen={props.isOpen}>
            <AddComplaineeModalInner {...props} />
        </AnalyticsModal>
    )
}

const AddComplaineeModalInner = ({ complaint, forceRefresh, toggle }) => {
    const [ results, setResults ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const { getMembers } = useContext(MembersContext);
    const { addDiscriminationMember, organisation_id } = useContext(OrganizationContext);
    const [ step, setStep ] = useState(1);

    const createDiscriminationMember = (val) => {
        setIsLoading(true);
        const newValues = { ...val };

        if(val.member?.member_id){
            newValues.member_id = val.member.member_id;
            delete newValues.member;
        }

        return addDiscriminationMember(complaint.discrimination_id, newValues)
            .then((id) => {
                forceRefresh({ discrimination_member: [ ...complaint.discrimination_member, { ...val, discrimination_member_id: id || '' } ] });
                toggle();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    toggle();
                }
            })
    };

    return (
        <Formik
            initialValues={{
                ...formikComplaineeInit,
                team_name: '',
                team_id: '',
                member: {},
            }}
            validationSchema={object().shape({
                ...formikComplaineeValidation,
                team_name: string(),
                team_id: string(),
            })}
            onSubmit={(values, { setStatus }) => {
                setIsLoading(true);
                if(step === 1){
                    return getMembers({
                        organisation_id: organisation_id,
                        first_name: values.first_name,
                        last_name: values.last_name,
                        all: 1,
                    })
                        .then((members) => {
                            if(members.length > 0){
                                setStep(2);
                                setResults(members);
                            }else{
                                setStep(3);
                            }
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                setIsLoading(false);
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            }
                        });
                }else if(step === 3){
                    return createDiscriminationMember({ ...values, belongs_to: 'HOME' });
                }
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={isLoading}>
                        <ModalHeader toggle={toggle}>
                            <Translate id='discrimination.sidePanel.people.addModal.title' />
                        </ModalHeader>
                        <Collapse isOpen={step === 1}>
                            <Fade in={step === 1}>
                                <ModalBody>
                                    <ComplaineeFormikFields />
                                    {formik.status && !results &&
                                        <Collapse isOpen appear>
                                            <Alert color="danger">
                                                <Translate id='misc.error' />
                                            </Alert>
                                        </Collapse>
                                    }
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" type="submit"><Translate id="misc.confirm" /></Button>
                                    <Button color="primary" type="button" outline onClick={toggle}><Translate id="misc.cancel" /></Button>
                                </ModalFooter>
                            </Fade>
                        </Collapse>
                        <Collapse isOpen={step === 2}>
                            <Fade in={step === 2}>
                                <DiscriminationSearchResults
                                    onMemberSelect={(member) => {
                                        formik.setFieldValue('member', member)
                                        setStep(3);
                                    }}
                                    isDisabled={(member) => {
                                        return complaint.discrimination_member.some((m) => m.member?.member_id === member.member_id);
                                    }}
                                    disabledLabel={<Translate id='discrimination.global.alreadyAdded' />}
                                    previous={() => setStep(1)}
                                    results={results}
                                />
                            </Fade>
                        </Collapse>
                        <Collapse isOpen={step === 3}>
                            <Fade in={step === 3}>
                                <ComplaineeTeamStep previous={() => setStep(results?.length > 0 ? 2 : 1)} currSubStep={step} />
                            </Fade>
                        </Collapse>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default AddComplaineeModal;