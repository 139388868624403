export default{
    "organization.profile.overview.generalInfo.title" : "General Information",

    // Form Fields
    "organization.profile.overview.generalInfo.form.fields.orgName"        : "Organization Name ({lang})",
    "organization.profile.overview.generalInfo.form.fields.orgNameEn"      : "Organization Name (EN)",
    "organization.profile.overview.generalInfo.form.fields.orgNameFr"      : "Organization Name (FR)",
    "organization.profile.overview.generalInfo.form.fields.abb"            : "Abbreviation",
    "organization.profile.overview.generalInfo.form.fields.code"           : "Code",
    "organization.profile.overview.generalInfo.form.fields.usualNameShort" : "Usual Name",
    "organization.profile.overview.generalInfo.form.fields.usualName"      : "Usual Name ({lang})",
    "organization.profile.overview.generalInfo.form.fields.usualNameEn"    : "Usual Name (EN)",
    "organization.profile.overview.generalInfo.form.fields.usualNameFr"    : "Usual Name (FR)",
    "organization.profile.overview.generalInfo.form.fields.website"        : "Contact website",
    "organization.profile.overview.generalInfo.form.fields.website.visit"  : "Visit website",
    "organization.profile.overview.generalInfo.form.fields.phoneExt"       : "Extension",
    "organization.profile.overview.generalInfo.form.fields.tags"           : "Tags",

    // Form Validation
    "organization.profile.overview.generalInfo.form.validation.orgName"        : "Organization name is required",
    "organization.profile.overview.generalInfo.form.validation.orgNameEn"      : "Organization name (EN) is required",
    "organization.profile.overview.generalInfo.form.validation.orgNameFr"      : "Organization name (FR) is required",
    "organization.profile.overview.generalInfo.form.validation.email"          : "Email is required",
    "organization.profile.overview.generalInfo.form.validation.abb"            : "Abbreviation is required",
    "organization.profile.overview.generalInfo.form.validation.email.format"   : "Email format is invalid",
    "organization.profile.overview.generalInfo.form.validation.website.format" : "Website format is invalid",

    // Swal toast
    "organization.profile.overview.generalInfo.toast.success" : "Updated information",
    "organization.profile.overview.generalInfo.toast.fail"    : "Unable to update information",
}