import { useHistory } from 'react-router-dom';
import {
    Button
} from "reactstrap";
import AlmostDoneImage from '../../../../assets/images/AlmostDone_Image.png'
import DoneImage from '../../../../assets/images/Done_Image.png'
import TeamGeneralInfo from '../../profile/TeamInfo/components/TeamGeneralInfo/TeamGeneralInfo';
import TeamContact from '../../profile/TeamInfo/components/TeamContact/TeamContact';
import Translate from '@spordle/intl-elements';

const TeamCreationReview = (props) => {

    const history = useHistory();

    return (
        <>
            <div className="text-center mx-auto mb-5" style={{ maxWidth: 500 }}>
                <img className="mb-3" src={AlmostDoneImage} alt="Form not confirmed" width="250" />
                <div className="h4 font-medium">
                    <Translate id="misc.wizard.review.almostDone" />
                </div>
                <p className="text-muted">
                    <Translate id="misc.wizard.review.reviewInfo" />
                </p>
            </div>
            <TeamGeneralInfo teamId={props.teamId} />
            {/* <TeamAddress teamId={teamId} /> */}
            <TeamContact teamId={props.teamId} />
            <div className="text-center mx-auto mb-5" style={{ maxWidth: 500 }}>
                <img className="mb-3" src={DoneImage} alt="Form confirmed" width="250" />
                <div className="mb-2">
                    <Button color="primary" type="button" onClick={() => history.push('/teams/profile/' + props.teamId + '/info')} id='createTeam'>
                        <Translate id="misc.create" />
                    </Button>
                </div>
            </div>
        </>
    );
}

export default TeamCreationReview;