export default{
    "members.search.sidePanelMemberSearch.tabs.profile" : "Profile",

    "members.search.sidePanelMemberSearch.title.selectedMembers" : "Selected Members",

    "members.search.sidePanelMemberSearch.section.profileInfo" : "Profile Info",
    "members.search.sidePanelMemberSearch.section.contact"     : "Contact",

    "members.search.sidePanelMemberSearch.profileInfo.gender"                         : "Gender Identity",
    "members.search.sidePanelMemberSearch.profileInfo.gender.MALE"                    : "Male",
    "members.search.sidePanelMemberSearch.profileInfo.gender.FEMALE"                  : "Female",
    "members.search.sidePanelMemberSearch.profileInfo.gender.PREFER_NOT_TO_SAY"       : "Prefer not to say",
    "members.search.sidePanelMemberSearch.profileInfo.gender.PREFER_TO_SELF_DESCRIBE" : "Prefer to self-describe",
    "members.search.sidePanelMemberSearch.profileInfo.gender.NON_BINARY"              : "Non-binary",

    "members.search.sidePanelMemberSearch.profileInfo.language"    : "Language(s)",
    "members.search.sidePanelMemberSearch.profileInfo.language.FR" : "French",
    "members.search.sidePanelMemberSearch.profileInfo.language.EN" : "English",

    "members.search.sidePanelMemberSearch.profileInfo.birthDate" : "Date of birth",

    "members.search.sidePanelMemberSearch.profileInfo.branch"      : "Branch",
    "members.search.sidePanelMemberSearch.profileInfo.memberTypes" : "Member type(s)",

    "members.search.sidePanelMemberSearch.contact.primary"    : "Primary",
    "members.search.sidePanelMemberSearch.contact.emergency"  : "Emergency Contact",
    "members.search.sidePanelMemberSearch.contact.noContacts" : "This member does not have any contacts.",

    "members.search.sidePanelMemberSearch.contact.unconfirmedAddress"   : "New address to be confirmed.",
    "members.search.sidePanelMemberSearch.contact.clickToVerifyAddress" : "Click here to verify address",

    "members.search.sidePanelMemberSearch.delete.confirmMessage" : "You are about to remove this member from {orgName}",
    "members.search.sidePanelMemberSearch.delete.success"        : "{memberName} deleted",
    "members.search.sidePanelMemberSearch.delete.fail"           : "Could not delete this member",

    "members.search.sidePanelMemberSearch.actions.resquestTransfer"                    : "Request {transfer_title}",
    "members.search.sidePanelMemberSearch.actions.resquestTransfer.manual"             : "Manual {transfer_title}",
    "members.search.sidePanelMemberSearch.actions.resquestTransfer.hasActive"          : "Has active {transfer} request",
    "members.search.sidePanelMemberSearch.actions.resquestTransfer.incomplete"         : "The members profile is incomplete.",
    "members.search.sidePanelMemberSearch.actions.noconfirm.shared"                    : "Can't confirm a shared member.",
    "members.search.sidePanelMemberSearch.actions.resquestTransfer.currentOrg"         : "Can't {transfer} to current organization",
    "members.search.sidePanelMemberSearch.actions.resquestTransfer.outstandingBalance" : "This member has an outstanding balance",
    "members.search.sidePanelMemberSearch.actions.resquestTransfer.usa"                : "{transfer_title} USA",
    "members.search.sidePanelMemberSearch.actions.resquestTransfer.international"      : "International {transfer_title}",

    "members.search.sidePanelMemberSearch.disabled.actions.noStatus"        : "No actions available. Member Status: NULL",
    "members.search.sidePanelMemberSearch.disabled.actions.DRAFT"           : "Limited actions available. Member Status: Draft",
    "members.search.sidePanelMemberSearch.disabled.actions.INELIGIBLE"      : "Limited actions available. Member Status: Ineligible",
    "members.search.sidePanelMemberSearch.disabled.registration.BLOCKED"    : "Cannot register. Member Status: Blocked",
    "members.search.sidePanelMemberSearch.disabled.registration.INELIGIBLE" : "Cannot register. Member Status: Ineligible",

    "members.search.sidePanelMemberSearch.multi.title.bulkUpdate"     : "Bulk update",
    "members.search.sidePanelMemberSearch.multi.title.confirmMembers" : "Confirm Members",
    "members.search.sidePanelMemberSearch.multi.title.blockMembers"   : "Block Members",

    "members.search.sidePanelMemberSearch.multi.validMembersWithUnconfirmed.title" : "{count, plural, =1 {# unconfirmed address} other {# unconfirmed addresses} }",
    "members.search.sidePanelMemberSearch.multi.validMembersWithUnconfirmed.text"  : "You have {count, plural, =1 {# valid member} other {# valid members}} with unconfirmed address(es). By confirming, you will confirm their address as well.",

    "members.search.sidePanelMemberSearch.bulkUpdate.allMembers.topMessage.block" : "You are about to block all the eligible members below.",
    "members.search.sidePanelMemberSearch.bulkUpdate.block.note.helper"           : "This note is for every member.",
    "members.search.sidePanelMemberSearch.bulkUpdate.allMembers.topMessage"       : "You are about to confirm all the eligible members below.",
    "members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validTitle"       : "Members ({members})",
    "members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validText"        : "The following members will be updated",
    "members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validNone"        : "No members",
    "members.search.sidePanelMemberSearch.bulkUpdate.allMembers.invalidTitle"     : "Ineligible members ({members})",
    "members.search.sidePanelMemberSearch.bulkUpdate.allMembers.invalidText"      : "The following members will not be updated",
    "members.search.sidePanelMemberSearch.bulkUpdate.allMembers.actionNone"       : "No members are eligible for this action.",

}