export default{
    "catalog.clinicItems.sidepanel.form.status"          : "Statut",
    "catalog.clinicItems.sidepanel.form.status.active"   : "Actif",
    "catalog.clinicItems.sidepanel.form.status.inactive" : "Inactif",
    "catalog.clinicItems.sidepanel.form.type"            : "Type",
    "catalog.clinicItems.sidepanel.form.name.required"   : "Le nom est obligatoire",
    "catalog.clinicItems.sidepanel.form.description"     : "Description",
    "catalog.clinicItems.sidepanel.form.price"           : "Prix",
    "catalog.clinicItems.sidepanel.form.tax"             : "taxe",
    "catalog.clinicItems.sidepanel.form.SKU"             : "SKU",

    "catalog.clinicItems.sidepanel.delete.message" : "Êtes-vous sûr que vous voulez supprimer",
}