export default{
    "organization.profile.venues.organizationVenues.title" : "Locations & venues",

    "organization.profile.venues.organizationVenues.table.venue"          : "Venue",
    "organization.profile.venues.organizationVenues.table.location"       : "Location & Venue",
    "organization.profile.venues.organizationVenues.table.playingSurface" : "Playing Surface",
    "organization.profile.venues.organizationVenues.table.details"        : "Details",
    "organization.profile.venues.organizationVenues.table.activity"       : "Activity",
    "organization.profile.venues.organizationVenues.table.size"           : "Size",

    "organization.profile.venues.organizationVenues.table.city"      : "City",
    "organization.profile.venues.organizationVenues.table.province"  : "Province",
    "organization.profile.venues.organizationVenues.table.country"   : "Country",
    "organization.profile.venues.organizationVenues.table.noresults" : "No locations found.",
}