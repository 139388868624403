export default{
    "settings.general.title"                 : "Organization Settings",
    "settings.general.tabs.regional"         : "Regional Settings",
    "settings.general.tabs.merchant"         : "Merchant Accounts",
    "settings.general.tabs.paymentMethods"   : "Payment Methods",
    "settings.general.tabs.paysafeOffer"     : "Paysafe Offer",
    "settings.general.tabs.sportPayOffer"    : "SportsPay Offer",
    "settings.general.tabs.taxSettings"      : "Taxes Settings",
    "settings.general.tabs.currencySettings" : "Currency Settings",
    "settings.general.tabs.regionalSettings" : "Registration settings",
    "settings.general.tabs.travelPermits"    : "Travel permits settings",
    "settings.general.tabs.paymentSettings"  : "Payment Settings",

    "settings.general.periodHint.all"    : "All periods",
    "settings.general.periodHint.select" : "Period: {periodName}",

    // Currency Settings
    "settings.general.tabs.currencySettings.iso"            : "ISO Code",
    "settings.general.tabs.currencySettings.symbol"         : "Symbol",
    "settings.general.tabs.currencySettings.canadianDollar" : "Canadian Dollar",

}