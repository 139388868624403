import Translate from "@spordle/intl-elements";
import { MembersContext } from "../../../../../contexts/MembersContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { useContext } from "react";
import { RolesContext } from "../../../../../contexts/RolesContext";
import { stringBuilder } from "@spordle/helpers";

const MemberStatusReasonNote = ({ className }) => {
    const { currentMember } = useContext(MembersContext);
    const rolesContext = useContext(RolesContext);
    const statusLogs = currentMember.member_status_logs;
    const lastLog = statusLogs ? statusLogs[statusLogs.length - 1] : null;

    const canSeeNoteAndReason = currentMember.last_member_status_note &&
        (currentMember.member_status.name !== "INELIGIBLE" ||
        (currentMember.member_status.name === "INELIGIBLE" && rolesContext.hasAccessTo('members', 'manage_ineligible_members')))

    if(!canSeeNoteAndReason){
        return null;
    }

    return (
        <div className={stringBuilder(className, "bg-light border p-2 rounded small")}>
            {(lastLog && lastLog.organisation_deciding) &&
                <div className="mb-2">
                    <div className="text-muted"><Translate id="members.profile.memberProfile.header.statusImposedBy" /></div>
                    <div className="font-medium">
                        <DisplayI18n field='name' defaultValue={lastLog.organisation_deciding.organisation_name} i18n={lastLog.organisation_deciding.i18n} />
                    </div>
                </div>
            }
            {(lastLog && lastLog.member_status_reason) &&
                <div className="mb-2">
                    <div className="text-muted"><Translate id="members.profile.memberProfile.header.statusReason" /></div>
                    <div className="font-medium">
                        <DisplayI18n field='name' defaultValue={lastLog.member_status_reason.name} i18n={lastLog.member_status_reason.i18n} />
                    </div>
                </div>
            }
            <div className="text-muted"><Translate id="members.profile.memberProfile.header.statusNote" /></div>
            <div className="font-medium">
                {currentMember.last_member_status_note.split('|').length > 1 ?
                    <ul className='pl-4 mb-0'>
                        {
                            currentMember.last_member_status_note.split('|').map((note) => {
                                return (
                                    <li key={note}>
                                        <StatusNote statusNote={note} />
                                    </li>
                                )
                            })
                        }
                    </ul>
                    :
                    <StatusNote statusNote={currentMember.last_member_status_note} />
                }
            </div>
        </div>
    );
}

const StatusNote = ({ statusNote }) => {
    // added ADDRESS_CHANGE in case someone fixes the spelling of the enum
    const isPresetNote = (note) => [ 'NEW_PROFILE', 'ADDRESS_CHNAGE', 'ADDRESS_CHANGE', 'BASIC_FIELDS_CHANGE', 'HISTORICAL_SANCTION' ].includes(note);

    return (
        isPresetNote(statusNote) ?
            <Translate id={`members.profile.header.note.${statusNote}`} />
            :
            statusNote
    )
}

export default MemberStatusReasonNote;