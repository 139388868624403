import Translate from '@spordle/intl-elements';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Modal } from 'reactstrap';
import WaiverCard from '../../../../../members/profile/registration/components/Sidepanel/components/waivers/WaiverCard';
import WaiversModal from '../../../../../members/profile/registration/components/Sidepanel/components/waivers/WaiversModal';

const Waivers = ({ registration, refreshTable }) => {
    const [ selectedWaiver, setSelectedWaiver ] = useState(null);
    const [ successModalIsOpen, setSuccessModalIsOpen ] = useState(false);

    return (
        <>
            <div className="h4 font-bold"><Translate id='memberProfile.registration.form.waivers' /></div>
            <WaiversModal
                item={registration.memberRegistration.registration_fee}
                signedBy={{ ...selectedWaiver?.waiver?.identity, invoice_date: selectedWaiver?.updated_at }}
                apiPartner={selectedWaiver?.api_partner?.name}
                waiver={selectedWaiver}
                answer={selectedWaiver?.answer}
                invoiceNumber={registration.invoice_number}
                organization={registration.memberRegistration.organisation}
                closeModal={() => {
                    setSelectedWaiver(null);
                }}
                onSubmit={() => {
                    setSuccessModalIsOpen(true);
                }}
            />
            {registration?.waivers?.map(((waiver) => {
                return (
                    <WaiverCard
                        key={waiver.waiver_id + '-' + waiver.invoiceItem.invoice_item_id}
                        waiver={{ ...waiver, waiver: waiver, invoiceItem: { ...waiver.invoiceItem, organisation: registration.memberRegistration.organisation } }}
                        signedBy={{ ...waiver.identity, invoice_date: waiver.updated_at }}
                        apiPartner={waiver.api_partner?.name}
                        answer={waiver.answer}
                        openModal={() => {
                            setSelectedWaiver({ ...waiver, description: waiver.description, waiver: waiver, identity_id: waiver.identity.identity_id });
                        }}
                    />
                )
            }))}
            <Modal isOpen={successModalIsOpen}>
                <div className='p-3 text-center'>
                    <div>
                        <i style={{ fontSize: 75 }} className={`mdi mdi-check-circle-outline text-success`} />
                    </div>
                    <div className="font-bold h4">
                        <Translate id='memberProfile.registration.form.waiver.signed' />
                    </div>
                    <div className='pt-3'>
                        <Button
                            color='primary'
                            onClick={() => {
                                refreshTable();
                                setSuccessModalIsOpen(false);
                            }}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

Waivers.propTypes = {
    registration: PropTypes.object.isRequired,
};

export default Waivers;