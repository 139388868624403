import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Card, CardBody } from 'reactstrap';
import useSWR from 'swr';
import { TeamsContext } from '../../../../contexts/TeamsContext';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import { getStatus } from '../../TeamHelpers';
import TeamSearchCommentsAddModal from '../components/TeamSearchCommentsAddModal';
import ConfirmModal from '../../../../components/confirmModal/ConfirmModal';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { fail } from '@spordle/toasts';
import { DisplayI18n } from '../../../../helpers/i18nHelper';

const TeamSearchSidepanelComments = ({ team }) => {
    const teamsContext = useContext(TeamsContext);

    const { data: comments, error, isValidating, mutate } = useSWR(
        [ 'getTeamComments', team.team_id, team.team_status ],
        () => teamsContext.getTeamComments(team.team_id)
            .then((comments) => {
                return comments.sort((commentA, commentB) => {
                    if(commentB.created_at){
                        return commentA.created_at ? commentB.created_at.localeCompare(commentA.created_at) : 1;
                    }
                    return commentA.created_at ? -1 : 0;

                })
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        ,
        {
            revalidateOnFocus: false,
        },
    );

    return (
        <>
            {error ?
                <div className='text-dark text-center font-bold mb-0'>
                    <Translate id='misc.error' />
                </div>
                :
                <>
                    <AddCommentButton team={team} mutate={mutate} />
                    {!isValidating && comments ?
                        <>
                            {comments.length == 0 ?
                                <div className='text-muted text-center mb-0'>
                                    <Translate id='teams.profile.roster.sidePanel.teamComments.none' />
                                </div>
                                :
                                comments?.map((comment) => (
                                    <Card key={comment.team_comment_id} className="card-shadow mb-3">
                                        <CardBody className="p-3">
                                            <div className="border-bottom pb-1 mb-3">
                                                <div className='d-flex justify-content-between'>
                                                    <div className='text-dark font-bold mb-0 text-break'>
                                                        {comment.identity.name} {comment.identity.family_name}
                                                    </div>
                                                    <div className='small text-right flex-shrink-0 ml-1 d-flex'>
                                                        <ConfirmModal
                                                            translateModalTitle
                                                            modalTitle='teams.profile.roster.sidePanel.teamComments.deleteMsg'
                                                            toggler={(onClick) => (
                                                                <div className='ml-2 clickable' onClick={onClick}>
                                                                    <i className='text-danger fas fa-trash' />
                                                                </div>
                                                            )}
                                                            onConfirm={() => {
                                                                return teamsContext.deleteTeamComment(team.team_id, comment.team_comment_id)
                                                                    .then(() => {
                                                                        mutate();
                                                                    })
                                                                    .catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            console.error(error.message)
                                                                            fail({
                                                                                msg: 'misc.error',
                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                skipInfoTranslate: true,
                                                                            })
                                                                        }
                                                                    })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='small d-flex justify-content-between align-items-center w-100'>
                                                    {comment.team_status &&
                                                        <div className='text-nowrap'>
                                                            {comment.previous_team_status &&
                                                                <>
                                                                    {getStatus(comment.previous_team_status)}
                                                                    <i className='mdi mdi-arrow-right mx-2' />
                                                                </>
                                                            }
                                                            {getStatus(comment.team_status)}
                                                        </div>
                                                    }
                                                    {comment.created_at &&
                                                        <span className='ml-auto'><DateFormat value={comment.created_at} format='YYYY-MM-DD H:mm' /></span>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                {comment.comment}
                                            </div>
                                        </CardBody>
                                    </Card>
                                ))
                            }
                        </>
                        :
                        <Skeleton height={120} count={3} className='mb-4' />
                    }
                </>
            }
        </>
    );
}

const AddCommentButton = ({ team, mutate }) => {
    const [ modalIsOpen, setModalOpen ] = useState(false);
    const toggleModal = () => { setModalOpen(!modalIsOpen) };

    return (
        <div className="border-bottom mb-3">
            <UserDisplay card hover className="border-primary p-0 text-center w-100 mb-3" style={{ borderStyle: 'dashed' }}>
                <button
                    onClick={toggleModal}
                    type="button"
                    className="reset-btn text-link p-3 w-100"
                >
                    <i className="mdi mdi-plus mr-1" /><Translate id='teams.profile.roster.sidePanel.teamMemberComments.add' />
                    <TeamSearchCommentsAddModal isOpen={modalIsOpen} toggle={toggleModal} team={team} mutate={mutate} />
                </button>
            </UserDisplay>
        </div>
    )
}

export default TeamSearchSidepanelComments;