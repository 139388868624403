import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Button, ModalBody, ModalFooter } from "reactstrap";
import { DISCRIMINATION_TYPE_ANONYMOUS, DISCRIMINATION_TYPE_OFFICIAL, DISCRIMINATION_TYPE_OTHER } from "../../DiscriminationHelper";
import { DiscriminationAddContext } from "../components/DiscriminationAddContext";
import DiscriminationButton from "../components/DiscriminationButton";

// After update, the component is not used anymore, always default to DISCRIMINATION_TYPE_OFFICIAL
const DiscriminationAddModalStepComplaint = ({ stepNb }) => {
    const { setComplaint, next, previous, changeStep, step } = useContext(DiscriminationAddContext);

    const handleTypeSelect = (type) => {
        setComplaint('complaint_type', type);

        if(type === DISCRIMINATION_TYPE_ANONYMOUS){
            changeStep((prev) => prev + 2);
        }else{
            next();
        }
    }

    return (
        <>
            <ModalBody className="pb-0">
                <div className="font-bold h4 mb-0"><Translate id="discrimination.addModal.stepComplaint.title" /></div>
                <p className="mb-0"><Translate id="discrimination.addModal.stepComplaint.subtitle" /></p>
            </ModalBody>
            <ModalBody>
                <DiscriminationButton
                    icon="members"
                    title={<Translate id='discrimination.addModal.stepComplaint.btn.official.title' />}
                    onClick={() => handleTypeSelect(DISCRIMINATION_TYPE_OFFICIAL)}
                >
                    <Translate id='discrimination.addModal.stepComplaint.btn.official.subtitle' />
                </DiscriminationButton>
                <DiscriminationButton
                    icon="members"
                    title={<Translate id='discrimination.addModal.stepComplaint.btn.other.title' />}
                    onClick={() => handleTypeSelect(DISCRIMINATION_TYPE_OTHER)}
                >
                    <Translate id='discrimination.addModal.stepComplaint.btn.other.subtitle' />
                </DiscriminationButton>
                <DiscriminationButton
                    icon="members"
                    title={<Translate id='discrimination.addModal.stepComplaint.btn.anonymous.title' />}
                    onClick={() => handleTypeSelect(DISCRIMINATION_TYPE_ANONYMOUS)}
                >
                    <Translate id='discrimination.addModal.stepComplaint.btn.anonymous.subtitle' />
                </DiscriminationButton>
            </ModalBody>
            <ModalFooter>
                <Button disabled={step !== stepNb} className="mr-auto" color="primary" outline type="button" onClick={previous}>
                    <Translate id="misc.previous" />
                </Button>
            </ModalFooter>
        </>
    )
}

export default DiscriminationAddModalStepComplaint;