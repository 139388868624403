import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';

/** @type {React.Context<Omit<CreditTypesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const CreditTypesContext = createContext();
CreditTypesContext.displayName = 'CreditTypesContext';

class CreditTypesContextProvider extends React.Component{


    /**
     * Get all credit types
     * @param {string} organisation_id
     * @param {object} queryParams
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Credit%20Types/Apicontroller%5CPricing%5CCredittypes%3A%3AgetAllCreditTypes|documentation}
     * @returns {Promise.<Array>}
     */
    getCreditTypes = (organisation_id, queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/credit-types`,
            query: Object.assign({
                organisation_id: organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.credit_types;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Get a specific credit type
    * @param {string} credit_type_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Credit%20Types/Apicontroller%5CPricing%5CCredittypes%3A%3AgetCreditType|documentation}
    * @returns {Promise}
    */
    getCreditType = (credit_type_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/credit-types/${credit_type_id}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.credit_types[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * create a credit type
     * @param {Object} values
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Credit%20Types/Apicontroller%5CPricing%5CCredittypes%3A%3AcreateCreditType|documentation}
     * @returns {Promise}
     */
    createCreditType = (values = {}) => {
        const params = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'description':
                    params.append('description', values.description);
                    break;
                case 'organisation_id':
                    params.append('organisation_id', values.organisation_id);
                    break;
                case 'active':
                    params.append('active', values.active ? 1 : 0);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/credit-types` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.credit_type_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
     * update a credit type
     * @param {string} credit_type_id
     * @param {Object} values
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Credit%20Types/Apicontroller%5CPricing%5CCredittypes%3A%3AupdatePutCreditType|documentation}
     * @returns {Promise}
     */
    updateCreditType = (credit_type_id, values = {}) => {
        const params = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'description':
                    params.append('description', values.description);
                    break;
                case 'active':
                    params.append('active', values.active ? 1 : 0);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/credit-types/${credit_type_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * update a specific credit type
     * @param {string} credit_type_id
     * @param {Object} values
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Credit%20Types/Apicontroller%5CPricing%5CCredittypes%3A%3AupdatePatchCreditType|documentation}
     * @returns {Promise}
     */
    updateCreditTypePartial = (credit_type_id, values = {}) => {
        const params = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'description':
                    params.append('description', values.description);
                    break;
                case 'active':
                    params.append('active', values.active ? 1 : 0);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/credit-types/${credit_type_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Deletes a specific credit type
    * @param {string} credit_type_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Credit%20Types/Apicontroller%5CPricing%5CCredittypes%3A%3AdeleteCreditType|documentation}
    * @returns {Promise}
    */
    deleteCreditType = (credit_type_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `credit-types/${credit_type_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <CreditTypesContext.Provider value={{ ...this }}>
                {this.props.children}
            </CreditTypesContext.Provider>
        )
    }
}

export default CreditTypesContextProvider