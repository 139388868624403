import { Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import DisplayOrg from "../../../../../components/DisplayOrg";
import CanDoAction from "../../../../../components/permissions/CanDoAction";
import Svg from "../../../../../components/Svg";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { isLeague } from "../../../../../helpers/orgHelper";
import CompetitionTeamStatus from "../../../competitionTeamsSearch/CompetitionTeamStatus";
import CompetitionTeamStatusIcon from "../../../competitionTeamsSearch/CompetitionTeamStatusIcon";
import ReviewCompetitionRequestModal from "../../../ReviewCompetitionRequest";
import HoverBlock from "../../../../../components/hoverBlock/HoverBlock";
import { FormattedDate, FormattedTime } from "react-intl";

const TeamHeaderCompetitiveOrg = ({ org, refreshTeam }) => {
    const [ isOpen, { open, close } ] = useDisclosure(false);
    const competitionTeam = org.team;
    const competition = competitionTeam?.competition;
    const isDeclined = competitionTeam?.competition_team_status?.system == "DECLINED";
    const orgIsLeague = isLeague(org.organisation_category?.category_system);

    const handleOnSuccess = () => {
        refreshTeam();
    }

    return (
        <HoverBlock
            dropdownProps={{
                className: "d-flex align-items-center",
            }}
            dropdownChildren={
                <>
                    <div className="position-absolute top-0 right-0">
                        <CanDoAction action="EDIT" componentCode="organization" componentPermissionCode="manage_competition_teams">
                            <i onClick={open} className="mdi mdi-pencil p-2 btn btn-link clickable" />
                        </CanDoAction>
                    </div>
                    <Svg icon={orgIsLeague ? "leagues" : "tournaments"} className="flex-shrink-0" />
                    <div className="pl-1">
                        <div className="text-muted small">
                            <DisplayI18n
                                field="name"
                                i18n={org.organisation_category?.i18n}
                                defaultValue={org.organisation_category?.name}
                            />
                        </div>
                        <div className="text-dark font-medium">
                            <DisplayOrg org={org} />
                        </div>
                        {competition &&
                            <div className="small font-weight-normal">
                                <DisplayI18n field="name" i18n={competition.i18n} defaultValue={competition.name} />
                            </div>
                        }
                        <Tooltip
                            width={250}
                            zIndex={1040}
                            disabled={!competitionTeam.decision_note}
                            label={
                                <div>
                                    <div className="mb-2">
                                        <b className="small font-medium d-block">Date</b>
                                        <FormattedDate value={competitionTeam.decision_date} /> <FormattedTime value={competitionTeam.decision_date} />
                                    </div>
                                    <b className="small font-medium d-block">Note</b>
                                    {competitionTeam.decision_note}
                                </div>
                            }
                        >
                            <CompetitionTeamStatus status={competitionTeam.competition_team_status} />
                        </Tooltip>
                    </div>
                </>
            }
        >
            <div style={{ opacity: isDeclined ? 0.65 : void 0 }} className="d-flex flex-wrap flex-md-nowrap align-items-center mb-2 badge bg-white shadow-sm mr-2">
                <Svg width={25} height={20} icon={orgIsLeague ? "leagues" : "tournaments"} className="flex-shrink-0" />
                <span className="text-truncate" style={{ maxWidth: 200 }}>
                    <DisplayOrg org={org} />
                </span>
                <CompetitionTeamStatusIcon status={competitionTeam.competition_team_status} className="ml-1 font-14" />
                <ReviewCompetitionRequestModal
                    isOpen={isOpen}
                    toggle={close}
                    competitiveOrg={org}
                    onSuccess={handleOnSuccess}
                    teams={[ competitionTeam ]}
                />
            </div>
        </HoverBlock>
    );
}

export default TeamHeaderCompetitiveOrg;