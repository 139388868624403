export default{
    "organisation.profile.title"                     : "Organisation",
    "organisation.profile.header.memberNumber"       : "{unique_identifier}",
    "organisation.profile.header.image.upload.hint"  : "Nous vous recommandons d'utiliser un logo de votre organisation d'un minimum de 200x200. Les images plus petites pourraient avoir des problèmes avec le partage de liens sur les réseaux sociaux.",
    "organisation.profile.header.image.upload.title" : "Téléchargez un logo pour votre organisation",

    "organisation.profile.badge.missingMerchantAccount" : "Compte marchand manquant",
    "organisation.profile.badge.missingCRCSettings"     : "Paramètres de vérification d'antécédents manquants",
    "organisation.profile.badge.missingMandatoryRoles"  : "Rôles d'organisation obligatoire manquants",
    "organisation.profile.badge.unsanctioned"           : "Non confirmé",

    "organisation.profile.action.print.memorandum" : "Imprimer bordereau de club",
    "organisation.profile.action.print.insurace"   : "Imprimer le certificat d'assurances",

    "organisation.profile.header.note" : "Note",

    "organisation.profile.tabs.overview"     : "Général",
    "organisation.profile.tabs.roles"        : "Rôles",
    "organisation.profile.tabs.users"        : "Utilisateurs",
    "organisation.profile.tabs.staff"        : "Personnel",
    "organisation.profile.tabs.zones"        : "Zones",
    "organisation.profile.tabs.leagues"      : "Ligues",
    "organisation.profile.tabs.tournaments"  : "Tournois",
    "organisation.profile.tabs.orgs"         : "Organisations Compétitives",
    "organisation.profile.tabs.competitions" : "Compétitions",
    "organisation.profile.tabs.postalCodes"  : "Codes Postaux",
    "organisation.profile.tabs.venues"       : "Emplacements",
    "organisation.profile.tabs.accounting"   : "Comptabilité",

    "organisation.profile.actions.copy"                   : "Copier le lien public",
    "organisation.profile.actions.goToPage"               : "Voir la page publique",
    "organisation.profile.actions.submitApproval"         : "Soumettre pour approbation",
    "organisation.profile.actions.submitApproval.desc"    : "Voulez-vous soumettre cette organisation pour approbation?",
    "organisation.profile.actions.submitApproval.success" : "Cette organisation a été soumise pour approbation.",

    "organisation.profile.tabs.overview.tax"             : "Taxe",
    "organisation.profile.tabs.overview.noTaxNumber"     : "No Tax Number",
    "organisation.profile.tabs.overview.noTaxNumber.txt" : "No tax number are added to this organization.",
    "organisation.profile.tabs.overview.tax.required"    : "Veuillez spécifier le taux d'imposition",
    "organisation.profile.tabs.overview.number.required" : "Veuillez spécifier le numéro de taxe",
    "organisation.profile.tabs.overview.addTax"          : "Ajouter un numéro de taxe",

}