import PropTypes from 'prop-types';
import Dot from '../../components/Dot';

/**
* @param {Number} [color=0] number associated with the clinic color
* @example <Priority color="2">Normal<Priority/>
*/
const Priority = ({ tag: Tag, className, color = 0, ...attributes }) => {

    // if the color cannot be a number, fallback to 2 (Normal)
    if(isNaN(parseInt(color))) color = 3

    return (
        <div className="d-inline-flex align-items-center">
            <Dot color={"priority-" + color} className="mr-1" />
            <Tag {...attributes} className={className} />
        </div>
    );
};

export default Priority;

Priority.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.oneOfType([
        PropTypes.string, // string must be parseIntable "3" is good. "hello" is not
        PropTypes.number,
    ]),
};
Priority.defaultProps = {
    tag: 'div',
    color: 0,
};