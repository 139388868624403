import { FormikInputNumber, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import {
    Col,
    FormGroup,
    Label, Row
} from "reactstrap";
import useSWR from 'swr';
import { mixed, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { I18nContext } from '../../../../contexts/I18nContext';
import { MembersContext } from '../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import FormikEditable from '../../../formik/FormikEditable';
import OverlayLoader from '../../../loading/OverlayLoader';
import { fail, success } from '@spordle/toasts';
import UserImg from '../../../UserImg';

const FinancesTab = ({ transfer, updateData, ...props }) => {
    const membersContext = useContext(MembersContext);
    const organizationContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext)

    const [ isLoading, setLoading ] = useState(false)

    const { data: orgs, isValidating } = useSWR(
        [ 'getBillableOrganizations', organizationContext.organisation_id ],
        () => organizationContext.getBillableOrganizations()
            .then((organizations) => organizations.filter((org) => org.active == 1))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            }),
    );

    const isClosed = transfer.transfer_request_state === 'CLOSED';// Can't update when the transfer is closed

    return (
        <OverlayLoader isLoading={isLoading}>
            <div className="p-3">
                <Row>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='teamReleaseFees' className='text-muted mb-0'><Translate id='members.profile.transfers.add.fields.releaseFee.label' /></Label>
                            <FormikEditable
                                id='teamReleaseFees'
                                disabled={isClosed}
                                initialValues={{
                                    teamReleaseFees: transfer.team_release_fee ? transfer.team_release_fee / 100 : '',
                                }}
                                validationSchema={object().shape({
                                    teamReleaseFees: mixed(),
                                })}
                                onSubmit={(values) => {
                                    const newFees = values.teamReleaseFees ? Math.round(values.teamReleaseFees * 100) : null
                                    if(newFees !== transfer.team_release_fee){
                                        setLoading(true)
                                        membersContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                            team_release_fee: newFees,
                                        })
                                            .then(() => {
                                                updateData();
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setLoading(false))
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>
                                                {transfer.team_release_fee ? <CurrencyFormat value={transfer.team_release_fee / 100} /> : '-'}
                                            </div>
                                        )
                                    }
                                    return (
                                        <I18nContext.Consumer>
                                            {({ getGenericLocale }) => (
                                                <FormikInputNumber
                                                    autoFocus
                                                    allowLeadingZeros
                                                    fixedDecimalScale
                                                    thousandSeparator=' '
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    name="teamReleaseFees"
                                                    id="teamReleaseFees"
                                                    decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                    suffix={getGenericLocale() === 'fr' ? ' $' : ''}
                                                    prefix={getGenericLocale() !== 'fr' ? '$ ' : ''}
                                                />
                                            )}
                                        </I18nContext.Consumer>
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='teamNameReleaseFees' className='text-muted mb-0'><Translate id='members.profile.transfers.add.fields.releaseFeeTeam.label' /></Label>
                            <FormikEditable
                                id='teamNameReleaseFees'
                                disabled={isClosed}
                                initialValues={{
                                    teamNameReleaseFees: transfer.release_fee_team_name || '',
                                }}
                                validationSchema={object().shape({
                                    teamNameReleaseFees: string(),
                                })}
                                onSubmit={(values) => {
                                    const newName = values.teamNameReleaseFees || null
                                    if(newName !== transfer.release_fee_team_name){
                                        setLoading(true)
                                        membersContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                            release_fee_team_name: newName,
                                        })
                                            .then(() => {
                                                updateData();
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setLoading(false))
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{transfer.release_fee_team_name || '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikInputText id='teamNameReleaseFees' name='teamNameReleaseFees' trim autoFocus />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='owes' className='text-muted mb-0'><Translate id='members.profile.transfers.sidepanel.finances.owes' /></Label>
                            <FormikEditable
                                id='owes'
                                disabled={isClosed}
                                noConfirmation
                                initialValues={{
                                    owes: transfer.owes ? transfer.owes / 100 : '',
                                }}
                                validationSchema={object().shape({
                                    owes: mixed(),
                                })}
                                onSubmit={(values) => {
                                    const newOwes = values.owes ? Math.round(values.owes * 100) : null
                                    if(newOwes != transfer.owes){
                                        setLoading(true)
                                        membersContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                            owes: newOwes,
                                        })
                                            .then(() => {
                                                updateData();
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setLoading(false))
                                    }
                                }}
                            >
                                {(isEditing, options) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{transfer.owes ? <CurrencyFormat value={transfer.owes / 100} /> : '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikSelect
                                            name="owes" id="owes" search={false}
                                            renderOption={({ option }) => <CurrencyFormat value={option.value} />}
                                            onOptionSelected={options.stopEditing}
                                            menuIsDefaultOpen autoFocus
                                            loadingStatus='success'
                                            defaultData={[
                                                {
                                                    value: 0,
                                                    label: '0',
                                                },
                                                {
                                                    value: 50,
                                                    label: '50',
                                                },
                                                {
                                                    value: 100,
                                                    label: '100',
                                                },
                                                {
                                                    value: 150,
                                                    label: '150',
                                                },
                                            ]}
                                        />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='paid' className='text-muted mb-0'><Translate id='members.profile.transfers.sidepanel.finances.paid' /></Label>
                            <FormikEditable
                                id='paid'
                                disabled={isClosed}
                                noConfirmation
                                initialValues={{
                                    paid: transfer.paid ? transfer.paid / 100 : '',
                                }}
                                validationSchema={object().shape({
                                    paid: mixed(),
                                })}
                                onSubmit={(values) => {
                                    const newPaid = values.paid ? Math.round(values.paid * 100) : null
                                    if(newPaid != transfer.paid){
                                        setLoading(true)
                                        membersContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                            paid: newPaid,
                                        })
                                            .then(() => {
                                                updateData();
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setLoading(false))
                                    }
                                }}
                            >
                                {(isEditing, options) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{transfer.paid ? <CurrencyFormat value={transfer.paid / 100} /> : '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikSelect
                                            name="paid" id="paid" search={false}
                                            renderOption={({ option }) => <CurrencyFormat value={option.value} />}
                                            onOptionSelected={options.stopEditing}
                                            menuIsDefaultOpen autoFocus
                                            loadingStatus='success'
                                            defaultData={[
                                                {
                                                    value: 0,
                                                    label: '0',
                                                },
                                                {
                                                    value: 50,
                                                    label: '50',
                                                },
                                                {
                                                    value: 100,
                                                    label: '100',
                                                },
                                                {
                                                    value: 150,
                                                    label: '150',
                                                },
                                            ]}
                                        />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup>
                            <Label for='client' className='text-muted mb-0'><Translate id='members.profile.transfers.sidepanel.finances.clientOrganization' /></Label>
                            <FormikEditable
                                id='client'
                                disabled={isClosed}
                                noConfirmation
                                initialValues={{
                                    clientId: transfer.client_organisation?.organisation_id || '',
                                    client: transfer.client_organisation || null,
                                }}
                                validationSchema={object().shape({
                                    clientId: string(),
                                    client: object().shape({
                                        abbreviation: string().nullable(),
                                        logo: object().nullable(),
                                        organisation_id: string(),
                                        organisation_name: string(),
                                    }).nullable(),
                                })}
                                onSubmit={(values) => {
                                    if(values.client?.organisation_id !== transfer.client_organisation?.organisation_id){
                                        setLoading(true);
                                        membersContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                            client_organisation_id: values.clientId || null,
                                        })
                                            .then(() => {
                                                updateData();
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setLoading(false))
                                    }
                                }}
                            >
                                {(isEditing, options, formik) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark text-truncate'>
                                                {transfer.client_organisation ?
                                                    <DisplayI18n field='name' defaultValue={transfer.client_organisation.organisation_name} i18n={transfer.client_organisation.i18n} />
                                                    :
                                                    '-'
                                                }
                                            </div>
                                        )
                                    }
                                    return (
                                        <FormikSelect
                                            id='clientId'
                                            name='clientId'
                                            isLoading={isValidating}
                                            searchKeys={[
                                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                            ]}
                                            renderSelectedOption={(option) => (
                                                <div className='d-flex align-items-center'>
                                                    <div className="d-flex justify-content-center align-items-center mr-2">
                                                        <UserImg
                                                            abbr={option.abbreviation}
                                                            src={option.logo?.full_path}
                                                            filePos={option.logo?.file_position}
                                                            width={20}
                                                            alt={option.name + ' logo'}
                                                            className="p-1"
                                                        />
                                                    </div>
                                                    <div>
                                                        <span className='font-medium'>
                                                            <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                        </span>
                                                        {option.abbreviation &&
                                                                <span className='text-muted ml-1'>({option.abbreviation})</span>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                            renderOption={({ option }) => (
                                                <div className='d-flex align-items-center'>
                                                    <div className="d-flex justify-content-center align-items-center mr-2">
                                                        <UserImg
                                                            abbr={option.abbreviation}
                                                            src={option.logo?.full_path}
                                                            filePos={option.logo?.file_position}
                                                            width={40}
                                                            alt={option.name + ' logo'}
                                                            className="p-1"
                                                        />
                                                    </div>
                                                    <div>
                                                        <span className='font-medium'>
                                                            <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                        </span>
                                                        {option.abbreviation &&
                                                                <span className='text-muted ml-1'>({option.abbreviation})</span>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                            onOptionSelected={([ value ], table) => {
                                                const select = table.getSpordleTable().getData().find((org) => org.value === value);

                                                if(select){
                                                    formik.setFieldValue('client', {
                                                        abbreviation: select.abbreviation,
                                                        logo: select.logo,
                                                        organisation_id: select.value,
                                                        organisation_name: select.label,
                                                    })
                                                }else{
                                                    formik.setFieldValue('client', null)
                                                }

                                                options.stopEditing();
                                            }}
                                            menuIsDefaultOpen
                                            clearable
                                            autoFocus
                                            backspaceRemovesValue={false}
                                            options={orgs ?
                                                orgs.map((org) => ({
                                                    value: org.organisation_id,
                                                    label: org.organisation_name,
                                                    logo: org.logo,
                                                    abbreviation: org.abbreviation,
                                                }))
                                                :
                                                []
                                            }
                                        />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='invoiceNo' className='text-muted mb-0'><Translate id='members.profile.transfers.sidepanel.finances.invoice' /></Label>
                            <FormikEditable
                                id='invoiceNo'
                                disabled={isClosed}
                                initialValues={{
                                    invoiceNo: transfer.hc_invoice_number || '',
                                }}
                                validationSchema={object().shape({
                                    invoiceNo: string(),
                                })}
                                onSubmit={(values) => {
                                    const newInvoice = values.invoiceNo || null
                                    if(newInvoice !== transfer.hc_invoice_number){
                                        setLoading(true)
                                        membersContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                            hc_invoice_number: newInvoice,
                                        })
                                            .then(() => {
                                                updateData();
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setLoading(false))
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{transfer.hc_invoice_number || '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikInputText id='invoiceNo' name='invoiceNo' trim autoFocus />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </OverlayLoader>
    );
}

export default FinancesTab;