import { stringifyUrl } from "query-string";
import API_SPORDLE from "../API-Spordle";
import { serverError } from "../CancellableAPI";
import { jsObjectToApi } from "@spordle/helpers";

/**
 * Gets all the tax classes
 */
export function getTaxClasses(){
    return API_SPORDLE.get('/tax-classes')
        .then((response) => {
            if(response.data.status){
                return response.data.tax_classes;
            }
            throw response.data.errors;
        }, serverError);
}

/**
 * Gets the tax class rates
 */
export function getTaxClassRates(taxId: string, queryParams?: { country_code?: string, province_coder: string }){
    return API_SPORDLE.get(stringifyUrl({
        url: `/tax-classes/${taxId}`,
        query: queryParams,
    }, {
        arrayFormat: 'comma',
        skipEmptyString: true,
        skipNull: true,
    }))
        .then((response) => {
            if(response.data.status){
                return response.data.rates;
            }
            throw response.data.errors;
        }, serverError);
}