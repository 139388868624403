import { Card } from "reactstrap";
import CardSectionTitle from "../../../../../components/CardSectionTitle";
import ConfigurationQualificationView from "./ConfigurationQualificationView";
import { useDisclosure } from "@mantine/hooks";
import ConfigurationQualificationForm from "./ConfigurationQualificationForm";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import CanDoAction from "../../../../../components/permissions/CanDoAction";

const TeamSettingsConfigurationQualificationRestrictions = () => {
    const [ isEditing, { open: startEditing, close: stopEditing } ] = useDisclosure(false);

    return (
        isEditing ?
            <ConfigurationQualificationForm stopEditing={stopEditing} />
            :
            <Card body className="card-shadow">
                <CardSectionTitle title="settings.tabs.teams.configuration.qualifications" className='d-flex'>
                    <CanDoAction action="EDIT" componentCode="teams" componentPermissionCode="team_settings" className="ml-auto">
                        {(isGodOnly) => (
                            <div className='text-link'>
                                <button
                                    onClick={startEditing}
                                    className={stringBuilder("reset-btn align-self-end", { "text-purple": isGodOnly })}
                                    type='button'
                                >
                                    <Translate id='misc.edit' />
                                </button>
                            </div>
                        )}
                    </CanDoAction>
                </CardSectionTitle>
                <ConfigurationQualificationView startEditing={startEditing} />
            </Card>

    );
}

export default TeamSettingsConfigurationQualificationRestrictions;