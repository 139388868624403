import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { fail } from '@spordle/toasts';
import { useContext, useEffect, useState } from 'react';
import { Card } from 'reactstrap';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import CanDoAction from '../../../../../../../components/permissions/CanDoAction';
import { UtilsContext } from '../../../../../../../contexts/UtilsContext';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import ReginalSettingsEdit from './ReginalSettingsEdit';
import ReginalSettingsRead from './ReginalSettingsRead';

const ReginalSettings = () => {
    const { getSettings } = useContext(UtilsContext);

    const [ isLoading, setLoading ] = useState(true);
    const [ settings, setSettings ] = useState(false);
    const [ isEditing, setEditing ] = useState(false);
    const toggleEditMode = () => { setEditing(!isEditing) };

    useEffect(() => {
        getSettings({ active: '1' })
            .then((settings) => {
                setSettings(settings);
                setLoading(false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    setLoading(false);
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, []);

    return (
        <Card body className="card-shadow">
            <OverlayLoader isLoading={isLoading}>
                <div className="border-bottom pb-1 mb-3 d-flex align-items-end">
                    <div className="card-title font-bold h4 mb-0"><Translate id='settings.general.tabs.regional' /></div>
                    <div className="text-muted small align-self-center ml-1">
                        <i className='mdi mdi-information-outline mr-1 text-primary' /><Translate id='settings.general.periodHint.all' />
                    </div>
                    {!isEditing &&
                        <CanDoAction className='ml-auto' action='EDIT' componentCode='settings' componentPermissionCode='settings_regional'>
                            {(godAccess) => (
                                <button className={stringBuilder('reset-btn', { 'text-link': !godAccess, 'text-purple': godAccess })} type="button" onClick={toggleEditMode}><Translate id='misc.edit' /></button>
                            )}
                        </CanDoAction>
                    }
                </div>
                {isEditing ?
                    <ReginalSettingsEdit toggleEditMode={toggleEditMode} settings={settings} />
                    :
                    <ReginalSettingsRead toggleEditMode={toggleEditMode} settings={settings} />
                }
            </OverlayLoader>
        </Card>
    );
}

export default ReginalSettings;