export default{
    "components.communications.modal.title"                    : "Envoyer un message",
    "components.communications.modal.label.subject"            : "Sujet",
    "components.communications.modal.label.message"            : "Message",
    "components.communications.modal.validation.subject"       : "Veuillez spécifier le sujet",
    "components.communications.modal.validation.message"       : "Veuillez spécifier le message",
    "components.communications.modal.label.recipients"         : "Destinataire(s)",
    "components.communications.modal.label.no.contacts"        : "Aucuns contacts",
    "components.communications.modal.label.no.contacts.please" : "Veuillez sélectionner une ou plusieurs organisations.",
    "components.communications.modal.label.contacts"           : "Contact(s)",

    "components.communications.modal.warning.text"       : "Le module de communication dans la plateforme {spordle_id} a pour but de communiquer avec vos membres pour tout ce qui touche à la de votre sport. Ceci n'est pas une plateforme pour communiquer avec vos participants concernant des services additionnels, des promotions, des promotions avec d'autres parties et/ou des sondages concernant votre sport. Utiliser avec discrétion et en cas de doute, contactez votre organisation provinciale.",
    "components.communications.modal.warning.label"      : "J'ai compris",
    "components.communications.modal.warning.validation" : "Veuillez confirmer que vous avez compris.",

    "components.communications.modal.search.members.toggleAdvanced" : "Recherche avancée",
    "components.communications.modal.empty.recipients"              : "Aucun destinataire",
    "components.communications.modal.orgs.min"                      : "Veuillez sélectionner au moins une (1) organisation",
    "components.communications.modal.orgs.contacts.min"             : "Veuillez sélectionner au moins un (1) contact d'organisation.",
    "components.communications.modal.orgs.max"                      : "Le maximum d'organisations sélectionnées ne doit pas dépasser 50.",

    "components.communications.modal.label.searchAndAdd" : "Chercher & Ajouter",
    "components.communications.modal.btn.search"         : "Chercher & Ajouter",
    "components.communications.modal.btn.error"          : "Erreur",
    "components.communications.modal.btn.notFound"       : "Introuvable",
    "components.communications.modal.btn.exists"         : "Déjà ajouté",
    "components.communications.modal.btn.success"        : "Chercher et ajouter",

    "components.communications.modal.btn.1" : "Introuvable",
    "components.communications.modal.btn.2" : "Déjà ajouté",

    "components.communications.modal.label.invalidRecipients"  : "Destinataire(s) invalide(s)",
    "components.communications.modal.helper.invalidRecipients" : "Un ou plusieurs des destinaires sélectionnés sont invalides ou n'ont pas encore de courriel configuré. Ce message ne peut leur être envoyé. Cliquez sur le bouton « Télécharger » pour avoir une liste des destinataires invalides.",
    "components.communications.modal.contact.empty"            : "Aucun contact",
    "components.communications.modal.email.none"               : "Pas de courriel",
    "components.communications.modal.contact.helper"           : "En choisissant un contact/compte de la liste, vous pouvez assigner une adresse primaire au membre non valide.",
    "components.communications.modal.contact.choose"           : "Choisir le courriel d'un contact...",
    "components.communications.modal.contact.apply"            : "Appliquer",

    "components.communications.modal.confirm.title"               : "Confirmation d'envoi de courriel",
    "components.communications.modal.confirm.singleEmail.success" : "Le message a été envoyé à {email} avec succès",
    "components.communications.modal.confirm.singleEmail.fail"    : "Le message n'a pas pu être envoyé à {email}",

    "components.communications.modal.confirm.multiEmails.msg"     : "Statut des courriels envoyés",
    "components.communications.modal.confirm.multiEmails.success" : "Le message a été envoyé avec succès à <b>{count, plural, one {ce courriel} other {ces # courriels}}</b>",
    "components.communications.modal.confirm.multiEmails.fail"    : "Le message n'a pas pu être envoyé à <b>{count, plural, one {ce courriel} other {ces # courriels}}</b>",

    "components.communications.modal.confirm.btn" : "Compris",
}
