import { Container } from "reactstrap";
import settingsMerchantAccountRoutes from "../../../../../routes/tabRoutes/organizationSettings/settingsMerchantAccountRoutes";
import SubRouteSettingWrapper from "../../../components/SubRouteSettingWrapper";

const OrganizationSettingsMerchantAccountProviders = () => {
    return (
        <Container>
            <SubRouteSettingWrapper
                back="organization"
                routes={settingsMerchantAccountRoutes}
            />
        </Container>
    );
}

export default OrganizationSettingsMerchantAccountProviders