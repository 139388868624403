import { Form, Formik } from 'formik';
import AnalyticsModal from "../../analytics/AnalyticsModal";
import OverlayLoader from "../loading/OverlayLoader";
import { Alert, Button, Collapse, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { array, object, bool, string } from "yup";
import Translate from '@spordle/intl-elements';
import { useEffect, useContext, useState } from 'react';
import { OfficiatingCoachContext } from "../../contexts/OfficiatingCoachContext";
import { SupervisionsContext } from "../../contexts/SupervisionsContext";
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { FormikSelect, FormikSwitch } from '@spordle/formik-elements';
import { DisplayI18n } from '../../helpers/i18nHelper';
import { PeriodsContext } from '../../contexts/contexts';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import CrossFade from "../crossFade/CrossFade";
import { stringBuilder } from "@spordle/helpers";
import CustomAnimatedIcon from "../customAnimatedIcon/CustomAnimatedIcon";
import UserDisplay from "../userDisplay/UserDisplay";
import CanDoAction from '../permissions/CanDoAction';

const AssignOfficiatingPermissionsModal = (props) => {
    const assignMode = props.officiatingCoach ? "edit" : "create";

    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName="AssignCoachOfficiatingPermissions">
            <AssignOfficiatingPermissionsModalInner assignMode={assignMode} {...props} />
        </AnalyticsModal>
    );
}

const AssignOfficiatingPermissionsModalInner = ({ assignMode = "create", toggle, member, refreshMemberProfile, officiatingCoach = {} }) => {
    const { organisation_id } = useContext(OrganizationContext);
    const { selectedPeriod } = useContext(PeriodsContext);
    const { createMemberOfficiatingCoach, updateMemberOfficiatingCoach, deleteMemberOfficiatingCoach } = useContext(OfficiatingCoachContext);
    const { getSupervisionTypes } = useContext(SupervisionsContext);
    const [ showSuccess, setShowSuccess ] = useState(false);
    const [ showDelete, setShowDelete ] = useState(false);
    const [ types, setTypes ] = useState(null);

    const assignPermissionsCall = (mode, memberId, { officiating_coach_id, ...apiVal }) => {
        if(mode === "create"){
            return createMemberOfficiatingCoach(memberId, apiVal);
        }

        return updateMemberOfficiatingCoach(memberId, officiating_coach_id, apiVal);
    }

    const handleOnDelete = (memberId, officiating_coach_id, formik) => {
        formik.setSubmitting(true);
        deleteMemberOfficiatingCoach(memberId, officiating_coach_id)
            .then(() => {
                setShowDelete(true);
                refreshMemberProfile();
                formik.setSubmitting(false);
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                    formik.setSubmitting(false);
                }
            });
    }

    useEffect(() => {
        getSupervisionTypes({ active: 1, organisation_id })
            .then((types) => setTypes(types.map((type) => ({ ...type, label: type.name, value: type.supervision_type_id }))))
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                }
            })
    }, [])

    return (
        <Formik
            enableReinitialize
            initialStatus={{
                isVisible: false,
                msg: null,
            }}
            initialValues={{
                officiating_coach_id: officiatingCoach?.member_officiating_coach_id || "",
                national_officiating_coach: officiatingCoach?.national_officiating_coach == "1" || false,
                active: true,
                supervision_type_ids: officiatingCoach?.supervision_types?.map((sT) => sT.supervision_type_id) || [],
            }}
            validationSchema={object().shape({
                national_officiating_coach: bool(),
                active: bool(),
                supervision_type_ids: array().of(string()).min(1, <Translate id='components.assignOfficiatingPermissionsModal.supervisionTypes.validation' />),
            })}
            onSubmit={(values, { setStatus }) => {

                return assignPermissionsCall(
                    assignMode,
                    member.member_id,
                    {
                        ...values,
                        active: values.active ? 1 : 0,
                        national_officiating_coach: values.national_officiating_coach ? 1 : 0,
                        period_id: selectedPeriod.period_id,
                    },
                )
                    .then(() => {
                        refreshMemberProfile();
                        setShowSuccess(true);
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            setStatus({
                                isVisible: true,
                                msg: <DisplayI18n field="message" defaultValue={e.message} i18n={e.i18n} />,
                            });
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={toggle}>
                            <Translate id='components.assignOfficiatingPermissionsModal.title' />
                        </ModalHeader>
                        <CrossFade isVisible={!showSuccess && !showDelete}>
                            <ModalBody>
                                <p>
                                    <Translate id='components.assignOfficiatingPermissionsModal.msg' />
                                </p>
                                <Label className="text-muted" for="supervision_type_ids"><Translate id='components.assignOfficiatingPermissionsModal.supervisionTypes' /></Label>
                                <FormikSelect
                                    multi
                                    manualError={assignMode === "edit"}
                                    clearable
                                    search={false}
                                    id="supervision_type_ids"
                                    isLoading={types === null}
                                    name="supervision_type_ids"
                                    renderOption={({ option }) => (
                                        <DisplayI18n
                                            field="name"
                                            i18n={option.i18n}
                                            defaultValue={option.name}
                                        />
                                    )}
                                    options={types ?? []}
                                />
                                <div className="text-muted">
                                    <CanDoAction action="ADD" componentCode="members" componentPermissionCode="member_national_officiating_coach">
                                        <div className="d-flex align-items-center mt-3">
                                            <FormikSwitch id="national_officiating_coach" name="national_officiating_coach" />
                                            <Label check className="ml-2" for="national_officiating_coach">
                                                <Translate id='components.assignOfficiatingPermissionsModal.isNational' />
                                            </Label>
                                        </div>
                                    </CanDoAction>
                                </div>
                                <Collapse isOpen={assignMode == "edit" && formik.touched.supervision_type_ids && formik.errors.supervision_type_ids}>
                                    <Alert className="mt-3" color="danger">
                                        <div className="mr-2">
                                            <div className="font-bold h4 text-danger mb-2">
                                                <Translate id='components.assignOfficiatingPermissionsModal.delete.warning.title' />
                                            </div>
                                            <Translate id='components.assignOfficiatingPermissionsModal.delete.warning' />
                                        </div>
                                        <Button onClick={() => handleOnDelete(member.member_id, formik.values.officiating_coach_id, formik)} className="mt-2 ml-auto" size="sm" type="button" color="danger"><Translate id='components.assignOfficiatingPermissionsModal.removeAccess.btn' /></Button>
                                    </Alert>
                                </Collapse>
                                <Collapse isOpen={formik.status.isVisible}>
                                    <div className="mt-3">
                                        <Alert color="danger" toggle={() => formik.setStatus({ ...formik.status, isVisible: false })}>
                                            {formik.status.msg}
                                        </Alert>
                                    </div>
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                {assignMode === "edit" &&
                                    <Button onClick={() => handleOnDelete(member.member_id, formik.values.officiating_coach_id, formik)} className="mr-auto" type="button" color="secondary" outline>
                                        <Translate id='components.assignOfficiatingPermissionsModal.removeAccess.btn' />
                                    </Button>
                                }
                                <Button type="submit" color="primary" disabled={formik.touched.supervision_type_ids && formik.errors.supervision_type_ids && assignMode == "edit"}>
                                    <Translate id="misc.submit" />
                                </Button>
                                <Button type="button" color="primary" outline onClick={toggle}>
                                    <Translate id="misc.cancel" />
                                </Button>
                            </ModalFooter>
                        </CrossFade>
                        <CrossFade isVisible={showDelete}>
                            <ModalBody className="text-center">
                                <CustomAnimatedIcon withCircle className="text-success" size={50} icon="checkmark" />
                                <div className="h4 font-bold mt-2"><Translate id='components.assignOfficiatingPermissionsModal.success.delete' /></div>
                                <p><Translate id='components.assignOfficiatingPermissionsModal.success.delete.msg' values={{ name: `${member.first_name} ${member.last_name}` }} /></p>
                                <Button color="primary" type="button" onClick={toggle}>
                                    <Translate id="misc.close" />
                                </Button>
                            </ModalBody>
                        </CrossFade>
                        <CrossFade isVisible={showSuccess}>
                            <ModalBody className="text-center">
                                <CustomAnimatedIcon withCircle className="text-success" size={50} icon="checkmark" />
                                <div className="h4 font-bold mt-2">
                                    <Translate id="misc.success" />
                                </div>
                                <p>
                                    <Translate id={`components.assignOfficiatingPermissionsModal.success.msg.${assignMode}`} />
                                </p>
                                <div className="mb-3">
                                    {Array.isArray(types) &&
                                        types.map((type) => {
                                            const hasAccess = (formik.values.supervision_type_ids || []).includes(type.supervision_type_id);
                                            return (
                                                <UserDisplay className="mb-2" card key={type.supervision_type_id}>
                                                    <UserDisplay.Container>
                                                        <UserDisplay.Title>
                                                            <DisplayI18n
                                                                field="name"
                                                                i18n={type.i18n}
                                                                defaultValue={type.name}
                                                            />
                                                        </UserDisplay.Title>
                                                        <UserDisplay.Subtitle>
                                                            {hasAccess ?
                                                                <>
                                                                    <i className="mdi mdi-check text-primary mr-1" /><Translate id='components.assignOfficiatingPermissionsModal.canSupervise' />
                                                                </>
                                                                :
                                                                <>
                                                                    <i className="mdi mdi-close text-danger mr-1" /><Translate id='components.assignOfficiatingPermissionsModal.cannotSupervise' />
                                                                </>
                                                            }
                                                        </UserDisplay.Subtitle>
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container className="ml-auto">
                                                        <div className="border bg-light rounded-circle d-flex justify-content-center align-items-center" style={{ width: 38, height: 38 }}>
                                                            <i className={stringBuilder("mdi font-20 text-body", hasAccess ? "mdi-account-edit" : "mdi-account-off")} />
                                                        </div>
                                                    </UserDisplay.Container>
                                                </UserDisplay>
                                            )
                                        })
                                    }
                                </div>
                                <Button color="primary" type="button" onClick={toggle}>
                                    <Translate id="misc.close" />
                                </Button>
                            </ModalBody>
                        </CrossFade>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default AssignOfficiatingPermissionsModal;