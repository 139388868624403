import { FormikTextArea } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { Fragment, useContext } from "react";
import { useIntl } from "react-intl";
import { Button, Card, CardBody, CardHeader, Col, Label, Row } from "reactstrap";
import { object, string } from "yup";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import FormikApiError from "../../../../../../components/formik/FormikApiError";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from "../../../../../../helpers/i18nHelper";

const MemberFieldsForm = ({ memberFields, onSuccess, stopEditing }) => {
    const { partialUpdateMemberField } = useContext(OrganizationContext);
    const { getValidationSchema, getInitialValues, getAPIValues, ...i18nHelper } = useContext(I18nHelperContext);
    const { formatMessage } = useIntl();
    const { validationSchema, initialValues } = memberFields.reduce((formatted, field) => {
        formatted.validationSchema[field.code] = object().shape(getValidationSchema({ description: string() }));
        formatted.initialValues[field.code] = getInitialValues(field);

        return formatted;
    }, { validationSchema: {}, initialValues: {} });

    return (
        <Formik
            initialStatus={{
                showError: false,
                error: null,
            }}
            initialValues={initialValues}
            validationSchema={object().shape(validationSchema)}
            onSubmit={(values, { setStatus }) => {
                const valuesEntries = Object.entries(values);

                const valuesHaveChanged = (fieldCode, val) => {
                    const currentField = memberFields.find(({ code }) => code === fieldCode);

                    if(!currentField)return false;

                    const translatedFields = i18nHelper.props.fields;
                    const fieldsLangs = i18nHelper.state.languages;

                    return !(translatedFields.every((field) => {
                        const defaultIsSame = (currentField[field] || "") === (val[field] || "");

                        const i18nIsSame = fieldsLangs.every((lang) => {
                            const currentI18n = currentField.i18n?.[lang]?.[field];
                            const newI18n = val.i18n?.[lang]?.[field];

                            return (currentI18n || "") === (newI18n || "");
                        });

                        return defaultIsSame && i18nIsSame;
                    }));
                }

                return Promise.all(valuesEntries.map(([ code, val ]) => {
                    if(valuesHaveChanged(code, val)){
                        return partialUpdateMemberField(code, getAPIValues(val))
                    }

                    return Promise.resolve();
                }))
                    .then(() => {
                        return onSuccess();
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            const error = <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message || formatMessage({ id: "misc.error" })} />;
                            setStatus({ showError: true, error });
                        }
                    });
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <Row form>
                            {(memberFields || []).map((field) => (
                                <Col key={field.code} sm={6} className="mb-2">
                                    <Card className="card-shadow mb-0">
                                        <CardHeader className="text-dark bg-white font-weight-bold px-3 h5"><DisplayI18n field="name" defaultValue={field.name} i18n={field.i18n} /></CardHeader>
                                        <CardBody className="p-3">
                                            <RenderI18nForm field='description'>
                                                {({ fieldName, lang, index }) => (
                                                    <Fragment key={lang + fieldName}>
                                                        {i18nHelper.state.languages.length > 1 && <Label for={`${field.code}.${fieldName}`} className={stringBuilder({ "mt-3": index })}><Translate id={`misc.${lang}.long`} /></Label>}
                                                        <FormikTextArea
                                                            id={`${field.code}.${fieldName}`}
                                                            name={`${field.code}.${fieldName}`}
                                                            row={5}
                                                        />
                                                    </Fragment>
                                                )}
                                            </RenderI18nForm>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        <FormikApiError />
                        <footer className="text-right mt-3">
                            <Button disabled={formik.isSubmitting} type="submit" color="primary"><Translate id="misc.save" /></Button>
                            <Button disabled={formik.isSubmitting} type="button" color="primary" outline className="ml-2" onClick={stopEditing}><Translate id="misc.cancel" /></Button>
                        </footer>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default MemberFieldsForm;