import React, { useEffect, useState } from 'react';

/**
 * Injects a callback function `isMounted` as props to the given component
 * @param {React.ComponentType} Component
 * @returns {React.ComponentClass}
 */
export function withMountedState(Component){
    return class extends React.Component{

        _isMounted = false;

        componentWillUnmount(){
            this._isMounted = false;
        }

        componentDidMount(){
            this._isMounted = true;
        }

        render(){
            return <Component {...this.props} isMounted={() => this._isMounted} />
        }
    }
}

/**
 * Custom hook to check if the component is mounted or not
 * @returns {() => boolean} The mounting state of the component
 */
export function useMountedState(){
    const [ isMounted, setIsMounted ] = useState(false);
    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        }
    }, [])
    return isMounted;
}