import * as Sentry from "@sentry/react";
import Translate from '@spordle/intl-elements';
import React from 'react';
import {
    ModalHeader,
    TabContent,
    TabPane
} from "reactstrap";
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import ManualRegistrationForms from '../../../../../components/manualRegistration/ManualRegistrationForms';
import ManualRegistrationPaymentMethods from '../../../../../components/manualRegistration/ManualRegistrationPaymentMethods';
import ManualRegistrationWaivers from '../../../../../components/manualRegistration/ManualRegistrationWaivers';
import ClinicProfileRegistrationAdd1 from './components/ClinicProfileRegistrationAdd1';
import ClinicProfileRegistrationAdd2 from './components/ClinicProfileRegistrationAdd2';
import { fail } from '@spordle/toasts';
import ClinicProfileRegistrationAdd4 from './components/ClinicProfileRegistrationAdd4';
import ManualRegistrationMerchantAccounts from "../../../../../components/manualRegistration/ManualRegistrationMerchantAccounts";
import ManualRegistrationMemberIdentities from "../../../../../components/manualRegistration/ManualRegistrationMemberIdentities";

// contexts
import { CartsContext } from '../../../../../contexts/CartsContext';
import { ClinicsContext } from '../../../../../contexts/ClinicsContext';
import { UtilsContext } from '../../../../../contexts/UtilsContext';
import { MerchantAccountContext } from '../../../../../contexts/MerchantAccountContext';
import withContexts from '../../../../../helpers/withContexts';
import ClinicProfileRegistrationAddExchangeWarning from "./components/ClinicProfileRegistrationAddExchangeWarning";
import ManualRegistrationConvertInvoiceConfirm from "../../../../../components/manualRegistration/ManualRegistrationConvertInvoiceConfirm";
import { MembersContext } from "../../../../../contexts/MembersContext";
import ClinicProfileRegistrationAddExchange from "./components/ClinicProfileRegistrationAddExchange";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import ManualRegistrationWaitingListReturn from "../../../../../components/manualRegistration/ManualRegistrationWaitingListReturn";
import ManualRegistrationWaitingListInfo from "../../../../../components/manualRegistration/ManualRegistrationWaitingListInfo";
import ManualRegistrationWaitingListConfirmation from "../../../../../components/manualRegistration/ManualRegistrationWaitingListConfirmation";
import ManualRegistrationWaitingListReturnConfirmation from "../../../../../components/manualRegistration/ManualRegistrationWaitingListReturnConfirmation";

class ClinicProfileRegistrationAdd extends React.Component{
    constructor(props){
        super(props);

        this.convertMode = !!this.props.invoiceNumber;
        this.exchangeMode = !!this.props.invoiceItemId;
        this.convertToTransactional = this.props.convertToTransactional;

        // waiting list
        this.waitingListMode = false;
        this.waitingListReturnMode = !!this.props.waitingListNumber;
        this.waitingListReturnManual = false,

        this.state = {
            activeTab: this.convertMode ? this.convertToTransactional ? 4 : 3 : this.exchangeMode ? -1 : this.waitingListReturnMode ? 'waitingListReturn' : 1,
            isLoading: false,
            invoiceNumber: "",
            totalAmount: props.ClinicsContext.currentClinic.total_amount,
            customFormsId: props.ClinicsContext.currentClinic.custom_form?.[0]?.custom_form_id,
            customForms: null,
            waivers: null,
            member: this.props.member || null,
            paymentMethods: null,
            selectedPaymentMethod: '',
            merchantAccounts: null,
            selectedMerchantAccount: '',
            memberIdentities: null,
            selectedIdentity: '',
            selectedIdentityName: '',
            shoppingCartId: '',
            invoiceDate: '',

            // waiting list
            waitingListNumber: this.waitingListReturnMode ? this.props.waitingListNumber : '',

            isFinal: false, // should be set to true when the cart is done, used to refresh the tables
        }
    }

    componentDidMount(){
        this.props.UtilsContext.getPaymentMethods()
            .then((_paymentMethods) => {
                this.setState(() => ({
                    paymentMethods: _paymentMethods.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order)),
                }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })

        // Get all merchant accounts for pre-init
        this.props.MerchantAccountContext.getMerchantAccounts({ active: 1 })
            .then((merchantAccounts) => {
                this.setState(() => ({
                    merchantAccounts: merchantAccounts,
                }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })

        if(this.state.member?.member_id){
            this.props.MembersContext.getMemberAccounts(this.state.member.member_id)
                .then((identities) => {
                    this.setState(() => ({
                        memberIdentities: identities,
                    }))
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.isOpen !== this.props.isOpen){
            Sentry.addBreadcrumb({
                type: 'info',
                message: `${this.props.isOpen ? 'Opening' : 'Closing'} manual registration modal`,
                level: Sentry.Severity.Log,
                category: 'RegistrationModal',
                data: {
                    activeTab: this.state.activeTab,
                },
            })
        }else if(prevState.activeTab !== this.state.activeTab){
            Sentry.addBreadcrumb({
                type: 'info',
                message: 'Active tab changed',
                level: Sentry.Severity.Log,
                category: 'RegistrationModal',
                data: {
                    activeTab: this.state.activeTab,
                },
            })
        }
    }

    setActiveTab = (tab) => {
        this.setState((prevState) => {
            if(parseInt(tab) === 1 && prevState.activeTab > 1) // Coming back to step one needs to clear the cart
                this.props.CartsContext.clearCarts().catch(console.error);
            return { activeTab: tab }
        });
    }

    setModalLoading = (toggle) => {
        this.setState(() => ({ isLoading: toggle }));
    }

    setShoppingCartId = (id, activeTab = 2) => {
        this.setState(() => ({ shoppingCartId: id, activeTab: activeTab }));
    }

    setCustomForms = (forms) => {
        this.setState(() => ({ customForms: forms }));
    }

    setCustomFormsId = (id) => {
        this.setState(() => ({ customFormsId: id }));
    }

    setTotalAmount = (amount) => {
        this.setState(() => ({ totalAmount: amount }));
    }

    setWaivers = (waivers) => {
        this.setState(() => ({ waivers: waivers }));
    }

    setMemberIdentities = (memberIdentities) => {
        this.setState(() => ({ memberIdentities: memberIdentities }));
    }

    setMember = (member) => {
        this.setState(() => ({ member: member }))
    }

    setFinal = (isFinal) => {
        this.setState(() => ({ isFinal: isFinal }))
    }

    waitingListReturnOnSubmit = (manual) => {
        this.waitingListReturnMode = true;
        this.waitingListReturnManual = manual;
        if(manual){
            return this.props.CartsContext.createCart({ item: {
                item_id: this.props.ClinicsContext.currentClinic.clinic_id,
                type: "CLINIC",
                member_id: this.state.member.member_id,
                waiting_list_item_id: this.state.waitingListNumber,
            } })
                .then(async(shopping_cart_id) => {
                    this.setShoppingCartId(shopping_cart_id);
                })
        }

        this.setActiveTab(7);
        return Promise.resolve();
    }

    waitingListInfoOnSubmit = () => {
        this.waitingListMode = true;
        return this.checkout();
    }

    paymentMethodsOnSubmit = (paymentMethod) => {
        this.setState(() => ({ selectedPaymentMethod: paymentMethod }))

        if(this.convertMode && !this.convertToTransactional){
            this.setActiveTab(9);
        }else if(paymentMethod !== 'CREDITCARD'){
            if(this.state.customForms){
                this.setActiveTab(5)
            }else if(this.state.waivers){
                this.setActiveTab(6)
            }else{
                this.checkout(this.props.CartsContext.state.cachedCart.shopping_cart_id, paymentMethod).catch(console.error)
            }
        }else{
            this.setActiveTab(4);
        }
    }

    merchantAccountsOnSubmit = (merchantAccount) => {
        this.setState(() => ({
            selectedMerchantAccount: merchantAccount,
        }))
        if(this.convertMode){
            this.setActiveTab(7);
        }else if(this.state.customForms){
            this.setActiveTab(5);
        }else if(this.state.waivers){
            this.setActiveTab(6);
        }else if(this.state.selectedPaymentMethod === 'CREDITCARD'){
            this.setActiveTab(7);
        }else{
            this.checkout().catch(console.error)
        }
    }

    formsOnSubmit = (formattedFields, setStatus) => {
        this.props.CartsContext.updateCartItemForm(this.props.CartsContext.state.cachedCart.shopping_cart_id, this.state.customForms.rowId, {
            custom_form_id: this.state.customForms.customFormId,
            fields: formattedFields,
        })
            .then(async() => {
                if(this.state.waivers){
                    this.setActiveTab(6)
                }else if(this.state.selectedPaymentMethod === 'CREDITCARD'){
                    this.setActiveTab(7);
                }else{
                    await this.checkout()
                        .finally(() => this.setModalLoading(false))
                }
                this.setModalLoading(false)
            })
            .catch((error) => {
                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                console.error(error.message)
                this.setModalLoading(false)
            })
    }

    waiversOnSubmit = (formattedWaivers, setStatus, activeWaiverIndex, showNextWaiver) => {
        this.setModalLoading(true)
        this.props.CartsContext.updateCartItemWaiver(
            this.props.CartsContext.state.cachedCart.shopping_cart_id,
            this.state.waivers.find((w) => w.waiver_id === formattedWaivers.waiver_id)?.row_id,
            formattedWaivers,
        )
            .then(async() => {
                if(activeWaiverIndex + 1 === this.state.waivers.length){
                    if(this.state.selectedPaymentMethod === 'CREDITCARD'){
                        this.setActiveTab(7)
                    }else{
                        await this.checkout()
                            .finally(() => this.setModalLoading(false))
                    }
                    this.setModalLoading(false)
                }else{
                    this.setModalLoading(false)
                    showNextWaiver()
                }
            })
            .catch((error) => {
                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                this.setModalLoading(false)
                console.error(error.message)
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
            })
    }

    memberIdentityOnSubmit = async(identityId, setStatus, name) => {
        this.setState(() => ({
            selectedIdentityName: name,
            selectedIdentity: identityId,
        }))
        if(this.convertMode){
            this.setActiveTab(9);
        }else if(this.waitingListReturnMode && !this.waitingListReturnManual){
            this.setActiveTab('waitingListReturnConfirmation')
        }else{
            this.setModalLoading(true)
            return this.checkout()
                .catch((e) => {
                    if(!AxiosIsCancelled(e.message)){
                        console.error(e)
                        setStatus(<DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />)
                        fail();
                    }
                })
                .finally(() => this.setModalLoading(false))
        }
    }

    // checkout = (shoppingCartId, paymentMethod, invoiceDate) => {
    checkout = (shoppingCartId, paymentMethod, invoiceDate, merchantAccount, identityId) => {
        const values = {
            payment_type: paymentMethod || this.state.selectedPaymentMethod,
            invoice_date: invoiceDate || this.state.invoiceDate,
            merchant_account_id: merchantAccount || this.state.selectedMerchantAccount,
            identity_id: identityId || this.state.selectedIdentity,
        }
        this.setModalLoading(true)
        return this.props.CartsContext.checkoutCart(shoppingCartId || this.state.shoppingCartId, !this.waitingListMode ? values : {})
            .then((invoiceData) => {
                if(invoiceData.invoice_number){
                    this.setState(() => ({ activeTab: 8, invoiceNumber: invoiceData.invoice_number }));
                    this.setModalLoading(false)
                }else if(invoiceData.waiting_list_number){
                    this.setState(() => ({ activeTab: 'waitingListConfirmation', waitingListNumber: invoiceData.waiting_list_number }));
                    this.setModalLoading(false)
                }
                this.setFinal(true)
                return true
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });
    }

    convert = () => {
        return this.props.CartsContext.convert(this.props.invoiceNumber, {
            identity_id: this.state.selectedIdentity,
            merchant_account_id: this.state.selectedMerchantAccount,
        })
            .then(() => {
                this.setState(() => ({ invoiceNumber: this.props.invoiceNumber }), () => this.toggle())
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    convertToManual = () => {
        return this.props.CartsContext.convertToManual(this.props.invoiceNumber, {
            payment_method: this.state.selectedPaymentMethod,
        })
            .then(() => {
                this.setState(() => ({ invoiceNumber: this.props.invoiceNumber }), () => this.toggle())
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    toggle = async() => {
        if(this.state.isFinal){ // we don't want to refresh the table if nothing changed
            await this.props.refreshTable()
            this.props.waitlistTable?.current?.refreshTable();

            this.props.closeSidepanel?.();
        }
        this.props.toggle()
    }

    render(){
        return (
            <AnalyticsModal isOpen={this.props.isOpen} unmountOnClose analyticsName='ClinicProfileRegistrationAdd'>
                <OverlayLoader isLoading={this.state.isLoading}>
                    <ModalHeader
                        tag="div"
                        toggle={!this.exchangeMode ?
                            this.toggle
                            :
                            undefined
                        }
                    >
                        <Translate id={this.convertMode ? 'components.manualRegistration.convert' : 'clinics.profile.attendees.addModal.memberSelect.title'} />
                    </ModalHeader>

                    <TabContent activeTab={this.state.activeTab}>
                        {/* Waiting list return */}
                        <TabPane tabId='waitingListReturn'>
                            {this.state.activeTab === 'waitingListReturn' &&
                                <ManualRegistrationWaitingListReturn
                                    member={this.state.member}
                                    onSubmit={this.waitingListReturnOnSubmit}
                                    setModalLoading={this.setModalLoading}
                                />
                            }
                        </TabPane>

                        {/* Exchange warning */}
                        <TabPane tabId={-1}>
                            <ClinicProfileRegistrationAddExchangeWarning
                                onSubmit={() => this.setActiveTab(0)}
                                toggle={() => this.props.toggle()}
                            />
                        </TabPane>

                        {/* Exchange */}
                        <TabPane tabId={0}>
                            {this.state.activeTab == 0 &&
                                    <ClinicProfileRegistrationAddExchange
                                        toggle={() => this.props.toggle()}
                                        setCustomForms={this.setCustomFormsId}
                                        setTotalAmount={this.setTotalAmount}
                                        setShoppingCartId={this.setShoppingCartId}
                                        setModalLoading={this.setModalLoading}
                                        setPaymentMethod={(p) => this.setState(() => ({ selectedPaymentMethod: p }))}
                                        onSubmit={() => this.setActiveTab(2)}
                                        goToPrevious={() => this.setActiveTab(-1)}
                                        setMember={this.setMember}
                                        member={this.state.member}
                                        invoiceItemId={this.props.invoiceItemId}
                                        currentClinicId={this.props.currentClinicId}
                                    />
                            }
                        </TabPane>

                        {/* Registration */}
                        <TabPane tabId={1}>
                            {this.state.activeTab == '1' &&
                                <ClinicProfileRegistrationAdd1
                                    setActiveTab={this.setActiveTab}
                                    setModalLoading={this.setModalLoading}
                                    setMember={this.setMember}
                                    setShoppingCartId={this.setShoppingCartId}
                                />
                            }
                        </TabPane>

                        {/* Waiting list info */}
                        <TabPane tabId='waitingListInfo'>
                            {this.state.activeTab === 'waitingListInfo' &&
                                <ManualRegistrationWaitingListInfo
                                    goToPrevious={() => this.setActiveTab(1)}
                                    setModalLoading={this.setModalLoading}
                                    onSubmit={this.waitingListInfoOnSubmit}
                                    toggle={() => this.props.toggle()}
                                />
                            }
                        </TabPane>

                        {/* Registration Date / Time */}
                        <TabPane tabId={2}>
                            {this.state.activeTab == '2' &&
                                    <ClinicProfileRegistrationAdd2
                                        setActiveTab={this.setActiveTab}
                                        goToPrevious={this.exchangeMode ? undefined : this.waitingListReturnMode ? () => this.setActiveTab('waitingListReturn') : () => this.setActiveTab(1)}
                                        member={this.state.member}
                                        shoppingCartId={this.state.shoppingCartId}
                                        invoiceDate={this.state.invoiceDate}
                                        setInvoiceDate={(invoiceDate) => { this.setState({ invoiceDate: invoiceDate }) }}
                                        checkout={this.checkout}
                                        setModalLoading={this.setModalLoading}
                                        getPaymentMethods={() => this.state.paymentMethods}
                                        getMerchantAccounts={() => this.state.merchantAccounts}
                                        setSelectedPaymentMethod={(pMethod) => this.setState(() => ({ selectedPaymentMethod: pMethod }))}
                                        setCustomForms={this.setCustomForms}
                                        setWaivers={this.setWaivers}
                                        setMemberIdentities={this.setMemberIdentities}
                                        // we need to make it so this component does not use the ClinicsContext, because the clinic package change
                                        // makes it so the user needs to sign waivers and fill up forms from another clinic, and not the one stored in the context
                                        formsId={this.state.customFormsId || null}
                                        totalAmount={this.state.totalAmount || null}
                                    />
                            }
                        </TabPane>

                        {/* Payment methods */}
                        <TabPane tabId={3}>
                            {this.state.paymentMethods &&
                                    <ManualRegistrationPaymentMethods
                                        paymentMethods={this.state.paymentMethods}
                                        selectedPaymentMethod={this.state.selectedPaymentMethod}
                                        goToPrevious={() => this.setActiveTab(2)}
                                        activateLoading={() => this.setModalLoading(true)}
                                        onSubmit={this.paymentMethodsOnSubmit}
                                        creditCardDisabled={this.state.merchantAccounts?.length <= 0 || (!this.convertToTransactional && this.convertMode)}
                                    />
                            }
                        </TabPane>
                        {/* merchant accounts */}
                        <TabPane tabId={4}>
                            {this.state.activeTab == '4' &&
                                    <ManualRegistrationMerchantAccounts
                                        merchantAccounts={this.state.merchantAccounts}
                                        selectedMerchantAccount={this.state.selectedMerchantAccount}
                                        hidePreviousButton={this.convertMode}
                                        goToPrevious={() => this.setActiveTab(3)} // no need for skip logic because the user needs to be in the payment methods to get to the merchant accounts view
                                        onSubmit={this.merchantAccountsOnSubmit}
                                    />
                            }
                        </TabPane>
                        {/* Forms */}
                        <TabPane tabId={5}>
                            {this.state.customForms &&
                                    <ManualRegistrationForms
                                        customForms={this.state.customForms}
                                        goToPrevious={() => this.setActiveTab(
                                            (this.state.merchantAccounts && this.state.selectedPaymentMethod === 'CREDITCARD') ?
                                                4
                                                : (this.state.paymentMethods && this.props.ClinicsContext.currentClinic.total_amount > 0) ?
                                                    3
                                                    :
                                                    2,
                                        )}
                                        activateLoading={() => this.setModalLoading(true)}
                                        onSubmit={this.formsOnSubmit}
                                    />
                            }
                        </TabPane>
                        {/* Waivers */}
                        <TabPane tabId={6}>
                            {this.state.waivers &&
                                    <ManualRegistrationWaivers
                                        waivers={this.state.waivers}
                                        goToPrevious={() => this.setActiveTab(
                                            this.state.customForms ?
                                                5
                                                : (this.state.merchantAccounts && this.state.selectedPaymentMethod === 'CREDITCARD') ?
                                                    4
                                                    : (this.state.paymentMethods && this.props.ClinicsContext.currentClinic.total_amount > 0) ?
                                                        3
                                                        :
                                                        2,
                                        )}
                                        onSubmit={this.waiversOnSubmit}
                                    />
                            }
                        </TabPane>
                        {/* Identities */}
                        <TabPane tabId={7}>
                            {this.state.activeTab == '7' && (this.convertMode || this.waitingListReturnMode ? true : this.state.selectedPaymentMethod === 'CREDITCARD') &&
                                    <ManualRegistrationMemberIdentities
                                        memberIdentities={this.state.memberIdentities}
                                        selectedIdentity={this.state.selectedIdentity}
                                        member={this.state.member}
                                        goToPrevious={() => {
                                            if(this.convertMode){
                                                this.setActiveTab(4);
                                            }else if(this.waitingListReturnMode && !this.waitingListReturnManual){
                                                this.setActiveTab('waitingListReturn');
                                            }else{
                                                this.setActiveTab(
                                                    this.state.waivers ?
                                                        6
                                                        : this.state.customForms ?
                                                            5
                                                            : (this.state.merchantAccounts && this.state.selectedPaymentMethod === 'CREDITCARD') ?
                                                                4
                                                                : (this.state.paymentMethods && this.props.ClinicsContext.currentClinic.total_amount > 0) ?
                                                                    3
                                                                    :
                                                                    2,
                                                )
                                            }
                                        }}
                                        onSubmit={this.memberIdentityOnSubmit}
                                    />
                            }
                        </TabPane>
                        {/* Confirmation */}
                        <TabPane tabId={8}>
                            <ClinicProfileRegistrationAdd4
                                member={this.state.member}
                                invoiceNumber={this.state.invoiceNumber}
                                toggle={this.toggle}
                            />
                        </TabPane>

                        {/* Waiting list confirmation */}
                        <TabPane tabId='waitingListConfirmation'>
                            {this.state.activeTab === 'waitingListConfirmation' &&
                                <ManualRegistrationWaitingListConfirmation
                                    member={this.state.member}
                                    waitingListNumber={this.state.waitingListNumber}
                                    toggle={this.toggle}
                                />
                            }
                        </TabPane>

                        {/* Waiting list return confirmation */}
                        <TabPane tabId='waitingListReturnConfirmation'>
                            {this.state.activeTab === 'waitingListReturnConfirmation' &&
                                <ManualRegistrationWaitingListReturnConfirmation
                                    selectedIdentityName={this.state.selectedIdentityName}
                                    selectedIdentityId={this.state.selectedIdentity}
                                    waitingListNumber={this.state.waitingListNumber}
                                    setModalLoading={this.setModalLoading}
                                    waitlistTable={this.props.waitlistTable}
                                    toggle={this.toggle}
                                    setFinal={this.setFinal}
                                />
                            }
                        </TabPane>

                        {/* Convert invoice confirmation */}
                        {this.state.activeTab === 9 &&
                            <TabPane tabId={9}>
                                <ManualRegistrationConvertInvoiceConfirm
                                    toggle={this.props.toggle}
                                    convert={this.convertToTransactional ? this.convert : this.convertToManual}
                                    goToPrevious={() => this.convertToTransactional ? this.setActiveTab(7) : this.setActiveTab(3)}
                                    selectedMerchantAccount={this.state.merchantAccounts.find((m) => m.merchant_account_id === this.state.selectedMerchantAccount)?.name}
                                    selectedIdentity={this.state.selectedIdentityName}
                                    selectedPaymentMethod={this.state.selectedPaymentMethod}
                                    member={`${this.props.MembersContext.currentMember.first_name} ${this.props.MembersContext.currentMember.last_name}`}
                                    invoiceNumber={this.props.invoiceNumber}
                                    convertToTransactional={this.convertToTransactional}
                                />
                            </TabPane>
                        }
                    </TabContent>
                </OverlayLoader>
            </AnalyticsModal>
        );
    }
}

export default withContexts(CartsContext, ClinicsContext, UtilsContext, MerchantAccountContext, MembersContext)(ClinicProfileRegistrationAdd);