import Translate from "@spordle/intl-elements"
import { fail } from "@spordle/toasts"
import { useContext } from "react"
import { Button, ModalBody, ModalFooter } from "reactstrap"
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI"
import CustomAnimatedIcon from "../../../../../components/customAnimatedIcon/CustomAnimatedIcon"
import { TeamsContext } from "../../../../../contexts/TeamsContext"
import { DisplayI18n } from "../../../../../helpers/i18nHelper"
import SignWaiversModalHeader from './SignWaiversModalHeader'

const SignWaiversModalConfirmation = ({ toggle, teamId }) => {
    const teamsContext = useContext(TeamsContext)

    const closeModal = () => {
        teamsContext.getRosterTablesData(teamId)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error);
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        toggle();
    }

    return (
        <>
            <SignWaiversModalHeader toggle={closeModal} />
            <ModalBody className="text-center">
                <CustomAnimatedIcon
                    icon='checkmark'
                    size={75}
                    withCircle
                    className='text-success mb-3'
                />
                <div className="h4 font-bold mb-2"><Translate id='teams.profile.header.signWaivers.confirmation' /></div>
            </ModalBody>
            <ModalFooter className="justify-content-center">
                <Button color='primary' type="button" onClick={closeModal}>
                    <Translate id='misc.close' />
                </Button>
            </ModalFooter>
        </>
    )
}

export default SignWaiversModalConfirmation