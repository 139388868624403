import Translate from "@spordle/intl-elements";
import React, { useCallback, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "reactstrap";
import { AppContext } from "../../../../../contexts/contexts";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { displayI18n, DisplayI18n } from "../../../../../helpers/i18nHelper";
import SidebarLink from "../sidebarLink/SidebarLink";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { stringBuilder } from "@spordle/helpers";
import { useDisclosure } from "@mantine/hooks";

const HistoryLinks = () => {
    const [ searchInput, setSearchInput ] = useState("");
    const [ isOpen, { toggle, close } ] = useDisclosure();
    const { formatMessage } = useIntl();
    const { getGenericLocale } = useContext(I18nContext);
    const { getAllRecentLinks, emptyRecentLinks } = useContext(AppContext);
    const allRecents = getAllRecentLinks();
    const filteredRecents = allRecents.filter((recent) => {
        const recentVal = recent.translate ? formatMessage({ id: recent.label }) : (recent.i18n ? displayI18n("label", recent.i18n, recent.label, getGenericLocale()) : recent.label);
        return ((recentVal || "").toLowerCase()).includes(searchInput.toLowerCase());
    });

    const handleOnSearch = useCallback((e) => {
        setSearchInput(e.target.value);
    }, []);

    const handleOnEmptySearch = () => {
        setSearchInput("");
    }

    const handleOnClear = () => {
        close();
        emptyRecentLinks();
    }

    return (
        allRecents.length > 0 &&
            <Dropdown tag="li" isOpen={isOpen} toggle={toggle}>
                <div className="sidebar-item">
                    <DropdownToggle type="button" tag="button" className={stringBuilder(`sidebar-link w-100 border-0`, { "bg-light": isOpen })}>
                        <span style={{ width: 25, fontSize: 16 }} className="text-dark text-center mdi mdi-history" /><Translate id="sidebar.history" /> <span className={`mdi mdi-chevron-${isOpen ? "down" : "right"} ml-auto`} />
                    </DropdownToggle>
                </div>
                <DropdownMenu className="w-100 max-w-100">
                    <DropdownItem header className="d-flex">
                        <Translate id="newnav.recentlyViewedHistory" /> <button onClick={handleOnClear} className="ml-auto reset-btn text-primary" type="button"><Translate id="misc.clear" /></button>
                    </DropdownItem>
                    <div className="px-3 pb-3 border-bottom">
                        <div className="position-relative">
                            <Input value={searchInput} onChange={handleOnSearch} bsSize="sm" placeholder={`${formatMessage({ id: "misc.search" })}...`} type="search" />
                            {!!searchInput && <button className="text-primary reset-btn position-absolute right-0 top-0 bottom-0 d-flex flex-center mr-2" type="button" onClick={handleOnEmptySearch}><i className="line-height-0 mdi mdi-close" /></button>}
                        </div>
                    </div>
                    <div className="p-2">
                        <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: 300 }}>
                            {filteredRecents.length > 0 ?
                                <ul className="list-unstyled">
                                    {filteredRecents
                                        .map((recent, key) => (
                                            <SidebarLink
                                                skipActive
                                                skipFavorite
                                                onClick={close}
                                                className="w-100 rounded-0 px-0"
                                                to={recent.path}
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={`${recent.label}-${recent.path}-${key}`}
                                            >
                                                <div className="w-100 min-w-0 pr-1 font-normal">
                                                    <div className="text-truncate py-1 my-n1">
                                                        {
                                                            recent.translate ?
                                                                <Translate id={recent.label} />
                                                                :
                                                                recent.i18n ?
                                                                    <DisplayI18n field="label" defaultValue={recent.label} i18n={recent.i18n} />
                                                                    :
                                                                    recent.label
                                                        }
                                                    </div>
                                                    {(
                                                        !!recent.subtitle
                                                        || (Array.isArray(recent.breadcrumbs) && recent.breadcrumbs.length > 0)
                                                    ) &&
                                                        <div className="w-100 small text-truncate text-muted py-1 my-n1">
                                                            {recent.subtitle ?
                                                                recent.subtitle
                                                                :
                                                                recent.breadcrumbs.map((crumb, index) => (
                                                                    // eslint-disable-next-line react/no-array-index-key
                                                                    <React.Fragment key={recent.path + crumb.name + index}>
                                                                        {index === 0 ? "" : " / "}
                                                                        {crumb.skipTranslate ? crumb.name : <Translate id={crumb.name} />}
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </SidebarLink>
                                        ))}
                                </ul>
                                :
                                <div className="text-center font-normal p-3">
                                    <i className="d-block mb-1 mdi-book-open-page-variant mdi fa-3x" />
                                    <Translate id="misc.noResults" />
                                </div>

                            }
                        </PerfectScrollbar>
                    </div>
                </DropdownMenu>
            </Dropdown>
    )
}

export default HistoryLinks;