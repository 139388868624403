import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import OverlayLoader from "../../../components/loading/OverlayLoader";
import UserImg from "../../../components/UserImg";
import { IdentityRolesContext, localStorageIdentityRoleId } from "../../../contexts/IdentityRolesContext";
import { OrganizationContext, localStorageOrganizationId } from "../../../contexts/OrganizationContext";
import TripleArrow from '../../../assets/images/Triple_Arrow.png';
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import { RolesContext } from "../../../contexts/RolesContext";
import { setLocalStorageItem, setSessionStorageItem } from "../../../helpers/browserStorage";
import AnalyticsModal from "../../../analytics/AnalyticsModal";
import Skeleton from "react-loading-skeleton";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import { AppContext } from "../../../contexts/contexts";

const ChangeOrgModal = (props) => (
    <AnalyticsModal unmountOnClose isOpen={props.isOpen} analyticsName='ChangeOrgModal'>
        {props.selected && <ChangeOrgInner {...props} />}
    </AnalyticsModal>
)

const ChangeOrgInner = ({ setRecentOrg, onConfirm, selected, toggle, selectedIsLoading, fromChangeOrgPage, ...props }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const orgContext = useContext(OrganizationContext);
    const idRoleContext = useContext(IdentityRolesContext);
    const rolesContext = useContext(RolesContext);
    const { updateRouteKey, addRecentOrg } = useContext(AppContext);

    const reduceIdentityRolesContext = () => {
        return {
            identity_role_id: idRoleContext.identity_role_id,
            role: idRoleContext.role,
            organisation: idRoleContext.organisation,
        }
    }
    const reduceOrganizationContext = () => {
        return {
            organisation_id: orgContext.organisation_id,
            organisation_name: orgContext.organisation_name,
            i18n: orgContext.i18n,
            logo: orgContext.logo,
            abbreviation: orgContext.abbreviation,
        }
    }

    const handleSubmit = () => {
        setIsLoading(true);
        setSessionStorageItem('initializedNews', 0);
        getChangeMethod()
            .then(async(newRole) => {
                await addRecentOrg(reduceIdentityRolesContext(), reduceOrganizationContext())
                setRecentOrg();
                if(!fromChangeOrgPage){
                    updateRouteKey();
                }
                toggle();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error);
                    setIsLoading(false);
                }
            });
    }

    const getChangeMethod = () => {
        if(idRoleContext.identity_role_id === selected.identityRole.identity_role_id){
            return changeOrg()
        }
        return changeIdRole()
    }

    const changeIdRole = async() => {
        // ** no need to do the getRolesPermissions anymore, getIdentityRole returns all components data now
        return idRoleContext.getIdentityRole(selected.identityRole.identity_role_id)
            .then(async(idRole) => {
                idRole.identity.power_user = idRoleContext.identity.power_user;
                await idRoleContext.setCurrentIdentityRole(idRole);

                const selectedOrgId = selected.org?.organisation_id ?? idRole.organisation.organisation_id
                await orgContext.setCurrentOrg(selectedOrgId),
                idRoleContext.setCurrentIdentityRole(idRole);
                rolesContext.setCurrentRole({ // ** spread the needed role data that is returned with the identity role now
                    ...idRole.role,
                    organisation_id: idRole.organisation.organisation_id,
                    components: idRole.components,
                })

                onConfirm?.(selectedOrgId);
                setLocalStorageItem(localStorageOrganizationId, selectedOrgId);
                setLocalStorageItem(localStorageIdentityRoleId, idRole.identity_role_id);

                // avoids issues with state
                return { orgName: selected?.org?.name ?? idRole?.organisation?.organisation_name, role: idRole.role.title };
            })
            .catch((error) => { console.error(error.message) });
    }

    const changeOrg = async() => {
        return orgContext.setCurrentOrg(selected.org?.organisation_id)
            .then((orgId) => {
                setLocalStorageItem(localStorageOrganizationId, selected.org?.organisation_id)
                onConfirm?.(orgId);

                // avoids issues with state
                return { orgName: selected.org?.organisation_name, role: idRoleContext.role.title };
            })
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalHeader toggle={toggle}>
                <Translate id='organization.changeOrganization.header.title' />
            </ModalHeader>
            <ModalBody>
                <div className="d-flex justify-content-around">
                    <div className="w-50 text-center">
                        <UserImg
                            className="mx-auto mb-3"
                            width={70}
                            abbr={orgContext.abbr}
                            src={orgContext.logo?.full_path}
                            filePos={orgContext.logo?.file_position}
                            alt={orgContext.organisation_name}
                        />
                        <div className="h6 font-medium"><DisplayI18n field='name' defaultValue={orgContext.organisation_name} i18n={orgContext.i18n} /></div>
                        <div className="text-muted small">{idRoleContext.role.title}</div>
                        <div className="text-muted small"><DisplayI18n field='name' defaultValue={idRoleContext.organisation.organisation_name} i18n={idRoleContext.organisation.i18n} /></div>
                    </div>

                    <div className="flex-shrink-0 px-3 align-self-center">
                        <img width="30" src={TripleArrow} />
                    </div>
                    {selectedIsLoading ?
                        <div className="w-50 text-center">
                            <Skeleton circle width={70} height={70} />
                            <Skeleton className='h6 font-medium mt-2' width={150} height={11} />
                            <Skeleton style={{ lineHeight: 1 }} className='small' width={150} height={9} />
                            <Skeleton style={{ lineHeight: 1 }} className='small' width={150} height={9} />
                        </div>
                        :
                        <div className="w-50 text-center">
                            <UserImg
                                className="mx-auto mb-3"
                                width={70}
                                abbr={selected.org.abbreviation}
                                src={selected.org.logo?.full_path}
                                filePos={selected.org.logo?.file_position}
                                alt={selected.org.organisation_name + ' logo'}
                            />
                            <div className="h6 font-medium"><DisplayI18n field='name' defaultValue={selected.org.organisation_name} i18n={selected.org.i18n} /></div>
                            <div className="text-muted small">{selected.identityRole.role.title}</div>
                            <div className="text-muted small"><DisplayI18n field='name' defaultValue={selected.identityRole.organisation.organisation_name} i18n={selected.identityRole.organisation.i18n} /></div>
                        </div>
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="primary" onClick={handleSubmit}><Translate id='organization.changeOrganization.swal.Ok' /></Button>
                <Button type="button" color="primary" outline onClick={toggle}><Translate id='organization.changeOrganization.swal.Cancel' /></Button>
            </ModalFooter>
        </OverlayLoader>
    )
}

export default ChangeOrgModal;