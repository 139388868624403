import ClinicItems from '../../views/catalog/clinicItems/ClinicItems';
import Forms from '../../views/catalog/forms/Forms';
import RegistrationFees from '../../views/catalog/registrationFees/RegistrationFees';
import OtherItems from '../../views/catalog/otherItems/OtherItems';
import AffiliationFees from '../../views/catalog/affiliationFees/AffiliationFees';
import Waivers from '../../views/catalog/waivers/Waivers';
import Terms from '../../views/catalog/terms/Terms';
import Rebates from '../../views/catalog/rebates/Rebates';
import Installment from '../../views/catalog/installment/Installment';
import catalogFormsTabsRoutes from '../tabRoutes/catalogFormsTabsRoutes';
import catalogWaiversTabsRoutes from '../tabRoutes/catalogWaiversTabsRoutes';
/**
 * @typedef {object} SubRoutes
 * @property {React.ReactNode} [name]
 * @property {boolean} [redirect]
 */
/** @type {Array.<import('react-router-dom').RouteProps & SubRoutes>} */
const catalogRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        title: 'sidebar.catalog.productsAndServices',
    },
    {
        path: '/registrationfees',
        name: 'sidebar.catalog.registrationFees',
        component: RegistrationFees,
        permissions: { code: 'catalog', permissionCode: 'registration_fees' },
    },
    {
        path: '/membershipfees',
        name: 'sidebar.catalog.membershipFees',
        component: AffiliationFees,
        permissions: { code: 'catalog', permissionCode: 'affiliation_fees' },
    },
    {
        path: '/otheritems',
        name: 'sidebar.catalog.otherItems',
        component: OtherItems,
        permissions: { code: 'catalog', permissionCode: 'other_fees' },
    },
    {
        path: '/clinicitems',
        name: 'sidebar.catalog.clinicItems',
        component: ClinicItems,
        permissions: { code: 'catalog', permissionCode: 'qualification_fees' },
    },

    {
        title: 'sidebar.catalog.legalAndForms',
    },
    {
        path: '/forms',
        name: 'sidebar.catalog.forms',
        component: Forms,
        permissions: { code: 'catalog', permissionCode: 'form_settings' },
        subPermissionsRoutes: catalogFormsTabsRoutes,
    },
    {
        path: '/waivers',
        name: 'sidebar.catalog.waivers',
        component: Waivers,
        permissions: { code: 'catalog', permissionCode: 'organization_waivers' },
        subPermissionsRoutes: catalogWaiversTabsRoutes,
    },
    {
        path: '/terms',
        name: 'sidebar.catalog.terms',
        component: Terms,
        permissions: { code: 'catalog', permissionCode: 'terms_and_conditions' },
    },

    {
        title: 'sidebar.catalog.finances',
    },
    {
        path: '/rebates',
        name: 'sidebar.catalog.rebates',
        component: Rebates,
        permissions: { code: 'catalog', permissionCode: 'rebate' },
    },
    {
        path: '/installments',
        name: 'sidebar.catalog.installments',
        component: Installment,
        permissions: { code: 'catalog', permissionCode: 'installment_mode' },
    },

    {
        redirect: true,
        to: '/dashboard',
        from: '/catalog',
    },
];

export default catalogRoutes;
