export default{
    "registration.settings.division.table.active" : "Actif",
    "registration.settings.division.table.empty"  : "Aucune Division",

    // add
    "registration.settings.division.add.title"                   : "Ajouter une Division",
    "registration.settings.division.add.status"                  : "Statut",
    "registration.settings.division.add.status.active"           : "Actif",
    "registration.settings.division.add.status.inactive"         : "Inactif",
    "registration.settings.division.add.status.required"         : "Le Statut est requis",
    "registration.settings.division.add.divisionParent.required" : "Veuillez lier avec une division.",
}