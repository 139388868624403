import Translate from '@spordle/intl-elements';
import { useFormikContext } from 'formik';
import { useContext } from 'react';
import { FormGroup, Label } from 'reactstrap';
import CrossFade from '../../../../../components/crossFade/CrossFade';
import FileUpload from '../../../../../components/uploader/fileUpload/FileUpload';
import { mbToBytes } from '../../../../../components/uploader/uploadHelpers';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { displayI18n, DisplayI18n } from '../../../../../helpers/i18nHelper';

const MemberAddForms2Documents = ({ documentTypes }) => {
    const formik = useFormikContext();
    const { getGenericLocale } = useContext(I18nContext);

    return (
        <CrossFade isVisible={Array.isArray(documentTypes) && documentTypes.length > 0} mountOnEnter unmountOnExit>
            <div className="h5 font-medium"><Translate id='form.fields.documents' /></div>
            {documentTypes?.map((documentType) => (
                <FormGroup key={documentType.document_type_id}>
                    <Label for={'documentType_' + documentType.document_type_id}><DisplayI18n field='name' defaultValue={documentType.name} i18n={documentType.i18n} /></Label>
                    <FileUpload
                        id={'documentType_' + documentType.document_type_id}
                        dropzoneProps={{
                            multiple: true,
                            maxSize: documentType.file_size ? mbToBytes(parseFloat(documentType.file_size)) : undefined,
                            accept: "image/*, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                        }}
                        onFileSelect={(files) => {
                            formik.setFieldValue(`documents.${documentType.document_type_id}`, files);
                        }}
                    />
                    {documentType.description &&
                        <div
                            className='small text-muted'
                            dangerouslySetInnerHTML={{
                                __html: displayI18n("description", documentType.i18n, documentType.description, getGenericLocale()),
                            }}
                        />
                    }
                </FormGroup>
            ))}
        </CrossFade>
    )
};

export default MemberAddForms2Documents;
