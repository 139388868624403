import { FormikDateTime, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import moment from "moment";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { object, string } from "yup";
import FormikEditable from "../../../../../../components/formik/FormikEditable";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../components/UserImg";
import { HoverableCopy } from "../../../../../../helpers/clipboardHelper";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { DisplayCategory } from "../../../../../teams/TeamHelpers";

const SupervisionSidePanelInfo = ({ showAllOfficialSupervisions, supervision, isReadOnly, updateSupervisionReport }) => {
    return (
        <>
            <div className="h4 font-bold"><Translate id="officialSupervision.sidePanel.tab.info" /></div>
            <Row form className="mb-1">
                <Col xs="6" className="mb-3">
                    <div className="text-muted"><Translate id='officialSupervision.homeTeam.label' /></div>
                    <div>{supervision.home_team?.short_name || supervision.home_team_name || "-"}</div>
                </Col>
                <Col xs="6" className="mb-3">
                    <div className="text-muted"><Translate id='officialSupervision.awayTeam.label' /></div>
                    <div>{supervision.away_team?.short_name || supervision.away_team_name || "-"}</div>
                </Col>
                <Col xs="6" className="mb-3">
                    <div className="text-muted"><Translate id='officialSupervision.season.label' /></div>
                    <div><DisplayI18n field="name" i18n={supervision.period.i18n} defaultValue={supervision.period.name} /></div>
                </Col>
                <Col xs="6" className="mb-3">
                    <div className="text-muted">Date</div>
                    <FormikEditable
                        id="supervision-date"
                        disabled={isReadOnly}
                        className="editable-transparent"
                        initialValues={{
                            supervision_date: supervision.supervision_date || "",
                        }}
                        onSubmit={(values) => {
                            const newDate = moment(values.supervision_date).format('YYYY-MM-DD');
                            if(newDate !== supervision.supervision_date){
                                updateSupervisionReport({ supervision_date: newDate });
                            }
                        }}
                    >
                        {(isEditing, options, formik) => {
                            if(!isEditing){
                                return (
                                    <div className="font-medium">{supervision.supervision_date || "-"}</div>
                                )
                            }
                            return (
                                <FormikDateTime
                                    id="date"
                                    className='date-picker-left'
                                    name="supervision_date"
                                    dateFormat="YYYY-MM-DD"
                                    placeholder='form.validation.date.placeholder'
                                    timeFormat={false}
                                    isValidDate={(current) => moment(current).isSameOrBefore(moment(), 'days')}
                                />
                            )
                        }}
                    </FormikEditable>
                </Col>
                <Col xs="12" sm="6" className="mb-3">
                    <div className="text-muted"><Translate id='officialSupervision.teamCategory.label' /></div>
                    <div><DisplayCategory category={supervision.team_category} /></div>
                </Col>
                <Col xs="12" sm="6" className="mb-3">
                    <div className="text-muted"><Translate id="officialSupervision.officialType.label" /></div>
                    <FormikEditable
                        id="official-type"
                        disabled={isReadOnly}
                        noConfirmation
                        className="editable-transparent"
                        initialValues={{
                            official_type: supervision.official_type || "",
                        }}
                        validationSchema={object().shape({
                            official_type: string().required(<Translate id="officialSupervision.addSupervision.officialType.required" />),
                        })}
                        onSubmit={(values) => {
                            if(values.official_type !== supervision.official_type){
                                updateSupervisionReport(values);
                            }
                        }}
                    >
                        {(isEditing, options, formik) => {
                            if(!isEditing){
                                return (
                                    <div>{supervision.official_type ? <Translate id={`officialSupervision.officialType.${supervision.official_type}`} defaultMessage={supervision.official_type} /> : "-"}</div>
                                )
                            }
                            return (
                                <FormikSelect
                                    name="official_type"
                                    id="official_type"
                                    search={false}
                                    autoFocus
                                    menuIsDefaultOpen
                                    loadingStatus='success'
                                    onOptionSelected={async([ value ]) => {
                                        await formik.setFieldValue('official_type', value);
                                        options.stopEditing();
                                        return true;
                                    }}
                                    defaultData={[
                                        {
                                            label: "officialSupervision.officialType.REFEREE",
                                            translateLabel: true,
                                            value: "REFEREE",
                                        },
                                        {
                                            label: "officialSupervision.officialType.LINESPERSON",
                                            translateLabel: true,
                                            value: "LINESPERSON",
                                        },
                                        {
                                            label: "officialSupervision.officialType.OFFICIAL_SYSTEM",
                                            translateLabel: true,
                                            value: "OFFICIAL_SYSTEM",
                                        },
                                    ]}
                                />
                            )
                        }}
                    </FormikEditable>
                </Col>
                <Col xs="12" className="mb-3">
                    <div className="text-muted"><Translate id='officialSupervision.officiatingCoach.label' /></div>
                    <UserDisplay card block>
                        <UserDisplay.Container>
                            <UserImg
                                className="text-uppercase"
                                src={null}
                                alt={`${supervision.officiating_coach?.first_name} ${supervision.officiating_coach?.last_name}`}
                                abbr={`${supervision.officiating_coach?.first_name.charAt(0)}${supervision.officiating_coach?.last_name.charAt(0)}`}
                                width={45}
                            />
                        </UserDisplay.Container>
                        <UserDisplay.Container>
                            <UserDisplay.Title>{supervision.officiating_coach?.first_name} {supervision.officiating_coach?.last_name}</UserDisplay.Title>
                            <UserDisplay.Subtitle>
                                <HoverableCopy toCopy={supervision.officiating_coach?.unique_identifier}>
                                    <Link to={`/members/profile/${supervision.officiating_coach?.member_id}`}>
                                        #{supervision.officiating_coach?.unique_identifier} <i className="mdi mdi-chevron-right" />
                                    </Link>
                                </HoverableCopy>
                            </UserDisplay.Subtitle>
                            {supervision.officiating_coach?.member_qualification &&
                                <UserDisplay.Subtitle>
                                    <DisplayI18n field='name' defaultValue={supervision.officiating_coach.member_qualification.qualification.name} i18n={supervision.officiating_coach.member_qualification.qualification.i18n} />{(supervision.officiating_coach.member_qualification.qualification_level) && <> - <DisplayI18n field='name' defaultValue={supervision.officiating_coach.member_qualification.qualification_level.name} i18n={supervision.officiating_coach.member_qualification.qualification_level.i18n} /></>}
                                </UserDisplay.Subtitle>
                            }
                        </UserDisplay.Container>
                    </UserDisplay>
                    <div className="text-muted"><Translate id='officialSupervision.official.label' /></div>
                    <UserDisplay card block>
                        <UserDisplay.Container>
                            <UserImg
                                className="text-uppercase"
                                src={null}
                                alt={`${supervision.official?.first_name} ${supervision.official?.last_name}`}
                                abbr={`${supervision.official?.first_name.charAt(0)}${supervision.official?.last_name.charAt(0)}`}
                                width={45}
                            />
                        </UserDisplay.Container>
                        <UserDisplay.Container>
                            <UserDisplay.Title>{supervision.official?.first_name} {supervision.official?.last_name}</UserDisplay.Title>
                            <UserDisplay.Subtitle>
                                <HoverableCopy toCopy={supervision.official?.unique_identifier}>
                                    <Link to={`/members/profile/${supervision.official?.member_id}`}>
                                        #{supervision.official?.unique_identifier} <i className="mdi mdi-chevron-right" />
                                    </Link>
                                </HoverableCopy>
                            </UserDisplay.Subtitle>
                            {supervision.official.member_qualification &&
                                <UserDisplay.Subtitle>
                                    <DisplayI18n field='name' defaultValue={supervision.official.member_qualification.qualification.name} i18n={supervision.official.member_qualification.qualification.i18n} />{(supervision.official.member_qualification.qualification_level) && <> - <DisplayI18n field='name' defaultValue={supervision.official.member_qualification.qualification_level.name} i18n={supervision.official.member_qualification.qualification_level.i18n} /></>}
                                </UserDisplay.Subtitle>
                            }
                        </UserDisplay.Container>
                        <UserDisplay.Container className="ml-auto">
                            <Button size="sm" type="button" color="primary" onClick={showAllOfficialSupervisions}>
                                <Translate id='officialSupervision.allReportsBtn.short' />
                            </Button>
                        </UserDisplay.Container>
                    </UserDisplay>
                </Col>
            </Row>
        </>
    );
}

export default SupervisionSidePanelInfo;