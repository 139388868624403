import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { formatSelectData } from "@spordle/spordle-select";
import { useContext } from "react";
import { Col, Collapse, Label, Row } from "reactstrap";
import FormikEditable from "../../../../../../../components/formik/FormikEditable";
import Required from "../../../../../../../components/formik/Required";
import { PeriodsContext } from "../../../../../../../contexts/contexts";
import { I18nContext } from "../../../../../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../../../../../contexts/IdentityRolesContext";
import { MembersContext } from "../../../../../../../contexts/MembersContext";
import { RegistrationDivisionsContext } from "../../../../../../../contexts/RegistrationDivisionsContext";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import { DisplayCategory } from "../../../../../../teams/TeamHelpers";
import { NO_TEAM, sortRessource } from "../../../InsuranceHelper";
import { string, object } from "yup";
import { RegistrationClassesContext } from "../../../../../../../contexts/RegistrationClassesContext";

const InsuranceSidePanelEditTeam = ({ injury, updateInjury, canEdit }) => {
    const { getMemberTeams } = useContext(MembersContext);
    const { selectedPeriod } = useContext(PeriodsContext);
    const { getRegistrationDivisions: getDivisions } = useContext(RegistrationDivisionsContext);
    const { getRegistrationClasses: getClasses } = useContext(RegistrationClassesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { federation } = useContext(IdentityRolesContext);

    return (
        <>
            <div className="text-muted mb-1"><Translate id='insurance.label.team' /></div>
            <FormikEditable
                id="edit-team"
                disabled={!canEdit}
                initialValues={{
                    team_name: injury.team_name || "",
                    team: injury.team || { team_id: injury.team_name ? NO_TEAM : "" },
                    team_category: injury.team_category || { team_category_id: "" },
                    division: injury.division || { division_id: "" },
                    class: injury.class || { class_id: "" },
                }}
                validationSchema={object().shape({
                    team: object().shape({
                        team_id: string(),
                    }),
                    team_category: object().shape({
                        team_category_id: string(),
                    }),
                    division: object().when("team.team_id", {
                        is: NO_TEAM,
                        then: (schema) => schema.shape({
                            division_id: string().required(<Translate id='insurance.validation.noTeam.teamDivision' />),
                        }),
                    }),
                    class: object().when("team.team_id", {
                        is: NO_TEAM,
                        then: (schema) => schema.shape({
                            class_id: string().required(<Translate id='insurance.validation.noTeam.teamClass' />),
                        }),
                    }),
                    team_name: string().when("team.team_id", {
                        is: NO_TEAM,
                        then: (schema) => schema.required(<Translate id='insurance.validation.noTeam.teamName' />),
                    }),
                })}
                onSubmit={(values) => {
                    const apiVal = {};
                    const tableVal = {};

                    // Possibilities :
                    // 1. No team id with team_name
                    // 2. No team id and no team name
                    // 3. Team id

                    const isOtherTeam = (team) => team.team_id === NO_TEAM;

                    if(isOtherTeam(values.team)){
                        // remove injury_team
                        if(injury.team){
                            apiVal.team_id = "";

                            tableVal.team = null;
                        }

                        // update other team name
                        if(values.team_name !== injury.team_name){
                            apiVal.team_name = values.team_name;

                            tableVal.team_name = values.team_name;
                        }

                        // update division
                        if(values.division.division_id !== injury.division?.division_id){
                            apiVal.division_id = values.division.division_id || null;

                            tableVal.division = values.division.division_id ? values.division : null;
                        }

                        // update class
                        if(values.class.class_id !== injury.class?.class_id){
                            apiVal.class_id = values.class.class_id || null;

                            tableVal.class = values.class.class_id ? values.class : null;
                        }

                        if(injury.team_category?.team_category_id){
                            apiVal.team_category_id = null;
                            tableVal.team_category = null;
                        }

                    }else if(values.team.team_id){

                        if(values.team.team_id !== injury.team?.team_id){
                            apiVal.team_id = values.team.team_id || null;
                            apiVal.class_id = values.team?.team_category?.class_id || null;
                            apiVal.division_id = values.team?.team_category?.division?.division_id || null;

                            tableVal.team = values.team;
                            tableVal.class = values.team.team_category?.class;
                            tableVal.division = values.team.team_category?.division;
                        }

                        if(injury.team_name){
                            apiVal.team_name = null;
                            tableVal.team_name = "";
                        }


                    }else{

                        apiVal.team_id = null;
                        apiVal.class_id = null;
                        apiVal.division_id = null;
                        apiVal.team_category_id = null;
                        apiVal.team_name = null;

                        tableVal.team_name = "";
                        tableVal.team = null;
                        tableVal.team_category = null;
                        tableVal.class = null;
                        tableVal.division = null;

                    }

                    if(Object.keys(apiVal).length > 0){
                        updateInjury(apiVal, tableVal);
                    }

                }}
            >
                {(isEditing, options, formik) => {
                    if(!isEditing){
                        return (
                            <div id='insurance-accident-teamName'>
                                {
                                    injury.team_name ?
                                        injury.team_name
                                        :
                                        injury.team ?
                                            <DisplayI18n
                                                field="name"
                                                i18n={injury.team?.i18n}
                                                defaultValue={injury.team?.name}
                                            />
                                            :
                                            '-'
                                }
                                {(injury.team_category?.division?.division_id === injury.division?.division_id
                                && injury.team_category?.class?.class_id === injury.class?.class_id) ?
                                    <div className="small" id='insurance-accident-teamCategory'>
                                        <DisplayCategory short category={injury.team_category} />
                                    </div>
                                    :
                                    <>
                                        {(injury.division || injury.class) &&
                                            <div className="small" id='insurance-accident-teamClassDivision'>
                                                <DisplayCategory short category={{ division: injury.division, class: injury.class }} />
                                            </div>
                                        }
                                        {injury.team_category &&
                                            <div className="small" id='insurance-accident-teamCategory'>
                                                <DisplayCategory short category={injury.team_category} />
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        )
                    }

                    return (
                        <>
                            <FormikSelect
                                search
                                name="team.team_id"
                                id="insurance-accident-team"
                                clearable
                                searchKeys={[
                                    `i18n.${getGenericLocale()}.name`,
                                    `i18n.${getGenericLocale()}.short_name`,
                                    `category.division.i18n.${getGenericLocale()}.name`,
                                    `division.i18n.${getGenericLocale()}.name`,
                                    `class.i18n.${getGenericLocale()}.name`,
                                    'category.gender',
                                ]}
                                onOptionSelected={([ value ], select) => {
                                    const allTeams = select.getSpordleTable().getData();

                                    if(value !== NO_TEAM){
                                        formik.setFieldValue('team', value ? allTeams.find((team) => team.value === value) : { team_id: "" })
                                    }else if(formik.values.team?.team_id !== NO_TEAM){
                                        formik.setFieldValue('division', { division_id: "" });
                                        formik.setFieldValue('team_category', { team_category_id: "" });
                                        formik.setFieldValue('team', { team_id: NO_TEAM })
                                    }

                                    return true;
                                }}
                                renderOption={(team) => (
                                    (team.option.isGroup || team.option.value === NO_TEAM) ?
                                        <Translate id={team.option.label} />
                                        :
                                        <div>
                                            <div>{team.option.label}</div>
                                            <div className='text-muted small'>
                                                {team.option.division ? team.option.division : <DisplayCategory category={{ ...team?.option.category }} short /> }
                                                {team.option.category?.gender && <> - <Translate id={`form.fields.gender.${team.option.category.gender.toLowerCase()}`} /></>}
                                            </div>
                                        </div>
                                )}
                                loadData={(from) => {
                                    switch (from){
                                        case 'CDM':
                                            return getMemberTeams(injury.member?.member_id, { period_id: selectedPeriod.period_id })
                                                .then((teams) => {
                                                    return formatSelectData(teams.reduce((shownTeams, team) => {
                                                        shownTeams.push({
                                                            ...team.team,
                                                            label: team.team.name,
                                                            i18n: team.team.i18n,
                                                            value: team.team.team_id,
                                                        });
                                                        return shownTeams;
                                                    }, [
                                                        {
                                                            value: 'NONE',
                                                            label: 'insurance.label.teamNonListed',
                                                        },
                                                    ]), {
                                                        getGroupId: (team) => team.value === NO_TEAM ? "NOT_LISTED" : undefined,
                                                        newGroupIndexes: {
                                                            0: {
                                                                label: 'insurance.label.teamNonListed',
                                                                groupId: 'NOT_LISTED',
                                                            },
                                                        },
                                                    })
                                                });
                                        default:
                                            break;
                                    }
                                }}
                            />
                            <Collapse isOpen={formik.values.team?.team_id === NO_TEAM}>
                                <Row className="pt-3" form>
                                    <Col className="mb-3" sm="12">
                                        <Label className="text-muted mb-0" for='insurance-accident-teamName'><Translate id='insurance.label.teamName' /> <Required /></Label>
                                        <FormikInputText
                                            id="insurance-accident-teamName"
                                            name="team_name"
                                            trim
                                        />
                                    </Col>
                                    <Col className="mb-3" sm="12">
                                        <Label className="text-muted mb-0" for='insurance-accident-teamDivisionId'><Translate id='insurance.label.teamDivision' /> <Required /></Label>
                                        <FormikSelect
                                            id="insurance-accident-teamDivisionId"
                                            name="division.division_id"
                                            renderSelectedOption={(option) => (
                                                <DisplayI18n
                                                    field="short_name"
                                                    defaultValue={option.name}
                                                    i18n={option.i18n}
                                                />
                                            )}
                                            onOptionSelected={([ value ], select) => {
                                                const allDivisions = select.getSpordleTable().getData();
                                                formik.setFieldValue('division', allDivisions.find((divi) => divi.value === value));
                                                return true;
                                            }}
                                            searchKeys={[
                                                `i18n.${getGenericLocale()}.name`,
                                                `i18n.${getGenericLocale()}.short_name`,
                                            ]}
                                            renderOption={({ option }) => (
                                                <>
                                                    <DisplayI18n
                                                        field="short_name"
                                                        defaultValue={option.short_name}
                                                        i18n={option.i18n}
                                                    />
                                                    <div className="small text-muted">
                                                        <DisplayI18n
                                                            field="name"
                                                            defaultValue={option.name}
                                                            i18n={option.i18n}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return (
                                                            getDivisions(federation.organisation_id, { active: 1, period_id: selectedPeriod.period_id })
                                                                .then((divisions) => {
                                                                    return sortRessource(divisions)
                                                                        .map((theDivision) => ({
                                                                            ...theDivision,
                                                                            value: theDivision.division_id,
                                                                            label: theDivision.name,
                                                                        }))
                                                                })
                                                        )
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col className="mb-3" sm="12">
                                        <Label className="text-muted mb-0" for='insurance-accident-teamClassId'>
                                            <Translate id='insurance.label.teamClass' /> <Required />
                                        </Label>
                                        <FormikSelect
                                            id="insurance-accident-teamClassId"
                                            name="class.class_id"
                                            renderSelectedOption={(option) => (
                                                <DisplayI18n
                                                    field="short_name"
                                                    defaultValue={option.name}
                                                    i18n={option.i18n}
                                                />
                                            )}
                                            searchKeys={[
                                                `i18n.${getGenericLocale()}.name`,
                                                `i18n.${getGenericLocale()}.short_name`,
                                            ]}
                                            onOptionSelected={([ value ], select) => {
                                                const allClasses = select.getSpordleTable().getData();

                                                formik.setFieldValue('class', allClasses.find((theClass) => theClass.value === value))

                                                return true;
                                            }}
                                            renderOption={({ option }) => (
                                                <>
                                                    <DisplayI18n
                                                        field="short_name"
                                                        defaultValue={option.short_name}
                                                        i18n={option.i18n}
                                                    />
                                                    <div className="small text-muted">
                                                        <DisplayI18n
                                                            field="name"
                                                            defaultValue={option.name}
                                                            i18n={option.i18n}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return getClasses(
                                                            federation.organisation_id,
                                                            { active: 1, period_id: selectedPeriod.period_id },
                                                        )
                                                            .then((allClasses) => {
                                                                return sortRessource(allClasses)
                                                                    .map((theClass) => ({
                                                                        ...theClass,
                                                                        value: theClass.class_id,
                                                                        label: theClass.name,
                                                                    }))
                                                            })
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Collapse>
                        </>
                    )
                }}
            </FormikEditable>
        </>
    );
}

export default InsuranceSidePanelEditTeam;