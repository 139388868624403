export default{
    "invoice.title"              : "Invoice",
    "invoice.date"               : "Date",
    "invoice.taxNumber"          : "{count, plural, one {Tax number} other {Tax numbers}}",
    "invoice.invoiceNo"          : "Invoice No",
    "invoice.paymentMethod"      : "Payment method",
    "invoice.payedBy"            : "Paid By",
    "invoice.organization"       : "Organization",
    "invoice.table.item"         : "Item",
    "invoice.table.tax"          : "{count, plural, one {Tax} other {Taxes}}",
    "invoice.table.credits"      : "Credit",
    "invoice.table.fees"         : "Platform fees",
    "invoice.table.discount"     : "Discount",
    "invoice.table.registration" : "Registration",
    "invoice.table.affiliation"  : "Membership fees",
    "invoice.table.clinic"       : "Clinic",
    "invoice.table.season"       : "Season",
    "invoice.table.season.to"    : "to",
    "invoice.table.price"        : "Price",
    "invoice.table.qty"          : "QTY",
    "invoice.table.total"        : "Total",
    "invoice.table.subTotal"     : "Sub Total",
    "invoice.table.poweredBy"    : "Powered by Spordle © {year} Spordle Inc. All rights reserved.",

    "invoice.receipt.members"     : "Member(s)",
    "invoice.receipt.total"       : "Total",
    "invoice.receipt.paid"        : "Paid",
    "invoice.receipt.alreadyPaid" : "Already paid",
    "invoice.receipt.balance"     : "Balance",

    "invoice.error.title"   : "Something went wrong",
    "invoice.error.message" : "We haven't found any invoice. Please come back later or contact the support team.",

    "invoice.transactions.paymentMethods.CHEQUE"       : "Cheque",
    "invoice.transactions.paymentMethods.CASH"         : "Cash",
    "invoice.transactions.paymentMethods.CREDITCARD"   : "Credit card",
    "invoice.transactions.paymentMethods.PAYMENTPROXY" : "Credit card (proxy payment)",
    "invoice.transactions.paymentMethods.INTERAC"      : "Interac transfer",
    "invoice.transactions.paymentMethods.FUNDING"      : "Funding",
    "invoice.transactions.paymentMethods.undefined"    : "N/A",

    "invoice.editModal.title" : "Edit invoice",

    "invoice.editModal.editIdentity.btn"          : "Identity",
    "invoice.editModal.editIdentity.btn.helper"   : "Click here if you wish to manage the identity of this invoice.",
    "invoice.editModal.editIdentity.success.msg"  : "Identity updated",
    "invoice.editModal.editIdentity.success.info" : "Identity of the invoice was successfully updated",

    "invoice.editModal.editIdentity.existing" : "This account is an already existing account. Do you wish to link the member(s) of this invoice to this existing account?",

    "invoice.editModal.editAddress.btn"          : "Address",
    "invoice.editModal.editAddress.btn.helper"   : "Click here if you wish to manage the address of this invoice.",
    "invoice.editModal.editAddress.success.msg"  : "Address updated",
    "invoice.editModal.editAddress.success.info" : "Address of the invoice was successfully updated",

    "invoice.changeAddress.title"              : "Change the invoice address",
    "invoice.changeAddress.addresses"          : "Member addresses",
    "invoice.changeAddress.addresses.required" : "Please select a member address",
}