import { FormikCurrencyInput, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { useContext } from 'react';
import { Card, Col, Row } from 'reactstrap';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { DisplayCategory } from '../../../TeamHelpers';

const TeamRolloverReviewTeamCard = ({
    categories,
    categoriesLoading,
    team,
    index,
    errors,
    touched,
    values,
    setFieldValue,
    renderOption,
    renderSelectedOption,
    removeTeam,
    fieldPrefix,
}) => {
    const i18nContext = useContext(I18nContext);
    // const suggestedCategory = categories.find((c) => c.team_category_id === team.team_category?.team_category_id && c.division.name !== 'Historical');

    return (
        <Card body key={team.team_id} className={`card-shadow mb-2${errors?.teams?.[index] && touched?.teams?.[index] ? ' border-danger' : ''}`}>
            <Row className="align-items-center" form>
                <Col sm="5">
                    <UserDisplay>
                        <UserDisplay.Container className="mr-3">
                            <UserImg
                                abbr={team.abbreviation || team.short_name || team.name}
                                src={team.logo?.full_path}
                                filePos={team.logo?.file_position}
                                width={60}
                                alt={team.name}
                            />
                        </UserDisplay.Container>
                        <UserDisplay.Container>
                            <UserDisplay.Title>{team.name}</UserDisplay.Title>
                            {team.unique_identifier && <UserDisplay.Subtitle>#{team.unique_identifier}</UserDisplay.Subtitle>}
                            <UserDisplay.Subtitle>
                                {team.division ?
                                    team.division
                                    :
                                    <DisplayCategory category={{ ...team.team_category }} short />
                                }
                            </UserDisplay.Subtitle>
                        </UserDisplay.Container>
                    </UserDisplay>
                </Col>
                {/* <Col sm="3">
                    {team.team_category &&
                        <div className="small text-dark font-medium">
                            <DisplayCategory category={team.team_category} short />
                        </div>
                    }
                </Col> */}
                <Col sm='5'>
                    <Row form>
                        <Col sm='7'>
                            <FormikSelect
                                isLoading={categoriesLoading}
                                id={fieldPrefix + 'teams.' + index + '.category_id'}
                                name={fieldPrefix + 'teams.' + index + '.category_id'}
                                searchKeys={[
                                    `division.i18n.${i18nContext.getGenericLocale()}.short_name`,
                                    `class.i18n.${i18nContext.getGenericLocale()}.short_name`,
                                ]}
                                renderOption={renderOption}
                                renderSelectedOption={renderSelectedOption}
                                onOptionSelected={([ value ]) => {
                                    // because we reinitialize formik when a team is removed
                                    // so we need to update the value from the team in the array received in the props
                                    team.new_team_category = categories.find((cat) => cat.team_category_id === value)
                                }}
                                options={categories?.map((cat) => ({
                                    value: cat.team_category_id,
                                    label: `form.fields.gender.${cat.gender.toLowerCase()}`,
                                    translateLabel: true,
                                    isActive: cat.isActive,
                                    class: cat.class,
                                    division: cat.division,
                                }))}
                            />
                        </Col>
                        <Col sm='5'>
                            <FormikCurrencyInput
                                id={fieldPrefix + 'teams.' + index + '.team_fee'}
                                name={fieldPrefix + 'teams.' + index + '.team_fee'}
                                allowNegative={false}
                                placeholder='teams.profile.teamInfos.general.teamFee'
                                translatePlaceholder
                            />
                        </Col>
                    </Row>
                    <Row form>
                        <Col sm='7'>
                            {/* {suggestedCategory &&
                                <span className='small text-muted'>
                                    <Translate id='teams.teamSearch.addTeam.rollover.suggestedCategory' />:
                                    <button
                                        type='button'
                                        className='reset-btn text-link ml-1'
                                        onClick={() => {
                                            team.new_team_category = suggestedCategory
                                            setFieldValue(`teams.${index}.category_id`, suggestedCategory.team_category_id, false)
                                        }}
                                    >
                                        <DisplayCategory category={suggestedCategory} short />
                                    </button>
                                </span>
                            } */}
                            {categories.find((cat) => cat.team_category_id === values.teams[index].category_id) &&
                                !categories.find((cat) => cat.team_category_id === values.teams[index].category_id).isActive &&
                                    <div className='text-warning small'>
                                        <Translate id='teams.teamSearch.addTeam.rollover.inactiveCategory' />
                                        {/* couldn't find a way to make the link fit properly, not that important for now */}
                                        {/* <HasAccessTo tag='div' componentCode='teams' componentPermissionCode='team_categories'>
                                            <Link
                                                to='/settings/registration/category'
                                                target='_blank'
                                            >
                                                <Translate id='teams.teamSearch.addTeam.rollover.goToCategories'/>
                                                <i className="ml-1 mdi mdi-arrow-right" />
                                            </Link>
                                        </HasAccessTo> */}
                                    </div>
                            }
                        </Col>
                        <Col sm='5' />
                    </Row>
                </Col>
                <Col sm="2" className="text-right">
                    <button
                        onClick={() => removeTeam(team.team_id)}
                        className="reset-btn text-danger"
                        type='button'
                    >
                        <Translate id="misc.remove" />
                    </button>
                </Col>
            </Row>
        </Card>
    )
}

export default TeamRolloverReviewTeamCard;