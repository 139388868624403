import { useContext, useEffect, useRef, useState } from "react"
import { Button, ModalBody, ModalFooter } from "reactstrap"
import Translate from "@spordle/intl-elements"
import { Form, Formik } from "formik"
import { mixed, object } from "yup"
import CrossFade from "../../../../../components/crossFade/CrossFade"
import CustomAlert from "../../../../../components/CustomAlert"
import { PeriodsContext } from "../../../../../contexts/contexts"
import moment from "moment";
import { getAdditionalFieldsInitialValues, getAdditionalFieldsValidationSchema } from "../../../../../helpers/additionalFieldsHelpers"
import { OrganizationContext } from "../../../../../contexts/OrganizationContext"
import RegistrationFeeCard from "./RegistrationFeeCard"

const RolloverModalConfirmation = ({ selectedFees, goToPrevious, divisions, categories, toggle, refreshTable }) => {

    const periodsContext = useContext(PeriodsContext);
    const organizationContext = useContext(OrganizationContext);

    const [ promises, setPromises ] = useState(selectedFees.reduce((promisesObject, r) => {
        promisesObject[r.registration_fee_id] = false;
        return promisesObject
    }, {}))
    const [ hasFinished, setHasFinished ] = useState(false);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const formikRef = useRef();

    const currentYear = moment(periodsContext.selectedPeriod.end_date, 'YYYY-MM-DD').year();

    const getFormGroups = () => {
        return organizationContext.additionalFieldsComponents?.find((a) => a.component === "REGISTRATION_FEE")?.groups || []
    }

    const getAdditionalFields = (row) => {
        return (row?.additional_fields?.map((field) => ({
            fieldId: field.custom_form_field.custom_form_field_id,
            answer: field.answer,
            optionId: field.custom_form_field_option_selected,
        })) || null)
    }

    const getInitDateVal = (date) => date ? moment(date).set('year', currentYear) : '';

    const getInitialValues = () => {
        const temp = {};
        selectedFees.forEach((r) => {
            temp[r.registration_fee_id] = {
                earlyDate: getInitDateVal(r.fee.early_amount_until),
                earlyTime: getInitDateVal(r.fee.early_amount_until),
                lateDate: getInitDateVal(r.fee.late_amount_after),
                lateTime: getInitDateVal(r.fee.late_amount_after),
                fields: getAdditionalFieldsInitialValues(getFormGroups(), getAdditionalFields(r)),
            }
        })
        return temp;
    }

    const getValidationSchema = () => {
        const temp = {};
        selectedFees.forEach((r) => {
            const hasEarlyFee = !!r.fee.early_amount;
            const hasLateFee = !!r.fee.late_amount;
            temp[r.registration_fee_id] = object().shape({
                earlyDate: mixed().when('doesNotMatter', {
                    is: () => hasEarlyFee,
                    then: mixed().required(<Translate id='catalog.registrationFees.rollover.earlyDate.required' />).isDate(),
                }),
                earlyTime: mixed().when('doesNotMatter', {
                    is: () => hasEarlyFee,
                    then: mixed().required(<Translate id='catalog.registrationFees.rollover.earlyDate.required' />).isDate(),
                }),
                lateDate: mixed().when('doesNotMatter', {
                    is: () => hasLateFee,
                    then: mixed().required(<Translate id='catalog.registrationFees.rollover.lateDate.required' />).isDate(),
                }),
                lateTime: mixed().when('doesNotMatter', {
                    is: () => hasLateFee,
                    then: mixed().required(<Translate id='catalog.registrationFees.rollover.lateDate.required' />).isDate(),
                }),
                fields: getAdditionalFieldsValidationSchema(getFormGroups()),
            })
        });
        return temp;
    }

    const validationSchema = getValidationSchema();

    useEffect(() => {
        if(Object.keys(promises).every((key) => promises[key])){ // every promise is resolved
            setHasFinished(true);
            setIsSubmitting(false);
            formikRef.current?.setSubmitting(false);
        }
    }, [ promises ])

    return (
        <Formik
            innerRef={formikRef}
            initialValues={getInitialValues()}
            validationSchema={object().shape(validationSchema)}
            onSubmit={(values) => {
                setIsSubmitting(true);
            }}
        >
            {(formik) => (
                <>
                    <ModalBody className="overflow-hidden d-flex flex-column">
                        <p className="h6 font-bold text-center mb-3">
                            <Translate id='catalog.registrationFees.rollover.confirm' />
                        </p>

                        {selectedFees.some((r) => (r.fee.early_amount || r.fee.late_amount)) &&
                            <CustomAlert
                                color='info' icon='mdi mdi-settings'
                                text={
                                    <>
                                        <Translate
                                            id="catalog.registrationFees.rollover.additionalFeesHint"
                                            values={{ year: currentYear }}
                                        />
                                        {getFormGroups().length > 0 &&
                                            <span className="ml-1">
                                                <Translate id='catalog.registrationFees.rollover.additionalFields' />
                                            </span>
                                        }
                                    </>
                                }
                                translateText={false}
                            />
                        }

                        {selectedFees.map((row) => (
                            <RegistrationFeeCard
                                key={row.registration_fee_id}
                                row={row}
                                divisions={divisions}
                                categories={categories}
                                onFinally={(registrationFeeId) => setPromises((prev) => ({ ...prev, [registrationFeeId]: true }))}
                                isSubmitting={isSubmitting}
                                values={formik.values[row.registration_fee_id]}
                                validationSchema={validationSchema[row.registration_fee_id]}
                                errors={formik.errors[row.registration_fee_id]}
                                getFormGroups={getFormGroups}
                                getAdditionalFields={getAdditionalFields}
                                onSubmit={(values) => {
                                    formik.setFieldValue(row.registration_fee_id, values)
                                }}
                            />
                        ))}
                    </ModalBody>
                    <Form>
                        <CrossFade isVisible={!hasFinished}>
                            <ModalFooter className="justify-content-between">
                                <Button color='primary' outline type='button' onClick={goToPrevious} disabled={formik.isSubmitting}><Translate id='misc.previous' /></Button>
                                <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.create' /></Button>
                            </ModalFooter>
                        </CrossFade>
                        <CrossFade isVisible={hasFinished}>
                            <ModalFooter>
                                <Button
                                    color='primary'
                                    type='button'
                                    disabled={formik.isSubmitting}
                                    onClick={() => {
                                        toggle();
                                        refreshTable();
                                    }}
                                >
                                    <Translate id='misc.close' />
                                </Button>
                            </ModalFooter>
                        </CrossFade>
                    </Form>
                </>
            )}
        </Formik>
    )
}

export default RolloverModalConfirmation