import DisplayOrganization from "../../../../../../components/organization/DisplayOrganization";
import DisplayOrg from "../../../../../../components/DisplayOrg";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import DisplayMemberTypeStatus from "../../../../../../components/display/DisplayMemberTypeStatus";
import DisplayMemberTypeIcon from "../../../../../../components/display/DisplayMemberTypeIcon";
import HoverBlock from "../../../../../../components/hoverBlock/HoverBlock";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { useContext, Fragment } from "react";
import { useDisclosure } from "@mantine/hooks";
import Translate from "@spordle/intl-elements";
import UpdateMemberTypeStatusModal from "../../../../components/UpdateMemberTypeStatusModal";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { stringBuilder } from "@spordle/helpers";
import { RolesContext } from "../../../../../../contexts/RolesContext";
import { IdentityRolesContext } from "../../../../../../contexts/IdentityRolesContext";
import styles from "./MemberOverviewMemberType.module.scss";
import moment from "moment";
import { FormattedDate } from "react-intl";
import ManageMemberTypesModal from "./ManageMemberTypesModal";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";

const MemberOverviewMemberType = ({ memberType, refreshMemberProfile, hasPendingRemovalRequest, shownMemberTypes }) => {
    const { currentMember, memberSuspensions } = useContext(MembersContext);
    const { settings } = useContext(OrganizationContext);
    const [ isOpen, { open, close } ] = useDisclosure(false);
    const [ manageModalIsOpen, { open: openManage, close: closeManage } ] = useDisclosure(false);
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const canEditStatus = canDoAction("EDIT", "members", "manage_member_type_approbation");
    const canShowStatus = settings.manage_member_type_approbation?.value == 1;
    const suspensionDate = memberSuspensions?.find((suspension) => {
        if(suspension.member_type?.system_type === memberType.member_type.system_type && suspension.end_date){
            return suspension.duration === 'DEFINITE' && moment().isBefore(moment(suspension.end_date))
        }
        return false
    })?.end_date

    const Wrapper = suspensionDate || hasPendingRemovalRequest ? 's' : Fragment

    const handleOnSuccess = () => {
        refreshMemberProfile();
    }

    return (
        <>
            <HoverBlock
                popoverProps={{
                    placement: "start",
                    position: "bottom",
                }}
                className={stringBuilder({ [`editable ${styles.wrapper}`]: ((canEditStatus || isGod()) && canShowStatus) }, "d-flex align-items-center")}
                onClick={canShowStatus && (canEditStatus || isGod()) ? open : void 0}
                dropdownChildren={
                    <>
                        <div className="d-flex">
                            <div>
                                <div className="font-medium text-dark">
                                    <Wrapper>
                                        <DisplayI18n defaultValue={memberType.member_type.name} i18n={memberType.member_type.i18n} field='name' />
                                    </Wrapper>
                                    {suspensionDate &&
                                        <div className="text-danger">
                                            <Translate
                                                id='members.profile.memberType.suspendedUntil'
                                                values={{
                                                    date: <FormattedDate value={suspensionDate} />,
                                                }}
                                            />
                                        </div>
                                    }
                                    {hasPendingRemovalRequest &&
                                        <div className="text-danger">
                                            <Translate id='members.profile.memberType.pendingRemovalRequest' />
                                        </div>
                                    }
                                </div>
                                <div><DisplayOrg org={memberType.organisation} /></div>
                                <small className="text-muted">(<DisplayI18n field="period" defaultValue={memberType.period?.name} i18n={memberType.period?.i18n} />)</small>
                            </div>
                            {!hasPendingRemovalRequest &&
                                <CanDoAction action="EDIT" componentCode="members" componentPermissionCode="member_types" className='ml-auto'>
                                    <button onClick={openManage} type="button" className={stringBuilder("reset-btn py-0 pl-2 text-danger ml-auto")}>
                                        <i className="fas fa-trash" />
                                    </button>
                                </CanDoAction>
                            }
                        </div>
                        {canShowStatus && (
                            <div className="pt-2 mt-2 border-top">
                                <div className="d-flex align-items-center">
                                    <DisplayMemberTypeStatus status={memberType.member_type_status} />
                                    {/* Custom management because to control CSS btn */}
                                    {(canEditStatus || isGod()) &&
                                        <button onClick={open} type="button" className={stringBuilder(`text-${canEditStatus ? "link" : "purple"}`, "reset-btn py-0 pl-2 ml-n1")}>
                                            {!memberType.member_type_status && <span className="mr-1"><Translate id="members.profile.memberType.status.edit" /></span>}
                                            <i className="mdi mdi-pencil" />
                                        </button>
                                    }
                                </div>
                                {memberType.decision_made_by &&
                                    <div className="mt-2 small">
                                        <div>
                                            <b><Translate id="members.profile.memberType.status.decidedBy" /></b>: {memberType.decision_made_by.name} {memberType.decision_made_by.family_name}
                                        </div>
                                        {memberType.decision_note &&
                                            <div>
                                                <b><Translate id="form.fields.note" /></b>: {memberType.decision_note}
                                            </div>
                                        }
                                    </div>

                                }
                            </div>
                        )}
                    </>
                }
            >
                <DisplayMemberTypeIcon memberType={memberType} />
                <div className="d-flex align-items-center flex-wrap">
                    <Wrapper>
                        <DisplayI18n defaultValue={memberType.member_type.name} i18n={memberType.member_type.i18n} field='name' />
                    </Wrapper>
                    {memberType.organisation &&
                        <div className="text-muted ml-1 small d-flex align-items-center">
                            (<DisplayOrg org={memberType.organisation} />)
                            <DisplayOrganization onlyButton organisation={{ organisation_id: memberType.organisation.organisation_id }} />
                        </div>
                    }
                </div>
                {((canEditStatus || isGod()) && canShowStatus) &&
                    <button
                        type="button"
                        className={stringBuilder(`text-${canEditStatus ? "link" : "purple"}`, styles.btn, "reset-btn py-0 pl-2 ml-n1")}
                    >
                        <i className="mdi mdi-pencil" />
                    </button>
                }
                <UpdateMemberTypeStatusModal
                    isOpen={isOpen}
                    toggle={close}
                    members={[
                        {
                            ...currentMember,
                            member_types: [ memberType ],
                        },
                    ]}
                    onSuccess={handleOnSuccess}
                />
            </HoverBlock>
            <ManageMemberTypesModal
                isOpen={manageModalIsOpen}
                toggle={(success) => {
                    if(success){
                        refreshMemberProfile();
                    }
                    closeManage()
                }}
                memberTypes={shownMemberTypes}
                defaultMemberMemberTypeId={memberType.member_member_type_id}
                memberId={currentMember.member_id}
            />
        </>
    );
}

export default MemberOverviewMemberType;