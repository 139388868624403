import React from "react";
import {
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane

} from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import FormikEditable from '../../../../../components/formik/FormikEditable';
import { FormikInputText, FormikSelect } from '@spordle/formik-elements';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../../helpers/i18nHelper';
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import UserImg from "../../../../../components/UserImg";

// Context
import { TeamsContext } from '../../../../../contexts/TeamsContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import withContexts from '../../../../../helpers/withContexts';

// Language
import Translate from "@spordle/intl-elements";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { success, fail } from '@spordle/toasts';

class SidePanelOrganizationTeamTravelTypes extends React.Component{
    state = {
        activeTab: '1',
        isLoading: false,
    }

    toggleTab = (tab) => {
        if(this.state.activeTab !== tab){
            this.setState({ activeTab: tab });
        }
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <I18nHelperContext.Consumer>
                    {(i18nHelper) => (
                        <>
                            <SidePanel.Header noBorder>
                                <div className='d-flex mb-2 align-items-center'>
                                    <SidePanel.ToggleButton />
                                    <div className="ml-auto d-flex align-items-center">
                                        {this.props.isFederation && // hides
                                            <SidePanel.ActionsMenu>
                                                <SidePanel.MenuDelete
                                                    translateModalMsg
                                                    modalMsg='organization.settings.sidePanelOrganizationTeamTravelTypes.actions.remove.topText'
                                                    onConfirm={() => {

                                                        return this.props.TeamsContext.deleteTravelType(this.props.getCommonValue('travel_type_id'))
                                                            .then(() => {
                                                                this.props.tableRef.deleteRow(this.props.getCommonValue('travel_type_id')).then(this.props.toggle);
                                                            })
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                }
                                                            })
                                                    }}
                                                    modalContent={
                                                        <Row className='mt-3'>
                                                            <Col md='6' className='d-flex align-items-center justify-content-center flex-column border-right'>
                                                                <div className='font-medium'>
                                                                    <DisplayI18n
                                                                        field="name"
                                                                        i18n={this.props.getCommonValue('i18n')}
                                                                        defaultValue={this.props.getCommonValue('name')}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md='6' className='d-flex align-items-center justify-content-center flex-column'>
                                                                <div className='mr-2'>
                                                                    <UserImg
                                                                        src={this.props.OrganizationContext.logo?.full_path}
                                                                        filePos={this.props.OrganizationContext.logo?.file_position}
                                                                        alt={this.props.OrganizationContext.organisation_name}
                                                                        abbr={this.props.OrganizationContext.abbreviation}
                                                                    />
                                                                </div>
                                                                <div className='font-medium'>{this.props.OrganizationContext.organisation_name}</div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                />
                                            </SidePanel.ActionsMenu>
                                        }
                                    </div>
                                </div>
                                <SidePanel.Title>
                                    <DisplayI18n
                                        field='name'
                                        defaultValue={this.props.getCommonValue('name')}
                                        i18n={this.props.getCommonValue('i18n')}
                                    />
                                </SidePanel.Title>
                            </SidePanel.Header>

                            <Nav tabs>
                                <NavItem className="flex-grow-1 text-center w-100">
                                    <NavLink
                                        className={stringBuilder({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggleTab('1'); }}
                                    >
                                        <Translate id='organization.settings.sidePanelOrganizationTeamTravelTypes.tabs.details' />
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    {/* TEAM Travel TYPE INFO */}
                                    <div className='p-3 border-bottom'>
                                        <div className="h4 font-bold mb-3"><Translate id='organization.settings.sidePanelOrganizationTeamTravelTypes.title' /></div>
                                        <RenderI18nForm field='name'>
                                            {({ fieldName, fieldLabel }) => {
                                                return (
                                                    <div className='mb-3' key={fieldName}>
                                                        <div className='text-muted'>{fieldLabel}</div>
                                                        <FormikEditable
                                                            id={fieldName}
                                                            initialValues={i18nHelper.getInitialValues(this.props.selectedRows[0])}
                                                            validationSchema={Yup.object().shape(
                                                                i18nHelper.getValidationSchema({ name: Yup.string().required(<Translate id='form.validation.name.required' />) }),
                                                            )}
                                                            onSubmit={(values) => {

                                                                if(!i18nHelper.compare(fieldName, this.props.selectedRows[0].i18n, values)){
                                                                    this.setState(() => ({ isLoading: true }))

                                                                    const newValues = this.props.createNewValues(values);

                                                                    this.props.TeamsContext.updateTravelTypePartial(this.props.getCommonValue('travel_type_id'), i18nHelper.getAPIValues(values))
                                                                        .then(() => {
                                                                            this.props.syncRows(newValues);
                                                                            success();
                                                                            this.setState(() => ({ isLoading: false }))
                                                                        })
                                                                        .catch((error) => {
                                                                            if(!AxiosIsCancelled(error.message)){
                                                                                console.error(error.message)
                                                                                fail({
                                                                                    msg: 'misc.error',
                                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                    skipInfoTranslate: true,
                                                                                })
                                                                            }
                                                                            this.setState(() => ({ isLoading: false }))
                                                                        })
                                                                }

                                                            }}
                                                        >
                                                            {(isEditing) => {
                                                                if(!isEditing){
                                                                    return (
                                                                        <DisplayI18n
                                                                            field={fieldName}
                                                                            defaultValue={this.props.selectedRows[0].name || '-'}
                                                                            i18n={this.props.selectedRows[0].i18n}
                                                                        />
                                                                    )
                                                                }
                                                                return (
                                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                                )

                                                            }}
                                                        </FormikEditable>
                                                    </div>
                                                )
                                            }}
                                        </RenderI18nForm>

                                        {/* STATUS */}
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='organization.settings.sidePanelOrganizationTeamTravelTypes.status' /></div>
                                            <FormikEditable
                                                noConfirmation
                                                id={'active'}
                                                initialValues={{
                                                    active: this.props.getCommonValue("active"),
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    active: Yup.string().required(<Translate id='organization.settings.sidePanelOrganizationTeamTravelTypes.status.required' />),
                                                })}
                                                onSubmit={(values) => {
                                                    if(values.active !== this.props.getCommonValue("active")){
                                                        this.setState(() => ({ isLoading: true }))
                                                        const newValues = this.props.createNewValues(values);
                                                        this.props.TeamsContext.updateTravelTypePartial(this.props.getCommonValue('travel_type_id'), values)
                                                            .then(() => {
                                                                this.props.syncRows(newValues);
                                                                this.setState(() => ({ isLoading: false }))
                                                            })
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                }
                                                                this.setState(() => ({ isLoading: false }))
                                                            })
                                                    }
                                                }}
                                            >
                                                {(isEditing, options) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className="font-medium">
                                                                <i className={`mdi ${this.props.getCommonValue("active") == '1' ? 'mdi-check text-primary' : 'mdi-close text-danger'} mr-2`} />
                                                                <span><Translate id={`organization.settings.sidePanelOrganizationTeamTravelTypes.status.${this.props.getCommonValue("active") == '1' ? 'active' : 'inactive'}`} /></span>
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikSelect
                                                            name='active'
                                                            id='active_SidepanelOrganizationTeamTravelTypes'
                                                            search={false}
                                                            autoFocus
                                                            menuIsDefaultOpen
                                                            onOptionSelected={options.stopEditing}
                                                            loadingStatus='success'
                                                            defaultData={[
                                                                { id: 'active', label: 'organization.settings.sidePanelOrganizationTeamTravelTypes.status.active', translateLabel: true, value: '1', selected: this.props.getCommonValue("active") == '1' },
                                                                { id: 'inactive', label: 'organization.settings.sidePanelOrganizationTeamTravelTypes.status.inactive', translateLabel: true, value: '0', selected: this.props.getCommonValue("active") == '0' },
                                                            ]}
                                                        />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </>
                    )}
                </I18nHelperContext.Consumer>
            </OverlayLoader>
        )
    }
}

SidePanelOrganizationTeamTravelTypes.propTypes = {
    tableRef: PropTypes.object.isRequired,
    i18n: PropTypes.object,
}

export default withContexts(OrganizationContext, TeamsContext)(SidePanelOrganizationTeamTravelTypes);