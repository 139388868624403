import Translate, { DateFormat } from '@spordle/intl-elements';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import ConfirmModal from '../../../../../../../components/confirmModal/ConfirmModal';
import CreditInvoiceModal from '../../../../../../../components/CreditInvoiceModal';
import SidePanel from '../../../../../../../components/sidePanel/SidePanel';
import { fail, success } from '@spordle/toasts';
import { MembersContext } from '../../../../../../../contexts/MembersContext';
import { HoverableCopy } from '../../../../../../../helpers/clipboardHelper';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import ClinicProfileRegistrationAdd from '../../../../../../clinics/profile/attendees/addModal/ClinicProfileRegistrationAdd';
import OnlineStoreTransactionPaymentReceptionModal from '../../../../../../onlineStore/profile/transactions/components/modals/OnlineStoreTransactionPaymentReceptionModal';
import OnlineStoreTransactionRefundModal from '../../../../../../onlineStore/profile/transactions/components/modals/refundModal/OnlineStoreTransactionRefundModal';
import MemberProfileRegistrationAddContext from '../../../../registration/components/AddModal/MemberProfileRegistrationAddContext';
import MemberProfileItemCancel from '../../../../registration/components/CancelItems/MemberProfileItemCancel';
import generatePassword from '../../../../../../../helpers/passwordGenerator';
import { CartsContext } from '../../../../../../../contexts/CartsContext';
import { Tooltip } from '@mantine/core';

const TransactionHeader = ({ hideHeader, transaction, setForceRegistrationRefresh, onPaymentReception, disableActions, formatAndSyncRows, ...props }) => {
    const membersContext = useContext(MembersContext);
    const cartsContext = useContext(CartsContext)

    const isMinimalView = (membersContext.currentMember?.minimalistic_view == 1 && !props.onlineStoreView)

    const [ currentRefundAmounts, setCurrentRefundAmounts ] = useState({ 'payment_gateway': 0, 'manual': 0 });

    const [ cancelModalIsOpen, setCancelModalIsOpen ] = useState(false);
    const toggleCancelModal = () => setCancelModalIsOpen(!cancelModalIsOpen);

    const [ refundModalIsOpen, openRefundModal ] = useState(false);
    const toggleRefundModal = () => { openRefundModal(!refundModalIsOpen) };

    const [ memberFunding, setMemberFunding ] = useState([]);
    const [ memberFundingLoading, setMemberFundingLoading ] = useState(false)

    const [ mode, setMode ] = useState('');
    const modes = {
        refundToCancel: 'refundToCancel',
        cancelToRefund: 'cancelToRefund',
    }

    useEffect(() => {
        getFundingAmounts();
        membersContext.getRefundAmounts(transaction.invoice_number)
            .then((refundAmounts) => {
                setCurrentRefundAmounts(refundAmounts);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });
    }, [ transaction.invoice_number, props.selectedRows[0]?.available_refund_amount ]);

    const isRegistration = !!transaction.invoice_items.find((item) => item.registration_fee) || !!transaction.invoice_items.find((item) => item.other_fee);

    const canConvert = transaction.invoice_items.every((item) => {
        const initialPayment = item.payments.find((p) => p.payment_type === 'INITIAL_PAYMENT');
        if(initialPayment){
            return initialPayment.status === 'PENDING' || initialPayment.status === 'PARTIALLY_PAID'
        } // no initial payment (affiliation fees, qualification fees, etc.)
        return true

    }) && transaction.invoice_payment_method?.code !== 'CREDITCARD';
    const canConvertToManual = transaction.invoice_items.every((item) => {
        const initialPayment = item.payments.find((p) => p.payment_type === 'INITIAL_PAYMENT');
        if(initialPayment){
            return initialPayment.status === 'PENDING' || initialPayment.status === 'PARTIALLY_PAID'
        } // no initial payment (affiliation fees, qualification fees, etc.)
        return true

    }) && transaction.invoice_payment_method?.code === 'CREDITCARD';

    const [ paymentReceptionModalIsOpen, openPaymentReceptionModal ] = useState(false);
    const togglePaymentReceptionModal = () => { openPaymentReceptionModal(!paymentReceptionModalIsOpen) };

    const [ transactionType, setTransactionType ] = useState('');
    const [ exchangeModal, exchangeModalIsOpen ] = useState(false);
    const toggleExchangeModal = (type) => {
        setTransactionType(type);
        exchangeModalIsOpen(!exchangeModal)
    };
    const [ convertModal, convertModalIsOpen ] = useState(false);
    const [ convertMode, setConvertMode ] = useState('');
    const toggleConvertModal = (type, mode) => {
        setTransactionType(type);
        setConvertMode(mode || '');
        convertModalIsOpen(!convertModal)
    };

    const getFundingAmounts = () => {
        if(transaction.status === 'COMPLETED'){
            const members = [];
            transaction.invoice_items.forEach((item) => {
                if(!members.find((m) => m.member_id === item.member.member_id))
                    members.push(item.member)
            })

            if(members.length > 0){
                setMemberFundingLoading(true)
                Promise.all(members.map((m) => {
                    return membersContext.getAvailableToBeFunded(transaction.invoice_number, { memberId: m.member_id })
                        .then((fundingAmount) => fundingAmount || 0)
                }))
                    .then((funding) => {
                        setMemberFunding(funding.map((fundVal, index) => {
                            return ({
                                fundingValue: fundVal,
                                label: `${members[index].first_name} ${members[index].last_name}`,
                                value: generatePassword(),
                                memberId: members[index].member_id,
                            });
                        }))
                        setMemberFundingLoading(false)
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                            setMemberFundingLoading(false)
                        }
                    })
            }
        }
    }

    const toggleModal = () => {
        if(exchangeModal){
            toggleExchangeModal();
        }else if(convertModal){
            toggleConvertModal();
        }
        setTransactionType('')
    }

    const [ creditInvoiceModalIsOpen, setCreditInvoiceModalIsOpen ] = useState(false);
    const toggleCreditInvoiceModal = () => setCreditInvoiceModalIsOpen(!creditInvoiceModalIsOpen);

    const history = useHistory();
    return (
        <>
            {!hideHeader &&
                <div className='d-flex align-items-center mb-2'>
                    {refundModalIsOpen &&
                        <OnlineStoreTransactionRefundModal
                            {...props}
                            chainWithCancel={mode === modes.refundToCancel}
                            refreshTable={() => { formatAndSyncRows().then(setForceRegistrationRefresh); }}
                            transaction={transaction}
                            isOpen={refundModalIsOpen}
                            toggle={() => {
                                toggleRefundModal();
                            }}
                            onCancelRegistration={() => {
                                toggleRefundModal();
                                toggleCancelModal();
                            }}
                            cancelPermissions={{ action: "EDIT", componentCode: "members", componentPermissionCode: "members_registrations" }}
                        />
                    }
                    {paymentReceptionModalIsOpen &&
                        <OnlineStoreTransactionPaymentReceptionModal
                            {...props}
                            allPaymentMethods={transaction.origin == 'ADMIN'}
                            onPaymentReception={onPaymentReception}
                            transaction={transaction}
                            isOpen={paymentReceptionModalIsOpen}
                            toggle={togglePaymentReceptionModal}
                            memberFunding={memberFunding}
                            memberFundingLoading={memberFundingLoading}
                            refreshTable={props.refreshTable}
                            getFundingAmounts={getFundingAmounts}
                        />
                    }
                    <MemberProfileItemCancel
                        chainWithRefund={mode === modes.cancelToRefund}
                        refreshTable={() => {
                            formatAndSyncRows().then(setForceRegistrationRefresh);
                        }}
                        toggle={() => {
                            toggleCancelModal();
                        }}
                        toggleRefund={() => toggleRefundModal()}
                        onCancel={() => {
                            formatAndSyncRows().then(setForceRegistrationRefresh)
                        }}
                        invoiceItems={transaction.invoice_items}
                        isOpen={cancelModalIsOpen}
                        availableRefund={currentRefundAmounts}
                        cancelPermissions={{ action: "EDIT", componentCode: "members", componentPermissionCode: "members_registrations" }}
                    />
                    {(exchangeModal || convertModal) &&
                        (transactionType === 'REGISTRATION' || transactionType === 'OTHER') ?
                        <MemberProfileRegistrationAddContext
                            isOpen={exchangeModal || convertModal}
                            toggle={() => {
                                toggleModal();
                            }}
                            closeModal={toggleModal}
                            refreshTable={formatAndSyncRows} // replaced these with formatAndSyncRows because the component is used in other places, therefore I don't want ot modify the component itself
                            setForceRefresh={setForceRegistrationRefresh}
                            invoiceItems={exchangeModal ? transaction.invoice_items : undefined}
                            invoiceNumber={convertModal ? transaction.invoice_number : undefined}
                            convertToTransactional={convertMode === 'transactional'}
                        />
                        : transactionType === 'CLINIC' ?
                            <ClinicProfileRegistrationAdd
                                isOpen={exchangeModal || convertModal}
                                toggle={() => {
                                    toggleModal();
                                }}
                                refreshTable={formatAndSyncRows} // replaced these with formatAndSyncRows because the component is used in other places, therefore I don't want ot modify the component itself
                                setForceRefresh={setForceRegistrationRefresh}
                                invoiceItemId={exchangeModal ? transaction.invoice_items.find((item) => item.clinic)?.invoice_item_id : undefined}
                                currentClinicId={exchangeModal ? transaction.invoice_items.find((item) => item.clinic)?.clinic.clinic_id : undefined}
                                invoiceNumber={convertModal ? transaction.invoice_number : undefined}
                                convertToTransactional={convertMode === 'transactional'}
                                member={transaction.invoice_items.find((item) => item.member)?.member}
                            />
                            : null
                    }
                    <CreditInvoiceModal
                        isOpen={creditInvoiceModalIsOpen}
                        toggle={() => {
                            toggleCreditInvoiceModal();
                        }}
                        invoiceNumber={transaction.invoice_number}
                        onCreditInvoice={() => {
                            formatAndSyncRows().then(setForceRegistrationRefresh);
                        }}
                    />

                    <SidePanel.ToggleButton />
                    {/* We only have one action for the moment */}
                    {/* TODO: When we add an action that needs a different permission, wrap the specific action in its CanDoAction */}
                    <SidePanel.ActionsMenu componentCode='members' componentPermissionCode='member_transactions' action='ADD'>

                        {/* VIEW INVOICE  */}
                        <SidePanel.MenuAction onClick={() => history.push('invoice?invoiceNumber=' + transaction.invoice_number)}>
                            <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.viewInvoice' />
                        </SidePanel.MenuAction>

                        {/* CONFIRM PAYMENT */}
                        {transaction.need_confirmation == 1 &&
                            <ConfirmModal
                                color='primary'
                                icon='mdi-check-circle-outline'
                                translateActionButtonText
                                actionButtonText='memberProfile.registration.confirmRegistration.confirm.btn'
                                onConfirm={async() => {
                                    await cartsContext.confirmInvoicePayment(transaction.invoice_number)
                                        .then(async() => {
                                            await formatAndSyncRows();
                                            setForceRegistrationRefresh();
                                            success({ msg: 'memberProfile.registration.sidepanel.confirmPayment.success' });
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                if(error.i18n){
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }else{
                                                    fail({ msg: 'memberProfile.registration.sidepanel.confirmPayment.error' });
                                                }
                                            }
                                        })
                                }}
                                modalTitle={<Translate id='memberProfile.registration.sidepanel.confirmPayment.confirm' />}
                                toggler={(fn) => (
                                    <SidePanel.MenuAction onClick={fn}>
                                        <Translate id='memberProfile.registration.sidepanel.confirmPayment' />
                                    </SidePanel.MenuAction>
                                )}
                            />
                        }

                        {/* APPLY PAYMENT / RECEIVE PAYMENT  */}
                        {((transaction.invoice_items.find((item) => item.payments_to_display?.find((payment) => payment.payment_method?.code !== 'CREDITCARD')) || transaction.origin == 'ADMIN') || memberFunding.some((fund) => fund.fundingValue > 0)) &&
                            // only show for manual payment methods -> admin is always manual payment
                            <SidePanel.MenuAction onClick={togglePaymentReceptionModal} disabled={disableActions || (transaction.due <= 0 && memberFunding.every((fund) => fund.fundingValue == 0))}>
                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.paymentReception' />
                            </SidePanel.MenuAction>
                        }

                        <SidePanel.MenuAction divider />

                        {/* CANCEL ITEMS */}
                        {isRegistration &&
                            <SidePanel.MenuAction
                                onClick={() => {
                                    setMode(modes.cancelToRefund)
                                    toggleCancelModal();
                                }}
                                disabled={transaction.invoice_items.every((item) => item.cancelled_at) || disableActions}
                            >
                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.cancelItems' />
                            </SidePanel.MenuAction>
                        }

                        {/* EXCHANGE ITEMS */}
                        {(!isMinimalView && transaction.type !== 'AFFILIATION') &&
                            <SidePanel.MenuAction
                                onClick={() => toggleExchangeModal(transaction.type)}
                                disabled={
                                    transaction.refunds.length > 0 ||
                                    !!transaction.invoice_items.find((item) => item.registration_fee)?.payments.find((payment) => payment.payment_type === 'INSTALLMENT' && payment.status === 'PENDING' && payment.payment_method?.code === "CREDITCARD") ||
                                    disableActions
                                }
                            >
                                <Translate id={transaction.type === 'CLINIC' ? 'memberProfile.registration.sidepanel.transfer' : 'memberProfile.registration.sidepanel.exchangeItems'} />
                            </SidePanel.MenuAction>
                        }

                        {/* REFUND */}
                        <SidePanel.MenuAction
                            onClick={() => {
                                setMode(modes.refundToCancel)
                                toggleRefundModal();
                            }}
                            disabled={(parseInt(currentRefundAmounts?.payment_gateway) <= 0 && parseInt(currentRefundAmounts?.manual) <= 0) || disableActions}
                        >
                            <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.refund' />
                        </SidePanel.MenuAction>

                        {/* CANCEL INSTALLMENTS */}
                        {/* {hasInstallments &&
                            <SidePanel.MenuAction
                                onClick={() => {
                                    setOnlyCancelInstallments(true);
                                    toggleRefundModal();
                                }}
                            >
                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.cancelInstallments'/>
                                {transaction.invoice_payment_method !== 'CREDITCARD' &&
                                    <div className='small'>
                                        <div><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.cancelInstallments.disabled.1'/></div>
                                        <div><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.cancelInstallments.disabled.2'/></div>
                                    </div>
                                }
                            </SidePanel.MenuAction>
                        } */}

                        {/* Convert to transactional */}
                        {(canConvert && transaction.is_pre_init == 0) &&
                            <SidePanel.MenuAction
                                onClick={() => toggleConvertModal(transaction.type, 'transactional')}
                                disabled={disableActions}
                            >
                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.convertInvoice' />
                            </SidePanel.MenuAction>
                        }

                        {/* Convert to manual */}
                        {canConvertToManual &&
                            <SidePanel.MenuAction
                                onClick={() => toggleConvertModal(transaction.type, 'manual')}
                                disabled={disableActions}
                            >
                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.convertInvoiceManual' />
                            </SidePanel.MenuAction>
                        }

                        {/* VOID INVOICE */}
                        <ConfirmModal
                            modalContent={
                                <>
                                    <div className='font-bold h4'>
                                        <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.void.title' />
                                    </div>
                                    <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.void.text' />
                                </>
                            }
                            actionButtonText='misc.confirm'
                            translateActionButtonText
                            onConfirm={async() => {
                                return membersContext.voidInvoice(transaction.invoice_number)
                                    .then(async() => {
                                        await formatAndSyncRows();
                                        setForceRegistrationRefresh();
                                        success();
                                    })
                                    .catch((e) => {
                                        if(!AxiosIsCancelled(e.message)){
                                            console.error(e.message)
                                            fail({
                                                msg: <Translate id={'members.profile.transactions.memberProfileTransactions.sidePanel.actions.void.errors.' + e.message} defaultMessageId='misc.error' />,
                                                skipMsgTranslate: true,
                                            });
                                        }
                                    })
                            }}
                            toggler={(fn) => (
                                <SidePanel.MenuAction
                                    onClick={fn}
                                    disabled={parseInt(transaction.paid) !== 0 || disableActions}
                                    action='ADD'
                                    componentCode='members'
                                    componentPermissionCode='member_invoice_void'
                                >
                                    <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.void' />
                                </SidePanel.MenuAction>
                            )
                            }
                        />

                        {/* CREDIT INVOICE */}
                        <SidePanel.MenuAction
                            onClick={toggleCreditInvoiceModal}
                            disabled={(transaction.status !== "PENDING" && transaction.status !== "PARTIALLY_PAID") || disableActions || transaction.due == 0}
                        >
                            <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.credit' />
                        </SidePanel.MenuAction>

                        <SidePanel.MenuAction divider />

                        {/* RESEND INVOICE */}
                        <SidePanel.MenuAction
                            onClick={() => {
                                membersContext.resendInvoice([ transaction.invoice_number ], false)
                                    .then(() => {
                                        success();
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            if(error.i18n){
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }else{
                                                fail({ msg: 'members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice.error' });
                                            }
                                        }
                                    })
                            }}
                            disabled={disableActions}
                        >
                            <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice' />
                        </SidePanel.MenuAction>

                        {/* RESEND INVOICE WITH RECEIPT */}
                        <SidePanel.MenuAction
                            onClick={() => {
                                membersContext.resendInvoice([ transaction.invoice_number ], true)
                                    .then(() => {
                                        success();
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            if(error.i18n){
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }else{
                                                fail({ msg: 'members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice.error' });
                                            }
                                        }
                                    })
                            }}
                            disabled={disableActions || (transaction.status !== "COMPLETED" && transaction.status !== "PARTIALLY_PAID")}
                        >
                            <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoiceWithReceipt' />
                        </SidePanel.MenuAction>
                    </SidePanel.ActionsMenu>
                </div>
            }
            <div>
                <div className="d-flex align-items-center">
                    <SidePanel.Title className="mr-1">
                        <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.header.invoice' />
                    </SidePanel.Title>
                    <Badge color={(transaction.status === 'COMPLETED') ? 'success' : (transaction.status === 'PENDING' || transaction.status === 'NEED_REVIEW' || transaction.status === 'PARTIALLY_PAID') ? 'warning' : 'danger'} size="lg" pill className="px-3 align-self-start">
                        <Translate id={'members.profile.transactions.memberProfileTransactions.sidePanel.status.' + transaction.status} />
                    </Badge>
                </div>
                <HoverableCopy toCopy={transaction.invoice_number}>
                    {transaction.type !== "WAIVER" ?
                        <Link to={'invoice?invoiceNumber=' + transaction.invoice_number}>#{transaction.invoice_number}</Link>
                        :
                        <span>#{transaction.invoice_number}</span>
                    }
                </HoverableCopy>
                {transaction.identity &&
                    <div className="text-muted">
                        <Translate id='members.profile.transactions.memberProfileTransactions.paidBy' /> {transaction.identity.name + ' ' + transaction.identity.family_name} (<DisplayI18n field='name' defaultValue={transaction.invoice_payment_method?.name} i18n={transaction.invoice_payment?.i18n} />)
                    </div>
                }
                {/* Show who initiated a pre-init */}
                {transaction.is_pre_init == 1 &&
                    <div>
                        <Tooltip
                            zIndex={3000}
                            withArrow label={
                                <>
                                    <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_init.tooltip' />
                                    <span className='ml-1'>{transaction.initiated_by?.name} {transaction.initiated_by?.family_name}:</span>
                                    <span className='ml-1'><DateFormat value={transaction.created_at} /></span>
                                </>
                            }
                        >

                            <div className="text-muted">
                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_init' />
                                <i className='mdi mdi-information-outline text-primary ml-1' />
                            </div>
                        </Tooltip>
                    </div>
                }
                {/* Show who initiated a pre-authorized  */}
                {transaction.need_confirmation == '1' &&
                    <div>
                        <Tooltip
                            zIndex={3000}
                            withArrow label={
                                <>
                                    <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_auth.tooltip' />
                                    <span className='ml-1'>{transaction.initiated_by?.name} {transaction.initiated_by?.family_name}:</span>
                                    <span className='ml-1'><DateFormat value={transaction.created_at} /></span>
                                </>
                            }
                        >

                            <div className="text-muted">
                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_auth' />
                                <i className='mdi mdi-information-outline text-primary ml-1' />
                            </div>
                        </Tooltip>
                    </div>
                }
                <div className="text-muted">
                    <div>
                        <Translate id={`members.profile.transactions.memberProfileTransactions.sidePanel.header.transactionType.${transaction.origin == 'ADMIN' ? 'manual' : 'online'}`} />
                    </div>
                    <div>
                        <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.header.initialPaymentDate' />: <DateFormat value={transaction.invoice_date} />
                    </div>
                </div>
            </div>
        </>
    );
}

TransactionHeader.propTypes = {
    hideHeader: PropTypes.bool,
    transaction: PropTypes.object.isRequired,
};

export default TransactionHeader;