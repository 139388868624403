import SubRouteRenderer from '../../components/subSidebar/SubRouteRenderer';
import settingsRoutes from '../../routes/routerRoutes/settingsRoutes';

const Settings = () => {
    return (
        <div className='page-content ml-0 px-0 pt-0'>
            <SubRouteRenderer routes={settingsRoutes} />
        </div>
    );
}

export default Settings;