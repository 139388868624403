import { Component } from 'react';

import API_SPORDLE from "../api/API-Spordle";
import { AxiosIsCancelled, serverError } from '../api/CancellableAPI';
import queryString, { stringifyUrl } from 'query-string';

import images from '@spordle/ui-kit';
import Axios from 'axios';
import { localStorageIdentityRoleId } from './IdentityRolesContext';
import { localStorageOrganizationId } from './OrganizationContext';
import { I18nContext } from './I18nContext';
import { AuthContext, AppContext } from './contexts';

import { getSessionStorageItem, removeLocalStorageItem, removeSessionStorageItem, setSessionStorageItem } from '../helpers/browserStorage';
import { setUser } from '@sentry/react';
import { setGAUser, setGAUserProperties } from '@spordle/ga4';
import getAccountUrl from '../helpers/getExternalUrl';
import moment from 'moment';
import { DisplayI18n } from '../helpers/i18nHelper';
import { fail } from "@spordle/toasts";
import withContexts from '../helpers/withContexts';
import { isSidMultiSport } from '../helpers/getReferer';

const noProfileLogo = images.noprofile;

class AuthContextProvider extends Component{
    static contextType = I18nContext;

    constructor(props){
        super(props);
        this.state = this._initState();

        if(window.frameElement && window.frameElement?.getAttribute('authcontext'))
            API_SPORDLE.defaults.headers.common['X-Access-Token'] = this.state.accessToken;
    }

    /**
     * @type {Array}
     * The cached identity roles for the logged in user
     */
    userIdentities;

    _initState = () => {
        // QuickView - Load state data loaded in iframe element
        if(window.frameElement && window.frameElement.getAttribute('authcontext')){
            return JSON.parse(window.frameElement.getAttribute('authcontext'));
        }

        return {
            isAuthenticated: false,
            account: {},
            primaryMeta: null,
            accessToken: null,
            type: null,
        }
    };

    setCurrentState = (state) => this.setState(() => ({ ...state }), () => API_SPORDLE.defaults.headers.common['X-Access-Token'] = this.state.accessToken);

    componentDidUpdate(){
        if(this.state.account.userName){
            const attributes = { ...this.state.account };
            delete attributes.logo;
            delete attributes.sub;
            setUser({
                email: this.state.account.email,
                id: this.state.account.userName,
                username: this.state.account.userName,
                attributes: attributes,
            });
            setGAUser(this.state.account.userName);
            setGAUserProperties(attributes);
        }else{
            setUser(null);
            setGAUser(null);
            setGAUserProperties(null);
        }
    }

    /**
     * Log out the user
     * @returns {Promise}
     */
    logout = () => {
        const deleteParam = new URLSearchParams();
        deleteParam.append('access_token', this.state.accessToken);
        const clearLogout = () => {
            removeLocalStorageItem(localStorageIdentityRoleId);
            removeLocalStorageItem(localStorageOrganizationId);
            removeLocalStorageItem('periodId');
            removeSessionStorageItem('initializedNews');
            removeSessionStorageItem('demo-accessToken');
            window.location.href = stringifyUrl({
                url: getAccountUrl(),
                query: {
                    referrer: isSidMultiSport() ? location.origin : null,
                },
            }, {
                skipNull: true,
            });
        }
        return API_SPORDLE.delete('/authentication/users/sign-out', { data: deleteParam })
            .then((response) => {
                if(response.data.status || response.data.errors.code === 'NotAuthorizedException'){
                    clearLogout();
                    return;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    isPrank = () => {
        const prankUsers = [
            'jpmichaud@spordle.com',
            'atouillet@spordle.com',
            'scooper@spordle.com',
            'frousselmorin@spordle.com',
            'gmichaud@spordle.com',
            'mlabelle@spordle.com',
            'ogalipeau@spordle.com',
            'amenard@spordle.com',
            'lessiass@spordle.com',
            'sbouchard@spordle.com',
            'mbriere@spordle.com',
            'yguerni@spordle.com',
            'flafrance@spordle.com',
            'apoitras@spordle.com',
            'kthomas@spordle.com',
            'gcossette@spordle.com',
        ];
        const aprilFools = moment('04-01', 'MM-DD');
        return !getSessionStorageItem('disablePrank') &&
            aprilFools.isSame(new Date(), 'days') &&
            aprilFools.isSame(new Date(), 'months') &&
            prankUsers.includes(this.state.account.email);
    }

    disablePrank = () => {
        setSessionStorageItem('disablePrank', true);
        this.forceUpdate();
    }

    /**
     * Update the state with the new one
     * @param {object} newState
     */
    updateState = (newState) => {
        this.setState(() => (newState));
    }

    /**
     * Will get the users info and put them is this context's state
     * @returns {Promise}
     */
    authenticate = () => {
        return API_SPORDLE.get('/authentication/users?access_token=' + this.state.accessToken)
            .then((response) => {
                if(response.data.status){
                    const attributes = {};

                    response.data.result.UserAttributes?.forEach((attribute) => {
                        attributes[attribute.Name] = attribute.Value;
                    });

                    attributes.userName = response.data.result.Username;
                    attributes.logo = noProfileLogo;
                    this.context.setLocale(attributes.locale);

                    this.setState(() => ({
                        account: attributes,
                        isAuthenticated: true,
                    }));

                    return response.data.result;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Get a list of Cognito users with a filter by field (name, email, etc.)
     * @param {string} field Filter name (username, email, phone_number, name, given_name or family_name)
     * @param {string} type Filter type (= for exact match, ^= for prefix match)
     * @param {string} value Look for a specified value
     * @returns {Promise}
     */
    getUsersList = (field, type, value) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/authentication/users/list`, query: { filter_name: field, filter_type: type, filter_value: value } }))
            .then((response) => {
                if(response.data.status){
                    if(response.data.result.Users.length > 0){
                        const userArray = [];

                        response.data.result.Users.forEach((user) => {
                            const attributes = {};
                            user.Attributes.forEach((attribute) => {
                                attributes[attribute.Name] = attribute.Value
                            })
                            delete user.Attributes
                            userArray.push({
                                ...user,
                                ...attributes,
                            })
                        })

                        response.data.result.Users = userArray;
                    }
                    return response.data.result;
                }
            }, serverError)
    }

    /**
     * @typedef {Object} User
     * Object to contain user information
     * @param {string} name User's First name
     * @param {string} familyName User's Last name
     * @param {string} email User's Email address
     * @param {string} phoneNumber User's Phone Number
     * @example
     * this.props.AuthContext.createUser({name: firstName, familyName: lastName, email: email, phoneNumber: phone})
    */

    /**
     * Create a user in the Cognito user pool
     * @param {User} user
     * @returns {Promise}
     */
    createUser = (user) => {
        const params = new URLSearchParams();
        params.append('email', user.email);
        params.append('name', user.name);
        params.append('family_name', user.familyName);
        if(user.phoneNumber)
            params.append('phone_number', user.phoneNumber);
        if(user.address)
            params.append('address', user.address);
        if(user.birthdate)
            params.append('birthdate', user.birthdate);
        if(user.gender)
            params.append('gender', user.gender);
        if(user.locale)
            params.append('locale', user.locale);
        if(user.language_code)
            params.append('language_code', user.language_code);
        if(user.picture)
            params.append('picture', user.picture);
        if(user.action)
            params.append('action', user.action);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/authentication/users` }), params)
            .then((response) => {
                if(response.data.status){
                    const attributes = {};
                    response.data.result.Attributes.forEach((attribute) => {
                        attributes[attribute.Name] = attribute.Value;
                    })

                    return ({
                        ...response.data.result,
                        ...attributes,
                    })
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a specific user with the given values
     * @param {string|Array<string>} userId One or multiple User IDS to update
     * @param {Object} values Every value for a user
     * @returns {Promise}
     */
    updateUser = (userId, values) => {
        const buildParams = (rowValues) => {
            const data = new URLSearchParams();

            for(const key in rowValues){
                // eslint-disable-next-line no-prototype-builtins
                if(rowValues.hasOwnProperty(key)){
                    switch (key){
                        case 'email':
                            data.append('email', rowValues[key]);
                            break;
                        case 'name':
                            data.append('name', rowValues[key]);
                            break;
                        case 'family_name':
                            data.append('family_name', rowValues[key]);
                            break;
                        case 'address':
                            data.append('address', rowValues[key]);
                            break;
                        case 'birthdate':
                            data.append('birthdate', rowValues[key]);
                            break;
                        case 'gender':
                            data.append('gender', rowValues[key]);
                            break;
                        case 'locale':
                            data.append('locale', rowValues[key]);
                            break;
                        case 'phone_number':
                            data.append('phone_number', rowValues[key]);
                            break;
                        case 'picture':
                            data.append('picture', rowValues[key]);
                            break;
                        case 'action':
                            data.append('action', rowValues[key]);
                            break;
                        default:
                            break;
                    }
                }
            }
            return data;
        }

        if(Array.isArray(userId)){ // Batch update
            return Axios.all(userId.map((id) => {
                return API_SPORDLE.put(queryString.stringifyUrl({ url: `authentication/users/${id}` }), buildParams(values.find((dataValue) => dataValue.identity_id === id)));
            }))
                .then((Axios.spread((...responses) => {
                    if(responses.every((response) => response.data.status)){
                        return;
                    }
                    throw responses[0].data.errors[0];
                }, serverError)));
        }
        // Single update
        return API_SPORDLE.put(queryString.stringifyUrl({ url: `authentication/users/${userId}` }), buildParams(values))
            .then((response) => {
                if(response.data.status){
                    return response.data.result;
                }
                throw response.data.errors[0];
            }, serverError)

    }

    /**
     * Returns every user information from a specific user from the Cognito user pool
     * @param {string} userId The User ID (identity_id)
     * @returns {Promise}
     */
    getUser = (userId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `authentication/users/${userId}` }))
            .then((response) => {
                if(response.data.status){
                    const attributes = {};
                    response.data.result.UserAttributes.forEach((attribute) => {
                        attributes[attribute.Name] = attribute.Value;
                    })

                    return ({
                        ...response.data.result,
                        ...attributes,
                    })
                }
                throw response.data.errors[0];
            })
    }

    /**
     * Gets the primary meta member of the identity
     * @param {string} identityId ID of the Identity to get Meta Members from
     * @returns {Promise<object>}
     */
    getPrimaryMeta = (identityId = this.state.account.userName, fromApi = false) => {
        if(!fromApi && this.state.primaryMeta){
            return Promise.resolve(this.state.primaryMeta);
        }

        return this.getMetaMembers(identityId, { is_primary: 1 })
            .then((meta) => {
                const primary = meta.find((m) => m.is_primary == "1");

                this.setState({ primaryMeta: primary });

                return primary;
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    /**
     * Gets all the Meta Members from a specified Identity
     * @param {string} identityId ID of the Identity to get Meta Members from
     * @param {object} [queryParams] Query params of the call {@link https://api.id.dev.spordle.dev/documentations/#/Accounts/Apicontroller%5CAccounts%5CAccounts%3A%3AgetMetaMembers|documentation}
     * @returns {Promise}
     */
    getMetaMembers = (identityId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/meta-members`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.meta_members;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Sets this context,s state with the data returned from Access
     * @param {object} accessInfo Access info looking like this -> {accessToken: accessToken, type: type}
     * @param {function} callback Function to execute after the setState
     */
    setAccessInfo = (accessInfo, callback) => {
        this.setState(() => accessInfo, callback);
    }

    /**
     * @returns {boolean} If the there is an accessToken in the state or not
     */
    hasToken = () => !!this.state.accessToken

    /**
     * Gets the current user's identity roles
     * @returns {Promise}
     */
    getUserIdentityRole = (fromApi = false) => {
        if(!fromApi && this.userIdentities){
            return Promise.resolve(this.userIdentities);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/authentication/users/${this.state.account.userName}/roles` }))
            .then((response) => {
                if(response.data.status){
                    return this.userIdentities = response.data.identity_roles;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updated the user information in Cognito
     * @param {object} newInfo
     * @returns {Promise}
     */
    updateUserInfo = (newInfo) => {
        const data = new URLSearchParams();
        data.append('access_token', this.state.accessToken);
        data.append('email', newInfo.email);
        data.append('name', newInfo.name);
        data.append('family_name', newInfo.family_name);
        // Optional params
        if(newInfo.gender)
            data.append('gender', newInfo.gender);
        if(newInfo.birthdate)
            data.append('birthdate', newInfo.birthdate);
        if(newInfo.phone_number)
            data.append('phone_number', newInfo.phone_number);
        if(newInfo.locale)
            data.append('locale', newInfo.locale);
        if(newInfo.language_code)
            data.append('language_code', newInfo.language_code);
        if(newInfo.front_end_custom_parameters !== undefined)
            data.append('front_end_custom_parameters', newInfo.front_end_custom_parameters)

        return API_SPORDLE.put('authentication/users', data)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0];
            }, serverError);
    }


    /**
     * Will set a temporary email to the user corresponding to the given email
     * @param {string} userEmail User's email we want to set a password
     * @returns {Promise}
     */
    unlockUser = (userName) => {
        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/authentication/users/${userName}/unlock` }))
            .then(() => {
                return true;
            }, serverError)
    }


    /**
     * Admin confirm signup
     * @param {string} userEmail User's email
     * @returns {Promise}
     */
    adminConfirmSignup = (userName) => {
        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/authentication/users/${userName}/confirm-sign-up` }))
            .then(() => {
                return true;
            }, serverError)
    }

    render(){
        return (
            <AuthContext.Provider value={{
                ...this.state,
                ...this,
            }}
            >
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export default withContexts(AppContext)(AuthContextProvider);