export default{
    "organization.settings.organizationPeriods.table.startDate"     : "Start Date",
    "organization.settings.organizationPeriods.table.endDate"       : "End Date",
    "organization.settings.organizationPeriods.table.active"        : "Active",
    "organization.settings.organizationPeriods.table.display_order" : "Display Order",
    "organization.settings.organizationPeriods.table.empty"         : "No Periods",

    // add
    "organization.settings.organizationPeriods.add.title"                  : "Add Period",
    "organization.settings.organizationPeriods.add.startDate"              : "Start Date",
    "organization.settings.organizationPeriods.add.startDate.format"       : "Start Date must be of a valid format",
    "organization.settings.organizationPeriods.add.startDate.required"     : "Start Date is required",
    "organization.settings.organizationPeriods.add.startDate.before"       : "The Start Date must be before the End Date",
    "organization.settings.organizationPeriods.add.endDate"                : "End Date",
    "organization.settings.organizationPeriods.add.endDate.format"         : "End Date must be of a valid format",
    "organization.settings.organizationPeriods.add.endDate.required"       : "End Date is required",
    "organization.settings.organizationPeriods.add.sharedDate"             : "Shared Date",
    "organization.settings.organizationPeriods.add.sharedDate.format"      : "Shared Date must be of a valid format",
    "organization.settings.organizationPeriods.add.calculationDate"        : "Calculation Date",
    "organization.settings.organizationPeriods.add.calculationDate.format" : "Calculation Date must be of a valid format",
    "organization.settings.organizationPeriods.add.status"                 : "Status",
    "organization.settings.organizationPeriods.add.status.active"          : "Active",
    "organization.settings.organizationPeriods.add.status.inactive"        : "Inactive",
    "organization.settings.organizationPeriods.add.status.required"        : "Status is required",
}