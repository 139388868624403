import { TravelPermitAddContext } from "../TravelPermitAddContext";
import TravelPermitAddLineup from "./TravelPermitAddLineup";
import { useContext } from "react";

const TravelPermitAddLineupStep = ({ next, previous }) => {
    const { setData, state } = useContext(TravelPermitAddContext);

    return (
        <TravelPermitAddLineup
            initialValues={{
                members: state.formData.members,
            }}
            previous={previous}
            teamId={state.formData.team_id}
            onSubmit={(values) => {
                setData(values);
                next();
                return Promise.resolve()
            }}
        />
    );
}

export default TravelPermitAddLineupStep;