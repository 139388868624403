import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { I18nContext } from "../../contexts/I18nContext";
import { IdentityRolesContext } from "../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { ReportsContext } from "../../contexts/ReportsContext";
import { handleExcelExport, removeNullValues } from "../../helpers/reportsHelper";
import CrossFade from "../crossFade/CrossFade";

const HasMoreRowsDownload = ({ hasMoreRows, filters, className }) => {
    const reportsContext = useContext(ReportsContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const i18nContext = useContext(I18nContext);
    const organizationContext = useContext(OrganizationContext);
    const reportType = reportsContext.currentReport.code;


    return (
        <CrossFade isVisible={!!hasMoreRows} className={className}>
            <div
                className="bg-light mt-2 p-2 rounded d-flex clickable"
                onClick={() => {
                    handleExcelExport(
                        () => {},
                        reportType,
                        removeNullValues(filters()),
                        9999,
                        reportsContext,
                        identityRolesContext.identity_role_id,
                        identityRolesContext.identity.identity_id,
                        i18nContext.getGenericLocale(),
                        organizationContext.organisation_id,
                    )
                }}
            >
                <div className="d-flex align-items-center mr-1">
                    <i className="mr-1 mdi mdi-information-outline text-primary" style={{ fontSize: 18 }} />
                </div>
                <div className='w-100 align-self-center'>
                    <div className="font-medium text-dark">
                        <Translate id='reports.limit.message1' />
                        <button
                            type='button'
                            className='reset-btn text-link font-bold mr-1'
                        >
                            <Translate id='reports.limit.message2' />
                        </button>
                        <Translate id='reports.limit.message3' />
                    </div>
                </div>
                <div
                    className='text-right ml-auto p-2 clickable'
                >
                    <div>
                        <i className='mr-1 mdi mdi-download text-primary' style={{ fontSize: 18 }} />
                    </div>
                </div>
            </div>
        </CrossFade>
    )

}

export default HasMoreRowsDownload