export default{
    "teams.profile.title" : "Profil d'équipe",

    "teams.profile.label.organization"               : "Organisation",
    "teams.profile.label.tournament"                 : "Tournoi",
    "teams.profile.label.tournament.enter"           : "Je souhaite inscrire cette équipe dans un tournoi.",
    "teams.profile.label.league"                     : "Ligue",
    "teams.profile.label.league.enter"               : "Je souhaite inscrire cette équipe dans une ligue.",
    "teams.profile.participate.competition.required" : "Veuillez spécifier dans quelle compétition cette équipe participera.",
    "teams.profile.participate.success"              : "Votre requête pour inscrire cette équipe à la compétition a été soumise avec succès!",
    "teams.profile.participate"                      : "Participer dans un(e) ligue/tournoi",
    "teams.profile.participate.league"               : "Participer dans une ligue",
    "teams.profile.participate.tournament"           : "Participer dans un tournoi",

    "teams.profile.notFound.title"       : "Équipe introuvable",
    "teams.profile.notFound.description" : "L'équipe que vous recherchez n'a pas été trouvé, elle a peut-être été déplacée ou supprimée.",
    "teams.profile.notFound.btn"         : "Retour",

    "teams.profile.header.organization"            : "Organisation",
    "teams.profile.header.participate"             : "Participer dans un(e) ligue/tournoi",
    "teams.profile.header.league"                  : "Ligue",
    "teams.profile.header.category"                : "Catégorie",
    "teams.profile.header.status"                  : "Statut",
    "teams.profile.header.status.PENDING"          : "En attente",
    "teams.profile.header.status.ACTIVE"           : "Actif",
    "teams.profile.header.back"                    : "Retour à la liste d'équipes",
    "teams.profile.header.verifyRules"             : "Vérifier les règles",
    "teams.profile.header.verifyRules.hasWarnings" : "Cette équipe a un ou des avertissement(s)",

    "teams.profile.header.signWaivers.title"            : "Confirmer les dispenses manquantes",
    "teams.profile.header.signWaivers.subtitle"         : "La fonction de confirmation de dispenses va enregistrer toutes les dispenses comme acceptées ( oui, j’accepte, etc.).  Si les dispenses pour les participants nécessitent une réponse différente, vous devez d’abord enregistrer individuellement les dispenses et ensuite revenir à ce module.",
    "teams.profile.header.signWaivers.text"             : "Sélectionnez un type d'accord pour accepter toutes les dispenses critiques des membres de votre équipe.",
    "teams.profile.header.signWaivers.noMissingWaivers" : "Il n'y a aucune dispense à signer pour votre équipe.",
    "teams.profile.header.signWaivers.hasWaivers"       : "Membres ayant des dispenses à signer",
    "teams.profile.header.signWaivers.hasNoWaivers"     : "Membres n'ayant aucune dispense à signer",
    "teams.profile.header.signWaivers.waivers"          : "{amount, plural, one {# dispense à signer} other {# dispenses à signer}}",
    "teams.profile.header.signWaivers.sign"             : "Signer toutes les dispenses",
    "teams.profile.header.signWaivers.confirmation"     : "Toutes les dispenses sont signées!",

    "teams.profile.header.cards.players"                         : "Joueurs",
    "teams.profile.header.cards.bench_staff"                     : "Personnel",
    "teams.profile.header.cards.columns.MALE"                    : "M",
    "teams.profile.header.cards.columns.NON_BINARY"              : "N",
    "teams.profile.header.cards.columns.FEMALE"                  : "F",
    "teams.profile.header.cards.columns.PREFER_NOT_TO_SAY"       : "PPR",
    "teams.profile.header.cards.columns.PREFER_TO_SELF_DESCRIBE" : "PSD",
    "teams.profile.header.cards.columns.TOTAL"                   : "Total",
    "teams.profile.header.cards.rows.affiliate"                  : "Affilié",
    "teams.profile.header.cards.rows.import"                     : "Importé",
    "teams.profile.header.cards.rows.injured_ineligible"         : "Blessé/Inéligible",
    "teams.profile.header.cards.rows.overage"                    : "Surclassé",
    "teams.profile.header.cards.rows.regular"                    : "Régulier",
    "teams.profile.header.cards.rows.released"                   : "Libéré",
    "teams.profile.header.cards.rows.tryout"                     : "Essaie",
    "teams.profile.header.cards.rows.underage"                   : "Sous-classé",
    "teams.profile.header.cards.rows.pending_deficient"          : "En attente de Validation",
    "teams.profile.header.cards.showMore"                        : "Voir plus",
    "teams.profile.header.cards.showLess"                        : "Voir moins",

    "teams.profile.header.cards.total"             : "Total",
    "teams.profile.header.cards.male"              : "{itemCount, plural, =0 {# Masculin} one {# Masculin} other {# Masculins}}",
    "teams.profile.header.cards.female"            : "{itemCount, plural, =0 {# Féminin} one {# Féminin} other {# Féminins}}",
    "teams.profile.header.cards.preferNotToSay"    : "{itemCount, plural, =0 {# X} one {# X} other {# X}}",
    "teams.profile.header.cards.benchStaff"        : "{itemCount, plural, =0 {# Personnel de banc} one {# Personnel de banc} other {# Personnels au banc}}",
    "teams.profile.header.cards.regular"           : "Régulier",
    "teams.profile.header.cards.affiliated"        : "Affilié",
    "teams.profile.header.cards.released"          : "Libéré",
    "teams.profile.header.cards.imported"          : "Importé",
    "teams.profile.header.cards.tryout"            : "Essaie",
    "teams.profile.header.cards.pending_deficient" : "En attente de Validation",

    "teams.profile.header.approbation.title"                             : "Approbation d'équipe",
    "teams.profile.header.approbation.error.team_member_not_found"       : "L'équipe n'a pas de membres",
    "teams.profile.header.approbation.reasons.crc_is_expired"            : "Vérification d'antécédents n'existe pas ou est expirée",
    "teams.profile.header.approbation.reasons.invalid_background_check"  : "Vérification d'antécédents n'existe pas ou est expirée",
    "teams.profile.header.approbation.reasons.has_outstanding_balance"   : "Solde impayé",
    "teams.profile.header.approbation.reasons.incomplete_profile"        : "Profil incomplet",
    "teams.profile.header.approbation.reasons.member_status"             : "Statut invalide",
    "teams.profile.header.approbation.reasons.missing_required_fields"   : "Champs requis manquants",
    "teams.profile.header.approbation.reasons.unsigned_critical_waivers" : "Dispense critique à signer",
    "teams.profile.header.approbation.bypass.title"                      : "Contourner et {action}",
    "teams.profile.header.approbation.bypass.text"                       : "Sauter les validations de statut",

    "teams.profile.header.approbation.text.approved" : "Vous êtes sur le point d'approuver l'équipe suivante",
    "teams.profile.header.approbation.text.rejected" : "Vous êtes sur le point de rejeter l'équipe suivante",
    "teams.profile.header.approbation.text.pending"  : "Vous êtes sur le point de soumetre l'équipe suivante pour l'approbation",
    "teams.profile.header.approbation.text.cancel"   : "Vous êtes sur le point d'annuler l'approbation de l'équipe suivante",
    "teams.profile.header.approbation.text.draft"    : "Vous êtes sur le point d'annuler l'approbation de l'équipe suivante",

    "teams.profile.header.approbation.comment"          : "Commentaire",
    "teams.profile.header.approbation.comment.required" : "Veuillez entrer un commentaire",

    "teams.profile.header.approbation.confirm.approved" : "Approuver",
    "teams.profile.header.approbation.confirm.rejected" : "Rejeter",
    "teams.profile.header.approbation.confirm.pending"  : "Soumettre pour l'approbation",
    "teams.profile.header.approbation.confirm.cancel"   : "Annuler l'approbation",
    "teams.profile.header.approbation.confirm.draft"    : "Annuler l'approbation",

    "teams.profile.roster.button.export.csv"          : "Exporter l'équipe (CSV)",
    "teams.profile.roster.button.export.pdf"          : "Exporter l'équipe (PDF)",
    "teams.profile.roster.button.export.pdf.disabled" : "L'exportation de l'équipe est désactivée pour les équipes vide vides.",

    "teams.profile.roster.tab.title"   : "Cahier d'équipe",
    "teams.profile.roster.lockdate"    : "Fermeture des cahiers",
    "teams.profile.roster.locked"      : "Les cahiers sont fermés",
    "teams.profile.roster.lock.bypass" : "Permission de contourner la fermeture des cahiers",

    "teams.profile.roster.table.age.OVERAGE"  : "Surclassement",
    "teams.profile.roster.table.age.UNDERAGE" : "Sous-classement",

    "teams.profile.roster.table.filters.title"        : "Filtres {filterCount, plural, =0 {} one {(# filtre actif)} other {(# filtres actifs)}}",
    "teams.profile.roster.table.activeRoster.title"   : "Liste de joueurs actifs",
    "teams.profile.roster.table.activeRoster.empty"   : "Aucun joueur actif",
    "teams.profile.roster.table.affiliated.title"     : "Liste de joueurs affiliés",
    "teams.profile.roster.table.affiliated.empty"     : "Aucun joueur affilié",
    "teams.profile.roster.table.benchStaff.title"     : "Personnel de l'équipe",
    "teams.profile.roster.table.benchStaff.empty"     : "Aucun personnel d'équipe",
    "teams.profile.roster.table.inactiveRoster.title" : "Liste de participants libérés",
    "teams.profile.roster.table.inactiveRoster.empty" : "Aucun participant libéré",

    "teams.profile.roster.table.addedDate"         : "Date d'ajout",
    "teams.profile.roster.table.releaseDate"       : "Date de libération",
    "teams.profile.roster.table.releaseTime"       : "Heure de libération",
    "teams.profile.roster.table.position"          : "Position",
    "teams.profile.roster.table.position.required" : "La position est requise",
    "teams.profile.roster.table.jerseyNumber"      : "# de chandail",
    "teams.profile.roster.table.jerseyNumber.home" : "Domicile",
    "teams.profile.roster.table.jerseyNumber.away" : "Visiteur",
    "teams.profile.roster.table.status"            : "Statut d'équipe",
    "teams.profile.roster.table.playerStatus"      : "Statut de joueur",
    "teams.profile.roster.table.teamMemberStatus"  : "Statut de membre",
    "teams.profile.roster.table.tryout"            : "Essaie",
    "teams.profile.roster.table.tryout.short"      : "E",
    "teams.profile.roster.table.imported"          : "Importé",
    "teams.profile.roster.table.imported.short"    : "I",
    "teams.profile.roster.table.affiliated"        : "Affilié",
    "teams.profile.roster.table.affiliated.short"  : "A",
    "teams.profile.roster.table.injured"           : "Blessé/Inéligible",
    "teams.profile.roster.table.injured.short"     : "B",

    "teams.profile.roster.table.editRoster" : "Modifier le cahier d'équipe",

    "teams.profile.roster.table.status.APPROVED"          : "Approuvé",
    "teams.profile.roster.table.status.PENDING"           : "En attente",
    "teams.profile.roster.table.status.DECLINED"          : "Rejeté",
    "teams.profile.roster.table.status.INELIGIBLE"        : "Inéligible",
    "teams.profile.roster.table.status.PENDING_DEFICIENT" : "En attente de Validation",

    "teams.profile.roster.sidePanel.tabs.general"     : "Général",
    "teams.profile.roster.sidePanel.tabs.teamHistory" : "Équipes",

    "teams.profile.roster.sidePanel.rosterType.ACTIVE"    : "Provient de la liste de joueurs actifs",
    "teams.profile.roster.sidePanel.rosterType.AFFILIATE" : "Provient de la liste de joueurs affiliés",
    "teams.profile.roster.sidePanel.rosterType.STAFF"     : "Provient du personnel de banc",

    "teams.profile.roster.sidePanel.qualifications"           : "Qualifications",
    "teams.profile.roster.sidePanel.emptyQualifications"      : "Aucune qualification",
    "teams.profile.roster.sidePanel.attending"                : "Participe",
    "teams.profile.roster.sidePanel.qualification.expired"    : "Expirée",
    "teams.profile.roster.sidePanel.qualification.expiration" : "Expiration",
    "teams.profile.roster.sidePanel.qualification.goTo"       : "Aller au stage",

    "teams.profile.roster.sidePanel.releasedDate.disabled" : "La date de libération est protégée dans les règles d'équipe",
    "teams.profile.roster.sidePanel.releasedDate.missing"  : "Règles d'équipe manquantes",

    "teams.profile.roster.sidePanel.registrationDate.disabled" : "La date d'enregistrement est protégée dans les règles d'équipe",
    "teams.profile.roster.sidePanel.registrationDate.missing"  : "Règles d'équipe manquantes",

    "teams.profile.roster.sidePanel.teamHistory.memberTeams" : "Équipes du membre",
    "teams.profile.roster.sidePanel.teamHistory.none"        : "Aucune équipe pour ce membre",

    "teams.profile.roster.sidePanel.teamMemberComments"          : "Commentaires",
    "teams.profile.roster.sidePanel.teamMemberComments.none"     : "Il n'y a pas de commentaires pour ce membre",
    "teams.profile.roster.sidePanel.teamMemberComments.add"      : "Ajouter un commentaire",
    "teams.profile.roster.sidePanel.teamMemberComments.required" : "Veuillez saisir un commentaire",
    "teams.profile.roster.sidePanel.teamMemberComments.delete"   : "Supprimer le commentaire",

    "teams.profile.roster.sidePanel.teamComments.none"      : "Il n'y a pas de commentaires pour cette équipe",
    "teams.profile.roster.sidePanel.teamComments.deleteMsg" : "Vous êtes sur le point de supprimer ce commentaire.",
    "teams.profile.roster.sidePanel.teamComments.required"  : "Veuillez saisir un commentaire.",

    "teams.profile.roster.sidePanel.actions.release.primary.title"            : "Changer l'équipe primaire",
    "teams.profile.roster.sidePanel.actions.release.primary.text"             : "Ce membre possède d'autres équipes. Sélectionnez une de ces équipes pour la définir en tant qu'équipe primaire.",
    "teams.profile.roster.sidePanel.actions.release.primary.team"             : "Équipe",
    "teams.profile.roster.sidePanel.actions.release.primary.team.required"    : "L'équipe est requise",
    "teams.profile.roster.sidePanel.actions.release.primary.comment"          : "Commentaire",
    "teams.profile.roster.sidePanel.actions.release.primary.comment.required" : "Le commentaire est requis",
    "teams.profile.roster.sidePanel.actions.release.primary.btn.change"       : "Changer",
    "teams.profile.roster.sidePanel.actions.release.primary.btn.skip"         : "Sauter",

    "teams.profile.roster.sidePanel.actions.release"                   : "Libérer",
    "teams.profile.roster.sidePanel.actions.release.text"              : "Vous êtes sur le point de libérer ce joueur. Cette action est irréversible.",
    "teams.profile.roster.sidePanel.actions.setAsActive"               : "Rétablir le membre",
    "teams.profile.roster.sidePanel.actions.jerseyHome"                : "# de chandail (Domicile)",
    "teams.profile.roster.sidePanel.actions.jerseyAway"                : "# de chandail (Visiteur)",
    "teams.profile.roster.sidePanel.actions.teamMemberStatus.player"   : "Statut du joueur",
    "teams.profile.roster.sidePanel.actions.teamMemberStatus.staff"    : "Statut du membre du personnel",
    "teams.profile.roster.sidePanel.actions.teamMemberStatus.required" : "Le statut est requis",
    "teams.profile.roster.sidePanel.actions.addedToTeam"               : "Ajouté(e) à l'équipe",
    "teams.profile.roster.sidePanel.actions.remove.activeRoster"       : "Vous êtes sur le point de supprimer ce joueur de la liste active",
    "teams.profile.roster.sidePanel.actions.remove.inactiveRoster"     : "Vous êtes sur le point de supprimer ce joueur de la liste inactive",
    "teams.profile.roster.sidePanel.actions.remove.staffRoster"        : "Vous êtes sur le point de supprimer ce membre du personnel",

    "teams.profile.roster.sidePanel.jerseyNumber.error.unique"  : "Le numéro de chandail doit être unique dans votre équipe.",
    "teams.profile.roster.sidePanel.jerseyNumbers.error.unique" : "Les numéros de chandail doit être uniques dans votre équipe.",

    "teams.profile.comments.title"              : "Commentaires d'équipe",
    "teams.profile.comments.noComments.message" : "Vous pouvez ajouter un commentaire",
    "teams.profile.comments.noComments.button"  : "Ajouter un commentaire",
    "teams.profile.comments.date"               : "Date de modification",
    "teams.profile.comments.sidepanel.title"    : "Commentaire",
    "teams.profile.comments.sidepanel.name"     : "Nom",
    "teams.profile.comments.sidepanel.email"    : "Courriel",

    // Team profile settings errors/warnings
    "teams.profile.settings.warning.hint.title" : "Avertissements d'équipe",
    "teams.profile.settings.warning.hint.msg"   : "Voir les restrictions",
    "teams.profile.settings.warning.hint.more"  : "{warningCount, plural, one {Un avertissement de plus} other {# avertissements de plus}}",
    "teams.profile.settings.errors"             : "{errorCount, plural, one {Erreur} other {Erreurs}}",
    "teams.profile.settings.error.3371"         : "Nombre maximal de joueurs dépassé ({data})",
    "teams.profile.settings.error.3372"         : "Joueurs actifs maximum dépassés ({data})",
    "teams.profile.settings.error.3373"         : "Joueurs affiliés maximum dépassés ({data})",
    "teams.profile.settings.error.3374"         : "Joueurs importés maximum dépassés ({data})",
    "teams.profile.settings.error.3375"         : "Maximum des joueurs en essai dépassés ({data})",
    "teams.profile.settings.error.3376"         : "Impossible d'ajouter ou de mettre à jour un membre avec une date d'inscription spécifique",
    "teams.profile.settings.error.3377"         : "Impossible d'ajouter un membre avec date de sortie spécifique",
    "teams.profile.settings.error.3378"         : "Le membre doit avoir une carte de la saison précédente",
    "teams.profile.settings.error.3379"         : "Membre manque une qualification ({data})",
    "teams.profile.settings.error.3380"         : "Ne peut pas mettre à jour un membre libéré qui est approuvé",
    "teams.profile.settings.error.3381"         : "Nombre de joueurs libérés maximal dépassés ({data})",
    "teams.profile.settings.error.3408"         : "Le membre manque une pré-inscription ({data})",
    "teams.profile.settings.error.3423"         : "Le membre n'a pas de vérification d'antécédents valide",
    "teams.profile.settings.error.3438"         : "Les numéros de chandail doivent être uniques",
    "teams.profile.settings.error.3494"         : "Les cahiers sont présentement fermés",

    "teams.profile.settings.error.3371.raw" : "Nombre maximal de joueurs dépassé",
    "teams.profile.settings.error.3372.raw" : "Joueurs actifs maximum dépassés",
    "teams.profile.settings.error.3373.raw" : "Joueurs affiliés maximum dépassés",
    "teams.profile.settings.error.3374.raw" : "Joueurs importés maximum dépassés",
    "teams.profile.settings.error.3375.raw" : "Maximum des joueurs en essai dépassés",
    "teams.profile.settings.error.3376.raw" : "Impossible d'ajouter ou de mettre à jour un membre avec une date d'inscription spécifique",
    "teams.profile.settings.error.3377.raw" : "Impossible d'ajouter un membre avec date de sortie spécifique",
    "teams.profile.settings.error.3378.raw" : "Le membre doit avoir une carte de la saison précédente",
    "teams.profile.settings.error.3379.raw" : "Membre manque une qualification",
    "teams.profile.settings.error.3380.raw" : "Ne peut pas mettre à jour un membre libéré qui est approuvé",
    "teams.profile.settings.error.3381.raw" : "Nombre de joueurs libérés maximal dépassés",
    "teams.profile.settings.error.3408.raw" : "Le membre manque une pré-inscription",
    "teams.profile.settings.error.3423.raw" : "Le membre n'a pas de vérification d'antécédents valide",
    "teams.profile.settings.error.3438.raw" : "Les numéros de chandail doivent être uniques",
    "teams.profile.settings.error.3494.raw" : "Les cahiers sont présentement fermés",

    "teams.profile.settings.open.all.warning.quickview"         : "Ouvrir tous les membres dans l'aperçu rapide",
    "teams.profile.settings.open.all.warning.quickview.tooltip" : "Ouvrir les membres qui créent des avertissements.",
    "teams.profile.settings.open.all.warning.number"            : "membres ont des avertissements",

    "teams.profile.actionsMenu.transfer"           : "Transférer équipe d'organisation",
    "teams.profile.actionsMenu.transfer.title"     : "Transférer équipe d'organisation",
    "teams.profile.actionsMenu.transfer.alert"     : "Utilisez cette fonctionnalité pour déplacer une équipe vers une autre organisation.",
    "teams.profile.actionsMenu.transfer.success.1" : "Cette équipe a été transférée avec succès à l'organisation suivante:",
    "teams.profile.actionsMenu.transfer.success.2" : "Vous serez maintenant redirigé vers la page de recherche d'équipe.",

}