import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import QualificationsAttendeesList from "./QualificationsAttendeesList";

/**
 * @description Container of the lookalike qualifications/attendees list
 * @param {Object} props
 * @param {JSX.Element|string} props.title
 * @param {JSX.Element|string} [props.emptyMsg] If no empty msg is sent, component will be hidden on load and if there's no data
 * @param {Promise.<object[]>} props.getList Should return a promise
 * @param {string} props.memberId Member id, will rerender and recall the promise if it changes
 * @returns {JSX.Element}
 */
const QualificationsAttendeesListContainer = ({ title, emptyMsg, getList, memberId }) => {
    const [ data, setData ] = useState({
        isLoading: false,
        list: [],
    });

    useEffect(() => {
        if(memberId){
            setData({ isLoading: true });
            getList(memberId)
                .then((theList) => {
                    const listInData = Array.isArray(theList) ? theList : [];
                    setData({ list: listInData, isLoading: false });
                })
                .catch((e) => {
                    if(!AxiosIsCancelled(e.message)){
                        console.error(e);
                        setData({ list: [], isLoading: false });
                    }
                })
        }
    }, [ memberId ])

    return (
        <>
            {(!!emptyMsg || (!data.isLoading && data.list.length > 0)) &&
                <div className="text-muted mb-1">{title}</div> // if there's no empty layout, we shouldn't show anything if there's no data
            }
            {
                data.isLoading ?
                    emptyMsg ? <Skeleton height={51} style={{ marginBottom: 1 }} /> : null
                    :
                    <QualificationsAttendeesList emptyMsg={emptyMsg} list={data.list} />
            }
        </>
    );
}

export default QualificationsAttendeesListContainer;


//******************************************************************************************************** */
// LOGIC WITH USESWR THAT IS NOT WORKING AND WE CAN'T FIGURE OUT WHY -- REVERTED TO PREVIOUS VERSION
//******************************************************************************************************** */

// import Skeleton from "react-loading-skeleton";
// import useSWR from "swr";
// import QualificationsAttendeesList from "./QualificationsAttendeesList";

// /**
//  * @description Container of the lookalike qualifications/attendees list
//  * @param {Object} props
//  * @param {JSX.Element|string} props.title
//  * @param {JSX.Element|string} [props.emptyMsg] If no empty msg is sent, component will be hidden on load and if there's no data
//  * @param {Promise.<object[]>} props.getList Should return a promise
//  * @param {string} props.memberId Member id, will rerender and recall the promise if it changes
//  * @returns {JSX.Element}
//  */
// const QualificationsAttendeesListContainer = ({ title, emptyMsg, getList, memberId }) => {
//     const { isValidating, data } = useSWR(
//         memberId ? [ 'QualificationsAttendeesListContainer', 'getList', memberId ] : null,
//         () => getList(memberId).then((theList) => Array.isArray(theList) ? theList : []),
//         {
//             onSuccess: (e) => console.log(e),
//         },
//     )
//     console.log("QualificationsAttendeesListContainer")
//     console.log(memberId)
//     console.log(data)
//     return (
//         <>
//             {(!!emptyMsg || (!isValidating && (data || []).length > 0)) &&
//                 <div className="text-muted mb-1">{title}</div> // if there's no empty layout, we shouldn't show anything if there's no data
//             }
//             {
//                 isValidating ?
//                     emptyMsg ? <Skeleton height={51} style={{ marginBottom: 1 }} /> : null
//                     :
//                     <QualificationsAttendeesList emptyMsg={emptyMsg} list={data || []} />
//             }
//         </>
//     );
// }

// export default QualificationsAttendeesListContainer;