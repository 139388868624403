import { useContext, useState } from "react";
import {
    Button,
    Card
} from "reactstrap";
import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from '@spordle/datatables';

import Translate from "@spordle/intl-elements";
import SpordlePanelTable from "../../../../../../components/sidePanel/SpordlePanel";

// Contexts
import { CrcContext } from "../../../../../../contexts/CrcContext";
import I18nHelperContextProvider, { DisplayI18n, I18nHelperContext } from "../../../../../../helpers/i18nHelper";
import BackgroundCheckTypesSidepanel from "./BackgroundCheckTypesSidepanel";
import BackgroundCheckTypesAdd from "./BackgroundCheckTypesAdd";
import CardSectionTitle from "../../../../../../components/CardSectionTitle";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";


const BackgroundCheckTypes = () => {

    const organizationContext = useContext(OrganizationContext);
    const crcContext = useContext(CrcContext);
    const i18nContext = useContext(I18nContext);

    const [ modalAdd, setModalAdd ] = useState(false);
    const toggleModalAdd = () => setModalAdd(!modalAdd);

    return (
        <I18nHelperContextProvider fields={[ 'name', 'description' ]}>
            <I18nHelperContext.Consumer>
                {(i18nHelper) => (
                    <div>
                        <SpordlePanelTable
                            allowOutsideClick
                            sidePanel={(props) => <BackgroundCheckTypesSidepanel {...props} />}
                            dataIndex='background_check_type_id'
                            table={(panelProps) => {
                                return (
                                    <Card body className="card-shadow">
                                        <CardSectionTitle title='settings.tabs.backgroundCheckTypes' />
                                        <SpordleTableProvider
                                            id='BackgroundCheckTypesTable'
                                            tableHover clickable striped
                                            bordered
                                            ref={panelProps.spordleTableRef}
                                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                            dataIndex='background_check_type_id'
                                            defaultSorting="+display_order"
                                            searchKeys={[
                                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                                'name',
                                            ]}
                                            columns={[
                                                {
                                                    label: <Translate id='form.fields.displayOrder' />,
                                                    key: "display_order",
                                                    className: "text-center",
                                                    dataClassName: "text-center",
                                                    mobile: true,
                                                    sortable: true,
                                                },
                                                ...i18nHelper.getTableColumns('name'),
                                                {
                                                    label: <Translate id='form.fields.status' />,
                                                    key: "active",
                                                    mobile: true,
                                                    sortable: true,
                                                },
                                            ]}
                                            onColumnClick={(e, backgroundCheckType) => {
                                                switch (e.button){
                                                    case 0: // Left mouse button
                                                        panelProps.onSingleSelectChange(backgroundCheckType);
                                                        break;
                                                }
                                            }}
                                            desktopWhen='md'
                                            renderRow={(columnKey, backgroundCheckType, spordleTable) => {

                                                if(columnKey.includes('i18n')){
                                                    const lang = columnKey.substring(0, columnKey.lastIndexOf('.')); // Get column key lang for i18n helper
                                                    return (
                                                        <UserDisplay>
                                                            <UserDisplay.Container>
                                                                <UserDisplay.Title>
                                                                    <DisplayI18n
                                                                        field={columnKey}
                                                                        defaultValue={backgroundCheckType.name}
                                                                        i18n={backgroundCheckType.i18n}
                                                                    />
                                                                </UserDisplay.Title>
                                                                <UserDisplay.Title className='text-muted small'>
                                                                    <DisplayI18n
                                                                        field={`${lang}.description`}
                                                                        defaultValue={backgroundCheckType.description}
                                                                        i18n={backgroundCheckType.i18n}
                                                                    />
                                                                </UserDisplay.Title>
                                                            </UserDisplay.Container>
                                                        </UserDisplay>
                                                    )
                                                }

                                                switch (columnKey){
                                                    case 'active':
                                                        return (
                                                            <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                                {backgroundCheckType.active == '1' ?
                                                                    <i className="text-primary mdi mdi-check" />
                                                                    :
                                                                    <i className="text-danger mdi mdi-close" />
                                                                }
                                                            </div>
                                                        )
                                                    default:
                                                        break;
                                                }
                                            }}
                                            rowIsHighlighted={(backgroundCheckType) => !!backgroundCheckType.checked}
                                            loadData={(from, filterData, spordleTable) => {
                                                switch (from){
                                                    case 'REFRESH':
                                                        spordleTable.setLoading();
                                                    case 'CDM':
                                                        return crcContext.getBackgroundCheckTypes(organizationContext.organisation_id);
                                                    default:
                                                        break;
                                                }
                                            }}
                                        >
                                            <div className="mb-2">
                                                <div className='d-flex justify-content-between'>
                                                    <SearchInput />
                                                    <div className='d-flex ml-auto text-right'>
                                                        <Refresh />

                                                        <CanDoAction action='ADD' componentCode='settings' componentPermissionCode='phone_type'>
                                                            <BackgroundCheckTypesAdd isOpen={modalAdd} toggle={toggleModalAdd} />
                                                            <Button className='ml-2' color='primary' onClick={toggleModalAdd}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                        </CanDoAction>
                                                    </div>
                                                </div>
                                            </div>
                                            <SpordleTableView />
                                        </SpordleTableProvider>
                                    </Card>
                                )
                            }}
                        />
                    </div>
                )}
            </I18nHelperContext.Consumer>
        </I18nHelperContextProvider>

    )

}

export default BackgroundCheckTypes;