import { useContext } from 'react';
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { object, string, mixed } from 'yup';
import Translate, { InputIntl } from '@spordle/intl-elements';
import AnalyticsModal from '../../../../../../analytics/AnalyticsModal';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { Button, Col, Fade, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FormikAddress, FormikDateTime, FormikGroup, FormikInputNumber, FormikInputText, FormikRadioButton, getFormikAddressInitialValues } from '@spordle/formik-elements';
import { FormattedMessage } from 'react-intl';
import FormikTiny from '../../../../../../components/formik/FormikTiny';
import Required from '../../../../../../components/formik/Required';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { ClinicsContext } from '../../../../../../contexts/ClinicsContext';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import { fail } from '@spordle/toasts';

const AddSessionModal = ({ isOpen, toggle, values, onSuccess }) => {
    const i18nContext = useContext(I18nContext);
    const clinicsContext = useContext(ClinicsContext);

    const editMode = !!values;

    /**
     * Makes sure that nothing is left in that object for no reason (e.g. No need for address if the session is online)
     * @param {Object} values Session values
     */
    const cleanUpSessionValues = (values) => {
        if(values.format === 'ONSITE'){
            values.startDate = '';
            values.startTime = '';
            values.endDate = '';
            values.endTime = '';
            values.urlLink = '';
            // values.source = '';
        }
        if(values.format === 'ONLINE_LIVE'){
            values.locationName = '';
            values.address = {
                streetNumber: '',
                address: '',
                city: '',
                country: '',
                state: '',
                zip: '',
                fullAddress: '',
                mapsUrl: '',
                origin: '',
            }
            values.startDate = '';
            values.startTime = '';
            values.endDate = '';
            values.endTime = '';
        }
        if(values.format === 'ONLINE'){
            values.locationName = '';
            values.address = {
                streetNumber: '',
                address: '',
                city: '',
                country: '',
                state: '',
                zip: '',
                fullAddress: '',
                mapsUrl: '',
                origin: '',
            }
            values.date = '';
            values.time = '';
        }
        return values
    }

    return (
        <AnalyticsModal analyticsName='FormClinicSessionInformation' size="lg" isOpen={isOpen} toggle={() => toggle(null)} backdrop='static'>
            <Formik
                initialValues={editMode ?
                    {
                        ...values,
                        address: getFormikAddressInitialValues(values.address),
                    }
                    :
                    {
                        language: i18nContext.getGenericLocale(),
                        format: 'ONSITE',

                        // online
                        // source: '',
                        urlLink: '',

                        // on site
                        locationName: '',
                        address: getFormikAddressInitialValues(),

                        // schedule -> on site & live
                        date: '',
                        time: '',

                        // schedule -> Recorded/On-Demand
                        startDate: '',
                        startTime: '',
                        endDate: '',
                        endTime: '',

                        // schedule -> all
                        hours: '',
                        minutes: '',

                        note: '',
                    }
                }
                validationSchema={object().shape({
                    language: string().required(<Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.language.required' />),
                    format: string().required(<Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.format.required' />),

                    // online
                    // source: string()
                    //     .when('format', {
                    //         is: (format) => format !== 'ONSITE',
                    //         then: string().required(<Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.source.required' />)
                    //     }),
                    urlLink: string()
                        .when('format', {
                            is: (format) => format !== 'ONSITE',
                            then: string().transform(function(value, originalvalue){
                                return !value || value.startsWith('http') ? value : 'http://' + value
                            }).url(<Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.url.valid' />),
                        }),

                    // ON SITE
                    locationName: string(),
                    address: object().when('format', {
                        is: (format) => format === 'ONSITE',
                        then: object().address(true, {
                            streetNumber: <Translate id='form.validation.streetNumber.required' />,
                            address: <Translate id='form.validation.address.required' />,
                            city: <Translate id='form.validation.city.required' />,
                            zipCode: <Translate id='form.validation.zip.required' />,
                            state: <Translate id='form.validation.province.required' />,
                            country: <Translate id='form.validation.country.required' />,
                        }),
                    }),

                    // schedule -> on site & live
                    date: mixed()
                        .when('format', {
                            is: (format) => format !== 'ONLINE',
                            then: mixed().required(<Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.date.required' />).test({
                                name: 'date_format',
                                message: <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.date.valid' />,
                                test: (value) => moment.isMoment(value),
                            }),
                        }),
                    time: mixed()
                        .when('format', {
                            is: (format) => format !== 'ONLINE',
                            then: mixed().required(<Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.time.required' />).test({
                                name: 'time_format',
                                message: <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.time.valid' />,
                                test: (value) => moment.isMoment(value),
                            }),
                        }),

                    // schedule -> Recorded/On-Demand
                    startDate: mixed()
                        .when('format', {
                            is: 'ONLINE',
                            then: mixed().required(<Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.startDate.required' />).test({
                                name: 'start_date_format',
                                message: <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.startDate.valid' />,
                                test: (value) => moment.isMoment(value),
                            }),
                        }),
                    startTime: mixed()
                        .when('format', {
                            is: 'ONLINE',
                            then: mixed().required(<Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.startTime.required' />).test({
                                name: 'start_time_format',
                                message: <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.startTime.valid' />,
                                test: (value) => moment.isMoment(value),
                            }),
                        }),
                    endDate: mixed()
                        .when('format', {
                            is: 'ONLINE',
                            then: mixed().required(<Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endDate.required' />).test({
                                name: 'end_date_format',
                                message: <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endDate.valid' />,
                                test: (value) => moment.isMoment(value),
                            }).test({
                                name: 'end_date_start_date_test',
                                message: <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endDate.after' />,
                                test: function(endDate){
                                    return moment(endDate).isAfter(moment(this.parent.startDate).subtract(1, 'day'))
                                },
                            }),
                        }),
                    endTime: mixed()
                        .when('format', {
                            is: 'ONLINE',
                            then: mixed().required(<Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endTime.required' />).test({
                                name: 'end_time_format',
                                message: <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endTime.valid' />,
                                test: (value) => moment.isMoment(value),
                            }).test({
                                name: 'end_time_start_time_test',
                                message: <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endTime.after' />,
                                test: function(endTime){
                                    if(moment.isMoment(this.parent.startDate) && moment.isMoment(this.parent.endDate) && !this.parent.endDate.isAfter(this.parent.startDate)){
                                        return moment(endTime).isAfter(moment(this.parent.startTime))
                                    }
                                    return true

                                },
                            }),
                        }),

                    // schedule -> all
                    hours: string().required(<Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.hours.required' />)
                        .test({
                            name: 'maxHours',
                            message: <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.hours.max' />,
                            test: (value) => parseInt(value) <= 24,
                        }),
                    minutes: string().required(<Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.minutes.required' />)
                        .test({
                            name: 'maxMinutes',
                            message: <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.minutes.max' />,
                            test: (value) => parseInt(value) < 60,
                        }),

                    note: string(),
                })}
                onSubmit={async(values) => {
                    const newValues = cleanUpSessionValues({ ...values, urlLink: !values.urlLink || values.urlLink.startsWith('http') ? values.urlLink : 'http://' + values.urlLink });
                    if(editMode){
                        await clinicsContext.updateSession(clinicsContext.currentClinic?.clinic_id, values.clinicSessionId, {
                            languages: newValues.language === 'bilingual' ? 'en,fr' : newValues.language,
                            format: newValues.format,
                            locationName: newValues.locationName,
                            ...newValues.address,
                            // source: session.source,
                            urlLink: newValues.urlLink,
                            startDate: newValues.startDate ? newValues.startDate.set({ 'hour': newValues.startTime.hour(), 'minute': newValues.startTime.minute() }).toISOString() : moment(newValues.date.set({ 'hour': newValues.time.hour(), 'minute': newValues.time.minute() })).toISOString(),
                            endDate: newValues.endDate ? newValues.endDate.set({ 'hour': newValues.endTime.hour(), 'minute': newValues.endTime.minute() }).toISOString() : moment(newValues.date.set({ 'hour': newValues.time.hour(), 'minute': newValues.time.minute() }).add(newValues.hours, 'h').add(newValues.minutes, 'm')).toISOString(),
                            duration: moment().set({ 'hour': newValues.hours, 'minute': newValues.minutes }).format('HH:mm'),
                            note: newValues.note,
                            mandatory: true,
                            active: true,
                        }).catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })
                    }else{
                        await clinicsContext.createSession(clinicsContext.currentClinic?.clinic_id, {
                            languages: newValues.language === 'bilingual' ? 'en,fr' : newValues.language,
                            format: newValues.format,
                            locationName: newValues.locationName,
                            ...newValues.address,
                            // source: session.source,
                            urlLink: newValues.urlLink,
                            startDate: newValues.startDate ? newValues.startDate.set({ 'hour': newValues.startTime.hour(), 'minute': newValues.startTime.minute() }).toISOString() : moment(newValues.date.set({ 'hour': newValues.time.hour(), 'minute': newValues.time.minute() })).toISOString(),
                            endDate: newValues.endDate ? newValues.endDate.set({ 'hour': newValues.endTime.hour(), 'minute': newValues.endTime.minute() }).toISOString() : moment(newValues.date.set({ 'hour': newValues.time.hour(), 'minute': newValues.time.minute() }).add(newValues.hours, 'h').add(newValues.minutes, 'm')).toISOString(),
                            duration: moment().set({ 'hour': newValues.hours, 'minute': newValues.minutes }).format('HH:mm'),
                            note: newValues.note,
                            mandatory: true,
                            active: true,
                        }).catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })
                    }

                    toggle(null);
                    onSuccess();
                }}
            >
                {(formik) => (
                    <Form id='clinicsCreationStep2ModalSession'>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalHeader className="align-items-center" tag="div" toggle={() => toggle(null)}>
                                <div className={`mb-0 h6 font-bold`}><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.title' /></div>
                            </ModalHeader>
                            <ModalBody>
                                <div className="mb-4">
                                    <Row className='mb-3'>
                                        <Col md="6" className="order-md-last">
                                            <Label for='language' className="text-muted mb-1"><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.language' /></Label>
                                            <FormikGroup name='language' rowProps={{ className: 'mx-0' }}>
                                                <FormikRadioButton className="mr-3" id='languageEn' name='language' value='en' label='misc.en' translateLabel defaultChecked={formik.values.language === 'en'} />
                                                <FormikRadioButton className="mr-3" id='languageFr' name='language' value='fr' label='misc.fr' translateLabel defaultChecked={formik.values.language === 'fr'} />
                                                <FormikRadioButton className="mr-3" id='languageBilingual' name='language' value='bilingual' label='misc.bilingual' translateLabel defaultChecked={formik.values.language === 'bilingual'} />
                                            </FormikGroup>
                                        </Col>

                                        <Col md="6">
                                            <Label for='format' className="text-muted mb-1"><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.format' /></Label>
                                            <FormikGroup name='format' rowProps={{ className: 'mx-0 flex-column' }}>
                                                <FormikRadioButton
                                                    className="mb-1" id='formatSite'
                                                    name='format' value='ONSITE'
                                                    label={
                                                        <>
                                                            <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.ONSITE' /> <i className='mdi mdi-map-marker' />
                                                        </>
                                                    }
                                                    translateLabel={false} defaultChecked={formik.values.format === 'ONSITE'}
                                                />
                                                <FormikRadioButton
                                                    className="mb-1" id='formatOnlineLive'
                                                    name='format' value='ONLINE_LIVE'
                                                    label={
                                                        <>
                                                            <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.ONLINE_LIVE' /> <i className='mdi mdi-play' />
                                                        </>
                                                    }
                                                    translateLabel={false} defaultChecked={formik.values.format === 'ONLINE_LIVE'}
                                                />
                                                <FormikRadioButton
                                                    className="mb-1" id='formatOnlineVODs'
                                                    name='format' value='ONLINE'
                                                    label={
                                                        <>
                                                            <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.ONLINE' /> <i className='mdi mdi-access-point' />
                                                        </>
                                                    }
                                                    translateLabel={false} onChange={(e) => { /*formik.setFieldValue('source', 'external');*/ formik.setFieldValue('format', 'ONLINE') }} defaultChecked={formik.values.format === 'ONLINE'}
                                                />
                                            </FormikGroup>
                                        </Col>
                                    </Row>

                                    {/* sessionFormat -> online */}
                                    <Fade in={formik.values.format === 'ONLINE_LIVE' || formik.values.format === 'ONLINE'}>
                                        {formik.values.format === 'ONLINE_LIVE' || formik.values.format === 'ONLINE' ?
                                            <>
                                                <FormGroup className="mb-0">
                                                    <div className="h6 font-medium mb-3"><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.url' /></div>
                                                    <FormikInputText name='urlLink' />
                                                </FormGroup>
                                                {/* <div className="h6 font-medium mb-3"><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.title' /></div>
                                                <FormikGroup name='source' rowProps={{ className: 'mx-0 flex-column mb-2' }} manualError>
                                                    <Label className="text-muted" for="source"><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.source' /> <Required/></Label>
                                                    <FormikRadioButton className="mb-1" id='sourceExternal' name='source' value='external' label='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.external' translateLabel checked={formik.values.source === 'external'} />
                                                    <FormikRadioButton className="mb-1" id='sourceHuc' name='source' value='huc' label='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.huc' translateLabel disabled={formik.values.format === 'ONLINE'} />
                                                    <FormikRadioButton className="mb-1" id='sourceCoach' name='source' value='coach' label='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.coach' translateLabel disabled={formik.values.format === 'ONLINE'} />
                                                    <FormikRadioButton className="mb-1" id='sourceRespect' name='source' value='respect' label='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.respect' translateLabel disabled={formik.values.format === 'ONLINE'} />
                                                    <FormikError name='source' />
                                                </FormikGroup>
                                                <FormGroup className="mb-0">
                                                    <Label className="text-muted" for="urlLink"><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.url' /> <Required /></Label>
                                                    <FormikInputText name='urlLink' />
                                                </FormGroup> */}
                                            </>
                                            : null}
                                    </Fade>

                                    {/* sessionFormat -> on site */}
                                    <Fade in={formik.values.format === 'ONSITE'}>
                                        {formik.values.format === 'ONSITE' ?
                                            <>
                                                <div className="h6 font-medium mb-3"><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.location' /></div>
                                                <FormGroup>
                                                    <Label className="text-muted" for="locationName"><Translate id='form.fields.name' /></Label>
                                                    <FormikInputText name='locationName' id='locationName' placeholder='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.location.example' translatePlaceholder />
                                                </FormGroup>
                                                <FormikAddress
                                                    id='address' name='address'
                                                    label='form.fields.address'
                                                    required
                                                    allowManualPlace
                                                    allowOpenInMaps
                                                    labelClassName='text-muted'
                                                />
                                            </>
                                            : null}
                                    </Fade>
                                </div>


                                <div className="mb-4">
                                    <div className="h6 font-medium mb-3"><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.title' /> <Required /></div>

                                    <Fade in={formik.values.format === 'ONSITE' || formik.values.format === 'ONLINE_LIVE'}>
                                        {formik.values.format === 'ONSITE' || formik.values.format === 'ONLINE_LIVE' ?
                                            <>
                                                <Row className='mb-1'>
                                                    <Col md='12' className='d-flex align-items-center'>
                                                        <div className='font-medium mr-1 text-nowrap'><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text1' /></div>
                                                        <FormGroup className='mb-0'>
                                                            <FormikDateTime
                                                                name='date'
                                                                id='date'
                                                                dateFormat={'YYYY-MM-DD'}
                                                                timeFormat={false}
                                                                renderInput={(props, openCalendar) => (
                                                                    <FormattedMessage id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.date.format'>
                                                                        {(message) => (
                                                                            <div className='search-wrapper'>
                                                                                <input {...props} placeholder={message} />
                                                                                <button className='reset-btn search-clear' type='button' onClick={openCalendar}>
                                                                                    <i className='mdi mdi-calendar' />
                                                                                </button>
                                                                            </div>
                                                                        )
                                                                        }
                                                                    </FormattedMessage>
                                                                )
                                                                }
                                                            />
                                                        </FormGroup>
                                                        <div className='font-medium mx-2 text-nowrap'><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text2' /></div>
                                                        <FormGroup className='mb-0'>
                                                            <FormikDateTime
                                                                name='time'
                                                                id='time'
                                                                dateFormat={false}
                                                                timeFormat={'h:mm A'}
                                                                onClose={(input) => {
                                                                    if(input === ''){ // empty input
                                                                        formik.setFieldValue('time', '')
                                                                    }else if(!moment.isMoment(formik.values.time)){ // was not selected from picker
                                                                        if(moment(input, [ "H:mm" ]).isValid()){
                                                                            formik.setFieldValue('time', moment(moment(input, [ "H:mm" ]).format("h:mm A"), [ "h:mm A" ]));
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md='12' className='d-flex align-items-center'>
                                                        <div className='font-medium mr-1 text-nowrap'><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text3' /></div>
                                                        <FormGroup className='mb-0 mr-1'>
                                                            <FormikInputNumber allowNegative={false} name='hours' id='hours' format='##' />
                                                        </FormGroup>
                                                        <div className='font-medium mx-2 text-nowrap'><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text4' /></div>
                                                        <FormGroup className='mb-0 mr-1'>
                                                            <FormikInputNumber allowNegative={false} name='minutes' id='minutes' format='##' />
                                                        </FormGroup>
                                                        <div className='font-medium mx-2 text-nowrap'><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text5' /></div>
                                                    </Col>
                                                </Row>
                                            </>
                                            : null}
                                    </Fade>

                                    <Fade in={formik.values.format === 'ONLINE'}>
                                        {formik.values.format === 'ONLINE' ?
                                            <>
                                                <Row className='mb-1'>
                                                    <Col md='12' className='d-flex align-items-center'>
                                                        <div className='font-medium mr-2 text-nowrap'><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text1' /></div>
                                                        <FormGroup className='mb-0'>
                                                            <FormikDateTime
                                                                name='startDate'
                                                                id='startDate'
                                                                dateFormat={'YYYY-MM-DD'}
                                                                renderInput={(props, openCalendar) => (
                                                                    <FormattedMessage id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.date.format'>
                                                                        {(message) => (
                                                                            <div className='search-wrapper'>
                                                                                <input {...props} placeholder={message} />
                                                                                <button className='reset-btn search-clear' type='button' onClick={openCalendar}>
                                                                                    <i className='mdi mdi-calendar' />
                                                                                </button>
                                                                            </div>
                                                                        )
                                                                        }
                                                                    </FormattedMessage>
                                                                )
                                                                }
                                                                timeFormat={false}
                                                            />
                                                        </FormGroup>
                                                        <div className='font-medium mx-2 text-nowrap'><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text2' /></div>
                                                        <FormGroup className='mb-0'>
                                                            <FormikDateTime
                                                                name='startTime'
                                                                id='startTime'
                                                                dateFormat={false}
                                                                timeFormat={'h:mm A'}
                                                                onClose={(input) => {
                                                                    if(input === ''){ // empty input
                                                                        formik.setFieldValue('startTime', '')
                                                                    }else if(!moment.isMoment(formik.values.startTime)){ // was not selected from picker
                                                                        if(moment(input, [ "H:mm" ]).isValid()){
                                                                            formik.setFieldValue('startTime', moment(moment(input, [ "H:mm" ]).format("h:mm A"), [ "h:mm A" ]));
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md='12' className='d-flex align-items-center'>
                                                        <div className='font-medium mr-2 text-nowrap'><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text3' /></div>
                                                        <FormGroup className='mb-0'>
                                                            <FormikDateTime
                                                                name='endDate'
                                                                id='endDate'
                                                                dateFormat={'YYYY-MM-DD'}
                                                                timeFormat={false}
                                                                isValidDate={(current) => formik.values.startDate ? moment(current).isAfter(moment(formik.values.startDate).subtract(1, "days")) : true}
                                                                renderInput={(props, openCalendar) => (
                                                                    <div className='search-wrapper'>
                                                                        <InputIntl {...props} placeholder='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.date.format' />
                                                                        <button className='reset-btn search-clear' type='button' onClick={openCalendar}>
                                                                            <i className='mdi mdi-calendar' />
                                                                        </button>
                                                                    </div>
                                                                )
                                                                }
                                                            />
                                                        </FormGroup>
                                                        <div className='font-medium mx-2 text-nowrap'><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text4' /></div>
                                                        <FormGroup className='mb-0'>
                                                            <FormikDateTime
                                                                name='endTime'
                                                                id='endTime'
                                                                dateFormat={false}
                                                                timeFormat={'h:mm A'}
                                                                onClose={(input) => {
                                                                    if(input === ''){ // empty input
                                                                        formik.setFieldValue('endTime', '')
                                                                    }else if(!moment.isMoment(formik.values.endTime)){ // was not selected from picker
                                                                        if(moment(input, [ "H:mm" ]).isValid()){
                                                                            formik.setFieldValue('endTime', moment(moment(input, [ "H:mm" ]).format("h:mm A"), [ "h:mm A" ]));
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md='12' className='d-flex align-items-center'>
                                                        <div className='font-medium mr-2 text-nowrap'><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text5' /></div>
                                                        <FormGroup className='mb-0'>
                                                            <FormikInputNumber allowNegative={false} name='hours' id='hours' format='##' />
                                                        </FormGroup>
                                                        <div className='font-medium mx-2 text-nowrap'><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text6' /></div>
                                                        <FormGroup className='mb-0'>
                                                            <FormikInputNumber allowNegative={false} name='minutes' id='minutes' format='##' />
                                                        </FormGroup>
                                                        <div className='font-medium mx-2 text-nowrap'><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text7' /></div>
                                                    </Col>
                                                </Row>
                                            </>
                                            : null}
                                    </Fade>
                                </div>
                                <FormGroup className="mb-0">
                                    <Label className="h6 font-medium mb-3" for="note"><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.note' /></Label>
                                    <FormikTiny name='note' />
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" type='submit'>
                                    {editMode ?
                                        <Translate id='misc.edit' />
                                        :
                                        <Translate id='misc.add' />
                                    }
                                </Button>
                                <Button color="primary" outline type='button' onClick={() => toggle(null)}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    )
}

export default AddSessionModal