import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import HasAccessTo from '../../../../../components/permissions/HasAccessTo';
import { MembersContext } from '../../../../../contexts/MembersContext';
import Translate from '@spordle/intl-elements';
import { memberTypeSpecial } from '../../../../settings/organization/components/memberTypes/types/components/memberTypesConstants';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import MemberAlertsIcon from '../../../../../components/memberBadges/MemberAlertsIcon';
import QuickTour from '../../../../../components/quickTour/QuickTour';
import MemberHeaderPotentialDuplicate from './MemberHeaderPotentialDuplicate';
import { stringBuilder } from '@spordle/helpers';
import { Tooltip } from '@mantine/core';
import { FormattedDate } from 'react-intl';

export const MemberStatusButton = (props) => {
    return (
        <div className="text-center text-nowrap text-md-left mb-2 mr-2">
            <div
                {...props}
                onClick={props.onClick && !props.disabled ? props.onClick : void 0}
                className={stringBuilder(
                    props.className,
                    { "clickable": !props.disabled },
                    "btn btn-sm rounded-pill bg-white font-medium shadow-sm",
                )}
            />
        </div>
    )
}

const MemberHeaderStatusButtons = ({ refreshMemberProfile }) => {
    const membersContext = useContext(MembersContext);
    const currentMember = membersContext.currentMember;

    // component code is 'addressComponentMemberProfile' to scroll to address and phones component
    // component code is 'profileComponentMemberPrimaryInfo' to scroll to general info
    // component code is 'criminalRecordCheckCard' to scroll to CRC in it's tab
    const scrollToComponent = (componentCode) => {
        const card = document.getElementById(componentCode);

        if(card){
            window.scrollTo({
                behavior: 'smooth', // not supported on mac?
                top: card.getBoundingClientRect().top - 150, // 2 * header(70 px high) + 10px(give some edge)
            })
        }
    }

    const history = useHistory();

    if(!currentMember){
        return null;
    }

    //TODO: Hardcoded
    const getBadgeIcon = (memberTypeId) => {
        switch (memberTypeId){
            case '1ec4d34a-9552-66b6-ad75-06bf14840f98': //int - dreams comes true
            case '9fc36077-9003-11ec-b007-022fbfd56a42': //prod - dreams comes true
                return <i className='mdi mdi-weather-night text-primary' />
            default:
                return <i className='mdi mdi-creation text-primary' />
        }
    }

    const hasBadge = currentMember.incomplete_profile == 1 || /* INCOMPLETE PROFILE */
   currentMember.addresses?.some((addr) => (addr.status === "UNCONFIRMED")) || /* UNCONFIRMED ADDRESS */
   currentMember.need_address_document == 1 || /* MISSING DOCUMENT */
    (currentMember.crc_is_expired == 1 || currentMember.missing_background_check == '1') || /* Background Check / CRC */
   currentMember.missing_required_fields?.length > 0 || /* missing required fields */
   currentMember.unsigned_critical_waivers == 1 || /* unsigned critical waivers */
    currentMember.pending_classification_request == 1 ||
    membersContext.permanentReleaseRequests.pending_permanent_release_requests ||
    !!currentMember.member_request_changes?.[0]?.member_request_change_id
    || currentMember.potential_duplicate == 1

    const currentMemberTypes = (currentMember.member_types || []).reduce((newArray, type) => {
        if(type.current_season == 1 && !newArray.some((n) => n.member_type.member_type_id === type.member_type.member_type_id)){
            newArray.push(type);
        }

        return newArray
    }, []);

    return (
        <div className='d-flex flex-wrap py-2'>
            {/* SPECIAL MEMBER TYPES */}
            {currentMemberTypes.reduce((newArray, m) => {
                if(m.member_type.system_type === memberTypeSpecial){
                    newArray.push(
                        <HasAccessTo componentCode='members' key={m.member_type.member_type_id}>
                            <MemberStatusButton
                                onClick={() => {
                                    history.push(`/members/profile/${currentMember.member_id}/overview`)
                                    setTimeout(() => { scrollToComponent('profileComponentMemberPrimaryInfo') }, 0)
                                }}
                            >
                                {getBadgeIcon(m.member_type.member_type_id)}
                                <span className="align-middle ml-2"><DisplayI18n field='name' defaultValue={m.member_type.name} i18n={m.member_type.i18n} /></span>
                            </MemberStatusButton>
                        </HasAccessTo>,
                    )
                }
                return newArray;
            }, [])}

            {hasBadge &&
                <QuickTour
                    quickTourName={'quickTourDeficienciesMemberProfile'}
                    title={<Translate id="members.profile.memberProfile.header.badges.quicktour.title" />}
                    message={<Translate id="members.profile.memberProfile.header.badges.quicktour.message" />}
                    disabled={!hasBadge}
                >
                    {currentMember.potential_duplicate == 1 &&
                        <HasAccessTo componentCode="members" componentPermissionCode="manage_potential_duplicate_members">
                            <MemberHeaderPotentialDuplicate refreshMemberProfile={refreshMemberProfile} />
                        </HasAccessTo>
                    }

                    {/* INCOMPLETE PROFILE */}
                    {currentMember.incomplete_profile == 1 &&
                        <HasAccessTo componentCode='members'>
                            <MemberStatusButton
                                onClick={() => {
                                    history.push(`/members/profile/${currentMember.member_id}/overview`)
                                    scrollToComponent('profileComponentMemberPrimaryInfo')
                                }}
                            >
                                <MemberAlertsIcon icon="profile" />
                                <span className="align-middle ml-2"><Translate id='members.profile.incomplete.title' /></span>
                            </MemberStatusButton>
                        </HasAccessTo>
                    }

                    {/* INELIGIBLE PROFILE */}
                    {currentMember.potential_ineligible == 1 &&
                        <HasAccessTo componentCode='members' componentPermissionCode='manage_potential_ineligible_members'>
                            <MemberStatusButton
                                onClick={() => {
                                    membersContext.setShouldOpenPotentialIneligibleModal(true);
                                }}
                            >
                                <i className='text-danger mdi mdi-account-remove' />
                                <span className="align-middle ml-2"><Translate id='members.profile.potential.ineligible.title' /></span>
                            </MemberStatusButton>
                        </HasAccessTo>
                    }

                    {/* UNCONFIRMED ADDRESS */}
                    {currentMember.addresses?.some((addr) => (addr.status === "UNCONFIRMED")) &&
                        <HasAccessTo componentCode='members'>
                            <MemberStatusButton
                                onClick={() => {
                                    history.push(`/members/profile/${currentMember.member_id}/overview`)
                                    setTimeout(() => { scrollToComponent('addressComponentMemberProfile') }, 0)
                                }}
                            >
                                <MemberAlertsIcon icon="address" />
                                <span className="align-middle ml-2"><Translate id='members.profile.unconfirmed_address.title' /></span>
                            </MemberStatusButton>
                        </HasAccessTo>
                    }

                    {/* MISSING DOCUMENT */}
                    {currentMember.need_address_document == 1 &&
                        <HasAccessTo componentCode='members'>
                            <MemberStatusButton
                                onClick={() => {
                                    history.push(`/members/profile/${currentMember.member_id}/overview`)
                                    setTimeout(() => { scrollToComponent('profileComponentMemberDocuments') }, 0)
                                }}
                            >
                                <MemberAlertsIcon icon="documents" />
                                <span className="align-middle ml-2"><Translate id="members.search.memberSearch.missingDocument" /></span>
                            </MemberStatusButton>
                        </HasAccessTo>
                    }

                    {/* Background Check / CRC */}
                    {(currentMember.crc_is_expired == 1 || currentMember.missing_background_check == '1') && // if CRC is invalid (non existant or expired)
                        <HasAccessTo componentCode='members' componentPermissionCode='member_background_check'>
                            <MemberStatusButton
                                onClick={() => {
                                    history.push(`/members/profile/${currentMember.member_id}/history`)
                                    setTimeout(() => { scrollToComponent('criminalRecordCheckCard') }, 0)
                                }}
                            >
                                <MemberAlertsIcon icon="crc" />
                                <span className="ml-2 align-middle"><Translate id='members.profile.crc.title' /></span>
                            </MemberStatusButton>
                        </HasAccessTo>
                    }

                    {/* missing required fields */}
                    {currentMember.missing_required_fields?.length > 0 && // if missing required fields
                        <HasAccessTo componentCode='members' componentPermissionCode='members_profile'>
                            <MemberStatusButton
                                onClick={() => {
                                    history.push(`/members/profile/${currentMember.member_id}/overview`)
                                    setTimeout(() => { scrollToComponent('profileComponentMemberPrimaryInfo') }, 0)
                                }}
                            >
                                <MemberAlertsIcon icon="fields" />
                                <span className="align-middle ml-2"><Translate id='members.search.memberSearch.missingFields.tooltip' /></span>
                            </MemberStatusButton>
                        </HasAccessTo>
                    }

                    {/* unsigned critical waivers */}
                    {currentMember.unsigned_critical_waivers == 1 && // if unsigned critical waivers
                        <MemberStatusButton
                            onClick={() => {
                                history.push(`/members/profile/${currentMember.member_id}/waivers`)
                            }}
                        >
                            <MemberAlertsIcon icon="waivers" />
                            <span className="align-middle ml-2"><Translate id='members.search.memberSearch.unsignedCriticalWaiver.tooltip' /></span>
                        </MemberStatusButton>
                    }

                    {/* Pending Madatory Changes Request */}
                    {!!currentMember.member_request_changes?.[0]?.member_request_change_id &&
                        <MemberStatusButton
                            onClick={() => {
                                membersContext.setShouldOpenChangeRequestModal(true)
                            }}
                        >
                            <MemberAlertsIcon icon="pendingRequest" />
                            <span className="align-middle ml-1"><Translate id='members.profile.badge.pendingRequestMandatoryChanges' /></span>
                        </MemberStatusButton>
                    }

                    {/* Pending Classification Changes Request */}
                    {currentMember.pending_classification_request == 1 &&
                        <MemberStatusButton
                            onClick={() => {
                                membersContext.setShouldOpenClassificationChangeRequestModal(true)
                            }}
                        >
                            <MemberAlertsIcon icon="pendingRequest" />
                            <span className="align-middle ml-1"><Translate id='members.profile.badge.pendingRequestClassificationChanges' /></span>
                        </MemberStatusButton>
                    }

                    {/* Pending Permanent Release Request */}
                    {membersContext.permanentReleaseRequests.pending_permanent_release_requests &&
                        <MemberStatusButton
                            onClick={() => {
                                membersContext.setShouldOpenPermanentReleaseRequestsModal(true)
                            }}
                        >
                            <MemberAlertsIcon icon="pendingRequest" />
                            <span className="align-middle ml-1"><Translate id='members.profile.badge.pendingRequestRelease' /></span>
                        </MemberStatusButton>
                    }

                    {/* Mutated year */}
                    {membersContext.currentMember.mutated_year &&
                        <Tooltip
                            label={
                                <>
                                    <Translate id='members.profile.badge.mutatedYear.expiration' />: <FormattedDate value={membersContext.currentMember.mutation_expiration_date} />
                                </>
                            }
                        >
                            <MemberStatusButton
                                onClick={() => {
                                    history.push(`/members/profile/${membersContext.currentMember.member_id}/history`)
                                    setTimeout(() => { scrollToComponent('memberProfileTransferCard') }, 0)
                                }}
                            >
                                <i style={{ width: 10, height: 10 }} className="mdi mdi-clock-alert-outline text-warning" />
                                <span className="align-middle ml-1"><Translate id='members.profile.badge.mutatedYear' values={{ count: membersContext.currentMember.mutated_year }} /></span>
                            </MemberStatusButton>
                        </Tooltip>
                    }

                    {/* Pending CTI Request */}
                    {/* {
                            true && // ***TODO: WIREFRAME
                            <div className={`text-center text-md-left mb-4 mr-2`}>
                                <div
                                    className="btn btn-sm rounded-pill bg-white font-medium shadow-sm clickable"
                                    onClick={() => {
                                        // Open Cancel Request Modal // ***TODO: WIREFRAME
                                    }}
                                >
                                    <MemberAlertsIcon icon="pendingRequest" />
                                    <span className="align-middle ml-1"><Translate id='members.profile.badge.pendingCtiRequest' /></span>
                                </div>
                            </div>
                        } */}
                </QuickTour>
            }
        </div>
    );
}

export default MemberHeaderStatusButtons;