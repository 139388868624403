import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import {
    Card
} from "reactstrap";
import CardSectionTitle from '../../../../../../components/CardSectionTitle';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { IdentityRolesContext } from '../../../../../../contexts/IdentityRolesContext';
import { RolesContext } from '../../../../../../contexts/RolesContext';
import ContactSettingsForm from './ContactSettingsForm';
import ContactSettingsView from './ContactSettingsView';
import { useSpordleTable } from '@spordle/datatables'
import CrossFade from '../../../../../../components/crossFade/CrossFade';
const ContactSettings = () => {
    const [ isEditing, setIsEditing ] = useState(false);
    const toggleIsEditing = () => setIsEditing(!isEditing);
    const spordleTable = useSpordleTable();
    const isLoading = spordleTable.state.loadingState === "loading";
    const allTypes = spordleTable.getData()
    const activeTypes = allTypes.filter((data) => data.active == 1);

    const rolesContext = useContext(RolesContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const canEdit = rolesContext.canDoAction("EDIT", "settings", "organization_contact_types");

    return (
        <Card body className="card-shadow">
            <OverlayLoader isLoading={isLoading}>
                <CardSectionTitle title='organization.settings.contactStaffSettings.card.title' className='d-flex'>
                    {((identityRolesContext.isGod() || canEdit) && !isEditing) &&
                        <div className={stringBuilder('ml-auto', { 'text-link': allTypes.length > 0 })}>
                            <button disabled={allTypes.length < 1} onClick={toggleIsEditing} className={stringBuilder("reset-btn align-self-end", { "text-purple": (identityRolesContext.isGod() && !canEdit) })} type='button'><Translate id='misc.edit' /></button>
                        </div>
                    }
                </CardSectionTitle>
                {allTypes.length > 0 &&
                    <CrossFade isVisible={isEditing}>
                        <ContactSettingsForm isLoading={isLoading} toggleIsEditing={toggleIsEditing} activeTypes={activeTypes} />
                    </CrossFade>
                }
                <CrossFade isVisible={!isEditing}>
                    <ContactSettingsView isLoading={isLoading} activeTypes={activeTypes} />
                </CrossFade>

            </OverlayLoader>
        </Card>
    );
}

export default ContactSettings;