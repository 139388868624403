import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import AnalyticsModal from "../../../../../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../../../../../api/CancellableAPI";
import { success } from '@spordle/toasts';
import { InjuriesContext } from "../../../../../../../../contexts/InjuriesContext";
import { OrganizationContext } from "../../../../../../../../contexts/OrganizationContext";
import { DisplayI18n } from "../../../../../../../../helpers/i18nHelper";
import { ISSUED_GUARDIAN, ISSUED_OTHER } from "../ChequeHelper";
import ChequeModalForm from "./form/ChequeModalForm";

const AddChequeModal = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName="injuryAddChequeModal">
            <AddChequeModalInner {...props} />
        </AnalyticsModal>
    );
}

const AddChequeModalInner = ({ toggle, getCheques, injury, ...props }) => {
    const { createInsuranceCheque } = useContext(InjuriesContext);
    const { organisation_id } = useContext(OrganizationContext);

    const handleOnSubmit = async(values, { setStatus }) => {
        const apiValues = {
            amount: values.amount,
            organisation_id: organisation_id,
            service_type_id: values.service_type_id,
            pay_to: values.pay_to,
            memo: values.memo,
        }

        if(values.pay_to === ISSUED_OTHER){
            apiValues.payee_id = values.payee_id;
        }else if(values.pay_to === ISSUED_GUARDIAN){
            apiValues.member_contact_id = values.member_contact_id;
        }

        if(values.pay_to !== ISSUED_OTHER){
            apiValues.member_address_id = values.member_address_id;
        }

        return createInsuranceCheque(injury.injury_id, apiValues)
            .then(() => {
                getCheques();
                success({
                    msg: 'insurance.sidePanel.cheque.addModal.success.msg',
                    info: 'insurance.sidePanel.cheque.addModal.success.info',
                });
                toggle();
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                    setStatus({ error: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />, isOpen: true });
                }

            })

    }

    return (
        <ChequeModalForm
            title={<Translate id='insurance.sidePanel.cheque.addModal.title' />}
            onSubmit={handleOnSubmit}
            toggle={toggle}
            injury={injury}
            isOpen={props.isOpen}
        />
    )
}

export default AddChequeModal;