import { FormikCurrencyInput, FormikError, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import React from 'react';
import {
    Alert,
    Button, Col, Collapse, FormGroup,
    Label, ModalBody,
    ModalFooter, ModalHeader, Row
} from "reactstrap";
import { array, number, object, string } from 'yup';
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import { success, fail } from '@spordle/toasts';
import { AffiliationFeesContext } from '../../../../contexts/AffiliationFeesContext';
import { PeriodsContext } from '../../../../contexts/contexts';
import { I18nContext } from '../../../../contexts/I18nContext';
import { MembersContext } from '../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { RegistrationCategoriesContext } from '../../../../contexts/RegistrationCategoriesContext';
import { RegistrationDivisionsContext } from '../../../../contexts/RegistrationDivisionsContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import withContexts from '../../../../helpers/withContexts';
import Required from '../../../../components/formik/Required';
import { FormsContext } from '../../../../contexts/FormsContext';
import { WaiversContext } from '../../../../contexts/WaiversContext';

class AffiliationFeesAdd extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            organizationChildrenSelect: null,
            memberTypeSelect: null,
            divisionSelect: null,
        }
    }

    onOpen = () => {
        // Load filters (preload if sent as props)
        // Branch Select
        if(this.props.organizationChildrenOptions){
            this.setState(() => ({ organizationChildrenSelect: this.props.organizationChildrenOptions.filter((o) => o.value !== 'ALL') }))
        }else{
            this.props.OrganizationContext.getOrganizationChildren(this.props.OrganizationContext.organisation_id)
                .then((organizations) => {
                    const myOptions = [];
                    organizations.forEach((organization) => (
                        myOptions.push({
                            value: organization.organisation_id,
                            label: organization.organisation_name,
                            i18n: organization.i18n,
                        })
                    ))
                    this.setState(() => ({ organizationChildrenSelect: myOptions }))
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                        this.setState(() => ({ organizationChildrenSelect: [] }))
                    }
                });
        }

        // Member Types Select Select
        if(this.props.memberTypeSelectOptions){
            this.setState(() => ({ memberTypeSelect: this.props.memberTypeSelectOptions.filter((o) => o.value !== 'ALL') }))
        }else{
            this.props.MembersContext.getMemberTypes(this.props.OrganizationContext.organisation_id)
                .then((memberTypes) => {
                    const myOptions = [];

                    memberTypes.forEach((type) => (
                        myOptions.push({
                            id: type.member_type_id,
                            value: type.member_type_id,
                            label: type.name,
                            i18n: type.i18n,
                        })
                    ))

                    this.setState(() => ({ memberTypeSelect: myOptions }));
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                        this.setState(() => ({ memberTypeSelect: [] }))
                    }
                })
        }

        // Divisions Select
        this.props.RegistrationDivisionsContext.getRegistrationDivisions(this.props.OrganizationContext.organisation_id, { active: 1 })
            .then((divisions) => {
                const myOptions = [];
                divisions
                    .sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))
                    .forEach((division) => (
                        myOptions.push({
                            value: division.division_id,
                            label: division.short_name,
                            i18n: division.i18n,
                        })
                    ))

                this.setState(() => ({ divisionSelect: myOptions }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({ divisionSelect: [] }))
                }
            })
    }


    render(){
        return (
            <AnalyticsModal analyticsName='AffiliationFeesAdd' isOpen={this.props.isOpen} onOpened={this.onOpen}>
                <Formik
                    initialValues={{
                        apply_for_organisation: [],
                        member_type_id: '',
                        division_id: [],
                        amount: '',
                        custom_form_id: '',
                        waiver_ids: [],
                    }}
                    validationSchema={object().shape({
                        apply_for_organisation: array(),
                        member_type_id: string().required(<Translate id='catalog.affiliationFees.memberType.required' />),
                        division_id: array().min(1, <Translate id='catalog.affiliationFees.division.required' />),
                        amount: number().min(0).required(<Translate id='catalog.affiliationFees.fee.required' />),
                        custom_form_id: string(),
                        waiver_ids: array().of(string()),
                    })}
                    onSubmit={({ custom_form_id, waiver_ids, ...values }, { setStatus }) => {
                        setStatus();
                        // data to send to api
                        const apiValues = { ...values, amount: (values.amount * 100).toFixed(0) };
                        return this.props.AffiliationFeesContext.createAffiliationFee(this.props.OrganizationContext.organisation_id, this.props.PeriodsContext.selectedPeriod.period_id, apiValues)
                            .then(async(affiliationFeeIds) => {
                                for(let index = 0; index < affiliationFeeIds.length; index++){
                                    const affiliationFeeId = affiliationFeeIds[index];
                                    if(custom_form_id)
                                        await this.props.AffiliationFeesContext.updateAffiliationFeePartial(affiliationFeeId, { custom_form_id: custom_form_id })
                                    if(waiver_ids.length > 0)
                                        await this.props.AffiliationFeesContext.updateAffiliationFeeWaiver(affiliationFeeId, waiver_ids)
                                }

                                this.props.spordleTable.refreshTable();
                                this.props.toggle();
                                success();
                            }).catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message);
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                                }
                            });
                    }}
                >
                    {(formik) => (
                        <Form>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <ModalHeader toggle={this.props.toggle}>
                                    <Translate id='catalog.affiliationFees.add.title' />
                                </ModalHeader>
                                <ModalBody>
                                    <Row form>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label for='apply_for_organisation' className='text-muted'><Translate id='catalog.affiliationFees.add.branch' /></Label>
                                                <FormikSelect
                                                    isLoading={!this.state.organizationChildrenSelect}
                                                    multi
                                                    textWhenSetting={{
                                                        count: 1,
                                                        label: 'catalog.affiliationFees.branches.selected',
                                                    }}
                                                    name='apply_for_organisation'
                                                    id='apply_for_organisation'
                                                    renderOption={({ option }) => (
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={option.label}
                                                            i18n={option.i18n}
                                                        />
                                                    )}
                                                    searchKeys={[
                                                        `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                    ]}
                                                    options={this.state.organizationChildrenSelect ?? []}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for='member_type_id' className='text-muted'><Translate id='catalog.affiliationFees.add.membertype' /> <Required /></Label>
                                                <FormikSelect
                                                    isLoading={!this.state.memberTypeSelect}
                                                    name='member_type_id'
                                                    id='member_type_id'
                                                    renderOption={({ option }) => (
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={option.label}
                                                            i18n={option.i18n}
                                                        />
                                                    )}
                                                    searchKeys={[
                                                        `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                    ]}
                                                    options={this.state.memberTypeSelect ?? []}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label for='division_id' className='text-muted'><Translate id='catalog.affiliationFees.add.division' /> <Required /></Label>
                                                <FormikSelect
                                                    isLoading={!this.state.divisionSelect}
                                                    name='division_id'
                                                    id='division_id'
                                                    textWhenSetting={{
                                                        count: 3,
                                                        label: 'catalog.affiliationFees.divsions.selected',
                                                    }}
                                                    multi
                                                    searchKeys={[
                                                        `i18n.${this.props.I18nContext.getGenericLocale()}.short_name`,
                                                    ]}
                                                    renderOption={({ option }) => (
                                                        <DisplayI18n
                                                            field='short_name'
                                                            defaultValue={option.label}
                                                            i18n={option.i18n}
                                                        />
                                                    )}
                                                    options={this.state.divisionSelect ?? []}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs='12' sm='7'>
                                            <FormGroup>
                                                <Label for='amount' className='text-muted'><Translate id='catalog.affiliationFees.add.fee' /> <Required /></Label>
                                                <div className="d-flex align-items-center">
                                                    <div className="mr-3">
                                                        <FormikCurrencyInput
                                                            id='amount'
                                                            name='amount'
                                                            allowNegative={false}
                                                            manualError
                                                        />
                                                    </div>
                                                    <div className="small text-muted text-nowrap">CAD (<Translate id='catalog.affiliationFees.add.taxIncluded' />)</div>
                                                </div>
                                                <FormikError name='amount' />
                                            </FormGroup>
                                        </Col>
                                        <Col xs='12'>
                                            <FormGroup>
                                                <Label for='custom_form_id' className='text-muted'><Translate id='form.fields.forms' /></Label>
                                                <FormikSelect
                                                    id='custom_form_id'
                                                    name='custom_form_id'
                                                    clearable
                                                    loadData={(from) => {
                                                        switch (from){
                                                            case 'CDM':
                                                                return this.props.FormsContext.getForms()
                                                                    .then((forms) => {
                                                                        return forms.map((form) => ({
                                                                            label: form.name,
                                                                            value: form.custom_form_id,
                                                                        }))
                                                                    })
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs='12'>
                                            <FormGroup>
                                                <Label for='waiver_ids' className='text-muted'><Translate id='catalog.registrationFees.add.form.waivers' /></Label>
                                                <I18nContext.Consumer>
                                                    {(i18n) => (
                                                        <FormikSelect
                                                            name='waiver_ids'
                                                            id='waiver_ids'
                                                            clearable
                                                            multi
                                                            searchKeys={[
                                                                `i18n.${i18n.getGenericLocale()}.name`,
                                                            ]}
                                                            renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                                            loadData={(from) => {
                                                                switch (from){
                                                                    case 'CDM':
                                                                        return this.props.WaiversContext.getWaivers()
                                                                            .then((waivers) => {
                                                                                return waivers.map((waiver) => ({
                                                                                    value: waiver.waiver_id,
                                                                                    i18n: waiver.i18n,
                                                                                    label: waiver.name,
                                                                                }))
                                                                            })
                                                                    default:
                                                                        break;
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </I18nContext.Consumer>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                                        <Alert color='danger' className='mb-0'>
                                            {formik.status}
                                        </Alert>
                                    </Collapse>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type="submit" disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                    <Button color='primary' type="button" disabled={formik.isSubmitting} outline onClick={this.props.toggle}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </OverlayLoader>
                        </Form>
                    )}
                </Formik>
            </AnalyticsModal>
        );
    }
}

export default withContexts(I18nContext, PeriodsContext, OrganizationContext, MembersContext, RegistrationDivisionsContext, RegistrationCategoriesContext, AffiliationFeesContext, FormsContext, WaiversContext)(AffiliationFeesAdd);