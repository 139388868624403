import { useContext, useEffect, useState } from "react";
import { RegistrationFeeContext } from "../../../../../contexts/RegistrationFeeContext";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { jsObjectToApi, stringBuilder } from "@spordle/helpers";
import { getAdditionalFieldsRender, getFormattedAdditionalFields } from "../../../../../helpers/additionalFieldsHelpers";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import CrossFade from "../../../../../components/crossFade/CrossFade";
import styles from "./RolloverModal.module.scss";
import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import TripleArrow from "../../../../../assets/images/Triple_Arrow.png"
import { Button, Col, Row, Spinner } from "reactstrap";
import CustomAnimatedIcon from "../../../../../components/customAnimatedIcon/CustomAnimatedIcon";
import { Form, Formik } from "formik";
import { FormikDateTime } from "@spordle/formik-elements";

const RegistrationFeeCard = ({ divisions, row, categories, onFinally, isSubmitting, values, errors, getFormGroups, getAdditionalFields, validationSchema, onSubmit }) => {
    const [ editMode, setEditMode ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ status, setStatus ] = useState(null);
    const [ hasSubmitted, setHasSubmitted ] = useState(false);

    const registrationFeesContext = useContext(RegistrationFeeContext);
    const i18nContext = useContext(I18nContext)

    const newDivision = (divisions || []).find((div) => div.division_id === row.division?.division_id)
    const newCategory = (categories || []).find((cat) => cat.team_category_id === row.team_category?.team_category_id)

    const oldAffiliationFee = row.exclude_affiliation_fee == 0 ? row.affiliation_fees?.total?.[row.member_type.member_type_id]?.amount || 0 : 0;
    const newAffiliationFee = row.exclude_affiliation_fee == 0 ? newDivision?.affiliation_fees?.total?.[row.member_type.member_type_id]?.amount || 0 : 0;

    const hasEarlyFee = !!row.fee.early_amount;
    const hasLateFee = !!row.fee.late_amount;

    useEffect(() => {
        if(isSubmitting){
            setIsLoading(true);
            setEditMode(false);
            setHasSubmitted(true);

            const newValues = {
                member_type_id: row.member_type.member_type_id,
                sku: '',
                division_id: newDivision?.division_id,
                team_category_id: newCategory?.team_category_id,
                active: '1',
                available_place: row.available_place || '',
                amount: row.fee.amount,
                tax_class_id: '',
                custom_form_id: '',
                exclude_affiliation_fee: row.exclude_affiliation_fee,
                manage_waiting_list: row.manage_waiting_list,
                show_registration_count: row.show_registration_count,
                with_position: row.with_position,
                name: row.fee.name,
                i18n: row.fee.i18n || { en: { name: row.fee.name, description: '' }, fr: { name: '', description: '' } },
            }

            if(hasEarlyFee){
                newValues.early_amount = row.fee.early_amount
                newValues.early_amount_until = values.earlyDate.set({ 'hour': values.earlyTime.hour(), 'minute': values.earlyTime.minute() }).toISOString()
            }

            if(hasLateFee){
                newValues.late_amount = row.fee.late_amount
                newValues.late_amount_after = values.lateDate.set({ 'hour': values.lateTime.hour(), 'minute': values.lateTime.minute() }).toISOString()
            }

            const apiFormat = jsObjectToApi(newValues)
            apiFormat.fields = getFormattedAdditionalFields(values.fields, getFormGroups(), i18nContext.getGenericLocale())

            registrationFeesContext.createRegistrationFee(apiFormat)
                .then(() => {
                    setIsLoading(false);
                    setStatus('success');
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message);
                        setIsLoading(false);
                        setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                    }
                })
                .finally(() => {
                    onFinally?.(row.registration_fee_id)
                })
        }
    }, [ isSubmitting ])

    return (
        <UserDisplay
            card block
            className={stringBuilder(styles.rolloverCard, { 'border-danger': !!errors })}
        >
            <CrossFade isVisible={!editMode} className='w-100'>
                <div className={stringBuilder(styles.feeDisplay)}>
                    {(hasEarlyFee || hasLateFee || getFormGroups().length > 0) &&
                        <button className={stringBuilder("reset-btn", styles.configure, { [styles.hasSubmitted]: hasSubmitted }, { 'text-danger': !!errors, 'text-primary': !errors })} type='button' style={{ cursor: hasSubmitted || isSubmitting ? 'inherit' : undefined }}>
                            <i
                                className="mdi mdi-settings font-18"
                                onClick={hasSubmitted || isSubmitting ? undefined : () => {
                                    setEditMode(!editMode);
                                }}
                            />
                        </button>
                    }

                    <UserDisplay.Container className={stringBuilder(styles.oldData, { [styles.hasSubmitted]: hasSubmitted }, 'text-truncate')}>
                        <UserDisplay.Title>
                            <div className="text-truncate">
                                <DisplayI18n field='name' defaultValue={row.fee.name} i18n={row.fee.i18n} />
                            </div>
                        </UserDisplay.Title>
                        <UserDisplay.Subtitle>
                            <DisplayI18n
                                field='short_name'
                                i18n={row.division?.i18n}
                                defaultValue={row.division?.short_name}
                            />
                            {row.team_category?.class &&
                                <>
                                    <span className='mx-1'>-</span>
                                    <DisplayI18n
                                        field='short_name'
                                        i18n={row.team_category?.class?.i18n}
                                        defaultValue={row.team_category?.class?.short_name}
                                    />
                                </>
                            }
                        </UserDisplay.Subtitle>
                        <UserDisplay.Subtitle><CurrencyFormat value={(parseInt(row.fee.amount) + parseInt(oldAffiliationFee)) / 100} /></UserDisplay.Subtitle>
                    </UserDisplay.Container>
                    <UserDisplay.Container className={stringBuilder(styles.arrow, { [styles.hasSubmitted]: hasSubmitted })}>
                        <img src={TripleArrow} width='30' />
                    </UserDisplay.Container>

                    {/* we display the "new" division, team category and price */}
                    <UserDisplay.Container className={stringBuilder(styles.newData, 'text-truncate', { [styles.hasSubmitted]: hasSubmitted })}>
                        <UserDisplay.Title>
                            <div className="text-truncate">
                                <DisplayI18n field='name' defaultValue={row.fee.name} i18n={row.fee.i18n} />
                            </div>
                        </UserDisplay.Title>
                        <UserDisplay.Subtitle>
                            <DisplayI18n
                                field='short_name'
                                i18n={newDivision?.i18n}
                                defaultValue={newDivision?.short_name}
                            />
                            {newCategory?.class &&
                                <>
                                    <span className='mx-1'>-</span>
                                    <DisplayI18n
                                        field='short_name'
                                        i18n={newCategory?.class?.i18n}
                                        defaultValue={newCategory?.class?.short_name}
                                    />
                                </>
                            }
                        </UserDisplay.Subtitle>
                        <UserDisplay.Subtitle><CurrencyFormat value={(parseInt(row.fee.amount) + parseInt(newAffiliationFee)) / 100} /></UserDisplay.Subtitle>

                        <CrossFade isVisible={!!status && status !== 'success'}>
                            <UserDisplay.Subtitle className="text-danger">
                                {status}
                            </UserDisplay.Subtitle>
                        </CrossFade>
                    </UserDisplay.Container>

                    <UserDisplay.Container className={stringBuilder(styles.icon, { [styles.hasSubmitted]: hasSubmitted })}>
                        {isLoading &&
                            <Spinner color='primary' style={{ width: '25px', height: '25px', borderWidth: '1px' }} />
                        }
                        {((!isLoading && status)) &&
                            <CustomAnimatedIcon
                                id={'customStatusIcon-' + row.registration_fee_id}
                                size='25px'
                                icon={status === 'success' ? 'checkmark' : 'error'}
                                withCircle
                                className={status === 'success' ? 'text-success' : 'text-danger'}
                            />
                        }
                    </UserDisplay.Container>
                </div>
                {!!errors &&
                    <div className="text-danger text-center small">
                        <Translate id='catalog.registrationFees.rollover.invalid' />
                    </div>
                }
            </CrossFade>

            <CrossFade isVisible={editMode} className='w-100' mountOnEnter unmountOnExit>
                <Formik
                    initialValues={values}
                    initialTouched={{
                        fields: Object.keys(values.fields).reduce((touched, key) => {
                            touched[key] = true
                            return touched
                        }, {}),
                    }}
                    validationSchema={validationSchema}
                    validateOnMount
                    onSubmit={(values) => {
                        onSubmit(values)
                        setEditMode(false)
                    }}
                >
                    <Form>
                        <div className="d-flex align-items-center flex-wrap">
                            <UserDisplay.Container className='w-100'>
                                <UserDisplay.Title className='mb-2'>
                                    <DisplayI18n field='name' defaultValue={row.fee.name} i18n={row.fee.i18n} />
                                </UserDisplay.Title>

                                <div className="mb-3 border-bottom">

                                    <div className="mb-3">
                                        <UserDisplay.Subtitle>
                                            <Translate
                                                id='catalog.registrationFees.rollover.earlyFee'
                                                values={{
                                                    amount: <CurrencyFormat value={hasEarlyFee ? (parseInt(row.fee.early_amount) + parseInt(newAffiliationFee)) / 100 : 0} />,
                                                }}
                                            />
                                        </UserDisplay.Subtitle>
                                        <Row form>
                                            <Col xs='6'>
                                                <FormikDateTime
                                                    inputProps={{
                                                        disabled: !hasEarlyFee,
                                                    }}
                                                    name={'earlyDate'}
                                                    timeFormat={false}
                                                />
                                            </Col>
                                            <Col xs='6'>
                                                <FormikDateTime
                                                    inputProps={{
                                                        disabled: !hasEarlyFee,
                                                    }}
                                                    name={'earlyTime'}
                                                    dateFormat={false}
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="mb-3">
                                        <UserDisplay.Subtitle>
                                            <Translate
                                                id='catalog.registrationFees.rollover.lateFee'
                                                values={{
                                                    amount: <CurrencyFormat value={hasLateFee ? (parseInt(row.fee.late_amount) + parseInt(newAffiliationFee)) / 100 : 0} />,
                                                }}
                                            />
                                        </UserDisplay.Subtitle>
                                        <Row form>
                                            <Col xs='6'>
                                                <FormikDateTime
                                                    inputProps={{
                                                        disabled: !hasLateFee,
                                                    }}
                                                    name={'lateDate'}
                                                    timeFormat={false}
                                                />
                                            </Col>
                                            <Col xs='6'>
                                                <FormikDateTime
                                                    inputProps={{
                                                        disabled: !hasLateFee,
                                                    }}
                                                    name={'lateTime'}
                                                    dateFormat={false}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                {getAdditionalFieldsRender(getFormGroups(), 'fields', getAdditionalFields(row), false, 'mb-2')}
                            </UserDisplay.Container>

                            <div className="mt-auto ml-auto">
                                <Button color='primary' type='submit' className="mr-2"><Translate id='misc.save' /></Button>
                                <Button color='primary' type='button' outline onClick={() => setEditMode(false)}><Translate id='misc.cancel' /></Button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </CrossFade>
        </UserDisplay>
    )
}

export default RegistrationFeeCard