import { useSpordleTable } from '@spordle/datatables';
import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import {
    Card
} from "reactstrap";
import CardSectionTitle from '../../../../../../components/CardSectionTitle';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { IdentityRolesContext } from '../../../../../../contexts/IdentityRolesContext';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { RolesContext } from '../../../../../../contexts/RolesContext';
import DocumentSettingsForm from './DocumentSettingsForm';
import DocumentSettingsView from './DocumentSettingsView';

const DocumentSettings = () => {
    const [ isEditing, setIsEditing ] = useState(false);
    const toggleIsEditing = () => setIsEditing(!isEditing);
    const spordleTable = useSpordleTable();
    const isLoading = spordleTable.state.loadingState === "loading";
    const activeTypes = spordleTable.getData().filter((data) => data.active == 1);

    const organisationContext = useContext(OrganizationContext);
    const rolesContext = useContext(RolesContext);
    const identityRolesContext = useContext(IdentityRolesContext);

    const [ memberRegistrationDocumentTypes, setMemberRegistrationDocumentTypes ] = useState(false);
    const [ clinicRegistrationDocumentTypes, setClinicRegistrationDocumentTypes ] = useState(false);
    const [ insuranceDocumentTypes, setInsuranceDocumentTypes ] = useState(false);
    const [ travelPermitDocumentTypes, setTravelPermitDocumentTypes ] = useState(false);

    const filterDocTypes = (docs, key) => {
        return organisationContext.settings[key].value.reduce((docTypes, typeId) => {
            const foundDoc = docs.find(({ document_type_id }) => document_type_id === typeId);

            if(foundDoc){
                docTypes.push(foundDoc);
            }

            return docTypes;
        }, []);
    };

    useEffect(() => {
        if(!isEditing){
            setMemberRegistrationDocumentTypes(filterDocTypes(activeTypes, "attachment_for_new_member_in_registration"));
            setClinicRegistrationDocumentTypes(filterDocTypes(activeTypes, "attachment_for_new_member_in_clinic_registration"));
            setInsuranceDocumentTypes(filterDocTypes(activeTypes, "document_type_list_insurance"));
            setTravelPermitDocumentTypes(filterDocTypes(activeTypes, "attachment_for_travel_permit"));
        }
    }, [ spordleTable.state.data, isEditing ]);

    // always need ADD regional_settings for PUT /settings
    const canEdit = rolesContext.canDoAction("ADD", "settings", "settings_regional") && (rolesContext.canDoAction("ADD", "settings", "member_registration_document_type_configuration") || rolesContext.canDoAction("ADD", "settings", "clinic_document_type_configuration") || rolesContext.canDoAction("ADD", "settings", "insurance_document_type_configuration"));

    return (
        <Card body className="card-shadow">
            <OverlayLoader isLoading={isLoading}>
                <CardSectionTitle title='settings.documentSettings.title' className='d-flex'>
                    {((identityRolesContext.isGod() || canEdit) && !isEditing) &&
                        <div className='ml-auto text-link'>
                            <button onClick={toggleIsEditing} className={stringBuilder("reset-btn align-self-end", { "text-purple": (identityRolesContext.isGod() && !canEdit) })} type='button'><Translate id='misc.edit' /></button>
                        </div>
                    }
                </CardSectionTitle>
                {isEditing ?
                    <DocumentSettingsForm
                        isDocumentsLoading={isLoading}
                        toggleIsEditing={toggleIsEditing}
                        documentTypes={activeTypes}
                        memberRegistrationDocumentTypes={memberRegistrationDocumentTypes}
                        clinicRegistrationDocumentTypes={clinicRegistrationDocumentTypes}
                        insuranceDocumentTypes={insuranceDocumentTypes}
                        travelPermitDocumentTypes={travelPermitDocumentTypes}
                    />
                    :
                    <DocumentSettingsView
                        isDocumentsLoading={isLoading}
                        memberRegistrationDocumentTypes={memberRegistrationDocumentTypes}
                        clinicRegistrationDocumentTypes={clinicRegistrationDocumentTypes}
                        insuranceDocumentTypes={insuranceDocumentTypes}
                        travelPermitDocumentTypes={travelPermitDocumentTypes}
                    />
                }
            </OverlayLoader>
        </Card>
    );
}

export default DocumentSettings;