import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Nav, NavItem, NavLink, Fade, Table, TabContent, TabPane, Row, Col } from 'reactstrap';
import { stringBuilder } from "@spordle/helpers";
import * as Yup from 'yup'

import { success, fail } from '@spordle/toasts';
import { DisplayI18n } from '../../../../helpers/i18nHelper';

// Formik
import FormikEditable from '../../../../components/formik/FormikEditable';
import { FormikCurrencyInput, FormikSelect } from '@spordle/formik-elements';

// Context
import withContexts from "../../../../helpers/withContexts";
import { I18nContext } from "../../../../contexts/I18nContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { QualificationsContext } from '../../../../contexts/QualificationsContext';
import { QualificationCategoriesContext } from "../../../../contexts/QualificationCategoriesContext";

// Language
import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import ClinicCategory from "../../../clinics/ClinicCategory";
import SidePanel from '../../../../components/sidePanel/SidePanel';
import UserImg from '../../../../components/UserImg';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';

class SidePanelClinicQualifications extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            activeTab: '1',
        }
    }

    // updateI18nInfo = () => {
    //     this.i18n = initSidePanelViewI18n(this.props.i18n.languages, [ 'name' ], this.props.getCommonValue('i18n'))
    // }

    // componentDidMount(){
    //     this.updateI18nInfo();
    // }

    // componentDidUpdate(prevProps){
    //     if(prevProps.selectedRows !== this.props.selectedRows){
    //         this.updateI18nInfo();
    //     }
    // }

    render(){
        return (
            <PerfectScrollbar>
                {/*-------------- HEADER --------------*/}
                <SidePanel.Header noBorder>
                    <div className='d-flex mb-2'>
                        <SidePanel.ToggleButton />
                        <SidePanel.ActionsMenu action='DELETE' componentCode='clinics' componentPermissionCode='clinics_settings'>
                            <SidePanel.MenuDelete
                                label='settings.clinics.sidePanelClinicQualifications.actions.remove'
                                translateModalMsg
                                modalMsg='settings.clinics.sidePanelClinicQualifications.actions.remove.topText'
                                onConfirm={() => {
                                    return this.props.QualificationCategoriesContext.deleteQualificationCategory(this.props.getCommonValue('qualification_id'))
                                        .then(() => {
                                            this.props.tableRef.deleteRow(this.props.getCommonValue('qualification_id'))
                                                .then(() => {
                                                    this.props.toggle;
                                                    success();
                                                });
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                }}
                                modalContent={
                                    <Row className='mt-3'>
                                        <Col md='6' className='d-flex align-items-center justify-content-center flex-column border-right'>
                                            <div className='font-medium'>
                                                <DisplayI18n
                                                    field="name"
                                                    i18n={this.props.getCommonValue('i18n')}
                                                    defaultValue={this.props.getCommonValue('name')}
                                                />
                                            </div>
                                        </Col>
                                        <Col md='6' className='d-flex align-items-center justify-content-center flex-column'>
                                            <div className='mr-2'>
                                                <UserImg
                                                    src={this.props.OrganizationContext.logo?.full_path}
                                                    abbr={this.props.OrganizationContext.abbreviation}
                                                    alt={this.props.OrganizationContext.organisation_name}
                                                    filePos={this.props.OrganizationContext.logo?.file_position}
                                                />
                                            </div>
                                            <div className='font-medium'>{this.props.OrganizationContext.organisation_name}</div>
                                        </Col>
                                    </Row>
                                }
                            />
                        </SidePanel.ActionsMenu>
                    </div>
                    <div className="font-bold h5 mb-1">
                        <ClinicCategory color={!isNaN(parseInt(this.props.selectedRows[0]?.qualification_category?.color)) ? this.props.selectedRows[0]?.qualification_category?.color : 0}>
                            <DisplayI18n
                                field='name'
                                defaultValue={this.props.selectedRows[0]?.qualification_category?.name}
                                i18n={this.props.selectedRows[0]?.qualification_category?.i18n}
                            />
                        </ClinicCategory>
                    </div>
                    <SidePanel.Title>
                        <DisplayI18n
                            field="name"
                            defaultValue={this.props.getCommonValue('name')}
                            i18n={this.props.getCommonValue('i18n')}
                        />
                    </SidePanel.Title>
                    <SidePanel.Subtitle>
                        <DisplayI18n
                            field='description'
                            defaultValue={this.props.getCommonValue('description')}
                            i18n={this.props.getCommonValue('i18n')}
                        />
                    </SidePanel.Subtitle>
                </SidePanel.Header>
                {/*------------------------------------*/}

                {/*-------------- BODY ----------------*/}
                <Fade in>
                    <Nav tabs className='d-flex'>
                        <NavItem className='flex-grow-1 text-center w-100'>
                            <NavLink
                                className={stringBuilder({ active: this.state.activeTab === '1' })}
                                onClick={() => this.setState(() => ({ activeTab: '1' }))}
                            >
                                <Translate id='settings.clinics.sidePanelClinicQualifications.tabs.details' />
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId='1'>
                            {/* Qualification INFO */}
                            <div className='p-3 border-bottom'>
                                <div className="h4 font-bold mb-3"><Translate id='settings.clinics.sidePanelClinicQualifications.title' /></div>

                                {/* Status */}
                                <div className='mb-3'>
                                    <div className="text-muted"><Translate id='settings.clinics.sidePanelClinicQualifications.status' /></div>
                                    <FormikEditable
                                        noConfirmation
                                        id={`qualificationCategoryStatus`}
                                        initialValues={{
                                            active: this.props.getCommonValue("active"),
                                        }}
                                        validationSchema={Yup.object().shape({
                                            active: Yup.string().required(<Translate id='settings.clinics.sidePanelClinicQualifications.status.required' />),
                                        })}
                                        onSubmit={(values) => {
                                            if(values.active !== this.props.getCommonValue("active")){
                                                const newValues = this.props.createNewValues(values);
                                                this.props.QualificationsContext.updateQualification(this.props.getCommonValue('qualification_id'), values)
                                                    .then(() => {
                                                        this.props.syncRows(newValues);
                                                        success();
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                            }
                                        }}
                                    >
                                        {(isEditing, options) => {
                                            if(!isEditing){
                                                return (
                                                    <div className="font-medium">
                                                        <i className={`mdi ${this.props.getCommonValue("active") == '1' ? 'mdi-check text-primary' : 'mdi-close text-danger'} mr-2`} />
                                                        <span><Translate id={`settings.clinics.sidePanelClinicQualifications.status.${this.props.getCommonValue("active") == '1' ? 'active' : 'inactive'}`} /></span>
                                                    </div>
                                                )
                                            }
                                            return (
                                                <FormikSelect
                                                    autoFocus menuIsDefaultOpen
                                                    name="active" id="active"
                                                    onOptionSelected={() => {
                                                        options.stopEditing();
                                                    }}
                                                    defaultData={[
                                                        { value: '1', label: 'settings.clinics.sidePanelClinicQualifications.status.active', translateLabel: true },
                                                        { value: '0', label: 'settings.clinics.sidePanelClinicQualifications.status.inactive', translateLabel: true },
                                                    ]}
                                                    loadingStatus='success'
                                                />
                                            )

                                        }}
                                    </FormikEditable>
                                </div>

                                <div className='mb-5'>
                                    <div className='text-muted'><Translate id='settings.clinics.sidePanelClinicQualifications.price' /></div>
                                    <FormikEditable
                                        id="fee"
                                        disabled
                                        initialValues={{
                                            fee: this.props.selectedRows[0].fee ?? 0.00,
                                        }}
                                        onSubmit={(values) => {
                                            if(values.fee !== this.props.getCommonValue("fee")){
                                                values.fee *= 100
                                                const newValues = this.props.createNewValues(values);
                                                this.props.QualificationsContext.updateQualification(this.props.getCommonValue('qualification_id'), values)
                                                    .then(() => {
                                                        this.props.syncRows(newValues);
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                            }
                                        }}
                                    >
                                        {(isEditing) => {
                                            if(!isEditing){
                                                return <div className='d-flex justify-content-between align-items-center'>{this.props.selectedRows[0].fee ? <CurrencyFormat currencyDisplay='narrowSymbol' value={this.props.selectedRows[0].fee / 100} /> : <CurrencyFormat currencyDisplay='narrowSymbol' value={0} />}</div>
                                            }
                                            return (
                                                <FormikCurrencyInput id='fee' name='fee' autoFocus />
                                            )

                                        }}
                                    </FormikEditable>
                                </div>

                                {this.props.selectedRows[0]?.prerequisites?.qualification_prerequisites?.length > 0 &&
                                    <div>
                                        <div className="h4 mb-1 font-bold"><Translate id='settings.clinics.sidePanelClinicQualifications.prerequisites' /></div>
                                        {(this.props.selectedRows[0]?.prerequisites?.prerequisite_info?.restriction_condition && this.props.selectedRows[0]?.prerequisites?.prerequisite_info?.restriction_condition === "all") ?
                                            <div className="small text-primary"><i className="mr-1 mdi mdi-information-outline" /><Translate id='settings.clinics.sidePanelClinicQualifications.prerequisites.required.all' /></div>
                                            :
                                            <div className="small text-primary"><i className="mr-1 mdi mdi-information-outline" /><Translate id='settings.clinics.sidePanelClinicQualifications.prerequisites.required.partial' /></div>
                                        }
                                        <Table className='mb-0' size='sm'>
                                            <tbody>
                                                {this.props.selectedRows[0]?.prerequisites?.qualification_prerequisites?.map((data, index) => {
                                                    return (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="font-bold">
                                                                    <DisplayI18n
                                                                        field='name'
                                                                        defaultValue={data?.name}
                                                                        i18n={data?.i18n}
                                                                    />
                                                                </div>
                                                                <div className="small">
                                                                    <DisplayI18n
                                                                        field='name'
                                                                        defaultValue={data?.organisation?.name}
                                                                        i18n={data?.organisation?.i18n}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                }
                            </div>
                        </TabPane>
                    </TabContent>
                </Fade>
                {/*------------------------------------*/}
            </PerfectScrollbar>
        );
    }
}
export default withContexts(I18nContext, OrganizationContext, QualificationsContext, QualificationCategoriesContext)(SidePanelClinicQualifications);