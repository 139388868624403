import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext, useEffect } from 'react';
import {
    Card,
    CardBody
} from "reactstrap";
import CardSectionTitle from '../../../../../../components/CardSectionTitle';
import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView, useSpordleTable } from '@spordle/datatables';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';

// contexts
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { I18nContext } from '../../../../../../contexts/I18nContext';
import EmptyLayout from '../../../../../../components/table/EmptyLayout';
import StatusBadge from '../../../../../../components/badges/StatusBadge';
import DisplayOrganization from '../../../../../../components/organization/DisplayOrganization';
import TruncateWithTooltip from '../../../../../../components/reports/TruncateWithTooltip';
import UserDisplay from '../../../../../../components/userDisplay/UserDisplay';
import SpordlePanelTable from '../../../../../../components/sidePanel/SpordlePanel';
import MemberStatusLogSidepanel from './components/MemberStatusLogSidepanel';
import { RolesContext } from '../../../../../../contexts/RolesContext';

const MemberStatusLog = () => {
    const membersContext = useContext(MembersContext);
    const i18nContext = useContext(I18nContext);
    const rolesContext = useContext(RolesContext);

    return (
        <Card id="memberStatusLogCard" className="card-shadow">
            <CardBody>
                <CardSectionTitle title='members.profile.status.log.title' />

                <SpordlePanelTable
                    id='member_status_logs_sidepanel'
                    allowOutsideClick
                    sidePanel={(props) => <MemberStatusLogSidepanel {...props} />}
                    dataIndex='member_status_log_id'
                    table={(panelProps) => (
                        <SpordleTableProvider
                            id='suspensions'
                            ref={panelProps.spordleTableRef}
                            tableHover bordered striped
                            clickable
                            desktopWhen
                            dataIndex='member_status_log_id'
                            pagination={5}
                            loadData={(from, data, spordleTable) => {
                                switch (from){
                                    case 'REFRESH':
                                    case 'CDM':
                                        spordleTable.setLoading();
                                        return Promise.resolve(membersContext.currentMember.member_status_logs);
                                    default:
                                        break;
                                }
                            }}
                            searchKeys={[
                                `member_status.i18n.${i18nContext.getGenericLocale()}.name`,
                                `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                                `member_status_reason.i18n.${i18nContext.getGenericLocale()}.name`,
                                'organisation.name',
                                'created_at',
                                'created_by.family_name',
                                'created_by.name',
                                'created_by.email',
                                `member_status.name`,
                                `member_status.code`,
                                `member_status_reason.code`,
                                `member_status_reason.name`,
                                'note',
                            ]}
                            columns={[
                                {
                                    label: <Translate id='members.profile.suspensions.changeDate' />,
                                    key: 'created_at',
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='reports.column.header.created_by' />,
                                    key: 'created_by',
                                    sortable: true,
                                    sortKey: 'created_by.family_name',
                                },
                                {
                                    label: <Translate id='members.profile.statusChange.reason.short' />,
                                    key: 'reason',
                                    sortable: true,
                                    sortKey: `member_status_reason.i18n.${i18nContext.getGenericLocale()}.name`,
                                },
                                {
                                    label: <Translate id='members.profile.suspensions.status' />,
                                    key: 'status',
                                    sortable: true,
                                    sortKey: `member_status.i18n.${i18nContext.getGenericLocale()}.name`,
                                },
                            ]}
                            defaultSorting='-created_at'
                            renderRow={(columnKey, statusChange, spordleTable) => {
                                const previousStatus = spordleTable.getData()[spordleTable.getData().findIndex((row) => row.member_status_log_id === statusChange.member_status_log_id) - 1]?.member_status
                                const canSeeNoteAndReason = (statusChange.member_status?.code === 'INELIGIBLE' || previousStatus?.code === 'INELIGIBLE') ? rolesContext.hasAccessTo('members', 'manage_ineligible_members') : true;
                                switch (columnKey){
                                    case 'created_at':
                                        return <DateFormat value={statusChange.created_at} format='YYYY-MM-DD' utc />
                                    case 'created_by':
                                        return (
                                            <UserDisplay>
                                                <UserDisplay.Container>
                                                    <UserDisplay.Title>
                                                        {statusChange.created_by?.name}  {statusChange.created_by?.family_name}
                                                    </UserDisplay.Title>
                                                    <UserDisplay.Subtitle>
                                                        {statusChange.created_by?.email}
                                                    </UserDisplay.Subtitle>
                                                    {statusChange.organisation?.organisation_id &&
                                                        <UserDisplay.Subtitle>
                                                            <DisplayOrganization organisation={{ organisation_id: statusChange.organisation?.organisation_id }}>
                                                                <DisplayI18n field='name' defaultValue={statusChange.organisation?.name} i18n={statusChange.organisation?.i18n} />
                                                            </DisplayOrganization>
                                                        </UserDisplay.Subtitle>
                                                    }
                                                </UserDisplay.Container>
                                            </UserDisplay>
                                        )
                                    case 'reason':
                                        return (
                                            canSeeNoteAndReason ?
                                                <>
                                                    {statusChange.member_status_reason && <div className="mb-1"><div className='font-bold'><Translate id="form.fields.reason" />: </div><DisplayI18n field='name' defaultValue={statusChange.member_status_reason?.name} i18n={statusChange.member_status_reason?.i18n} /></div>}
                                                    {statusChange.organisation_deciding && <div className="mb-1"><div className='font-bold'><Translate id="members.profile.statusChange.organization" />: </div><DisplayI18n field='name' defaultValue={statusChange.organisation_deciding?.organisation_name} i18n={statusChange.organisation_deciding?.i18n} /></div>}
                                                    {statusChange.note && <><div className='font-bold'><Translate id="form.fields.note" />: </div><TruncateWithTooltip text={statusChange.note} maxWidth={250} /></> }
                                                </>
                                                :
                                                ' - '

                                        )
                                    case 'status':
                                        return (
                                            <div className='d-flex'>
                                                <div>
                                                    <StatusBadge
                                                        color={previousStatus?.color_code}
                                                        status={previousStatus}
                                                    />
                                                </div>
                                                <i className='mdi mdi-arrow-right mx-2' />
                                                <div>
                                                    <StatusBadge
                                                        color={statusChange.member_status.color_code}
                                                        status={statusChange.member_status}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    default:
                                        break;
                                }
                            }}
                            emptyLayout={<EmptyLayout />}
                            onColumnClick={(e, row) => {
                                switch (e.button){
                                    case 0: // Left mouse button
                                        panelProps.onSingleSelectChange(row)
                                        break;
                                }
                            }}
                        >
                            <div className='mb-2'>
                                <div className='d-flex flex-wrap justify-content-between'>
                                    <SearchInput />
                                    <div className='d-flex ml-auto text-right'>
                                        <Refresh />
                                        <RefreshTableTrigger />
                                    </div>
                                </div>
                            </div>
                            <SpordleTableView />
                        </SpordleTableProvider>
                    )}
                />

            </CardBody>
        </Card>
    );
}

const RefreshTableTrigger = () => {
    const { currentMember } = useContext(MembersContext);
    const spordleTable = useSpordleTable();
    useEffect(() => {
        spordleTable.refreshTable()
    }, [ currentMember?.member_status?.member_status_id ])
    return null;
}

export default MemberStatusLog;