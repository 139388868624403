export default{
    "teams.profile.teamInfos.tab.title" : "Informations de l'équipe",

    "teams.profile.teamInfos.general.title"                : "Informations générales",
    "teams.profile.teamInfos.general.teamName"             : "Nom d'équipe",
    "teams.profile.teamInfos.general.teamName.required"    : "Le nom d'équipe est requis",
    "teams.profile.teamInfos.general.shortName"            : "Nom abrégé",
    "teams.profile.teamInfos.general.shortName.required"   : "Le nom abrégé est requis",
    "teams.profile.teamInfos.general.category"             : "Catégorie",
    "teams.profile.teamInfos.general.colorHome"            : "Couleur à domicile",
    "teams.profile.teamInfos.general.colorHome.required"   : "La couleur à domicile est requise",
    "teams.profile.teamInfos.general.colorAway"            : "Couleur à l'étranger",
    "teams.profile.teamInfos.general.colorAway.required"   : "La couleur à l'étrange est requise",
    "teams.profile.teamInfos.general.league"               : "Ligue",
    "teams.profile.teamInfos.general.sponsor"              : "Sponsor",
    "teams.profile.teamInfos.general.tournamentNumber"     : "Tournoi #",
    "teams.profile.teamInfos.general.address.required"     : "L'adresse est requise si une deuxième adresse est spéficiée",
    "teams.profile.teamInfos.general.addressType"          : "Type d'adresse",
    "teams.profile.teamInfos.general.addressType.required" : "Le type d'adresse est requis si une adresse est spéficiée",
    "teams.profile.teamInfos.general.teamFee"              : "Frais d'équipe",

    "teams.profile.teamInfos.general.lock_roster_date"           : "Fermeture des cahiers pour les joueurs actifs",
    "teams.profile.teamInfos.general.lock_affiliate_roster_date" : "Fermeture des cahiers pour les joueurs affiliés",

    "teams.profile.teamInfos.contact.title"                  : "Contacts d'équipe",
    "teams.profile.teamInfos.contact.reset"                  : "Réinitialiser",
    "teams.profile.teamInfos.contact.reset.title"            : "Réinitialisation",
    "teams.profile.teamInfos.contact.confirmDelete"          : "Confirmez que vous souhaitez supprimer ce contact d'équipe.",
    "teams.profile.teamInfos.contact.reset.confirm"          : "Vous êtes sur le point de réinitialiser les informations de contact de cette équipe",
    "teams.profile.teamInfos.contact.role"                   : "Rôle",
    "teams.profile.teamInfos.contact.role.required"          : "Le rôle est requis",
    "teams.profile.teamInfos.contact.skip"                   : "Sauter cette étape",
    "teams.profile.teamInfos.contact.display.on.team.roster" : "Afficher sur le PDF de cahier d'équipe",
    "teams.profile.teamInfos.contact.display.sidepanel"      : "Affichage",

    "teams.profile.teamInfos.logs.title" : "Registre des approbations d'équipes",
    "teams.profile.teamInfos.logs.show"  : "Afficher",
    "teams.profile.teamInfos.logs.hide"  : "Cacher",

    "team.profile.teamInfos.logs.identity.name"        : "Prénom",
    "team.profile.teamInfos.logs.identity.family_name" : "Nom de famille",
    "team.profile.teamInfos.logs.identity.email"       : "Courriel",
    "team.profile.teamInfos.logs.created_at"           : "Date de modification",
    "team.profile.teamInfos.logs.organisation_name"    : "Nom de l'organisation",
    "team.profile.teamInfos.logs.team_status"          : "Statut d'équipe",
}