import { FormikSelect } from '@spordle/formik-elements';
import { useContext } from 'react';
import useSWR from 'swr';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { TransferContext } from '../../../../../contexts/TransferContext';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import FormikEditable from '../../../../formik/FormikEditable';
import { fail } from '@spordle/toasts';

const TransferDuration = ({ canEditTransfer, transfer, setLoading, updateData }) => {
    const memberContext = useContext(MembersContext);
    const transferContext = useContext(TransferContext);
    const { getGenericLocale } = useContext(I18nContext);

    const { data: durations, isValidating } = useSWR(
        [ 'getActiveTransferDuration' ],
        () => transferContext.getTransferDurations({ active: 1 }, false, true)
            .then((durations) => durations.sort((durationA, durationB) => parseInt(durationA.display_order) - parseInt(durationB.display_order)))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            }),
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        },
    );

    return (
        <FormikEditable
            id='transfer-duration'
            noConfirmation
            disabled={!canEditTransfer && !isValidating && Array.isArray(durations)}
            readOnly={transfer.transfer_request_state === 'CLOSED'}
            initialValues={{
                durationId: transfer.transfer_duration?.transfer_duration_id || '',
            }}
            onSubmit={(values) => {
                if(values.durationId != (transfer.transfer_duration?.transfer_duration_id || '')){
                    setLoading(true);
                    return memberContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                        transfer_duration_id: values.durationId,
                    })
                        .then(async() => {
                            await updateData({ // Updating data without having to make the api call
                                ...transfer,
                                transfer_duration: durations.find((duration) => duration.transfer_duration_id === values.durationId),
                            }, false);
                            setLoading(false);
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                                setLoading(false);
                            }
                        })
                }
            }}
        >
            {(isEditing, options) => {
                if(!isEditing){
                    return (transfer.transfer_duration ?
                        <DisplayI18n
                            field='name'
                            defaultValue={transfer.transfer_duration.name}
                            i18n={transfer.transfer_duration.i18n}
                        />
                        : '-'
                    );
                }
                return (
                    <div style={{ width: 200 }}>
                        <FormikSelect
                            name='durationId'
                            id='durationId'
                            renderOption={({ option: transferStatus }) => <DisplayI18n field='name' defaultValue={transferStatus.label} i18n={transferStatus.i18n} />}
                            onOptionSelected={() => {
                                options.stopEditing();
                            }}
                            menuIsDefaultOpen autoFocus search={false}
                            searchKeys={[
                                `i18n.${getGenericLocale()}.name`,
                            ]}
                            options={durations ?
                                durations.map((duration) => ({
                                    label: duration.name,
                                    i18n: duration.i18n,
                                    value: duration.transfer_duration_id,
                                }))
                                :
                                []
                            }
                        />
                    </div>
                )

            }}
        </FormikEditable>
    )
}

export default TransferDuration
