import createClinic from "./createClinic";
import dashboard from "./dashboard";
import profile from "./profile";
import search from "./search";
import settings from "./settings";
import component from "./components";

export default{
    ...createClinic,
    ...dashboard,
    ...profile,
    ...search,
    ...settings,
    ...component,
}