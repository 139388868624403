import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { IdentityRolesContext } from '../../contexts/IdentityRolesContext';
import OrganizationCreationModal from '../organization/OrganizationCreationModal';
import OrganizationParentChangeModal from '../organization/OrganizationParentChangeModal';
import ViewStateSideBar from '../ViewStateSidePanel';
import IsGod from '../permissions/IsGod';
import SidePanel from '../sidePanel/SidePanel';
import PeriodWarning from './PeriodWarning';
import ManageRolePermissions from './ManageRolePermissions';
import { createPortal } from 'react-dom';
import { AppContext } from '../../contexts/contexts';

const ViewHeader = ({ tag: Tag, className, children, ...attributes }) => {
    const identityRolesContext = useContext(IdentityRolesContext);
    const { resetFrontEndParams } = useContext(AppContext);

    const [ isOpen, setIsOpen ] = useState({
        parent: false,
        create: false,
        viewState: false,
        permissions: false,
    });

    const toggleIsOpen = (view, bool) => setIsOpen((prev) => ({ ...prev, [view]: bool }));

    return (
        <>
            <Tag {...attributes} className={stringBuilder('viewHeader', className, { 'pr-5': identityRolesContext.isGod() })}>
                {children}
                <IsGod className='mx-3 position-absolute right-0 clickable' tooltipOff={Object.values(isOpen).some((v) => v)}>
                    <UncontrolledDropdown>
                        <DropdownToggle tag='div' className="text-purple"><i className="mdi mdi-settings font-22" /></DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem className="text-purple" onClick={() => toggleIsOpen('permissions', true)}>
                                Role permissions
                            </DropdownItem>
                            <DropdownItem className="text-purple" onClick={() => toggleIsOpen('create', true)}>
                                <Translate id='components.createOrganizationModal.title' />
                            </DropdownItem>
                            <DropdownItem className="text-purple" onClick={() => toggleIsOpen('parent', true)}>
                                Organization Parent Change
                            </DropdownItem>
                            <DropdownItem className="text-purple" onClick={() => toggleIsOpen('viewState', true)}>
                                View State
                            </DropdownItem>
                            <DropdownItem className="text-purple" onClick={resetFrontEndParams}>
                                Reset front end params
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <OrganizationParentChangeModal isOpen={isOpen?.parent || false} closeModal={() => toggleIsOpen('parent', false)} />
                    <OrganizationCreationModal isOpen={isOpen.create} toggle={() => toggleIsOpen('create', false)} />

                    {createPortal(
                        <SidePanel isOpen={isOpen.viewState}>
                            <ViewStateSideBar toggle={() => toggleIsOpen('viewState', false)} />
                        </SidePanel>
                        , document.getElementById('page-wrapper') || document.body,
                    )}
                    {createPortal(
                        <SidePanel isOpen={isOpen.permissions}>
                            <ManageRolePermissions closeSidePanel={() => toggleIsOpen('permissions', false)} />
                        </SidePanel>
                        , document.getElementById('page-wrapper') || document.body,
                    )}
                </IsGod>
            </Tag>
            <div className="page-content min-h-0 py-0">
                <PeriodWarning className="p-0 mb-2" />
            </div>
        </>
    );
};

export default ViewHeader;

ViewHeader.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
};
ViewHeader.defaultProps = {
    tag: 'header',
};