import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";
import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Table
} from "reactstrap";
import EditPaymentReceptionModal from "../../../../../../components/transactions/EditPaymentReceptionModal";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../components/UserImg";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import ClinicCategory from "../../../../../clinics/ClinicCategory";
import TransactionCredits from "./components/TransactionCredits";
import TransactionHeader from "./components/TransactionHeader";
import TransactionPaymentReceptions from "./components/TransactionPaymentReceptions";
import TransactionTotalDue from "./components/TransactionTotalDue";
import TransationRefunds from "./components/TransationRefunds";
import { WatermarkWrapper } from '../../../../../../components/Watermark';
import withContexts from "../../../../../../helpers/withContexts";
import { ClinicsContext } from "../../../../../../contexts/ClinicsContext";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts"
import InlineCopy from "../../../../../../components/inlineCopy/InlineCopy";
class MemberProfileTransactionsSidepanelInvoiceClinic extends React.Component{

    state = {
        editPaymentReceptionModalOpen: false,
        currentPaymentReceptionId: '',
        sessions: [],
    }

    componentDidMount(){
        this.props.ClinicsContext.getClinicSessions(this.props.selectedRows[0].clinic.clinic.clinic_id)
            .then((sessions) => {
                this.setState(() => ({
                    sessions: sessions,
                }))
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedRows[0].clinic.clinic.clinic_id !== this.props.selectedRows[0].clinic.clinic.clinic_id){
            this.props.ClinicsContext.getClinicSessions(this.props.selectedRows[0].clinic.clinic.clinic_id)
                .then((sessions) => {
                    this.setState(() => ({
                        sessions: sessions,
                    }))
                }).catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }
    }

    toggleEditPaymentModal = (paymentReceptionId) => {
        this.setState((prevState) => ({
            currentPaymentReceptionId: paymentReceptionId,
            editPaymentReceptionModalOpen: !prevState.editPaymentReceptionModalOpen,
        }))
    }

    onDeletePaymentReception = () => {
        this.props.formatAndSyncRows().then(this.props.toggle);
    }

    onDeleteRefund = () => {
        this.props.formatAndSyncRows().then(this.props.toggle);
    }

    onPaymentReception = (paymentReceptionId, values) => {
        this.props.formatAndSyncRows();
    }

    render(){
        const clinnicFeesPayments = this.props.selectedRows[0].clinicFees.flatMap((item) => item.payments);
        const isVoid = this.props.selectedRows[0].status === 'VOID'
        return (
            <>
                {this.state.editPaymentReceptionModalOpen &&
                    <EditPaymentReceptionModal
                        isOpen={this.state.editPaymentReceptionModalOpen}
                        toggle={this.toggleEditPaymentModal}
                        payment={// finds the correct payment reception object from the paymentReceptionId set in the toggle function
                            this.props.selectedRows[0].payment_receptions.find(
                                (paymentReception) => paymentReception.payment_reception_id === this.state.currentPaymentReceptionId,
                            )
                        }
                        invoiceNumber={this.props.selectedRows[0].invoice_number}
                        paymentReceptionId={this.state.currentPaymentReceptionId}// this.props.selectedRows[0].payment_receptions?.[0]?.payment_reception_id
                        onEdit={(values) => {
                            const targetedReception = this.props.selectedRows[0].payment_receptions.findIndex(
                                (paymentReception) => paymentReception.payment_reception_id === this.state.currentPaymentReceptionId,
                            )

                            const newReceptions = this.props.selectedRows[0].payment_receptions;
                            newReceptions[targetedReception].note = values.note;
                            newReceptions[targetedReception].received_at = values.received_at;

                            const newValues = this.props.createNewValues({ payment_receptions: newReceptions });
                            this.props.syncRows(newValues);
                        }}
                    />
                }

                <WatermarkWrapper
                    showWatermark={isVoid}
                    watermarkProps={{
                        text: 'members.profile.transactions.memberProfileTransactions.sidePanel.status.VOID',
                    }}
                >
                    <div className="p-3 border-bottom">
                        <TransactionHeader
                            {...this.props}
                            refreshTable={this.props.tableRef.refreshTable}
                            formatAndSyncRows={this.props.formatAndSyncRows}
                            hideHeader={!!this.props.hideHeader}
                            onPaymentReception={this.onPaymentReception}
                            transaction={this.props.selectedRows[0]}
                            disableActions={isVoid}
                        />
                    </div>

                    <div className="p-3">
                        {/* TOTAL / DUE */}
                        <TransactionTotalDue totalAmount={this.props.selectedRows[0].total} dueAmount={this.props.selectedRows[0].due} />

                        {/* Refunds */}
                        <TransationRefunds
                            totalRefundAmount={this.props.selectedRows[0].total_refunded_amount}
                            refunds={this.props.selectedRows[0].refunds}
                            invoiceNumber={this.props.selectedRows[0].invoice_number}
                            onDeleteRefund={this.onDeleteRefund}
                            disableActions={isVoid}
                        />

                        {/* Payments */}
                        <TransactionPaymentReceptions
                            paymentReceptions={this.props.selectedRows[0].payment_receptions}
                            invoiceNumber={this.props.selectedRows[0].invoice_number}
                            onDeletePaymentReception={this.onDeletePaymentReception}
                            toggleEdit={this.toggleEditPaymentModal}
                            disableActions={isVoid}
                        />

                        {/* Credits */}
                        <TransactionCredits credits={this.props.selectedRows[0].credits} />

                        {/* CLINIC */}
                        {this.props.selectedRows[0].clinic &&
                            <div className='mb-3'>
                                <div className="h4 font-bold"><Translate id='members.profile.transactions.memberProfileTransactions.tableView.header.clinic' /></div>
                                <Card className='card-shadow mb-3'>
                                    <CardBody>
                                        <UserDisplay className="mb-3">
                                            <UserDisplay.Container>
                                                <UserImg
                                                    abbr={this.props.selectedRows[0].clinic.member.first_name[0] + this.props.selectedRows[0].clinic.member.last_name[0]}
                                                    src={undefined}
                                                    width={40}
                                                    alt={this.props.selectedRows[0].clinic.member.first_name + ' ' + this.props.selectedRows[0].clinic.member.last_name}
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container>
                                                <UserDisplay.Title>{this.props.selectedRows[0].clinic.member.first_name + ' ' + this.props.selectedRows[0].clinic.member.last_name}</UserDisplay.Title>
                                                <UserDisplay.Subtitle>#{this.props.selectedRows[0].clinic.member.unique_identifier}</UserDisplay.Subtitle>
                                            </UserDisplay.Container>
                                        </UserDisplay>

                                        <div className='mb-3'>
                                            <span className="font-medium">
                                                <DisplayI18n
                                                    field='name'
                                                    defaultValue={this.props.selectedRows[0].clinic.clinic.name}
                                                    i18n={this.props.selectedRows[0].clinic.clinic.i18n}
                                                />
                                            </span>
                                            <div className="small">
                                                <ClinicCategory.Builder qualification={this.props.selectedRows[0].clinic.clinic.qualification} />
                                                {this.state.sessions[0]?.session_format == "ONSITE" &&
                                                    <div>
                                                        { this.state.sessions[0]?.location_name
                                                            ?
                                                            this.state.sessions[0]?.location_name
                                                            :
                                                            this.state.sessions[0]?.street_number
                                                                ?
                                                                `${this.state.sessions[0]?.city}, ${this.state.sessions[0]?.province_id}`
                                                                :
                                                                null}
                                                    </div>
                                                }
                                                <InlineCopy toCopy={this.props.selectedRows[0].clinic.clinic.clinic_reference}>
                                                    <Link to={`/clinics/profile/${this.props.selectedRows[0].clinic.clinic.clinic_id}`}>
                                                        #{this.props.selectedRows[0].clinic.clinic.clinic_reference} <i className="mdi mdi-chevron-right" />
                                                    </Link>
                                                </InlineCopy>
                                            </div>
                                        </div>

                                        <Table size="sm" responsive className="mb-0">
                                            <thead>
                                                <tr>
                                                    <td className='td-shrink'><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.items.date' /></td>
                                                    <td><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.items.amount' /></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {clinnicFeesPayments.map((payment) => (
                                                    <tr key={payment.invoice_payment_id}>
                                                        <td className='td-shrink'><DateFormat value={payment.due_date} utc /></td>
                                                        <td className="text-right"><CurrencyFormat value={parseInt(payment.amount) / 100} /></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td className="w-100 text-right">
                                                        <div className="font-bold"><Translate id='members.profile.transactions.memberProfileTransactions.total' /> : </div>
                                                        {/* due was confusing, the actual due amount for the whole transaction is displayed at the top */}
                                                        {/* <div className="font-medium"><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.items.due'/> : </div> */}
                                                    </td>
                                                    <td className="text-right td-shrink">
                                                        <div className="text-nowrap"><CurrencyFormat value={this.props.selectedRows[0].total / 100} /></div>
                                                        {/* <div className="text-nowrap"><CurrencyFormat value={(clinnicFeesPayments.reduce((amountDue, payment) => amountDue += (payment.status !== 'COMPLETED' && payment.status !== 'REFUNDED') ? parseInt(payment.amount) : 0, 0)) / 100}/></div> */}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </CardBody>
                                </Card>

                                {/* CLINIC FEES */}
                                <div className='mb-3'>
                                    <div className="h4 font-bold"><Translate id='members.profile.transactions.memberProfileTransactions.tableView.header.clinic.fee' /></div>
                                    {this.props.selectedRows[0].clinicFees?.map((item) => {
                                        return (
                                            <Card className='card-shadow mb-3' key={item.invoice_item_id}>
                                                <CardBody>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div className='h6 mb-0 text-truncate'>
                                                            <span className='font-bold'>
                                                                <DisplayI18n
                                                                    field='name'
                                                                    i18n={item.clinic_fee.fee.i18n}
                                                                    defaultValue={item.clinic_fee.fee.name}
                                                                />
                                                            </span>
                                                        </div>
                                                        <div className='ml-3'><CurrencyFormat value={parseInt(item.new_amount) / 100} /></div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        )
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                </WatermarkWrapper>
            </>
        )
    }
}

export default withRouter(withContexts(ClinicsContext)(MemberProfileTransactionsSidepanelInvoiceClinic));