import UserDisplay from "../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../components/UserImg";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import { DisplayTeamCategory } from "../../TeamHelpers";
import CompetitionTeamStatus from "../CompetitionTeamStatus";

const MultiAffiliatedTeamsSidePanel = ({ teams }) => {
    return (
        <>
            {teams.map((team) => (
                <UserDisplay className="p-3 border-bottom" key={team.competition_team_id}>
                    <UserDisplay.Container>
                        <UserImg
                            src={team.team?.logo?.full_path}
                            filePos={team.team?.logo?.file_position}
                            width={40}
                            abbr={team.team?.name}
                            alt={team.team?.name}
                        />
                    </UserDisplay.Container>
                    <UserDisplay.Container>
                        <UserDisplay.Title>
                            {team.team?.name}
                        </UserDisplay.Title>
                        <UserDisplay.Subtitle>
                            <span className="mr-1">
                                <DisplayI18n
                                    field="name"
                                    i18n={team.competition?.i18n}
                                    defaultValue={team.competition?.name}
                                />
                            </span>
                            (<DisplayTeamCategory ressource={team.competition} />)
                        </UserDisplay.Subtitle>
                    </UserDisplay.Container>
                    <UserDisplay.Container className="ml-auto">
                        <CompetitionTeamStatus status={team.competition_team_status} />
                    </UserDisplay.Container>
                </UserDisplay>
            ))}
        </>
    );
}

export default MultiAffiliatedTeamsSidePanel;