export default{
    "members.search.sidePanelMemberSearch.tabs.profile" : "Profil",

    "members.search.sidePanelMemberSearch.title.selectedMembers" : "membres sélectionnés",

    "members.search.sidePanelMemberSearch.section.profileInfo" : "Informations du profil",
    "members.search.sidePanelMemberSearch.section.contact"     : "Contact",

    "members.search.sidePanelMemberSearch.profileInfo.gender"                         : "Identité de genre",
    "members.search.sidePanelMemberSearch.profileInfo.gender.MALE"                    : "Masculin",
    "members.search.sidePanelMemberSearch.profileInfo.gender.FEMALE"                  : "Féminin",
    "members.search.sidePanelMemberSearch.profileInfo.gender.PREFER_NOT_TO_SAY"       : "Préfère ne pas répondre",
    "members.search.sidePanelMemberSearch.profileInfo.gender.PREFER_TO_SELF_DESCRIBE" : "Préfère se décrire eux-même",
    "members.search.sidePanelMemberSearch.profileInfo.gender.NON_BINARY"              : "Non binaire",

    "members.search.sidePanelMemberSearch.profileInfo.language"    : "Langage(s)",
    "members.search.sidePanelMemberSearch.profileInfo.language.FR" : "Français",
    "members.search.sidePanelMemberSearch.profileInfo.language.EN" : "Anglais",

    "members.search.sidePanelMemberSearch.profileInfo.birthDate" : "Date de naissance",

    "members.search.sidePanelMemberSearch.profileInfo.branch"      : "Branche",
    "members.search.sidePanelMemberSearch.profileInfo.memberTypes" : "Type(s) de membre",

    "members.search.sidePanelMemberSearch.contact.primary"    : "Primaire",
    "members.search.sidePanelMemberSearch.contact.emergency"  : "Contact d'urgence",
    "members.search.sidePanelMemberSearch.contact.noContacts" : "Ce membre n'a pas de contacts.",

    "members.search.sidePanelMemberSearch.contact.unconfirmedAddress"   : "Nouvelle addresse nécessitant une confirmation.",
    "members.search.sidePanelMemberSearch.contact.clickToVerifyAddress" : "Cliquer ici pour vérifier l'addresse",

    "members.search.sidePanelMemberSearch.delete.confirmMessage" : "Vous êtes sur le point de supprimer ce membre de {orgName}",
    "members.search.sidePanelMemberSearch.delete.success"        : "{memberName} supprimé",
    "members.search.sidePanelMemberSearch.delete.fail"           : "Impossible de supprimer ce membre",

    "members.search.sidePanelMemberSearch.actions.resquestTransfer"                    : "Demande de {transfer}",
    "members.search.sidePanelMemberSearch.actions.resquestTransfer.manual"             : "{transfer_title} manuel",
    "members.search.sidePanelMemberSearch.actions.resquestTransfer.hasActive"          : "Demande de {transfer} actif",
    "members.search.sidePanelMemberSearch.actions.resquestTransfer.incomplete"         : "Le profil du membre est incomplet.",
    "members.search.sidePanelMemberSearch.actions.noconfirm.shared"                    : "Impossible de confirmer un membre partagé.",
    "members.search.sidePanelMemberSearch.actions.resquestTransfer.currentOrg"         : "Ne peut pas transférer à l'organisation actuelle",
    "members.search.sidePanelMemberSearch.actions.resquestTransfer.outstandingBalance" : "Ce membre a un solde impayé",
    "members.search.sidePanelMemberSearch.actions.resquestTransfer.usa"                : "{transfer_title} USA",
    "members.search.sidePanelMemberSearch.actions.resquestTransfer.international"      : "{transfer_title} international",

    "members.search.sidePanelMemberSearch.disabled.actions.noStatus"        : "Aucune action disponible. Statut du membre: NULL",
    "members.search.sidePanelMemberSearch.disabled.actions.DRAFT"           : "Les actions sur ce membre sont limitées. Statut du membre: Temporaire ",
    "members.search.sidePanelMemberSearch.disabled.actions.INELIGIBLE"      : "Les actions sur ce membre sont limitées. Statut du membre: Inéligible ",
    "members.search.sidePanelMemberSearch.disabled.registration.BLOCKED"    : "Impossible d'enregistrer. Statut du membre: Bloqué",
    "members.search.sidePanelMemberSearch.disabled.registration.INELIGIBLE" : "Impossible d'enregistrer. Statut du membre: Inéligible",

    "members.search.sidePanelMemberSearch.multi.title.bulkUpdate"     : "Mise à jour groupée",
    "members.search.sidePanelMemberSearch.multi.title.confirmMembers" : "Confirmer les membres",
    "members.search.sidePanelMemberSearch.multi.title.blockMembers"   : "Bloquer les membres",

    "members.search.sidePanelMemberSearch.multi.validMembersWithUnconfirmed.title" : "{count, plural, =1 {# adresse non confirmée} other {# adresses non confirmées}}",
    "members.search.sidePanelMemberSearch.multi.validMembersWithUnconfirmed.text"  : "Il y a {count, plural, =1 {# membre valide} other {# membres valides}} avec au moins une adresse non confirmée. En poursuivant, vous confirmez les adresses aussi.",

    "members.search.sidePanelMemberSearch.bulkUpdate.allMembers.topMessage.block" : "Vous allez bloquer tous les membres éligibles ci-dessous.",
    "members.search.sidePanelMemberSearch.bulkUpdate.block.note.helper"           : "Cette note est pour tous les membres.",
    "members.search.sidePanelMemberSearch.bulkUpdate.allMembers.topMessage"       : "Vous allez confirmer tous les membres éligibles ci-dessous.",
    "members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validTitle"       : "Membres ({members}) ",
    "members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validText"        : "Les membres suivants seront mis à jour",
    "members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validNone"        : "Aucun membre",
    "members.search.sidePanelMemberSearch.bulkUpdate.allMembers.invalidTitle"     : "Membres inéligibles ({members})",
    "members.search.sidePanelMemberSearch.bulkUpdate.allMembers.invalidText"      : "Les membres suivants ne seront pas mis à jour",
    "members.search.sidePanelMemberSearch.bulkUpdate.allMembers.actionNone"       : "Aucun membre n'est admissible à cette action. ",
}