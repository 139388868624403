import { Row } from 'reactstrap';
import MemberOverviewCardItem from './MemberOverviewCardItem';

const MemberOverviewCards = ({ children }) => {
    return (
        <Row className="align-items-center mb-2" form>
            {children}
        </Row>
    );
}

MemberOverviewCards.Item = MemberOverviewCardItem;

export default MemberOverviewCards;