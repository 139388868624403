import { useSpordleTable } from "@spordle/datatables";
import PaginationApi from "../../../../components/table/PaginationApi";
import MessagePreview from "../messagePreview/MessagePreview";
import SpordleListLoading from "./SpordleListLoading";
import SpordleListEmpty from "./SpordleListEmpty";
import InboxTools from "../inboxTools/InboxTools";
import { useContext, useEffect } from "react";
import { InboxContext } from "../inboxContext/InboxContext";
import { AppContext } from "../../../../contexts/contexts";

const SpordleListView = ({
    handleOnSelectMsg,
    selectAll,
    unselectAll,
    handleOnCheck,
    updateCheckedMsgs,
    handleOnUncheck,
    getIsChecked,
    checked,
    hideTools,
    toolsProps,
}) => {
    const spordleTable = useSpordleTable();
    const allData = spordleTable.getData();
    const { isMobileDevice } = useContext(AppContext);
    const { showTools } = useContext(InboxContext);
    const isLoading = spordleTable.state.loadingState !== "success";
    const displayedData = spordleTable.getDisplayedData();

    useEffect(() => {
        if(!showTools && isMobileDevice){
            unselectAll();
        }
    }, [ showTools ]);

    return (
        <div
            className="d-flex flex-column h-100"
        >
            <InboxTools
                updateCheckedMsgs={updateCheckedMsgs}
                selectAll={selectAll}
                unselectAll={unselectAll}
                checked={checked}
                hideTools={hideTools}
                {...toolsProps}
            />
            <div className="h-100">
                {
                    isLoading ?
                        <SpordleListLoading />
                        :
                        displayedData.length <= 0 ?
                            <SpordleListEmpty emptySearch={allData.length > 0} />
                            :
                            displayedData.map((msg) => (
                                <MessagePreview
                                    key={`message-preview-${msg.message_id}`}
                                    onClick={handleOnSelectMsg(msg)}
                                    checkMsg={handleOnCheck(msg)}
                                    uncheckMsg={handleOnUncheck(msg)}
                                    isChecked={getIsChecked(msg)}
                                    msg={msg}
                                    hideTools={hideTools}
                                />
                            ))

                }
            </div>
            {spordleTable.getFilters().totalResults > spordleTable.getPagination() &&
                <div className="p-3 mt-auto d-flex justify-content-end">
                    <PaginationApi listClassName="mb-0" />
                </div>
            }
        </div>
    );
}

export default SpordleListView;