import { useContext } from "react";
import { Badge } from "reactstrap";
import CustomAnimatedIcon from "../../../../components/customAnimatedIcon/CustomAnimatedIcon";
import UserDisplay from "../../../../components/userDisplay/UserDisplay";
import { useHistory, Link } from "react-router-dom";
import { ChecklistsContext } from "../../../../contexts/ChecklistsContext";
import Translate from "@spordle/intl-elements";
import { stringBuilder } from "@spordle/helpers";
import styles from "./ChecklistStep.module.scss";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import { FormattedDate } from "react-intl";
import { useStepDataSWR } from "../../../../components/checklist/data/useStepDataSWR";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import { RolesContext } from "../../../../contexts/RolesContext";

const ChecklistStep = ({ index, step, checklistId, circleSize = 32 }) => {
    const checklistContext = useContext(ChecklistsContext);
    const { isGod } = useContext(IdentityRolesContext);
    const { canDoAction } = useContext(RolesContext);
    const history = useHistory();

    const info = step.checklist_item;
    const permissions = step.component_permission;
    const canManage = !permissions || canDoAction(step.action, permissions?.component?.code, permissions?.code) || isGod();
    const data = useStepDataSWR(step, checklistId, canManage);

    const ressourceLabel = data?.label;
    const completedData = checklistContext.getCompletedData(checklistId, step.checklist_step_id)?.[0];
    const isComplete = !!completedData;
    const canComplete = canManage && !isComplete && (!data || data?.canComplete);

    const startChecklist = () => {
        checklistContext.startChecklist(checklistId, index);
    }

    const handleOnClick = () => {
        startChecklist();

        if(step.path){
            history.push(step.path);
        }

    }

    const handleOnCheckClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        startChecklist();
    }

    return (
        <UserDisplay onClick={handleOnClick} card hover className="w-100 mb-3">
            <UserDisplay.Container onClick={handleOnCheckClick} className="mr-3">
                {isComplete ?
                    <CustomAnimatedIcon icon='checkmark' className='text-success' withCircle size={circleSize} strokeWidth='12' />
                    :
                    <div
                        className={stringBuilder(
                            canComplete ? `border-success text-success border-2x ${styles.completableCheck}` : 'bg-light-inverse',
                            'border rounded-circle',
                        )}
                        style={{
                            width: circleSize,
                            height: circleSize,
                        }}
                    />
                }
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Title>
                    {index + 1}. <DisplayI18n field="name" i18n={info.i18n} defaultValue={info.name} />
                </UserDisplay.Title>
                <UserDisplay.Subtitle>
                    {info.tool_link && canManage ?
                        <Link to={info.tool_link}>
                            <DisplayI18n field="description" i18n={info.i18n} defaultValue={info.description} />
                        </Link>
                        :
                        <DisplayI18n field="description" i18n={info.i18n} defaultValue={info.description} />
                    }
                </UserDisplay.Subtitle>
            </UserDisplay.Container>
            <UserDisplay.Container className="ml-auto text-right">
                {isComplete ?
                    <>
                        {ressourceLabel && (
                            <b className="text-dark">
                                {ressourceLabel}
                            </b>
                        )}
                        {completedData &&
                            <div className="text-muted small">
                                <div>
                                    <Translate id="checklists.approvedBy" />: <b>{completedData.identity?.name} {completedData.identity?.family_name}</b>
                                </div>
                                <div>
                                    <Translate id="checklists.date" />:{" "}
                                    <b>
                                        <FormattedDate
                                            value={completedData.created_at}
                                            hour="numeric"
                                            minute="numeric"
                                            year="numeric"
                                            day="numeric"
                                            month="numeric"
                                        />
                                    </b>
                                </div>
                            </div>
                        }
                    </>
                    :
                    <>
                        {canManage ?
                            <Badge color={canComplete ? "success" : "light"}>
                                {ressourceLabel || <Translate id={`checklists.${canComplete ? "canComplete" : canManage ? "notStarted" : "waitingForCompletion"}`} />}
                            </Badge>
                            :
                            <b className="text-dark"><Translate id="checklists.waitingForCompletion" /></b>

                        }
                        {!canManage && <div className="small text-muted"><Translate id="checklists.noAccess" /></div>}
                    </>
                }
            </UserDisplay.Container>
        </UserDisplay>
    );
}

export default ChecklistStep;