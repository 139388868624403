import Translate from "@spordle/intl-elements"
import { Alert } from "reactstrap"

const ReportEngineContactSupport = ({ currentReport }) => {
    return (
        <Alert color="danger" className="text-center mb-0 mt-2">
            <div className="font-medium h3 mb-1 text-inherit">
                <Translate id="misc.oops" />
            </div>
            <div className="h4 text-inherit"><Translate id="engine.report.no.filters.or.headers" /></div>
            <p className="mb-0"><Translate id="misc.error.apology" /></p>
            {!currentReport.headers &&
                <Translate id='engine.report.no.headers' />
            }
            {!currentReport.filters &&
                <Translate id='engine.report.no.filters' />
            }
        </Alert>
    )
}

export default ReportEngineContactSupport