import Translate from "@spordle/intl-elements"
import { DisplayI18n } from "../../helpers/i18nHelper"
import UserDisplay from "../userDisplay/UserDisplay"
import UserImg from "../UserImg"

const MemberRegistrationCard = ({ registration, onRemove, errors }) => {
    return (
        <UserDisplay className="d-flex w-100 mb-1" card>
            <UserDisplay.Container>
                <UserImg
                    width={40}
                    src={registration.memberRegistration.member.picture?.full_path}
                    filePos={registration.memberRegistration.member.picture?.file_position}
                    abbr={registration.memberRegistration.member.first_name.charAt(0) + registration.memberRegistration.member.last_name.charAt(0)}
                    alt={registration.memberRegistration.member.first_name + ' ' + registration.memberRegistration.member.last_name}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Title>
                    {registration.memberRegistration.member.first_name + ' ' + registration.memberRegistration.member.last_name}
                </UserDisplay.Title>
                <UserDisplay.Subtitle>
                    {registration.invoice_number}
                    <div className="font-medium">
                        <DisplayI18n
                            field='name'
                            defaultValue={registration.memberRegistration.registration_fee.fee.name}
                            i18n={registration.memberRegistration.registration_fee.fee.i18n}
                        />
                    </div>
                </UserDisplay.Subtitle>
                {Array.isArray(errors) &&
                    <UserDisplay.Subtitle>
                        {errors.map((error) => error)}
                    </UserDisplay.Subtitle>
                }
            </UserDisplay.Container>
            {onRemove &&
                <UserDisplay.Container className="ml-auto small text-danger">
                    <button onClick={onRemove} className="reset-btn text-danger" type='button'>
                        <Translate id='misc.remove' />
                    </button>
                </UserDisplay.Container>
            }
        </UserDisplay>
    )
}

export default MemberRegistrationCard