import Translate, { DateFormat } from "@spordle/intl-elements";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import {
    Alert,
    Badge,
    Col,
    Fade,
    Row
} from "reactstrap";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import HasAccessTo from "../../../../../components/permissions/HasAccessTo";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { fail, success } from '@spordle/toasts';
import { PeriodsContext } from '../../../../../contexts/contexts';
import { I18nContext } from "../../../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import { MembersContext } from "../../../../../contexts/MembersContext";
// contexts
import { OnlineStoreContext } from '../../../../../contexts/OnlineStoreContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { ClinicsContext } from '../../../../../contexts/ClinicsContext';
import { HoverableCopy } from "../../../../../helpers/clipboardHelper";
import withContexts from '../../../../../helpers/withContexts';
import SidePanelMemberSearch from "../../../../members/search/SidePanelMemberSearch";
import CancelWaitlistMemberModal from "./modals/CancelWaitlistMemberModal";
import ClinicProfileRegistrationAdd from "../../attendees/addModal/ClinicProfileRegistrationAdd";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import QuickViewButton from "../../../../../components/quickView/QuickViewButton";
import QuickViewActionButton from "../../../../../components/quickView/QuickViewActionButton";
// Actions Modals


class SidePanelWaitlist extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            isCancellationModalOpen: false,
            manualRegistrationModalOpen: false,
        }
    }

    toggleCancelModal = () => {
        this.setState((prevState) => ({ isCancellationModalOpen: !prevState.isCancellationModalOpen }))
    }

    toggleManualRegistrationModal = () => {
        this.setState((prevState) => ({ manualRegistrationModalOpen: !prevState.manualRegistrationModalOpen }))
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <CancelWaitlistMemberModal
                    isOpen={this.state.isCancellationModalOpen}
                    toggle={this.toggleCancelModal}
                    waiting_list_item_sequential_number={this.props.selectedRows[0].waiting_list_item_sequential_number}
                    onSuccess={() => {
                        this.props.tableRef.refreshTable();
                        this.props.forceCloseSidePanel();
                    }}
                />
                <Fade in>
                    <div className='sticky-top bg-white z-index-1'>
                        <SidePanel.Header noBorder>
                            <div className='d-flex mb-2 align-items-center'>
                                <SidePanel.ToggleButton />

                                <div className="ml-auto d-flex align-items-center">
                                    <HasAccessTo componentCode='members' componentPermissionCode='members_profile'>
                                        <Link to={"/members/profile/" + this.props.selectedRows[0].member.member_id} className="mr-2">
                                            <Translate id='misc.goToProfile' /> <i className="mdi mdi-chevron-right" />
                                        </Link>
                                    </HasAccessTo>
                                    <SidePanel.ActionsMenu>

                                        {this.props.selectedRows[0].identity ?
                                            <ConfirmModal
                                                color='success'
                                                icon='mdi-account-plus'
                                                modalTitle='clinics.profile.waitlist.actions.add_as_attendee'
                                                modalMsg='clinics.profile.waitlist.actions.add_as_attendee.msg'
                                                translateModalTitle
                                                translateModalMsg
                                                actionButtonText='misc.confirm'
                                                translateActionButtonText
                                                onConfirm={async() => {
                                                    return this.props.ClinicsContext.approveWaitlistMember(this.props.selectedRows[0].waiting_list_item_sequential_number)
                                                        .then(() => {
                                                            this.props.tableRef.refreshTable();
                                                            this.props.forceCloseSidePanel();
                                                            success();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                }}
                                                toggler={(onClick) => (
                                                    <SidePanel.MenuAction onClick={onClick} disabled={this.props.selectedRows[0].status !== 'PENDING'}>
                                                        <Translate id='clinics.profile.waitlist.actions.add_as_attendee' />
                                                    </SidePanel.MenuAction>
                                                )}
                                            />
                                            :
                                            <>
                                                <ClinicProfileRegistrationAdd
                                                    isOpen={this.state.manualRegistrationModalOpen}
                                                    toggle={this.toggleManualRegistrationModal}
                                                    closeSidepanel={this.props.toggle}
                                                    member={this.props.selectedRows[0].member}
                                                    waitingListNumber={this.props.selectedRows[0].waiting_list_item_sequential_number}
                                                    refreshTable={this.props.attendeeTable.current?.refreshTable}
                                                    waitlistTable={{ current: this.props.tableRef }}
                                                />
                                                <SidePanel.MenuAction onClick={this.toggleManualRegistrationModal} disabled={this.props.selectedRows[0].status !== 'PENDING'}>
                                                    <Translate id='clinics.profile.waitlist.actions.add_as_attendee' />
                                                </SidePanel.MenuAction>
                                            </>
                                        }

                                        <ConfirmModal
                                            color='danger'
                                            icon='mdi-autorenew'
                                            modalTitle='clinics.profile.waitlist.actions.reset'
                                            modalMsg='clinics.profile.waitlist.actions.reset.msg'
                                            translateModalTitle
                                            translateModalMsg
                                            actionButtonText='misc.confirm'
                                            translateActionButtonText
                                            onConfirm={async() => {
                                                return this.props.ClinicsContext.resetWaitlistMember(this.props.selectedRows[0].waiting_list_item_sequential_number)
                                                    .then(() => {
                                                        this.props.tableRef.refreshTable();
                                                        this.props.forceCloseSidePanel();
                                                        success();
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                            }}
                                            toggler={(onClick) => (
                                                <SidePanel.MenuAction onClick={onClick} disabled={this.props.selectedRows[0].status !== 'CANCELED'}>
                                                    <Translate id='clinics.profile.waitlist.actions.reset' />
                                                </SidePanel.MenuAction>
                                            )}
                                        />

                                        <SidePanel.MenuAction
                                            onClick={this.toggleCancelModal}
                                            disabled={this.props.selectedRows[0].status !== 'PENDING' && this.props.selectedRows[0].status !== 'WAITING_FOR_CLIENT'}
                                        >
                                            <Translate id='clinics.profile.waitlist.actions.cancel' />
                                        </SidePanel.MenuAction>
                                        <QuickViewActionButton member={this.props.selectedRows[0].member} />
                                    </SidePanel.ActionsMenu>
                                </div>
                            </div>
                            <SidePanel.Title>
                                <QuickViewButton member={this.props.selectedRows[0].member} className="d-flex">
                                    {this.props.selectedRows[0].member.first_name} {this.props.selectedRows[0].member.last_name}
                                </QuickViewButton>
                            </SidePanel.Title>
                            <SidePanel.Subtitle className="mr-4 d-flex align-items-center">
                                <HoverableCopy className="text-medium" toCopy={this.props.selectedRows[0].member.unique_identifier}>
                                    <Link className="text-nowrap" to={`/members/profile/${this.props.selectedRows[0].member.member_id}`}>
                                        {this.props.selectedRows[0].member.unique_identifier ?
                                            <>#{this.props.selectedRows[0].member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                            : '-'}
                                    </Link>
                                </HoverableCopy>
                            </SidePanel.Subtitle>

                            {this.props.selectedRows[0].status === 'CONVERTED' ?
                                <Alert className="mb-0 mt-3 px-2 position-relative" color="success">
                                    <div className="d-flex align-items-center">
                                        <div className="mr-2">
                                            <i className="font-22 mdi mdi-check" />
                                        </div>
                                        <div className="font-medium">
                                            <Translate id='clinics.profile.waitlist.status.alert.title' />
                                            <small className="d-block text-body">
                                                <div>
                                                    <Link
                                                        to='attendees'
                                                        onClick={() => {
                                                            this.props.attendeeTable.current?.setInputValue(this.props.selectedRows[0].member.unique_identifier)
                                                            this.props.toggle();
                                                        }}
                                                    >
                                                        <Translate id='clinics.profile.waitlist.status.alert.clickHere' />
                                                    </Link>
                                                </div>
                                                <Translate
                                                    id='clinics.profile.waitlist.status.alert.text'
                                                    values={{
                                                        date: <DateFormat value={this.props.selectedRows[0].invoice?.invoice_date} format='YYYY-MM-DD HH:mm' />,
                                                    }}
                                                />
                                            </small>
                                        </div>
                                    </div>
                                </Alert>
                                :
                                <Badge color={this.props.getBadgeColour(this.props.selectedRows[0].status)}><Translate id={`clinics.profile.waitlist.status.${this.props.selectedRows[0].status}`} /></Badge>
                            }


                        </SidePanel.Header>
                    </div>
                    <SidePanelMemberSearch
                        {...this.props}
                        MembersContext={this.props.MembersContext}
                        IdentityRolesContext={this.props.IdentityRolesContext}
                        OrganizationContext={this.props.OrganizationContext}
                        selectedRows={[ {
                            ...this.props.selectedRows[0],
                            ...this.props.selectedRows[0].member,
                        } ]}
                        hideHeader
                    />
                    {this.props.selectedRows[0].cancellation_reason &&
                        <div className="ml-3 pt-3 border-top">
                            <div className="h4 font-bold mb-3"><Translate id='clinics.profile.waitlist.status.CANCELLED' /></div>
                            <Row>
                                <Col sm="6" className="mb-3">
                                    <div className='text-muted'><Translate id='clinics.profile.waitlist.cancelModal.label' /></div>
                                    <div>{this.props.selectedRows[0].cancellation_reason}</div>
                                </Col>
                            </Row>
                        </div>
                    }
                </Fade>
            </OverlayLoader>
        )
    }
}

export default withRouter(withContexts(OnlineStoreContext, OrganizationContext, PeriodsContext, MembersContext, IdentityRolesContext, I18nContext, ClinicsContext)(SidePanelWaitlist));