import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Fade, Spinner } from 'reactstrap';
import ProfileHeader from '../../../../components/profileHeader/ProfileHeader';
import { AuthContext, PeriodsContext } from '../../../../contexts/contexts';
import { MembersContext } from '../../../../contexts/MembersContext';
import { RolesContext } from '../../../../contexts/RolesContext';
import MemberHeaderActions from './components/MemberHeaderActions';
import MemberHeaderStatusButtons from './components/MemberHeaderStatusButtons';
import MemberOverviewCards from './components/MemberOverviewCards';
import MemberOverviewInfo from './components/MemberOverviewInfo';
import { object, number } from 'yup';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import FormikEditable from '../../../../components/formik/FormikEditable';
import { FormikInputNumber } from '@spordle/formik-elements';
import { I18nContext } from '../../../../contexts/I18nContext';
import { fail, success } from '@spordle/toasts';
import MemberProfileTransactionAdd from '../transactions/components/AddModal/MemberProfileTransactionsAdd';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { OfficiatingCoachContext } from '../../../../contexts/OfficiatingCoachContext';
import { useRouteMatch } from 'react-router-dom';
import useSWR from 'swr';
import { Tooltip } from "@mantine/core";
import MemberProfileHeaderImage from './components/MemberProfileHeaderImage';
import { parseJSON } from '../../../../helpers/helper';
import { getPermanentRelease } from '../../../../api/client/permanentReleaseRequests';
import MemberOverviewTopInfo from './components/MemberOverviewTopInfo';
import MemberStatusReasonNote from './components/MemberStatusReasonNote';
import MemberHeaderMinimalBadges from './components/MemberHeaderMinimalBadges';

const MemberHeader = ({ headerLoading, minimalView, headerCardsState, setForceRegistrationRefresh, refreshMemberProfile, inQuickView }) => {
    const initCardsData = {
        transaction: 0,
        paid_amount: 0,
        due_amount: 0,
        schedule_amount: 0,
        credit_left: 0,
        outstanding_balance: 0,
    }

    const membersContext = useContext(MembersContext);
    const organizationContext = useContext(OrganizationContext);
    const periodsContext = useContext(PeriodsContext);
    const rolesContext = useContext(RolesContext);
    const { getMemberOfficiatingCoachList } = useContext(OfficiatingCoachContext);
    const { account } = useContext(AuthContext);
    const { canDoAction } = useContext(RolesContext);

    const routeMatch = useRouteMatch();
    const { memberId } = useParams();

    const [ creditModalIsOpen, setCreditModalIsOpen ] = useState(false);
    const [ cardsLoading, setCardsLoading ] = useState(false);

    const { data: cardValues, isValidating, mutate } = useSWR(
        [ 'getMemberStatements', headerCardsState, routeMatch.url ],
        () => membersContext.getMemberStatements(memberId, organizationContext.organisation_id, periodsContext.selectedPeriod.period_id)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            }),
        {
            fallbackData: initCardsData,
            revalidateOnMount: true,
        },
    );

    const { data: officiatingCoach, mutate: mutateOfficiating } = useSWR(
        [ 'getMemberOfficiatingCoach', routeMatch.url ],
        () => getMemberOfficiatingCoachList(memberId, { current_period_only: 1 }).then((list) => list[0]),
        {
            revalidateOnMount: true,
        },
    );

    const documentNeedsApproval = organizationContext.settings.system_document_type_need_approbation.value ? !!parseJSON(organizationContext.settings.system_document_type_need_approbation.value).MEMBER_PROFILE_PICTURE : false;
    const hasApprovalPermissions = canDoAction("EDIT", "members", "system_document_type_need_approbation")

    useEffect(() => {
        if(rolesContext.hasAccessTo('members', 'members_release_request')){
            getPermanentRelease({
                member_id: memberId,
                organisation_id: organizationContext.organisation_id,
                period_id: periodsContext.selectedPeriod.period_id,
            })
                .then((data) => {
                    membersContext.setPermanentReleaseRequests({
                        permanent_release_requests: data,
                        pending_permanent_release_requests: data.some((release) => release.request_change_status.system === 'PENDING'),
                    }) // filter cause member_id API filter doesn't work
                })
        }
    }, [ memberId ]);

    return (headerLoading ?
        <div className='d-flex justify-content-center align-items-center' style={{ height: '385px' }}><Spinner color='primary' type='grow' /></div>
        :
        <Fade>
            <MemberHeaderActions
                inQuickView={inQuickView}
                minimalView={minimalView}
                officiatingCoach={officiatingCoach}
                refreshOfficiatingInfo={mutateOfficiating}
                refreshMemberProfile={() => { refreshMemberProfile(); mutate("getMemberStatements"); }}
            />
            <ProfileHeader className="mb-2">
                <MemberProfileHeaderImage
                    placeholder={account.logo}
                    alt={membersContext.currentMember.first_name + ' ' + membersContext.currentMember.last_name}
                    file={membersContext.currentMember.picture}
                    abbr={membersContext.currentMember.first_name?.charAt(0) + '' + membersContext.currentMember.last_name?.charAt(0)}
                    canEdit={canDoAction([ 'EDIT' ], 'members', 'members_profile')}
                    documentNeedsApproval={documentNeedsApproval}
                    hasApprovalPermissions={hasApprovalPermissions}
                    onSave={(logo, filePos, dataUrl) => (
                        membersContext.createMemberPicture(membersContext.currentMember.member_id, { picture: logo, file_position: filePos }, dataUrl)
                            .then(() => {
                                return membersContext.getAllMemberInfos(membersContext.currentMember.member_id)
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                }
                            })
                    )}
                />
                <div className="w-100">
                    <MemberOverviewTopInfo />
                    <MemberOverviewInfo
                        officiatingCoach={officiatingCoach}
                        refreshMemberProfile={refreshMemberProfile}
                    />
                </div>
            </ProfileHeader>
            <MemberStatusReasonNote className="mb-3" />
            {!!minimalView &&
                <MemberHeaderMinimalBadges mutate={mutate} />
            }
            {!minimalView &&
                <>
                    <MemberHeaderStatusButtons refreshMemberProfile={refreshMemberProfile} />
                    <MemberOverviewCards>
                        <MemberOverviewCards.Item
                            cardsLoading={isValidating || cardsLoading}
                            label={<Translate id='members.profile.memberProfile.header.card.transactions' />}
                            value={<CurrencyFormat value={cardValues.transaction / 100} />}
                        />
                        <MemberOverviewCards.Item
                            cardsLoading={isValidating || cardsLoading}
                            label={<Translate id='members.profile.memberProfile.header.card.amountPaid' />}
                            value={<CurrencyFormat value={cardValues.paid_amount / 100} />}
                        />
                        <MemberOverviewCards.Item
                            cardsLoading={isValidating || cardsLoading}
                            label={<Translate id='members.profile.memberProfile.header.card.amountDue' />}
                            value={<CurrencyFormat value={cardValues.due_amount / 100} />}
                        />
                        <MemberOverviewCards.Item
                            cardsLoading={isValidating || cardsLoading}
                            label={<Translate id='members.profile.memberProfile.header.card.amountScheduled' />}
                            value={<CurrencyFormat value={cardValues.schedule_amount / 100} />}
                        />
                        {canDoAction('ADD', 'members', 'member_transactions') ?
                            <MemberOverviewCards.Item
                                cardsLoading={isValidating || cardsLoading}
                                customRender={() => (
                                    <div>
                                        <div className='editable d-flex align-self-center' onClick={() => { setCreditModalIsOpen(true) }}>
                                            <div className='text-truncate'>
                                                <div className="h4 font-bold"><CurrencyFormat value={cardValues.credit_left / 100} /></div>
                                                <Translate id='members.profile.memberProfile.header.card.creditAmount' />
                                            </div>
                                            <i className='mdi mdi-pencil text-primary align-self-center ml-2' />
                                        </div>
                                        <MemberProfileTransactionAdd
                                            isOpen={creditModalIsOpen}
                                            toggle={() => setCreditModalIsOpen(!creditModalIsOpen)}
                                            refreshTable={() => {
                                                setForceRegistrationRefresh();
                                                mutate();
                                                membersContext.getMemberCredits(organizationContext.organisation_id, {
                                                    member_id: membersContext.currentMember.member_id,
                                                })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                            }}
                                        />
                                    </div>
                                )}
                            />
                            :
                            <MemberOverviewCards.Item
                                cardsLoading={isValidating || cardsLoading}
                                label={<Translate id='members.profile.memberProfile.header.card.creditAmount' />}
                                value={<CurrencyFormat value={cardValues.credit_left / 100} />}
                            />
                        }

                        <MemberOverviewCards.Item
                            cardsLoading={isValidating || cardsLoading}
                            customRender={() => (
                                <Tooltip
                                    key={cardValues.outstanding_balance}
                                    withArrow
                                    label={
                                        <>
                                            {membersContext.currentMember.outstanding_balance_details?.map((detail) => (
                                                <div key={detail.member_outstanding_balance_id} className='d-flex justify-content-between'>
                                                    <DisplayI18n field='name' defaultValue={detail.organisation.organisation_name} i18n={detail.organisation.i18n} />
                                                    <div className='ml-3'><CurrencyFormat value={detail.outstanding_balance / 100} /></div>
                                                </div>
                                            ))}
                                        </>
                                    }
                                    disabled={!membersContext.currentMember.outstanding_balance_details || membersContext.currentMember.outstanding_balance_details.length == 0}
                                >
                                    <FormikEditable
                                        disabled={!canDoAction("EDIT", "members", "members_outstanding_balance", true)}
                                        id='outstandingBalanceHeaderCards'
                                        initialValues={{
                                            outstandingBalance: (membersContext.currentMember.outstanding_balance_details?.find((bal) => bal.organisation.organisation_id === organizationContext.organisation_id)?.outstanding_balance || 0) / 100,
                                        }}
                                        validationSchema={object().shape({
                                            outstandingBalance: number().required(<Translate id='members.profile.memberProfile.header.card.outstandingBalance.required' />),
                                        })}
                                        onSubmit={(values) => {
                                            setCardsLoading(true);
                                            const newOutstandingBalance = Math.round(values.outstandingBalance * 100).toString();
                                            const currentOutstandingBalance = membersContext.currentMember.outstanding_balance_details?.find((bal) => bal.organisation.organisation_id === organizationContext.organisation_id)
                                            Promise.all([
                                                newOutstandingBalance == 0 ? Promise.resolve() : membersContext.updateMemberOutstandingBalance(memberId, newOutstandingBalance, organizationContext.organisation_id),
                                                (newOutstandingBalance == 0 && currentOutstandingBalance) ? membersContext.deleteMemberOutstandingBalance(
                                                    currentOutstandingBalance.member_outstanding_balance_id,
                                                ) : Promise.resolve(),
                                            ])
                                                .then(async() => {
                                                    Promise.all([
                                                        membersContext.getMember(membersContext.currentMember.member_id),
                                                        mutate(),
                                                    ])
                                                    success();
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                                .finally(() => setCardsLoading(false))
                                        }}
                                    >
                                        {(isEditing) => {
                                            if(!isEditing){
                                                return (
                                                    <div className='text-truncate'>
                                                        <div className="h4 font-bold"><CurrencyFormat value={cardValues.outstanding_balance / 100} /></div>
                                                        <Translate id='members.profile.memberProfile.header.card.outstandingBalance' />
                                                    </div>
                                                )
                                            }
                                            return (
                                                <I18nContext.Consumer>
                                                    {({ getGenericLocale }) => (
                                                        <FormikInputNumber
                                                            allowLeadingZeros
                                                            fixedDecimalScale
                                                            thousandSeparator=' '
                                                            allowNegative={false}
                                                            decimalScale={2}
                                                            name="outstandingBalance"
                                                            id="outstandingBalance"
                                                            decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                            suffix={getGenericLocale() === 'fr' ? ' $' : ''}
                                                            prefix={getGenericLocale() !== 'fr' ? '$ ' : ''}
                                                        />
                                                    )}
                                                </I18nContext.Consumer>
                                            )

                                        }}
                                    </FormikEditable>
                                </Tooltip>
                            )}
                        />
                    </MemberOverviewCards>
                </>
            }
        </Fade>
    );
}

export default MemberHeader;

// const getMemberStatements = () => {
//     return membersContext.getMemberStatements(memberId, organizationContext.organisation_id, periodsContext.selectedPeriod.period_id)
//         .then((cards) => setCardValues(cards))
//         .catch((error) => {
//             if(!AxiosIsCancelled(error.message)){
//                 console.error(error.message)
//                 fail({
//                     msg: 'misc.error',
//                     info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
//                     skipInfoTranslate: true,
//                 })
//             }
//         })
// }

// const getMemberOfficiatingCoach = () => {
//     return getMemberOfficiatingCoachList(memberId, { current_period_only: 1 })
//         .then((list) => list[0])
//         .then((list) => setOfficiatingCoach(list))
//         .catch((error) => {
//             if(!AxiosIsCancelled(error.message)){
//                 console.error(error.message)
//                 fail({
//                     msg: 'misc.error',
//                     info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
//                     skipInfoTranslate: true,
//                 })
//             }
//         })
// }

// useEffect(() => {
//     setIsValidating(true);
//     Promise.all([
//         getMemberStatements(),
//         getMemberOfficiatingCoach(),
//     ])
//         .finally(() => setIsValidating(false))

// }, [ routeMatch.url ])