import SpordleTableProvider, { SpordleTableView, Refresh, SearchInput } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import { Button, Card } from "reactstrap";
import { AxiosCancelGIRCalls, AxiosIsCancelled } from "../../../api/CancellableAPI";
import SpordlePanelTable from "../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../components/table/EmptyLayout";
import UserDisplay from "../../../components/userDisplay/UserDisplay";
import { I18nContext } from "../../../contexts/I18nContext";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import AddGameIncidentModal from "./components/modal/AddGameIncident";
import { BC_ID, GameIncidentStatusBadge } from "./components/gameIncidentHelper";
import GameIncidentSidePanel from "./components/sidePanel/GameIncidentSidePanel";
import { GameIncidentsContext } from "../../../contexts/GameIncidentsContext";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { SuspensionsContext } from "../../../contexts/SuspensionsContext";
import CanDoAction from "../../../components/permissions/CanDoAction";
import { RolesContext } from "../../../contexts/RolesContext";
import { IdentityRolesContext } from "../../../contexts/IdentityRolesContext";
import { fail } from "@spordle/toasts";
import ViewHeaderV2 from "../../../components/viewHeader/ViewHeaderV2";

const GameIncident = () => {
    // Contexts
    const { organisation_id } = useContext(OrganizationContext);
    const { getSuspensions, getSuspensionTypes } = useContext(SuspensionsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { getOrgGameIncidentList, getGameIncidentStatusList } = useContext(GameIncidentsContext);
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);

    // States
    const [ isOpen, setIsOpen ] = useState(false);
    const [ gameIncidentRessources, setGameIncidentRessources ] = useState({
        statuses: [],
        suspensions: [],
        suspension_types: [],
    });

    // Other
    const canEdit = canDoAction("EDIT", "organization", "game_incidents") || isGod();

    // React cycles
    useEffect(() => {
        Promise.all([
            getSuspensions(organisation_id, { visible_on_gir: 1 }),
            getSuspensionTypes(organisation_id),
            getGameIncidentStatusList(BC_ID),
        ])
            .then(([ suspensions, suspensionTypes, statuses ]) => {
                setGameIncidentRessources({
                    statuses: statuses,
                    suspensions: suspensions,
                    suspension_types: suspensionTypes,
                })
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, []);

    return (
        <>
            {/* Task View Header */}
            <ViewHeaderV2 title='gameIncident.title' />

            <div className="px-4">
                <Card body className='card-shadow'>
                    <SpordlePanelTable
                        allowOutsideClick
                        dataIndex='game_incident_id'
                        sidePanel={(sProps) => (
                            <GameIncidentSidePanel
                                statuses={gameIncidentRessources.statuses}
                                suspensions={gameIncidentRessources.suspensions}
                                suspensionTypes={gameIncidentRessources.suspension_types}
                                canEdit={canEdit}
                                {...sProps}
                            />
                        )}
                        table={(panelProps) => (
                            <SpordleTableProvider
                                tableHover bordered striped
                                clickable
                                id='GameIncidents'
                                ref={panelProps.spordleTableRef}
                                pagination={10}
                                desktopWhen="md"
                                dataIndex='game_incident_id'
                                defaultSorting="-season"
                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                loadData={(from, _, { setLoading }) => {
                                    switch (from){
                                        case 'REFRESH':
                                            AxiosCancelGIRCalls();
                                            setLoading();
                                        case 'CDM':
                                            return getOrgGameIncidentList(organisation_id)
                                                .catch((error) => {
                                                    if(AxiosIsCancelled(error.message)){
                                                        return true;
                                                    }
                                                })
                                        default:
                                            break;
                                    }
                                }}
                                searchKeys={[
                                    'game_incident_number',
                                    'period.name',
                                    'period.i18n.name',
                                    'home_team.short_name',
                                    'home_team.name',
                                    'created_by.name',
                                    'created_by.family_name',
                                    'created_by.email',
                                    'home_team.i18n.short_name',
                                    'away_team.short_name',
                                    'away_team.name',
                                    'away_team.i18n.short_name',
                                    'game_incident_status.name',
                                    'game_incident_status.i18n.name',
                                ]}
                                columns={[
                                    {
                                        label: '#',
                                        key: 'game_incident_number',
                                        sortKey: 'game_incident_number',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='gameIncident.addModal.label.season' />,
                                        key: 'season',
                                        sortKey: 'period.start_date',
                                        fallbackSortKey: 'period.end_date',
                                        sortable: true,
                                    },
                                    {
                                        label: 'Submitted by',
                                        key: 'created_by',
                                        sortKey: 'created_by.name',
                                        fallbackSortKey: 'created_by.family_name',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='gameIncident.addModal.teams.home.label' />,
                                        key: 'home_team',
                                        sortKey: 'home_team.short_name',
                                        fallbackSortKey: 'home_team.name',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='gameIncident.addModal.teams.away.label' />,
                                        key: 'away_team',
                                        sortKey: 'away_team.short_name',
                                        fallbackSortKey: 'away_team.name',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id="misc.status" />,
                                        key: 'status',
                                        sortKey: () => `game_incident_status.${getGenericLocale()}.name`,
                                        fallbackSortKey: 'game_incident_status.name',
                                        sortable: true,
                                    },
                                ]}
                                renderRow={(columnKey, gameIncident) => {

                                    switch (columnKey){
                                        case 'season':
                                            return (
                                                <span className="text-nowrap">
                                                    <DisplayI18n
                                                        field="name"
                                                        defaultValue={gameIncident.period?.name}
                                                        i18n={gameIncident.period?.i18n}
                                                    />
                                                </span>
                                            )
                                        case 'created_by':
                                            return (
                                                <UserDisplay>
                                                    <UserDisplay.Container>
                                                        <UserDisplay.Title>
                                                            {gameIncident.created_by?.name}  {gameIncident.created_by?.family_name}
                                                        </UserDisplay.Title>
                                                        <UserDisplay.Subtitle>
                                                            {gameIncident.created_by?.email}
                                                        </UserDisplay.Subtitle>
                                                    </UserDisplay.Container>
                                                </UserDisplay>
                                            )
                                        case 'home_team':
                                            return (
                                                <UserDisplay>
                                                    <UserDisplay.Container>
                                                        <UserDisplay.Title>{gameIncident.home_team.name}</UserDisplay.Title>
                                                        {gameIncident.home_team.short_name &&
                                                            <UserDisplay.Subtitle>({gameIncident.home_team.short_name})</UserDisplay.Subtitle>
                                                        }
                                                        {gameIncident.home_team.unique_identifier &&
                                                            <UserDisplay.Subtitle>
                                                                #{gameIncident.home_team.unique_identifier}
                                                            </UserDisplay.Subtitle>
                                                        }
                                                    </UserDisplay.Container>
                                                </UserDisplay>
                                            )
                                        case 'away_team':
                                            return (
                                                <UserDisplay>
                                                    <UserDisplay.Container>
                                                        <UserDisplay.Title>{gameIncident.away_team.name}</UserDisplay.Title>
                                                        {gameIncident.away_team.short_name &&
                                                            <UserDisplay.Subtitle>({gameIncident.away_team.short_name})</UserDisplay.Subtitle>
                                                        }
                                                        {gameIncident.away_team.unique_identifier &&
                                                            <UserDisplay.Subtitle>
                                                                #{gameIncident.away_team.unique_identifier}
                                                            </UserDisplay.Subtitle>
                                                        }
                                                    </UserDisplay.Container>
                                                </UserDisplay>
                                            )
                                        case 'status':
                                            return (
                                                <GameIncidentStatusBadge status={gameIncident.game_incident_status} />
                                            )
                                        default:
                                            break;
                                    }
                                }}
                                emptyLayout={<EmptyLayout hideArrow hideMsg />}
                                onColumnClick={(e, data) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            panelProps.onSingleSelectChange(data);
                                            break;
                                    }
                                }}
                                rowIsHighlighted={(data) => data.checked}
                            >
                                <div className='mb-2'>
                                    <div className='d-flex flex-wrap justify-content-between'>
                                        <div className="order-1 order-md-0 flex-grow-1 flex-md-grow-0">
                                            <SearchInput />
                                        </div>
                                        <Refresh className="order-3 order-md-0 ml-2 ml-md-auto" />
                                        <CanDoAction componentCode="organization" action="ADD" componentPermissionCode="game_incidents">
                                            <Button onClick={() => setIsOpen(true)} type="button" className="mb-2 mb-md-0 w-100 w-md-auto ml-md-2 order-0 order-md-3" color="primary">
                                                <i className="mdi mdi-plus" /> <Translate id="misc.add" />
                                            </Button>
                                            <AddGameIncidentModal
                                                statuses={gameIncidentRessources.statuses}
                                                suspensions={gameIncidentRessources.suspensions}
                                                suspensionTypes={gameIncidentRessources.suspension_types}
                                                isOpen={isOpen}
                                                toggle={() => setIsOpen(false)}
                                            />
                                        </CanDoAction>
                                    </div>
                                </div>
                                <SpordleTableView />
                            </SpordleTableProvider>
                        )}
                    />
                </Card>
            </div>
        </>
    )
}

export default GameIncident;