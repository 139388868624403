import { FormikSelect } from "@spordle/formik-elements"
import { useFormikContext } from "formik"
import { useContext, useEffect, useRef, useState } from "react"
import { Col, FormGroup, Label } from "reactstrap"
import { I18nContext } from "../../../../../contexts/I18nContext"
import { OrganizationContext } from "../../../../../contexts/OrganizationContext"
import { FormsContext } from "../../../../../contexts/FormsContext"
import { DisplayI18n } from "../../../../../helpers/i18nHelper"
import Translate from "@spordle/intl-elements"
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI"
import FilterLabel from "../FilterLabel"
import EditFilter from "../manageFilters/EditFilter"

const EngineQuestionnaireFormSelect = ({ filter, setCustomFormId, filterRefs, customFormId }) => {
    const formik = useFormikContext();
    const orgContext = useContext(OrganizationContext);
    const i18n = useContext(I18nContext);
    const formsContext = useContext(FormsContext)

    const [ registrationFees, setRegistrationFees ] = useState([]);
    const [ affiliationFees, setAffiliationFees ] = useState([]);
    const [ clinics, setClinics ] = useState([]);

    const formIdRef = useRef();
    const registrationFeeIdRef = useRef();
    const affiliationFeeIdRef = useRef();
    const clinicIdRef = useRef();

    useEffect(() => {
        formik.setFieldValue('registration_fee_id', []);
        formik.setFieldValue('affiliation_fee_id', []);
        formik.setFieldValue('clinic_id', ''); // change this to [] when we go to multi select for clinics
        if(customFormId){
            // refresh registration fees
            registrationFeeIdRef.current?.getSpordleTable()?.filterChange()
            // refresh affiliation fees
            affiliationFeeIdRef.current?.getSpordleTable()?.filterChange()
            // refresh clinic fees
            clinicIdRef.current?.getSpordleTable()?.filterChange()
        }else if(formik.initialValues?.custom_form_id){
            setCustomFormId(formik.initialValues?.custom_form_id)
        }
    }, [ formik?.values?.organisation_id, formik?.values?.period_id, customFormId ])

    useEffect(() => {
        formIdRef.current?.getSpordleTable().refreshTable();
    }, [ formik?.values?.organisation_id, formik?.values?.period_id ])


    return (
        <>
            <Col md={4}>
                <FormGroup name={filter.code}>
                    <FilterLabel filter={filter} />
                    <FormikSelect
                        clearable
                        name={`custom_form_id`}
                        id='formSelectQuestionnaireReport'
                        ref={formIdRef}
                        loadingStatus='loading'
                        loadData={(from) => {
                            switch (from){
                                case 'REFRESH':
                                case 'CDM':
                                case 'FILTER':
                                    return formsContext.getForms(formik.values?.organisation_id || orgContext.organisation_id)
                                        .then((forms) => {
                                            return (
                                                forms.map((form) => ({
                                                    id: form.custom_form_id,
                                                    value: form.custom_form_id,
                                                    label: form.name,
                                                }))
                                            );
                                        })
                                default:
                                    break;
                            }
                        }}
                        onOptionSelected={(form) => {
                            if(form[0])
                                setCustomFormId(form[0]) // this is for the engine
                            else
                                setCustomFormId(form[0]) // this is for the engine

                            formik.setFieldValue('registration_fee_id', []);
                            formik.setFieldValue('affiliation_fee_id', []);
                            setTimeout(() => {
                            // this is to make the validation message disappear, formik seems to not time the validation correctly on selects
                                formik.validateField('custom_form_id');
                            }, 150);
                        }}
                    />
                </FormGroup>
            </Col>
            <Col md={4}>
                <FormGroup>
                    <Label>
                        <Translate id='reports.filters.registration_fee_id' />
                    </Label>
                    <FormikSelect
                        clearable multi
                        name='registration_fee_id'
                        id='registration_fee_id_questionnaireReport'
                        ref={registrationFeeIdRef}
                        key={customFormId}
                        loadingStatus={"success"}
                        loadData={(from, { filters }, spordleTable) => {
                            spordleTable?.setLoading();
                            if(customFormId)
                                return formsContext.getRegistrationFeesFromForm(customFormId, formik.values.period_id)
                                    .then((regFees) => {
                                        setRegistrationFees(regFees)
                                        return regFees.map((fee) => ({
                                            id: fee.registration_fee_id,
                                            value: fee.registration_fee_id,
                                            label: fee.name,
                                            i18n: fee.i18n,
                                        }))
                                    }).catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                        }
                                    })
                            return Promise.resolve([]);
                        }}
                        disabled={!(registrationFees && registrationFees?.length > 0)}
                        renderOption={(option) => {
                            return <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                        }}
                        searchKeys={[
                            `i18n.${i18n.getGenericLocale()}.name`,
                            'label',
                        ]}
                        textWhenSetting={{
                            count: 1,
                            label: 'reports.filters.registrationFees.count',
                        }}
                    />
                </FormGroup>
            </Col>
            <Col md={4}>
                <FormGroup>
                    <Label>
                        <Translate id='reports.filters.affiliation_fee_id' />
                    </Label>
                    <FormikSelect
                        clearable multi
                        name='affiliation_fee_id'
                        id='affiliation_fee_id_questionnaireReport'
                        ref={affiliationFeeIdRef}
                        key={customFormId}
                        disabled={!(affiliationFees && affiliationFees?.length > 0)}
                        loadingStatus={'success'}
                        loadData={(from, { filters }, spordleTable) => {
                            spordleTable.setLoading();
                            if(customFormId)
                                return formsContext.getAffiliationFeesFromForm(customFormId, formik.values.period_id)
                                    .then((affiliationFees) => {
                                        setAffiliationFees(affiliationFees)
                                        return affiliationFees.map((fee) => ({
                                            id: fee.affiliation_fee_id,
                                            value: fee.affiliation_fee_id,
                                            label: fee.name,
                                            i18n: fee.i18n,
                                        }))
                                    }).catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                        }
                                    })
                            return Promise.resolve([]);
                        }}
                        renderOption={(option) => {
                            return <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                        }}
                        searchKeys={[
                            `i18n.${i18n.getGenericLocale()}.name`,
                            'label',
                        ]}
                        textWhenSetting={{
                            count: 1,
                            label: 'reports.filters.registrationFees.count',
                        }}
                    />
                </FormGroup>
            </Col>
            <Col md={4}>
                <EditFilter currentFilter={filter} />
                <FormGroup>
                    <Label>
                        <Translate id='reports.filters.clinic_id' />
                    </Label>
                    <FormikSelect
                        clearable // multi
                        name='clinic_id'
                        id='clinic_id_questionnaireReport'
                        ref={clinicIdRef}
                        key={customFormId}
                        disabled={!(clinics && clinics?.length > 0)}
                        loadingStatus={'success'}
                        loadData={(from, { filters }, spordleTable) => {
                            spordleTable.setLoading();
                            if(customFormId)
                                return formsContext.getFormClinics({ customFormId: customFormId, periodId: formik.values.period_id })
                                    .then((clinics) => {
                                        setClinics(clinics)
                                        return clinics.map((fee) => ({
                                            id: fee.clinic_id,
                                            value: fee.clinic_id,
                                            label: fee.name,
                                            i18n: fee.i18n,
                                        }))
                                    }).catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                        }
                                    })
                            return Promise.resolve([]);
                        }}
                        renderOption={(option) => {
                            return <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                        }}
                        searchKeys={[
                            `i18n.${i18n.getGenericLocale()}.name`,
                            'label',
                        ]}
                        textWhenSetting={{
                            count: 1,
                            label: 'reports.filters.clinics.count',
                        }}
                    />
                </FormGroup>
            </Col>
        </>
    )
}

export default EngineQuestionnaireFormSelect