import { Tooltip } from "@mantine/core";
import { useSpordleTable } from "@spordle/datatables";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import SpordleSelect from "@spordle/spordle-select";
import { Button } from "reactstrap";

const SortTableSelect = ({ onOptionSelected }) => {
    const spordleTable = useSpordleTable();

    return (
        <div className="d-flex align-items-center">
            <Tooltip
                withArrow
                label={
                    <>
                        <Translate id={`news.table.sortBy.${spordleTable.state.orderingBy.order === "DESC" ? "asc" : "desc"}`} />
                        <i className={stringBuilder(spordleTable.state.orderingBy.order === "DESC" ? "mdi-sort-ascending" : "mdi-sort-descending", "mdi ml-1")} />
                    </>
                }
            >
                <Button
                    disabled={!spordleTable.state.orderingBy.column}
                    color="light"
                    type="button"
                    onClick={() => {
                        spordleTable.setColumnSorting(`${spordleTable.state.orderingBy.order === "DESC" ? "+" : "-"}${spordleTable.state.orderingBy.column}`)
                    }}
                    style={{
                        lineHeight: 1.5,
                    }}
                    className={
                        stringBuilder(
                            "mdi border-top w-auto border-bottom border-left rounded-right-0",
                            spordleTable.state.orderingBy.order === "DESC" ? "mdi-sort-descending" : "mdi-sort-ascending",
                        )
                    }
                />
            </Tooltip>
            <SpordleSelect
                className="w-100"
                inputClassName="rounded-left-0"
                name="sortBy"
                id="sortBy"
                loadingStatus="success"
                clearable
                values={spordleTable.state.orderingBy.column ? [ spordleTable.state.orderingBy.column ] : []}
                search={false}
                placeholder="news.table.sortBy"
                renderSelectedOption={(option) => (
                    <>
                        <span className="text-muted mr-1"><Translate id="news.table.sortBy" />:</span>
                        <span>{option.label}</span>
                    </>
                )}
                onOptionSelected={([ value ]) => {
                    spordleTable.setColumnSorting(value ? `${spordleTable.state.orderingBy.order === "DESC" ? "-" : "+"}${value}` : "");
                    onOptionSelected?.(value ? `${spordleTable.state.orderingBy.order === "DESC" ? "-" : "+"}${value}` : "");
                }}
                defaultData={
                    spordleTable.getColumns().reduce((options, col) => {
                        if(col.sortable){
                            options.push({
                                label: col.label,
                                value: col.key,
                            })
                        }

                        return options;
                    }, [])
                }
            />
        </div>
    );
}

export default SortTableSelect;