import Translate from "@spordle/intl-elements";
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import { FormattedPlural } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import CollapsibleCard from "../../../components/collapsibleCard/CollapsibleCard";
import UserDisplay from "../../../components/userDisplay/UserDisplay";
import UserImg from "../../../components/UserImg";
import { HoverableCopy } from "../../../helpers/clipboardHelper";
import { stringBuilder } from "@spordle/helpers";


const AttendeesList = ({ clinic, attendees, hostView }) => {
    const attendeesCount = clinic.clinic_attendee_count;
    const maxAttendees = clinic.max_attendee;
    const isLoading = !Array.isArray(attendees);
    const havePaid = isLoading ? null : attendees.filter((a) => a.invoice?.status === 'COMPLETED' || a.invoice?.status === 'PAID').length;

    const getAttendeesCount = () => {
        if(maxAttendees){
            if(hostView){
                return (
                    <span>({attendeesCount} / {maxAttendees})</span>
                )
            }
            // filter attendees with current org

        }else if(hostView){
            return (
                <span>({attendeesCount})</span>
            )
            // filter attendes with current org
        }
    }

    const getAttendeesSubtitle = () => (
        isLoading ?
            <Spinner type="grow" size="sm" />
            :
            hostView ?
                havePaid !== 0 &&
                <FormattedPlural
                    value={havePaid}
                    one={<Translate id='clinics.search.sidePanelClinicSearch.attendees.single.havePaid' />}
                    other={<Translate id='clinics.search.sidePanelClinicSearch.attendees.multiple.havePaid' values={{ amount: havePaid }} />}
                />
                :
                <span><Translate id='clinics.search.sidePanelClinicSearch.attendees.guest' /></span>
    )

    return (
        <>
            <CollapsibleCard
                className="rounded-0 border-top-0"
                innerClassName="rounded-0"
                key={clinic.clinic_id}
                initOpen={attendeesCount <= 10}
                title={
                    <div className="d-flex align-items-center">
                        <span className="mr-1">
                            <Translate id='clinics.search.sidePanelClinicSearch.attendees.attendees' />
                        </span>
                        {getAttendeesCount()}
                    </div>
                }
                subtitle={
                    <div className="d-flex align-items-center">
                        {getAttendeesSubtitle()}
                    </div>
                }
            >
                {!isLoading &&
                    <AttendeesCardList attendees={attendees} clinic={clinic} />
                }
            </CollapsibleCard>
        </>
    );
}

const AttendeesCardList = ({ attendees, clinic }) => {
    return (
        <>
            <SpordleTableProvider
                id="clinicSearchSidePanelAttendees"
                searchKeys={[
                    { name: 'member.unique_identifier', weight: 2 },
                    'member.last_name',
                    'member.first_name',
                ]}
                viewMode='GRID'
                dataIndex="clinic_attendee_id"
                loadData={(from) => {
                    switch (from){
                        case 'CDM':
                            return Promise.resolve(attendees || []);
                        default:
                            break;
                    }
                }}
                loadingMessage={
                    Array.from({ length: 3 }, (_, i) => i).map((key) => (
                        <UserDisplay className="mb-2" block card key={key}>
                            <UserDisplay.Container>
                                <Skeleton circle height={40} width={40} />
                            </UserDisplay.Container>
                            <UserDisplay.Container>
                                <Skeleton containerClassName="line-height-1 d-block" className="mb-0" height={10} width={87} />
                                <Skeleton className="mb-1" height={16} width={150} />
                                <Skeleton className="mb-0" height={12} width={125} />
                            </UserDisplay.Container>
                        </UserDisplay>
                    ))
                }
                pagination={50}
                gridConfig={{
                    row: {
                        form: true,
                    },
                    col: {
                        sm: '12',
                    },
                    gridRender: (attendee, spordleTable) => (
                        <AttendeeCard attendee={attendee} />
                    ),
                }}
                paginationMessage={() => null}
                emptyLayout={
                    <div className="max-w-75 mx-auto text-center text-muted">
                        <div className={`${attendees.length > 0 ? "mdi-account-search" : "mdi-account-circle"} mdi line-height-1`} style={{ fontSize: 50 }} />
                        <div className="font-bold"><Translate id='clinics.search.sidePanelClinicSearch.attendees.noAttendees' /></div>
                        <div className="small">
                            <Translate id={`clinics.search.sidePanelClinicSearch.attendees.noAttendees${attendees.length > 0 ? "Found" : "Registered"}`} />
                        </div>
                        <Link
                            to={`/clinics/profile/${clinic?.clinic_id}/attendees`}
                            className="text-link small"
                        >
                            <Translate id='clinics.search.sidePanelClinicSearch.attendees.viewList' /> <i className="mdi mdi-chevron-right" />
                        </Link>
                    </div>
                }
            >
                {(spordleTable) => (
                    <>
                        {attendees.length > 0 &&
                            <>
                                <SpordleTableProvider.SearchInput className="mb-3 w-100" />
                                <div
                                    className={stringBuilder(
                                        "d-flex justify-content-end",
                                        { "mb-3": spordleTable.filteredData.length > spordleTable.getPagination() },
                                    )}
                                >
                                    <SpordleTableProvider.Paginator.Pagination />
                                </div>
                            </>
                        }
                        <SpordleTableView />
                        {spordleTable.getDisplayedData().length > 0 &&
                            <Link to={`/clinics/profile/${clinic?.clinic_id}/attendees`} className="text-right btn btn-sm btn-link mt-2 w-100">
                                <Translate id='clinics.search.sidePanelClinicSearch.attendees.viewList' /> <i className="mdi mdi-chevron-right" />
                            </Link>
                        }
                    </>
                )}
            </SpordleTableProvider>
        </>
    )
}

const AttendeeCard = ({ attendee }) => {
    return (
        <UserDisplay className="mb-2" card block>
            <UserDisplay.Container>
                <UserImg
                    className="text-uppercase"
                    width={40}
                    alt={attendee.member.first_name + " " + attendee.member.last_name}
                    abbr={attendee.member.first_name.charAt(0) + attendee.member.last_name.charAt(0)}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Subtitle>
                    <Link
                        to={{
                            pathname: `/clinics/profile/${attendee.clinic?.clinic_id}/attendees`,
                            search: attendee.member.unique_identifier,
                        }}
                    >
                        <Translate id='clinics.search.sidePanelClinicSearch.attendees.viewInClinic' /> <i className="mdi mdi-chevron-right" />
                    </Link>
                </UserDisplay.Subtitle>
                <UserDisplay.Title>
                    {attendee.member.first_name} {attendee.member.last_name}
                </UserDisplay.Title>
                <UserDisplay.Subtitle>
                    <HoverableCopy toCopy={attendee.member.unique_identifier}>
                        <Link to={'/members/profile/' + attendee.member.member_id}>
                            #{attendee.member.unique_identifier} <i className="mdi mdi-chevron-right" />
                        </Link>
                    </HoverableCopy>
                </UserDisplay.Subtitle>
            </UserDisplay.Container>
            <UserDisplay.Container className="ml-auto text-center">
                {(attendee.invoice?.status === 'COMPLETED' || attendee.invoice?.status === 'PAID') &&
                    <>
                        <i className="line-height-1 font-22 mdi mdi-check-circle text-primary" />
                        <div className="text-uppercase small">
                            <Translate id='clinics.profile.attendees.table.paid' />
                        </div>
                    </>
                }
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default AttendeesList;