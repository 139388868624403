import { FormikCheckedButton, FormikGroup, FormikInputMasked, FormikInputNumber, FormikRadioButton, FormikSelect, FormikSwitch } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { FieldArray, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import {
    Button, Col,
    Collapse,
    Label, Row
} from "reactstrap";
import { array, boolean, number, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import Required from '../../../../../../components/formik/Required';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import I18nHelperContextProvider, { DisplayI18n, I18nHelperContext } from "../../../../../../helpers/i18nHelper";
import { fail } from '@spordle/toasts';
import BtnDisplay from '../../../../../../components/input/BtnDisplay';
import { stringBuilder } from '@spordle/helpers';
import CrossFade from '../../../../../../components/crossFade/CrossFade';
import CustomAlert from '../../../../../../components/CustomAlert';
import generatePassword from '../../../../../../helpers/passwordGenerator';
import { UtilsContext } from '../../../../../../contexts/UtilsContext';
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { CrcContext } from '../../../../../../contexts/CrcContext';
import BackgroundCheckTypesAdd from '../backgroundCheckTypes/BackgroundCheckTypesAdd';
import { useDisclosure } from "@mantine/hooks";
import { Tooltip } from '@mantine/core';
import CanDoAction from '../../../../../../components/permissions/CanDoAction';

const CriminalRecordCheckForm = ({ toggleIsEditing, settings, backgroundChecks, getBackgroundChecks, expirationMethods }) => {
    const organisationContext = useContext(OrganizationContext);
    const utilsContext = useContext(UtilsContext);
    const membersContext = useContext(MembersContext);
    const crcContext = useContext(CrcContext);

    const initialEnabled = organisationContext.settings.enable_background_check?.value == 1;

    const emptyCheck = {
        orgId: organisationContext.organisation_id,
        background_check_type_id: '',
        member_type_id: '',
        background_check_duration_id: '',
        need_document: false,
        expiration_method: '',
        expiration_date: '',
        compliance_due_date: '',
        min_age: '18',
    }
    const otherOption = 'OTHER';

    const [ backgroundCheckTypes, setBackgroundCheckTypes ] = useState(null);
    const [ memberTypes, setMemberTypes ] = useState(null);
    const [ backgroundCheckDurations, setBackgroundCheckDurations ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ isOpen, { toggle } ] = useDisclosure(false);
    const [ currentFieldName, setCurrentFieldName ] = useState('');

    const formikRef = useRef();

    useLayoutEffect(() => {
        setLoading(true);
        Promise.all([
            crcContext.getBackgroundCheckTypes(organisationContext.organisation_id),
            membersContext.getMemberTypes(organisationContext.organisation_id),
            utilsContext.getBackgroundCheckDurations(),
        ])
            .then((promises) => {
                setBackgroundCheckTypes(promises[0]);
                setMemberTypes(promises[1]);
                setBackgroundCheckDurations(promises[2]);
                setLoading(false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message);
                    setLoading(false);
                }
            })
    }, [])

    const toggleAndSelect = async(backgroundCheckTypeId) => {
        if(backgroundCheckTypeId){
            setLoading(true);
            const types = await crcContext.getBackgroundCheckTypes(organisationContext.organisation_id)
            setBackgroundCheckTypes(types);
            formikRef.current?.setFieldValue(currentFieldName, backgroundCheckTypeId);
            setLoading(false);
        }
        toggle();
    }

    return (
        <>
            <I18nHelperContextProvider fields={[ 'name', 'description' ]}>
                <I18nHelperContext.Consumer>
                    {(i18nHelper) => (
                        <BackgroundCheckTypesAdd isOpen={isOpen} toggle={toggleAndSelect} />
                    )}
                </I18nHelperContext.Consumer>
            </I18nHelperContextProvider>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    active: initialEnabled,
                    backgroundChecks: backgroundChecks.map((check) => {
                        return {
                            organisation_background_check_id: check.organisation_background_check_id,
                            id: check.organisation_background_check_id,
                            background_check_type_id: check.background_check_type.background_check_type_id,
                            member_type_id: check.member_type.member_type_id,
                            background_check_duration_id: check.background_check_duration.background_check_duration_id,
                            need_document: check.need_document == 1,
                            expiration_method: check.expiration_method,
                            expiration_date: check.expiration_date || '',
                            compliance_due_date: check.compliance_due_date || '',
                            min_age: check.min_age,
                            orgId: check.organisation.organisation_id,
                        }
                    }),
                }}
                validationSchema={object().shape({
                    active: boolean(),
                    backgroundChecks: array().of(object().shape({
                        background_check_type_id: string().required(<Translate id='settings.crc.rules.yup.validationType.required' />),
                        member_type_id: string().required(<Translate id='settings.crc.memberType.required' />),
                        background_check_duration_id: string().required(<Translate id='settings.crc.rules.yup.duration.required' />),
                        need_document: boolean(),
                        expiration_method: string().required(<Translate id='settings.crc.expirationMethod.required' />),
                        expiration_date: string().when('expiration_method', {
                            is: 'FIXED_PERIOD',
                            then: string().required(<Translate id='settings.crc.expirationMethod.date.required' />).test({
                                name: 'expirationDateIsDate',
                                message: <Translate id='form.validation.date.format' />,
                                test: (value) => value ? moment(value, 'MM-DD').isValid() : true,
                            }),
                        }),
                        compliance_due_date: string().test({
                            name: 'complianceIsDate',
                            message: <Translate id='form.validation.date.format' />,
                            test: (value) => value ? moment(value, 'MM-DD').isValid() : true,
                        }),
                        min_age: number().nullable(),
                    })),
                })}
                onSubmit={({ active, ...values }, { setSubmitting, setStatus }) => {
                    const backgroundChecksToDelete = backgroundChecks.filter((check) => {
                        return !values.backgroundChecks.find((_check) => _check.id === check.organisation_background_check_id)
                    });
                    const settingsApiValues = {
                        enable_background_check: active,
                    }
                    const apiValues = { background_checks: values.backgroundChecks.reduce((newArray, check) => {
                        if(check.orgId && check.orgId === organisationContext.organisation_id){
                            newArray.push({
                                ...check,
                                id: '',
                                orgId: '',
                                expiration_date: check.expiration_method === 'FIXED_PERIOD' ? check.expiration_date : '',
                                compliance_due_date: check.compliance_due_date ? check.compliance_due_date : '',
                                min_age: check.min_age ? check.min_age : '',
                            })
                        }
                        return newArray
                    }, []) }

                    Promise.all([
                        active !== initialEnabled ? organisationContext.updateSettings(organisationContext.organisation_id, settingsApiValues) : Promise.resolve(),
                        (apiValues.background_checks.length > 0 && active) ? organisationContext.updateOrganizationBackgroundChecks(organisationContext.organisation_id, apiValues) : Promise.resolve(),
                        ...backgroundChecksToDelete.map((check) => organisationContext.deleteOrganizationBackgroundCheck(organisationContext.organisation_id, check.organisation_background_check_id)),
                    ])
                        .then(async() => {
                            await getBackgroundChecks()
                            toggleIsEditing()
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message);
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                                setSubmitting(false);
                            }
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <div className="d-flex align-items-center mb-4">
                                <FormikSwitch
                                    name="active"
                                    id="active-crc"
                                />
                                <label className="ml-2 mb-0 text-muted" htmlFor="active"><Translate id='settings.crc.enable' /></label>
                            </div>
                            <Collapse isOpen={formik.initialValues.active}>
                                <>
                                    <div className="mb-4">
                                        <div className="text-dark font-bold mb-2 h5"><Translate id='settings.crc.rules' /></div>
                                        {!formik.values.backgroundChecks.length &&
                                            <div className='font-medium text-dark mb-3'>
                                                <Translate id='settings.crc.rules.none' />
                                            </div>
                                        }

                                        {/* start FieldArray */}
                                        <FieldArray name='backgroundChecks'>
                                            {(backgroundChecksFieldHelper) => (
                                                <>
                                                    {formik.values.backgroundChecks.map((check, index) => {
                                                        const differentOrg = check.orgId && check.orgId !== organisationContext.organisation_id
                                                        const checkOrg = differentOrg ? backgroundChecks.find((bgc) => bgc.organisation_background_check_id === check.organisation_background_check_id)?.organisation : null
                                                        const isDisabled = !formik.values.active || differentOrg
                                                        return (
                                                            <Collapse key={check.id} isOpen appear className="pb-3">
                                                                <div className={stringBuilder(!isDisabled ? "shadow-sm" : "bg-light-inverse", "p-3 border rounded position-relative")}>
                                                                    {differentOrg && checkOrg &&
                                                                        <div className='font-medium text-dark mb-3'><u><DisplayI18n field='name' defaultValue={checkOrg.organisation_name} i18n={checkOrg.i18n} /></u></div>
                                                                    }
                                                                    <Row form>
                                                                        <Col xs={6} md={4} className="mb-3 mb-md-">
                                                                            <Label for={`backgroundChecks.${index}.background_check_type_id`} className='text-muted'><Translate id='settings.crc.validationType' /> <Required /></Label>
                                                                            <FormikSelect
                                                                                name={`backgroundChecks.${index}.background_check_type_id`} id={`backgroundChecks.${index}.background_check_type_id`}
                                                                                renderSelectedOption={(option) => {
                                                                                    if(option.value === otherOption)
                                                                                        return <Translate id={option?.label} />
                                                                                    return <DisplayI18n field='name' defaultValue={option?.label} i18n={option?.i18n} />
                                                                                }}
                                                                                renderOption={(option) => (
                                                                                    option.option?.value === otherOption
                                                                                        ?
                                                                                        <Translate id={option.option.label} />
                                                                                        :
                                                                                        <>
                                                                                            <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                                                                                            <div className='text-muted'><DisplayI18n field='description' defaultValue={option.option?.label} i18n={option.option?.i18n} /></div>
                                                                                        </>
                                                                                )}
                                                                                disabled={isDisabled}
                                                                                search={false}
                                                                                options={backgroundCheckTypes ? [
                                                                                    ...backgroundCheckTypes.map((type) => ({
                                                                                        value: type.background_check_type_id,
                                                                                        label: type.name,
                                                                                        i18n: type.i18n,
                                                                                    })),
                                                                                    {
                                                                                        value: otherOption,
                                                                                        label: 'misc.other',
                                                                                    },
                                                                                ] : []}
                                                                                isLoading={loading}
                                                                                onOptionSelected={(values) => {
                                                                                    if(values[0] === otherOption){
                                                                                        setCurrentFieldName(`backgroundChecks.${index}.background_check_type_id`)
                                                                                        toggle();
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col xs={6} md={4}>
                                                                            <Label for={`backgroundChecks.${index}.member_type_id`} className='text-muted'><Translate id='settings.crc.memberType' /> <Required /></Label>
                                                                            <FormikSelect
                                                                                name={`backgroundChecks.${index}.member_type_id`}
                                                                                id={`OrganisationSettingsCRCMemberTypesSelect${check.id}`}
                                                                                renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                                                options={memberTypes ? memberTypes.reduce((newArray, memberType) => {
                                                                                    if(memberType.system_type !== 'SPECIAL'){
                                                                                        newArray.push({
                                                                                            value: memberType.member_type_id,
                                                                                            label: memberType.name,
                                                                                            i18n: memberType.i18n,
                                                                                        })
                                                                                    }
                                                                                    return newArray
                                                                                }, []) : []}
                                                                                isLoading={loading}
                                                                                disabled={isDisabled}
                                                                            />
                                                                        </Col>
                                                                        <Col xs={6} md={4} className="mb-3 mb-md-">
                                                                            <Label for={`backgroundChecks.${index}.background_check_duration_id`} className='text-muted'><Translate id='settings.crc.duration' /> <Required /></Label>
                                                                            <FormikSelect
                                                                                name={`backgroundChecks.${index}.background_check_duration_id`} id={`backgroundChecks.${index}.background_check_duration_id`}
                                                                                disabled={isDisabled}
                                                                                search={false}
                                                                                renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                                                options={backgroundCheckDurations ? backgroundCheckDurations.map((duration) => ({
                                                                                    value: duration.background_check_duration_id,
                                                                                    name: duration.name,
                                                                                    i18n: duration.i18n,
                                                                                })) : []}
                                                                                isLoading={loading}
                                                                            />
                                                                        </Col>
                                                                        <Col xs={6} md={4}>
                                                                            <div className="text-muted mb-2">
                                                                                <Translate id='settings.crc.expirationMethod' />
                                                                                <Tooltip
                                                                                    label={
                                                                                        <div className='text-wrap'>
                                                                                            <p>
                                                                                                <Translate id='settings.crc.expirationMethod.tip.period' />
                                                                                            </p>
                                                                                            <Translate id='settings.crc.expirationMethod.tip.date' />
                                                                                        </div>
                                                                                    }
                                                                                    width='300px'
                                                                                >
                                                                                    <i className='mdi mdi-information-outline ml-1' />
                                                                                </Tooltip>
                                                                                <Required className='ml-1' />
                                                                            </div>
                                                                            <FormikGroup name={`backgroundChecks.${index}.expiration_method`} rowProps={{ className: 'd-block p-0 m-0' }}>
                                                                                {expirationMethods.map((validationType) => (
                                                                                    <>
                                                                                        <FormikRadioButton
                                                                                            className='mb-2'
                                                                                            key={validationType.code}
                                                                                            id={`crc-expiration-method-${validationType.code}-${check.id}`}
                                                                                            name={`backgroundChecks.${index}.expiration_method`} value={validationType.code}
                                                                                            translateLabel={false}
                                                                                            disabled={isDisabled}
                                                                                            label={
                                                                                                <React.Fragment>
                                                                                                    <DisplayI18n
                                                                                                        field='name'
                                                                                                        defaultValue={validationType.name}
                                                                                                        i18n={validationType.i18n}
                                                                                                    />
                                                                                                </React.Fragment>
                                                                                            }
                                                                                        />
                                                                                        {validationType.code === 'FIXED_PERIOD' &&
                                                                                            <Collapse isOpen={formik.values.backgroundChecks[index]?.expiration_method == 'FIXED_PERIOD'}>
                                                                                                <FormikInputMasked
                                                                                                    name={`backgroundChecks.${index}.expiration_date`}
                                                                                                    mask='00-00'
                                                                                                    placeholder='settings.crc.expirationMethod.date.placeholder'
                                                                                                    disabled={formik.values.backgroundChecks[index]?.expiration_method !== 'FIXED_PERIOD' || isDisabled}
                                                                                                    className='mb-2'
                                                                                                />
                                                                                            </Collapse>
                                                                                        }
                                                                                    </>
                                                                                ))}
                                                                            </FormikGroup>
                                                                        </Col>
                                                                        <Col xs={6} md={4} className='mb-3'>
                                                                            <Label className="text-muted" for={`backgroundChecks.${index}.compliance_date`}>
                                                                                <Translate id='settings.crc.complianceDate' />
                                                                                <Tooltip
                                                                                    label={
                                                                                        <div className='text-wrap'>
                                                                                            <Translate id='settings.crc.complianceDate.tip' />
                                                                                        </div>
                                                                                    }
                                                                                    width='300px'
                                                                                >
                                                                                    <i className='mdi mdi-information-outline ml-1' />
                                                                                </Tooltip>
                                                                            </Label>
                                                                            <FormikInputMasked
                                                                                name={`backgroundChecks.${index}.compliance_due_date`}
                                                                                mask='00-00'
                                                                                disabled={isDisabled}
                                                                                placeholder='settings.crc.complianceDate.placeholder'
                                                                            />
                                                                        </Col>
                                                                        <Col xs={6} md={4}>
                                                                            <Label className="text-muted" for={`backgroundChecks.${index}.min_age`}>
                                                                                <Translate id='settings.crc.minAge' />
                                                                                <Tooltip
                                                                                    label={
                                                                                        <div className='text-wrap'>
                                                                                            <Translate id='settings.crc.minAge.tip' />
                                                                                        </div>
                                                                                    }
                                                                                    width='300px'
                                                                                >
                                                                                    <i className='mdi mdi-information-outline ml-1' />
                                                                                </Tooltip>
                                                                            </Label>
                                                                            <FormikInputNumber
                                                                                name={`backgroundChecks.${index}.min_age`}
                                                                                disabled={isDisabled}
                                                                            />
                                                                        </Col>
                                                                        <Col xs={6} md={8} />
                                                                        <Col xs={12} md={4}>
                                                                            <FormikCheckedButton
                                                                                id={`backgroundChecks.${index}.need_document`}
                                                                                name={`backgroundChecks.${index}.need_document`}
                                                                                label={
                                                                                    <span className="text-muted">
                                                                                        <Translate id='settings.crc.supportingDocuments' />
                                                                                    </span>
                                                                                }
                                                                                translateLabel={false}
                                                                                disabled={!check.background_check_type_id || isDisabled}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    {!isDisabled &&
                                                                        <div className='position-absolute top-0 right-0'>
                                                                            <button
                                                                                className="reset-btn px-3 py-2"
                                                                                type='button'
                                                                                onClick={backgroundChecksFieldHelper.handlePush({
                                                                                    orgId: organisationContext.organisation_id,
                                                                                    background_check_type_id: check.background_check_type_id || '',
                                                                                    member_type_id: '',
                                                                                    background_check_duration_id: check.background_check_duration_id || '',
                                                                                    need_document: check.need_document || false,
                                                                                    expiration_method: check.expiration_method || '',
                                                                                    expiration_date: check.expiration_date || '',
                                                                                    compliance_due_date: check.compliance_due_date || '',
                                                                                    min_age: check.min_age || '',
                                                                                    id: generatePassword(),
                                                                                })}
                                                                            >
                                                                                <i className="mdi mdi-content-copy" />
                                                                            </button>
                                                                            <CanDoAction action="DELETE" componentCode="settings" componentPermissionCode="organization_background_checks">
                                                                                <button className="reset-btn text-danger px-3 py-2" type='button' onClick={backgroundChecksFieldHelper.handleRemove(index)}>
                                                                                    <i className="mdi mdi-delete" />
                                                                                </button>
                                                                            </CanDoAction>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </Collapse>
                                                        )
                                                    })}
                                                    {formik.values.active ?
                                                        <BtnDisplay className="w-100 flex-center" title={<span className="text-primary"><i className="mdi mdi-plus" /><Translate id='misc.add' /></span>} onClick={backgroundChecksFieldHelper.handlePush({ ...emptyCheck, id: generatePassword() })} />
                                                        :
                                                        <div className="border-dashed bg-light-inverse rounded-lg border p-3 text-center text-muted"><Translate id='settings.crc.enableTip' /></div>
                                                    }
                                                </>
                                            )}
                                        </FieldArray>

                                        <CrossFade isVisible={!!formik.status} appear mountOnEnter unmountOnExit>
                                            {formik.status &&
                                                <CustomAlert className='mt-3' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                            }
                                        </CrossFade>
                                    </div>
                                </>
                            </Collapse>
                            <div className="text-right">
                                <Button color="primary" className="mx-2" type='submit' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                                <Button color="primary" outline onClick={toggleIsEditing} type='button' disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                            </div>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default CriminalRecordCheckForm;