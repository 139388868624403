import React from 'react';
import { Collapse, ModalBody } from 'reactstrap';
import Translate from '@spordle/intl-elements';
import UserDisplay from '../userDisplay/UserDisplay';
import { stringBuilder } from '@spordle/helpers';
import Svg from '../Svg';
import CustomAlert from '../CustomAlert';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import { DisplayI18n } from '../../helpers/i18nHelper';
import { CartsContext } from '../../contexts/CartsContext';
import withContexts from '../../helpers/withContexts';

class ManualRegistrationWaitingListReturn extends React.Component{

    state = {
        errorMessage: null,
    }

    componentDidMount(){
        this.props.CartsContext.clearCarts().catch(console.error);
    }

    onOptionClick = (manual) => {
        this.props.setModalLoading(true);
        this.props.onSubmit(manual)
            .then(() => {
                this.props.setModalLoading(false);
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    this.props.setModalLoading(false);
                    this.setState(() => ({ errorMessage: <DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} /> }))
                }
            })
    }

    render(){
        return (
            <>
                <ModalBody>
                    <div className="h4 font-bold mb-0"><Translate id='components.manualRegistration.waitingListReturn.title' /></div>
                    <div className='mb-3'>
                        <Translate
                            id='components.manualRegistration.waitingListReturn.text'
                            values={{
                                firstName: <b>{this.props.member.first_name}</b>,
                                lastName: <b>{this.props.member.last_name}</b>,
                            }}
                        />
                    </div>

                    <OptionsButton
                        icon="clinics"
                        title={<Translate id='components.manualRegistration.waitingListReturn.manual' />}
                        onClick={() => this.onOptionClick(true)}
                    >
                        <Translate id='components.manualRegistration.waitingListReturn.manual.desc' />
                    </OptionsButton>
                    {/* <OptionsButton
                        icon="members"
                        title={<Translate id='components.manualRegistration.waitingListReturn.identity' />}
                        onClick={() => this.onOptionClick(false)}
                    >
                        <Translate id='components.manualRegistration.waitingListReturn.identity.desc' />
                    </OptionsButton> */}

                    <Collapse isOpen={!!this.state.errorMessage} appear mountOnEnter unmountOnExit>
                        {this.state.errorMessage &&
                            <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={this.state.errorMessage} translateText={false} toggle={() => this.setState(() => ({ errorMessage: null }))} />
                        }
                    </Collapse>
                </ModalBody>
            </>
        )
    }
}

const OptionsButton = ({ icon, className, title, children, ...props }) => {
    return (
        <UserDisplay hover card className={stringBuilder('d-flex mb-2', className)} {...props}>
            <UserDisplay.Container className="mr-3">
                <div style={{ height: 45, width: 45 }} className="border bg-light rounded-circle d-flex align-items-center justify-content-center">
                    <Svg icon={icon || "members"} />
                </div>
            </UserDisplay.Container>
            <UserDisplay.Container>
                <div className="h5 mb-0 font-bold">{title}</div>
                {children}
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default withContexts(CartsContext)(ManualRegistrationWaitingListReturn)