import moment from "moment";
import { useContext } from "react";
import useSWR from "swr";
import { AxiosIsCancelled } from "../../api/CancellableAPI";
import { RolesContext } from "../../contexts/RolesContext";
import { IdentityRolesContext } from "../../contexts/IdentityRolesContext";
import { NewsContext } from "../../contexts/NewsContext";
import { USER_LIST_KEY } from "./newsHelpers";

export function useNewsSWR(canRead){
    const { organisation_id, role_id } = useContext(RolesContext);
    const idRoleContext = useContext(IdentityRolesContext);
    const { getNewsList } = useContext(NewsContext);

    const data = useSWR(
        canRead ? [ organisation_id, role_id, USER_LIST_KEY ] : null,
        () => {
            return getNewsList({
                identity_role_id: idRoleContext.identity_role_id,
                published_only: 1,
                active: 1,
            })
                .then(({ news }) => news.sort((a, b) => moment(b.published_at || b.created_at).diff(a.published_at || a.created_at, 'seconds')) || 0)
        },
        {
            fallbackData: [],
            revalidateOnFocus: false,
            onError: (e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                }

                return [];
            },
        },
    );

    return data;
}