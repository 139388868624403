import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { GApageView } from '@spordle/ga4';
import { stringBuilder } from '@spordle/helpers';

// Components
import Header from './layout-components/header/header';
import MobileNav from './layout-components/mobile/MobileNav';
import Sidebar from './layout-components/sidebar/sidebar';

// Routes
import routes from '../routes';

// Context Providers
import { AppContext } from '../contexts/contexts';
import { RolesContext } from '../contexts/RolesContext';
import { QuickViewContext } from '../contexts/QuickViewContext';
import withContexts from '../helpers/withContexts';

import { mobileView, smallDesktopView } from '../helpers/constants';
import setDefaultComponentProps from '../helpers/setDefaultComponentProps';
import NewNavigationTour from '../components/tour/newNavigation/NewNavigationTour';

import '../helpers/yupValidations';// Imports all custom Yup validations for the entire project NOTE: import here and not in index.js to preserve dynamic imports for Yup
import NotificationsDataManagerProvider from './layout-components/header/notifications/components/NotificationsDataManager';
import { findRouteExactMatch } from '../helpers/navigationHelper';
import { getBreadcrumbs } from './layout-components/breadcrumbs/Breadcrumbs';
import { injectIntl } from 'react-intl';
import QuickView from '../components/quickView/QuickView';
import QuickViewInnerWrapper from '../components/quickView/QuickViewInnerWrapper';
import QuickViewHistoryListener from '../components/quickView/QuickViewHistoryListener';
import TourManagerProvider from '../components/tour/TourManager';
class Fulllayout extends React.Component{

    constructor(props){
        super(props);
        const isSmall = window.innerWidth < mobileView;
        this.state = {
            isSmallDesktop: window.innerWidth < smallDesktopView,
            isMobile: isSmall || this.props.AppContext.isMobileDevice,
        };

        GApageView(this.props.history.location.pathname);
        this.historyListener = this.props.history.listen((location) => {
            GApageView(location.pathname);

            findRouteExactMatch(location.pathname, (route) => !route.skipRecents)
                .then((foundMatch) => {
                    if(foundMatch && !foundMatch.skipRecents && foundMatch.name && foundMatch.match.url !== "/reports"){
                        this.props.AppContext.updateRecentlyViewed({
                            label: foundMatch.name,
                            translate: !foundMatch.skipTranslate,
                            breadcrumbs: getBreadcrumbs(location),
                            path: foundMatch.match.url,
                        })
                    }
                });


            if(window.innerWidth < smallDesktopView){ // Closes sidebar in mobile view
                this.props.AppContext.setSidebarOpen(false);
            }
        });

        setDefaultComponentProps();
    }

    componentDidMount(){
        window.addEventListener('resize', this.onResize);
        this.onResize();
    }

    componentWillUnmount(){
        this.historyListener();
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        const isMobile = window.innerWidth < mobileView || this.props.AppContext.isMobileDevice;
        const isSmallDesktop = window.innerWidth < smallDesktopView && !isMobile;

        if(isSmallDesktop !== this.state.isSmallDesktop && !isMobile){
            if(this.state.isSmallDesktop){
                this.props.AppContext.setSidebarOpen(true);
            }else{
                this.props.AppContext.setSidebarOpen(false);
            }
        }

        this.setState({ isMobile: isMobile, isSmallDesktop: isSmallDesktop });
    }

    render(){
        /*--------------------------------------------------------------------------------*/
        /* Theme Setting && Layout Options will be Change From Here                       */
        /*--------------------------------------------------------------------------------*/
        const { routeKey, isMiniSidebar, isMobileDevice } = this.props.AppContext;

        return (
            <TourManagerProvider>
                <NotificationsDataManagerProvider>
                    <div
                        ref={(r) => this.mainWrapper = r}
                        id="main-wrapper"
                        dir="ltr"
                        data-theme="light"
                        data-layout="vertical"
                        data-sidebar-position="fixed"
                        data-header-position="fixed"
                        data-boxed-layout="full"
                        data-sidebartype={isMiniSidebar ? "mini-sidebar" : "full"}
                        className={stringBuilder({ 'mini-sidebar': isMiniSidebar }, "sid-main-wrapper")}
                    >
                        {(!isMobileDevice && !this.props.QuickViewContext.inIframe) &&
                            <>
                                <QuickView />
                                <NewNavigationTour />
                                <Header {...this.props} />
                                <Sidebar mainWrapper={this.mainWrapper} {...this.props} />
                            </>
                        }
                        {this.props.QuickViewContext.inIframe &&
                            <QuickViewHistoryListener />
                        }

                        {/*--------------------------------------------------------------------------------*/}
                        {/* Page Main-Content                                                              */}
                        {/*--------------------------------------------------------------------------------*/}
                        <QuickViewInnerWrapper>
                            <div className={stringBuilder("page-wrapper sid-page-wrapper", { "sid-mobile-page-wrapper": this.state.isMobile, "ml-0": this.props.QuickViewContext.inIframe })} id='page-wrapper'>
                                <RolesContext.Consumer>
                                    {({ validateRouteAccess }) => (
                                        <Suspense
                                            fallback={
                                                <div className="justify-content-center position-absolute top-0 bottom-0 right-0 left-0 d-flex align-items-center">
                                                    <Spinner color="primary" type="grow" />
                                                </div>
                                            }
                                        >
                                            <Switch>
                                                {routes.map((prop) => {
                                                    if(prop.navlabel || prop.divider || prop.noRender)
                                                        return null;

                                                    if(process.env.REACT_APP_ENVIRONMENT === 'prod' && prop.inProduction === false)
                                                        return <Redirect from={prop.path} to='/' key={prop.path} />;

                                                    const routeAccess = validateRouteAccess(prop);
                                                    if(!routeAccess){
                                                        return <Redirect from={prop.path} to='/' key={prop.path} exact={prop.exact} />
                                                    }

                                                    if(prop.bundleRoute){
                                                        return (
                                                            <Route
                                                                path={prop.path} key={prop.path + "-" + routeKey} render={(routeProps) => (
                                                                    <prop.bundleRoute routeProps={routeProps} {...prop} />
                                                                )}
                                                            />
                                                        )
                                                    }

                                                    if(prop.redirect){
                                                        return <Redirect from={prop.path} to={prop.pathTo} key={prop.path} exact={(prop.exact)} />;
                                                    }

                                                    return (
                                                        <Route
                                                            path={prop.path}
                                                            component={prop.component}
                                                            key={prop.path + "-" + routeKey}
                                                            exact={prop.exact}
                                                        />
                                                    );

                                                })}
                                            </Switch>
                                        </Suspense>
                                    )}
                                </RolesContext.Consumer>
                            </div>
                        </QuickViewInnerWrapper>
                        {(isMobileDevice && !this.props.QuickViewContext.inIframe) &&
                            <MobileNav {...this.props} />
                        }
                    </div>
                </NotificationsDataManagerProvider>
            </TourManagerProvider>
        );
    }
}

export default withContexts(AppContext, QuickViewContext)(injectIntl(Fulllayout));