import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import IsGod from '../../../../../../components/permissions/IsGod';
import Translate from '@spordle/intl-elements';
import { Formik, Form } from 'formik';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import CanDoAction from '../../../../../../components/permissions/CanDoAction';
import { FormikSelect } from '@spordle/formik-elements';
import { useContext } from 'react';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import { fail, success } from '@spordle/toasts';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import { useSpordleTable } from '@spordle/datatables';

const ContactSettingsForm = ({ isLoading, toggleIsEditing, activeTypes }) => {
    const organizationContext = useContext(OrganizationContext);
    const spordleTable = useSpordleTable()

    const initialValues = {
        activeTypes: activeTypes.filter((type) => type.mandatory_in_organisation == '1').map((type) => (type.organisation_contact_type_id)),
        staffOnlySystemMembers: organizationContext.settings.organisation_contact_must_be_member.value,
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                setSubmitting(true);
                const newMandatoryRoles = values.activeTypes.filter((contactTypeId) => (!initialValues.activeTypes.some((initCTypeId) => initCTypeId == contactTypeId)));
                const removedMandatoryRoles = initialValues.activeTypes.filter((contactTypeId) => (!values.activeTypes.some((initCTypeId) => initCTypeId == contactTypeId)));

                const shouldUpdateStaffOnlySystemMembers = initialValues.staffOnlySystemMembers !== values.staffOnlySystemMembers;
                const shouldremoveMandatoryRoles = removedMandatoryRoles.length > 0;
                const shouldAddMandatoryRoles = newMandatoryRoles.length > 0;

                // organisation_contact_must_be_member setting submission if changed
                if(shouldUpdateStaffOnlySystemMembers){
                    organizationContext.updateSettings(organizationContext.organisation_id, {
                        organisation_contact_must_be_member: values.staffOnlySystemMembers,
                    })
                        .then(async() => {
                            // this call re-gets the settings and updates them in state on success by itslef, therefore no need to reload anything
                            if(!shouldremoveMandatoryRoles && !shouldAddMandatoryRoles){
                                success();
                                setSubmitting(false);
                                toggleIsEditing();
                            }
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message);
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                            setSubmitting(false);
                        })
                }

                // newly mandatory roles submission
                if(shouldAddMandatoryRoles || shouldremoveMandatoryRoles){
                    Promise.all([
                        ...newMandatoryRoles.map((contactTypeId) => organizationContext.updateOrganizationContactType(contactTypeId, { mandatory_in_organisation: '1' })),
                        ...removedMandatoryRoles.map((contactTypeId) => organizationContext.updateOrganizationContactType(contactTypeId, { mandatory_in_organisation: '0' })),
                    ]).then(() => {
                        spordleTable.refreshTable()
                        success();
                        setSubmitting(false);
                        toggleIsEditing();
                    }).catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    })
                }

            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting || isLoading}>
                        <Row form>
                            <CanDoAction tag={Col} sm="6" className='mb-3 px-1' action='EDIT' componentCode='settings' componentPermissionCode='organization_contact_types'>
                                <Card className="card-shadow mb-0 h-100 p-1">
                                    <CardHeader className="bg-white">
                                        <div className="h5 font-bold mb-1"><Translate id="organization.settings.contactStaffSettings.table.column.mandatory_in_organisation.plural" /></div>
                                        <div className="small font-medium mb-0"><Translate id="organization.settings.contactStaffSettings.table.column.mandatory_in_organisation.desc" /></div>
                                    </CardHeader>
                                    <CardBody>
                                        {(activeTypes && activeTypes.length > 0) &&
                                            <FormikSelect
                                                name='activeTypes'
                                                id='activeTypes'
                                                multi clearable
                                                loadingStatus='success'
                                                renderOption={(option) => (<DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />)}
                                                options={activeTypes.map((type) => ({
                                                    value: type.organisation_contact_type_id,
                                                    i18n: type.i18n,
                                                    label: type.name,
                                                }))}
                                            />
                                        }
                                    </CardBody>
                                </Card>
                            </CanDoAction>

                            {/* check if current org can_manage */}
                            {organizationContext.settings.organisation_contact_must_be_member.can_manage == 1 &&
                                <Col sm="6" className='mb-3 px-1'>
                                    <Card className="card-shadow mb-0 h-100 p-1">
                                        <CardHeader className="bg-white">
                                            <div className="h5 font-bold mb-1"><DisplayI18n field='name' i18n={organizationContext.settings.organisation_contact_must_be_member?.i18n} defaultValue={organizationContext.settings.organisation_contact_must_be_member?.name} /></div>
                                            <div className="small font-medium mb-0"><Translate id="organization.settings.contactStaffSettings.table.column.contactMustBeMember.desc" /></div>
                                            <IsGod><div className="small mt-1 font-medium text-purple">setting: organisation_contact_must_be_member</div></IsGod>
                                        </CardHeader>
                                        <CardBody>
                                            <FormikSelect
                                                name='staffOnlySystemMembers'
                                                id='staffOnlySystemMembers'
                                                loadingStatus='success'
                                                defaultData={[
                                                    {
                                                        value: '1',
                                                        label: 'misc.yes',
                                                    },
                                                    {
                                                        value: '0',
                                                        label: 'misc.no',
                                                    },
                                                ]}
                                                dataIndex="document_type_id"
                                                renderOption={({ option }) => { return (<Translate id={option.label} />) }}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            }
                        </Row>

                        <div className="text-right">
                            <Button color="primary" className="mx-2" type='submit' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                            <Button color="primary" outline onClick={toggleIsEditing} type='button' disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                        </div>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default ContactSettingsForm;