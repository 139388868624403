import PropTypes from 'prop-types';
import { stringBuilder } from "@spordle/helpers";

/**
* @param {React.ComponentType} [tag='div'] The desired htmlElement of the container.
* @param {string} [className]
*/
const ProfileHeaderInfo = ({
    tag: Tag,
    className,
    ...attributes
}) => {

    return (
        <Tag {...attributes} className={stringBuilder('profileHeader-info-container', className)} />
    );
};

export default ProfileHeaderInfo;

ProfileHeaderInfo.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
};
ProfileHeaderInfo.defaultProps = {
    tag: 'div',
};