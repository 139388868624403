import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from "@spordle/datatables";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import { Button, Card, CardBody, Spinner, Table } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import CardSectionTitle from "../../../../../../components/CardSectionTitle";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";
import SpordlePanelTable from "../../../../../../components/sidePanel/SpordlePanel";
import { fire, removeToast, updateToast } from "@spordle/toasts";
import { triggerDownload } from "../../../../../../components/uploader/uploadHelpers";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../../../../contexts/IdentityRolesContext";
import { InjuriesContext } from "../../../../../../contexts/InjuriesContext";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { RolesContext } from "../../../../../../contexts/RolesContext";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { InjuryStatus } from "../../../../../tasks/insurance/components/InsuranceHelper";
import InsuranceAddModal from "../../../../../tasks/insurance/components/modal/InsuranceAddModal";
import InsuranceSidePanel from "../../../../../tasks/insurance/components/sidePanel/InsuranceSidePanel";
import { useInsuranceContent } from "../../../../../tasks/insurance/components/useInsuranceContent";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { DocumentTypesContext } from "../../../../../../contexts/DocumentTypesContext";
import { array } from "yup";
import { stringBuilder } from "@spordle/helpers";

const MemberInsurance = ({ godAccess }) => {
    const injuryContext = useContext(InjuriesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { currentMember } = useContext(MembersContext);
    const [ isOpen, setIsOpen ] = useState(false);
    const injuryDataLists = useInsuranceContent();
    const { canDoAction, hasAccessTo } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const canEdit = canDoAction("EDIT", "members", "members_injuries") || isGod();
    const canPrint = canDoAction("EXPORT", "members", "member_checks_generation") || isGod();
    const canReadCheques = canDoAction("READ", "members", "member_checks_generation") || isGod();
    const { getOrganizationSettings } = useContext(OrganizationContext);
    const { federation } = useContext(IdentityRolesContext);
    const { getDocumentTypes } = useContext(DocumentTypesContext);

    const [ docInfo, setDocInfo ] = useState({
        initVal: {},
        validationSchema: {},
        requiredTypes: [],
    });


    const tableColumns = [
        {
            label: 'Season',
            key: 'period',
            sortable: true,
        },
        {
            label: '#',
            key: 'injury_case_number',
            sortable: true,
        },
        {
            label: <Translate id='insurance.label.branch' />,
            key: 'branch',
            sortKey: `branch.${getGenericLocale()}.name`,
            fallbackSortKey: 'branch.organisation_name',
            sortable: true,
        },
        {
            label: <Translate id='insurance.label.injury' />,
            key: 'injury',
            sortable: true,
        },
        {
            label: <Translate id='insurance.label.reserve' />,
            key: 'reserve',
            sortable: false,
        },
        {
            label: 'Date',
            key: 'date',
            sortable: false,
        },
        {
            label: <Translate id="misc.status" />,
            key: 'status',
            sortable: false,
        },
    ]

    /**
     * @param {() => Promise<string>} promise
     * @param {string} id
     */
    const printItems = (promise, id) => {
        fire({
            id: id,
            icon: <Spinner color="primary" size="sm" />,
            msg: 'insurance.sidePanel.cheque.download.msg',
            info: 'insurance.sidePanel.cheque.download.info',
            permanent: true,
        });

        return promise()
            .then((path) => {
                triggerDownload(path);
                removeToast(id);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    updateToast({
                        id: id,
                        icon: false,
                        msg: 'misc.error',
                        info:
                        <DisplayI18n
                            field="message"
                            i18n={error.i18n}
                            defaultValue={error.message}
                        />,
                        skipInfoTranslate: true,
                        permanent: false,
                        theme: 'danger',
                    });
                }
            });
    }

    const getDocumentInfos = async() => {
        const docTypes = await getDocumentTypes(federation.organisation_id).catch(() => []);
        const settings = await getOrganizationSettings(federation.organisation_id).catch(() => []);
        const insuranceDocs = settings.document_type_list_insurance;

        if(insuranceDocs){
            const newDocInfo = insuranceDocs.value.reduce((docs, docId) => {
                const settingDoc = docTypes.find((dT) => dT.document_type_id === docId);

                if(settingDoc){
                    docs.requiredTypes.push(settingDoc);
                    docs.initVal[settingDoc.document_type_id] = [];
                    docs.validationSchema[settingDoc.document_type_id] = array();
                }

                return docs;
            }, {
                initVal: {},
                validationSchema: {},
                requiredTypes: [],
            });

            setDocInfo(newDocInfo);
        }

    }

    useEffect(() => {
        getDocumentInfos();
    }, []);

    /**
     * @description Function used to print one or multiple cheques
     * @param {object} params
     */
    const printCheques = (params) => {
        printItems(() => {
            return injuryContext.printInsuranceCheques(params)
        }, 'printChequeToast');
    }

    /**
     * @description Function used to print cheques worksheet
     * @param {object} params
     */
    const printWorksheet = (params) => {
        return printItems(() => {
            return injuryContext.printInsuranceWorksheet(params)
        }, 'printWorksheetToast')
    }

    return (
        <Card id="memberInsuranceCard" className="card-shadow">
            <CardBody>
                <CardSectionTitle title='insurance.title' titleClassname={godAccess ? "text-purple" : undefined} />
                <SpordlePanelTable
                    dataIndex='injury_id'
                    allowOutsideClick
                    sidePanel={(props) => (
                        <InsuranceSidePanel
                            docInfo={docInfo}
                            canReadCheques={canReadCheques}
                            printWorksheet={printWorksheet}
                            canPrint={canPrint}
                            printCheques={printCheques}
                            canEdit={canEdit}
                            injuryDataLists={injuryDataLists}
                            {...props}
                        />
                    )}
                    table={(panelProps) => (
                        <SpordleTableProvider
                            id='TasksInsuranceTable'
                            tableHover bordered striped
                            pagination={5}
                            clickable
                            ref={panelProps.spordleTableRef}
                            dataIndex='injury_id'
                            desktopWhen
                            searchKeys={[
                                { name: 'injury_case_number', weight: 2 },
                                'period.name',
                                'period.i18n.name',
                                `branch.${getGenericLocale()}.name`,
                                'branch.organisation_name',
                            ]}
                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                            loadData={(from, _data, spordleTable) => {
                                switch (from){
                                    case 'REFRESH':
                                        spordleTable.setLoading()
                                    case 'CDM':
                                        return injuryContext.getInjuryList({ all: 1, member_id: currentMember.member_id });
                                    default:
                                        break;
                                }
                            }}
                            columns={hasAccessTo('members', 'member_insurance_sensitive_data') ? tableColumns : tableColumns.filter((c) => c.key !== 'status' && c.key !== 'reserve')}
                            renderRow={(columnKey, injury) => {
                                switch (columnKey){
                                    case 'period':
                                        return (
                                            <span className="text-nowrap">
                                                <DisplayI18n
                                                    field="name"
                                                    i18n={injury.period?.i18n}
                                                    defaultValue={injury.period?.name}
                                                />
                                            </span>
                                        )
                                    case 'branch':
                                        return (
                                            <DisplayI18n
                                                field="name"
                                                i18n={injury.branch.i18n}
                                                defaultValue={injury.branch.organisation_name}
                                            />
                                        )
                                    case 'injury':
                                        return (
                                            <DisplayI18n
                                                field="name"
                                                defaultValue={injury.body_part?.name}
                                                i18n={injury.body_part?.i18n}
                                            />
                                        )
                                    case 'reserve':
                                        return <i className={`mdi text-${injury.reserve == 1 ? "primary mdi-check" : "danger mdi-close"}`} />;
                                    case 'date':
                                        return (
                                            <div>
                                                <div className="text-muted small">
                                                    <Translate id="insurance.label.accidentDate" />
                                                </div>
                                                <div className="text-nowrap font-medium mb-2">
                                                    <DateFormat value={injury.accident_date} utc />
                                                </div>
                                                <div className="text-muted small">
                                                    <Translate id="insurance.label.deliveredDate" />
                                                </div>
                                                <div className="text-nowrap font-medium">
                                                    {injury.delivered_date ?
                                                        <DateFormat value={injury.delivered_date} utc />
                                                        :
                                                        '-'
                                                    }
                                                </div>
                                            </div>
                                        )
                                    case 'status':
                                        return <InjuryStatus injuryStatus={injury.injury_status} />
                                    default:
                                        break;
                                }
                            }}
                            onColumnClick={(e, data) => {
                                switch (e.button){
                                    case 0: // Left mouse button
                                        panelProps.onSingleSelectChange(data);
                                        break;
                                }
                            }}
                            rowIsHighlighted={(data) => data.checked}
                            emptyLayout={
                                <Table bordered striped hover>
                                    <tbody>
                                        <tr>
                                            <td className='text-center'>
                                                {(canDoAction('ADD', 'members', 'members_injuries') || isGod()) ?
                                                    <button className={stringBuilder('font-medium reset-btn text-primary', { 'text-purple': !canDoAction('ADD', 'members', 'members_injuries') && isGod() })} type='button' onClick={() => setIsOpen(!isOpen)}><i className='mdi mdi-plus' /><Translate id='misc.add' /> <Translate id="insurance.title" /></button>
                                                    :
                                                    <Translate id="misc.search.empty.title" />
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            }
                        >
                            <div className='mb-2'>
                                <div className='d-flex flex-wrap justify-content-between'>
                                    <SearchInput />
                                    <div className='d-flex ml-auto text-right'>
                                        <Refresh />
                                        <CanDoAction componentCode="members" componentPermissionCode="members_injuries" action="ADD">
                                            <Button type="button" className='ml-2' color='primary' onClick={() => setIsOpen(!isOpen)}>
                                                <i className='ti-plus' /> <Translate id='misc.add' />
                                            </Button>
                                            <InsuranceAddModal
                                                docInfo={docInfo}
                                                fromMemberView
                                                injuryDataLists={injuryDataLists}
                                                isOpen={isOpen}
                                                toggle={() => setIsOpen(false)}
                                            />
                                        </CanDoAction>
                                    </div>
                                </div>
                            </div>
                            <SpordleTableView />
                        </SpordleTableProvider>
                    )}
                />
            </CardBody>
        </Card>
    )
}

export default MemberInsurance;