import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Button, ModalBody } from "reactstrap";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import CollapsibleCard from "../../../../collapsibleCard/CollapsibleCard";
import { SendCommModalContext } from "../../../context/SendCommModalContext";
import EmailIcon from "../../emailIcon/EmailIcon";

const SuccessView = ({ isVisible }) => {
    const { toggle, confirmInfo } = useContext(SendCommModalContext);

    return (
        <ModalBody className="text-center">
            <div className="p-3">
                {isVisible && <EmailIcon color="dark" />}
                <div className="h4 font-bold"><Translate id='components.communications.modal.confirm.title' /></div>
                {(confirmInfo.success.length + confirmInfo.failed.length === 1) ?
                    confirmInfo.success.length == 1 ?
                        <Translate id='components.communications.modal.confirm.singleEmail.success' values={{ email: <span className='d-block text-dark font-medium'>{confirmInfo.success[0]}</span> }} />
                        :
                        <>
                            <Translate id='components.communications.modal.confirm.singleEmail.fail' values={{ email: <span className='d-block text-dark font-medium'>{confirmInfo.failed[0].email}</span> }} />
                            {confirmInfo.failed[0].warning && <small className="text-muted"><i className="mdi mdi-alert-outline text-danger mr-1" /><DisplayI18n field="message" i18n={confirmInfo.failed[0].warning.i18n} defaultValue={confirmInfo.failed[0].warning.message} /></small>}
                        </>
                    :
                    <>
                        <p><Translate id='components.communications.modal.confirm.multiEmails.msg' /></p>
                        {confirmInfo.success.length > 0 &&
                            <CollapsibleCard
                                initOpen={confirmInfo.success.length <= 10}
                                className="mb-3"
                                title={<span className="text-success"><Translate id="misc.success" /> <i className="mdi mdi-check" /></span>}
                                subtitle={<Translate id='components.communications.modal.confirm.multiEmails.success' values={{ count: confirmInfo.success.length, b: (chunks) => <b>{chunks}</b> }} />}
                            >
                                {confirmInfo.success.map((email) => (
                                    <div className="text-left mb-2" key={email}>
                                        {email}
                                    </div>
                                ))}
                            </CollapsibleCard>
                        }
                        {confirmInfo.failed.length > 0 &&
                            <CollapsibleCard
                                title={<span className="text-danger"><Translate id="misc.error" /> <i className="mdi mdi-close" /></span>}
                                subtitle={<Translate id='components.communications.modal.confirm.multiEmails.fail' values={{ count: confirmInfo.failed.length, b: (chunks) => <b>{chunks}</b> }} />}
                            >
                                {confirmInfo.failed.map((fail) => (
                                    <div className="text-left mb-2" key={fail.email}>
                                        {fail.email}
                                        {fail.warning &&
                                            <div className="small text-danger">
                                                <i className="mdi mdi-alert-outline mr-1" /><DisplayI18n field="message" i18n={fail.warning.i18n} defaultValue={fail.warning.message} />
                                            </div>
                                        }
                                    </div>
                                ))}
                            </CollapsibleCard>
                        }
                    </>
                }
                <div>
                    <Button onClick={toggle} style={{ minWidth: 200 }} className="mt-4" type="button" color="primary">
                        <Translate id='components.communications.modal.confirm.btn' />
                    </Button>
                </div>
            </div>
        </ModalBody>
    );
}

export default SuccessView;