import { stringBuilder } from '@spordle/helpers';
import { useContext, useEffect } from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import useSWR from 'swr';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { ClinicsContext } from '../../../../contexts/ClinicsContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { fail } from "@spordle/toasts";
import Translate from '@spordle/intl-elements';
import { Tooltip } from '@mantine/core';

const ClinicHeaderStatus = ({ updateCurrentVisibility, selectedClinicState, setSelectedClinicState }) => {
    const clinicsContext = useContext(ClinicsContext);
    const organizationContext = useContext(OrganizationContext);

    const { data: clinicStates, isValidating } = useSWR(
        [ 'ClinicProfile_HeaderStatus', organizationContext.organisation_id ],
        () => clinicsContext.getClinicStates().catch((error) => {
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message)
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
            }
        }),
    );

    useEffect(() => {
        setSelectedClinicState(clinicStates?.find((clinic) => clinic.clinic_state_id === clinicsContext.currentClinic?.active_clinic_state_id))
    }, [ clinicsContext.currentClinic?.active_clinic_state_id, isValidating ])

    return (
        <Tooltip withArrow label={<Translate id='clinics.profile.clinicProfile.tooltip.status' />}>
            <DropdownToggle inNavbar caret className="mr-2" color="light" outline>
                {selectedClinicState && <DisplayI18n field='name' defaultValue={selectedClinicState?.name} i18n={selectedClinicState?.i18n} />}
            </DropdownToggle>
            <DropdownMenu right>
                <>
                    {clinicStates?.map((state) => (
                        <DropdownItem
                            id={'clinic-header-status-dropdown' + state.clinic_state_id}
                            key={state.clinic_state_id}
                            className={stringBuilder({ 'text-primary': clinicsContext.currentClinic?.active_clinic_state_id === state.clinic_state_id })}
                            onClick={() => { updateCurrentVisibility(state.state_code, state.clinic_state_id, { name: state?.name, i18n: state?.i18n }) }}
                        >
                            {clinicsContext.currentClinic?.active_clinic_state_id === state.clinic_state_id && <i className="mr-1 mdi mdi-check" />}
                            <DisplayI18n field='name' defaultValue={state.name} i18n={state.i18n} />
                        </DropdownItem>
                    ))}
                </>
            </DropdownMenu>
        </Tooltip>
    )
}

export default ClinicHeaderStatus
