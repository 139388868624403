import { Form, Formik } from "formik";
import { isFn } from "../../helpers/helper";
import CrossFade from "../crossFade/CrossFade";
import OverlayLoader from "../loading/OverlayLoader";
import { useRef } from "react";
import ApiErrorMsg from "./ApiErrorMsg";
import ModalSuccess from "./ModalSuccess";

/**
 * @typedef {function(import("formik").FormikProps): React.ReactNode} formikChildFn
 * @callback formikChildFn
 * @param {import("formik").FormikProps}
 * @returns {React.ReactNode}
 */

/**
 * @description This component contains repetitive tags used in a LOT of modals.
 * @example
 * <FormikModalForm
 *      toggle={toggle}
 *      successText={<p>Successfully added the ressource :) !</p>}
 *      onSubmit={(values, { setStatus }) => {
 *          return asyncCall()
 *              .then(() => {
 *                  setStatus({
 *                      isSuccess: true,
 *                      showError: false,
 *                      error: null
 *                  })
 *              })
 *              .catch((e) => {
 *                  console.error(e);
 *                  setStatus({
 *                      isSuccess: false,
 *                      showError: true,
 *                      error: e,
 *                  })
 *              })
 *      }}
 * >
 *  {(formik) => (
 *      <>
 *          {" ... "}
 *          <ModalFooter>
 *              <Button disabled={formik.isSubmitting || !formik.status.isSuccess} type="submit">
 *                  Submit
 *              </Button>
 *              <Button disabled={formik.isSubmitting || !formik.status.isSuccess} onClick={toggle} type="button">
 *                  Close
 *              </Button>
 *          </ModalFooter>
 *      </>
 *  )}
 * </FormikModalForm>
 *
 * @param {import('formik').FormikConfig>} props
 * @param {boolean} [props.isLoading] Useful if loading state is managed outside formik.isSubmitting
 * @param {function} props.toggle
 * @param {React.ReactNode | formikChildFn} [props.successText]
 * @param {React.ReactNode | formikChildFn} props.children
 */
const FormikModalForm = ({ children, isLoading = false, toggle, successText, ...props }) => {
    const formikRef = useRef();

    // This function injects a showSuccess and a showError helper functions.
    // These functions prevent repetitive code.
    const handleOnSubmit = (values, formikBag) => {
        return props.onSubmit(values, {
            ...formikBag,
            showSuccess: (extra) => {
                formikBag.setStatus({
                    ...(extra || {}),
                    ...(formikRef.current?.status || {}),
                    isSuccess: true,
                    showError: false,
                });
            },
            showError: (error, extra) => {
                formikBag.setStatus({
                    ...(extra || {}),
                    ...(formikRef.current?.status || {}),
                    isSuccess: false,
                    showError: true,
                    error: Array.isArray(error) ? error : <ApiErrorMsg error={error} />,
                });
            },
        })
    }

    return (
        <Formik
            innerRef={formikRef}
            initialStatus={{
                showError: false,
                error: null,
                isSuccess: false,
            }}
            initialValues={{}}
            {...props}
            onSubmit={handleOnSubmit}
        >
            {(formik) => (
                <OverlayLoader isLoading={formik.isSubmitting || isLoading}>
                    <CrossFade isVisible={!formik.status.isSuccess}>
                        <Form>
                            {isFn(children) ? children(formik) : children}
                        </Form>
                    </CrossFade>
                    <CrossFade isVisible={formik.status.isSuccess}>
                        <ModalSuccess
                            toggle={() => toggle(formik.status.isSuccess)}
                        >
                            {isFn(successText) ? successText(formik) : successText}
                        </ModalSuccess>
                    </CrossFade>
                </OverlayLoader>
            )}
        </Formik>
    );
}

export default FormikModalForm;