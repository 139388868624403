import SubRouteRenderer from '../../components/subSidebar/SubRouteRenderer';
import reportsRoutes from '../../routes/routerRoutes/reportsRoutes';
import ReportsBreadcrumbs from '../../components/reports/ReportsBreadcrumbs';

const Reports = () => {
    return (
        <div className='px-4 pt-3 page-content'>
            <ReportsBreadcrumbs />
            <SubRouteRenderer routes={reportsRoutes} />
        </div>
    );
}

export default Reports;