import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';

export const RegistrationDivisionsContext = createContext();
RegistrationDivisionsContext.displayName = 'RegistrationDivisionsContext';

class RegistrationDivisionsContextProvider extends React.Component{

    /**
     * Get all the Divisions within an organization
     * @param {string} organizationId The organization id we want to get the Divisions from
     * @returns {Promise<Array>}
     */
    getRegistrationDivisions = (organizationId, params) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `divisions`, query: { organisation_id: organizationId, ...params } }))
            .then((response) => {
                if(response.data.status){
                    return response.data.divisions;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
     * Updates a specified Division with new values
     * @param {string} divisionId ID of the Division to update
     * @param {object} values Object containing the values to update - The keys to the values need to be the same as the API ones
     * @param {string} [lang] Language variable corresponding to the values - Used for the I18N logic
     * @param {object} [i18n] Object containing an array of all the languages available and an object of the initialValues for language variables - Used for the I18N logic
     * @returns {Promise}
     */
    updateRegistrationDivision = (divisionId, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                    // case 'name':
                    //     appendI18nForUpdate(params, 'name', lang, values, i18n)
                    //     break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `divisions/${divisionId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PUT] Updates a specified Division with new values
     * @param {string} divisionId ID of the division to update
     * @param {object} values Current order of the current division
     * @returns {Promise}
     */
    updateRegistrationParentDivision = (divisionId, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `divisions/${divisionId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a Division under an Organization with specified values
     * @param {string} organizationId ID of the Organization to add a Division to
     * @param {object} values Values to create a Division with
     * @param {object} [i18n] I18n object containing the languages array
     * @returns {Promise}
     */
    createRegistrationDivision = (organizationId, values, i18n) => {
        const params = new URLSearchParams();

        // appendI18nForCreate(params, 'name', values, i18n)

        params.append('organisation_id', organizationId);
        params.append('active', values.active ? 1 : 0);

        for(const key in values){
            switch (key){
                default: // name, descritpion, etc
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `divisions` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.division_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a specific Division
     * @param {string} divisionId ID of the Division to delete
     * @returns {Promise}
     */
    deleteRegistrationDivision = (divisionId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `divisions/${divisionId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <RegistrationDivisionsContext.Provider value={{
                getRegistrationDivisions: this.getRegistrationDivisions,
                updateRegistrationDivision: this.updateRegistrationDivision,
                updateRegistrationParentDivision: this.updateRegistrationParentDivision,
                createRegistrationDivision: this.createRegistrationDivision,
                deleteRegistrationDivision: this.deleteRegistrationDivision,
            }}
            >
                {this.props.children}
            </RegistrationDivisionsContext.Provider>
        )
    }
}

export default RegistrationDivisionsContextProvider