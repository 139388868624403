import { FormikTextArea } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { useFormikContext } from 'formik';
import { Button, Collapse, FormGroup, Label, ModalBody, ModalFooter } from 'reactstrap';
import CustomAlert from '../../../../../../components/CustomAlert';
import Required from '../../../../../../components/formik/Required';
import UserDisplay from '../../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../../components/UserImg';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';

const CancelItemReason = ({ invoiceItems, toggle, setActiveStep, steps, ...props }) => {
    const formik = useFormikContext();

    return (
        <>
            <ModalBody>
                <FormGroup>
                    <Translate id='memberProfile.registration.cancelRegistration.items' values={{ items: formik.values.items.length }} />
                    {formik.values.items.map((itemId) => {
                        const item = invoiceItems.find((item) => item.invoice_item_id === itemId);
                        const fee = item.registration_fee?.fee || item.other_fee?.fee;

                        return (
                            <UserDisplay key={itemId} card className='p-2 w-100 mb-2'>
                                <UserDisplay.Container>
                                    <UserImg
                                        abbr={item.member.first_name.substring(0, 1) + item.member.last_name.substring(0, 1)}
                                        src={item.member.picture?.full_path}
                                        filePos={item.member.picture?.file_position}
                                        width={40}
                                        alt={item.member.first_name + ' ' + item.member.last_name}
                                    />
                                </UserDisplay.Container>
                                <UserDisplay.Container>
                                    <UserDisplay.Title>{item.member.first_name + ' ' + item.member.last_name}</UserDisplay.Title>
                                    <UserDisplay.Subtitle className='text-dark'><DisplayI18n field='name' defaultValue={fee.name} i18n={fee.i18n} /></UserDisplay.Subtitle>
                                    <UserDisplay.Subtitle>#{item.member.unique_identifier}</UserDisplay.Subtitle>
                                </UserDisplay.Container>
                            </UserDisplay>
                        )
                    })}
                </FormGroup>

                <FormGroup>
                    <Label for='reason' className='text-muted'><Translate id='memberProfile.registration.cancelRegistration.reason' /> <Required /></Label>
                    <FormikTextArea id='reason' name='reason' trim />
                </FormGroup>

                <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                    <CustomAlert className='mt-3' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                </Collapse>

            </ModalBody>
            <ModalFooter>
                <Button color='primary' outline className='mr-auto' onClick={() => setActiveStep(steps.cancelItemSelection)}><Translate id='misc.previous' /></Button>

                <div>
                    <Button color='primary' type='submit' disabled={formik.isSubmitting}>
                        <Translate id='memberProfile.registration.cancelRegistration.submit' />
                    </Button>
                    <Button color='primary' type='button' onClick={toggle} outline className="ml-2" disabled={formik.isSubmitting}>
                        <Translate id='misc.cancel' />
                    </Button>
                </div>
            </ModalFooter>
        </>
    )
}

export default CancelItemReason