import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';

export const PrerequisitesContext = createContext();
PrerequisitesContext.displayName = 'PrerequisitesContext';

class PrerequisitesContextProvider extends React.PureComponent{

    /**
     * Get a list of all the Prerequisites
     */
    getPrerequisites = (organizationId, qualificationId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/prerequisites`, query: { 'organisation_id': organizationId, 'qualification_id': qualificationId } }))
            .then((response) => {
                if(response.data.status){
                    return response.data.prerequisites;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <PrerequisitesContext.Provider value={{
                getPrerequisites: this.getPrerequisites,
            }}
            >
                {this.props.children}
            </PrerequisitesContext.Provider>
        );
    }
}

export default PrerequisitesContextProvider;
