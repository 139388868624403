import { Button, ModalBody } from "reactstrap";
import CustomAnimatedIcon from "../customAnimatedIcon/CustomAnimatedIcon";
import Translate from "@spordle/intl-elements";

const ModalSuccess = ({ children, toggle }) => {
    return (
        <ModalBody className="text-center">
            <CustomAnimatedIcon
                className="text-success"
                icon="checkmark"
                withCircle
                size="60"
                strokeWidth="8px"
            />
            <div className="h4 font-bold mt-2"><Translate id="misc.success" />!</div>
            {children}
            <Button color="primary" type="button" onClick={toggle}><Translate id="misc.close" /> <i className="mdi mdi-check" /></Button>
        </ModalBody>
    );
}

export default ModalSuccess;