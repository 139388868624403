import { Tooltip } from '@mantine/core';
import { FormikSelect } from '@spordle/formik-elements';
import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { useContext } from 'react';
import { Card } from 'reactstrap';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import ClinicCategory from '../../../ClinicCategory';

const EditablePrerequisitesSetCard = ({ title, restriction, handleRemove, index, qualificationsDisabled, qualifications, ...props }) => {

    const i18nContext = useContext(I18nContext);

    return (
        <Card className='border card-shadow mb-1'>
            <div className='px-3 py-2'>
                <div className='d-flex justify-content-between align-items-center'>
                    {/* title */}
                    <span className='font-bold text-dark mr-5'>{title}</span>

                    <div className='d-flex flex-grow-1 justify-content-end align-items-center ml-auto'>
                        {/* qualifications */}
                        <div style={{ minWidth: 300 }} className='mx-auto'>
                            <FormikSelect
                                className='w-100' search
                                id={`prerequisites.${index}.qualifications`}
                                name={`prerequisites.${index}.qualifications`}
                                multi disabled={qualificationsDisabled}
                                options={qualifications}
                                searchKeys={[
                                    'label',
                                    `category.i18n.${i18nContext.getGenericLocale()}.name`,
                                    'category.name',
                                    `i18nQualification.${i18nContext.getGenericLocale()}.name`,
                                    'qualificationName',
                                    `i18nLevel.${i18nContext.getGenericLocale()}.name`,
                                    'levelName',
                                ]}
                                textWhenSetting={{
                                    count: 1,
                                    label: 'clinics.clinicInformation.formClinicInformation.clinicInfo.qualPrerequisites.selected',
                                }}
                                renderOption={(option) => {
                                    const data = option.option;
                                    if(data.levelName){
                                        return (
                                            <div className='align-items-center d-flex'>
                                                <ClinicCategory color={data.category.color}>
                                                    <DisplayI18n field='name' defaultValue={data.category?.name} i18n={data.category?.i18n} /> - <DisplayI18n field='name' defaultValue={data.qualificationName} i18n={data.i18nQualification} /> - <DisplayI18n field='name' defaultValue={data.levelName} i18n={data.i18nLevel} />
                                                </ClinicCategory>
                                            </div>
                                        )
                                    }
                                    return (
                                        <div className='align-items-center d-flex'>
                                            <ClinicCategory color={data.category.color}>
                                                <DisplayI18n field='name' defaultValue={data.category?.name} i18n={data.category?.i18n} /> - <DisplayI18n field='name' defaultValue={data.label} i18n={data.i18nQualification} />
                                            </ClinicCategory>
                                        </div>
                                    )

                                }}
                            />
                        </div>

                        {/* mandatory */}
                        <div className='mr-2'>
                            <Tooltip withArrow label={<Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites.hint' />}>
                                <i className='text-primary mdi mdi-information-outline font-16' />
                            </Tooltip>
                        </div>
                        <div style={{ minWidth: '250px' }}>
                            <FormikSelect
                                id={`prerequisites.${index}.restriction`} name={`prerequisites.${index}.restriction`} loadingStatus='success'
                                defaultData={[
                                    { value: 'all', label: 'clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites.select.all' },
                                    { value: 'one', label: 'clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites.select.one' },
                                ]}
                                renderOption={(option) => <Translate id={option.option.label} />}
                            />
                        </div>

                        {/* remove */}
                        <button className={stringBuilder('reset-btn text-danger ml-3')} onClick={handleRemove(index)} type='button'><i className='fas fa-trash' /></button>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default EditablePrerequisitesSetCard