import { useContext, useEffect, useState } from "react";
import {
    Row,
    Col
} from "reactstrap";
import FormikEditable from '../../../components/formik/FormikEditable';
import { addExtension, DisplayPhoneNumber, FormikAddress, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect, getFormikAddressInitialValues } from '@spordle/formik-elements';
import { string, object, number } from 'yup';
import { success, fail } from '@spordle/toasts';
import { DisplayI18n } from '../../../helpers/i18nHelper';

// Context
import { OrganizationContext } from '../../../contexts/OrganizationContext';

// Language
import Translate from "@spordle/intl-elements";
import OverlayLoader from "../../../components/loading/OverlayLoader";
import SidePanel from "../../../components/sidePanel/SidePanel";
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import { I18nContext } from "../../../contexts/I18nContext";
import { getLocation } from "../../teams/TeamHelpers";
import { RolesContext } from "../../../contexts/RolesContext";

const PayeesSidepanel = (props) => {

    const [ isLoading, setIsLoading ] = useState(true);
    const [ serviceTypes, setServiceTypes ] = useState([]);
    const orgContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext);
    const rolesContext = useContext(RolesContext);

    const editDisabled = (props.getCommonValue('organisation').organisation_id !== orgContext.organisation_id || !rolesContext.canDoAction('EDIT', 'organization', 'payee_management'));

    useEffect(() => {
        orgContext.getServiceTypes()
            .then((types) => {
                setServiceTypes(types.sort((aT, bT) => aT.display_order - bT.display_order).map((type) => ({
                    value: type.service_type_id,
                    label: type.name,
                    i18n: type.i18n,
                })))
            })
            .then(() => {
                setIsLoading(false);
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e.message);
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, [])

    const getFullAddress = () => {
        return getLocation({
            street_number: props.getCommonValue('street_number') || '',
            street: props.getCommonValue('street') || '',
            city: props.getCommonValue('city') || '',
            zip_code: props.getCommonValue('zip_code') || '',
            province_code: props.getCommonValue('province_code') || '',
            country_code: props.getCommonValue('country_code') || '',
        })
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <SidePanel.Header>
                <div className='d-flex mb-2 align-items-center'>
                    <SidePanel.ToggleButton />
                    <SidePanel.ActionsMenu action='DELETE' componentCode='organization' componentPermissionCode='payee_management'>
                        <SidePanel.MenuDelete
                            disabled={editDisabled}
                            modalMsg={<Translate id='settings.payees.sidepanel.delete.text' values={{ name: `${props.getCommonValue('first_name')} ${props.getCommonValue('last_name')}` }} />}
                            onConfirm={() => {
                                return orgContext.deletePayee(props.getCommonValue('payee_id'))
                                    .then(() => {
                                        props.tableRef.deleteRow(props.getCommonValue('payee_id')).then(() => {
                                            props.forceCloseSidePanel();
                                        })
                                    })
                                    .catch((e) => {
                                        if(!AxiosIsCancelled(e.message)){
                                            console.error(e);
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })
                            }}
                        />
                    </SidePanel.ActionsMenu>
                </div>
                <SidePanel.Title>
                    {props.getCommonValue('prefix') ? props.getCommonValue('prefix') + " " : ""}{props.getCommonValue('first_name')} {props.getCommonValue('last_name')}
                </SidePanel.Title>
                <SidePanel.Subtitle>
                    <DisplayI18n field='name' defaultValue={props.getCommonValue('organisation').organisation_name} i18n={props.getCommonValue('organisation').i18n} />
                </SidePanel.Subtitle>
            </SidePanel.Header>

            <div className='p-3 border-bottom'>
                <Row className='mb-0'>
                    <Col sm='6'>
                        <div className="mb-3">
                            <div className="text-muted"><Translate id='form.fields.firstName' /></div>
                            <FormikEditable
                                id='formikEditableFirstName'
                                disabled={editDisabled}
                                initialValues={{
                                    first_name: props.getCommonValue("first_name") || "",
                                }}
                                validationSchema={object().shape({
                                    first_name: string().test({
                                        name: 'isRequired',
                                        message: <Translate id='form.validation.firstName.required' />,
                                        test: function(name){
                                            return !!props.getCommonValue('business_name') || !!name;
                                        },
                                    }),
                                })}
                                onSubmit={(values) => {
                                    if(values.first_name !== props.getCommonValue("first_name")){
                                        setIsLoading(true);
                                        const newValues = props.createNewValues(values);
                                        orgContext.updatePayee(props.getCommonValue('payee_id'), values)
                                            .then(() => {
                                                props.syncRows(newValues);
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setIsLoading(false))
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className="font-medium text-truncate">
                                                {props.getCommonValue('first_name') || '-'}
                                            </div>
                                        )
                                    }
                                    return (
                                        <FormikInputText id='first_name' name='first_name' trim />
                                    )

                                }}
                            </FormikEditable>
                        </div>
                    </Col>
                    <Col sm='6'>
                        <div className="mb-3">
                            <div className="text-muted"><Translate id='form.fields.lastName' /></div>
                            <FormikEditable
                                id='FormikEditableLastName'
                                disabled={editDisabled}
                                initialValues={{
                                    last_name: props.getCommonValue("last_name") || "",
                                }}
                                validationSchema={object().shape({
                                    last_name: string().test({
                                        name: 'isRequired',
                                        message: <Translate id='form.validation.firstName.required' />,
                                        test: function(name){
                                            return !!props.getCommonValue('business_name') || !!name;
                                        },
                                    }),
                                })}
                                onSubmit={(values) => {
                                    if(values.last_name !== props.getCommonValue("last_name")){
                                        setIsLoading(true);
                                        const newValues = props.createNewValues(values);
                                        orgContext.updatePayee(props.getCommonValue('payee_id'), values)
                                            .then(() => {
                                                props.syncRows(newValues);
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setIsLoading(false))
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className="font-medium text-truncate">
                                                {props.getCommonValue('last_name') || '-'}
                                            </div>
                                        )
                                    }
                                    return (
                                        <FormikInputText id='last_name' name='last_name' trim />
                                    )

                                }}
                            </FormikEditable>
                        </div>
                    </Col>
                    <Col sm='6'>
                        <div className="mb-3">
                            <div className="text-muted"><Translate id='form.fields.email' /></div>
                            <FormikEditable
                                id='FormikEditableEmail'
                                disabled={editDisabled}
                                initialValues={{
                                    email: props.getCommonValue("email") || '',
                                }}
                                validationSchema={object().shape({
                                    email: string().email(<Translate id='form.validation.email.valid' />),
                                })}
                                onSubmit={(values) => {
                                    if(values.email !== props.getCommonValue("email") || ''){
                                        setIsLoading(true);
                                        const newValues = props.createNewValues(values);
                                        orgContext.updatePayee(props.getCommonValue('payee_id'), values)
                                            .then(() => {
                                                props.syncRows(newValues);
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setIsLoading(false))
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className="font-medium text-truncate">
                                                {props.getCommonValue('email') || '-'}
                                            </div>
                                        )
                                    }
                                    return (
                                        <FormikInputText id='email' name='email' trim />
                                    )

                                }}
                            </FormikEditable>
                        </div>
                    </Col>
                    <Col sm='6'>
                        <div className="mb-3">
                            <div className="text-muted"><Translate id='settings.payees.serviceType' /></div>
                            <FormikEditable
                                id='FormikEditableServiceType'
                                disabled={editDisabled}
                                noConfirmation
                                initialValues={{
                                    service_type_id: props.getCommonValue("service_type_id"),
                                }}
                                validationSchema={object().shape({
                                    service_type_id: string(),
                                })}
                                onSubmit={(values) => {
                                    if(values.service_type_id !== props.getCommonValue("service_type_id")){
                                        setIsLoading(true);
                                        const newValues = props.createNewValues(values);
                                        orgContext.updatePayee(props.getCommonValue('payee_id'), values)
                                            .then(() => {
                                                props.syncRows(newValues);
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setIsLoading(false))
                                    }
                                }}
                            >
                                {(isEditing, options) => {
                                    const selectedServiceType = serviceTypes.find((s) => s.value === props.getCommonValue('service_type_id'));
                                    if(!isEditing){
                                        return (
                                            <div className="font-medium">
                                                {selectedServiceType ?
                                                    <DisplayI18n field='name' defaultValue={selectedServiceType.name} i18n={selectedServiceType.i18n} />
                                                    :
                                                    '-'
                                                }
                                            </div>
                                        )
                                    }
                                    return (
                                        <FormikSelect
                                            id='service_type_id' name='service_type_id'
                                            clearable autoFocus menuIsDefaultOpen
                                            onOptionSelected={(values) => {
                                                options.stopEditing();
                                            }}
                                            searchKeys={[
                                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                            ]}
                                            renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                            loadingStatus='success'
                                            defaultData={serviceTypes}
                                        />
                                    )

                                }}
                            </FormikEditable>
                        </div>
                    </Col>
                    <Col sm='6'>
                        <div className="mb-3">
                            <div className="text-muted"><Translate id='settings.payees.businessName' /></div>
                            <FormikEditable
                                id='FormikEditableBusinessName'
                                disabled={editDisabled}
                                initialValues={{
                                    business_name: props.getCommonValue("business_name"),
                                }}
                                validationSchema={object().shape({
                                    business_name: string().test({
                                        name: 'isRequired',
                                        message: <Translate id='settings.payees.businessName.required' />,
                                        test: function(name){
                                            return (!!props.getCommonValue("first_name") && !!props.getCommonValue("last_name")) || !!name;
                                        },
                                    }),
                                })}
                                onSubmit={(values) => {
                                    if(values.business_name !== props.getCommonValue("business_name")){
                                        setIsLoading(true);
                                        const newValues = props.createNewValues(values);
                                        orgContext.updatePayee(props.getCommonValue('payee_id'), values)
                                            .then(() => {
                                                props.syncRows(newValues);
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setIsLoading(false))
                                    }
                                }}
                            >
                                {(isEditing, options) => {
                                    if(!isEditing){
                                        return (
                                            <div className="font-medium">{props.getCommonValue("business_name") || "-"}</div>
                                        )
                                    }
                                    return (
                                        <FormikInputText
                                            id='businessName'
                                            name='business_name'
                                        />
                                    )

                                }}
                            </FormikEditable>
                        </div>
                    </Col>
                    <Col sm='6'>
                        <div className="mb-3">
                            <div className="text-muted"><Translate id='settings.payees.prefix' /></div>
                            <FormikEditable
                                id='FormikEditablePrefix'
                                disabled={editDisabled}
                                noConfirmation
                                initialValues={{
                                    prefix: props.getCommonValue("prefix") || '',
                                }}
                                validationSchema={object().shape({
                                    prefix: string(),
                                })}
                                onSubmit={(values) => {
                                    if(values.prefix !== props.getCommonValue("prefix") || ''){
                                        setIsLoading(true);
                                        const newValues = props.createNewValues(values);
                                        orgContext.updatePayee(props.getCommonValue('payee_id'), values)
                                            .then(() => {
                                                props.syncRows(newValues);
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => setIsLoading(false))
                                    }
                                }}
                            >
                                {(isEditing, options) => {
                                    if(!isEditing){
                                        return (
                                            <div className="font-medium">
                                                {props.getCommonValue("prefix") ?
                                                    <Translate
                                                        defaultMessage={props.getCommonValue("prefix")}
                                                        id={'settings.payees.prefix.' + (props.getCommonValue("prefix") || "").toUpperCase()}
                                                    />
                                                    : '-'}
                                            </div>
                                        )
                                    }
                                    return (
                                        <FormikSelect
                                            id='prefix' name='prefix'
                                            autoFocus menuIsDefaultOpen
                                            clearable
                                            onOptionSelected={(values) => {
                                                options.stopEditing();
                                            }}
                                            loadingStatus='success'
                                            defaultData={[
                                                { value: 'Dr.', label: 'settings.payees.prefix.DR.', translateLabel: true },
                                                { value: 'Mr.', label: 'settings.payees.prefix.MR.', translateLabel: true },
                                                { value: 'Mrs.', label: 'settings.payees.prefix.MRS.', translateLabel: true },
                                            ]}
                                        />
                                    )

                                }}
                            </FormikEditable>
                        </div>
                    </Col>
                </Row>

                <div className='mb-3'>
                    <div className="text-muted"><Translate id='form.fields.phone' /></div>
                    <FormikEditable
                        id='formikEditablePhone'
                        disabled={editDisabled}
                        initialValues={{
                            phone: props.getCommonValue('phone')?.split('#')?.[0] || '',
                            extension: props.getCommonValue('phone')?.split('#')?.[1] || '',
                        }}
                        validationSchema={object().shape({
                            phone: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />),
                            extension: number(),
                        })}
                        onSubmit={async(values) => {
                            if(values.phone !== props.getCommonValue('phone')?.split('#')?.[0] || '' || values.extension !== props.getCommonValue('phone')?.split('#')?.[1] || ''){
                                setIsLoading(true)
                                const newPhone = await addExtension(values.phone, values.extension)
                                const newValues = props.createNewValues({ phone: newPhone })
                                orgContext.updatePayee(props.getCommonValue('payee_id'), { phone: newPhone })
                                    .then(() => {
                                        props.syncRows(newValues)
                                        success();
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                            console.error(error.message)
                                        }
                                    })
                                    .finally(() => setIsLoading(false))
                            }
                        }}
                    >
                        {(isEditing, options, formik) => {
                            if(!isEditing){
                                return (
                                    <DisplayPhoneNumber phoneString={props.getCommonValue('phone')} withExtension format='INTERNATIONAL' emptyValue='-' />
                                )
                            }
                            return (
                                <div className='d-flex'>
                                    <div className='flex-grow-1'>
                                        <FormikPhoneInput
                                            id='phone'
                                            name='phone'
                                        />
                                    </div>
                                    <div className='flex-grow-0'>
                                        <FormikInputNumber id='extension' name='extension' trim allowNegative={false} />
                                    </div>
                                </div>
                            )

                        }}
                    </FormikEditable>
                </div>

                <div className='mb-3'>
                    <FormikEditable
                        id='formikEditableAddress'
                        disabled={editDisabled}
                        initialValues={{
                            address: getFormikAddressInitialValues(props.selectedRows[0]),
                        }}
                        validationSchema={object().shape({
                            address: object().address(true, {
                                streetNumber: <Translate id='form.validation.streetNumber.required' />,
                                address: <Translate id='form.validation.address.required' />,
                                city: <Translate id='form.validation.city.required' />,
                                zipCode: <Translate id='form.validation.zip.required' />,
                                state: <Translate id='form.validation.province.required' />,
                                country: <Translate id='form.validation.country.required' />,
                            }),
                        })}
                        onSubmit={({ address }) => {
                            if(
                                address.streetNumber !== (props.getCommonValue('street_number') || '') ||
                                    address.address !== (props.getCommonValue('street') || '') ||
                                    address.city !== (props.getCommonValue('city') || '') ||
                                    address.zipCode !== (props.getCommonValue('zip_code') || '') ||
                                    address.state !== (props.getCommonValue('province_code') || '') ||
                                    address.country !== (props.getCommonValue('country_code') || '')
                            ){
                                setIsLoading(true)
                                const newAddressObject = {
                                    street_number: address.streetNumber,
                                    street: address.address,
                                    city: address.city,
                                    zip_code: address.zipCode,
                                    province_code: address.state,
                                    country_code: address.country,
                                    map_url: address.mapsUrl,
                                    origin_address: address.origin,
                                }
                                const newValues = props.createNewValues(newAddressObject)
                                orgContext.updatePayee(props.getCommonValue('payee_id'), newAddressObject)
                                    .then(() => {
                                        props.syncRows(newValues)
                                        success();
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                            console.error(error.message)
                                        }
                                    })
                                    .finally(() => setIsLoading(false))
                            }
                        }}
                    >
                        {(isEditing, options, formik) => {
                            if(!isEditing){
                                return (
                                    <div>
                                        <div className="text-muted"><Translate id='form.fields.address' /></div>
                                        <div>
                                            {getFullAddress()}
                                        </div>
                                    </div>
                                )
                            }
                            return (
                                <FormikAddress
                                    id='address' name='address'
                                    allowManualPlace
                                    required
                                    label='form.fields.address'
                                    labelClassName="text-muted"
                                />
                            )

                        }}
                    </FormikEditable>
                </div>

                <div className='mb-3'>
                    <div className="text-muted"><Translate id='form.fields.address' /> 2 (<Translate id='form.fields.address2.placeholder' />)</div>
                    <FormikEditable
                        id='formikEditableAddress2'
                        disabled={editDisabled}
                        initialValues={{
                            unit_number: props.getCommonValue('unit_number') || '',
                        }}
                        validationSchema={object().shape({
                            unit_number: string(),
                        })}
                        onSubmit={async(values) => {
                            if(values.unit_number !== props.getCommonValue('unit_number') || ''){
                                setIsLoading(true)
                                const newValues = props.createNewValues(values)
                                orgContext.updatePayee(props.getCommonValue('payee_id'), values)
                                    .then(() => {
                                        props.syncRows(newValues)
                                        success();
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                            console.error(error.message)
                                        }
                                    })
                                    .finally(() => setIsLoading(false))
                            }
                        }}
                    >
                        {(isEditing, options, formik) => {
                            if(!isEditing){
                                return props.getCommonValue('unit_number') || '-'
                            }
                            return (
                                <FormikInputText id='unit_number' name='unit_number' trim />
                            )

                        }}
                    </FormikEditable>
                </div>
            </div>
        </OverlayLoader>
    )
}

export default PayeesSidepanel