import { FormGroup, Label } from 'reactstrap'
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import { useContext } from 'react';
import { UtilsContext } from '../../../../../contexts/UtilsContext';
import Translate from '@spordle/intl-elements';
import useSWR from 'swr';
import { useFormikContext } from 'formik';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';

const animatedConmponents = makeAnimated()

const OrganizationTags = ({ godAccess }) => {
    const { organisation_id } = useContext(OrganizationContext);
    const { getTags } = useContext(UtilsContext);
    const formik = useFormikContext();

    const { data: tags, isValidating } = useSWR(
        [ 'OrganizationTags', 'getTags', organisation_id ],
        () => getTags({ organisation_id: organisation_id, active: 1 }),
    );

    return (
        <FormGroup>
            <Label className={godAccess ? 'text-purple' : 'text-muted'} for='tags'><Translate id='organization.profile.overview.generalInfo.form.fields.tags' /></Label>
            <CreatableSelect
                id='tags'
                name='tags'
                isClearable
                isMulti
                components={animatedConmponents}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                placeholder={<Translate id='misc.search' />}
                noOptionsMessage={() => <Translate id='misc.noOptions' />}
                loadingMessage={() => <Translate id='misc.loading' />}
                formatCreateLabel={(inputValue) => <Translate id='misc.create.option' values={{ label: inputValue }} />}
                isLoading={isValidating}
                options={Array.isArray(tags) ?
                    tags.map((tag) => ({
                        value: tag.tag_id,
                        label: tag.name,
                    }))
                    : undefined
                }
                value={formik.values.tags}
                onChange={(values) => {
                    formik.setFieldValue('tags', values);
                }}
                onCreateOption={(inputValue) => {
                    formik.setFieldValue('tags', [ ...formik.values.tags, { newTag: true, label: inputValue, value: inputValue } ])
                }}
            />
        </FormGroup>
    )
}

export default OrganizationTags