import components from "./components";
import contexts from "./contexts";
import global from "./global";
import views from "./views";
import supportRequests from "./views/supportRequests";

export default{
    ...components,
    ...contexts,
    ...global,
    ...views,
    ...supportRequests,
}