import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import useSWR from 'swr';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import { MembersContext } from '../../../contexts/MembersContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import OverlayLoader from '../../loading/OverlayLoader';
import { fail } from "@spordle/toasts";
import TransferSidePanelHeader from './header/TransferSidePanelHeader';
import CommentsTab from './tabs/Comments';
import FinancesTab from './tabs/Finances';
import FinancesInternationalTab from './tabs/FinancesInternational';
import TeamsTab from './tabs/Teams';
import TransferTab from "./tabs/Transfer";

const TransferSidepanel = (props) => {
    const organizationContext = useContext(OrganizationContext);
    const memberContext = useContext(MembersContext);
    const [ activeTab, setActiveTab ] = useState('1');
    const [ sidePanelLoading, setLoading ] = useState(false);

    /** @returns {'OPEN'|'REFUSED'|'COMPLETED'} */
    const getActualStatus = (transfer) => {
        switch (transfer.transfer_request_state){
            case 'OPEN':
                return 'OPEN';
            case 'CLOSED':// Is either refused or completed
                return transfer.previous_action_done === 'DECLINE' ? 'REFUSED' : 'COMPLETED';
            default:
                break;
        }
        return transfer.transfer_request_state;
    }

    const { data: transfer, isValidating, mutate } = useSWR(
        [ 'getTransfer', props.selectedRows[0]?.member_transfer_id ],
        () => memberContext.getTransfer(props.selectedRows[0]?.member.member_id, props.selectedRows[0]?.member_transfer_id)
            .then((transferDetail) => {
                transferDetail.actualStatus = getActualStatus(transferDetail);
                return transferDetail;
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        ,
        {
            fallbackData: props.selectedRows[0] || {},
            revalidateOnMount: true, // Necessary here because of the fallbackData (https://swr.vercel.app/docs/options#options)
        },
    );

    const canDoWorkflow = (action) => {
        return isFetchedData() && transfer.current_workflow_step.find((step) => step.step_action === action)?.organisation.organisation_id === organizationContext.organisation_id;
    }

    const isFetchedData = () => !('member_id' in transfer)// The fetched data won't have the member_id here. It will be at transfer.member.member_id

    const onTransfer = () => {
        props.toggle();
        props.tableRef.refreshTable();
    }

    return (
        <OverlayLoader isLoading={isValidating || sidePanelLoading}>
            <div className='bg-white'>
                <TransferSidePanelHeader transfer={transfer} canDoWorkflow={canDoWorkflow} onTransfer={onTransfer} from={props.from} updateData={mutate} />

                <Nav tabs className='is-full'>
                    <NavItem className="flex-grow-1 text-center">
                        <NavLink
                            onClick={() => { setActiveTab('1'); }}
                            active={activeTab == '1'}
                        >
                            <Translate id='transfer.sidepanel.tabs.transfer.title' />
                        </NavLink>
                    </NavItem>
                    <NavItem className="flex-grow-1 text-center">
                        <NavLink
                            onClick={() => { setActiveTab('2'); }}
                            active={activeTab == '2'}
                        >
                            <Translate id='transfer.sidepanel.tabs.team.title' />
                        </NavLink>
                    </NavItem>
                    {(transfer.transfer_type === 'USA' || transfer.transfer_type === 'INTERNATIONAL') &&
                        <NavItem className="flex-grow-1 text-center">
                            <NavLink
                                onClick={() => { setActiveTab('3'); }}
                                active={activeTab == '3'}
                            >
                                <Translate id='members.profile.transfers.sidepanel.tabs.finances' />
                            </NavLink>
                        </NavItem>
                    }
                    <NavItem className="flex-grow-1 text-center">
                        <NavLink
                            onClick={() => { setActiveTab('4'); }}
                            active={activeTab == '4'}
                        >
                            <Translate id='transfer.sidepanel.tabs.comment.title' />
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <TransferTab {...props} transferLoading={isValidating} transfer={transfer} onTransfer={onTransfer} setLoading={setLoading} updateData={mutate} />
                    </TabPane>
                    <TabPane tabId="2">
                        <TeamsTab transfer={transfer} sidePanelProps={props} />
                    </TabPane>
                    {(transfer.transfer_type === 'USA' || transfer.transfer_type === 'INTERNATIONAL') &&
                        <TabPane tabId="3">
                            {transfer.transfer_type === 'USA' ?
                                <FinancesTab {...props} transfer={transfer} updateData={mutate} />
                                :
                                <FinancesInternationalTab {...props} transfer={transfer} mutate={mutate} />
                            }
                        </TabPane>
                    }
                    <TabPane tabId="4">
                        <CommentsTab isClosed={transfer.transfer_request_state === 'CLOSED'} transfer={transfer} />
                    </TabPane>
                </TabContent>
            </div>
        </OverlayLoader>
    );
}

export default TransferSidepanel;