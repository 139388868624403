import { useSpordleTable } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { success } from "@spordle/toasts";
import { useContext, useState } from "react";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { MessagesContext } from "../../../../../contexts/MessagesContext";
import { InboxContext } from "../../inboxContext/InboxContext";
import ToolBtn from "../ToolBtn";

const ArchiveTool = ({ checked, updateCheckedMsgs, disabled, newVal }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const { partiallyUpdateMessage } = useContext(MessagesContext);
    const { setShowTools } = useContext(InboxContext);
    const { updateDataWith, refreshTable } = useSpordleTable();

    const archiveMessages = (e) => {
        e.stopPropagation();
        setIsLoading(true);

        updateDataWith(checked.map((msg) => msg.message_id), { archive: newVal ?? 1 });
        updateCheckedMsgs({ archive: newVal ?? 1 });
        setShowTools(false);

        Promise.all(
            checked.map((msg) => partiallyUpdateMessage(msg.communication_message_id, { archive: newVal ?? 1 })),
        )
            .then(() => {
                refreshTable();
                success({ msg: `communications.inbox.action.toast.${newVal === 0 ? "unarchive" : "archive"}` });
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <ToolBtn
            label={<Translate id="communications.inbox.tools.archive" />}
            onClick={archiveMessages}
            isLoading={isLoading}
            disabled={disabled}
            icon="archive"
        />
    );
}

export default ArchiveTool;