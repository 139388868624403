export default{
    "catalog.clinicItems.add.title"            : "Add item",
    "catalog.clinicItems.add.form.name"        : "Name",
    "catalog.clinicItems.add.form.description" : "Description",
    "catalog.clinicItems.add.form.price"       : "Price",
    "catalog.clinicItems.add.form.taxable"     : "Taxable",
    "catalog.clinicItems.add.form.sku"         : "SKU",

    "catalog.clinicItems.add.form.validation.name.required"  : "Name is required",
    "catalog.clinicItems.add.form.validation.amout.required" : "Price is required",

    "catalog.clinicItems.add.form.error.3045" : "Can't apply taxes when the organisation doesn't have a province.",
}