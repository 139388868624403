import React from "react";
import {
    Row,
    Col,
    Fade,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Spinner
} from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import FormikEditable from '../../../../components/formik/FormikEditable';
import { FormikInputText, FormikSelect } from '@spordle/formik-elements';
import * as Yup from 'yup'
import { success, fail } from '@spordle/toasts';
import PropTypes from 'prop-types';

// Context
import { QualificationCategoriesContext } from '../../../../contexts/QualificationCategoriesContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import withContexts from '../../../../helpers/withContexts';

// Language
import I18nHelperContextProvider, { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../helpers/i18nHelper';
import Translate from "@spordle/intl-elements";
import ClinicCategory from "../../../clinics/ClinicCategory";
import ClinicColorPicker from "../../../clinics/ClinicColorPicker";
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../components/sidePanel/SidePanel";
import UserImg from "../../../../components/UserImg";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";

class SidePanelClinicCategories extends React.Component{

    state = {
        loadingState: 'lazy',
        isLoading: false,
        activeTab: '1',
    }

    toggleTab = (tab) => {
        if(this.state.activeTab !== tab){
            this.setState({ activeTab: tab });
        }
    }

    componentDidMount(){
        this.setState({ loadingState: 'success' })
    }

    render(){
        return (
            <I18nHelperContextProvider fields={[ 'name', 'description' ]}>
                <I18nHelperContext.Consumer>
                    {(i18nHelper) => (
                        <>
                            {this.state.loadingState === 'lazy' ?
                                <Fade in={this.state.loadingState === 'lazy'}>
                                    <div className='d-flex justify-content-center p-5'>
                                        <Spinner type='grow' color='primary' />
                                    </div>
                                </Fade>
                                : this.state.loadingState === 'success' ?
                                    <Fade in={this.state.loadingState === 'success'}>
                                        <OverlayLoader isLoading={this.state.isLoading}>
                                            <SidePanel.Header noBorder>
                                                <div className='d-flex mb-2 align-items-center'>
                                                    <SidePanel.ToggleButton />
                                                    <SidePanel.ActionsMenu action='DELETE' componentCode='clinics' componentPermissionCode='clinics_settings'>
                                                        <SidePanel.MenuDelete
                                                            translateModalMsg
                                                            label='settings.clinics.sidePanelClinicCategories.actions.remove'
                                                            modalMsg='settings.clinics.sidePanelClinicCategories.actions.remove.topText'
                                                            modalContent={
                                                                <Row className='mt-3'>
                                                                    <Col md='6' className='d-flex align-items-center justify-content-center flex-column border-right'>
                                                                        <div className='font-medium'>
                                                                            <DisplayI18n
                                                                                field="name"
                                                                                i18n={this.props.getCommonValue('i18n')}
                                                                                defaultValue={this.props.getCommonValue('name')}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md='6' className='d-flex align-items-center justify-content-center flex-column'>
                                                                        <div className='mr-2'>
                                                                            <UserImg
                                                                                src={this.props.OrganizationContext.logo?.full_path}
                                                                                alt={this.props.OrganizationContext.organisation_name}
                                                                                filePos={this.props.OrganizationContext.logo?.file_position}
                                                                                abbr={this.props.OrganizationContext.abbreviation}
                                                                            />
                                                                        </div>
                                                                        <div className='font-medium'>{this.props.OrganizationContext.organisation_name}</div>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            onConfirm={() => {
                                                                return this.props.QualificationCategoriesContext.deleteQualificationCategory(this.props.getCommonValue('qualification_category_id'))
                                                                    .then(() => {
                                                                        this.props.tableRef.deleteRow(this.props.getCommonValue('qualification_category_id'))
                                                                            .then(() => {
                                                                                this.props.toggle;
                                                                                success();
                                                                            })
                                                                    })
                                                                    .catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            console.error(error.message)
                                                                            fail({
                                                                                msg: 'misc.error',
                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                skipInfoTranslate: true,
                                                                            })
                                                                            this.setState(() => ({ loadingState: 'error' }));
                                                                        }
                                                                    })
                                                            }}
                                                        />
                                                    </SidePanel.ActionsMenu>
                                                </div>
                                                <SidePanel.Title>
                                                    <ClinicCategory className="mr-1" color={isNaN(parseInt(this.props.getCommonValue('color'))) ? 0 : parseInt(this.props.getCommonValue('color'))}>
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={this.props.getCommonValue('name')}
                                                            i18n={this.props.getCommonValue('i18n')}
                                                        />
                                                    </ClinicCategory>
                                                </SidePanel.Title>
                                                <SidePanel.Subtitle>
                                                    <DisplayI18n
                                                        field='description'
                                                        defaultValue={this.props.getCommonValue('description')}
                                                        i18n={this.props.getCommonValue('i18n')}
                                                    />
                                                </SidePanel.Subtitle>
                                            </SidePanel.Header>
                                            <Nav tabs>
                                                <NavItem className="flex-grow-1 text-center w-100">
                                                    <NavLink
                                                        className={stringBuilder({ active: this.state.activeTab === '1' })}
                                                        onClick={() => { this.toggleTab('1'); }}
                                                    >
                                                        <Translate id='settings.clinics.sidePanelClinicCategories.tabs.details' />
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent activeTab={this.state.activeTab}>
                                                <TabPane tabId="1">
                                                    {/* Categories INFO */}
                                                    <div className='p-3 border-bottom'>
                                                        <div className="h4 font-bold mb-3"><Translate id='settings.clinics.sidePanelClinicCategories.title' /></div>

                                                        <Row>
                                                            {/* STATUS */}
                                                            <Col className="mb-3">
                                                                <div className="text-muted"><Translate id='settings.clinics.sidePanelClinicCategories.status' /></div>
                                                                <FormikEditable
                                                                    noConfirmation
                                                                    id={`qualificationCategoryStatus`}
                                                                    initialValues={{
                                                                        active: this.props.getCommonValue("active"),
                                                                    }}
                                                                    validationSchema={Yup.object().shape({
                                                                        active: Yup.string().required(<Translate id='settings.clinics.sidePanelClinicCategories.status.required' />),
                                                                    })}
                                                                    onSubmit={(values) => {
                                                                        if(values.active !== this.props.getCommonValue("active")){
                                                                            this.setState(() => ({ isLoading: true }));
                                                                            const newValues = this.props.createNewValues(values);
                                                                            this.props.QualificationCategoriesContext.updateQualificationCategory(this.props.getCommonValue('qualification_category_id'), values)
                                                                                .then(() => {
                                                                                    this.props.syncRows(newValues);
                                                                                    success();
                                                                                })
                                                                                .catch((error) => {
                                                                                    if(!AxiosIsCancelled(error.message)){
                                                                                        console.error(error.message)
                                                                                        fail({
                                                                                            msg: 'misc.error',
                                                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                            skipInfoTranslate: true,
                                                                                        })
                                                                                    }
                                                                                })
                                                                                .finally(() => {
                                                                                    this.setState(() => ({ isLoading: false }));
                                                                                })
                                                                        }
                                                                    }}
                                                                >
                                                                    {(isEditing, options) => {
                                                                        if(!isEditing){
                                                                            return (
                                                                                <div className="font-medium">
                                                                                    <i className={`mdi ${this.props.getCommonValue("active") == '1' ? 'mdi-check text-primary' : 'mdi-close text-danger'} mr-2`} />
                                                                                    <span><Translate id={`settings.clinics.sidePanelClinicCategories.status.${this.props.getCommonValue("active") == '1' ? 'active' : 'inactive'}`} /></span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        return (
                                                                            <FormikSelect
                                                                                autoFocus menuIsDefaultOpen
                                                                                name="active" id="active"
                                                                                onOptionSelected={() => {
                                                                                    options.stopEditing();
                                                                                }}
                                                                                defaultData={[
                                                                                    { value: '1', label: 'settings.clinics.sidePanelClinicCategories.status.active', translateLabel: true },
                                                                                    { value: '0', label: 'settings.clinics.sidePanelClinicCategories.status.inactive', translateLabel: true },
                                                                                ]}
                                                                                loadingStatus='success'
                                                                            />
                                                                        )

                                                                    }}
                                                                </FormikEditable>
                                                            </Col>

                                                            {/* Color */}
                                                            <Col className="mb-3">
                                                                <div className="text-muted"><Translate id='settings.clinics.sidePanelClinicCategories.color' /></div>

                                                                <ClinicColorPicker
                                                                    key={isNaN(parseInt(this.props.getCommonValue('color'))) ? 0 : parseInt(this.props.getCommonValue('color'))}
                                                                    color={isNaN(parseInt(this.props.getCommonValue('color'))) ? 0 : parseInt(this.props.getCommonValue('color'))}
                                                                    onChangeColor={(value) => {
                                                                        return new Promise((resolve, reject) => {
                                                                            const newValues = this.props.createNewValues({ color: value });
                                                                            this.props.QualificationCategoriesContext.updateQualificationCategory(this.props.getCommonValue('qualification_category_id'), { color: value })
                                                                                .then(() => {
                                                                                    this.props.syncRows(newValues);
                                                                                    resolve(true);
                                                                                    success();
                                                                                })
                                                                                .catch((error) => {
                                                                                    if(!AxiosIsCancelled(error.message)){
                                                                                        console.error(error.message)
                                                                                        fail({
                                                                                            msg: 'misc.error',
                                                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                            skipInfoTranslate: true,
                                                                                        })
                                                                                    }
                                                                                    reject(false);
                                                                                })
                                                                        })
                                                                    }}
                                                                />

                                                            </Col>
                                                        </Row>

                                                        <RenderI18nForm field="name">
                                                            {({ fieldName, fieldLabel }) => {
                                                                return (
                                                                    <div className="mb-3" key={fieldName}>
                                                                        <div className='text-muted'>{fieldLabel}</div>
                                                                        <FormikEditable
                                                                            id={fieldName}
                                                                            initialValues={i18nHelper.getInitialValues(this.props.selectedRows[0])}
                                                                            validationSchema={Yup.object().shape(
                                                                                i18nHelper.getValidationSchema({ name: Yup.string().required() }),
                                                                            )}
                                                                            onSubmit={(values) => {
                                                                                this.setState(() => ({ isLoading: true }));
                                                                                const newValues = this.props.createNewValues(values);
                                                                                this.props.QualificationCategoriesContext.updateQualificationCategory(this.props.getCommonValue('qualification_category_id'), i18nHelper.getAPIValues(values))
                                                                                    .then(() => {
                                                                                        this.props.syncRows(newValues);
                                                                                        success();
                                                                                    })
                                                                                    .catch((error) => {
                                                                                        if(!AxiosIsCancelled(error.message)){
                                                                                            console.error(error.message)
                                                                                            fail({
                                                                                                msg: 'misc.error',
                                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                                skipInfoTranslate: true,
                                                                                            })
                                                                                        }
                                                                                    })
                                                                                    .finally(() => {
                                                                                        this.setState(() => ({ isLoading: false }));
                                                                                    })
                                                                            }}
                                                                        >
                                                                            {(isEditing) => {
                                                                                if(!isEditing){
                                                                                    return (
                                                                                        <DisplayI18n
                                                                                            field={fieldName}
                                                                                            defaultValue={this.props.selectedRows[0].name || '-'}
                                                                                            i18n={this.props.selectedRows[0].i18n}
                                                                                        />
                                                                                    )
                                                                                }
                                                                                return (
                                                                                    <FormikInputText id={fieldName} name={fieldName} trim autoFocus />
                                                                                )

                                                                            }}
                                                                        </FormikEditable>
                                                                    </div>
                                                                )
                                                            }}
                                                        </RenderI18nForm>

                                                        <RenderI18nForm field="description">
                                                            {({ fieldName, fieldLabel }) => {
                                                                return (
                                                                    <div className="mb-3" key={fieldName}>
                                                                        <div className='text-muted'>{fieldLabel}</div>
                                                                        <FormikEditable
                                                                            id={fieldName}
                                                                            initialValues={i18nHelper.getInitialValues(this.props.selectedRows[0])}
                                                                            validationSchema={Yup.object().shape(
                                                                                i18nHelper.getValidationSchema(),
                                                                            )}
                                                                            onSubmit={(values) => {
                                                                                this.setState(() => ({ isLoading: true }));
                                                                                const newValues = this.props.createNewValues(values);
                                                                                this.props.QualificationCategoriesContext.updateQualificationCategory(this.props.getCommonValue('qualification_category_id'), i18nHelper.getAPIValues(values))
                                                                                    .then(() => {
                                                                                        this.props.syncRows(newValues);
                                                                                        success();
                                                                                    })
                                                                                    .catch((error) => {
                                                                                        if(!AxiosIsCancelled(error.message)){
                                                                                            console.error(error.message)
                                                                                            fail({
                                                                                                msg: 'misc.error',
                                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                                skipInfoTranslate: true,
                                                                                            })
                                                                                        }
                                                                                    })
                                                                                    .finally(() => {
                                                                                        this.setState(() => ({ isLoading: false }));
                                                                                    })
                                                                            }}
                                                                        >
                                                                            {(isEditing) => {
                                                                                if(!isEditing){
                                                                                    return (
                                                                                        <DisplayI18n
                                                                                            field={fieldName}
                                                                                            defaultValue={this.props.selectedRows[0].description || '-'}
                                                                                            i18n={this.props.selectedRows[0].i18n}
                                                                                        />
                                                                                    )
                                                                                }
                                                                                return (
                                                                                    <FormikInputText id={fieldName} name={fieldName} trim autoFocus />
                                                                                )

                                                                            }}
                                                                        </FormikEditable>
                                                                    </div>
                                                                )
                                                            }}
                                                        </RenderI18nForm>

                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </OverlayLoader>
                                    </Fade>
                                    : null}
                        </>
                    )}
                </I18nHelperContext.Consumer>
            </I18nHelperContextProvider>
        )
    }
}

SidePanelClinicCategories.propTypes = {
    tableRef: PropTypes.object.isRequired,
    i18n: PropTypes.object,
}

export default withContexts(QualificationCategoriesContext, OrganizationContext)(SidePanelClinicCategories);