import { stringBuilder } from "@spordle/helpers";
import UserDisplay from "../../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../../components/UserImg";
import { HoverableCopy } from "../../../../../../../helpers/clipboardHelper";

const CancelAttendeeCard = ({ attendee, error, className, subtitle }) => {
    return (
        <UserDisplay card block className={stringBuilder(className)}>
            <UserDisplay.Container>
                <UserImg
                    src={attendee.member.picture?.full_path}
                    filePos={attendee.member.picture?.file_position}
                    className="text-uppercase"
                    width={40}
                    alt={attendee.member.first_name + ' ' + attendee.member.last_name}
                    abbr={attendee.member.first_name.charAt(0) + attendee.member.last_name.charAt(0)}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Title>{attendee.member.first_name} {attendee.member.last_name}</UserDisplay.Title>
                <UserDisplay.Subtitle>
                    <HoverableCopy toCopy={attendee.member.unique_identifier}>
                        #{attendee.member.unique_identifier}
                    </HoverableCopy>
                </UserDisplay.Subtitle>
                {subtitle &&
                    <UserDisplay.Subtitle>
                        {subtitle}
                    </UserDisplay.Subtitle>
                }
                {error &&
                    <UserDisplay.Subtitle className="text-danger">
                        {error}
                    </UserDisplay.Subtitle>
                }
            </UserDisplay.Container>
        </UserDisplay>
    );
}

export default CancelAttendeeCard;