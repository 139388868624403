export default{
    "organization.profile.venues.modals.components.createVenueStep1.btn.back" : "Back to locations list",

    "organization.profile.venues.modals.components.createVenueStep1.title" : "Location form step 1",

    "organization.profile.venues.modals.components.createVenueStep1.location.abbr"    : "Abbreviation",
    "organization.profile.venues.modals.components.createVenueStep1.location.address" : "Address",
    "organization.profile.venues.modals.components.createVenueStep1.location.name"    : "Location Name",
    "organization.profile.venues.modals.components.createVenueStep1.location.type"    : "Location Type",

    "organization.profile.venues.modals.components.createVenueStep1.location.error.address" : "Address is required.",
    "organization.profile.venues.modals.components.createVenueStep1.location.error.abbr"    : "Location abbreviation is required.",
    "organization.profile.venues.modals.components.createVenueStep1.location.error.name"    : "Location name is required.",
    "organization.profile.venues.modals.components.createVenueStep1.location.error.type"    : "Location type is required.",
}