export default{
    "catalog.installment.title"   : "Versements",
    "catalog.installment.rule"    : "Versements",
    "catalog.installment.rule.on" : " le ",

    "catalog.installment.form.installments"                    : "Versements",
    "catalog.installment.form.installments.note"               : "Sur le reste du montant à payer, appliquer les pourcentages pour atteindre un total de 100%.",
    "catalog.installment.form.installments.required"           : "Veuillez sélectionner un versement",
    "catalog.installment.form.installments.date.afterPrevious" : "Cette date doit être postérieure à la date du versement précédent.",
    "catalog.installment.form.installments.label.plural"       : "{count} versements",
    "catalog.installment.form.installments.label.single"       : "1 versement",
    "catalog.installment.form.title"                           : "Nom",
    "catalog.installment.form.title.note"                      : "Le titre des versements ne sera pas visible au public.",
    "catalog.installment.form.rules"                           : "Règles",
    "catalog.installment.form.initialValue"                    : "Paiement initial (%)",
    "catalog.installment.form.initialValue.note"               : "Le pourcentage du montant initial à débourser.",
    "catalog.installment.form.value"                           : "Valeur (%)",
    "catalog.installment.form.value.required"                  : "Veuillez spécifier un pourcentage.",
    "catalog.installment.form.value.total"                     : "Le total des pourcentages des versements doit être de 100%.",
    "catalog.installment.form.date"                            : "Date",
    "catalog.installment.form.date.helper"                     : "AAAA-MM-JJ",
    "catalog.installment.form.addRule"                         : "Ajouter un versement",
    "catalog.installment.form.status"                          : "Statut",

    "catalog.installment.add.title" : "Ajouter des versements",

    "catalog.installment.sidepanel.catalog.registrationFees"     : "Frais d'inscription",
    "catalog.installment.sidepanel.catalog.delete"               : "Vous êtes sur le point de supprimer ces versements",
    "catalog.installment.sidepanel.catalog.catalog"              : "Catalog",
    "catalog.installment.sidepanel.catalog.selectedRegistration" : "Frais d'inscription sélectionnés",

    "catalog.installment.linkedFees.error"       : "Nous n'avons pas pu trouver les frais d'inscriptions liés, veuillez contacter le support",
    "catalog.installment.linkedFees.title"       : "Dissocier des frais d'inscriptions",
    "catalog.installment.linkedFees.text"        : "Vous ne pouvez pas désactiver ce versement puisqu'il est associé à ces frais d'inscriptions.",
    "catalog.installment.linkedFees.submit"      : "Dissocier tout",
    "catalog.installment.linkedFees.success"     : "Tous les frais d'inscriptions sont dissociés!",
    "catalog.installment.linkedFees.successText" : "Vous pouvez maintenant désactiver le versement.",
    "catalog.installment.linkedFees.deactivate"  : "Désactiver",

    "catalog.installment.table.initialPayment"      : "Paiement initial",
    "catalog.installment.table.initialPayment.text" : " du montant total",

    "catalog.installment.table.empty.title" : "Aucun versement",

    "catalog.installment.delete.error.3197" : "Ces versements sont liés à un ou des frais d'inscription.",


    "catalog.installment.rollover.dropdown.button" : "Roulement",
    "catalog.installment.rollover.title"           : "Roulement des versements",
    "catalog.installment.rollover.warning"         : "La saison courante possède déjà {amount, plural, one {# versement} other {# versements}}. Veuillez vérifier si un roulement a déjà été fait pour minimiser les risques de données dupliquées.",
    "catalog.installment.rollover.prevSeasons"     : "Saison précédente",
    "catalog.installment.rollover.selectAll"       : "Tout sélectionner",
    "catalog.installment.rollover.selectNone"      : "Tout désélectionner",
    "catalog.installment.rollover.required"        : "Veuillez sélectionner au moins un versement",
    "catalog.installment.rollover.confirm"         : "Cliquez le bouton ci-dessous pour créer ces versements dans la nouvelle saison.",
}